import React, { useEffect } from 'react';
import theme from '../theme/theme';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { trunc, getTOC, randomString as generateRandomString, convertBlocksToPlain, convertPlainToBlocks,
         calculateExpiryDate } from '../utils'
import { useHistory } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer'
import { Element, scroller } from 'react-scroll'
import { Avatar, Box, Button, FormControlLabel, Grid, InputBase, Backdrop, 
         List, ListItem, ListItemAvatar, ListItemText, 
         Typography, IconButton, Radio, RadioGroup,
         makeStyles, 
         CircularProgress} from '@material-ui/core';
import { DialogAgrActivity, DialogAVDiff, DialogClauseHistory,
         DialogNegoApprovals, DialogNegoComments, DialogClauseEditMode, DrawerCore, FABMagic,
         NegoCard, SmartFieldEditable } from '.';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faLock, faExclamationTriangle, faThumbsUp, faSearch, faEllipsisH, faChevronLeft, 
         faChevronRight, faDownload, faUpload, faEye, faUserPlus, faPen, faComment, faSignature, faArrowRight, faArrowLeft, faUserEdit, faExclamationCircle, faExclamation, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import comments_chip from '../assets/img/comments_chip.png';
import pdf from '../assets/img/pdf.png';

import { renewaltypes } from '../assets/static/renewaltypes';

const axios = require('axios');
const dayjs = require('dayjs');

const AgreementEditor = (props) => {

  const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
    },
    clPlain: {
        lineHeight: '1.9',
        [theme.breakpoints.only('xs')]: { width: '300px' },
        [theme.breakpoints.only('sm')]: { width: '430px' },
        [theme.breakpoints.only('md')]: { width: '550px' },
        [theme.breakpoints.up('lg')]: { width: '670px',  },
    },
    lightBulbIcon: {
      color: props.org !== undefined && props.org !== null && props.org.orgSettings !== undefined && ['full'].includes(props.org.orgSettings.whiteLabelLevel) &&
        props.whiteLabel !== undefined && props.whiteLabel !== null && props.whiteLabel.color !== undefined ? 
        props.whiteLabel.color : theme.palette.primary.main,
      marginRight: '12px',
      fontSize: '12px'
    },
    snackBox3: {
      [theme.breakpoints.down('sm')]: { bottom: '85px' },
      [theme.breakpoints.up('md')]: { bottom: '95px' },
      position: 'fixed',
      right: '20px',
      height: 'auto',
      maxHeight: '70%',
      overflowY: 'auto',
      width: '215px', 
      backgroundColor: theme.palette.primary.contrastText,
      boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 24px 0px',
      //boxShadow: 'rgba(0, 0, 0, 0.25) 0px 3px 24px 0px, rgba(0, 0, 0, 0.18) 0px 0px 0px 1px',
      color: theme.palette.grey[900],
      //fontSize: '17px',
      padding: '15px 15px 15px 15px',
      borderRadius: '10px',
      lineHeight: '1.4',
      /*
      boxSizing: 'border-box',

      '&::after': {
        content: '""',
        position: 'absolute',
        width: '0px',
        height: '10px',
        marginLeft: '10px',
        bottom: '-10px',
        left: '50%',
        boxSizing: 'border-box',
        //border: 1em solid black;
        //border-color: transparent transparent #bada55 #bada55;
        transformOrigin: '0 0',
        transform: 'rotate(-45deg)',
        backgroundColor: theme.palette.primary.contrastText,
        border: '1px solid black',
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 24px 0px',
      }*/
    },
    snackClose: {
      position: 'absolute',
      left: 'auto',
      top: '20px', 
      right: '20px'
    },
    snackIconClose: {
      padding: '5px 7px 5px 7px',
      color: theme.palette.grey[600],
      fontSize: '16px',
    },
    agrTitle: {
      fontWeight: '700',
      [theme.breakpoints.only('xs')]: { width: '284px', fontSize: '20px' },
      [theme.breakpoints.only('sm')]: { width: '430px', fontSize: '28px' },
      [theme.breakpoints.only('md')]: { width: '560px', fontSize: '34px' },
      [theme.breakpoints.up('lg')]: { width: '670px', fontSize: '36px'  },
      paddingBottom: '5px',
    },
    cptyLock: {
      [theme.breakpoints.down('sm')]: { display: 'none' },
      width: '160px',
      position: 'fixed', 
      backgroundColor: 'rgba(255, 255, 255, .8)',
      color: theme.palette.grey[800],
      fontSize: '18px',
      top: 'auto', 
      left: 'auto',
      right: '20px', 
      bottom: '30px',
      zIndex: '200',
    },
    pdfNavigation: {
      [theme.breakpoints.only('xs')]: { bottom: '70px' },
      [theme.breakpoints.only('sm')]: { bottom: '80px' },
      [theme.breakpoints.up('md')]: { bottom: '20px' },
      position: 'fixed',
      right: '20px', 
      backgroundColor: 'rgba(255, 255, 255, .8)', 
      zIndex: '200',
    },
    secIcon: {
      color: theme.palette.secondary.main,
      paddingRight: '10px',
    },
    iconBCancel: {
      color: theme.palette.error.main,
      padding: '6px 10px 6px 10px'
    },
    iconBAccept: {
      color: theme.palette.success.main,
      padding: '6px 6px 6px 6px'
    },
    bold: {
      fontWeight: '700',
    },
    propertyUpdateBox:{
      borderRadius: '10px',
      border: '1px solid' + theme.palette.grey[400],
    },
    pdfIcon: {
      height: '36px',
      cursor: 'pointer',
    },
    boldSmall: {
      [theme.breakpoints.only('xs')]: { fontSize: '13px' },
      fontWeight: '700',
      fontSize: '20px',
    },
    chevIconB: {
      padding: '12px 16px 12px 16px',
      margin: '0px 5px 0px 5px',
    },
    upDownButton: {
      padding: '2px 15px 2px 15px',
    },
    penTurnComment: {
      fontWeight: 300, 
      fontSize: '15px',
      fontStyle: 'italic',
      borderTop: '1px dotted #dddddd',
    },
    bottomLinks: { 
      [theme.breakpoints.down('xs')]: { width: '330px' },
      [theme.breakpoints.only('sm')]: { width: '460px' },
      [theme.breakpoints.only('md')]: { width: '580px' },
      [theme.breakpoints.up('lg')]: { width: '700px' },
    },
    lightBulbIconSm: {
      color: theme.palette.secondary.main, 
      marginLeft: '7px',
    }
  }));
  const classes = useStyles();

  const history = useHistory()

  const [clauses, setClauses] = React.useState(props.clauses);
  const [chOpen, setChOpen] = React.useState(null) // Whether to the ClauseHistory is opened -- a singleClauseID  
  //const [ctOpen, setCtOpen] = React.useState(null) // Whether to the ClauseType is opened -- a ClauseID  
  const [optionClicked, setOptionClicked] = React.useState({option: null}); // Whether an option has been clicked inside the ReviewMode
  const [negoCommentsOpen, setNegoCommentsOpen] = React.useState(null); // Whether the negoCommentsDialog is opened
  const [negoApprovalsOpen, setNegoApprovalsOpen] = React.useState(null); // Whether the negoApprovalsDialog is opened
  const [agrActivityOpen, setAgrActivityOpen] = React.useState(false)
  const [activeAgrTitleUpdate, setActiveAgrTitleUpdate] = React.useState(false);
  const [toc, setToc] = React.useState(null);
  const [scrollHandled, setScrollHandled] = React.useState(false);
  const [dialogOpeningHandled, setDialogOpeningHandled] = React.useState(false)
  //const [snackWasShown, setSnackWasShown] = React.useState(false)
  const [clauseEditModeOpen, setClauseEditModeOpen] = React.useState(null)
  const [agrTitleValue, setAgrTitleValue] = React.useState({ currentFull: props.agrFullName, oldFull: props.agrFullName, currentShort: props.agrShortName, oldShort: props.agrShortName })
  //const [agrShortTitleValue, setAgrShortTitleValue] = React.useState(props.agrShortName) 
  const [snackReason, setSnackReason] = React.useState(null);
  const [penTurnComment, setPenTurnComment] = React.useState(null)
  const [activeClause, setActiveClause] = React.useState(null)
  const [changingEffectiveProperties, setChangingEffectiveProperties] = React.useState(false)
  const [effectivePropertyStaging, setEffectivePropertyStaging] = React.useState(null)
  const [numPagesPDF, setNumPagesPDF] = React.useState(null);
  const [pageNumberPDF, setPageNumberPDF] = React.useState(1);
  const [newFileAttachment, setNewFileAttachment] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  
  const [migrationNeeded, setMigrationNeeded] = React.useState(false) // TEMPORARY CODE---

  useEffect(() => { // Generate the Table of Content
    if(props.avClauses !== undefined && props.avClauses.length > 0 && // NOTE: This useeffect will not occur when there are no clauses (e.g. PDF import)
       clauses !== undefined && clauses.length > 0) {

        // TEMPORARY CODE---
        if(clauses.filter((c) => ['cl','cl1','cl2','cl3','cl4','preamble','sec1','sec2']).some((c) => c.title !== null)){
          setMigrationNeeded(true)
        } // END OF TEMPORARY CODE---
        
        //console.log(clauses, props.avClauses, props.plainMode)
        let t = getTOC(clauses, props.avClauses, props.plainMode)
        setToc(t);

        if(props.clid !== undefined && props.clid !== null && props.clid.startsWith('tick_') && !dialogOpeningHandled) {
          // The "clid" starts with tick_ indicating we need to open the review tickets dialog
          props.parentCall('reviewtickets'); 
          setDialogOpeningHandled(true);

        } else if(props.clid !== undefined && props.clid !== null && props.clid.startsWith('agractivity_') && !dialogOpeningHandled) {
          // the "clid" starts with "agractivity_ indicating we need to open the agr activity dialog
          handleCommsToggle()
          setDialogOpeningHandled(true);

        } else if(props.clid !== undefined && props.clid !== null && !['edp'].includes(props.clid) && !scrollHandled) { 
          let cid = clauses.some((c) => c.singleClauseID === props.clid) ? clauses.filter((c) => c.singleClauseID === props.clid)[0]._id : props.clid
          setActiveClause(cid)
          scrollTo(cid); 
          setScrollHandled(true); 
        }
    }

  }, [clauses, props.avClauses, props.clid, scrollHandled, props.plainMode])

  useEffect(() => { // Snack handling
    let snack = null
    if(!props.alreadyOpened && props.clid === undefined && !props.laterVersionOwnedByCpty && 
      ['Review', 'Negotiation'].includes(props.agrStatus)) {

      // Ready to Sign lightbulb
      if(props.signable) { snack = 'signReady' }
      // How to edit lightbulb ("REVIEW")
      if(
        props.agrStatus === 'Review' && props.user.role === 'Counterparty' && ['comment', 'edit', 'full'].includes(props.editMode)) { 
        if(snack !== null) { snack = 'howToEditAndSignReady' }
        else { snack = 'howToEdit' }
        setSnackReason(snack)
      } else if( // Changed by counterparty lightbulb ("NEGOTIATION")
      /*!props.edited*/ 
      props.agrStatus === 'Negotiation' && ['comment', 'edit', 'full'].includes(props.editMode) &&
      props.rel !== undefined && props.rel !== null && props.user !== undefined && props.user !== null) { 

        let oid = props.rel.primaryOrgID === props.user.orgID ? props.rel.secondaryOrgID : props.rel.primaryOrgID
        axios.get('/comment/commentpenturn/' + props.agrID + '/' + oid + '/' + props.avv) // Pull potential comment on penturn
        .then(function(resc) {

          if(snack !== null) { snack = 'changesByCptyAndSignReady' }
          else { snack = 'changesByCpty' }

          setPenTurnComment(resc.data.data)
          setSnackReason(snack)

        }).catch(function(err) { console.log(err) })
      } else if(!['none'].includes(props.editMode)) {
        setSnackReason(snack);
      }
    }
    
  }, [props.editMode, props.agrStatus, props.avsPenTurnDetail, props.user.role])

  const handleOnIdle = e => { // If idle for too long, reroute as you lost any active/locked clauses
    let path = "/core/" + props.module + "/agreement/" + props.rid + "/" + props.agrID
    if(props.templating) { path = "/core/legal/templates/" + props.module + "/edit/" + props.activeOat._id }
    history.push(path)
  }

  /*const {} = */
  useIdleTimer({
    timeout: 1000 * 60 * 30, // last variable is # of minutes
    onIdle: handleOnIdle,
    debounce: 500
  })

  const cptyName = props.curCpty === "primary" && props.secondaryCpty !== undefined && props.secondaryCpty !== null && props.secondaryCpty.shortName !== undefined ? props.secondaryCpty.shortName :
                   props.curCpty === "secondary" && props.primaryCpty !== undefined && props.primaryCpty !== null && props.primaryCpty.shortName !== undefined ? props.primaryCpty.shortName : 'Unknown'

  const cptyLegalName = props.curCpty === "primary" && props.secondaryCpty !== undefined && props.secondaryCpty !== null && props.secondaryCpty.legalName !== undefined ? props.secondaryCpty.legalName :
                   props.curCpty === "secondary" && props.primaryCpty !== undefined && props.primaryCpty !== null && props.primaryCpty.legalName !== undefined ? props.primaryCpty.legalName : 'Unknown'

  const isPrimary = props.rel !== undefined && props.rel !== null && props.user !== undefined && props.user !== null && props.user.orgID === props.rel.primaryOrgID

  const showPDFAgrExec = (props.agr !== undefined && props.agr.agrStatus === 'InForce' && props.viewablePDF !== undefined && props.viewablePDF !== null && clauses.length === 0)
  const showPDFAttachment = (props.avAttachment !== undefined && props.avAttachment !== null && props.avAttachment !== '')

  // TEMPORARY CODE---
  const migrateAgreement = () => {

    let bulkWrites = []
    let lastUpdateDate = new Date().toISOString();

    console.log("toc", toc)

    clauses.forEach((c) => {

      if(['preamble','cl','cl1','cl2','cl3','cl4','sec','ssec', 'table', 'agr'].includes(c.clauseCat) && c.title !== null){
        console.log("migrating")
        let t = toc.filter((tc) => tc.id === c._id)[0];
        if(t === undefined) { console.log("ERROR: toc not found", c); }
        console.log(t === undefined ? "toc not found" : t.no)
        console.log(c.title)
        console.log(c.blocks)

        let newBlocks = {}
        let newPlain = []
        let newClauseObject = {}
        let newPC = c.pendingChange.filter((pc) => !['tableInsert', 'tableChange', 'titleChange'].includes(pc.type))

        // Construct Title Block
        let titleBlock = ''
        if(t !== undefined && t.no !== undefined && t.no !== '') { titleBlock = (titleBlock + t.no).trim() + ' ' }
        if(c.title !== undefined && c.title !== '') { titleBlock = titleBlock + c.title.trim() }

        if(['table'].includes(c.clauseCat)) {

          let newHead = [], newRows = []
          let pc = 
            c.pendingChange.filter((pc) => pc.type === 'tableInsert')[0] !== undefined ? 
                c.pendingChange.filter((pc) => pc.type === 'tableInsert')[0] : 
            c.pendingChange.filter((pc) => pc.type === 'tableChange')[0] !== undefined ? 
                c.pendingChange.filter((pc) => pc.type === 'tableChange')[0] : null

          c.clauseObject.head.forEach((h) => {
            let newH = h
            if(pc !== null) { 
              newH.change = {
                type: pc.type === 'tableInsert' ? 'insertTable' : 'change',
                cpty: pc.changeOrg,
                oldVal: '',
              }
            } else { newH.change = {} }
            newHead.push(newH)            
          })

          c.clauseObject.rows.forEach((r) => {
            let newR = { rowKey: r.rowKey }
            let newCols = []
            r.cols.forEach((c) => {
              let newC = c;
              if(pc !== null) { 
                newC.change = {
                  type: pc.type === 'tableInsert' ? 'insertTable' : 'change',
                  cpty: pc.changeOrg,
                  oldVal: '',
                }
              } else { newC.change = {} }
              newCols.push(newC)
            })
            newR.cols = newCols
            newRows.push(newR)
          })

          newClauseObject = {
            invisibleHead: false,
            head: newHead,
            rows: newRows,
          }

        } else if(['sec','ssec'].includes(c.clauseCat)) {

          titleBlock = 
            (['sec'].includes(c.clauseCat) ? '<secTitle>' : '<ssecTitle>') + 
            titleBlock +
            (['sec'].includes(c.clauseCat) ? '</secTitle>' : '</ssecTitle>')

          newBlocks = convertPlainToBlocks([titleBlock]);
          newPlain = titleBlock;

        } else {

          let bblocks = []
          if(titleBlock !== '' && c.title !== 'preamble' && c.clauseCat !== 'preamble') { // If applicable, insert a new titleBlock into newBlocks
            bblocks.push({
              entityRanges: [],
              inlineStyleRanges: [],
              key: generateRandomString(5),
              text: titleBlock,
              type: "clauseTitle",
              //_id: generateRandomString(24)
            })
          }
          c.blocks.blocks.forEach((cb) => { // Push all the remaining blocks as regularFont
            cb.type = 'clausePar';
            bblocks.push(cb)
          })

          newBlocks = { blocks: bblocks, entityMap: c.blocks.entityMap }
          newPlain = convertBlocksToPlain(newBlocks)
        }

        console.log("from B to B", c.blocks, newBlocks)
        console.log("from P to P", c.plain, newPlain)
        console.log("from CO to CO", c.clauseObject, newClauseObject)
        console.log("from PC to PC", c.pendingChange, newPC)

        bulkWrites.push({
          //{ updateOne: { 
              "filter": { "_id": c._id }, 
              "update": { $set: {
                "clauseTypes": c.clauseTypes,
                "clauseStatus": c.clauseStatus,
                "commentStatus": c.commentStatus,
                "approvalStatus": c.approvalStatus,
                "internalWorkflow": c.internalWorkflow,
                "clauseCat": c.clauseCat === 'agr' ? 'cl1' : c.clauseCat, 
                "title": null,
                "blocks": newBlocks, 
                "plain": newPlain, 
                "clauseObject": newClauseObject,
                "version": c.version + 1,
                "pendingChange": newPC,
                "numberType": c.numberType,
                "numberManual": c.numberManual,
                "lastUpdateBy": 'migration-for-titleredlining',
                "lastUpdateDate": lastUpdateDate,
              } },
              "upsert": true
            //} 
          })

      } else {
        console.log("not migrating", c.clauseCat, c)
      }
      
    })

    console.log("bulkWrites",bulkWrites)

    axios.put('/clause/protect/clauses', { // REUSING THE BULK CREATE (FOR BULK UPDATE PURPOSES)
      bulkWrites: bulkWrites, 
    })
    .then((res) => {

      console.log("complete migration - waiting for the push", res.data)

      let path = "/core/" + props.module + "/agreement/" + props.rid + "/" + props.agrID + "/" + props.clid
      if(props.templating) { path = "/core/legal/templates/" + props.module + "/edit/" + props.activeOat._id + "/" + props.clid }
      setTimeout(function() { history.push(path) }, 2000);

    }).catch((err) => { console.log("ERR IN MIGRATION / BULKWRITE", err) })


  }// END OF TEMPORARY CODE---

  function onDocumentLoadSuccess({ numPages }) {
    setNumPagesPDF(numPages);
  }

  const scrollTo = (ref) => {
    scroller.scrollTo(ref, {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -50,
    })
  }

  const handleSnackOpen = () => {
    
    let oid = props.rel.primaryOrgID === props.user.orgID ? props.rel.secondaryOrgID : props.rel.primaryOrgID
    axios.get('/comment/commentpenturn/' + props.agrID + '/' + oid + '/' + props.avv) // Pull potential comment on penturn
    .then(function(resc) {

      // Set any potential penTurnComment
      if(resc.data !== undefined && resc.data !== null && resc.data.data !== undefined && resc.data.data !== null &&
        resc.data.data.comment !== undefined && resc.data.data.comment !== null) {
        setPenTurnComment(resc.data.data) 
      }

      // Set snackreason
      setSnackReason(props.signable && ['Review', 'Negotiation'].includes(props.agrStatus) ? 
        'changesByCptyAndSignReady' : 'changesByCpty')

    }).catch(function(err) { console.log(err) })

  }

  const toggleCommentApprovalStatus = (newStatus, type, clid) => {

    let cls = clauses; // initialize cls for state update
    let c = cls.filter((c) => c._id === clid || c.singleClauseID === clid)[0]
    const index = cls.findIndex(cl => cl._id === clid || cl.singleClauseID === clid)

    if(c !== undefined) { // the clause was still found in the current agreement - update in the database AND locally
      props.updateClauseStatus(
        type === 'comment' ? c._id : c.singleClauseID, // singleClauseID
        type, // type
        newStatus, // newStatus
        props.user._id // lastUpdateBy
      )
      
      if(!props.templating && ['approval', 'comment'].includes(type)) { // logLevel, logType, singleClauseID, ticketID, keyVal1, keyVal2
        props.handleActivityLog('clause', type === 'comment' ? 'clNewCommentStatus' : 'clNewApprovalStatus', c.singleClauseID, '', props.avv, newStatus, {}) 
      } 

      cls[index] = { ...cls[index], 
        commentStatus: (type === 'comment') ? newStatus : c.commentStatus, 
        approvalStatus: (type === 'approval') ? newStatus : c.approvalStatus,
        internalWorkflow: (type === 'workflow') ? newStatus.status === 'None' ? [] : [newStatus] : c.internalWorkflow // newStatus for internalWorkflow only contains an {object} about the curcpty - this will be merged server side
      }

      setClauses([...cls]);

    } else { // the clause no longer exists in the current agreement (e.g. deleted) - still update in the database

      props.updateClauseStatus(
        clid, // singleClauseID
        type, // type
        newStatus, // newStatus
        props.user._id // lastUpdateBy
      )
    }
  }

  const handleDrawerClick = (action) => {
    if(action !== undefined && action !== null && action.click === 'version'){
      props.parentCall('oldAV', action.avv)
    } else if (action === 'cloneVersion') {
      props.parentCall('cloneVersion', null)
    } else if(action === 'filterClause') {
      props.handleToggleFilter(null, "clause")
    } else if (action === 'filterChanges') {
      props.handleToggleFilter(null, "changes")
    } else if(action === 'initiateSigning') {
      handleInitiateSigning()
    } else if(['changeEffDate', 'changeRenewal', 'changeTerm', 'changeExpDate'].includes(action)) {
      window.scrollTo(0, 0); // Scroll to top
      setChangingEffectiveProperties(true)
      setEffectivePropertyStaging({
        effectiveDate: props.agr.effectiveDate,
        renewalType: props.agr.renewalType,
        effectiveTerm: props.agr.effectiveTerm !== undefined && props.agr.effectiveTerm !== null && props.agr.effectiveTerm.initial !== undefined && props.agr.effectiveTerm !== null ?
          props.agr.effectiveTerm :
          {isCustom: true, initial: null, renewal: null},
        expiryDate: props.agr.expiryDate,
      })
    } else if(action === 'acceptagr') {
      props.handleAcceptEverything()
    }
  }

  const handleCancelTitleUpdate = () => {
    setAgrTitleValue({...agrTitleValue, currentFull: agrTitleValue.oldFull, currentShort: agrTitleValue.oldShort})
    setActiveAgrTitleUpdate(false)
  }

  const handleAgrTitleUpdate = () => { // For exhibits this function is inside "AgreementExhList"
    if(agrTitleValue.currentFull.length > 2 && agrTitleValue.currentShort.length > 1) {
      props.updateAgrNames(props.templating ? props.activeOat._id : props.agrID, agrTitleValue.currentFull, agrTitleValue.currentShort, props.user._id)
      if(!props.templating) { props.handleActivityLog('agr', 'agrNewName', '', '', props.user.displayName, '', { old: props.agrFullName, new: agrTitleValue.currentFull }) } // logLevel, logType, singleClauseID, ticketID, keyVal1, keyVal2
      setActiveAgrTitleUpdate(false)
      setAgrTitleValue({...agrTitleValue, oldFull: agrTitleValue.currentFull, oldShort: agrTitleValue.currentShort})
    }
  }

  const handleClauseEditMode = (type, clid) => {
    setClauseEditModeOpen(type === null ? null : { type:type, clid: clid });
  }

  const handleSnackClose = (event, reason) => {
    setSnackReason(null);
    if(penTurnComment !== null && penTurnComment.expanded) { setPenTurnComment({...penTurnComment, expanded: false }) }
  };

  const handleChOpen = (sclid) => { // Whether to open the ClauseHistory
    setChOpen(sclid)
  }

  const handleChClose = () => { // Whether to close the ClauseHistory
    setChOpen(null)
  }

  /*
  const handleCtClose = () => { // Whether to close the ClauseType assistance
    setCtOpen(null)
  }*/

  const handleNegoCommentsOpen = (clid) => {
    setNegoCommentsOpen(clid)
  }

  const handleNegoCommentsClose = () => {
    setNegoCommentsOpen(null)
  }

  const handleNegoApprovalsOpen = (clid) => {
    setNegoApprovalsOpen(clid)
  }

  const handleNegoApprovalsClose = () => {
    setNegoApprovalsOpen(null)
  }

  const handleInitiateSigning = () => {
    setSnackReason(null)
    props.parentCall('sign', null)
  }


  const callbackOptions = (child, action, optionalHint) => { // upon having a redline of an editor
  
    if (action === "clickHandled") {
      setOptionClicked({option: null, clid: child});

    } else if (action === 'acceptagr') {
      props.handleAcceptEverything()

    } else if (action === 'lightbulbOpen') {
      setSnackReason('howToEdit')

    /*} else if (action === 'clauseTypeOpen') {
      setCtOpen(optionalHint)
    */
    } else if (['toggleActivateClause'].includes(action)) {
      setActiveClause(optionalHint ? child : null)

    } else if (['previousClause','nextClause'].includes(action)) {
      let cs = clauses.filter((c) => c.clauseStatus !== 'Deleted').sort((a,b) => props.avClauses.indexOf(a._id) - props.avClauses.indexOf(b._id))
      let newIdx = cs.findIndex((c) => c._id === child) + (['previousClause'].includes(action) ? -1 : 1)

      if(cs[newIdx] !== undefined) {
        setActiveClause(cs[newIdx]._id)
        setOptionClicked({option: ['previousClause'].includes(action) ? 'focusEnd' : 'focusStart', clid: cs[newIdx]._id})
      }

    } else if (['insertTitle'].includes(action)) {
      setOptionClicked({ option: action, clid: child });

    }
    // Set "edited" because if cpty was "ready to sign" then need to make sure to update the Agreement Version to "not anymore"   
    if (['wasEdited', 'titleUpdate'].includes(action) && !props.templating && !props.edited ) { props.parentCall('wasEdited', true); }
  }

  const handleCommsToggle = () => {
    setAgrActivityOpen(!agrActivityOpen)
  }

  const handleAgrActivityClose = () => {
    setAgrActivityOpen(false);
  }

  const handleEffectivePropertyChange = (sfid, val, value) => {

    //let newExpDate = calculateExpiryDate(newEffectiveDate, newRenewalType, newEffectiveTerm) !== null ? 
    //      calculateExpiryDate(newEffectiveDate, newRenewalType, newEffectiveTerm) : props.agr.expiryDate

    if(['changeEffDate'].includes(sfid)) {
      //let newExpDate = calculateExpiryDate(value, effectivePropertyStaging.renewalType, effectivePropertyStaging.effectiveTerm) !== null ? 
          //calculateExpiryDate(value, effectivePropertyStaging.renewalType, effectivePropertyStaging.effectiveTerm) : effectivePropertyStaging.expiryDate
      setEffectivePropertyStaging({...effectivePropertyStaging, effectiveDate: value/*, expiryDate: newExpDate*/})
    } else if(['initial', 'renewal'].includes(sfid)) {
      let curTerm = 
        sfid === "initial" && effectivePropertyStaging.effectiveTerm !== undefined && effectivePropertyStaging.effectiveTerm.initial !== undefined && effectivePropertyStaging.effectiveTerm.initial !== null ? effectivePropertyStaging.effectiveTerm.initial : 
        sfid === "renewal" && effectivePropertyStaging.effectiveTerm !== undefined && effectivePropertyStaging.effectiveTerm.renewal !== undefined && effectivePropertyStaging.effectiveTerm.renewal !== null ? effectivePropertyStaging.effectiveTerm.renewal : 
        "_"
      let underscoreIdx = curTerm.indexOf("_")
      let newTerm = 
        (val === 'val1' ? value : curTerm.substring(0, underscoreIdx)) +
        "_" +
        (val === 'val2' ? value : curTerm.substring(underscoreIdx + 1))

      let newEffectiveTerm = {...effectivePropertyStaging.effectiveTerm,
        isCustom: false,
        initial: sfid === "initial" ? newTerm : effectivePropertyStaging.effectiveTerm.initial,
        renewal: sfid === "renewal" ? newTerm : effectivePropertyStaging.effectiveTerm.renewal,
      }

      //let newExpDate = calculateExpiryDate(effectivePropertyStaging.effectiveDate, effectivePropertyStaging.renewalType, newEffectiveTerm) !== null ? 
        //calculateExpiryDate(effectivePropertyStaging.effectiveDate, effectivePropertyStaging.renewalType, newEffectiveTerm) : effectivePropertyStaging.expiryDate

      setEffectivePropertyStaging({
        ...effectivePropertyStaging,
          effectiveTerm: newEffectiveTerm,
          //expiryDate: newExpDate,
      })
    } else if(['changeExpDate'].includes(sfid)) {
      setEffectivePropertyStaging({...effectivePropertyStaging, expiryDate: value})
    }
  }

  const handleSubmitEffectivePropertyChange = () => {

    let newEffectiveDate = effectivePropertyStaging.effectiveDate
    let newRenewalType = effectivePropertyStaging.renewalType
    let newEffectiveTerm = 
      //effectivePropertyStaging.effectiveDate !== props.agr.effectiveDate ? 
      //{ isCustom: true } :
          effectivePropertyStaging.effectiveTerm

    let newExpDate = 
      effectivePropertyStaging.effectiveDate !== props.agr.effectiveDate ? 
          effectivePropertyStaging.expiryDate : 
      calculateExpiryDate(newEffectiveDate, newRenewalType, newEffectiveTerm) !== null ? 
          calculateExpiryDate(newEffectiveDate, newRenewalType, newEffectiveTerm) :
          props.agr.expiryDate

    axios.put('/agr/agreffective/' + props.agrID, {
      effectiveDate: newEffectiveDate,
      renewalType: newRenewalType,
      effectiveTerm: newEffectiveTerm,
      expiryDate: newExpDate,
      lastUpdateBy: props.user._id,
      lastUpdateDate: new Date().toISOString(),
    })
    .then(function (res) { // success

      history.push("/core/" + props.module + "/agreement/" + props.rid + "/" + props.agrID + "/edp")

    }).catch(function (err) { console.log(err) })
  }

  const handleUploadChange = (e) => {
    if(e.target !== undefined && e.target.files !== undefined) {
        setNewFileAttachment(e.target.files[0])
    }
  }

  const handleNewFileAttachmentSubmit = () => {
    setLoading(true)
    
    if(newFileAttachment !== undefined && newFileAttachment !== null) {
      const formData = new FormData(); // upload attachment  
      formData.append('file', newFileAttachment, generateRandomString(20)); // appending file
      axios.post('/upload/protect/pdfattach', formData, {}) // Upload PDF and get url
      .then(resupload => {
        axios.put('/agr/agrvattach/' + props.avID, { attachment: resupload.data.docURL })
        .then(resnewav => {
          props.parentCall('wasEdited', true) // Set wasEdited for parent
          // Now create an activity log for this attachment change - then reroute
          props.handleActivityLog('agr', 'pdfChanged', '', '', props.avv, props.user.displayName, { old: props.avAttachment, new: resupload.data.docURL}) 
          history.push("/core/" + props.module + "/agreement/" + props.rid + "/" + props.agrID)

        }).catch(function(err) { console.log(err) })
      }).catch(function(err) { console.log(err) })
    }
  }

  return (
    <div className={classes.root}>

    <DrawerCore
        agreements={[]}
        uid={props.user !== undefined && props.user !== null ? props.user._id : ''}
        agrID={props.agrID}
        activeOat={props.activeOat}
        page="agreement"
        toc={toc}
        rid={props.rid}
        relType={props.rel !== undefined && props.rel !== null ? props.rel.relationshipType : ''}
        pageType='agreement'
        module={props.templating ? 'legal' : props.module}
        scrollTo={scrollTo}
        whiteLabel={props.whiteLabel}
        whiteLabelLoading={props.whiteLabelLoading}
        oatVersions={props.oatVersions}
        handleTemplateFunction={props.handleTemplateFunction}
        freezeTemplating={props.freezeTemplating}
        agrTypes={props.agrTypes}
        handleCommsToggle={handleCommsToggle}
        handleDrawerClick={handleDrawerClick}
        primaryCpty={props.primaryCpty}
        secondaryCpty={props.secondaryCpty}
        penHolder={!props.templating && ['Draft', 'Review', 'Negotiation', 'Request'].includes(props.agrStatus) &&
                   ((props.curCpty === "primary" && !props.laterVersionOwnedByCpty) || (props.curCpty === "secondary" && props.laterVersionOwnedByCpty)) ?
                      "primary" :
                   !props.templating && ['Draft', 'Review', 'Negotiation', 'Request'].includes(props.agrStatus) &&
                   ((props.curCpty === "primary" && props.laterVersionOwnedByCpty) || (props.curCpty === "secondary" && !props.laterVersionOwnedByCpty)) ?
                      "secondary" : "none"}
        readyToSign={props.agrStatus === 'Execution' ? 
                      "both" :
                     !props.edited && ['Review', 'Negotiation'].includes(props.agrStatus) && 
                     props.avPrimaryReady ?
                      "primary" :
                     !props.edited && ['Review', 'Negotiation'].includes(props.agrStatus) && 
                     props.avSecondaryReady ?
                      "secondary" : "none"}
        agrStatus={props.agrStatus}
        agrsAvs={props.agrsAvs}
        parentAgrStatus={props.parentAgrStatus}
        oatStatus={props.oatStatus}
        editMode={props.editMode}
        plainMode={props.plainMode}
        avsPenTurnDetail={props.avsPenTurnDetail}
        agr={props.agr}
        org={props.org}
        user={props.user}
        avv={props.avv}
        avs={props.avs}
        isAttachment={showPDFAttachment}
        uemail={props.user !== undefined && props.user !== null ? props.user.email : ''}
        openSummary={(['Negotiation'].includes(props.agrStatus) && !props.laterVersionOwnedByCpty && !props.edited) || props.templating}
        openEdp={['edp'].includes(props.clid)}
        signable={props.signable}
        isPrimary={isPrimary}
        users={props.users}
        laterVersionOwnedByCpty={props.laterVersionOwnedByCpty}
        approvalTicketsExist={props.approvalTicketsExist}
        approvalTicketsBadge={props.approvalTicketsBadge}
        reviewTicketsExist={props.reviewTicketsExist}
        reviewTicketsBadge={props.reviewTicketsBadge}
        parentCall={props.parentCall}
        handleNegoApprovalsOpen={handleNegoApprovalsOpen}
        handleClauseEditMode={handleClauseEditMode}
        handleAgrActOpen={props.handleAgrActOpen}
        handleReorderExhibits={props.handleReorderExhibits}
        deactivateAgreement={props.deactivateAgreement}
        handleDownloadPDF={props.handleDownloadPDF}
        subagLink={props.subagLink}
        saveTemplateLink={props.saveTemplateLink}
        timeBase={props.timeBase}
        pushTime={props.pushTime}
        handleSmartModeToggle={props.handleSmartModeToggle} 
        smartMode={props.smartMode}
        smartFields={props.smartFields !== undefined && props.smartFields !== null ? props.smartFields : []}
    />

    {showPDFAgrExec ?
    <Box className={classes.pdfNavigation}>
      <Box align="center">
        <img src={pdf} alt="PDF Download" className={classes.pdfIcon} onClick={props.handleDownloadPDF} />
      </Box>
      {/*
      <IconButton className={classes.chevIconB} disabled={pageNumberPDF === 1}
      onClick={e => setPageNumberPDF(pageNumberPDF - 1)}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </IconButton>
      <span className={classes.boldSmall}>
      {pageNumberPDF} of {numPagesPDF}
      </span>
      <IconButton className={classes.chevIconB} disabled={pageNumberPDF === numPagesPDF}
      onClick={e => setPageNumberPDF(pageNumberPDF + 1)}>
        <FontAwesomeIcon icon={faChevronRight} />
      </IconButton>*/}
    </Box>
    :
    !props.plainMode && 
    !props.laterVersionOwnedByCpty &&
    !props.templating && !['Request'].includes(props.agrStatus) && 
    ((clauses !== undefined && clauses !== null && clauses.filter((c) => !c.delete).length > 0) ||
     (showPDFAttachment)) ? // the PEN

    /*!props.plainMode && 
    (!props.laterVersionOwnedByCpty &&
    ((!props.templating && !['Request'].includes(props.agrStatus) && clauses !== undefined && clauses !== null &&
        (clauses.filter((c) => !c.delete).length > 0 || showPDFAttachment)) )) ?*/

        <FABMagic
        hasActiveClause={activeClause !== null}
        userRole={props.user.role}
        signable={props.signable}
        editMode={props.editMode}
        snackOpen={handleSnackOpen}
        fabType= {
            !props.laterVersionOwnedByCpty && 
            (!props.templating && !['Request'].includes(props.agrStatus) && clauses !== undefined && clauses !== null &&
            (clauses.filter((c) => !c.delete).length > 0 || showPDFAttachment)) ? 
                "thepen" : "standard" }
        click={
            activeClause !== null ? e => setActiveClause(null) : 
            (!props.templating && !['Request'].includes(props.agrStatus) && clauses !== undefined && clauses !== null &&
            (clauses.filter((c) => !c.delete).length > 0 || showPDFAttachment)) ? 
                props.parentCall : null}
        iconType={
            activeClause !== null ? 
                "times" : 
            !props.laterVersionOwnedByCpty && (!props.templating && !['Request'].includes(props.agrStatus) && clauses !== null &&
            (clauses.filter((c) => !c.delete).length > 0 || showPDFAttachment)) ? 
                "pen" : '' }
        whiteLabel={props.whiteLabel}
        whiteLabelLoading={props.whiteLabelLoading}
        />
    
    :
    props.laterVersionOwnedByCpty && (['Review', 'Negotiation'].includes(props.agrStatus) || 
    ['Review', 'Negotiation'].includes(props.parentAgrStatus)) ? 
    <Box align="center" className={classes.cptyLock}>
      <FontAwesomeIcon icon={faLock} />
      <Box mt={1} align="center">
      <Typography variant="body2" style={{color: theme.palette.grey[700]}}>Latest version owned by {trunc(cptyLegalName,19)}</Typography>
      </Box>
      <Button color="primary" variant="text" size="small" onClick={handleCommsToggle}>Send message</Button>
      {props.user.role !== 'Counterparty' && props.agrStatus !== undefined/* && props.agrStatus !== 'Exhibit'*/ ?
      <Button color="primary" variant="text" size="small" onClick={e => props.parentCall('retrieve', true)}>Retrieve</Button> : '' }
    </Box>
    : '' }


    {// Show the PDF for any offline signed / uploaded agreements
    showPDFAgrExec || showPDFAttachment ?
    <div style={showPDFAttachment ? {minHeight: '101vh'} : {marginTop: '-75px', marginBottom: '-75px', minHeight: '101vh'}}>

      {loading ?
      <Box mt={10} mb={10} align="center"><CircularProgress size={24} /></Box>
      :
      showPDFAttachment ?
      <Box align="center">        
        <Typography variant="h3" align="center" className={classes.agrTitle}>{props.agrFullName}</Typography>
        <Box mt={2}>{props.agrHead()}</Box>
        
        {props.clauseActivityLog.filter((cal) => ['pdfChanged'].includes(cal.logType)).length > 0 ?
        <Box mb={2}>
          <Typography variant="subtitle2">
          <FontAwesomeIcon color="textSecondary" icon={faExclamationTriangle} className={classes.secIcon} /> This PDF attachment has been updated
          </Typography>
        </Box>:''}

        <Box mt={0} mb={1} align="center">
          {!props.laterVersionOwnedByCpty && ['Draft', 'Review', 'Negotiation'].includes(props.parentAgrStatus) ?
            <>
            <input type="file" id="icon-button-file" hidden
            accept=".pdf"
            onChange={handleUploadChange} />
            <label htmlFor="icon-button-file">
            <Button component="span"
            className={classes.upDownButton}
            color="primary" variant="text" size={props.avAttachment === 'placeholder' ? "large" : "small"}><FontAwesomeIcon icon={faUpload} />&nbsp;&nbsp;New upload</Button>
            </label>
            </>
          :''}
          {props.avAttachment !== 'placeholder' ?
            <Button onClick={props.handleDownloadPDF}
            className={classes.upDownButton}
            color="primary" variant="text" size="small">Download&nbsp;&nbsp;<FontAwesomeIcon icon={faDownload} /></Button>
          :''}
        </Box>
        {newFileAttachment !== null ?
        <Box mt={2} mb={2}>
          <Button color="secondary" variant="text" onClick={e => setNewFileAttachment(null)}
          className={classes.upDownButton}>Clear upload</Button>
          <Button color="secondary" variant="contained" disableElevation onClick={e => handleNewFileAttachmentSubmit()}
          className={classes.upDownButton}>Submit new upload</Button>
        </Box>        
        :''}
      </Box>
      :''}

      {props.avAttachment === 'placeholder' ?
        <Box align="center" mt={10} mb={10}>
          <Typography variant="body1">This is a placeholder for a PDF Attachment</Typography>
        </Box>
      :props.viewablePDF === null ?
        <Box align="center" mt={10} mb={10}><CircularProgress size={24} /></Box>
      :
        <Document
        file={props.viewablePDF}
        onLoadSuccess={onDocumentLoadSuccess}>
          <Box display={{ xs: 'block', sm: 'none' }}><Page pageNumber={pageNumberPDF} width={320} /></Box>
          <Box display={{ xs: 'none', sm: 'block', md: 'none' }}><Page pageNumber={pageNumberPDF} width={530} /></Box>
          <Box display={{ xs: 'none', md: 'block', lg: 'none' }}><Page pageNumber={pageNumberPDF} width={658} /></Box>
          <Box display={{ xs: 'none', lg: 'block' }}><Page pageNumber={pageNumberPDF} width={900} /></Box>
          <div className="page-controls">
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item>
                <IconButton className={classes.chevIconB} disabled={pageNumberPDF === 1} onClick={e => setPageNumberPDF(pageNumberPDF - 1)}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </IconButton>
              </Grid>
              <Grid item><span className={classes.boldSmall}>{pageNumberPDF} of {numPagesPDF}</span></Grid>
              <Grid item>
                <IconButton className={classes.chevIconB} disabled={pageNumberPDF === numPagesPDF} onClick={e => setPageNumberPDF(pageNumberPDF + 1)}>
                  <FontAwesomeIcon icon={faChevronRight} />
                </IconButton>
              </Grid>
            </Grid>
          </div>
        </Document>
      }
      
    </div>
    : // Standard Agreement Editor Screen
    <div className={classes.content}>
    <Grid container direction="column" justify="flex-start" alignItems="center">

        {props.agr !== undefined &&
        changingEffectiveProperties ?

        <Box mt={0} mb={4} pl={3} pr={3} pt={3} pb={2} align="center" className={classes.propertyUpdateBox}>
          <Typography variant="h5" className={classes.bold}>
            Update Effective Properties
          </Typography>
          <Box mt={2}>

            <Grid container direction="row">
              <Grid item xs={2}></Grid>
              <Grid item xs={8}><Typography variant="subtitle2" className={classes.bold}>Effective Date:</Typography></Grid>
              <Grid item xs={2}>
                {effectivePropertyStaging !== undefined && effectivePropertyStaging !== null &&
                effectivePropertyStaging.effectiveDate !== undefined && effectivePropertyStaging.effectiveDate !== null ?
                <Button variant="text" size="small" color="primary" style={{padding:'0px 5px 0px 5px'}}
                onClick={e => setEffectivePropertyStaging({...effectivePropertyStaging, effectiveDate: null})}>Clear</Button>
                :''}
              </Grid>
            </Grid>
            
            {['Draft','Review','Negotiation','Execution'].includes(props.agrStatus) && isNaN(Date.parse(effectivePropertyStaging.effectiveDate)) ? 
            <Typography variant="body2" color="textSecondary">Leave empty for automatic update upon last signature</Typography>:''}

            <Box mt={1} mb={2}>
              <SmartFieldEditable                        
                fromClause={false}
                type={'date-stamp'}
                val1={!isNaN(Date.parse(effectivePropertyStaging.effectiveDate)) ? effectivePropertyStaging.effectiveDate : ''}
                val2={''}
                onFieldChange={handleEffectivePropertyChange}
                sfid={"changeEffDate"} />
            </Box>

            <Grid container direction="row">
              <Grid item xs={2}></Grid>
              <Grid item xs={8}><Typography variant="subtitle2" className={classes.bold}>Term of Agreement:</Typography></Grid>
              <Grid item xs={2}>
                {effectivePropertyStaging !== undefined && effectivePropertyStaging !== null &&
                effectivePropertyStaging.effectiveTerm !== undefined && (
                effectivePropertyStaging.effectiveTerm.initial !== undefined ||
                effectivePropertyStaging.effectiveTerm.renewal !== undefined 
                )?
                <Button variant="text" size="small" color="primary" style={{padding:'0px 5px 0px 5px'}}
                onClick={e => setEffectivePropertyStaging({...effectivePropertyStaging, effectiveTerm: { isCustom: true }})}>Clear</Button>
                :''}
              </Grid>
            </Grid>
            <Typography variant="subtitle2" className={classes.bold}></Typography>
            <Typography variant="body2" color="textSecondary">Initial and Renewal Term</Typography>

            <Box mt={1} mb={2}>

              <SmartFieldEditable                        
              fromClause={false}
              type={'date-period'}
              val1={effectivePropertyStaging.effectiveTerm !== undefined &&
                effectivePropertyStaging.effectiveTerm.initial !== undefined && effectivePropertyStaging.effectiveTerm.initial !== null && 
                effectivePropertyStaging.effectiveTerm.initial.indexOf("_") > -1 ?
                    effectivePropertyStaging.effectiveTerm.initial.substring(0, effectivePropertyStaging.effectiveTerm.initial.indexOf("_")) : 
                    ''}
              val2={effectivePropertyStaging.effectiveTerm !== undefined &&
                effectivePropertyStaging.effectiveTerm.initial !== undefined && effectivePropertyStaging.effectiveTerm.initial !== null && 
                effectivePropertyStaging.effectiveTerm.initial.indexOf("_") > -1 ?
                    effectivePropertyStaging.effectiveTerm.initial.substring(effectivePropertyStaging.effectiveTerm.initial.indexOf("_") + 1) : 
                    ''}
              onFieldChange={handleEffectivePropertyChange}
              sfid={"initial"} />

              {// The Second SmartField Editable, a period input + select for a renewal period
              <Box mt={1} mb={2}>
              <SmartFieldEditable
              fromClause={false}
              type={'date-period'}
              val1={ 
                effectivePropertyStaging !== undefined && effectivePropertyStaging.effectiveTerm !== undefined &&
                effectivePropertyStaging.effectiveTerm.renewal !== undefined && effectivePropertyStaging.effectiveTerm.renewal !== null && 
                effectivePropertyStaging.effectiveTerm.renewal.indexOf("_") > -1 ?
                    effectivePropertyStaging.effectiveTerm.renewal.substring(0, effectivePropertyStaging.effectiveTerm.renewal.indexOf("_")) : 
                    ''}
              val2={
                effectivePropertyStaging !== undefined && effectivePropertyStaging.effectiveTerm !== undefined &&
                effectivePropertyStaging.effectiveTerm.renewal !== undefined && effectivePropertyStaging.effectiveTerm.renewal !== null && 
                effectivePropertyStaging.effectiveTerm.renewal.indexOf("_") > -1 ?
                    effectivePropertyStaging.effectiveTerm.renewal.substring(effectivePropertyStaging.effectiveTerm.renewal.indexOf("_") + 1) : 
                    ''}
              onFieldChange={handleEffectivePropertyChange}
              sfid={"renewal"} />
              </Box>
              /*:''*/}
            </Box>

            <Grid container direction="row">
              <Grid item xs={2}></Grid>
              <Grid item xs={8}><Typography variant="subtitle2" className={classes.bold}>Expiry Date:</Typography></Grid>
              <Grid item xs={2}>
                {
                <Button variant="text" size="small" color="primary" style={{padding:'0px 5px 0px 5px'}}
                onClick={e => setEffectivePropertyStaging({...effectivePropertyStaging, expiryDate: null})}>Clear</Button>
                }
              </Grid>
            </Grid>            

            {props.agrStatus !== undefined &&
            ['Draft','Review','Negotiation','Execution'].includes(props.agrStatus) && isNaN(Date.parse(effectivePropertyStaging.effectiveDate)) &&
             effectivePropertyStaging.effectiveTerm !== undefined &&
             effectivePropertyStaging.effectiveTerm.initial !== undefined && effectivePropertyStaging.effectiveTerm.initial !== null && 
             effectivePropertyStaging.effectiveTerm.initial.indexOf("_") > 0 &&
             (effectivePropertyStaging.effectiveTerm.initial.indexOf("_") + 1) < effectivePropertyStaging.effectiveTerm.initial.length ? 
            <Typography variant="body2" color="textSecondary">Leave empty for automatic calculation upon last signature</Typography>:''}

            <Box mt={1} mb={2}>
              <SmartFieldEditable                        
                fromClause={false}
                type={'date-stamp'}
                val1={!isNaN(Date.parse(effectivePropertyStaging.expiryDate)) ? effectivePropertyStaging.expiryDate : ''}
                val2={''}
                onFieldChange={handleEffectivePropertyChange}
                sfid={"changeExpDate"} />
            </Box>

            <Typography variant="subtitle2" className={classes.bold}>Renewal Option:</Typography>

            <Box mt={1} mb={2}>

            <RadioGroup 
              name={'renewalType'}
              value={effectivePropertyStaging.renewalType} 
              onChange={e => setEffectivePropertyStaging({...effectivePropertyStaging, renewalType: e.target.value} )}>
                {// Display the Radio options
                renewaltypes.map((edt) => (
                  <FormControlLabel key={edt.val} value={edt.val} control={<Radio />} label={edt.long} />
                ))}
              </RadioGroup>
            </Box>
            
          </Box>
          <Box mt={1}>
            <Button color="primary" variant="contained" disableElevation fullWidth 
            onClick={e => handleSubmitEffectivePropertyChange()}>Submit</Button>
          </Box>
          <Box mt={1} align="right">
            <Button color="primary" variant="text" onClick={e => setChangingEffectiveProperties(false)}>Cancel</Button>
          </Box>

        </Box>
        
        :''}

        <Element name="topOfPage">

            <Box mb={2} align="center">
              {!props.laterVersionOwnedByCpty && ((props.templating && !props.plainMode) || ['Draft', 'Review', 'Negotiation'].includes(props.agrStatus)) &&
              agrTitleValue !== undefined && agrTitleValue !== null && agrTitleValue.currentFull !== undefined && agrTitleValue.currentShort !== undefined ?
              <>
              <InputBase 
                multiline
                //defaultValue={agrTitleValue.currentFull}
                value={agrTitleValue.currentFull}
                className={classes.agrTitle}
                inputProps={{style: { textAlign: 'center' }}}
                onClick={e => setActiveAgrTitleUpdate(true)}
                //onBlur={e => setAgrTitleValue({...agrTitleValue, currentFull: e.target.value})}
                onChange={e => setAgrTitleValue({...agrTitleValue, currentFull: e.target.value})}
              />
              {activeAgrTitleUpdate ?
              <>
              <InputBase 
                multiline
                //defaultValue={agrTitleValue.currentShort}
                value={agrTitleValue.currentShort}
                className={classes.agrTitle}
                inputProps={{style: { textAlign: 'center' }}}
                //onBlur={e => setAgrTitleValue({...agrTitleValue, currentShort: e.target.value})}
                onChange={e => setAgrTitleValue({...agrTitleValue, currentShort: e.target.value})}
              />
              <Box mt={1} align="center">
                <IconButton onClick={handleCancelTitleUpdate}>
                  <FontAwesomeIcon icon={faTimes} className={classes.iconBCancel} />
                </IconButton>
                <IconButton onClick={handleAgrTitleUpdate}>
                  <FontAwesomeIcon icon={faCheck} className={classes.iconBAccept} />
                </IconButton>
              </Box>
              </>
              : ''}

              </>
              :
              <Typography variant="h3" align="center" className={classes.agrTitle} >
                {props.agrFullName}
              </Typography>
              }
            </Box>
            <Box align="center">
              {props.agrHead()}
            </Box>

        </Element>

        {props.clauseActivityLog.filter((cal) => ['clDeleted'].includes(cal.logType)).length > 0 ? 
          <Box mt={2} mb={2}>
            <Typography variant="subtitle2">
              <FontAwesomeIcon color="textSecondary" icon={faExclamationTriangle} className={classes.secIcon} /> 
              {props.clauseActivityLog.filter((cal) => cal.logType === 'clDeleted').length === 1 ? "A clause was" : (props.clauseActivityLog.filter((cal) => cal.logType === 'clDeleted').length + " clauses were")} <span onClick={e => props.handleToggleFilter(null, "changes")} className="seclink">deleted by {cptyName}</span>
            </Typography>
          </Box>
        :''}

        {props.toggleFilter === "changes" && props.clauseActivityLog.filter((cal) => cal.logType === 'clDeleted').length > 0 ?
        <Box mb={2} align="center">
          {props.clauseActivityLog.filter((cal) => cal.logType === 'clDeleted').map((cal) => (
            <Box key={cal._id} align="center">
            <Button color="secondary" onClick={e => handleChOpen(cal.singleClauseID)}>
              {cal.logDesc + " on " + dayjs(cal.creationDate).format('MMM D, YYYY h:mm A')}
            </Button>
            {cal.change !== undefined && cal.change.old !== undefined ?
            <Box mb={3} className={classes.clPlain} align="left">
              <Typography color="textSecondary" variant="body2">{cal.change.old}</Typography>
            </Box>
            :''}
            </Box>
          ))}
        </Box>
        : '' }

        {migrationNeeded ? // TEMPORARY CODE---
        <Box mt={6} mb={10} align="center">
          <Box mb={2}><Typography>We’ve updated our platform to a new version.<br/><br/>Please migrate this agreement to get the latest experience.<br/>Contact us at <a href="https://getcanveo.com/company.html#contact" target="_blank" rel="noopener noreferrer">support@getcanveo.com</a> if you have any questions.</Typography></Box>
          <Box mb={4}><Typography>After you've clicked the "migrate button", please wait a couple of seconds to be redirected.</Typography></Box>
          <Button color="primary" disableElevation variant="contained" onClick={migrateAgreement}>Migrate Agreement to new Editor</Button>
        </Box>
        : // END OF TEMPORARY CODE---
        // Show the actual sections and clauses
        clauses
        .filter((c) => c.clauseStatus !== 'Deleted')
        .sort((a,b) => props.avClauses.indexOf(a._id) - props.avClauses.indexOf(b._id))
        .map((cl, i) => (
            <Element name={cl._id} key={cl._id}>
            {
            (props.plainMode && (['Execution', 'InForce'].includes(props.parentAgrStatus) || 
            ['Execution', 'InForce'].includes(props.agrStatus)) && cl.clauseStatus === 'proposedDel') ?
              ''
            :
            ['sec','ssec','cl','cl1','cl2','cl3','cl4','preamble','table'].includes(cl.clauseCat) ?
              <NegoCard
                cl={cl}
                active={activeClause === cl._id}
                no={toc !== null && toc.filter((t) => t.id === cl._id)[0] !== undefined ? toc.filter((t) => t.id === cl._id)[0].no : null}
                toggleFilter={props.toggleFilter}
                appRequestTicket={props.tickets !== undefined && props.tickets !== null && props.user !== undefined && props.user !== null &&
                  props.tickets.some((t) => t.singleClauseID === cl.singleClauseID && t.creationBy === props.user._id)}
                appAssigneeTicket={props.tickets !== undefined && props.tickets !== null && props.user !== undefined && props.user !== null &&
                  props.tickets.some((t) => t.singleClauseID === cl.singleClauseID && t.assignee === props.user._id)}
                org={props.org}
                user={props.user}
                users={props.users}
                avv={props.avv}
                avID={props.avID}
                editMode={props.editMode} // None - should not be able to comment/approve etc.
                module={props.module}
                agrID={props.agrID}
                cptyName={cptyName}
                oatID={props.activeOat !== undefined ? props.activeOat._id : ''}
                laterVersionOwnedByCpty={props.laterVersionOwnedByCpty}
                plainMode={props.plainMode} // For Execution / InForce
                curCpty={props.curCpty}
                templating={props.templating}
                clauseActivityLog={props.clauseActivityLog.filter((cal) => cal.singleClauseID === cl.singleClauseID)}
                callbackOptions={callbackOptions}
                handleNegoCommentsOpen={handleNegoCommentsOpen}
                handleNegoApprovalsOpen={handleNegoApprovalsOpen}
                handleChOpen={handleChOpen}
                handleClauseEditMode={handleClauseEditMode}
                updateClauseStatus={props.updateClauseStatus}
                updatePendingChange={props.updatePendingChange}
                updateClauseNumbering={props.updateClauseNumbering}
                updateAgrvRemoveClause={props.updateAgrvRemoveClause}
                updateSmartField={props.updateSmartField}
                optionClicked={optionClicked !== undefined && optionClicked !== null && optionClicked.clid !== undefined && optionClicked.clid === cl._id ? optionClicked : { option: null }}
                smartMode={props.smartMode}
                smartFields={props.smartFields !== undefined && props.smartFields !== null ? 
                  props.smartFields.filter((sf) => sf.singleClauseID === cl.singleClauseID) : []}
                updateClauseContentFAF={props.updateClauseContentFAF}
                handleActivityLog={props.handleActivityLog}
              />
            : '' }
            </Element>
        ))}

        {props.toggleFilter !== null ?
        <Box mt={3} mb={props.agrsAvs !== undefined && props.agrsAvs !== null && props.agrsAvs.length > 1 ? 0 : 3}>
          <Typography variant="subtitle2" align="center" color="textSecondary"><FontAwesomeIcon icon={faEllipsisH} />&nbsp;&nbsp;End of the filtered clauses - <span onClick={e => props.handleToggleFilter(null, null)} className="seclink">remove filter</span>&nbsp;&nbsp;<FontAwesomeIcon icon={faEllipsisH} /></Typography>
        </Box>
        :''}

        {props.activeAgrs !== undefined && props.activeAgrs !== null && props.activeAgrs.length > 1 ?
        <Box mt={4} mb={0} className={classes.bottomLinks}>
          <Typography variant="subtitle2" color="textSecondary" 
          align={(props.activeAgrs.findIndex((aa) => aa._id === props.agrID) + 1 === props.activeAgrs.length) ? "left" : "right"}>
            {(props.activeAgrs.findIndex((aa) => aa._id === props.agrID) + 1 === props.activeAgrs.length) ?
            <span onClick={e => history.push("/core/" + props.module + "/agreement/" + props.rid + "/" + props.agr.parentAID  + (
              props.toggleFilter !== null ? ("/" + props.toggleFilter) : ""))} className="greylink">
            <FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Main Body</span>
            :
            <span 
            //onClick={e => props.handleToggleFilter(null, null)} 
            onClick={e => history.push("/core/" + props.module + "/agreement/" + props.rid + "/" + props.activeAgrs[props.activeAgrs.findIndex((aa) => aa._id === props.agrID) + 1]._id  + (
              props.toggleFilter !== null ? ("/" + props.toggleFilter) : ""))}
            className="greylink">
              Exhibit {String.fromCharCode(97 + props.activeAgrs.findIndex((aa) => aa._id === props.agrID)).toUpperCase() + ": " + props.activeAgrs[props.activeAgrs.findIndex((aa) => aa._id === props.agrID) + 1].agrFullName}
              &nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} />
            </span>
            }
          </Typography>
        </Box>
        :''}


    </Grid>
    </div>
    }

    {snackReason !== null || (penTurnComment !== null && penTurnComment.expanded) &&
    props.tickets !== undefined && props.tickets !== null && props.user !== undefined && props.user !== null ?

    <Backdrop
    style={{zIndex: '2000'}}
    open={snackReason !== null || (penTurnComment !== null && penTurnComment.expanded)}
    onClick={handleSnackClose}
    >
      <Box className={classes.snackBox3} style={(penTurnComment !== null && penTurnComment.expanded) ? { width: '300px'} : {}} mb={0} mt={0}>
        
        <IconButton size="small" className={classes.snackClose} onClick={handleSnackClose}>
          <FontAwesomeIcon icon={faTimes} className={classes.snackIconClose} />
        </IconButton>

        {['howToEdit', 'howToEditAndSignReady'].includes(snackReason) ?

            <Box mt={3} mb={2} ml={2} mr={2} style={{fontWeight: 400, fontSize: '15px'}}>
              {props.user.role === 'Counterparty' ?
              <Box mb={2}>
                <Typography variant="body1" className={classes.bold}>
                  {props.whiteLabelLoading ? '' :
                   props.org !== undefined && props.org !== null && props.org.orgSettings !== undefined && ['full'].includes(props.org.orgSettings.whiteLabelLevel) &&
                   props.whiteLabel !== undefined && props.whiteLabel !== null && props.whiteLabel.logoURL !== undefined ? 'Welcome,' :
                   'Welcome to Canveo'}</Typography>
              </Box>
              :''}
              <Box display="inline">
                {['comment'].includes(props.editMode) ? 
                  <><FontAwesomeIcon icon={faComment} className={classes.lightBulbIcon} />To add comments,<br/>click into a clause and press</> :
                  <><FontAwesomeIcon icon={faPen} className={classes.lightBulbIcon} />To make edits,<br/>click into a clause</>}
              </Box>

              {['edit', 'full'].includes(props.editMode) ? 
              <Box><span className="typewriter"> and start typing. <span>&nbsp;</span></span></Box>
              : 
              <Box mt={2} align="center"><img src={comments_chip} alt="Add comments" width="96" /></Box>
              }

              {!props.templating ?
              <>
              <Box display="block" mt={2}>
                <FontAwesomeIcon icon={faEye} className={classes.lightBulbIcon} />
                {['comment'].includes(props.editMode) ? "Comments " : "Changes "}are only visible to {cptyName} after you {props.agrStatus === 'Draft' || props.parentAgrStatus === 'Draft' ? "sent" : "return"} the agreement.
              </Box>
              {props.user.role === 'Counterparty' ?
                <Box mt={2}><FontAwesomeIcon icon={faUserPlus} className={classes.lightBulbIcon} />Add collaborators{['howToEditAndSignReady'].includes(snackReason) ? 
                    <>, return the agreement or <span className="seclink" onClick={handleInitiateSigning}>initiate signing</span> </> : 
                    " or return the agreement "
                }using the "Continue" button.</Box>
                :
                ''
              }
              </>
              : '' }
            </Box>

        :props.avsPenTurnDetail !== undefined &&
        ['changesByCpty','changesByCptyAndSignReady'].includes(snackReason) ?
        <Box mt={3} mb={1} ml={0} mr={0} align="left">

          <List>

            <ListItem alignItems="flex-start" button
            onClick={e => props.handleToggleFilter(null, "changes")}>
              <ListItemAvatar>
                <Avatar src={isPrimary ?
                  props.secondaryCpty.logoURL :                  
                  props.primaryCpty.logoURL} style={{width: '30px', height: '30px'}} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <span style={{fontSize: '14px'}}>
                  {trunc(cptyName, 20)} made <span style={{fontWeight: 500}}>{props.avsPenTurnDetail.reduce((a, b) => a + (b.changesOnPT || 0), 0)} change{props.avsPenTurnDetail.reduce((a, b) => a + (b.changesOnPT || 0), 0) === 1 ? "" : "s"}</span>
                  </span>
                }
              />
            </ListItem>

            <ListItem alignItems="flex-start" button
            onClick={e => props.handleToggleFilter(null, "clause")}>
              <ListItemAvatar>
                <FontAwesomeIcon icon={faExclamationCircle} className={classes.lightBulbIconSm} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  props.avsPenTurnDetail.some((aptd) => aptd.isEdited) || props.edited ?
                  <span style={{fontSize: '14px'}}>
                  There {props.avsPenTurnDetail.reduce((a, b) => a + (b.openOnPT || 0), 0) === 1 ? "was" : "were"} <span style={{fontWeight: 500}}>{props.avsPenTurnDetail.reduce((a, b) => a + (b.openOnPT || 0), 0)} open issue{props.avsPenTurnDetail.reduce((a, b) => a + (b.openOnPT || 0), 0) === 1 ? "":"s"}</span> on pen-turn
                  </span>
                  :
                  <span style={{fontSize: '14px'}}>
                  There {props.avsPenTurnDetail.reduce((a, b) => a + (b.openOnPT || 0), 0) === 1 ? "is" : "are"} <span style={{fontWeight: 500}}>{props.avsPenTurnDetail.reduce((a, b) => a + (b.openOnPT || 0), 0)} open issue{props.avsPenTurnDetail.reduce((a, b) => a + (b.openOnPT || 0), 0) === 1 ? "":"s"}</span>
                  </span>
                }
              />
            </ListItem>

            {props.tickets.filter((t) => ['Approval'].includes(t.ticketType) && t.assignee === props.user._id && ['Assigned','In Progress'].includes(t.ticketStatus)).length > 0 ||
            props.tickets.filter((t) => ['Approval'].includes(t.ticketType) && t.creationBy === props.user._id && ['Assigned','In Progress'].includes(t.ticketStatus)).length > 0 ?
            <ListItem alignItems="flex-start" button
            onClick={e => handleNegoApprovalsOpen('agreement')}>
              <ListItemAvatar>
                <FontAwesomeIcon icon={faThumbsUp} className={classes.lightBulbIconSm}  />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <span style={{fontSize: '14px'}}>
                  {props.tickets.filter((t) => ['Approval'].includes(t.ticketType) && t.assignee === props.user._id && ['Assigned','In Progress'].includes(t.ticketStatus)).length > 0 ?
                      <>{props.tickets.filter((t) => ['Approval'].includes(t.ticketType) && t.assignee === props.user._id && ['Assigned','In Progress'].includes(t.ticketStatus)).length} request{props.tickets.filter((t) => ['Approval'].includes(t.ticketType) && t.assignee === props.user._id && ['Assigned','In Progress'].includes(t.ticketStatus)).length === 1 ? " is":"s are"} <span style={{fontWeight: 500}}>pending your approval</span></> :
                      <>{props.tickets.filter((t) => ['Approval'].includes(t.ticketType) && t.creationBy === props.user._id && ['Assigned','In Progress'].includes(t.ticketStatus)).length} request{props.tickets.filter((t) => ['Approval'].includes(t.ticketType) && t.creationBy === props.user._id && ['Assigned','In Progress'].includes(t.ticketStatus)).length === 1 ? " is":"s are"} of yours are <span style={{fontWeight: 500}}>pending approval</span></>}
                  </span>
                }
              />
            </ListItem>
            :''}

            {props.tickets.filter((t) => ['AgrReviewInternal'].includes(t.ticketType) && t.assignee === props.user._id && ['Assigned','In Progress'].includes(t.ticketStatus)).length > 0 ||
            props.tickets.filter((t) => ['AgrReviewInternal'].includes(t.ticketType) && t.creationBy === props.user._id && ['Assigned','In Progress'].includes(t.ticketStatus)).length > 0 ?
            <ListItem alignItems="flex-start" button
            onClick={e => props.parentCall('reviewtickets')}>
              <ListItemAvatar>
                <FontAwesomeIcon icon={faSearch} className={classes.lightBulbIconSm}  />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <span style={{fontSize: '14px'}}>
                  {props.tickets.filter((t) => ['AgrReviewInternal'].includes(t.ticketType) && t.assignee === props.user._id && ['Assigned','In Progress'].includes(t.ticketStatus)).length > 0 ?
                      <>{props.tickets.filter((t) => ['AgrReviewInternal'].includes(t.ticketType) && t.assignee === props.user._id && ['Assigned','In Progress'].includes(t.ticketStatus)).length} request{props.tickets.filter((t) => ['AgrReviewInternal'].includes(t.ticketType) && t.assignee === props.user._id && ['Assigned','In Progress'].includes(t.ticketStatus)).length === 1 ? " is":"s are"} <span style={{fontWeight: 500}}>pending your review</span></> :
                      <>{props.tickets.filter((t) => ['AgrReviewInternal'].includes(t.ticketType) && t.creationBy === props.user._id && ['Assigned','In Progress'].includes(t.ticketStatus)).length} request{props.tickets.filter((t) => ['AgrReviewInternal'].includes(t.ticketType) && t.creationBy === props.user._id && ['Assigned','In Progress'].includes(t.ticketStatus)).length === 1 ? " of yours is":"s of yours are"} <span style={{fontWeight: 500}}>pending review</span></>}
                  </span>
                }
              />
            </ListItem>
            :''}

            {props.signable ?
            <ListItem alignItems="flex-start" button
            onClick={handleInitiateSigning}>
              <ListItemAvatar>
                <FontAwesomeIcon icon={faSignature} className={classes.lightBulbIconSm} style={{marginLeft: '6px'}}  />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <span style={{fontSize: '14px'}}>
                  {trunc(cptyName, 20)} is ready to sign: <span style={{fontWeight: 500, color: theme.palette.secondary.main}}>initiate signing</span>
                  </span>
                }
              />
            </ListItem>
            :''}

            {props.avsPenTurnDetail !== undefined &&
            (['changesByCpty','changesByCptyAndSignReady'].includes(snackReason) || 
            (penTurnComment !== null && penTurnComment.expanded)) && penTurnComment !== null &&
            penTurnComment.comment !== undefined && penTurnComment.comment !== null ?
            <ListItem alignItems="flex-start" button
            onClick={e => setPenTurnComment({...penTurnComment, expanded: true})}>
              <ListItemAvatar>
                <FontAwesomeIcon icon={faComment} className={classes.lightBulbIconSm}  />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <span style={{fontSize: '14px', fontStyle: 'italic'}}>
                  {trunc(penTurnComment.comment, 36)}
                  </span>
                }
              />
            </ListItem>
            :''}

          </List>

        </Box>

        :penTurnComment !== null && penTurnComment.comment !== undefined && penTurnComment.comment !== null && penTurnComment.expanded ?

          <Box mt={4} mb={3} ml={2} mr={2} style={{fontWeight: '300', fontStyle:'italic'}}>
            {penTurnComment.comment
            .split('\n').map((cpar,i) => ( <React.Fragment key={i}>{cpar}<br/></React.Fragment> ))}
          </Box>

        :''}

        {// The sign ready block can be shown individually (for signReady) or in addition to (for changesByCptyAndSignReady)
        /*['signReady', 'changesByCptyAndSignReady'].includes(snackReason) ?

        <Box mt={snackReason !== undefined && ['changesByCptyAndSignReady'].includes(snackReason) ? 2 : 4} 
        mb={2} ml={1} mr={1} align={"left"} 
        style={{fontWeight: 400, fontSize: '15px'}}>

          {(!['howToEditAndSignReady'].includes(snackReason) && props.agrStatus === 'Negotiation') ? 
          <>
          {trunc(cptyName, 10)} is ready to sign this version.&nbsp;
          <span className="seclink" style={{fontWeight: '700'}} onClick={handleInitiateSigning}>Initiate signing</span>.
          </>          
          :''}

          {/*cptyName} is ready to sign this version of the agreement.
          {(!['howToEditAndSignReady'].includes(snackReason) && props.agrStatus === 'Negotiation') ? 
          <Box mt={2} align="center">
            <Button size="small" color="secondary" variant={"contained"} disableElevation style={{padding: '2px 14px 2px 14px'}}
            onClick={handleInitiateSigning}>
              Initiate signing&nbsp;&nbsp;
              <FontAwesomeIcon icon={faSignature} />
            </Button>
          </Box>
        :''}
        </Box>
        :''
        </Box>
        </Backdrop>*/}


        {/*props.avsPenTurnDetail !== undefined &&
        (['changesByCpty','changesByCptyAndSignReady'].includes(snackReason) || (penTurnComment !== null && penTurnComment.expanded)) && penTurnComment !== null &&
        penTurnComment.comment !== undefined && penTurnComment.comment !== null ?
        <Box mt={2} mb={2} pt={2} pl={1} pr={1} align="left" className={classes.penTurnComment} style={(penTurnComment !== null && penTurnComment.expanded) ? { borderTop: '0px'} : {}}>

            {penTurnComment.comment
            .substring(0, penTurnComment.comment.length > 180 && !penTurnComment.expanded ? 180 : penTurnComment.comment.length)
            .split('\n').map((cpar,i) => ( <React.Fragment key={i}>{cpar}<br/></React.Fragment> ))}
            {penTurnComment.comment.length > 180 && !penTurnComment.expanded ? 
            <Box align="right"><Button color="secondary" variant="text" onClick={e => setPenTurnComment({...penTurnComment, expanded: true})}>Full message&nbsp;&nbsp;<FontAwesomeIcon icon={faComment} /></Button></Box> :
            ''}

        </Box>
        :''*/}

      </Box>
    </Backdrop>
    :''     

    }
    <DialogClauseHistory
        open={Boolean(chOpen)}
        user={props.user}
        primaryCpty={props.primaryCpty}
        secondaryCpty={props.secondaryCpty}
        singleClauseID={chOpen}
        parentCallToClose={handleChClose}
    />

    <DialogClauseEditMode
        open={Boolean(clauseEditModeOpen)}
        type={clauseEditModeOpen}
        avv={props.avv}
        user={props.user}
        curCpty={props.curCpty}
        avClauses={props.avClauses}
        history={history}
        path={props.templating ? 
          ('/core/legal/templates/' + props.module + '/edit/' + props.activeOat._id + (clauseEditModeOpen !== null && clauseEditModeOpen.clid !== undefined ? ('/' + clauseEditModeOpen.clid) : '')) :
          ('/core/' + props.module + "/agreement/" + props.rid + "/" + props.agrID + (clauseEditModeOpen !== null && clauseEditModeOpen.clid !== undefined ? ('/' + clauseEditModeOpen.clid) : ''))}
        oatoravid={props.templating ? props.activeOat._id : props.avID}
        templating={props.templating}
        updateAgrvNewClauses={props.updateAgrvNewClauses}
        parentCallToClose={handleClauseEditMode}
        handleActivityLog={props.handleActivityLog}
        agrsAvs={props.agrsAvs}
    />

    <DialogAgrActivity
      open={agrActivityOpen}
      parentCallToClose={handleAgrActivityClose}
      agrID={props.parentAID !== undefined && props.parentAID !== null && props.parentAID.length > 2 ? props.parentAID : props.agrID}
      agrFullName={props.parentAgrFullName !== undefined && props.parentAgrFullName !== null && props.parentAgrFullName.length > 2 ? props.parentAgrFullName : props.agrFullName}
      agrStatus={props.agrStatus}
      parentAgrStatus={props.parentAgrStatus}
      user={props.user}
      rel={props.rel}
      rid={props.rid}
      module={props.module}
      cptyModule={props.cptyModule}
      primaryCpty={props.primaryCpty}
      secondaryCpty={props.secondaryCpty}
      createNotification={props.createNotification}
      createComment={props.createComment}
    />

    <DialogNegoComments
      open={Boolean(negoCommentsOpen)}
      parentCallToClose={handleNegoCommentsClose}
      user={props.user}
      users={props.users}
      curCpty={props.curCpty}
      cptyLegalName={cptyLegalName}
      agrFullName={props.agrFullName}
      agrsAvs={props.agrsAvs}
      rel={props.rel}
      module={props.module}
      sub={props.primaryCpty}
      cust={props.secondaryCpty}
      agrID={props.agrID}
      oatID={props.activeOat !== undefined && props.activeOat !== null && props.activeOat._id !== undefined ? props.activeOat._id : null}
      agrStatus={props.agrStatus}
      avv={props.avv}
      createComment={props.createComment}
      updateComment={props.updateComment}
      laterVersionOwnedByCpty={props.laterVersionOwnedByCpty}
      creationDateOfLatestAV={props.creationDateOfLatestAV}
      plainMode={props.plainMode || ['none'].includes(props.editMode)}
      toggleCommentApprovalStatus={toggleCommentApprovalStatus}
      clause={clauses !== null && clauses.filter((c) => c._id === negoCommentsOpen)[0] !== undefined ? clauses.filter((c) => c._id === negoCommentsOpen)[0] : {}}
      handleActivityLog={props.handleActivityLog}
      createNotification={props.createNotification}
      updateAidForUserAgrIDs={props.updateAidForUserAgrIDs}
      updateAgrUsers={props.updateAgrUsers}
      templating={props.templating}
      isTerms={props.activeOat !== undefined && props.activeOat !== null && (props.activeOat.draftFor === 'Terms' || props.activeOat.external)}
    />

    <DialogNegoApprovals
      templating={props.templating}
      open={Boolean(negoApprovalsOpen)}
      plainMode={props.templating ? false : props.plainMode}
      parentCallToClose={handleNegoApprovalsClose}
      module={props.module}
      users={props.users}
      user={props.user}
      rel={props.rel}
      entID={props.entID}
      activeOat={props.activeOat}
      agrID={props.agrID}
      parentAID={props.parentAID}
      agrFullName={props.agrFullName}
      avID={props.avID}
      clause={negoApprovalsOpen === 'agreement' ? 'agreement' :
        clauses !== null && clauses.filter((c) => c._id === negoApprovalsOpen)[0] !== undefined ? clauses.filter((c) => c._id === negoApprovalsOpen)[0] : {}}
      cptyName={cptyLegalName}
      ticketFromApprovalScreen={null}
      updateAidForUserAgrIDs={props.updateAidForUserAgrIDs}
      toggleCommentApprovalStatus={toggleCommentApprovalStatus}
      createComment={props.createComment}
      updateComment={props.updateComment}
      updateTicket={props.updateTicket}
      lastUpdateTicket={props.lastUpdateTicket}
      createNotification={props.createNotification}
      fetchUsersByOrgID={props.fetchUsersByOrgID}
      scrollTo={scrollTo}
      clauses={clauses}
    />

    <DialogAVDiff
      open={props.openDiffDialog}
      templating={props.templating}
      aid={props.templating && props.activeOat !== undefined ? props.activeOat._id : props.agrID}
      agrsAvs={props.templating ? [] : props.agrsAvs}
      avs={props.templating ? props.oatVersions : props.avs}
      isPrimary={props.templating ? true : isPrimary}
      primaryLogo={
        props.templating && props.org !== undefined && props.org !== null ? props.org.logoURL : 
        props.primaryCpty !== undefined && props.primaryCpty !== null ? props.primaryCpty.logoURL : null}
      secondaryLogo={props.secondaryCpty !== undefined && props.secondaryCpty !== null ? props.secondaryCpty.logoURL : null}
      //primaryCpty={props.primaryCpty}
      //secondaryCpty={props.secondaryCpty}
      firstav={props.templating ? props.oatVersions[props.oatVersions.length-1] :
        props.avs.filter((agrv) => (agrv.owner === "primary" && props.rel.primaryOrgID === props.user.orgID) || (agrv.owner === "secondary" && props.rel.secondaryOrgID === props.user.orgID))[
          props.avs.filter((agrv) => (agrv.owner === "primary" && props.rel.primaryOrgID === props.user.orgID) || (agrv.owner === "secondary" && props.rel.secondaryOrgID === props.user.orgID)).length -1]}
      prevav={props.templating ? props.oatVersions[1] : 
        props.avs.filter((agrv) => (agrv.owner === "primary" && props.rel.primaryOrgID === props.user.orgID) || (agrv.owner === "secondary" && props.rel.secondaryOrgID === props.user.orgID))[1]}
      curav={
        props.templating ? 
            props.activeOat :
        props.avs !== undefined && props.avs !== null && props.avid !== undefined && props.avid !== null && props.avs.filter((av) => av._id === props.avid)[0] !== undefined ? 
            props.avs.filter((av) => av._id === props.avid)[0] : null}
      handleDiffDialogClose={props.handleDiffDialogClose}
    />

    </div>
  );
}

export default AgreementEditor