import React, { useEffect } from 'react';
import { getActorsForModule, trunc } from '../../utils';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useHistory } from 'react-router-dom';
import { Avatar, Box, Button, Container, Grid, ListItem, ListItemAvatar, ListItemText,Typography,
         makeStyles, CircularProgress } from '@material-ui/core';
import { DialogNegoApprovals, DialogReview, DrawerCore, Header, SkeletonCustom, ThumbTodo } from '../../components';

import { faCheckSquare, faSearch, faHandshake, faShoppingCart, faUsers, faUser, faBuilding } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const dayjs = require('dayjs')
const axios = require('axios');

function Tasks(props) {

  const useStyles = makeStyles(theme => ({
    root: { // mainDiv
      display: 'flex',
    },
    outerFlexGrid: {
        minHeight: '80vh',
        paddingTop: '100px',
        paddingBottom: '20px',
    },
    header: {
        zIndex: 1400
    },
    selectPillActive: {
      [theme.breakpoints.only('xs')]: { padding: '5px 10px 5px 10px', fontSize: '12px' },
      padding: '5px 20px 5px 20px',
      margin: '0px',
      fontWeight: '600',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
      }
    },
    selectPillInactive: {
      [theme.breakpoints.only('xs')]: { padding: '5px 10px 5px 10px', fontSize: '12px' },
      padding: '5px 20px 5px 20px',
      margin: '0px',
      fontWeight: '600',
      backgroundColor: '#f6f6f6',
      color: theme.palette.grey[600],
      '&:hover': {
        backgroundColor: '#e0e0e0',
        color: theme.palette.grey[600],
      }
    },
    paperResizing: {
      overflowX: 'auto',
      [theme.breakpoints.only('xs')]: {  width: '320px' },
      [theme.breakpoints.only('sm')]: { width: '520px' },
      [theme.breakpoints.up('md')]: { width: '800px' },
    },
    bold: {
      fontWeight: '700',
    },
    justGrey: {
      color: theme.palette.grey[500],
    },
    trulySecondary: {
      color: theme.palette.secondary.main,
      fontWeight: '700',
    },
    moduleButtonActive: {
      boxSizing: 'border-box',
      display:'inline-block',
      padding: '15px 25px 15px 25px',
      border: '1px solid' + theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        cursor: 'pointer',
      }
    },
    moduleButtonInactive: {
      boxSizing: 'border-box',
      display:'inline-block',
      padding: '15px 25px 15px 25px',
      border: '1px solid #e0e0e0',
      borderRight: '0px',
      backgroundColor: '#ffffff',
      color: theme.palette.grey[600],
      '&:hover': {
        backgroundColor: '#f1f1f1',
        color: theme.palette.grey[800],
        cursor: 'pointer',
      }
    },
    moduleIcon: {
      fontSize: '20px',
      padding: '5px 0px 3px 0px'
    },
    tinyFont: {
      [theme.breakpoints.only('xs')]: {  display: 'none' },
      fontSize: '12px',
    },
    statusPrimary: {
      fontWeight: '700',
      fontSize: '14px',
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      padding: '1px 5px 1px 5px',
      borderRadius: '3px',
      marginRight: '10px',
    },
    statusClosed: {
      fontWeight: '700',
      fontSize: '14px',
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.grey[700],
      padding: '1px 5px 1px 5px',
      borderRadius: '3px',
      marginRight: '10px',
    },
    listAv: {
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.primary.contrastText,
      fontWeight: '600',
      fontSize: '15px',
    },
    evenRow: {
      padding: '15px 25px 15px 25px',
      '&:hover': {
        backgroundColor: '#e0e0e0',
      }
    },
    unevenRow: {
      padding: '15px 25px 15px 25px',
      backgroundColor: '#f6f6f6',
      '&:hover': {
        backgroundColor: '#e0e0e0',
      }
    },
    listBox: {
      [theme.breakpoints.only('xs')]: { height: '300px', width: '320px' },
      [theme.breakpoints.only('sm')]: { height: '400px', width: '540px' },
      [theme.breakpoints.up('md')]: { height: '70vh', width: '640px' },
    },
    ticketTopic: {
      [theme.breakpoints.only('xs')]: { display: 'none' },
      [theme.breakpoints.only('sm')]: { fontSize: '13px' },
    }
  }));
  const classes = useStyles();

  const history = useHistory();

  const [page, setPage] = React.useState(props.type !== undefined && props.type !== null ? props.type : 'overview')
  const [tickets, setTickets] = React.useState(null)
  const [filteredRows, setFilteredRows] = React.useState(null)
  const [activeTicket, setActiveTicket] = React.useState({})
  const [negoApprovalsOpen, setNegoApprovalsOpen] = React.useState(null);
  const [viewAssignment, setViewAssignment] = React.useState('assignee')
  const [viewStatus, setViewStatus] = React.useState('pending')
  const [loading, setLoading] = React.useState(true)
  const [clause, setClause] = React.useState({});
  const [rel, setRel] = React.useState('');
  const [agrID, setAgrID] = React.useState('');
  const [entID, setEntID] = React.useState('');
  const [cptyName, setCptyName] = React.useState('');
  const [reviewOpen, setReviewOpen] = React.useState(null);

  useEffect(() => { // pull all open tickets created by or assigned to the current user
    let unmounted = false;
    if(props.user !== undefined && props.user._id !== undefined && props.module !== undefined && tickets === null) {
      axios.get('/ticket/ticketsusr/' + props.user._id)
      .then(function(res) { if (!unmounted) { setTickets(res.data.data); } })
      .catch(function(err) { console.log("err", err) })
    }
    return () => { unmounted = true };
  }, [props.type, props.user, props.module]);

  useEffect(() => { // Upon any selection hange - reassign filteredRows

    setLoading(true);
  
    if(viewStatus === 'completed') { // Completed tickets, pull on request only

      axios.get('/ticket/ticketsusrclosed/' + props.user._id + '/' + props.module + '/' + page + "/" + viewAssignment)
      .then(function(res) { 
        setFilteredRows(res.data.data);
        setLoading(false);
      }).catch(function(err) { console.log("err", err) })

    } else if(['approvals', 'reviews'].includes(page) && viewStatus === 'pending' && tickets !== null){ // Live tickets, from initial load

      let newRows = tickets
      .filter((t) => t.module === props.module)
      .filter((t) => (t.ticketType === 'Approval' && page === 'approvals') || (t.ticketType === 'AgrReviewInternal' && page === 'reviews'))
      .filter((t) => (viewAssignment === 'assignee' && t.assignee === props.user._id) || (viewAssignment === 'requestor' && t.creationBy === props.user._id))
      .filter((t) => !['Resolved', 'Cancelled', 'Closed'].includes(t.ticketStatus))
      .sort((a,b) => (a.lastUpdateDate < b.lastUpdateDate) ? 1 : ((b.lastUpdateDate < a.lastUpdateDate) ? -1 : 0))

      setFilteredRows(newRows)
      setLoading(false);

    } else {
      setLoading(false);
    }

  },[tickets, page, viewAssignment, viewStatus])

  // Slave function - updating through Agreement.js and copy over
  const handleActivityLog = (logLevel, logType, singleClauseID, ticketID, keyVal1, keyVal2, change) => {
    props.createActivityLog(
      logLevel, // logLevel, 
      logType, // logType, 
      ( logType === 'agrInternalReviewRequested' ? 'Agreement sent to ' + keyVal2 + ' internal reviewer' + (keyVal2 === 1 ? '' : 's') + ' by ' + props.user.displayName :
        logType === 'agrInternalReviewStarted' ? 'Agreement internal review started by ' + props.user.displayName :
        logType === 'agrInternalReviewCompleted' ? 'Agreement internal review completed by ' + props.user.displayName :
        logType === 'agrInternalReviewCanceled' ? 'Agreement internal review from ' + keyVal2 + ' canceled by ' + props.user.displayName :
        logType === 'clNewClauseStatus' ? 'Clause status changed to ' + keyVal2  + ' by ' + props.user.displayName :
        logType === 'clNewCommentStatus' ? 'Clause comment status changed to ' + keyVal2  + ' by ' + props.user.displayName :
        logType === 'clNewApprovalStatus' ? 'Clause approval status changed to ' + keyVal2  + ' by ' + props.user.displayName :
        logType === 'clNewVersion' ? 'Clause edited by ' + props.user.displayName  : 
                    'The activity type was not recognized' ), // logDesc, 
      props.user.orgID, // orgID
      activeTicket.entID, // entID, 
      activeTicket.relID, // relID, 
      activeTicket.agrID, // agrID, 
      activeTicket.singleClauseID, // singleClauseID, 
      activeTicket._id, // ticketID, 
      props.user._id, // creationBy, 
      ['agrInternalReviewRequested', 'agrInternalReviewStarted', 'agrInternalReviewCompleted', 'agrInternalReviewCanceled'].includes(logType) ? true : false, // isInternal, 
      keyVal1, // keyVal1, 
      keyVal2, // keyVal2
      change, // change
    )    
  }

  const handleRowClick = (id) => {
    
    if(page === 'approvals') {
      let relevantTicket = filteredRows.filter((t) => t._id === id)[0]

      axios.get('/clause/protect/clause/' + relevantTicket.clauseID)
      .then(function(res) {
        setClause(res.data.data)
        setActiveTicket(relevantTicket)
        setRel(props.rels.filter((r) => r._id === relevantTicket.relID)[0]);
        setAgrID(relevantTicket.agrID);
        setEntID(props.rels.filter((r) => r._id === relevantTicket.relID)[0] !== undefined ? props.rels.filter((r) => r._id === relevantTicket.relID)[0].primaryCptyID : ''); // for now will always be the primary counterparty
        setCptyName(props.rels.filter((r) => r._id === relevantTicket.relID)[0] !== undefined &&
          props.user.orgID === props.rels.filter((r) => r._id === relevantTicket.relID)[0].secondaryOrgID ?
            props.rels.filter((r) => r._id === relevantTicket.relID)[0].name.substring(props.rels.filter((r) => r._id === relevantTicket.relID)[0].name.indexOf('|') + 2) :
          props.rels.filter((r) => r._id === relevantTicket.relID)[0] !== undefined &&
          props.user.orgID === props.rels.filter((r) => r._id === relevantTicket.relID)[0].primaryOrgID ?
            props.rels.filter((r) => r._id === relevantTicket.relID)[0].name.substring(0, props.rels.filter((r) => r._id === relevantTicket.relID)[0].name.indexOf('|') - 1) 
            : 'Counterparty') // cpty name is legalName
        handleNegoApprovalsOpen(res.data.data.singleClauseID)
      })
      .catch(function(err) { console.log(err) })

    } else if(page === 'reviews') {
      let relevantTicket = filteredRows.filter((t) => t._id === id)[0]
      setActiveTicket(relevantTicket)
      setRel(props.rels.filter((r) => r._id === relevantTicket.relID)[0]);
      
      let lastUpdateDate = new Date().toISOString();
  
      if(relevantTicket.ticketStatus === 'Unassigned'){
        props.updateTicket(
          false, // FAF
          relevantTicket._id, 
          relevantTicket.module, // module
          'Assigned', // ticketStatus
          relevantTicket.topic, 
          props.user._id, // assignee
          props.user._id, // lastUpdateBy 
          lastUpdateDate, 
          '', // closedBy
          '', // closedDate
          'None', // resolveReason
        )
      } else {
        handleReviewOpen(relevantTicket._id);
      }
    }
  }

  const handleNegoApprovalsOpen = (clid) => {
    setNegoApprovalsOpen(clid)
  }

  const handleNegoApprovalsClose = () => {
    axios.get('/ticket/ticketsscl/' + negoApprovalsOpen) // is a clid
    .then(function(res) {
      let newTicks = res.data.data;
      let ticks = tickets;
      let isChanged = false;
      newTicks.forEach((nt) => { // update all applicable tickets in rows
        const index = ticks.findIndex(t => t._id === nt._id)
        if(ticks[index] !== undefined && 
        (ticks[index].ticketStatus !== nt.ticketStatus || ticks[index].lastUpdateDate !== nt.lastUpdateDate || ticks[index].assignee !== nt.assignee)) {
          isChanged = true;
          ticks[index] = { ...ticks[index], ticketStatus: nt.ticketStatus, lastUpdateDate: nt.lastUpdateDate, assignee: nt.assignee }
        }
      })
      if(isChanged) { setTickets([...ticks]); }
    })

    setRel('');
    setAgrID('');
    setEntID('');
    setCptyName('')
    setNegoApprovalsOpen(null)
  }

  const handleReviewOpen = (tid) => {
    setReviewOpen(tid)
  }

  const handleReviewClose = () => {
    let isChanged = false;
    axios.get('/ticket/ticket/' + reviewOpen) // is a tid
    .then(function(res) {
      let nt = res.data.data;
      let ticks = tickets;
      const index = ticks.findIndex(t => t._id === nt._id)
      if(ticks[index] !== undefined &&
        (ticks[index].ticketStatus !== nt.ticketStatus || ticks[index].lastUpdateDate !== nt.lastUpdateDate)) {
        isChanged = true;
        ticks[index] = { ...ticks[index], ticketStatus: nt.ticketStatus, lastUpdateDate: nt.lastUpdateDate }
      }
      if(isChanged) { setTickets([...ticks]); }
    })
    setActiveTicket({});
    setReviewOpen(null)
  }

  const toggleCommentApprovalStatus = (newStatus, type, clid) => {
    props.updateClauseStatus(
      clause.singleClauseID, // singleClauseID
      type, // type
      newStatus, // newStatus
      props.user._id // lastUpdateBy
    )
  }

  const handleAssignment = (newAssignment) => {
    setViewAssignment(newAssignment)
  }

  const handleStatus = (newStatus) => {
    setViewStatus(newStatus)
  }

  const switchPage = (newPage) => {
    setViewAssignment('assignee')
    setViewStatus('pending')
    setPage(newPage)
  }

  function renderRow(p) {
    const { index, style } = p;
  
    return (
      <div style={style}>
      <ListItem key={index} component="div" button onClick={e => handleRowClick(filteredRows[index]._id)}
      className={index % 2 === 0 ? classes.evenRow : classes.unevenRow}>
        <ListItemAvatar>
          <Avatar className={classes.listAv}
          src={
            viewAssignment === 'assignee' && props.users.filter((u) => u._id === filteredRows[index].creationBy)[0] !== undefined ?
                props.users.filter((u) => u._id === filteredRows[index].creationBy)[0].photoURL :
            viewAssignment === 'requestor' && props.users.filter((u) => u._id === filteredRows[index].assignee)[0] !== undefined ?
                props.users.filter((u) => u._id === filteredRows[index].assignee)[0].photoURL : null}>
            {viewAssignment === 'assignee' && props.users.filter((u) => u._id === filteredRows[index].creationBy)[0] !== undefined ?
                props.users.filter((u) => u._id === filteredRows[index].creationBy)[0].firstName[0] + props.users.filter((u) => u._id === filteredRows[index].creationBy)[0].lastName[0] :
            viewAssignment === 'requestor' && props.users.filter((u) => u._id === filteredRows[index].assignee)[0] !== undefined ?
                props.users.filter((u) => u._id === filteredRows[index].assignee)[0].firstName[0] + props.users.filter((u) => u._id === filteredRows[index].assignee)[0].lastName[0] : null}
          </Avatar>
        </ListItemAvatar>
        <ListItemText 
        primary={<span className={classes.ticketTopic}>
          <span className={viewStatus === 'pending' ? classes.statusPrimary : classes.statusClosed}>
            {filteredRows[index].ticketStatus}
          </span>
          {page === 'approvals' && filteredRows[index].topic.split('|')[2] !== undefined && filteredRows[index].topic.split('|')[3] !== undefined ?
              trunc("[" + filteredRows[index].topic.split('|')[2].trim() + "] " + filteredRows[index].topic.split('|')[3].trim(), 55) :
              trunc(filteredRows[index].topic,55)}
        </span>}
        secondary={<>
          {viewAssignment === 'assignee' && props.users.filter((u) => u._id === filteredRows[index].creationBy)[0] !== undefined ?
              <>Requestor:&nbsp;{props.users.filter((u) => u._id === filteredRows[index].creationBy)[0].displayName}</> :
           viewAssignment === 'requestor' && props.users.filter((u) => u._id === filteredRows[index].assignee)[0] !== undefined ?
              <>Assignee:&nbsp;{props.users.filter((u) => u._id === filteredRows[index].assignee)[0].displayName}</> :
              ''}
          &nbsp;&nbsp;&nbsp;
          Last Update:&nbsp;{dayjs(filteredRows[index].lastUpdateDate).format('MMM D, YYYY')}
        </>} />
      </ListItem>
      </div>
    );
  }

  return (

    <div className={classes.root}>

        <Header 
          module={props.module}
          active="Tasks"
          user={props.user} 
          org={props.org}
          modules={
            props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
              ['sales','procurement','partnerships','people','corporate'] : 
            props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
              props.org.modules : [] }
          
          crumbs={[
            {name: "Home", to: "/core/" + props.module + "/dashboard"},
            {name: "Tasks", to: ['approvals', 'reviews'/*, 'requests'*/].includes(page) ? ("/core/" + props.module + "/tasks") : "active"},
            ...['approvals', 'reviews'/*, 'requests'*/].includes(page) ? 
            [{name: (page === 'approvals' ? "Approvals" : page === 'reviews' ? "Reviews" /*: page === 'requests' ? "Network Requests"*/ : "Overview"), to: "active"}] : []
          ]}
          withSubHead={true}
          notifications={props.notifications}
          markNotificationAsRead={props.markNotificationAsRead}
          markAllNotificationsAsRead={props.markAllNotificationsAsRead}
          className={classes.header}
        />

        <DrawerCore 
          page={page}
          sections={[]}
          pageType='tasks'
          module={props.module}
          switchPage={switchPage}
        />

        <Container maxWidth="lg">
        <Grid container direction="column" justify="flex-start" alignItems="center" className={classes.outerFlexGrid}>

        {tickets === null ?

          <SkeletonCustom type="tasks" />

        :['approvals', 'reviews'].includes(page) ?
          <>
            <Box mt={3} mb={1}>
            <Grid item container direction="row" justify="center" alignItems="center" spacing={5} className={classes.paperResizing}>
              <Grid item>
                <Button color="primary" variant="contained" disableElevation style={{borderRadius: '10px 0px 0px 10px'}}
                  className={viewAssignment === 'assignee' ? classes.selectPillActive : classes.selectPillInactive} 
                  onClick={e => handleAssignment('assignee')}>Assigned to me</Button>
                <Button color="primary" variant="contained" disableElevation style={{borderRadius: '0px 10px 10px 0px'}}
                  className={viewAssignment === 'requestor' ? classes.selectPillActive : classes.selectPillInactive}
                  onClick={e => handleAssignment('requestor')}>Requested by me</Button>
              </Grid>
              <Grid item>
                <Button color="primary" variant="contained" disableElevation style={{borderRadius: '10px 0px 0px 10px'}}
                  className={viewStatus === 'pending' ? classes.selectPillActive : classes.selectPillInactive} 
                  onClick={e => handleStatus('pending')}>Pending</Button>
                <Button color="primary" variant="contained" disableElevation style={{borderRadius: '0px 10px 10px 0px'}}
                  className={viewStatus === 'completed' ? classes.selectPillActive : classes.selectPillInactive} 
                  onClick={e => handleStatus('completed')}>Closed</Button>
              </Grid>
            </Grid>
            </Box>

            {loading ?
            <Box mt={10} mb={10} align="center">
              <CircularProgress size={24} disableShrink />
            </Box>
            :
            filteredRows !== null && filteredRows.length > 0 ?
            <Box className={classes.listBox} mt={5}>
            <AutoSizer>
              {({height, width}) => (
              <FixedSizeList
                height={height}
                width={width}
                itemSize={84}
                itemCount={filteredRows.length}
                overscanCount={5}
              >
                {renderRow}
              </FixedSizeList>
              )}
            </AutoSizer>
            </Box>
            :
            <Box mt={10} mb={10} align="center">
              <Typography>No items to display</Typography>
            </Box>
            }
          </>
          : // Overview page

          <Box>
            {<Box mt={1} mb={10} align="center">
            <Typography align="center" variant="h4" className={classes.bold}>
              Tasks Overview
            </Typography>
            </Box>}

            <Grid container direction="row" alignItems="flex-start" justify="center" spacing={2}>
              <Grid item>

                <ThumbTodo
                route={"click"} 
                icon={faCheckSquare} 
                click={e => setPage("approvals")}
                line1={"Approvals"} 
                line2={<span className={tickets.filter((t) => t.ticketType === 'Approval' && !['Resolved', 'Cancelled', 'Closed'].includes(t.ticketStatus) && t.assignee === props.user._id && t.module === props.module).length === 0 ? classes.justGrey : classes.trulySecondary}>
                  {tickets.filter((t) => t.ticketType === 'Approval' && !['Resolved', 'Cancelled', 'Closed'].includes(t.ticketStatus) && t.assignee === props.user._id && t.module === props.module).length} pending your action</span>} />

              </Grid>
              <Grid item>

                <ThumbTodo
                route={"click"} 
                icon={faSearch} 
                click={e => setPage("reviews")}
                line1={"Reviews"} 
                line2={<span className={tickets.filter((t) => t.ticketType === 'AgrReviewInternal' && !['Resolved', 'Cancelled', 'Closed'].includes(t.ticketStatus) && t.assignee === props.user._id && t.module === props.module).length === 0 ? classes.justGrey : classes.trulySecondary}>
                  {tickets.filter((t) => t.ticketType === 'AgrReviewInternal' && !['Resolved', 'Cancelled', 'Closed'].includes(t.ticketStatus) && t.assignee === props.user._id && t.module === props.module).length} pending your action</span>} />

              </Grid>
            </Grid>

            {['Admin', 'Business', 'Legal'].includes(props.user.role) && tickets !== null ?
            <Box mt={10} pt={1} mb={3} align="center" display={{ xs: 'none', md: 'block' }}>
              {['sales', 'procurement', 'partnerships', 'people', 'corporate']
              .filter((mod) => !['people', 'corporate'].includes(mod) || ['Admin', 'Legal'].includes(props.user.role))              
              .map((mod, i) => (
                <Box key={i}
                align="center"
                onClick={e => history.push('/core/' + mod + '/tasks')}
                className={props.module === mod ? classes.moduleButtonActive : classes.moduleButtonInactive} 
                style={
                  mod === 'sales' ? 
                      { borderRadius: '10px 0px 0px 10px' } : 
                  (mod === 'partnerships' && ['Business'].includes(props.user.role)) || (mod === 'corporate' && ['Admin', 'Legal'].includes(props.user.role)) ? 
                      { borderRadius: '0px 10px 10px 0px', borderRight: '1px solid #e0e0e0' } : {}}>
                  <FontAwesomeIcon className={classes.moduleIcon}
                  icon={
                    mod === 'sales' ? faHandshake : 
                    mod === 'procurement' ? faShoppingCart :
                    mod === 'partnerships' ? faUsers :
                    mod === 'people' ? faUser :
                    mod === 'corporate' ? faBuilding : faHandshake } />
                  <Typography className={classes.bold}>{getActorsForModule(mod)}</Typography>
                  <Typography className={classes.tinyFont}>
                    <span className={props.module !== mod && tickets.filter((t) => t.module === mod && t.assignee === props.user._id && ['Unassigned', 'Assigned', 'In Progress'].includes(t.ticketStatus)).length > 0 ? classes.trulySecondary : null}>
                      {tickets.filter((t) => t.module === mod && t.assignee === props.user._id && ['Unassigned', 'Assigned', 'In Progress'].includes(t.ticketStatus)).length} pending action
                    </span>
                  </Typography>
              </Box>
              ))}
            </Box>
            :''}
          </Box>
          }

        </Grid>
        </Container>

        <DialogNegoApprovals
          open={Boolean(negoApprovalsOpen)}
          parentCallToClose={handleNegoApprovalsClose}
          module={props.module}
          users={props.users}
          user={props.user}
          //relID={rel._id}
          activeOat={{}}
          rel={rel}
          entID={entID}
          agrID={agrID}
          agrFullName={null}
          agrVersion={props.agrVersion}
          clause={clause}
          cptyName={cptyName}
          ticketFromApprovalScreen={activeTicket._id}
          toggleCommentApprovalStatus={toggleCommentApprovalStatus}
          createComment={props.createComment}
          updateComment={props.updateComment}
          updateTicket={props.updateTicket}
          lastUpdateTicket={props.lastUpdateTicket}
          createNotification={props.createNotification}
        />

        {
        <DialogReview
          module={props.module}
          open={Boolean(reviewOpen)}
          parentCallToClose={handleReviewClose}
          ticket={activeTicket}   
          user={props.user}
          rel={rel}
          users={props.users}
          createComment={props.createComment}
          updateComment={props.updateComment}
          updateTicket={props.updateTicket}
          lastUpdateTicket={props.lastUpdateTicket}
          handleActivityLog={handleActivityLog}
          createNotification={props.createNotification}
        />
        }

    </div>

  )
}

export default Tasks;
