import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { EditorState, ContentState, convertToRaw, convertFromRaw, CompositeDecorator } from 'draft-js';
import { Box, Button } from '@material-ui/core';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention';

import defaultUser from '../assets/img/defaultuser.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const MentionEditor = (props) => {

    const ref = React.useRef(null);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    const [open, setOpen] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [allUsers, setAllUsers] = useState([])

    const decorator = new CompositeDecorator([
        { strategy: MentionStrategy, component: MentionSpan },
    ]);

    useEffect(() => {
        if(props.user !== undefined && props.users.length > 0){
            let mu = []
            props.users.forEach((u) => { mu.push({
                uid: u._id, 
                name: u.displayName, 
                email: u.email,
                avatar: u.photoURL !== undefined && u.photoURL !== null && u.photoURL !== '' ? u.photoURL : defaultUser
            }) })
            setSuggestions(mu)
            setAllUsers(mu)
        }
    }, [props.user, props.users])

    useEffect(() => {
        if(props.content !== undefined && props.content !== null && props.content.blocks !== undefined) {
            if(props.content.entityMap === undefined || props.content.entityMap === null || props.content.entityMap === {}){
                Object.assign(props.content, { entityMap: {} })
            }
            setEditorState(EditorState.createWithContent(convertFromRaw(props.content), decorator))
        }
    }, [props.content])

    useEffect(() => { // UE to clean up the editor if "isPublic changes"
        if(['clean', 'additional'].includes(props.editorType) && editorState !== undefined && 
        editorState.getCurrentContent() !== undefined && editorState.getCurrentContent().getPlainText() !== '') {
            setEditorState(EditorState.push(editorState, ContentState.createFromText('')))
        }
    }, [props.isPublic, props.editorType])

    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin({
            entityMutability: 'IMMUTABLE',
            supportWhitespace: true,
            mentionPrefix: '@',
        });
        const { MentionSuggestions } = mentionPlugin;
        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    }, []);

    const onOpenChange = useCallback((_open) => {
        setOpen(_open);
      }, []);
    
    const onSearchChange = useCallback(({ value }) => {
        setSuggestions(defaultSuggestionsFilter(value, allUsers));
    }, [allUsers]);

    const handleSubmit = () => {

        props.handleCommentSubmit(
            true, 
            convertToRaw(editorState.getCurrentContent()), 
            'Open')
        setEditorState(EditorState.createEmpty());
        //setEditorState(EditorState.push(editorState, editorState.getCurrentContent().createFromText('')))

    }

    const handleCancelUpdate = () => {
        // Revert back to the pre-editing state
        if(props.content.entityMap === undefined || props.content.entityMap === null || props.content.entityMap === {}){
            Object.assign(props.content, { entityMap: {} })
        }
        setEditorState(EditorState.createWithContent(convertFromRaw(props.content), decorator))
        props.cancelUpdating();
    }

    const handleConfirmUpdate = () => {
        props.handleCommentChangeSubmit(
            true, 
            convertToRaw(editorState.getCurrentContent()))
    }

    return (
    <div className={editorState !== undefined && editorState !== null && editorState.getSelection() !== undefined &&
        editorState.getSelection().getHasFocus() ? 'MentionEditor-hidePlaceholder' : null}
        style={props.editorType === "additional" ? {maxWidth: '360px'} : {maxWidth: '400px', margin: '0px', padding: '0px'}}
        align="right">
        <div className={
            props.isPlain ? "MentionEditor-plain" : 
            props.editorType === "additional" ? "MentionEditor-additional" : "MentionEditor-editor"} 
        style={
            props.isPlain && props.beingUpdated && props.align === "left" ? {borderRadius: '0px 10px 0px 0px'} :
            props.isPlain && props.align === "left" ? {borderRadius: '0px 10px 10px 0px'} :
            props.isPlain && props.align === "right" ? {borderRadius: '10px 0px 0px 10px'} : {}}
        onClick={e => ref.current.focus()}>
        <Editor
          editorKey={'editor'}
          customStyleMap={{}}
          editorState={editorState}
          onChange={setEditorState}
          stripPastedStyles={true}
          plugins={plugins}
          ref={ref}
          placeholder={"Insert Comment..."}
          readOnly={props.isPlain && !props.beingUpdated}
        />
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          onAddMention={() => {
            // get the mention object selected
          }}
        />
        </div>
        {props.editorType === "additional" ?
        <Button style={{fontWeight: '700', padding: '2px 14px 2px 14px'}}
        disableElevation color={props.curCpty} variant="text" size="small" onClick={handleSubmit}>
            Submit&nbsp;&nbsp;<FontAwesomeIcon icon={faPaperPlane} />
        </Button>
        :!props.isPlain ?
        <Button 
        disableElevation color={props.curCpty} variant="contained" fullWidth onClick={handleSubmit}>
            {props.isApprovalStyling ? 
            <>Next&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} /></>
            :
            <>Submit&nbsp;&nbsp;<FontAwesomeIcon icon={faPaperPlane} /></>
            }
        </Button>
        :
        props.beingUpdated ?
        <Box align="right" pb={1} pr={1} style={{backgroundColor: '#f9f9f9', borderRadius: '0px 0px 10px 0px'}}>
            <Button size="small" variant="text" color={props.curCpty} onClick={handleCancelUpdate}>Cancel</Button>
            <Button size="small" variant="contained" disableElevation color={props.curCpty} onClick={e => handleConfirmUpdate()}>Update</Button>
        </Box>
        :''}
    </div>
    );
}

function MentionStrategy(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null
        );
      },
      callback
    );
}

const MentionSpan = (props) => {
    let data = props.contentState.getEntity(props.entityKey).data;
    return (
      <span style={{fontWeight: '700'}}>
        {props.children}
      </span>
    );
};

export default MentionEditor