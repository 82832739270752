import React, { useEffect, useRef } from 'react';
import HelloSign from 'hellosign-embedded';
import { useHistory } from 'react-router-dom';
import { convertPlainToBlocks, userFriendlyAVV, getUsersWhoLockAClause, trunc } from '../../utils'
import { Box, Button, CircularProgress, 
         Container, Grid, List, ListItem, ListItemText, Typography,
         makeStyles } from '@material-ui/core';
import { AgreementEditor, AgreementExhList, AgreementStructure, DialogAVOld, DialogAgrAction, DialogReview, 
         DialogSignature, Header, MenuMoreOptions, SkeletonCustom } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faInfoCircle, faUserEdit, 
         faHourglassHalf, faSignature, faUserTimes, faBan } from '@fortawesome/free-solid-svg-icons';
import ReadProgressBar from 'read-progressbar'

import pdf from '../../assets/img/pdf.png';

const download = require("js-file-download")
const axios = require('axios');

function Agreement(props) {

  const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        //backgroundColor: '#f9f9f9',
    },
    outerFlexGrid: {
      minHeight: '100vh',
      paddingTop: '120px',
      paddingBottom: '100px',
    },
    header: {
        zIndex: 1400
    },
    content: {
        flexGrow: 1,
        padding: '0px',
    },
    warnIcon: {
      color: theme.palette.primary.main,
      fontSize: '28px',
    },
    deleteIcon: {
      color: theme.palette.error.main,
      fontSize: '56px',
      marginBottom: '50px',
    },
    warnIcon2: {
      color: theme.palette.warning.main,
      fontSize: '56px',
      marginBottom: '50px',
    },
    deleteButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.primary.contrastText,
      marginRight: '20px',
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.primary.contrastText,
      }
    },
    acceptButton: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.primary.contrastText,
      marginRight: '20px',
      '&:hover': {
        backgroundColor: theme.palette.success.dark,
        color: theme.palette.primary.contrastText,
      }
    },
    signOffBox: {
      border: '1px solid #e6e6e6',
      //backgroundColor: '#f9f9f9',
      borderRadius: '10px',
      [theme.breakpoints.down('xs')]: { width: '320px', padding: '20px 17px 20px 17px' },
      [theme.breakpoints.only('sm')]: { width: '460px', padding: '30px 20px 30px 20px' },
      [theme.breakpoints.only('md')]: { width: '580px', padding: '30px 30px 30px 30px' },
      [theme.breakpoints.up('lg')]: { width: '640px', padding: '30px 30px 30px 30px' },
    },
    signName: {
      [theme.breakpoints.down('xs')]: { fontSize: '13px'},
      fontSize: '15px',
    },
    signaturePending: {
      [theme.breakpoints.down('xs')]: { display: 'block', marginLeft: '0px' },
      fontSize: '13px',
      fontWeight: '700',
      marginLeft: '15px',
      padding: '2px 0px 2px 0px',
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
    signatureSigned: {
      [theme.breakpoints.down('xs')]: { display: 'block', marginLeft: '0px' },
      fontSize: '13px',
      fontWeight: '700',
      marginLeft: '15px',
      padding: '2px 0px 2px 0px',
      //color: theme.palette.grey[900],
      color: theme.palette.success.main,
      backgroundColor: 'transparent'
    },
    signatureDeclined: {
      [theme.breakpoints.down('xs')]: { display: 'block', marginLeft: '0px' },
      fontSize: '13px',
      fontWeight: '700',
      marginLeft: '15px',
      padding: '2px 0px 2px 0px',
      color: theme.palette.error.main,
      backgroundColor: 'transparent'
    },
    signatureAwaiting: {
      [theme.breakpoints.down('xs')]: { display: 'block', marginLeft: '0px' },
      fontSize: '13px',
      fontWeight: '700',
      marginLeft: '15px',
      padding: '2px 0px 2px 0px',
      color: theme.palette.grey[500],
      backgroundColor: 'transparent'
    },
    bold: {
      fontWeight: '700'
    },
    downloadPDF: {
      width: '30px', 
      height: 'auto', 
      '&:hover': {
        cursor: 'pointer',
      }
    }
  }));

  const client = new HelloSign();
  const classes = useStyles();
  const readRef = useRef();
  const history = useHistory()

  const [openDiffDialog, setOpenDiffDialog] = React.useState(false);
  const [avOldDialogOpen, setAvOldDialogOpen] = React.useState(null);
  const [signatureDialogOpen, setSignatureDialogOpen] = React.useState(null);
  const [timeBase, setTimeBase] = React.useState(0); // The basis for the time tracker
  const [alreadyOpened, setAlreadyOpened] = React.useState(false); // Whether the agreement was already opened
  const [activeRel, setActiveRel] = React.useState(null); // The relevant Relationship
  const [activeAgr, setActiveAgr] = React.useState(null); // The relevant Agreement
  const [activeAgrs, setActiveAgrs] = React.useState(null); // The relevant Agreement records (exhibits etc.)
  const [activeAV, setActiveAV] = React.useState(null); // The relevant (ie. latest) Agreement Version for this counterparty for this Agreement
  const [mainBodyAV, setMainBodyAV] = React.useState(null); // The relevant (ie. latest AV) for the main body
  const [avs, setAvs] = React.useState(null)
  const [avClauses, setAvClauses] = React.useState(null); // The clauses array for the Agreement Version
  const [clauses, setClauses] = React.useState(null); // The Clauses of the Agreement
  const [tickets, setTickets] = React.useState(null); // The Tickets related to the Agreement
  const [laterVersionOwnedByCpty, setLaterVersionOwnedByCpty] = React.useState(null); // Whether the Pen is held by the counterparty or not
  const [creationDateOfLatestAV, setCreationDateOfLatestAV] = React.useState(null) // Used to potentially hide newer comments
  const [signatories, setSignatories] = React.useState([]);
  const [pdfSignedURL, setPdfSignedURL] = React.useState('');
  const [clauseActivityLog, setClauseActivityLog] = React.useState(null)
  const [signURL, setSignURL] = React.useState(null) // Whether there is a signature URL and it's relevant for this User
  const [toggleFilter, setToggleFilter] = React.useState(['clause', 'changes', 'starred', 'internalcomments', 'lockedByOrg', 'apprequest', 'appassignee'].includes(props.clid) ? props.clid : null); // Whether the cards filter is set
  const [plainMode, setPlainMode] = React.useState(null) // Variable whether in cards or in plain mode
  const [primaryCpty, setPrimaryCpty] = React.useState(null) // The primaryEntity record
  const [secondaryCpty, setSecondaryCpty] = React.useState(null) // The secondaryEntity record
  const [edited, setEdited] = React.useState(false); // Whether the agreement has been edited during the session
  const [reviewOpen, setReviewOpen] = React.useState(false); // Whether DialogReview is opened or not
  const [agrActTrigger, setAgrActTrigger] = React.useState(null); // Whether the DialogAgrAction is open or not (and it's trigger)
  const [cptyModule, setCptyModule] = React.useState('unknown'); // What the receivingModule is - ie. the module for the CPTY
  const [fetchError, setFetchError] = React.useState(null) // Whether there was an error fetching
  const [downloadFileName, setDownloadFileName] = React.useState('unknown')
  const [agrExec, setAgrExec] = React.useState(null)
  const [viewablePDF, setViewablePDF] = React.useState(null)
  const [deleting, setDeleting] = React.useState(false)
  const [acceptingEverything, setAcceptingEverything] = React.useState(false)
  const [usersEditing, setUsersEditing] = React.useState([])
  const [reorderExhibits, setReorderExhibits] = React.useState(false)
  const [avsPenTurnDetail, setAvsPenTurnDetail] = React.useState([]) 
  const [agrsAvs, setAgrsAvs] = React.useState([]) // All Agreements & Their respective AVs
  const [indexAssignedSigner, setIndexAssignedSigner] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [smartFields, setSmartFields] = React.useState([])

  useEffect(() => {
    //var startTime = performance.now()
    let unmounted = false;

    // USE EFFECT Section to define agreement content
    let rel = {}, agrs = [], agrsavs = [], aids = [], ticks = [], agr = {}, agr_main = {}, avcs = [], cls = [], lvoc = false, pmode = false, cdola = null, tsav = 1, pcpty = {}, scpty = {}, cpMod = '', iiv = false;

    if(!props.isLoading && props.rels.filter((r) => r._id === props.rid)[0] !== undefined && props.user !== undefined && props.subs !== undefined && props.cpents !== undefined) {

      rel = props.rels.filter((r) => r._id === props.rid)[0] // ASSIGN rel and cpMod (counterparty Module)
      cpMod = (props.module === 'people' || rel.relationshipType === 'people' ? 'people' : props.module === 'corporate' || rel.relationshipType === 'corporate' ? 'corporate' :
               props.module === 'sales' ? 'procurement' : props.module === 'procurement' ? 'sales' : props.module === 'partnerships' ? 'partnerships' : 
               props.module === 'counterparty' && rel.relationshipType === 'partnership' ? 'partnerships' : 
               props.module === 'counterparty' && rel.relationshipType === 'b2b' && props.user.orgID === rel.primaryOrgID ? 'procurement' : 
               props.module === 'counterparty' && rel.relationshipType === 'b2b' && props.user.orgID === rel.secondaryOrgID ? 'sales' : 'unknown')

      // Retrieve Agreement, and it's children (if parent) or it's parent and it's children (if child)
      axios.get('/agr/agrtied/' + props.aid)
      .then(function(res) {
        agrs = res.data.data
        agr = agrs.filter((a) => a._id === props.aid)[0]
        agr_main = agrs.filter((a) => a.parentAID === '')[0]

        // Reroute the user (with a snack) since they should not be able to access the agreement (anymore)
        if(props.user !== undefined && props.user.role === 'Counterparty' && props.user.agrIDs !== undefined && props.user.agrIDs !== null &&
        !props.user.agrIDs.some((a) => a === agr._id && a !== "") && !props.user.agrIDs.some((a) => a === agr.parentAID && a !== "")){
          history.push('/core/counterparty/relationship/' + props.rid + '/overview/3')
        }

        pcpty = rel.primaryOrgID === props.user.orgID && props.subs.filter((s) => s._id === agr.primaryCptyID)[0] !== undefined ? 
                  props.subs.filter((s) => s._id === agr.primaryCptyID)[0] :
                rel.secondaryOrgID === props.user.orgID && props.cpents.filter((c) => c._id === agr.primaryCptyID)[0] !== undefined ? 
                  props.cpents.filter((c) => c._id === agr.primaryCptyID)[0] : null;
        scpty = rel.secondaryOrgID === props.user.orgID && props.subs.filter((s) => s._id === agr.secondaryCptyID)[0] !== undefined ? 
                  props.subs.filter((s) => s._id === agr.secondaryCptyID)[0] :
                rel.primaryOrgID === props.user.orgID && props.cpents.filter((c) => c._id === agr.secondaryCptyID)[0] !== undefined ? 
                  props.cpents.filter((c) => c._id === agr.secondaryCptyID)[0] : null;


        agrs.forEach((a) => { aids.push(a._id) })

        axios.post('/agr/agrvstied', { aids: aids }) // Pull all AVS for all AGs
        .then(function(resavs) { // You got all the Agr Versions for all Agreements, they are ordered DESC by "version"
          
          axios.post('/ticket/ticketstied', { aids: aids, orgID: props.user.orgID, stripped: true }) // Pull all Tickets for all AGs
          .then(function(rest) {
            ticks = rest.data.data;

            aids.forEach((aid) => { // For every agreement in the overall set
              let tempagr = agrs.filter((a) => a._id === aid)[0]
              let tempavs = resavs.data.data.filter((av) => av.agrID === aid)
              let tempav = ['Execution', 'InForce'].includes(agr_main.agrStatus) ? tempavs[0] :
                          tempavs.filter((agrv) => (agrv.owner === "primary" && rel.primaryOrgID === props.user.orgID) || 
                            (agrv.owner === "secondary" && rel.secondaryOrgID === props.user.orgID))[0] !== undefined ?
                          tempavs.filter((agrv) => (agrv.owner === "primary" && rel.primaryOrgID === props.user.orgID) || 
                            (agrv.owner === "secondary" && rel.secondaryOrgID === props.user.orgID))[0] : {}; // ASSIGN av
              let isMain = (aid === agr_main._id)
              agrsavs.push({
                _id: aid, 
                agrFullName: tempagr.agrFullName, 
                priority: tempagr.priority, 
                avid: tempav._id,
                avv: tempav.version,
                isMain: isMain, 
                parentAID: tempagr.parentAID,
                contributors:  isMain ? tempagr.contributors : [],
                signatories: isMain ? tempagr.signatories : []
              })
              let i = 0; // Check recent AVs (only for the most recent cpty) to ensure interim versions are ignored
              while (tempavs[i] !== undefined && tempavs[i].owner === tempavs[0].owner) { cdola = tempavs[i].creationDate; i = i + 1; }
              while (tempavs[i] !== undefined && tempavs[i].owner === (tempavs[0].owner === 'primary' ? 'secondary' : 'primary')) {
                tsav = i; // define their (ie. cpty) start AV (tsav), to map the activityLog Changes correctly
                i = i + 1;
              }
              // Need to pull all activity logs inbetween the current and start of cpty version to count # changes to the AV (for coreDrawer)
              if(['Negotiation'].includes(agr_main.agrStatus) && tempav === tempavs[0] && tempavs.length > 1 && tempavs[tsav] !== undefined) { 
                let clsChanged = [];
                axios.get('/logging/protect/alclchanges/' + aid + '/' + tempavs[tsav].version)
                .then(function(resalcls) {

                  resalcls.data.data
                  .filter((al) => !al.isInternal || al.orgID === props.user.orgID)
                  .forEach((change) => { if(!clsChanged.some((c) => c === change.singleClauseID)) { clsChanged.push(change.singleClauseID) } })

                  if (!unmounted) {
                    if(aid === props.aid) { // For the current agreement, assign the clauseActivityLog detail
                      setClauseActivityLog(resalcls.data.data.filter((al) => !al.isInternal || al.orgID === props.user.orgID)) 
                    }
                    // For all agreements, assign the appropriate "agr version state on pen turn" detail
                    setAvsPenTurnDetail(aptd => [...aptd, {
                      aid: aid,
                      avid: tempav._id,
                      isEdited: tempav.isEdited,
                      openOnPT: tempav.openOnPenTurn !== undefined ? tempav.openOnPenTurn : null,
                      changesOnPT: clsChanged.length
                    }])
                  }
                }).catch(function(err) { if (!unmounted) { setFetchError(err); } })
              } else { setClauseActivityLog([]) }

              // For the currently active Agreement part 
              if(tempagr._id === props.aid) {
                avcs = tempav.clauses // ASSIGN avcs
                lvoc = tempavs.find((agrv) => agrv.version.localeCompare(tempav.version) > 0 && 
                        ((agrv.owner === "primary" && rel.secondaryOrgID === props.user.orgID) || 
                        (agrv.owner === "secondary" && rel.primaryOrgID === props.user.orgID))) !== undefined // ASSIGN lvoc (Later Version Owned by Cpty)
                pmode = // Derive plainmode
                  (tempagr !== null && tempagr._id !== undefined && ['Execution', 'InForce'].includes(tempagr.agrStatus)) || 
                  (tempagr.agrStatus === 'Exhibit' && ['Execution', 'InForce'].includes(agr_main.agrStatus)) ||
                  (rel !== null && rel._id !== undefined && rel.deactive)

                iiv = // Whether this is an Interim version or not
                  tempavs.length > 1 &&
                  tempavs.findIndex((tavs) => tavs._id === tempav._id) > -1 && !lvoc && (
                  (tempavs[tempavs.findIndex((tavs) => tavs._id === tempav._id) + 1].owner === "primary" && rel.primaryOrgID === props.user.orgID) ||
                  (tempavs[tempavs.findIndex((tavs) => tavs._id === tempav._id) + 1].owner === "secondary" && rel.secondaryOrgID === props.user.orgID))
                  
                axios.post('/clause/clauses', { clauses: avcs }) // Need to get the relevant clauses
                .then(function(rescls) {
                  cls = rescls.data.data;  // ASSIGN cls
      
                  axios.get('/logging/protect/gettime/' + props.user._id + "/" + props.aid)
                  .then((res) => {
                    let time = res.data.data === undefined || res.data.data === null ? 0 : res.data.data.time;
                    axios.get('/sf/sf/' + props.aid)
                    .then(function(ressfs) {
                      if (!unmounted) { // Now set all the relevant useState variables
                        setActiveAgrs(agrs.sort((a,b) => (a.priority > b.priority ? 1 : a.priority < b.priority ? -1 : 0)))
                        setSmartFields(ressfs.data.data)
                        setTickets(ticks)
                        setTimeBase(time)
                        setAlreadyOpened(res.data.data === undefined || res.data.data === null ? false : res.data.data.openedToday)
                        setActiveRel(rel);
                        setActiveAgr(tempagr);
                        setAvs(tempavs);
                        setActiveAV(tempav);
                        setEdited(tempav.isEdited || iiv);
                        setAvClauses(avcs);
                        setClauses(cls);
                        setLaterVersionOwnedByCpty(lvoc);
                        setCreationDateOfLatestAV(cdola); // This is the creation date of the owning CPTY latest MAJOR AV, i.e. if they created an interim version it does not count
                        setPrimaryCpty(pcpty);
                        setSecondaryCpty(scpty);
                        setCptyModule(cpMod);
                        setDownloadFileName(agr_main.agrShortName + " - " + agr_main.agrStatus + " - " + agr_main.lastUpdateDate);
                        setPlainMode(pmode)
                        //var endTime = performance.now()
                        //console.log(`Call to doSomething took ${endTime - startTime} milliseconds`)
                      }

                      if(tempav.attachment !== undefined && tempav.attachment !== null && tempav.attachment !== '' && tempav.attachment !== 'placeholder'){
                        // Download the Viewable PDF for a PDF Attachment
                        axios.get('/signing/protect/download/' + tempav.attachment + '/attachment', {responseType: 'arraybuffer'})
                        .then(function (respdf) { if (!unmounted) { setViewablePDF(respdf.data); setPdfSignedURL(tempav.attachment); }
                        }).catch(function (err) { console.log("Unable to get the Attachment PDF", err) })
                      }

                    }).catch(function(err) { if (!unmounted) { setFetchError(err); } })
                  }).catch(function(err) { if (!unmounted) { setFetchError(err); } })
                }).catch(function(err) { if (!unmounted) { setFetchError(err); } })
              }

              // Define the Main Body AV - this is applicable to DialogAgrAction
              if(tempagr._id === agr_main._id) { if (!unmounted) { setMainBodyAV(tempav); } }

            }) // end of the agreement loop

            if (!unmounted) { 
              setAgrsAvs(agrsavs) 
            }

          }).catch(function(err) { if (!unmounted) { setFetchError(err); } })
        }).catch(function(err) { if (!unmounted) { setFetchError(err); } })

        // USE EFFECT Section to define the agr. execution details
        if(!props.isLoading && agr._id !== undefined && (['Execution', 'InForce'].includes(agr.agrStatus))) {
          axios.get('/agr/agrexec/' + props.aid) // Pull the agrExec for this agrID
          .then(function (res) { // got a msg from the server
            if(res.data.data !== null) {
              if (!unmounted) {

                let signers = ["offline","historical"].includes(res.data.data.signatoryRequestID) ? 
                  [] : res.data.data.signatories.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
                let idxAssignedSigner = null;
                if(!["offline","historical"].includes(res.data.data.signatoryRequestID) && signers[0] !== undefined && signers[0].order !== undefined && signers[0].order !== null && signers[0].order !== -1){ // If there is an assigned order, find the current assigned signer
                  signers.forEach((s,i) => { if(idxAssignedSigner === null &&(s.status_code === 'OPEN' || s.signed_at === null)) { idxAssignedSigner = s.order; } })

                  setIndexAssignedSigner(idxAssignedSigner)
                }

                setSignatories(signers)
                setPdfSignedURL(res.data.data.pdfSignedURL)
                setAgrExec(res.data.data)

                let signerTemp = signers.filter((s) => (s.signer_email_address.toLowerCase() === props.user.email.toLowerCase()) ||
                                          (s.signer_identity_data !== undefined && s.signer_identity_data !== null && 
                                           s.signer_identity_data.email_address !== undefined && 
                                           s.signer_identity_data.email_address.toLowerCase() === props.user.email.toLowerCase()))[0]

                if((res.data.data.signMethod === undefined || res.data.data.signMethod === null || res.data.data.signMethod === '' || res.data.data.signMethod === 'hellosign') &&
                  agr.agrStatus === 'Execution' && signers.filter((s) => s.signer_email_address === props.user.email.toLowerCase())[0] !== undefined &&
                    signers.filter((s) => s.signer_email_address === props.user.email.toLowerCase())[0].signed_at === null && (idxAssignedSigner === null || idxAssignedSigner === signers.filter((s) => s.signer_email_address === props.user.email.toLowerCase())[0].order)) {
                  // legacy HelloSign signing flow
                  setSignURL ("exists"); // User is a signer and agreement is pending signature (& IF ORDERED, IS THE ASSIGNEE)
                } else if(agr.agrStatus === 'Execution' && signerTemp !== undefined && signerTemp.status_code !== 'SIGNED' && 
                (idxAssignedSigner === null || idxAssignedSigner === signerTemp.order)) {
                  // Skribble signing flow
                  let url = signerTemp.signing_url !== undefined ? signerTemp.signing_url : ('https://my.skribble.com/view/' + res.data.data.signatoryRequestID)
                  setSignURL(url); // User is a signer and agreement is pending signature (& IF ORDERED, IS THE ASSIGNEE)
                } else if(res.data.data.pdfSignedURL !== undefined && res.data.data.pdfSignedURL !== null &&
                  res.data.data.pdfSignedURL !== '' && ["offline", "historical"].includes(res.data.data.signatoryRequestID)) {
                  // No signing applies, download the PDF
                  // Download the Viewable PDF for AgrExec
                  axios.get('/signing/protect/download/' + res.data.data.pdfSignedURL, {responseType: 'arraybuffer'})
                  .then(function (respdf) { setViewablePDF(respdf.data)
                  }).catch(function (err) { console.log("Unable to get AgrExec PDF", err) })

                }
              }
            }
          }).catch(function (err) { console.log("Unable to get Agr Execution Detail", err) })
        }
      }).catch(function(err) { if (!unmounted) { setFetchError(err); } })
    }

    return () => { unmounted = true };

  }, [props.isLoading, props.aid, props.rels, props.rid, props.user, props.module, props.subs, props.cpents, props.agrExec, history]) 
  // *** END OF USEEFFECT ***


  useEffect(() => {
    if(agrExec !== undefined && agrExec !== null && activeAgr !== undefined && activeAgr !== null &&
    signatories !== undefined && signatories !== null &&
    ['swisscom'].includes(agrExec.signMethod) && activeAgr.agrStatus === 'Execution' && signURL !== null && 
    !signatories.some((s) => s.status_code === 'DECLINED') && 
    signatories.filter((s) => s.signer_email_address === props.user.email.toLowerCase() && s.signed_at === null)[0] !== undefined) {  
      handleSignatureDialog()
    }
  }, [activeAgr, signURL, agrExec, signatories])

  /*useEffect(() => { // USE EFFECT TO POTENTIALLY CHANGE THE FAVICON (FOR WHITELABEL)
    if(activeRel !== null && activeRel.whiteLabel !== undefined && activeRel.whiteLabel !== null &&
      activeRel.whiteLabel.logoURL !== undefined && props.user !== undefined && props.user.role === 'Counterparty') {
      let ico = document.getElementById("favicon");
      console.log("ico", ico)
      if(ico !== null && ico.href === '/img/favicon.ico'){ // if Favicon has not been changed yet
        // THE STANDARD LOGO (PNG/JPG) DOES NOT WORK FOR SETTING THE FAVICON, MAY NEED TO ENABLE UPLOAD OF .ICO file
        // ALSO NEED TO UPDATE THE TITLE OF THE PAGE
        ico.href = activeRel.whiteLabel.logoURL;
      }
    }
  }, [activeRel, props.user])*/

  const cptyName = activeRel !== null && secondaryCpty !== null && props.user.orgID === activeRel.primaryOrgID ? secondaryCpty.shortName :
                   activeRel !== null && primaryCpty !== null && props.user.orgID === activeRel.secondaryOrgID ? primaryCpty.shortName : 'Counterparty'

  const parentAgrStatus = activeAgr !== null && activeAgrs !== null && activeAgrs.filter((a) => a._id === activeAgr.parentAID)[0] !== undefined && activeAgrs.filter((a) => a._id === activeAgr.parentAID)[0].agrStatus !== undefined ?
                          activeAgrs.filter((a) => a._id === activeAgr.parentAID)[0].agrStatus : null

  const whiteLabel = activeRel !== null && activeRel.whiteLabel !== undefined && activeRel.whiteLabel !== null && activeRel.whiteLabel.color !== undefined &&
    props.user !== undefined && props.user.role === 'Counterparty' ? activeRel.whiteLabel : null
  const whiteLabelLoading = props.isLoading || activeRel === null

  const handleChildInformation = (type, value) => { // A callback from the child component to set Agr/Oat level variables
    if(type === 'wasEdited') {
      if(!edited && activeAgr.agrStatus === 'Exhibit') { // This is an exhibit - need to update the AV for the parent "Main Body"
        axios.get('/agr/agrvs/' + activeAgr.parentAID) // Need to get the latest AV for this counterparty
        .then(function(resavs) { // You got all the Agr Versions for this Agreement, they are ordered DESC by "version"
          let av = resavs.data.data.filter((agrv) => (agrv.owner === "primary" && activeRel.primaryOrgID === props.user.orgID) || 
                                                 (agrv.owner === "secondary" && activeRel.secondaryOrgID === props.user.orgID))[0] // ASSIGN av
          props.updateAgrvMarkAsEdited(av._id, true, false, false);
        }).catch(function (err) { console.log("err", err) })

      } else if(!edited && value) { // update the agrVersion and mark as no longer ready for signature
        props.updateAgrvMarkAsEdited(activeAV._id, true, false, false);

      }
      setEdited(value);

    } else if(['oldAV', 'cloneVersion'].includes(type)) {

      setAvOldDialogOpen(type === 'cloneVersion' ? 'cloneVersion' : value);

    } else if (['sign', 'cpty', 'collab', 'reviewtickets', 'retrieve'].includes(type)) {
      handleAgrActOpen(type)
    } else if (['int'].includes(type)){
      setReviewOpen(true);
    }
  }

  const handleToggleFilter = (e, type) => { // Setting the Cards Filter (through Header)
    setToggleFilter(type !== toggleFilter ? type : null);
  }

  const deactivateAgreement = () => { // Deactive the agreement with boolean whether to reroute or not
    setDeleting(true)
  }

  const handleNewExhibitOrder = (exhibits) => {
    exhibits.forEach((e,i) => { props.updateAgrPriority(e._id, ((i+10)*10), props.user._id) })
    history.push("/core/" + props.module + "/agreement/" + props.rid + "/" + props.aid); 
  }

  const handleConfirmDeactivateAgreement = (reroute) => {
    let parentAID = activeAgr.parentAID !== undefined && activeAgr.parentAID !== null && activeAgr.parentAID.length > 10 ? activeAgr.parentAID : null
    props.updateAgr(
      props.rid, // rid 
      activeAgr._id, // aid 
      activeAgr.primaryCptyID,
      activeAgr.secondaryCptyID,
      'Deactive', // agrStatus
      activeAgr.curAVOwner, // curAVOwner
      activeAgr.sourceFileID, // sourceFileID 
      activeAgr.contributors, // contributors
      activeAgr.signatories, // signatories
      props.user._id, // lastUpdateBy
    )
    handleActivityLog('agr', 'agrNewStatus', '', '', 'Deactive', '', {}) // logLevel, logType, singleClauseID, ticketID, keyVal1, keyVal2

    if(reroute && parentAID === null) { history.push("/core/" + props.module + "/relationship/" + props.rid + "/overview"); }
    else if (reroute && parentAID !== null) { history.push("/core/" + props.module + "/agreement/" + props.rid + "/" + parentAID) }
  }

  const handleArchiveAndRecreate = () => { // Archive the agreement and go to the newRelationship screen for a new Agreement
    handleConfirmDeactivateAgreement(false); // Confirm deactivation without rerouting (the "false")
    history.push(activeAgr.parentAID !== undefined && activeAgr.parentAID !== null && activeAgr.parentAID.length > 10 ? 
      ("/core/" + props.module + "/agreement/" + props.rid + "/" + activeAgr.parentAID) :
      ("/core/" + props.module + "/new"));
  }

  const handleRefresh = () => {
    history.push("/core/" + props.module + "/agreement/" + props.rid + "/" + props.aid);
  }

  const handleAgrActOpen = (type) => { // Open the DialogAgrAction
    if(type === 'reviewtickets'){ setReviewOpen(true) } 
    else { setAgrActTrigger(type) }
  }

  const handleAgrActClose = () => { // Close the DialogAgrAction
    setAgrActTrigger(null);
  }

  const handlePushToAgr = (agrid, filter) => { // Push to Agreement screen (for an Exhibit scenario)
    history.push("/core/" + props.module + "/agreement/" + props.rid + "/" + agrid + (
      filter !== null ? ("/" + filter) :
      toggleFilter !== null ? ("/" + toggleFilter) : ""));
  }

  const handleReviewClose = () => {
    setReviewOpen(false)
  }

  const updateTicketInParent = (tick) => {
    let ticks = tickets
    const index = tickets.findIndex(t => t._id === tick._id)
    ticks[index] = tick
    setTickets([...ticks])
  }

  const handleCancelAcceptEverything = () => {
    setAcceptingEverything(false)
    setUsersEditing([])
  }

  const handleAcceptEverything = () => {
    setLoading(true)
    let uEditing = []

    agrsAvs.forEach((aa, i) => {
      let agsDone = 0
      axios.get('/agr/agrv/' + aa.avid)
      .then((resav) => {
        axios.post('/clause/clauses', { clauses: resav.data.data.clauses }) // Need to get the relevant clauses
        .then(function(rescls) {
          agsDone = agsDone + 1
          uEditing = getUsersWhoLockAClause(rescls.data.data, props.users, props.user._id, uEditing)
          // if you hit the last AV
          if(agsDone === (i + 1)) {
            setUsersEditing(uEditing)
            setLoading(false)
            setAcceptingEverything(true)
          }

        }).catch(function(err) { console.log("err capturing av details", err)})
      }).catch(function(err) { console.log("err capturing av details", err)})
    })
  }

  const handleConfirmAcceptEverything = () => {
    setLoading(true)
    agrsAvs.forEach((aa, i) => {
      axios.get('/agr/agrv/' + aa.avid)
      .then((resav) => {
        axios.post('/clause/clauses', { clauses: resav.data.data.clauses }) // Need to get the relevant clauses
        .then(function(rescls) {
          let newAvcs = resav.data.data.clauses;
          let cls = rescls.data.data; 
          cls.forEach((c, j) => {
            if(c.clauseStatus !== 'Accepted') {
  
              let newBlocks = c.blocks
              let newClauseObject = c.clauseObject
              if(['sec','ssec','cl','cl1','cl2','cl3','cl4','preamble'].includes(c.clauseCat) && !['proposedDel', 'Deleted'].includes(c.clauseStatus)){ // Clear out pending changes on the blocks
                let oldBlocks = c.blocks
                newBlocks = convertPlainToBlocks(c.plain) // Take plain and convert to blocks array
                handleActivityLog('clause', 'clAgrChangesApproved', c.singleClauseID, '', aa.avv, props.user.displayName, { old: oldBlocks, new: newBlocks })
              } else if(['table'].includes(c.clauseCat) && !['proposedDel', 'Deleted'].includes(c.clauseStatus)) { // Clear out pendnig changes on the table heads/cells
                // Simply run through head and rows by picking up key and ignoring / clearing "change"
                let newHead = [], newRows = [];
                c.clauseObject.head.forEach((h) => { newHead.push({colKey: h.colKey, value: h.value}) })
                c.clauseObject.rows.forEach((r) => {
                  let newCols = [];
                  r.cols.forEach((c) => { newCols.push({ cellKey: c.cellKey, value: c.value }) })
                  newRows.push({rowKey: r.rowKey, cols: newCols})
                })
                newClauseObject = { head: newHead, rows: newRows }
                handleActivityLog('clause', 'clAgrChangesApproved', c.singleClauseID, '', aa.avv, props.user.displayName, {})
              }
  
              let newStatus = c.clauseStatus
              if(['proposedDel', 'Deleted'].includes(c.clauseStatus)) {
                newAvcs = newAvcs.filter((avc) => avc !== c._id); // Remove the clause from Avc
                if(['proposedDel'].includes(c.clauseStatus)) {
                  newStatus = 'Deleted' // set clauseStatus to 'Deleted'
                  handleActivityLog('clause', 'clDeleted', c.singleClauseID, '', aa.avv, props.user.displayName, {})  
                }  

              } else if(c.approvalStatus === 'None' && c.commentStatus === 'None') { // If no comments or approvals pending
                newStatus = 'Accepted' // Clear out status
                handleActivityLog('clause', 'clNewClauseStatus', c.singleClauseID, '', aa.avv, newStatus, {})
              }

              axios.put('/clause/protect/cl/' + c._id, {
                clauseTypes: c.clauseTypes,
                clauseStatus: newStatus,
                commentStatus: c.commentStatus,
                approvalStatus: c.approvalStatus,
                internalWorkflow: c.internalWorkflow,
                title: c.title,
                blocks: newBlocks, 
                plain: c.plain, 
                clauseObject: newClauseObject,
                version: c.version + 1,
                pendingChange: [],
                numberType: c.numberType,
                numberManual: c.numberManual,
                lastUpdateBy: props.user._id,
                lastUpdateDate: new Date().toISOString(),
              })
  
              /*
              props.updateClauseFAF(
                c._id, //clid
                c.clauseTypes, // clauseTypes
                newStatus, // clauseStatus
                c.commentStatus, // commentStatus
                c.approvalStatus, // approvalStatus
                c.internalWorkflow, // internalWorkflow
                c.title, // title
                newBlocks, // blocks
                c.plain, // plain
                newClauseObject, // clauseObject
                c.version + 1, // version
                [], // pendingChange -- Close out pending change (just clear)
                c.numberType, // numberType
                c.numberManual, // numberManual
                props.user._id, // lastUpdateBy
              )*/
            }
  
            // If you hit the last clause of the last av
            if((agrsAvs.length === i + 1) && (cls.length === j + 1)) {
  
              // Mark the AV as edited.
              axios.put('/agr/agrvedited/' + agrsAvs.filter((aa) => aa.isMain)[0].avid, { // updateAgrvMarkAsEdited
                isEdited: true,
                primaryReady: agrsAvs.filter((aa) => aa.isMain)[0].primaryReady, 
                secondaryReady: agrsAvs.filter((aa) => aa.isMain)[0].secondaryReady
              })
              .then(function (res) { // success
                handleActivityLog('agr', 'agrChangesApproved', '', '', props.user.displayName, '', {}) // Define activity Log
                let path = '/core/' + props.module + '/agreement/' + props.rid + "/" + props.aid;
                props.updateAgrvNewClauses(aa.avid, newAvcs, history, path) // Update the AV with new cls and reroute
  
              }).catch(function(err) { console.log("err updating av as edited", err)}) 
            }
          })
        }).catch(function(err) { console.log("err capturing av details", err)})  
      }).catch(function(err) { console.log("err retrieving av", err)})  
    })
  }

  const handleDiffDialogOpen = () => {
    setOpenDiffDialog(true);
  }

  const handleDiffDialogClose = () => {
    setOpenDiffDialog(false);
  }

  const handleAvOldDialogClose = () => {
    setAvOldDialogOpen(null);
  }

  const handleReorderExhibits = () => {
    setReorderExhibits(true)
  }

  const handleDownloadPDF = () => {
    let isAttachment = activeAV.attachment !== undefined && activeAV.attachment !== null && activeAV.attachment !== ''
    let targetName = isAttachment ? 'Attachment' : downloadFileName
    axios.get('/signing/protect/download/' + pdfSignedURL + (isAttachment ? '/attachment' : ''), {responseType: 'arraybuffer'})
    .then(function (res) { // got a msg from the server
      download(res.data, targetName + ".pdf");
    })
    .catch(function (err) { // err in the server request
      console.log("erroring out", err)
    })
  }

  const handleSignFlowMoreOptions = (action) => {
    if(action === 'cancelSignFlow' && agrExec !== undefined && agrExec !== null && agrExec.signatoryRequestID !== undefined) {
      setLoading(true)

      let avids = []
      agrsAvs.forEach((aa) => { avids.push(aa.avid) })

      axios.post('/signing/protect/' + agrExec.signMethod + '/cancelrequest', {
        agrID: agrExec.agrID,
        signatoryRequestID: agrExec.signatoryRequestID,
        lastUpdateBy: props.user._id,
        avid: avs[0]._id, 
        avids: avids,
        curAVOwner: props.user.orgID === activeRel.primaryOrgID ? activeAgr.primaryCptyID : activeAgr.secondaryCptyID, 
        owner:  props.user.orgID === activeRel.primaryOrgID ? "primary" : "secondary",
      })
      .then(function (res) { // got a msg from the server

        setAgrExec(null)
        let emailmsg = `
          <p><b>The sign-off flow for the ` + activeAgr.agrFullName + ` between ` + primaryCpty.legalName + ` and ` + secondaryCpty.legalName + ` has been canceled.</b></p>
          <p>Visit the agreement through Canveo to view it's latest status.</p>`;
        let additionalSigners = activeAgr.signatories.filter((s) => !activeAgr.contributors.some((c) => c.uid === s.uid))
        // Notify collabs and signers that the agreement sign flow has been canceled
        activeAgr.contributors.concat(additionalSigners).forEach((c) => { // send email and notification
          props.createNotification(
            'Execution flow canceled', // notificationType
            'Sign-off flow canceled', // title
            'The ' + activeAgr.agrShortName + ' sign-off flow has been canceled by ' + props.org.shortName + ' (' + props.user.displayName + ')', // description
            '/core/' + cptyModule + '/agreement/' + props.rid + '/' + props.aid, // linkTo 
            false, // isRead
            c.uid, // uid
          )
          
          axios.post('/mail/signcancel', {
            recipient: c.email,
            message: emailmsg,
          })
        })
        handleActivityLog('agr', 'agrExecutionFlowCanceled', '', '', props.user.displayName, '', {}) // logLevel, logType, singleClauseID, ticketID, keyVal1, keyVal2

        // now forward to the same agreement page to reload
        history.push('/core/' + props.module + "/agreement/" + props.rid + "/" + props.aid)

      })
      .catch(function (err) { console.log("err", err) })
    }
  }

  
  const handleSignatureDialog = () => { // Set the HelloSign and Swisscom Signature Flow dialog

    if(agrExec !== undefined && agrExec !== null && agrExec.signMethod === 'swisscom') {

      setSignatureDialogOpen(true);

    } else { // Otherwise proceed with legacy HelloSign flow
      let reqid = signatories.filter((signer) => signer.signer_email_address === props.user.email)[0] !== undefined ?
        signatories.filter((signer) => signer.signer_email_address === props.user.email)[0].signature_id : 'error'

      axios.get('/signing/protect/hellosign/getsignurl/' + reqid)
      .then(function (resu) { // got a msg from the server

        client.open(resu.data.data, {
          clientId: (window.location.href.indexOf("getcanveo.com") > -1 && window.location.href.indexOf("test.getcanveo.com") === -1) || window.location.href.indexOf('canveo.oa.r.appspot.com') > -1 ?
          '86339239d425becf80c153342e833508' : 'ead84d8c52a39fb7fc36614c79ad075a',
          skipDomainVerification: true,
          timeout: 300000
        })

        client.on('sign', (data) => {
          // Inform all contributors
          activeAgr.contributors.forEach((c) => {
            props.createNotification(
            'Agreement Signed', // notificationType
            'Agreement Signed', // title
            'The ' + activeAgr.agrShortName + ' has been signed by ' + props.org.shortName + ' (' + props.user.displayName + ')', // description
            '/core/' + cptyModule + '/relationship/' + props.rid + '/overview', // linkTo 
            false, // isRead
            c.uid, // uid
            )
          })
          client.close();
          // The document has been signed - show "wait snackbar" on the relationship page
          let path = '/core/' + props.module + '/relationship/' + props.rid + '/overview/1';        
          if(props.user.role === 'Counterparty') { path = "/core/counterparty/relationship/" + props.rid + "/cptydone/1"; } 
          history.push(path)
        });
      }).catch(function (err) { console.log("An error in triggering the Signature Dialog", err) })
    }
  }

  /*
  const handleSign = () => {
    console.log("triggering the signing from the front-end")
    axios.post('/signing/protect/swisscom/sign', {
      filename: agrExec.pdfUnsignedURL
    })
    .then((res) => {

    }).catch(function (err) { console.log("An error in completing the signing", err) })
  }*/

  // Update here and inside TASKS -- TODO: Break out into separate component
  const handleActivityLog = (logLevel, logType, singleClauseID, ticketID, keyVal1, keyVal2, change) => {
    props.createActivityLog(
      logLevel, // logLevel, 
      logType, // logType, 
      (logType === 'agrNewName' ? 'Agreement title changed by ' + props.user.displayName :
       logType === 'agrNewStatus' ? 'Agreement status changed to: ' + keyVal1 : 
       logType === 'agrExecutionFlowStarted' ? 'Agreement sign-off flow started by ' + props.user.displayName : 
       logType === 'agrExecutionFlowCanceled' ? 'Agreement sign-off flow canceled by ' + props.user.displayName :
       logType === 'agrMarkedReadyForSignature' ? 'Agreement sent to counterparty and marked as ready for signature by ' + props.user.displayName :
       logType === 'agrSentToCounterparty' ? 'Agreement sent to counterparty by ' + props.user.displayName :
       logType === 'agrRequestRejected' ? 'Agreement request declined by ' + props.user.displayName :
       logType === 'agrRequestAccepted' ? 'Agreement request accepted by ' + props.user.displayName :
       logType === 'agrInternalReviewRequested' ? 'Agreement sent to ' + keyVal2 + ' internal reviewer' + (keyVal2 === 1 ? '' : 's') + ' by ' + props.user.displayName :
       logType === 'agrInternalReviewStarted' ? 'Agreement internal review started by ' + props.user.displayName :
       logType === 'agrInternalReviewCompleted' ? 'Agreement internal review completed by ' + props.user.displayName :
       logType === 'agrInternalReviewCanceled' ? 'Agreement internal review from ' + keyVal2 + ' canceled by ' + props.user.displayName :
       logType === 'agrChangesApproved' ? "All proposed agreement changes were approved by " + props.user.displayName :
       logType === 'agrVersionRestored' ? "The Agreement has been restored from earlier version " + userFriendlyAVV(keyVal2) + " by " + props.user.displayName :
       logType === 'agrRetrieved' ? 'The Agreement has been retrieved by ' + props.user.displayName :
       logType === 'clProposedDelete' ? 'Clause proposed for deletion by ' + props.user.displayName :
       logType === 'clDeleted' ? 'Clause deleted by ' + props.user.displayName + (activeAgrs.filter((a) => a._id === activeAgr.parentAID)[0] !== undefined ? (' inside the ' + activeAgr.agrFullName) : '') :
       logType === 'pdfChanged' ? 'PDF Attachment changed by ' + props.user.displayName :
       logType === 'clDeleteCanceled' ? 'Clause deletion canceled by ' + props.user.displayName :
       logType === 'clInserted' ? 'Clause inserted by ' + props.user.displayName :
       logType === 'clNewClauseStatus' ? 'Clause status changed to ' + keyVal2  + ' by ' + props.user.displayName :
       logType === 'clNewCommentStatus' ? 'Clause comment status changed to ' + keyVal2  + ' by ' + props.user.displayName :
       logType === 'clNewComment' ? 'A new comment was inserted for this clause by ' + props.user.displayName :
       logType === 'clNewApprovalStatus' ? 'Clause approval status changed by ' + props.user.displayName :
       logType === 'clNewVersion' ? 'Clause edited by ' + props.user.displayName  : 
       logType === 'clNewTitle' ? 'Clause title edited by ' + props.user.displayName :
       logType === 'clTableUpdate' ? 'Table edited by ' + props.user.displayName :
       /*: ' + (
                      keyVal2 === 'acceptTable' ? 'New Table was accepted' :
                      keyVal2 === 'headUpdate' ? 'Column head updated' :
                      keyVal2 === 'cellUpdate' ? 'Cell updated' :
                      keyVal2 === 'insertRow' ? 'A new row was inserted' :
                      keyVal2 === 'insertCol' ? 'A new column was inserted' :
                      keyVal2 === 'deleteRow' ? 'A row was deleted' :
                      keyVal2 === 'deleteCol' ? 'A column was deleted' : 'The table was updated') :*/
       logType === 'clAgrChangesApproved' ? 'All pending clause changes were approved by ' + props.user.displayName :
       logType === 'clMoved' ? 'Clause moved by ' + props.user.displayName :
       logType === 'clNumberChange' ? 'Clause number changed by ' + props.user.displayName :
       logType === 'clRestored' ? 'Clause has been restored to an older version by ' + props.user.displayName :
                   'The activity type was not recognized' ), // logDesc, 
      props.user.orgID, // orgID
      props.user.orgID === activeRel.primaryOrgID ? activeAgr.primaryCptyID :
        props.user.orgID === activeRel.secondaryOrgID ? activeAgr.secondaryCptyID : 'unknown', // entID, 
      props.rid, // relID, 
      ['agr','rel'].includes(logLevel) && activeAgr.parentAID !== undefined && activeAgr.parentAID !== null && activeAgr.parentAID !== '' ? 
        activeAgr.parentAID : activeAgr._id, // agrID
      singleClauseID, // singleClauseID, 
      ticketID, // ticketID, 
      props.user._id, // creationBy, 
      ['agrInternalReviewRequested', 'agrInternalReviewStarted', 'agrInternalReviewCompleted', 'agrInternalReviewCanceled'].includes(logType) ||
        ((activeAgr.agrStatus === 'Draft' || 
        (activeAgrs.filter((a) => a._id === activeAgr.parentAID)[0] !== undefined && activeAgrs.filter((a) => a._id === activeAgr.parentAID)[0].agrStatus === 'Draft')) &&
        ['agrNewName', 'agrRequestRejected', 'agrChangesApproved', 'agrVersionRestored', 'clProposedDelete', 'pdfChanged', 'clDeleted', 'clDeleteCanceled', 'clInserted', 'clNewClauseStatus', 
         'clNewCommentStatus', 'clNewApprovalStatus', 'clNewVersion', 'clNewTitle', 'clAgrChangesApproved', 'clMoved', 'clNumberChange', 'clRestored', 'clTableUpdate'].includes(logType)) ? // These logTypes are INTERNAL while in DRAFT
        true : false, // isInternal,
      keyVal1, // keyVal1, 
      keyVal2, // keyVal2
      change, // change
    )    
  }

  const agrHead = () => {
    return (
      <>
        {
        activeRel._id !== undefined && ![undefined, null].includes(primaryCpty) && ![undefined, null].includes(secondaryCpty) ?
        <>
          {// CONDITIONAL RENDERING OF KEY COMPONENTS FOR THE AGR HEAD STARTS HERE
          // No content for the agreement
          clauses.length === 0 && 
          (agrExec === undefined || agrExec === null || !["offline","historical"].includes(agrExec.signatoryRequestID)) && 
          (activeAV === undefined || activeAV === null || activeAV.attachment === undefined || activeAV.attachment === null || activeAV.attachment === '') ?
          <Box mt={5} mb={5} align="center">
            <Typography variant="h6" align="center">We're still working on this agreement...</Typography>
            <Typography variant="body1" align="center">Try refreshing in a few minutes.</Typography>
            {activeRel._id !== undefined && props.user.role !== 'Counterparty' ?
              <Box mt={3}>
                <Button onClick={handleRefresh} color="primary">Refresh the Agreement</Button>
                <Button onClick={handleArchiveAndRecreate} color="primary">Archive</Button></Box> 
            : ''}
          </Box>
          : // The agreement has been signed offline
          agrExec !== undefined && agrExec !== null && ["offline","historical"].includes(agrExec.signatoryRequestID) && clauses.length > 0 ?
          <>
          <Box mt={3} mb={2} className={classes.signOffBox}>
            <Box mb={1} align="center">
              <img src={pdf} onClick={handleDownloadPDF} className={classes.downloadPDF} alt="Download PDF" />
            </Box>
            <Typography variant="subtitle1" align="center">
                This agreement has been signed outside of Canveo.
            </Typography>
            <Typography variant="body2" align="center" gutterBottom>
                It's possible that the below digitized version is not identical to the signed version
            </Typography>
          </Box>
          </>
          : // The Agreement is in Execution Stage
          ['Execution'].includes(activeAgr.agrStatus) ? 
          <Box mt={3} mb={2} className={classes.signOffBox}>
            <Grid container direction="row">
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
              <Box mb={3} align="center"><FontAwesomeIcon icon={faInfoCircle} className={classes.warnIcon} /></Box>
              </Grid>
              <Grid item xs={1}>
                {activeAgr.agrStatus === 'Execution' && props.user.role !== 'Counterparty' ?
                <MenuMoreOptions
                  level={"signFlow"}
                  clid={null}
                  parentAction={handleSignFlowMoreOptions}
                  user={null}
                />
                : '' }
              </Grid>
            </Grid>

            {// Agreement is in Execution stage but the agrSigned variable is still loading
            activeAgr.agrStatus === 'Execution' && signURL === null && signatories.length === 0 ?
              <Box mt={2} mb={2} align="center">
                Loading the signature flow...&nbsp;&nbsp;<CircularProgress size={20} disableShrink />
              </Box>
            :// Agreement is in Execution and you know who needs to sign
            activeAgr.agrStatus === 'Execution' && signatories.length > 0 ?
              <>
              <Box mb={1}>
              <Typography variant="subtitle1" align="center">
                This agreement requires signatures from:
              </Typography>
              </Box>
              <List>
              {signatories.map((s, i) => (

                <ListItem key={i} style={{margin: '0px', padding: '0px 20px 0px 20px'}}>
                  <ListItemText 
                  primary={
                    <>
                    <span className={classes.signName}>
                      {s.order !== undefined && s.order !== null && s.order !== -1 ? ((s.order + 1) + ". ") : ''}
                      {
                      ((s.signer_identity_data !== undefined && s.signer_identity_data !== null && s.signer_identity_data.email_address !== undefined) || s.signer_email_address.includes('@')) &&
                      activeAgr !== undefined && activeAgr !== null && activeAgr.signatories.filter((sig) => sig.email === s.signer_email_address || (s.signer_identity_data !== undefined && s.signer_identity_data !== null && sig.email === s.signer_identity_data.email_address))[0] !== undefined &&
                      primaryCpty !== null && secondaryCpty !== null ?
                          <>
                          <span className={classes.bold} style={{marginRight: '8px'}}>
                            {s.signer_name !== undefined && s.signer_name !== null ? // helloSign
                                trunc(s.signer_name, 23) : // helloSign
                                trunc(
                                (s.signer_identity_data !== undefined && s.signer_identity_data !== null && s.signer_identity_data.first_name !== undefined && s.signer_identity_data.first_name !== null ?
                                    s.signer_identity_data.first_name :
                                s.first_name !== undefined && s.first_name !== null ?
                                    s.first_name : '') + ' ' + 
                                (s.signer_identity_data !== undefined && s.signer_identity_data !== null && s.signer_identity_data.last_name !== undefined && s.signer_identity_data.last_name !== null ?
                                    s.signer_identity_data.last_name :
                                s.last_name !== undefined && s.last_name !== null ?
                                    s.last_name : ''), 23)}
                          </span>
                          ({trunc(
                           (activeAgr.signatories.filter((sig) => sig.email === s.signer_email_address || (s.signer_identity_data !== undefined && s.signer_identity_data !== null && sig.email === s.signer_identity_data.email_address))[0].entityName !== undefined &&
                            activeAgr.signatories.filter((sig) => sig.email === s.signer_email_address || (s.signer_identity_data !== undefined && s.signer_identity_data !== null && sig.email === s.signer_identity_data.email_address))[0].entityName !== null &&
                            activeAgr.signatories.filter((sig) => sig.email === s.signer_email_address || (s.signer_identity_data !== undefined && s.signer_identity_data !== null && sig.email === s.signer_identity_data.email_address))[0].entityName.length > 2 ?
                              activeAgr.signatories.filter((sig) => sig.email === s.signer_email_address || (s.signer_identity_data !== undefined && s.signer_identity_data !== null && sig.email === s.signer_identity_data.email_address))[0].entityName :
                            activeAgr.signatories.filter((sig) => sig.email === s.signer_email_address || (s.signer_identity_data !== undefined && s.signer_identity_data !== null && sig.email === s.signer_identity_data.email_address))[0].entity === "primary" ?
                              primaryCpty.legalName :
                            activeAgr.signatories.filter((sig) => sig.email === s.signer_email_address || (s.signer_identity_data !== undefined && s.signer_identity_data !== null && sig.email === s.signer_identity_data.email_address))[0].entity === "secondary" ?
                              secondaryCpty.legalName : 
                              'Unknown legal entity'), 24)})
                          </> :
                      s.signer_identity_data !== undefined && s.signer_identity_data !== null && s.signer_identity_data.first_name !== undefined && s.signer_identity_data.last_name !== undefined ?
                          <span className={classes.bold}>{s.signer_identity_data.first_name + ' ' + s.signer_identity_data.last_name}</span> :                  
                          'unknown user'}
                    </span> 
                    {
                    s.status_code === 'SIGNED' ?
                      <span className={classes.signatureSigned}>Agreement Signed&nbsp;&nbsp;<FontAwesomeIcon icon={faSignature} /></span> :
                    s.status_code === 'DECLINED' ?
                      <span className={classes.signatureDeclined}>Request Declined&nbsp;&nbsp;<FontAwesomeIcon icon={faUserTimes} /></span> :
                    s.status_code === 'OPEN' && (indexAssignedSigner === null || indexAssignedSigner === s.order) ? 
                      <span className={classes.signaturePending}>Pending Signature&nbsp;&nbsp;<FontAwesomeIcon icon={faUserEdit} /></span> : 
                    s.status_code === 'OPEN' && indexAssignedSigner !== null && indexAssignedSigner !== s.order ? 
                      <span className={classes.signatureAwaiting}>Awaiting Assignment&nbsp;&nbsp;<FontAwesomeIcon icon={faHourglassHalf} /></span> :
                    // start of HelloSign  
                    (s.signed_at === null && (indexAssignedSigner === null || indexAssignedSigner === s.order)) ? 
                      <span className={classes.signaturePending}>Pending Signature&nbsp;&nbsp;<FontAwesomeIcon icon={faUserEdit} /></span> : 
                    (s.signed_at !== undefined && s.signed_at === null) ? 
                      <span className={classes.signatureAwaiting}>Awaiting Assignment&nbsp;&nbsp;<FontAwesomeIcon icon={faHourglassHalf} /></span> : 
                    (s.signed_at !== undefined && s.signed_at !== null) ? 
                      <span className={classes.signatureSigned}>Agreement Signed&nbsp;&nbsp;<FontAwesomeIcon icon={faSignature} /></span> :
                    // end of HelloSign
                      <span className={classes.signatureAwaiting}>Not signed&nbsp;&nbsp;<FontAwesomeIcon icon={faExclamationTriangle} /></span>}
                    </>
                  }  />
                </ListItem>

              ))}
              </List>
              
              {// The Swisscom / HelloSign Sign Button
              activeAgr.agrStatus === 'Execution' && signURL !== null && !['skribble'].includes(agrExec.signMethod) && 
              !signatories.some((s) => s.status_code === 'DECLINED') && 
              signatories.filter((s) => s.signer_email_address === props.user.email.toLowerCase() && s.signed_at === null)[0] !== undefined 
              /* HelloSign and Swisscom */ ?
                <Box align="center" mt={2}>
                  <Button variant="contained" disableElevation fullWidth color="primary" onClick={handleSignatureDialog}>
                    Sign&nbsp;&nbsp;<FontAwesomeIcon icon={faSignature} />
                  </Button>
                </Box>
              :activeAgr.agrStatus === 'Execution' && signURL !== null && agrExec.signMethod === 'swisscom' && signatories.some((s) => s.status_code === 'DECLINED')  ?
                <Box align="center" mt={2}>
                  TODO DECLINED
                </Box>
              :activeAgr.agrStatus === 'Execution' && signURL !== null && !signatories.some((s) => s.status_code === 'DECLINED') && 
              ['skribble'].includes(agrExec.signMethod) ?
              // The Skribble Sign button
                <>
                <Box align="center" mt={2}>
                  <Button variant="contained" disableElevation fullWidth color="primary"
                  onClick={e => window.location.assign(signURL + "?exitURL=" + encodeURIComponent(window.location.origin + 
                    (props.user.role === 'Counterparty' ?
                      '/core/counterparty/relationship/' + props.rid + '/cptydone/1' : 
                      '/core/' + props.module + '/relationship/' + props.rid + '/overview/1')
                    ) + '&redirectTimeout=10&hidedownload=true')}>
                    Sign&nbsp;&nbsp;<FontAwesomeIcon icon={faSignature} />
                  </Button>
                </Box>
                </>
              :activeAgr.agrStatus === 'Execution' && signURL !== null && signatories.some((s) => s.status_code === 'DECLINED') ?
              <Box mt={2} align="center">
              <Typography>The request was declined</Typography>
              {props.user.role === 'Counterparty' ? 
              <Typography>Ask your counterparty to cancel the request</Typography> : 
              <Button color="primary" variant="text" onClick={e => handleSignFlowMoreOptions('cancelSignFlow')}><FontAwesomeIcon icon={faBan} />&nbsp;&nbsp;Cancel Signature Request</Button>}
              </Box>
              : '' }

              </>              
            : // Fallback
              <Typography variant="subtitle1" align="center">A signature is pending for this agreement</Typography>
            }
          </Box>
          : ''}

        {activeAgrs !== null && activeAgrs.length > 1 ? // There are Exhibits - show the list

        <AgreementExhList 
          variant="agrScreen" 
          user={props.user}
          module={props.module}
          parentAgrStatus={parentAgrStatus}
          agrs={activeAgrs}
          agr={activeAgr}
          avsPenTurnDetail={avsPenTurnDetail} 
          edited={edited}
          toggleFilter={toggleFilter}
          click={handlePushToAgr}
          updateAgrNames={props.updateAgrNames}
          handleActivityLog={handleActivityLog}
        />

        : '' }

        </>
        : '' }
      </>
    )
  }

  // *** START OF AGREEMENT COMPONENT ***
  return(
    <div className={classes.root}>

      <Header 
        module={props.module}
        pageType="Agreement"
        active="Relationships"
        crumbs={[
          {name: "Home", to: "/core/" + props.module + "/dashboard"},
          ...props.user !== undefined && props.user.role !== undefined && props.user.role !== 'Counterparty' && !props.user.role.startsWith('ClientFor_') ? [{name: 'Relationships', to: '/core/' + props.module + '/relationships'}] : [],
          {name: trunc((activeRel !== null && activeRel._id !== undefined && props.org._id === activeRel.primaryOrgID ? 
                    activeRel.name.substring(activeRel.name.indexOf('|') + 2) :
                  activeRel !== null && activeRel._id !== undefined && props.org._id === activeRel.secondaryOrgID ?
                    activeRel.name.substring(0, activeRel.name.indexOf('|') - 1) : 'Loading...'), 12), 
             to: "/core/" + props.module + "/relationship/" + props.rid + "/overview"},
          {name: activeAgr !== null ? activeAgr.agrShortName : "" , to: "active"}]}
        user={props.user}
        org={props.org}
        modules={
          props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
            ['sales','procurement','partnerships','people','corporate'] : 
          props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
            props.org.modules : [] }
        notifications={props.notifications}
        withSubHead={true}
        markNotificationAsRead={props.markNotificationAsRead}
        markAllNotificationsAsRead={props.markAllNotificationsAsRead}
        className={classes.header}
        whiteLabel={whiteLabel}
        whiteLabelLoading={whiteLabelLoading}
        
        exitOnly={reorderExhibits}
        editMode={activeAV !== null ? activeAV.editMode : null}
        exitClick={e => setReorderExhibits(false)}
        reorderExhibits={activeAgrs !== null && activeAgrs.length > 2 ? handleReorderExhibits : null}
        agrStatus={activeAgr !== null ? activeAgr.agrStatus : ''}
        parentAgrStatus={parentAgrStatus}
        filename={downloadFileName}
        toggleFilter={toggleFilter}
        //handleAgrActOpen={handleAgrActOpen}
        handleToggleFilter={handleToggleFilter}
        handleDiffDialogOpen={handleDiffDialogOpen}
        handleAcceptEverything={handleAcceptEverything}
        parentCallToDeactivate={deactivateAgreement}
        plainMode={plainMode}
        relDeactive={activeRel !== null ? activeRel.deactive : true}
        laterVersionOwnedByCpty={laterVersionOwnedByCpty}
        cptyName={cptyName}
      />

      {!reorderExhibits ?
      <ReadProgressBar attachTo={readRef} />
      :''}

      <Container maxWidth={false} className={classes.content} ref={readRef}>

        <Container maxWidth="lg">
        <Grid container direction="column" justify="flex-start" alignItems="center" className={classes.outerFlexGrid}
        style={reorderExhibits ? {paddingTop: '80px'} : {}}>

        {fetchError !== null ?
        <>
          <Typography align="center">There was an error retrieving the data.</Typography>
          <Typography align="center">Please try again or contact Canveo Support if the issue persists.</Typography>
        </>
        :
        deleting || acceptingEverything ? 
        <>
        <Box mt={5} mb={5} align="center">
          <FontAwesomeIcon icon={faExclamationTriangle} className={acceptingEverything ? classes.warnIcon2 : classes.deleteIcon} />
          <Typography variant="h6" style={{fontWeight: '700'}}>Are you sure you want to {acceptingEverything ? "accept all pending changes in the entire agreement": ("delete this " + (activeAgr.agrStatus === 'Exhibit' ? 'exhibit' : 'agreement'))}?</Typography>
          {!acceptingEverything ?
          <Typography variant="h6" style={{fontWeight: '300'}}>{activeAgr.agrFullName}</Typography>: 
          <Typography variant="body1" color="textSecondary">Confirming will clean up all open edits in the agreement. Comments and Approvals will not be updated.</Typography>}

          {usersEditing.length > 0 ? 
          <Box mt={3} mb={3} align="center">
              <Typography color="error" variant="subtitle2">{usersEditing.join(' and ') + (usersEditing.length === 1 ? " is " : " are ") + "currently editing this agreement."}</Typography>
              <Typography color="error" variant="subtitle2">Continuing will disregard the latest edits.</Typography>
          </Box>
          : '' }

          <Box mt={5}>
            <Button variant="contained" disableElevation className={acceptingEverything ? classes.acceptButton : classes.deleteButton} onClick={acceptingEverything ? e => handleConfirmAcceptEverything() : e => handleConfirmDeactivateAgreement(true)}>Confirm</Button>
            <Button variant="text" onClick={acceptingEverything ? e => handleCancelAcceptEverything() : e => setDeleting(false)}>Cancel</Button>
          </Box>
        </Box>
        </>
        :
        reorderExhibits && activeAgr !== null && activeAgrs !== null ?
        <Box mt={0} mb={5} align="center">
          <AgreementStructure
            agrShortName={activeAgr !== null && activeAgrs !== null && activeAgrs.filter((a) => a._id === activeAgr.parentAID)[0] !== undefined && activeAgrs.filter((a) => a._id === activeAgr.parentAID)[0].agrShortName !== undefined ?
              activeAgrs.filter((a) => a._id === activeAgr.parentAID)[0].agrShortName : activeAgr.agrShortName}
            agrFullName={activeAgr !== null && activeAgrs !== null && activeAgrs.filter((a) => a._id === activeAgr.parentAID)[0] !== undefined && activeAgrs.filter((a) => a._id === activeAgr.parentAID)[0].agrFullName !== undefined ?
              activeAgrs.filter((a) => a._id === activeAgr.parentAID)[0].agrFullName : activeAgr.agrFullName}
            activeAgrs={activeAgrs}
            reordering={true}
            parentCallToClose={e => setReorderExhibits(false)}
            parentCallToConfirm={handleNewExhibitOrder}
          />
        </Box>
        :
        // CHECK IF DONE WITH INITIAL LOADING OF AGREEMENT CONTENT
        activeRel !== null && activeAgr !== null && activeAV !== null && avClauses !== null && clauses !== null &&
        laterVersionOwnedByCpty !== null && activeAgrs !== null && tickets !== null && !loading && plainMode !== null &&
        clauseActivityLog !== null &&
        !(activeAgr.agrStatus === 'Execution' && signURL === null && signatories.length === 0) ?
        <>
          <AgreementEditor
            templating={false} // whether templating or negotiating
            agrHead={agrHead}
            viewablePDF={viewablePDF}
            clauses={clauses}
            avs={avs}
            avClauses={avClauses}
            plainMode={plainMode}
            edited={edited}
            signable={
              !edited && /*activeAgr.agrStatus !== 'Exhibit' &&*/ activeRel !== undefined && activeRel !== null && 
              mainBodyAV !== undefined && mainBodyAV !== null && !mainBodyAV.isEdited &&
              ((mainBodyAV.primaryReady && activeRel.secondaryOrgID === props.user.orgID) || 
               (mainBodyAV.secondaryReady && activeRel.primaryOrgID === props.user.orgID)) ? 
              true : false}
            laterVersionOwnedByCpty={laterVersionOwnedByCpty}
            creationDateOfLatestAV={creationDateOfLatestAV}
            toggleFilter={toggleFilter}
            clauseActivityLog={clauseActivityLog}
            user={props.user}
            org={props.org}
            users={props.users}
            rid={props.rid}
            rel={props.rels !== undefined && props.rels.filter((r) => r._id === props.rid)[0] !== undefined ? 
              props.rels.filter((r) => r._id ===  props.rid)[0] : null}
            agrID={props.aid}
            clid={props.clid}
            agrStatus={activeAgr.agrStatus}
            agrFullName={activeAgrs !== null && activeAgrs.length > 1 && activeAgrs.filter((c) => c._id === activeAgr.parentAID)[0] ? // Parent or Self Agreement name
              activeAgrs.filter((c) => c._id === activeAgr.parentAID)[0].agrFullName :  activeAgr.agrFullName}
            agrShortName={activeAgrs !== null && activeAgrs.length > 1 && activeAgrs.filter((c) => c._id === activeAgr.parentAID)[0] ? // Parent or Self Agreement name
                activeAgrs.filter((c) => c._id === activeAgr.parentAID)[0].agrShortName :  activeAgr.agrShortName}
            agr={activeAgr}
            agrsAvs={agrsAvs}
            activeAgrs={activeAgrs}
            avsPenTurnDetail={avsPenTurnDetail}
            cptyModule={cptyModule}
            avID={activeAV._id}
            avv={activeAV.version}
            avAttachment={activeAV.attachment}
            editMode={activeAV.editMode !== undefined && activeAV.editMode !== null ? activeAV.editMode : 'full'}
            avPrimaryReady={activeAV.primaryReady}
            avSecondaryReady={activeAV.secondaryReady}
            oatID={activeAgr._id}
            entID={activeRel.primaryOrgID === props.user.orgID ? activeAgr.primaryCptyID : activeAgr.secondaryCptyID}
            module={props.module}
            parentAID={activeAgr.parentAID}
            parentAgrFullName={activeAgr !== null && activeAgrs !== null && activeAgrs.filter((a) => a._id === activeAgr.parentAID)[0] !== undefined && activeAgrs.filter((a) => a._id === activeAgr.parentAID)[0].agrFullName !== undefined ?
              activeAgrs.filter((a) => a._id === activeAgr.parentAID)[0].agrFullName : 'Agreement'}
            parentAgrStatus={parentAgrStatus}
            tickets={tickets}
            approvalTicketsExist={tickets !== null && tickets.filter((t) => t.ticketType === 'Approval') !== undefined
                                                && tickets.filter((t) => t.ticketType === 'Approval').length > 0}
            approvalTicketsBadge={tickets !== null && tickets.filter((t) => t.ticketType === 'Approval' && [t.assignee, t.creationBy].includes(props.user._id) && (props.user.lastLoginDate === undefined || props.user.lastLoginDate === '' || props.user.lastLoginDate < t.lastUpdateDate) && t.lastUpdateBy !== props.user._id) !== undefined
                                                && tickets.filter((t) => t.ticketType === 'Approval' && [t.assignee, t.creationBy].includes(props.user._id) && (props.user.lastLoginDate === undefined || props.user.lastLoginDate === '' || props.user.lastLoginDate < t.lastUpdateDate) && t.lastUpdateBy !== props.user._id).length > 0}
            reviewTicketsExist={tickets !== null && tickets.filter((t) => t.ticketType === 'AgrReviewInternal') !== undefined
                                                 && tickets.filter((t) => t.ticketType === 'AgrReviewInternal').length > 0}
            reviewTicketsBadge={tickets !== null && tickets.filter((t) => t.ticketType === 'AgrReviewInternal' && [t.assignee, t.creationBy].includes(props.user._id) && (props.user.lastLoginDate === undefined || props.user.lastLoginDate === '' || props.user.lastLoginDate < t.lastUpdateDate) && t.lastUpdateBy !== props.user._id) !== undefined
                                                  && tickets.filter((t) => t.ticketType === 'AgrReviewInternal' && [t.assignee, t.creationBy].includes(props.user._id) && (props.user.lastLoginDate === undefined || props.user.lastLoginDate === '' || props.user.lastLoginDate < t.lastUpdateDate) && t.lastUpdateBy !== props.user._id).length > 0}
            updateClauseContentFAF={props.updateClauseContentFAF}
            updateClauseStatus={props.updateClauseStatus}
            updatePendingChange={props.updatePendingChange}
            updateClauseNumbering={props.updateClauseNumbering}
            updateAgrvNewClauses={props.updateAgrvNewClauses}
            updateAgrvRemoveClause={props.updateAgrvRemoveClause}
            updateAgrNames={props.updateAgrNames}
            updateTicket={props.updateTicket}
            lastUpdateTicket={props.lastUpdateTicket}
            createComment={props.createComment}
            updateComment={props.updateComment}
            updateAidForUserAgrIDs={props.updateAidForUserAgrIDs}
            updateAgrUsers={props.updateAgrUsers}
            createNotification={props.createNotification}
            createSmartField={props.createSmartField}
            updateSmartField={props.updateSmartField}
            fetchUsersByOrgID={props.fetchUsersByOrgID}
            openDiffDialog={openDiffDialog}
            handleDiffDialogClose={handleDiffDialogClose}
            curCpty={activeRel.primaryOrgID === props.user.orgID ? "primary" : "secondary"}
            primaryCpty={primaryCpty}
            secondaryCpty={secondaryCpty}
            parentCall={handleChildInformation}
            handleActivityLog={handleActivityLog}
            handleToggleFilter={handleToggleFilter}
            handleAcceptEverything={handleAcceptEverything}
            handleDownloadPDF={handleDownloadPDF}
            handleAgrActOpen={handleAgrActOpen}
            handleReorderExhibits={handleReorderExhibits}
            deactivateAgreement={deactivateAgreement}
            subagLink={activeAgr !== null && activeRel !== null && activeRel._id !== undefined && activeRel.primaryOrgID === props.org._id ?
                          ("/core/" + props.module + "/new/" + activeAgr.secondaryCptyID + "/" + (activeAgr.parentAID !== undefined && activeAgr.parentAID !== null && activeAgr.parentAID !== '' ? activeAgr.parentAID : props.aid)) : 
                      activeAgr !== null && activeRel !== null && activeRel._id !== undefined && activeRel.secondaryOrgID === props.org._id ?
                          ("/core/" + props.module + "/new/" + activeAgr.primaryCptyID + "/" + (activeAgr.parentAID !== undefined && activeAgr.parentAID !== null && activeAgr.parentAID !== '' ? activeAgr.parentAID : props.aid)) : '/core/' + props.module + '/new'}
            saveTemplateLink={activeAgr !== null ? '/core/legal/templates/' + props.module + '/copy-agr/' + activeAgr._id : '/core/' + props.module + "/dashboard"}
            timeBase={timeBase}
            alreadyOpened={alreadyOpened}
            pushTime={props.pushTime}
            //createClauseTypeClassification={props.createClauseTypeClassification}
            handleSmartModeToggle={e => props.defineSmartMode({templating: props.userSession.smartMode.templating, agreement: !props.userSession.smartMode.agreement})} 
            smartMode={
              props.user !== undefined && props.user.role !== undefined && props.user.role === 'Counterparty' ? false :
              props.org !== undefined && props.org.clientType !== undefined && !['custE', 'legalOrg'].includes(props.org.clientType) ? false :
              props.userSession.smartMode.agreement}
            smartFields={smartFields}
            whiteLabel={whiteLabel}
            whiteLabelLoading={whiteLabelLoading}
          />

        </>
        : // STILL DOING INITIAL LOADING OF AGREEMENT CONTENT

        <Grid container alignItems="center" justify="center">

          <SkeletonCustom type="agreement" />

        </Grid>
        }
      
        </Grid>
        </Container>

      </Container>

      {/*avs !== null ?
      <DialogAVDiff
        open={openDiffDialog}
        aid={props.aid}
        agrsAvs={agrsAvs}
        avs={avs}
        isPrimary={activeRel !== undefined && activeRel !== null && props.user !== undefined && props.user !== null && props.user.orgID === activeRel.primaryOrgID}
        primaryCpty={primaryCpty}
        secondaryCpty={secondaryCpty}
        firstav={avs.filter((agrv) => (agrv.owner === "primary" && activeRel.primaryOrgID === props.user.orgID) || 
                                      (agrv.owner === "secondary" && activeRel.secondaryOrgID === props.user.orgID))[
                                        avs.filter((agrv) => (agrv.owner === "primary" && activeRel.primaryOrgID === props.user.orgID) || 
                                                (agrv.owner === "secondary" && activeRel.secondaryOrgID === props.user.orgID)).length -1]}
        prevav={avs.filter((agrv) => (agrv.owner === "primary" && activeRel.primaryOrgID === props.user.orgID) || 
                                  (agrv.owner === "secondary" && activeRel.secondaryOrgID === props.user.orgID))[1]}
        curav={activeAV}
        handleDiffDialogClose={handleDiffDialogClose}
      />
      : ''*/}

      <DialogAVOld
        open={Boolean(avOldDialogOpen)}
        handleAvOldDialogClose={handleAvOldDialogClose}
        agrTypes={props.agrTypes}
        createAgrVersion={props.createAgrVersion}
        latestAV={activeAV}
        rel={props.rels !== undefined && props.rels.filter((r) => r._id === props.rid)[0] !== undefined ? 
          props.rels.filter((r) => r._id ===  props.rid)[0] : null}
        avv={avOldDialogOpen}
        agr={activeAgr}
        agrsAvs={agrsAvs}
        user={props.user}
        org={props.org}
        module={props.module}
        updateAgr={props.updateAgr}
        updateAgrStatus={props.updateAgrStatus}
        createAgrVersionWithImport={props.createAgrVersionWithImport}
        handleActivityLog={handleActivityLog}
        disableRestore={plainMode || laterVersionOwnedByCpty}
      />

      <DialogSignature
        open={Boolean(signatureDialogOpen)}
        handleSignatureDialogClose={e => setSignatureDialogOpen(false)}
        signatoryRequestID={agrExec !== undefined && agrExec !== null ? agrExec.signatoryRequestID : ''}
        pdfUnsignedURL={agrExec !== undefined && agrExec !== null ? agrExec.pdfUnsignedURL : null}
        signatories={agrExec !== undefined && agrExec !== null ? agrExec.signatories : []}
        downloadFileName={downloadFileName}
        quality={agrExec}
        user={props.user}
        module={props.module}
        rid={props.rid}
      />

      <DialogAgrAction
        open={Boolean(agrActTrigger)}
        parentCallToClose={handleAgrActClose}
        user={props.user}
        users={props.users}
        org={props.org}
        primaryCpty={primaryCpty}
        secondaryCpty={secondaryCpty}
        userIsPrimary={props.user !== undefined && props.user !== null && primaryCpty !== null && 
          ((['Subsidiary','LegalClient'].includes(primaryCpty.entityType) && primaryCpty.orgID === props.user.orgID) || 
           (!['Subsidiary','LegalClient'].includes(primaryCpty.entityType) && primaryCpty.parentOrgID === props.user.orgID))}
        rel={activeRel}
        agr={activeAgrs !== undefined && activeAgrs !== null && activeAgrs.filter((a) => a.parentAID === "")[0] !== undefined ? 
          activeAgrs.filter((a) => a.parentAID === "")[0] : {}} // Always submit the Main Body
        agrs={activeAgrs}
        agrTypes={props.agrTypes}
        module={props.module}
        cptyModule={cptyModule}
        updateAgrUsers={props.updateAgrUsers}
        updateAidForUserAgrIDs={props.updateAidForUserAgrIDs}
        edited={edited}
        trigger={agrActTrigger}
        activeAgrVersion={/*activeAV*/mainBodyAV}
        downloadFileName={downloadFileName}
        editMode={activeAV !== null && activeAV.editMode !== undefined && activeAV.editMode !== null ? activeAV.editMode : 'full'}
        createAgrVersion={props.createAgrVersion}
        createAgrVersionWithImport={props.createAgrVersionWithImport}
        updateAgrvMarkAsEdited={props.updateAgrvMarkAsEdited}
        createComment={props.createComment}
        createNotification={props.createNotification}
        updateAgr={props.updateAgr}
        updateComment={props.updateComment}
        handleActivityLog={handleActivityLog}
        createAgrExec={props.createAgrExec}
        fetchUsersByOrgID={props.fetchUsersByOrgID}
        whiteLabel={whiteLabel}
        whiteLabelLoading={whiteLabelLoading}
      />

      {['custS', 'custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ?
      (
      <DialogReview
        module={props.module}
        open={reviewOpen}
        primaryCpty={primaryCpty}
        secondaryCpty={secondaryCpty}
        agr={activeAgrs !== undefined && activeAgrs !== null && activeAgrs.filter((a) => a.parentAID === "")[0] !== undefined ? 
          activeAgrs.filter((a) => a.parentAID === "")[0] : {}} // Always submit the Main Body
        activeAgrVersion={/*activeAV*/mainBodyAV}
        parentCallToClose={handleReviewClose}
        ticket={null}   
        user={props.user}
        users={props.users}
        rel={activeRel}
        createComment={props.createComment}
        updateComment={props.updateComment}
        createNotification={props.createNotification}
        updateTicket={props.updateTicket}
        lastUpdateTicket={props.lastUpdateTicket}
        updateTicketInParent={updateTicketInParent}
        handleActivityLog={handleActivityLog}
        ticketsFromAgrPage={tickets !== null ? tickets.filter((t) => t.ticketType === 'AgrReviewInternal') : []}
      />
      ) : '' }
      
    </div>
  )
}

export default Agreement;