import { FETCH_LOGGEDINUSER_PENDING, FETCH_LOGGEDINUSER_SUCCESS, FETCH_LOGGEDINUSER_ERROR,
    ADD_USER, REMOVE_USER, FETCH_USERS_PENDING, FETCH_USERS_SUCCESS, FETCH_USERS_ERROR } from '../ActionTypes';

export const User = (state  = { 
    pending: false,
    error: null,
    user: {}
}, action) => {
switch(action.type) {
        case FETCH_LOGGEDINUSER_PENDING: 
            return {
                ...state,
                pending: true
            }
        case FETCH_LOGGEDINUSER_SUCCESS:
            return {
                ...state,
                pending: false,
                user: action.payload
            }
        case FETCH_LOGGEDINUSER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }
        default: 
            return state;
    }
};

export const Users = (state  = { 
    pending: false,
    error: null,
    users: []
}, action) => {
switch(action.type) {
        case FETCH_USERS_PENDING: 
            return {
                ...state,
                pending: true
            }
        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                pending: false,
                users: action.payload
            }
        case FETCH_USERS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }
        case ADD_USER:
            var user = action.payload;
            return { 
                ...state, 
                pending: false,
                users: state.users.concat(user)};
        case REMOVE_USER:
            var uid = action.payload;
            return { 
                ...state, 
                users: state.users.filter(({ _id }) => _id !== uid)};
        default: 
            return state;
    }
};
