import React from 'react';
import theme from '../../theme/theme';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Grid, InputAdornment, TextField } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faGlobe, faLandmark, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

const CompanyContactForm = (props) => {

  const formik = useFormik({
    initialValues: {
      shortName: props.initialValues.shortName,
      legalName: props.initialValues.legalName,
      url: props.initialValues.url,
      phone: props.initialValues.phone,
      email: props.initialValues.email,
      primaryContactID: props.initialValues.primaryContactID,
    },
    validationSchema: Yup.object({
      shortName: Yup.string()
        .max(40, 'The company name is too long')
        .required('Company name is required'),
      legalName: Yup.string()
        .max(50, 'The legal name is too long')
        .required('Legal name is required'),
      url: Yup.string()
        .url('A valid website is required, include http://'),
      phone: Yup.string()
        .min(8 , 'The phone number is too short')
        .max(20, 'The phone number is too long'),
      email: Yup.string()
        .email('A valid email address is required'),
    }),
    onSubmit: values => {
      props.parentCallback(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
    <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
    <Grid item container xs={12} sm={8} md={6}>
        <TextField
            required
            disabled={props.type === 'update'}
            style={props.type ==='update' ? {backgroundColor: theme.palette.grey[100]} : {backgroundColor: theme.palette.primary.contrastText}}
            fullWidth
            variant="outlined"
            id="shortName"
            label="Company Name"
            placeholder="Short name (e.g. ABC)"
            name="shortName"
            autoComplete="sname"
            {...formik.getFieldProps('shortName')}
            error={formik.touched.shortName && formik.errors.shortName ? true : false}
            helperText={formik.touched.shortName && formik.errors.shortName ? formik.errors.shortName : ''}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <FontAwesomeIcon icon={faBuilding} color={props.type === 'update' ? theme.palette.grey[400] : theme.palette.grey[600]} />
                </InputAdornment>
                ),
            }}
        />
      </Grid>
      <Grid item container xs={12} sm={8} md={6}>
        <TextField
            required
            fullWidth
            variant="outlined"
            id="legalName"
            label="Legal Name"
            placeholder="Legal name (e.g. ABC LLC)"
            name="legalName"
            autoComplete="legalname"
            {...formik.getFieldProps('legalName')}
            error={formik.touched.legalName && formik.errors.legalName ? true : false}
            helperText={formik.touched.legalName && formik.errors.legalName ? formik.errors.legalName : ''}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <FontAwesomeIcon icon={faLandmark} color={theme.palette.grey[600]} />
                </InputAdornment>
                ),
            }}     
        />
      </Grid>      
      {!props.signup ?
      (
      <React.Fragment>
        <Grid item container xs={12} sm={8} md={6}>
          <TextField
              required
              fullWidth
              variant="outlined"
              id="url"
              label="Company website"
              placeholder="URL (e.g. https://www.domain.com)"
              name="url"
              autoComplete="url"
              {...formik.getFieldProps('url')}
              error={formik.touched.url && formik.errors.url ? true : false}
              helperText={formik.touched.url && formik.errors.url ? formik.errors.url : ''}
              InputProps={{
                  startAdornment: (
                  <InputAdornment position="start">
                      <FontAwesomeIcon icon={faGlobe} color={theme.palette.grey[600]} />
                  </InputAdornment>
                  ),
              }}
          />
        </Grid>
        <Grid item container xs={12} sm={8} md={6}>
          <TextField
              required
              fullWidth
              variant="outlined"
              id="phone"
              label="Company phone"
              placeholder="Phone number (e.g. +1 234 567 89)"
              name="phone"
              autoComplete="phone"
              {...formik.getFieldProps('phone')}
              error={formik.touched.phone && formik.errors.phone ? true : false}
              helperText={formik.touched.phone && formik.errors.phone ? formik.errors.phone : ''}
              InputProps={{
                  startAdornment: (
                  <InputAdornment position="start">
                      <FontAwesomeIcon icon={faPhone} color={theme.palette.grey[600]} />
                  </InputAdornment>
                  ),
              }}     
          />
        </Grid>
        <Grid item container xs={12} sm={8} md={6}>
          <TextField
              required
              fullWidth
              variant="outlined"
              id="email"
              label="Company e-mail"
              placeholder="Company e-mail (e.g. info@domain.com)"
              name="email"
              autoComplete="email"
              {...formik.getFieldProps('email')}
              error={formik.touched.email && formik.errors.email ? true : false}
              helperText={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
              InputProps={{
                  startAdornment: (
                  <InputAdornment position="start">
                      <FontAwesomeIcon icon={faEnvelope} color={theme.palette.grey[600]} />
                  </InputAdornment>
                  ),
              }}
          />
        </Grid>
      </React.Fragment>
      ): '' }
      
      <Grid item container xs={12} sm={8} md={6}>
        <Button
          fullWidth
          disableElevation
          disabled={props.signup && (
            formik.values.shortName === '' ||
            formik.values.legalName === ''
          )}
          type="submit"
          variant="contained"
          color="primary"
          >{props.signup ? "Next" : "Submit"}
        </Button>
      </Grid>
    </Grid>

    </form>
  );
};

export default CompanyContactForm