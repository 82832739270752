import React from 'react';
import { pSBC } from '../utils'
import { Backdrop, Box, Fab, Tooltip,
         makeStyles, useScrollTrigger } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSave, faTimes, faArrowLeft, faArrowRight, 
         faPaperPlane, faSignature, faUserPlus, faPlay, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const FABMagic = (props) => {

    const useStyles = makeStyles(theme => ({
        fabExtendedWhiteLabel: {
            [theme.breakpoints.down('sm')]: { width: '140px', height: '50px', bottom: '20px', right: '10px', borderRadius: '25px', fontSize: '15px', fontWeight: '600' },
            [theme.breakpoints.up('md')]: { width: '170px', height: '60px', bottom: '20px', right: '20px', borderRadius: '30px', fontSize: '18px', fontWeight: '600' },
            margin: 0,
            top: 'auto',
            left: 'auto',
            position: 'fixed',
            zIndex: 1000,
            color: props.org !== undefined && props.org !== null && props.org.orgSettings !== undefined && ['full'].includes(props.org.orgSettings.whiteLabelLevel) &&
                props.whiteLabel !== undefined && props.whiteLabel !== null && props.whiteLabel.contrastColor !== undefined ? 
                    props.whiteLabel.contrastColor: '#ffffff',
            backgroundColor: props.org !== undefined && props.org !== null && props.org.orgSettings !== undefined && ['full'].includes(props.org.orgSettings.whiteLabelLevel) &&
                props.whiteLabel !== undefined && props.whiteLabel !== null && props.whiteLabel.color !== undefined ? 
                    props.whiteLabel.color: '#ffffff',
            '&:hover': {
                backgroundColor: props.org !== undefined && props.org !== null && props.org.orgSettings !== undefined && ['full'].includes(props.org.orgSettings.whiteLabelLevel) &&
                    props.whiteLabel !== undefined && props.whiteLabel !== null && props.whiteLabel.color !== undefined ? 
                        pSBC(props.whiteLabel.color, -0.2) : '#ffffff',
            },
            transition: theme.transitions.create("all", {
                easing: theme.transitions.easing.easeIn,
                duration: '0.2s'
            })
        },
        fabWhiteLabel: {
            [theme.breakpoints.down('sm')]: { width: '50px', height: '50px', bottom: '20px', right: '10px', },
            [theme.breakpoints.up('md')]: { width: '60px', height: '60px', bottom: '20px', right: '20px', },
            margin: 0,
            top: 'auto',
            left: 'auto',
            position: 'fixed',
            zIndex: 1000,
            color: props.org !== undefined && props.org !== null && props.org.orgSettings !== undefined && ['full'].includes(props.org.orgSettings.whiteLabelLevel) &&
                props.whiteLabel !== undefined && props.whiteLabel !== null && props.whiteLabel.contrastColor !== undefined ? 
                    props.whiteLabel.contrastColor: '#ffffff',
            backgroundColor: props.org !== undefined && props.org !== null && props.org.orgSettings !== undefined && ['full'].includes(props.org.orgSettings.whiteLabelLevel) &&
                props.whiteLabel !== undefined && props.whiteLabel !== null && props.whiteLabel.color !== undefined ? 
                    props.whiteLabel.color : '#ffffff',
            '&:hover': {
                backgroundColor: props.org !== undefined && props.org !== null && props.org.orgSettings !== undefined && ['full'].includes(props.org.orgSettings.whiteLabelLevel) &&
                    props.whiteLabel !== undefined && props.whiteLabel !== null && props.whiteLabel.color !== undefined ? 
                        pSBC(props.whiteLabel.color, -0.2) : '#ffffff',
            },
            transition: theme.transitions.create("all", {
                easing: theme.transitions.easing.easeIn,
                duration: '0.2s'
            })
        },
        fabExtended: {
            [theme.breakpoints.down('sm')]: { width: '140px', height: '50px', bottom: '20px', right: '10px', borderRadius: '25px', fontSize: '15px', fontWeight: '600' },
            [theme.breakpoints.up('md')]: { width: '170px', height: '60px', bottom: '20px', right: '20px', borderRadius: '30px', fontSize: '18px', fontWeight: '600' },
            margin: 0,
            top: 'auto',
            left: 'auto',
            position: 'fixed',
            zIndex: 1000,
            '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
            },
            transition: theme.transitions.create("all", {
                easing: theme.transitions.easing.easeIn,
                duration: '0.2s'
            })
        },
        fab: {
            [theme.breakpoints.down('sm')]: { width: '50px', height: '50px', bottom: '20px', right: '10px', },
            [theme.breakpoints.up('md')]: { width: '60px', height: '60px', bottom: '20px', right: '20px', },
            margin: 0,
            top: 'auto',
            left: 'auto',
            position: 'fixed',
            zIndex: 1000,
            '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
            },
            transition: theme.transitions.create("all", {
                easing: theme.transitions.easing.easeIn,
                duration: '0.2s'
            })
        },
        fabexit: {
            [theme.breakpoints.down('sm')]: { width: '50px', height: '50px', bottom: '20px', right: '10px', },
            [theme.breakpoints.up('md')]: { width: '60px', height: '60px', bottom: '20px', right: '20px', },
            margin: 0,
            top: 'auto',
            left: 'auto',
            position: 'fixed',
            zIndex: 2000,
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.grey[600],
            '&:hover': {
                backgroundColor: theme.palette.grey[100],
            },
        },
        /*
        penOpt1: { 
            [theme.breakpoints.down('sm')]: { right: '10px', bottom: '182px', },
            [theme.breakpoints.up('md')]: { right: '20px', bottom: '197px', },
            margin: 0,
            top: 'auto',
            left: 'auto',
            position: 'fixed',
            zIndex: 1000,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            },
            '&:disabled': {
                color: theme.palette.grey[500]
            }
        },*/
        penOpt0Sec: { 
            [theme.breakpoints.down('sm')]: { right: '10px', bottom: '233px', },
            [theme.breakpoints.up('md')]: { right: '20px', bottom: '250px', },
            margin: 0,
            top: 'auto',
            left: 'auto',
            position: 'fixed',
            zIndex: 1000,
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.secondary.light,
            },
            '&:disabled': {
                color: theme.palette.grey[500]
            }
        },
        penOpt1: { 
            [theme.breakpoints.down('sm')]: { right: '10px', bottom: '182px', },
            [theme.breakpoints.up('md')]: { right: '20px', bottom: '197px', },
            margin: 0,
            top: 'auto',
            left: 'auto',
            position: 'fixed',
            zIndex: 1000,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            },
            '&:disabled': {
                color: theme.palette.grey[500]
            }
        },
        penOpt1Sec: { 
            [theme.breakpoints.down('sm')]: { right: '10px', bottom: '182px', },
            [theme.breakpoints.up('md')]: { right: '20px', bottom: '197px', },
            margin: 0,
            top: 'auto',
            left: 'auto',
            position: 'fixed',
            zIndex: 1000,
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.secondary.light,
            },
            '&:disabled': {
                color: theme.palette.grey[500]
            }
        },
        penOpt2: { 
            [theme.breakpoints.down('sm')]: { right: '10px', bottom: '131px', },
            [theme.breakpoints.up('md')]: { right: '20px', bottom: '146px', },
            margin: 0,
            top: 'auto',
            left: 'auto',
            position: 'fixed',
            zIndex: 1000,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            },
            '&:disabled': {
                color: theme.palette.grey[500]
            }
        },
        penOpt2Sec: { 
            [theme.breakpoints.down('sm')]: { right: '10px', bottom: '131px', },
            [theme.breakpoints.up('md')]: { right: '20px', bottom: '146px', },
            margin: 0,
            top: 'auto',
            left: 'auto',
            position: 'fixed',
            zIndex: 1000,
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.secondary.light,
            },
            '&:disabled': {
                color: theme.palette.grey[500]
            }
        },
        penOpt3: { 
            [theme.breakpoints.down('sm')]: { right: '10px', bottom: '80px', },
            [theme.breakpoints.up('md')]: { right: '20px', bottom: '95px' },
            margin: 0,
            top: 'auto',
            left: 'auto',
            position: 'fixed',
            zIndex: 1000,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            },
        },
        hide: {
            display: 'none'
        },
        iconPad: {
            paddingRight: '10px'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        largerIcon: {
            fontSize: '18px',
        },
    }));
    const classes = useStyles();

    const trigger = useScrollTrigger({
        threshold: 100,
    });

    const [openPenOptions, setOpenPenOptions] = React.useState(false);
    const [hover, setHover] = React.useState(false)

    const handleTogglePenOptions = () => {
        setOpenPenOptions(!openPenOptions)
    }

    const handlePenOption = (type) => {
        props.click(type);
        setOpenPenOptions(false);
    }

    return (
    <div>
        {props.fabType === 'thepen' ?
        <>
            <Backdrop className={classes.backdrop} open={openPenOptions} onClick={handleTogglePenOptions}>
            <Box className={!openPenOptions ? classes.hide : ''}>
                
                <Fab size="medium" variant="extended" disabled={!props.signable}
                className={['none'].includes(props.editMode) ? classes.penOpt1Sec : classes.penOpt0Sec} onClick={e => handlePenOption('sign')}
                >&nbsp;&nbsp;<FontAwesomeIcon icon={faSignature} className={classes.iconPad} />Initiate signing&nbsp;&nbsp;</Fab>

                <Fab size="medium" variant="extended" className={['none'].includes(props.editMode) ? classes.penOpt2 : classes.penOpt1} onClick={e => handlePenOption('cpty')}
                >&nbsp;&nbsp;<FontAwesomeIcon icon={faPaperPlane} className={classes.iconPad} />Send to counterparty&nbsp;&nbsp;</Fab>

                {['comment', 'edit', 'full'].includes(props.editMode) ?
                <Fab size="medium" variant="extended" className={classes.penOpt2} onClick={e => handlePenOption(props.userRole !== 'Counterparty' ? 'int' : 'collab')}
                >&nbsp;&nbsp;<FontAwesomeIcon icon={props.userRole !== 'Counterparty' ? faSearch : faUserPlus} className={classes.iconPad} />{props.userRole !== 'Counterparty' ? "Assign for internal review" : "Add a collaborator"}&nbsp;&nbsp;</Fab>
                : '' }

                <Fab size="medium" variant="extended" className={classes.penOpt3} onClick={e => props.snackOpen()}
                >&nbsp;&nbsp;<FontAwesomeIcon icon={faInfoCircle} className={classes.iconPad} />Quick Summary&nbsp;&nbsp;</Fab>

            </Box>
            </Backdrop>
        </>
        : '' }

        {!props.whiteLabelLoading ?
        <Tooltip title={props.hasActiveClause ? "Close to continue" : ""} placement="left">
        <Fab 
        color={"secondary"}
        onMouseOver={e => setHover(true)}
        onMouseLeave={e => setHover(false)}
        className={openPenOptions || props.getStartedOpen || props.hasActiveClause ? 
                        classes.fabexit : 
                   ['thepen', 'exhibit', 'getstarted'].includes(props.fabType) && ((!trigger && window.pageYOffset > 100) || hover || window.pageYOffset === 0) && 
                   props.org !== undefined && props.org !== null && props.org.orgSettings !== undefined && ['full'].includes(props.org.orgSettings.whiteLabelLevel) &&
                   props.whiteLabel !== undefined && props.whiteLabel !== null && props.whiteLabel.color !== undefined && props.whiteLabel.contrastColor !== undefined ? 
                        classes.fabExtendedWhiteLabel : 
                   ['thepen', 'exhibit', 'getstarted'].includes(props.fabType) && ((!trigger && window.pageYOffset > 100) || hover || window.pageYOffset === 0) ? 
                        classes.fabExtended : 
                    props.org !== undefined && props.org !== null && props.org.orgSettings !== undefined && ['full'].includes(props.org.orgSettings.whiteLabelLevel) &&
                    props.whiteLabel !== undefined && props.whiteLabel !== null && props.whiteLabel.color !== undefined && props.whiteLabel.contrastColor !== undefined ? 
                        classes.fabWhiteLabel :
                        classes.fab}
        onClick={props.fabType === 'thepen' && !props.hasActiveClause ? handleTogglePenOptions : props.click}
        variant={
            ['thepen', 'exhibit', 'getstarted'].includes(props.fabType) && 
            ((!trigger && window.pageYOffset > 100) || hover || window.pageYOffset === 0) && 
            !openPenOptions && !props.getStartedOpen && !props.hasActiveClause ? "extended" : "round"} 
        >
            {['thepen', 'exhibit', 'getstarted'].includes(props.fabType) && 
            ((!trigger && window.pageYOffset > 100) || hover || window.pageYOffset === 0) && 
            !openPenOptions && !props.getStartedOpen && !props.hasActiveClause ? 
                <Box mr={1}>
                    {props.fabType === 'thepen' ? "Continue" : 
                    props.fabType === 'exhibit' ? <span style={{whiteSpace: 'nowrap'}}>Main Body</span> :
                    <span style={{whiteSpace: 'nowrap'}}>Get Started</span>}
                </Box> : ""}
                <FontAwesomeIcon icon={openPenOptions || props.getStartedOpen || props.hasActiveClause ? faTimes :
                    props.iconType === 'arrowLeft' ? faArrowLeft :
                    props.iconType === 'pen' ? faArrowRight : 
                    props.iconType === 'getstarted' ? faPlay :
                    props.iconType === 'save' ? faSave :
                    props.iconType === 'times' ? faTimes :
                    props.iconType === 'search' ? faSearch : 
                    props.iconType === 'back' ? faArrowLeft : faArrowRight} 
                    className={['pen', 'arrowLeft', 'getstarted'].includes(props.iconType) ? classes.largerIcon : ''} />
        </Fab>
        </Tooltip>
        :''}
    </div>
    );
}

export default FABMagic