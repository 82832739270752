import React from 'react';
import { Avatar } from '@material-ui/core';

function stringAvatar(name) {
    return {
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const AvatarInitials = (props) => {
    return (
        <Avatar src={props.photoURL} 
        {...stringAvatar(props.displayName)}
        style={{ width: 28, height: 28, 
            margin: (props.variant === 'left' ? '0px 5px 0px 0px' : props.variant === 'right' ? '0px 0px 0px 5px' : '0px'), 
            backgroundColor: props.photoURL !== null ? '#ffffff' : '#4d4b59', 
            fontWeight: '500', 
            fontSize: '14px' }} />
    );
}

export default AvatarInitials