function convertLabelToTitle(label) {
  
  let title = '';
  let labelArr = label.split("_")

  if(label === 'sla') { title = 'SLA' }
  else if(label === 'representations_warranties') { title = 'Representations & Warranties' }
  else if(label === 'limitation_of_liability') { title = 'Limitation of Liability' }
  else {
    labelArr.forEach((la) => { title = title + la.charAt(0).toUpperCase() + la.slice(1).toLowerCase() + ' '; })
    title = title.substring(0, title.length - 1);
  }

  return title;
}

export { convertLabelToTitle }