import React from 'react';
import theme from '../theme/theme';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Box, Grid, TextField, Typography, 
         makeStyles, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';         
import { Footer, Header } from '../components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const CssTextField = withStyles({
  root: {
    [theme.breakpoints.only('xs')]: { width: '300px' },
    [theme.breakpoints.up('sm')]: { width: '500px' },
    marginBottom: '10px',
    color: '#ff0000',
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: '25px',
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grey[500],
      },
      '&:hover fieldset': { // hover
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& fieldset': {
        borderRadius: '25px',
    }
  },
})(TextField);

function Network(props) {
  
  const useStyles = makeStyles(theme => ({
    outerFlexGrid: {
      [theme.breakpoints.only('xs')]: { minHeight: '80vh', },
      [theme.breakpoints.up('sm')]: { minHeight: '88vh', },
      paddingTop: '90px',
    },
    searchIcon: {
      paddingRight: '10px',
      paddingLeft: '10px',
      color: theme.palette.primary.main,
      '&:hover, &.Mui-focusVisible': { 
        backgroundColor: theme.palette.primary.contrastText, 
        border: 'none'
      }
    },
    clink: {
      [theme.breakpoints.only('xs')]: { padding: '0px 5px 0px 5px', },
      [theme.breakpoints.up('sm')]: { padding: '0px 20px 0px 20px', },
      color: theme.palette.primary.main,
      fontWeight: '500',
      fontSize: '13px',
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.secondary.main,
        cursor: 'pointer',
      },
      display: 'inline',
    },
  }));
  const classes = useStyles();

  const history = useHistory();  

  const [showOptions, setShowOptions] = React.useState(false);

  const handleInputChange = (e, value) => {
    if(value.length > 1 && !showOptions) { 
      setShowOptions(true);
    } else if(value.length < 2 && showOptions){
      setShowOptions(false);
    }
  }

  const handleValueChange = (e, value) => {
    setShowOptions(false)
    history.push('/network/' + value.orgurl); // send to search option
  }

  return(
    <div>

      <Header 
        module={props.user.email !== undefined ? "network" : "open"}
        openOnly={props.user.email !== undefined ? false : true}
        user={props.user}
        org={props.org}
        modules={
          props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
            ['sales','procurement','partnerships','people','corporate'] : 
          props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
            props.org.modules : [] }
        crumbs={[]}
        notifications={props.notifications}
        markNotificationAsRead={props.markNotificationAsRead}
        markAllNotificationsAsRead={props.markAllNotificationsAsRead}
      />

      <Grid container direction="column" justify="center" alignItems="center" className={classes.outerFlexGrid}>

      <Box mt={0} mb={3}>

        <Typography variant="h4" style={{fontWeight: '700', color: theme.palette.grey[900]}}>
            <span>Find</span> your counterparty<span style={{color: theme.palette.secondary.main}}>.</span>
        </Typography>

      </Box>
      <Box mt={3} mb={3}>

        <Autocomplete
        id="search-box"
        options={props.network !== undefined && props.network.length > 0 ? props.network : []}
        getOptionLabel={option => option.shortName}
        popupIcon={<FontAwesomeIcon icon={faSearch} className={classes.searchIcon} />}
        forcePopupIcon={!showOptions}
        onInputChange={handleInputChange}
        onChange={handleValueChange}
        open={showOptions}
        clearOnEscape={true}
        renderInput={params => 
        <CssTextField 
            {...params} 
            label="Search the network" 
            placeholder="Counterparty name..."
            variant="outlined"
            />}
        />
      
      </Box>
      <Box mt={3} mb={5}>

        {props.user !== undefined && props.user.role !== undefined ?
        (
        <Typography variant="body2">
            {['Counterparty'].includes(props.user.role) ?
              <RouterLink to="/core/counterparty/dashboard" className={classes.clink}>Switch to Counterparty Module</RouterLink>
            :
            ['Procurement'].includes(props.user.role) ?
              <RouterLink to="/core/procurement/dashboard" className={classes.clink}>Switch to Procurement Module</RouterLink>
            :
            ['Partnerships'].includes(props.user.role) ?
              <RouterLink to="/core/partnerships/dashboard" className={classes.clink}>Switch to Partnerships Module</RouterLink>
            :
            ['HR'].includes(props.user.role) ?
              <RouterLink to="/core/people/dashboard" className={classes.clink}>Switch to People Module</RouterLink>
            :
            ['Corporate'].includes(props.user.role) ?
              <RouterLink to="/core/corporate/dashboard" className={classes.clink}>Switch to Corporate Module</RouterLink>
            :
            ['Admin', 'Business', 'Legal', 'Sales'].includes(props.user.role) ?
              <RouterLink to="/core/sales/dashboard" className={classes.clink}>Switch to Sales Module</RouterLink>
            : '' }
        </Typography>
        )
        :
        (
        <Typography variant="body2">
            <a href="https://getcanveo.com/" className={classes.clink}>What is canveo?</a> | <RouterLink to="/signup" className={classes.clink}>Create an account</RouterLink>
        </Typography>
        )}

      </Box>

      </Grid>

      {props.user.email !== undefined ? '' : <Footer />}

    </div>
    );

}
export default Network