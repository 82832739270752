import * as ActionTypes from '../ActionTypes';

const axios = require('axios');

export const increaseDashboardVisits = (module) => ({
    type: ActionTypes.INCREASE_DASHBOARD_VISITS,
    payload: module
});

export const pushTime = (orgID, uid, rid, aid, time, displayName, module, cpmodule, cptyType) => {
  return dispatch => {

    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    axios.put('/logging/protect/time', {
      orgID: orgID,
      uid: uid,
      rid: rid,
      aid: aid,
      date: year + '-' + month + '-' + day,
      time: time,
      displayName: displayName, 
      module: module,
      cpmodule: cpmodule,
      cptyType: cptyType,
    })
  }
};

export const defineSessionExpiry = (expiry) => ({
    type: ActionTypes.DEFINE_SESSION_EXPIRY,
    payload: expiry
})

export const defineSmartMode = (smartMode) => ({
    type: ActionTypes.DEFINE_SMART_MODE,
    payload: smartMode
})

/*
export const logErr = (errID, uid, errMsg) => {
  let errDate = new Date().toISOString()
  console.log(errID)
  console.log(uid)
  console.log(errDate)
  console.log(errMsg)
}*/