import React, { useEffect } from 'react';
import theme from '../theme/theme';
import { trunc } from '../utils'
import { Box, Button, Card, CardContent, CircularProgress, IconButton, Typography,
         makeStyles } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTrash } from '@fortawesome/free-solid-svg-icons';

function Task(props){
    const { id, index, title, isNewAfterTheFact, parentCallToRemove } = props;
    let style = {
        width: '250px', 
        marginBottom: '10px',
        marginTop: '10px',
        backgroundColor: isNewAfterTheFact ? '#f3f3f3' : theme.palette.primary.contrastText,
    }

    return (
       <Draggable draggableId={id} key={id} index={index} >

         {(provided) => (
            <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps} 
            align="center" mb={0}>
            <Card variant="outlined" style={style}>
            <Box mt={1} mb={1}>
            <Typography variant="subtitle1" style={{fontWeight: '700'}}>
                {props.isNew ? trunc(title, 20) : trunc(title, 25)}
                {props.isNew ?
                <IconButton onClick={e => parentCallToRemove(id)} size="small" style={{marginLeft: '10px'}}>
                    <FontAwesomeIcon icon={faTrash} style={{fontSize: '14px'}} />
                </IconButton>
                : '' }
            </Typography>
            </Box>
            </Card>
            </Box>
        )}

       </Draggable>
    )
}

function Column(props){
    const { id, list, parentCallToRemove } = props;

    return (
 
        <Droppable droppableId={id}>
        {provided => (
 
           <div {...provided.droppableProps} ref={provided.innerRef}>
 
             {list.map((val,index)=>{
                return <Task id={
                            val.agrComponentID !== undefined ? val.agrComponentID : 
                            val._id !== undefined ? val._id : val.atid !== undefined ? val.atid : ''} key={index} index={index} 
                            title={trunc(val.agrFullName !== undefined ? val.agrFullName :
                                val.fullName !== undefined ? val.fullName : '', 26)}
                            isNew={val.isNew}
                            isNewAfterTheFact={val.isNewAfterTheFact}
                            parentCallToRemove={parentCallToRemove}
                        />
             })}
 
            {provided.placeholder}
           </div>
         )
        }
        </Droppable>
    )
}

const AgreementStructure = (props) => {

  const useStyles = makeStyles(theme => ({
    cardNewAg: {
        width: '250px', 
        height: '280px',
        marginBottom: '10px',
        marginTop: '10px',
    },
    cardNewEx: {
        width: '250px', 
        marginBottom: '10px',
        marginTop: '10px',
        '&:hover': {
            color: theme.palette.error.main,
            textDecoration: 'line-through',
            cursor: 'pointer',
        },
    },
    cardExReorder: {
        width: '250px', 
        marginBottom: '10px',
        marginTop: '10px',
        backgroundColor: '#f9f9f9',
        '&:hover': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.grey[700],
            cursor: 'pointer',
        },
    },
    cardNewExNoHover: {
        width: '250px', 
        marginBottom: '10px',
        marginTop: '10px',
    },
    fakeText: {
        backgroundColor: '#f3f3f3',
        borderRadius: '10px',
        height: '15px',
        margin: '10px',
    },
    bold: {
        fontWeight: '700',
    }
    
  }));
  const classes = useStyles();

  const [exList, setExList] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
      if(props.activeAgrs !== undefined && props.activeAgrs !== null) {
        setExList(props.activeAgrs.filter((ag) => ag.parentAID !== ''))
      }

  }, [props.activeAgrs])


  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) { return; }
    if (result.destination.index === result.source.index) { return; }

    const exs = reorder(exList, result.source.index, result.destination.index);
    setExList([...exs]);
    if(!props.reordering){ props.parentCallToConfirm(exs)}
  }

  const handleConfirmNewOrder = () => {
    setLoading(true)
    props.parentCallToClose()
    props.parentCallToConfirm(exList)
    setLoading(false)
  }

  return (
    <div>

        <Card variant="outlined" className={classes.cardNewAg}>
        <CardContent>

            <Box align="center" mt={2} mb={3} ml={2} mr={2}>
            <Typography variant="h4" className={classes.bold}>
                {trunc(props.agrShortName,7)}
            </Typography>
            <Typography variant="body2">
                {trunc(props.agrFullName, 26)}
            </Typography>
            </Box>

            <Box align="left">
            <div className={classes.fakeText} style={{width: '190px'}} />
            <div className={classes.fakeText} style={{width: '180px'}} />
            <div className={classes.fakeText} style={{width: '200px'}} />
            <div className={classes.fakeText} style={{width: '190px'}} />
            <div className={classes.fakeText} style={{width: '100px'}} />
            </Box>

        </CardContent>
        </Card>

        
        {props.activeAgrs !== null && props.activeAgrs.length > 0 ?

        <DragDropContext onDragEnd={onDragEnd}>

            <Column id="1" list={exList} parentCallToRemove={props.parentCallToRemove}/>

        </DragDropContext>
        
        : ''}

        {props.reordering ? 
        <Box mt={5}>
        <Button variant="text" onClick={props.parentCallToClose} color="primary" style={{width: '130px'}}>
          <FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Cancel
        </Button>
        &nbsp;&nbsp;
        <Button variant="contained" onClick={handleConfirmNewOrder} color="primary" disableElevation  style={{width: '130px'}}
        disabled={loading}>
            { loading ? <CircularProgress size={24} /> : ''}
            { loading ? "Confirming..." : "Confirm"}
        </Button>
        </Box>
        : '' }

    </div>
  );
}

export default AgreementStructure