import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { server } from '../utils'
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';

function CustomLink(props) {

  const history = useHistory()

  useEffect(() => {

    let orgurl = props.orgurl;
    let customURL = props.customURL;
    let uid = props.uid;
    let aid = props.aid;
    let clid = props.clid;

    // The async login Function
    async function loginThroughCustomLink() {
      const { success, data } = await server.postAsync('/auth/customlink', {
        orgurl,
        customURL,
        uid,
        aid,
        clid,
      })

      const isFirefox = typeof InstallTrigger !== 'undefined';
      
      if (success) { // successfully logged in
        window.location.replace(data);
        return
      } else if(!isFirefox) {
        history.push('/error/cl')
      } else if(isFirefox) {
        // an artificial delay since otherwise the reroute may come before the clauses are deleted
        setTimeout(function (){ history.push('/error/cl') }, 6000);
      }
    }

    if(!props.loading && !props.orgurl !== undefined && 
       customURL !== undefined && (customURL.length > 10 || customURL === 'signup') && 
       uid !== undefined && uid.length > 10 && uid.length < 30 && 
       aid !== undefined && ((aid.length > 15 && aid.length < 30) || aid === 'cpty')) {

        loginThroughCustomLink()  // Logging in

    }
    
  }, [props.orgurl, props.customURL, props.uid, props.aid, props.clid, history, props.loading]);

  return(
    <div>

        <Grid container justify="center" alignItems="center" style={{ minHeight: '95vh' }}>
            <Grid container direction="column" justify="center" alignItems="center" spacing={2} style={{ margin: 0, width: '100%', }}>

            <Box mb={5}>
                <CircularProgress size={44} disableShrink />
            </Box>
        
            <Box pl={2} pr={2}>
              <Typography variant="body2" align="center" gutterBottom>
              You are being redirected.
              </Typography>

              <Typography variant="body2" align="center">
              If you don't see your dashboard within a few seconds then retry clicking the link.
              </Typography>
            </Box>

            </Grid>
        </Grid>

    </div>
    );
}

export default CustomLink;