const axios = require('axios');

export const createTicket = (module, ticketStatus, ticketType, orgID, entID, relID, agrID, agrVersion, singleClauseID, clauseID, topic, assignee, creationBy, workflow) => {
  return dispatch => {
    let creationDate = new Date().toISOString();
    axios.post('/ticket/ticket', {
        module: module,
        ticketStatus: ticketStatus,
        ticketType: ticketType,
        orgID: orgID,
        entID: entID,
        relID: relID,
        agrID: agrID,
        agrVersion: agrVersion,
        singleClauseID: singleClauseID,
        clauseID: clauseID,
        topic: topic,
        assignee: assignee,
        creationBy: creationBy,
        creationDate: creationDate,
        lastUpdateBy: creationBy,
        lastUpdateDate: creationDate,
        closedBy: '',
        closedDate: '',
        resolveReason: 'None',
        workflow: workflow
    })
  }
};

export const updateTicket = (faf, tid, module, ticketStatus, topic, assignee, lastUpdateBy, lastUpdateDate, closedBy, closedDate, resolveReason, workflow) => {
  return dispatch => {
    
    axios.put('/ticket/ticket/' + tid, {
        module: module,
        ticketStatus: ticketStatus, 
        topic: topic,
        assignee: assignee,
        lastUpdateBy: lastUpdateBy,
        lastUpdateDate: lastUpdateDate,
        closedBy: closedBy,
        closedDate: closedDate,
        resolveReason: resolveReason,
        workflow: workflow,
    })

  }
};

export const lastUpdateTicket = (tid, lastUpdateBy) => {
  return dispatch => {
    let lastUpdateDate = new Date().toISOString();
    axios.put('/ticket/ticketlastupdate/' + tid, {
        lastUpdateBy: lastUpdateBy,
        lastUpdateDate: lastUpdateDate,
    })

  }
};