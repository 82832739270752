import { taxes } from '../assets/static/taxes';

function getTaxTypesByCountry(country) {
    let types = []
    if(taxes.filter((t) => t.country.toUpperCase() === country.toUpperCase())[0] !== undefined) {
        types = taxes.filter((t) => t.country.toUpperCase() === country.toUpperCase());
    }
    return types;
}

export { getTaxTypesByCountry }