import React, { useEffect } from 'react';
import theme from '../theme/theme';
import { Link as RouterLink } from 'react-router-dom';
import { sendApprovalEmailToUser, clauseOpenCheck, getRecipientModule } from '../utils';
import { Box, Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown, faExclamationTriangle, faBan } from "@fortawesome/free-solid-svg-icons";

const axios = require('axios');

function Act(props) {

    const [loading, setLoading] = React.useState(true)
    const [confirmationMsg, setConfirmationMsg] = React.useState(null)
    const [invalidRequest, setInvalidRequest] = React.useState(false)
    const [rerouteURL, setRerouteURL] = React.useState(null)
    const [isCancelled, setIsCancelled] = React.useState(false)

    useEffect(() => {

        const updateClauseStatus = props.updateClauseStatus
        
        if(props.action !== undefined && props.val1 !== undefined && !props.loading && confirmationMsg === null) {
            
            let uid = props.val2;

            if(props.user !== undefined && props.user._id !== undefined && props.user._id !== uid) {
                // The user is already logged in but NOT the same as the user that was in the email link
                setInvalidRequest(true)

            } else if(['_ta','_tr'].includes(props.action)) { // ticket accept or reject

                let decision = ('_ta' === props.action ? 'approved' : 'rejected')

                axios.post('/act/ticketdecision/', {
                    tid: props.val1,
                    uid: uid,
                    sclid: props.val3,
                    aid: props.val4,
                    decision: decision, 
                }).then((res) => {

                    let ticket = res.data.data.ticket

                    if(res.data.data.cancelled) {
                        setIsCancelled(true);
                        setConfirmationMsg('This approval request was cancelled by the requestor.')
                        setLoading(false)
                        if(props.user !== undefined && props.user._id !== undefined && props.user._id === uid) {
                            let mod = ['Counterparty'].includes(props.user.role) || props.user.role.startsWith('ClientFor_') ? 'counterparty' : ticket.module
                            setRerouteURL('/core/' + mod + '/agreement/' + ticket.relID + '/' + ticket.agrID + '/' + ticket.singleClauseID);
                        }

                    } else {

                        let sa = ticket.topic.split('|')
                        let nextAssigneeText = ''
                        let cl = res.data.data.clause

                        // Update the new Approval Status of the clause
                        updateClauseStatus(ticket.singleClauseID, 'approval', res.data.data.newApprovalStatus, uid)
                        if(!clauseOpenCheck(cl.blocks, cl.commentStatus, res.data.data.newApprovalStatus, cl.pendingChange, cl.clauseObject)) {
                            // The clause is no longer "Open", change to "Accepted"
                            updateClauseStatus(cl._id, 'clause', 'Accepted', uid)
                        }

                        // Email the new Assignee if applicable
                        if(res.data.data.userNewAssignee !== undefined && res.data.data.userNewAssignee !== null && res.data.data.userNewAssignee._id !== undefined) {
                            let recModule1 = getRecipientModule(res.data.data.userCreator, res.data.data.userNewAssignee, ticket.module, res.data.data.rel)
                            sendApprovalEmailToUser (
                                'approvalrequest', // type
                                recModule1, //ticket.module, // module - recModule
                                res.data.data.rel, // rel
                                res.data.data.orgurl, // orgurl
                                ticket.agrID, // agrID
                                cl, // clause
                                res.data.data.userNewAssignee, // recipient
                                sa[2].trim(), // cptyLegalName
                                sa[1].trim(), // agrFullName
                                res.data.data.userCreator !== undefined && res.data.data.userCreator.displayName !== undefined ?
                                    res.data.data.userCreator.displayName : 'Unkown User', // creatorFullName
                                ticket, // ticket
                                {
                                    reassign: true,
                                    comment: 'The request has been approved by ' + res.data.data.userActor.displayName + ' and is now assigned to you.',
                                }, // commentOrDecision
                                res.data.data.comments, // allComments
                            )
                            nextAssigneeText = ' It is now assigned to ' + res.data.data.userNewAssignee.displayName + '.'
                        }
                    
                        let recModule2 = getRecipientModule(res.data.data.userActor, res.data.data.userCreator, ticket.module, res.data.data.rel)
                        // Email the creator on the decision
                        sendApprovalEmailToUser (
                            'approvaldecision', 
                            recModule2, //ticket.module, // module - recModule
                            res.data.data.rel, // rel
                            res.data.data.orgurl, // orgurl
                            ticket.agrID, // agrID
                            cl, // clause
                            res.data.data.userCreator, 
                            sa[2].trim(), 
                            sa[1].trim(), 
                            res.data.data.userActor.displayName, 
                            ticket, // ticket
                            {
                                decision: true,
                                outcome: decision,
                                comment: 'The request has been ' + decision + ' by ' + res.data.data.userActor.displayName + '.' + nextAssigneeText,
                            },
                            res.data.data.comments, // allComments
                        )

                        setConfirmationMsg('Your decision for the ' + sa[1] + ' with ' + sa[2] + ' has been recorded.')
                        setLoading(false)
                        if(props.user !== undefined && props.user._id !== undefined && props.user._id === uid) {
                            let mod = ['Counterparty'].includes(props.user.role) || props.user.role.startsWith('ClientFor_') ? 'counterparty' : ticket.module
                            setRerouteURL('/core/' + mod + '/agreement/' + ticket.relID + '/' + ticket.agrID + '/' + ticket.singleClauseID);
                        }
                    }

                }).catch((err) => { setInvalidRequest(true); })

            } else if(['_xrem'].includes(props.action)) { // unsubscribe from the reminder

                axios.post('/act/remunsubscribe', {
                    id: props.val1,
                    uid: props.val2,
                    aid: props.val3, 
                }).then((res) => {

                    setConfirmationMsg('You have been unsubscribed from the auto-reminder.')
                    setLoading(false)
                    setIsCancelled(true)

                }).catch((err) => { setInvalidRequest(true); })


            } else {
                setInvalidRequest(true);
            }

        }

    }, [props.action, props.loading /* do not add other triggers for the use-effect */])

    return(
    <div>

    <Grid container justify="center" alignItems="center" style={{ minHeight: '95vh' }}>
        {invalidRequest ?
        <Grid container direction="column" justify="center" alignItems="center" spacing={2} style={{ margin: 0, width: '100%', }}>

        <Box mb={5}>
            <FontAwesomeIcon size="5x" 
            icon={faExclamationTriangle}
            color={theme.palette.warning.main}
            />
        </Box>
    
        <Box pl={2} pr={2} mb={3}>
            <Typography variant="h5" align="center" gutterBottom>
                The request is not valid.
            </Typography>
            <Typography align="center">
                Try again or contact Canveo support if the issue persists.
            </Typography>
        </Box>

        </Grid>

        :loading ?
        <Grid container direction="column" justify="center" alignItems="center" spacing={2} style={{ margin: 0, width: '100%', }}>

            <Box mb={5}>
                <CircularProgress size={44} disableShrink />
            </Box>
        
            <Box pl={2} pr={2}>
            <Typography variant="body2" align="center" gutterBottom>
            You're decision is being processed.
            </Typography>

            <Typography variant="body2" align="center">
            If you don't see an acknowledgement within a few seconds then retry clicking the link.
            </Typography>
            </Box>

        </Grid>
        :
        <Grid container direction="column" justify="center" alignItems="center" spacing={2} style={{ margin: 0, width: '100%', }}>

        <Box mb={5}>
            <FontAwesomeIcon size="5x" 
            icon={
                isCancelled ? faBan :
                props.action === '_ta' ? faThumbsUp : 
                props.action === '_tr' ? faThumbsDown : faExclamationTriangle}
            color={
                isCancelled ? theme.palette.grey[600] : 
                props.action === '_ta' ? theme.palette.success.main : 
                props.action === '_tr' ? theme.palette.error.main : theme.palette.warning.main}
            />
        </Box>
    
        <Box pl={2} pr={2}>
          <Typography variant="h5" align="center" gutterBottom>
           {['_xrem'].includes(props.action) ? 'Unsubscribed' :
            isCancelled ? "Cancelled" :
            ['_ta'].includes(props.action) ? 
            "Approved" :
            ['_tr'].includes(props.action) ? 
            "Not Approved" :
            "You're decision has not been recorded."}
          </Typography>

          {confirmationMsg !== null ?
          <>
          <Box mt={3} mb={3}>
            <Typography variant="body1" align="center">
                {confirmationMsg}
            </Typography>
            {!['_xrem'].includes(props.action) ?
            <Box mt={5} align="center">
                <Button color="primary" variant="contained" disableElevation component={RouterLink} 
                to={rerouteURL !== null ? rerouteURL : "/core/login"}>
                    {rerouteURL !== null ? "View Agreement" : "Login to Canveo"}
                </Button>
            </Box>
            :''}
          </Box>
          </>
          :''}

        </Box>

        </Grid>
        }
    </Grid>

    </div>
    );
}

export default Act;