import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const primaryColor = '#7243dd'; // lighter shade: #7E50DF, darker shade: #572CCE
const secondaryColor = '#E80064';
const tertiaryColor = '#1446A0';
const white = '#ffffff';
const errorColor = '#D0252E';
const successColor = '#44AF69';
const primaryGrey = '#2b2936';
const secondaryGrey = '#827f8f';

let theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
      contrastText: white,
    },
    secondary: {
      main: secondaryColor,
      contrastText: white,
    }, 
    tertiary: {
      main: tertiaryColor,
      contrastText: white,
    }, 
    error: { 
      main: errorColor 
    },
    success: { 
      main: successColor 
    },
    grey: {
      900: primaryGrey,
      800: '#4d4b59',
      700: '#6d6a7a',
      600: secondaryGrey,
      500: '#aca9ba',
      400: '#cac7d8',
      300: '#ece8fa',
      200: '#f3f0ff',
      100: '#f8f5ff',
      50: '#fdfaff',
      //1: '#f9f9f9', // subtle backgroundColor
    },
    text: {
      primary: primaryGrey,
      secondary: secondaryGrey,
    }
  },
  typography: {
    fontFamily: 'proxima-nova',
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 700
    },
    subtitle1: {
      fontWeight: 500,
    },
    body1: {
      fontWeight: 300,
    },
    body2: {
      fontSize: '13px',
      fontWeight: 300,
    },
    button: {
      textTransform: 'none',
    }
  },
  shape: {
    borderRadius: 10,
  },
  spacing: 10,
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        color: primaryColor,
        backgroundColor: white
      }
    },
    MuiDrawer: {
      paper: {
        background: white,
        '& span': { color: primaryGrey },
        '& svg': { color: primaryColor, marginLeft: '5px' },
      },
    },
    MuiMenu: {
      paper: {
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 24px 0px',
        //boxShadow: 'rgba(0, 0, 0, 0.05) 0px 3px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: white,
        color: primaryGrey,
        fontSize: "14px",
        fontWeight: 400,
      }
    },
    MuiSlider: {
      valueLabel:{
        fontWeight: '700',
      }
    }
  },
});

theme = responsiveFontSizes(theme);

export default theme;