import React, { useEffect } from 'react';
import { userFriendlyAVV, getFilteredAVList } from '../utils';
import { Avatar, Box, Dialog, Grid, FormControl, InputLabel, ListItemAvatar, ListItemText, MenuItem, 
         Select, Slide, Typography,
         makeStyles } from '@material-ui/core';
import { Header, SkeletonCustom, AgreementExhList, TableEditable } from '../components';

const axios = require('axios');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});  

const DialogAVDiff = (props) => {

  const useStyles = makeStyles(theme => ({
    fullFlexGrid: {
        height: '100vh',
        marginTop: '100px',
    },
    added: {
        display: 'inline',
        color: theme.palette.success.main,
        backgroundColor: '#e3f5e9',
        borderBottom: '1px dotted ' + theme.palette.success.main,
    },
    removed: {
        color: theme.palette.error.main,
        textDecoration: 'line-through',
    },
    normal: {
        color: theme.palette.grey[700]
    },
    boxResizing: {
        [theme.breakpoints.only('xs')]: { width: '300px' },
        [theme.breakpoints.only('sm')]: { width: '430px' },
        [theme.breakpoints.only('md')]: { width: '550px' },
        [theme.breakpoints.up('lg')]: { width: '670px',  },
    },
    formControl: {
        margin: theme.spacing(1),
    },
    secTitle: {
        fontWeight: '700',
        margin: '25px 0px 15px 0px',
        fontSize: '26px',
    },
    ssecTitle: {
        fontWeight: '500',
        margin: '25px 0px 15px 0px',
        fontSize: '20px',
    },
    clTitle: {
        fontWeight: '700',
        margin: '20px 0px 0px 0px'
    },
    cl: {
        margin: '0px 0px 20px 0px',
    },
    menuAvatar: {
      width: '25px',
      height: '25px',
    },
    menuDesc: {
      fontWeight: 700,
      color: theme.palette.grey[700],
      marginLeft: '15px',
      fontSize: '13px,'
    },
    hide: {
      display: 'none',
    }
  }));
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [diff, setDiff] = React.useState(null);
  const [aid, setAID] = React.useState(props.aid);
  const [avs, setAvs] = React.useState([])
  
  const [oldValue, setOldValue] = React.useState(null);
  const [newValue, setNewValue] = React.useState(null);

  useEffect(() => {

    // Will only run once
    if(props.open && props.avs !== undefined && props.avs !== null && props.avs.length > 0 && oldValue === null && newValue === null) {
      setLoading(true);

      let tempAvs = [], oldVal = null, newVal = null

      if(props.templating) {
        tempAvs = props.avs
        newVal = props.curav._id
        oldVal = props.prevav._id !== newVal ? props.prevav._id : props.firstav._id

      } else {
        tempAvs = getFilteredAVList(props.avs, props.isPrimary) // Find the AVs that can be selected for comparison for this AID
        if((tempAvs[0].owner === "primary" && !props.isPrimary) || 
        (tempAvs[0].owner === "secondary" && props.isPrimary)) { // Remove the latest v. owned by CPTY (if applicable)
          tempAvs = tempAvs.slice(1) 
        }
        oldVal = tempAvs.length > 2 ? tempAvs[2]._id : tempAvs.length > 1 ? tempAvs[1]._id : tempAvs[0]._id
        newVal = tempAvs[0]._id
      }

      axios.get((props.templating ? '/template/oatvdiff/' : '/agr/agrvdiff/') + oldVal + '/' + tempAvs[0]._id)
      .then(function (res) {
        setOldValue(oldVal)
        setNewValue(newVal)
        setAvs(tempAvs)
        setLoading(false)
        setDiff(res.data.data)
      })
      .catch(function(err) { console.log(err); })
    }
  }, [props.open, props.firstav, props.prevav, props.curav, oldValue, newValue, props.avs, props.isPrimary])

  const handleOldChange = (event) => {
    setLoading(true)
    let avid = event.target.value;
    runNewDiff(avid, newValue, aid)
    setOldValue(avid);
  }

  const handleNewChange = (event) => {
    setLoading(true)
    let avid = event.target.value;
    runNewDiff(oldValue, avid, aid)
    setNewValue(avid);
  }

  const handleClick = (newaid, filter) => { // This function is only applicable to agreements (not to templates)
    setLoading(true)
    axios.get('/agr/agrvs/' + newaid) // Need to get the latest AV for this counterparty
    .then((res) => {
      let oldavid = '', newavid = '';
      let tempAvs = getFilteredAVList(res.data.data, props.isPrimary)
      let oldavv = avs.filter((av) => av._id === oldValue)[0].version
      let newavv = avs.filter((av) => av._id === newValue)[0].version

      if((tempAvs[0].owner === "primary" && !props.isPrimary) || 
      (tempAvs[0].owner === "secondary" && props.isPrimary)) { // Remove the latest v. owned by CPTY (if applicable)
        tempAvs = tempAvs.slice(1) 
      }

      if(tempAvs.filter((av) => av.version === oldavv)[0] !== undefined && // select the AVV that was currently active for Old
      oldavv !== newavv) { // && Ensure it's not the same as the New
        oldavid = tempAvs.filter((av) => av.version === oldavv)[0]._id
      } else { // If not available - take the oldest available
        oldavid = tempAvs.length > 1 ? tempAvs[tempAvs.length -1]._id : tempAvs[0]._id
      }

      if(tempAvs.filter((av) => av.version === newavv)[0] !== undefined) { // select the AVV that was currently active for New
        newavid = tempAvs.filter((av) => av.version === newavv)[0]._id
      } else { // If not available - take the newest available
        newavid = tempAvs[0]._id
      }

      setOldValue(oldavid)
      setNewValue(newavid)
      setAvs(tempAvs)
      setAID(newaid)
      runNewDiff(oldavid, newavid, newaid)
    
    })
  }

  const runNewDiff = (oldAvid, newAvid, aid) => {
    axios.get((props.templating ? '/template/oatvdiff/' : '/agr/agrvdiff/') + oldAvid + '/' + newAvid) // Get users for CPTY org
    .then(function (res) {
      setDiff(res.data.data)
      setLoading(false)
    })
    .catch(function(err) { console.log(err); })
  }

  return (
    <Dialog 
    fullScreen 
    open={props.open} 
    onClose={props.handleDiffDialogClose} 
    TransitionComponent={Transition}
    >
        <Header 
            exitOnly={true}
            exitClick={props.handleDiffDialogClose}
            module="open"
        />

        <Grid container direction="row" justify="center" alignItems="stretch" className={classes.fullFlexGrid}>

        <Box align="left" className={classes.boxResizing} pb={6}>
        {diff === null || loading ? 
        <Box mb={5} align="center">
            <SkeletonCustom type="agreement" />
        </Box>
        :
        <>
        <Box mb={4} align="center">
            <Grid container direction="row">
                <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <Box align="left">
                        <InputLabel>Base for comparison</InputLabel>
                        <Select
                        style={{width: '240px'}}
                        value={oldValue}
                        renderValue={(v) => <Typography>
                          v{userFriendlyAVV(avs.filter((av) => av._id === v)[0].version)} 
                          {([0,1,avs.length - 1].includes(avs.findIndex((av) => av._id === v)) ?
                          <span className={classes.menuDesc}>{avs.findIndex((av) => av._id === v)===0 ? "Current" : avs.findIndex((av) => av._id === v)===1 ? "Previous" : "First"}</span> : '' )}
                        </Typography>}
                        onChange={handleOldChange}
                        label="Base for comparison">
                        {avs.map((av, i) => (
                          <MenuItem key={av._id} value={av._id} className={av._id === newValue && avs.length > 1 ? classes.hide : ''}>
                            
                            <ListItemAvatar>
                              <Avatar 
                              className={classes.menuAvatar}
                              src={
                                props.templating || av.owner === "primary" ? props.primaryLogo : 
                                av.owner === "secondary" ?  props.secondaryLogo : null} />
                            </ListItemAvatar>
                            
                            <ListItemText 
                            primary={<Typography>
                              v{userFriendlyAVV(av.version)} 
                              {([0,1,avs.length - 1].includes(i) ?
                              <span className={classes.menuDesc}>{i===0 ? "Current" : i===1 ? "Previous" : "First"}</span> : '' )}
                            </Typography>}
                            />
                          </MenuItem>
                        ))}
                        </Select>
                        </Box>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <Box align="left">
                        <InputLabel>Compare with</InputLabel>
                        <Select
                        style={{width: '240px'}}
                        value={newValue}
                        renderValue={(v) => <Typography>
                          v{userFriendlyAVV(avs.filter((av) => av._id === v)[0].version)} 
                          {([0,1,avs.length - 1].includes(avs.findIndex((av) => av._id === v)) ?
                          <span className={classes.menuDesc}>{avs.findIndex((av) => av._id === v)===0 ? "Current" : avs.findIndex((av) => av._id === v)===1 ? "Previous" : "First"}</span> : '' )}
                        </Typography>}
                        onChange={handleNewChange}
                        label="Compare with">
                        {avs.map((av, i) => (
                          <MenuItem key={av._id} value={av._id} className={av._id === oldValue && avs.length > 1 ? classes.hide : ''}>
                            
                            <ListItemAvatar>
                              <Avatar 
                              className={classes.menuAvatar}
                              src={
                                props.templating || av.owner === "primary" ? props.primaryLogo : 
                                av.owner === "secondary" ?  props.secondaryLogo : null} />
                            </ListItemAvatar>
                            
                            <ListItemText 
                            primary={<Typography>
                              v{userFriendlyAVV(av.version)} 
                              {([0,1,avs.length - 1].includes(i) ?
                              <span className={classes.menuDesc}>{i===0 ? "Current" : i===1 ? "Previous" : "First"}</span> : '' )}
                            </Typography>}
                            />
                          </MenuItem>
                        ))}
                        </Select>
                        </Box>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>

        {props.agrsAvs.length > 1 ?
        <AgreementExhList 
        variant="dialog" 
        agr={{_id: aid, agrStatus: 'None'}}
        agrs={props.agrsAvs} 
        click={handleClick}
        />
        : '' }

        {diff.map((cl, i) => (
            ['oldTable', 'newTable', 'sameTable'].includes(cl.type) ?
            
            <Box mb={2} mt={2} key={i}>
              { 
                <TableEditable
                  active={false}
                  disabled={true}
                  editMode={'none'}
                  proposedDel={null}
                  focusClause={() => void 0}
                  curCpty={"primary"}
                  diffColor={['oldTable'].includes(cl.type) ? "red" : ['newTable'].includes(cl.type) ? "green" : "none"}
                  clause={{clauseObject: cl.diff}}
                />
              }
            </Box>
            
            :
            <Typography key={i}
            className={
                ['sec'].includes(cl.type) ? classes.secTitle :
                ['ssec'].includes(cl.type) ? classes.ssecTitle :
                ['cltitle'].includes(cl.type) ?  classes.clTitle : classes.cl}
            variant={
                ['sec'].includes(cl.type) ? "h4" :
                ['ssec'].includes(cl.type) ? "h5" :
                ['cltitle'].includes(cl.type) ? "subtitle1" : "body1"}>

            {cl.diff.map((d, j) => (
                d[0] === 1 ?
                  <span className={classes.added} key={j}>

                    {d[1].split('\n').map((item, key) => 
                        <React.Fragment key={"add_" + i + key}>
                        <span style={{display: 'inline'}} >{item}</span>
                        {d[1].split('\n').length !== key + 1 ? <br/> : ''}
                        </React.Fragment>
                    )}

                  </span>
                :d[0] === -1 ?
                  <span className={classes.removed} key={j}>

                    {d[1].split('\n').map((item, key) => 
                        <React.Fragment key={"del_" + i + key}>
                        <span style={{display: 'inline'}} >{item}</span>
                        {d[1].split('\n').length !== key + 1 ? <br/> : ''}
                        </React.Fragment>
                    )}

                  </span>
                :d[0] === 0 ?
                  <span className={classes.normal} key={j}>

                    {d[1].split('\n').map((item, key) => 
                        <React.Fragment key={"add_" + i + key}>
                        <span style={{display: 'inline'}} >{item}</span>
                        {d[1].split('\n').length !== key + 1 ? <br/> : ''}
                        </React.Fragment>
                    )}

                  </span>
                : ''
            ))

            }
            </Typography>
        ))}
        </>
        }
        </Box>

        </Grid>
    </Dialog>

  )
}

export default DialogAVDiff