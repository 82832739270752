function getFilteredAVList(allAvs, isPrimary) {

    let tempAvs = [], prevuseravv = ''
    allAvs.forEach((av) => {
        if((av.owner === 'primary' && isPrimary) || (av.owner === 'secondary' && !isPrimary) || 
        av.version.substr(0,6) !== prevuseravv.substr(0,6)) { tempAvs.push(av); }
        prevuseravv = av.version;
    })
    return tempAvs;
}

export { getFilteredAVList }