import React from 'react';
import { Avatar, Button, Box, Dialog, DialogActions, DialogContent, Divider, 
         IconButton, Slide, Link, Typography,
         makeStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";  

const dayjs = require('dayjs')

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = (props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" style={{position: 'absolute', right: '8px', top: '10px'}} onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} style={{fontSize: '20px', padding: '0px 3px 0px 3px'}} />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

const DialogCertificate = (props) => {

  const useStyles = makeStyles(theme => ({
    cancelButton: {
        marginRight: 'auto',
        color: theme.palette.grey[600],
    },
    mlittle: {
        marginTop: '4px',
        marginBottom: '4px',
    },
    logoAvatar: {
        height: '100px',
        width: '100px',
    },
}));
const classes = useStyles();

const closeDialog = () => {
    props.parentCallToClose()
}

//const history = useHistory()

//const [comments, setComments] = React.useState([]);

return (
    <Dialog
    open={props.open}
    scroll="paper"
    TransitionComponent={Transition}
    keepMounted
    onClose={closeDialog}
    fullWidth={true}
    maxWidth="sm"
    >
        <DialogTitle onClose={closeDialog}>
            {"Certificate Detail"}
        </DialogTitle>
        <Divider />
        <DialogContent id="msgContainer">

            <Box mt={3} mb={3} ml={1} mr={1}>
            
                <Box align="center" mt={3} mb={3}>
                <Avatar src={props.cert.logoURL} alt="Certificate logo" className={classes.logoAvatar}></Avatar>
                </Box>

                <Box align="center">
                    <Typography align="center" variant="h6">
                    {props.cert.title}
                    </Typography>
                    <Typography align="center" variant="body2" color="textSecondary" gutterBottom>
                    Since: {dayjs(props.cert.sinceDate).format('MMM D, YYYY')}
                    </Typography>
                    <Box align="center" mt={2} mb={3}>
                    <Typography align="center" variant="body1" gutterBottom>
                        {props.cert.description}
                    </Typography>
                    {props.cert.referenceURL !== '' && props.cert.referenceURL !== null ? 
                    <Box mt={2}>
                    <Typography align="center" variant="body1">
                        <Link href={props.cert.referenceURL}>More detail can be found here</Link>
                    </Typography>
                    </Box>
                    : '' }
                    </Box>
                </Box>

            </Box>

        </DialogContent>
        <Divider />
        <DialogActions className={classes.mlittle}>
            <Button onClick={closeDialog} variant="outlined" className={classes.cancelButton}>
                Close
            </Button>
        </DialogActions>

    </Dialog>

  )
}

export default DialogCertificate