import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Grid, Typography, 
         makeStyles } from '@material-ui/core';
import { Footer, Header } from '../components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSurprise } from '@fortawesome/free-regular-svg-icons';
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

function Error(props) {
  
  const useStyles = makeStyles(theme => ({
    outerFlexGrid: {
      minHeight: '85vh',
      paddingTop: '100px',
    },
    textBox: {
      [theme.breakpoints.only('xs')]: { width: '325px' },
      [theme.breakpoints.up('sm')]: { width: '440px' },
    },
    errIcon: {
      color: theme.palette.primary.main,
    },
    bold: {
      fontWeight: '700',
    }
  }));
  const classes = useStyles();

  return(
    <div>

      <Header 
        module={props.origin === 'cl' ? "Access Denied" : "oops"}
        user={props.user}
        org={props.org}
        modules={
          props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
            ['sales','procurement','partnerships','people','corporate'] : 
          props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
            props.org.modules : [] }
        crumbs={[
          {name: "Error", to: "active"} ]}
        notifications={props.notifications}
        markNotificationAsRead={props.markNotificationAsRead}
        markAllNotificationsAsRead={props.markAllNotificationsAsRead}
      />

      <Grid container direction="column" justify="center" alignItems="center" className={classes.outerFlexGrid}>

        <Grid item>
        <FontAwesomeIcon icon={props.origin === 'cl' ? faExclamationTriangle : faSurprise} size="6x" className={classes.errIcon} />
        </Grid>
        <Grid item>
        <Box mt={5} className={classes.textBox}>
            <Typography variant="h4" align="center" gutterBottom className={classes.bold}>
            {props.origin === 'cl' ? 
              "Access denied" : 
              "This page could not be found"}
            </Typography>
            <Typography variant="subtitle1" align="center" color="textSecondary">
            {props.origin === 'cl' ? 
              "You may no longer have access to the selected page. Contact Canveo support to clarify." : 
              "There may have been an error during your request. Please contact Canveo support if the error persists."}
            </Typography>
        </Box>
        <Box mt={3} mb={2} align="center">
            <Button component={RouterLink} to="/contact"
            disableElevation color="primary">
            Contact us
            </Button>
        </Box>
        </Grid>

      </Grid>

      <Footer />

    </div>
    );

}
export default Error