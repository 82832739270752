import React, { useEffect } from 'react';
import { Box, Container, Grid, Typography,
         makeStyles } from '@material-ui/core';
import { DrawerCore, Header, ThumbAgr } from '../components'

function Terms(props) {

  const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    outerFlexGrid: {
        minHeight: '80vh',
        paddingTop: '70px',
        paddingBottom: '80px',
    },
    notpub: {
      fontWeight: '600',
      color: theme.palette.grey[700],
      marginBottom: '30px',
      marginTop: '20px',
    },
    boxResizing: {
      [theme.breakpoints.only('xs')]: { width: '300px' },
      [theme.breakpoints.only('sm')]: { width: '540px' },
      [theme.breakpoints.up('md')]: { width: '600px' },
      marginTop: '40px',
      marginBottom: '30px',
    },
    light: {
      fontWeight: '400'
    }
  }));
  const classes = useStyles();

  const [searchOptions, setSearchOptions] = React.useState([]);

  useEffect(() => {
    if(props.agrTypes !== undefined && props.agrTypes !== null) {
      let options = [] // temparray for agrs
      props.orgProfileAgrTemplates
      .filter((oat) => oat.external)
      .forEach((oat) => {
        // Add all agreements to the search options
        let oatSN = oat.agrShortName !== undefined && oat.agrShortName !== '' ? oat.agrShortName :
          (props.agrTypes.filter((at) => at._id === oat.agrTypeID)[0] !== undefined) ? props.agrTypes.filter((at) => at._id === oat.agrTypeID)[0].shortName : ''
        let oatFN = props.agrTypes.filter((at) => at._id === oat.agrTypeID)[0] !== undefined ? props.agrTypes.filter((at) => at._id === oat.agrTypeID)[0].fullName[0] : 
          oat.agrFullName !== '' ? oat.agrFullName : 'Published Terms';

        options.push({
          oatid: oat._id,
          module: oat.module,
          reference: oat.reference,
          agrTypeID: oat.agrTypeID,
          version: oat.version,
          creationDate: oat.creationDate,
          oatShortName: oatSN,
          oatFullName: oatFN,
          clause: 'na',
          title: oatFN + ' (Agreement)',
        });
      })
      setSearchOptions(options);
    }

  }, [props.orgProfileAgrTemplates, props.agrTypes, props.location])

  return(
    <div className={classes.root}>
        <Header 
          module="network"
          user={props.user}
          org={props.org}
          modules={
            props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
              ['sales','procurement','partnerships','people','corporate'] : 
            props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
              props.org.modules : [] }
          orgProfile={props.orgProfile}
          searchOptions={props.network}
          crumbs={[
            {name: "Home", to: "/network" },
            {name: (props.orgProfile.shortName !== undefined)? props.orgProfile.shortName : "", to: "/network/" + props.orgurl},
            {name: "Terms & Agreements", to: "active"} 
          ]}
          notifications={props.notifications}
          markNotificationAsRead={props.markNotificationAsRead}
          markAllNotificationsAsRead={props.markAllNotificationsAsRead}
        />

        <DrawerCore
          agreements={[]}
          page={props.cat === 'all' ? "terms" : props.cat }
          rid=""
          orgurl={props.orgurl}
          showCerts={props.orgProfileCerts.length > 0}
          customurl={props.customurl}
          sections={[]} 
          pageType='profile'
          userLoggedIn={props.user !== undefined && props.user.email !== undefined}
        />

        <Container maxWidth="lg">
        
        <Grid container direction="column" justify="flex-start" alignItems="center" className={classes.outerFlexGrid}>

        <Box align="center">
        {searchOptions.length === 0 ?
        <Typography variant="h5" className={classes.notpub}>
          Terms have not been published (yet).
        </Typography>
        : ''}

        {searchOptions.filter((so) => so.module === 'sales').length > 0 && (props.cat === 'sales' || props.cat === 'all') ?
        <>
          <Box align="center" className={classes.boxResizing}><Typography variant="h3" className={classes.light}>Customers</Typography></Box>

          <Box align="center">
          <Grid container direction="row" alignItems="flex-start" spacing={2}
          justify={searchOptions.filter((so) => so.module === 'sales').length > 3 ? "center" : "center"}>
            {searchOptions.filter((so) => so.module === 'sales').map((opt) => (
            <Grid item key={opt.oatid}>
              <ThumbAgr 
                ag={{
                  agrStatus: 'Published',
                  agrFullName: opt.oatFullName,
                  lastUpdateDate: opt.creationDate,
                }}
                path={"/network/" + props.orgurl + "/" + opt.oatid}
                cptyLogo={props.orgProfile.logoURL}
                primaryLegalName={opt.reference !== undefined && opt.reference !== null && opt.reference !== '' ? opt.reference : 'DEFAULT'}
                secondaryLegalName={'Version ' + opt.version.substr(0,3)}
                actionReq={false}
                fromLegal={true}
                hideDot={true}
              />
            </Grid>
            ))}
          </Grid>
          </Box>
        </> 
        : ''}

        {searchOptions.filter((so) => so.module === 'procurement').length > 0 && (props.cat === 'procurement' || props.cat === 'all') ?
        <>
          <Box align="center" className={classes.boxResizing}><Typography variant="h3" className={classes.light}>Suppliers</Typography></Box>

          <Grid container direction="row" alignItems="flex-start" spacing={2}
          justify={searchOptions.filter((so) => so.module === 'procurement').length > 3 ? "flex-start" : "center"}>
            {searchOptions.filter((so) => so.module === 'procurement').map((opt) => (
            <Grid item key={opt.oatid}>
              <ThumbAgr 
                ag={{
                  agrStatus: 'Published',
                  agrFullName: props.agrTypes.filter((at) => at._id === opt.agrTypeID)[0] !== undefined ? props.agrTypes.filter((at) => at._id === opt.agrTypeID)[0].fullName[0] : 
                    opt.oatFullName !== '' ? opt.oatFullName : 'Published Terms',
                  lastUpdateDate: opt.creationDate,
                }}
                path={"/network/" + props.orgurl + "/" + opt.oatid}
                cptyLogo={props.orgProfile.logoURL}
                primaryLegalName={opt.reference !== undefined && opt.reference !== null && opt.reference !== '' ? opt.reference : 'DEFAULT'}
                secondaryLegalName={'Version ' + opt.version.substr(0,3)}
                actionReq={false}
                fromLegal={true}
                hideDot={true}
              />
            </Grid>
            ))}
          </Grid>
        </> 
        : ''}

        {searchOptions.filter((so) => so.module === 'partnerships').length > 0 && (props.cat === 'partnerships' || props.cat === 'all') ?
        <>
          <Box align="center" className={classes.boxResizing}><Typography variant="h3" className={classes.light}>Partners</Typography></Box>

          <Grid container direction="row" alignItems="flex-start" spacing={2}
          justify={searchOptions.filter((so) => so.module === 'partnerships').length > 3 ? "flex-start" : "center"}>
            {searchOptions.filter((so) => so.module === 'partnerships').map((opt) => (
            <Grid item key={opt.oatid}>
              <ThumbAgr 
                ag={{
                  agrStatus: 'Published',
                  agrFullName: props.agrTypes.filter((at) => at._id === opt.agrTypeID)[0] !== undefined ? props.agrTypes.filter((at) => at._id === opt.agrTypeID)[0].fullName[0] : 
                    opt.oatFullName !== '' ? opt.oatFullName : 'Published Terms',
                  lastUpdateDate: opt.creationDate,
                }}
                path={"/network/" + props.orgurl + "/" + opt.oatid}
                cptyLogo={props.orgProfile.logoURL}
                primaryLegalName={opt.reference !== undefined && opt.reference !== null && opt.reference !== '' ? opt.reference : 'DEFAULT'}
                secondaryLegalName={'Version ' + opt.version.substr(0,3)}
                actionReq={false}
                fromLegal={true}
                hideDot={true}
              />
            </Grid>
            ))}
          </Grid>
        </> 
        : ''}

        {searchOptions.filter((so) => so.module === 'privacy').length > 0 && (props.cat === 'privacy' || props.cat === 'all') ?
        <>
          <Box align="center" className={classes.boxResizing}><Typography variant="h3" className={classes.light}>Privacy</Typography></Box>

          <Grid container direction="row" alignItems="flex-start" spacing={2}
          justify={searchOptions.filter((so) => so.module === 'privacy').length > 3 ? "flex-start" : "center"}>
            {searchOptions.filter((so) => so.module === 'privacy').map((opt) => (
            <Grid item key={opt.oatid}>
              <ThumbAgr 
                ag={{
                  agrStatus: 'Published',
                  agrFullName: props.agrTypes.filter((at) => at._id === opt.agrTypeID)[0] !== undefined ? props.agrTypes.filter((at) => at._id === opt.agrTypeID)[0].fullName[0] : 
                    opt.oatFullName !== '' ? opt.oatFullName : 'Published Terms',
                  lastUpdateDate: opt.creationDate,
                }}
                path={"/network/" + props.orgurl + "/" + opt.oatid}
                cptyLogo={props.orgProfile.logoURL}
                primaryLegalName={opt.reference !== undefined && opt.reference !== null && opt.reference !== '' ? opt.reference : 'DEFAULT'}
                secondaryLegalName={'Version ' + opt.version.substr(0,3)}
                actionReq={false}
                fromLegal={true}
                hideDot={true}
              />
            </Grid>
            ))}
          </Grid>
        </> 
        : ''}
        
        </Box>
        </Grid>
        </Container>
        
    </div>
  )
}
  
export default Terms