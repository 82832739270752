import React from 'react';
import { FormControlLabel, IconButton, Menu, MenuItem, Tooltip, Typography, makeStyles, Checkbox } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faPlusCircle, faTimesCircle, faBan, faSortAmountDown, faQuestionCircle, 
         faArrowsAltH, faHeading, faFont, faLock, faUnlock } from "@fortawesome/free-solid-svg-icons";

// CLAUSE LEVEL
const MenuMoreOptions = (props) => {

    const useStyles = makeStyles(theme => ({
        clauseMenuText: {
            fontWeight: '500',
            paddingRight: '6px',
        },
        iconErr: {
            color: theme.palette.error.main,
            paddingRight: '14px',
        },
        iconSuc: {
            color: theme.palette.success.main,
            paddingRight: '14px',
        },
        iconPrim: {
            color: theme.palette.primary.main,
            paddingRight: '14px',
        },
        clausePush: {
            color: theme.palette.grey[600],
            padding: '10px 14px 10px 14px',
            fontSize: '14px',
            marginTop: '1px'
        },
        insertIcon: {
            color: theme.palette.grey[600],
            padding: '10px 10px 10px 10px',
            fontSize: '14px',
            marginTop: '1px'
        },
        deleteIcon: {
            color: theme.palette.grey[600],
            padding: '10px 10px 10px 10px',
            fontSize: '13px',
            marginTop: '0px'
        },
        clauseCircle: {
            color: theme.palette.grey[600],
            padding: '10px 10px 10px 10px',
            fontSize: '14px',
            marginTop: '1px'
        },
        clauseCircleGreen: {
            color: theme.palette.success.main,
            padding: '10px 10px 10px 10px',
            fontSize: '14px',
            marginTop: '1px'
        },
        clauseCircleRed: {
            color: theme.palette.error.main,
            padding: '10px 10px 10px 10px',
            fontSize: '14px',
            marginTop: '1px'
        },
        subsectionPush: {
            color: theme.palette.grey[600],
            padding: '11px 15px 11px 15px',
            fontSize: '14px',
            marginTop: '19px'
        },
        sectionPush: {
            color: theme.palette.grey[600],
            padding: '11px 15px 11px 15px',
            fontSize: '14px',
            marginTop: '25px'
        },
        signMoreOptions: {
            fontSize: '14px',
            color: theme.palette.grey[600],
        },
        signIconB: {
            marginTop: '0px',
            padding: '9px 14px 9px 14px',
        },
    }));
    const classes = useStyles();

    const [anchorClEl, setAnchorClEl] = React.useState(null);
    const [lockClick, setLockClick] = React.useState(false)
    const [lockStatus, setLockStatus] = React.useState(props.lockedByOrg !== undefined && props.lockedByOrg !== null && props.lockedByOrg.type !== undefined ? props.lockedByOrg.type : 'none')

    const handleClClick = event => {
        //if(props.lockedByOrg !== undefined && props.lockedByOrg !== null && props.lockedByOrg.type !== undefined/* &&
        //props.lockedByOrg.orgID === props.user.orgID*/) { // initialize the lockstatus
        //    setLockStatus(props.lockedByOrg.type)
        //}
        setAnchorClEl(event.currentTarget);
    };

    const handleClClose = () => {
        
        if(props.lockedByOrg !== undefined && props.lockedByOrg !== null && props.lockedByOrg.type !== undefined &&
        lockStatus !== props.lockedByOrg.type) {
            props.parentAction("newlock_" + lockStatus, props.clid)
        }
        setLockClick(false)
        setAnchorClEl(null);
    };

    const handleClick = (type) => {
        props.parentAction(type, props.clid)
        handleClClose();
    }

    const handleLockClick = () => {
        setLockClick(true)
    }

    const handleLockUpdate = (type, value) => {
        let ls = lockStatus;
        if(ls === 'none' && value) { ls = type; } // 'internal' or 'cpty 
        else if(ls === 'full' && type === 'internal' && !value) { ls = 'cpty'; }
        else if(ls === 'full' && type === 'cpty' && !value) { ls = 'internal'; }
        else if(ls === 'cpty' && type === 'cpty' && !value) { ls = 'none'; }
        else if(ls === 'cpty' && type === 'internal' && value) { ls = 'full'; }
        else if(ls === 'internal' && type === 'internal' && !value) { ls = 'none'; }
        else if(ls === 'internal' && type === 'cpty' && value) { ls = 'full'; }
        setLockStatus(ls)
    }
  
    return (
        <React.Fragment>

            {['sec','ssec','cl', 'cl1', 'cl2', 'cl3', 'cl4', 'table', 'preamble'].includes(props.level) ?
            <>
                {['clauseTitle', 'clausePar'].includes(props.blockStyleButton) && ['cl', 'cl1', 'cl2', 'cl3', 'cl4'].includes(props.level) ? 
                <Tooltip title={['clausePar'].includes(props.blockStyleButton) ? "Change from Title to Normal Text" : "Make Clause Title"} arrow>
                <IconButton onClick={e => handleClick("insertTitle")} className={classes.clauseCircle}>
                    <FontAwesomeIcon icon={['clausePar'].includes(props.blockStyleButton) ? faFont : faHeading} />
                </IconButton>
                </Tooltip>
                :''}
                
                <Tooltip title="Insert Clause" arrow>
                <IconButton onClick={e => handleClick("insert")} className={classes.clauseCircleGreen}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                </IconButton>
                </Tooltip>
                
                <Tooltip title={"Delete" + (['cl', 'cl1', 'cl2', 'cl3', 'cl4'].includes(props.level) ? " Clause" : 
                    ['table'].includes(props.level) ? " Table" : " Section")} arrow>
                <IconButton onClick={e => handleClick("delete")} className={classes.clauseCircleRed}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                </IconButton>
                </Tooltip>
            </>
            : '' }
            
            <Tooltip title="More options" arrow>
            <IconButton onClick={handleClClick} className={props.level === 'signFlow' ? classes.signIconB : classes.clausePush}>
                <FontAwesomeIcon 
                    icon={faEllipsisV}
                    className={props.level === 'signFlow' ? classes.signMoreOptions : ''} />
            </IconButton>
            </Tooltip>

            <Menu
                id="clausemore-menu"
                anchorEl={anchorClEl}
                keepMounted
                open={Boolean(anchorClEl)}
                onClose={handleClClose}
            >
        
            {!lockClick && props.level === 'signFlow' ?
            <MenuItem onClick={e => handleClick("cancelSignFlow")}>
                <Typography variant="body2" className={classes.clauseMenuText}>
                    <FontAwesomeIcon icon={faBan} className={classes.iconErr} />
                    Cancel signature request
                </Typography>
            </MenuItem>
            : '' }

            {!lockClick && props.level !== 'signFlow' && props.level !== 'preamble' ?
            <MenuItem onClick={
                ['sec', 'ssec', 'cl', 'cl1', 'cl2', 'cl3', 'cl4'].includes(props.level) ? e => handleClick("moveClause") : 
                props.level === 'table' ? e => handleClick("moveTable") : null} >
                <Typography variant="body2" className={classes.clauseMenuText}>
                    <FontAwesomeIcon icon={faSortAmountDown} className={classes.iconPrim} />
                    Move{['cl', 'cl1', 'cl2', 'cl3', 'cl4'].includes(props.level) ? " Clause" : 
                         props.level === 'ssec' ? " Sub section" : 
                         props.level === 'sec' ? " Section" : 
                         props.level === 'table' ? " Table" : ""}
                </Typography>
            </MenuItem>
            : '' }

            {!lockClick && ['sec','ssec','cl1', 'cl2', 'cl3', 'cl4'].includes(props.level) ?
            <MenuItem onClick={e => handleClick("levelClause")} >
                <Typography variant="body2" className={classes.clauseMenuText}>
                    <FontAwesomeIcon icon={faArrowsAltH} className={classes.iconPrim} />
                    Level up / down
                </Typography>
            </MenuItem>
            : '' }

            {!lockClick && ['cl', 'cl1', 'cl2', 'cl3', 'cl4', 'preamble'].includes(props.level) && !props.templating ?
            <MenuItem onClick={e => handleClick("lightbulb")} >
                <Typography variant="body2" className={classes.clauseMenuText}>
                    <FontAwesomeIcon icon={faQuestionCircle} className={classes.iconPrim} />
                    How to edit
                </Typography>
            </MenuItem>
            : '' }

            {!lockClick && props.user !== null && ['Admin','Legal'].includes(props.user.role) && props.clauseLock && 
            (props.lockedByOrg === undefined || props.lockedByOrg === null || props.lockedByOrg.type === undefined || 
            (props.lockedByOrg !== undefined && props.lockedByOrg.type === 'none') || 
            (props.lockedByOrg !== undefined && props.lockedByOrg.type !== 'none' && props.lockedByOrg.orgID === props.user.orgID)) ? 
            <MenuItem /*onClick={e => setLockClick(true)}*/ onClick={handleLockClick} >
                <Typography variant="body2" className={classes.clauseMenuText}>
                    <FontAwesomeIcon 
                    icon={
                        (props.lockedByOrg === undefined || props.lockedByOrg === null || props.lockedByOrg.type === undefined || 
                        (props.lockedByOrg !== undefined && props.lockedByOrg.type === 'none')) ? faLock : faUnlock} className={classes.iconPrim} />
                    {(props.lockedByOrg === undefined || props.lockedByOrg === null || props.lockedByOrg.type === undefined || 
                        (props.lockedByOrg !== undefined && props.lockedByOrg.type === 'none')) ? "Apply": "Update"} clause lock
                </Typography>
            </MenuItem>
            /*:!lockClick && props.user !== null && ['Admin','Legal'].includes(props.user.role) && props.lockedByOrg !== undefined && 
                props.lockedByOrg !== null && props.lockedByOrg.type !== undefined && props.lockedByOrg.type !== 'none' ?
            <MenuItem onClick={handleLockClick} >
                <Typography variant="body2" className={classes.clauseMenuText}>
                    <FontAwesomeIcon icon={faUnlock} className={classes.iconPrim} />
                    Update clause lock
                </Typography>
            </MenuItem>*/
            :''}

            {lockClick && props.user !== null && ['Admin','Legal'].includes(props.user.role) ?
            <MenuItem /*onClick={e => handleClick("lockCpty")} */ style={{padding: '0px 0px 0px 15px'}}>
                <FormControlLabel 
                control={<Checkbox color="primary" size="small" checked={['full', 'cpty'].includes(lockStatus)} onClick={e => handleLockUpdate('cpty', e.target.checked)} />} 
                label={<Typography variant="body2" className={classes.clauseMenuText}>Lock for counterparty editing</Typography>} />
            </MenuItem>
            :''}

            {lockClick && props.user !== null && ['Admin','Legal'].includes(props.user.role) ?
            <MenuItem /*onClick={e => handleClick("lockFull")} */ style={{padding: '0px 0px 0px 15px'}}>
                <FormControlLabel 
                control={<Checkbox color="primary" size="small" checked={['full', 'internal'].includes(lockStatus)} onClick={e => handleLockUpdate('internal', e.target.checked)} />} 
                label={<Typography variant="body2" className={classes.clauseMenuText}>Lock for internal users except Legal or Admin</Typography>} />
            </MenuItem>
            : '' }

            </Menu>

        </React.Fragment>      
    )

}

export default MenuMoreOptions