import React, { useEffect } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { getActorsForModule } from '../utils';
import { Box, Button, Checkbox, Card, CardContent, CardActions, Chip, 
         IconButton, FormControlLabel, Grid,
         TextField, Tooltip, Radio, RadioGroup, 
         makeStyles, 
         Typography} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSquare, faCheckSquare, faPen, faTag, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';

const axios = require('axios');

const Labels = (props) => {

    const useStyles = makeStyles(theme => ({
        stdChip: {
            marginRight: '10px',
            marginBottom: '10px',
        },
        addChip: {
            [theme.breakpoints.down('sm')]: { display: 'none' },
            zIndex: '1200',
            position: 'fixed',
            bottom: '20px',
            left: '0px',
            width: '85px',
            color: theme.palette.primary.main,
            fontSize: '13px',
            fontWeight: '700',
            '&:hover': {
                cursor: 'pointer',
                color: theme.palette.secondary.main,
            }
        },
        inactiveChip: {
            backgroundColor: theme.palette.grey[500],
            marginRight: '10px',
            marginBottom: '10px',
        },
        textInput:{
            maxWidth: '420px',
        },
        card: {
            maxWidth: '280px',
            padding: '10px',
        },
        listBox: {
            //border: '1px solid #e0e0e0',
            //borderRadius: '10px',
        },
        divider: {
            margin: '8px 0px 8px 0px'
        },
        bold: {
            fontWeight: '700',
        },
        iconB1: {
            margin: '1px 0px 0px 0px',
            padding: '8px 8px 8px 8px',
        },
        iconB2: {
            margin: '0px',
            padding: '8px 10px 8px 10px',
        },
        iconB1icon: {
            fontSize: '12px',
        },
        iconB2icon: {
            fontSize: '14px',
        },
        pushRight: {
            marginRight: 'auto',
        },
        iconDelete: {
            fontSize: '13px',
            padding: '0px 8px 0px 2px'
        }
    }));
    const classes = useStyles();
    const history = useHistory()

    const modules = ['corporate', 'sales', 'partnerships', 'people', 'procurement']

    const [labels, setLabels] = React.useState([])
    const [addingLabel, setAddingLabel] = React.useState(false);
    const [selectType, setSelectType] = React.useState(props.orgLabel !== undefined && labels !== undefined && props.orgLabel.filter((ol) => !labels.some((l) => l.orgLabelID === ol._id)).length === 0 ? 'create' : 'select');
    const [activeLabel, setActiveLabel] = React.useState(null)
    const [editingLabel, setEditingLabel] = React.useState(null)

    const [labelName, setLabelName] = React.useState('')
    const [labelDesc, setLabelDesc] = React.useState('')
    const [labelModules, setLabelModules] = React.useState(modules.filter((m) => m.module === props.module))
    const [labelError, setLabelError] = React.useState(null)

    const labelToReactivate = props.level === 'dash' && props.orgLabel[0] !== undefined ? props.orgLabel[0] : null

    useEffect(() => {
        let unmounted = false;
  
        if(props.orgLabel !== undefined && props.orgLabel !== null && props.user !== undefined && props.user.orgID !== undefined &&
        ['rel','agr'].includes(props.level)) {
          axios.get('/orglabel/orglabellink/' + props.user.orgID + "/" + (props.level === 'rel' ? props.relID : props.agrID) )
          .then((resoll) => {
            if (!unmounted) { setLabels(resoll.data.data) }
          }).catch((err) => { console.log(err) })
        }

        return () => { unmounted = true };

      }, [props.orgLabel, props.user, props.level, props.relID, props.agrID])

    const handleClickLabel = (lid) => {
        setActiveLabel(lid)
    }

    const handleAddLabel = () => {
        setSelectType(props.orgLabel !== undefined && props.orgLabel.filter((ol) => !labels.some((l) => l.orgLabelID === ol._id)).length === 0 ? 'create' : 'select')
        setAddingLabel(true)
    }

    const handleCancel = () => {
        setAddingLabel(false)
        setLabelError(null)
        setActiveLabel(null)
        setEditingLabel(null)
    }

    const handleSelectLabel = (orgLabelID) => {
        axios.post('/orglabel/orglabellink', {
            orgID: props.user.orgID,
            orgLabelID: orgLabelID,
            relID: props.level === 'rel' ? props.relID : '',
            agrID: props.level === 'agr' ? props.agrID : '',
        }).then(function(resol) {

            setLabels(labels => [...labels, resol.data.data])
            setAddingLabel(false)

        }).catch(function (err) { console.log("err adding orgLabelLink") })
    }

    const handleModules = (value) => {

        let newArr = labelModules, update = []

        value.forEach((m) => {
            if(!labelModules.includes(m)) {
                update.push({ type: "add", module: m})
            }
        })
        labelModules.forEach((m) => {
            if(!value.includes(m)) {
                update.push({ type: "remove", module: m})
            }
        })

        update.forEach((u) => {
            if(['Admin','Legal'].includes(props.user.role) || 
                (['Business'].includes(props.user.role) && ['sales', 'procurement', 'partnerships'].includes(u.module)) ||
                (['Sales'].includes(props.user.role) && ['sales'].includes(u.module)) ||
                (['Procurement'].includes(props.user.role) && ['procurement'].includes(u.module)) ||
                (['Partnerships'].includes(props.user.role) && ['partnerships'].includes(u.module)) ||
                (['Corporate'].includes(props.user.role) && ['corporate'].includes(u.module)) ||
                (['HR'].includes(props.user.role) && ['people'].includes(u.module)) ||
                (props.user.role !== undefined && props.user.role.startsWith('ClientFor_'))) { // You're allowed to make the change
                
                if(u.type === "add") { newArr = newArr.concat(u.module) }
                else if(u.type === "remove") { newArr = newArr.filter((na) => na !== u.module) }
            }
        })

        setLabelModules(newArr)

    }

    const handleSubmitNewLabel = () => {
        setLabelError(null)
        if(labelName.length < 2 || labelDesc.length < 2 || labelModules.length < 1){
            setLabelError("Please ensure to complete all fields.")
        } else if(props.orgLabel.some((ol) => ol.name === labelName) && editingLabel === null) {
            setLabelError("This label already exists for your organisation.")
        } else if(editingLabel !== null) {
            props.updateOrgLabel(editingLabel, labelName, labelDesc, labelModules, false, props.user._id)
        } else {
            let applyTo = { type: props.level, id: props.level === 'rel' ? props.relID : props.agrID } // agrID n/a for now
            props.createOrgLabel(props.user.orgID, labelName, labelDesc, labelModules, false, props.user._id, applyTo)
        }
    }

    const deactivateLabel = () => {
        let ol = props.orgLabel.filter((ol) => ol._id === editingLabel)[0]
        if(ol !== undefined){
            props.updateOrgLabel(editingLabel, ol.name, ol.desc, ol.modules, true, props.user._id)
        }
    }

    const reactivateLabel = () => {
        if(labelToReactivate !== undefined && labelToReactivate._id !== undefined){
            props.updateOrgLabel(labelToReactivate._id, labelToReactivate.name, labelToReactivate.desc, labelToReactivate.modules, !labelToReactivate.deactive, props.user._id)
            history.push('/core/' + props.module + '/dashboard')
        }
    }

    const deleteLabel = () => {
        if(labelToReactivate !== undefined && labelToReactivate._id !== undefined){
            props.deleteOrgLabel(labelToReactivate._id)
            history.push('/core/' + props.module + '/dashboard')
        }
    }

    const handleEditingLabel = () => {
        let l = labels.filter((lb) => lb._id === activeLabel)[0]
        if(l !== undefined && props.orgLabel.filter((ol) => ol._id === l.orgLabelID)[0] !== undefined){
            let ol = props.orgLabel.filter((ol) => ol._id === l.orgLabelID)[0]
            setLabelName(ol.name)
            setLabelDesc(ol.desc)
            setLabelModules(ol.modules)
            setEditingLabel(l.orgLabelID)
        }
    }

    const handleRemoveLabel = () => {
        axios.delete('/orglabel/orglabellink/' + activeLabel)
        .then((resdel) => {
            setLabels(labels.filter((l) => l._id !== activeLabel))
            handleCancel()
        }).catch((err) => { console.log(err) })
    }

    const handleSelectType = (val) => {
        if(val === 'create') {
            setLabelName('')
            setLabelDesc('')
            setLabelModules([props.module])
        }
        setSelectType(val)
    }
  
    return (
        <React.Fragment>

            {labelToReactivate !== null ?
            <>
            <Box mb={2}>
                <Typography variant="h6" className={classes.bold}>{labelToReactivate.name}</Typography>
                <Typography variant="h6" color={labelToReactivate.deactive ? "error" : "textPrimary"}>{labelToReactivate.deactive ? "(Deactive)" : "(Active)"}</Typography>
            </Box>
            
            <Box mb={4}>
                <Typography variant="body1">{labelToReactivate.desc}</Typography>
            </Box>

            <Box mb={2} className={classes.textInput}>
                <Button color="secondary" variant="contained" disableElevation fullWidth
                onClick={reactivateLabel}>
                    {labelToReactivate.deactive ? "R":"D"}eactivate label across relationships
                </Button>
            </Box>
            <Box mb={2} className={classes.textInput}>
                <Button color="secondary" variant="outlined" disableElevation fullWidth
                onClick={deleteLabel}>
                    Delete label
                </Button>
            </Box>

            </>
            :addingLabel || editingLabel !== null ?
            <>
                <Box mb={2}>
                    <Typography variant="h6" className={classes.bold}>
                        {editingLabel !== null ? "Edit label across relationships" : "Apply a label"}
                    </Typography>
                </Box>
                {editingLabel === null ? // Only show when adding a label
                <Box align="center" mb={3}>
                    <RadioGroup style={{width: '315px'}}
                    row name="selectType" value={selectType} onChange={e => handleSelectType(e.target.value)}>
                        <FormControlLabel value="select" control={<Radio disabled={props.orgLabel !== undefined && props.orgLabel.filter((ol) => !labels.some((l) => l.orgLabelID === ol._id)).length === 0} />} label="Select a Label" />
                        <FormControlLabel value="create" control={<Radio />} label="Create new Label" />
                    </RadioGroup>
                </Box>
                : '' }

                {selectType === 'select' && editingLabel === null ? // Only possible when Adding a label
                <Box mb={3}>
                    {props.orgLabel
                    .filter((ol) => !labels.some((l) => l.orgLabelID === ol._id) && ol.modules.includes(props.module))
                    .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                    .map((ol, i) => (
                    <Tooltip key={ol._id} placement="top"
                    title={ol.desc}>
                    <Chip
                    variant="default" 
                    color="secondary" 
                    label={ol.name}
                    className={classes.stdChip}
                    style={props.orgLabel.filter((ol) => !labels.some((l) => l.orgLabelID === ol._id)).length === i + 1 ? 
                        { marginRight: '0px'} : {}}
                    onClick={e=> handleSelectLabel(ol._id)} 
                    />
                    </Tooltip>
                    ))}
                </Box>
                :
                <Box mb={3}>
                    {labelError !== null ?
                    <Box mb={2}>
                        <Typography variant="subtitle1" color="error">{labelError}</Typography>
                    </Box>
                    :''}
                    <Box className={classes.textInput}>
                        <TextField label="Label" variant="outlined" fullWidth value={labelName} onChange={e => setLabelName(e.target.value)} />
                    </Box>
                    <Box mt={2} className={classes.textInput}>
                        <TextField label="Description" variant="outlined" fullWidth value={labelDesc} onChange={e => setLabelDesc(e.target.value)} />
                    </Box>
                    <Box mt={2} className={classes.textInput}>
                        <Autocomplete
                            multiple
                            onChange={(e, value) => handleModules(value)}
                            value={labelModules}
                            options={modules}
                            disableCloseOnSelect
                            getOptionLabel={(option) => getActorsForModule(option)}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                <Checkbox
                                    icon={<FontAwesomeIcon icon={faSquare} />}
                                    checkedIcon={<FontAwesomeIcon icon={faCheckSquare} />}
                                    style={{ marginRight: 8 }}
                                    //checked={labelModules.includes(option)}
                                    checked={selected}
                                    disabled={!(['Admin','Legal'].includes(props.user.role) || 
                                        (['Business'].includes(props.user.role) && ['sales', 'procurement', 'partnerships'].includes(option)) ||
                                        (['Sales'].includes(props.user.role) && ['sales'].includes(option)) ||
                                        (['Procurement'].includes(props.user.role) && ['procurement'].includes(option)) ||
                                        (['Partnerships'].includes(props.user.role) && ['partnerships'].includes(option)) ||
                                        (['Corporate'].includes(props.user.role) && ['corporate'].includes(option)) ||
                                        (['HR'].includes(props.user.role) && ['people'].includes(option)) ||
                                        (props.user.role !== undefined && props.user.role.startsWith('ClientFor_')))}
                                />
                                {getActorsForModule(option)}
                                </React.Fragment>
                            )}
                            //style={{ width: 500 }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Select Modules" placeholder="Modules where label could apply" />
                            )}
                        />
                    </Box>
                    <Box mt={2} className={classes.textInput}>
                        <Button color="primary" variant="contained" disableElevation fullWidth onClick={handleSubmitNewLabel}>
                            {editingLabel !== null ? "Update this label" : "Create and apply label"}
                        </Button>
                    </Box>
                    {editingLabel !== null && 
                    props.orgLabel.filter((ol) => ol._id === editingLabel)[0] !== undefined &&
                    props.orgLabel.filter((ol) => ol._id === editingLabel)[0].modules !== undefined &&
                    (['Admin','Legal'].includes(props.user.role) ||
                    (['Business'].includes(props.user.role) && !props.orgLabel.filter((ol) => ol._id === editingLabel)[0].modules.some((m) => ['corporate', 'people'].includes(m))) ||
                    (['Sales'].includes(props.user.role) && !props.orgLabel.filter((ol) => ol._id === editingLabel)[0].modules.some((m) => ['corporate', 'people', 'procurement', 'partnerships'].includes(m))) ||
                    (['Procurement'].includes(props.user.role) && !props.orgLabel.filter((ol) => ol._id === editingLabel)[0].modules.some((m) => ['corporate', 'people', 'sales', 'partnerships'].includes(m))) ||
                    (['Partnerships'].includes(props.user.role) && !props.orgLabel.filter((ol) => ol._id === editingLabel)[0].modules.some((m) => ['corporate', 'people', 'procurement', 'sales'].includes(m))) ||
                    (['Corporate'].includes(props.user.role) && !props.orgLabel.filter((ol) => ol._id === editingLabel)[0].modules.some((m) => ['sales', 'people', 'procurement', 'partnerships'].includes(m))) ||
                    (['HR'].includes(props.user.role) && !props.orgLabel.filter((ol) => ol._id === editingLabel)[0].modules.some((m) => ['corporate', 'sales', 'procurement', 'partnerships'].includes(m)))) ? 
                    <Box mt={2} className={classes.textInput}>
                    <Button color="secondary" variant="outlined" disableElevation fullWidth
                    onClick={deactivateLabel}>
                        Deactivate label across relationships
                    </Button>
                    </Box>
                    : '' }
                </Box>
                }
                <Box>
                    <Button color="primary" variant="outlined" onClick={handleCancel}>
                        <FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Cancel
                    </Button>
                </Box>
            </>
            :labels !== undefined && labels !== null ?
            <>
            {labels
            .filter((l) => props.orgLabel.some((ol) => ol._id === l.orgLabelID && !ol.deactive))
            .map((l,i) => (
                <Chip
                key={l._id}
                variant="default" 
                color="secondary" 
                label={props.orgLabel.filter((ol) => ol._id === l.orgLabelID)[0] !== undefined ?
                    props.orgLabel.filter((ol) => ol._id === l.orgLabelID)[0].name : 'Unknown Label'}
                className={activeLabel !== null && activeLabel !== l._id ? classes.inactiveChip : classes.stdChip}
                style={labels.filter((l) => props.orgLabel.some((ol) => ol._id === l.orgLabelID && !ol.deactive)).length === i + 1 ? 
                    { marginRight: '0px'} : {}}
                onClick={e => handleClickLabel(l._id)} 
                onDelete={activeLabel !== null && activeLabel === l._id ? e => handleRemoveLabel() : null}
                deleteIcon={<FontAwesomeIcon icon={faTrash} className={classes.iconDelete} />}
                />
            ))
            }

            <Box className={classes.addChip} onClick={e => handleAddLabel()}>
                <Box mb={1}><FontAwesomeIcon icon={faTag} /></Box>
                Add Label
            </Box>

            {activeLabel !== null && labels.length > 0 && labels.filter((l) => l._id === activeLabel)[0] !== undefined &&
            props.orgLabel.filter((ol) => ol._id === labels.filter((l) => l._id === activeLabel)[0].orgLabelID)[0] !== undefined ?

            <Box mt={3}>

                <Card variant="outlined" className={classes.card}>
                <CardContent>
                    <Grid container direction="row" justify="space-between" alignItems="flex-start">
                        <Grid item>
                            <IconButton className={classes.iconB1} onClick={handleEditingLabel}>
                                <FontAwesomeIcon icon={faPen} className={classes.iconB1icon} />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" className={classes.bold}>
                                {props.orgLabel.filter((ol) => ol._id === labels.filter((l) => l._id === activeLabel)[0].orgLabelID)[0].name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton className={classes.iconB2} onClick={handleCancel}>
                                <FontAwesomeIcon icon={faTimes} className={classes.iconB2icon} />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Box mt={2}>
                    <Typography variant="body1">
                    {props.orgLabel.filter((ol) => ol._id === labels.filter((l) => l._id === activeLabel)[0].orgLabelID)[0].desc}
                    </Typography>
                    </Box>
                </CardContent>
                <CardActions>
                    <Button size="small" color="secondary" className={classes.pushRight} component={RouterLink} to={"/core/" + props.module + "/dashboard/label/rel/" + labels.filter((l) => l._id === activeLabel)[0].orgLabelID}>Relationships</Button>
                    <Button size="small" color="secondary" component={RouterLink} to={"/core/" + props.module + "/dashboard/label/agr/" + labels.filter((l) => l._id === activeLabel)[0].orgLabelID}>Agreements</Button>
                </CardActions>
                </Card>

                {/*
                <Typography variant="subtitle1">{props.orgLabel.filter((ol) => ol._id === labels.filter((l) => l._id === activeLabel)[0].orgLabelID)[0].desc}</Typography>

                <Box mt={3} className={classes.menuBox}>
                    <List component="nav" className={classes.listBox}>
                        <ListItem button>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faHandshake} />
                        </ListItemIcon>
                        <ListItemText primary="View all relationships" />
                        </ListItem>
                        <ListItem button>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faFileContract} />
                        </ListItemIcon>
                        <ListItemText primary="View all agreements" />
                        </ListItem>
                    </List>
                </Box>
                */}

                {/*
                <Divider className={classes.divider} />

                <ListItem button onClick={handleRemoveLabel}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={faTimes} />
                </ListItemIcon>
                <ListItemText primary="Remove this label" />
                </ListItem>
                <ListItem button onClick={handleEditingLabel}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={faPen} />
                </ListItemIcon>
                <ListItemText primary="Edit this label" />
                </ListItem>

                <Divider className={classes.divider} />

                <ListItem button onClick={handleCancel}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </ListItemIcon>
                <ListItemText primary="Cancel" />
                </ListItem>
                */}

            </Box>
            : '' }
            </>
            : '' }

        </React.Fragment>      
    )
}

export default Labels