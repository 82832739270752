import React, { useEffect } from 'react';
import theme from '../theme/theme';
import { server, randomString as generateRandomString } from '../utils'
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { DrawerCore, ErrorMsg, Header } from '../components'
import { Avatar, Box, Button, CircularProgress, Container, Grid, IconButton, InputAdornment, TextField, Typography,
         makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faExclamationTriangle, faKey, faEnvelopeOpenText, faUserCheck, faArrowRotateLeft, faEye, faEyeSlash, faSignInAlt } from "@fortawesome/free-solid-svg-icons";

import Google from '../assets/img/icon_google.png';
import Slack from '../assets/img/icon_slack.png';

const axios = require('axios');

function Login(props) {

  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    outerFlexGrid: {
      minHeight: '100vh',
      paddingTop: '70px',
      paddingBottom: '80px',
    },
    outerFlexGridBasic: {
      minHeight: '100vh',
      paddingTop: '70px',
      paddingBottom: '10px',
    },
    orgLogo: {
      height: '100px',
      width: '100px',
      marginTop: '40px',
      marginBottom: '60px',
    },
    iconError: {
      color: theme.palette.error.main,
      fontSize: '80px',
    },
    clink: {
      color: theme.palette.primary.main,
      fontWeight: '500',
      fontSize: '13px',
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.secondary.main,
        cursor: 'pointer',
      },
      display: 'inline',
    },
    iconImgPl: {
      width: '20px',
      height: 'auto',
      padding: '5px 0px 5px 10px',
    },
    iconImgPr: {
      width: '20px',
      height: 'auto',
      padding: '5px 10px 5px 0px',
    },
    ptFont: {
      [theme.breakpoints.only('xs')]: { paddingBottom: '80px' },
      [theme.breakpoints.only('sm')]: { paddingBottom: '50px' },
      paddingTop: '5px',
      fontSize: '13px',
    },
    titleText: {
      [theme.breakpoints.only('xs')]: { fontSize: '20px' },
      [theme.breakpoints.up('sm')]: { fontSize: '28px' },
      fontWeight: '700',
      marginBottom: '5px',
    },
    backLink: {
      textDecoration: 'none',
      color: theme.palette.grey[900],
      '&:hover': {
        color: theme.palette.primary.main
      }
    },
    howToIcon: {
      color: theme.palette.primary.main,
      fontSize: '42px',
      marginTop: '30px',
      marginBottom: '30px',
    },
    loginB:{
      [theme.breakpoints.only('xs')]: { fontSize: '15px' },
      [theme.breakpoints.up('sm')]: { fontSize: '16px' },
      //fontSize: '17px', 
      fontWeight: '600', 
      padding: '5px 10px 5px 10px',
    }
  }));
  const classes = useStyles();  

  const history = useHistory()

  const [formData, setFormData] = React.useState({ email: '', password: '' })
  const [submitting, setSubmitting] = React.useState(false)
  const [loginError, setLoginError] = React.useState(null)
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const [howToAccess, setHowToAccess] = React.useState(false);
  const [instructionsSent, setInstructionsSent] = React.useState(false);
  const [newPath, setNewPath] = React.useState(null)
  const [showPassword, setShowPassword] = React.useState(false)

  useEffect(() => {
    
    if(props.type !== undefined && props.val1 !== undefined && newPath === null && props.loading !== undefined && !props.loading) { // there was a path - determine path post routing
      
      let path = ''
      let uid = props.val1;

      // Determine what the target path will be
      if(['_a', '_o'].includes(props.type) && // Route to an agreement or template, potentially with clause
        props.val2 !== undefined && props.val3 !== undefined && props.val4 !== undefined) {

        path = ['_a'].includes(props.type) ?
          '/core/' + props.val2 + '/agreement/' + props.val3 + '/' + props.val4 + (props.val5 !== undefined ? ('/' + props.val5) : '') :
          '/core/legal/templates/' + props.val2 + (props.val3 === 'terms' ? '/edit-terms/' : '/edit/') + props.val4 + (props.val5 !== undefined ? ('/' + props.val5) : '')

      } else if (['_ph'].includes(props.type) && props.val2 === 'settings') {
        path = '/core/settings'
      }

      if(props.user._id !== undefined && props.user._id === uid) {
        // If the user is already logged in, and equal to UID then route to the path
        history.push(path)

      } else {
        // If not, attach the path to the login attempt for routing from the server side
        setNewPath(path)
      }
    }

  }, [props.type, props.loading, props.val1, props.val2, props.val3, props.val4, props.val5, props.user, newPath, history])

  const handleSubmit = async e => {
    e.preventDefault();

    setSubmitting(true);
    const { email, password } = formData
    if(!forgotPassword) { // Logging in
      const { success, data } = await server.postAsync('/auth/login', {
        email,
        password,
        newPath
      })
      if (success) { // successfully logged in
        window.location.replace(data);
        return
      } else { // error
        setSubmitting(false);
        setLoginError(data);
      }
    } else { // Forgot your password

      // set random string
      let randomString = generateRandomString(20);

      axios.post('/auth/createpassword', {
        email: email, 
        newPassword: randomString,
        forgot: true,
      })
      .then(function(res) { // Send email with instructions
        axios.post('/mail/forgotpw/' + email, {
          randomString: randomString,
        })
        .then(function(res) { 
          setSubmitting(false);
          setInstructionsSent(true);
        })
        .catch(function(err) {
          setSubmitting(false);
          setLoginError("An error occurred, please try again to reset your passsword");
        })
      })
      .catch(function(err) {
        setSubmitting(false);
        setLoginError("Unable to reset your password, check that the email address is correct");
      })
    }
  }

  const handleForgotPassword = () => {
    setLoginError(null)
    setHowToAccess(false);
    setForgotPassword(!forgotPassword);
    setInstructionsSent(false);
  }

  const handleHowToAccess = () => {
    setHowToAccess(!howToAccess);
  }

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return(
      <div className={classes.root}>

        {
        (props.orgurl !== undefined && props.orgurl !== null && props.orgurl.length > 0) ?
        (
        <>
        <Header 
          module="network"
          org={props.org}
          user={props.user}
          modules={
            props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
              ['sales','procurement','partnerships','people','corporate'] : 
            props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
              props.org.modules : [] }
          orgProfile={props.orgProfile}
          searchOptions={props.network}
          crumbs={[]}
          notifications={props.notifications}
          markNotificationAsRead={props.markNotificationAsRead}
          markAllNotificationsAsRead={props.markAllNotificationsAsRead}
        />

        <DrawerCore
          agreements={[]} // props.orgProfileAgrTemplates 
          page="access" 
          rid=""
          orgurl={props.orgurl}
          showCerts={props.orgProfileCerts.length > 0}
          customurl={props.customurl}
          sections={[]} 
          pageType='profile'
          userLoggedIn={props.user !== undefined && props.user.email !== undefined}
        />
        </>
        ): '' }
  

          {['_a', '_o'].includes(props.type) && newPath === null ?

          <Container maxWidth="lg">
          <Grid container direction="column" justify="center" alignItems="stretch" className={classes.outerFlexGrid}>
            <Grid item>
              <Box align="center">
                <CircularProgress size={44} disableShrink />
              </Box>
            </Grid>
          </Grid>
          </Container>

          :

          <Container maxWidth="lg">
          
          <Grid container direction="column" justify="center" alignItems="stretch" 
            className={(props.orgurl !== undefined && props.orgurl !== null && props.orgurl.length > 0) ? classes.outerFlexGrid : classes.outerFlexGridBasic}>
  
          {
            (props.orgurl !== undefined && props.orgurl !== null && props.orgurl.length > 0) && (props.orgProfilePending) ? 
            (
              <Grid item>
                <Box align="center">
                  <CircularProgress size={84} disableShrink style={{marginTop: '100px'}} />
                </Box>
              </Grid>
            )
            :
            (props.orgurl !== undefined && props.orgurl !== null && props.orgurl.length > 0) && (props.orgProfileError !== null) ? 
            (
              <Grid item>
                <Box mt={5} mb={8} align="center">
                  <FontAwesomeIcon icon={faExclamationTriangle} className={classes.iconError} />
                </Box>
                <Typography variant="h6" align="center">
                    This page could not be found
                </Typography>
                <Box mt={5} mb={5} align="center">
                  <Button variant="outlined" color="primary" component={RouterLink} to="/network">Search the Network</Button>
                </Box>
              </Grid>
            )
            :
            (
              <React.Fragment>
              {(props.orgurl !== undefined && props.orgurl !== null && props.orgurl.length > 0) ? 
              <Grid item>
                <Box align="center">
                  <Avatar src={props.orgProfile.logoURL} alt={props.orgProfile.shortName} className={classes.orgLogo} />
                </Box>
              </Grid>
              : '' }

              <Grid item>

                <form method="post" noValidate onSubmit={handleSubmit}>
                <Grid container direction="column" justify="center" alignItems="center" spacing={2} style={{ margin: 0, width: '100%' }}>
          
                  <Grid item container xs={12} sm={8} md={6} justify="center">
                    <Box mb={2} align="center">
                      {!forgotPassword ? 
                        (
                        <Typography variant="h4" align="center" className={classes.titleText}>
                          {(props.orgurl !== undefined && props.orgurl !== null && props.orgurl.length > 0) ? 
                          "Access your relationship"
                          :
                          <a href="https://getcanveo.com/" className={classes.backLink}>Welcome back to Canveo</a>
                          }
                        </Typography>
                        ) : 
                        (
                        <Typography variant="h4" align="center" className={classes.titleText}>
                          Forgot your password
                        </Typography>
                        )
                      }
                      {loginError !== null ? (<ErrorMsg msg={!forgotPassword ? "Authorization not successful" : "Unable to reset your password" } />):''}
                    </Box>
                  </Grid>
                  {!instructionsSent && !howToAccess ? 
                  (
                  <Grid item container xs={9} sm={7} md={(props.orgurl !== undefined && props.orgurl !== null && props.orgurl.length > 0) ? 7 : 5}>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      id="email"
                      placeholder="Your username"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value={formData.email}
                      onChange={e => setFormData({ ...formData, email: e.target.value.toLowerCase() })}
                      InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <FontAwesomeIcon icon={faEnvelope} color={theme.palette.primary.main} />
                        </InputAdornment>
                        ),
                      }}
                    />                  
                  </Grid>
                  ) 
                  : 
                  !howToAccess?
                  (
                    <Box mt={2} mb={3}>
                    <Typography>
                      New Password reset instructions have been sent to your email address.<br/>
                    </Typography>
                    </Box>
                  )
                  :
                  (
                    <Box mb={10}>
                    <Grid container direction="row" alignItems="flex-start" justify="center" spacing={4}>

                      <Grid item xs={10} sm={8} md={4}>
                        <Box align="center">
                          <FontAwesomeIcon icon={faEnvelopeOpenText} className={classes.howToIcon} />
                          <Typography variant="h6" gutterBottom>Get an invitation</Typography>
                          <Typography variant="body2">Reach out to your counterparty contact person and ask him or her to set you up with a "counterparty account". You will receive an invitation with further instructions.</Typography>
                          <Box mt={3} mb={3}>
                            <Button variant="contained" disableElevation color="primary" size="small" fullWidth onClick={handleHowToAccess}>Got it</Button>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={10} sm={8} md={4}>
                        <Box align="center">
                          <FontAwesomeIcon icon={faArrowRotateLeft} className={classes.howToIcon} />
                          <Typography variant="h6" gutterBottom>Reset your invation</Typography>
                          <Typography variant="body2">If you have already been invited to Canveo but lost the email with instructions or forgot your password, then simply follow the "forgot password" instructions.</Typography>
                          <Box mt={3} mb={3}>
                            <Button variant="contained" disableElevation color="primary" size="small" fullWidth onClick={handleForgotPassword}>Forgot Password</Button>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={10} sm={8} md={4}>
                        <Box align="center">
                          <FontAwesomeIcon icon={faUserCheck} className={classes.howToIcon} />
                          <Typography variant="h6" gutterBottom>Become a customer</Typography>
                          <Typography variant="body2">Contact us to request a demo and obtain full access to all of Canveo' functionality, we'll be excited to onboard your organisation as quickly as possible.</Typography>
                          <Box mt={3} mb={3}>
                            <Button variant="contained" disableElevation color="primary" size="small" fullWidth component={RouterLink} to="/contact">Request Demo</Button>
                          </Box>
                        </Box>
                      </Grid>

                    </Grid>
                    </Box>
                  ) }
                  { !forgotPassword && !howToAccess ? 
                  (
                  <Grid item container xs={9} sm={7} md={(props.orgurl !== undefined && props.orgurl !== null && props.orgurl.length > 0) ? 7 : 5}>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      name="password"
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      placeholder="Your password"
                      autoComplete="current-password"
                      defaultValue={formData.password}
                      onChange={e => setFormData({ ...formData, password: e.target.value })}
                      InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <FontAwesomeIcon icon={faKey} color={theme.palette.primary.main} />
                        </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={e => handleToggleShowPassword()}
                              onMouseDown={handleMouseDownPassword}
                              //size="small"
                              style={{fontSize: '16px', padding: '7px 6px 7px 6px'}}
                            >
                              {showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  ): '' }
                  {!instructionsSent && !howToAccess ? 
                  (
                  <Grid item container xs={9} sm={7} md={(props.orgurl !== undefined && props.orgurl !== null && props.orgurl.length > 0) ? 7 : 5}>
                    <Button
                      fullWidth
                      disableElevation
                      disabled={submitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.loginB}
                    >
                    { submitting && (<CircularProgress size={24} style={{marginRight: '10px'}} /> )}
                    { (submitting && !forgotPassword) ? 'Signing in...' : 
                      (!submitting && !forgotPassword) ? <>Sign In&nbsp;&nbsp;<FontAwesomeIcon icon={faSignInAlt} /></> :
                      (submitting && forgotPassword) ? 'Requesting...' : 
                      (!submitting && forgotPassword) ? 'Request instructions' : 'Submit' }
                    </Button>
                  </Grid>
                  ) : '' }
                  <Grid item container xs={12} sm={7} md={(props.orgurl !== undefined && props.orgurl !== null && props.orgurl.length > 0) ? 7 : 5} justify="center" alignItems="center">
                    {!forgotPassword && !howToAccess ? 
                    (
                    <Grid item container justify="center">
                      <Box mt={0} mb={1}>
                        <Button href="https://app.getcanveo.com/auth/google">
                          <img src={Google} className={classes.iconImgPr} alt="Sign in with Google" />
                          <Box display={{ xs: 'none', sm: 'inline-block' }}>Sign in with&nbsp;</Box>Google
                        </Button>
                        <Button href="https://app.getcanveo.com/auth/slack">
                          <Box display={{ xs: 'none', sm: 'inline-block' }}>Sign in with&nbsp;</Box>Slack
                          <img src={Slack} className={classes.iconImgPl} alt="Sign in with Slack" />
                        </Button>
                      </Box>
                    </Grid>
                    ) : '' }
                    {!howToAccess ? 
                    (
                    <Grid item container justify="center">
                      <Box mt={0} mb={2} className={classes.ptFont}>

                        {(props.orgurl !== undefined && props.orgurl !== null && props.orgurl.length > 0)?
                        <Typography onClick={handleHowToAccess} className={classes.clink}>How do I get access?</Typography>
                        :
                        <RouterLink to="/signup" className={classes.clink}>Create account</RouterLink>
                        }
                        &nbsp;&nbsp;or&nbsp;&nbsp; 
                        {!forgotPassword ? 
                        (
                        <Typography onClick={handleForgotPassword} className={classes.clink}>forgot password?</Typography>
                        )
                        :
                        (
                        <Typography onClick={handleForgotPassword} className={classes.clink}>login to Canveo</Typography>
                        )}

                      </Box>
                    </Grid>
                    ): '' }
                  </Grid>

                </Grid>
                
                </form>

              </Grid>
              </React.Fragment>
            )
          }
          </Grid>
          </Container>
          }
      </div>
  )
}

export default Login;
