import React from 'react';
import { Box, Button, Container, Grid,
         makeStyles, 
         Typography} from '@material-ui/core';
import { Header } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';

function Hub(props) {

  const useStyles = makeStyles(theme => ({
    outerFlexGrid: {
      minHeight: '80vh',
      paddingTop: '100px',
      paddingBottom: '100px',
    },
    mbAndBold: {
      marginBottom: '55px',
      fontWeight: '700',
      color: theme.palette.grey[900]
    },
    hubIcon: {
      color: theme.palette.secondary.main,
      fontSize: '24px',
      marginRight: '15px',
    },
    secondary: {
      color: theme.palette.secondary.main
    },
    joinButton: {
      [theme.breakpoints.only('xs')]: { width: '200px' },
      [theme.breakpoints.up('sm')]: { width: '240px' },
    }
  }));
  const classes = useStyles();

  const [learnMore, setLearnMore] = React.useState(false);

  const toggleLearnMore = () => {
    setLearnMore(!learnMore);
  }

  const handleJoin = () => {
    props.enableHubForOrg(props.user.orgID, true)
  }

  return(
    <div>
        <Header module="legal"
        active="Hub"
        crumbs={[
            {name: "The Hub", to: "active"} ]}
        user={props.user} 
        org={props.org}
        modules={
          props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
            ['sales','procurement','partnerships','people','corporate'] : 
          props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
            props.org.modules : [] }
        withSubHead={false}
        notifications={props.notifications}
        markNotificationAsRead={props.markNotificationAsRead}
        markAllNotificationsAsRead={props.markAllNotificationsAsRead}  />

        <Container maxWidth="md">
        <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>                
            <Grid item>
              <Box mt={4} mb={4} align="center">
                <Typography variant="h3" className={classes.mbAndBold} align="center">
                  The Hub<span className={classes.secondary}>.</span>
                </Typography>
              </Box>

              <Box mt={4} mb={4} align="center">
                <Typography variant="h6" className={classes.mbAndBold} align="center">
                  Leverage the power of the Canveo network.
                </Typography>
              </Box>

              <Box align="center" mt={4} mb={4}>
                {props.org.hubParticipant ?
                <Typography><FontAwesomeIcon icon={faThumbsUp} className={classes.hubIcon} />You are a hub participant</Typography>
                :
                <Button size="large" color="secondary" variant="contained" disableElevation
                  className={classes.joinButton} onClick={handleJoin}>Join the hub</Button>
                }
              </Box>

              <Box mt={8} mb={6} align="center">
                {learnMore ?
                (
                <>
                <Box mt={2} mb={2} ml={2} mr={2} style={{maxWidth: '520px'}}>
                <Typography align="left" variant="body1">
                The Hub is an opt-in feature whereby you will share your active Legal templates and will be able to view and utilize the templates of participating organisations inside the Canveo Network.
                </Typography>
                <br/>
                <Typography align="left" variant="body1">
                  Additionally, the Hub participants will have access to market intelligence on a clause level and the "comparable clauses feature".
                </Typography>
                <br/>
                <Typography align="left" variant="body1">
                The Hub functionality is partially available currently, but remaining features will be coming soon. If you join the hub then you will be able to access this functionality as soon as it is available.
                </Typography>
                </Box>
                </>
                ) : '' }
                <Button size="small" color={learnMore ? "secondary" : "default"} onClick={toggleLearnMore}>{learnMore ? "Ok, got it" : "What is The Hub?"}</Button>
              </Box>

            </Grid>
        </Grid>
        </Container>
    </div>
  );
}

export default Hub;