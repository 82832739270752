import React, { useEffect } from 'react';
import theme from '../theme/theme';
import { useHistory } from 'react-router-dom';
import { clauseOpenCheck, userFriendlyAVV, getTOC, pad } from '../utils';
import { Box, Button, Dialog, Grid, Slide,
         makeStyles, 
         Typography} from '@material-ui/core';
import { AgreementExhList, DialogImportDoc, NegoCardEditor, Header, SkeletonCustom, TableEditable } from '../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faThumbsUp, faClone, faFileImport } from "@fortawesome/free-solid-svg-icons";

const axios = require('axios');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});  

const DialogAVOld = (props) => {

  const useStyles = makeStyles(theme => ({
    fullFlexGrid: {
        height: '100vh',
        marginTop: '100px',
        width: '100%',
    },
    agrTitle: {
        fontWeight: '700',
        [theme.breakpoints.only('xs')]: { fontSize: '20px' },
        [theme.breakpoints.only('sm')]: { fontSize: '28px' },
        [theme.breakpoints.only('md')]: { fontSize: '34px' },
        [theme.breakpoints.up('lg')]: { fontSize: '36px'  },
        paddingBottom: '5px',
    },
    boxResizing: {
        [theme.breakpoints.only('xs')]: { width: '300px' },
        [theme.breakpoints.only('sm')]: { width: '450px' },
        [theme.breakpoints.only('md')]: { width: '580px' },
        [theme.breakpoints.up('lg')]: { width: '670px' },   
    },
    contentBox: {
        lineHeight: 1.6,
    },
    sectionHead: {
        marginTop: '20px',
        marginBottom: '25px',
        [theme.breakpoints.down('xs')]: { width: '205px', fontSize: '20px', }, // 220
        [theme.breakpoints.only('sm')]: { width: '337px', fontSize: '24px', }, // 420
        [theme.breakpoints.only('md')]: { width: '455px', fontSize: '26px', }, // 469
        [theme.breakpoints.up('lg')]: { width: '575px', fontSize: '28px',}, //588
        fontWeight: '700',
    },
    subSectionHead: {
        marginTop: '20px',
        marginBottom: '25px',
        fontWeight: '700',
        [theme.breakpoints.down('xs')]: { width: '205px', fontSize: '16px', },
        [theme.breakpoints.only('sm')]: { width: '337px', fontSize: '18px', },
        [theme.breakpoints.only('md')]: { width: '455px', fontSize: '20px', },
        [theme.breakpoints.up('lg')]: { width: '575px', fontSize: '22px',},
    },
    titleInput: {
        [theme.breakpoints.down('xs')]: { width: '198px' },
        [theme.breakpoints.only('sm')]: { width: '320px' },
        [theme.breakpoints.only('md')]: { width: '440px', fontSize: '16px' },
        [theme.breakpoints.up('lg')]: { width: '565px', fontSize: '16px'},
        fontWeight: '700',
        marginBottm: '0px',
    },
    clauseBox: {
        fontWeight: '300',
    },
    iconP: {
        color: theme.palette.primary.main,
        padding: '0px 15px 0px 15px',
    },
    doneIcon: {
        color: theme.palette.primary.main,
        fontSize: '56px',
    },
    buttonActive: {
        padding: '25px 15px 25px 15px', 
        margin: '0px 10px 0px 10px',
        color: theme.palette.primary.main,
    },
    button: {
        padding: '25px 15px 25px 15px', 
        margin: '0px 10px 0px 10px',
        color: theme.palette.grey[500]
    },
    bold: {
        fontWeight: '700',
    },
    iconInButton: {
        padding: '10px 20px 10px 0px', 
        fontSize: '26px'
    },
    buttonToConfirm: {
        width: '150px',
        margin: '0px 10px 0px 10px',
    },
    boxTextSmaller: {
        [theme.breakpoints.down('xs')]: { width: '320px' },
        [theme.breakpoints.up('sm')]: { width: '450px' },
    }
  }));
  const classes = useStyles();
  
  const history = useHistory()

  const [aid, setAID] = React.useState(null)
  const [cls, setCls] = React.useState([])
  const [avcs, setAvcs] = React.useState([])
  const [toc, setToc] = React.useState([])
  const [excludeAgrs, setExcludeAgrs] = React.useState([]) // exhibits that didn't exist yet at the requested version
  const [loading, setLoading] = React.useState(false)
  const [restoring, setRestoring] = React.useState(false)
  const [newInterim, setNewInterim] = React.useState(false)
  const [cloning, setCloning] = React.useState(false)
  const [importing, setImporting] = React.useState(false)
  const [doneRestoring, setDoneRestoring] = React.useState(false)
  const [doneImporting, setDoneImporting] = React.useState(false)
  const [importOpen, setImportOpen] = React.useState(false)
  const [iDoc, setIDoc] = React.useState(null)

  useEffect(() => {

    setLoading(true)

    let a = aid
    if(a === null && props.agr !== undefined && props.agr !== null) { a = props.agr._id }

    if(props.open && a !== null && props.avv === 'cloneVersion') {

        setCloning(true)
        setNewInterim(true);
        setLoading(false)

    } else if(props.open && a !== null && props.avv !== 'cloneVersion') {

        axios.get('/agr/agrvold/' + a + "/" + props.avv)
        .then(function(res) {

            axios.post('/clause/clauses', { clauses: res.data.data.clauses }) // Need to get the relevant clauses
            .then(function(rescls) {

                props.agrsAvs.forEach((aa) => {
                    axios.get('/agr/agrvold/' + aa._id + "/" + props.avv)
                    .then(function(res) {
                        if(res.data.data._id === undefined) { setExcludeAgrs(eas => [...eas, aa._id]) }
                    }).catch((err) => { console.log(err) })
                })

                setToc(getTOC(rescls.data.data, res.data.data.clauses, false))
                setAvcs(res.data.data.clauses)
                setCls(rescls.data.data)
                setLoading(false)

            }).catch((err) => { console.log(err) })

        }).catch((err) => { console.log(err) })
    }
  }, [props.open, aid, props.agr, props.agrsAvs, props.avv])

  const closeDialog = () => {
    setAID(null)
    setCls([])
    setAvcs([])
    setToc([])
    setLoading(false)
    setRestoring(false)
    setCloning(false)
    setImporting(false)
    setImportOpen(false);
    setIDoc(null);
    setNewInterim(false);

    if(doneRestoring) {
        history.push('/core/' + props.module + '/agreement/' + props.rel._id + "/" + props.agrsAvs.filter((a) => a.isMain)[0]._id)
    }
    setDoneRestoring(false)
    setDoneImporting(false)
    props.handleAvOldDialogClose()
  }

  const handleFinalizeImport = (iDoc) => {
    
    setImportOpen(false);
    setIDoc(iDoc)
  }

  const handleClick = (aid, filter) => {

    setAID(aid)

  }

  const toggleCloningImporting = (newAction) => {
      if(newAction === 'importing') {
        setCloning(false)
        setImporting(true)
        setImportOpen(true)
      } else {
        setCloning(true)
        setImporting(false)
      }
  }

  const handleConfirmImport = () => {
    setLoading(true);

    props.createAgrVersionWithImport(
        props.latestAV.agrID, // aid
        getNewerInterimAVV(props.latestAV.version), // avv
        props.latestAV.owner, // owner
        false, // primaryReady
        false, // secondaryReady
        true, // isEdited
        props.latestAV.editMode, // editMode 
        iDoc, // iDoc
        props.user._id, // creationBy
    )

    setLoading(false)
    setDoneImporting(true);
  }

  const getNewerInterimAVV = (latestAVV) => {

    let newMajorVersion = latestAVV.substring(0, 7)
    let newMinorVersion = pad(parseInt(latestAVV.substring(7, 10)) + 1,3)
    let newMiniVersion = ".000001"
    let newAgrVersion = newMajorVersion + newMinorVersion + newMiniVersion

    return newAgrVersion;

  }

  const handleConfirmRestore = () => { // also used for cloning

    setLoading(true)

    let newAgrVersion = getNewerInterimAVV(props.latestAV.version) // Create a new Agreement version
    let creationDate = new Date().toISOString();

    props.agrsAvs.forEach(((a, i) => {

        axios.get('/agr/agrvold/' + a._id + "/" + (cloning ? props.latestAV.version : props.avv))
        .then(function(res) {

            let targetAV = res.data.data
            let targetAVC = res.data.data.clauses

            if(targetAVC !== undefined) {

                axios.post('/clause/clauses', { clauses: targetAVC }) // Need to get the relevant clauses for the AV to be restored
                .then(function(rescls) {

                    if(!cloning) { // Restoring an older version - pull latest clauses and run through comment appr. status
                        axios.get('/agr/agrvold/' + a._id + "/" + props.latestAV.version)
                        .then(function(resc) {
                            
                            let latestAVC = resc.data.data.clauses

                            if(latestAVC !== undefined) {

                                axios.post('/clause/clauses', { clauses: latestAVC }) // Need to get the relevant clauses for the latest AV
                                .then(function(resccls) {

                                    let bulkWrites = []
                                    //resccls.data.data
                                    rescls.data.data
                                    .sort((a,b) => targetAVC.indexOf(a._id) - targetAVC.indexOf(b._id))
                                    .forEach((c) => {

                                        let latestClause = resccls.data.data.filter((cl) => cl.singleClauseID === c.singleClauseID)[0]
                                        let targetClause = c

                                        // Check if the comment / approval status needs to be merged
                                        if(latestClause !== undefined && (latestClause.commentStatus !== targetClause.commentStatus || 
                                            latestClause.approvalStatus !== targetClause.approvalStatus)) { // ie. the clause was still there and c/a status was different
                                            // the comment or approval status changed - merge the latest into the target
                                            targetClause = {...targetClause, 
                                                clauseStatus: ['proposedDel', 'Deleted'].includes(targetClause.clauseStatus) ? targetClause.clauseStatus :
                                                    clauseOpenCheck(targetClause.blocks, latestClause.commentStatus, latestClause.approvalStatus, targetClause.pendingChange, targetClause.clauseObject) ?
                                                    'Open' : 'Accepted',
                                                commentStatus: latestClause.commentStatus, 
                                                approvalStatus: latestClause.approvalStatus,
                                                internalWorkflow: latestClause.internalWorkflow,
                                            }
                                        }

                                        bulkWrites.push(
                                        { insertOne :
                                          {
                                            "document" :
                                            {
                                                "singleClauseID": targetClause.singleClauseID,
                                                "clauseCat": targetClause.clauseCat,
                                                "lockedBy": '', 
                                                "lockedByOrg": targetClause.lockedByOrg !== undefined && targetClause.lockedByOrg !== null && targetClause.lockedByOrg.type !== undefined ? targetClause.lockedByOrg : {type: 'none'},
                                                "clauseTypes": targetClause.clauseTypes,
                                                "title": targetClause.title, 
                                                "blocks": targetClause.blocks, 
                                                "plain": targetClause.plain, 
                                                "clauseObject": targetClause.clauseObject,
                                                "version": targetClause.version + 1, // version  
                                                "clauseStatus": targetClause.clauseStatus, // clauseStatus
                                                "commentStatus": targetClause.commentStatus, // commentStatus
                                                "approvalStatus": targetClause.approvalStatus, // approvalStatus
                                                "internalWorkflow": targetClause.internalWorkflow, // internalWorkflow
                                                "numberType": targetClause.numberType,
                                                "numberManual": targetClause.numberManual,
                                                "creationBy": props.user._id,
                                                "creationDate": creationDate,
                                            }
                                          }
                                        })

                                        // Check if there needs to be an Activity Log posted for this clause
                                        let samePlain = true;
                                        if(latestClause !== undefined) { // plain is an arrray so compare paragraph by paragraph
                                            targetClause.plain.forEach((par,i) => {
                                                if(latestClause.plain[i] !== par) { samePlain = false}
                                            })
                                        }
                                        if(latestClause !== undefined && (!samePlain || targetClause.clauseStatus !== latestClause.clauseStatus)) {
                                            props.handleActivityLog('clause', 'clRestored', targetClause.singleClauseID, '', newAgrVersion, props.user.displayName, (!samePlain ? {old: latestClause.blocks, new: targetClause.blocks} : {})) // logLevel, logType, singleClauseID, ticketID, keyVal1, keyVal2
                                        }
                                    })
                    
                                    axios.post('/clause/protect/clauses', {
                                      bulkWrites: bulkWrites, 
                                    })
                                    .then((res) => {
                    
                                      if(res.data.success) {
                                        let avcs = []
                                        res.data.data.insertedIds.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0)).forEach((id) => {
                                          avcs.push(id._id)
                                        })
                                        //dispatch(updateAgrvNewClauses(resav.data.data._id, avcs));
                                        props.createAgrVersion(
                                            a._id, // aid
                                            newAgrVersion, // version, 
                                            props.latestAV.owner, // owner
                                            avcs, // clauses
                                            targetAV.attachment, // attachment
                                            false, // primaryReady
                                            false, // secondaryReady
                                            false, // duplicateClauses
                                            false, // freshAg
                                            props.latestAV.editMode, // editMode
                                        )

                                        if(props.agrsAvs.length === i + 1) {
                                            props.handleActivityLog('agr', 'agrVersionRestored', '', '', props.user.displayName, props.avv, { old: props.avv, new: newAgrVersion}) // logLevel, logType, singleClauseID, ticketID, keyVal1, keyVal2
                                            setDoneRestoring(true);
                                            setTimeout(function() { setLoading(false) }, 1000);
                                        }
                    
                                      } else { console.log("Unable to update the AV"); }
                    
                                    })
                                    .catch(function(err) { console.log("fetcherr",err); })

                                }).catch((err) => { console.log(err) })
                            }
                        }).catch((err) => { console.log(err) })

                    } else { // You are simply cloning

                        props.createAgrVersion(
                            a._id, // aid
                            newAgrVersion, // version, 
                            props.latestAV.owner, // owner
                            /*rescls.data.data.sort((a,b) => targetAVC.indexOf(a._id) - targetAVC.indexOf(b._id))*/
                            targetAVC, // clauses
                            targetAV.attachment, // attachment
                            false, // primaryReady
                            false, // secondaryReady
                            true, // duplicateClauses
                            false, // freshAg
                            props.latestAV.editMode, // editMode
                        )

                        if(props.agrsAvs.length === i + 1) { 
                            setDoneRestoring(true);
                            setTimeout(function() { setLoading(false) }, 1000);
                        }

                    }
                }).catch((err) => { console.log(err) })

            } else { // the agr version was not found - ie. didn't exist at the time

                props.updateAgrStatus(a._id, 'Deactive', props.user._id) // need to archive a._id
                props.handleActivityLog('agr', 'agrNewStatus', '', '', 'Deactive', '', {}) // logLevel, logType, singleClauseID, ticketID, keyVal1, keyVal2

            }

        }).catch((err) => { console.log(err) })
    }))
  }

  const getNumberForCl = (clid, numberType, numberManual) => {
    let r = ''
    if(['none'].includes(numberType)) {
        r = '';
    } else if(['Manual'].includes(numberType)) {
        r = numberManual;
    } else {
        r = toc !== null && toc.filter((t) => t.id === clid)[0] !== undefined ? toc.filter((t) => t.id === clid)[0].no : '';
    }
    return (r === '' ? r : (r + ' '));
  }
  
  return (
    <div>
    <Dialog 
    fullScreen 
    open={props.open} 
    onClose={closeDialog} 
    TransitionComponent={Transition}
    >
        <Header 
            exitOnly={true}
            exitClick={closeDialog}
            module="open"
        />

        <Grid container direction="row" justify="center" alignItems="flex-start" className={classes.fullFlexGrid}>

            {loading ?
            <SkeletonCustom type="agreement" />
            :
            <Box align="center" className={classes.boxResizing}>
                <Typography variant="h3" align="center" className={classes.agrTitle} >
                    {props.agrsAvs !== undefined && props.agrsAvs.filter((aa) => aa.isMain)[0] !== undefined ? props.agrsAvs.filter((aa) => aa.isMain)[0].agrFullName : ''}
                </Typography>

                {props.disableRestore || restoring || cloning || importing || doneRestoring || doneImporting ?
                <Typography variant="body2" align="center">
                    Version {
                    (cloning || importing) && props.latestAV !== undefined && props.latestAV !== null && props.latestAV.version !== undefined ? userFriendlyAVV(props.latestAV.version) :
                    !(cloning || importing) && props.avv !== undefined && props.avv !== null && props.avv !== '' ? userFriendlyAVV(props.avv) : ''}
                </Typography>
                : // TEMPORARY STATEMENT TO AVOID RESTORING OF PRE-MIGRATION AGREEMENT VERSIONS
                !cls.some((c) => ['sec','ssec','cl','cl1','cl2','cl3','cl4'].includes(c.clauseCat) && c.title !== null) ?
                <Button size="small" variant="text" color="primary" onClick={e => setRestoring(true)}>
                    <span style={{fontWeight: '300', color: theme.palette.grey[900]}}>Version {props.avv !== undefined && props.avv !== null && props.avv !== '' ? userFriendlyAVV(props.avv) : ''}&nbsp;-&nbsp;</span>
                    Restore this version
                </Button>
                :''
                }

                {doneRestoring || doneImporting ?
                <Box align="center" mt={6}>
                    <FontAwesomeIcon icon={faThumbsUp} className={classes.doneIcon} />
                    <Box className={classes.boxTextSmaller} mt={7}>
                        <Typography variant="h6">You're done. The new version is being created.</Typography>
                    </Box>
                    <Box mt={7}>
                        <Button variant="contained" color="primary" disableElevation className={classes.buttonToConfirm}
                        onClick={e => history.push('/core/' + props.module + '/agreement/' + props.rel._id + "/" + props.agrsAvs.filter((a) => a.isMain)[0]._id)}>Got it</Button>
                    </Box>
                </Box>
                :restoring ?
                <Box align="center" mt={6}>
                    <Box className={classes.boxTextSmaller}>
                        <Typography variant="h6">Are you sure you want to restore this version? This will create a new interim version.</Typography>
                    </Box>                    
                    <Box mt={7}>
                        <Button variant="text" color="primary" onClick={e => setRestoring(false)} className={classes.buttonToConfirm}>Cancel</Button>&nbsp;&nbsp;
                        <Button variant="contained" color="primary" disableElevation onClick={handleConfirmRestore} className={classes.buttonToConfirm}>Confirm</Button>
                    </Box>
                </Box>
                :newInterim ?
                <Box align="center" mt={4}>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item>

                            <Button align="center" onClick={e => toggleCloningImporting('cloning')}
                            className={cloning ? classes.buttonActive : classes.button}>
                            <FontAwesomeIcon icon={faClone} className={classes.iconInButton} />
                            <Typography variant="subtitle1" className={cloning ? classes.bold : null}>Clone latest version</Typography>
                            </Button>

                        </Grid>
                        {!props.user.readOnly ?
                        <Grid item>

                            <Button align="center" onClick={e => toggleCloningImporting('importing')}
                            className={importing ? classes.buttonActive : classes.button}>
                            <FontAwesomeIcon icon={faFileImport} className={classes.iconInButton} />
                            <Typography variant="subtitle1" className={importing ? classes.bold : null}>Import a version</Typography>
                            </Button>

                        </Grid>
                        : '' }
                    </Grid>

                    {cloning ? 
                    <Box align="center" mt={4}>
                        <Box className={classes.boxTextSmaller}>
                            <Typography variant="h6">Are you sure you want to clone the current latest version into a new interim version?</Typography>
                        </Box>

                        <Box mt={7}>
                            <Button variant="text" color="primary" onClick={closeDialog} className={classes.buttonToConfirm}>Cancel</Button>&nbsp;&nbsp;
                            <Button variant="contained" color="primary" disableElevation onClick={handleConfirmRestore} className={classes.buttonToConfirm}>Confirm</Button>
                        </Box>
                    </Box>
                    :
                    importing && iDoc === null ?
                    <Box align="center" mt={4}>
                        <Box className={classes.boxTextSmaller}>
                            <Typography variant="h6">Proceed to import the agreement</Typography>
                        </Box>

                        <Box mt={7}>
                            <Button variant="text" color="primary" onClick={closeDialog} className={classes.buttonToConfirm}>Cancel</Button>&nbsp;&nbsp;
                            <Button variant="contained" color="primary" disableElevation onClick={e => setImportOpen(true)} className={classes.buttonToConfirm}>Open Import</Button>
                        </Box>
                    </Box>
                    :
                    importing && iDoc !== null ?
                    <Box align="center" mt={4}>
                        <Box className={classes.boxTextSmaller}>
                            <Typography variant="h6">The import has been finalized - are you sure you want to create a new interim version from the imported agreement?</Typography>
                            <Box mt={3}>
                            <Typography variant="body2">NOTE: Clause history and Version compare will be reinitiated upon import.</Typography>
                            <Typography variant="body2">NOTE: Any exhibits need to be imported seperately.</Typography>
                            </Box>
                        </Box>

                        <Box mt={7}>
                            <Button variant="text" color="primary" onClick={closeDialog} className={classes.buttonToConfirm}>Cancel</Button>&nbsp;&nbsp;
                            <Button variant="contained" color="primary" disableElevation onClick={handleConfirmImport} className={classes.buttonToConfirm}>Confirm</Button>
                        </Box>
                    </Box>
                    :
                    ''}

                </Box>
                :
                <>
                {props.agrsAvs.filter((aa) => !excludeAgrs.includes(aa._id)).length > 1 ?
                <AgreementExhList 
                variant="dialog" 
                agr={{_id: aid !== null ? aid : props.agr !== undefined && props.agr !== null ? props.agr._id : '', agrStatus: 'None'}}
                agrs={props.agrsAvs.filter((aa) => !excludeAgrs.includes(aa._id))} 
                click={handleClick}
                />
                : '' }

                <Box mb={8} mt={props.agrsAvs.filter((aa) => !excludeAgrs.includes(aa._id)).length > 1 ? 0 : 4} align="left" className={classes.contentBox}>
                {cls.length === 0 ?
                <Box mt={3} mb={3} align="center">
                    <Typography>This version is not yet available</Typography>
                    <Typography>Retry in a few minutes or contact Canveo Support if the issue persists</Typography>
                </Box>
                :
                cls
                .sort((a,b) => avcs.indexOf(a._id) - avcs.indexOf(b._id))
                .map((cl, i) => (

                    ['sec','ssec'].includes(cl.clauseCat) && cl.title !== null ?
                    <Box
                    key={cl._id}
                    style={
                        cl.clauseStatus === 'proposedDel' && cl.pendingChange.filter((pc) => pc.type === 'proposedDel')[0] !== undefined &&
                        cl.pendingChange.filter((pc) => pc.type === 'proposedDel')[0].changeOrg === 'primary' ? 
                            {textDecoration: 'line-through', color: theme.palette.primary.main} : 
                        cl.clauseStatus === 'proposedDel' && cl.pendingChange.filter((pc) => pc.type === 'proposedDel')[0] !== undefined &&
                        cl.pendingChange.filter((pc) => pc.type === 'proposedDel')[0].changeOrg === 'secondary' ? 
                            {textDecoration: 'line-through', color: theme.palette.secondary.main} : 
                        cl.pendingChange.filter((pc) => pc.type === 'titleChange')[0] !== undefined && cl.pendingChange.filter((pc) => pc.type === 'titleChange')[0].changeOrg === 'primary' ? 
                            {color: theme.palette.primary.main} :
                        cl.pendingChange.filter((pc) => pc.type === 'titleChange')[0] !== undefined && cl.pendingChange.filter((pc) => pc.type === 'titleChange')[0].changeOrg === 'secondary' ? 
                            {color: theme.palette.secondary.main} : {}}>
                        <Typography className={['sec'].includes(cl.clauseCat) ? classes.sectionHead : ['ssec'].includes(cl.clauseCat) ? classes.subsectionHead : null}>
                            {getNumberForCl(cl._id, cl.numberType, cl.numberManual)}
                            {cl.title}
                        </Typography>
                    </Box>

                    :
                    ['sec','ssec','cl','cl1','cl2','cl3','cl4','preamble'].includes(cl.clauseCat) ?
                    <Box className={classes.clauseBox} mb={2} 
                    key={cl._id}
                    style={
                        cl.clauseStatus === 'proposedDel' && cl.pendingChange.filter((pc) => pc.type === 'proposedDel')[0] !== undefined && cl.pendingChange.filter((pc) => pc.type === 'proposedDel')[0].changeOrg === 'primary' ? 
                            {textDecoration: 'line-through', color: theme.palette.primary.main} : 
                        cl.clauseStatus === 'proposedDel' && cl.pendingChange.filter((pc) => pc.type === 'proposedDel')[0] !== undefined && cl.pendingChange.filter((pc) => pc.type === 'proposedDel')[0].changeOrg === 'secondary' ? 
                            {textDecoration: 'line-through', color: theme.palette.secondary.main} : {}}>

                        {cl.title !== null ?
                        <Typography className={classes.titleInput}
                        style={
                            cl.pendingChange.filter((pc) => pc.type === 'titleChange')[0] !== undefined && cl.pendingChange.filter((pc) => pc.type === 'titleChange')[0].changeOrg === 'primary' ? {color: theme.palette.primary.main} :
                            cl.pendingChange.filter((pc) => pc.type === 'titleChange')[0] !== undefined && cl.pendingChange.filter((pc) => pc.type === 'titleChange')[0].changeOrg === 'secondary' ? {color: theme.palette.secondary.main} : {} }>
                            {getNumberForCl(cl._id, cl.numberType, cl.numberManual)}
                            {cl.title}

                            {['Open'].includes(cl.commentStatus) ? <FontAwesomeIcon icon={faComments} className={classes.iconP} /> : ''}
                        </Typography>
                        :''}

                        <NegoCardEditor 
                        clid={cl._id}
                        curCpty={"primary"}
                        user={props.user}
                        content={cl.blocks}
                        parentCallback={null}
                        reviewChangesMode={false}
                        activeClause={false}
                        optionClicked={null}
                        disableEditing={true}
                        focusClause={() => void 0}
                        callbackOptions={() => void 0} />

                    </Box>
                    :
                    ['table'].includes(cl.clauseCat) ?
                    <React.Fragment key={cl._id}>
                    <Box mt={1} mb={2} className={classes.clauseBox}>
                    {cl !== undefined ?
            
                        <Typography variant="body1"  className={classes.titleInput}
                        style={
                            cl.pendingChange.filter((pc) => pc.type === 'titleChange')[0] !== undefined && cl.pendingChange.filter((pc) => pc.type === 'titleChange')[0].changeOrg === 'primary' ? {color: theme.palette.primary.main} :
                            cl.pendingChange.filter((pc) => pc.type === 'titleChange')[0] !== undefined && cl.pendingChange.filter((pc) => pc.type === 'titleChange')[0].changeOrg === 'secondary' ? {color: theme.palette.secondary.main} : {} }>
                            {getNumberForCl(cl._id, cl.numberType, cl.numberManual)}
                            {cl.title}
                        </Typography>
                    : '' }
                    </Box>
                    <Box mb={3} className={classes.clPlain}>
                    <TableEditable
                    active={false}
                    disabled={true}
                    editMode={'none'}
                    clause={cl}
                    proposedDel={cl.pendingChange.filter((pc) => pc.status === 'Open' && pc.type === 'proposedDel')[0] !== undefined ?
                        cl.pendingChange.filter((pc) => pc.status === 'Open' && pc.type === 'proposedDel')[0].changeOrg : null}
                    focusClause={() => void 0}
                    curCpty={"primary"}
                    />
                    </Box>
            
                    </React.Fragment>
                    :
                    ''
                ))}
                </Box>
                </>
                }

            </Box>
            }
        </Grid>
    </Dialog>
    <DialogImportDoc
        importOpen={importOpen}
        handleImportClose={closeDialog}
        handleFinalizeImport={handleFinalizeImport}
        agrTypes={props.agrTypes}
        relType={props.rel !== null ? props.rel.relationshipType : ''}
        orgAgrTemplates={[]}
        org={props.org}
        user={props.user}
        relevantSection={""}
        action={"new-version"}
    />
    </div>

  )
}

export default DialogAVOld