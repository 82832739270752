import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types'; // https://reactjs.org/docs/typechecking-with-proptypes.html
import { AppBar, BottomNavigation, BottomNavigationAction,
         IconButton, Slide, Toolbar, Typography,
         useScrollTrigger, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faTasks, faBars, faUsersCog, faHandsHelping, faHome, faBalanceScaleRight, faArrowLeft,
         faStar, faSign, faBook, faChartLine } from "@fortawesome/free-solid-svg-icons";

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({ });

  return (
    <Slide appear={false} direction="up" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

const MenuBottomModule = (props) => {

    const useStyles = makeStyles(theme => ({
        bottomNav: {
            zIndex: '100',
            width: '100%',
            bottom: '0',
            top: 'auto',
            position: 'fixed',
            backgroundColor: theme.palette.primary.contrastText,
            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 24px 0px',
            //boxShadow: '0px -3px 10px -1px rgba(112, 61, 220, 0.1)',
        },
        bottomNavAction: {
            padding: '5px 0px 5px 0px',
        },
        navText: {
            fontSize: '10px',
            fontWeight: '400',
        },
        navIcon: {
            fontSize: '20px',
            margin: '3px 0px 3px 0px',
        },
        grow: {
            flexGrow: 1,
        },
    }));
    const classes = useStyles();

    if(['agreement', 'help', 'profile', 'relationship'].includes(props.pageType)) {
        return (
        <HideOnScroll {...props}>
        <AppBar position="fixed" color="primary" className={classes.bottomNav}>
        <Toolbar /*id={props.pageType === 'agreement' ? "introTOC" : "notIntroTOC"}*/>
          <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={props.toggleDrawer}>
            <FontAwesomeIcon icon={faBars} className={classes.navIcon} />
          </IconButton>
          <div className={classes.grow} />
          <IconButton edge="end" color="inherit" component={RouterLink} to={props.backLink}>
            <FontAwesomeIcon icon={faArrowLeft} className={classes.navIcon} />
          </IconButton>
        </Toolbar>
        </AppBar>
        </HideOnScroll>
        )
    } else if(['partnerships', 'procurement', 'sales', 'corporate', 'people'].includes(props.module)) { // 'counterparty', 
        return (
        <HideOnScroll {...props}>
            <BottomNavigation
                value={props.active}
                className={classes.bottomNav}
                showLabels
                >
                <BottomNavigationAction 
                    className={classes.bottomNavAction}
                    label={<Typography className={classes.navText}>Home</Typography>} 
                    value="Home"
                    icon={<FontAwesomeIcon icon={faHome} className={classes.navIcon} />}
                    component={RouterLink} 
                    to={"/core/" + props.module + "/dashboard"} />
                <BottomNavigationAction
                    className={classes.bottomNavAction}
                    label={<Typography className={classes.navText}>Relationships</Typography>}
                    value="Relationships"
                    icon={<FontAwesomeIcon icon={faHandsHelping} className={classes.navIcon} />}
                    component={RouterLink} 
                    to={"/core/" + props.module + "/relationships"} />
                <BottomNavigationAction 
                    className={classes.bottomNavAction}
                    label={<Typography className={classes.navText}>Tasks</Typography>}
                    value="Tasks"
                    icon={<FontAwesomeIcon icon={faTasks} className={classes.navIcon} />}
                    component={RouterLink} 
                    to={"/core/" + props.module + "/tasks"} />
                {props.user !== undefined && ['Legal', 'Admin'].includes(props.user.role) ?
                <BottomNavigationAction 
                    className={classes.bottomNavAction}
                    label={<Typography className={classes.navText}>Analytics</Typography>}
                    value="Analytics"
                    icon={<FontAwesomeIcon icon={faChartLine} className={classes.navIcon} />}
                    component={RouterLink} 
                    to={"/core/" + props.module + "/analytics"} />
                :''}
            </BottomNavigation>
        </HideOnScroll>
        )
    } else if(props.module === "admin") {
        return (
            <HideOnScroll {...props}>
                <BottomNavigation
                    value={props.active}
                    className={classes.bottomNav}
                    showLabels
                    >
                    <BottomNavigationAction 
                        className={classes.bottomNavAction}
                        label={<Typography className={classes.navText}>Company</Typography>} 
                        value="Company"
                        icon={<FontAwesomeIcon icon={faBuilding} className={classes.navIcon} />}
                        component={RouterLink} 
                        to="/core/admin/company" />
                    <BottomNavigationAction 
                        className={classes.bottomNavAction}
                        label={<Typography className={classes.navText}>Users</Typography>} 
                        value="Users"
                        icon={<FontAwesomeIcon icon={faUsersCog} className={classes.navIcon} />}
                        component={RouterLink} 
                        to="/core/admin/usermgt" />
                </BottomNavigation>
            </HideOnScroll>
        )
    } else if(props.module === "legal") {
        return (
            <HideOnScroll {...props}>
                <BottomNavigation
                    value={props.active}
                    className={classes.bottomNav}
                    showLabels
                    >
                    <BottomNavigationAction 
                        className={classes.bottomNavAction}
                        label={<Typography className={classes.navText}>Templates</Typography>}
                        value="Templates"
                        icon={<FontAwesomeIcon icon={faBalanceScaleRight} className={classes.navIcon} />}
                        component={RouterLink} 
                        to="/core/legal/templates" />
                    
                    <BottomNavigationAction 
                        className={classes.bottomNavAction}
                        label={<Typography className={classes.navText}>Terms</Typography>}
                        value="Terms"
                        icon={<FontAwesomeIcon icon={faSign} className={classes.navIcon} />}
                        component={RouterLink} 
                        to="/core/legal/templates/terms/terms" />
                    {<BottomNavigationAction 
                        className={classes.bottomNavAction}
                        label={<Typography className={classes.navText}>Playbook</Typography>}
                        value="Playbook"
                        icon={<FontAwesomeIcon icon={faBook} className={classes.navIcon} />}
                        component={RouterLink} 
                        to="/core/legal/playbook/sales" />}
                    <BottomNavigationAction 
                        className={classes.bottomNavAction}
                        label={<Typography className={classes.navText}>Certifications</Typography>}
                        value="Certifications"
                        icon={<FontAwesomeIcon icon={faStar} className={classes.navIcon} />}
                        component={RouterLink} 
                        to="/core/legal/certifications" />
                    {/*
                    <BottomNavigationAction 
                        className={classes.bottomNavAction}
                        label={<Typography className={classes.navText}>The Hub</Typography>} 
                        value="Hub"
                        icon={<FontAwesomeIcon icon={faProjectDiagram} className={classes.navIcon} />}
                        component={RouterLink} 
                        to="/core/legal/hub" />*/}
                </BottomNavigation>
            </HideOnScroll>
        )
    } else {
        return null
    }

}

export default MenuBottomModule