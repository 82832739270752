import React, { useEffect } from 'react';
import theme from '../theme/theme';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { trunc, getEditModeOption, userFriendlyAVV, getFilteredAVList } from '../utils';
import { countries } from '../assets/static/countries';
import { DrawerCoreRender, Logo, MenuBottomModule } from '.'
import { Avatar, Box, Button, Chip, CircularProgress, Drawer, Grid, Hidden, IconButton, List, 
         ListItem, ListItemText, ListItemIcon, Menu, MenuItem,
         Snackbar, Tooltip, Typography, makeStyles, useScrollTrigger } from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt, faCaretLeft, faList, faBuilding, faComments, faPen, faThumbsUp, faGaugeSimple, faSearch, 
         faCheckSquare, faCheck, faFileContract, faClone, faExclamationCircle, faUserEdit, faUserFriends, faSignature, 
         faCaretDown, faCaretRight, faCopy, faFolderOpen, faUniversity, faLightbulb, faEyeSlash, faCog, 
         faCameraRetro, faMagic, faTimes, faHandshake, faShoppingCart, faUser, faShieldAlt, faFileAlt, faToggleOff, faToggleOn, 
         faTrash, faTag, faAsterisk, faGlobe, faEye, faStar, faFileWord, faDownload, faUserCircle, faCalendarCheck, 
         faCalendarTimes, faSyncAlt, faHourglassStart, faSortAmountDown, faSave, faChevronRight, faChevronCircleDown, faCheckDouble, 
         faArrowLeft, faHashtag, faTrashAlt, faQuestionCircle, faClock, faRetweet, faHandSparkles } from "@fortawesome/free-solid-svg-icons";

import defaultUser from '../assets/img/defaultuser.png';

import { effectivedatetypes } from '../assets/static/effectivedatetypes';
import { renewaltypes } from '../assets/static/renewaltypes';

const axios = require('axios');
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const useTreeItemStyles = makeStyles((theme) => ({
    root: {
      color: theme.palette.text.secondary,
      '&$selected > $content $label:hover, &$selected:focus > $content $label': {
        backgroundColor: 'transparent',
      },
      '&:hover > $content, &$selected:hover > $content': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:focus > $content, &$selected > $content': {
        backgroundColor: 'transparent'
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
      '&$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    content: {
      color: theme.palette.text.primary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightLight,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    group: {
      marginLeft: 0,
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelLevel1: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1, 0),
    },
    labelLevel2: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 1),
    },
    labelLevel2CurrentlyActive: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 1),
        backgroundColor: '#f5f5f5',
        color: theme.palette.grey[800],
        borderRadius: '20px',
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },
    inactiveLabelText: {
        color: theme.palette.grey[500],
    }
}));
  
function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const { level, labelText, button, currentlyActive, labelIcon, labelIconSpecial, avatarIcon, labelInfo, tooltip,
        color, bgColor, clientType, hasViewed, line2, miniLink, ...other } = props;

    return (
      <Tooltip placement={"right"} title={tooltip !== undefined && tooltip !== null && tooltip !== '' ? tooltip : ''}>
      <TreeItem
        label={
          <div className={
              level === 1 ? classes.labelLevel1 : 
              level === 2 && currentlyActive ? classes.labelLevel2CurrentlyActive :
              level === 2 ? classes.labelLevel2 : null}>
            <span style={{width: '38px', textAlign: 'left'}}>
                {labelIcon !== undefined && labelIcon !== null ?
                    <FontAwesomeIcon icon={labelIcon} style={labelIconSpecial === "secondary" ? { color: theme.palette.secondary.main } : labelIconSpecial === 'interim' ? { color: theme.palette.grey[500], fontSize: '13px'} : {}} />
                :avatarIcon !== undefined && avatarIcon !== null ?
                    <Avatar style={{height: '20px', width: '20px'}} src={avatarIcon} />
                :''}
            </span>
            <Typography variant="body2" style={miniLink ? {color: theme.palette.primary.main, fontWeight: '600', fontSize: '12px', padding: '3px 0px 3px 0px'}:{}}
            className={['cust', 'custE', 'legalOrg'].includes(clientType) && hasViewed !== undefined && !hasViewed ? classes.inactiveLabelText : classes.labelText} align="left">
              {labelText}
              {['cust', 'custE', 'legalOrg'].includes(clientType) && hasViewed !== undefined && !hasViewed ? 
                <FontAwesomeIcon icon={faEyeSlash} style={{color: theme.palette.grey[500], marginLeft: '10px'}} /> : 
               ['cust', 'custE', 'legalOrg'].includes(clientType) && hasViewed !== undefined && hasViewed ? 
                <FontAwesomeIcon icon={faEye} style={{color: theme.palette.primary.main, marginLeft: '10px'}} /> : ''}
              {button !== undefined && button !== null && button.text !== undefined ?
                <span style={{backgroundColor: (button.color === 'secondary' ? theme.palette.secondary.main : theme.palette.primary.main), borderRadius: '10px', fontSize: '12px', fontWeight: '700', marginLeft: '10px', padding: '2px 8px 2px 8px', color: theme.palette.primary.contrastText}}>
                    {button.text}
                </span>
              : '' }
              {line2 !== undefined ? 
              <>
              <br/>{line2}
              </> :''}
            </Typography>
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          </div>
        }
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }}
        {...other}
      />
      </Tooltip>
    );
}

/*
function StyledCheckbox(props) {
    return (
      <Checkbox
        disableRipple
        checkedIcon={<FontAwesomeIcon icon={faCheckSquare} style={{fontSize: '22px', color: '#7243dd'}} />}
        icon={<FontAwesomeIcon icon={faSquare} style={{fontSize: '22px', color: '#827f8f'}} />}
        {...props} />
    );
}*/

const DrawerCore = (props) => {

    const drawerWidth = 260;
    const drawerMiniWidth = 85;
    const menuWidth = 250

    const useStyles = makeStyles(theme => ({
      drawer: {
          [theme.breakpoints.down('sm')]: {
              zIndex: 1200,
              flexShrink: 0,
          },
          [theme.breakpoints.up('md')]: {
              flexShrink: 0,
              width: drawerWidth,
              zIndex: 100,
              transition: 'width 0.2s ease-in',
              whiteSpace: 'nowrap',
          },
      },
      drawerGone: {
        [theme.breakpoints.down('sm')]: {
            width: '0px',
            flexShrink: 0,
        },
        [theme.breakpoints.up('md')]: {
            flexShrink: 0,
            width: drawerMiniWidth,
            zIndex: 100,
            transition: 'width 0.2s ease-in',
        },
      },
      drawerIcon: {
        fontSize: '26px',
      },
      drawerSmallIcon: {
        fontSize: '18px',
      },
      boxWithIconSM: {
        width: '60px',
        color: theme.palette.grey[800],
        fontSize: '12px',
        '&:hover': {
            color: theme.palette.primary.main,
            fontWeight: '700',
            cursor: 'pointer',
        }
      },
      boxWithIcon:{
        width: drawerMiniWidth,
        color: theme.palette.grey[800],
        fontSize: '12px',
        '&:hover': {
            color: theme.palette.primary.main,
            fontWeight: '700',
            cursor: 'pointer',
        }
      },
      boxWithIconActive: {
        width: drawerMiniWidth,
        color: theme.palette.primary.main,
        fontWeight: '700',
        fontSize: '12px',
      },
      drawerPaper: {
        //backgroundColor: '#f9f9f9',
        width: drawerWidth,
      },
      drawerPaperClose: {
        [theme.breakpoints.down('sm')]: {
            width: '0px',
        },
        [theme.breakpoints.up('md')]: {
            width: drawerMiniWidth,
        },
        overflowX: 'hidden',
      },
      containerMargin: {
          marginTop: '30px',
          marginBottom: '15px'
      },
      containerMarginUp: {
        marginTop: '30px',
        marginBottom: '0px'
      },
      activeListItem: {
          backgroundColor: theme.palette.grey[100]
      },
      nested: {
          paddingLeft: theme.spacing(3),
      },
      nestedActive: {
          paddingLeft: theme.spacing(3),
          backgroundColor: theme.palette.grey[100]
      },
      nested2: {
          paddingLeft: theme.spacing(5),
          paddingTop: '3px',
          paddingBottom: '3px',
      },
      nested2Active: {
          paddingLeft: theme.spacing(5),
          paddingTop: '3px',
          paddingBottom: '3px',
          backgroundColor: theme.palette.grey[100]
      },
      listItemLevel1Done: {
        fontWeight: '600',
        color: theme.palette.success.main,
      },
      listItemLevel1: {
          fontWeight: '600',
          color: theme.palette.primary.main,
      },
      listItemLevel2: {
          fontSize: '13px',
          fontWeight: '500'
      },
      listItemLevel3: {
          fontSize: '12px',
          fontWeight: '300',
      },
      toggleTOC: {
        position: 'fixed', 
        top: '100px', 
        left: '254px', 
        backgroundColor: theme.palette.primary.contrastText, 
        borderRadius: '6px',
        width: '24px',
        height: '34px',
        border: '1px #e0e0e0 solid',
        color: theme.palette.grey[600],
        '&:hover' : {
          border: '1px solid' + theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          cursor: 'pointer',
        },
        transition: 'left 0.2s',
      },
      toggleTOCGone: {
        display: 'none',
      },
      toggleCaretLeft: {
        paddingLeft: '10px', 
        paddingTop: '9px',
        transform: 'rotate(0deg)',
      },
      bold: {
          fontWeight: '700',
      },
      tinyPen: {
        fontSize: '10px',
        paddingLeft: '6px',
      },
      statusChip: {
        color: theme.palette.grey[700],
        fontSize: '12px', 
        fontWeight: '500',
        padding: '2px 5px 2px 5px',
        marginTop: '15px'
      },
      smartModeSpan: {
        fontSize: '15px',
        fontWeight: '700',
        margin: '0px 0px 0px 8px',
      },
      smartCheck: {
        padding: '0px',
        margin: '0px',
      },
      mr1: {
          marginRight: '10px',
      },
      stdChip: {
        padding: '0px 6px 0px 6px', 
        margin: '5px 8px 5px 0px', 
        height: '28px',
      },
      iconPrimary: {
        color: theme.palette.primary.main,
      },
      iconSecondary: {
        color: theme.palette.secondary.main,
      },
      agrStatusChip: {
          fontSize: '13px',
          fontWeight: '600',
          color: theme.palette.secondary.main,
      },
      agrStatusChipBeforeAfter: {
        fontSize: '11px',
        fontWeight: '300',
      },
      updateTooltip: {
          border: '1px solid #e0e0e0',
          padding: '20px',
          borderRadius: '10px',
          fontWeight: '300',
      }
    }));
    const classes = useStyles();

    const history = useHistory()

    const trigger = useScrollTrigger({ threshold: 500, disableHysteresis: true });

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [tocGone, setTocGone] = React.useState(true);
    const [smartFieldsGone, setSmartFieldsGone] = React.useState(true);
    const [summaryGone, setSummaryGone] = React.useState(props.module === 'legal' || props.openEdp ? false : true);
    //const [summaryGone, setSummaryGone] = React.useState(true);
    const [summaryCollabs, setSummaryCollabs] = React.useState([])
    const [summarySigners, setSummarySigners] = React.useState([])
    const [avs, setAvs] = React.useState([])
    const [snack, setSnack] = React.useState(false);
    const [anchorElToolbox, setAnchorElToolbox] = React.useState(null);
    const [anchorElOatChip, setAnchorElOatChip] = React.useState(null);
    const [showAllVersions, setShowAllVersions] = React.useState(false);

    useEffect(() => {
        // Determine which older versions are visible - either own versions or the last of the cpty for that specific iteration
        if(props.avs !== undefined && props.avs !== null && props.avs.length > 0) {
            setAvs(getFilteredAVList(props.avs, props.isPrimary))
        }
    }, [props.avs, props.isPrimary])

    const backLink = (['sales', 'procurement', 'partnerships', 'people', 'corporate', 'counterparty'].includes(props.module) && props.pageType === 'agreement' ?  
                        "/core/" + props.module + "/relationship/" + props.rid + "/overview" : // Agreement
                      props.pageType === 'agreement' && ['legal'].includes(props.module) && props.activeOat !== undefined && (['Template'].includes(props.activeOat.draftFor) || props.activeOat.active) ? 
                        "/core/legal/templates/" + props.activeOat.module : // Templates
                      props.pageType === 'agreement' && ['legal'].includes(props.module) && props.activeOat !== undefined && (['Terms'].includes(props.activeOat.draftFor) || props.activeOat.external) ? 
                        "/core/legal/templates/" + props.activeOat.module + '/terms' : // Terms
                      props.pageType === 'relationship' ? 
                        "/core/" + props.module + "/dashboard" : // Relationship
                      props.pageType === 'signup' ? 
                        '/home' :
                      props.pageType === 'help' && props.page !== 'overview' ? 
                        "/core/help/overview" : // help
                      props.pageType === 'help' && ['overview', 'settings'].includes(props.page) ? 
                        "/core/settings" :
                      props.pageType === 'profile' ? 
                        "/network" : "/network")

    const handleSummaryClick = (type) => {

        // template clicks
        if(['deactivate','unpublish','activate','publishing','none','newdraft','export','delete','deleteTemplate'].includes(type)) {

            handleCloseOatChip()
            props.handleTemplateFunction(type);

        } else {
        // otherwise
            let mainAgr = props.agrsAvs !== undefined && props.agrsAvs !== null && props.agrsAvs.filter((aa) => aa.isMain)[0] !== undefined ?
                    props.agrsAvs.filter((aa) => aa.isMain)[0] : null

            if(type !== undefined && type !== null && (type.click === 'version' || type === 'cloneVersion')) {

                if(type.avv !== props.avv) {
                    props.handleDrawerClick(type)
                }

            } else if(type === 'pullCollaborators' && mainAgr !== null) {

                mainAgr.contributors.forEach((c) => {
                    if(summaryCollabs.filter((sc) => sc._id === c.uid)[0] === undefined && props.users.filter((u) => u._id === c.uid)[0] !== undefined) {
                        axios.get('/logging/protect/gettime/' + c.uid + "/" + mainAgr._id)
                        .then((restime) => {
                            setSummaryCollabs(scs => [...scs, {...props.users.filter((u) => u._id === c.uid)[0], entity: c.entity, hasViewed: restime.data.data !== undefined && restime.data.data !== null }])
                        }).catch((err) => console.log(err))

                    } else if (summaryCollabs.filter((sc) => sc._id === c.uid)[0] === undefined) {
                        axios.get('/user/protect/user/' + c.uid)
                        .then((res) => {
                            axios.get('/logging/protect/gettime/' + c.uid + "/" + mainAgr._id)
                            .then((restime) => {
                                setSummaryCollabs(scs => [...scs, {...res.data.data, entity: c.entity, hasViewed: restime.data.data !== undefined && restime.data.data !== null}])
                            }).catch((err) => console.log(err))
                        }).catch((err) => console.log(err))
                    }
                })
                
            } else if(type === 'pullSigners' && mainAgr !== null) {

                mainAgr.signatories.forEach((s) => {
                    if(summarySigners.filter((ss) => ss._id === s.uid)[0] === undefined && props.users.filter((u) => u._id === s.uid)[0] !== undefined) {
                        axios.get('/logging/protect/gettime/' + s.uid + "/" + mainAgr._id)
                        .then((restime) => {
                            setSummarySigners(sss => [...sss, {...props.users.filter((u) => u._id === s.uid)[0], entity: s.entity, hasViewed: restime.data.data !== undefined && restime.data.data !== null }])
                        }).catch((err) => console.log(err))

                    } else if (summarySigners.filter((ss) => ss._id === s.uid)[0] === undefined) {
                        axios.get('/user/protect/user/' + s.uid)
                        .then((res) => {
                            axios.get('/logging/protect/gettime/' + s.uid + "/" + mainAgr._id)
                            .then((restime) => {
                                setSummarySigners(sss => [...sss, {...res.data.data, entity: s.entity, hasViewed: restime.data.data !== undefined && restime.data.data !== null}])
                            }).catch((err) => console.log(err))
                        }).catch((err) => console.log(err))
                    }
                }) 

            } else {
                props.handleDrawerClick(type)
            }
        }

    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleScroll = (e, ref) => {
        setMobileOpen(false);
        props.scrollTo(ref)
    }

    const handleSummaryToggle = () => {
        setSummaryGone(!summaryGone)
    }

    const handleTocToggle = () => {
        setTocGone(!tocGone);
    }

    /*
    const handlePartiesToggle = () => {
        setPartiesGone(!partiesGone)
    }*/

    const handleSmartFieldsToggle = () => {
        setSmartFieldsGone(!smartFieldsGone)
    }

    const handleSMMenuOption = (option) => {
        handleDrawerToggle();
        if(option === 'comms') {
            props.handleCommsToggle()
        } else if (option === 'intro'){
            props.handleIntroStart()
        }
    }

    /*
    const handleTogglePartiesSM = () => {
        setPartiesToggleSM(!partiesToggleSM)
    }*/

    const handleCopySmartFieldJSON = () => {

        let sfs = ''
        props.smartFields.forEach((sf) => {
            if(props.toc.filter((t) => t.sclid === sf.singleClauseID)[0] !== undefined) {
                sfs = sfs.concat(`
                { 
                    "sfid": "` + sf._id + `",
                    "clause": "` + trunc(props.toc.filter((t) => t.sclid === sf.singleClauseID)[0].title, 100) + `",
                    "name": "` + sf.name + `",
                    "val1": "` + sf.val1 + `",
                    "val2": "` + sf.val2 + `",
                 },
                 `)
            }
        })
        sfs = sfs.slice(0, -1)

        let j = ''

        if(props.agr !== undefined && props.agr._id !== undefined) {
            j = `
            {
                "aid": "` + props.agr._id + `",
                "agreement": "` + props.agr.agrFullName + ` (` + props.agr.agrShortName + `)",
                "primary": "` + props.primaryCpty.legalName + `",
                "secondary": "` + props.secondaryCpty.legalName + `",
                "status": "` + props.agrStatus + `",
                "smartfields": [` + sfs + `]
            }`
        } else {
            j = `
            {
                "templateid": "` + (props.activeOat !== undefined ? props.activeOat._id : '') + `",
                "agreement": "TEMPLATE / TERMS",
                "smartfields": [` + sfs + `]
            }`
        }
        
        copy(j);
        setSnack(true);
    }

    const RenderEffectiveProperties = () => {
        return (
            <StyledTreeItem 
            nodeId={props.module === "legal" ? "3" : "8"} 
            labelText={"Effective Date & Term"} 
            labelIcon={faToggleOn} 
            labelIconSpecial={null}
            labelInfo={''} 
            level={1}>

                <Box mt={1} mb={1} align="center">
                    <span onClick={
                        props.module === "legal" ?
                            e => props.handleTemplateFunction('changeedt') :
                            e => props.handleDrawerClick('changeEffDate')}>
                    <StyledTreeItem
                    nodeId={props.module === "legal" ? "31" : "81"}
                    labelText={"Effective Date: " + 
                        trunc((props.module === "legal" && props.activeOat.effectiveDateType !== undefined && props.activeOat.effectiveDateType !== null &&
                        effectivedatetypes.filter((edt) => edt.val === props.activeOat.effectiveDateType)[0] !== undefined ?
                                effectivedatetypes.filter((edt) => edt.val === props.activeOat.effectiveDateType)[0].short : 
                        props.agr.effectiveDate !== undefined && props.agr.effectiveDate !== null && !isNaN(Date.parse(props.agr.effectiveDate)) ? 
                                dayjs.utc(props.agr.effectiveDate).format('MMM D, YYYY') :
                        props.module !== "legal" && props.agr !== undefined && props.agr.agrStatus !== 'InForce' ?
                                'last signature' :
                                'not defined'), 13)}
                    labelIcon={faCalendarCheck}
                    labelIconSpecial={"interim"}
                    level={2} />
                    </span>
                    <span onClick={
                        props.module === "legal" ? 
                            e => props.handleTemplateFunction('changeterm') :
                            e => props.handleDrawerClick('changeTerm')}>
                    <StyledTreeItem
                    nodeId={props.module === "legal" ? "32" : "82"}
                    labelText={"Term: " + 
                        (props.module === "legal" &&
                        props.activeOat !== undefined && props.activeOat !== null &&
                        props.activeOat.effectiveTerm !== undefined && props.activeOat.effectiveTerm !== null &&
                        props.activeOat.effectiveTerm.isCustom ?
                            'not defined' :
                        props.module === "legal" &&
                        props.activeOat !== undefined && props.activeOat !== null &&
                        props.activeOat.effectiveTerm !== undefined && props.activeOat.effectiveTerm !== null &&
                        props.activeOat.effectiveTerm.isCustom !== undefined && props.activeOat.effectiveTerm.isCustom !== null &&
                        !props.activeOat.effectiveTerm.isCustom && props.activeOat.effectiveTerm.initial !== undefined && 
                        props.activeOat.effectiveTerm.initial !== null && props.activeOat.effectiveTerm.initial.indexOf("_") > 0 &&
                        (props.activeOat.effectiveTerm.initial.indexOf("_") + 1) < props.activeOat.effectiveTerm.initial.length ?
                            // initial term
                            (props.activeOat.effectiveTerm.initial.substring(0, props.activeOat.effectiveTerm.initial.indexOf("_")) +
                            " " + props.activeOat.effectiveTerm.initial.substring(props.activeOat.effectiveTerm.initial.indexOf("_") + 1)/* +
                            // renewal term
                            (props.activeOat !== undefined && props.activeOat !== null &&
                            renewaltypes.filter((edt) => edt.val === props.activeOat.renewalType)[0] !== 'none' && 
                            props.activeOat.effectiveTerm.renewal !== undefined && 
                            props.activeOat.effectiveTerm.renewal !== null && props.activeOat.effectiveTerm.renewal.indexOf("_") > 0 &&
                            (props.activeOat.effectiveTerm.renewal.indexOf("_") + 1) < props.activeOat.effectiveTerm.renewal.length ?
                                ' + ' + props.activeOat.effectiveTerm.renewal.substring(0, props.activeOat.effectiveTerm.renewal.indexOf("_")) +
                                " " + props.activeOat.effectiveTerm.renewal.substring(props.activeOat.effectiveTerm.renewal.indexOf("_") + 1) : 
                                '')*/
                            ) : 
                        props.agr !== undefined && props.agr !== null &&
                        props.agr.effectiveTerm !== undefined && props.agr.effectiveTerm !== null &&
                        props.agr.effectiveTerm.isCustom ?
                            'not defined' :
                        props.agr !== undefined && props.agr !== null &&
                        props.agr.effectiveTerm !== undefined && props.agr.effectiveTerm !== null &&
                        props.agr.effectiveTerm.isCustom !== undefined && props.agr.effectiveTerm.isCustom !== null &&
                        !props.agr.effectiveTerm.isCustom && props.agr.effectiveTerm.initial !== undefined && 
                        props.agr.effectiveTerm.initial !== null && props.agr.effectiveTerm.initial.indexOf("_") > 0 &&
                        (props.agr.effectiveTerm.initial.indexOf("_") + 1) < props.agr.effectiveTerm.initial.length ?
                            // initial term
                            (props.agr.effectiveTerm.initial.substring(0, props.agr.effectiveTerm.initial.indexOf("_")) +
                            " " + props.agr.effectiveTerm.initial.substring(props.agr.effectiveTerm.initial.indexOf("_") + 1)/* +
                            // renewal term
                            (renewaltypes.filter((edt) => edt.val === props.agr.renewalType)[0] !== 'none' && props.agr.effectiveTerm.renewal !== undefined && 
                            props.agr.effectiveTerm.renewal !== null && props.agr.effectiveTerm.renewal.indexOf("_") > 0 &&
                            (props.agr.effectiveTerm.renewal.indexOf("_") + 1) < props.agr.effectiveTerm.renewal.length ?
                                ' + ' + props.agr.effectiveTerm.renewal.substring(0, props.agr.effectiveTerm.renewal.indexOf("_")) +
                                " " + props.agr.effectiveTerm.renewal.substring(props.agr.effectiveTerm.renewal.indexOf("_") + 1) : 
                                '')*/
                            ) : 
                            'not defined')}
                    labelIcon={faHourglassStart}
                    labelIconSpecial={"interim"}
                    level={2} />
                    </span>

                    {props.module !== "legal" ? 
                    <span onClick={e => props.handleDrawerClick('changeExpDate')}>
                    <StyledTreeItem
                    nodeId={"83"}
                    labelText={"Expiry: " + (
                        props.agr.expiryDate !== undefined && props.agr.expiryDate !== null && !isNaN(Date.parse(props.agr.expiryDate)) ? 
                                dayjs.utc(props.agr.expiryDate).format('MMM D, YYYY') :
                                'not defined')}
                    labelIcon={faCalendarTimes}
                    labelIconSpecial={"interim"}
                    level={2} />
                    </span>
                    :''}

                    <span onClick={
                        props.module === "legal" ? 
                            e => props.handleTemplateFunction('changernt') :
                            e => props.handleDrawerClick('changeRenewal')}>
                    <StyledTreeItem
                    nodeId={props.module === "legal" ? "33" : "84"}
                    labelText={"Renewal: " + 
                        (props.module === "legal" && props.activeOat.renewalType !== undefined && props.activeOat.renewalType !== null &&
                        renewaltypes.filter((edt) => edt.val === props.activeOat.renewalType)[0] !== undefined ?
                                renewaltypes.filter((edt) => edt.val === props.activeOat.renewalType)[0].short : 
                        props.agr.renewalType !== undefined && props.agr.renewalType !== null &&
                        renewaltypes.filter((edt) => edt.val === props.agr.renewalType)[0] !== undefined ?
                                renewaltypes.filter((edt) => edt.val === props.agr.renewalType)[0].short :
                                'not defined')}
                    labelIcon={faSyncAlt}
                    labelIconSpecial={"interim"}
                    level={2} />
                    </span>
                </Box>

            </StyledTreeItem>
        )
    }

    const handleOpenOatChip = event => {
        setAnchorElOatChip(event.currentTarget);
    };

    const handleCloseOatChip = () => {
        setAnchorElOatChip(null);
    };

    const handleOpenToolbox = event => {
        setAnchorElToolbox(event.currentTarget);
    };

    const handleCloseToolbox = () => {
        setAnchorElToolbox(null);
    };

    const handleToolboxOption = (option) => {
        handleCloseToolbox()
        if(['export', 'offlinesigning'].includes(option)) { props.handleAgrActOpen(option) }
        else if(['reorderExhibits'].includes(option)) { props.handleReorderExhibits() }
        else if(['deactivateAgreement'].includes(option)) { props.deactivateAgreement() }
        else if(['acceptagr'].includes(option)) { props.handleDrawerClick(option) }
    }

    const RenderSmartFieldsBlock = () => {
        return (
            <>

                {props.smartFields !== undefined && props.smartFields !== null && props.smartFields.length > 0 ?
                <>
                <Box mt={3} align="center">
                    <Button onClick={handleCopySmartFieldJSON} >
                        <FontAwesomeIcon icon={faCopy} className={classes.mr1} />Copy as Data
                    </Button>
                </Box>
                <Box mt={3} mb={3} align="center">
                    {props.smartFields.map((sf) => (
                        <Box key={sf._id}>
                        <Chip
                        label={<span style={{color:'#ffffff', fontWeight: '500'}}>{trunc(sf.name !== '' ? sf.name : '(Name not defined)', 30)}</span>}
                        clickable
                        color="primary"
                        size="small"
                        className={classes.stdChip}
                        onClick={e => handleScroll(e, props.toc.filter((t) => t.sclid === sf.singleClauseID)[0] !== undefined ? props.toc.filter((t) => t.sclid === sf.singleClauseID)[0].id : '')}
                        />
                        </Box>
                    ))}
                </Box>

                </>
                :
                <Grid item>
                    <Box mt={6} align="center">
                        <Typography variant="body2">
                        Smart fields do not exist<br/>
                        for this agreement.
                        </Typography>
                    </Box>
                </Grid>
                }
            </>       
        )
    }

    const RenderStatusBlock = ({beforeOrAfter, agrStatus}) => {
        return (
            <>
            {['before'].includes(beforeOrAfter) && ['Review', 'Negotiation', 'Execution', 'InForce'].includes(agrStatus) ?
            <span className={classes.agrStatusChipBeforeAfter} style={{color: theme.palette.grey[600]}}>
            {agrStatus === 'Review' ? 'Draft' :
            agrStatus === 'Negotiation' ? 'Review' :
            agrStatus === 'Execution' ? 'Negotiation' :
            agrStatus === 'InForce' ? 'Execution' : ''}
            </span>
            : '' }

            {['Review', 'Negotiation', 'Execution'].includes(agrStatus) ||
            (['InForce'].includes(agrStatus) && ['before'].includes(beforeOrAfter)) ||
            (['Draft'].includes(agrStatus) && ['after'].includes(beforeOrAfter)) ?
            <FontAwesomeIcon icon={faChevronRight} 
            style={{color: theme.palette.grey[600], fontSize: '9px', padding: '0px 8px 0px 7px'}} />
            : '' }

            {['after'].includes(beforeOrAfter) && ['Draft', 'Review', 'Negotiation', 'Execution'].includes(agrStatus) ?
            <span className={classes.agrStatusChipBeforeAfter} style={{color: theme.palette.grey[600]}}>
            {agrStatus === 'Draft' ? 'Review' :
            agrStatus === 'Review' ? 'Negotiation' :
            agrStatus === 'Negotiation' ? 'Execution' :
            agrStatus === 'Execution' ? 'In Effect' : ''}
            </span>
            : '' }
            </>
        )
    }

    const RenderCptyBox = ({cpty, summary}) => {
        return(
            <>
                <Typography variant="subtitle1" gutterBottom className={classes.bold}>
                {cpty !== undefined && cpty !== null && cpty.shortName !== undefined ? 
                 trunc(cpty.shortName, 32) : 'Unknown'}
                </Typography>

                {!summary ?
                <>
                <Typography variant="body2">
                {cpty !== undefined && cpty !== null && cpty.legalName !== undefined ? 
                 trunc(cpty.legalName, 32) : <span>&nbsp;</span>}
                </Typography>
                <Typography variant="body2">
                {cpty !== undefined && cpty !== null && cpty.address !== undefined && cpty.address.filter((a) => a.addressType === 'billto')[0] !== undefined ?
                 trunc(cpty.address.filter((a) => a.addressType === 'billto')[0].streetAndNo, 32) : <span>&nbsp;</span>}
                </Typography>
                
                <Typography variant="body2">
                {cpty !== undefined && cpty !== null && cpty.address !== undefined && cpty.address.filter((a) => a.addressType === 'billto')[0] !== undefined &&
                cpty.address.filter((a) => a.addressType === 'billto')[0].address2 !== undefined &&  cpty.address.filter((a) => a.addressType === 'billto')[0].address2 !== null &&
                cpty.address.filter((a) => a.addressType === 'billto')[0].address2 !== '' ?
                 trunc(cpty.address.filter((a) => a.addressType === 'billto')[0].address2, 32) : ''}
                </Typography>


                <Typography variant="body2">
                {cpty !== undefined && cpty !== null && cpty.address !== undefined && cpty.address.filter((a) => a.addressType === 'billto')[0] !== undefined ?
                trunc(cpty.address.filter((a) => a.addressType === 'billto')[0].city + " " + cpty.address.filter((a) => a.addressType === 'billto')[0].zip + 
                     (cpty.address.filter((a) => a.addressType === 'billto')[0].state !== undefined && cpty.address.filter((a) => a.addressType === 'billto')[0].state !== null && cpty.address.filter((a) => a.addressType === 'billto')[0].state !== '' ?
                        (" " + cpty.address.filter((a) => a.addressType === 'billto')[0].state) : ''), 32) : <span>&nbsp;</span>}
                </Typography>
                <Typography variant="body2">
                {cpty !== undefined && cpty !== null && cpty.address !== undefined && cpty.address.filter((a) => a.addressType === 'billto')[0] !== undefined ?
                countries.filter((c) => c.code === cpty.address.filter((a) => a.addressType === 'billto')[0].country)[0].label : <span>&nbsp;</span>}
                </Typography>
                </>
                : ''}
            </>
        )
    }

    const relDrawer = [{
        page: 'overview', 
        title: 'Overview',
        click: e => history.push("/core/" + props.module + "/relationship/" + props.rid + "/overview"),
        icon: faFolderOpen,
        hide: false,
    },
    {
        page: 'agreements', 
        title: 'Agreements',
        click: e => history.push("/core/" + props.module + "/relationship/" + props.rid + "/agreements"),
        icon: faCopy,
        hide: false,
    },
    {
        page: 'entities', 
        title: 'Entities',
        click: e => history.push("/core/" + props.module + "/relationship/" + props.rid + "/entities"),
        icon: faUniversity,
        hide: !props.isCustomer,
    }/*,
    {
        page: 'activity', 
        title: 'Activity',
        click: e => history.push("/core/" + props.module + "/relationship/" + props.rid + "/activity"),
        icon: faHistory,
        hide: false,
    }*/]

    const profileDrawer = [{
        page: 'overview', 
        title: 'Overview',
        click: e => history.push("/network/" + props.orgurl),
        icon: faSearch,
        hide: false,
        children: [],
    },
    {
        page: 'terms', 
        title: 'Terms',
        click: e => history.push("/network/" + props.orgurl + "/terms/all"),
        icon: faFileAlt,
        hide: false,
        children: [],
    },
    {
        page: 'certifications', 
        title: 'Certifications',
        click: e => history.push("/network/" + props.orgurl + "/certs"),
        icon: faStar,
        hide: !props.showCerts,
        children: [],
    },
    {
        page: 'access', 
        title: 'Access',
        click: e => history.push("/network/" + props.orgurl + "/login"),
        icon: faSignInAlt,
        hide: props.userLoggedIn,
        children: [],
    }]

    const legalDrawer = [
    ...props.modules !== undefined && props.modules.includes('sales') ? [{
        page: 'sales', 
        title: 'Customers',
        click: e => history.push(
            props.pageType === 'terms' ? "/core/legal/templates/sales/terms" : 
            props.pageType === 'playbook' ? "/core/legal/playbook/sales" :
            "/core/legal/templates/sales"),
        icon: faHandshake,
        hide: false,
    }] : [],
    ...props.modules !== undefined && props.modules.includes('procurement') ? [{
        page: 'procurement', 
        title: 'Suppliers',
        click: e => history.push(
            props.pageType === 'terms' ? "/core/legal/templates/procurement/terms" : 
            props.pageType === 'playbook' ? "/core/legal/playbook/procurement" :
            "/core/legal/templates/procurement"),
        icon: faShoppingCart,
        hide: false,
    }] : [],
    ...props.modules !== undefined && props.modules.includes('partnerships') ? [{
        page: 'partnerships', 
        title: 'Partners',
        click: e => history.push(
            props.pageType === 'terms' ? "/core/legal/templates/partnerships/terms" : 
            props.pageType === 'playbook' ? "/core/legal/playbook/partnerships" :
            "/core/legal/templates/partnerships"),
        icon: faUserFriends,
        hide: false,
    }] : [],
    ...props.modules !== undefined && props.modules.includes('people') ? [{
        page: 'people', 
        title: 'Employees',
        click: e => history.push(
            props.pageType === 'playbook' ? "/core/legal/playbook/people" : 
            "/core/legal/templates/people"),
        icon: faUser,
        hide: props.pageType === 'terms',
    }] : [],
    ...props.modules !== undefined && props.modules.includes('corporate') ? [{
        page: 'corporate', 
        title: 'Corporate',
        click: e => history.push(
            props.pageType === 'playbook' ? "/core/legal/playbook/corporate" :
            "/core/legal/templates/corporate"),
        icon: faBuilding,
        hide: props.pageType === 'terms',
    }] : [],
    {
        page: 'privacy', 
        title: 'Privacy',
        click: e => history.push("/core/legal/templates/privacy/terms"),
        icon: faShieldAlt,
        hide: ['templates','playbook'].includes(props.pageType),
    }]

    const tasksDrawer = [{
        page: 'overview', 
        title: 'Overview',
        click: e => props.switchPage('overview'),
        icon: faGaugeSimple,
        hide: false,
    },
    {
        page: 'approvals', 
        title: 'Approvals',
        route: null,
        click: e => props.switchPage('approvals'),
        icon: faCheckSquare,
        hide: ['terms','templates'].includes(props.module),
    },
    {
        page: 'reviews', 
        title: 'Reviews',
        route: null,
        click: e => props.switchPage('reviews'),
        icon: faSearch,
        hide: ['terms','templates'].includes(props.module),
    }/*,
    {
        page: 'requests', 
        title: 'Requests',
        route: null,
        click: e => props.switchPage('requests'),
        icon: faProjectDiagram,
        hide: !['sales', 'procurement', 'partnerships'].includes(props.module),
    }*/]

    const analyticsDrawer = [{
        page: 'agreements', 
        title: 'Agreements',
        click: e => props.switchPage('agreements'),
        icon: faFileContract,
        hide: false,
    },
    {
        page: 'time', 
        title: 'Time',
        route: null,
        click: e => props.switchPage('time'),
        icon: faClock,
        hide: false,
    },
    {
        page: 'cycles', 
        title: 'Cycles',
        route: null,
        click: e => props.switchPage('cycles'),
        icon: faRetweet,
        hide: false,
    },
    {
        page: 'smartfields', 
        title: 'Smart Fields',
        route: null,
        click: e => props.switchPage('smartfields'),
        icon: faHandSparkles,
        hide: false,
    }]
   
    const agMiniDrawer = [
    ...props.agrStatus !== undefined && props.agrStatus === 'Exhibit' ? [{
        page: 'mainbody', 
        title: 'Main Body',
        click: e => history.push("/core/" + props.module + "/agreement/" + props.rid + "/" + props.agr.parentAID),
        icon: faArrowLeft,
        changeColor: 'secondary',
        //hide: props.pageType === 'terms',
    }] : [],    
    {
        page: 'version', 
        title: 'Version',
        click: e => handleSummaryToggle(),
        icon: faFileContract,
        hide: false,
    },
    {
        page: 'contents', 
        title: 'Contents',
        click: e => handleTocToggle(),
        icon: faList,
        hide: props.toc === undefined || props.toc === null || props.toc.length === 0,
    },
    {
        page: 'activity', 
        title: 'Activity',
        click: e => props.handleCommsToggle(),
        icon: faComments,
        hide: ['legal'].includes(props.module),
    },
    {
        page: 'approvals',
        title: 'Approvals',
        click: e => props.handleNegoApprovalsOpen('agreement'),
        icon: faThumbsUp,
        hide: !props.approvalTicketsExist,
        badge: props.approvalTicketsBadge,
    },
    {
        page: 'reviews',
        title: 'Reviews',
        click: e => props.parentCall('reviewtickets'),
        icon: faSearch,
        hide: !props.reviewTicketsExist,
        badge: props.reviewTicketsBadge,
    },
    /*{
        page: 'smartfields',
        title: 'Smart fields',
        click: e => handleSmartFieldsToggle(),
        icon: faMagic,
        hide: !['legal'].includes(props.module),
    },*/ 
    {
        page: 'downloadpdf',
        title: 'Download',
        click: e => props.handleDownloadPDF(),
        icon: faDownload,
        hide: props.agrStatus !== 'InForce',
    },
    {
        page: 'more', 
        title: 'More',
        click: e => handleOpenToolbox(e),
        icon: faChevronCircleDown,
        hide: ['legal'].includes(props.module) || 
            ((props.toc === undefined || props.toc === null || props.toc.length === 0) && !props.isAttachment) ||
            (props.agrStatus === 'InForce' && !['Admin', 'Legal'].includes(props.user.role)),
    }]

    const drawerAgPageSummary = (
        <div>

            <Box mb={3} mt={4} align="center">

            {props.module === 'legal' && props.activeOat !== undefined && props.activeOat !== null ?
            <>
            <Tooltip title="Expand options" placement="right">
            <Chip
                onClick={e => handleOpenOatChip(e)}
                variant={props.activeOat !== undefined && (props.activeOat.active || props.activeOat.external) ? "default" : "outlined"}
                color={
                    (props.activeOat !== undefined && props.activeOat.active) ? "primary" :
                    (props.activeOat !== undefined && props.activeOat.external) ? "secondary" :
                    (props.activeOat !== undefined && ['Template', 'Terms'].includes(props.activeOat.draftFor)) ? "default" : "secondary"} 
                label={
                    <span style={{
                        fontSize: '13px', fontWeight: '700', padding: '2px 15px 2px 15px',
                        color: 
                            props.activeOat !== undefined && (props.activeOat.active || props.activeOat.external) ? theme.palette.secondary.contrastText : 
                            props.activeOat !== undefined && ['Template'].includes(props.activeOat.draftFor) ? theme.palette.primary.main : theme.palette.secondary.main,
                    }}>
                    {props.oatStatus}&nbsp;&nbsp;
                    <FontAwesomeIcon icon={faCaretDown} style={
                        props.activeOat !== undefined && (props.activeOat.active || props.activeOat.external) ? {color: '#ffffff'} : 
                        props.activeOat !== undefined && (props.activeOat.draftFor === 'Terms') ? {color: theme.palette.secondary.main} : {}} />
                    </span>}
                style={{cursor: 'pointer'}}
            />
            </Tooltip>
            <Menu
            id="avatar-menu"
            anchorEl={anchorElOatChip}
            keepMounted
            disableAutoFocusItem
            open={Boolean(anchorElOatChip)}
            onClose={handleCloseOatChip}
            >
                <MenuItem onClick={e => handleSummaryClick(
                        props.activeOat.active ? 'deactivate' : 
                        props.activeOat.external ? 'unpublish' : 
                        props.activeOat.draftFor === 'Template' ? 'activate' : 
                        props.activeOat.draftFor === 'Terms' ? 'publishing' : 'none'
                        )} style={{width: menuWidth + 10}}>
                    <ListItemIcon><FontAwesomeIcon icon={props.activeOat.active || props.activeOat.external ? faToggleOff : faToggleOn} 
                    className={props.activeOat.external || props.activeOat.draftFor === 'Terms' ?
                        classes.iconSecondary : classes.iconPrimary} /></ListItemIcon>
                    <ListItemText>
                        {props.activeOat.active ? "Deactivate" :
                        props.activeOat.external ? "Unpublish" : 
                        props.activeOat.draftFor === "Template" ? "Activate" :
                        props.activeOat.draftFor === "Terms" ? "Publish" : "Change"} this version
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={e => handleSummaryClick('newdraft')} style={{width: menuWidth + 10}}>
                    <ListItemIcon><FontAwesomeIcon icon={faClone} className={props.activeOat.external || props.activeOat.draftFor === 'Terms' ?
                        classes.iconSecondary : classes.iconPrimary} /></ListItemIcon>
                    <ListItemText>
                        Copy into new version
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={e => handleSummaryClick('export')} style={{width: menuWidth + 10}}>
                    <ListItemIcon><FontAwesomeIcon icon={faFileWord} className={props.activeOat.external || props.activeOat.draftFor === 'Terms' ?
                        classes.iconSecondary : classes.iconPrimary} /></ListItemIcon>
                    <ListItemText>
                        Export this version
                    </ListItemText>
                </MenuItem>
                {!props.activeOat.active && !props.activeOat.external ?
                <MenuItem onClick={e => handleSummaryClick('delete')} style={{width: menuWidth + 10}}>
                    <ListItemIcon><FontAwesomeIcon icon={faTrashAlt} className={props.activeOat.external || props.activeOat.draftFor === 'Terms' ?
                        classes.iconSecondary : classes.iconPrimary} /></ListItemIcon>
                    <ListItemText>
                        Delete this version
                    </ListItemText>
                </MenuItem>
                :''}
                {props.activeOat.blueprintID !== '' ?
                <MenuItem onClick={e => handleSummaryClick('deleteTemplate')} style={{width: menuWidth + 10}}>
                    <ListItemIcon><FontAwesomeIcon icon={faTrash} className={props.activeOat.external || props.activeOat.draftFor === 'Terms' ?
                        classes.iconSecondary : classes.iconPrimary} /></ListItemIcon>
                    <ListItemText>
                        Delete {props.activeOat.external || props.activeOat.draftFor === 'Terms' ? "these terms" : "this template"}
                    </ListItemText>
                </MenuItem>
                :''}
            </Menu>

            </>
            : // Agreement flow
            <>
                <Typography className={classes.agrStatusChip}>
                    <RenderStatusBlock beforeOrAfter={'before'} agrStatus={props.agrStatus !== 'Exhibit' ? props.agrStatus : props.parentAgrStatus} />
                    {props.agrStatus === 'InForce' || props.parentAgrStatus === 'InForce' ? "In Effect" : props.agrStatus !== 'Exhibit' ? props.agrStatus : props.parentAgrStatus}
                    <RenderStatusBlock beforeOrAfter={'after'} agrStatus={props.agrStatus !== 'Exhibit' ? props.agrStatus : props.parentAgrStatus} />
                </Typography>
            </>
            
            }
            </Box>

            <TreeView
                style={{width: '245px'}}
                defaultCollapseIcon={<FontAwesomeIcon icon={faCaretDown} style={{color: theme.palette.grey[600], fontSize: '10px'}} />}
                defaultExpandIcon={<FontAwesomeIcon icon={faCaretRight} style={{color: theme.palette.grey[600], fontSize: '10px'}} />}
                defaultEndIcon={<div style={{ width: 24 }}/>}
                defaultExpanded={props.module === 'legal' ? ["1", "2", "3"] : props.openEdp ? ["8"] : []}
                >
                <StyledTreeItem nodeId="1" 
                labelIconSpecial={props.module === 'legal' && props.activeOat !== undefined && (props.activeOat.external || props.activeOat.draftFor === 'Terms') ? "secondary" : null}
                //labelText={"Version " + (props.module === 'legal' && props.activeOat !== undefined ? 'Management' : props.avv !== undefined ? userFriendlyAVV(props.avv) : '')} labelIcon={faClone} level={1}>
                labelText={"Version Management"} labelIcon={faClone} level={1}>

                    {props.module === 'legal' && props.activeOat !== undefined && props.activeOat.external ?
                        <Box mt={1} mb={0}>
                        <span /*onClick={e => history.push('/network/' + props.org.orgurl + "/" + props.activeOat._id)}*/
                        onClick={e => props.handleTemplateFunction('changingURL')}>
                        <StyledTreeItem
                        nodeId={"10_"}
                        labelText={"Public Link"}
                        labelIcon={faGlobe}
                        labelIconSpecial={"interim"}
                        level={2} />
                        </span>
                        </Box>
                    :''}

                    {props.module !== 'legal' && !props.laterVersionOwnedByCpty && !['Exhibit', 'Execution', 'InForce'].includes(props.agrStatus) ?
                        <Box mt={1} 
                        mb={avs !== undefined && avs.filter((av) => av.version < props.avv).length > 0 ? 0 : 1}>
                        <span onClick={e => handleSummaryClick('cloneVersion')}>
                        <StyledTreeItem
                        nodeId={"11"}
                        labelText={"Create interim version"}
                        labelIcon={faClone}
                        labelIconSpecial={"interim"}
                        level={2} />
                        </span>
                        </Box>
                    :props.module === 'legal' && props.activeOat !== undefined && props.activeOat !== null ?
                        <>
                        <Box mt={1} mb={1}>
                        {props.oatVersions.slice(0, showAllVersions ? 20 : 4).map((ov, i) => (
                            <span key={i} onClick={e => history.push('/core/legal/templates/' + ov.module + (ov.draftFor === 'Template' || ov.active ? '/edit/' : '/edit-terms/') + ov._id)}>
                            <StyledTreeItem
                            nodeId={"1" + i}
                            tooltip={
                                <Box className={classes.updateTooltip}>
                                <span className={classes.bold}>Created by</span><br/>
                                {dayjs(ov.lastUpdateDate).format('MMM D, YYYY')} by {(props.users.filter((u) => u._id === ov.lastUpdateBy)[0] !== undefined ? props.users.filter((u) => u._id === ov.lastUpdateBy)[0].displayName : "Unknown")}<br/><br/>
                                <span className={classes.bold}>Last Update</span><br/>
                                {dayjs(ov.lastUpdateDate).format('MMM D, YYYY')} by {(props.users.filter((u) => u._id === ov.lastUpdateBy)[0] !== undefined ? props.users.filter((u) => u._id === ov.lastUpdateBy)[0].displayName : "Unknown")}
                                </Box>}
                            labelText={"Version " + userFriendlyAVV(ov.version)}
                            button={ov.active ? {text: 'Active', color: 'primary'} : ov.external ? {text: 'Published', color: 'secondary'} : {}}
                            currentlyActive={ov._id === props.activeOat._id}
                            labelIcon={faFileAlt}
                            labelIconSpecial={"interim"}
                            level={2} />
                            </span>
                        ))}
                        {props.oatVersions.length > 4 ?
                        <span onClick={e => setShowAllVersions(!showAllVersions)}>
                        <StyledTreeItem
                        nodeId={"14"}
                        labelText={!showAllVersions ? "Show older versions" : "Hide older versions"}
                        labelIcon={null}
                        labelIconSpecial={"interim"}
                        miniLink={true}
                        level={2} />
                        </span>
                        :''}
                        </Box>
                        </>
                    : '' }

                    {// List all the agreement versions for an Agr
                    props.module !== 'legal' && avs !== undefined && avs.filter((av) => av.version <= props.avv).length > 0 ? 
                    <Box mt={0} mb={1}>
                    {avs.filter((av) => av.version <= props.avv)
                    //.filter((av) => av.version < props.avv)
                    .map((av,i) => (
                        <span onClick={e => handleSummaryClick({ click: 'version', avid: av._id, avv: av.version })} key={av._id}>
                        <StyledTreeItem
                        nodeId={"1" + (i + 1)}
                        currentlyActive={av.version === props.avv}
                        labelText={"Version " + userFriendlyAVV(av.version)}
                        avatarIcon={
                            av.owner === "primary" && props.primaryCpty !== undefined && props.primaryCpty !== null ? props.primaryCpty.logoURL : 
                            av.owner === "secondary" && props.secondaryCpty !== undefined && props.secondaryCpty !== null ?  props.secondaryCpty.logoURL : null}
                        level={2} />
                        </span>
                    ))}
                    </Box>
                    /*:props.module === 'legal' && (props.activeOat.active || props.activeOat.external ||
                        ['Template', 'Terms'].includes(props.activeOat.draftFor)) ?
                    <Box mt={0} mb={1}>
                    <span onClick={e => props.handleTemplateFunction('export')}>
                    <StyledTreeItem
                    nodeId={"14"}
                    labelText={"Export this version"}
                    labelIcon={faFileWord}
                    labelIconSpecial={"interim"}
                    level={2} />
                    </span>
                    </Box>*/ 
                    :''}

                </StyledTreeItem>

                {props.module !== 'legal' && 
                (props.agrStatus === 'Negotiation' || props.parentAgrStatus === 'Negotiation') ? 
                <span onClick={e => handleSummaryClick('filterChanges')}>
                    <StyledTreeItem 
                    nodeId="2" 
                    labelText={"Changed clauses"} 
                    //button={props.openSummary && props.avsPenTurnDetail !== undefined && props.avsPenTurnDetail.reduce((a, b) => a + (b.changesOnPT || 0), 0) > 0 ? {text: 'View', color: 'primary'} : {}}
                    labelIcon={faUserEdit} 
                    labelInfo={props.avsPenTurnDetail !== undefined && props.agrStatus !== 'Exhibit' ?
                        props.avsPenTurnDetail.reduce((a, b) => a + (b.changesOnPT || 0), 0) : ''} 
                    level={1} />
                </span>
                :props.module === 'legal' && props.activeOat !== undefined && props.agrTypes !== undefined ?
                <span>
                    <StyledTreeItem 
                    nodeId="2" 
                    //labelText={(props.activeOat.active || props.activeOat.draftFor === 'Template' ? "Template " : "Terms ") + "Properties"} 
                    labelText={"Agreement Type"}
                    labelIcon={faTag} 
                    labelIconSpecial={props.module === 'legal' && props.activeOat !== undefined && (props.activeOat.external || props.activeOat.draftFor === 'Terms') ? "secondary" : null}
                    labelInfo={''} 
                    level={1}>

                        <Box mt={1} mb={1} align="center">
                            <span onClick={e => props.handleTemplateFunction('changeagrtype')}>
                            <StyledTreeItem
                            nodeId={"21"}
                            labelText={props.agrTypes.filter((at) => at._id === props.activeOat.agrTypeID)[0] !== undefined ?
                                trunc(props.agrTypes.filter((at) => at._id === props.activeOat.agrTypeID)[0].fullName[0], 24) : ''}
                            labelIcon={faHashtag}
                            labelIconSpecial={"interim"}
                            level={2} />
                            </span>
                            <span onClick={e => props.handleTemplateFunction('changeagrtype')}>
                            <StyledTreeItem
                            nodeId={"22"}
                            labelText={trunc(props.activeOat.reference !== undefined && props.activeOat.reference !== null && props.activeOat.reference !== '' ? props.activeOat.reference : "DEFAULT", 22)}
                            labelIcon={faAsterisk}
                            labelIconSpecial={"interim"}
                            level={2} />
                            </span>
                            {/*
                            <span onClick={e => props.handleTemplateFunction('copyover')}>
                            <StyledTreeItem
                            nodeId={"23"}
                            labelText={
                                props.activeOat.module === 'sales' ? "For Customers" : 
                                props.activeOat.module === 'procurement' ? "For Suppliers" : 
                                props.activeOat.module === 'partnerships' ? "For Partners" : 
                                props.activeOat.module === 'people' ? "For Employees" : 
                                props.activeOat.module === 'corporate' ? "For Corporate" : 
                                props.activeOat.module === 'privacy' ? "For Privacy" : "For Agreements"}
                            labelIcon={
                                props.activeOat.module === 'sales' ? faHandshake : 
                                props.activeOat.module === 'procurement' ? faShoppingCart : 
                                props.activeOat.module === 'partnerships' ? faUserFriends : 
                                props.activeOat.module === 'people' ? faUser : 
                                props.activeOat.module === 'corporate' ? faBuilding : 
                                props.activeOat.module === 'privacy' ? faShieldAlt : faHandshake}
                            labelIconSpecial={"interim"}
                            level={2} />
                            </span>*/}
                            {/*['custE'].includes(props.org.clientType) ?
                            <span onClick={e => history.push("/core/legal/templates/" + props.activeOat.module + "/topics/" + props.activeOat._id)}>
                            <StyledTreeItem nodeId="24" labelText={"Assign Topics"} labelIconSpecial={"interim"}
                            labelIcon={faLightbulb} 
                            level={2} />
                            </span>
                            :''*/}
                        </Box>
                    
                    </StyledTreeItem>

                    {(props.activeOat.active || props.activeOat.draftFor === 'Template') &&
                    props.org !== undefined && ['custE', 'legalOrg'].includes(props.org.clientType) ? 

                    <RenderEffectiveProperties />

                    :''}

                </span>                
                : '' }
                
                {['Draft', 'Review', 'Negotiation'].includes(props.agrStatus) ||
                ['Draft', 'Review', 'Negotiation'].includes(props.parentAgrStatus) ?
                <span onClick={e => handleSummaryClick('filterClause')}>
                    <StyledTreeItem 
                    nodeId="3" 
                    labelText={"Open issues"} 
                    //button={props.openSummary && props.avsPenTurnDetail !== undefined && props.avsPenTurnDetail.reduce((a, b) => a + (b.openOnPT || 0), 0) > 0 ? {text: 'View', color: 'primary'} : {}}
                    labelIcon={faExclamationCircle} 
                    labelInfo={props.avsPenTurnDetail !== undefined && props.openSummary ?
                        props.avsPenTurnDetail.reduce((a, b) => a + (b.openOnPT || 0), 0) : ''} 
                    level={1} />
                </span>
                : '' }

                {props.signable && (['Review', 'Negotiation'].includes(props.agrStatus) ||
                 ['Review', 'Negotiation'].includes(props.parentAgrStatus)) ?
                <span onClick={e => handleSummaryClick('initiateSigning')}>
                    <StyledTreeItem 
                    nodeId="4" 
                    labelText={"Ready to sign"} 
                    button={{text: 'Initiate', color: 'primary'}}
                    //labelIconSpecial={"secondary"}
                    labelIcon={faThumbsUp} level={1} />
                </span>
                :['Review', 'Negotiation'].includes(props.agrStatus) ||
                ['Review', 'Negotiation'].includes(props.parentAgrStatus) ?
                    <StyledTreeItem nodeId="4" 
                    labelText={"Not ready to sign"} 
                    labelIcon={faThumbsUp}
                    level={1} />
                : ''}
                
                {/*props.agrStatus !== 'Exhibit' && */
                props.module !== 'legal' ?
                <>
                <span>
                <StyledTreeItem nodeId="5" labelText={"Parties"} labelIcon={faBuilding} labelInfo={'2'} level={1}>

                    <Grid container direction="column" alignItems="center" justify="center" spacing={0}>
                    <Grid item>
                        <Box mt={4} align="center">
                            <Avatar src={props.primaryCpty !== undefined && props.primaryCpty !== null ? props.primaryCpty.logoURL : null} />
                            <Box mt={2}><RenderCptyBox type="primary" cpty={props.primaryCpty} summary={false} /></Box>
                            
                            {['InForce'].includes(props.agrStatus) || props.penHolder === "primary" || ['both', 'primary'].includes(props.readyToSign) ?
                            <Tooltip arrow title={!['InForce'].includes(props.agrStatus) && props.penHolder === "primary" && props.editMode !== undefined && !props.laterVersionOwnedByCpty ? 
                                getEditModeOption(props.editMode, 'Company') : ""}>
                            <Chip variant="outlined" 
                            label={<span>{['InForce'].includes(props.agrStatus) ? "Signed the Agreement" : props.penHolder === "primary" ? "Current Agreement Owner" : "Ready to Sign"}<FontAwesomeIcon icon={['InForce'].includes(props.agrStatus) ? faCheck : props.penHolder === "primary" ? faPen : faThumbsUp} className={classes.tinyPen} style={{color: theme.palette.primary.main}} /></span>} 
                            size="small" color="primary" className={classes.statusChip} />
                            </Tooltip>
                            : ""}
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box mt={5} mb={4} align="center">
                            <Avatar src={props.secondaryCpty !== undefined && props.secondaryCpty !== null ? props.secondaryCpty.logoURL: null} />
                            <Box mt={2}><RenderCptyBox type="secondary" cpty={props.secondaryCpty} summary={false} /></Box>
                            
                            {['InForce'].includes(props.agrStatus) || props.penHolder === "secondary" || ['both', 'secondary'].includes(props.readyToSign) ?
                            <Tooltip arrow title={!['InForce'].includes(props.agrStatus) && props.penHolder === "secondary" && props.editMode !== undefined && !props.laterVersionOwnedByCpty ? 
                                getEditModeOption(props.editMode, 'Company') : ""}>
                            <Chip variant="outlined" 
                            label={<span>{['InForce'].includes(props.agrStatus) ? "Signed the Agreement" : props.penHolder === "secondary" ? "Current Agreement Owner" : "Ready to Sign"}<FontAwesomeIcon icon={['InForce'].includes(props.agrStatus) ? faCheck : props.penHolder === "secondary" ? faPen : faThumbsUp} className={classes.tinyPen} style={{color: theme.palette.secondary.main}} /></span>} 
                            size="small" color="secondary" className={classes.statusChip} />
                            </Tooltip>
                            : ""}
                        </Box>
                    </Grid>
                    </Grid>

                </StyledTreeItem>
                </span>

                <span onClick={e => handleSummaryClick('pullCollaborators')}>
                <StyledTreeItem nodeId="6" labelText={"Collaborators"} labelIcon={faUserFriends} level={1}
                //labelInfo={props.agr !== undefined && props.agr.contributors !== undefined ? props.agr.contributors.length : ''}
                labelInfo={props.agrsAvs !== undefined && props.agrsAvs !== null && props.agrsAvs.filter((aa) => aa.isMain)[0] !== undefined &&
                    props.agrsAvs.filter((aa) => aa.isMain)[0].contributors !== undefined ? props.agrsAvs.filter((aa) => aa.isMain)[0].contributors.length : '' }>
                    {props.agrsAvs !== undefined && props.agrsAvs !== null && props.agrsAvs.filter((aa) => aa.isMain)[0] !== undefined &&
                    props.agrsAvs.filter((aa) => aa.isMain)[0].contributors !== undefined && props.agrsAvs.filter((aa) => aa.isMain)[0].contributors.length > 0 &&
                    summaryCollabs !== null && summaryCollabs.length < props.agrsAvs.filter((aa) => aa.isMain)[0].contributors.length
                    /*props.agr !== undefined && props.agr.contributors !== null && summaryCollabs !== null &&
                    props.agr.contributors.length > 0 && summaryCollabs.length < props.agr.contributors.length*/ ?
                    <Box mt={2} mb={2} ml={2} align="center">
                        <CircularProgress size= {24} />
                    </Box>
                    :props.agrsAvs !== undefined && props.agrsAvs !== null && props.agrsAvs.filter((aa) => aa.isMain)[0] !== undefined &&
                    props.agrsAvs.filter((aa) => aa.isMain)[0].contributors !== undefined && props.agrsAvs.filter((aa) => aa.isMain)[0].contributors.length > 0 &&
                    summaryCollabs !== null && summaryCollabs.length === props.agrsAvs.filter((aa) => aa.isMain)[0].contributors.length
                    /*props.agr !== undefined && props.agr.contributors !== null && summaryCollabs !== null &&
                    props.agr.contributors.length > 0 && summaryCollabs.length === props.agr.contributors.length*/ ?

                    <Box mt={1} mb={2}>
                    {summaryCollabs.map((sc,i) => (
                    
                    <StyledTreeItem
                    key={sc._id}
                    nodeId={"6" + i}
                    labelText={trunc(sc.displayName, 20)}
                    avatarIcon={sc.photoURL !== "" && sc.photoURL !== undefined && sc.photoURL !== null ? sc.photoURL : defaultUser}
                    hasViewed={sc.hasViewed || sc._id === props.uid}
                    clientType={props.org.clientType}
                    line2={trunc(sc.email,23)}
                    tooltip={
                        ['cust', 'custE', 'legalOrg'].includes(props.org.clientType) && !sc.hasViewed && sc._id !== props.uid ? 
                            <Box className={classes.updateTooltip}>Has not viewed the agreement yet</Box> : 
                        ['cust', 'custE', 'legalOrg'].includes(props.org.clientType) && (sc.hasViewed || sc._id === props.uid) ? 
                            <Box className={classes.updateTooltip}>Has viewed the agreement</Box> : ""
                    }
                    level={2} />
                    ))}
                    </Box>

                    : '' }
                </StyledTreeItem>
                </span>

                <span onClick={e => handleSummaryClick('pullSigners')}>
                <StyledTreeItem nodeId="7" labelText={"Signers"} labelIcon={faSignature} level={1}
                //labelInfo={props.agr !== undefined && props.agr.signatories !== undefined ? props.agr.signatories.length : ''}
                labelInfo={props.agrsAvs !== undefined && props.agrsAvs !== null && props.agrsAvs.filter((aa) => aa.isMain)[0] !== undefined &&
                    props.agrsAvs.filter((aa) => aa.isMain)[0].signatories !== undefined ? props.agrsAvs.filter((aa) => aa.isMain)[0].signatories.length : '' }>
                    {props.agrsAvs !== undefined && props.agrsAvs !== null && props.agrsAvs.filter((aa) => aa.isMain)[0] !== undefined &&
                    props.agrsAvs.filter((aa) => aa.isMain)[0].signatories !== undefined && props.agrsAvs.filter((aa) => aa.isMain)[0].signatories.length > 0 &&
                    summarySigners !== null && summarySigners.length < props.agrsAvs.filter((aa) => aa.isMain)[0].signatories.length
                    /*props.agr !== undefined && props.agr.signatories !== null && summarySigners !== null &&
                    props.agr.signatories.length > 0 && summarySigners.length < props.agr.signatories.length*/ ?
                    <Box mt={2} mb={2} ml={2} align="center">
                        <CircularProgress size= {24} />
                    </Box>
                    :props.agrsAvs !== undefined && props.agrsAvs !== null && props.agrsAvs.filter((aa) => aa.isMain)[0] !== undefined &&
                    props.agrsAvs.filter((aa) => aa.isMain)[0].signatories !== undefined && props.agrsAvs.filter((aa) => aa.isMain)[0].signatories.length > 0 &&
                    summarySigners !== null && summarySigners.length === props.agrsAvs.filter((aa) => aa.isMain)[0].signatories.length
                    /*props.agr !== undefined && props.agr.signatories !== null && summarySigners !== null &&
                    props.agr.signatories.length > 0 && summarySigners.length === props.agr.signatories.length*/ ?

                    <Box mt={1} mb={2}>
                    {summarySigners.map((ss,i) => (
                    <StyledTreeItem
                    key={ss._id}
                    nodeId={"7" + i}
                    labelText={trunc(ss.displayName, 20)}
                    avatarIcon={ss.photoURL !== "" && ss.photoURL !== undefined && ss.photoURL !== null ? ss.photoURL : defaultUser}
                    hasViewed={ss.hasViewed || ss._id === props.uid}
                    clientType={props.org.clientType}
                    line2={trunc(ss.email, 23)}
                    tooltip={
                        ['cust', 'custE', 'legalOrg'].includes(props.org.clientType) && !ss.hasViewed && ss._id !== props.uid ? 
                            <Box className={classes.updateTooltip}>Has not viewed the agreement yet</Box> : 
                        ['cust', 'custE', 'legalOrg'].includes(props.org.clientType) && (ss.hasViewed || ss._id === props.uid) ? 
                            <Box className={classes.updateTooltip}>Has viewed the agreement</Box> : ""
                    }
                    level={2} />
                    ))}
                    </Box>

                    : '' }
                </StyledTreeItem>
                </span>
                </>
                :''}

                {props.agr !== undefined && props.agr.parentAID === '' &&
                props.org !== undefined && ['custE', 'legalOrg'].includes(props.org.clientType) ? // Do not show for exhibits
                <RenderEffectiveProperties />
                :''}

                { (!props.laterVersionOwnedByCpty || ['Execution', 'InForce'].includes(props.agrStatus)) &&
                props.org !== undefined && ['custE', 'legalOrg'].includes(props.org.clientType) &&
                !(props.toc === undefined || props.toc === null || props.toc.length === 0) ?
                <span>
                <StyledTreeItem nodeId="9" labelText={"Smart Fields"} 
                labelIconSpecial={props.module === 'legal' && props.activeOat !== undefined && (props.activeOat.external || props.activeOat.draftFor === 'Terms') ? "secondary" : null}
                labelIcon={faMagic} labelInfo={props.smartFields !== undefined && props.smartFields !== null ? props.smartFields.length : ''} level={1}>

                    <RenderSmartFieldsBlock />

                </StyledTreeItem>
                </span>
                : '' }
                
            </TreeView>
        </div>
    )

    /*
    const drawerAgPageParties = (
        <div>

            

        </div>
    )*/

    const drawerAgPageSmartFields = (
        <div>
            <Grid container direction="column" alignItems="center" justify="center" spacing={0}>
            <Grid item>
                <RenderSmartFieldsBlock />
            </Grid>
            </Grid>
        </div>
    )

    /*function renderRow(i, t) {
        //const { i, t } = props;
     
        
                  <ListItem button style={style} key={index}>
            <ListItemText primary={`Item ${index + 1}`} />
          </ListItem>
          
        return (
          <ListItem button
          key={i}
          //className={t.level === 3 ? classes.nested2 : t.level === 2 ? classes.nested : null}
          onClick={e => handleScroll(e, t.id)}>
              <ListItemText
              disableTypography
              primary={
                  <Typography 
                  //className={t.level === 3 ? classes.listItemLevel3 : t.level === 2 ? classes.listItemLevel2 : classes.listItemLevel1}
                  >
                      {trunc((t.no + " " + t.title), t.level === 1 ? 25 : 28)}
                  </Typography>} />
          </ListItem>
        );
    }*/


    const TocRow = ({ index, style }) => (
        <ListItem button
        key={index}
        style={style}
        className={[3,4].includes(props.toc[index].level) ? classes.nested2 : props.toc[index].level === 2 ? classes.nested : null}
        onClick={e => handleScroll(e, props.toc[index].id)}>
            <ListItemText
            disableTypography
            primary={
                <Typography 
                className={[3,4].includes(props.toc[index].level) ? classes.listItemLevel3 : props.toc[index].level === 2 ? classes.listItemLevel2 : classes.listItemLevel1}
                >
                    {/*trunc((props.toc[index].no + " " + props.toc[index].title), props.toc[index].level === 1 ? 25 : 28)*/
                    trunc(props.toc[index].title, props.toc[index].level === 1 ? 25 : 28)}
                </Typography>} />
        </ListItem>
    );

    const getTocItemSize = (index) => {
        return props.toc[index].level === 1 ? 48 : props.toc[index].level === 2 ? 43 : [3,4].includes(props.toc[index].level) ? 32 : 0;
    };

    const RenderDrawerAgPage = () => {
        return (
        <div style={{height: '100vh'}}>
        <AutoSizer>
            {({height, width}) => (
            <VariableSizeList
                height={height}
                itemCount={props.toc === undefined || props.toc === null ? 0 : props.toc.length}
                itemSize={getTocItemSize}
                width={width}
            >
            {TocRow}
            </VariableSizeList>
            )}
        </AutoSizer>
        </div>
        );
    }

    const drawerSignup = (
        <div>
            <List>
              <ListItem 
                  button 
                  disabled={props.page === 'done'}
                  key="personal" 
                  className={props.page === 'personal' ? classes.activeListItem : ''}
                  onClick={props.page === 'done' ? null : e => props.routeToPage('personal')}>
                  <ListItemText 
                      disableTypography 
                      primary={<Typography className={
                        props.page === 'done' || props.donePersonal ?
                        classes.listItemLevel1Done : 
                        classes.listItemLevel1}>Personal Details {props.page === 'done' || props.donePersonal ? <FontAwesomeIcon icon={faCheck} style={{color: theme.palette.success.main}}/> : ''}</Typography>} 
                  />
              </ListItem>
              <ListItem 
                  button 
                  disabled={!props.donePersonal}
                  key="company" 
                  className={props.page === 'company' ? classes.activeListItem : ''}
                  onClick={props.page === 'done' ? null : e => props.routeToPage('company')}>
                  <ListItemText 
                      disableTypography 
                      primary={<Typography className={
                        props.page === 'done' || props.doneCompany ?
                        classes.listItemLevel1Done : 
                        classes.listItemLevel1}>Company Details {props.page === 'done' || props.doneCompany ? <FontAwesomeIcon icon={faCheck} style={{color: theme.palette.success.main}}/> : ''}</Typography>} 
                  />
              </ListItem>
              <ListItem 
                  button 
                  disabled={!props.doneCompany}
                  key="logo" 
                  className={props.page === 'logo' ? classes.activeListItem : ''}
                  onClick={props.page === 'done' ? null : e => props.routeToPage('logo')}>
                  <ListItemText 
                      disableTypography 
                      primary={<Typography className={
                        props.page === 'done' || props.doneLogo ?
                        classes.listItemLevel1Done : 
                        classes.listItemLevel1}>Company Logo {props.page === 'done' || props.doneLogo ? <FontAwesomeIcon icon={faCheck} style={{color: theme.palette.success.main}}/> : ''}</Typography>} 
                  />
              </ListItem>
              <ListItem 
                  button 
                  disabled={!props.doneCompany}
                  key="picture" 
                  className={props.page === 'picture' ? classes.activeListItem : ''}
                  onClick={props.page === 'done' ? null : e => props.routeToPage('picture')}>
                  <ListItemText 
                      disableTypography 
                      primary={<Typography className={
                        props.page === 'done' || props.donePicture ?
                        classes.listItemLevel1Done : 
                        classes.listItemLevel1}>Profile Picture {props.page === 'done' || props.donePicture ? <FontAwesomeIcon icon={faCheck} style={{color: theme.palette.success.main}}/> : ''}</Typography>} 
                  />
              </ListItem>

              {props.withUsers ? 
              <ListItem 
                button 
                disabled={!props.doneCompany}
                key="users" 
                className={props.page === 'users' ? classes.activeListItem : ''}
                onClick={props.page === 'done' ? null : e => props.routeToPage('users')}>
                <ListItemText 
                    disableTypography 
                    primary={<Typography className={
                        props.page === 'done' || props.doneUsers ?
                        classes.listItemLevel1Done : 
                        classes.listItemLevel1}>Select Users {props.page === 'done' || props.doneUsers ? <FontAwesomeIcon icon={faCheck} style={{color: theme.palette.success.main}}/> : ''}</Typography>} 
                />
              </ListItem>
              : ''}

              <ListItem 
                  button 
                  disabled={!props.doneCompany || (props.withUsers && !props.doneUsers)}
                  key="package" 
                  className={props.page === 'package' ? classes.activeListItem : ''}
                  onClick={props.page === 'done' ? null : e => props.routeToPage('package')}>
                  <ListItemText 
                      disableTypography 
                      primary={<Typography className={
                      props.page === 'done' || props.donePackage ?
                      classes.listItemLevel1Done : 
                      classes.listItemLevel1}>Select Package {props.page === 'done' || props.donePackage ? <FontAwesomeIcon icon={faCheck} style={{color: theme.palette.success.main}}/> : ''}</Typography>} 
                  />
              </ListItem>
              <ListItem 
                  button 
                  disabled={!props.donePackage}
                  key="payment" 
                  className={props.page === 'payment' ? classes.activeListItem : ''}
                  onClick={props.page === 'done' ? null : e => props.routeToPage('payment')}>
                  <ListItemText 
                      disableTypography 
                      primary={<Typography className={
                        props.page === 'done' ?
                        classes.listItemLevel1Done : 
                        classes.listItemLevel1}>Review & Confirm {props.page === 'done' ? <FontAwesomeIcon icon={faCheck} style={{color: theme.palette.success.main}}/> : ''}</Typography>} 
                  />
              </ListItem>
            </List>
        </div>
    )

    const helpDrawer = [{
        page: 'overview', 
        title: 'Overview',
        click: e => history.push("/core/help/overview"),
        icon: faFolderOpen,
        hide: false,
    },
    {
        page: 'concepts', 
        title: 'Concepts',
        click: e => history.push("/core/help/concepts"),
        icon: faLightbulb,
        hide: false,
    },
    {
        page: 'faq', 
        title: 'FAQ',
        click: e => history.push("/core/help/faq"),
        icon: faQuestionCircle,
        hide: false,
    }]

    const settingsDrawer = [{
        page: 'personal', 
        title: 'Personal',
        click: e => history.push("/core/settings"),
        icon: faUserCircle,
        hide: false,
    },
    {
        page: 'photo', 
        title: 'Photo',
        click: e => history.push("/core/settings/photo"),
        icon: faCameraRetro,
        hide: false,
    },
    {
        page: 'settings', 
        title: 'Settings',
        click: e => history.push("/core/settings/settings"),
        icon: faCog,
        hide: props.isCpty,
    },]

    return (
      <React.Fragment>
        <nav 
        className={(summaryGone && tocGone && /*partiesGone && */smartFieldsGone && props.pageType === 'agreement') || 
        ['tasks','analytics','relationship','templates','playbook','terms','help', 'profile', 'settings'].includes(props.pageType) ? classes.drawerGone : classes.drawer}>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                {props.pageType !== 'agreement' ? 
                (
                <Grid container item justify="center" alignItems="center"
                    className={classes.containerMargin}>
                    <Box mt={props.pageType === 'signup' ? 1 : 0} mb={props.pageType === 'signup' ? 1 : 0}>
                    <Logo
                      to={backLink}
                      size={props.pageType === 'signup' ? "l" : "m"}
                      whiteLabel={props.whiteLabel}
                      whiteLabelLoading={props.whiteLabelLoading}
                    />
                    </Box>
                </Grid>
                ) 
                :props.pageType === 'agreement' && props.module !== "legal" ? 
                <Box mt={3} mb={1} align="center">
                    <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                        {/*<Grid item>
                            {<Box className={classes.boxWithIconSM} align="center" onClick={handleTogglePartiesSM}>
                            {partiesToggleSM ? 
                            <>
                            <FontAwesomeIcon icon={faList} className={classes.drawerIcon} />
                            <Box mt={1}>Contents</Box>
                            </>
                            :
                            <>
                            <FontAwesomeIcon icon={faBuilding} className={classes.drawerIcon} />
                            <Box mt={1}>Parties</Box>
                            </>
                            }
                            </Box>
                        </Grid>*/}
                        <Grid item>
                            <Box className={classes.boxWithIconSM} align="center" onClick={e => handleSMMenuOption('comms')}>
                            <FontAwesomeIcon icon={faComments} className={classes.drawerIcon} />
                            <Box mt={1}>Activity</Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                : '' }

                {//partiesToggleSM ? drawerAgPageParties :
                 props.pageType === 'agreement' ? <RenderDrawerAgPage /> : 
                 props.pageType === 'signup' ? drawerSignup : 
                ['profile', 'templates','terms','playbook', 'tasks','analytics','help', 'settings', 'relationship'].includes(props.pageType) ?
                    <DrawerCoreRender
                    toTopButton={['agreement'].includes(props.pageType)}
                    type={"mini"} 
                    list={
                        props.whiteLabelLoading ? null :
                        props.pageType === 'profile' ? profileDrawer :
                        props.pageType === 'terms' || props.pageType === 'templates' || props.pageType === 'playbook' ? legalDrawer :
                        props.pageType === 'tasks' ? tasksDrawer :
                        props.pageType === 'analytics' ? analyticsDrawer :
                        props.pageType === 'help' ? helpDrawer :
                        props.pageType === 'settings' ? settingsDrawer :
                        props.pageType === 'relationship' ? relDrawer : []
                    }
                    page={props.page}
                    trigger={trigger}
                    agrID={props.agrID}
                    user={props.users !== undefined && props.users.filter((u) => u._id === props.uid)[0] !== undefined ? props.users.filter((u) => u._id === props.uid)[0] : {}}
                    rid={props.rid}
                    orgID={props.org !== undefined ? props.org._id : ''}
                    orgName={props.org !== undefined ? props.org.shortName : ''}
                    module={props.module} 
                    cpmodule={
                        ['partnerships', 'people', 'corporate'].includes(props.module) ? props.module :
                        ['sales'].includes(props.module) ? 'procurement' :
                        ['procurement'].includes(props.module) ? 'sales' :
                        ['counterparty'].includes(props.module) && ['people', 'corporate'].includes(props.relType) ? props.relType :
                        ['counterparty'].includes(props.module) && ['partnership'].includes(props.relType) ? 'partnerships' :
                        ['counterparty'].includes(props.module) && ['b2b'].includes(props.relType) && !props.isPrimary ? 'sales' :
                        ['counterparty'].includes(props.module) && ['b2b'].includes(props.relType) && props.isPrimary ? 'procurement' : 'unknown'}
                    timeBase={props.timeBase} 
                    pushTime={props.pushTime}
                    cptyType={props.isPrimary ? "primary" : "secondary"}
                    /> : ''}
                </Drawer>   

                {['agreement', 'help'].includes(props.pageType) ? 
                <Box display={{ xs: 'block', md: 'none' }}>
                    <MenuBottomModule 
                    module={props.module}
                    pageType={props.pageType}
                    active={props.active}
                    toggleDrawer={handleDrawerToggle}
                    backLink={backLink}
                    user={props.user}
                    />
                </Box>
                : '' }

            </Hidden>
            
            <Hidden smDown implementation="css">
            
                <Drawer
                    variant="permanent"
                    open={!summaryGone || !tocGone || /*!partiesGone ||*/ !smartFieldsGone || props.pageType !== 'agreement'}
                    className={clsx(classes.drawer, {
                        [classes.drawerPaper]: !summaryGone || !tocGone || /*!partiesGone ||*/ !smartFieldsGone || props.pageType !== 'agreement',
                        [classes.drawerPaperClose]: (summaryGone && tocGone && /*partiesGone &&*/ smartFieldsGone && props.pageType === 'agreement') || ['tasks','analytics','relationship','templates','terms','playbook', 'help', 'profile', 'settings'].includes(props.pageType),
                    })}
                    classes={{
                        paper: clsx({
                          [classes.drawerPaper]: !summaryGone || !tocGone || /*!partiesGone ||*/ !smartFieldsGone || props.pageType !== 'agreement',
                          [classes.drawerPaperClose]: (summaryGone && tocGone && /*partiesGone &&*/ smartFieldsGone && props.pageType === 'agreement') || ['tasks','analytics','relationship','templates','terms','playbook', 'help', 'profile', 'settings'].includes(props.pageType),
                        }),
                    }}
                >
                
                <Grid container item justify="center" alignItems="center"
                    className={classes.containerMarginUp}>
                    <Box mt={props.pageType === 'signup' ? 1 : 0} mb={props.pageType === 'signup' ? 1 : 0}>
                    <Logo
                      to={backLink}
                      size={props.pageType === 'signup' ? "l" : "m"}
                      whiteLabel={props.whiteLabel}
                      whiteLabelLoading={props.whiteLabelLoading}
                    />
                    </Box>
                </Grid>
                {props.pageType === 'agreement' && !summaryGone ? drawerAgPageSummary :
                 props.pageType === 'agreement' && !tocGone ? <RenderDrawerAgPage /> : 
                 //props.pageType === 'agreement' && !partiesGone ? drawerAgPageParties : 
                 props.pageType === 'agreement' && !smartFieldsGone ? drawerAgPageSmartFields :
                 props.pageType === 'signup' ? drawerSignup : 
                (props.pageType === 'agreement' && summaryGone && tocGone && /*partiesGone &&*/ smartFieldsGone) ||
                ['profile', 'templates','terms','playbook','tasks','analytics', 'help', 'settings', 'relationship'].includes(props.pageType) ?
                    <DrawerCoreRender
                    toTopButton={['agreement'].includes(props.pageType)}
                    type={"mini"} 
                    list={
                        props.whiteLabelLoading ? null :
                        props.pageType === 'agreement' && summaryGone && tocGone && /*partiesGone &&*/ smartFieldsGone ? agMiniDrawer :
                        props.pageType === 'profile' ? profileDrawer :
                        props.pageType === 'templates' || props.pageType === 'terms' || props.pageType === 'playbook' ? legalDrawer :
                        props.pageType === 'tasks' ? tasksDrawer :
                        props.pageType === 'analytics' ? analyticsDrawer :
                        props.pageType === 'help' ? helpDrawer :
                        props.pageType === 'settings' ? settingsDrawer :
                        props.pageType === 'relationship' ? relDrawer : []
                    }
                    page={props.page}
                    trigger={trigger}
                    agrID={props.agrID}
                    user={props.users !== undefined && props.users.filter((u) => u._id === props.uid)[0] !== undefined ? props.users.filter((u) => u._id === props.uid)[0] : {}}
                    rid={props.rid}
                    orgID={props.org !== undefined ? props.org._id : ''}
                    orgName={props.org !== undefined ? props.org.shortName : ''}
                    module={props.module} 
                    cpmodule={
                        ['partnerships', 'people', 'corporate'].includes(props.module) ? props.module :
                        ['sales'].includes(props.module) ? 'procurement' :
                        ['procurement'].includes(props.module) ? 'sales' :
                        ['counterparty'].includes(props.module) && ['people', 'corporate'].includes(props.relType) ? props.relType :
                        ['counterparty'].includes(props.module) && ['partnership'].includes(props.relType) ? 'partnerships' :
                        ['counterparty'].includes(props.module) && ['b2b'].includes(props.relType) && !props.isPrimary ? 'sales' :
                        ['counterparty'].includes(props.module) && ['b2b'].includes(props.relType) && props.isPrimary ? 'procurement' : 'unknown'}
                    timeBase={props.timeBase} 
                    pushTime={props.pushTime}
                    cptyType={props.isPrimary ? "primary" : "secondary"}
                    />
                    : ''}

                    <Menu
                    id="avatar-menu"
                    anchorEl={anchorElToolbox}
                    keepMounted
                    disableAutoFocusItem
                    open={Boolean(anchorElToolbox)}
                    onClose={handleCloseToolbox}
                    >


                        {(['Draft', 'Review', 'Negotiation'].includes(props.agrStatus) || ['Draft', 'Review', 'Negotiation'].includes(props.parentAgrStatus)) &&
                        !props.user.readOnly && !props.laterVersionOwnedByCpty && props.editMode === 'full' ?
                        (
                        <MenuItem onClick={e => handleToolboxOption('acceptagr')} style={{width: menuWidth}}>
                            <ListItemIcon><FontAwesomeIcon icon={faCheckDouble} className={classes.iconPrimary} style={{marginLeft: '1px'}} /></ListItemIcon>
                            <ListItemText>Accept all changes...</ListItemText>
                        </MenuItem>
                        ) 
                        : '' }
                        
                        {['Draft', 'Review', 'Negotiation', 'Execution'].includes(props.agrStatus) ||
                        ['Draft', 'Review', 'Negotiation', 'Execution'].includes(props.parentAgrStatus) ?
                        (
                        <MenuItem onClick={e => handleToolboxOption('export')} style={{width: menuWidth}}>
                            <ListItemIcon><FontAwesomeIcon icon={faDownload} className={classes.iconPrimary} /></ListItemIcon>
                            <ListItemText>Export Agreement...</ListItemText>
                        </MenuItem>
                        ) 
                        : '' }

                        {['Draft', 'Review', 'Negotiation', 'Exhibit'].includes(props.agrStatus) && !props.laterVersionOwnedByCpty &&
                        props.user !== undefined && !['Counterparty'].includes(props.user.role) ?
                        (
                        <MenuItem component={RouterLink} to={props.subagLink} style={{width: menuWidth}}>
                            <ListItemIcon><FontAwesomeIcon icon={faFileAlt} className={classes.iconPrimary} style={{marginLeft: '2px'}} /></ListItemIcon>
                            <ListItemText>Add Exhibit...</ListItemText>
                        </MenuItem>
                        ) : '' }

                        {props.handleReorderExhibits !== null && (['Draft', 'Review', 'Negotiation'].includes(props.agrStatus) || ['Draft', 'Review', 'Negotiation'].includes(props.parentAgrStatus)) &&
                        !props.laterVersionOwnedByCpty && props.agrsAvs.length > 2 ? 
                        <MenuItem onClick={e => handleToolboxOption('reorderExhibits')} style={{width: menuWidth}}>
                            <ListItemIcon><FontAwesomeIcon icon={faSortAmountDown} className={classes.iconPrimary} /></ListItemIcon>
                            <ListItemText>Reorder Exhibits...</ListItemText>
                        </MenuItem>
                        : '' }

                        {['Draft', 'Review', 'Negotiation', 'Execution', 'Exhibit'].includes(props.agrStatus) && !props.laterVersionOwnedByCpty &&
                        props.user !== undefined && ['cust', 'custE', 'legalOrg'].includes(props.org.clientType) ?
                        (
                        <MenuItem onClick={e => handleToolboxOption('offlinesigning')} style={{width: menuWidth}}>
                            <ListItemIcon><FontAwesomeIcon icon={faSignature} className={classes.iconPrimary} /></ListItemIcon>
                            <ListItemText>Offline Signing...</ListItemText>
                        </MenuItem>
                        ) : '' }

                        {['Draft', 'Review', 'Negotiation', 'Execution', 'InForce', 'Exhibit'].includes(props.agrStatus) && 
                        props.user !== undefined && ['Admin', 'Legal'].includes(props.user.role) &&
                        !props.isAttachment ?
                        (
                        <MenuItem component={RouterLink} to={props.saveTemplateLink} style={{width: menuWidth}}>
                            <ListItemIcon><FontAwesomeIcon icon={faSave} className={classes.iconPrimary} style={{marginLeft: '1px'}} /></ListItemIcon>
                            <ListItemText>Save as Template...</ListItemText>
                        </MenuItem>
                        ) : '' }

                        {(['Draft', 'Review', 'Negotiation'].includes(props.agrStatus) || (['Exhibit'].includes(props.agrStatus) && ['Draft', 'Review', 'Negotiation'].includes(props.parentAgrStatus))) &&
                        props.user !== undefined && props.user.role !== 'Counterparty' ?
                        (                                    
                        <MenuItem onClick={e => handleToolboxOption('deactivateAgreement')} style={{width: menuWidth}}>
                            <ListItemIcon><FontAwesomeIcon icon={faTrash} className={classes.iconPrimary} style={{marginLeft: '1px'}} /></ListItemIcon>
                            <ListItemText>Delete {props.agrStatus === 'Exhibit' ? "Exhibit" : "Agreement"}...</ListItemText>
                        </MenuItem>
                        ) : '' }
                        
                    </Menu>
                </Drawer>

                {props.pageType === 'agreement' ?
                <div className={summaryGone && tocGone && /*partiesGone &&*/ smartFieldsGone ? classes.toggleTOCGone : classes.toggleTOC} 
                    onClick={!summaryGone ? handleSummaryToggle : !tocGone ? handleTocToggle : /*!partiesGone ? handlePartiesToggle :*/ !smartFieldsGone ? handleSmartFieldsToggle : null}>
                    <FontAwesomeIcon icon={faCaretLeft} className={classes.toggleCaretLeft} />
                </div>
                : '' }

            </Hidden>
        </nav>
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
            open={Boolean(snack)}
            autoHideDuration={6000}
            onClose={e => setSnack(false)}
            message={<Typography variant="subtitle2">Data copied to clipboard.</Typography>}
            action={
            <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={e => setSnack(false)}>
                <FontAwesomeIcon icon={faTimes} />
                </IconButton>
            </React.Fragment>
            }
        />
      </React.Fragment>
    );
}
export default DrawerCore