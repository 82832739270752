import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Grid, InputAdornment, TextField, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons";

const ContactUsForm = (props) => {

  const useStyles = makeStyles(theme => ({
    prb2: {
        paddingRight: '20px',
        paddingBottom: '20px'
    },
    pb2: {
        paddingBottom: '20px'
    },
    adornIcon: {
        color: theme.palette.primary.main,
        paddingRight: '8px',
    }
  }));
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
        firstName: props.initialValues.firstName,
        lastName: props.initialValues.lastName,
        email: props.initialValues.email,
        company: props.initialValues.company,
        message: props.initialValues.message,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(30, 'The first name is too long')
        .required('First name is required'),
      lastName: Yup.string()
        .max(50, 'The last name is too long')
        .required('Last name is required'),
      email: Yup.string()
        .email('A valid email address is required')
        .required('An email address is required'),
      company: Yup.string()
        .max(30, 'The company name is too long'),
      message: Yup.string()
        .min(4, 'The message is too short')
        .max(1000, 'The message is too long')
        .required('A message or question is required'),
    }),
    onSubmit: values => {
      props.parentCallback(values);
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} noValidate>
    
    <Grid container direction="column" justify="center" alignItems="center">
        <Grid item container direction="row"
        xs={10} sm={8} md={6}>
        <Grid item container xs={6} 
            className={classes.prb2}>
            <TextField
            required
            fullWidth
            variant="outlined"
            id="firstName"
            label="First Name"
            placeholder="First name"
            name="firstName"
            autoComplete="fname"
            {...formik.getFieldProps('firstName')}
            error={formik.touched.firstName && formik.errors.firstName ? true : false}
            helperText={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : ''}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <FontAwesomeIcon icon={faUser} className={classes.adornIcon} />
                </InputAdornment>
                ),
            }}  
            />
        </Grid>
        <Grid item container xs={6}
            className={classes.pb2}>
            <TextField
            required
            fullWidth
            variant="outlined"
            id="lastName"
            label="Last Name"
            placeholder="Last name"
            name="lastName"
            autoComplete="lname"
            {...formik.getFieldProps('lastName')}
            error={formik.touched.lastName && formik.errors.lastName ? true : false}
            helperText={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : ''}
            />
        </Grid>
        </Grid>
        <Grid item container xs={10} sm={8} md={6}
        className={classes.pb2}>
        <TextField
            required
            fullWidth
            variant="outlined"
            id="email"
            label="Email Address"
            placeholder="Your email address"
            name="email"
            autoComplete="email"
            {...formik.getFieldProps('email')}
            error={formik.touched.email && formik.errors.email ? true : false}
            helperText={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
            InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                <FontAwesomeIcon icon={faEnvelope} className={classes.adornIcon} />
                </InputAdornment>
            ),
            }}
        />
        </Grid>
        <Grid item container xs={10} sm={8} md={6}
        className={classes.pb2}>
        <TextField
            fullWidth
            variant="outlined"
            id="company"
            label="Company name"
            placeholder="Your company name (optional)"
            name="company"
            autoComplete="company"
            {...formik.getFieldProps('company')}
            error={formik.touched.company && formik.errors.company ? true : false}
            helperText={formik.touched.company && formik.errors.company ? formik.errors.company : ''}
            InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                <FontAwesomeIcon icon={faBuilding} className={classes.adornIcon} />
                </InputAdornment>
            ),
            }}
        />
        </Grid>
        <Grid item container xs={10} sm={8} md={6}
        className={classes.pb2}>
        <TextField
            fullWidth
            multiline
            rows="4"
            variant="outlined"
            id="message"
            label="Message"
            placeholder="Your inquiry"
            name="message"
            autoComplete="message"
            {...formik.getFieldProps('message')}
            error={formik.touched.message && formik.errors.message ? true : false}
            helperText={formik.touched.message && formik.errors.message ? formik.errors.message : ''}
        />
        </Grid>
        <Grid item container xs={10} sm={8} md={6}>
        <Button
            fullWidth
            disableElevation
            type="submit"
            variant="contained"
            color="primary"
            >Submit
        </Button>
        </Grid>
    </Grid>

    </form>
  );
};

export default ContactUsForm