import React from 'react';
import PropTypes from 'prop-types'; // https://reactjs.org/docs/typechecking-with-proptypes.html
import { Box, Typography } from '@material-ui/core';

const ErrorMsg = (props) => {

    return (
          <Box mt={1} mb={1} align="center">
            <Typography color="error" variant="subtitle1" align="center">
              {props.msg}
            </Typography>
          </Box>
    );
}

ErrorMsg.propTypes = {
  msg: PropTypes.string
};

export default ErrorMsg