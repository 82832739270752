import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core';

const SelectUserRole = (props) => {
  const useStyles = makeStyles(theme => ({
    modules: {
      display: 'inline-block',
      fontSize: '11px',
      color: theme.palette.grey[600],
      marginLeft: '10px',
    },
  }));
  const classes = useStyles();  

  return (

        <FormControl variant="outlined" fullWidth>
            <InputLabel id="role-select-label">User role</InputLabel>
            <Select
              labelId="role-select-label"
              defaultValue={props.initialVal}
              onChange={e => props.handleChangeUserRole(e)}
              label="User role"
            >
              <MenuItem value="Admin">Admin
                <span className={classes.modules}>(all modules)</span></MenuItem>
              <MenuItem value="Business" disabled={props.adminOnly}>Business 
                <span className={classes.modules}>(Sales, Procurement, Partnerships)</span></MenuItem>
              <MenuItem value="Legal" disabled={props.adminOnly}>Legal
                <span className={classes.modules}>(all modules, excluding Admin)</span></MenuItem>

              {props.modules.includes('partnerships') ? 
              <MenuItem value="Partnerships" disabled={props.adminOnly}>Partnerships
                <span className={classes.modules}>(Partnerships)</span></MenuItem>:''}

              {props.modules.includes('procurement') ? 
              <MenuItem value="Procurement" disabled={props.adminOnly}>Procurement
                <span className={classes.modules}>(Procurement)</span></MenuItem>:''}

              {props.modules.includes('people') ? 
              <MenuItem value="HR" disabled={props.adminOnly}>HR
                <span className={classes.modules}>(People)</span></MenuItem>:''}

              {props.modules.includes('corporate') ? 
              <MenuItem value="Corporate" disabled={props.adminOnly}>Corporate
                <span className={classes.modules}>(Corporate)</span></MenuItem>:''}

              {props.modules.includes('sales') ? 
              <MenuItem value="Sales" disabled={props.adminOnly}>Sales
                <span className={classes.modules}>(Sales)</span></MenuItem>:''}

            </Select>
        </FormControl>
        
    );
}

export default SelectUserRole