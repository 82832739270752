import { getHtmlForComment } from '.'

const axios = require('axios');

function sendReviewEmailToUser (type, recipient, module, relID, agrID, ticket, senderFullName, topicObject, comment, comments, curCpty) {

    const baseURL = ['https://getcanveo.com', 'https://www.getcanveo.com'].includes(window.location.origin) ? 'https://app.getcanveo.com' : window.location.origin

    let loginLink = (baseURL + `/core/loginp/_a/` + recipient._id + `/` + module + `/` + relID + `/` + agrID + `/tick_` + ticket._id)

    let emailBody = ''
    
    if(comment !== undefined && comment !== null && comment !== '') {
        emailBody = emailBody + `<div style="margin: 40px 0px 20px 0px; padding: 0px 0px 0px 20px; border-left: 4px solid #f3f3f3; font-size: 14px; color: #6d6a7a; text-align: left;">`;

        comment.split('\n').forEach((cpar, i) => { 
            emailBody = emailBody + cpar + (i !== (comment.split('\n').length - 1) ? '<br/>' : '')
        })

        emailBody = emailBody + `</div>`
    }

    // Include the button that has the loginp link
    emailBody = emailBody + `
        <table width="100%" cellspacing="0" cellpadding="0"><tr><td align="center" style="padding: 20px 0px 0px 0px">
        <table cellspacing="0" cellpadding="0"><tr>
            <td style="border-radius: 6px; background-color: #E80064;">
            <a href="` + loginLink + `" target="_blank" 
            style="padding: 4px 20px;border: 1px solid #E80064;border-radius: 6px;font-size: 14px;color: #ffffff;text-decoration: none;font-weight: bold;display: inline-block;">
                View Agreement
            </a>
            </td>
        </tr></table>
        </td></tr></table>
        `;

    if(comments !== undefined && comments !== null && comments.filter((c) => c.comment !== '___DELETED___').length > 0) {
        emailBody = emailBody + `
            <table width="100%" cellspacing="0" cellpadding="0"><tr><td align="center">
            <div style="max-width: 320px; margin: 50px 0px 40px 0px;">
            <p style="text-align: center; font-size: 14px; margin: 20px 0px 20px 0px;"><b>Recent comments</b></p>`

        // include all comments for the review
        comments.filter((c) => c.comment !== '___DELETED___').slice(0,5).forEach((ac) => {

            let leftOrRight = ac.creatorID === recipient._id ? 'left' : 'right'
            let color = curCpty
            emailBody = emailBody + getHtmlForComment(ac.comment, leftOrRight, color, ac.creatorDisplayName, ac.creationDate)

        })
        emailBody = emailBody + `</div></td></tr></table>`
    }

    if (type === 'reviewRequest') {

        axios.post('/mail/reviewrequest', {
            recipient: recipient.email,
            senderFullName: senderFullName,
            agrFullName: topicObject.agrFullName,
            cptyName: topicObject.cptyName,
            emailBody: emailBody,
        })

    } else if(type === 'replyonreview') {

        axios.post('/mail/replyonreview', { 
            recipient: recipient.email,
            creatorFullName: senderFullName,
            topic: topicObject.topic,
            emailBody: emailBody,
        })

    } else if (type === 'reviewcompleted') {

        axios.post('/mail/reviewcompleted', {
            recipient: recipient.email,
            creatorFullName: senderFullName,
            topic: topicObject.topic,
            emailBody: emailBody
        })

    }

}

export { sendReviewEmailToUser }