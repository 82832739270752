import React from 'react';
import theme from '../../theme/theme';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Box, Button, Container, Grid, Link, Typography,
         makeStyles } from '@material-ui/core';
import { FAB, GetStarted, Header, InformationalTooltip, ThumbCert, UploadImg } from '../../components';
import { CertificateForm } from '../../components/forms';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import defaultLogo from '../../assets/img/defaultlogo.png';

const dayjs = require('dayjs')

function Certifications(props) {

  const useStyles = makeStyles(theme => ({
    outerFlexGrid: {
      minHeight: '80vh',
      paddingTop: '110px',
      paddingBottom: '100px',
    },
    bandwide: {
      fontWeight: '700',
      marginBottom: '20px',
    },
    logoAvatar: {
      height: '100px',
      width: '100px',
    },
    boxResizing: {
      [theme.breakpoints.only('xs')]: { width: '300px' },
      [theme.breakpoints.only('sm')]: { width: '450px' },
      [theme.breakpoints.up('md')]: { width: '550px' },
    },
    reviewButton: {
      width: '140px',
    },
    grey600: {
      color: theme.palette.grey[600]
    },
    pr15: {
      paddingRight: '15px',
    },
    gridBox: {
      padding: '10px',
    },
    delButton: {
      color: theme.palette.error.main
    },
    delConfirm: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.primary.contrastText,
    },
    bold: {
      fontWeight: '700',
    },
  }));
  const classes = useStyles();

  const [certificateAdd, setCertificateAdd] = React.useState(false);
  const [logoAdd, setLogoAdd] = React.useState(false);
  const [editCert, setEditCert] = React.useState(null);
  const [delCert, setDelCert] = React.useState(null);
  const [formData, setFormData] = React.useState({
    title: '', 
    description: '',
    referenceURL: '',
    sinceDate: new Date(),
    logoURL: defaultLogo,
    detailsDone: false,
    logoDone: false,
  })

  const handleCertificateAddOpen = () => {
      setCertificateAdd(true)
      setLogoAdd(false)
  }

  const callbackCertificateForm = (child) => {
    setFormData({ ...formData, 
      title: child.title,
      description: child.description,
      referenceURL: child.referenceURL,
      sinceDate: dayjs(child.sinceDate).toDate(),
      detailsDone: true });
    setLogoAdd(true);
  }

  const callbackLogoUploadForm = (child) => {
    setFormData({ ...formData, 
      logoURL: child,
      logoDone: true });
  }

  const handleSkipLogo = () => {
    setFormData({ ...formData, 
      logoDone: true });
  }

  const handleCancel = () => {
    setFormData({ ...formData, 
      title: '', 
      description: '',
      referenceURL: '',
      sinceDate: new Date(),
      logoURL: 'https://storage.cloud.google.com/ctimages/defaultlogo.png',
      detailsDone: false,
      logoDone: false});
    setCertificateAdd(false);
    setLogoAdd(false);
  }

  const handleSubmit = () => {
    if(editCert !== null){
      props.updateCert(
        editCert, // certid, 
        formData.title, // title
        formData.description, // description
        formData.logoURL, // logoURL
        formData.referenceURL, // referenceURL
        formData.sinceDate, // sinceDate
        props.user._id, // lastUpdateBy
      )
    } else {
      props.createCert(
        formData.title, // title
        formData.description, // description
        formData.logoURL, // logoURL
        formData.referenceURL, // referenceURL
        formData.sinceDate, // sinceDate
        props.user.orgID, // orgID
        props.user._id, // creationBy
      )
    }

    setFormData({ ...formData, 
      title: '', 
      description: '',
      referenceURL: '',
      sinceDate: new Date(),
      logoURL: 'https://storage.cloud.google.com/ctimages/defaultlogo.png',
      detailsDone: false,
      logoDone: false});
    setCertificateAdd(false);
    setLogoAdd(false);
    setEditCert(null);
  }

  const handleEditClick = (e, certid) => {
    setFormData({ ...formData, 
      logoURL: props.certs.filter((c) => c._id === certid)[0].logoURL });
    setEditCert(certid);
  }

  const handleProposeDelete = () => {
    setDelCert(true);
  }

  const handleCancelDel = () => {
    setDelCert(false);
  }

  const confirmDelete = () => {
    props.deleteCert(editCert);
    setDelCert(false);
    setCertificateAdd(false);
    setLogoAdd(false);
    setEditCert(null);
  }

  return(
    <div>
      <div>
        <Header 
          module="legal"
          active="Certifications"
          crumbs={[
            {name: "Certifications", to: "active"} ]}
          user={props.user} 
          org={props.org}
          modules={
            props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
              ['sales','procurement','partnerships','people','corporate'] : 
            props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
              props.org.modules : [] }
          withSubHead={false}
          notifications={props.notifications}
          markNotificationAsRead={props.markNotificationAsRead}
          markAllNotificationsAsRead={props.markAllNotificationsAsRead}  />

        <Container maxWidth="md">
        <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
          
        {delCert ?
        (
          <Grid item>

            <Typography align="center" variant="h4" className={classes.bandwide}>
              Delete certificate
            </Typography>

            <Grid item container alignItems="center" justify="center">
              <Box mb={5} mt={3}>
                <FontAwesomeIcon icon={faExclamationTriangle} size="5x" color={theme.palette.error.main} />
              </Box>
            </Grid>
            <Grid item container alignItems="center" justify="center">
              <Typography align="center" variant="body1">
                Are you sure you want to delete the following certificate?
              </Typography>              
            </Grid>
            <Grid item container direction="column" alignItems="center" justify="center">

              <Box mt={5}>
                <Avatar src={props.certs.filter((c) => c._id === editCert)[0].logoURL} className={classes.logoAvatar} />
              </Box>
              <Box mt={3} mb={6}>
                <Typography align="center" variant="h5">
                  {props.certs.filter((c) => c._id === editCert)[0].title}
                </Typography>
              </Box>

            </Grid>
            <Grid item container alignItems="center" justify="center">
              <Box mb={5}>
                <Button variant="contained" disableElevation
                  className={classes.delConfirm} onClick={confirmDelete}>
                  Confirm
                </Button>
                &nbsp;&nbsp;
                <Button variant="outlined" className={classes.delButton}
                  onClick={handleCancelDel}>
                  Cancel
                </Button>
              </Box>
            </Grid>


          </Grid>

        )
        :
        editCert !== null && !formData.detailsDone && !formData.logoDone ?
        (
          <Grid item>

            <Typography align="center" variant="h4" className={classes.bandwide}>
              Edit certificate
            </Typography>

            <Box mb={3} mt={0} align="center">
              <Button component={RouterLink} to="/core/legal/certifications" className={classes.grey600}>
                <FontAwesomeIcon icon={faArrowCircleLeft} className={classes.pr15} color={theme.palette.grey[600]} />
                Go back
              </Button>
            </Box>

            <CertificateForm
                parentCallback={callbackCertificateForm}
                initialValues={{
                    title: props.certs.filter((c) => c._id === editCert)[0].title,
                    description: props.certs.filter((c) => c._id === editCert)[0].description,
                    referenceURL: props.certs.filter((c) => c._id === editCert)[0].referenceURL,
                }}
            />

            <Box mt={2} mb={2} align="center">
              <Button variant="outlined" onClick={handleProposeDelete} className={classes.delButton}>
                Delete Certificate
              </Button>
            </Box>

          </Grid>

        )
        :
        certificateAdd && !logoAdd ?
        (
          <Grid item>

            <Typography align="center" variant="h4" className={classes.bandwide}>
              Add a certificate
            </Typography>

            <Box mb={3} mt={0} align="center">
              <Button component={RouterLink} to="/core/legal/certifications" className={classes.grey600}>
                <FontAwesomeIcon icon={faArrowCircleLeft} className={classes.pr15} color={theme.palette.grey[600]} />
                Go back
              </Button>
            </Box>

            <CertificateForm
                parentCallback={callbackCertificateForm}
                initialValues={{
                    title: '', 
                    description: '',
                    referenceURL: '',
                }}
            />

          </Grid>

        )
        :
        ((logoAdd && !formData.logoDone) || 
         (editCert !== null && formData.detailsDone && !formData.logoDone)) ?
        (
          <Grid item>

            <Typography align="center" variant="h4" className={classes.bandwide}>
              Add the logo
            </Typography>

            { formData.logoURL !== defaultLogo ?
            (
              <div>
                <Box mt={2} mb={2} align="center">
                  <img alt="logo" src={formData.logoURL} height="50" />
                </Box>
                <Box mt={2} mb={3} align="center">                
                  <UploadImg type="logo" parentCallback={callbackLogoUploadForm} initialImg={formData.logoURL} />
                </Box>
              </div>
            ) : 
            (
            <Grid item>
              <Box mt={1} mb={3} align="center">                
                <UploadImg type="logo" parentCallback={callbackLogoUploadForm} initialImg={null} />
              </Box>
            </Grid>
            ) }

              <Box mb={7} align="center">
                <Button variant="outlined" color="primary" disableElevation 
                  onClick={handleSkipLogo} >
                  Skip this step
                </Button>
              </Box>

          </Grid>
        )
        :
        ((certificateAdd && logoAdd && formData.detailsDone && formData.logoDone) ||
         (editCert !== null && formData.detailsDone && formData.logoDone)) ?
        (
          <Grid item>

            <Typography align="center" variant="h4" className={classes.bandwide}>
              Review and submit
            </Typography>

            <Box align="center" mt={3} mb={3}>
              <Avatar src={formData.logoURL} alt="Certificate logo" className={classes.logoAvatar}></Avatar>
            </Box>

            <Box width="100%" align="center">
              <Box align="center" className={classes.boxResizing}>
                <Typography align="center" variant="h6">
                  {formData.title}
                </Typography>
                <Typography align="center" variant="body2" color="textSecondary" gutterBottom>
                  Since: {dayjs(formData.sinceDate).format('MMM D, YYYY')}
                </Typography>
                <Box align="center" mt={2} mb={3}>
                  <Typography align="center" variant="body1" gutterBottom>
                    {formData.description}
                  </Typography>
                  {formData.referenceURL !== '' && formData.referenceURL !== null ? 
                  <Box mt={2}>
                  <Typography align="center" variant="body1">
                    <Link href={formData.referenceURL} target="_blank" rel="noopener">More detail can be found here</Link>
                  </Typography>
                  </Box>
                  : '' }
                </Box>
              </Box>
              <Box align="center" className={classes.boxResizing}>
                <Grid container direction="row" alignItems="center" justify="space-between">
                  <Grid item>
                    <Button variant="outlined" disableElevation className={classes.reviewButton} onClick={handleCancel}>Cancel</Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" disableElevation color="primary" className={classes.reviewButton} onClick={handleSubmit}>Submit</Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>

          </Grid>
        )
        :
        (
          <Grid container direction="row">
            {props.user.role !== 'Counterparty' && (props.user.introComplete === undefined || props.user.introComplete.dashboard === undefined || props.user.introComplete.dashboard === null || !props.user.introComplete.dashboard )  &&
             props.org !== undefined && props.org.clientType !== 'legalOrg' ?
                  <GetStarted 
                  fromDashboard={false}
                  step={"2_4"}
                  pushDown={false}
                  module={props.module}
                  user={props.user}
                  org={props.org}
                  certs={props.certs}
                  />
            :''}
            <FAB iconType="plus" click={handleCertificateAddOpen} />
            {props.certs.length === 0 ?
            (
            <Grid container direction="column">
              <Grid item>
                <Typography align="center" variant="h4" className={classes.bold}>
                  Company Certificates&nbsp;&nbsp;<InformationalTooltip msg={"Certificates (e.g. ISO, GDPR, etc.) can be created and managed by Legal and Admin users, and will be displayed on your public Canveo Network Profile page.\n\nYour public page is accessible via: https://app.getcanveo.com/network/" + props.org.orgurl} />
                </Typography>
              </Grid>
              <Grid item>
                <Box align="center" mt={8} mb={8}>
                  <Typography>
                    There are no company certificates setup (yet)
                  </Typography>
                  <Box mt={8}>
                    <Button variant="contained" color="secondary" size="large" disableElevation
                      onClick={handleCertificateAddOpen}>
                        Create a certificate
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            )
            :
            props.certs.map((cert) => (
            <Grid item xs={12} sm={6} md={4} className={classes.gridBox} key={cert._id}>
              <ThumbCert
                cert={cert}
                type="edit"
                click={handleEditClick}
              />
            </Grid>
            ))}
          </Grid>
        )}
        
        </Grid>
        </Container>
                
      </div>

    </div>
  );
}

export default Certifications;