import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import AutoSizer from 'react-virtualized-auto-sizer';
import SignatureCanvas from 'react-signature-canvas'
import { Backdrop, Button, Box, Dialog, Fab, Grid, Typography, makeStyles, CircularProgress, IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSignature, faTimes } from '@fortawesome/free-solid-svg-icons';

const axios = require('axios');
const download = require("js-file-download") 

const DialogAVDiff = (props) => {

  const useStyles = makeStyles(theme => ({
    pdfBox: {
        [theme.breakpoints.down('xs')]: { width: '100%', },
        [theme.breakpoints.only('sm')]: { width: '90%', left: '5%' },
        [theme.breakpoints.only('md')]: { width: '900px', left: 'calc(50% - 450px)' },
        [theme.breakpoints.up('lg')]: { width: '900px', left: 'calc(50% - 450px)' },
        height: '100vh',
        marginTop: '0px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },
    signWindow: {
        [theme.breakpoints.down('xs')]: { height: '310px', borderRadius: '8px', },
        width: '90%',
        maxWidth: '600px',
        height: '400px',
        backgroundColor: '#ffffff',
        borderRadius: '8px',
    },
    exitSignWindow: {
        [theme.breakpoints.down('xs')]: { top: '15px', right: '20px', fontSize: '30px' },
        position: 'fixed',
        zIndex: '2000',
        right: '30px',
        top: '20px',
        fontSize: '44px',
        borderRadius: '50px',
        //backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.contrastText,
        '&:hover':{
            color: theme.palette.secondary.main,
            cursor: 'pointer'
        }
    },
    exitSignDialog: {
        [theme.breakpoints.down('xs')]: { top: '15px', right: '20px', fontSize: '30px' },
        position: 'fixed',
        zIndex: '2000',
        right: '35px',
        top: '20px',
        fontSize: '40px',
        borderRadius: '50px',
        color: theme.palette.grey[600],
        '&:hover':{
            color: theme.palette.secondary.main,
            cursor: 'pointer'
        }
    },
    buttonsSignDialog: {
        [theme.breakpoints.down('sm')]: { bottom: '15px', left: '10px', fontSize: '13px' },
        [theme.breakpoints.up('md')]: { top: '15px', left: '20px', fontSize: '14px' },
        position: 'fixed',
        zIndex: '2000',
        fontWeight: '500',
        backgroundColor: '#f6f6f6',
        borderRadius: '8px',
        color: theme.palette.grey[600],
    },
    buttonSmall: {
        [theme.breakpoints.down('xs')]: { margin: '10px', },
        display: 'block',
        margin: '12px',
        '&:hover':{
            color: theme.palette.secondary.main,
            cursor: 'pointer'
        }
    },
    sigCanvas: {
        [theme.breakpoints.down('xs')]: { height: '232px' },
        border: '1px solid #e0e0e0',
        borderRadius: '5px',
        width: 'calc(100% - 22px)',
        height: '80%',
        margin: '10px',
    },
    signFAB: {
        //[theme.breakpoints.down('xs')]: { height: '50px' },
        //[theme.breakpoints.up('sm')]: { height: '60px' },
        height: '60px',
        margin: 0,
        bottom: '20px', 
        right: '20px',
        borderRadius: '30px',
        padding: '0px 30px 0px 30px',
        top: 'auto',
        left: 'auto',
        position: 'fixed',
        zIndex: 1000,
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
    reuseSigBox: {
        bottom: '90px', 
        right: '30px',
        borderRadius: '4px',
        backgroundColor: '#ffffff',
        border: '1px solid #eeeeee',
        top: 'auto',
        left: 'auto',
        position: 'fixed',
        zIndex: 1000,
    },
    savedSignature: {
        width: '80px',
        marginRight: '5px',
    },
    fabFont: {
        //[theme.breakpoints.down('sm')]: { fontSize: '15px' },
        //[theme.breakpoints.up('md')]: { fontSize: '18px' },
        fontSize: '18px',
        paddingRight: '13px',
        fontWeight: '600',
    },
    page: {
        //border: '1px solid #eeeeee',
        margin: '5px 0px 5px 0px',
    },
    delSignature: {
        fontSize: '13px',
        padding: '5px 7px 5px 7px',
    }
  }));
  const classes = useStyles();

  const history = useHistory()

  const [loading, setLoading] = React.useState(false);
  const [viewablePDF, setViewablePDF] = React.useState(null);
  const [signWindow, setSignWindow] = React.useState(false)
  const [numPages, setNumPages] = React.useState(null);
  const [reuseSigImg, setReuseSigImg] = React.useState(null)

  const sigCanvas = React.useRef({});
  const exitPath = props.user.role === 'Counterparty' ?
        '/core/counterparty/relationship/' + props.rid + '/cptydone/1' :
        '/core/' + props.module + '/relationship/' + props.rid + '/overview/1'

  useEffect(() => {

    if(props.open && props.pdfUnsignedURL !== undefined && props.pdfUnsignedURL !== null && props.pdfUnsignedURL !== '') {
        axios.get('/signing/protect/download/' + props.pdfUnsignedURL, {responseType: 'arraybuffer'})
        .then(function (respdf) {  
            axios.get('/user/protect/usersig/' + props.user._id)
            .then(function (ressig) { 

                setReuseSigImg(ressig.data.data)
                setViewablePDF(respdf.data);

            }).catch(function(err) { console.log("Unable to load PDF for signing", err)})
        }).catch(function(err) { console.log("Unable to load PDF for signing", err)})
    }

  }, [props.pdfUnsignedURL, props.open])

  const handleClear = () => {
    sigCanvas.current.clear()
  };

  const handleSaveAndSign = () => {

    if(reuseSigImg !== null || !sigCanvas.current.isEmpty()) {
        setLoading(true)
        setSignWindow(false)
        let signer = props.signatories.filter((s) => s.signer_email_address === props.user.email)[0]
        let sigImg = reuseSigImg !== null ? reuseSigImg : sigCanvas.current.toDataURL("image/png");
    
        if(signer !== undefined && sigImg !== null && sigImg !== undefined && sigImg !== '') {
            
            axios.post('/signing/protect/swisscom/sign', {
                signatoryRequestID: props.signatoryRequestID,
                filename: props.pdfUnsignedURL,
                signatureImg: sigImg,
                signer: signer,
                signatories: props.signatories,
            }).then((res) => {
                
                if(reuseSigImg === null) { axios.put('/user/protect/sigimg/' + props.user._id, { uid: props.user._id, sig: sigImg, lastUpdate: new Date().toISOString() }) }
    
                history.push(exitPath)
                props.handleSignatureDialogClose()
          
            }).catch(function (err) { console.log("An error in completing the signing", err) })
        }
    }

  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const closeDialog = () => {
    setLoading(false)
    setViewablePDF(null)
    setSignWindow(false)
    if(loading) {
        history.push(exitPath)
    } else {
        props.handleSignatureDialogClose()
    }
  }

  const handleDownloadFile = () => {
    download(viewablePDF, props.downloadFileName + ".pdf");
  }

  return (
    <Dialog 
    fullScreen 
    open={props.open} 
    onClose={closeDialog} 
    //TransitionComponent={Transition}
    PaperProps={{
        style: {
          backgroundColor: numPages !== null ? '#f6f6f6' : '#ffffff',
        },
      }}
    >
        {!signWindow ?
        <>
        {!loading ?
        <div className={classes.buttonsSignDialog}>
            <span className={classes.buttonSmall} onClick={handleDownloadFile}><FontAwesomeIcon icon={faDownload} />&nbsp;&nbsp;Download</span>
        </div>:''}
        <div className={classes.exitSignDialog} onClick={closeDialog}><FontAwesomeIcon icon={faTimes} /></div>
        </>
        :''}

        {loading ?
        <div style={{zIndex: '2000', width: '100%', top: '200px', position: 'fixed', textAlign: 'center' }}>
            <Box mb={2}><CircularProgress size={60}  style={{color: '#ffffff'}} /></Box>
            <Typography variant="h6" style={{color: '#ffffff'}}>Processing the signature...</Typography>
        </div>
        : '' }

        {reuseSigImg !== null && !loading ?
            <div className={classes.reuseSigBox}>
                <Grid container direction="row" alignItems="flex-start" justify="flex-end">
                    <Grid item>
                    <Box align="center" pt={1} pb={1} pr={0} pl={1}>
                    <img src={reuseSigImg} alt="Saved signature" className={classes.savedSignature} />
                    </Box>
                    </Grid>
                    <Grid item>
                    <IconButton size="small" onClick={e => setReuseSigImg(null)}><FontAwesomeIcon icon={faTimes} className={classes.delSignature} /></IconButton>
                    </Grid>                    
                </Grid>                
            </div>    
        :''}


        <Fab
        disabled={viewablePDF === null || signWindow || loading}
        color={"secondary"}
        className={classes.signFAB}
        variant="extended"
        onClick={reuseSigImg !== null ?
            e => handleSaveAndSign() :
            e => setSignWindow(true)}
        >
        <Typography className={classes.fabFont}>
            Sign Agreement{loading ? <>&nbsp;&nbsp;<CircularProgress size={20} /></>:''}
        </Typography>
        <FontAwesomeIcon icon={faSignature} />
        </Fab>

        {viewablePDF !== null ?
        <div className={classes.pdfBox}>
            <AutoSizer disableHeight>
                {({width}) => (
                <>
                <Backdrop className={classes.backdrop} open={signWindow || loading}>
                {signWindow ? 
                <div className={classes.signWindow}>
                    <div className={classes.exitSignWindow} onClick={e => setSignWindow(false)}><FontAwesomeIcon icon={faTimes} /></div>
                    <SignatureCanvas
                    canvasProps={{className: classes.sigCanvas}} 
                    ref={sigCanvas}
                    minWidth={2}
                    maxWidth={4}
                    />
                    <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center" style={{padding: '0px 10px 0px 10px'}}>
                        <Grid item>
                            <Button variant="text" color="primary" onClick={e => setSignWindow(false)}>Cancel</Button>&nbsp;&nbsp;
                            <Button variant="text" color="primary" onClick={e => handleClear()}>Clear</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="secondary" disableElevation disabled={loading}
                            onClick={e => handleSaveAndSign()}>{loading ? <CircularProgress size={18} /> : ''}Submit Signature&nbsp;&nbsp;<FontAwesomeIcon icon={faSignature} /></Button>
                        </Grid>
                    </Grid>
                </div>
                :''}
                </Backdrop>
                
                <Document file={viewablePDF}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={
                    <Box align="center" style={{width: width, marginTop: '80px'}}>
                    {/*<SkeletonCustom type="agreement" />*/}
                    <CircularProgress size={34} disableShrink />
                    </Box>}
                >
                    
                    {Array.from(new Array(numPages)).map((el, index) => (
                    <div className={classes.page} style={{width: width}} key={"page_" + (index + 1)}>
                      <Page
                        pageNumber={index + 1}
                        width={width}
                      />
                    </div>
                    ))}
                </Document>
                <br/>
                </>
                )}
            </AutoSizer>
        </div>
        :''}

    </Dialog>

  )
}

export default DialogAVDiff