import React from 'react';
import { matchSorter } from 'match-sorter';
import theme from '../theme/theme';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'; // https://reactjs.org/docs/typechecking-with-proptypes.html
import { Avatar, Box, Chip, Grid, TextField, makeStyles, withStyles, Typography, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import defaultLogo from '../assets/img/defaultlogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';

const filterOptions = (options, { inputValue }) =>
    matchSorter(options, inputValue, {
      keys: ['title','description']
    })
    .slice(0,10)
    .sort((a, b) => -b.category.localeCompare(a.category))
    ; // Only the first 25 results

const CssTextField = withStyles({
    root: {
      [theme.breakpoints.only('xs')]: { width: '230px', },
      [theme.breakpoints.only('sm')]: { width: '360px', },
      [theme.breakpoints.up('md')]: { width: '460px', },
      marginBottom: '10px',
      backgroundColor: theme.palette.primary.contrastText,
      borderRadius: '25px 0px 0px 25px',
      '& label.Mui-focused': {
        color: theme.palette.primary.main,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.primary.main,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.grey[400],
        },
        '&:hover fieldset': { // hover
          borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.primary.main,
        },
      },
      '& fieldset': {
        borderRadius: '25px 0px 0px 25px',
      }
    },
})(TextField);

function SearchField (props) {

  const useStyles = makeStyles(theme => ({
    avatarOption: {
        width: '30px',
        height: '30px',
        marginRight: '20px',
    },
    avatarOptionIcon: {
      width: '30px',
      height: '30px',
      marginRight: '20px',
      fontSize: '16px',
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.grey[700],
    },
    submitButton: {
      border: '1px solid' + theme.palette.grey[400],
      borderLeft: '0px',
      backgroundColor: '#f6f6f6',
      color: theme.palette.grey[700],
      fontSize: '16px',
      display: 'inline',
      padding: '17px 16px 17px 14px',
      borderRadius: '0px 25px 25px 0px',
      '&:hover' : {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        border: '1px solid' + theme.palette.primary.main,
        borderLeft: '0px',
        cursor: 'pointer',
      }
    },
    searchChip: {
      backgroundColor: '#f6f6f6',
      border: '1px solid #dddddd',
      fontWeight: '500',
      marginRight: '3px',
    },
    chipIcon: {
      color: theme.palette.primary.main,
      padding: '0px 3px 0px 6px',
    },
    deleteIcon: {
      fontSize: '12px',
      padding: '3px 4px 3px 4px',
    }
  }));
  const classes = useStyles();

  const history = useHistory()

  const [showOptions, setShowOptions] = React.useState(false);
  const [searchChips, setSearchChips] = React.useState([])
  const [errMsg, setErrMsg] = React.useState(null)

  const handleInputChange = (e, value) => {
    if(value.length > 2 && !showOptions) { 
      setShowOptions(true);
    } else if(value.length < 3 && showOptions){
      setShowOptions(false)
    }
  }

  const handleValueChange = (e, value) => {
    setSearchChips(value)
    if(errMsg !== null) { setErrMsg(null) }
  }

  const handleSubmit = () => {
    if (searchChips.length === 0) {
      setErrMsg('Select at least one search category')

    } else if (searchChips.length > 2) {
      setErrMsg('It is not (yet) possible to select more than two search items')

    } else if(searchChips.length === 1 && searchChips[0].route === null) {
      setErrMsg('Also select a counterparty or relationship')

    } else if(searchChips.length === 1 && searchChips[0].route !== null) {
      history.push(searchChips[0].route);

    } else if(searchChips.length === 2 && // Agreements combination
      searchChips.some((sc) => ['Agreements'].includes(sc.category)) && 
      searchChips.some((sc) => ['Counterparties', 'Relationships'].includes(sc.category))
      ) {

      // AGREEMENTS combination: Currently this is simplified, to simply send to the relationship with all agreements
      let relID = searchChips.filter((sc) => ['Relationships', 'Counterparties'].includes(sc.category))[0] !== undefined ? 
        searchChips.filter((sc) => ['Relationships', 'Counterparties'].includes(sc.category))[0].relID : null

      if(relID === null) { setErrMsg('The relationship was not found') } 
      else {

        let searchchip = ''

        if(searchChips.filter((sc) => ['Agreements'].includes(sc.category))[0] !== undefined) {
          searchchip = searchchip + "at_" + searchChips.filter((sc) => ['Agreements'].includes(sc.category))[0].agrTypeID
        }

        if(searchChips.filter((sc) => ['Counterparties'].includes(sc.category))[0] !== undefined) {
          searchchip = searchchip + ",cp_" + searchChips.filter((sc) => ['Counterparties'].includes(sc.category))[0].entID
        }
          
        history.push('/core/' + props.module + '/relationship/' + relID + '/agreements/0/' + searchchip)

      }

    } else if(searchChips.length === 2 && // Smart Fields / Topics combination
      searchChips.some((sc) => ['Smart Fields', 'Topics'].includes(sc.category)) &&
      searchChips.some((sc) => ['Counterparties', 'Relationships'].includes(sc.category))
      ) {

      let type = searchChips.some((sc) => ['Counterparties'].includes(sc.category)) ? 'sf_ent' : 'sf_rel'
      let osfid = 
        searchChips.filter((sc) => ['Smart Fields', 'Topics'].includes(sc.category))[0] !== undefined ? 
            searchChips.filter((sc) => ['Smart Fields', 'Topics'].includes(sc.category))[0].osfid : null
      let otherid = 
        searchChips.filter((sc) => ['Counterparties'].includes(sc.category))[0] !== undefined ?
            searchChips.filter((sc) => ['Counterparties'].includes(sc.category))[0].entID : 
        searchChips.filter((sc) => ['Relationships'].includes(sc.category))[0] !== undefined ?
            searchChips.filter((sc) => ['Relationships'].includes(sc.category))[0].relID : null

      if(osfid !== null && otherid !== null) {
        history.push('/core/' + props.module + '/dashboard/' + type + '/' + osfid + '/' + otherid)
      } else {
        setErrMsg("Unable to perform the search query")
      }

    } else {
      setErrMsg('Unable to handle the search query, try a different combination')

    }
  }

  return (
    <>
    <Grid container direction="row" alignItems="center">
      <Grid item style={{paddingTop: '10px'}}>
        <Autocomplete
          id="search-box"
          multiple
          options={props.options !== undefined && props.options !== null ? props.options : []}
          getOptionLabel={option => option.title}
          forcePopupIcon={false}
          filterOptions={filterOptions}
          onInputChange={handleInputChange}
          value={searchChips}
          onChange={handleValueChange}
          open={showOptions}
          clearOnEscape
          groupBy={option => option.category}
          renderOption={(option) => (
            <React.Fragment>
              {option.logoURL === null && option.icon !== null ?
              <Avatar alt={option.title} className={classes.avatarOptionIcon}>
                <FontAwesomeIcon icon={option.icon} />
              </Avatar>
              :
              <Avatar alt={option.title} 
              src={option.logoURL !== undefined && option.logoURL !== null && option.logoURL !== '' ? option.logoURL : defaultLogo} className={classes.avatarOption} />
              }
              {option.title}
            </React.Fragment>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" label={option.title} {...getTagProps({ index })} className={classes.searchChip}
              avatar={option.logoURL !== null ? <Avatar src={option.logoURL !== undefined && option.logoURL !== '' ? option.logoURL : defaultLogo} /> : null}
              icon={option.logoURL === null ? <FontAwesomeIcon icon={option.icon} className={classes.chipIcon} /> : null} />
            ))
          }
          renderInput={params => 
          <CssTextField
            {...params}  
            placeholder={props.placeholder}
            variant="outlined"
            label={props.label}
          />}
        />
      </Grid>
      <Grid item>
        <Box className={classes.submitButton} onClick={handleSubmit}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Box>
      </Grid>
    </Grid>
    {errMsg !== null ?
    <Box align="center">
      <Typography color="error" variant="body2">{errMsg}&nbsp;&nbsp;
      <IconButton size="small" onClick={e => setErrMsg(null)}>
        <FontAwesomeIcon icon={faTimes} className={classes.deleteIcon} />
      </IconButton>
      </Typography>
    </Box>
    : '' }
    </>
  )
}

SearchField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired
};

export default SearchField
