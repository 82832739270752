import { stripPlain } from '.'

function getClauseTitleIndication(plainArr) {

    let s = ''
    let strippedFirstLine = stripPlain(plainArr[0])

    let firstLeaderLead = strippedFirstLine.match(/^(\d{1,2}|[ivxIVX]{1,3})(\.|\)| )/) || // e.g. "1."; "16 "
        strippedFirstLine.match(/^([a-zA-Z]{1,1})(\.|\))/) || // e.g. "A)"; "a."
        strippedFirstLine.match(/^\((\d{1,2}|[a-zA-Z]{1,1}|[ivxIVX]{1,3})\)/) // e.g. "iv." or "X)" 

    if(((firstLeaderLead && strippedFirstLine.trim().split(' ').length < 2) || strippedFirstLine.length < 4) && plainArr[1] !== undefined) {
        s = strippedFirstLine + '  ' + plainArr[1]
    } else {
        s = strippedFirstLine
    }

    return s
}

export { getClauseTitleIndication }