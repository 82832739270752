import React from 'react';
import { useHistory } from 'react-router-dom';
import { trunc, getAppropriateIcon } from '../utils';
import { Badge, Box, Button, Grid, Grow, IconButton, MenuList, MenuItem,
         ListItem, ListItemAvatar, ListItemText, Paper, Popper, Typography, 
         ClickAwayListener, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faTimes } from "@fortawesome/free-solid-svg-icons";

const MenuPopNotifications = (props) => {

    const useStyles = makeStyles(theme => ({
        specialMenuOption: {
            fontSize: '13px',
            fontWeight: '500',
            color: theme.palette.primary.main,
        },
        centering: {
            textAlign: 'center',
        },
        menuItemRead: {
            paddingLeft: '5px',
            '&:hover': {
                backgroundColor: theme.palette.grey[200]
            }
        },
        menuItemUnread: {
            paddingLeft: '5px',
            backgroundColor: theme.palette.grey[100],
            borderBottom: '1px solid' + theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.grey[200]
            }
        },
        faexit: {
            fontSize: '20px',
            color: theme.palette.grey[700],
        },
        faunread: {
            fontSize: '20px',
            color: theme.palette.secondary.main,
            paddingLeft: '5px',
            paddingRight: '5px'
        },
        faread: {
            fontSize: '20px',
            color: theme.palette.grey[700],
            paddingLeft: '5px',
            paddingRight: '5px'
        },
        line1unread: {
            fontSize: '14px',
            fontWeight: '700',
            color: theme.palette.grey[800]
        },
        line1read: {
            fontSize: '14px',
            fontWeight: '400',
            color: theme.palette.grey[800]
        },
        line2unread: {
            fontSize: '12px',
            fontWeight: '700',
            color: theme.palette.grey[700]
        },
        line2read: {
            fontSize: '12px',
            fontWeight: '300',
            color: theme.palette.grey[700]
        },
        showXS: {
            [theme.breakpoints.only('xs')]: { display: 'block' },
            [theme.breakpoints.up('sm')]: { display: 'none' },
        },
        hideXS: {
            [theme.breakpoints.only('xs')]: { display: 'none' },
            [theme.breakpoints.up('sm')]: { display: 'block' },
        },
        noGutterBottom: {
            marginBottom: '0px', 
            paddingBottom: '0px'
        },
        menuHeight: {
            height: '300px',
            [theme.breakpoints.only('xs')]: { width: '320px' },
            [theme.breakpoints.up('sm')]: { width: '400px' },
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                display: 'none'
            }
        },
        bellIcon: {
            padding: '12px 13px 12px 13px',
        },
        exitIconB: {
            padding: '10px 12px 10px 12px',
        }
    }));
    const classes = useStyles();

    const history = useHistory()

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
        }
    }

    const handleClick = (e, nid, to, isRead) => {
        // always route a cpty user into the counterparty module as notifications will have a path as-if a ct subscriber
        if(!isRead){ props.markNotificationAsRead(nid); }
        if ((props.userRole === 'Counterparty' || props.userRole.startsWith('ClientFor_')) && to.split('/')[1] === 'core' && to.split('/')[2] === 'partnerships') {
            history.push(to.replace('partnerships', 'counterparty'));
        } else if ((props.userRole === 'Counterparty' || props.userRole.startsWith('ClientFor_')) && to.split('/')[1] === 'core' && to.split('/')[2] === 'procurement') {
            history.push(to.replace('procurement', 'counterparty'));
        } else if ((props.userRole === 'Counterparty' || props.userRole.startsWith('ClientFor_')) && to.split('/')[1] === 'core' && to.split('/')[2] === 'sales') {
            history.push(to.replace('sales', 'counterparty'));
        } else if ((props.userRole === 'Counterparty' || props.userRole.startsWith('ClientFor_')) && to.split('/')[1] === 'core' && to.split('/')[2] === 'corporate') {
            history.push(to.replace('corporate', 'counterparty'));
        } else if ((props.userRole === 'Counterparty' || props.userRole.startsWith('ClientFor_')) && to.split('/')[1] === 'core' && to.split('/')[2] === 'people') {
            history.push(to.replace('people', 'counterparty'));
        } else {
            history.push(to);
        }
    }

    const handleMarkAll = () => {
        props.markAllNotificationsAsRead(props.uid);
        setOpen(false)
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    return (
        <React.Fragment>
        <IconButton
        ref={anchorRef}
        className={classes.bellIcon}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}>
        <Badge color="secondary" variant="dot" invisible={props.notifications !== undefined && props.notifications.find((n) => n.isRead === false) === undefined}>
            <FontAwesomeIcon icon={faBell} className={classes.headIcon} />
        </Badge>
        </IconButton>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
            <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
                <Paper variant="outlined">
                <ClickAwayListener onClickAway={handleClose}>
                  <div>
                    <ListItem alignItems="center" className={classes.noGutterBottom}>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item>
                                <Button 
                                    className={classes.specialMenuOption}
                                    onClick={handleMarkAll}
                                    disabled={props.notifications.find((n) => n.isRead === false) === undefined}>
                                    Mark all as read
                                </Button>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={handleClose} className={classes.exitIconB}>
                                    <FontAwesomeIcon icon={faTimes} className={classes.faexit} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Box className={classes.menuHeight}>
                        <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>

                        {props.notifications.map((n) => (
                            <MenuItem key={n._id} onClick={e => handleClick(e, n._id, n.linkTo, n.isRead)} className={n.isRead ? classes.menuItemRead : classes.menuItemUnread}>
                            <ListItemAvatar className={classes.centering}>
                                <FontAwesomeIcon className={n.isRead ? classes.faread : classes.faunread}
                                 icon={getAppropriateIcon('notification', n.notificationType)} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography className={n.isRead ? classes.line1read : classes.line1unread}>
                                    {n.title}
                                    </Typography>}
                                secondary={
                                    <Typography className={n.isRead ? classes.line2read : classes.line2unread}>
                                        <span className={classes.showXS}>{trunc(n.description,39)}</span>
                                        <span className={classes.hideXS}>{trunc(n.description,52)}</span>
                                    </Typography>
                                    }
                            />
                            </MenuItem>
                        )) }

                        </MenuList>
                    </Box>
                  </div>
                </ClickAwayListener>
                </Paper>
            </Grow>
            )}
        </Popper>
        </React.Fragment>
    )

}

export default MenuPopNotifications