import React from 'react';
import theme from '../theme/theme';
import { prices } from '../assets/static/prices';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Avatar, Box, Button, Checkbox, CircularProgress, Container, Grid, 
         List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, Typography, 
         makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faEnvelope, faLandmark, faThumbsUp, faUserCircle, faTag,
         faExternalLinkAlt, faCheck } from "@fortawesome/free-solid-svg-icons";

import { PricingContent, DrawerCore, Header, UploadImg } from '../components';
import { EntityForm, UserForm } from '../components/forms';
import { getCcyByCountry, randomString as generateRandomString, countryToFlag, trunc } from '../utils'
import Google from '../assets/img/icon_google.png';
import Slack from '../assets/img/icon_slack.png';
import defaultLogo from '../assets/img/defaultlogo.png';

const axios = require('axios');

function SignUp(props) {
  
  const useStyles = makeStyles(theme => ({
    outerGrid: {
      minHeight: '720px',
      paddingTop: '120px',
      paddingBottom: '60px',
    },
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
    },
    secondary: {
      color: theme.palette.secondary.main
    },
    mbAndLight: {
      marginBottom: '40px',
      fontWeight: '300',
      color: theme.palette.grey[800]
    },
    greyIcon: {
      color: theme.palette.grey[600],
      fontSize: '20px'
    },
    primaryIcon: {
      color: theme.palette.primary.main
    },
    avatar: {
      width: '70px',
      height: '70px',
      marginTop: '10px',
      backgroundColor: theme.palette.grey[800],
    },
    orgAvatar: {
      [theme.breakpoints.down('sm')]: { marginTop: '40px' },
      width: '70px',
      height: '70px',
      marginTop: '10px',
    },
    bold: {
        fontWeight: '700',
    },
    signupIcon: {
        width: '22px',
        height: 'auto',
        marginRight: '12px'
    },
    border: {
      border: '2px solid' + theme.palette.primary.main
    },
    toggleB: {
      height: '240px',
      width: '180px',
      color: theme.palette.grey[800],
    },
    toggleBTall: {
      height: '260px',
      width: '180px',
      color: theme.palette.grey[800],
    },
    toggleBSM: {
      height: '100px',
      width: '100px',
      color: theme.palette.grey[800],
    },
    toggleGrid: {
      backgroundColor: theme.palette.primary.contrastText,
      padding: '30px 10px 30px 10px', 
      borderRadius: '5px'
    },
    toggleGridSM: {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.grey[700],
      padding: '20px 10px 20px 10px', 
      borderRadius: '5px',
      fontSize: '13px',
    },
    secondaryPrice: {
      fontWeight: '600',
      color: theme.palette.secondary.main,
      fontSize: '28px',
      marginBottom: '10px',
    },
    greyPrice: {
      fontWeight: '600',
      color: theme.palette.grey[300],
      fontSize: '28px',
      marginBottom: '10px',
    },
    boxResizing: {
      [theme.breakpoints.only('xs')]: { width: '320px' },
      [theme.breakpoints.up('sm')]: { width: '420px' },
    },
    errorMsg: {
      color: theme.palette.error.main,
    },
    freetrial: {
      marginTop: '9px',
      lineHeight: '14px',
      fontSize: '13px',
      fontWeight: '700',
    },
    priceCycle: {
      fontSize: '14px',
      color: theme.palette.grey[700],
      fontWeight: '500',
      marginLeft: '4px'
    }
  }));
  const classes = useStyles();

  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  //const [page, setPage] = React.useState('package')
  const [page, setPage] = React.useState(props.action === 'oauth' ? 'personal' : ['done'].includes(props.action) ? props.action : 'personal')
  const [submitting, setSubmitting] = React.useState(false);
  const [agreed, setAgreed] = React.useState(false);  
  const [custType, setCustType] = React.useState(null)
  const [formError, setFormError] = React.useState('');
  const [cardElement, setCardElement] = React.useState(null);
  const [cardError, setCardError] = React.useState(null);
  const [cardComplete, setCardComplete] = React.useState(false)
  const [custPricing, setCustPricing] = React.useState(null)
  const [formData, setFormData] = React.useState({
    firstName: (['oauth', 'cpty'].includes(props.action) ? props.user.firstName : ''),
    lastName: (['oauth', 'cpty'].includes(props.action) ? props.user.lastName : ''),
    title: ['cpty'].includes(props.action) ? props.user.title : '',
    email: (['cpty'].includes(props.action) && props.email !== undefined ? props.email :
            ['oauth', 'cpty'].includes(props.action) ? props.user.email : ''),
    password: ['cpty'].includes(props.action) && props.user !== undefined && props.user.password !== undefined &&
      props.user.password.length > 20 ? props.user.password : '',
    photoURL: (['oauth', 'cpty'].includes(props.action) && props.user.photoURL !== '' ? props.user.photoURL : ''),
    shortName: ['cpty'].includes(props.action) ? props.org.shortName : '',
    legalName: ['cpty'].includes(props.action) ? props.org.legalName : '',
    address: ['cpty'].includes(props.action) && props.subs !== undefined && props.subs[0] !== undefined && props.subs[0].address.filter((a) => a.addressType === 'billto')[0] !== undefined ? 
      props.subs[0].address.filter((a) => a.addressType === 'billto')[0] : {},
    taxids: [],
    logoURL: ['cpty'].includes(props.action) && props.org !== undefined && props.org.logoURL !== undefined ? props.org.logoURL : defaultLogo,
    users: ['cpty'].includes(props.action) ? props.users.filter((u) => u._id !== props.user._id && u.active) : [],
    usersDone: false,
    userDataDone: false,
    companyDataDone: false,
  })

  const discountCode = props.action === 'dc' && props.email !== undefined && props.email !== null && props.email.length === 8 &&
    props.email.toUpperCase() === props.email && props.email.match(/^[A-Z]+$/) ? props.email : null

  const routeToPage = (page) => {
    setPage(page)
  }

  const discountSelectPackage = () => {

    setPage('payment')
  }

  const handleCustTypeChange = (event, newSelection) => {

    let cType = 
      props.action === 'business' ? 'Business' : 
      props.action === 'premium' ? 'Business Premium' : 
      props.action === 'cpty' && props.users !== undefined && prices.filter((p) => p.maxUsers >= props.users.filter((u) => u.active).length)[0] !== undefined ? prices.filter((p) => p.maxUsers >= props.users.filter((u) => u.active).length)[0].custType
        : 'Business';

    if(['business'].includes(event)) { cType = 'Business' }
    else if(['premium'].includes(event)) { cType = 'Business Premium'; }

    let ccy = getCcyByCountry(formData.address !== undefined && formData.address.country !== undefined && formData.address.country !== null && formData.address.country.length === 2 ? formData.address.country : navigator.language.slice(-2)).ccy
    if(prices.filter((p) => p.custType === cType)[0] && prices.filter((p) => p.custType === cType)[0].currencies.filter((c) => c.ccy === ccy)[0] !== undefined) {
      setCustType(cType)
      setCustPricing(
        {...prices.filter((p) => p.custType === cType)[0], 
          stripeid: (window.location.href.indexOf("getcanveo.com") > -1 && window.location.href.indexOf("test.getcanveo.com") === -1) || window.location.href.indexOf('canveo.oa.r.appspot.com') > -1 ?
            prices.filter((p) => p.custType === cType)[0].currencies.filter((c) => c.ccy === ccy)[0].stripeid : prices.filter((p) => p.custType === cType)[0].currencies.filter((c) => c.ccy === ccy)[0].stripeid_test,
          stripeuid: (window.location.href.indexOf("getcanveo.com") > -1 && window.location.href.indexOf("test.getcanveo.com") === -1) || window.location.href.indexOf('canveo.oa.r.appspot.com') > -1 ?
            prices.filter((p) => p.custType === cType)[0].currencies.filter((c) => c.ccy === ccy)[0].stripeuid : prices.filter((p) => p.custType === cType)[0].currencies.filter((c) => c.ccy === ccy)[0].stripeuid_test,
        })
      setPage('payment')
    } else {
      setCustPricing(null)
    }

  };

  const handleCardInputChange = (e) => {
    const cardEl = elements.getElement(CardElement);

    if(e.error !== undefined) {
      setCardError(e.error.message)
    } else {
      setCardError(null)
      if(e.complete) { 
        setCardComplete(true) 
        setCardElement(cardEl)
      }
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitting(true);
    const { firstName, lastName, title, email, password, 
            photoURL, shortName, legalName, address, taxids, logoURL, 
            users, userDataDone, companyDataDone } = formData

    let creationDate = new Date().toISOString();
    // Tests on the client side
    if((!userDataDone && props.action !== 'oauth') || !companyDataDone){
      setFormError('Please go back to complete your personal and business information');
      setSubmitting(false);
    } else if (!agreed) {
      setFormError('Please review and accept our Service Terms and Privacy Policy');
      setSubmitting(false);
    } else if (props.network.some(n => n.shortName === shortName)) {
      setFormError('This company name already exists, please change or contact canveo support');
      setSubmitting(false);
    } else {

      // Now send to server side for submission / validation
      let orgurl = shortName.replace(/[^\w\s]/gi, '').replace(/\s+/g, '-').replace(".","").toLowerCase(); // strip special characters, then replace space with dash, then eliminate dots
      let clientType = custType === 'Business' || discountCode !== null ? 'custB' : 'cust'
      let modules = ['Sales', 'Procurement', 'Partnerships', 'People', 'Corporate']

      axios.post('/auth/signup', {
        uid: !['oauth','cpty'].includes(props.action) ? null : props.user._id,
        orgid: !['cpty'].includes(props.action) ? null : props.user.orgID,
        firstName: firstName,
        lastName: lastName,
        title: title,
        email: email,
        password: props.action === 'oauth' ? generateRandomString(20) : password,
        newPassword: ['cpty'].includes(props.action) && props.user !== undefined && props.user.password !== undefined && props.user.password.length <= 20 ? 
          true : false,
        photoURL: photoURL,
        orgurl: orgurl,
        clientType: clientType,
        shortName: shortName,
        legalName: legalName,
        address: address,
        taxids: taxids,
        logoURL: logoURL,
        creationBy: 'self',
        creationDate: creationDate,
        agrIDs: [],
        modules: modules,
        oauth: props.action === 'oauth',
        isCpty: props.action === 'cpty',
      })
      .then(function(res) {
        if(res.data.success) {

          if(props.action === 'cpty') {
            // CPTY flow: Create Free Trial Account
            let subItems = []

            // The conversion
            props.users.filter((u) => u.active).forEach((u) => { // convert other users to 'Business' or Delete
              if(users.includes(u)) { props.updateUserRole(u._id, u.orgID, 'Business', 'sales', false, u.title, true) } // Update user role
              else if(u._id !== props.user._id) { props.deleteUser(u._id, props.user._id, u.orgID, true) } // Delete the user
            })

            // convert entities
            let lastUpdateDate = new Date().toISOString();
            props.subs.forEach((s) => {
              axios.put('/entity/protect/convert/' + s._id, {
                orgID: res.data.orgid, // Change it’s “orgID” field to the current value for “parentOrgID”
                parentOrgID: '', // Delete the “parentOrgID” field
                entityType: 'Subsidiary', // Update entityType to “Subsidiary”
                legalName:  s.legalName,
                logoURL: logoURL,
                lastUpdateBy: props.user._id,
                lastUpdateDate: lastUpdateDate,
              })                
            })

            // Create subscriptionItem for additional users
            if(custPricing.usersBase < (users.length + 1)) {
              let additionalUsers = (users.length + 1) - custPricing.usersBase
              subItems.push({
                price: custPricing.stripeuid,
                quantity: additionalUsers,
              })
            }

            subItems.push({
              price: custPricing.stripeid,
              quantity: 1,
            })

            createSubscription({
              customerId: res.data.stripecusid,
              paymentMethodId: null,
              orgID: res.data.orgid,
              email: email, 
              firstName: firstName, 
              uid: res.data.uid,
              subItems: subItems,
              isCpty: true,
              withTax: address !== undefined && address !== null && address.country === 'GB',
            });


          } else if(discountCode !== null) {
            // APPSUMO flow: Create account based on discountCode
            
            axios.put('/discountcode/redeem', {
              code: discountCode,
              redeemDate: new Date().toISOString(),
              redeemBy: res.data.uid,
            })
            .then((response) => { 

              props.createEntity(
                res.data.orgid, // orgID
                '', // parentOrgID 
                'Subsidiary', // entityType
                'Company', // entityCategory 
                shortName, 
                legalName, 
                [address],
                logoURL, 
                {}, // primaryContact 
                {}, // signatory 
                null, // sourceData
                res.data.uid, // creationBy
                true // faf
              )

              axios.get('/mail/signup/' + email + '/' + firstName)
              
              props.createNotification(
                'Welcome new User', // notificationType
                'Welcome to Canveo', // title 
                firstName + ', we\'re excited to have you on board', // description
                '/core/help/welcome', // linkTo
                false, // isRead
                res.data.uid, // uid
              )
              // send to success or logout for OAUTH
              setSubmitting(false);

              if(['oauth'].includes(props.action)) {
                props.fetchLoggedInUser()
              } 
              history.push('/signup/done');
              
            }).catch((err) => {

              setSubmitting(false);
              setFormError('Unable to redeem discount code');

            })

          } else {
            // NEW SIGNUP flow: create and attach payment method
            stripe
            .createPaymentMethod({
              type: 'card',
              card: cardElement,
              billing_details: {
                name: legalName,
              },
            })
            .then((result) => {
              if (result.error) {
                setSubmitting(false);
                setFormError('Error creating the payment method');
              } else {
  
                let subItems = []
  
                props.createEntity(
                  res.data.orgid, // orgID
                  '', // parentOrgID 
                  'Subsidiary', // entityType
                  'Company', // entityCategory 
                  shortName, 
                  legalName, 
                  [address],
                  logoURL, 
                  {}, // primaryContact 
                  {}, // signatory 
                  null, // sourceData
                  props.user._id, // creationBy
                  props.action === 'oauth' ? false : true // faf
                )
  
                subItems.push({
                  price: custPricing.stripeid,
                  quantity: 1,
                })
  
                createSubscription({
                  customerId: res.data.stripecusid,
                  paymentMethodId: result.paymentMethod.id,
                  orgID: res.data.orgid,
                  email: email, 
                  firstName: firstName, 
                  uid: res.data.uid,
                  subItems: subItems,
                  isCpty: false,
                  withTax: address !== undefined && address !== null && address.country === 'GB',
                });
  
              }
            });
          }
        } else {
          setSubmitting(false);
          setFormError('Something went wrong while submitting, please try again');
        }          
      }).catch(function(err) {
        console.log(err)
        setFormError('Unable to register, the email may already be in use');
        setSubmitting(false);
      })
    }
  }  

  function createSubscription({ customerId, paymentMethodId, orgID, email, firstName, uid, subItems, isCpty, withTax }) {

    //let coupon = props.action === 'cpty' ? 'OyQ9zlC9' : null // Cpty Discount: hFzAJnGP || Trial Period: OyQ9zlC9
    let date = new Date()
    let trialEnd = props.action === 'cpty' ? Math.round(new Date(date.setMonth(date.getMonth()+1)).getTime() / 1000) : null

    return (
      axios.post('/billing/protect/createsubscription', {
        customerId: customerId,
        paymentMethodId: paymentMethodId, // either [null] (cpty flow) or [id] (new signup)
        subItems: subItems,
        trialEnd: trialEnd,
        taxRates: withTax ? ['txr_1IOrbtGTo1OR8YkMghiIxPjL'] : [],
      })
      .then((response) => { 
        return response.data.data; 
      })
      .then((result) => { // If the card is declined, display an error to the user.
        if (result.error) { throw result; } // The card had an error when trying to attach it to a customer.
        return result;
      })
      .then((result) => { // Normalize the result to contain the object returned by Stripe && Add the additional details we need.
        return {
          paymentMethodId: paymentMethodId,
          subscription: result,
        };
      })
      .then(handlePaymentThatRequiresCustomerAction) // Require a customer to be on session to complete the payment process
      .then(handleRequiresPaymentMethod) // If attaching this card to a Customer object succeeds, but attempts to charge the customer fail
      .then(function(result) {

        if (result.subscription.status === 'active' || props.action === 'cpty') {

          axios.put('/billing/protect/updateorgwithstripe', {
            customerId: customerId,
            orgID: orgID,
          })
          .then(function(res) { // Update the ORG with Stripe details.

            axios.get('/mail/signup/' + email + '/' + firstName)
            .then(function(resmail) {
              props.createNotification(
                'Welcome new User', // notificationType
                'Welcome to Canveo', // title 
                firstName + ', we\'re excited to have you on board', // description
                '/core/help/welcome', // linkTo
                false, // isRead
                uid, // uid
              )
              // send to success or logout for OAUTH
              setSubmitting(false);

              if(['cpty', 'oauth'].includes(props.action)) {
                props.fetchLoggedInUser()
              } 
              history.push('/signup/done');
              // Call your backend to grant access to your service based on `result.subscription.items.data[0].price.product` the customer subscribed to.        
            })
            .catch(function(err) { // send to success - though there was an error while sending the email
              setSubmitting(false);

              if(['cpty', 'oauth'].includes(props.action)) {
                props.fetchLoggedInUser()
              } 
              history.push('/signup/done');
            })            

          })
          .catch(function(err){ 
            setFormError("Unable to couple the subscription with your account");
            setSubmitting(false);
          })          
        }
      })       
      .catch((error) => { // An error has happened. Display the failure to the user here.
        console.log(error)
        setFormError("Unable to create the subscription with our billing provider, please contact Canveo Support");
        setSubmitting(false);
      })
    );
  }

  function handlePaymentThatRequiresCustomerAction({ subscription, invoice, priceId, paymentMethodId, isRetry }) {
    if(props.action === 'cpty'){ // Cpty flow - no need for a payment method action
      return { subscription, priceId, paymentMethodId };
    } else if (subscription && subscription.status === 'active') { // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }
  
    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = (invoice !== undefined && invoice !== null ? invoice.payment_intent : subscription.latest_invoice.payment_intent);
  
    if (paymentIntent.status === 'requires_action' || (isRetry === true && paymentIntent.status === 'requires_payment_method')) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, { payment_method: paymentMethodId })
        .then((result) => { // Start code flow to handle updating the payment details.
          if (result.error) { // Display error message in your UI. The card was declined (i.e. insufficient funds, card has expired, etc).
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') { // Show a success message to your customer.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        })
        .catch((error) => {
          setFormError("Unable to create the subscription with our billing provider: ", error);
          setSubmitting(false);
        });
    } else { return { subscription, priceId, paymentMethodId }; } // No customer action needed.
  }

  function handleRequiresPaymentMethod({ subscription, paymentMethodId, priceId }) {
    if (props.action === 'cpty') { return { subscription, priceId, paymentMethodId }; } // Cpty flow - no need for payment method actions
    else if (subscription.status === 'active') { return { subscription, priceId, paymentMethodId }; } // subscription is active, no customer actions required. 
    else if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
      // Using localStorage to manage the state of the retry here
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem('latestInvoicePaymentIntentStatus', subscription.latest_invoice.payment_intent.status );
      throw new Error("Your card was declined")
      //throw { error: { message: 'Your card was declined.' } };
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  const callbackUserForm = (childData) => {

    axios.get('/user/usercheck/' + childData.email.toLowerCase())
    .then(function(res) { // User found, craft warning message
      if(res.data.data !== undefined && res.data.data !== null && res.data.data.role === 'Counterparty') {
        setFormError("This user is already setup as a counterparty user, please contact canveo support for help.")
      } else if (res.data.data !== undefined && res.data.data !== null) {
        setFormError("This user is setup for another Canveo subscriber, please contact canveo support for help.")
      } else {
        setFormError('');
        setFormData({ ...formData, 
          firstName: childData.firstName, 
          lastName: childData.lastName, 
          title: childData.title,
          email: childData.email.toLowerCase(),
          password: childData.password,
          userDataDone: true });
        setPage('company')
      }
    }).catch(function(err){ 
      setFormError("Something went wrong will defining the user, please try again or contact canveo support if the issue persists");
    })
  }

  const callbackEntityForm = (childData) => {

    if(!props.network.some((n) => n.shortName.trim().toLowerCase() === childData.shortName.trim().toLowerCase()) &&
    !props.network.some((n) => n.legalName.trim().toLowerCase() === childData.legalName.trim().toLowerCase())) {
      setFormError('');
      let addr =  {
        streetAndNo: childData.streetAndNo !== undefined && childData.streetAndNo !== null ? childData.streetAndNo.trim() : '',
        address2: childData.address2 !== undefined && childData.address2 !== null ? childData.address2.trim() : '',
        zip: childData.zip !== undefined && childData.zip !== null ? childData.zip.trim() : '',
        city: childData.city !== undefined && childData.city !== null ? childData.city.trim() : '',
        country: childData.country !== undefined && childData.country !== null ? childData.country.trim() : '',
        state: childData.state !== undefined && childData.state !== null ? childData.state.trim() : '',
        addressType: 'billto',
      }
  
      setFormData({ ...formData, 
        shortName: childData.shortName !== undefined && childData.shortName !== null ? childData.shortName.trim() : '', 
        legalName: childData.legalName !== undefined && childData.legalName !== null ? childData.legalName.trim() : '',
        address: addr,
        taxids: childData.taxids,
        companyDataDone: true });
      setPage('logo')
    } else {
      setFormError('This company and/or legal name already exists, please contact Canveo Support')
    }
  }

  const callbackLogoUploadForm = (childData) => {
    setFormData({ ...formData, 
      logoURL: childData });
    setPage('picture')
  }

  const callbackAvatarUploadForm = (childData) => {
    setFormData({ ...formData, 
      photoURL: childData });
    setPage(props.action === 'cpty' && props.users.filter((u) => u.active).length > 1 ? 'users' : 'package')
  }

  const handleToggleUser = (u) => () => {
    const currentIndex = formData.users.indexOf(u);
    const newChecked = [...formData.users];

    if (currentIndex === -1) {
      newChecked.push(u);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFormData({ ...formData, 
      users: newChecked });
  };

  const handleUsersSelectDone = () => {
    setFormData({ ...formData, 
      usersDone: true });
    setPage('package')
  }

  return(
    <div className={classes.root}>
      <Header 
        exitOnly={true}
        exitClick={e => window.location = "https://getcanveo.com/"}
        module="open"
      />

      <DrawerCore 
        pageType='signup'     
        page={page}   
        routeToPage={routeToPage}
        donePersonal={formData.userDataDone}
        doneCompany={formData.companyDataDone}
        doneLogo={formData.logoURL !== null && formData.logoURL !== defaultLogo && formData.logoURL !== ''}
        donePicture={formData.photoURL !== null && formData.photoURL !== defaultLogo && formData.photoURL !== ''}
        withUsers={props.action === 'cpty' && props.users.filter((u) => u.active).length > 1}
        doneUsers={formData.usersDone}
        donePackage={custPricing !== null || (discountCode !== null && ['package','payment'].includes(page))}
      />


      <Container maxWidth="lg" className={classes.content}>
      <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerGrid}>

        {
        page === 'personal' ?

          <Grid item>
            <Box mt={0}>
              <Typography align="center" variant="h3" className={classes.bold}>
                {['cpty'].includes(props.action) ? "Complete" : "Enter"} your personal details<span className={classes.secondary}>.</span>
              </Typography>
            </Box>
            
            {props.action !== 'cpty' ? 
            <Box mt={5} align="center">
                <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                    <Grid item>
                        <Button 
                          disabled={props.action === 'oauth' && props.user.provider !== 'google'} 
                          variant="outlined" 
                          //href="http://localhost:5000/auth/google"
                          //href="https://test.getcanveo.com/auth/google"
                          href="https://app.getcanveo.com/auth/google"
                          className={props.action === 'oauth' && props.user.provider === 'google' ? classes.border : ''}>
                            <img src={Google} className={classes.signupIcon} alt="Sign up with Google" />
                            Sign up with Google
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button 
                          disabled={props.action === 'oauth' && props.user.provider !== 'slack'} 
                          variant="outlined" 
                          //href="http://localhost:5000/auth/slack"
                          //href="https://test.getcanveo.com/auth/slack"
                          href="https://app.getcanveo.com/auth/slack"
                          className={props.action === 'oauth' && props.user.provider === 'slack' ? classes.border : ''}>
                            <img src={Slack} className={classes.signupIcon} alt="Sign up with Slack" />
                            Sign up with Slack
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            : '' }

            <Box mt={props.action !== 'cpty' ? 4 : 5} >
              { formError !== '' ? <Typography variant="subtitle1" align="center" color="error">{formError}</Typography> : '' }               
              <Typography align="center" variant="subtitle1">
                {props.isLoading ? 
                  "Loading ..."
                :props.action === 'oauth' ?
                  "Provide your corporate title:"
                :props.action === 'cpty' ?
                  "Verify your personal details:"
                :
                  "Or sign up using your email address and password:"
                }
              </Typography>
            </Box>

            <Box mt={3} mb={10}>
              
                {props.isLoading ?
                <Box align="center">
                <CircularProgress size={34} disableShrink />
                </Box>
                :
                <UserForm 
                    parentCallback={callbackUserForm} 
                    initialValues={formData} 
                    signup={true} 
                    oauth={props.action === 'oauth'}
                    hidePassword={['cpty'].includes(props.action) && props.user !== undefined && props.user.password !== undefined &&
                      props.user.password.length > 20 ? 'hide' : 
                      ['cpty'].includes(props.action) && props.user !== undefined && props.user.password !== undefined ? 'clear' : null}
                />
                }

            </Box>

          </Grid>

        :page === 'company' ?

          <Grid item>
            <Box mt={0}>
              <Typography align="center" variant="h3" className={classes.bold}>
                {['cpty'].includes(props.action) ? "Complete" : "Enter"} your company details<span className={classes.secondary}>.</span>
              </Typography>
              { formError !== '' ? <Box mt={3}><Typography variant="subtitle1" align="center" color="error">{formError}</Typography></Box> : '' }
            </Box>

            <Box mt={5} mb={10}>
              
            <EntityForm 
              submitting={false}
              parentCallback={callbackEntityForm}
              type={'signup'}
              hideRadioCategory={true}
              initialValues={{
                entityCategory: 'Company',
                shortName: formData.shortName, // formData.shortName, 
                legalName: formData.legalName, // formData.legalName, 
                streetAndNo: formData.address !== undefined && formData.address !== null && formData.address.streetAndNo !== undefined ?
                  formData.address.streetAndNo : '', // formData.streetAndNo,
                address2: formData.address !== undefined && formData.address !== null && formData.address.address2 !== undefined ?
                  formData.address.address2 : '', // formData.address2,
                city:  formData.address !== undefined && formData.address !== null && formData.address.city !== undefined ?
                  formData.address.city : '', // formData.city,
                zip:  formData.address !== undefined && formData.address !== null && formData.address.zip !== undefined ?
                  formData.address.zip : '', // formData.zip,
                state:  formData.address !== undefined && formData.address !== null && formData.address.state !== undefined ?
                  formData.address.state : '', // formData.state,
                country:  formData.address !== undefined && formData.address !== null && formData.address.country !== undefined ?
                  formData.address.country : '', // formData.country
                taxids: formData.taxids,
              }}
              />
            </Box>

          </Grid>

        :page === 'logo' ?

          <Grid item>
            <Box mt={0}>
              <Typography align="center" variant="h3" className={classes.bold}>
                Upload your company logo<span className={classes.secondary}>.</span>
              </Typography>
            </Box>
              
              { formData.logoURL !== defaultLogo && formData.logoURL !== null && formData.logoURL !== undefined ?
              (
                <div>
                  <Box mt={2} mb={2} align="center">                
                    <UploadImg 
                      type="logo" 
                      parentCallback={callbackLogoUploadForm}
                      initialImg={formData.logoURL} />
                  </Box>
                  <Box mb={7} align="center">
                    <Button variant="outlined" color="primary" disableElevation 
                      onClick={e => setPage('picture')} >
                      Next
                    </Button>
                  </Box>
                </div>
              ) : 
              (
                <div>
                  <Box mt={2} mb={2} align="center">                
                    <UploadImg 
                      type="logo" 
                      parentCallback={callbackLogoUploadForm}
                      initialImg={null} />
                  </Box>
                  <Box mb={7} align="center">
                    <Button variant="outlined" color="primary" disableElevation 
                      onClick={e => setPage('picture')} >
                      Skip this step
                    </Button>
                  </Box>
                </div>
              )
              }
          </Grid>

        :page === 'picture' ?

          <Grid item>
            <Box mt={0}>
              <Typography align="center" variant="h3" className={classes.bold}>
                Upload your profile picture<span className={classes.secondary}>.</span>
              </Typography>
            </Box>

              { formData.photoURL !== '' && formData.photoURL !== null && formData.photoURL !== undefined ?
              (
                <div>
                  <Box mt={2} mb={2} align="center">                
                    <UploadImg 
                      type="avatar" 
                      parentCallback={callbackAvatarUploadForm} 
                      initialImg={formData.photoURL}
                    />
                  </Box>
                  <Box mb={7} align="center">
                    <Button variant="outlined" color="primary" disableElevation 
                      onClick={e => setPage(props.action === 'cpty' && props.users.filter((u) => u.active).length > 1 ? 'users' : 'package')} >
                      Continue
                    </Button>
                  </Box>
                </div>
              ) : 
              (
                <div>
                  <Box mt={2} mb={2} align="center">                
                    <UploadImg 
                      type="avatar" 
                      parentCallback={callbackAvatarUploadForm} 
                      initialImg={null}
                    />
                  </Box>
                  <Box mb={7} align="center">
                    <Button variant="outlined" color="primary" disableElevation 
                      onClick={e => setPage(props.action === 'cpty' && props.users.filter((u) => u.active).length > 1 ? 'users' : 'package')} >
                      Skip this step
                    </Button>
                  </Box>
                </div>
              )
              }
          </Grid>

        :page === 'users' ?

          <Grid item>
            <Box mt={0}>
              <Typography align="center" variant="h3" className={classes.bold}>
                Select users to convert<span className={classes.secondary}>.</span>
              </Typography>
            </Box>        
            <Box mt={4} align="center">
              <Box className={classes.boxResizing} align="left">
              <List >
                {props.users.filter((u) => u.active).filter((u) => u._id !== props.user._id).map((u) => {
                  const labelId = `checkbox-list-secondary-label-${u._id}`;
                  return (
                    <ListItem key={u._id} button>
                      <ListItemAvatar>
                        <Avatar src={u.photoURL}>{u.firstName.charAt(0).toUpperCase()}{u.lastName.charAt(0).toUpperCase()}</Avatar>
                      </ListItemAvatar>
                      <ListItemText id={labelId} primary={u.displayName} />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          onChange={handleToggleUser(u)}
                          checked={formData.users.indexOf(u) !== -1}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>)
                })}
              </List>
              </Box>
              <Box className={classes.boxResizing} mt={3}>
                <Button onClick={e => handleUsersSelectDone()} fullWidth
                color="primary" variant="contained" disableElevation>Next</Button>

              </Box>
            </Box>



          </Grid>

        :page === 'package' ?

          <Grid item>
            <Box mt={0}>
              <Typography align="center" variant="h3" className={classes.bold}>
                Select your Package<span className={classes.secondary}>.</span>
              </Typography>
            </Box>

            <Box mt={4} mb={0}>

            {discountCode !== null ?
            <Box align="center" mt={2} mb={1}>
              <Box mb={1}>
                <Typography>
                  You have purchased a lifetime license for our Business package via <a href="https://www.appsumo.com/" target='_blank' rel="noopener noreferrer" className="seclink">AppSumo <FontAwesomeIcon icon={faExternalLinkAlt} style={{fontSize: '12px'}} /></a>.
                </Typography>
              </Box>

              <img src="https://storage.googleapis.com/cvimages/appsumo.png" alt="appsumo" width="280" />

              <Box mt={2}>
                <Button variant="contained" color="primary" disableElevation onClick={discountSelectPackage}
                style={{width: '300px'}}>That's right, continue!</Button>
              </Box>
            </Box>
            :  
            <PricingContent
            defaultUsers={formData.users.length + 1}
            sliderValue={formData.users.length + 1}
            fromCompanyScreen={false}
            fromSignupScreen={true}
            //currentTier={props.currentTier}
            ccy={getCcyByCountry(formData.address !== undefined && formData.address.country !== undefined && formData.address.country !== null && formData.address.country.length === 2 ? formData.address.country : navigator.language.slice(-2)).symbol}
            handleSelection={handleCustTypeChange}
            />
            }
            </Box>

            {/*
            <Box align="center" mt={3}>
              <Button className={classes.homeHeadButton} onClick={handlePricingOpen} color="primary" variant="text">
                View Pricing Details
              </Button>
            </Box>

            <Box align="center" mt={3} mb={5}>
            <StyledToggleButtonGroup
              value={custType}
              exclusive={true}
              onChange={handleCustTypeChange}
              >
            */}
                {/*
                <ToggleButton className={classes.toggleBTall} value={"Starter"}
                disabled={props.action === 'cpty' && formData.users.length >= prices.filter((p) => p.custType === 'Starter')[0].maxUsers}>
                  <Grid container direction="column" className={classes.toggleGrid}>
                    <Grid item>
                      <Box mb={1}>
                        <Typography variant="h5">Starter</Typography>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Typography style={{marginBottom:'10px'}}>
                        <span className={props.action === 'cpty' && formData.users.length >= prices.filter((p) => p.custType === 'Starter')[0].maxUsers ? classes.greyPrice : classes.secondaryPrice}>
                          {getCcyByCountry(formData.address !== undefined && formData.address.country !== undefined && formData.address.country !== null && formData.address.country.length === 2 ? formData.address.country : navigator.language.slice(-2)).symbol}
                          {(prices.filter((p) => p.custType === 'Starter')[0].currencies.filter((c) => c.ccy === (getCcyByCountry(formData.address !== undefined && formData.address.country !== undefined && formData.address.country !== null && formData.address.country.length === 2 ? formData.address.country : navigator.language.slice(-2)).ccy))[0].amount +
                            (prices.filter((p) => p.custType === 'Starter')[0].currencies.filter((c) => c.ccy === (getCcyByCountry(formData.address !== undefined && formData.address.country !== undefined && formData.address.country !== null && formData.address.country.length === 2 ? formData.address.country : navigator.language.slice(-2)).ccy))[0].addUserAmt * (formData.users.length >= prices.filter((p) => p.custType === 'Starter')[0].maxUsers ? 0 : formData.users.length)))
                          }
                        </span>
                        <span className={classes.priceCycle}>p.m.</span>
                      </Typography>

                      <Typography align="center" variant="body2">Includes {props.action === 'cpty' && formData.users.length < prices.filter((p) => p.custType === 'Starter')[0].maxUsers ?
                        formData.users.length + 1 :
                        prices.filter((p) => p.custType === 'Starter')[0].usersBase} user{formData.users.length < prices.filter((p) => p.custType === 'Starter')[0].maxUsers && formData.users.length === 1 ? "s" : ""}</Typography>
                      <Typography align="center" variant="body2">Per extra user: {getCcyByCountry(formData.address !== undefined && formData.address.country !== undefined && formData.address.country !== null && formData.address.country.length === 2 ? formData.address.country : navigator.language.slice(-2)).symbol}{prices.filter((p) => p.custType === 'Starter')[0].currencies[0].addUserAmt}</Typography>
                      <Typography align="center" variant="body2">Max {prices.filter((p) => p.custType === 'Starter')[0].maxUsers} users</Typography>
                      {props.action === 'cpty' ?
                      <Typography align="center" variant="subtitle2" className={classes.freetrial}>First month free,<br/>cancel anytime</Typography>
                      : ''}
                    </Grid>
                  </Grid>
                </ToggleButton>
                */}
                {/*
                <ToggleButton className={classes.toggleBTall} value={"Business"} 
                disabled={props.action === 'cpty' && formData.users.length >= prices.filter((p) => p.custType === 'Business')[0].maxUsers}>
                  <Grid container direction="column" className={classes.toggleGrid}>
                    <Grid item>
                      <Box mb={1}>
                        <Typography variant="h5">Business</Typography>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Typography style={{marginBottom:'10px'}}>
                        <span className={props.action === 'cpty' && formData.users.length >= prices.filter((p) => p.custType === 'Business')[0].maxUsers ? classes.greyPrice : classes.secondaryPrice}>
                          {getCcyByCountry(formData.address !== undefined && formData.address.country !== undefined && formData.address.country !== null && formData.address.country.length === 2 ? formData.address.country : navigator.language.slice(-2)).symbol}
                          {(prices.filter((p) => p.custType === 'Business')[0].currencies.filter((c) => c.ccy === (getCcyByCountry(formData.address !== undefined && formData.address.country !== undefined && formData.address.country !== null && formData.address.country.length === 2 ? formData.address.country : navigator.language.slice(-2)).ccy))[0].amount +
                            (prices.filter((p) => p.custType === 'Business')[0].currencies.filter((c) => c.ccy === (getCcyByCountry(formData.address !== undefined && formData.address.country !== undefined && formData.address.country !== null && formData.address.country.length === 2 ? formData.address.country : navigator.language.slice(-2)).ccy))[0].addUserAmt * (formData.users.length >= prices.filter((p) => p.custType === 'Business')[0].maxUsers ? 0 : (Math.max(0, formData.users.length + 1 - prices.filter((p) => p.custType === 'Business')[0].usersBase)))))
                            }
                        </span>
                        <span className={classes.priceCycle}>p.m.</span>
                      </Typography>

                      <Typography align="center" variant="body2">Includes {props.action === 'cpty' && formData.users.length < prices.filter((p) => p.custType === 'Business')[0].maxUsers && formData.users.length >= prices.filter((p) => p.custType === 'Starter')[0].maxUsers ?
                        formData.users.length + 1 :
                        prices.filter((p) => p.custType === 'Business')[0].usersBase} users</Typography>
                      <Typography align="center" variant="body2">Per extra user: {getCcyByCountry(formData.address !== undefined && formData.address.country !== undefined && formData.address.country !== null && formData.address.country.length === 2 ? formData.address.country : navigator.language.slice(-2)).symbol}{prices.filter((p) => p.custType === 'Business')[0].currencies[0].addUserAmt}</Typography>
                      <Typography align="center" variant="body2">Max {prices.filter((p) => p.custType === 'Business')[0].maxUsers} users</Typography>
                      {props.action === 'cpty' ?
                      <Typography align="center" variant="subtitle2" className={classes.freetrial}>First month free,<br/>cancel anytime</Typography>
                      : ''}
                    </Grid>
                  </Grid>
                </ToggleButton>
                <ToggleButton className={classes.toggleBTall} value={"Business Premium"}
                disabled={props.action === 'cpty' && formData.users.length >= prices.filter((p) => p.custType === 'Business Premium')[0].maxUsers}>
                  <Grid container direction="column" className={classes.toggleGrid}>
                    <Grid item>
                      <Box mb={1}>
                        <Typography variant="h5">Premium</Typography>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Typography style={{marginBottom:'10px'}}>
                        <span className={props.action === 'cpty' && formData.users.length >= prices.filter((p) => p.custType === 'Business Premium')[0].maxUsers ? classes.greyPrice : classes.secondaryPrice}>
                          {getCcyByCountry(formData.address !== undefined && formData.address.country !== undefined && formData.address.country !== null && formData.address.country.length === 2 ? formData.address.country : navigator.language.slice(-2)).symbol}
                          {(prices.filter((p) => p.custType === 'Business Premium')[0].currencies.filter((c) => c.ccy === (getCcyByCountry(formData.address !== undefined && formData.address.country !== undefined && formData.address.country !== null && formData.address.country.length === 2 ? formData.address.country : navigator.language.slice(-2)).ccy))[0].amount +
                            (prices.filter((p) => p.custType === 'Business Premium')[0].currencies.filter((c) => c.ccy === (getCcyByCountry(formData.address !== undefined && formData.address.country !== undefined && formData.address.country !== null && formData.address.country.length === 2 ? formData.address.country : navigator.language.slice(-2)).ccy))[0].addUserAmt * (formData.users.length >= prices.filter((p) => p.custType === 'Business Premium')[0].maxUsers ? 0 : (Math.max(0, formData.users.length + 1 - prices.filter((p) => p.custType === 'Business Premium')[0].usersBase)))))
                            }
                        </span>
                        <span className={classes.priceCycle}>p.m.</span>
                      </Typography>

                      <Typography align="center" variant="body2">Includes {props.action === 'cpty' && formData.users.length < prices.filter((p) => p.custType === 'Business Premium')[0].maxUsers && formData.users.length >= prices.filter((p) => p.custType === 'Business')[0].maxUsers ?
                        formData.users.length + 1 :
                        prices.filter((p) => p.custType === 'Business Premium')[0].usersBase} users</Typography>
                      <Typography align="center" variant="body2">Per extra user: {getCcyByCountry(formData.address !== undefined && formData.address.country !== undefined && formData.address.country !== null && formData.address.country.length === 2 ? formData.address.country : navigator.language.slice(-2)).symbol}{prices.filter((p) => p.custType === 'Business Premium')[0].currencies[0].addUserAmt}</Typography>
                      <Typography align="center" variant="body2">Max {prices.filter((p) => p.custType === 'Business Premium')[0].maxUsers} users</Typography>
                      {props.action === 'cpty' ?
                      <Typography align="center" variant="subtitle2" className={classes.freetrial}>First month free,<br/>cancel anytime</Typography>
                      : ''}
                    </Grid>
                  </Grid>
                </ToggleButton>
            </StyledToggleButtonGroup>
            </Box>
            */}
 
            {/*
            <Box align="center" mt={4}>
              <Box mb={2}>
                <Typography variant="subtitle1" className={classes.bold}>
                  {custType === 'Starter' ? "Select your Module" : "All Modules are included"}
                </Typography>
              </Box>
              <StyledToggleButtonGroup
              value={custType === 'Starter' ? moduleSelected : ['sales', 'procurement', 'partnerships', 'people', 'corporate']}
              exclusive={custType === 'Starter' ? true : false}
              onChange={handleChangeModuleSelected}
              >
                <ToggleButton value={"sales"} className={classes.toggleBSM}>
                  <Grid container direction="column" className={classes.toggleGridSM}>
                    <Grid item>
                      <FontAwesomeIcon icon={faHandshake} style={{fontSize: '17px'}} />
                    </Grid>
                    <Grid item>
                      Sales
                    </Grid>
                  </Grid>
                </ToggleButton>
                <ToggleButton value={"procurement"} className={classes.toggleBSM}>
                  <Grid container direction="column" className={classes.toggleGridSM}>
                    <Grid item>
                      <FontAwesomeIcon icon={faShoppingCart} style={{fontSize: '17px'}} />
                    </Grid>
                    <Grid item>
                      Procurement
                    </Grid>
                  </Grid>
                </ToggleButton>
                <ToggleButton value={"partnerships"} className={classes.toggleBSM}>
                  <Grid container direction="column" className={classes.toggleGridSM}>
                    <Grid item>
                      <FontAwesomeIcon icon={faUserFriends} style={{fontSize: '17px'}} />
                    </Grid>
                    <Grid item>
                      Partnerships
                    </Grid>
                  </Grid>
                </ToggleButton>
                <ToggleButton value={"people"} className={classes.toggleBSM}>
                  <Grid container direction="column" className={classes.toggleGridSM}>
                    <Grid item>
                      <FontAwesomeIcon icon={faUser} style={{fontSize: '17px'}} />
                    </Grid>
                    <Grid item>
                      People
                    </Grid>
                  </Grid>
                </ToggleButton>
                <ToggleButton value={"corporate"} className={classes.toggleBSM}>
                  <Grid container direction="column" className={classes.toggleGridSM}>
                    <Grid item>
                      <FontAwesomeIcon icon={faBuilding} style={{fontSize: '17px'}} />
                    </Grid>
                    <Grid item>
                      Corporate
                    </Grid>
                  </Grid>
                </ToggleButton>
              </StyledToggleButtonGroup>
            </Box>
            */}

            {/*
            <Box mb={7} mt={cardComplete ? 3 : 5} align="center">
              <Button variant="contained" color="primary" disableElevation className={classes.boxResizing}
                onClick={handlePackageConfig}>
                Next
              </Button>
            </Box>
            */}
          </Grid>

        :page === 'payment' ?

          <Grid item>
            <Box mt={0}>
              <Typography variant="h3" align="center" className={classes.bold}>
                Review and {props.action === 'cpty' ? "Confirm" : "Provide Payment Detail"}<span className={classes.secondary}>.</span>
              </Typography>
              { formError !== '' ? <Typography variant="subtitle1" align="center" color="error">{formError}</Typography> : '' }
            </Box>

            <Box mt={5} mb={5}>
              <Grid item container direction="row" justify="center" alignItems="center">
                <Grid item xs={12} sm={10} md={5} lg={5}>
                  <Grid item container direction="column" justify="center" alignItems="center" spacing={2}>
                    <Grid item>
                      <Box height={100}>
                        <Avatar alt="User name" src={formData.photoURL}
                          className={classes.avatar} />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box minWidth={240}>
                        <Grid item container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                          <Grid item>
                            <FontAwesomeIcon icon={faUserCircle} className={classes.greyIcon} />
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle2">
                              {(formData.firstName !== '' && formData.lastName !== '' && formData.title !== '') ? 
                                (formData.firstName + ' ' + formData.lastName + ', ' + formData.title ) : 
                                'Not yet provided'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box minWidth={240}>
                        <Grid item container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                          <Grid item>
                            <FontAwesomeIcon icon={faEnvelope} className={classes.greyIcon} />
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle2">
                              {formData.email !== '' ? formData.email : 'Not yet provided'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item style={{height: '44px'}}>
                      &nbsp;&nbsp;
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={10} md={5} lg={5}>
                  <Grid item container direction="column" justify="center" alignItems="center" spacing={2}>
                    <Grid item>
                      <Box height={100}>
                        <Avatar alt="User name" src={formData.logoURL}
                          className={classes.orgAvatar} />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box minWidth={240}>
                        <Grid item container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                          <Grid item>
                            <FontAwesomeIcon icon={faBuilding} className={classes.greyIcon} />
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle2">
                              {formData.shortName !== '' ? formData.shortName : 'Not yet provided'}&nbsp;&nbsp;
                              {formData.address !== undefined && formData.address.country !== undefined && formData.address.country !== null && formData.address.country.length === 2 ? <span>{countryToFlag(formData.address.country)}</span> : ''}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box minWidth={240}>
                        <Grid item container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                          <Grid item>
                            <FontAwesomeIcon icon={faLandmark} className={classes.greyIcon} />
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle2">
                              {formData.legalName !== '' ? trunc(formData.legalName, 25) : 'Not yet provided'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box minWidth={240}>
                        <Grid item container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                          <Grid item>
                            <FontAwesomeIcon icon={faTag} className={classes.greyIcon} />
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle2">
                              {discountCode !== null ? "AppSumo Deal":
                               custPricing !== null ? custPricing.label : 'Not yet provided'}
                              {/*custType !== undefined && custType !== null && custType === 'Starter'
                               && moduleSelected !== undefined && moduleSelected !== null ? (' - ' + moduleSelected[0].toUpperCase() + moduleSelected.slice(1).toLowerCase()) : ''*/}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            {props.action !== 'cpty' ?
            <Box mt={discountCode !== null ? 6 : 4} align="center">
              {discountCode !== null ?
              <>
              <Typography>Payment completed via AppSumo&nbsp;&nbsp;<FontAwesomeIcon icon={faCheck} style={{color: theme.palette.success.main, fontSize: '18px'}} /></Typography>
              </>
              :
              <Box className={classes.boxResizing} align="center">
                <Box mb={1}>
                  <Typography variant="subtitle1" className={classes.bold}>Provide credit card details</Typography>
                </Box>
                <CardElement
                  onChange={(e) => handleCardInputChange(e)}
                  options={{
                    iconStyle: 'solid',
                    style: {
                      base: {
                        iconColor: theme.palette.secondary.main,
                        fontSize: '16px',
                        color: theme.palette.grey[800],
                        '::placeholder': {
                          color: theme.palette.grey[500],
                        },
                      },
                      invalid: {
                        color: theme.palette.error.main,
                      },
                    },
                  }}
                />
                {cardError !== null ?
                <Box align="center" mt={1}>
                <Typography variant="body2" align="left" className={classes.errorMsg}>{cardError}</Typography>
                </Box> : ''}
              </Box>
              }
            </Box>
            : '' }

            <Box mt={5} align="center">

              <Checkbox
                checked={agreed}
                onChange={e => setAgreed(!agreed)}
                name="agreeing"
                color="secondary"
              />
              I understand and agree to the <a href="/network/canveo/msa" target='_blank' className="seclink">Service Terms <FontAwesomeIcon icon={faExternalLinkAlt} style={{fontSize: '12px'}} /></a> and the <a href="/network/canveo/privacy-policy" target='_blank' className="seclink">Privacy Policy <FontAwesomeIcon icon={faExternalLinkAlt} style={{fontSize: '12px'}} /></a>

            </Box>
            <Box mt={4} align="center">
              <Button variant="contained" color="primary" 
                disableElevation 
                onClick={handleSubmit}
                disabled={submitting || formData.firstName === '' || formData.lastName === '' || formData.shortName === '' ||
                formData.title === '' || formData.email === '' || (formData.password === '' && props.action !== 'oauth') || formData.legalName === '' || 
                formData.address === undefined || formData.address === null || formData.address.country === undefined || 
                ((!stripe || !elements || !cardComplete) && props.action !== 'cpty' && discountCode === null) || !agreed}
                className={classes.boxResizing}>
                { submitting && (<CircularProgress size={24} /> )}
                { submitting ? 'Submitting...' : 'Looks good, submit' }
              </Button>
            </Box>

          </Grid>

        :page === 'done' ?

          <Grid container direction="column" justify="center" alignItems="center">

            <Grid item>
              <Box mt={5}>
              <FontAwesomeIcon icon={faThumbsUp} size="6x" className={classes.primaryIcon} />
              </Box>
            </Grid>
            <Grid item>
              <Box mt={5} >
                <Typography variant="h3" align="center" gutterBottom className={classes.bold}>
                  That's it, you're signed up!
                </Typography>              
              </Box>
              <Box mt={3}>
                <Typography variant="body1" align="center" className={classes.mbAndLight}>
                  {props.user !== undefined && props.user !== null && props.user._id !== undefined ? 
                  "Visit your Dashboard to get started with Canveo." :
                  "Proceed to login to get started with Canveo"}
                </Typography>
              </Box>
              <Box mt={4} mb={2} align="center">
                <Button component={RouterLink} to={"/core/sales/dashboard"}
                  disableElevation color="primary">
                  {props.user !== undefined && props.user !== null && props.user._id !== undefined ? 
                  "Go to dashboard" :
                  "Go to login"}
                </Button>
              </Box>
            </Grid>

          </Grid>

        : '' }


      </Grid>
      </Container>

    </div>
  );

}
export default SignUp