import React, { useEffect } from 'react';
import theme from '../theme/theme';
import { useHistory } from 'react-router-dom';
import { convertBlocksToPlainComment, convertPlainToBlocksComment, sendCommentEmailToUser, getRecipientModule } from '../utils'
import { AvatarInitials, CommentDisplayBox, InformationalTooltip, MentionEditor, TableEditable, NegoCardEditor } from '.';
import { Box, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, 
         IconButton, FormControlLabel, Grid, Switch, Slide, Typography,
         makeStyles,} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

const axios = require('axios');

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = (props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h6" style={{fontWeight: '700'}}>{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" style={{position: 'absolute', right: '8px', top: '10px'}} onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} style={{fontSize: '20px', padding: '0px 3px 0px 3px'}} />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

const DialogNegoComments = (props) => {

    const useStyles = makeStyles(theme => ({
        cancelButton: {
            marginRight: 'auto',
        },
        mlittle: {
            paddingTop: '4px',
            marginBottom: '4px',
        },
        switchGroup: {
            display: 'inline',
            marginLeft: '30px',
        },
        buttonActive: {
            [theme.breakpoints.only('xs')]: { 
                padding: '2px 5px 2px 5px',
                fontSize: '14px'
            },
            padding: '2px 18px 2px 18px',
            //borderRadius: '0px 10px 10px 0px',
            fontSize: '14px',
            fontWeight: '700',
            //color: theme.palette.grey[800],
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            '&:hover':{
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
                //backgroundColor: theme.palette.primary.light,
                //backgroundColor: '#eeeeee',
            }
        },
        button: {
            [theme.breakpoints.only('xs')]: { 
                padding: '2px 5px 2px 5px',
                fontSize: '14px'
            },
            padding: '2px 18px 2px 18px',
            //borderRadius: '5px',
            //borderRadius: '10px 0px 0px 10px',
            fontSize: '14px',
            fontWeight: '700',
            color: theme.palette.grey[600],
            //backgroundColor: 'transparent',
            backgroundColor: '#eeeeee',
            '&:hover':{
                color: theme.palette.grey[800],
                backgroundColor: '#dddddd',
            }
        },
        toggleText: {
            color: theme.palette.grey[700],
            marginLeft: '4px',
        },
        showingClause: {
            position:'sticky', 
            backgroundColor: '#ffffff',
            zIndex: 1000,
            top: '0px', 
            left: '0px', 
            paddingTop: '15px',
        },
        clauseBox: {
            fontSize: '13px', 
            fontWeight: '300'
        },
        additionalGridItem: {
            [theme.breakpoints.only('xs')]: { width: '260px' },
            width: '400px',
        },
        hideOnXs: {
            [theme.breakpoints.only('xs')]: { display: 'none' },
        },
        openChip: {
            [theme.breakpoints.only('xs')]: { display: 'none' },
            margin: '0px 0px 0px 8px',
            //padding: '0px 8px 0px 8px',
            //backgroundColor: theme.palette.primary.main,
            //color: theme.palette.primary.contrastText,
            fontSize: '11px',
            lineHeight: '14px',
            fontWeight: '700',
            //borderRadius: '10px',
        },
    }));
    const classes = useStyles();

    const history = useHistory()

    const [isPublic, setIsPublic] = React.useState(false)
    const [showClause, setShowClause] = React.useState(false)
    const [clause, setClause] = React.useState(null)
    const [comments, setComments] = React.useState(null)
    const [users, setUsers] = React.useState(null)
    const [filteredComments, setFilteredComments] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const [justAdded, setJustAdded] = React.useState(null)
    const [updating, setUpdating] = React.useState(null)
    const [orgurl, setOrgurl] = React.useState(null)
    const [publicStatus, setPublicStatus] = React.useState(null)
    const [internalStatus, setInternalStatus] = React.useState(null)

    /*
    const isResolved = !props.plainMode && (
        (isPublic && !props.laterVersionOwnedByCpty && props.clause.commentStatus === 'Resolved') ||
        (!isPublic && props.clause.internalWorkflow !== undefined && props.clause.internalWorkflow.filter((iwf) => iwf.changeOrg === props.curCpty && iwf.type === 'comment')[0] !== undefined &&
        props.clause.internalWorkflow.filter((iwf) => iwf.changeOrg === props.curCpty && iwf.type === 'comment')[0].status === 'Resolved'))

    const publicStatus = props.clause !== undefined && props.clause.commentStatus !== undefined ? props.clause.commentStatus : 'None';
    const internalStatus = props.clause.internalWorkflow !== undefined && props.clause.internalWorkflow.filter((iwf) => iwf.changeOrg === props.curCpty && iwf.type === 'comment')[0] !== undefined &&
        props.clause.internalWorkflow.filter((iwf) => iwf.changeOrg === props.curCpty && iwf.type === 'comment')[0].status !== undefined ? 
        props.clause.internalWorkflow.filter((iwf) => iwf.changeOrg === props.curCpty && iwf.type === 'comment')[0].status : 'None'*/

    const isResolved = !props.plainMode && clause !== null && (
        (isPublic && !props.laterVersionOwnedByCpty && clause.commentStatus === 'Resolved') ||
        (!isPublic && clause !== null && clause.internalWorkflow !== undefined && clause.internalWorkflow.filter((iwf) => iwf.changeOrg === props.curCpty && iwf.type === 'comment')[0] !== undefined &&
        clause.internalWorkflow.filter((iwf) => iwf.changeOrg === props.curCpty && iwf.type === 'comment')[0].status === 'Resolved'))

    useEffect(() => {
        if(props.open){
            axios.get('/clause/protect/clause/' + props.clause._id)
            .then((rescl) => {
                let cl = rescl.data.data
                axios.get('/comment/commentsscl/' + props.clause.singleClauseID + '/' + props.user.orgID)
                .then((res) => {
                    let cporgid = 
                        props.curCpty === "primary" && props.cust !== undefined && !['Subsidiary','LegalClient'].includes(props.cust.entityType)? props.cust.parentOrgID :
                        props.curCpty === "primary" && props.cust !== undefined && ['Subsidiary','LegalClient'].includes(props.cust.entityType) ? props.cust.orgID :
                        props.curCpty === "secondary" && props.sub !== undefined && !['Subsidiary','LegalClient'].includes(props.sub.entityType)? props.sub.parentOrgID :
                        props.curCpty === "secondary" && props.sub !== undefined && ['Subsidiary','LegalClient'].includes(props.sub.entityType) ? props.sub.orgID : 'unknown'

                    axios.get('/user/protect/users/' + cporgid)
                    .then((resu) => {

                        let oid = props.user.role === 'Counterparty' ? cporgid : props.user.orgID
                        axios.get('/org/org/' + oid)
                        .then(function (resorg) {
                            setOrgurl(resorg.data.data.orgurl)
                            // Filter only the clause public and internal comments
                            let cs = res.data.data.filter((c) => ['clause','clauseInternal'].includes(c.commentType));
                            let isP = props.templating ? // Whether to default to "Public" or "Internal"
                                    false : // if templating - no public comments exist - set to INTERNAL
                                props.clause.internalWorkflow !== undefined && props.clause.internalWorkflow.filter((iwf) => iwf.changeOrg === props.curCpty && iwf.type === 'comment')[0] !== undefined &&
                                props.clause.internalWorkflow.filter((iwf) => iwf.changeOrg === props.curCpty && iwf.type === 'comment')[0].status === 'Open' && 
                                ['None', 'Resolved'].includes(props.clause.commentStatus) ? 
                                    false : // if internal is open and public is resolved / none
                                cs.filter((c) => c.commentType === 'clauseInternal' && c.comment !== '___DELETED___').length > 0 && 
                                cs.filter((c) => c.commentType === 'clause' && c.comment !== '___DELETED___').length === 0 ?
                                    false : // if you have internal comments but no public comments yet - set to INTERNAL
                                props.agrStatus === 'Draft' && cs.filter((c) => c.commentType === 'clause' && c.comment !== '___DELETED___').length === 0 ?
                                    false : // if the agreement is in Draft Status and there are no public comments yet - set to INTERNAL
                                    true; // else - set to PUBLIC

                            setIsPublic(isP)
                            setClause(cl)
                            setComments(cs)
                            setUsers(props.users.concat(resu.data.data).filter((u) => u.active && !u.email.endsWith('support@getcanveo.com')).sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0)))
                            setLoading(false)
                            setJustAdded(null)
                            setUpdating(null)
                            setPublicStatus(cl.commentStatus !== undefined ? cl.commentStatus : 'None')
                            setInternalStatus(cl.internalWorkflow !== undefined && cl.internalWorkflow.filter((iwf) => iwf.changeOrg === props.curCpty && iwf.type === 'comment')[0] !== undefined &&
                                cl.internalWorkflow.filter((iwf) => iwf.changeOrg === props.curCpty && iwf.type === 'comment')[0].status !== undefined ? 
                                cl.internalWorkflow.filter((iwf) => iwf.changeOrg === props.curCpty && iwf.type === 'comment')[0].status : 'None')

                        }).catch(function(err) { console.log(err) })
                    }).catch(function(err) { console.log(err) })
                }).catch(function(err) { console.log(err) })
            }).catch(function(err) { console.log(err) })
        }
    }, [props.open])

    useEffect(() => {

        if(comments !== null) {
            setFilteredComments(
                comments
                .filter((c) => 
                (!isPublic && c.commentType === 'clauseInternal') || 
                (isPublic && c.commentType === 'clause' &&
                    (!props.laterVersionOwnedByCpty || c.creationDate < props.creationDateOfLatestAV || props.creationDateOfLatestAV === null || props.creationDateOfLatestAV === undefined)))
                .filter((c) => c.comment !== '___DELETED___'))
        }

    }, [comments, isPublic])

    const handleCommentSubmit = (isBlocks, comment, newStatus) => {
        setJustAdded('inprogress')

        if(comment !== null && comment !== undefined && (
        (!isBlocks && comment !== '') ||
        (isBlocks && comment !== {} && comment.blocks !== undefined && comment.blocks.some((b) => b.text !== '')))) {

            let creationDate = new Date().toISOString();
            let entID = props.curCpty === "primary" ? props.sub._id : props.cust._id

            let plainComment = isBlocks ? convertBlocksToPlainComment(comment) : comment;
            let blocksComment = isBlocks ? comment : convertPlainToBlocksComment(comment);

            // Review if there are any @mentions in the newly created comment - only applicable to internal users
            let atMentions = []
            if(blocksComment.entityMap !== undefined && blocksComment.entityMap !== {}) {
                let eMap = Object.values(blocksComment.entityMap)
                eMap.forEach((em) => {
                    if(em.data !== undefined && em.data.mention !== undefined &&
                    em.data.mention.uid !== undefined && !atMentions.some((am) => am === em.data.mention.uid)) {
                        atMentions.push(em.data.mention.uid)
                    }
                })
            }

            axios.post('/comment/comment', {
                creatorID: props.user._id, // creatorID
                creatorDisplayName: props.user.displayName, // creatorDisplayName
                orgID: props.user.orgID, // orgID
                entityID: entID, // entityID
                creationDate: creationDate, // creationDate
                comment: plainComment, // comment
                blocks: blocksComment, // blocks
                commentType: isPublic ? 'clause' : 'clauseInternal', // commentType
                agrID: props.templating ? ("oat_" + props.oatID) : props.agrID, // agrID
                singleClauseID: props.clause.singleClauseID, // singleClauseID
                ticketID: '', // ticketID
                relID: '', // relID 
            }).then((res) => {
                if(!plainComment.startsWith('===') && !props.templating && isPublic) {
                    props.handleActivityLog('clause', 'clNewComment', props.clause.singleClauseID, '', props.avv, '', {})
                }
                
                setComments([...comments, res.data.data])
                setJustAdded(!plainComment.startsWith('===') ? 'done' : null);

                let lastUpdateDate = new Date().toISOString();
                if(isPublic && props.clause.commentStatus !== newStatus) {
                    props.toggleCommentApprovalStatus(newStatus, 'comment', props.clause._id)
                    setPublicStatus(newStatus)
                    setClause({...clause, commentStatus: newStatus})
                } else if(!isPublic) { // always push an update for internal comments
                    let newWF = {status: newStatus, type: 'comment', changeOrg: props.curCpty, lastUpdateDate: lastUpdateDate, lastUpdateBy: props.user._id}
                    props.toggleCommentApprovalStatus(newWF, 'workflow', props.clause._id)
                    setInternalStatus(newStatus)
                    setClause({...clause, internalWorkflow: [newWF]})
                }

                // For any @mentions - send them an email
                let mainAgr = !props.templating ? props.agrsAvs.filter((aa) => aa.isMain)[0] : null;
                let newColabs = []
                let curColabs = mainAgr !== undefined && mainAgr !== null && mainAgr.contributors !== undefined ? mainAgr.contributors : null
                
                atMentions.forEach((uid) => {
                    let recipient = users.filter((u) => u._id === uid)[0]
                    if(recipient !== undefined) {
                        let recipientModule = getRecipientModule(props.user, recipient, props.module, props.rel)

                        // Create a notification
                        props.createNotification(
                            'Comment Mention', // notificationType
                            'Comment Mention', // title
                            props.user.displayName + ' has mentioned you in a comment', // description
                            props.templating ? 
                                '/core/legal/templates/' + recipientModule + (props.isTerms ? '/edit-terms/' : '/edit/') + props.oatID + "/" + clause.singleClauseID : 
                                '/core/' + recipientModule + '/agreement/' + props.rel._id + "/" + props.agrID + "/" + clause.singleClauseID, // linkTo 
                            false, // isRead
                            recipient._id, // uid
                        )
                        // Send a notification email
                        sendCommentEmailToUser(
                            recipientModule, // module
                            props.rel, // rel 
                            orgurl, // orgurl
                            props.templating ? props.oatID : props.agrID, // agrID 
                            clause, // clause
                            recipient, // recipient
                            props.cptyLegalName, // cptyLegalName
                            props.agrFullName, // agrFullName
                            props.user, // creator
                            filteredComments.concat(res.data.data), // filteredComments
                            isPublic, //isPublic
                            props.templating, // isTemplating
                            props.isTerms // isTerms
                        )
                    }
                    // If @mention recipient is not yet a collab - then push to newColabs
                    if(curColabs !== null && !curColabs.some((cc) => cc.uid === recipient._id) && !newColabs.some((nc) => nc.uid === recipient._id)) {
                        newColabs.push({
                            uid: recipient._id,
                            email: recipient._id,
                            entity: props.curCpty,
                        })
                        if(props.user.role === 'Counterparty') { 
                            props.updateAidForUserAgrIDs('insert', recipient._id, mainAgr._id);
                        }
                    }
                })

                if(newColabs.length > 0) { // If there is anything to push - then do it, also reroute afterwards to load new Collabs
                    props.updateAgrUsers(mainAgr._id, curColabs.concat(newColabs), mainAgr.signatories)
                    history.push('/core/' + props.module + '/agreement/' + props.rel._id + "/" + props.agrID + "/" + clause.singleClauseID)
                }

            }).catch((err) => console.log(err))
        } else {
            setJustAdded(null)
        }
    }

    const handleCommentChangeSubmit = (isBlocks, newComment) => {

        let plainComment = isBlocks ? convertBlocksToPlainComment(newComment) : newComment;
        let blocksComment = isBlocks ? newComment : convertPlainToBlocksComment(newComment);

        props.updateComment(updating, plainComment, blocksComment, props.user._id) // Update the DATABASE
    
        let idx = comments.findIndex((c) => c._id === updating)
        setComments([
            ...comments.slice(0,idx),
            {
                ...comments[idx],
                comment: plainComment,
                blocks: blocksComment,
                lastUpdateDate: new Date().toISOString(),
            },
            ...comments.slice(idx + 1)
        ])
        setUpdating(null)
    }

    const handleDeleteComment = (cid) => {
        props.updateComment(cid, '___DELETED___', null, props.user._id) // Update the DATABASE
        let toggleCommentStatus = false, toggleWorkflow = false;
        if(isPublic && comments.filter((c) => c.comment !== '___DELETED___' && c.commentType === 'clause').length === 1) { toggleCommentStatus = true; }
        else if(!isPublic && comments.filter((c) => c.comment !== '___DELETED___' && c.commentType === 'clauseInternal').length === 1) { toggleWorkflow = true; }
    
        let idx = comments.findIndex((c) => c._id === cid)
        setComments([
            ...comments.slice(0,idx),
            {
                ...comments[idx],
                comment: '___DELETED___',
                blocks: null,
            },
            ...comments.slice(idx + 1)
        ])
        setUpdating(null)
    
        let lastUpdateDate = new Date().toISOString();
    
        if(toggleCommentStatus) { 
            props.toggleCommentApprovalStatus('None', 'comment', props.clause._id); 
            setClause({...clause, commentStatus: 'None'}) 
        } else if (toggleWorkflow) { 
            props.toggleCommentApprovalStatus({status: 'None' ,type: 'comment', changeOrg: props.curCpty, lastUpdateDate: lastUpdateDate, lastUpdateBy: props.user._id}, 'workflow', props.clause._id) 
            setClause({...clause, commentStatus: 'None'})
        }
    }

    const togglePublic = (isP) => {
        setIsPublic(isP)
        setUpdating(null)
        setJustAdded(null)
    }

    const closeDialog = () => {
        setLoading(true)
        props.parentCallToClose()
    }

    return (
        <Dialog
        open={props.open}
        scroll="paper"
        TransitionComponent={Transition}
        keepMounted
        onClose={closeDialog}
        fullWidth={true}
        maxWidth="sm"
        /*PaperProps={{
            style: !isPublic ? {backgroundColor: '#f3f3f3'} : { backgroundColor: '#ffffff' },
        }}*/
        >
        <DialogTitle onClose={closeDialog}>
            Comments
            {//!['Open', 'Resolved'].includes(internalStatus) && !['Open', 'Resolved'].includes(publicStatus) ?
            <Box display={{ xs: 'none', sm: 'inline' }}>&nbsp;&nbsp;<InformationalTooltip msg={"You can add \"Internal\" and \"Public\" comments to a clause.\n\nInternal comments will be visible only to users of your organisation. You can @mention internal users to notify them via e-mail.\n\nPublic comments will be visible for internal and counterparty users. Counterparty users will only be able to see newly inserted ones after you return the agreement to them.\n\nComment threads can be in \"open\" or \"resolved\" status, either way all comments will remain visible during and after the agreement is signed."} /></Box>
            //:''
            }
            <Box className={classes.switchGroup}>
                <Button size="small" variant="text" onClick={e => togglePublic(false)}
                className={!isPublic ? classes.buttonActive : classes.button}
                style={
                    props.templating ? 
                        {borderRadius: '10px 10px 10px 10px'} :
                    !isPublic && ['Resolved'].includes(internalStatus) ?
                        {backgroundColor: theme.palette.grey[600], borderRadius: '10px 0px 0px 10px'} :
                        {borderRadius: '10px 0px 0px 10px'}}
                color={"default"}>
                    {/*<FontAwesomeIcon icon={faNetworkWired} />&nbsp;&nbsp;*/}Internal
                    {['Open', 'Resolved'].includes(internalStatus) ? 
                    <span /*style={isPublic ? { backgroundColor: theme.palette.grey[400] } : {}}*/ 
                    className={classes.openChip}>{['Open'].includes(internalStatus) ? "open" : "resolved"}</span>
                    : ""}
                </Button>
                {!props.templating ? 
                <Button size="small" variant="text" onClick={e => togglePublic(true)}
                className={isPublic ? classes.buttonActive : classes.button}
                style={isPublic && ['Resolved'].includes(publicStatus) ?
                    {backgroundColor: theme.palette.grey[600], borderRadius: '0px 10px 10px 0px'} :
                    {borderRadius: '0px 10px 10px 0px'}}
                color={"default"}>
                    {/*<FontAwesomeIcon icon={faBullhorn} />&nbsp;&nbsp;*/}Public
                    {['Open', 'Resolved'].includes(publicStatus) ? 
                    <span /*style={!isPublic ? { backgroundColor: theme.palette.grey[400] } : {}}*/
                    className={classes.openChip}>{['Open'].includes(publicStatus) ? "open" : "resolved"}</span>
                    : ""}
                </Button>
                : '' }
            </Box>
        </DialogTitle>

        <DialogContent id="msgContainer" style={{paddingTop: '0px'}}>

            {loading ? 
            <Box mt={10} mb={10} align="center">

                <CircularProgress size={24} />

            </Box>
            :
            <Box 
            style={{width: '100%'}}
            mb={5}
            mt={showClause ? 0 :
                filteredComments !== null && filteredComments.length > 0 ? 3 : 6} 
            align={
                filteredComments !== null && filteredComments.length === 0 ? "center" : "left"
                /*props.curCpty === "primary" ? "left" : "right"*/}>

                {showClause ?
                <Box align="center" width="100%" className={classes.showingClause}>
                    <Box width="90%">

                        <Collapse in={showClause}>
                            <Box mb={3}>
                            <Box align="left" className={classes.clauseBox}>
                            {props.clause.clauseCat === 'table' ?
                                <TableEditable
                                active={false}
                                disabled={true}
                                editMode={'none'}
                                clause={clause !== null && clause.clauseObject !== undefined ? clause : props.clause}
                                proposedDel={
                                    clause !== null && clause.pendingChange !== undefined && 
                                    clause.pendingChange.filter((pc) => pc.status === 'Open' && pc.type === 'proposedDel')[0] !== undefined ?
                                        clause.pendingChange.pendingChange.filter((pc) => pc.status === 'Open' && pc.type === 'proposedDel')[0] !== undefined :
                                    props.clause.pendingChange.filter((pc) => pc.status === 'Open' && pc.type === 'proposedDel')[0] !== undefined ?
                                        props.clause.pendingChange.filter((pc) => pc.status === 'Open' && pc.type === 'proposedDel')[0].changeOrg : null}
                                focusClause={() => void 0}
                                curCpty={"primary"}
                                />
                            :clause !== null && clause.blocks !== undefined ?
                                <NegoCardEditor 
                                clid={props.clause._id}
                                curCpty={"primary"}
                                user={props.user}
                                content={clause !== null && clause.blocks !== undefined ? clause.blocks : {}}
                                parentCallback={() => void 0}
                                callbackOptions={() => void 0}
                                reviewChangesMode={false}
                                activeClause={false}
                                optionClicked={null}
                                focusClause={() => void 0}
                                disableEditing={true} />
                            : '' }
                            </Box>
                            </Box>
                        </Collapse>

                    </Box>
                </Box>
                :''}

                {justAdded === 'inprogress' ?
                <Box mt={2} mb={2}>
                    <CircularProgress size={20} disableShrink />
                </Box>
                :justAdded === 'done' ?
                <Box mt={1} ml={1} mr={1} align={"left"}>
                    <Button color={props.curCpty} onClick={e=> setJustAdded(null)} size="small"><FontAwesomeIcon icon={faPlusCircle}/>&nbsp;&nbsp;Add another comment</Button>
                    {/*&nbsp;&nbsp;
                    <Button color={props.curCpty} onClick={closeDialog} size="small"><FontAwesomeIcon icon={faTimesCircle}/>&nbsp;&nbsp;Close</Button>*/}
                </Box>
                :isPublic &&
                filteredComments !== null && filteredComments.length === 0 &&
                (props.laterVersionOwnedByCpty || props.plainMode) ?
                <Box width="100%" align="center" mt={8} mb={8}>
                    <Typography align="center">There are no public comments for this clause{props.plainMode ? "." : " yet."}</Typography>
                    <Typography align="center">You can only add public comments {props.plainMode ? "before the agreement is (being) signed." : "when you are the Agreement Editor."}</Typography>
                </Box>
                :// Show "Add Comment" only if thread is not resolved && isInternal or public but not in plainmode or with the cpty
                !isResolved && (!isPublic || (isPublic && !props.laterVersionOwnedByCpty && !props.plainMode)) ?
                <Grid direction="row" container justify={filteredComments !== null && filteredComments.length === 0 ? "center" : "flex-start"} alignItems="flex-start">
                    <Grid item className={classes.hideOnXs}>
                    {filteredComments !== null && filteredComments.length > 0 ?
                        <AvatarInitials
                        displayName={props.user !== undefined && props.user !== null && props.user.displayName !== undefined ? props.user.displayName : '?'}
                        photoURL={props.user !== undefined && props.user.photoURL !== undefined && props.user.photoURL !== '' ? props.user.photoURL : null }
                        variant={'left'}
                        />
                    :''}
                    </Grid>
                    <Grid item className={classes.additionalGridItem}>
                    <MentionEditor
                        user={props.user}
                        //users={props.templating ? [] : users.filter((u) => u.orgID === props.user.orgID)}
                        users={props.templating ? 
                            users.filter((u) => u.orgID === props.user.orgID && ['Admin','Legal'].includes(u.role)) :
                            users.filter((u) => u.orgID === props.user.orgID && (!u.role.startsWith("ClientFor_") || u.role.substring(10) === props.rel.primaryCptyID || u.role.substring(10) === props.rel.secondaryCptyID))}
                        handleCommentSubmit={handleCommentSubmit}
                        editorType={filteredComments !== null && filteredComments.length === 0 ? "clean" : "additional"}
                        curCpty={props.curCpty}
                        isPublic={isPublic}
                    />
                    </Grid>
                </Grid>
                :''}

                <Box mt={3} style={{width: '100%'}}>
                {filteredComments !== null ?
                filteredComments
                .sort((a,b) => (a.creationDate < b.creationDate) ? 1 : ((b.creationDate < a.creationDate) ? -1 : 0))
                .map((c) =>
                    <CommentDisplayBox
                        key={c._id}
                        c={c}
                        align={
                            (isPublic && c.entityID === props.sub._id && props.curCpty === "primary") || 
                            (isPublic && c.entityID === props.cust._id && props.curCpty === "secondary") || 
                            (!isPublic && c.creatorID === props.user._id) ? 'left' : 'right'}
                        user={users.filter((u) => u._id === c.creatorID)[0]}
                        updating={c._id === updating ? updating : null}
                        canEdit={((isPublic && props.creationDateOfLatestAV < c.creationDate) || (!isPublic && c.creatorID === props.user._id))}
                        color={props.templating ? "primary" : 
                            c.entityID === props.sub._id ? "primary" : "secondary"}
                        setUpdating={setUpdating}
                        handleDeleteComment={handleDeleteComment}
                        handleCommentChangeSubmit={handleCommentChangeSubmit}
                    />
                )
                :''}
                </Box>


                            {/*}
                            <CardContent className={classes.cardContent}>
                                {(this.state.updating === undefined || this.state.updating === null) ?
                                <Typography variant="subtitle2" className={classes.author} gutterBottom>
                                    {comm.creatorDisplayName + (this.state.isPublic ? 
                                        (" from " + (comm.entityID === this.props.sub._id ? 
                                            this.props.sub.shortName : 
                                            this.props.cust.shortName)) : '')}
                                    
                                    {((this.state.isPublic && this.props.creationDateOfLatestAV < comm.creationDate) ||
                                     (!this.state.isPublic && comm.creatorID === this.props.user._id)) ? 
                                    <>
                                    <IconButton size="small" className={classes.miniIconB} onClick={e => this.handleChangeComment(comm._id)}>
                                        <FontAwesomeIcon icon={faPen} className={(comm.entityID === this.props.sub._id) ? classes.miniIconPrimary : classes.miniIconSecondary} />
                                    </IconButton>
                                    <IconButton size="small" onClick={e => this.handleDeleteComment(comm._id)}>
                                        <FontAwesomeIcon icon={faTrash} className={(comm.entityID === this.props.sub._id) ? classes.miniIconPrimary : classes.miniIconSecondary} />
                                    </IconButton>
                                    </>
                                    : '' }
                                </Typography>
                                :''}
                                <Typography variant="body2" color="textSecondary" className={classes.date}>
                                    {dayjs(comm.creationDate).format('MMM D, YYYY h:mm A')} {
                                    !(this.state.updating === undefined || this.state.updating === null) ? " - being edited" : 
                                    comm.lastUpdateDate !== undefined && comm.lastUpdateDate !== null ? " - edited" : ""}
                                </Typography>
                                <Typography variant="body2" className={classes.commentText}>
                                    {comm.comment.split('\n').map((cpar,i) => ( <React.Fragment key={comm._id + "_" + i}>{cpar}<br/></React.Fragment> ))}
                                </Typography>
                            </CardContent>
                            */}

            </Box>
            }

        </DialogContent>

        <DialogActions className={classes.mlittle}>
            <Button onClick={closeDialog} variant="text" color="primary" /*className={classes.cancelButton}*/>
                Close
            </Button>

            {!props.plainMode && (
            (isPublic && ['Open'].includes(publicStatus) && !props.laterVersionOwnedByCpty && !props.plainMode) ||
            (!isPublic && ['Open'].includes(internalStatus))) ?

                <Button
                onClick={e => handleCommentSubmit(false, '===RESOLVED===', 'Resolved')}
                color={"primary"} variant="text">
                    Resolve<Box display={{ xs: 'none', sm: 'inline' }}>&nbsp;Thread</Box>
                </Button> 

            :isResolved && (!isPublic ||
            (isPublic && !props.laterVersionOwnedByCpty && !props.plainMode)) ?

                <Button 
                onClick={e => handleCommentSubmit(false, '===REOPENED===', 'Open')}
                color={"primary"} variant="text">
                Reopen<Box display={{ xs: 'none', sm: 'inline' }}>&nbsp;Thread</Box>
                </Button>
            : ''}

            <Box style={{marginLeft: 'auto'}}>
                <FormControlLabel
                    style={{marginRight: '14px'}}
                    control={<Switch size="small" color="primary" checked={showClause} onChange={e => setShowClause(!showClause)} name="showClause" />}
                    label={<Typography className={classes.toggleText} variant="subtitle2">Show clause</Typography>}
                />
                {/*justAdded ?
                    <Button color={"primary"} variant="contained" disableElevation onClick={this.closeDialog}
                    style={{marginLeft: '5px'}}>Close</Button>
                : ''
                */}
            </Box>

        </DialogActions>

    </Dialog>

    )
}

export default DialogNegoComments