import * as ActionTypes from '../ActionTypes';
import { importDocxForAV } from '.';
import { convertPlainToBlocks, getAcronym, randomString as generateRandomString } from '../../utils';

const axios = require('axios');

// ORGAGRTEMPLATES

export function fetchOrgAgrTemplatesByOrgID(orgid) {
  return dispatch => {
    
      dispatch(fetchOrgAgrTemplatesPending());
      axios.get('/template/oats/' + orgid)
      .then(function(res) {
        dispatch(fetchOrgAgrTemplatesSuccess(res.data.data));
      })
      .catch(function(err) {
        dispatch(fetchOrgAgrTemplatesError(err));
      })
  }
}

export const toggleExternal = (oatid, newExternal, draftFor, lastUpdateBy) => {
  return dispatch => {
    dispatch(fetchOrgAgrTemplatesPending());
    
    let lastUpdateDate = new Date().toISOString();
    axios.put('/template/protect/oatpublish/' + oatid, {
      external: newExternal,
      draftFor: draftFor,
      lastUpdateBy: lastUpdateBy,
      lastUpdateDate: lastUpdateDate,
    })
    .then(function (res) { // success
        dispatch(updateOrgAgrTemplate(res.data.data));
    })
    .catch(function (err) { // err
        dispatch(fetchOrgAgrTemplatesError(err))
    })       
  }
};

export const toggleOatDraftFor = (oatid, draftFor, lastUpdateBy) => {
  return dispatch => {
    dispatch(fetchOrgAgrTemplatesPending());

    let lastUpdateDate = new Date().toISOString();
    axios.put('/template/protect/oatdraftfor/' + oatid, {
      draftFor: draftFor,
      lastUpdateBy: lastUpdateBy,
      lastUpdateDate: lastUpdateDate,
    })
    .then(function (res) { // success
      dispatch(updateOrgAgrTemplate(res.data.data));
      // succesful update - you will get the updated OAT from: res.data.data
    })
    .catch(function (err) { // err
      dispatch(fetchOrgAgrTemplatesError(err))
    })   
  }
}

export const toggleOatActivation = (oatid, active, draftFor, lastUpdateBy) => {
  return dispatch => {
    dispatch(fetchOrgAgrTemplatesPending());

    let lastUpdateDate = new Date().toISOString();
    axios.put('/template/protect/oatactive/' + oatid, {
      active: active,
      draftFor: draftFor,
      lastUpdateBy: lastUpdateBy,
      lastUpdateDate: lastUpdateDate,
    })
    .then(function (res) { // success
      dispatch(updateOrgAgrTemplate(res.data.data));
      // succesful update - you will get the updated OAT from: res.data.data
    })
    .catch(function (err) { // err
      dispatch(fetchOrgAgrTemplatesError(err))
    })   
  }
}



export const updateOatNames = (oatid, agrFullName, agrShortName, lastUpdateBy) => {
  return dispatch => {    
    let lastUpdateDate = new Date().toISOString();
    axios.put('/template/protect/oatnames/' + oatid, {
      agrFullName: agrFullName, 
      agrShortName: agrShortName,
      lastUpdateBy: lastUpdateBy,
      lastUpdateDate: lastUpdateDate
    })
    .then(function (res) { // success
      dispatch(updateOrgAgrTemplate(res.data.data));
    })
    .catch(function (err) { // err
      dispatch(fetchOrgAgrTemplatesError(err))
    })
  }
};

export const updateOatReference = (oatid, reference, lastUpdateBy) => {
  return dispatch => {    
    let lastUpdateDate = new Date().toISOString();
    axios.put('/template/protect/oatreference/' + oatid, {
      reference: reference,
      lastUpdateBy: lastUpdateBy,
      lastUpdateDate: lastUpdateDate
    })
    .then(function (res) { // success
      dispatch(updateOrgAgrTemplate(res.data.data));
    })
    .catch(function (err) { // err
      dispatch(fetchOrgAgrTemplatesError(err))
    })
  }
};

export const updateOatEffectiveProperties = (oatid, effectiveDateType, renewalType, effectiveTerm, lastUpdateBy) => {
  return dispatch => {
    let lastUpdateDate = new Date().toISOString();
    axios.put('/template/protect/oateffective/' + oatid, {
      effectiveDateType: effectiveDateType, 
      renewalType: renewalType, 
      effectiveTerm: effectiveTerm,
      lastUpdateBy: lastUpdateBy,
      lastUpdateDate: lastUpdateDate
    })
    .then(function (res) { // success
      dispatch(updateOrgAgrTemplate(res.data.data));
    })
    .catch(function (err) { // err
      dispatch(fetchOrgAgrTemplatesError(err))
    })
  }
}

export const updateOatURL = (oatid, oaturl, lastUpdateBy) => {
  return dispatch => {    
    let lastUpdateDate = new Date().toISOString();
    axios.put('/template/protect/oaturl/' + oatid, {
      oaturl: oaturl,
      lastUpdateBy: lastUpdateBy,
      lastUpdateDate: lastUpdateDate
    })
    .then(function (res) { // success
      dispatch(updateOrgAgrTemplate(res.data.data));
    })
    .catch(function (err) { // err
      dispatch(fetchOrgAgrTemplatesError(err))
    })
  }
};

export const updateOatAgrType = (oatid, agrTypeID, agrFullName, agrShortName, reference, lastUpdateBy) => {
  return dispatch => {    
    let lastUpdateDate = new Date().toISOString();
    axios.put('/template/protect/oatagrtype/' + oatid, {
      agrTypeID: agrTypeID,
      agrFullName: agrFullName, 
      agrShortName: agrShortName,
      reference: reference,
      lastUpdateBy: lastUpdateBy,
      lastUpdateDate: lastUpdateDate
    })
    .then(function (res) { // success
      dispatch(updateOrgAgrTemplate(res.data.data));
    })
    .catch(function (err) { // err
      dispatch(fetchOrgAgrTemplatesError(err))
    })
  }
};

export const updateOATDeletion = (oatorblueprintid, newOrgID, lastUpdateBy) => {
  return dispatch => {
    dispatch(fetchOrgAgrTemplatesPending());

    let lastUpdateDate = new Date().toISOString();
    axios.put('/template/protect/oatdelete/' + oatorblueprintid, {
      orgID: newOrgID, // starts with either deletebp_ (for blueprints) or delete_ (for versions)
      lastUpdateBy: lastUpdateBy,
      lastUpdateDate: lastUpdateDate,
    })
    .then(function (res) { // success
      if(newOrgID.startsWith('deletebp_')) {
        dispatch(fetchOrgAgrTemplatesByOrgID(newOrgID.substr(9))) // Reload all templates
      } else {
        dispatch(deleteOrgAgrTemplate(oatorblueprintid)); // remove the version
      }
    })
    .catch(function (err) { // err
      dispatch(fetchOrgAgrTemplatesError(err))
    })   
  }
}

export const createOrgAgrTemplate = (module, blueprintID, defaultChildren, language, agrFullName, agrShortName, reference,
                                     atid, orgID, isNew, duplicateClauses, clauses, external, fileSourceID, 
                                     active, draftFor, oaturl, effectiveDateType, renewalType, effectiveTerm, version, 
                                     creationBy, importedDoc, oatIDtoCopySmartFields, oldOatIDs, history, path) => {
  return dispatch => {
    if(history === undefined || history === null || path === undefined || path === null) {
      dispatch(fetchOrgAgrTemplatesPending()); // this if statements avoids an unwanted rerender for cases where a reload is happening later on
    }

    let creationDate = new Date().toISOString();
    axios.post('/template/protect/oat', {
      module: module,
      blueprintID: blueprintID,
      defaultChildren: defaultChildren,
      language: language,
      agrFullName: agrFullName,
      agrShortName: agrShortName,
      reference: reference === undefined || reference === null ? '' : reference,
      agrTypeID: atid,
      orgID: orgID,
      clauses: isNew || duplicateClauses ? [] : clauses, // New OAT: create without clauses, otherwise create with Clauses
      external: external,
      fileSourceID: fileSourceID, 
      active: active,
      draftFor: draftFor,
      oaturl: oaturl,
      effectiveDateType: effectiveDateType, 
      renewalType: renewalType, 
      effectiveTerm: effectiveTerm,
      version: version,
      creationBy: creationBy,
      creationDate: creationDate,
      lastUpdateBy: creationBy,
      lastUpdateDate: creationDate
    })
    .then(function (r) { // got a msg from the server
      if(r.data.success) { // successfully created the orgAgrTemplate
        if(isNew && importedDoc !== null) { // ImportedDoc post clauses to oat id

          // Import the Main Body
          dispatch(importDocxForAV(importedDoc, null, r.data.data._id, creationBy, orgID))

          // Import any Exhibits that were part of the ImportedDoc
          if(importedDoc.data.exhibits !== undefined && importedDoc.data.exhibits.length > 0) {
            importedDoc.data.exhibits.forEach((iDocEx) => {

              dispatch(createOrgAgrTemplate(
                module, 
                generateRandomString(20), // blueprintID
                [], // defaultChildren
                language, 
                iDocEx.agrTitle,
                getAcronym(iDocEx.agrTitle),
                iDocEx.reference,
                iDocEx.agrTypeID, 
                orgID, 
                isNew, 
                duplicateClauses, 
                [], // clauses 
                external, 
                fileSourceID, 
                active, 
                draftFor, 
                '', // oaturl 
                'signoff', // effectiveDateType
                'none', // renewalType
                {}, // effectiveTerm
                version, 
                creationBy, 
                {
                  data: {
                    clauses: iDocEx.clauses,
                    numberType: importedDoc.data.numberType,
                    exhibits: [],
                  }
                }, // artificially create the importedDoc 
                null, // oatIDtoCopySmartFields
                [] // oldOatIDs
              ))
            })
          }

        } else if(duplicateClauses || isNew) { // If you're duplicating clauses from the prev OAT
          
          // Fetch all current clauses
          axios.post('/clause/clauses', {
            clauses: clauses
          })
          .then(function(resclauses) {

            let bulkWrites = []
            resclauses.data.data
            .sort((a,b) => clauses.indexOf(a._id) - clauses.indexOf(b._id))
            .forEach((cl) => {
              bulkWrites.push(
                { insertOne :
                  {
                    "document" :
                    {
                        "singleClauseID": isNew ? generateRandomString(20) : cl.singleClauseID, 
                        "clauseCat": cl.clauseCat,
                        "lockedBy": '', // newly created PSID
                        "lockedByOrg": cl.lockedByOrg !== undefined && cl.lockedByOrg !== null && cl.lockedByOrg.type !== undefined && !isNew ? cl.lockedByOrg : {type: 'none'},
                        "clauseTypes": isNew ? [] : cl.clauseTypes, 
                        "title": cl.title, 
                        "blocks":
                          ['sec','ssec','cl', 'cl1', 'cl2', 'cl3', 'cl4', 'preamble'].includes(cl.clauseCat) && ['AgrCopy'].includes(oatIDtoCopySmartFields) ? convertPlainToBlocks(cl.plain) : 
                          ['sec','ssec','cl', 'cl1', 'cl2', 'cl3', 'cl4', 'preamble'].includes(cl.clauseCat) ? cl.blocks : {}, 
                        "plain": ['sec','ssec','cl', 'cl1', 'cl2', 'cl3', 'cl4', 'preamble'].includes(cl.clauseCat) ? cl.plain : [], 
                        "clauseObject": ['table'].includes(cl.clauseCat) ? cl.clauseObject : {}, // clauseObject
                        "version": isNew ? 1 : (cl.version + 1), // version  
                        "clauseStatus": (external || active) && !isNew ? cl.clauseStatus : 'Accepted', // clauseStatus
                        "commentStatus": isNew ? 'None' : cl.commentStatus, // commentStatus
                        "approvalStatus": isNew ? 'None' : cl.approvalStatus, // approvalStatus
                        "internalWorkflow": isNew ? [] : cl.internalWorkflow, // internalWorkflow
                        "numberType": cl.numberType !== undefined && !isNew ? cl.numberType : 'Auto',
                        "numberManual": cl.numberManual !== undefined && !isNew ? cl.numberManual : '',
                        "creationBy": isNew ? creationBy : cl.creationBy,
                        "creationDate": creationDate,
                    }
                  }
                })
            })

            axios.post('/clause/protect/clauses', {
              bulkWrites: bulkWrites, 
            })
            .then((res) => {

              if(res.data.success) {
                let avcs = []
                res.data.data.insertedIds.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0)).forEach((id) => {
                  avcs.push(id._id)
                })
                
                dispatch(updateOATNewClauses(r.data.data._id, avcs)); // you're done - path w/o smartfields
                // reroute to the newly created template if applicable
                if(history !== undefined && history !== null && path !== undefined && path !== null) {                   
                  setTimeout(function() { history.push(path + r.data.data._id); }, 1000); // Insert delay for 3seconds before rerouting
                }

              } else { dispatch(fetchOrgAgrTemplatesError("Unable to update the AV")); }

            }).catch(function(err) { dispatch(fetchOrgAgrTemplatesError(err)); })
          }).catch(function(err) { dispatch(fetchOrgAgrTemplatesError(err)); })

        } else { // In any other scenario - add the OAT to the reducer
          dispatch(addOrgAgrTemplate(r.data.data)); // Add newly created OAT to the reducer
        }

        // If activated: For all templates where the previously active template was part of defaultChildren - 
        // Should replace with newly activated oatid 
        /*
        oldOatIDs.forEach((o) => {
          let newDefaultChildren = o.defaultChildrenToInsert
          newDefaultChildren.splice(o.indexToInsert, 0, r.data.data._id)
          dispatch(updateOatNewDefaultChildren(o.oatToUpdate, newDefaultChildren, true))
        })*/

        // You want to copy over the smartfields - bulk write the smartfields with new oatid
        if(oatIDtoCopySmartFields !== null && oatIDtoCopySmartFields !== 'AgrCopy') { 

          let bulkSFWrites = []

          axios.get('/sf/sf/' + oatIDtoCopySmartFields)
          .then(function(ressfs) {

            if(ressfs.data.data.length > 0) {

              ressfs.data.data.forEach((oldsf) => {
                bulkSFWrites.push(
                  { insertOne :
                    {
                      "document" :
                      {
                          "osfid": oldsf.osfid,
                          "agrid": '',
                          "oatid": r.data.data._id,
                          "singleClauseID": oldsf.singleClauseID,
                          "ref": oldsf.ref,
                          "name": oldsf.name,
                          "type": oldsf.type,
                          "val1": oldsf.val1,
                          "val2": oldsf.val2,
                          "val3": oldsf.val3,
                          "createWizard": oldsf.createWizard,
                          "creationBy": oldsf.creationBy,
                          "creationDate": oldsf.creationDate,
                          "lastUpdateBy": creationBy,
                          "lastUpdateDate": creationDate,
                      }
                    }
                  })
              })

              // pull all smartfields for oatid !== deleted
              axios.post('/sf/smartfields', { bulkWrites: bulkSFWrites })
            }
          }).catch(function(err) { dispatch(fetchOrgAgrTemplatesError(err)); })

        }
        // reroute to the newly created template if applicable
        //if(history !== undefined && history !== null && path !== undefined && path !== null) { history.push(path + r.data.data._id) }

      } else { dispatch(fetchOrgAgrTemplatesError(r.message)) } // something went wrong while adding

    }).catch(function (err) { dispatch(fetchOrgAgrTemplatesError(err)) }) // err in the server request
  }
};

export const updateOatNewDefaultChildren = (oatid, newDefaultChildren, faf) => {
  return dispatch => {
    axios.put('/template/protect/oatchildren/' + oatid, {
      defaultChildren: newDefaultChildren,
    })
    .then(function (res) { // success
      // succesful update - you will get the updated agrV from: res.data.data
      if(!faf) { dispatch(updateOrgAgrTemplate(res.data.data)); }
      //if(history !== undefined && history !== null && path !== undefined && path !== null) { history.push(path) }
    })
    .catch(function (err) { // err
      dispatch(fetchOrgAgrTemplatesError(err))
    })       
  }
}

export const updateOATNewClauses = (oatid, newClauses, history, path) => {
  return dispatch => {
    axios.put('/template/protect/oatclauses/' + oatid, {
      clauses: newClauses,
    })
    .then(function (res) { // success
      if(history !== undefined && history !== null && path !== undefined && path !== null) { 
        history.push(path) // A path is provided inside editMode
      } else { // If no path is provided (ie. inside Template mgt) - then it should add the template to the reducer (since it just got created)
        dispatch(addOrgAgrTemplate(res.data.data));
      }
    })
    .catch(function (err) { // err
      dispatch(fetchOrgAgrTemplatesError(err))
    })       
  }
};

export const updateOATRemoveClause = (oatid, clid) => {
  return dispatch => {
    axios.put('/template/protect/oatdelcl/' + oatid, {
      delcl: clid,
    })
    .then(function (res) { // success
      // succesful update - you will get the updated oat from: res.data.data
    })
    .catch(function (err) { // err
      dispatch(fetchOrgAgrTemplatesError(err))
    })       
  }
};

export const fetchOrgAgrTemplatesPending = () => ({
  type: ActionTypes.FETCH_ORGAGRTEMPLATES_PENDING
});

export const fetchOrgAgrTemplatesError = (error) => ({
  type: ActionTypes.FETCH_ORGAGRTEMPLATES_ERROR,
  payload: error
});

export const fetchOrgAgrTemplatesSuccess = (orgAgrTemplates) => ({
  type: ActionTypes.FETCH_ORGAGRTEMPLATES_SUCCESS,
  payload: orgAgrTemplates
});

export const updateOrgAgrTemplate = (oat) => ({
  type: ActionTypes.UPDATE_ORGAGRTEMPLATE,
  payload: oat
});

export const addOrgAgrTemplate = (oat) => ({
  type: ActionTypes.ADD_ORGAGRTEMPLATE,
  payload: oat
});

export const deleteOrgAgrTemplate = (oatid) => ({
  type: ActionTypes.DELETE_ORGAGRTEMPLATE,
  payload: oatid
});

// ORGPROFILEAGRTEMPLATES

export function fetchOrgProfileAgrTemplatesByOrgID(orgid) {
  return dispatch => {
      dispatch(fetchOrgProfileAgrTemplatesPending());
      axios.get('/template/oats/' + orgid)
      .then(function(res) {
        dispatch(fetchOrgProfileAgrTemplatesSuccess(res.data.data.filter((oat) => oat.external)));
      })
      .catch(function(err) {
        dispatch(fetchOrgProfileAgrTemplatesError(err));
      })
  }
}

export const fetchOrgProfileAgrTemplatesPending = () => ({
  type: ActionTypes.FETCH_ORGPROFILEAGRTEMPLATES_PENDING
});

export const fetchOrgProfileAgrTemplatesError = (error) => ({
  type: ActionTypes.FETCH_ORGPROFILEAGRTEMPLATES_ERROR,
  payload: error
});

export const fetchOrgProfileAgrTemplatesSuccess = (orgProfileAgrTemplates) => ({
  type: ActionTypes.FETCH_ORGPROFILEAGRTEMPLATES_SUCCESS,
  payload: orgProfileAgrTemplates
});
