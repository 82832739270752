import React, { useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { prices } from '../../assets/static/prices';
import { pad, getAcronym, getActorsForModule, trunc,
         getAgrContentForPDF, randomString as generateRandomString } from '../../utils'
import { Button, Box, Container, 
         FormControl, FormControlLabel, Grid, InputLabel, 
         List, ListItem, ListItemText, ListItemSecondaryAction, MenuItem, Radio, RadioGroup,
         Select, TextField, Typography, makeStyles } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { AgreementEditor, DialogImportDoc, DrawerCore, FAB, GetStarted, SkeletonCustom, SmartFieldEditable,
         Header, ThumbAgr, ThumbTodo, RadioTargetModule, InformationalTooltip } from '../../components';
import { faFileWord, faCopy, faExclamationTriangle, faPlusCircle, faMinusCircle, faTag, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { effectivedatetypes } from '../../assets/static/effectivedatetypes';
import { renewaltypes } from '../../assets/static/renewaltypes';
 
const axios = require('axios');
const filter = createFilterOptions();
const download = require("js-file-download")

function Templates(props) {

  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    outerFlexGrid: {
      minHeight: '80vh',
      paddingTop: '110px',
      paddingBottom: '100px',
    },
    header: {
      zIndex: 1400
    },
    content: {
        flexGrow: 1,
    },
    bold: {
      fontWeight: '700',
    },
    thumbContainer : {
      [theme.breakpoints.only('xs')]: { width: '312px' },
      [theme.breakpoints.only('sm')]: { width: '276px' },
      [theme.breakpoints.up('md')]: { width: '440px' },
    },
    thumbContainerAgrs: {
      [theme.breakpoints.only('xs')]: { width: '312px' },
      [theme.breakpoints.only('sm')]: { width: '444px' },
    },
    gridForThumb:{
      [theme.breakpoints.only('xs')]: { padding: '0px 6px 12px 6px' },
      [theme.breakpoints.only('sm')]: { padding: '0px 6px 12px 6px' },
      [theme.breakpoints.up('md')]: { padding: '0px 10px 20px 10px' },
    },
    warnIcon: {
      fontSize: '56px',
      color: theme.palette.secondary.main,
    },
    boxResizing: {
      [theme.breakpoints.only('xs')]: { width: '320px' },
      width: '400px'
    },
    boxResizing2: {
      [theme.breakpoints.only('xs')]: { width: '320px' },
      [theme.breakpoints.only('sm')]: { width: '440px' },
      [theme.breakpoints.up('md')]: { width: '540px' }, 
    },
    draftSpan: {
      marginLeft: '10px',
      padding: '0px 2px 0px 2px',
      fontSize: '12px',
      backgroundColor: theme.palette.grey[500],
      color: theme.palette.primary.contrastText,
    },
    activeSpan: {
      marginLeft: '10px',
      padding: '0px 2px 0px 2px',
      fontSize: '12px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    publishedSpan: {
      marginLeft: '10px',
      padding: '0px 2px 0px 2px',
      fontSize: '12px',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
    boxPlain: {
      marginTop: '30px',
      marginBottom: '40px',
      padding: '20px 20px 15px 20px',
      lineHeight: '1.9',
      border: '1px solid ' + theme.palette.grey[300],
      [theme.breakpoints.only('xs')]: { width: '284px' },
      [theme.breakpoints.only('sm')]: { width: '430px' },
      [theme.breakpoints.only('md')]: { width: '560px' },
      [theme.breakpoints.up('lg')]: { width: '670px',  },
    },
    deleteButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.primary.contrastText,
      }
    },
    warn: {
      fontWeight: '700',
      color: theme.palette.error.main,
    },
    exhList: {
      borderLeft: '3px solid ' + theme.palette.grey[500],
    },
    greyishOption: {
      color: theme.palette.grey[500],
    },
    secCheck: {
      color: theme.palette.secondary.main,
    },
    regCheck: {
      color: theme.palette.grey[800],
    }
  }));
  const classes = useStyles();

  const history = useHistory();

  const [smartFields, setSmartFields] = React.useState([])
  const [importOpen, setImportOpen] = React.useState(null);
  const [sourceOATID, setSourceOATID] = React.useState('')
  const [activeOat, setActiveOat] = React.useState(null);
  const [oatClauses, setOatClauses] = React.useState(null);
  const [clauses, setClauses] = React.useState(null);
  const [fetchError, setFetchError] = React.useState(null) // Whether there was an error fetching
  const [plainMode, setPlainMode] = React.useState(null)
  const [activationError, setActivationError] = React.useState(null)
  const [deleteOATQuestion, setDeleteOATQuestion] = React.useState(null)
  const [deactivateOATQuestion, setDeactivateOATQuestion] = React.useState(null)
  const [toggleFilter, setToggleFilter] = React.useState(null);
  const [addingExhibit, setAddingExhibit] = React.useState(false);
  const [exhibitToBeAdded, setExhibitToBeAdded] = React.useState("none")
  const [newTemplateReference, setNewTemplateReference] = React.useState(props.action === 'copy-agr' ? 'COPY FROM AGREEMENT' : null)
  const [freezeTemplating, setFreezeTemplating] = React.useState(null)
  const [publishing, setPublishing] = React.useState(false)
  const [changingURL, setChangingURL] = React.useState(false)
  const [urlError, setUrlError] = React.useState(false)
  const [newOatURL, setNewOatURL] = React.useState('')
  const [changingAgrType, setChangingAgrType] = React.useState(false)
  //const [changingRef, setChangingRef] = React.useState(false)
  const [changingEdt, setChangingEdt] = React.useState(false)
  const [changingRnt, setChangingRnt] = React.useState(false)
  const [changingTerm, setChangingTerm] = React.useState(false)
  //const [copyToOtherModule, setCopyToOtherModule] = React.useState(false)
  //const [changeRefDecision, setChangeRefDecision] = React.useState('current')
  const [changeEdtDecision, setChangeEdtDecision] = React.useState('signoff')
  const [changeRntDecision, setChangeRntDecision] = React.useState('none')
  const [changeTermDecision, setChangeTermDecision] = React.useState({})
  const [targetModule, setTargetModule] = React.useState(null);
  const [newAgrType, setNewAgrType] = React.useState(null)
  const [newAgrTypeToggle, setNewAgrTypeToggle] = React.useState(false)
  const [newAgrTypeFullName, setNewAgrTypeFullName] = React.useState('')
  const [newAgrTypeShortName, setNewAgrTypeShortName] = React.useState('')
  const [relevantOATs, setRelevantOATs] = React.useState(null)
  const [manageAgrTypes, setManageAgrTypes] = React.useState(false);
  const [agrTypeToBeUpdated, setAgrTypeToBeUpdated] = React.useState("none");
  const [agrTypeError, setAgrTypeError] = React.useState(null)
  const [oatVersions, setOatVersions] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [openDiffDialog, setOpenDiffDialog] = React.useState(false);
  //const [clauseForLabeling, setClauseForLabeling] = React.useState(null)
  //const [clausesForLabeling, setClausesForLabeling] = React.useState(null)
  //const [clauseLabels, setClauseLabels] = React.useState([])

  const module = props.module !== undefined && props.module !== 'terms' ? props.module : 
    props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
      props.org.modules[0] : 'sales';

  const modules = props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
                    ['sales','procurement','partnerships','people','corporate'] : 
                  props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
                    props.org.modules : [];

  useEffect(() => {

    let unmounted = false;
    // Set relevant fields to show the overview of terms/ templates
    if(props.orgAgrTemplates !== undefined && props.orgAgrTemplates !== null && props.user !== undefined && props.user.orgID !== undefined) {

      if (['edit','edit-terms'].includes(props.action) && props.oatid !== undefined) {

        // USE EFFECT Section to define orgAgrTemplate content
        let oat = {}, oatcs = [], cls = []

        /*
        let blueprint = props.orgAgrTemplates.filter((o) => o._id === props.oatid)[0] !== undefined &&
          props.orgAgrTemplates.filter((o) => o._id === props.oatid)[0].blueprintID !== undefined && props.orgAgrTemplates.filter((o) => o._id === props.oatid)[0].blueprintID !== '' ? 
              props.orgAgrTemplates.filter((o) => o._id === props.oatid)[0].blueprintID : 'notapplicable';*/

        axios.get('/template/oatplus/' + props.user.orgID + '/' + props.oatid)
        .then(function(resoats) {

          oat = resoats.data.data !== undefined && resoats.data.data !== null && resoats.data.data !== '' &&
            resoats.data.data.filter((o) => o._id === props.oatid)[0] !== undefined ?
                resoats.data.data.filter((o) => o._id === props.oatid)[0] : {}; // ASSIGN oat
          oatcs = oat.clauses // ASSIGN oat clauses

          axios.post('/clause/clauses', { clauses: oatcs }) // Need to get the relevant clauses
          .then(function(rescls) {
            cls = rescls.data.data;  // ASSIGN cls

            axios.get('/sf/sf/' + oat._id)
            .then(function(ressfs) {
              
              if (!unmounted && props.user !== undefined) {
                setSmartFields(ressfs.data.data)
                setActiveOat(oat)
                setOatVersions(resoats.data.data)
                setChangeEdtDecision(oat.effectiveDateType !== undefined && oat.effectiveDateType !== null ? oat.effectiveDateType : 'signoff')
                setChangeRntDecision(oat.renewalType !== undefined && oat.renewalType !== null ? oat.renewalType : 'none')
                setChangeTermDecision(oat.effectiveTerm !== undefined && oat.effectiveTerm !== null ? oat.effectiveTerm : {})
                setOatClauses(oatcs)
                setClauses(cls)
                setPlainMode(oat.active || oat.external)
              }

            }).catch(function(err) { setFetchError(err) })
          }).catch(function(err) { setFetchError(err) })
        }).catch(function(err) { setFetchError(err) })

      
      } else { // not in the editing screen - sort relevantOats by filtering drafts that have an active
        /*
        let roats = []
        //Legacy Filtering
        let firstFilterResult = props.orgAgrTemplates.filter((oat) => ((oat.active || oat.draftFor === 'Template') && props.action === undefined) || ((oat.external || oat.draftFor === 'Terms') && props.action === 'terms'))
        firstFilterResult.forEach((ffr) => {
          if(ffr.active || ffr.external || 
            (props.action === undefined && ffr.draftFor === 'Template' && 
              firstFilterResult.filter((ff) => ff._id !== ffr._id && 
                ff.agrTypeID === ffr.agrTypeID && ff.module === ffr.module && ff.reference === ffr.reference && 
                (ff.active || (ff.blueprintID === ffr.blueprintID && ff.version > ffr.version)))[0] === undefined) ||
            (props.action === 'terms' && ffr.draftFor === 'Terms' && firstFilterResult.filter((ff) => ff._id !== ffr._id && ff.agrTypeID === ffr.agrTypeID && ff.module === ffr.module && ff.reference === ffr.reference && (ff.external || (ff.blueprintID === ffr.blueprintID && ff.version > ffr.version)))[0] === undefined)) {
            roats.push(ffr);
          }
        })
        setRelevantOATs(roats)*/
        
        setRelevantOATs(
          props.orgAgrTemplates
          .filter((oat) => 
            (props.action === undefined && (
              (oat.active) || (oat.draftFor === 'Template' && !props.orgAgrTemplates.some((o) => o._id !== oat._id && o.blueprintID === oat.blueprintID)) 
              || // For Legacy Templates
              (oat.draftFor === 'Template' && (oat.blueprintID === undefined || oat.blueprintID === null || oat.blueprintID === "") &&
                !props.orgAgrTemplates.some((o) => o._id !== oat._id && o.agrTypeID === oat.agrTypeID && o.module === oat.module && o.reference === oat.reference))
            )) || 
            (props.action === 'terms' && (
              (oat.external) || (oat.draftFor === 'Terms' && !props.orgAgrTemplates.some((o) => o._id !== oat._id && o.blueprintID === oat.blueprintID)) 
              || // For Legacy Templates
              (oat.draftFor === 'Terms' && (oat.blueprintID === undefined || oat.blueprintID === null || oat.blueprintID === "") &&
                !props.orgAgrTemplates.some((o) => o._id !== oat._id && o.agrTypeID === oat.agrTypeID && o.module === oat.module && o.reference === oat.reference))
            )))
        )
      }

      let p = prices.filter((p) => p.clientType === props.org.clientType)[0]

      let thisOat = props.orgAgrTemplates.filter((o) => o._id === props.oatid)[0]

      let ft = p !== undefined && props.orgAgrTemplates !== undefined && 
        props.orgAgrTemplates.filter((oat) => (oat.active && (thisOat === undefined || thisOat.draftFor !== 'Template' || thisOat.agrTypeID !== oat.agrTypeID)) || 
                                              (oat.external && (thisOat === undefined || thisOat.draftFor !== 'Terms' || thisOat.agrTypeID !== oat.agrTypeID))).length >= p.maxTemplates ? 'maxAmt' : null

      if(!['cust', 'custE'].includes(props.org.clientType)) { ft = ft !== null ? 'both' : 'noPublish'; }

      setFreezeTemplating(ft)
    }

    return () => { unmounted = true };

  }, [props.module, props.action, props.oatid, props.agrTypes, props.orgAgrTemplates, props.clid, props.user,
      props.org.clientType, /*clauseForLabeling, props.clauseType*/])

  
  /*
  const handleClassifySubmit = () => {
    if(clauseForLabeling !== null && clausesForLabeling !== null) {
      
      // Need to update clause inside clausesForLabeling
      let cls = clausesForLabeling; // initialize cls for state update
      const index = cls.findIndex(cl => cl._id === clauseForLabeling._id)
      cls[index] = { ...cls[index], clauseTypes: clauseLabels }
      setClauseLabels([...cls]);   

      if(!clauseLabels.includes("none_of_the_above")) {
        props.updateClauseFAF ( // Update clause in DB (Fire & Forget)
          clauseForLabeling._id, // clid 
          clauseLabels, // clauseTypes
          clauseForLabeling.clauseStatus, // clauseStatus
          clauseForLabeling.commentStatus, // commentStatus
          clauseForLabeling.approvalStatus, // approvalStatus
          clauseForLabeling.internalWorkflow, // internalWorkflow
          clauseForLabeling.title, // title
          clauseForLabeling.blocks, // blocks
          clauseForLabeling.plain, // plain
          clauseForLabeling.clauseObject, // clauseObject
          clauseForLabeling.version + 1, // version
          clauseForLabeling.pendingChange, // pendingChange
          clauseForLabeling.numberType, // numberType
          clauseForLabeling.numberManual, // numberManual
          props.user._id // lastUpdateBy
        )
      }
      
      //if(!clausesForLabeling.isDefinition || clauseLabels.length !== 1 || clauseLabels[0] !== 'definition') {
      props.createClauseTypeClassification(
        getClauseClassificationString(clauseForLabeling.title, clauseForLabeling.plain), 
        clauseLabels, 
        clauseForLabeling.singleClauseID, 
        props.user._id
      )
      //}

      handleDifferentClauseForLabeling('next')
    }  
  }*/

  /*
  const handleToggleLabel = (newLabel) => {
    if(clauseLabels.some((l) => l === newLabel)) {
      setClauseLabels(clauseLabels.filter((l) => l !== newLabel))
    } else {
      setClauseLabels(ls => [...ls, newLabel]);
    }
  }*/

  const handleExportTemplate = () => {
    let agrName = activeOat.agrFullName !== undefined && activeOat.agrFullName !== null && activeOat.agrFullName !== '' ?
                    activeOat.agrFullName :
                  props.agrTypes !== undefined && activeOat !== null && props.agrTypes.filter((at) => at._id === activeOat.agrTypeID)[0] !== undefined ?
                    props.agrTypes.filter((at) => at._id === activeOat.agrTypeID)[0].fullName[0] : ''
    let agrContent = [getAgrContentForPDF(0, null, agrName, activeOat.clauses, clauses)]
    axios.post('/export/docx', {
      filename: generateRandomString(20) + '.docx',
      agrContent: agrContent,
      primarySigners: [],
      secondarySigners: [],
    })
    .then(function (res) { // the msg was sent

        axios.get(('/export/download/' + res.data.data), {
            responseType: 'arraybuffer'
        })
        .then(function (resd) { 
            download(resd.data, "template.docx"); 
        }) // Download right away when ready
        .catch(function (err) { console.log("erroring out", err) }) // err in the server request
    })
    .catch(function(err) { console.log(err) })
  }

  /*
  const handleDifferentClauseForLabeling = (prevNext) => {
    if(clausesForLabeling !== null && clauseForLabeling !== null) {
      let curIdx = clausesForLabeling.findIndex((clfl) => clfl._id === clauseForLabeling._id)
      let newIdx = prevNext === 'next' && curIdx === clausesForLabeling.length - 1 ? 0 :
                  prevNext === 'next' ? curIdx + 1 : 
                  prevNext === 'prev' && curIdx === 0 ? clausesForLabeling.length - 1 :
                  curIdx - 1
      let clfl = clausesForLabeling[newIdx];
      let ctKeywords = []
      let hasKeyword = false;
      props.clauseTypes.forEach((ct) => {
        let classifyString = clfl.plain.join(' ');
        ct.keywords.forEach((ck) => {
          if(classifyString.toLowerCase().includes(ck) && !ctKeywords.some((ckey) => ckey === ct._id)) { ctKeywords.push(ct._id); hasKeyword = true;}
        })
      })
      clfl.ctKeywords = ctKeywords;
      clfl.hasKeyword = hasKeyword;
      let clls = []
      if(clfl.clauseTypes !== undefined) { // Filter through any previously defined labels with an "ID" -- legacy, can be removed after training
        clfl.clauseTypes.forEach((cct) => {
          if(props.clauseTypes.some((pct) => pct.label === cct)) {
            clls.push(cct);
          }
        })
      } else {
        clls = []
      }
      setClauseLabels(clls)
      setClauseForLabeling(clfl)
    }
  }*/

  const handleToggleFilter = (e, type) => { // Setting the Cards Filter (through Header)
    setToggleFilter(type !== toggleFilter ? type : null);
  }

  const handleImportClose = () => {
    setImportOpen(null);
  };

  const handleDiffDialogOpen = () => {
    setOpenDiffDialog(true);
  }

  const handleDiffDialogClose = () => {
    setOpenDiffDialog(false);
  }

  const handleFinalizeImport = (iDoc) => {

    // Only create if there is not already an existing template for this AgrType / Module / Reference / Terms vs. Template
    if(props.orgAgrTemplates.filter((oat) => oat.agrTypeID === iDoc.data.agrTypeID && oat.reference === iDoc.data.reference && oat.module === iDoc.data.targetModule &&
      ((props.action === 'new' && (oat.active || oat.draftFor === 'Template')) || (props.action === 'new-terms' && (oat.external || oat.draftFor === 'Terms'))))[0] === undefined) {
      // create the OAT
      props.createOrgAgrTemplate(
        iDoc.data.targetModule, // module
        generateRandomString(20), // blueprintID 
        [], // defaultChildren
        'English', // language
        iDoc.data.agrTitle !== '' ? iDoc.data.agrTitle :
          props.agrTypes.filter((at) => at._id === iDoc.data.agrTypeID)[0] !== undefined ? 
          props.agrTypes.filter((at) => at._id === iDoc.data.agrTypeID)[0].fullName[0] : '', // agrFullName,
        iDoc.data.agrTitle !== '' ? iDoc.data.agrTitle.match(/\b(\w)/g).join('') :
          props.agrTypes.filter((at) => at._id === iDoc.data.agrTypeID)[0] !== undefined ? 
          props.agrTypes.filter((at) => at._id === iDoc.data.agrTypeID)[0].shortName : '', // agrShortName
        iDoc.data.reference, // reference
        iDoc.data.agrTypeID, // agrTypeID 
        props.user.orgID, // orgID
        true, // isNew 
        false, // duplicateClauses
        [], // clauses 
        false, // external 
        iDoc.data.docURL, // fileSourceID
        false, // active
        props.action === 'new-terms' ? "Terms" : "Template", // draftFor
        '', // oaturl
        'signoff', // effectiveDateType
        'none', // renewalType
        {}, // effectiveTerm
        '001.0000', // version
        props.user._id, // creationBy
        iDoc, // importedDoc
        null, // oatIDtoCopySmartFields
        [], // oldOatIDs - no longer being used
        null, // history
        null, // path
      )
      if(iDoc.data.atPushToReducer !== undefined && iDoc.data.atPushToReducer !== null && iDoc.data.atPushToReducer.length > 0) {
        iDoc.data.atPushToReducer.forEach((at) => {
          props.addAgrType(at) // push the new ATs into the reducer
        })
      }
      let path = props.action === 'new-terms' ? ("/core/legal/templates/" + iDoc.data.targetModule + "/terms") : ("/core/legal/templates/" + iDoc.data.targetModule);
      history.push(path);
    } else {
      let path = "/core/legal/templates/" + iDoc.data.targetModule + "/err";
      history.push(path);
    }

    handleImportClose();
  }  

  const handleTemplateChange = (value) => {
    setSourceOATID(value !== null ? value._id : '');
  }

  const handleSaveFromAgreement = () => {
    let aid = props.oatid;

    axios.get('/agr/agr/' + aid) // Need to get the relevant Agreement
    .then(function(resag) {
      let ag = resag.data.data;
      let cp = props.subs.some((s) => s._id === ag.primaryCptyID) ? "primary" : props.subs.some((s) => s._id === ag.secondaryCptyID) ? "secondary" : "unknown" // whether the user org is primary or secondary

      axios.get('/agr/agrvs/' + aid) // Need to get the latest AV for this ORG
      .then(function(resavs) { // You got all the Agr Versions for this Agreement, they are ordered DESC by "version"
        let avs = resavs.data.data;
        let av = ['Execution', 'InForce'].includes(ag.agrStatus) ? avs[0] : avs.filter((agrv) => agrv.owner === cp)[0] // ASSIGN av

        if(av === undefined || props.orgAgrTemplates.some((oat) => oat.reference === newTemplateReference && oat.module === targetModule && oat.agrTypeID === ag.agrTypeID)) {
          history.push("/core/legal/templates/" + targetModule + "/err"); // The target reference, module, agrType combination already exists

        } else {
          let avcs = av.clauses // ASSIGN avcs
          props.createOrgAgrTemplate(
            targetModule, // module 
            generateRandomString(20), // blueprintID 
            [], // defaultChildren
            'English', // language
            ag.agrFullName, // agrFullName,
            ag.agrShortName, // agrShortName
            newTemplateReference, // reference
            ag.agrTypeID, // agrTypeID 
            props.user.orgID, // orgID
            true, // isNew 
            true, // duplicateClauses
            avcs, // clauses 
            false, // external 
            'Copy from agreement: ' + ag._id, // fileSourceID
            false, // active
            'Template', // draftFor
            '', // oaturl
            'signoff', // effectiveDateType
            'none', // renewalType
            {}, // effectiveTerm
            '001.0000', // version
            props.user._id, // creationBy
            null, // importedDoc
            'AgrCopy', // oatIDtoCopySmartFields - this will conver the plain to blocks to lose any redlines or smartfields
            [], // oldOatIDs - no longer being used
            null, // history
            null, // path
          )

          // this AgrType is unknown for this org - make globally available
          if(!props.agrTypes.some((at) => at._id === ag.agrTypeID)) { 
            axios.put('/agrtype/protect/agrtypeglobal/' + ag.agrTypeID, {})
            .then(function (res) { // got a msg from the server
              if(res.data.success) { // successfully added
      
                // Send notification email
                axios.post('/mail/canveoops', {
                  subject: "Agreement Type globalized",
                  message: `<p>An existing Agreement type was globalized by ` + props.user.displayName + ` from ` + props.org.legalName + `</p>
                    <p>The agreement type is: <b>` + ag.agrTypeID + `</p>`,
                })
      
                props.addAgrType(res.data.data) // push the new AT into the reducer
                history.push("/core/legal/templates/" + targetModule); // reroute after globalizing the agreement type
      
              } else {
                setAgrTypeError("There was an issue globalizing the agreement type")
              }
            }).catch(function(err) { setAgrTypeError("Unable to globalize the agreement type") })
          } else {
            history.push("/core/legal/templates/" + targetModule); // reroute without globalizing the agreement type
          }
        }
      }).catch(function(err) { setFetchError(err) })
    }).catch(function(err) { setFetchError(err) })
  }

  const handleCopySubmit = (sourceOatID, targetAtID, newRef, draftFor, targetMod) => {
    if(sourceOatID !== '' && targetAtID !== '') {

      //let sourceOAT = props.orgAgrTemplates.filter((oat) => oat._id === sourceOatID)[0]

      axios.get('/template/oat/' + props.user.orgID + '/' + sourceOatID)
      .then(function(resoat) {
        let sourceOAT = resoat.data.data;

        props.createOrgAgrTemplate(
          targetMod, // module 
          generateRandomString(20), // blueprintID 
          [], // defaultChildren
          'English', // language
          sourceOAT.agrFullName !== undefined && sourceOAT.agrFullName !== '' ? sourceOAT.agrFullName :
          props.agrTypes.filter((at) => at._id === sourceOAT.agrTypeID)[0] !== undefined ?
            props.agrTypes.filter((at) => at._id === sourceOAT.agrTypeID)[0].fullName[0] : '', // agrFullName,
          sourceOAT.agrShortName !== undefined && sourceOAT.agrShortName !== '' ? sourceOAT.agrShortName :
            props.agrTypes.filter((at) => at._id === sourceOAT.agrTypeID)[0] !== undefined ?
              props.agrTypes.filter((at) => at._id === sourceOAT.agrTypeID)[0].shortName : '', // agrShortName
          newRef, // reference
          targetAtID, // agrTypeID 
          props.user.orgID, // orgID
          true, // isNew
          true, // duplicateClauses
          sourceOAT.clauses, // clauses 
          false, // external 
          'Copy from: ' + sourceOAT._id, // fileSourceID
          false, // active
          draftFor, // draftFor
          '', // oaturl
          sourceOAT.effectiveDateType, // effectiveDateType
          sourceOAT.renewalType, // renewalType
          sourceOAT.effectiveTerm, // effectiveTerm
          '001.0000', // version
          props.user._id, // creationBy
          null, // importedDoc
          sourceOAT.module !== targetMod ? 'AgrCopy' : sourceOatID, // oatIDtoCopySmartFields - AgrCopy will convert the plain to blocks to lose any redlines or smartfields
          [], // oldOatIDs - no longer being used
          null, // history
          null, // path
        )
    
        let path = draftFor === 'Template' ? ("/core/legal/templates/" + targetMod) : ("/core/legal/templates/" + targetMod + "/terms");
        history.push(path);
      }).catch(function(err) { setFetchError(err) })

    }
  }

  const handleNewDraft = () => {

    setLoading(true)
    /*
    let latestV = props.orgAgrTemplates
      .sort((a,b) => (a.version < b.version) ? 1 : ((b.version < a.version) ? -1 : 0))
      .filter((oat) => oat.module === activeOat.module && oat.agrTypeID === activeOat.agrTypeID && oat.reference === activeOat.reference && (
        ((activeOat.draftFor === "Template" || activeOat.active) && (oat.draftFor === "Template" || oat.active)) ||
        ((activeOat.draftFor === "Terms" || activeOat.external) && (oat.draftFor === "Terms" || oat.external))
      ))[0]*/
    let latestV = oatVersions !== undefined && oatVersions !== null && oatVersions[0] !== undefined ? oatVersions[0].version : '001.0001'
    let newMajorVersion = latestV.substring(0,4);
    let newMinorVersion = pad(parseInt(latestV.substring(4,8)) + 1, 4);

    // The newBlueprintID is transitional code - to give those templates without a blueprintID a new one that is also attached to the current template
    let newBlueprintID = activeOat.blueprintID !== undefined && activeOat.blueprintID !== null && activeOat.blueprintID !== '' ?
          null : generateRandomString(20)
    
    if(newBlueprintID !== null) { 
      axios.put('/template/protect/oatblueprint/' + activeOat._id, { blueprintID: newBlueprintID })
    }
    
    props.createOrgAgrTemplate(
      activeOat.module, // module 
      newBlueprintID !== null ? newBlueprintID : activeOat.blueprintID, // blueprintID 
      activeOat.defaultChildren, // defaultChildren
      activeOat.language, // language
      activeOat.agrFullName, // agrFullName,
      activeOat.agrShortName, // agrShortName
      activeOat.reference, // reference
      activeOat.agrTypeID, // agrTypeID 
      props.user.orgID, // orgID
      false, // isNew 
      true, // duplicateClauses
      oatClauses, // clauses 
      false, // external 
      activeOat.fileSourceID, // fileSourceID
      false, // active
      props.action === 'edit-terms' ? 'Terms' : "Template", // draftFor
      activeOat.oaturl !== undefined && activeOat.oaturl !== null ? activeOat.oaturl : '', // oaturl
      activeOat.effectiveDateType, // effectiveDateType
      activeOat.renewalType, // renewalType 
      activeOat.effectiveTerm, // effectiveTerm
      newMajorVersion + newMinorVersion, // version
      props.user._id, // creationBy
      null, // importedDoc
      activeOat._id, // oatIDtoCopySmartFields
      [], // oldOatIDs - no longer being used
      history, // history
      (activeOat.draftFor === "Template" || activeOat.active) ? ("/core/legal/templates/" + activeOat.module + "/edit/") : ("/core/legal/templates/" + activeOat.module + "/edit-terms/"), // path
    )

  }

  const handleActivate = (type) => {

    //setLoading(true);
    
    // Get the latest OAT (clauses) since it may have been updated through FAF inside the Child component
    axios.get('/template/oat/' + props.user.orgID + '/' + activeOat._id)
    .then(function(resoat) {

      //setLoading(false)
      
      let curOat = resoat.data.data; // check to see if the current oat has any open entities
      axios.post('/clause/clauses', { clauses: curOat.clauses }) // Need to get the relevant clauses
      .then(function(rescls) {
        let cls = rescls.data.data;  // ASSIGN cls

        let oatavcs = curOat.clauses; // a temp array to exclude any deleted clauses
        cls.filter((c) => c.clauseStatus === 'Deleted').forEach((c) => {
          oatavcs = oatavcs.filter((oa) => oa !== c._id);
        })

        let hasEntity = false;
        // Loop through all blocks for non-deleted clauses - make sure no entityRange exists
        cls.filter((c) => c.clauseStatus !== 'Deleted').forEach((c) => {
          if(c.pendingChange !== undefined && c.pendingChange.length > 0) { hasEntity = true; }
          if(['preamble','sec','ssec','cl','cl1','cl2','cl3','cl4'].includes(c.clauseCat)) {
            c.blocks.blocks.forEach((b) => { if(!hasEntity && b.entityRanges.length > 0){ hasEntity = true; } })
          } else if(['table'].includes(c.clauseCat)) {
            if(!c.clauseObject.invisibleHead && c.clauseObject.head.some((h) => h.change !== undefined && h.change !== null && h.change.type !== undefined)) { hasEntity = true }
            else {
              c.clauseObject.rows.forEach((row) => {
                if(row.cols.some((c) => c.change !== undefined && c.change !== null && c.change.type !== undefined)) { hasEntity = true }
              })
            }
          }
        })
        if(hasEntity) {
          //setChangingRef(false)
          //setCopyToOtherModule(false)
          setChangingAgrType(false)
          setActivationError("Please accept or reject all redlines before activating / publishing")
          //setLoading(false)
        } else { // Activate or Publish the OAT

          let latestV = oatVersions !== undefined && oatVersions !== null && oatVersions[0] !== undefined ? oatVersions[0].version : '001.0001';
          let newMajorVersion = pad(parseInt(latestV.substr(0,3)) + 1, 3) // Activate / Publish the new Oat by creating a new Major version
          let newMinorVersion = '.0000'

          // Deactivate / Depublish any old Oat(s)
          let activeOats = oatVersions.filter((ov) => (type === 'activate' && ov.active) || (type === 'publish' && ov.external))
          
          //let oldOatIDs = [] // object for which OATs to update the defaultChildren

          activeOats.forEach((oat) => {
            if(type === 'activate') {
              props.toggleOatActivation(oat._id, false, oat.draftFor, props.user._id);
            } else if (type === 'publish') {
              props.toggleExternal(oat._id, false, oat.draftFor, props.user._id)
            }
          })

          props.createOrgAgrTemplate(
            curOat.module, // module 
            curOat.blueprintID !== undefined && curOat.blueprintID !== null && curOat.blueprintID !== '' ?
                curOat.blueprintID : generateRandomString(20), // blueprintID 
            curOat.defaultChildren, // defaultChildren
            curOat.language, // language
            curOat.agrFullName, // agrFullName,
            curOat.agrShortName, // agrShortName
            curOat.reference, // reference
            curOat.agrTypeID, // agrTypeID 
            props.user.orgID, // orgID
            false, // isNew 
            true, // duplicateClauses
            oatavcs, // clauses 
            type === 'publish' ? true : false, // external 
            curOat.fileSourceID, // fileSourceID
            type === 'activate' ? true : false, // active
            curOat.draftFor, // draftFor
            curOat.oaturl !== undefined && curOat.oaturl !== null ? curOat.oaturl : '', // oaturl
            curOat.effectiveDateType, // effectiveDateType
            curOat.renewalType, // renewalType 
            curOat.effectiveTerm, // effectiveTerm
            newMajorVersion + newMinorVersion, // version
            props.user._id, // creationBy
            null, // importedDoc
            curOat._id, // oatIDtoCopySmartFields
            [], // oldOatIDs - no longer being used
            history, // history
            (curOat.draftFor === "Template" || curOat.active) ? ("/core/legal/templates/" + curOat.module + "/edit/") : ("/core/legal/templates/" + curOat.module + "/edit-terms/"), // path
          )

        }
      }).catch(function(err) { setFetchError(err) })
    }).catch(function(err) { setFetchError(err) })
  }

  const handleConfirmDeactivate = (type) => {
    if(type === 'deactivate') { 
      // Remove any link to the deactivated template for others that have it as a default child
      /*
      props.orgAgrTemplates.forEach((o) => {
        if(o.defaultChildren.some((child) => child === activeOat._id)) {
          let newdc = o.defaultChildren.filter((child) => child !== activeOat._id)
          props.updateOatNewDefaultChildren(o._id, newdc, true);
        }
      })*/
      props.toggleOatActivation(activeOat._id, false, 'Template', props.user._id)
    } else if (type === 'unpublish') {
      props.toggleExternal(activeOat._id, false, 'Terms', props.user._id)
    }

    // Remove any existing "draftFor"
    /*
    props.orgAgrTemplates
    .filter((o) => o.agrTypeID === activeOat.agrTypeID && o.module === activeOat.module && o.reference === activeOat.reference && o._id !== activeOat._id && ((type === 'deactivate' && o.draftFor === 'Template') || (type === 'unpublish' && o.draftFor === 'Terms')))
    .forEach((o) => {
      props.toggleOatDraftFor(o._id, 'None', props.user._id)
    })*/

    setDeactivateOATQuestion(null)
    let path = "/core/legal/templates/" + activeOat.module + (type === 'deactivate' ? "/edit/" : "/edit-terms/") + activeOat._id;
    history.push(path);
  }

  const handleDeactivate = (type) => {
    setChangingURL(false)
    setDeactivateOATQuestion(type)
  }

  const handleCancelDeactivate = () => {
    setDeactivateOATQuestion(null)
  }
  

  const handleDeleteVersion = () => {
    setDeleteOATQuestion('version');
  }

  const handleDeleteTemplate = () => {
    setDeleteOATQuestion('template');
  }

  const handleConfirmDelete = () => {
    let oatForDelete = activeOat || props.orgAgrTemplates.filter((oat) => oat._id === props.oatid)[0]
    let path = 
        deleteOATQuestion === 'version' &&
        oatVersions.filter((o) => oatForDelete.blueprintID !== undefined && oatForDelete.blueprintID !== null && oatForDelete.blueprintID !== '' && o.blueprintID === oatForDelete.blueprintID && o._id !== oatForDelete._id)[0] !== undefined ?
            ("/core/legal/templates/" + oatForDelete.module + "/" +
             (oatForDelete.active || oatForDelete.draftFor === 'Template' ? "edit/" : "edit-terms/") +
             oatVersions.filter((o) => oatForDelete.blueprintID !== undefined && oatForDelete.blueprintID !== null && oatForDelete.blueprintID !== '' && o.blueprintID === oatForDelete.blueprintID && o._id !== oatForDelete._id)[0]._id) :
        oatForDelete.external || oatForDelete.draftFor === 'Terms' ? 
                  ("/core/legal/templates/" + oatForDelete.module + "/terms") :
                  ("/core/legal/templates/" + oatForDelete.module);

    if(deleteOATQuestion === 'template' && oatForDelete.blueprintID !== undefined && oatForDelete.blueprintID !== null &&
    oatForDelete.blueprintID !== '') { // You are confirm deleting the entire template

      // Need to consider where the active version is a default child, remove then
      //let oatForDeleteActiveVersion = props.orgAgrTemplates.filter((oat) => oat.blueprintID === oatForDelete.blueprintID && oat.active)[0] !== undefined ?
          //props.orgAgrTemplates.filter((oat) => oat.blueprintID === oatForDelete.blueprintID && oat.active)[0] : oatForDelete

      // Then remove all versions for this blueprintID, provided the blueprintID is not ''
      props.updateOATDeletion(oatForDelete.blueprintID, "deletebp_" + props.user.orgID, props.user._id)
      setDeleteOATQuestion(null)
      history.push(path);

    } else { // You are confirm deleting a version
      props.updateOATDeletion(oatForDelete._id, "delete_" + props.user.orgID, props.user._id)
      setDeleteOATQuestion(null)
      history.push(path);
    }
  }

  const handleCancelDelete = () => {
    setDeleteOATQuestion(null)
  }

  const handleRefresh = () => {
    history.push("/core/legal/templates/" + module + "/edit/" + props.oatid);
  }

  const handleExhibitSelectChange = (e) => {
    setExhibitToBeAdded(e.target.value);
  }

  const handleExhibitConfirmAdd = () => {
    let dc = activeOat.defaultChildren;
    dc.push(exhibitToBeAdded);
    props.updateOatNewDefaultChildren(activeOat._id, dc, false);
  }

  const handleExhibitConfirmRemove = (oatbpid) => {
    let dc = activeOat.defaultChildren.filter((o) => o !== oatbpid);
    props.updateOatNewDefaultChildren(activeOat._id, dc, false);
  }

  const RenderExhibitList = () => { // 'push' is agr screen, 'id' is avDiff Dialog
    return (
      <Box mb={3} mt={3}>
        <List dense>

          <ListItem button selected className={classes.exhList}>
            <ListItemText primary={"Main Body"} />
            {!addingExhibit && activeOat !== undefined && !activeOat.active && !activeOat.external ? 
            <ListItemSecondaryAction>
              <Button size="small" variant="text" color="primary" disableElevation onClick={e => setAddingExhibit(true)}>
                Add Default Exhibit&nbsp;&nbsp;<FontAwesomeIcon icon={faPlusCircle} />
              </Button>
            </ListItemSecondaryAction>
            : '' }
          </ListItem>

          {activeOat.defaultChildren
          .filter((oatbp) => props.orgAgrTemplates.some((o) => o.blueprintID === oatbp))
          .map((oatbp,i) => (
            <ListItem button key={oatbp}
            onClick={e => history.push('/core/legal/templates/' + module + '/edit/' + props.orgAgrTemplates.filter((oat) => oat.blueprintID === oatbp)[0]._id)}
            className={classes.exhList}>
              <ListItemText primary={
                props.orgAgrTemplates.filter((o) => o.blueprintID === oatbp)[0] !== undefined && 
                props.agrTypes.filter((at) => at._id === props.orgAgrTemplates.filter((o) => o.blueprintID === oatbp)[0].agrTypeID)[0] !== undefined ? 
                  ("Exhibit " + String.fromCharCode(97 + i).toUpperCase() + ": " + 
                  ((props.orgAgrTemplates.filter((o) => o.blueprintID === oatbp)[0].agrFullName !== undefined &&
                    props.orgAgrTemplates.filter((o) => o.blueprintID === oatbp)[0].agrFullName !== null && props.orgAgrTemplates.filter((o) => o.blueprintID === oatbp)[0].agrFullName !== '') ?
                      props.orgAgrTemplates.filter((o) => o.blueprintID === oatbp)[0].agrFullName :
                      props.agrTypes.filter((at) => at._id === props.orgAgrTemplates.filter((o) => o.blueprintID === oatbp)[0].agrTypeID)[0].fullName[0])) :
                  "Unknown Exhibit"} />
              {!addingExhibit && activeOat !== undefined && !activeOat.active && !activeOat.external ? 
              <ListItemSecondaryAction>
                <Button size="small" variant="text" color="secondary" disableElevation onClick={e => handleExhibitConfirmRemove(oatbp)}>
                  Remove&nbsp;&nbsp;<FontAwesomeIcon icon={faMinusCircle} />
                </Button>
              </ListItemSecondaryAction>
              : '' }
            </ListItem>
          ))}
        </List>
      </Box>      
    )
  }

  const handleUpdateAgrTypeSubmit = () => {
    
    if(newAgrTypeToggle) { // CREATING

      let alreadyExists = false;
      props.agrTypes.forEach((at) => {
        at.fullName.forEach((fn) => { if(fn.toLowerCase() === newAgrTypeFullName.toLowerCase()) { alreadyExists = true; } })
      })
      if(!alreadyExists) {

        axios.post('/agrtype/protect/agrtype', {
          relationshipType: ['b2b', 'partnership', 'people', 'corporate', 'terms'],
          shortName: newAgrTypeShortName,
          fullName: newAgrTypeFullName,
          agrPartType: 'Contract',
          orgID: props.org._id,
        })
        .then(function (res) { // got a msg from the server
          if(res.data.success) { // successfully added

            // Send notification email
            axios.post('/mail/canveoops', {
              subject: "New Agreement Type created",
              message: `<p>A new Agreement type was created by ` + props.user.displayName + ` from ` + props.org.legalName + `</p>
                <p>The new type is: <b>` + newAgrTypeFullName + ` (` + newAgrTypeShortName + `)</b> for relationship type(s): <b>ALL OF THEM.</b></p>
                <p>Please evaluate and determine whether the orgID needs to change to CANVEO in order to make it available across subscribers.</p>`,
            })

            props.addAgrType(res.data.data) // push the new AT into the reducer
            setManageAgrTypes(false)
            setNewAgrTypeShortName('')
            setNewAgrTypeFullName('')

          } else {
            setAgrTypeError("There was an issue creating the agreement type")
          }
        }).catch(function(err) { setAgrTypeError("Unable to create the agreement type") })

      } else {
        setAgrTypeError('This Agreement Type already exists')
      }

    } else { // UPDATING
      let at = props.agrTypes.filter((a) => a._id === agrTypeToBeUpdated)[0]
      let alreadyExists = false;
      if(props.agrTypes.some((a) => a.fullName.includes(newAgrTypeFullName))) { alreadyExists = true; }
  
      if(agrTypeToBeUpdated !== 'none' && newAgrTypeFullName !== '' && newAgrTypeFullName.length > 2 && newAgrTypeShortName !== '' &&
      newAgrTypeShortName.length > 1 && at !== undefined && !alreadyExists){
  
        props.updateAgrType(agrTypeToBeUpdated, at.relationshipType, newAgrTypeShortName, [newAgrTypeFullName], at.agrPartType)
  
      } else if (alreadyExists) {
        setAgrTypeError('This Agreement Type already exists')
      }
    }
  }

  const handleChangeAgrTypeSubmit = () => {
    setLoading(true);

    let newRef = newTemplateReference !== null ? newTemplateReference : activeOat.reference
 
    if((newAgrType !== null && newAgrType._id !== undefined && (newAgrType.inputValue === undefined || newAgrType.inputValue === null)) || // The agreement type changed
    (newAgrType === null && newTemplateReference !== null)) { // OR the reference changed

      props.updateOatAgrType(
        activeOat._id, 
        newAgrType !== null ? newAgrType._id : activeOat.agrTypeID, 
        newAgrType !== null ? newAgrType.fullName[0] : activeOat.agrFullName, 
        newAgrType !== null ? newAgrType.shortName : activeOat.agrShortName, 
        newRef, 
        props.user._id)

    } else if (newAgrType !== null && newAgrType.inputValue !== undefined && newAgrType.inputValue !== null) {

      let alreadyExists = false;
      props.agrTypes.forEach((at) => {
        at.fullName.forEach((fn) => { if(fn.toLowerCase() === newAgrType.inputValue.toLowerCase()) { alreadyExists = true; } })
      })
      if(!alreadyExists) {
        axios.post('/agrtype/protect/agrtype', {
          relationshipType: ['b2b', 'partnership', 'people', 'corporate', 'terms'],
          shortName: newAgrTypeShortName,
          fullName: newAgrType.inputValue,
          agrPartType: 'Contract',
          orgID: props.org._id,
        })
        .then(function (res) { // got a msg from the server
          if(res.data.success) { // successfully added

            // Send notification email
            axios.post('/mail/canveoops', {
              subject: "New Agreement Type created",
              message: `<p>A new Agreement type was created by ` + props.user.displayName + ` from ` + props.org.legalName + `</p>
                <p>The new type is: <b>` + newAgrType.inputValue + ` (` + newAgrTypeShortName + `)</b> for relationship type(s): <b>ALL OF THEM.</b></p>
                <p>Please evaluate and determine whether the orgID needs to change to CANVEO in order to make it available across subscribers.</p>`,
            })

            props.updateOatAgrType(activeOat._id, res.data.data._id, newAgrType.inputValue, newAgrTypeShortName, newRef, props.user._id)
            props.addAgrType(res.data.data) // push the new AT into the reducer

          } else {
            setActivationError("There was an issue creating the agreement type")
          }
        }).catch(function(err) { setActivationError("Unable to create the agreement type") })
      } else { 
        setActivationError("This agreement type already exists.")
      }
    }
  }

  const handleChangingURL = () => {
    var regex = /^[a-zA-Z0-9-_]+$/;
    if (newOatURL === null || newOatURL === '' || newOatURL.search(regex) === -1 || newOatURL.length < 2) { 
      setUrlError(true) // invalid oat url
    } else {
      setUrlError(false)
      props.orgAgrTemplates
      .filter((o) => o.module === activeOat.module && o.reference === activeOat.reference && o.agrTypeID === activeOat.agrTypeID && (o.external || o.draftFor === 'Terms'))
      .forEach((o) => {
        props.updateOatURL(o._id, newOatURL.toLowerCase(), props.user._id)
      })
    }
  }

  const handleChangingTerm = () => {

    props.updateOatEffectiveProperties(
      activeOat._id, 
      activeOat.effectiveDateType, 
      activeOat.renewalType, 
      changeTermDecision, 
      props.user._id)

  }

  const handleChangingEdt = (newEdt) => {

    props.updateOatEffectiveProperties(
      activeOat._id, 
      newEdt, 
      activeOat.renewalType, 
      activeOat.effectiveTerm, 
      props.user._id)

  }

  const handleChangingRnt = (newRnt) => {

    props.updateOatEffectiveProperties(
      activeOat._id, 
      activeOat.effectiveDateType, 
      newRnt, 
      activeOat.effectiveTerm, 
      props.user._id)

  }

  /*
  const handleChangingRef = () => {

    if(changeRefDecision === 'current' && !props.orgAgrTemplates.some((o) => o.agrTypeID === activeOat.agrTypeID && o.reference === newTemplateReference && o.module === activeOat.module && ((props.action === 'edit-terms' && (o.external || o.draftFor === 'Terms')) || (props.action === 'edit' && (o.active || o.draftFor === 'Template'))))) {
      props.updateOatReference(activeOat._id, newTemplateReference, props.user._id)

    } else if(changeRefDecision === 'copy' && !props.orgAgrTemplates.some((o) => o.agrTypeID === activeOat.agrTypeID && o.reference === newTemplateReference && o.module === activeOat.module && ((props.action === 'edit-terms' && (o.external || o.draftFor === 'Terms')) || (props.action === 'edit' && (o.active || o.draftFor === 'Template'))))) {

      handleCopySubmit(
        activeOat._id, 
        activeOat.agrTypeID, 
        newTemplateReference, 
        activeOat.active || activeOat.draftFor === "Template" ? "Template" : "Terms",
        module
      ) 

    } else if (props.orgAgrTemplates.some((o) => o.agrTypeID === activeOat.agrTypeID && o.reference === newTemplateReference && o.module === activeOat.module && ((props.action === 'edit-terms' && (o.external || o.draftFor === 'Terms')) || (props.action === 'edit' && (o.active || o.draftFor === 'Template'))))) {
      setActivationError("The Agreement Type / Reference combination already exists")
    }
  }*/

  const toggleChangingTerm = () => {
    setChangingTerm(!changingTerm)
    //setChangingRef(false)
    //setCopyToOtherModule(false)
    setChangingAgrType(false)
    setChangingEdt(false)
    setChangingRnt(false)
  }

  const toggleChangingRnt = () => {
    setChangingRnt(!changingRnt)
    //setChangingRef(false)
    //setCopyToOtherModule(false)
    setChangingAgrType(false)
    setChangingEdt(false)
    setChangingTerm(false)
  }

  const toggleChangingEdt = () => {
    setChangingEdt(!changingEdt)
    //setChangingRef(false)
    //setCopyToOtherModule(false)
    setChangingAgrType(false)
    setChangingRnt(false)
    setChangingTerm(false)
  }

  /*
  const toggleChangingRef = () => {
    setChangingRef(!changingRef)
    setCopyToOtherModule(false)
    setChangingAgrType(false)
    setChangingEdt(false)
    setChangingRnt(false)
    setChangingTerm(false)
  }

  const toggleCopyToOtherModule = () => {
    setCopyToOtherModule(!copyToOtherModule)
    setChangingRef(false)
    setChangingAgrType(false)
    setChangingEdt(false)
    setChangingRnt(false)
    setChangingTerm(false)
  }*/

  const toggleChangingAgrType = () => {
    setChangingAgrType(!changingAgrType)
    setNewAgrType(null)
    setNewTemplateReference(null)

    //setCopyToOtherModule(false)
    //setChangingRef(false)
    setChangingEdt(false)
    setChangingRnt(false)
    setChangingTerm(false)
  }

  const handleAgrTypeChange = (newValue) => {
    setAgrTypeToBeUpdated(newValue)
    let atype = props.agrTypes.filter((at) => at._id === newValue)[0]
    if(atype !== undefined) {
      setAgrTypeError(null)
      setNewAgrTypeFullName(atype.fullName[0])
      setNewAgrTypeShortName(atype.shortName)      
    }
  }
  
  const handleAgrTypeToggle = () => {
    setAgrTypeToBeUpdated('none')
    setNewAgrTypeFullName('')
    setNewAgrTypeShortName('')
    setAgrTypeError(null)
    setNewAgrTypeToggle(!newAgrTypeToggle)
  }
  
  const handleManageAgrTypes = (newVal) => {
    setAgrTypeToBeUpdated('none')
    setNewAgrTypeFullName('')
    setNewAgrTypeShortName('')
    setAgrTypeError(null)
    setManageAgrTypes(newVal) 
  }

  const handleTermChange = (termType, valnumber, newval) => {
    let curTerm = 
      termType === "initial" && changeTermDecision.initial !== undefined && changeTermDecision.initial !== null ? changeTermDecision.initial : 
      termType === "renewal" && changeTermDecision.renewal !== undefined && changeTermDecision.renewal !== null ? changeTermDecision.renewal : 
      "_"
    let underscoreIdx = curTerm.indexOf("_")
    let newTerm = 
      (valnumber === 'val1' ? newval : curTerm.substring(0, underscoreIdx)) +
      "_" +
      (valnumber === 'val2' ? newval : curTerm.substring(underscoreIdx + 1))

    setChangeTermDecision({
      ...changeTermDecision,
      isCustom: false,
      initial: termType === "initial" ? newTerm : changeTermDecision.initial,
      renewal: termType === "renewal" ? newTerm : changeTermDecision.renewal,
    })
  }

  const handleAgrTypeDelete = (atid) => {

    if(props.orgAgrTemplates.filter((oat) => oat.agrTypeID === atid)[0] !== undefined) {
      setAgrTypeError('This Agreement Type is being used for one or more templates.')
    } else {
      axios.get('/agr/agrsbyat/' + atid)
      .then((res) => {
        if(res.data.data.filter((a) => a.agrStatus !== 'Deactive').length === 0) {
          props.deleteAgrType(atid)
        } else {
          setAgrTypeError('This Agreement Type is being used for one or more agreements.')
        }
      }).catch((err) => console.log(err))
    }
  }

  const handleTemplateFunction = (type, val) => {
    window.scrollTo(0, 0);
    if(type === 'deactivate') { handleDeactivate('deactivate') }
    else if (type === 'unpublish') { handleDeactivate('unpublish') }
    else if (type === 'activate') { handleActivate('activate') }
    else if (type === 'publishing') { setPublishing(true) } 
    else if (type === 'publish') { handleActivate('publish') }
    else if (type === 'newdraft') { handleNewDraft() }
    else if (type === 'delete') { handleDeleteVersion() }
    else if (type === 'deleteTemplate') { handleDeleteTemplate() }
    else if (type === 'changingURL') { setChangingURL(true) }
    else if (type === 'changeagrtype') { toggleChangingAgrType() }
    //else if (type === 'changeref' && !activeOat.active && !activeOat.external) { toggleChangingRef() } 
    //else if (type === 'copyover' && !activeOat.active && !activeOat.external) { toggleCopyToOtherModule() }
    else if (type === 'changeedt') { toggleChangingEdt() }
    else if (type === 'changernt') { toggleChangingRnt() }
    else if (type === 'changeterm') { toggleChangingTerm() }
    else if (type === 'export') { handleExportTemplate() }
  }

  const agrHead = () => {
    return (
      <>
      {activationError !== null ? 
        <Box className={classes.boxPlain}>
          <Box mt={2}>
          <Typography variant="subtitle1" className={classes.warn} align="center">
            {activationError}
          </Typography>
          <Box mt={2} mb={2} align="center">
            {activationError.includes('redlines') ?
            <>
            <Button variant="contained" 
            color={props.action === 'edit-terms' || toggleFilter === 'clause' ? "secondary" : "primary"} disableElevation
            onClick={e => handleToggleFilter(e, 'clause')} size="small">
              {toggleFilter === 'clause' ? "Remove filter" : "Filter open issues"}
            </Button>
            &nbsp;&nbsp;
            </>
            :''}
            <Button variant="contained" color={props.action === 'edit-terms' ? "secondary" : "primary"} disableElevation
            onClick={e => setActivationError(null)} size="small">
              Got it
            </Button>
          </Box>
          </Box>
        </Box>
        
      :activeOat !== undefined && activeOat !== null && (deleteOATQuestion !== null || publishing || Boolean(deactivateOATQuestion) || changingURL) ? 
        <Box className={classes.boxPlain}>
          {changingURL ?
          <Box mt={2} align="center">
            <a href={activeOat.oaturl !== undefined && activeOat.oaturl !== null && activeOat.oaturl !== '' ?
              '/network/' + props.org.orgurl + '/' + activeOat.oaturl :
              '/network/' + props.org.orgurl + '/' + activeOat._id} 
            target="_blank" rel="noopener noreferrer"
            className="seclink">
              {activeOat.oaturl !== undefined && activeOat.oaturl !== null && activeOat.oaturl !== '' ?
                '/network/' + props.org.orgurl + '/' + activeOat.oaturl :
                '/network/' + props.org.orgurl + '/' + activeOat._id}
            </a>

            <Box mt={2} align="left" style={{width: '320px'}}>
              <TextField
                fullWidth
                label="Public terms URL"
                variant="outlined"
                color="secondary"
                defaultValue={
                  newOatURL !== '' ? newOatURL :
                  activeOat.oaturl !== undefined && activeOat.oaturl !== null && activeOat.oaturl !== '' ?
                  activeOat.oaturl : activeOat._id}
                onChange={e => setNewOatURL(e.target.value)}
              />
              {urlError ?
              <Box mt={1} align="center">
                  <Typography color="error" variant="body2">Please use only valid characters for the url<br/>(alphabetical, numeric or dash)</Typography>
              </Box>
              :''}
            </Box>
          </Box>
          :publishing ?
          <Box mt={2}>
          <Typography variant="subtitle1" className={classes.warn} align="center">This will make the terms visible to external viewers on your network page.</Typography>
          <Typography variant="subtitle1" className={classes.warn} align="center">For Templates that create agreements: go to the Templates Section.</Typography>
          <Typography variant="subtitle1" className={classes.warn} align="center">Are you sure you want to publish these terms?</Typography>
          </Box>
          :Boolean(deactivateOATQuestion) ?
          <Box mt={2}>
            <Typography variant="subtitle1" className={classes.warn} align="center">Are you sure you want to {deactivateOATQuestion === 'deactivate' ? "deactivate the Template" : "unpublish the Terms"}?</Typography>
            <Typography variant="subtitle1" className={classes.warn} align="center">The {deactivateOATQuestion === 'deactivate' ? "Template" : "Terms"} will be changed to a draft.</Typography>
          </Box>
          :
          <Box mt={2}>
            <Typography variant="subtitle1" className={classes.warn} align="center">Are you sure you want to delete the {deleteOATQuestion === 'template' ? "entire template" : "template version"}?</Typography>
            <Typography variant="subtitle1" className={classes.warn} align="center">This action cannot be undone.</Typography>
          </Box>
          }
          <Box mt={2} mb={2} align="center">
            <Button color={publishing || changingURL ? "secondary" : "default"} variant={"contained"} disableElevation
              onClick={
                Boolean(deactivateOATQuestion) ? 
                    e => handleConfirmDeactivate(deactivateOATQuestion) :
                publishing ? 
                    e => handleActivate('publish') :
                changingURL ? 
                    e => handleChangingURL() : 
                    e => handleConfirmDelete()} className={publishing || changingURL ? null : classes.deleteButton}>
                  {Boolean(deactivateOATQuestion) && deactivateOATQuestion === 'deactivate' ? 
                    "Yes, deactivate" : Boolean(deactivateOATQuestion) ? "Yes, unpublish" : 
                   publishing ? "Yes, publish" : 
                   changingURL ? "Update URL" : "Yes, delete"}</Button>
            <Button onClick={
              Boolean(deactivateOATQuestion) ? 
                e => handleCancelDeactivate() : 
              changingURL ? 
                e => setChangingURL(false) :  
              publishing ? 
                e => setPublishing(false) : 
                e => handleCancelDelete()}>Cancel</Button>
          </Box>
        </Box>

      :changingAgrType ? 
        <Box className={classes.boxPlain}>
          <Box mt={2} mb={1}>
            <Box mb={3}>
              <Typography className={classes.bold}>Change the Agreement Type / Reference</Typography>
            </Box>
            <Box mb={2}>
              <Autocomplete // AGR TYPE
                //disabled={activeOat.external || activeOat.active}
                value={
                  newAgrType !== null ? newAgrType :
                  activeOat !== undefined && props.agrTypes.filter((at) => at._id === activeOat.agrTypeID)[0] !== undefined ? 
                  props.agrTypes.filter((at) => at._id === activeOat.agrTypeID)[0].fullName[0] : ''}
                onChange={(e, newValue) => {
                    setNewAgrType(newValue);
                    if(typeof newValue.inputValue === 'string') {
                      setNewAgrTypeShortName(getAcronym(newValue.inputValue))
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        fullName: [`Create "${params.inputValue}" as a new Agreement Type`],
                      });
                    }
                    return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={props.agrTypes.sort((a,b) => (a.fullName[0] > b.fullName[0]) ? 1 : ((b.fullName[0] > a.fullName[0]) ? -1 : 0)) || []}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') { return option; } // Value selected with enter, right from the input
                    if (option.inputValue) { return option.inputValue; } // Add "xxx" option created dynamically
                    return option.fullName[0]; // Regular option
                }}
                renderOption={(option) => (
                    <Typography 
                    variant={option._id !== undefined ? "body1" : "subtitle1"}
                    color="textPrimary">
                    {option.fullName[0]}
                    </Typography>
                )}
                style={{ width: '100%' }}
                freeSolo
                renderInput={(params) => (
                    <TextField {...params} label={"Search Agreement Type"} variant="outlined" fullWidth
                    inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                    }} />
                )}
              />
            </Box>
            {newAgrType !== null && newAgrType.inputValue !== undefined && newAgrType.inputValue !== null ? 
            <Box mb={2}>

              <TextField
                fullWidth
                label="Short name"
                variant="outlined"
                value={newAgrTypeShortName}
                onChange={e => setNewAgrTypeShortName(e.target.value)}
                //onChange={e => setNewTemplateReference(e.target.value)}
              />

            </Box>
            : '' }
            <Box mb={2}>
            <TextField
              fullWidth
              label="Template Reference"
              placeholder="DEFAULT"
              variant="outlined"
              defaultValue={activeOat.reference !== undefined && activeOat.reference !== null && activeOat.reference !== '' ? activeOat.reference : "DEFAULT"}
              onChange={e => setNewTemplateReference(e.target.value)}
            />
            </Box>
            <Button variant="contained" 
            disabled={(newAgrType === null || newAgrType._id === activeOat.agrTypeID || 
              props.orgAgrTemplates.some((o) => o.agrTypeID === newAgrType._id && o.reference.toUpperCase() === activeOat.reference.toUpperCase() && o.module === activeOat.module && (
                  ((o.draftFor === 'Template' || o.active) && (activeOat.draftFor === 'Template' || activeOat.active)) ||
                  ((o.draftFor === 'Terms' || o.external) && (activeOat.draftFor === 'Terms' || activeOat.external))
                ))) && (newTemplateReference === null || newTemplateReference === '' || newTemplateReference.toUpperCase() === activeOat.reference.toUpperCase())} 
            onClick={handleChangeAgrTypeSubmit}
            color={props.action === 'edit' ? "primary" : "secondary"} disableElevation fullWidth>
              Submit
            </Button>
            <Box mt={1} align="right">
              <Button variant="text" color={props.action === 'edit' ? "primary" : "secondary"} onClick={toggleChangingAgrType}>Cancel</Button>
            </Box>
          </Box>
        </Box>
      /*
      :copyToOtherModule ? 
        <Box className={classes.boxPlain}>
          <Box mt={2} mb={1}>
            <Box mb={2}>
              <Typography className={classes.bold}>Copy {props.action === 'edit' ? "the template" : "the terms"} to a different module</Typography>
            </Box>

            <Box mt={2} mb={2}>
              <RadioTargetModule
                relevantOat={activeOat}
                relevantRef={activeOat.reference}
                relevantSection={activeOat.external || activeOat.draftFor === "Template" ? "Template" : "Terms"}
                targetModule={targetModule}
                changeTargetModule={setTargetModule}
                orgAgrTemplates={props.orgAgrTemplates}
                modules={modules}
                action={props.action}
              />
            </Box>
            <Button variant="contained" disabled={targetModule === null}
            color={props.action === 'edit' ? "primary" : "secondary"} onClick={e => handleCopySubmit(activeOat._id, activeOat.agrTypeID, activeOat.reference, activeOat.active || activeOat.draftFor === 'Template' ? 'Template' : 'Terms', targetModule)} disableElevation fullWidth>Submit</Button>
            <Box mt={1} align="right">
              <Button variant="text" color={props.action === 'edit' ? "primary" : "secondary"} onClick={toggleCopyToOtherModule}>Cancel</Button>
            </Box>
          </Box>
        </Box>

      :changingRef ?
        <Box className={classes.boxPlain}>
          <Box mt={2} mb={1}>
            <Box mb={2}>
              <Typography className={classes.bold}>Change reference or copy into {props.action === 'edit' ? "a new template" : "new terms"}</Typography>
            </Box>          
            <TextField
              fullWidth
              label="Template Reference"
              placeholder="DEFAULT"
              variant="outlined"
              style={{marginTop: '16px'}}
              defaultValue={activeOat.reference !== undefined && activeOat.reference !== null && activeOat.reference !== '' ? activeOat.reference : "DEFAULT"}
              onChange={e => setNewTemplateReference(e.target.value)}
            />
            <Box mt={2} mb={2}>
              <RadioGroup name="changeRefDecision" value={changeRefDecision} onChange={e => setChangeRefDecision(e.target.value)}>
                <FormControlLabel value="current" control={<Radio />} label={"Change reference of the current " + (props.action === 'edit' ? "template" : "terms")} />
                <FormControlLabel value="copy" control={<Radio />} label={"Copy into" + (props.action === 'edit' ? " a new template " : " new terms ") + "with the reference provided"} />
              </RadioGroup>
            </Box>
            <Button variant="contained" disabled={changeRefDecision === undefined || changeRefDecision === null || newTemplateReference === null || newTemplateReference.length < 3 || newTemplateReference.length > 100}
            color={props.action === 'edit' ? "primary" : "secondary"} onClick={handleChangingRef} disableElevation fullWidth>Submit</Button>
            <Box mt={1} align="right">
              <Button variant="text" color={props.action === 'edit' ? "primary" : "secondary"} onClick={toggleChangingRef}>Cancel</Button>
            </Box>
          </Box>
        </Box>
      */
      :changingEdt ?
        <Box className={classes.boxPlain}>
          <Box mt={2} mb={1}>
            <Box mb={2}>
              <Typography className={classes.bold}>Change how the Effective date of the agreement is determined:</Typography>
            </Box>          
            <Box mb={2}>
              <RadioGroup name="changeEdtDecision" value={changeEdtDecision} onChange={e => setChangeEdtDecision(e.target.value)}>
                {effectivedatetypes.map((edt) => (
                  <FormControlLabel key={edt.val} value={edt.val} control={<Radio />} label={edt.long}
                  disabled={
                    (smartFields.some((sf) => sf.name === "Agreement Effective Date") && edt.val !== 'smartfield') ||
                    (!smartFields.some((sf) => sf.name === "Agreement Effective Date") && edt.val === 'smartfield')} />
                ))}
              </RadioGroup>
            </Box>
            <Button variant="contained" 
            color={"primary"} onClick={e => handleChangingEdt(changeEdtDecision)} disableElevation fullWidth>Submit</Button>
            <Box mt={1} align="right">
              <Button variant="text" color={"primary"} onClick={toggleChangingEdt}>Cancel</Button>
            </Box>
          </Box>
        </Box>

      :changingRnt ?
        <Box className={classes.boxPlain}>
          <Box mt={2} mb={1}>
            <Box mb={2}>
              <Typography className={classes.bold}>Change which renewal type applies to this agreement:</Typography>
            </Box>          
            <Box mb={2}>
              <RadioGroup name="changeRntDecision" value={changeRntDecision} onChange={e => setChangeRntDecision(e.target.value)}>
                {renewaltypes.map((edt) => (
                  <FormControlLabel key={edt.val} value={edt.val} control={<Radio />} label={edt.long} />
                ))}
              </RadioGroup>
            </Box>
            <Button variant="contained" 
            color={"primary"} onClick={e => handleChangingRnt(changeRntDecision)} disableElevation fullWidth>Submit</Button>
            <Box mt={1} align="right">
              <Button variant="text" color={"primary"} onClick={toggleChangingRnt}>Cancel</Button>
            </Box>
          </Box>
        </Box>

      :changingTerm ?
        <Box className={classes.boxPlain}>
          <Box mt={2} mb={1}>
            <Box mb={2}>
              <Typography className={classes.bold}>Change the Term of the agreement:</Typography>
            </Box>          
            <Box mb={(changeTermDecision.initial !== undefined && changeTermDecision.initial !== null && changeTermDecision.initial.indexOf("_") > -1) ||
                (changeTermDecision.renewal !== undefined && changeTermDecision.renewal !== null && changeTermDecision.renewal.indexOf("_") > -1) ? 1 : 2}>

              {/*<RadioGroup name="changeTermDecision" value={
                changeTermDecision.isCustom !== undefined &&
                changeTermDecision.isCustom !== null && changeTermDecision.isCustom ? "custom" :
                changeTermDecision.isCustom !== undefined &&
                changeTermDecision.isCustom !== null && !changeTermDecision.isCustom ? "defined" : null} onChange={e => setChangeTermDecision({...changeTermDecision, isCustom: e.target.value === "custom"})}>
                  <FormControlLabel key={"custom"} value={"custom"} control={<Radio />} label={"The term of the agreement is custom"} />
                  <FormControlLabel key={"defined"} value={"defined"} control={<Radio />} label={"The term of the agreement is defined" + (changeTermDecision.isCustom !== undefined &&
                changeTermDecision.isCustom !== null && !changeTermDecision.isCustom ? ":" : " ...")} />
              </RadioGroup>

              <Collapse in={changeTermDecision.isCustom !== undefined &&
              changeTermDecision.isCustom !== null && !changeTermDecision.isCustom}>*/}
              <Box mt={1}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs={4}>
                    <Box mt={2} mb={2}>Initial Term:</Box>
                  </Grid>
                  <Grid item xs={8}>
                    <SmartFieldEditable
                    fromClause={false}
                    type={'date-period'}
                    val1={changeTermDecision.initial !== undefined && changeTermDecision.initial !== null && changeTermDecision.initial.indexOf("_") > -1 ?
                      changeTermDecision.initial.substring(0, changeTermDecision.initial.indexOf("_")) : ''}
                    val2={changeTermDecision.initial !== undefined && changeTermDecision.initial !== null && changeTermDecision.initial.indexOf("_") > -1 ?
                      changeTermDecision.initial.substring(changeTermDecision.initial.indexOf("_") + 1) : ''}
                    onFieldChange={handleTermChange}
                    sfid={"initial"} />
                  </Grid>
                </Grid>
                {//activeOat.renewalType !== 'none' ?
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs={4}>
                    <Box mt={2} mb={2}>Renewal Term:</Box>
                  </Grid>
                  <Grid item xs={8}>
                    <SmartFieldEditable                        
                    fromClause={false}
                    type={'date-period'}
                    val1={changeTermDecision.renewal !== undefined && changeTermDecision.renewal !== null && changeTermDecision.renewal.indexOf("_") > -1 ?
                      changeTermDecision.renewal.substring(0, changeTermDecision.renewal.indexOf("_")) : ''}
                    val2={changeTermDecision.renewal !== undefined && changeTermDecision.renewal !== null && changeTermDecision.renewal.indexOf("_") > -1 ?
                      changeTermDecision.renewal.substring(changeTermDecision.renewal.indexOf("_") + 1) : ''}
                    onFieldChange={handleTermChange}
                    sfid={"renewal"} />
                  </Grid>
                </Grid>
                /*: ''*/}
                {(changeTermDecision.initial !== undefined && changeTermDecision.initial !== null && changeTermDecision.initial.indexOf("_") > -1) ||
                (changeTermDecision.renewal !== undefined && changeTermDecision.renewal !== null && changeTermDecision.renewal.indexOf("_") > -1) ?
                <Box align="right" mt={1}>
                  <Button variant="text" color="primary"
                  onClick={e => setChangeTermDecision({...changeTermDecision, isCustom: true, initial: null, renewal: null})}
                  >Clear term</Button>
                </Box>
                :''}
              </Box>
              {/*</Collapse>*/}

            </Box>
            <Button variant="contained" 
            color={"primary"} onClick={e => handleChangingTerm()} disableElevation fullWidth>Submit</Button>
            <Box mt={1} align="right">
              <Button variant="text" color={"primary"} onClick={toggleChangingTerm}>Cancel</Button>
            </Box>
          </Box>
        </Box>

      :activeOat !== undefined && (
        (!activeOat.active && activeOat.draftFor === 'Template' && ['maxAmt', 'both'].includes(freezeTemplating)) ||
        (!activeOat.external && activeOat.draftFor === 'Terms' && ['noPublish', 'both'].includes(freezeTemplating))) ? 
      <Box mt={3}>
        <Typography variant="subtitle1" color="textSecondary">
          An upgrade is required to
          {['maxAmt', 'both'].includes(freezeTemplating) ? " activate or publish additional templates." :
           ['noPublish', 'both'].includes(freezeTemplating) ? " publish your terms" : " have full functionality"}
          {props.user.role === 'Admin' ?
          <Button variant="text" color="secondary" component={RouterLink} to="/core/admin/company/subscription/upgrade">Upgrade</Button>
          :''}
        </Typography>
      </Box>
      : '' }

      {// Always show the default exhibit list
      activeOat !== undefined && ((!activeOat.active && !activeOat.external) || activeOat.defaultChildren.length > 0) ? 
      <RenderExhibitList />
      : '' }
      </>
    )
  }

  switch(props.action) {
  case 'edit':
  case 'edit-terms':

    return(
      <div className={classes.root}>

      <Header module="legal" 
        templating 
        templateInDraft={activeOat !== null && activeOat !== undefined && !activeOat.external && !activeOat.active && ['Terms', 'Template'].includes(activeOat.draftFor)}
        oatVersions={oatVersions}
        handleDiffDialogOpen={handleDiffDialogOpen}
        handleToggleFilter={handleToggleFilter} 
        toggleFilter={toggleFilter}
        active={props.action === 'edit-terms' ? "Terms" : "Templates"}
        crumbs={[
          {name: props.action === 'edit' ? "Templates" : "Terms", to: props.action === "edit" ? "/core/legal/templates" : "/core/legal/templates/terms/terms"},
          {name: getActorsForModule(module),
           to: props.action === 'edit' ? ("/core/legal/templates/" + module) : ("/core/legal/templates/" + module + "/terms")},
          {name: "Edit", to: "active"}
        ]}
        user={props.user} 
        org={props.org}
        modules={modules}
        notifications={props.notifications}
        markNotificationAsRead={props.markNotificationAsRead}
        markAllNotificationsAsRead={props.markAllNotificationsAsRead} 
        className={classes.header} />

      <Container maxWidth="md" className={classes.content}>
      <Grid container direction="column" justify="flex-start" alignItems="center" className={classes.outerFlexGrid}>
        
        {fetchError !== null ?
        <>
          <Typography align="center">There was an error retrieving the data.</Typography>
          <Typography align="center">Please try again or contact Canveo Support if the issue persists.</Typography>
          {/*<Typography align="center" variant="subtitle1">Details: {fetchError}</Typography>*/}
        </>
        :
        // CHECK IF DONE WITH INITIAL LOADING OF AGREEMENT CONTENT - No content for the agreement
        activeOat !== null && oatClauses !== null && clauses !== null && props.users !== undefined && 
        props.users.length > 0 && clauses.length === 0 ?
        <Box mt={5} mb={5} align="center">
          <Typography variant="h6" align="center">We're still working on this agreement...</Typography>
          <Typography variant="body1" align="center">Try refreshing in a few minutes.</Typography>
          <Box mt={3}>
            <Button onClick={handleRefresh} color="primary">Refresh the Agreement</Button>&nbsp;&nbsp;
            <Button onClick={handleConfirmDelete} color="primary">Delete</Button>
          </Box>
        </Box>
        :
        // YOU ARE ADDING A DEFAULT EXHIBIT TO THE TEMPLATE
        addingExhibit ? 
        <Box mt={5} mb={5} align="center">

          <Typography variant="h3" className={classes.bold}>
            {activeOat !== null && activeOat.agrFullName !== undefined && activeOat.agrFullName !== null && activeOat.agrFullName !== '' ?
                activeOat.agrFullName :
             props.agrTypes.filter((at) => at._id === activeOat.agrTypeID)[0] !== undefined ?
                props.agrTypes.filter((at) => at._id === activeOat.agrTypeID)[0].fullName[0] : "Agreement"}
          </Typography>

          <Box mt={2}>
            <RenderExhibitList />
          </Box>

          <Box mt={2} mb={5}>
            <Typography>Select a default exhibit to be added.</Typography>
            <Typography>Note: A Template needs to be "Active" before you can add it as a default exhibit.</Typography>
          </Box>

          <Box align="left" style={{width: '300px'}}>
          <FormControl variant="outlined">
            <InputLabel id="template-select">Active Templates</InputLabel>
            <Select
              labelId="template-select"
              value={exhibitToBeAdded}
              onChange={handleExhibitSelectChange}
              label="Active Templates"
              style={{width: '300px'}}
            >
              <MenuItem value={"none"}><em>Select Exhibit</em></MenuItem>
              {props.orgAgrTemplates
              .filter((oat) => oat.module.includes(module) && oat.blueprintID !== undefined && activeOat.blueprintID !== oat.blueprintID &&
              oat.blueprintID !== null && oat.blueprintID !== '' && !activeOat.defaultChildren.includes(oat.blueprintID) && oat.active)
              //.filter((oat) => oat.module.includes(module) && oat.blueprintID !== undefined && activeOat.blueprintID !== oat.blueprintID &&
                //oat.blueprintID !== null && oat.blueprintID !== '' && !activeOat.defaultChildren.includes(oat.blueprintID))
              .map((oat) => (
                <MenuItem value={oat.blueprintID} key={oat._id}>
                {trunc((props.agrTypes.filter((at) => at._id === oat.agrTypeID)[0].fullName[0]) + " - " + (oat.reference !== undefined && oat.reference !== null && oat.reference !== '' ? oat.reference : 'DEFAULT'), 40)}
                </MenuItem>
              ))
              }
            </Select>
          </FormControl>
          </Box>

          <Box mt={5} mb={3}>
            <Button variant="text" color="primary" onClick={e => history.push('/core/legal/templates/' + module + '/edit/' + activeOat._id)}><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Cancel</Button>&nbsp;&nbsp;
            <Button variant="contained" color="primary" disableElevation disabled={exhibitToBeAdded === "none"}
            onClick={handleExhibitConfirmAdd}>Add Exhibit</Button>
          </Box>

        </Box>
        :
        // CHECK IF DONE WITH INITIAL LOADING OF AGREEMENT CONTENT
        activeOat !== null && oatClauses !== null && clauses !== null && props.users !== undefined && plainMode !== null &&
        props.users.length > 0 && !loading ?

          <AgreementEditor
            templating={true} // whether templating or negotiating
            agrHead={agrHead}
            clauses={clauses}
            avClauses={oatClauses}
            plainMode={plainMode}
            edited={true}
            laterVersionOwnedByCpty={false}
            toggleFilter={toggleFilter}
            clauseActivityLog={[]}
            user={props.user}
            users={props.users}
            org={props.org}
            handleTemplateFunction={handleTemplateFunction}
            rid={''}
            rel={null}
            agrID={''}
            clid={props.clid}
            oatStatus={activeOat.active ? 'Active' : activeOat.external ? 'Published' : ['Terms','Template'].includes(activeOat.draftFor) ? 'Draft' : 'Inactive'}
            agrStatus={''}
            agrTypes={props.agrTypes}
            agrFullName={
              activeOat.agrFullName !== undefined && activeOat.agrFullName !== null && activeOat.agrFullName !== '' ?
                activeOat.agrFullName :
              props.agrTypes !== undefined && activeOat !== null && props.agrTypes.filter((at) => at._id === activeOat.agrTypeID)[0] !== undefined ?
                props.agrTypes.filter((at) => at._id === activeOat.agrTypeID)[0].fullName[0] : ''}
            agrShortName={
              activeOat.agrShortName !== undefined && activeOat.agrShortName !== null && activeOat.agrShortName !== '' ?
                activeOat.agrShortName :
              props.agrTypes !== undefined && activeOat !== null && props.agrTypes.filter((at) => at._id === activeOat.agrTypeID)[0] !== undefined ?
                props.agrTypes.filter((at) => at._id === activeOat.agrTypeID)[0].shortName : ''}
            avID={''}
            avv={''}
            editMode={'full'}
            activeOat={activeOat}
            oatVersions={oatVersions}
            openDiffDialog={openDiffDialog}
            handleDiffDialogClose={handleDiffDialogClose}
            entID={''}
            module={module}
            reviewTicketsExist={false}
            reviewTicketsActive={false}
            updateAgrvRemoveClause={props.updateOATRemoveClause} // Note - for OATS this function is sent to updateOATRemoveClause
            updateClauseContentFAF={props.updateClauseContentFAF}
            updateClauseStatus={props.updateClauseStatus}
            updatePendingChange={props.updatePendingChange}
            updateClauseNumbering={props.updateClauseNumbering}
            updateAgrvNewClauses={props.updateOATNewClauses} // OAT equivalent
            updateAgrNames={props.updateOatNames} // OAT equivalent
            updateTicket={props.updateTicket}
            lastUpdateTicket={props.lastUpdateTicket}
            createComment={props.createComment}
            updateComment={props.updateComment}
            createTicketWithCommentFAF={null}
            createNotification={props.createNotification}
            createSmartField={props.createSmartField}
            updateSmartField={props.updateSmartField}
            smartFields={smartFields}
            curCpty={"primary"}
            primaryCpty={{}}
            secondaryCpty={{}}
            parentCall={() => void 0}
            handleActivityLog={null}
            freezeTemplating={freezeTemplating}
            handleToggleFilter={handleToggleFilter}
            handleSmartModeToggle={e => props.defineSmartMode({templating: !props.userSession.smartMode.templating, agreement: props.userSession.smartMode.agreement})} 
            smartMode={
              props.org !== undefined && props.org.clientType !== undefined && !['custE', 'legalOrg'].includes(props.org.clientType) ? false :
              props.userSession.smartMode.templating}
          />

        : // STILL DOING INITIAL LOADING OF AGREEMENT CONTENT

        <Grid container alignItems="center" justify="center">

          <SkeletonCustom type="agreement" />

        </Grid>
        }

      </Grid>
      </Container>
      </div>
    );




  case 'new':
  case 'new-terms': 

    return(
      <div className={classes.root}>

      <Header module="legal"
        active={props.action === 'new-terms' ? "Terms" : "Templates"}
        crumbs={[
          {name: props.action === 'new-terms' ? "Terms" : "Templates", to: props.action === 'new-terms' ? "/core/legal/templates/terms/terms" : "/core/legal/templates"},
          {name: getActorsForModule(module),
           to: props.action === 'new' ? ("/core/legal/templates/" + module) : ("/core/legal/templates/" + module + "/terms")},
          {name: "New", to: "active"}
        ]}
        user={props.user} 
        org={props.org}
        modules={modules}
        withSubHead={false}
        notifications={props.notifications}
        markNotificationAsRead={props.markNotificationAsRead}
        markAllNotificationsAsRead={props.markAllNotificationsAsRead} 
        className={classes.header} />

      {manageAgrTypes && !newAgrTypeToggle ? <FAB iconType="plus" click={handleAgrTypeToggle} /> : '' }

      <Container maxWidth="md" className={classes.content}>
      <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
        <Grid item>
          {manageAgrTypes ?
          <Typography align="center" variant="h4" className={classes.bold}>
            Manage Agreement Types
          </Typography>
          :
          <Typography align="center" variant="h4" className={classes.bold}>
            Create{props.action === 'new-terms' ? "" : " a"} new {props.action === 'new-terms' ? "terms" : "template"} for {getActorsForModule(module)}
          </Typography>
          }
        </Grid>
        
        {manageAgrTypes ?
        <>
        <Grid item>
          <Box mt={5} mb={5}>
          <Typography align="center" variant="body1">
            Manage agreement types created and owned by your organisation
          </Typography>
          {agrTypeError !== null ?
          <Typography align="center" color="error">{agrTypeError}</Typography>
          :''}
          </Box>
        </Grid>
        <Grid item>
          <Box mt={0} mb={5} align="center">
            <Box align="left" style={{width: '320px'}}>
              {!newAgrTypeToggle ?
              <FormControl variant="outlined">
                <InputLabel id="template-select">Agreement types</InputLabel>
                <Select
                  labelId="agrtype-select"
                  value={agrTypeToBeUpdated}
                  onChange={e => handleAgrTypeChange(e.target.value)}
                  label="Agreement Types"
                  style={{width: '320px'}}
                >
                  <MenuItem value={"none"}><span className={classes.greyishOption}>Select Agreement Type</span></MenuItem>
                  {props.agrTypes
                  .filter((at) => at.orgID === props.org._id)
                  .sort((a,b) => (a.fullName[0] > b.fullName[0]) ? 1 : ((b.fullName[0] > a.fullName[0]) ? -1 : 0))
                  .map((at) => (
                    <MenuItem key={at._id} value={at._id}>{at.fullName[0]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              :''}

              {agrTypeToBeUpdated !== "none" || newAgrTypeToggle ?
              <>
                <Box mb={2} mt={newAgrTypeToggle ? 0 : 2}>
                  <TextField
                    fullWidth
                    label="Full name"
                    variant="outlined"
                    value={newAgrTypeFullName}
                    onChange={e => setNewAgrTypeFullName(e.target.value)}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    label="Short name"
                    variant="outlined"
                    value={newAgrTypeShortName}
                    onChange={e => setNewAgrTypeShortName(e.target.value)}
                  />
                </Box>
              </>
              : '' }

              <Box mt={2} mb={2}>
              <Button variant="contained" 
              disabled={(!newAgrTypeToggle && agrTypeToBeUpdated === "none") || (newAgrTypeToggle && (newAgrTypeFullName.length < 3 || newAgrTypeShortName.length < 2))} 
              onClick={handleUpdateAgrTypeSubmit}
              color={"primary"} disableElevation fullWidth>
                {newAgrTypeToggle ? "Create" : "Update"} Agreement Type
              </Button>
              <Box mt={1} align="right">
                <Grid container direction="row" alignItems="center" justify="space-between">
                  <Grid item>
                    {agrTypeToBeUpdated !== "none" && !newAgrTypeToggle ?
                    <Button variant="text" className={classes.warn} onClick={e => handleAgrTypeDelete(agrTypeToBeUpdated)}>Delete</Button>
                    :''}
                  </Grid>
                  <Grid item>
                    <Button variant="text" color={"primary"} onClick={newAgrTypeToggle ? e => handleAgrTypeToggle() : e => handleManageAgrTypes(false)}>Cancel</Button>
                  </Grid>
                </Grid>
              </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        </>
        :
        <>
        <Grid item>
          <Box mt={5} mb={5}>
          <Typography align="center" variant="body1">
            Select the basis for the new {props.action === 'new-terms' ? "Terms" : "Template"}:
          </Typography>
          </Box>
        </Grid>
        <Box mt={0} mb={3} align="center">
        <Grid item container direction="row" justify="center" alignItems="flex-start" className={classes.thumbContainer}>
          <Grid item xs={6} /*sm={6}*/ className={classes.gridForThumb}>
            {<ThumbTodo
            route={"click"} 
            click={e => setImportOpen(true)}
            icon={faFileWord} 
            line1={"Import"} 
            line2={"Word document (DOCX)"}
            color={props.action === 'new-terms' ? "secondary" : "primary"} />}
          </Grid>
          <Grid item xs={6} className={classes.gridForThumb}>
            <ThumbTodo
              route={"/core/legal/templates/" + module + (props.action === 'new-terms' ? "/copy-terms" : "/copy")} 
              icon={faCopy} 
              line1={"Copy"} 
              line2={"Copy from existing"}
              color={props.action === 'new-terms' ? "secondary" : "primary"} />
          </Grid>
        </Grid>
        </Box>
        <Box mt={0} mb={3} align="center">
          <Button color={props.action === 'new-terms' ? "secondary" : "primary"} variant="text" onClick={e => handleManageAgrTypes(true)}>
            <FontAwesomeIcon icon={faTag} />&nbsp;&nbsp;
            Manage Agreement types
          </Button>
          <Box mt={3} align="center">
            <Button color={props.action === 'new-terms' ? "secondary" : "primary"} variant="text" onClick={e => history.push('/core/legal/templates/' + props.module + (props.action === 'new-terms' ? '/terms' : ''))}
            ><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Go back</Button>
          </Box>
        </Box>
        </>
        }
      </Grid>

      <DialogImportDoc
        importOpen={importOpen}
        handleImportClose={handleImportClose}
        handleFinalizeImport={handleFinalizeImport}
        agrTypes={props.agrTypes}
        relType={module === 'sales' || module === 'procurement' ? 'b2b' :
                 module === 'partnerships' ? 'partnership' :
                 module === 'people' ? 'people' :
                 module === 'corporate' ? 'corporate' :
                 module === 'privacy' ? 'terms' : 'err'}
        orgAgrTemplates={props.orgAgrTemplates}
        org={props.org}
        user={props.user}
        relevantSection={props.action === 'new-terms' ? "Terms" : "Template"}
        action={props.action}
      />
      
      </Container>
      </div>
    );

  case 'copy':
  case 'copy-terms':
  case 'copy-agr':
  //case 'create':

    return(
      <div className={classes.root}>

      <Header module="legal"
        active={props.action === 'copy-terms' ? "Terms" : "Templates"}
        crumbs={[
          {name: props.action === 'copy-terms' ? "Terms" : "Templates", to: props.action === 'copy-terms' ? "/core/legal/templates/terms/terms" : "/core/legal/templates"},
          {name: "Copy" + (props.action === 'copy-agr' ? " from Agreement" : ""), to: "active"}
        ]}
        user={props.user} 
        org={props.org}
        modules={modules}
        withSubHead={false}
        notifications={props.notifications}
        markNotificationAsRead={props.markNotificationAsRead}
        markAllNotificationsAsRead={props.markAllNotificationsAsRead} 
        className={classes.header} />

      <Container maxWidth="md" className={classes.content}>
      <Grid container direction="column" justify="flex-start" alignItems="center" className={classes.outerFlexGrid}>
        <Grid item>
          <Typography align="center" variant="h4" className={classes.bold}>Create {props.action === 'copy-terms' ? "terms" : "template"} from {props.action === 'copy-agr' ? "an agreement" : "a copy"}</Typography>
        </Grid>
        <Grid item>
          <Box mt={4} mb={4} className={classes.boxResizing}>
            <Grid direction="column" container justify="center" alignItems="stretch">
              {['copy', 'copy-terms'].includes(props.action) ?
              <Grid item>
                <Box mt={2} mb={2}>
                <Autocomplete
                  id="select-source-template"
                  options={
                    props.orgAgrTemplates
                    .filter((o) => props.agrTypes.some((a) => a._id === o.agrTypeID))
                    .sort((a,b) => (props.agrTypes.filter((at) => at._id === a.agrTypeID)[0].fullName[0] > props.agrTypes.filter((at) => at._id === b.agrTypeID)[0].fullName[0]) ? 1 : ((props.agrTypes.filter((at) => at._id === b.agrTypeID)[0].fullName[0] > props.agrTypes.filter((at) => at._id === a.agrTypeID)[0].fullName[0]) ? -1 : 0))}
                  renderOption={(option) => (
                    <Grid direction="column" container>
                      <Grid item><Typography variant="subtitle2" className={classes.bold}>
                          {props.agrTypes.filter((at) => at._id === option.agrTypeID)[0] !== undefined ? props.agrTypes.filter((at) => at._id === option.agrTypeID)[0].fullName[0] : ''}
                          <span className={option.external ? classes.publishedSpan : option.active ? classes.activeSpan : classes.draftSpan}>
                            {option.external ? "PUBLISHED" : option.active ? "ACTIVE" : ("DRAFT" + (option.draftFor === "Template" ? " TEMPLATE" : option.draftFor === "Terms" ? " TERMS" : ""))}
                          </span>
                      </Typography></Grid>
                      <Grid item><Typography variant="body2">{option.reference === undefined || option.reference === null || option.reference === '' ? 'DEFAULT' : option.reference} - For {getActorsForModule(option.module)}</Typography></Grid>
                    </Grid>
                  )}
                  onChange={(e, value) => {
                    handleTemplateChange(value)
                    setNewTemplateReference(value !== null && value.reference !== undefined ? value.reference : '')
                  }}
                  getOptionLabel={option => (props.agrTypes.filter((at) => at._id === option.agrTypeID)[0] !== undefined ? props.agrTypes.filter((at) => at._id === option.agrTypeID)[0].fullName[0] : '')}
                  style={{ width: '100%' }}
                  renderInput={params => (
                    <TextField 
                      {...params} 
                      label="Select source" 
                      variant="outlined" 
                      fullWidth />
                  )}
                />
                </Box>
              </Grid>
              : '' }

              <Grid item>
                {(sourceOATID !== undefined && sourceOATID !== null && sourceOATID !== '' && 
                      props.orgAgrTemplates.filter((o) => o._id === sourceOATID)[0] !== undefined) ||
                  props.action === 'copy-agr' ?
                <Box mt={1} mb={2}>
                  <Box mb={3}>
                    <TextField
                      fullWidth
                      label={"Target " + (props.action === 'copy-terms' ? "Terms" : "Template") + " Reference"}
                      variant="outlined"
                      value={newTemplateReference}
                      //defaultValue={activeOat.reference !== undefined && activeOat.reference !== null && activeOat.reference !== '' ? activeOat.reference : "DEFAULT"}
                      onChange={e => setNewTemplateReference(e.target.value)}
                    />
                  </Box>
                  <RadioTargetModule
                    withLabel
                    relevantOat={props.action === 'copy-agr' ? { agrTypeID: '' } : props.orgAgrTemplates.filter((o) => o._id === sourceOATID)[0]}
                    relevantRef={newTemplateReference}
                    relevantSection={props.action === 'copy-terms' ? "Terms" : "Template"}
                    targetModule={targetModule}
                    changeTargetModule={setTargetModule}
                    orgAgrTemplates={props.orgAgrTemplates}
                    modules={modules}
                    action={props.action}
                  />
                </Box>
                :''}
              </Grid>
              <Grid item>
                <Box mt={2} mb={2}>
                  <Button fullWidth variant="contained" disableElevation color="primary" 
                  disabled={targetModule === undefined || targetModule === null || newTemplateReference === undefined || newTemplateReference === null ||
                    (props.action !== 'copy-agr' && (sourceOATID === undefined || props.orgAgrTemplates.filter((o) => o._id === sourceOATID)[0] === undefined || sourceOATID === null))}
                  onClick={['copy-agr'].includes(props.action) ?
                    e => handleSaveFromAgreement() :
                    e => handleCopySubmit(sourceOATID, props.orgAgrTemplates.filter((o) => o._id === sourceOATID)[0].agrTypeID, newTemplateReference, props.action === 'copy-terms' ? "Terms" : "Template", targetModule)}>Submit</Button>
                </Box>
                {props.action !== 'copy-agr' ?
                <Box mb={2} align="center">
                  <Button color={props.action === 'copy-terms' ? "secondary" : "primary"} variant="text" onClick={e => history.push('/core/legal/templates/' + props.module + '/' + (props.action === 'copy-terms' ? 'new-terms' : 'new'))}
                  ><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Go back</Button>
                </Box>
                :''}
              </Grid>


            </Grid>
          </Box>
        </Grid>
        
      </Grid>
      
      </Container>

      </div>
    )                    

    /*
    case 'topics':
    case 'topics-terms':
  
      return (
      <div className={classes.root}>
  
      <Header module="legal"
        active={props.action === 'topics-terms' ? "Terms" : "Templates"}
        crumbs={[
          {name: props.action === 'topics-terms' ? "Terms" : "Templates", to: props.action === 'topics-terms' ? "/core/legal/templates/terms/terms" : "/core/legal/templates"},
          {name: getActorsForModule(module),
          to: props.action === 'topics' ? ("/core/legal/templates/" + module) : ("/core/legal/templates/" + module + "/terms")},
          {name: "Topics", to: "active"}
        ]}
        user={props.user} 
        org={props.org}
        modules={modules}
        withSubHead={false}
        notifications={props.notifications}
        markNotificationAsRead={props.markNotificationAsRead}
        markAllNotificationsAsRead={props.markAllNotificationsAsRead} 
        className={classes.header} />
  
      <Container maxWidth="md" className={classes.content}>
      <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
        <Grid item>
          <Typography align="center" variant="h4" className={classes.bold}>
            Assign clause topics
          </Typography>
        </Grid>
        <Grid item>

        <Box align="center">
          <Box mt={5} align="left" className={classes.boxResizing2}>
            {clauseForLabeling !== null ?
            <>
            {}
            <Typography variant="body1">
              {clauseForLabeling.plain.map((cpar,i) => ( <React.Fragment key={i}>{stripPlain(cpar)}<br/></React.Fragment> ))}
            </Typography>
            <Typography variant="body2" color="textSecondary"><br/>{clauseForLabeling.plain.join(' ').replace('<title>','').replace('</title>','').length} chars</Typography>
            </>
            :
            <CircularProgress size={24} />
            }
          </Box>

          <Box mt={5} align="center">
            <FormControl component="fieldset">
            <FormGroup row>
            <Box>
            {props.clauseTypes
            .sort((a,b) => (a.type > b.type) ? 1 : ((b.type > a.type) ? -1 : 0))
            .map((ct, i) => (
              <FormControlLabel
                key={ct._id}
                control={
                  <Checkbox 
                  checked={clauseLabels.some((l) => l === ct.label)}
                  onChange={e => handleToggleLabel(ct.label)} 
                  name={ct.label} 
                  />}
                label={ct.type}
                className={clauseForLabeling !== null && clauseForLabeling.ctKeywords.some((ctk) => ctk === ct._id) ? 
                  classes.secCheck : classes.regCheck}
              />
            ))
            }
              <FormControlLabel
                control={
                  <Checkbox 
                  checked={clauseLabels.some((l) => l === 'none_of_the_above')} 
                  onChange={e => handleToggleLabel('none_of_the_above')} 
                  name={'none_of_the_above'} 
                  />}
                label={'None of the above'}
                className={classes.regCheck}
              />
            </Box>
            </FormGroup>
            </FormControl>
          </Box>

          <Box mt={5} align="center" className={classes.boxResizing2}>
            <Button disabled={clauseForLabeling !== null && !clauseForLabeling.hasKeyword && (clauseLabels === null || clauseLabels.length === 0)} 
            variant="contained" color="primary" fullWidth disableElevation onClick={e => handleClassifySubmit()}>Submit</Button>
            <Box mt={1}>
              <IconButton onClick={e => handleDifferentClauseForLabeling('prev')}>
                <FontAwesomeIcon icon={faChevronCircleLeft} />
              </IconButton>
              <IconButton onClick={e => handleDifferentClauseForLabeling('next')}>
                <FontAwesomeIcon icon={faChevronCircleRight} />
              </IconButton>
            </Box>
          </Box>

        </Box>

        </Grid>
      </Grid>
      </Container>
      </div>
      )*/

  case 'err':

    return(
      <div className={classes.root}>

      <Header module="legal"
        active="Templates"
        crumbs={[
          {name: "Templates & Terms", to: "/core/legal/templates"},
          {name: module[0].toUpperCase() + module.slice(1).toLowerCase(), to: "/core/legal/templates/" + module},
          {name: "Error", to: "active"}
        ]}
        user={props.user} 
        org={props.org}
        modules={modules}
        withSubHead={false}
        notifications={props.notifications}
        markNotificationAsRead={props.markNotificationAsRead}
        markAllNotificationsAsRead={props.markAllNotificationsAsRead} 
        className={classes.header} />

      <Container maxWidth="md" className={classes.content}>
      <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
        <Grid item>
          <Box mb={0} mt={4} align="center">
            <FontAwesomeIcon icon={faExclamationTriangle} className={classes.warnIcon} />
          </Box>
        </Grid>
        <Grid item>
          <Box mt={8} mb={4}>
          <Typography align="center" variant="h6" gutterBottom>
            The creation of the template was not succesful.
          </Typography>
          <Typography align="center" variant="body1">
            The template already exists for the module that you have selected.
          </Typography>
          <Typography align="center" variant="body1">
            Try changing the target module, reference or agreement type.
          </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box align="center" mt={4} mb={4}>
            <Button color="primary" component={RouterLink} to={"/core/legal/templates/" + module}>Go back</Button>
          </Box>
        </Grid>
      </Grid>
      
      </Container>

      </div>
    );
    
  default:

    return(
      <div className={classes.root}>

      <Header module="legal"
        active={props.action === 'terms' ? "Terms" : "Templates"}
        crumbs={
          props.action === 'terms' && module === 'terms' ? 
            [{name: "Terms", to: "active"}] :         
          module === undefined ?
            [{name: "Templates", to: "active"}] :
          module !== undefined ?
            [props.action === 'terms' ? 
                {name: "Terms", to: "/core/legal/templates/terms/terms"} : 
                {name: "Templates", to: "/core/legal/templates"},
             {name: getActorsForModule(module),
              to: "active"}] : []}
        user={props.user}
        org={props.org}
        modules={modules}
        withSubHead={false}
        notifications={props.notifications}
        markNotificationAsRead={props.markNotificationAsRead}
        markAllNotificationsAsRead={props.markAllNotificationsAsRead} 
        className={classes.header} />

      <DrawerCore 
        page={module || 'none'} 
        pageType={props.action === 'terms' ? 'terms' : 'templates'}
        modules={modules}
      />

      {module !== undefined && module !== 'terms' ? <FAB iconType="plus" routeTo={"/core/legal/templates/" + module + (props.action === 'terms' ? "/new-terms": "/new")} /> : '' }

      <Container maxWidth="md" className={classes.content}>
      {!loading && !props.orgAgrTemplatesPending && props.user !== undefined && props.user !== null && props.user.role !== undefined &&
      props.user.role !== 'Counterparty' && (props.user.introComplete === undefined || props.user.introComplete.dashboard === undefined || props.user.introComplete.dashboard === null || !props.user.introComplete.dashboard )  &&
      props.org !== undefined && props.org.clientType !== 'legalOrg' ?
          <GetStarted 
          fromDashboard={false}
          step={"2_1"}
          pushDown={module !== undefined ? false : true}
          module={module}
          user={props.user}
          org={props.org}
          orgAgrTemplates={props.orgAgrTemplates}
          />
      :''}

      <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
        <Grid item>

        {// A Module has been selected
        loading || props.orgAgrTemplatesPending ?
        <SkeletonCustom type="templates" />
        :
        <>
          {props.action === 'terms' ?
          <Typography align="center" variant="h4" className={classes.bold}>
            {module === 'sales' ? 'Public Terms for Customers' : 
             module === 'procurement' ? 'Public Terms for Suppliers' : 
             module === 'partnerships' ? 'Public Terms for Partners' : 
             module === 'privacy' ? 'Privacy Terms' : 'Public Terms'}&nbsp;&nbsp;<InformationalTooltip msg={"Premium and Enterprise customers can publish their terms online. Terms that are published will be visible through your Canveo network page, the specific URL can be defined inside the Terms page.\n\nTo create new terms, click on the click on the \"plus\" button on the bottom right of the screen. You can copy over a template to create your terms or you can import an MS Word template.\n\nYou can publish terms for Customers, Suppliers and Partners as well as publish any Privacy related terms."} />
          </Typography>
          :
          <Typography align="center" variant="h4" className={classes.bold}>
            Templates for {
            module === 'sales' ? 'Customer Agreements' : 
            module === 'procurement' ? 'Supplier Agreements' : 
            module === 'partnerships' ? 'Partner Agreements' : 
            module === 'people' ? 'Employee Agreements' : 
            module === 'corporate' ? 'Corporate transactions' : 'Agreements'}&nbsp;&nbsp;<InformationalTooltip msg={"Company Templates are separated by Module. Navigate between Modules through the menu on the left-hand side.\n\nTo create a new template, click on the \"plus\" button on the bottom right of the screen. From there you can select whether you want to create a new template by copying an existing one or by importing an MS Word template.\n\nEdit, delete, activate or deactivate an existing template version by clicking on the respective item, and then inside the Agreement screen clicking on the status chip (top left of the screen).\n\nEach template is of a specific \"Agreement Type\" (e.g. Non-Disclosure Agreement) and holds a \"reference\" (e.g. FOR THE US)."} />
          </Typography>
          }

          {freezeTemplating !== null && ['noPublish', 'both'].includes(freezeTemplating) && props.action === 'terms' ?

          <Box mt={8} mb={8} align="center">
          <Typography variant="subtitle1" color="textSecondary">
            An upgrade is required to publish your terms
            {props.user.role === 'Admin' ?
            <Button variant="text" color="secondary" component={RouterLink} to="/core/admin/company/subscription/upgrade">Upgrade</Button>
            :''}
          </Typography>
          </Box>
          :relevantOATs === null || (relevantOATs !== null && relevantOATs.filter((oat) => oat.module === module).length === 0) ? // There are no terms / templates for this module yet
          <Box align="center" mt={8} mb={8}>
            <Typography>There are no templates or terms setup (yet)</Typography>
            <Box mt={8}>
              <Button variant="contained" color="secondary" size="large" disableElevation
                component={RouterLink} to={"/core/legal/templates/" + module + (props.action === "terms" ? "/new-terms" : "/new")}>Create new</Button>
            </Box>
          </Box>
          :relevantOATs !== null && props.agrTypes !== undefined && props.agrTypes.length > 0 ? // There is at least one template for this module - show the templates
          <Box align="center" mt={6} mb={6}>

          <Grid container direction="row" className={classes.thumbContainerAgrs} align="center">
          {relevantOATs
          .filter((oat) => oat.module === module)
          .sort((a,b) => (props.agrTypes.filter((at) => at._id === a.agrTypeID)[0].fullName[0] > props.agrTypes.filter((at) => at._id === b.agrTypeID)[0].fullName[0]) ? 1 : ((props.agrTypes.filter((at) => at._id === b.agrTypeID)[0].fullName[0] > props.agrTypes.filter((at) => at._id === a.agrTypeID)[0].fullName[0]) ? -1 : 0))
          .map((oat) => (

            <Grid item className={classes.gridForThumb} key={oat._id} xs={6} md={3}>
            <ThumbAgr 
              ag={{
                agrStatus: oat.active ? 'Active' : oat.external ? 'Published' : 'Draft',
                draftFor: oat.draftFor,
                agrFullName: props.agrTypes.filter((at) => at._id === oat.agrTypeID)[0] !== undefined ? props.agrTypes.filter((at) => at._id === oat.agrTypeID)[0].fullName[0] : '',
                lastUpdateDate: oat.lastUpdateDate,
              }}
              path={'/core/legal/templates/' + module + (oat.active || oat.draftFor === 'Template' ? '/edit/' : '/edit-terms/') + oat._id}
              cptyLogo={props.org.logoURL}
              primaryLegalName={oat.reference !== undefined && oat.reference !== null && oat.reference !== '' ? oat.reference : 'DEFAULT'}
              secondaryLegalName={'Version ' + oat.version.substr(0,3)}
              actionReq={!props.external && !props.active && ['Template','Terms'].includes(oat.draftFor)}
              fromLegal={true}
              hideDot={true}
            />
            </Grid>
          ))}
          </Grid>
          </Box>
          :''}
        </>
        }
        </Grid>
      </Grid>
      
      </Container>

      </div>
    );
  }

}

export default Templates;