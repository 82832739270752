import * as ActionTypes from '../ActionTypes';
import { createAgr, createActivityLog } from '.';

const axios = require('axios');

// RELS ACTIONS

export function fetchRelsByOrgID(orgid) {
  return dispatch => {
      dispatch(fetchRelsPending());

      axios.get('/rel/rels/' + orgid)
      .then(function(res) {
        dispatch(fetchRelsSuccess(res.data.data));
      })
      .catch(function(err) {
        dispatch(fetchRelsError(err));
      })
  }
}

export const fetchRelsPending = () => ({
    type: ActionTypes.FETCH_RELS_PENDING
});

export const fetchRelsError = (error) => ({
    type: ActionTypes.FETCH_RELS_ERROR,
    payload: error
});

export const fetchRelsSuccess = (rels) => ({
    type: ActionTypes.FETCH_RELS_SUCCESS,
    payload: rels
});

export const updateRel = (rid, orgid, primaryCptyID, secondaryOrgID, invisibleFor, lastUpdateBy, deactive, deactiveReason) => {
  return dispatch => {
    dispatch(fetchRelsPending());
    
    let lastUpdateDate = new Date().toISOString();
    axios.put('/rel/rel/' + rid, {
      primaryCptyID: primaryCptyID,
      secondaryOrgID: secondaryOrgID,
      invisibleFor: invisibleFor,
      lastUpdateBy: lastUpdateBy,
      lastUpdateDate: lastUpdateDate,
      deactive: deactive,
      deactiveReason: deactiveReason,
    })
    .then(function (res) { // success
      dispatch(updRel(res.data.data))
    })
    .catch(function (err) { // err
      dispatch(fetchRelsError(err))
    })       
  }
};

export const updateRelName = (rid, name) => {
  return dispatch => {
    axios.put('/rel/relname/' + rid, {
      name: name,
    })
    .then(function (res) { // success
      dispatch(updRel(res.data.data));
    })
    .catch(function (err) { // err
      dispatch(fetchRelsError(err))
    })       
  }
};

export const lastUpdateRel = (rid, lastUpdateBy) => {
  return dispatch => {
    
    let lastUpdateDate = new Date().toISOString();
    axios.put('/rel/rellu/' + rid, {
      lastUpdateBy: lastUpdateBy,
      lastUpdateDate: lastUpdateDate,
    })
    .then(function (res) { // success
      dispatch(updRel(res.data.data));
    })
    .catch(function (err) { // err
      dispatch(fetchRelsError(err))
    })       
  }
};

export const updRel = (rel) => ({
  type: ActionTypes.UPDATE_REL,
  payload: rel
});

export const createRelWithAgreements = (relationshipType, relName, customURL, primaryCptyID, secondaryCptyID, tertiaryCptyID, curAVOwner, contributors,
  signatories, primaryOrgID, secondaryOrgID, invisibleFor, creationBy, deactive, deactiveReason, whiteLabel, mainAgr, exhibits) => {

  return dispatch => {
    
    let creationDate = new Date().toISOString();
    axios.post('/rel/rel', {
      relationshipType: relationshipType,
      name: relName,
      customURL: customURL,
      primaryCptyID: primaryCptyID,
      secondaryCptyID: secondaryCptyID,
      primaryOrgID: primaryOrgID,
      secondaryOrgID: secondaryOrgID,
      invisibleFor: invisibleFor,
      creationBy: creationBy,
      creationDate: creationDate,
      lastUpdateBy: creationBy,
      lastUpdateDate: creationDate,
      deactive: deactive,
      deactiveReason: deactiveReason,
      whiteLabel: whiteLabel,
    })
    .then(function (resrel) { // got a msg from the server
      if(resrel.data.success) { // successfully added the Relationship
        dispatch(addRel(resrel.data.data));

        let isUpload = (mainAgr.importedDoc !== null && mainAgr.importedDoc.data !== null)

        dispatch(createAgr(
          resrel.data.data._id, // rid
          mainAgr.atid, // agrTypeID
          primaryCptyID, // primaryCptyID
          secondaryCptyID, // secondaryCptyID 
          tertiaryCptyID, // tertiaryCptyID
          contributors, // contributors
          signatories, // signatories
          'Draft', // agrStatus 
          curAVOwner, // curAVOwner
          null, // avv
          isUpload ? mainAgr.importedDoc.data.docURL : '', // sourceFileID
          mainAgr.fullName, // agrFullName
          mainAgr.shortName, // agrShortName
          '', // parentAID
          mainAgr.effectiveDate, // effectiveDate
          mainAgr.renewalType, // renewalType
          mainAgr.effectiveTerm, // effectiveTerm
          mainAgr.expiryDate, // expiryDate
          'Contract', // agrPartType
          0, // priority 
          '', // linkedToAID
          {}, // amendment
          creationBy, // creationBy 
          isUpload ? [] : mainAgr.oat.clauses, // templateClauses
          isUpload ? mainAgr.importedDoc : null, // importedDoc
          exhibits, // exhibits
          true, // activate
          mainAgr.smartFieldObject, // smartFieldObject: sfs, sfPush
        ))

        dispatch(createActivityLog('rel', 'relCreated', 'Relationship "' + relName + '" created', '', '', resrel.data.data._id, '', '', '', creationBy, false, '', '', {}))

      } else { // something went wrong while adding the relationship
        dispatch(fetchRelsError(resrel.message));
      }
    })
    .catch(function (err) { // err in the server request
      dispatch(fetchRelsError(err))
    })    
  }
};

export const addRel = (rel) => ({
  type: ActionTypes.ADD_REL,
  payload: rel
});

export const removeRel = (rid) => ({
  type: ActionTypes.REMOVE_REL,
  payload: rid
});