import React from 'react';
import { trunc, countryToFlag } from '../utils'
import { Avatar, Box, Button, Card, CardContent, Grid, Menu, MenuItem, ListItemText, Typography,
         makeStyles } from '@material-ui/core';
import { countries } from '../assets/static/countries';

const CardCpty = (props) => {

  const useStyles = makeStyles(theme => ({
    cardPrimaryCpty: {
        width: '260px',
        borderRadius: '3px',
        borderBottom: '2px solid ' + theme.palette.primary.main,
    },
    cardSecondaryCpty: {
        width: '260px',
        borderRadius: '3px',
        borderBottom: '2px solid ' + theme.palette.secondary.main,
    },
    cardContent: {
        marginTop: '10px',
        "&:first-child": {
            paddingTop: '15px',
        },
        "&:last-child": {
            paddingBottom: '15px',
        }
    },
    avLogo: {
        position: 'absolute',
        top: '-25px',
        left: '110px',
        height: '40px',
        width: '40px',
        backgroundColor: theme.palette.primary.contrastText
    }
  }));
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleSwitchClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectEntity = (id) => {
    props.parentCallBack('newent', props.type, id)
    handleClose()
  }

  return (
    <div style={{position: 'relative'}}>

    {/*
    <Badge 
        badgeContent={<FontAwesomeIcon id={!props.readyToSign && !props.penHolder ? "nonIntroPenowner" : "introPenowner"} icon={
            props.signed ? faCheck :
            props.readyToSign ? faThumbsUp : faPen} style={{fontSize: '8px'}} />} 
        color={props.type === "primary" ? "primary" : "secondary"}
        anchorOrigin={{ vertical: 'bottom', horizontal: (props.type === "primary" ? 'left' : 'right') }}
        invisible={!props.readyToSign && !props.penHolder}
    >
    <Tooltip title={props.signed ? "Fully Signed" : props.readyToSign ? "Ready to Sign" : props.penHolder ? "Current Agreement Editor" : ""} arrow>
    */}
        <Card variant="outlined" className={props.type === "primary" ? classes.cardPrimaryCpty : classes.cardSecondaryCpty}>
            <Avatar src={props.cpty !== undefined && props.cpty !== null ? props.cpty.logoURL : ''} className={classes.avLogo} />
            <CardContent  className={classes.cardContent}>
                <Typography variant="subtitle1" gutterBottom style={{fontWeight: '700'}}>
                {props.cpty !== undefined && props.cpty !== null && props.cpty.shortName !== undefined ? 
                 trunc(props.cpty.shortName, 25) : 'Unknown'}
                </Typography>
                <Typography variant="body2">
                {props.cpty !== undefined && props.cpty !== null && props.cpty.legalName !== undefined ? 
                 trunc(props.cpty.legalName, 32) : <span>&nbsp;</span>}
                </Typography>
                <Typography variant="body2">
                {props.cpty !== undefined && props.cpty !== null && props.cpty.address !== undefined && props.cpty.address.filter((a) => a.addressType === 'billto')[0] !== undefined ?
                 trunc(props.cpty.address.filter((a) => a.addressType === 'billto')[0].streetAndNo, 32) : <span>&nbsp;</span>}
                </Typography>
                
                <Typography variant="body2">
                {props.cpty !== undefined && props.cpty !== null && props.cpty.address !== undefined && props.cpty.address.filter((a) => a.addressType === 'billto')[0] !== undefined &&
                props.cpty.address.filter((a) => a.addressType === 'billto')[0].address2 !== undefined &&  props.cpty.address.filter((a) => a.addressType === 'billto')[0].address2 !== null &&
                props.cpty.address.filter((a) => a.addressType === 'billto')[0].address2 !== '' ?
                 trunc(props.cpty.address.filter((a) => a.addressType === 'billto')[0].address2, 32) : ''}
                </Typography>


                <Typography variant="body2">
                {props.cpty !== undefined && props.cpty !== null && props.cpty.address !== undefined && props.cpty.address.filter((a) => a.addressType === 'billto')[0] !== undefined ?
                 trunc(props.cpty.address.filter((a) => a.addressType === 'billto')[0].city + " " + props.cpty.address.filter((a) => a.addressType === 'billto')[0].zip + 
                    (props.cpty.address.filter((a) => a.addressType === 'billto')[0].state !== undefined && props.cpty.address.filter((a) => a.addressType === 'billto')[0].state !== null && props.cpty.address.filter((a) => a.addressType === 'billto')[0].state !== '' ?
                        (" " + props.cpty.address.filter((a) => a.addressType === 'billto')[0].state) : ''), 32) : <span>&nbsp;</span>}
                </Typography>
                <Typography variant="body2">
                {props.cpty !== undefined && props.cpty !== null && props.cpty.address !== undefined && props.cpty.address.filter((a) => a.addressType === 'billto')[0] !== undefined ?
                countries.filter((c) => c.code === props.cpty.address.filter((a) => a.addressType === 'billto')[0].country)[0].label : <span>&nbsp;</span>}
                </Typography>

                {props.extraBreak ? <Typography variant="body2">&nbsp;</Typography> : ''}

                <Box mt={1}>
                    <Grid container direction="row" alignItems="center" justify="center">
                        {props.switch !== undefined && props.switch !== null && props.switch.length > 0 ?
                        <Grid item>
                            <Button size="small" color={props.type} aria-controls="entity-menu" aria-haspopup="true" 
                            variant={props.cpty !== null && props.cpty.legalName !== undefined ? "text" : "contained"} disableElevation
                            onClick={handleSwitchClick} disabled={props.cpty !== null && props.cpty.legalName !== undefined && props.switch.length === 1}>
                                {props.cpty !== null && props.cpty.legalName !== undefined ? "Switch" : "Select"}
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                {props.switch
                                .sort((a,b) => (a.legalName > b.legalName) ? 1 : ((b.legalName > a.legalName) ? -1 : 0))
                                .map((ent, i) => (
                                <MenuItem key={i} onClick={e => selectEntity(ent._id)} style={{width: 280}}>
                                    <ListItemText 
                                        //primary={trunc(ent.shortName, 30)} 
                                        primary={
                                            <>{trunc(ent.legalName, 27)}&nbsp;&nbsp;{(ent.address !== undefined && ent.address[0] !== undefined && ent.address[0].country !== undefined ? 
                                            countryToFlag(ent.address[0].country) : '')}</>} />
                                </MenuItem>
                                ))}
                            </Menu>
                        </Grid>
                        : '' }
                        {props.edit !== null ?
                        <Grid item>
                            <Button size="small" color={props.type} onClick={props.edit}>Edit</Button>
                        </Grid>
                        : '' }
                    </Grid>
                </Box>

            </CardContent>
        </Card>
    {/*
        </Tooltip>        
        </Badge>*/}
    </div>
  );
}

export default CardCpty