import React, { useEffect } from 'react';
import theme from '../theme/theme';
import { getActorsForModule } from '../utils'; 
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Divider, Menu, MenuItem, ListItemText, Typography, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

const MenuPopModules = (props) => {

    const useStyles = makeStyles(theme => ({
        moduleText: {
            color: theme.palette.grey[700],
            fontFamily: 'century-gothic, sans-serif',
            fontWeight: '400',
            [theme.breakpoints.only('xs')]: { fontSize: '16px', padding: '1px 5px 6px 10px' },
            [theme.breakpoints.up('sm')]: { fontSize: '20px', padding: '3px 5px 8px 10px' },
            cursor: 'pointer'
        },
        logoText: {
            color: theme.palette.grey[800],
            fontFamily: 'century-gothic, sans-serif',
            fontWeight: '400',
            [theme.breakpoints.only('xs')]: { fontSize: '16px', padding: '1px 5px 6px 10px' },
            [theme.breakpoints.up('sm')]: { fontSize: '20px', padding: '3px 5px 8px 10px' },
            cursor: 'pointer'
        },
        regularMenuOption: {
            color: theme.palette.grey[700],
            fontFamily: 'century-gothic, sans-serif',
            fontWeight: '400',
            [theme.breakpoints.only('xs')]: { fontSize: '16px' },
            [theme.breakpoints.up('sm')]: { fontSize: '18px' },
        },
        specialMenuOption: {
            color: theme.palette.grey[500],
            fontWeight: '600',
            [theme.breakpoints.only('xs')]: { fontSize: '13px' },
            [theme.breakpoints.up('sm')]: { fontSize: '14px' },
        },
        caret: {
            [theme.breakpoints.only('xs')]: { fontSize: '13px' },
            [theme.breakpoints.up('sm')]: { fontSize: '15px' },
            marginLeft: '1px',
        },
        divider: {
            margin: '10px 0px 8px 0px',
        }
    }));
    const classes = useStyles();

    const history = useHistory()

    useEffect(() => {
        if(props.triggeredFromParentModules > 0) { setAnchorEl(document.getElementById('introModules')) }
    }, [props.triggeredFromParentModules])

    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>

            {props.whiteLabelLoading ?
            ''
            :props.org !== undefined && props.org !== null && props.org.orgSettings !== undefined && ['full'].includes(props.org.orgSettings.whiteLabelLevel) &&
            props.whiteLabel !== undefined && props.whiteLabel !== null ?
            <Typography className={classes.logoText} onClick={e => history.push('/core/counterparty/dashboard')} id="introModules">
                {props.whiteLabel.orgName}
            </Typography>
            :props.module === 'counterparty' || (props.user !== undefined && ['Counterparty'].includes(props.user.role)) ? // COUNTERPARTY USER ?
            <Typography className={classes.logoText} onClick={e => history.push('/core/counterparty/dashboard')} id="introModules">
                Canveo
            </Typography>
            :props.user === undefined || props.user.role === undefined ?
            <Typography className={classes.logoText} onClick={e => window.location = 'https://getcanveo.com/'}>
                Canveo
            </Typography>
            /*:props.hideDropdown ?
            <Typography className={classes.logoText} onClick={e => history.push('/core/' + props.module + '/dashboard')}>
                {getActorsForModule(props.module)}
            </Typography>*/
            :
            <>
            <Typography className={classes.moduleText} aria-controls="module-menu" aria-haspopup="true" 
                onClick={props.user.role !== undefined ? handleClick : null} id="introModules">
                {['sales', 'procurement', 'partnerships', 'people', 'corporate'].includes(props.module) ? getActorsForModule(props.module) :
                 props.module === 'network' ? "Network" : 
                 props.module === 'legal' ? "Templates" : props.module[0].toUpperCase() + props.module.slice(1).toLowerCase()}
                &nbsp;<FontAwesomeIcon icon={faCaretDown} className={classes.caret} />
            </Typography>

            <Menu
                id="module-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                keepMounted
                disableAutoFocusItem
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                
                { props.user !== undefined && (['Counterparty'].includes(props.user.role) || props.user.role.startsWith('ClientFor_')) ? // COUNTERPARTY MODULE
                (
                <MenuItem onClick={handleClose} component={RouterLink} to="/core/counterparty/dashboard" style={props.module === 'counterparty' ? {backgroundColor: '#f3f3f3',width:200} : {width:200}}>
                    <ListItemText><Typography className={classes.regularMenuOption} align="center">Home</Typography></ListItemText>          
                </MenuItem>                
                ) : '' }
                { props.user !== undefined && ['Admin', 'Business', 'Legal', 'Sales'].includes(props.user.role) &&
                  props.modules !== undefined && props.modules.includes('sales') ? // SALES MODULE
                (
                <MenuItem onClick={handleClose} component={RouterLink} to="/core/sales/dashboard" style={props.module === 'sales' ? {backgroundColor: '#f3f3f3',width:200} : {width:200}}>
                    <ListItemText><Typography className={classes.regularMenuOption} align="center">Customers</Typography></ListItemText>          
                </MenuItem>                
                ) : '' }
                { props.user !== undefined && ['Admin', 'Business', 'Legal', 'Procurement'].includes(props.user.role) &&
                  props.modules !== undefined && props.modules.includes('procurement') ? // PROCUREMENT MODULE
                (
                <MenuItem onClick={handleClose} component={RouterLink} to="/core/procurement/dashboard" style={props.module === 'procurement' ? {backgroundColor: '#f3f3f3',width:200} : {width:200}}>
                    <ListItemText><Typography className={classes.regularMenuOption} align="center">Suppliers</Typography></ListItemText>          
                </MenuItem>                
                ) : '' }
                { props.user !== undefined && ['Admin', 'Business', 'Legal', 'Partnerships'].includes(props.user.role) &&
                  props.modules !== undefined && props.modules.includes('partnerships') ? // PARTNERSHIPS MODULE
                (
                <MenuItem onClick={handleClose} component={RouterLink} to="/core/partnerships/dashboard" style={props.module === 'partnerships' ? {backgroundColor: '#f3f3f3',width:200} : {width:200}}>
                    <ListItemText><Typography className={classes.regularMenuOption} align="center">Partners</Typography></ListItemText>          
                </MenuItem>                
                ) : '' }
                { props.user !== undefined && ['Admin', 'Legal', 'HR'].includes(props.user.role) &&
                  props.modules !== undefined && props.modules.includes('people') ? // PEOPLE MODULE
                (
                <MenuItem onClick={handleClose} component={RouterLink} to="/core/people/dashboard" style={props.module === 'people' ? {backgroundColor: '#f3f3f3', width:200} : {width:200}}>
                    <ListItemText><Typography className={classes.regularMenuOption} align="center">Employees</Typography></ListItemText>          
                </MenuItem>                
                ) : '' }
                { props.user !== undefined && ['Admin', 'Legal', 'Corporate'].includes(props.user.role) &&
                  props.modules !== undefined && props.modules.includes('corporate') ? // CORPORATE MODULE
                (
                <MenuItem onClick={handleClose} component={RouterLink} to="/core/corporate/dashboard" style={props.module === 'corporate' ? {backgroundColor: '#f3f3f3',width:200} : {width:200}}>
                    <ListItemText><Typography className={classes.regularMenuOption} align="center">Corporate</Typography></ListItemText>          
                </MenuItem>                
                ) : '' }
                <Divider className={classes.divider} />
                { props.user !== undefined ? // NETWORK MODULE
                (
                <MenuItem dense onClick={handleClose} component={RouterLink} to="/network" style={props.module === 'network' ? {backgroundColor: '#f3f3f3',width:200} : {width:200}}>
                    <ListItemText><Typography className={classes.specialMenuOption} align="center"
                    style={props.module === 'network' ? {color: theme.palette.grey[700]} : {}}>Canveo Network</Typography></ListItemText>          
                </MenuItem>                
                ) : '' }

                { props.user !== undefined &&  ['Admin', 'Legal'].includes(props.user.role) ? // LEGAL MODULE
                (
                <MenuItem dense onClick={handleClose} component={RouterLink} to="/core/legal/templates" style={props.module === 'legal' ? {backgroundColor: '#f3f3f3',width:200} : {width:200}}>
                    <ListItemText><Typography className={classes.specialMenuOption} align="center"
                    style={props.module === 'legal' ? {color: theme.palette.grey[700]} : {}}>Templates</Typography></ListItemText>          
                </MenuItem>
                ) : '' }
                { props.user !== undefined &&  ['Admin'].includes(props.user.role) ? // ADMIN MODULE
                (
                <MenuItem dense onClick={handleClose} component={RouterLink} to="/core/admin/company" style={props.module === 'admin' ? {backgroundColor: '#f3f3f3',width:200} : {width:200}}>
                    <ListItemText><Typography className={classes.specialMenuOption} align="center"
                    style={props.module === 'admin' ? {color: theme.palette.grey[700]} : {}}>Admin</Typography></ListItemText>          
                </MenuItem>
                ) : '' }
            </Menu>
            </>
            }

        </React.Fragment>      
    )

}

export default MenuPopModules