import React from 'react';
import { Box, FormLabel, FormControlLabel, RadioGroup, Radio, Typography } from '@material-ui/core';

const RadioTargetModule = (props) => {

    return (

        <RadioGroup name="targetModule" value={props.targetModule} onChange={e => props.changeTargetModule(e.target.value)}>
        {props.withLabel ?
          <Box mb={1}><FormLabel component="legend">Target Module</FormLabel></Box>
        :''}
        
        {props.modules.includes('sales') && props.relevantOat !== null &&
        !props.orgAgrTemplates.some((o) => o.agrTypeID === props.relevantOat.agrTypeID && (o.reference === props.relevantRef || (o.reference === undefined && props.relevantRef === '') || (o.reference === '' && props.relevantRef === undefined)) 
        && o.module === 'sales' && 
        ((props.relevantSection === "Template" && (o.active || o.draftFor === 'Template')) || (props.relevantSection === "Terms" && (o.external || o.draftFor === 'Terms')))) ?
          <FormControlLabel value="sales" control={<Radio />} label={"Customers"} /> 
        :props.modules.includes('sales') && props.relevantOat !== null && !['edit'].includes(props.action) ?
          <Box mt={1} mb={1}><Typography variant="body2">Change the type or reference for the Customers module</Typography></Box>
        : ''}

        {props.modules.includes('procurement') && props.relevantOat !== null && 
        !props.orgAgrTemplates.some((o) => o.agrTypeID === props.relevantOat.agrTypeID && (o.reference === props.relevantRef || (o.reference === undefined && props.relevantRef === '') || (o.reference === '' && props.relevantRef === undefined)) 
        && o.module === 'procurement' && 
        ((props.relevantSection === "Template" && (o.active || o.draftFor === 'Template')) || (props.relevantSection === "Terms" && (o.external || o.draftFor === 'Terms')))) ?
          <FormControlLabel value="procurement" control={<Radio />} label={"Suppliers"} /> 
        :props.modules.includes('procurement') && props.relevantOat !== null && !['edit'].includes(props.action) ?
          <Box mt={1} mb={1}><Typography variant="body2">Change the type or reference for the Suppliers module</Typography></Box>
        : ''} 

        {props.modules.includes('partnerships') && props.relevantOat !== null && 
        !props.orgAgrTemplates.some((o) => o.agrTypeID === props.relevantOat.agrTypeID && (o.reference === props.relevantRef || (o.reference === undefined && props.relevantRef === '') || (o.reference === '' && props.relevantRef === undefined)) 
        && o.module === 'partnerships' && 
        ((props.relevantSection === "Template" && (o.active || o.draftFor === 'Template')) || (props.relevantSection === "Terms" && (o.external || o.draftFor === 'Terms')))) ?
          <FormControlLabel value="partnerships" control={<Radio />} label={"Partners"} /> 
        :props.modules.includes('partnerships') && props.relevantOat !== null && !['edit'].includes(props.action) ?
          <Box mt={1} mb={1}><Typography variant="body2">Change the type or reference for the Partners module</Typography></Box>
        : ''}

        {props.modules.includes('people') && ['new', 'edit', 'copy', 'copy-agr'].includes(props.action) && props.relevantOat !== null && 
        !props.orgAgrTemplates.some((o) => o.agrTypeID === props.relevantOat.agrTypeID && (o.reference === props.relevantRef || (o.reference === undefined && props.relevantRef === '') || (o.reference === '' && props.relevantRef === undefined)) 
        && o.module === 'people' && 
        ((props.relevantSection === "Template" && (o.active || o.draftFor === 'Template')) || (props.relevantSection === "Terms" && (o.external || o.draftFor === 'Terms')))) ?
          <FormControlLabel value="people" control={<Radio />} label={"Employees"} /> 
        :props.modules.includes('people') && ['new', 'edit', 'copy', 'copy-agr'].includes(props.action) && props.relevantOat !== null && !['edit'].includes(props.action) ?
          <Box mt={1} mb={1}><Typography variant="body2">Change the type or reference for the Employees module</Typography></Box>
        : ''}

        {props.modules.includes('corporate') && ['new', 'edit', 'copy', 'copy-agr'].includes(props.action) && props.relevantOat !== null && 
        !props.orgAgrTemplates.some((o) => o.agrTypeID === props.relevantOat.agrTypeID && (o.reference === props.relevantRef || (o.reference === undefined && props.relevantRef === '') || (o.reference === '' && props.relevantRef === undefined)) 
        && o.module === 'corporate' && 
        ((props.relevantSection === "Template" && (o.active || o.draftFor === 'Template')) || (props.relevantSection === "Terms" && (o.external || o.draftFor === 'Terms')))) ?
          <FormControlLabel value="corporate" control={<Radio />} label={"Corporate"} /> 
        :props.modules.includes('corporate') && ['new', 'edit', 'copy', 'copy-agr'].includes(props.action) && props.relevantOat !== null && !['edit'].includes(props.action) ?
          <Box mt={1} mb={1}><Typography variant="body2">Change the type or reference for the Corporate module</Typography></Box>
        : ''}
          
        {['new-terms', 'edit-terms','copy-terms'].includes(props.action) && props.relevantOat !== null && 
        !props.orgAgrTemplates.some((o) => o.agrTypeID === props.relevantOat.agrTypeID && (o.reference === props.relevantRef || (o.reference === undefined && props.relevantRef === '') || (o.reference === '' && props.relevantRef === undefined)) 
        && o.module === 'privacy' && (o.external || o.draftFor === 'Terms')) ?
        <FormControlLabel value="privacy" control={<Radio />} label={"Privacy"} /> : '' }

      </RadioGroup>

    );
}

export default RadioTargetModule