import React from 'react';
import { Box, Button, Grid, Typography, makeStyles } from '@material-ui/core';

const ChangeDecisionButtons = (props) => {
    
    const useStyles = makeStyles(theme => ({
        delButton: {
            marginRight: '10px',
            borderRadius: '14px',
            padding: '2px 5px 2px 5px',
            fontWeight: '700',
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.error.main,
            '&:hover': {
                backgroundColor: theme.palette.error.light,
                color: theme.palette.primary.contrastText,
            },
        },
        cancelButton: {
            marginRight: '10px',
            borderRadius: '14px',
            padding: '2px 5px 2px 5px',
            fontWeight: '700',
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.grey[500],
        },
        acceptButton: {
            marginRight: '10px',
            borderRadius: '14px',
            padding: '2px 5px 2px 5px',
            fontWeight: '700',
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.success.main,
            '&:hover': {
                backgroundColor: theme.palette.success.light,
                color: theme.palette.primary.contrastText,
            },
        },
        rejectButton: {
            marginRight: '10px',
            borderRadius: '14px',
            padding: '2px 5px 2px 5px',
            fontWeight: '700',
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.error.main,
            '&:hover': {
                backgroundColor: theme.palette.error.light,
                color: theme.palette.primary.contrastText,
            },
        },
        bold: {
            fontWeight: '700',
        },
    }));
    const classes = useStyles(); 

    return (
    <Grid container direction="row" alignItems="center" justify="flex-start">
        <Grid item>

            {!props.laterVersionOwnedByCpty && (['full'].includes(props.editMode) || 
            (['edit'].includes(props.editMode) && !['tableChange'].includes(props.variant) && /* their cpty created*/
            props.relevantPendingChange !== null && props.relevantPendingChange.changeOrg !== props.curCpty)) ?
            <Button variant="contained" size="small" disableElevation 
            className={['changeTitleCl', 'changeTitleSec', 'tableChange'].includes(props.variant) ? classes.acceptButton : 
                       ['proposedDelCl', 'proposedDelSec'].includes(props.variant) ? classes.delButton : null}
                onClick={['changeTitleCl', 'changeTitleSec', 'tableChange'].includes(props.variant) ? e => props.handlePDecision(props.clid, 'accept') :
                         ['proposedDelCl', 'proposedDelSec'].includes(props.variant) ? e => props.handlePDecision(props.clid, 'delete') : null}
                >{['changeTitleCl', 'changeTitleSec'].includes(props.variant) ? "Accept" : 
                  ['proposedDelCl', 'proposedDelSec'].includes(props.variant) ? "Delete" :
                  ['tableChange'].includes(props.variant) ? "Accept" : ''}</Button>
                : '' }

            {!props.laterVersionOwnedByCpty && !['tableChange'].includes(props.variant) && 
            (['full'].includes(props.editMode) || 
            (['edit'].includes(props.editMode) && /* they themselves created*/
            props.relevantPendingChange !== null && props.relevantPendingChange.changeOrg === props.curCpty)) ?
            <Button variant="contained" size="small" disableElevation 
            className={['changeTitleCl', 'changeTitleSec'].includes(props.variant) ? classes.rejectButton : 
                       ['proposedDelCl', 'proposedDelSec'].includes(props.variant) ? classes.cancelButton : null}
                onClick={['changeTitleCl', 'changeTitleSec'].includes(props.variant) ? e => props.handlePDecision(props.clid, 'reject') :
                         ['proposedDelCl', 'proposedDelSec'].includes(props.variant) ? e => props.handlePDecision(props.clid, 'cancel') : null}
                >{['changeTitleCl', 'changeTitleSec'].includes(props.variant) ? "Reject" : 
                  ['proposedDelCl', 'proposedDelSec'].includes(props.variant) ? "Keep" : ''}</Button>
                :'' }

        </Grid>
        <Grid item>

            <Typography variant="subtitle2" className={classes.bold} 
            color={props.relevantPendingChange !== null && props.relevantPendingChange.changeOrg !== undefined ? props.relevantPendingChange.changeOrg : "error"}>
                {['changeTitleCl', 'changeTitleSec'].includes(props.variant) ? 
                    <><Box display={{ xs: 'none', md: 'inline-block' }}>This title has been changed</Box></>
                :['proposedDelCl'].includes(props.variant) ? 
                    <><Box display={{ xs: 'none', md: 'inline-block' }}>This clause has been&nbsp;</Box>proposed for deletion</>
                :['proposedDelSec'].includes(props.variant) ? 
                    <><Box display={{ xs: 'none', md: 'inline-block' }}>This section has been&nbsp;</Box>proposed for deletion</>
                :['tableChange'].includes(props.variant) && props.relevantPendingChange.type === 'tableInsert' ?
                "This table has been inserted"
                :['tableChange'].includes(props.variant) ?
                "This table has been updated"
                : ''}
            </Typography>

        </Grid>
    </Grid>
    );
}

export default ChangeDecisionButtons
