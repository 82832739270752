import React, { useEffect } from 'react';
import theme from '../theme/theme';
import { Box, Container, Grid, IconButton, InputAdornment, TextField, Typography,
         makeStyles, withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { Element, scroller } from 'react-scroll'
import { Header, DrawerCore, ThumbTodo, InformationalTooltip } from '../components';
import { getDefaultModule } from '../utils';

import help_modules from '../assets/img/help_modules.png';
import help_notifications from '../assets/img/help_notifications.png';
import help_relationships from '../assets/img/help_relationships.png';
import help_tasks from '../assets/img/help_tasks.png';
import help_templates from '../assets/img/help_templates.png';
import help_agreements from '../assets/img/help_agreements.png';
import help_import from '../assets/img/help_import.png';
import help_clauses from '../assets/img/help_clauses.png';
import help_redlines from '../assets/img/help_redlines.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faGaugeSimple, faHeadset, faLightbulb, faQuestionCircle, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

const axios = require('axios');

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': { borderBottom: 0, },
    '&:before': { display: 'none', },
    '&$expanded': { margin: 'auto', },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#f9f9f9',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': { minHeight: 56, },
  },
  content: {
    '&$expanded': { margin: '12px 0', },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

function Help(props) {

  const useStyles = makeStyles(theme => ({
    outerFlexGrid: {
      minHeight: '80vh',
      paddingTop: '100px',
      paddingBottom: '100px',
    },
    bold: {
        fontWeight: '700',
    },
    gridResizing: {
        [theme.breakpoints.up('md')]: { width: '700px' }
    },
    greyish: {
        color: theme.palette.grey[800],
    },
    root: {
        display: 'flex',
    },
    header: {
        zIndex: 1400
    },
    content: {
        flexGrow: 1,
    },
    mainButton: {
        width: '150px',
    },
    thumbContainer : {
      [theme.breakpoints.only('xs')]: { width: '312px' },
      [theme.breakpoints.only('sm')]: { width: '276px' },
      [theme.breakpoints.up('md')]: { width: '440px' },
    },
    gridForThumb:{
      [theme.breakpoints.only('xs')]: { padding: '0px 6px 12px 6px' },
      [theme.breakpoints.only('sm')]: { padding: '0px 6px 12px 6px' },
      [theme.breakpoints.up('md')]: { padding: '0px 10px 20px 10px' },
    },
    faqSection: {
      width: '33%', 
      maxWidth: '160px',
      flexShrink: 0,
      fontWeight: '700',
    },
    adornIcon: {
      color: theme.palette.grey[600],
      padding: '0px 5px 0px 5px',
    },
    searchInput: {
      [theme.breakpoints.only('xs')]: { width: '300px' },
      [theme.breakpoints.only('sm')]: { width: '400px' },
      [theme.breakpoints.up('md')]: { width: '500px' },
    },
    typePar: {
      padding: '15px 20px 15px 20px',
    },
    typeBullet: {
      margin: '10px 20px 10px 40px',
    },
    accordionBlock: {
      [theme.breakpoints.only('xs')]: { width: '320px' },
      [theme.breakpoints.only('sm')]: { width: '450px' },
      [theme.breakpoints.up('md')]: { width: '740px' },
    }
  }));
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);
  const [faqFilter, setFaqFilter] = React.useState('')

  useEffect(() => {
    if(props.notifications.some((n) => n.notificationType === 'Welcome new User' && !n.isRead)) {
      let nid = props.notifications.filter((n) => n.notificationType === 'Welcome new User' && !n.isRead)[0]._id;
      axios.put('/notification/notification/' + nid)
    }
  }, [props.notifications]);

  const scrollTo = (ref) => {
    scroller.scrollTo(ref, {
      duration: 1000,
      delay: 50,
      smooth: true,
      offset: -50,
    })
  }

  const faq = [
    {
      key: 'canv_1',
      section: 'Canveo',
      question: 'How do I contact Canveo Support?',
      answer: [
        { type: 'par', val: 'To contact Canveo Support, proceed as follows:' },
        { type: 'bullet', bullet: 'Step 1.', val: 'Click on your profile picture / user icon' },
        { type: 'bullet', bullet: 'Step 2.', val: 'Click "Help"' },
        { type: 'bullet', bullet: 'Step 3.', val: 'Click "Contact Support"' },
        { type: 'bullet', bullet: 'Step 4.', val: 'Select "Customer Support", provide the necessary detail and submit' }]
    },
    {
      key: 'canv_2',
      section: 'Canveo',
      question: 'What is the "Canveo Network" and how do I use it?',
      answer: [
        { type: 'par', val: 'Canveo is a network of companies, when companies subscribe they are automatically part of the network.' },
        { type: 'par', val: 'When you are creating an agreement for a counterparty that is already part of the Canveo Network, you do no longer need to create it during the "New Agreement" workflow and can simply select the network profile.' },
        { type: 'par', val: 'You can also change to the "Canveo Network Module" to search for a company on the Canveo Network. Premium account holders are able to publish their legal terms and certifications.' }]
    },
    {
      key: 'pers_1',
      section: 'Personal',
      question: 'How do I update my personal details?',
      answer: [
        { type: 'par', val: 'You can update your personal details, including your first and last name, corporate title, phone number and profile picture as follows:' },
        { type: 'bullet', bullet: 'Step 1.', val: 'Click on your profile picture / user icon' },
        { type: 'bullet', bullet: 'Step 2.', val: 'Click "Account"' },
        { type: 'bullet', bullet: 'Step 3.', val: 'Update the appropriate information and submit' }]
    },
    {
      key: 'pers_2',
      section: 'Personal',
      question: 'How do I update my e-mail address?',
      answer: [
        { type: 'par', val: 'To update your e-mail address, please send an email to support@getcanveo.com with the subject: "Change of e-mail address".' },
        { type: 'par', val: 'Make sure that you are the owner of the current e-mail address as a verification will be required.'}]
    },
    {
      key: 'pers_3',
      section: 'Personal',
      question: 'What is a "default module" and how do I change it?',
      answer: [
        { type: 'par', val: 'Your "Default Module" is the primary module in case your user role allows you to access multiple modules. For example, a Legal or Admin User can access all Modules (Customers, Suppliers, etc.) and a Business User can access the Customers, Suppliers and Partners Modules. Without any configuration, your default module is the "Customers" Module.' },
        { type: 'par', val: 'The module you have selected as a "default module" is the one where you will default to after you log in or when you return to your dashboard from outside (e.g. from the Help section).' },
        { type: 'par', val: 'You can change your Default Module as follows:'},
        { type: 'bullet', bullet: 'Step 1.', val: 'Click on your profile picture / user icon' },
        { type: 'bullet', bullet: 'Step 2.', val: 'Click "Account"' },
        { type: 'bullet', bullet: 'Step 3.', val: 'Click "Settings"' },
        { type: 'bullet', bullet: 'Step 4.', val: 'Select your preferred default module' }]
    },
    {
      key: 'comp_1',
      section: 'Company',
      question: 'How do I update my company details?',
      answer: [
        { type: 'par', val: 'This action can only be performed by an Admin User. To check your role, click on your profile picture / user icon and then click "Account".'},
        { type: 'par', val: 'If you are an Admin user, proceed as follows:' },
        { type: 'bullet', bullet: 'Step 1.', val: 'Click on the Module Selector (next to the Canveo Logo)' },
        { type: 'bullet', bullet: 'Step 2.', val: 'Select the "Admin" Module' },
        { type: 'bullet', bullet: 'Step 3.', val: 'Update the information and submit' }]
    },
    {
      key: 'comp_2',
      section: 'Company',
      question: 'How do I create a new Legal Entity?',
      answer: [
        { type: 'par', val: 'This action can only be performed by an Admin User. To check your role, click on your profile picture / user icon and then click "Account".'},
        { type: 'par', val: 'If you are an Admin user, proceed as follows:' },
        { type: 'bullet', bullet: 'Step 1.', val: 'Click on the Module Selector (next to the Canveo Logo)' },
        { type: 'bullet', bullet: 'Step 2.', val: 'Select the "Admin" Module' },
        { type: 'bullet', bullet: 'Step 3.', val: 'Click "Entities"' },
        { type: 'bullet', bullet: 'Step 4.', val: 'Click on the "+" Button at the bottom right of the screen' },
        { type: 'bullet', bullet: 'Step 5.', val: 'Complete the details and submit' }]
    },
    {
      key: 'comp_3',
      section: 'Company',
      question: 'How do I update a Legal Entity?',
      answer: [
        { type: 'par', val: 'This action can only be performed by an Admin User. To check your role, click on your profile picture / user icon and then click "Account".'},
        { type: 'par', val: 'If you are an Admin user, proceed as follows:' },
        { type: 'bullet', bullet: 'Step 1.', val: 'Click on the Module Selector (next to the Canveo Logo)' },
        { type: 'bullet', bullet: 'Step 2.', val: 'Select the "Admin" Module' },
        { type: 'bullet', bullet: 'Step 3.', val: 'Click "Entities"' },
        { type: 'bullet', bullet: 'Step 4.', val: 'Click on "Search Entity", find the entity and click "Edit"' },
        { type: 'bullet', bullet: 'Step 5.', val: 'Complete the details and submit' }]
    },
    {
      key: 'comp_4',
      section: 'Company',
      question: 'How do I add or update another user for my company?',
      answer: [
        { type: 'par', val: 'This action can only be performed by an Admin User. To check your role, click on your profile picture / user icon and then click "Account".'},
        { type: 'par', val: 'If you are an Admin user, proceed as follows:' },
        { type: 'bullet', bullet: 'Step 1.', val: 'Click on the Module Selector (next to the Canveo Logo)' },
        { type: 'bullet', bullet: 'Step 2.', val: 'Select the "Admin" Module' },
        { type: 'bullet', bullet: 'Step 3.', val: 'Click "Users"' },
        { type: 'bullet', bullet: 'Step 4.', val: 'Click on the "+" button (bottom right of the screen) to add a User, click into an existing user to update it (e.g. delete or change user role)' },
        { type: 'bullet', bullet: 'Step 5.', val: 'Complete the details and submit' }]
    },
    {
      key: 'comp_5',
      section: 'Company',
      question: 'How do I update default signers for a legal entity?',
      answer: [
        { type: 'par', val: 'This action can only be performed by an Admin User. To check your role, click on your profile picture / user icon and then click "Account".'},
        { type: 'par', val: 'If you are an Admin user, proceed as follows:' },
        { type: 'bullet', bullet: 'Step 1.', val: 'Click on the Module Selector (next to the Canveo Logo)' },
        { type: 'bullet', bullet: 'Step 2.', val: 'Select the "Admin" Module' },
        { type: 'bullet', bullet: 'Step 3.', val: 'Click "Signatory"' },
        { type: 'bullet', bullet: 'Step 4.', val: 'Click "Add Signer" for the relevant entity or click "Remove" to delete' }]
    },
    {
      key: 'impo_1',
      section: 'Import',
      question: 'How do I use the "Import", and what do I need to know?',
      answer: [
        { type: 'par', val: 'Canveo agreements are not just simple files with plain text, instead they are a collection of clauses and parameters.' },
        { type: 'par', val: 'To transform an existing contract template (e.g. in MS Word, PDF, HTML or Google Docs format) into the Canveo Format, you need to use the Canveo Import Algorithm. You can use this either to import a Company Template (inside the Templates Module) or to generate a new Agreement, for example when your counterparty wants to use their own template.'},
        { type: 'par', val: 'To complete the Import, you need to open both the Canveo Import Screen and make sure that you open the file or webpage for the agreement to be imported.' },
        { type: 'par', val: 'Once both are open, here is a step-by-step guide to complete the Import:' },
        { type: 'bullet', bullet: 'Step 1.', val: 'Select All ("Ctrl+A") and Copy ("Ctrl+C") the entire agreement from the source file (e.g. MS Word) or page' },
        { type: 'bullet', bullet: 'Step 2.', val: 'Click into the Import screen and Paste the text ("Ctrl+V"). Now the Canveo Import Classification Algorithm will automatically classify each paragraph inside the agreement in order to transform it into the Canveo format.' },
        { type: 'bullet', bullet: 'Step 3.', val: 'Review the automatic classification, adjust a paragraph classification by using the buttons on the right hand side. For example, if you think that a certain paragraph is classified as "New Clause" but it should actually be part of the "Signature Block", then click into that paragraph and then click the button "Signature Block"' },
        { type: 'bullet', bullet: 'Step 4.', val: 'Once each paragraph has been classified correctly, click "Complete Import", if any errors or warnings come up, make sure to resolve these and click "Retry Import"' },
        { type: 'bullet', bullet: 'Step 5.', val: 'Select the Agreement Type of the Contract that you are importing, you can also create a new type and specify a Reference when you are importing inside the Templates Module.'},
        { type: 'par', val: 'Common challenges and important tips:' },
        { type: 'bullet', bullet: '•', val: 'The Import algorithm is heavily based on the numbering that is typical inside contracts (e.g. "1.a" or "Section 1"), if your template does not have consistent numbering it may cause challenges for the import.' },
        { type: 'bullet', bullet: '•', val: 'Some PDFs are breaking up longer paragraphs at the end of each line. While Canveo merges such lines, please be extra careful in reviewing the classification of PDF agreements' },
        { type: 'bullet', bullet: '•', val: 'Ensure auto-hyphenation (a setting inside MS Word) is switched off.' },
        { type: 'bullet', bullet: '•', val: 'When you are importing a MS Word file (DOCX format), make sure you open it with MS Word and not import using the Google Docs version of that DOCX file.' },
        { type: 'bullet', bullet: '•', val: 'The default import includes a plain text version of any table. If you like to replace this with the table version, go to "Table Review", delete the applicable plain text, place the cursor where you want the table, and then click “insert".' },
        { type: 'bullet', bullet: '•', val: 'Exhibits are imported seperately and are recognized by the Exhibit Title, exhibits come in different shapes and formats and may require extra careful review of the classification' },
        { type: 'par', val: 'If your agreement does not get imported correctly, email us at support@getcanveo.com, we\'ll do it for you.' }
      ]
    },
    {
      key: 'impo_2',
      section: 'Import',
      question: 'The Import is not working for a specific template, what do I do?',
      answer: [
        { type: 'par', val: 'If your agreement does not get imported correctly, email us at support@getcanveo.com, we\'ll do it for you.' }]
    },
    {
      key: 'temp_1',
      section: 'Templates',
      question: 'How do I create a legal template?',
      answer: [
        { type: 'par', val: 'This action can only be performed by an Admin or a Legal User. To check your role, click on your profile picture / user icon and then click "Account".'},
        { type: 'par', val: 'If you are an Admin or Legal user, proceed as follows:' },
        { type: 'bullet', bullet: 'Step 1.', val: 'Click on the Module Selector (next to the Canveo Logo)' },
        { type: 'bullet', bullet: 'Step 2.', val: 'Select the "Templates" Module' },
        { type: 'bullet', bullet: 'Step 3.', val: 'Click the "+" button (bottom right of the screen)' },
        { type: 'bullet', bullet: 'Step 4.', val: 'Click whether you want to create from Importing a file or from Copying an existing Template' },
        { type: 'bullet', bullet: 'Step 5.', val: 'Follow the steps depending on your selection in Step 4. For Import, have a look at the Import Questions inside this FAQ' }]
    },
    {
      key: 'temp_2',
      section: 'Templates',
      question: 'How do I activate or deactivate a legal template?',
      answer: [
        { type: 'par', val: 'This action can only be performed by an Admin or a Legal User. To check your role, click on your profile picture / user icon and then click "Account".'},
        { type: 'par', val: 'If you are an Admin or Legal user, proceed as follows:' },
        { type: 'bullet', bullet: 'Step 1.', val: 'Click on the Module Selector (next to the Canveo Logo)' },
        { type: 'bullet', bullet: 'Step 2.', val: 'Select the "Templates" Module' },
        { type: 'bullet', bullet: 'Step 3.', val: 'Click on the appropriate Module (left hand side) where the Template applies' },
        { type: 'bullet', bullet: 'Step 4.', val: 'Click into the appropriate Template item' },
        { type: 'bullet', bullet: 'Step 5.', val: 'Inside the Template screen, click on the Template Status Chip and select "Deactivate this version" or "Activate this version"' },
        { type: 'par', val: 'You can have only one active version for each template. You could however, decide to copy the same template into a new one and give it a different "Reference".'}
      ]
    },
    {
      key: 'agr_1',
      section: 'Agreements',
      question: 'How do I create a new Agreement?',
      answer: [
        { type: 'par', val: 'If you want to create a new agreement and are in the appropriate Module (e.g. Customers for Sales), proceed as follows:' },
        { type: 'bullet', bullet: 'Step 1.', val: 'From your Dashboard or Relationships page, click the "New +" button' },
        { type: 'bullet', bullet: 'Step 2.', val: 'Search and select the counterparty or create a new one by typing inside the input field' },
        { type: 'bullet', bullet: 'Step 3.', val: 'Select or create a legal entity for that counterparty. A counterparty may have multiple legal entities and all agreements with the parent will be placed inside the Relationship folder with that counterparty.' },
        { type: 'bullet', bullet: 'Step 4.', val: 'Next, you can decide whether to create the agreement from a Company Template or from an Import (e.g. your counterparty template). For Import, review the applicable questions inside this FAQ.' },
        { type: 'bullet', bullet: 'Step 5.', val: 'You can decide to add exhibits if applicable (similarly either from your company templates or from import)' },
        { type: 'bullet', bullet: 'Step 6.', val: 'For Enterprise customers, you will then configure the agreement through Smart Clauses and Smart Fields' },
        { type: 'bullet', bullet: 'Step 7.', val: 'Click "Create Agreement", the new agreement will be created instantly as a "Draft". If no relationship existed yet, then also a new Relationship will be created with that counterparty.'}
      ]
    },
    {
      key: 'agr_2',
      section: 'Agreements',
      question: 'How do I send an agreement to my counterparty?',
      answer: [
        { type: 'par', val: 'After the agreement has been created, proceed as follows:' },
        { type: 'bullet', bullet: 'Step 1.', val: 'Find the applicable Relationship and click into the Agreement' },
        { type: 'bullet', bullet: 'Step 2.', val: 'Click "Continue" (at the bottom right of the screen)' },
        { type: 'bullet', bullet: 'Step 3.', val: 'Click "Send to counterparty"' },
        { type: 'bullet', bullet: 'Step 4.', val: 'Review collaborators for your own organisation and for the counterparty, optionally create new collaborators for your counterparty through the "Create collaborator" button' },
        { type: 'bullet', bullet: 'Step 5.', val: 'Next, review and update the signers for the agreement. This can be completed up to the point the agreement is sent for signature.' },
        { type: 'bullet', bullet: 'Step 6.', val: 'Next, review the edit access for your counterparty and whether you are ready to sign the current version of the agreement.' },
        { type: 'bullet', bullet: 'Step 7.', val: 'Optionally add a message for your counterparty and click "Send to counterparty".'}
      ]
    },
    {
      key: 'agr_3',
      section: 'Agreements',
      question: 'What does it mean when you indicate you\'re "Ready to Sign"?',
      answer: [
        { type: 'par', val: 'The Ready to Sign (Thumbs Up) button helps to speed up the signing process. When a company sends the agreement to a counterparty, the user can decide whether they are ready to sign the current version of the agreement. Doing this will enable the counterparty to initiate signing immediately through Canveo.' },
        { type: 'par', val: 'If the counterparty makes a change to the agreement, the agreement will no longer be ready to sign.' },
      ]
    },
    {
      key: 'agr_4',
      section: 'Agreements',
      question: 'How do I "initiate signing" of the agreement?',
      answer: [
        { type: 'par', val: 'Once an agreement has been marked as "Ready to Sign" by one of the two parties, the other party can click "initiate signing" to kick-off the signing process.' },
        { type: 'par', val: 'Initiating Signing will trigger notifications and emails to the signers with a request to proceed to sign the agreement.' },
        { type: 'par', val: 'If a signing order is defined then only the first assignee will be notified. New notifications and emails will be sent upon completion of each signer.' }
      ]
    },
    {
      key: 'agr_5',
      section: 'Agreements',
      question: 'How do I sign an agreement through Canveo?',
      answer: [
        { type: 'par', val: 'When you are defined as a signer and when there is no signing order or you are the current assignee, you will see a large "Sign" button upon opening of the agreement.' },
        { type: 'par', val: 'Clicking on the sign button will route the user through the digital signing workflow, depending on which Signing Provider is selected by the company.' }
      ]
    },
    {
      key: 'agr_6',
      section: 'Agreements',
      question: 'How do upload an agreement that was signed outside of Canveo?',
      answer: [
        { type: 'par', val: 'This functionality is only available for Premium and Enterprise customers. The following steps can be taken:' },
        { type: 'bullet', bullet: 'Step 1.', val: 'Click into the Agreement, if there is none, create a new agreement with the applicable counterparty' },
        { type: 'bullet', bullet: 'Step 2.', val: 'Click "More"' },
        { type: 'bullet', bullet: 'Step 3.', val: 'Click "Offline Signing"' },
        { type: 'bullet', bullet: 'Step 4.', val: 'Click "Signed Offline"' },
        { type: 'bullet', bullet: 'Step 5.', val: 'Upload the signed PDF, decide whether you want to digitize the agreement through an import and click "Submit""' },
      ]
    },
    {
      key: 'agr_7',
      section: 'Agreements',
      question: 'Can we upload all our historical agreements into Canveo?',
      answer: [
        { type: 'par', val: 'Yes, please contact support@getcanveo.com' },
      ]
    },
    {
      key: 'mgmt_1',
      section: 'Management',
      question: 'How do I search for historical agreements?',
      answer: [
        { type: 'par', val: 'To find historical agreements and clauses or parameters, proceed as follows:' },
        { type: 'bullet', bullet: 'Step 1.', val: 'Go to the dashboard of the applicable Module.' },
        { type: 'bullet', bullet: 'Step 2.', val: 'Type inside the "Search Canveo" input box' },
        { type: 'bullet', bullet: 'Step 3.', val: 'After selecting one or two chips, click on the Search Arrow to proceed' },
        { type: 'par', val: 'Alternatively:' },
        { type: 'bullet', bullet: 'Step 1.', val: 'Go to the applicable Relationship page' },
        { type: 'bullet', bullet: 'Step 2.', val: 'Click "Agreements" (left-hand side)' },
        { type: 'bullet', bullet: 'Step 3.', val: 'Find the agreement by using one or more of the filters' },
      ]
    },
    {
      key: 'mgmt_2',
      section: 'Management',
      question: 'What are "labels" and how can I use them?',
      answer: [
        { type: 'par', val: 'All agreements are automatically categorized through creation of a Relationship for each counterparty. However, you may want to add additional tags for searching, categorization and reporting purposes. This can be achieved by the usage of "Labels", to create a label:' },
        { type: 'bullet', bullet: 'Step 1.', val: 'Go to the applicable Relationship page' },
        { type: 'bullet', bullet: 'Step 2.', val: 'Click "Add Label" (bottom left of the screen)' },
        { type: 'bullet', bullet: 'Step 3.', val: 'Select an earlier created label or create a new label' },
        { type: 'par', val: 'After a label is applied to a relationship, you can update or remove it by clicking into the label.' }
      ]
    },
  ]

  const concepts = [
      {
        'img' : help_agreements,
        'imgWidth': '202',
        'concept' : 'Agreements',
        'desc' : 'Agreements are contracts between you and the counterparty. An Agreement consists of a collection of clauses. All agreements are part of a Relationship between two counterparties. Each Agreement has a status (e.g. "Draft", "Negotiation", "Execution", "In Effect")'
      },
      {
        'img' : help_clauses,
        'imgWidth': '400',
        'concept' : 'Clauses',
        'desc' : 'An agreement is a collection of clauses, a clause is a component of an agreement that can be described by a title and may consist of one or multiple paragraphs. For each clause there is a "redline" version and a "plain" version (which assumes any existing redline is approved). Canveo features such as comments and approval flows are on a Clause level. Smart clauses and smart fields are enabled for our Enterprise customers.'
      },
      {
        'img' : help_import,
        'imgWidth': '424',
        'concept' : 'Import',
        'desc' : 'Templates or Agreements that are in MS Word, HTML, PDF or another format need to be imported into Canveo. During the Import, Canveo\'s algorithm classifies each paragraph in order to break up the contract into individual clauses. The user is able to correct and complete the classification if applicable.'
      },
      {
        'img' : help_modules,
        'imgWidth': '180',
        'concept' : 'Modules',
        'desc' : 'The Canveo applications consists of different modules. For example for Sales there is a "Customers Module", which enables you to manage your relationships and agreements with your customers. The "Network module" enables you to search and efficiently interact with other companies in the Canveo network. Legal and Admin users may have access to the "Templates" and "Admin" Modules.'
      },
      {
        'img' : help_notifications,
        'imgWidth': '60',
        'concept' : 'Notifications',
        'desc' : 'You will receive notifications for key activities with regards to your account, agreements or relationships. Clicking on a notification will bring you directly to the relevant page.'
      },
      {
        'img' : help_relationships,
        'imgWidth': '202',
        'concept' : 'Relationships',
        'desc' : 'Relationships are overarching collections between you and a specific counterparty. A counterparty can be single entity or may be multiple entities in case of affiliates / parent-child structures. Essentially a relationship is a folder that contains all agreements for a specific counterparty.'
      },
      {
        'img' : help_redlines,
        'imgWidth': '420',
        'concept' : 'Redlining',
        'desc' : 'Redlining means that you are making changes to the original (or the currently accepted) text of the contract and can be done entirely inside the Canveo application. Redlines can be new insertions, proposed deletions or rejections of these by the counterparty. Redlines will contain a different color for each of the counterparties and can be accepted or rejected.'
      },
      {
        'img' : help_tasks,
        'imgWidth': '424',
        'concept' : 'Tasks',
        'desc' : 'Tasks are actions that are assigned to a user within your organisation or may be unassigned. There are different types of tasks including approval requests and agreement reviews. Activities related to tasks will trigger (email) notifications for the requestor and/or the assignee.'
      },
      {
        'img' : help_templates,
        'imgWidth': '424',
        'concept' : 'Templates',
        'desc' : 'Company Templates can be imported into Canveo and used to simplify and standardize the Agreement generation. A Company template is of a certain Agreement Type (e.g. Non-Disclosure Agreement) and can hold a reference (e.g. for the US region). When a template is "Active" then it can be used to generate agreements.'
      }
  ]

  const handleFAQChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return(
    <div className={classes.root}>
      <Header module="help" 
        crumbs={[
            {name: "Help Center", to: "/core/help/overview"},
            {name: (props.type === 'welcome' ? "Welcome" : 
                    props.type === 'overview' ? "Overview" :
                    props.type === 'concepts' ? "Concepts" :
                    props.type === 'faq' ? "Frequently Asked Questions" : ""), to: "active"} 
        ]}
        org={props.org}
        user={props.user}
        modules={
          props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
            ['sales','procurement','partnerships','people','corporate'] : 
          props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
            props.org.modules : [] }
        notifications={props.notifications}
        markNotificationAsRead={props.markNotificationAsRead}
        markAllNotificationsAsRead={props.markAllNotificationsAsRead}
        className={classes.header} />

      <DrawerCore 
        page={props.type} 
        scrollTo={scrollTo}
        concepts={concepts}
        pageType='help'
        hideLaunchpad={props.user.role === 'Counterparty'}
      />

      <Container maxWidth="lg" className={classes.content}>
      <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
        {props.type === 'overview' || props.type === 'welcome' ?
        (
        <>
        <Grid item>
          <Box mb={4} mt={2}>
            <Typography align="center" variant="h3" className={classes.bold}>
                {props.type === 'welcome' ?
                "Welcome to Canveo" : "Help Center"}&nbsp;
                <InformationalTooltip msg={"Canveo aims to make the user experience as self-explanatory as possible. In case you do have questions or are not sure what to do, we recommend reviewing the key concepts and the frequently asked questions (FAQ).\n\nIn case you do not find an answer here, then do not hesitate to contact us."} />
            </Typography>
          </Box>
        </Grid>

        <Grid item container direction="column" alignItems="center" justify="center">

            <Grid item className={classes.gridResizing}>

                {props.type === 'welcome' ?
                <Box mt={1} mb={4}>
                <Typography variant="subtitle1" align="center">
                We're excited to have you on board as a new user!
                </Typography>
                </Box>
                : ''}

                <Box mt={4} mb={10} align="center">
                    <Grid direction="row" container className={classes.thumbContainer}>
                      <Grid item xs={6} className={classes.gridForThumb}>
                        <ThumbTodo
                        route={"/core/help/concepts"} 
                        dash={true}
                        icon={faLightbulb} 
                        iconOnly={true}
                        iconColor={"primary"}
                        line1={"Canveo Concepts"} 
                        line2={"Understand the basics"} />
                      </Grid>
                      <Grid item xs={6} className={classes.gridForThumb}>
                        <ThumbTodo
                        route={"/core/help/faq"} 
                        dash={true}
                        icon={faQuestionCircle} 
                        iconOnly={true}
                        iconColor={"primary"}
                        line1={"FAQ"} 
                        line2={"Frequent Questions"} />
                      </Grid>
                      <Grid item xs={6} className={classes.gridForThumb}>
                        <ThumbTodo
                        route={"/contact/support"} 
                        dash={true}
                        icon={faHeadset} 
                        iconOnly={true}
                        iconColor={"primary"}
                        line1={"Contact Support"} 
                        line2={"We're here to help"} />
                      </Grid>
                      <Grid item xs={6} className={classes.gridForThumb}>
                        <ThumbTodo
                        route={"/core/" + getDefaultModule(props.org, props.user)  + "/dashboard"} 
                        dash={true}
                        icon={faGaugeSimple} 
                        iconOnly={true}
                        iconColor={"primary"}
                        line1={"To Dashboard"} 
                        line2={"Back to your home screen"} />
                      </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
        </>
        )
        :
        props.type === 'concepts' ?
        (
        <>
        <Grid item>
          <Box mb={4} mt={2}>
            <Typography align="center" variant="h3" className={classes.bold}>
                Key Concepts
            </Typography>
          </Box>
        </Grid>

        <Grid item container direction="column" alignItems="center" justify="center">
            
            {concepts.map((c, i) => (

                <Grid item className={classes.gridResizing} key={i}>
                <Element name={c.concept}>
                <Box align="center" mb={4} pt={3}>
                    <Typography variant="h5" className={classes.bold} gutterBottom>
                        {c.concept}
                    </Typography>
                    <Typography variant="body1" align="left" className={classes.greyish}>
                        {c.desc}
                    </Typography>
                    <Box mt={3} mb={1}><img src={c.img} alt={c.concept} width={c.imgWidth} /></Box>
                </Box>
                </Element>
                </Grid>
            ))}

        </Grid>
        </>
        )
        :
        (
        <>
            <Grid item>
            <Box mb={4} mt={2}>
                <Typography align="center" variant="h3" className={classes.bold}>
                    Frequently Asked Questions
                </Typography>
            </Box>
            </Grid>

            <Grid item container direction="column" alignItems="center" justify="center">
              <Box mt={5} mb={5}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Search FAQ"
                  onChange={e => setFaqFilter(e.target.value.toLowerCase())}
                  value={faqFilter}
                  placeholder="Search by typing..."
                  className={classes.searchInput}
                  InputProps={{
                    //className: classes.filterInput,
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon={faSearch} className={classes.adornIcon} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={e => setFaqFilter('')}
                          onMouseDown={e => setFaqFilter('')}
                          style={{fontSize: '17px', padding: '7px 6px 7px 6px'}}
                        >
                          {faqFilter !== undefined && faqFilter.length > 0 ? <FontAwesomeIcon icon={faTimes} style={{padding: '4px 7px 4px 7px'}} /> : ''}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
              <Box mt={4}>

                {faq
                .filter((q) => faqFilter === '' || q.section.toLowerCase().includes(faqFilter) || q.question.toLowerCase().includes(faqFilter) || q.answer.some((a) => a.val.toLowerCase().includes(faqFilter)))
                .map((q, i) => (
                  <Accordion square expanded={expanded === q.key} onChange={handleFAQChange(q.key)} className={classes.accordionBlock} key={i}>
                  <AccordionSummary style={expanded === q.key ? {backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText} : {}}
                  expandIcon={<FontAwesomeIcon icon={faChevronDown} style={expanded === q.key ? {color: theme.palette.primary.contrastText, fontSize: '15px'} : {fontSize: '15px'}} />}>
                    <Typography className={classes.faqSection}>
                      {q.section}
                    </Typography>
                    <Typography style={expanded === q.key ? {fontWeight: '400'} : {}}>{q.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box style={{display: 'block'}} mb={1}>
                    {q.answer.map((a, j) => (
                      a.type === 'par' ?
                        <Typography className={classes.typePar} key={j}>{a.val}</Typography>
                      :a.type === 'bullet' ?
                        <Typography className={classes.typeBullet} key={j}><span className={classes.bold}>{a.bullet}&nbsp;&nbsp;</span>{a.val}</Typography>
                      :''
                    ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
                ))
                }
              </Box>

            </Grid>
        </>
        )}

        
      </Grid>
      </Container>

    </div>
  );
}

export default Help;