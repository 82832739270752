import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types'; // https://reactjs.org/docs/typechecking-with-proptypes.html
import { Box, Button, Grid, Toolbar,
         makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function HeaderSub(props) {

  const useStyles = makeStyles(theme => ({

    mainToolBar: {
      backgroundColor: theme.palette.grey[100],
      minHeight: '44px',
      zIndex: '80',
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    iconInButton: {
        paddingRight: '15px'
    },
    tabButton: {
        [theme.breakpoints.only('xs')]: { 
          fontSize: '10px',
          fontWeight: '400',
          padding: '16px 12px 16px 12px',
        },
        [theme.breakpoints.only('sm')]: { 
          fontSize: '13px',
          fontWeight: '400',
          padding: '14px 15px 14px 15px',
        },
        [theme.breakpoints.up('md')]: { 
          fontSize: '13px',
          fontWeight: '400',
          padding: '14px 25px 14px 25px',
        },
        background: 'transparent',
        borderRadius: '0px',
        border: 'none',
        color: theme.palette.primary.main,
        margin: '0px',
        display: 'inline',
        outline: 'none',
        transition: '0.3s',
        '&:hover': {
            backgroundColor: theme.palette.primary.contrastText,
        },
        '&:active' : {
            background: theme.palette.primary.main,
            color: 'white',
            transition: 'none',
        }
    },
    activeTabButton: {
        [theme.breakpoints.only('xs')]: { 
          fontSize: '10px',
          fontWeight: '400',
          padding: '16px 12px 16px 12px',
        },
        [theme.breakpoints.only('sm')]: { 
          fontSize: '13px',
          fontWeight: '400',
          padding: '14px 15px 14px 15px',
        },
        [theme.breakpoints.up('md')]: { 
          fontSize: '13px',
          fontWeight: '400',
          padding: '14px 25px 14px 25px',
        },
        background: theme.palette.primary.contrastText,
        borderRadius: '0px',
        border: 'none',
        color: theme.palette.primary.main,
        margin: '0px',
        display: 'inline',
        outline: 'none',
        transition: '0.3s',
        '&:hover': {
            backgroundColor: theme.palette.primary.contrastText,
        },
        '&:active' : {
            background: theme.palette.primary.main,
            color: 'white',
            transition: 'none'      
        }
    },
  }));
  const classes = useStyles();

  return (
    <div>

      <Toolbar />

      <Toolbar className={classes.mainToolBar}>
        <Grid container direction="row" alignItems="stretch" justify="center" className={classes.mainGrid}>

            {props.buttons.map((b, i) => 
            (
              <Grid item key={i}>
                <Box display={{ xs: 'none', sm: 'block' }}>
                  <Button 
                    color="primary"
                    className={b.active ? classes.activeTabButton : classes.tabButton}
                    component={RouterLink}
                    to={b.to}>
                    <FontAwesomeIcon icon={b.icon} className={classes.iconInButton} />
                    {b.name}
                  </Button>
                </Box>
                <Box display={{ xs: 'block', sm: 'none' }}>

                  <Button 
                    color="primary"
                    className={b.active ? classes.activeTabButton : classes.tabButton}
                    component={RouterLink}
                    to={b.to}>
                    {b.name}
                  </Button>

                </Box>
              </Grid>
            ))
            }
            
        </Grid>
      </Toolbar>

    </div>
  )
}

HeaderSub.propTypes = {
  buttons: PropTypes.array,
  agreement: PropTypes.bool,
  clauseStatusFilter: PropTypes.bool,
  commentStatusFilter: PropTypes.bool,
  approvalStatusFilter: PropTypes.bool,
  toggleStatus: PropTypes.func,
};

export default HeaderSub
