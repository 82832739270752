export const sfautofields = [
    {val: 'org-shortName', desc: 'Our entity: Business name'},
    {val: 'org-legalName', desc: 'Our entity: Legal entity name'},
    {val: 'org-address', desc: 'Our entity: Full address'},
    {val: 'org-city', desc: 'Our entity: City'},
    {val: 'org-country', desc: 'Our entity: Country'},
    {val: 'cpty-shortName', desc: 'Counterparty: Business name'},
    {val: 'cpty-legalName', desc: 'Counterparty: Legal entity name'},
    {val: 'cpty-address', desc: 'Counterparty: Full address'},
    {val: 'cpty-city', desc: 'Counterparty: City'},
    {val: 'cpty-country', desc: 'Counterparty: Country'},
];