function calculateExpiryDate(effectiveDate, renewalType, effectiveTerm) {

    let expDate = null

    // NOTE THIS IS ALSO (PARTIALLY) USED IN THE BACKEND, CHANGES MAY NEED TO BE DUPLICATED INTO agrExecController.js
    if(
        effectiveDate !== null && !isNaN(Date.parse(effectiveDate)) &&
        renewalType !== null && 
        effectiveTerm !== undefined &&
        effectiveTerm !== null && effectiveTerm.isCustom !== undefined &&
        effectiveTerm.isCustom !== null && !effectiveTerm.isCustom &&
        effectiveTerm.initial !== undefined && effectiveTerm.initial !== null &&
        effectiveTerm.initial.indexOf("_") > 0 && 
        effectiveTerm.initial.indexOf("_") + 1 < effectiveTerm.initial.length &&
        (renewalType === 'none' || (
            effectiveTerm.renewal !== undefined && effectiveTerm.renewal !== null &&
            effectiveTerm.renewal.indexOf("_") > 0 && 
            effectiveTerm.renewal.indexOf("_") + 1 < effectiveTerm.renewal.length
        ))
    ) {
        let startDate = new Date(effectiveDate)        
        let endDate = getEndDateOfTerm(effectiveTerm.initial, startDate)
        let today = new Date()

        if(endDate < today && renewalType !== 'none' &&
        effectiveTerm.renewal !== undefined && effectiveTerm.renewal !== null &&
        !isNaN(parseInt(effectiveTerm.renewal.substring(0, effectiveTerm.renewal.indexOf("_")))) &&
        parseInt(effectiveTerm.renewal.substring(0, effectiveTerm.renewal.indexOf("_"))) > 0 &&
        ['years', 'months', 'weeks', 'days'].includes(effectiveTerm.renewal.substring(effectiveTerm.renewal.indexOf("_") + 1))) {
            // you're beyond the initial term, let's calculate based on the renewal term - if applicable
            let newEndDate = endDate;
            while(newEndDate !== null && newEndDate < today) {
                newEndDate = getEndDateOfTerm(effectiveTerm.renewal, newEndDate)
            }
            // Check if due to montly durations (e.g. Feb) you've cut off days, then add them back in
            if(['months'].includes(effectiveTerm.renewal.substring(effectiveTerm.renewal.indexOf("_") + 1)) &&
            startDate.getDate() > 28) {
                let tweakYear = newEndDate.getFullYear();
                let tweakMonth = newEndDate.getMonth();
                let tweakDay = 
                    parseInt(startDate.getDate()) > 28 && tweakMonth % 12 === 1 ? 28 :
                    parseInt(startDate.getDate()) > 30 && [3,5,8,10].includes(tweakMonth % 12) ? 30 : parseInt(startDate.getDate())
                newEndDate = new Date(tweakYear, tweakMonth, tweakDay)
            }

            endDate = newEndDate
        }

        if(endDate !== null && !isNaN(Date.parse(endDate))) {
            expDate = endDate;
        }

    }

    return expDate;
}

// DIRECT DUPLICATED INTO THE BACK-END, CHANGES HERE TO BE DUPLICATED
function getEndDateOfTerm(term, startDate) {

    let endDate = null;
        
    let multiplierInitial = term.substring(0, term.indexOf("_"))
    let periodInitial = term.substring(term.indexOf("_") + 1)

    let year = startDate.getFullYear();
    let month = startDate.getMonth();
    let day = startDate.getDate();

    if(periodInitial === "years") {
        endDate = new Date(parseInt(year) + parseInt(multiplierInitial), month, day)

    } else if(periodInitial === "months") {
        let newMonth = parseInt(month) + parseInt(multiplierInitial)
        let newDay = 
            parseInt(day) > 28 && newMonth % 12 === 1 ? 28 :
            parseInt(day) > 30 && [3,5,8,10].includes(newMonth % 12) ? 30 : parseInt(day)
        endDate = new Date(year, newMonth, newDay)
        
    } else if(periodInitial === "weeks") {
        endDate = startDate;
        endDate.setDate(endDate.getDate() + multiplierInitial * 7);
        
    } else if(periodInitial === "days") {
        endDate = startDate;
        endDate.setDate(endDate.getDate() + multiplierInitial);
        
    }  
    
    return endDate;
}

export { calculateExpiryDate }