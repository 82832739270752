import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Container, Chip, FormControlLabel, Grid, 
         List, ListItem, ListItemIcon, ListItemText,
         Switch, TextareaAutosize, Typography,
         makeStyles, 
         TextField} from '@material-ui/core';
import { DrawerCore, FAB, Header } from '../../components';
import { trunc, convertLabelToTitle } from '../../utils'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faArrowLeft, faBoxArchive, faBoxOpen } from "@fortawesome/free-solid-svg-icons";

const axios = require('axios');

function Playbook(props) {

  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    outerFlexGrid: {
      minHeight: '80vh',
      paddingTop: '110px',
      paddingBottom: '100px',
    },
    header: {
      zIndex: 1400
    },
    content: {
        flexGrow: 1,
    },
    bold: {
      fontWeight: '700',
    },
    boldGrey: {
      fontWeight: '700',
      color: theme.palette.grey[500],
    },
    listBox: {
        maxWidth: '540px',
    },
    iconB: {
        padding: '8px 6px 8px 6px',
    },
    starred: {
        color: '#f9d978',
    },
    textAreaAuto: {
        width: '270px',
        fontSize: '14px',
        padding: '15px',
        border: '1px solid' + theme.palette.grey[400],
        borderRadius: '10px',
        minHeight: '35px',
    },
    archiveIcon: {
        color: theme.palette.grey[400],
    },
    primIcon: {
        color: theme.palette.primary.main,
    },
    regIcon: {
        color: theme.palette.grey[700],
    },
    stdChip: {
        [theme.breakpoints.down('xs')]: { fontSize: '10px', padding: '1px 4px 1px 4px', margin: '0px 3px 3px 0px', },
        [theme.breakpoints.only('sm')]: { fontSize: '11px', padding: '1px 4px 1px 4px', margin: '0px 8px 8px 0px', },
        [theme.breakpoints.only('md')]: { fontSize: '12px', padding: '1px 6px 1px 6px', margin: '0px 8px 8px 0px', },
        [theme.breakpoints.up('lg')]: { fontSize: '12px', padding: '1px 6px 1px 6px', margin: '0px 8px 8px 0px',  },
        fontWeight: '400',
    },
    chipIcon: {
        padding: '0px 2px 0px 5px',
    },
    deleteButton: {
        color: theme.palette.error.main,
    },
    toggleText: {
        color: theme.palette.grey[900],
        marginLeft: '4px',
    },
  }));
  const classes = useStyles();

  const [changingTooltip, setChangingTooltip] = React.useState(null);
  const [orgSmartFields, setOrgSmartFields] = React.useState([])
  const [showArchived, setShowArchived] = React.useState(false)

  useEffect(() => {

    if(props.module !== undefined && props.user !== undefined) {
        axios.get('/sf/osfs/' + props.user.orgID + "/" + props.module + "/topic") // PULL TOPIC ORG SMARTFIELD FOR THAT MODULE
        .then(function(res) {
            
            setOrgSmartFields(res.data.data)
            setChangingTooltip(null)
    
        }).catch(function(err) { console.log(err) })
    }
  }, [props.user, props.module])

  const modules = props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
                    ['sales','procurement','partnerships','people','corporate'] : 
                  props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
                    props.org.modules : [];

  const handleNewTooltipName = (newName) => {
    if(/^[a-z0-9 ]*$/i.test(newName)){ // String is Alpha (upper/lower), Numeric, dash or space
        let nn = '', nl = ''
        let nnArr = newName.split(/\s/)
        nnArr.forEach((n) => {
            nn = nn + n.charAt(0).toUpperCase() + n.slice(1).toLowerCase() + ' ';
            nl = nl + n.toLowerCase() + "_"
        })
        nn = nn.substring(0, nn.length - 1);
        nl = nl.substring(0, nl.length - 1);
        setChangingTooltip({...changingTooltip, name: newName, label: nl})
    }
  }

  const handleGuidanceUpdate = (deleteTopic) => {

    if(['new', 'new-topic'].includes(changingTooltip.osfid)) {
        axios.post('/sf/osf', {
            orgID: props.user.orgID,
            module: props.module,
            name: changingTooltip.name,
            type: 'clause-topic',
            question: '',
            defaultValue1: changingTooltip.label,
            defaultValue2: '',
            guidance: changingTooltip.guidance,
            createWizard: false,
            reusable: true,
        }).then((res) => {

            let newOsfs = orgSmartFields.filter((o) => o.defaultValue1 !== changingTooltip.label)
            newOsfs.push(res.data.data)
            setOrgSmartFields(newOsfs)
            setChangingTooltip(null)
    
        }).catch(err => console.log(err))

    } else {
        let curOsf = orgSmartFields.filter((o) => o._id === changingTooltip.osfid)[0]

        if(curOsf !== undefined) {
            axios.put('/sf/osf/' + curOsf._id, {
                name: curOsf.name,
                type: curOsf.type,
                question: curOsf.question,
                defaultValue1: curOsf.defaultValue1,
                defaultValue2: curOsf.defaultValue2,
                guidance: changingTooltip.guidance,
                createWizard: curOsf.createWizard,
                reusable: deleteTopic ? false : true,
            }).then((res) => {

                let newOsfs = orgSmartFields.filter((o) => o._id !== changingTooltip.osfid)
                newOsfs.push(res.data.data)
                setOrgSmartFields(newOsfs)
                setChangingTooltip(null)
    
            }).catch(err => console.log(err))
        }
    }
  }

  return(
    <div className={classes.root}>
      
    <Header 
        module="legal"
        active="Playbook"
        crumbs={[
        {name: "Playbook", to: "active"} ]}
        user={props.user} 
        org={props.org}
        modules={modules}
        withSubHead={false}
        notifications={props.notifications}
        markNotificationAsRead={props.markNotificationAsRead}
        markAllNotificationsAsRead={props.markAllNotificationsAsRead}
        className={classes.header} />


    <DrawerCore 
    page={props.module || 'none'} 
    pageType={'playbook'}
    modules={modules}
    />

    <FAB iconType="plus" disabled={changingTooltip !== null} click={e => setChangingTooltip({ osfid: 'new-topic', name: '', label: '', guidance: '' })} /> 

    <Container maxWidth="md" className={classes.content}>
    <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
        
        <Typography align="center" variant="h4" className={classes.bold}>
            Legal Guidance
        </Typography>
        {/*['custE'].includes(props.org.clientType) ? 
        <Box mt={2}>
        <Typography align="center" variant="body2">
            Provide internal guidance on specific legal topics.
        </Typography>
        </Box>
        :''*/}

        {['cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
        <Box align="center">
            {changingTooltip !== null ?
            <Box mt={3}>
                {changingTooltip.osfid === 'new-topic' ?
                <Typography variant="h6">Create a new topic</Typography>
                :
                <Chip
                label={convertLabelToTitle(changingTooltip.label)}
                icon={<FontAwesomeIcon icon={faInfoCircle} className={classes.chipIcon} />}
                //clickable
                color={changingTooltip.reusable ? "primary" : "default"}
                size="small"
                className={classes.stdChip}
                style={changingTooltip.reusable ? {} : {backgroundColor: '#f3f3f3'}}
                //onClick={e => setActiveTopic(ct)}
                />
                }
                <Box mt={2} style={{width: '300px'}}>

                    {changingTooltip.osfid === 'new-topic' ?
                    <Box mb={2}>
                    <TextField
                    fullWidth
                    color="primary"
                    variant="outlined"
                    placeholder="Topic Name..."
                    label="New Topic Name"
                    value={changingTooltip.name}
                    onChange={e => handleNewTooltipName(e.target.value)}
                    />
                    </Box>
                    : '' }

                    <TextareaAutosize
                    className={classes.textAreaAuto}
                    name="addComment"
                    rowsMin={4}
                    rowsMax={15}
                    placeholder="Add guidance for this topic..."
                    value={changingTooltip.guidance}
                    onChange={e => setChangingTooltip({...changingTooltip, guidance: e.target.value})}
                    />

                    <Box mt={1}>
                    <Button disabled={changingTooltip.osfid === 'new-topic' && orgSmartFields.some((o) => o.name.toLowerCase() === changingTooltip.name.toLowerCase() ||  o.defaultValue1 === changingTooltip.label )}
                    onClick={e => handleGuidanceUpdate(false)} variant="contained" color="primary" fullWidth disableElevation>Submit</Button>
                    </Box>
                </Box>
                <Box mt={1}>
                    <Button onClick={e => setChangingTooltip(null)} variant="text" color="primary"><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Go back</Button>
                    {!['new','new-topic'].includes(changingTooltip.osfid) ?
                    <>&nbsp;&nbsp;
                    <Button onClick={e => handleGuidanceUpdate(changingTooltip.reusable ? true : false)} variant="text" color="primary" className={changingTooltip.reusable ? classes.deleteButton : null}
                    >{changingTooltip.reusable ? "Archive" : "Unarchive"}&nbsp;&nbsp;<FontAwesomeIcon icon={changingTooltip.reusable ? faBoxArchive : faBoxOpen} /></Button>
                    </>
                    :''}
                </Box>
            </Box>        
            :
            <Box align="left" mt={3} className={classes.listBox}>
            <Box align="center" mb={2}>
                <FormControlLabel
                    style={{marginRight: '14px'}}
                    control={<Switch size="small" color="primary" checked={showArchived} onChange={e => setShowArchived(!showArchived)} name="showArchived" />}
                    label={<Typography className={classes.toggleText} variant="subtitle2">Show archived topics</Typography>}
                />
            </Box>
            <List>
            {orgSmartFields
            .filter((o) => o.reusable || showArchived)
            .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            .map((osf) => (
            <ListItem button key={osf._id}
            onClick={e => setChangingTooltip(osf.orgID === 'CANVEO' ? 
                { osfid: 'new', name: osf.name, label: osf.defaultValue1, guidance: '', reusable: osf.reusable } : 
                { osfid: osf._id, label: osf.defaultValue1, guidance: osf.guidance, reusable: osf.reusable })}
            >
            <ListItemIcon>
            <FontAwesomeIcon icon={osf.reusable ? faInfoCircle : faBoxArchive} 
            className={!osf.reusable ? classes.archiveIcon : osf.orgID !== 'CANVEO' ? classes.primIcon : classes.regIcon} />
            </ListItemIcon>
            <ListItemText id={osf._id} primary={<span className={!osf.reusable ? classes.boldGrey : classes.bold}>{osf.name}</span>} 
            secondary={osf.orgID !== 'CANVEO' ? trunc(osf.guidance, 60) : 'No guidance provided'} />
            </ListItem>
            ))}
            
            {/*props.clauseTypes
            .sort((a,b) => (a.type > b.type) ? 1 : ((b.type > a.type) ? -1 : 0))
            .map((ct) => (
                <React.Fragment key={ct._id}>
                <ListItem button onClick={e => setChangingTooltip(changingTooltip === ct._id ? null : ct._id)}
                style={changingTooltip === ct._id ? {backgroundColor: '#f3f3f3'} : {}}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faInfoCircle} className={
                            props.orgConfigs !== undefined && 
                            props.orgConfigs.filter((o) => o.clauseTypeLabel === ct.label && o.module === props.module)[0] !== undefined && 
                            props.orgConfigs.filter((o) => o.clauseTypeLabel === ct.label && o.module === props.module)[0].config !== undefined && 
                            props.orgConfigs.filter((o) => o.clauseTypeLabel === ct.label && o.module === props.module)[0].config.tooltip !== '' ?
                                classes.primIcon : classes.regIcon
                        } />
                    </ListItemIcon>
                    <ListItemText id={ct._id} primary={ct.type} />
                    <ListItemSecondaryAction>
                        <IconButton size="small" className={classes.iconB} onClick={e => toggleOrgConfigStar(ct.label)}>
                            <FontAwesomeIcon icon={
                                props.orgConfigs !== undefined && 
                                props.orgConfigs.filter((o) => o.clauseTypeLabel === ct.label && o.module === props.module)[0] !== undefined && 
                                props.orgConfigs.filter((o) => o.clauseTypeLabel === ct.label && o.module === props.module)[0].config !== undefined && 
                                props.orgConfigs.filter((o) => o.clauseTypeLabel === ct.label && o.module === props.module)[0].config.starred ? 
                                faStarFull : faStarEmpty}
                                className={classes.starred} />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                <Collapse in={changingTooltip === ct._id}>
                    <Box mt={4} mb={4} align="center">
                        <Box style={{width: '260px'}} align="left">
                            <TextareaAutosize
                            className={classes.textAreaAuto}
                            name="addComment"
                            rowsMin={4}
                            rowsMax={15}
                            placeholder="Add guidance for this topic..."
                            defaultValue={
                                newTooltip !== null && newTooltip.length > 0 && newTooltip.filter((ntt) => ntt.ctlabel === ct.label)[0] !== undefined && 
                                newTooltip.filter((ntt) => ntt.ctlabel === ct.label)[0].tooltip !== '' ? newTooltip.filter((ntt) => ntt.ctlabel === ct.label)[0].tooltip :  
                                props.orgConfigs !== undefined && 
                                props.orgConfigs.filter((o) => o.clauseTypeLabel === ct.label && o.module === props.module)[0] !== undefined && 
                                props.orgConfigs.filter((o) => o.clauseTypeLabel === ct.label && o.module === props.module)[0].config !== undefined && 
                                props.orgConfigs.filter((o) => o.clauseTypeLabel === ct.label && o.module === props.module)[0].config.tooltip !== '' ? 
                                props.orgConfigs.filter((o) => o.clauseTypeLabel === ct.label && o.module === props.module)[0].config.tooltip : ''}
                            onBlur={e => handleUpdateTooltip(ct.label, e.target.value)}
                            onChange={e => setToSave(true)}
                            />
                        </Box>
                    </Box>
                </Collapse>
                </React.Fragment>
            ))*/}
            </List>
            </Box>
            }
        </Box>
        :
        <Box align="center" mt={5} mb={5}>
            <Typography>This functionality is only available to Premium or Enterprise customers.</Typography>
            {props.user.role === 'Admin' ?
            <Box mt={3}><Button variant="text" color="secondary" component={RouterLink} to="/core/admin/company/subscription/upgrade">Upgrade</Button></Box> : ''}
        </Box>
        }
    
    </Grid>
    </Container>

    </div>
  );
}

export default Playbook;