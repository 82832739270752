import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Box, Grid, makeStyles } from '@material-ui/core';

const SkeletonCustom = (props) => {

  const useStyles = makeStyles(theme => ({
    thumbSkelet: {
        [theme.breakpoints.only('xs')]: { width: '150px', height: '150px' },
        [theme.breakpoints.only('sm')]: { width: '180px', height: '180px' },
        [theme.breakpoints.up('md')]: { width: '190px', height: '190px' }, // 225 h ?
        marginBottom: '20px',
        borderRadius: '10px',
    },
    chartSkelet: {
      [theme.breakpoints.only('xs')]: { width: '300px', height: '200px' },
      [theme.breakpoints.only('sm')]: { width: '520px', height: '320px' },
      [theme.breakpoints.only('md')]: { width: '680px', height: '380px' },
      [theme.breakpoints.up('lg')]: { width: '760px', height: '400px' },
    },
    chartSkeletFooter: {
      [theme.breakpoints.only('xs')]: { width: '300px', height: '40px' },
      [theme.breakpoints.only('sm')]: { width: '520px', height: '50px' },
      [theme.breakpoints.only('md')]: { width: '680px', height: '50px' },
      [theme.breakpoints.up('lg')]: { width: '760px', height: '50px' },
    },
    titleSkelet: {
        [theme.breakpoints.only('xs')]: { width: '300px', height: '55px' },
        [theme.breakpoints.only('sm')]: { width: '420px', height: '55px' },
        [theme.breakpoints.only('md')]: { width: '540px', height: '55px' },
        [theme.breakpoints.up('lg')]: { width: '540px', height: '55px' }, //680 w?
    },
    submitSkelet:{ 
        [theme.breakpoints.only('xs')]: { width: '300px', height: '40px' },
        [theme.breakpoints.only('sm')]: { width: '420px', height: '40px' },
        [theme.breakpoints.only('md')]: { width: '540px', height: '40px' },
        [theme.breakpoints.up('lg')]: { width: '540px', height: '40px' },
    },
    text1Skelet:{ 
      [theme.breakpoints.only('xs')]: { width: '300px', height: '25px' },
      [theme.breakpoints.only('sm')]: { width: '480px', height: '25px' },
      [theme.breakpoints.only('md')]: { width: '520px', height: '25px' },
      [theme.breakpoints.up('lg')]: { width: '660px', height: '25px' },
    },
    text2Skelet:{ 
      [theme.breakpoints.only('xs')]: { width: '290px', height: '25px' },
      [theme.breakpoints.only('sm')]: { width: '470px', height: '25px' },
      [theme.breakpoints.only('md')]: { width: '510px', height: '25px' },
      [theme.breakpoints.up('lg')]: { width: '650px', height: '25px' },
    },
    text3Skelet:{ 
      [theme.breakpoints.only('xs')]: { width: '310px', height: '25px' },
      [theme.breakpoints.only('sm')]: { width: '490px', height: '25px' },
      [theme.breakpoints.only('md')]: { width: '530px', height: '25px' },
      [theme.breakpoints.up('lg')]: { width: '670px', height: '25px' },
    },
    text4Skelet:{ 
      [theme.breakpoints.only('xs')]: { width: '140px', height: '25px' },
      [theme.breakpoints.only('sm')]: { width: '230px', height: '25px' },
      [theme.breakpoints.only('md')]: { width: '250px', height: '25px' },
      [theme.breakpoints.up('lg')]: { width: '360px', height: '25px' },
    },
    bold: {
      fontWeight: '700'
    },
    boldL: {
      fontWeight: '700',
      //fontSize: '20px',
    },
    medium: {
      fontWeight: '500',
    },
    avatar: {
      backgroundColor: theme.palette.primary.main,
      fontWeight: '700',
      fontSize: '16px',
      width: '35px',
      height: '35px',
    }
  }));
  const classes = useStyles();

  switch(props.type) {

    case 'tasks' :

        return (
        <Box mt={3} mb={10} align="center">
            <Box mb={5}>
                <Skeleton variant="text" animation="wave" width={200} height={35} />
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
                <Skeleton variant="text" animation="wave" width={150} />
                <Skeleton variant="text" animation="wave" width={150} />
                <Skeleton variant="text" animation="wave" width={150} />
                </Grid>
                <Grid item xs={12} sm={4}>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
                <Skeleton variant="text" animation="wave" width={150} />
                <Skeleton variant="text" animation="wave" width={150} />
                <Skeleton variant="text" animation="wave" width={150} />
                </Grid>
                <Grid item xs={12} sm={4}>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
                <Skeleton variant="text" animation="wave" width={150} />
                <Skeleton variant="text" animation="wave" width={150} />
                <Skeleton variant="text" animation="wave" width={150} />
                </Grid>
            </Grid>
            <Box mt={3}>

            </Box>
        </Box>)

    case 'templates' :

      return (
      <Box mt={3} mb={10} align="center">
          <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
              </Grid>
          </Grid>
          <Box mt={3}>
          <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
              </Grid>
          </Grid>
          </Box>
      </Box>)

    case 'agreement':
      return (
        <Box mt={2} mb={10} align="center">

            <Skeleton variant="text" animation="wave" className={classes.titleSkelet} />
            {// Previous "Face" Agreement Loading can be decommed
            /*
            <Box mt={5} align="center">
            <Grid direction="row" container justify="center" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={6} lg={5}>
                <Skeleton variant="rect" animation="wave" width={50} height={25} style={{borderTopLeftRadius: '25px', borderTopRightRadius: '25px'}} />
                <Skeleton variant="rect" animation="wave" width={260} height={160} /></Grid>
                <Grid item xs={12} sm={6} lg={5}>
                <Skeleton variant="rect" animation="wave" width={50} height={25} style={{borderTopLeftRadius: '25px', borderTopRightRadius: '25px'}} />
                <Skeleton variant="rect" animation="wave" width={260} height={160} /></Grid>
            </Grid>
            </Box>
            */}
            <Box mt={5} align="center">
                <Grid container direction="column" justify="center" alignItems="center">
                <Grid item>
                    <Skeleton variant="text" animation="wave" width={240} height={55} />
                </Grid>
                <Grid item>
                    <Box align="left" mt={2}>
                    <Skeleton variant="text" animation="wave" className={classes.text1Skelet} />
                    <Skeleton variant="text" animation="wave" className={classes.text2Skelet} />
                    <Skeleton variant="text" animation="wave" className={classes.text3Skelet} />
                    <Skeleton variant="text" animation="wave" className={classes.text1Skelet} />
                    <Skeleton variant="text" animation="wave" className={classes.text4Skelet} />
                    </Box>
                </Grid>
                </Grid>
            </Box>

            <Box mt={7} align="center">
                <Grid container direction="column" justify="center" alignItems="center">
                <Grid item>
                        <Skeleton variant="text" animation="wave" width={200} height={35} />
                </Grid>
                <Grid item>
                    <Box align="left" mt={2}>
                    <Skeleton variant="text" animation="wave" className={classes.text1Skelet} />
                    <Skeleton variant="text" animation="wave" className={classes.text2Skelet} />
                    <Skeleton variant="text" animation="wave" className={classes.text3Skelet} />
                    <Skeleton variant="text" animation="wave" className={classes.text1Skelet} />
                    <Skeleton variant="text" animation="wave" className={classes.text4Skelet} />
                    </Box>
                </Grid>
                </Grid>
            </Box>

            <Box mt={5} align="center">
                <Grid container direction="column" justify="center" alignItems="center">
                <Grid item>
                    <Skeleton variant="text" animation="wave" width={240} height={55} />
                </Grid>
                <Grid item>
                    <Box align="left" mt={2}>
                    <Skeleton variant="text" animation="wave" className={classes.text1Skelet} />
                    <Skeleton variant="text" animation="wave" className={classes.text2Skelet} />
                    <Skeleton variant="text" animation="wave" className={classes.text3Skelet} />
                    <Skeleton variant="text" animation="wave" className={classes.text1Skelet} />
                    <Skeleton variant="text" animation="wave" className={classes.text4Skelet} />
                    </Box>
                </Grid>
                </Grid>
            </Box>            
        </Box>          
      )

    case 'relationship':

      return (
        <Box mt={5} mb={10} align="center">
            <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item><Skeleton variant="circle" animation="wave" width={100} height={100} /></Grid>
            </Grid>
            <Box mt={8}>
            <Box mb={3}>
                <Skeleton variant="text" animation="wave" width={150} />
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
                </Grid>
                <Grid item xs={12} sm={4}>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
                </Grid>
                <Grid item xs={12} sm={4}>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
                </Grid>
            </Grid>
            </Box>
        </Box>
      )
    
    case 'chart':
      return(
        <Box mt={0} mb={10} align="center">
            <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                <Grid item><Skeleton variant="rect" animation="wave" className={classes.text4Skelet} /></Grid>
                <Grid item><Skeleton variant="rect" animation="wave" className={classes.text4Skelet} style={{width: '40px'}} /></Grid>
            </Grid>
            <Box mt={3}>
              <Skeleton variant="rect" animation="wave" className={classes.chartSkelet} />
            </Box>
            <Box mt={2}><Skeleton variant="text" animation="wave" className={classes.chartSkeletFooter} /></Box>
            <Box mt={0}><Skeleton variant="text" animation="wave" className={classes.chartSkeletFooter} /></Box>
            <Box mt={0}><Skeleton variant="text" animation="wave" className={classes.chartSkeletFooter} /></Box>
            <Box mt={0}><Skeleton variant="text" animation="wave" className={classes.chartSkeletFooter} /></Box>
            <Box mt={0}><Skeleton variant="text" animation="wave" className={classes.chartSkeletFooter} /></Box>
            <Box mt={0}><Skeleton variant="text" animation="wave" className={classes.chartSkeletFooter} /></Box>
        </Box>
      )

    case 'form':
    default:

      return (
        <Box mt={0} mb={10} align="center">
            <Skeleton variant="text" animation="wave" className={classes.titleSkelet} />
            <Skeleton variant="text" animation="wave" className={classes.titleSkelet} />
            <Skeleton variant="text" animation="wave" className={classes.titleSkelet} />
            <Skeleton variant="text" animation="wave" className={classes.titleSkelet} />
            <Skeleton variant="text" animation="wave" className={classes.titleSkelet} />        
            <Box mt={1}>
            <Skeleton variant="text" animation="wave" className={classes.submitSkelet} />
            </Box>
        </Box>
      )

    }

}

export default SkeletonCustom