import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import theme from '../theme/theme';

const InfoTooltip = withStyles({
    arrow: {
        //"&:before": {
          //border: "1px solid #E6E8ED"
        //},
        color: '#62b2fc'
    },
    tooltip: {
        backgroundColor: '#62b2fc',
        //color: theme.palette.grey[900],
        fontSize: "14px",
        fontWeight: 500,
        padding: '20px',
        color: theme.palette.primary.contrastText,
        //backgroundColor: '#f6f6f6',
        //border: '1px solid #eeeeee',
        borderRadius: '3px',
    }
})(Tooltip);

const InformationalTooltip = (props) => {

    return (
          <InfoTooltip arrow
          enterTouchDelay={0}
          leaveTouchDelay={5000}
          title={<span style={{ whiteSpace: 'pre-line' }}>{props.msg}</span>}>
              <span>
              <FontAwesomeIcon icon={faInfoCircle} style={{color: '#62b2fc', fontSize: '15px'}} />
              </span>
          </InfoTooltip>
    );
}

export default InformationalTooltip