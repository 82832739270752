import React from 'react';
import theme from '../theme/theme';
import { trunc, processLongString, getTimeIndicationForDate } from '../utils'
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'; // https://reactjs.org/docs/typechecking-with-proptypes.html
import { Avatar, Badge, Box, Card, Chip, Tooltip, Typography, makeStyles, withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from "@fortawesome/free-solid-svg-icons";

const StyledDot = withStyles(theme => ({
  badge: {
    right: 3,
    top: 3,
  },
}))(Badge);

const ThumbAgr = (props) => {

  const useStyles = makeStyles(theme => ({
    cardResizing: {
        [theme.breakpoints.only('xs')]: { width: '150px' },
        [theme.breakpoints.only('sm')]: { width: '180px' },
        [theme.breakpoints.up('md')]: { width: '200px' },
        '&:hover' : {
            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 24px 0px',
            cursor: 'pointer',
        }
    },
    cardResizingDash: {
        [theme.breakpoints.only('xs')]: { width: '144px' },
        [theme.breakpoints.only('sm')]: { width: '200px' },
        [theme.breakpoints.up('md')]: { width: '200px' },
        '&:hover' : {
            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 24px 0px',
            cursor: 'pointer',
        },
    },
    bold: {
        fontWeight: '700'
    },
    tinyFont: {
        fontSize: '11px'
    },
    agrStatusPrimary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontWeight: '500',
        paddingLeft: '2px',
        paddingRight: '2px',
    },
    agrStatusDefault: {
        color: theme.palette.grey[800],
        fontWeight: '500',
        paddingLeft: '2px',
        paddingRight: '2px',
    },
    hideOnSM: {
        [theme.breakpoints.down('sm')]: { display: 'none' },
        //[theme.breakpoints.up('md')]: { fontSize: '11px' },
    },
    boxFullName: {
        [theme.breakpoints.only('xs')]: { height: '26px' },
        [theme.breakpoints.only('sm')]: { height: '30px' },
        [theme.breakpoints.up('md')]: { height: '35px' },
    },
    agrFullName: {
        fontWeight: '700',
        [theme.breakpoints.only('xs')]: { fontSize: '11px', fontWeight: '500', lineHeight: '13px', marginTop: '6px', marginBottom: '0px' },
        [theme.breakpoints.only('sm')]: { fontSize: '13px', lineHeight: '15px', marginTop: '6px', marginBottom: '0px', },
        [theme.breakpoints.up('md')]: { fontSize: '14px', lineHeight: '16px', marginTop: '6px', marginBottom: '0px', },
    },
    statusChip: {
        fontSize: '12px', 
        fontWeight: '500',
        marginBottom: '10px',
        padding: '2px 5px 2px 5px',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    tinyPen: {
        color: theme.palette.primary.main,
        fontSize: '10px',
        paddingLeft: '8px',
    },
    tinyPenSec: {
        color: theme.palette.secondary.main,
        fontSize: '10px',
        paddingLeft: '8px',
    },
  }));
  const classes = useStyles();

  const history = useHistory()

  const handleThumbClick = () => {
    if(props.handleSelection !== undefined && props.selectType !== undefined && props.ag !== undefined && props.ag.oatid !== undefined) {
        props.handleSelection(props.selectType, props.ag.oatid)
    } else if(props.path !== undefined) {
        history.push(props.path);
    }
  }

  return (
    <Tooltip title={props.fromDashboard || props.fromLegal ? "" : props.actionReq && props.ag.agrStatus === 'Execution' ? "Signature(s) pending" : props.ag.agrStatus === 'InForce' ? "" : props.actionReq ? "Next action is with you" : "Waiting for counterparty"} placement="bottom" arrow>
    <StyledDot color="secondary" variant="dot" invisible={props.hideDot}>
        <Card variant="outlined" className={props.fromDashboard || props.fromLegal ? classes.cardResizingDash : classes.cardResizing} onClick={e => handleThumbClick()}>
        <Box mt={2} mb={1} ml={2} mr={2}>
            <Avatar src={props.cptyLogo} style={{height: '30px', width: '30px'}} />
            <Box className={classes.boxFullName} mt={1} display={{ xs: 'none', sm: 'block' }} /* SM UP */ >
                <Typography align="center" variant="body1" className={classes.agrFullName}>
                    {processLongString(props.ag.agrFullName,19,27)}
                </Typography>
            </Box>
            <Box className={classes.boxFullName} mt={1} display={{ xs: 'block', sm: 'none' }} /* XS */ >
                <Typography align="center" variant="body1" className={classes.agrFullName}>
                    {processLongString(props.ag.agrFullName,17,22)}
                </Typography>
            </Box>
        </Box>

        <Box mt={0} mb={0} display={{ xs: 'none', sm: 'block' }}>
        <Typography align="center" variant={props.fromLegal ? "subtitle2" : "body2"} className={classes.tinyFont}>
            {trunc(props.primaryLegalName, props.fromLegal ? 25 : 27)}
        </Typography>
        <Typography align="center" variant="body2" className={classes.tinyFont}>
            {trunc(props.secondaryLegalName, 27)}
        </Typography>
        </Box>

        <Box mb={2} style={{marginTop: '15px'}}>

            <Chip 
                variant={["Active", "Published"].includes(props.ag.agrStatus) ? "default" : "outlined"}
                color={["Active"].includes(props.ag.agrStatus) ? "primary" : props.ag.agrStatus === "Published" ? "secondary" : "default"} // only Relevant for Template thumbs
                label={<span>
                    {props.ag.agrStatus === "ActiveSelection" ? "Active" :
                     props.ag.agrStatus === "InForce" ? "In Effect" : props.ag.agrStatus}
                    {props.actionReq && !['Active', 'Published'].includes(props.ag.agrStatus) ? <FontAwesomeIcon icon={faPen} 
                    className={props.fromLegal && props.ag !== undefined && props.ag !== null && props.ag.draftFor !== undefined && props.ag.draftFor === 'Terms' ? classes.tinyPenSec : classes.tinyPen} /> : ''}</span>} 
                size="small" 
                className={classes.statusChip}
                style={['ActiveSelection'].includes(props.ag.agrStatus) ? {color: theme.palette.primary.main} :
                    !['Active', 'Published'].includes(props.ag.agrStatus) ? {color: theme.palette.grey[700]} : {}}
            />

            <Typography align="center" variant="body2" color="textSecondary" style={{fontSize: '12px'}}>
            <span className={classes.hideOnSM}>Last update: </span>{getTimeIndicationForDate(props.ag.lastUpdateDate)}
            </Typography>
        </Box>

        </Card>
    </StyledDot>
    </Tooltip>

  );
}

ThumbAgr.propTypes = {
  ag: PropTypes.object.isRequired,
};

export default ThumbAgr
