const dayjs = require('dayjs')

function getHtmlForComment(comment, leftOrRight, color, creatorName, creationDate) {

    let s = ''
    let col = color === 'primary' ? '#7243dd' : '#E80064'

    if(['===REVIEW STARTED===', '===REVIEW COMPLETED===', '===REVIEW CANCELLED==='].includes(comment)) {

        s = s + `
        <div style="width: 100%; margin-top: 20px; margin-bottom: 20px">
            <p style="text-align: center; color: #827f8f; font-size: 13px"><b>` +
            ((comment === '===REVIEW STARTED===' ? "Review started" : comment === '===REVIEW COMPLETED===' ? "Review completed" :
            comment === '===REVIEW CANCELLED===' ? "Review cancelled" : "Updated")
            +
            ` on ` +
            dayjs(creationDate).format('MMM D, YYYY h:mm A') + 
            ` by ` + 
            creatorName) +
            `</b></p>
        </div>`

    } else {

        s = s + `
        <div style="max-width: 300px; padding: 5px 20px 10px 20px; text-align: left; margin-bottom: 10px; border-radius: 0px; border: 1px solid #e0e0e0; color: #4d4b59; font-size: 12px;` +
        (leftOrRight === 'left' ? 
            'border-top-right-radius: 10px; border-bottom-right-radius: 10px; border-left: 4px solid ' + col + ';' : 
            'border-top-left-radius: 10px; border-bottom-left-radius: 10px; border-right: 4px solid ' + col + ';')
        + `">
            <p><b>` + creatorName + `</b>
            <span style="color: #827f8f; font-size: 10px; margin-left: 10px">` + dayjs(creationDate).format('MMM D, YYYY h:mm A') + `</span></p>`;

        comment.split('\n').forEach((cpar) => { s = s + '<p>' + cpar + '</p>' })
    
        s = s + `</div>`;

    }
    
    return s

}

export { getHtmlForComment }