import React from 'react';
import theme from '../theme/theme';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Checkbox, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography,
         makeStyles } from '@material-ui/core';
import { ErrorMsg } from '../components';
import { server } from '../utils'         
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faKey, faThumbsUp, faExternalLinkAlt, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import Google from '../assets/img/icon_google.png';
import Slack from '../assets/img/icon_slack.png';

function CreatePassword(props) {

  const useStyles = makeStyles(theme => ({
    clink: {
      color: theme.palette.primary.main,
      fontWeight: '500',
      fontSize: '13px',
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.secondary.main,
      },
      display: 'inline',
    },
    iconImg: {
      width: '20px',
      height: 'auto',
    },
  }));
  const classes = useStyles();  

  const [formData, setFormData] = React.useState({ email: '', newPassword: '' })
  const [submitting, setSubmitting] = React.useState(false)
  const [loginError, setLoginError] = React.useState(null)
  const [emailVerified, setEmailVerified] = React.useState(false)
  const [lastLogin, setLastLogin] = React.useState(null);
  const [userRole, setUserRole] = React.useState(null);
  const [agreed, setAgreed] = React.useState(false);  
  const [passwordCreated, setPasswordCreated] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)

  const handleEmailSubmit = async e => {
    e.preventDefault();

    setSubmitting(true);
    setLoginError(null)
    const { email } = formData
    const verifyString = props.randomstring;
    
    const { success, role, lastLogin, data } = await server.postAsync('/auth/verifyemail', {
      email, verifyString
    })


    if (success) { // success
      setSubmitting(false);
      setLastLogin(lastLogin)
      setUserRole(role)
      setEmailVerified(true);
      return
    } else { // error
      setSubmitting(false);
      setEmailVerified(false)
      setLoginError(data);
    }
  }

  const handlePassSubmit = async e => {
    e.preventDefault();
    setSubmitting(true);
    setLoginError(null)

    if((lastLogin !== undefined && lastLogin !== null) || userRole !== 'Counterparty' || agreed) {

      const { email, newPassword } = formData
      const verifyString = props.randomstring;

      // reverify to ensure nothing changed
      const { success, data } = await server.postAsync('/auth/verifyemail', {
        email, verifyString
      })

      if (success) { // successfully reverified email
        const { success, data } = await server.postAsync('/auth/createpassword', {
          email, newPassword
        })
    
        if (success) { // success
          setPasswordCreated(true);
          return
        } else { // error
          setSubmitting(false);
          setEmailVerified(false)
          setLoginError(data);
        }
      } else { // error
        setSubmitting(false);
        setEmailVerified(false)
        setLoginError(data);
      }
    } else {
      setSubmitting(false);
      setLoginError('Please review and accept our Terms of Use and Privacy Policy');
    }
  }


  switch (emailVerified) {

    case true:

      if(passwordCreated) {
        return (
          <div>

            <Grid container justify="center" alignItems="center" style={{ minHeight: '95vh' }}>
              <Grid container direction="column" justify="center" alignItems="center" spacing={2} style={{ margin: 0, width: '100%', }}>
            
                <Grid item>
                    <Box mt={6} mb={6} mr={4} ml={4} align="center">
                    <FontAwesomeIcon icon={faThumbsUp} size="5x" color={theme.palette.primary.main} />
                    </Box>
                    <Box mb={7} align="center">
                    <Typography align="center" style={{fontWeight: '600'}}>
                        That's it, you are done
                    </Typography>
                    <Typography align="center" gutterBottom >
                      You have succesfully created your password
                    </Typography>
                    <Box align="center" mt={5} mb={3}>
                        <Button component={RouterLink} to="/core/login" color="primary">Login to Canveo</Button>
                    </Box>
                    </Box>
                </Grid>
              </Grid>
            </Grid>

          </div>

        )
      } else {
        return(
          <div>
            <form onSubmit={handlePassSubmit}>
            <Grid container justify="center" alignItems="center" style={{ minHeight: '95vh' }}>
              <Grid container direction="column" justify="center" alignItems="center" spacing={2} 
              style={{ margin: 0, width: '100%', }}>
                <Grid item container direction="column" xs={10} sm={8} md={6} justify="center">
                  <Box mb={2}>
                    <Typography variant="h4" align="center">Create your password</Typography>
                  </Box>
                  <Box mb={2}>
                    <Typography variant="body1" color="textSecondary" align="center">Step 2 of 2 - Provide your new password</Typography>
                  </Box>
                </Grid>
                <Grid item container xs={9} sm={7} md={5}>
                  <Box mb={1} style={{width: '100%'}}>
                    <TextField
                    required
                    fullWidth
                    variant="outlined"
                    id="email"
                    placeholder="Your email address"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    disabled
                    value={formData.email}
                    //onChange={e => setFormData({ ...formData, email: e.target.value.toLowerCase() })}
                    InputProps={{
                      startAdornment: (
                      <InputAdornment position="start">
                          <FontAwesomeIcon icon={faEnvelope} color={theme.palette.grey[600]} />
                      </InputAdornment>
                      ),
                    }}
                    />
                  </Box>
                </Grid>
                <Grid item container xs={9} sm={7} md={5}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    id="newPassword"
                    placeholder="Your new password"
                    label="New password"
                    name="newPassword"
                    autoComplete="newPassword"
                    //type="password"
                    type={showPassword ? 'text' : 'password'}
                    autoFocus
                    value={formData.newPassword}
                    onChange={e => setFormData({ ...formData, newPassword: e.target.value })}
                    InputProps={{
                      startAdornment: (
                      <InputAdornment position="start">
                          <FontAwesomeIcon icon={faKey} color={theme.palette.grey[600]} />
                      </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={e => setShowPassword(!showPassword)}
                            onMouseDown={e => e.preventDefault()}
                            //size="small"
                            style={{fontSize: '16px', padding: '7px 6px 7px 6px'}}
                          >
                            {showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />                  
                </Grid>
                {(lastLogin === undefined || lastLogin === null) && userRole === 'Counterparty' ?
                <Grid item>

                  <Box mt={2} mb={2} align="center">
                    <Checkbox
                    checked={agreed}
                    onChange={e => setAgreed(!agreed)}
                    name="agreeing"
                    color="secondary"
                    />
                    I understand and agree to the <a href="/network/canveo/msa" target='_blank' className="seclink">Terms of Use <FontAwesomeIcon icon={faExternalLinkAlt} style={{fontSize: '12px'}} /></a> and the <a href="/network/canveo/privacy-policy" target='_blank' className="seclink">Privacy Policy <FontAwesomeIcon icon={faExternalLinkAlt} style={{fontSize: '12px'}} /></a>
                  </Box>

                </Grid>
                : '' }
                <Grid item container xs={9} sm={7} md={5}>
                  <Button
                    fullWidth
                    disableElevation
                    disabled={submitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                  { submitting && (<CircularProgress size={24} /> )}
                  { submitting ? 'Submitting...' : 'Submit' }
                  </Button>
                </Grid>
                <Grid item container xs={9} sm={7} md={5} justify="center">
                  <Box mt={1} mb={2}>
                    <Typography variant="body2"><RouterLink to="/core/login" className={classes.clink}>Already have a password?</RouterLink></Typography>
                  </Box>
                </Grid>
              </Grid>      
            </Grid>
            </form>
          </div>
        );
      }

    default:
      return(
        <div>
          <form onSubmit={handleEmailSubmit}>
          <Grid container justify="center" alignItems="center" style={{ minHeight: '95vh' }}>
            <Grid container direction="column" justify="center" alignItems="center" spacing={2} style={{ margin: 0, width: '100%', }}>
              <Grid item container direction="column" xs={10} sm={8} md={6} justify="center">
                <Box mb={2}>
                  <Typography variant="h4" align="center">Create your password</Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="body1" color="textSecondary" align="center">Step 1 of 2 - Provide your username / email</Typography>
                  { (loginError !== null) ? (<ErrorMsg msg="Unable to create your password" />) : '' }
                </Box>
              </Grid>
              <Grid item container xs={9} sm={7} md={5}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  id="email"
                  placeholder="Your email address"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  //defaultValue={formData.email.toLowerCase()}
                  value={formData.email}
                  onChange={e => setFormData({ ...formData, email: e.target.value.toLowerCase() })}
                  InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <FontAwesomeIcon icon={faEnvelope} color={theme.palette.grey[600]} />
                    </InputAdornment>
                    ),
                  }}
                />                  
              </Grid>
              <Grid item container xs={9} sm={7} md={5}>
                <Button
                  fullWidth
                  disableElevation
                  disabled={submitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                { submitting && (<CircularProgress size={24} /> )}
                { submitting ? 'Submitting...' : 'Submit' }
                </Button>
              </Grid>
              <Grid item container justify="center" xs={9} sm={7} md={5}>
              <Box mt={0} mb={0}>
                <IconButton href="https://app.getcanveo.com/auth/google">
                  <img src={Google} className={classes.iconImg} alt="Sign in with Google" />
                </IconButton>
                <IconButton href="https://app.getcanveo.com/auth/slack">
                  <img src={Slack} className={classes.iconImg} alt="Sign in with Slack" />
                </IconButton>
              </Box>
              </Grid>
              <Grid item container xs={9} sm={7} md={5} justify="center">
                <Box mt={0} mb={2}>
                  <Typography variant="body2"><RouterLink to="/core/login" className={classes.clink}>Already have a password?</RouterLink></Typography>
                </Box>
              </Grid>
            </Grid>      
          </Grid>
          </form>
        </div>
      );
    }
}

export default CreatePassword;