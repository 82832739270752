import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { stripPlain, convertBlocksToPlain } from '../utils';

const processString = require('react-process-string');

const CleanClause = (props) => {

    const useStyles = makeStyles(theme => ({
      secTitle: { 
        fontWeight: '700', 
        marginBottom: '6px', 
        fontSize: '24px' 
      },
      ssecTitle: { 
        fontWeight: '600', 
        marginBottom: '6px', 
        fontSize: '20px' 
      },
      cTitle: {
        fontWeight: '700', 
        marginBottom: '6px',
        [theme.breakpoints.down('xs')]: { fontSize: '13px' },
        [theme.breakpoints.only('sm')]: { fontSize: '14px' },
        [theme.breakpoints.up('md')]: { fontSize: '16px' },
      },
      cPar: {
        fontWeight: '300',
        [theme.breakpoints.down('xs')]: { fontSize: '13px' },
        [theme.breakpoints.only('sm')]: { fontSize: '14px' },
        [theme.breakpoints.up('md')]: { fontSize: '16px' },
      }
    }));
    const classes = useStyles();

    const regexConfig = [{
        regex: /<(sf\d+)>\s*.*<\/\1>/gim,
        fn: (key, result) => 
          <span key={key} 
          style={props.active && (props.singleRef === undefined || props.singleRef === null || props.singleRef === result[0].match(/<\/sf\d+>/)[0].substring(2,result[0].match(/<\/sf\d+>/)[0].length - 1)) ? {backgroundColor: (props.activeRef === result[0].match(/<\/sf\d+>/)[0].substring(2,result[0].match(/<\/sf\d+>/)[0].length - 1) ? '#ffdd76' : '#ffefbe'), cursor: 'pointer'} : {}}
          onClick={props.active && props.click !== null ? e => props.click(result[0].match(/<\/sf\d+>/)[0].substring(2,result[0].match(/<\/sf\d+>/)[0].length - 1)) : null}>
            {result[0].replace(/<(sf\d+)>/g,'').replace(/<\/sf\d+>/g,'')}
          </span>
        }, {
        regex: /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |,|$|\.)/gim,
        fn: (key, result) => 
          <span key={key} className="cookieconsent">
            <a target="_blank" rel="noopener noreferrer" href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`}>{result[2]}.{result[3]}{result[4]}</a>{result[5]}
          </span>
        }
    ];

    return (
    <>
        {(convertBlocksToPlain(props.blocks, props.withSmartFields).map((cpar,i) => ( 
        <Typography key={i} 
        className={
          cpar.startsWith('<secTitle>') ? classes.secTitle :
          cpar.startsWith('<ssecTitle>') ? classes.ssecTitle :
          cpar.startsWith('<title>') ? classes.cTitle : classes.cPar
        }
        //style={
        //cpar.startsWith('<secTitle>') ? { fontWeight: '700', marginBottom: '6px', fontSize: '24px' } :
        //cpar.startsWith('<ssecTitle>') ? { fontWeight: '600', marginBottom: '6px', fontSize: '20px' } :
        //cpar.startsWith('<title>') ? { fontWeight: '700', marginBottom: '6px' } : {}}
        >
          {processString(regexConfig)(stripPlain(cpar))}<br/>
        </Typography> )))}
    </>
    );
}

export default CleanClause