import * as ActionTypes from '../ActionTypes';

const axios = require('axios');

export function fetchCertsByOrgID(orgid) {
  return dispatch => {
      dispatch(fetchCertsPending());

      axios.get('/cert/certs/' + orgid)
      .then(function(res) {
        dispatch(fetchCertsSuccess(res.data.data));
      })
      .catch(function(err) {
        dispatch(fetchCertsError(err));
      })
  }
}

export const fetchCertsPending = () => ({
    type: ActionTypes.FETCH_CERTS_PENDING
});

export const fetchCertsDone = () => ({
  type: ActionTypes.FETCH_CERTS_DONE
});

export const fetchCertsError = (error) => ({
    type: ActionTypes.FETCH_CERTS_ERROR,
    payload: error
});

export const fetchCertsSuccess = (certs) => ({
    type: ActionTypes.FETCH_CERTS_SUCCESS,
    payload: certs
});

export const createCert = (title, description, logoURL, referenceURL, sinceDate, orgID, creationBy) => {
  return dispatch => {
    dispatch(fetchCertsPending())
    let creationDate = new Date().toISOString();
    axios.post('/cert/protect/cert', {
        title: title,
        description: description,
        logoURL: logoURL,
        referenceURL: referenceURL,
        sinceDate: sinceDate,
        orgID: orgID,
        creationBy: creationBy,
        creationDate: creationDate,
        lastUpdateBy: creationBy,
        lastUpdateDate: creationDate,
    })
    .then(function (res) { // got a msg from the server
      if(res.data.success) { // successfully added
        dispatch(addCert(res.data.data));
        dispatch(fetchCertsDone())
      } else { // something went wrong while adding
        dispatch(fetchCertsError(res.message));
      }
    })
    .catch(function (err) { // err in the server request
      dispatch(fetchCertsError(err))
    })       
  }
};

export const addCert = (cert) => ({
  type: ActionTypes.ADD_CERT,
  payload: cert
});

export const updateCert = (certid, title, description, logoURL, referenceURL, sinceDate, lastUpdateBy) => {
  return dispatch => {
    
    let lastUpdateDate = new Date().toISOString();
    axios.put('/cert/protect/cert/' + certid, {
        title: title,
        description: description,
        logoURL: logoURL,
        referenceURL: referenceURL,
        sinceDate: sinceDate,
        lastUpdateBy: lastUpdateBy,
        lastUpdateDate: lastUpdateDate,
    })
    .then(function (res) { // success
      // succesful update - you will get the updated cert
      dispatch(updCert(res.data.data))
    })
    .catch(function (err) { // err
      dispatch(fetchCertsError(err))
    })       
  }
};

export const updCert = (cert) => ({
  type: ActionTypes.UPDATE_CERT,
  payload: cert
});

export const deleteCert = (certid) => {
  return dispatch => {
    axios.delete('/cert/protect/cert/' + certid)
    .then(function(res) {
      dispatch(removeCert(certid));
    })
    .catch(function(err) {
      dispatch(fetchCertsError(err))
    })
  }
}

export const removeCert = (certid) => ({
  type: ActionTypes.REMOVE_CERT,
  payload: certid
});


// ORGPROFILE CERTS

export function fetchOrgProfileCertsByOrgID(orgid) {
  return dispatch => {
      dispatch(fetchOrgProfileCertsPending());

      axios.get('/cert/certs/' + orgid)
      .then(function(res) {
        dispatch(fetchOrgProfileCertsSuccess(res.data.data));
      })
      .catch(function(err) {
        dispatch(fetchOrgProfileCertsSuccess([]))
        dispatch(fetchOrgProfileCertsError(err));
      })
  }
}

export const fetchOrgProfileCertsPending = () => ({
  type: ActionTypes.FETCH_ORGPROFILECERTS_PENDING
});

export const fetchOrgProfileCertsError = (error) => ({
  type: ActionTypes.FETCH_ORGPROFILECERTS_ERROR,
  payload: error
});

export const fetchOrgProfileCertsSuccess = (orgProfileCerts) => ({
  type: ActionTypes.FETCH_ORGPROFILECERTS_SUCCESS,
  payload: orgProfileCerts
});