const axios = require('axios');

export const createComment = (faf, creatorID, creatorDisplayName, orgID, entityID, comment, commentType, agrID, singleClauseID, ticketID, relID) => {
  return dispatch => {

    let creationDate = new Date().toISOString();
    axios.post('/comment/comment', {
      creatorID: creatorID, 
      creatorDisplayName: creatorDisplayName, 
      orgID: orgID,
      entityID: entityID,
      creationDate: creationDate,
      comment: comment, 
      commentType: commentType, 
      agrID: agrID,
      singleClauseID: singleClauseID, 
      ticketID: ticketID,
      relID: relID, 
    })
  }
};

export const updateComment = (cid, comment, blocks, lastUpdateBy) => {
  return dispatch => {

    let lastUpdateDate = new Date().toISOString();
    axios.put('/comment/comment/' + cid, {
      comment: comment,
      blocks: blocks,
      lastUpdateBy: lastUpdateBy, 
      lastUpdateDate: lastUpdateDate,
    })
  }
}
