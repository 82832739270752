
import React, { useEffect } from 'react';
import { getAgrContentForPDF } from '../utils';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Box, Container, Grid, Typography,
         makeStyles } from '@material-ui/core';
import { Header, SkeletonCustom, TableEditable } from '../components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const axios = require('axios');
const dayjs = require('dayjs')
const processString = require('react-process-string');

function Oat(props) {

  const useStyles = makeStyles(theme => ({
    outerFlexGrid: {
        minHeight: '80vh',
        paddingTop: '100px',
        paddingBottom: '80px',
    },
    iconError: {
      color: theme.palette.error.main,
      fontSize: '80px',
    },
    titleSpan: {
      fontWeight: '700',
      fontDecoration: 'underline',
    },
    agrTitle: {
      paddingTop: '15px',
      paddingBottom: '0px',
      fontWeight: '700',
    },
    agrCreated: {
      paddingTop: '15px',
      paddingBottom: '45px',
    },
    secTitle: {
      paddingTop: '25px',
      paddingBottom: '15px',
      fontWeight: '400',
    },
    subsecTitle: {
      fontWeight: '600',
      paddingTop: '15px',
      paddingBottom: '15px',
    },
    mainBody: {
      lineHeight: '1.9',
      paddingTop: '0px',
    },
    buttonIcon: {
      marginRight: '10px',
    },
    orgLogo: {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      height: '100px',
      width: '100px',
      marginTop: '40px',
      marginBottom: '50px',
    },
  }));
  const classes = useStyles();

  const [oat, setOat] = React.useState({});
  const [agrType, setAgrType] = React.useState({});
  const [logoHover, setLogoHover] = React.useState(false);
  const [agrContent, setAgrContent] = React.useState(null)

  let config = [{
    regex: /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |,|$|\.)/gim,
    fn: (key, result) => <span key={key} className="cookieconsent">
                             <a href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`}>{result[2]}.{result[3]}{result[4]}</a>{result[5]}
                         </span>
  }, {
    regex: /(\S+)\.([a-z]{2,}?)(.*?)( |,|$|\.)/gim,
    fn: (key, result) => <span key={key} className="cookieconsent">
                              <a href={`http://${result[1]}.${result[2]}${result[3]}`}>{result[1]}.{result[2]}{result[3]}</a>{result[4]}
                          </span>
  }];

  useEffect(() => {
    let unmounted = false;
    if (props.orgProfileAgrTemplates !== undefined && props.agrTypes !== undefined && props.agrTypes.length > 0 && 
        props.orgProfileAgrTemplates.length > 0 && props.oat !== undefined && 
        !props.orgProfilePending && !props.orgProfileAgreTemplatesPending &&
        props.oat !== 'rel' && props.oat !== '' &&
        (props.orgProfileAgrTemplates.filter((oat) => oat._id === props.oat || (oat.oaturl !== undefined && oat.oaturl === props.oat))[0] !== undefined/* || 
        ['cookie-policy', 'privacy-policy', 'data-security-exhibit', 'technical-support-exhibit', 'terms-of-use', 'msa', 'sla', 'dpa', 'scc'].includes(props.oat)*/)) {
      // Set the Agreement Type
      let oat = '', at = ''
      /*if(['cookie-policy', 'privacy-policy', 'data-security-exhibit', 'technical-support-exhibit', 'terms-of-use', 'msa', 'sla', 'dpa', 'scc'].includes(props.oat)) {
        let sn = (props.oat === 'cookie-policy' ? 'CP' : 
                  props.oat === 'privacy-policy' ? 'PP' : 
                  props.oat === 'data-security-exhibit' ? 'SE' :
                  props.oat === 'technical-support-exhibit' ? 'TSE' :
                  props.oat === 'terms-of-use' ? 'TOU' : 
                  props.oat === 'msa' ? 'MSA' : 
                  props.oat === 'sla' ? 'SLA' : 
                  props.oat === 'dpa' ? 'DPA' : 
                  props.oat === 'scc' ? 'SCC' : 
                  'PP')
        at = (sn === 'MSA' ? props.agrTypes.filter((at) => at.shortName === sn && at.fullName[0] === 'Master Services Agreement')[0]
             : props.agrTypes.filter((at) => at.shortName === sn)[0])
        oat = props.orgProfileAgrTemplates.filter((oat) => oat.agrTypeID === at._id && oat.external)[0]
      } else {*/
      oat = props.orgProfileAgrTemplates.filter((oat) => oat._id === props.oat || (oat.oaturl !== undefined && oat.oaturl === props.oat))[0];
      at = props.agrTypes.filter((at) => at._id === oat.agrTypeID)[0]
      //}

      let cls = [] // tempArray for clauses

      if(oat !== undefined && oat.clauses !== undefined) {
        axios
        .post('/clause/clauses', { clauses: oat.clauses }) // Need to get the relevant clauses
        .then(function(rescls) {
          cls = rescls.data.data;  // ASSIGN cls
  
          let agrContent = getAgrContentForPDF(0, null, oat.agrFullName !== undefined && oat.agrFullName !== '' ? oat.agrFullName : at.fullName[0], oat.clauses, cls)
  
          if (!unmounted) {
            setAgrContent(agrContent)
            setAgrType(at);
            setOat(oat)
          }
  
        }).catch(function(err) { console.log(err) })
      } else {
        setAgrContent('notfound')
      }
    } else {
      setAgrContent('notfound')
    }

    return () => { unmounted = true };
    
  }, [props.agrTypes, props.oat, props.orgProfileAgrTemplates, props.orgProfileAgreTemplatesPending, props.orgProfilePending])

  return(
    <div>
        <Header 
          module="network"
          user={props.user}
          org={props.org}
          modules={
            props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
              ['sales','procurement','partnerships','people','corporate'] : 
            props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
              props.org.modules : [] }
          orgProfile={props.orgProfile}
          searchOptions={props.network}
          crumbs={[
            {name: "Home", to: "/network" },
            {name: (props.orgProfile.shortName !== undefined)? props.orgProfile.shortName : "", to: "/network/" + props.orgurl},
            {name: "Terms & Agreements", to: "/network/" + props.orgurl + "/terms/all"},
            {name: (agrType !== undefined ? agrType.shortName : ""), to: "active" }
          ]}
          notifications={props.notifications}
          markNotificationAsRead={props.markNotificationAsRead}
          markAllNotificationsAsRead={props.markAllNotificationsAsRead}
        />

        <Container maxWidth="lg">
        <Grid container direction="column" justify="flex-start" alignItems="center" className={classes.outerFlexGrid}>
        {
          props.orgProfilePending || agrContent === null || agrContent === undefined || agrContent.clauseContent === undefined  ? 
          (
            <Grid item>
              <Box mt={6} align="center">
                <SkeletonCustom type="agreement" />
              </Box>
            </Grid>
          )
          :
          (props.orgProfileError !== null || agrContent === 'notfound') ? 
          (
            <Grid item>
              <Box mt={5} mb={8} align="center">
                <FontAwesomeIcon icon={faExclamationTriangle} className={classes.iconError} />
              </Box>
              <Typography variant="h6" align="center">
                { props.orgProfileError !== null || agrContent === 'notfound' ?
                  "This page could not be found"
                  : "There is an unexpected error" }
              </Typography>
              <Box mt={5} mb={5} align="center">
                { props.orgProfileError !== null || agrContent === 'notfound' ? 
                  <a href="https://getcanveo.com/" className="primlink">Visit Canveo</a>
                  : 
                  <Avatar src={props.orgProfile.logoURL} alt={props.orgProfile.shortName} className={classes.orgLogo}
                    component={RouterLink} to={"/network/" + props.orgurl} />
                }
              </Box>
            </Grid>
          )
          :
          (
            <Grid item md={9}>
              <Box align="center" mb={2}>                
                <Avatar src={logoHover ? '' : props.orgProfile.logoURL} alt={props.orgProfile.shortName} className={classes.orgLogo}
                    component={RouterLink} to={"/network/" + props.orgurl}
                    onMouseEnter={e => setLogoHover(true)} onMouseLeave={e => setLogoHover(false)}>
                    {logoHover ? <FontAwesomeIcon icon={faArrowLeft} style={{fontSize: '50px'}} /> : ''}
                </Avatar>
              </Box>
              <Typography variant="h3" align="center" className={classes.agrTitle}>
                {agrContent.agrTitle}
              </Typography>
              <Typography variant="body1" align="center" color="textSecondary" className={classes.agrCreated}>
                Created on {oat !== undefined ? dayjs(oat.creationDate).format('MMM D, YYYY') : ''}
              </Typography>

              {agrContent.clauseContent.map((cl, i) => (
                /*['sec'].includes(cl.clauseCat) ?
                  <Typography variant="h4" color="textPrimary" align="left" className={classes.secTitle} key={i}>
                    {cl.no + " " + cl.title}
                  </Typography>
                :
                ['ssec'].includes(cl.clauseCat) ?
                  <Typography variant="h5" color="textPrimary" align="left" className={classes.subsecTitle} key={i}>
                    {cl.no + " " + cl.title}
                  </Typography>
                :*/
                ['sec','ssec','cl', 'cl1', 'cl2', 'cl3', 'cl4', 'preamble'].includes(cl.clauseCat) ?
                  <Box mt={1} mb={2} key={i}>
                    <Typography className={classes.mainBody} 
                    variant={['sec'].includes(cl.clauseCat) ? "h5" :
                    ['ssec'].includes(cl.clauseCat) ? "h6" : "body1"}>
                    <span className={classes.titleSpan}>
                      {/*cl.no !== '' && cl.title !== '' ? (cl.no + " " + (cl.title === "(no title)" ? '' : (cl.title + (cl.title.endsWith(":") || cl.title.endsWith("\"") || cl.title.endsWith("”") ? " " : ". ")))) :
                       cl.no !== '' && cl.title === '' ? (cl.no + " ") :
                       cl.no === '' && */
                       cl.title.trim() !== '' ? (cl.title.trim() + (cl.title.trim().endsWith(":") || cl.title.trim().endsWith("\"") || cl.title.trim().endsWith("”") ? " " : ". ")) : '' }
                    </span>
                    {/*processString(config)(cl.content)*/}
                    {cl.content.split('\n').map((cpar,i) => ( <React.Fragment key={cl._id + "_" + i}>{processString(config)(cpar)}<br/></React.Fragment> ))}
                    </Typography>
                  </Box>
                :
                ['table'].includes(cl.clauseCat) ?
                  <React.Fragment key={i}>
                    {/*<Box mt={1} mb={2} className={classes.mainBody}>
                    {cl !== undefined ?
            
                        <Typography variant="body1"  className={classes.titleSpan}>
                          {cl.no !== '' && cl.title !== '' ? (cl.no + " " + (cl.title === "(no title)" ? '' : (cl.title + (cl.title.endsWith(":") || cl.title.endsWith("\"") || cl.title.endsWith("”") ? " " : ". ")))) :
                           cl.no !== '' && cl.title === '' ? (cl.no + " ") :
                           cl.no === '' && cl.title !== '' ? (cl.title + (cl.title.endsWith(":") || cl.title.endsWith("\"") || cl.title.endsWith("”") ? " " : ". ")) : '' }
                        </Typography>
                    : '' }
                    </Box>*/}
                    <Box mb={3} mt={1} className={classes.mainBody}>
                    <TableEditable
                      active={false}
                      disabled={true}
                      editMode={'none'}
                      clause={cl}
                      proposedDel={null}
                      focusClause={() => void 0}
                      curCpty={"primary"}
                    />
                    </Box>
            
                  </React.Fragment>
                    :
                ''
              ))}

            </Grid>
          )
        }
        </Grid>
        </Container>
        
    </div>
  )
}
  
export default Oat
