function didBlocksChange (oldBlocks, newBlocks) {
    
    let wasUpdated = false;
    let memEntMap = oldBlocks.entityMap;
    let updEntMap = newBlocks.entityMap;

    // Check if the blocks / entities were changed and saving is required
    if(oldBlocks.blocks.length !== newBlocks.blocks.length){ wasUpdated = true; } // WAS UPDATED if number of blocks differs 
    else {
        oldBlocks.blocks.forEach((b, i) => { // check for every block
            // Check entity differences
            if(b.entityRanges.length !== newBlocks.blocks[i].entityRanges.length || b.text !== newBlocks.blocks[i].text || b.type !== newBlocks.blocks[i].type) { wasUpdated = true; } // WAS UPDATED if text OR number of entities OR blockType differ
            else {
              b.entityRanges.forEach((er, j) => { // check for every entity inside the block
                if(memEntMap[er.key].type !== updEntMap[newBlocks.blocks[i].entityRanges[j].key].type || er.length !== newBlocks.blocks[i].entityRanges[j].length || !er.offset === newBlocks.blocks[i].entityRanges[j].offset) {
                    wasUpdated = true; // WAS UPDATED if any of the entity details differ
                }
              })
            }
            // Check smartfield differences - if necessary
            if(!wasUpdated && (
              (b.inlineStyleRanges !== undefined && (newBlocks.blocks[i] === undefined || newBlocks.blocks[i].inlineStyleRanges === undefined)) || 
              (b.inlineStyleRanges === undefined && newBlocks.blocks[i] !== undefined && newBlocks.blocks[i].inlineStyleRanges !== undefined) || 
              (b.inlineStyleRanges.length !== newBlocks.blocks[i].inlineStyleRanges.length))) {                     
                wasUpdated = true; 
            }
            else if(!wasUpdated) {
              b.inlineStyleRanges.forEach((ir, k) => {
                if(newBlocks.blocks[i] === undefined || newBlocks.blocks[i].inlineStyleRanges === undefined ||
                  newBlocks.blocks[i].inlineStyleRanges[k] === undefined || 
                  newBlocks.blocks[i].inlineStyleRanges[k].style !== ir.style ||
                  newBlocks.blocks[i].inlineStyleRanges[k].offset !== ir.offset ||
                  newBlocks.blocks[i].inlineStyleRanges[k].length !== ir.length) {
                    wasUpdated = true;
                  }
              })
            }
        })
    }
    return wasUpdated
  }

  export { didBlocksChange }