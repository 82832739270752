import React, { useEffect } from 'react';
import theme from '../theme/theme';
import { sendReviewEmailToUser, getTimeIndicationForDate, convertBlocksToPlainComment, 
         convertPlainToBlocksComment, getRecipientModule } from '../utils'
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Avatar, Badge, Button, Box, CircularProgress, Chip, Dialog, DialogActions, DialogContent, Grid,
         IconButton, List, ListItem, ListItemAvatar, ListItemText, Slide, Typography,
         makeStyles, withStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { AvatarInitials, CommentDisplayBox, MentionEditor, InformationalTooltip, SelectUsers } from '.';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSlash, faArrowLeft, faCheck, faPlusCircle, faCaretRight, faMinusCircle, faPaperPlane, faHourglassStart, faSearch } from "@fortawesome/free-solid-svg-icons";  

const axios = require('axios');

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledBadge = withStyles(theme => ({
    badge: {
      backgroundColor: theme.palette.error.main,
      right: 1,
      top: 60,
      padding: '0 4px',
      '&:hover' : {
        backgroundColor: theme.palette.error.dark,
        cursor: 'pointer'
      }
    },
}))(Badge);

const DialogTitle = (props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h6" style={{fontWeight: '700'}}>{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" style={{position: 'absolute', right: '8px', top: '10px'}} onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} style={{fontSize: '20px', padding: '0px 3px 0px 3px'}} />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

const DialogReview = (props) => {

  const useStyles = makeStyles(theme => ({
    cancelButton: {
        marginRight: 'auto',
    },
    mlittle: {
        marginTop: '4px',
        marginBottom: '4px',
    },
    boxFormSizingAlignsWithCardComment: {
        [theme.breakpoints.only('xs')]: { width: '200px' },
        [theme.breakpoints.up('sm')]: { width: '300px' }    
    },
    textAreaAuto: {
        [theme.breakpoints.only('xs')]: { width: '170px' },
        [theme.breakpoints.up('sm')]: { width: '270px' },
        fontSize: '14px',
        padding: '15px',
        border: '1px solid' + theme.palette.grey[400],
        borderRadius: '10px',
        minHeight: '15px',
    },
    chipDefault: {
        marginLeft: '10px',
        paddingLeft: '5px',
        paddingRight: '5px',
    },
    chipGrey: {
        marginLeft: '10px',
        paddingLeft: '5px',
        paddingRight: '5px',
        backgroundColor: theme.palette.grey[600],
    },
    /*
    chipDarkGrey: {
        marginLeft: '10px',
        paddingLeft: '5px',
        paddingRight: '5px',
        backgroundColor: theme.palette.grey[800],
    },*/
    smallOnXS: {
        [theme.breakpoints.only('xs')]: { fontSize: '10px' },
    },
    userAv: {
        backgroundColor: theme.palette.tertiary.main,
        [theme.breakpoints.only('xs')]: { 
            height: '40px',
            width: '40px',
            marginTop: '10px',
            marginBottom: '10px',
            marginLeft: '8px',
            marginRight: '8px',
        },
        [theme.breakpoints.only('sm')]: { 
            height: '50px',
            width: '50px',
            marginTop: '10px',
            marginBottom: '10px',
            marginLeft: '12px',
            marginRight: '12px',
        },
        [theme.breakpoints.up('md')]: { 
            height: '60px',
            width: '60px',
            marginTop: '25px',
            marginBottom: '25px',
            marginLeft: '18px',
            marginRight: '18px',
        },
    },
    userAvList: {
        backgroundColor: theme.palette.tertiary.main,
        [theme.breakpoints.only('xs')]: { 
            height: '40px',
            width: '40px',
            marginRight: '10px',
        },
        [theme.breakpoints.up('sm')]: { 
            height: '60px',
            width: '60px',
            marginRight: '25px',
        },
    },
    userAvFlow: {
      height: '60px',
      width: '60px',
      marginTop: '10px',
      marginBottom: '10px',
      backgroundColor: theme.palette.grey[800],
    },
    initialsAv: {
      height: '60px',
      width: '60px',
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.primary.contrastText,
      marginTop: '10px',
      marginBottom: '10px',
    },
    approvedAvGreyList: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.grey[600],
        [theme.breakpoints.only('xs')]: { 
            height: '40px',
            width: '40px',
            marginRight: '10px',
        },
        [theme.breakpoints.up('sm')]: { 
            height: '60px',
            width: '60px',
            marginRight: '25px',
        },
    },
    approvedAv: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '60px',
      width: '60px',
      marginTop: '10px',
      marginBottom: '10px',
    },
    higlightPrimary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontWeight: '600',
        paddingLeft: '2px',
        paddingRight: '2px',
        marginRight: '10px',
    },
    higlightGrey: {
        backgroundColor: theme.palette.grey[500],
        color: theme.palette.primary.contrastText,
        fontWeight: '600',
        paddingLeft: '2px',
        paddingRight: '2px',
    },
    additionalGridItem: {
        [theme.breakpoints.only('xs')]: { width: '260px' },
        width: '400px',
    },
    selectResizing: {
        [theme.breakpoints.only('xs')]: { maxWidth: '240px' },
        [theme.breakpoints.up('sm')]: { maxWidth: '400px' },
    },
    arrowIcon: {
        color: theme.palette.grey[500],
        [theme.breakpoints.only('xs')]: { padding: '5px 25px 5px 25px', },
        [theme.breakpoints.up('sm')]: { padding: '5px 25px 5px 25px' }
    },
}));
const classes = useStyles();

const history = useHistory()

const [comments, setComments] = React.useState([]);
const [commentValue, setCommentValue] = React.useState('');
const [activeTicket, setActiveTicket] = React.useState(null);
const [updating, setUpdating] = React.useState(null)
const [approverOpen, setApproverOpen] = React.useState(false)
const [approvers, setApprovers] = React.useState([]);
const [userValue, setUserValue] = React.useState(null)
const [loading, setLoading] = React.useState(false)

const curCpty = props.rel !== undefined && props.rel !== null && props.user !== undefined && props.user !== null && props.rel.primaryOrgID === props.user.orgID ? "primary" : "secondary"

useEffect(() => {

    let unmounted = false;

    if(props.open && activeTicket === null) {
        if(props.ticket !== undefined && props.ticket !== null && props.ticket._id !== undefined){
            axios.get('/ticket/ticket/' + props.ticket._id)
            .then(function(rest) {
                // Section to retrieve the comments for this ticket ID
                if(rest.data.data !== undefined && rest.data.data !== null && rest.data.data._id !== undefined){
                    axios.get('/comment/commentsticket/' + rest.data.data._id)
                    .then(function(res) {
                        if (!unmounted) { 
                            setActiveTicket(rest.data.data)
                            setComments(res.data.data) 
                        }
                    }).catch(function(err) { console.log(err) })
                }
            }).catch(function(err) { console.log(err) })
        }   
    }

    return () => { unmounted = true };

}, [props.open, activeTicket, props.ticket]);

const closeDialog = () => {
    setUpdating(null)
    setApproverOpen(false)
    setApprovers([])
    setUserValue(null)
    setActiveTicket(null)
    setComments([])
    props.parentCallToClose()
}

const handleStartReview = () => {
    
    if(commentValue.length > 0){ // If there was a comment, post it
        props.createComment(
            false, // FAF
            props.user._id, // creatorID 
            props.user.displayName, // creatorDisplayName 
            props.user.orgID, // orgID
            '', // entityID 
            commentValue, // comment
            'ticket', // commentType
            activeTicket.agrID, // agrID 
            '', // singleClauseID 
            activeTicket._id, // ticketID 
            activeTicket.relID, // relID
        )
    }

    handleAddCommentSubmit('===REVIEW STARTED===', false);

    let lastUpdateDate = new Date().toISOString();
    props.updateTicket(
        true, // FAF
        activeTicket._id, // ticketID
        activeTicket.module, // module
        'In Progress', // ticketStatus 
        activeTicket.topic, 
        activeTicket.assignee, 
        props.user._id, // lastUpdateBy 
        lastUpdateDate, // lastUpdateDate
        '', // closedBy
        '', // closedDate
        'None' // resolveReason - didn't get resolved
    )
    let updatedTick = {...activeTicket,
        ticketStatus: 'In Progress',
        lastUpdateBy: props.user._id,
        lastUpdateDate: lastUpdateDate}
    setActiveTicket(updatedTick)
    
    if(props.ticketsFromAgrPage !== undefined && props.ticketsFromAgrPage !== null && props.ticketsFromAgrPage.length > 0) {
        props.updateTicketInParent(updatedTick)
    }

    let recipient = props.users.filter((u) => u._id === activeTicket.creationBy)[0];

    // Create notification for creator of review
    props.createNotification(
        'Review Started', // notificationType
        'Agreement review started', // title
        props.user.displayName + ' started the review: ' + activeTicket.topic, // description
        '/core/' + getRecipientModule(props.user, recipient, props.module, props.rel) + '/agreement/' + activeTicket.relID + "/" + activeTicket.agrID + "/tick_" + activeTicket._id, // linkTo
        //'/core/' + props.module + '/tasks/reviews', // linkTo 
        false, // isRead
        activeTicket.creationBy, // uid
    )

    props.handleActivityLog('agr', 'agrInternalReviewStarted', '', activeTicket._id, props.user.displayName, '', {})

    // Route the user to the respective agreement
    let path = "/core/" + props.module  + "/agreement/" + activeTicket.relID + "/" + activeTicket.agrID;
    history.push(path);

    closeDialog()
}

const handleCompleteReview = () => { // If there was a comment, post it
    
    if(commentValue.length > 0){
        props.createComment(
            false, // FAF
            props.user._id, // creatorID 
            props.user.displayName, // creatorDisplayName 
            props.user.orgID, // orgID
            '', // entityID 
            commentValue, // comment
            'ticket', // commentType
            activeTicket.agrID, // agrID 
            '', // singleClauseID 
            activeTicket._id, // ticketID 
            activeTicket.relID, // relID
        )
    }

    handleAddCommentSubmit('===REVIEW COMPLETED===', false);

    let lastUpdateDate = new Date().toISOString();
    props.updateTicket(
        true, // FAF
        activeTicket._id, // ticketID
        activeTicket.module, // module
        'Resolved', // ticketStatus 
        activeTicket.topic, 
        activeTicket.assignee, 
        props.user._id, // lastUpdateBy 
        lastUpdateDate, // lastUpdateDate
        props.user._id, // closedBy
        lastUpdateDate, // closedDate
        'Review Completed' // resolveReason
    )

    let updatedTick = {...activeTicket,
        ticketStatus: 'Resolved',
        lastUpdateBy: props.user._id,
        lastUpdateDate: lastUpdateDate,
        resolveReason: 'Review Completed',
        closedBy: props.user._id}
    setActiveTicket(updatedTick)
    
    if(props.ticketsFromAgrPage !== undefined && props.ticketsFromAgrPage !== null && props.ticketsFromAgrPage.length > 0) {
        props.updateTicketInParent(updatedTick)
    }

    let recipient = props.users.filter((u) => u._id === activeTicket.creationBy)[0];

    // Create notification for creator of review
    props.createNotification(
        'Review Completed', // notificationType
        'Agreement review completed', // title
        props.user.displayName + ' completed the review: ' + activeTicket.topic, // description
        '/core/' + getRecipientModule(props.user, recipient, props.module, props.rel) + '/agreement/' + activeTicket.relID + "/" + activeTicket.agrID + "/tick_" + activeTicket._id, // linkTo
        false, // isRead
        activeTicket.creationBy, // uid
    )

    let allComments = comments.sort((a,b) => (a.creationDate < b.creationDate) ? 1 : ((b.creationDate < a.creationDate) ? -1 : 0))
    if(commentValue.length > 0) {
        allComments.unshift({ 
            comment: commentValue, 
            creationDate: lastUpdateDate, 
            creatorDisplayName: props.user.displayName, 
            creatorID: props.user._id
        })
    }

    sendReviewEmailToUser(
        'reviewcompleted', // type
        recipient, // recipient
        getRecipientModule(props.user, recipient, props.module, props.rel), // module
        activeTicket.relID, // relID
        activeTicket.agrID, // agrID
        activeTicket, // ticket
        props.user.displayName, // senderFullName 
        { topic: activeTicket.topic }, // topicObject
        commentValue, // comment
        allComments, // comments
        curCpty, // curCpty
    )

    props.handleActivityLog('agr', 'agrInternalReviewCompleted', '', activeTicket._id, props.user.displayName, '', {})

    setActiveTicket(null)
    closeDialog();

}

const handleCancelReview = () => { // If there was a comment, post it
    
    if(commentValue.length > 0){
        props.createComment(
            false, // FAF
            props.user._id, // creatorID 
            props.user.displayName, // creatorDisplayName 
            props.user.orgID, // orgID
            '', // entityID 
            commentValue, // comment
            'ticket', // commentType
            activeTicket.agrID, // agrID 
            '', // singleClauseID 
            activeTicket._id, // ticketID 
            activeTicket.relID, // relID
        )
    }

    handleAddCommentSubmit('===REVIEW CANCELLED===', false);

    let lastUpdateDate = new Date().toISOString();
    props.updateTicket(
        true, // FAF
        activeTicket._id, // ticketID
        activeTicket.module, // module
        'Cancelled', // ticketStatus 
        activeTicket.topic, 
        activeTicket.assignee, 
        props.user._id, // lastUpdateBy 
        lastUpdateDate, // lastUpdateDate
        props.user._id, // closedBy
        lastUpdateDate, // closedDate
        'None' // resolveReason - didn't get resolved
    )

    let updatedTick = {...activeTicket,
        ticketStatus: 'Cancelled',
        lastUpdateBy: props.user._id,
        lastUpdateDate: lastUpdateDate,
        resolveReason: 'None',
        closedBy: props.user._id}
    setActiveTicket(updatedTick)
    
    if(props.ticketsFromAgrPage !== undefined && props.ticketsFromAgrPage !== null && props.ticketsFromAgrPage.length > 0) {
        props.updateTicketInParent(updatedTick)
    }

    let recipient = props.users.filter((u) => u._id === activeTicket.assignee)[0];

    // Create notification for the assignee
    props.createNotification(
        'Request Cancelled', // notificationType
        'Review Request Cancelled', // title
        props.user.displayName + ' cancels the request on ' + activeTicket.topic, // description
        '/core/' + getRecipientModule(props.user, recipient, props.module, props.rel) + '/agreement/' + activeTicket.relID + "/" + activeTicket.agrID + "/tick_" + activeTicket._id, // linkTo
        //'/core/' + getRecipientModule(props.user, recipient, props.module, props.rel) + '/tasks/reviews', // linkTo 
        false, // isRead
        activeTicket.assignee, // uid
    )

    if(props.user !== undefined && props.users.filter((u) => u._id === activeTicket.assignee)[0] !== undefined) {
        props.handleActivityLog('agr', 'agrInternalReviewCanceled', '', activeTicket._id, props.user.displayName, props.users.filter((u) => u._id === activeTicket.assignee)[0].displayName, {})
    }

    setActiveTicket(null)
    closeDialog()

}

const handleAddCommentSubmit = (comment, fromUser) => {
    
    if(comment.length > 0 || !fromUser){

        let creationDate = new Date().toISOString();
        let cDisplayName = props.user.displayName

        axios.post('/comment/comment', {
            creatorID: props.user._id, // creatorID
            creatorDisplayName: cDisplayName, // creatorDisplayName
            orgID: props.user.orgID, // orgID
            entityID: '', // entityID
            creationDate: creationDate, // creationDate
            comment: comment, // comment
            commentType: 'ticket', // commentType
            agrID: activeTicket.agrID, // agrID
            singleClauseID: '', // singleClauseID
            ticketID: activeTicket._id, // ticketID
            relID: activeTicket.relID, // relID 
        }).then((res) => {

            setCommentValue('');
    
            // Add comment to array
            let allComments = comments.concat({ comment: comment, creationDate: creationDate, creatorDisplayName: cDisplayName, creatorID: props.user._id}).sort((a,b) => (a.creationDate < b.creationDate) ? 1 : ((b.creationDate < a.creationDate) ? -1 : 0))

            setComments(comments => [...comments, res.data.data])
    
            // Create notification for counterpart in the communication
            if(fromUser) {
                let recipient = (activeTicket.assignee === props.user._id ? 
                    props.users.filter((u) => u._id === activeTicket.creationBy)[0] : 
                    props.users.filter((u) => u._id === activeTicket.assignee)[0])

                props.createNotification(
                    'Review Reply', // notificationType
                    'Reply to Review Request', // title
                    cDisplayName + ' replied on ' + activeTicket.topic, // description
                    '/core/' + getRecipientModule(props.user, recipient, props.module, props.rel) + '/agreement/' + activeTicket.relID + "/" + activeTicket.agrID + "/tick_" + activeTicket._id, // linkTo
                    false, // isRead
                    (activeTicket.assignee === props.user._id ? activeTicket.creationBy : activeTicket.assignee), // uid
                )

                // Send email about open review
                sendReviewEmailToUser(
                    'replyonreview', // type
                    recipient, // recipient
                    getRecipientModule(props.user, recipient, props.module, props.rel), // module
                    activeTicket.relID, // relID
                    activeTicket.agrID, // agrID
                    activeTicket, // ticket
                    props.user.displayName, // senderFullName 
                    { topic: activeTicket.topic }, // topicObject
                    comment, // comment
                    allComments, // comments
                    curCpty, // curCpty
                )
                
                if(fromUser && activeTicket.ticketStatus === 'Resolved') {
                    // update ticket status to In Progress
                    let lastUpdateDate = new Date().toISOString();
                    props.updateTicket(
                        true, // FAF
                        activeTicket._id, // ticketID
                        activeTicket.module, // module
                        'In Progress', // ticketStatus 
                        activeTicket.topic, 
                        activeTicket.assignee, 
                        props.user._id, // lastUpdateBy 
                        lastUpdateDate, // lastUpdateDate
                        '', // closedBy
                        '', // closedDate
                        'None' // resolveReason - didn't get resolved
                    )
                    let updatedTick = {...activeTicket,
                        ticketStatus: 'In Progress',
                        lastUpdateBy: props.user._id,
                        lastUpdateDate: lastUpdateDate}
                    setActiveTicket(updatedTick)
                } else {
                    props.lastUpdateTicket(activeTicket._id, props.user._id) // FAF a ticket update to the backend
                }
            }

        }).catch((err) => console.log(err))
    }
}

const handleDeleteComment = (cid) => {
    props.updateComment(cid, '___DELETED___', null, props.user._id) // Update the DATABASE
    let cs = comments;
    let idx = comments.findIndex((c) => c._id === cid)
    cs[idx] = {...cs[idx], comment: '___DELETED___', lastUpdateDate: new Date().toISOString() }
    setComments([...cs])
}

const handleCommentChangeSubmit = (isBlocks, newComment) => {
    let plainComment = isBlocks ? convertBlocksToPlainComment(newComment) : newComment;
    let blocksComment = isBlocks ? newComment : convertPlainToBlocksComment(newComment);

    props.updateComment(updating, plainComment, blocksComment, props.user._id) // Update the DATABASE

    let idx = comments.findIndex((c) => c._id === updating)
    setComments([
        ...comments.slice(0,idx),
        {
            ...comments[idx],
            comment: plainComment,
            blocks: blocksComment,
            lastUpdateDate: new Date().toISOString(),
        },
        ...comments.slice(idx + 1)
    ])
    setUpdating(null)    
}

const handleCommentSubmit = (isBlocks, comment, newStatus) => {

    let commentIsValid = comment !== null && comment !== undefined && ((!isBlocks && comment !== '') || (isBlocks && comment !== {} && comment.blocks !== undefined && comment.blocks.some((b) => b.text !== '')))

    if(commentIsValid && approverOpen) {
        handleAddApprover(userValue, convertBlocksToPlainComment(comment))
    } else if(commentIsValid) {
        handleAddCommentSubmit(convertBlocksToPlainComment(comment), true)
    }
}

const handleAddApprover = (u, c) => {
    setApprovers(approvers => [...approvers, {user: u, comment: c}])
    setApproverOpen(false);
}

const handleRemoveApprover = (e, uid) => {
    let newApprovers = approvers.filter((a) => a.user._id !== uid);
    setApprovers(newApprovers)
}

const handleTicketDetailClose = () => {
    setActiveTicket(null)
    setComments([])
}

const handleTicketDetailOpen = (tid) => {
    setLoading(true)
    axios.get('/ticket/ticket/' + tid)
    .then(function(rest) {
        axios.get('/comment/commentsticket/' + tid)
        .then(function(res) {
            setActiveTicket(rest.data.data)
            setComments(res.data.data)
            setLoading(false)
        }).catch(function(err) { console.log(err) })
    }).catch(function(err) { console.log(err) })
}

const handleSearchChange = (value) => {
    setUserValue((value !== undefined && value !== null && value._id !== undefined) ? value : null);
}

const RenderAvatar = ({uid, type}) => { // Same in DialogReview
    return (
        props.users.filter((usr) => usr._id === uid)[0] !== undefined ? 
        <Avatar src={props.users.filter((usr) => usr._id === uid)[0].photoURL} className={type === "flow" ? classes.userAv : type === "list" ? classes.userAvList : ''}>
        {props.users.filter((usr) => usr._id === uid)[0].firstName.substr(0,1) + props.users.filter((usr) => usr._id === uid)[0].lastName.substr(0,1)}
        </Avatar>
        : '' 
    )
}

const handleApproverOpen = () => {
    //setRequestComment('');
    setApproverOpen(true)
}

const handleSubmitReviewers = () => {
    if(approvers.length > 0){
        let cptyName = props.rel.primaryOrgID === props.user.orgID ? props.secondaryCpty.legalName : props.primaryCpty.legalName
        approvers.forEach((a) => { // for every approver that was added

            let comment = a.comment !== undefined && a.comment !== null && a.comment !== '' ? a.comment : ''; //(No comment provided)
            let entID = props.rel.primaryOrgID === props.user.orgID ? props.primaryCpty._id : props.secondaryCpty._id;

            let recModule = getRecipientModule(props.user, a.user, props.module, props.rel)
            let creationDate = new Date().toISOString();
            axios.post('/ticket/ticket', {
                //module: props.module,
                module: recModule,
                ticketStatus: 'Assigned',
                ticketType: 'AgrReviewInternal',
                orgID: props.user.orgID,
                relID: props.rel._id,
                entID: entID,
                agrID: props.agr._id,
                agrVersion: props.activeAgrVersion._id,
                singleClauseID: '',
                clauseID: '',
                topic: props.agr.agrFullName + ' review for ' + cptyName,
                assignee: a.user._id,
                creationBy: props.user._id,
                creationDate: creationDate,
                lastUpdateBy: props.user._id,
                lastUpdateDate: creationDate,
                closedBy: '',
                closedDate: '',
                resolveReason: 'None',
                workflow: [],
            })
            .then(function (res) { // got a msg from the server

                let ticket = res.data.data
                
                if(comment !== '') {
                    // Now create the comment for this specific ticket
                    axios.post('/comment/comment', {
                        creatorID: props.user._id, // creatorID
                        creatorDisplayName: props.user.displayName, // creatorDisplayName
                        orgID: props.user.orgID, // orgID
                        entityID: entID, // entityID
                        creationDate: creationDate, // creationDate
                        comment: comment, // comment
                        commentType: 'ticket', // commentType
                        agrID: props.agr._id, // agrID
                        singleClauseID: '', // singleClauseID
                        ticketID: ticket._id, // ticketID
                        relID: props.rel._id, // relID 
                    })
                }

                // Create notification for counterpart in the communication
                props.createNotification(
                    'Review Request', // notificationType
                    'Review Request', // title
                    props.user.displayName + ' requested review of the ' + props.agr.agrShortName + ' for ' + cptyName, // description
                    '/core/' + recModule + '/agreement/' + props.rel._id + "/" + props.agr._id + "/tick_" + ticket._id, // linkTo
                    false, // isRead
                    a.user._id, // uid
                )

                // Send email about open review
                sendReviewEmailToUser(
                    'reviewRequest', // type
                    a.user, // recipient
                    recModule, // module
                    props.rel._id, // relID
                    props.agr._id, // agrID
                    ticket, // ticket
                    props.user.displayName, // senderFullName 
                    { cptyName: cptyName, agrFullName: props.agr.agrFullName }, // topicObject
                    comment, // comment
                    [], // comments
                    props.rel.primaryOrgID === props.user.orgID ? "primary" : "secondary", // curCpty
                )

            }).catch(function(err) { console.log(err) })
        })

        props.handleActivityLog('agr', 'agrInternalReviewRequested', '', '', props.user.displayName, approvers.length, {})

        // Now forward to relationship overview page
        let path = "/core/" + props.module + "/relationship/" + props.rel._id + "/overview";
        history.push(path);
    } // else no approver was selected
}

return (
    <Dialog
    open={props.open}
    scroll="paper"
    TransitionComponent={Transition}
    keepMounted
    onClose={closeDialog}
    fullWidth={true}
    maxWidth="sm"
    >

    {activeTicket !== null ? 
    (
    <>
        <DialogTitle onClose={closeDialog}>

            {"Review"}
            <Chip size="small"
             label={activeTicket.ticketStatus}
             color={"primary"}
             className={['Cancelled', 'Resolved'].includes(activeTicket.ticketStatus) ? classes.chipGrey : classes.chipDefault} />
            
        </DialogTitle>

        <DialogContent id="msgContainer">

            <Box mt={2} mb={3}>
            
                <Grid container direction="column" justify="flex-start" alignItems="stretch">
                    <Grid item xs={12}>
                        <Box mb={2}>
                        <Grid container direction="row" alignItems="center" justify="center">
                            <Grid item>
                                <Box align="center">
                                    <Typography variant="body2" color="textSecondary">Requestor</Typography>
                                    <RenderAvatar uid={activeTicket.creationBy} type="flow" />
                                    <Typography variant="body2" color="textSecondary">&nbsp;</Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <FontAwesomeIcon icon={faCaretRight} className={classes.arrowIcon} />
                            </Grid>
                            <Grid item>
                            <Grid item>
                                <Box align="center">
                                    <Typography variant="body2" color="textSecondary">Reviewer</Typography>
                                    <RenderAvatar uid={activeTicket.assignee} type="flow" />
                                    <Typography variant="body2" color="textSecondary">&nbsp;</Typography>
                                </Box>
                            </Grid>
                            </Grid>
                            <Grid item>
                                <FontAwesomeIcon icon={faCaretRight} className={classes.arrowIcon} />
                            </Grid>
                            <Grid item>
                                <Box align="center">
                                    <Typography variant="body2" color="textSecondary">Status</Typography>
                                    <Avatar className={classes.userAv} style={{backgroundColor: 
                                        ['Unassigned', 'Assigned', 'In Progress'].includes(activeTicket.ticketStatus) ? theme.palette.primary.main :
                                        [].includes(activeTicket.ticketStatus) ? faSearch :
                                        ['Cancelled'].includes(activeTicket.ticketStatus) ? theme.palette.grey[600] :
                                        ['Closed', 'Resolved'].includes(activeTicket.ticketStatus) ? theme.palette.success.main : theme.palette.primary.main}}>
                                    <FontAwesomeIcon icon={
                                        ['Unassigned', 'Assigned'].includes(activeTicket.ticketStatus) ? faHourglassStart :
                                        ['In Progress'].includes(activeTicket.ticketStatus) ? faSearch :
                                        ['Cancelled'].includes(activeTicket.ticketStatus) ? faSlash :
                                        ['Closed', 'Resolved'].includes(activeTicket.ticketStatus) ? faCheck : faSearch} />
                                    </Avatar>
                                    <Typography variant="body2" color="textSecondary">&nbsp;</Typography>
                                </Box>                                
                            </Grid>
                        </Grid>
                        </Box>
                    </Grid>

                    {(props.user._id === activeTicket.creationBy || props.user._id === activeTicket.assignee) && 
                      activeTicket.ticketStatus !== 'Cancelled' /*&& activeTicket.ticketStatus !== 'Resolved'*/ ?
                    (
                    <Grid xs={12} item>
                        <Box mb={3}>
                            <Grid direction="row" container justify="flex-start" alignItems="flex-start">
                                <Grid item>
                                    <Box display={{ xs: 'none', sm: 'block' }}>
                                    <AvatarInitials
                                    displayName={props.user !== undefined && props.user !== null && props.user.displayName !== undefined ? props.user.displayName : '?'}
                                    photoURL={props.user !== undefined && props.user.photoURL !== undefined && props.user.photoURL !== '' ? props.user.photoURL : null }
                                    variant={'left'}
                                    />
                                    </Box>
                                </Grid>
                                <Grid item className={classes.additionalGridItem}>
                                <MentionEditor
                                    user={props.user}
                                    users={[]}
                                    handleCommentSubmit={handleCommentSubmit}
                                    editorType={"additional"}
                                    curCpty={curCpty}
                                    isPublic={false}
                                />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    ) : '' }

                    {comments.sort((a,b) => (a.creationDate < b.creationDate) ? 1 : ((b.creationDate < a.creationDate) ? -1 : 0)).map((c) => (
                        <Grid item key={c._id}>

                            <CommentDisplayBox
                                key={c._id}
                                c={c}
                                align={c.creatorID === props.user._id ? 'left' : 'right'}
                                user={props.users.filter((u) => u._id === c.creatorID)[0]}
                                updating={c._id === updating ? updating : null}
                                canEdit={props.user !== undefined && props.user._id === c.creatorID &&
                                    !['Cancelled', 'Closed'].includes(activeTicket.ticketStatus) &&
                                    ['Unassigned', 'Assigned', 'In Progress'].includes(activeTicket.ticketStatus) ? true : false}
                                color={curCpty}
                                setUpdating={setUpdating}
                                handleDeleteComment={handleDeleteComment}
                                handleCommentChangeSubmit={handleCommentChangeSubmit}
                            />

                        </Grid>
                    ))}
                    
                </Grid>                

            </Box>

        </DialogContent>

        <DialogActions className={classes.mlittle}>
            {props.ticketsFromAgrPage !== undefined && props.ticketsFromAgrPage !== null && props.ticketsFromAgrPage.length > 0 ?
            <Button onClick={handleTicketDetailClose} variant="text" color="primary" className={classes.cancelButton}>
                <FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Back
            </Button>
            :
            <Button onClick={closeDialog} variant="text" color="primary" className={classes.cancelButton}>
                Close
            </Button>
            }

            {   activeTicket.creationBy === props.user._id && 
                (activeTicket.ticketStatus === 'Assigned' || activeTicket.ticketStatus === 'In Progress' || activeTicket.ticketStatus === 'Unassigned') ?
                
                <Button 
                    variant="text"
                    color="primary"   
                    onClick={handleCancelReview}
                >
                    Cancel Review&nbsp;&nbsp;<FontAwesomeIcon icon={faTimes} />
                </Button>
                :
                activeTicket.ticketStatus === 'Assigned' && activeTicket.assignee === props.user._id ?
                (
                <Button 
                    variant="contained"
                    color="primary"
                    onClick={handleStartReview}
                    disableElevation
                >
                    Start<Box display={{ xs: 'none', sm: 'inline' }}>&nbsp;Review</Box>
                </Button>
                )
                :''}
                {(props.ticketsFromAgrPage === null || props.ticketsFromAgrPage === undefined) &&
                !(activeTicket.ticketStatus === 'Assigned' && activeTicket.assignee === props.user._id) ?
                (
                <Button 
                    variant="contained"
                    color="primary"
                    component={RouterLink}
                    to={"/core/" + props.module + "/agreement/" + activeTicket.relID + "/" + activeTicket.agrID}
                    disableElevation
                >
                    View Agreement
                </Button>
                )
                : ''
            }
            { ['Assigned', 'In Progress'].includes(activeTicket.ticketStatus) && activeTicket.assignee === props.user._id ?
                (
                <Button 
                    variant="contained"
                    color="primary"
                    onClick={handleCompleteReview}
                    disableElevation
                >
                    Mark&nbsp;<Box display={{ xs: 'none', sm: 'inline' }}>as&nbsp;</Box>"Complete"<Box display={{ xs: 'none', sm: 'inline' }}>&nbsp;&nbsp;<FontAwesomeIcon icon={faCheck} /></Box>
                </Button>
                )
                : '' }

        </DialogActions>
    </>
    )
    : // Coming from Agreement with (potentially) multiple tickets
    props.ticketsFromAgrPage !== undefined && props.ticketsFromAgrPage !== null && !loading ?
    (
    <>

        <DialogTitle onClose={closeDialog}>
            Agreement review&nbsp;&nbsp;<InformationalTooltip msg={"Sending an agreement for internal review will notify the reviewer and create a \"review ticket\".\n\nA reviewer can comment across the agreement and potentially make edits.\n\nBoth the reviewer and the requestor can post update comments on the review ticket and close or cancel the ticket as appropriate."}/>
        </DialogTitle>

        <DialogContent id="msgContainer">

            <Box mt={props.ticketsFromAgrPage.length > 2 || approverOpen ? 3 : props.ticketsFromAgrPage.length > 1 ? 6 : 8} mb={props.ticketsFromAgrPage.length > 2 || approverOpen ? 3 : props.ticketsFromAgrPage.length > 1 ? 6 : 8} ml={1} mr={1}>


            {approverOpen ? // once you're adding an additional internal reviewer
            (
            <Box align="center" /*mt={2} mb={3}*/>
                <Box align="center" mt={5} mb={0}>
                    <Box align="left" className={classes.selectResizing}>
                    <SelectUsers
                    users={props.users.filter((u) => u.active && u._id !== props.user._id && !u.email.endsWith('support@getcanveo.com') &&
                            ((['Admin', 'Legal'].includes(u.role)) ||
                            (['Business', 'Sales'].includes(u.role) && props.module === 'sales') ||
                            (['Business', 'Procurement'].includes(u.role) && props.module === 'procurement') ||
                            (['Business', 'Partnerships'].includes(u.role) && props.module === 'partnerships') ||
                            (['Corporate'].includes(u.role) && props.module === 'corporate') ||
                            (['HR'].includes(u.role) && props.module === 'people') ||
                            (u.role.startsWith('ClientFor_') && [props.primaryCpty._id, props.secondaryCpty._id].includes(u.role.substring(10)))
                            ))}
                    parentCall={handleSearchChange}
                    label="Select Reviewer"
                    />
                    </Box>
                </Box>
                <Box align="center" mt={1} mb={4} className={classes.selectResizing}>

                    <MentionEditor
                        user={props.user}
                        users={[]}
                        handleCommentSubmit={handleCommentSubmit}
                        //handleCommentSubmit={e=>null}
                        editorType={"clean"}
                        curCpty={"primary"}
                        isPublic={false}
                        isApprovalStyling={true}
                    />
                    
                </Box>
            </Box>
            )
            :approvers.length > 0 || props.ticketsFromAgrPage.length === 0 ?
            <Grid container direction="row" alignItems="center" justify="center">
                <Grid item>
                    { (props.user.photoURL !== "" && props.user.photoURL !== undefined && props.user.photoURL !== null ) ?
                        (<Avatar src={props.user.photoURL} className={classes.userAvFlow} />) :
                        (<Avatar className={classes.initialsAv}>{props.user.firstName.charAt(0) + props.user.lastName.charAt(0)}</Avatar>)
                    }
                </Grid>
                <Grid item>
                    <FontAwesomeIcon icon={faCaretRight} className={classes.arrowIcon} />
                </Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center" justify="center">
                    {approvers.map((approver, i) => (
                        <Grid item key={i}>
                            <Grid item>
                            <StyledBadge 
                                onClick={e => handleRemoveApprover(e, approver.user._id)}
                                color="secondary" 
                                badgeContent={<FontAwesomeIcon icon={faMinusCircle} />}>
                            { (approver.user.photoURL !== "" && approver.user.photoURL !== undefined && approver.user.photoURL !== null ) ?
                                (<Avatar src={approver.user.photoURL} className={classes.userAvFlow} />) :
                                (<Avatar className={classes.initialsAv}>{approver.user.firstName.charAt(0) + approver.user.lastName.charAt(0)}</Avatar>)
                            }
                            </StyledBadge>
                            </Grid>
                        </Grid>
                        
                    )) }
                    {props.users.filter((u) => u.active && u._id !== props.user._id).length > 0 && approvers.length === 0 ? 
                    (
                    <Grid item>
                        <IconButton onClick={e => handleApproverOpen(e, 0)}>
                            <FontAwesomeIcon icon={faPlusCircle} className={classes.plusAvLarge} />
                        </IconButton>
                    </Grid>
                    ): ''}

                    </Grid>
                </Grid>
                <Grid item>
                    <FontAwesomeIcon icon={faCaretRight} className={classes.arrowIcon} />
                </Grid>
                <Grid item>
                    <Avatar className={classes.approvedAv}>
                    <FontAwesomeIcon icon={faCheck} />
                    </Avatar>
                </Grid>
            </Grid>
            :
            <Grid container direction="column" justify="flex-start" alignItems="stretch">

                <Grid item>
                    <Box mb={2}>
                        <Typography variant="body2" color="textSecondary" align="center">
                            Click on the reviewer for more detail
                        </Typography>
                    </Box>
                </Grid>
                <List>
                {props.ticketsFromAgrPage
                .sort((a,b) => (a.lastUpdateDate < b.lastUpdateDate) ? 1 : ((b.lastUpdateDate < a.lastUpdateDate) ? -1 : 0))
                .map((t) => (

                    <ListItem key={t._id} button onClick={e => handleTicketDetailOpen(t._id)}
                    >
                        <ListItemAvatar>
                            {t.ticketStatus === 'Cancelled' ?
                            <Avatar className={classes.approvedAvGreyList}><FontAwesomeIcon icon={faSlash} /></Avatar>
                            :
                            <RenderAvatar uid={t.assignee} type="list" />
                            }
                        </ListItemAvatar>
                        <ListItemText 
                            primary={
                                props.users.filter((u) => u._id === t.assignee)[0] !== undefined ?
                                props.users.filter((u) => u._id === t.assignee)[0].displayName 
                                : ''}
                            secondary={
                                <>
                                {((t.ticketStatus === 'Assigned' || t.ticketStatus === 'In Progress') && t.assignee === props.user._id) ?
                                    <span className={classes.higlightPrimary}>Pending your action</span> : 
                                    <span>{t.ticketStatus === 'Resolved' ? t.resolveReason : t.ticketStatus} - </span>}
                                Last update: {getTimeIndicationForDate(t.lastUpdateDate)}
                                </>
                            }
                        />
                    </ListItem>
                ))}
                </List>

            </Grid> 
            }

            </Box>

        </DialogContent>

        <DialogActions className={classes.mlittle}>
            {approverOpen ?
            <Button onClick={e => setApproverOpen(false)} variant="text" color="primary" className={classes.cancelButton}>
                <FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Back
            </Button>
            :
            <Button onClick={closeDialog} variant="text" color="primary" className={classes.cancelButton}>
                Close
            </Button>
            }
            {!approverOpen /*&& approvers.length > 0*/ ?
            <Button disableElevation color="primary" variant="text" onClick={e => handleApproverOpen()}    
                disabled={props.users.filter((u) => u.active && u._id !== props.user._id).length === 0} >Add Review&nbsp;&nbsp;<FontAwesomeIcon icon={faPlusCircle}/></Button>
            :''}
            {!approverOpen && approvers.length > 0 ?
            <Button disableElevation color="primary" variant="contained" onClick={handleSubmitReviewers}    
                disabled={approvers.length < 1} >Send for review&nbsp;&nbsp;<FontAwesomeIcon icon={faPaperPlane} /></Button>
            :''}
        </DialogActions>
    </>
    )
    :
    <Box mt={10} mb={10} align="center">
    <CircularProgress size={24} />
    </Box>
    }
    </Dialog>

  )
}

export default DialogReview