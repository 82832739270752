import React from 'react';
import { Box, Button, IconButton, InputBase, List, ListItem, ListItemText, ListItemSecondaryAction, 
         Tooltip, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import pdf from '../assets/img/pdf.png';

const AgreementExhList = (props) => {

    const useStyles = makeStyles(theme => ({
        agreementComponents: {
            [theme.breakpoints.down('xs')]: { width: '330px', padding: '0px 0px 0px 0px' },
            [theme.breakpoints.only('sm')]: { width: '460px', padding: '0px 25px 0px 25px' },
            [theme.breakpoints.only('md')]: { width: '580px', padding: '0px 25px 0px 25px' },
            [theme.breakpoints.up('lg')]: { width: '700px', padding: '0px 30px 0px 30px' },
        },
        smallerAgComponents: {
          [theme.breakpoints.down('xs')]: { width: '280px', padding: '0px 0px 0px 0px' },
          [theme.breakpoints.only('sm')]: { width: '400px', padding: '0px 25px 0px 25px' },
          [theme.breakpoints.only('md')]: { width: '500px', padding: '0px 25px 0px 25px' },
          [theme.breakpoints.up('lg')]: { width: '600px', padding: '0px 30px 0px 30px' },
        },
        exhList: {
            borderLeft: '3px solid ' + theme.palette.grey[500],
        },
        exhButton: {
            fontWeight: '700',
            borderRadius: '0px',
            fontSize: '12px',
            width: '80px',
            color: theme.palette.secondary.main,
            '&:hover': {
              backgroundColor: 'transparent',
              color: theme.palette.secondary.main,
              textDecoration: 'underline',
            }
        },
        editIcon: {
          fontSize: '12px',
          padding: '4px 4px 4px 4px',
        },
        agrTitle: {
          fontWeight: '700',
          [theme.breakpoints.only('xs')]: { width: '284px', fontSize: '20px' },
          [theme.breakpoints.only('sm')]: { width: '430px', fontSize: '28px' },
          [theme.breakpoints.only('md')]: { width: '560px', fontSize: '34px' },
          [theme.breakpoints.up('lg')]: { width: '670px', fontSize: '36px'  },
          paddingBottom: '5px',
        },
        iconBCancel: {
          color: theme.palette.error.main,
          padding: '6px 10px 6px 10px'
        },
        iconBAccept: {
          color: theme.palette.success.main,
          padding: '6px 6px 6px 6px'
        },
        pdfIcon: {
          marginLeft: '10px',
          height: '13px',
        }
    }));
    const classes = useStyles();

    const history = useHistory()

    const [exhibitTitleEditing, setExhibitTitleEditing] = React.useState(false);
    const [newExhibitTitle, setNewExhibitTitle] = React.useState('')

    const handleAgrTitleUpdate = () => {
      if(newExhibitTitle !== props.agr.agrFullName && newExhibitTitle.length > 2) {
        let matches = newExhibitTitle.match(/\b(\w)/g);
        let sn = matches.join('');
        props.updateAgrNames(props.agr._id, newExhibitTitle, sn, props.user._id)
        if(!props.templating) { props.handleActivityLog('agr', 'agrNewName', '', '', props.user.displayName, '', { old: props.agr.agrFullName, new: newExhibitTitle }) } // logLevel, logType, singleClauseID, ticketID, keyVal1, keyVal2
        history.push('/core/' + props.module + "/agreement/" + props.agr.relID + "/" + props.agr._id);
      } else if (newExhibitTitle === props.agr.agrFullName || newExhibitTitle === '') {
        setExhibitTitleEditing(false);
      }
    }

    return (
    <Box className={props.variant === 'agrScreen' ? classes.agreementComponents : classes.smallerAgComponents} mb={3} mt={3}>
        {exhibitTitleEditing ?

        <Box mt={3} mb={2}>
          
          <InputBase 
            multiline
            defaultValue={props.agr.agrFullName}
            className={classes.agrTitle}
            inputProps={{style: { textAlign: 'center' }}}
            onBlur={e => setNewExhibitTitle(e.target.value)}
          />
          <Box mt={1} align="center">
            <IconButton onClick={e => setExhibitTitleEditing(false)}>
              <FontAwesomeIcon icon={faTimes} className={classes.iconBCancel} />
            </IconButton>
            <IconButton onClick={handleAgrTitleUpdate}>
              <FontAwesomeIcon icon={faCheck} className={classes.iconBAccept} />
            </IconButton>
          </Box>
        </Box>
        
        :
        <List dense>
          {props.agrs            
          .map((s,i) => (
            <ListItem button key={s._id} selected={props.agr._id === s._id} 
            onClick={e => props.click(s._id, null)}
            className={classes.exhList}>

              <ListItemText primary={s.parentAID === '' ? 
                  "Main Body" : 
                  <>Exhibit {String.fromCharCode(96 + i).toUpperCase() + ": " + s.agrFullName}{s.sourceFileID === 'attachment' ? <img src={pdf} alt={"PDF"} className={classes.pdfIcon} />:''}</>}  />

              {props.variant === 'agrScreen' && ['Negotiation'].includes(props.agr.agrStatus) && !props.edited &&
               props.avsPenTurnDetail.length > 0 && props.avsPenTurnDetail.filter((aptd) => aptd.openOnPT !== null).length > 0 &&
               props.avsPenTurnDetail.filter((aptd) => aptd.aid === s._id)[0] !== undefined ?
              <ListItemSecondaryAction>
                {props.toggleFilter === 'clause' ?
                <Button onClick={e => props.click(s._id, 'clause')} className={classes.exhButton}>
                {props.avsPenTurnDetail.filter((aptd) => aptd.aid === s._id)[0].openOnPT} open</Button>
                :'' }
                {props.toggleFilter === 'changes' ?
                <Button onClick={e => props.click(s._id, 'changes')} className={classes.exhButton}>
                {props.avsPenTurnDetail.filter((aptd) => aptd.aid === s._id)[0].changesOnPT} change{
                  props.avsPenTurnDetail.filter((aptd) => aptd.aid === s._id)[0].changesOnPT !== 1 ? "s" : ""}</Button>
                :''}
              </ListItemSecondaryAction>
              :
              ['Draft','Review','Negotiation'].includes(props.parentAgrStatus) &&
              props.variant === 'agrScreen' && s.parentAID !== '' && props.agr._id === s._id && props.user.role !== 'Counterparty' ?
              <ListItemSecondaryAction>
                <Tooltip title="Edit the exhibit title">
                <IconButton size="small" onClick={e => setExhibitTitleEditing(true)}>
                <FontAwesomeIcon icon={faPen} className={classes.editIcon} />
                </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
              :
              ''}
            </ListItem>
          ))}
        </List>
        }
    </Box> 
    );
}

export default AgreementExhList