import React from 'react';
import PropTypes from 'prop-types'; // https://reactjs.org/docs/typechecking-with-proptypes.html
import { Card, CardContent, IconButton, Typography, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen } from "@fortawesome/free-solid-svg-icons";  

const dayjs = require('dayjs')

const CardComment = (props) => {

  const useStyles = makeStyles(theme => ({
    cardPrimary: {
        borderRadius: '0px',
        [theme.breakpoints.only('xs')]: { width: '200px' },
        [theme.breakpoints.up('sm')]: { width: '300px' },
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
        border: '1px solid' + theme.palette.grey[400],
        borderLeft: '4px solid ' + theme.palette.primary.main,
        marginBottom: '10px',
    },
    cardSecondary: {
        borderRadius: '0px',
        [theme.breakpoints.only('xs')]: { width: '200px' },
        [theme.breakpoints.up('sm')]: { width: '300px' },
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        border: '1px solid' + theme.palette.grey[400],
        borderRight: '4px solid ' + theme.palette.secondary.main,
        marginBottom: '10px',
    },
    cardHisPrimary: {
        borderRadius: '0px',
        [theme.breakpoints.only('xs')]: { width: '200px' },
        [theme.breakpoints.up('sm')]: { width: '300px' },
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
        border: '1px solid' + theme.palette.grey[400],
        borderLeft: '4px solid ' + theme.palette.primary.main,
    },
    cardHisSecondary: {
        borderRadius: '0px',
        [theme.breakpoints.only('xs')]: { width: '200px' },
        [theme.breakpoints.up('sm')]: { width: '300px' },
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
        border: '1px solid' + theme.palette.grey[400],
        borderLeft: '4px solid ' + theme.palette.secondary.main,
    },
    cardContent: {
        borderRadius: '0px',
        textAlign: 'left',
        [theme.breakpoints.only('xs')]: { 
            "&:first-child": { paddingTop: '8px', },
            "&:last-child": { paddingBottom: '8px', },
        },
        [theme.breakpoints.up('sm')]: { 
            "&:first-child": { paddingTop: '15px', },
            "&:last-child": { paddingBottom: '15px', },
        },
    },
    commentText: {
        [theme.breakpoints.only('xs')]: { fontSize: '12px', },
        [theme.breakpoints.up('sm')]: { fontSize: '14px', },
        color: theme.palette.grey[900],
    },
    author: {
        [theme.breakpoints.only('xs')]: { fontSize: '12px' },
        fontSize: '13px',
        fontWeight: '700',
        paddingBottom: '0px',
        display: 'block',
    },
    date: {
        [theme.breakpoints.only('xs')]: { fontSize: '10px' },
        display: 'block', 
        fontSize: '11px',
        paddingBottom: '10px'
    },
    datedel: {
        [theme.breakpoints.only('xs')]: { fontSize: '10px' },
        display: 'block', 
        fontSize: '11px',
    },
    miniIconPrimary: {
        color: theme.palette.primary.main,
        fontSize: '11px',
        padding: '4px',
    },
    miniIconSecondary: {
        color: theme.palette.secondary.main,
        fontSize: '11px',
        padding: '4px',
    },
    miniIconB: {
        marginLeft: '10px',
    },
    deleted: {
        color: theme.palette.grey[600],
        fontWeight: '700',
        marginLeft: '10px',
    }
  }));
  const classes = useStyles();

    return (
        <Card variant="outlined" 
            style={props.updating ? {backgroundColor: '#f3f3f3'} : {}}
            className={
                props.secondary && props.historyPage ? classes.cardHisSecondary :
                !props.secondary && props.historyPage ? classes.cardHisPrimary :
                props.secondary ? classes.cardSecondary : classes.cardPrimary}>
            <CardContent className={classes.cardContent}>
                {props.comment.comment !== '___DELETED___' ? 
                <Typography variant="subtitle2" className={classes.author} gutterBottom>
                    {props.comment.creatorDisplayName}
                    {props.editOptions && (props.updating === undefined || props.updating === null || !props.updating) && props.comment.comment !== '___DELETED___' ? 
                    <>
                    <IconButton size="small" className={classes.miniIconB} onClick={e => props.handleCommentAction('edit', props.comment._id)}>
                        <FontAwesomeIcon icon={faPen} className={props.secondary ? classes.miniIconSecondary : classes.miniIconPrimary} />
                    </IconButton>
                    <IconButton size="small" onClick={e => props.handleCommentAction('delete', props.comment._id)}>
                        <FontAwesomeIcon icon={faTrash} className={props.secondary ? classes.miniIconSecondary : classes.miniIconPrimary} />
                    </IconButton>
                    </>
                    : '' }
                </Typography>
                :''}
                {!props.historyPage ?
                <Typography variant="body2" color="textSecondary" className={props.comment.comment === '___DELETED___' ? classes.datedel : classes.date}>
                    {dayjs(props.comment.creationDate).format('MMM D, YYYY h:mm A')}&nbsp;
                    {props.comment.comment === '___DELETED___' ? <span className={classes.deleted}>deleted</span> :
                    props.comment.lastUpdateDate !== undefined && props.comment.lastUpdateDate !== null ? (" - edited " + dayjs(props.comment.lastUpdateDate).format('MMM D, YYYY h:mm A')) : ""}
                </Typography>
                : '' }
                {props.comment.comment !== '___DELETED___' ? 
                <Typography variant="body2" className={classes.commentText}>
                {props.comment.comment.split('\n').map((cpar,i) => ( <React.Fragment key={props.comment._id + "_" + i}>{cpar}<br/></React.Fragment> ))}
                </Typography>
                :''}
            </CardContent>
        </Card>
    );
}

CardComment.propTypes = {
  comment: PropTypes.object.isRequired,
  secondary: PropTypes.bool,
};

export default CardComment