import React, { useEffect } from 'react';
import TimeMe from "timeme.js";
import { useHistory } from 'react-router-dom';
import { useBeforeunload } from 'react-beforeunload';

import { Box, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

const TimeTrack = (props) => {

    const history = useHistory()

    useEffect(() => {

        const pushTime = props.pushTime

        if(props.aid !== undefined && props.aid !== null && TimeMe.getTimeOnPageInSeconds(props.aid) === undefined) { // initialize time-tracking
            TimeMe.initialize({ idleTimeoutInSeconds: 30 }); // seconds
            TimeMe.stopTimer();
            TimeMe.resetAllRecordedPageTimes();
            TimeMe.setCurrentPageName(props.aid);
            TimeMe.startTimer();
            if(!props.uemail.endsWith('support@getcanveo.com')){
                pushTime(props.orgID, props.uid, props.rid, props.aid, 0.0001, props.uname, props.module, props.cpmodule) // Push a milisecond to ensure "hasViewed" gets activated
            }
        }
        return () => { // You're leaving the page - push time
            if(!history.location.pathname.startsWith(('/core/' + props.module + '/agreement/' + props.rid + "/" + props.aid)) &&
            !props.uemail.endsWith('support@getcanveo.com')) {
                pushTime(props.orgID, props.uid, props.rid, props.aid, TimeMe.getTimeOnPageInSeconds(props.aid), props.uname, props.module, props.cpmodule)
                TimeMe.stopTimer();
                TimeMe.resetAllRecordedPageTimes();
            }
        }
    }, [props.aid, props.uemail, props.uid, props.rid, props.orgID, props.pushTime, props.cpmodule, props.module, props.uname, history])

    useBeforeunload((event) => {
        if(!props.uemail.endsWith('support@getcanveo.com') && !isNaN(TimeMe.getTimeOnPageInSeconds(props.aid))) {
            props.pushTime(props.orgID, props.uid, props.rid, props.aid, TimeMe.getTimeOnPageInSeconds(props.aid), props.uname, props.module, props.cpmodule)
        }
    });
    
    return (
        <Tooltip title="Time spent on agreement" placement="right">
        <Box mt={1} mb={1} align="center">
            <FontAwesomeIcon icon={faClock} style={{marginRight: '8px'}} />
            <b>
            {Math.round(((!isNaN(props.timeBase) ? props.timeBase : 0) + (!isNaN(TimeMe.getTimeOnPageInSeconds(props.aid)) ? TimeMe.getTimeOnPageInSeconds(props.aid) : 0)) / 60) <= 60 ?

             (Math.round(((!isNaN(props.timeBase) ? props.timeBase : 0) + (!isNaN(TimeMe.getTimeOnPageInSeconds(props.aid)) ? TimeMe.getTimeOnPageInSeconds(props.aid) : 0)) / 60) +
             " min" + (Math.round(((!isNaN(props.timeBase) ? props.timeBase : 0) + (!isNaN(TimeMe.getTimeOnPageInSeconds(props.aid)) ? TimeMe.getTimeOnPageInSeconds(props.aid) : 0)) / 60) === 1 ? "" : "s")) :
             
             (Math.floor(((!isNaN(props.timeBase) ? props.timeBase : 0) + (!isNaN(TimeMe.getTimeOnPageInSeconds(props.aid)) ? TimeMe.getTimeOnPageInSeconds(props.aid) : 0)) / 3600) + 
             "h" + ((Math.round(((!isNaN(props.timeBase) ? props.timeBase : 0) + (!isNaN(TimeMe.getTimeOnPageInSeconds(props.aid)) ? TimeMe.getTimeOnPageInSeconds(props.aid) : 0)) / 60) - 
                    (Math.floor(((!isNaN(props.timeBase) ? props.timeBase : 0) + (!isNaN(TimeMe.getTimeOnPageInSeconds(props.aid)) ? TimeMe.getTimeOnPageInSeconds(props.aid) : 0)) / 3600) * 60)) + "m"))}
            </b>
        </Box>
        </Tooltip>
    );
}
export default TimeTrack

