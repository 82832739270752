import { FETCH_USERORG_PENDING, FETCH_USERORG_SUCCESS, FETCH_USERORG_ERROR,
    FETCH_ORGPROFILE_PENDING, FETCH_ORGPROFILE_ERROR, FETCH_ORGPROFILE_SUCCESS,
    FETCH_ORGLABELS_PENDING, FETCH_ORGLABELS_ERROR, FETCH_ORGLABELS_SUCCESS, FETCH_ORGLABELS_DONE, ADD_ORGLABEL, UPDATE_ORGLABEL, REMOVE_ORGLABEL,
    FETCH_ORGSMARTFIELDS_PENDING, FETCH_ORGSMARTFIELDS_SUCCESS, FETCH_ORGSMARTFIELDS_DONE, FETCH_ORGSMARTFIELDS_ERROR, ADD_ORGSMARTFIELD, UPDATE_ORGSMARTFIELD, REMOVE_ORGSMARTFIELD,
    FETCH_NETWORK_PENDING, FETCH_NETWORK_SUCCESS, FETCH_NETWORK_ERROR } from '../ActionTypes';

// ORG
export const Org = (state  = { 
    pending: false,
    error: null,
    org: {}
}, action) => {
switch(action.type) {
        case FETCH_USERORG_PENDING: 
            return {
                ...state,
                pending: true,
            }
        case FETCH_USERORG_SUCCESS:
            return {
                ...state,
                pending: false,
                org: action.payload
            }
        case FETCH_USERORG_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }

        default: 
            return state;
    }
};

// ORG PROFILE
export const OrgProfile = (state  = { 
    pending: false,
    error: null,
    orgProfile: {}
}, action) => {
switch(action.type) {
        case FETCH_ORGPROFILE_PENDING: 
            return {
                ...state,
                pending: true,
            }
        case FETCH_ORGPROFILE_SUCCESS:
            return {
                ...state,
                pending: false,
                orgProfile: action.payload
            }
        case FETCH_ORGPROFILE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }

        default: 
            return state;
    }
};

// ORG LABEL
export const OrgLabel = (state  = { 
    pending: false,
    error: null,
    orgLabel: []
}, action) => {
switch(action.type) {
        case FETCH_ORGLABELS_PENDING: 
            return {
                ...state,
                pending: true,
            }
        case FETCH_ORGLABELS_SUCCESS:
            return {
                ...state,
                pending: false,
                orgLabel: action.payload
            }
        case FETCH_ORGLABELS_DONE: 
            return {
                ...state,
                pending: false
            }            
        case FETCH_ORGLABELS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }
        case ADD_ORGLABEL:
            var ol = action.payload;
            return { 
                ...state, 
                orgLabel: state.orgLabel.concat(ol)};
        case UPDATE_ORGLABEL:
            // Find the index of the orgLabel to replace with the updated one
            var newol = action.payload
            var newOLs = state.orgLabel
            var index = null;
            for (var i=0; i<state.orgLabel.length; i++) {
                if ( state.orgLabel[i]._id === newol._id ) {
                    index = i;
                    break;
                }
            }
            newOLs[index] = newol
            return { 
                ...state, 
                orgLabel: newOLs};
        case REMOVE_ORGLABEL:
            var olid = action.payload;
            return { 
                ...state, 
                orgLabel: state.orgLabel.filter(({ _id }) => _id !== olid)};
        default: 
            return state;
    }
};

// ORG SMARTFIELDS
export const OrgSmartField = (state  = { 
    pending: false,
    error: null,
    orgSmartField: []
}, action) => {
switch(action.type) {
        case FETCH_ORGSMARTFIELDS_PENDING: 
            return {
                ...state,
                pending: true,
            }
        case FETCH_ORGSMARTFIELDS_SUCCESS:
            return {
                ...state,
                pending: false,
                orgSmartField: action.payload
            }
        case FETCH_ORGSMARTFIELDS_DONE: 
            return {
                ...state,
                pending: false
            }            
        case FETCH_ORGSMARTFIELDS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }
        case ADD_ORGSMARTFIELD:
            var osf = action.payload;
            return { 
                ...state, 
                orgSmartField: state.orgSmartField.concat(osf)};
        case UPDATE_ORGSMARTFIELD:
            // Find the index of the orgSmartField to replace with the updated one
            var newosf = action.payload
            var newOSFs = state.orgSmartField
            var index = null;
            for (var i=0; i<state.orgSmartField.length; i++) {
                if ( state.orgSmartField[i]._id === newosf._id ) {
                    index = i;
                    break;
                }
            }
            newOSFs[index] = newosf
            return { 
                ...state, 
                orgSmartField: newOSFs};
        case REMOVE_ORGSMARTFIELD:
            var osfid = action.payload;
            return { 
                ...state, 
                orgSmartField: state.orgSmartField.filter(({ _id }) => _id !== osfid)};
        default: 
            return state;
    }
};


// NETWORK
export const Network = (state  = { 
    pending: false,
    error: null,
    network: []
}, action) => {
switch(action.type) {
        case FETCH_NETWORK_PENDING: 
            return {
                ...state,
                pending: true,
            }
        case FETCH_NETWORK_SUCCESS:
            return {
                ...state,
                pending: false,
                network: action.payload
            }
        case FETCH_NETWORK_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }

        default: 
            return state;
    }
};