import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, CircularProgress, FormControl, FormControlLabel, Grid, RadioGroup, 
         Typography, makeStyles, Collapse } from '@material-ui/core';
import { Footer, Header, ErrorMsg } from '../components';
import { ContactUsForm } from '../components/forms'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadset, faQuestion, faThumbsUp, faUserTie } from "@fortawesome/free-solid-svg-icons";

const axios = require('axios');

function Contact(props) {
  
  const useStyles = makeStyles(theme => ({
    outerFlexGrid: {
      minHeight: '82vh',
      paddingTop: '100px',
      paddingBottom: '70px'
    },
    bold: {
      fontWeight: '700',
    },
    iconResize: {
      color: theme.palette.grey[700],
      [theme.breakpoints.only('xs')]: { fontSize: '30px', },
      [theme.breakpoints.up('sm')]: { fontSize: '50px', },
      marginBottom: '18px',
    },
    iconResizeActive: {
      color: theme.palette.primary.main,
      [theme.breakpoints.only('xs')]: { fontSize: '30px', },
      [theme.breakpoints.up('sm')]: { fontSize: '50px', },
      marginBottom: '18px',
    },
    textResizeActive: {
      [theme.breakpoints.only('xs')]: { fontSize: '11px', },
      [theme.breakpoints.up('sm')]: { fontSize: '15px', },
      fontWeight: '700',
      color: theme.palette.primary.main,
    },
    textResize: {
      [theme.breakpoints.only('xs')]: { fontSize: '11px', },
      [theme.breakpoints.up('sm')]: { fontSize: '15px', },
      fontWeight: '700',
      color: theme.palette.grey[700],
    },
    primaryIcon: {
      color: theme.palette.primary.main,
      marginTop: '60px',
    },
    fcl: {
      [theme.breakpoints.only('xs')]: { width: '92px', marginLeft: '10px', marginRight: '10px' },
      [theme.breakpoints.up('sm')]: { width: '150px' },
    },
    secondary: {
      color: theme.palette.secondary.main,
    },
    pushDown: {
      marginTop: '100px',
      marginBottom: '100px',
    },
    noPush: {
      marginTop: '0px',
      marginBottom: '0px',
      transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.easeIn,
        duration: '0.2s'
      }),
    }
  }));
  const classes = useStyles();

  const history = useHistory();

  useEffect(() => {

    let action = props.action;
    let id = props.id;

    if(!props.loading && action === 'verify' && id !== undefined && id !== null && id.length > 10 && id.length < 30) {
      axios.get('/mail/mailinglistverify/' + id)
    }
    
  }, [props.action, props.id, props.loading]);

  const [reason, setReason] = React.useState(props.action === 'support' ? 'support' : props.action === 'demo' ? 'demo' : ['inquiry','upgrade'].includes(props.action) ? 'inquiry' : '')
  const [formData, setFormData] = React.useState({ 
    firstName: props.user !== undefined && props.user.firstName !== undefined ? props.user.firstName : '', 
    lastName: props.user !== undefined && props.user.lastName !== undefined ? props.user.lastName : '', 
    email: props.user !== undefined && props.user.email !== undefined ? props.user.email : '', 
    company: props.org !== undefined && props.org.shortName !== undefined ? props.org.shortName : '', 
    message: props.action === 'upgrade' ? 'Hello, I would like to upgrade our account. Thank you.' : '' // to Enterprise
  })
  const [submitting, setSubmitting] = React.useState(false);  
  const [formError, setFormError] = React.useState('');

  const handleReason = (e, newReason) => {
    setReason(newReason)
  }

  const callbackContactForm = (child) => {
    setSubmitting(true)
    setFormError('');

    let creationDate = new Date().toISOString();
    if(child.firstName !== '' && child.lastName !== '' && child.email !== '' && child.message !== '' && reason !== '' && reason !== null){
      axios.post('/mail/contactus', {
        firstName: child.firstName,
        lastName: child.lastName,
        email: child.email,
        reason: reason,
        company: child.company,
        dateTime: creationDate,
        message: child.message
      })
      .then(function(resmail) {
        axios.get('/mail/contactsender/' + child.email + '/' + child.firstName)
        .then(function(res2mail) {
          // send to success
          history.push('/contact/done');
        })
        .catch(function(err) {
          setSubmitting(false)
          setFormError('There was an error while sending a confirmation email');
        })        
      })
      .catch(function(err) {
        setSubmitting(false)
        setFormError('There was an error while submitting the message, please try again');
      })

    } else {
      setFormData({
        firstName: child.firstName,
        lastName: child.lastName,
        email: child.email,
        company: child.company,
        message: child.message        
      })
      setFormError("Please select a reason and complete all required fields")
      setSubmitting(false);
    }
  }

  return(
    <div>

      <Header 
        module="open"
        openOnly={true}
        user={props.user}
        org={props.org}
        modules={
          props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
            ['sales','procurement','partnerships','people','corporate'] : 
          props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
            props.org.modules : [] }
        crumbs={[]}
        notifications={props.notifications}
        markNotificationAsRead={props.markNotificationAsRead}
        markAllNotificationsAsRead={props.markAllNotificationsAsRead}
      />

      { props.action === 'verify' ? 
      (
      <Grid container direction="column" justify="center" alignItems="center" className={classes.outerFlexGrid}>

      <Grid item>
        <FontAwesomeIcon icon={faThumbsUp} size="6x" className={classes.primaryIcon} />
      </Grid>
      <Grid item>
        <Box mt={5} >
          <Typography variant="h4" align="center" gutterBottom className={classes.bold}>
            Thank you.
          </Typography>              
        </Box>
        <Box mt={3}>
          <Typography variant="body1" align="center" className={classes.mbAndLight}>
            Thanks for verifying your email address.<br/>
            You will be receiving valuable content and updates soon.
          </Typography>
        </Box>
        <Box mt={4} mb={2} align="center">
          <a href="https://getcanveo.com/" className="primlink">Go to our homepage</a>
        </Box>
      </Grid>
      </Grid>
      )
      :
      props.action === 'done' ?
      (
        <Grid container direction="column" justify="center" alignItems="center" className={classes.outerFlexGrid}>

          <Grid item>
            <FontAwesomeIcon icon={faThumbsUp} size="6x" className={classes.primaryIcon} />
          </Grid>
          <Grid item>
            <Box mt={5} >
              <Typography variant="h4" align="center" gutterBottom className={classes.bold}>
                Thanks for reaching out
              </Typography>              
            </Box>
            <Box mt={3}>
              <Typography variant="body1" align="center" className={classes.mbAndLight}>
                We'll be in touch with you soon.
              </Typography>
            </Box>
            <Box mt={4} mb={2} align="center">
              <a href="/" className="primlink">Go back</a>
            </Box>
          </Grid>
        </Grid>
      )
      : 
      (

      <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>

        <Grid container direction="column" justify="flex-start" alignItems="center">

          <Grid item xs={9} className={props.action !== 'cp' || ['demo', 'inquiry', 'support'].includes(reason) ? classes.noPush : classes.pushDown}>
            <Box mt={2} mb={3}>
              <Typography variant="h3" align="center" gutterBottom className={classes.bold}>
                {submitting ? "Submitting..." :
                props.action === 'cp' ? 
                <Box>Thanks for using Canveo<span className={classes.secondary}>.</span></Box> :
                "How can we help?"}
              </Typography>
              {formError !== '' ?
              <ErrorMsg msg={formError} />
              : ''}
            </Box>
          </Grid>

        </Grid>

        {submitting ? 
        
        (
          <Grid container justify="center" alignItems="center">
            <Box mt={10} mb={10} align="center">
              <CircularProgress size="80px" />
            </Box>
          </Grid>
        )
        :
        (
        <React.Fragment>
        <Grid container justify="center" alignItems="center">

          <Box mt={3} mb={6}>
            <FormControl component="fieldset">
              <RadioGroup row value={reason}>
                  <FormControlLabel onClick={e => handleReason(e, 'demo')} className={classes.fcl}
                  value="demo" control={<FontAwesomeIcon icon={faUserTie} className={reason === 'demo' ? classes.iconResizeActive : classes.iconResize} />} 
                  label={<Typography className={reason === 'demo' ? classes.textResizeActive : classes.textResize}>Request Demo</Typography>}
                  labelPlacement="bottom" />
                  <FormControlLabel onClick={e => handleReason(e, 'inquiry')} className={classes.fcl}
                  value="inquiry" control={<FontAwesomeIcon icon={faQuestion} className={reason === 'inquiry' ? classes.iconResizeActive : classes.iconResize} />} 
                  label={<Typography className={reason === 'inquiry' ? classes.textResizeActive : classes.textResize}>General Inquiry</Typography>}
                  labelPlacement="bottom" />
                  <FormControlLabel onClick={e => handleReason(e, 'support')} className={classes.fcl}
                  value="support" control={<FontAwesomeIcon icon={faHeadset} className={reason === 'support' ? classes.iconResizeActive : classes.iconResize} />} 
                  label={<Typography className={reason === 'support' ? classes.textResizeActive : classes.textResize}>Customer Support</Typography>}
                  labelPlacement="bottom" />
              </RadioGroup>
            </FormControl>
          </Box>

        </Grid>

        <Collapse in={props.action !== 'cp' || ['demo', 'inquiry', 'support'].includes(reason)}>
        <ContactUsForm 
                    parentCallback={callbackContactForm}
                    initialValues={{
                      firstName: formData.firstName, 
                      lastName: formData.lastName, 
                      email: formData.email,
                      company: formData.company,
                      message: formData.message}} />   
        </Collapse>
        </React.Fragment>

        )}

      </Grid>
      
      )}
      

    <Footer />
                      
    </div>
    );

}
export default Contact