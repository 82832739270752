import { stripPlain } from '.'

function convertPlainToBlocks(plain) {
  
  let jsonString = '{ "blocks": [';
  let plainPars = []

  if(typeof plain === "string"){
    plainPars = plain.split(/\r?\n/) // split into array based on newLine
  } else { // is already an Array
    plainPars = plain;
  }

  plainPars.forEach((p) => { // eliminating any tabs (\t), carriage return (\r) ; escape double quote (\\\")

    let blockType = p.startsWith('<title>') && p.endsWith('</title>') ? '"clauseTitle"' : 
                    p.startsWith('<secTitle>') && p.endsWith('</secTitle>') ? '"secTitle"' : 
                    p.startsWith('<ssecTitle>') && p.endsWith('</ssecTitle>') ? '"ssecTitle"' : 
                    '"clausePar"'

    jsonString = jsonString + `
    { 
      "text": "` + stripPlain(p).replace(/\t/g,'').replace(/\r/g,'').replace(/"/g,'\\"') + `", 
      "type": ` + blockType + `
    },`

  })
  jsonString = jsonString.substring(0, jsonString.length - 1); // remove the last comma
  jsonString = jsonString + `], 
    "entityMap": 
    {
      "proposedAdd": 
      {
        "type": "PROPOSEDADD",
        "mutability": "MUTABLE"
      },
      "rejectPAdd": 
      {
        "type": "REJECTPADD",
        "mutability": "MUTABLE"
      },
      "proposedDel": 
      {
        "type": "PROPOSEDDEL",
        "mutability": "MUTABLE"
      },
      "rejectPDel": 
      {
        "type": "REJECTPDEL",
        "mutability": "MUTABLE"
      },
      "proposedAddSecondary": 
      {
        "type": "PROPOSEDADDSECONDARY",
        "mutability": "MUTABLE"
      },
      "rejectPAddSec": 
      {
        "type": "REJECTPADDSEC",
        "mutability": "MUTABLE"
      },
      "proposedDelSecondary": 
      {
        "type": "PROPOSEDDELSECONDARY",
        "mutability": "MUTABLE"
      },
      "rejectPDelSec": 
      {
        "type": "REJECTPDELSEC",
        "mutability": "MUTABLE"
      }
    }
  }`

  return JSON.parse(jsonString);
}

export { convertPlainToBlocks }