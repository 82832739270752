import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { ConfigureStore } from './redux/configureStore';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import './index.css';
import theme from "./theme/theme";
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import Main from './Main';

const store = ConfigureStore();
const stripePromise = loadStripe(
  (window.location.href.indexOf("getcanveo.com") > -1 && window.location.href.indexOf("test.getcanveo.com") === -1) || window.location.href.indexOf('canveo.oa.r.appspot.com') > -1 ?
  'pk_live_51I8n0zGTo1OR8YkMJlBCNtDwBMuB0OhMgf7t76AFbZFFSuXHsFD5JvTU0WOrLGR65HsfyZWT287b58Lm2Il24RPc00PLHRJtSI' :
  'pk_test_51I8n0zGTo1OR8YkMr4eJoYICC0WuOCuXztbAApNpkCJtm4ZysugZVVuZq0oc58WPkCwDTFbio2p50BSrFQHnd7oi00i2fdoyJL');

ReactDOM.render(
  <Provider store={store}>  
    <MuiThemeProvider theme={theme}>
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </Elements>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
//serviceWorker.register();