function getActorsForModule(mod, single, entityType) {

    let s = 
        mod === 'sales' && single ? 'Customer' :
        mod === 'sales' ? 'Customers' :
        mod === 'procurement' && single ? 'Supplier' :
        mod === 'procurement' ? 'Suppliers' :
        mod === 'partnerships' && single ? 'Partner' :
        mod === 'partnerships' ? 'Partners' :
        mod === 'people' && single ? 'Employee' :
        mod === 'people' ? 'Employees' :
        mod === 'corporate' && entityType ? 'Corpcp' : 
        mod === 'corporate' ? 'Corporate' :
        mod === 'privacy' ? 'Privacy' :
        'Counterparties';

    return s;
}

export { getActorsForModule }