import { faBell, faUserCheck, faFileAlt, faComment, faBan, faThumbsUp, faThumbsDown, 
    faTimes, faHourglassStart,faHourglassEnd, faHandshake, faSignature, faFileContract, 
    faQuestion, faEllipsisH, faProjectDiagram, faSearch, faCheckSquare, faEye, faAt } from "@fortawesome/free-solid-svg-icons";

function getAppropriateIcon(type, desc) {

  let icon = null

  if(type === 'notification'){ 

    icon = (['Approval Request'].includes(desc) ? faUserCheck :
            ['Request Approved'].includes(desc) ? faThumbsUp :
            ['Request Rejected'].includes(desc) ? faThumbsDown :
            ['Request Cancelled'].includes(desc) ? faBan :
            ['Request Reopened'].includes(desc) ? faUserCheck :
            ['Request Reply'].includes(desc) ? faComment :
            ['Review Request'].includes(desc) ? faFileAlt :
            ['Review Started'].includes(desc) ? faHourglassStart :
            ['Review Completed'].includes(desc) ? faHourglassEnd :
            ['Review Reply', 'Agreement comment'].includes(desc) ? faComment :
            ['Agreement Request Network'].includes(desc) ? faQuestion :
            ['Agreement Request Non-Network'].includes(desc) ? faQuestion :
            ['CPTY Agreement returned'].includes(desc) ? faFileContract :
            ['CPTY Ready to Sign'].includes(desc) ? faThumbsUp :
            ['Execution flow initiated'].includes(desc) ? faEllipsisH :
            ['Execution flow canceled'].includes(desc) ? faBan :
            ['Signature Request'].includes(desc) ? faSignature :
            ['Agreement Signed'].includes(desc) ? faThumbsUp :
            ['Agreement viewed'].includes(desc) ? faEye :
            ['Welcome new User'].includes(desc) ? faThumbsUp :
            ['AgrRequest Rejected'].includes(desc) ? faTimes :
            ['AgrRequest Accepted'].includes(desc) ? faHandshake :
            ['Comment Mention'].includes(desc) ? faAt :
            faBell)

  } else if (type === 'ticket'){

    icon = (['Approval'].includes(desc) ? faCheckSquare :
            ['AgrReviewInternal'].includes(desc) ? faSearch :
            ['AgrRequest'].includes(desc) ? faProjectDiagram :
            faBell)

  }
  return icon;
}

export { getAppropriateIcon }


