import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DayJsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Button, Grid, InputAdornment, TextField, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faGlobe, faStar } from "@fortawesome/free-solid-svg-icons";

const CertificateForm = (props) => {

  const useStyles = makeStyles(theme => ({
    pb2: {
        paddingBottom: '20px'
    },
    adornIcon: {
        color: theme.palette.primary.main,
        paddingRight: '8px',
    }
  }));
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = React.useState(props.initialValues.sinceDate);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const formik = useFormik({
    initialValues: {
        title: props.initialValues.title,
        description: props.initialValues.description,
        referenceURL: props.initialValues.referenceURL,
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .max(30, 'The title is too long')
        .required('Title is required'),
      description: Yup.string()
        .required('Description is required'),
      referenceURL: Yup.string()
        .url('A valid website is required, include http://'),
    }),
    onSubmit: values => {
      if(selectedDate !== null) {
        values.sinceDate = selectedDate;
        props.parentCallback(values);
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} noValidate>
    
    <Grid container direction="column" justify="center" alignItems="center">

        <Grid item container xs={10} sm={8} md={6} className={classes.pb2}>
            <TextField
            required
            fullWidth
            variant="outlined"
            id="title"
            label="Certificate Title"
            placeholder="Title"
            name="title"
            autoComplete="title"
            {...formik.getFieldProps('title')}
            error={formik.touched.title && formik.errors.title ? true : false}
            helperText={formik.touched.title && formik.errors.title ? formik.errors.title : ''}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <FontAwesomeIcon icon={faStar} className={classes.adornIcon} />
                </InputAdornment>
                ),
            }}  
            />
        </Grid>
        <Grid item container xs={10} sm={8} md={6} className={classes.pb2}>
            <TextField
            required
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            id="description"
            label="Certificate Description"
            placeholder="Certificate Description"
            name="description"
            autoComplete="description"
            {...formik.getFieldProps('description')}
            error={formik.touched.description && formik.errors.description ? true : false}
            helperText={formik.touched.description && formik.errors.description ? formik.errors.description : ''}
            />
        </Grid>
        <Grid item container xs={10} sm={8} md={6} className={classes.pb2}>
            <TextField
            required
            fullWidth
            variant="outlined"
            id="referenceURL"
            label="Reference Link"
            placeholder="https://..."
            name="referenceURL"
            autoComplete="referenceURL"
            {...formik.getFieldProps('referenceURL')}
            error={formik.touched.referenceURL && formik.errors.referenceURL ? true : false}
            helperText={formik.touched.referenceURL && formik.errors.referenceURL ? formik.errors.referenceURL : ''}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <FontAwesomeIcon icon={faGlobe} className={classes.adornIcon} />
                </InputAdornment>
                ),
            }}  
            />
        </Grid>
        <Grid item container xs={10} sm={8} md={6} className={classes.pb2}>

          <MuiPickersUtilsProvider utils={DayJsUtils} >
            <KeyboardDatePicker
            fullWidth
            margin="normal"
            id="date-picker-dialog"
            label="Certificate Start Date"
            format="DD/MM/YYYY"
            value={selectedDate}
            inputVariant="outlined"
            onChange={handleDateChange}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
            InputProps={{
              startAdornment: (
              <InputAdornment position="start">
                  <FontAwesomeIcon icon={faCalendarAlt} className={classes.adornIcon} />
              </InputAdornment>
              ),
            }}
            />
          </MuiPickersUtilsProvider>

        </Grid>

        <Grid item container xs={10} sm={8} md={6}>
        <Button
            fullWidth
            disableElevation
            type="submit"
            variant="contained"
            color="primary"
            >Submit
        </Button>
        </Grid>
    </Grid>

    </form>
  );
};

export default CertificateForm