import React, { useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { getDefaultModule } from '../utils';
import { Backdrop, Checkbox, ClickAwayListener, 
         Collapse, FormControlLabel, Grid, List, ListItem, ListItemText, ListItemIcon,
         Step, Stepper, StepLabel, StepContent, Button, Paper, Typography, Box, makeStyles, IconButton } from '@material-ui/core';

import { FABMagic } from '.';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faCheck, faFileAlt, faFileContract, faSignature, faStar, faTag, 
         faToggleOn, faUniversity, faUser, faUsers, faHome, faArrowRight, faInfoCircle, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const GetStarted = (props) => {

    const useStyles = makeStyles(theme => ({ 
      snackBox: {
        position: 'fixed',
        [theme.breakpoints.down('sm')]: { right: '10px', bottom: (props.pushDown ? '10px' : '80px') },
        [theme.breakpoints.up('md')]: { right: '20px', bottom: (props.pushDown ? '20px' : '90px') },
        height: 'auto', 
        width: props.fromDashboard ? '300px' : '210px', 
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.grey[800],
        padding: '15px 20px 15px 20px',
        borderRadius: '10px',
        border: '1px solid ' + theme.palette.grey[400],
        zIndex: 1000,
      },
      snackText: {
        fontWeight: '400',
      },
      stepLabel: {
          fontWeight: '700',
          fontSize: '15px',
          margin: '0px 5px 0px 5px',
      },
      complete: {
          color: theme.palette.success.main,
      },
      bold: {
        fontWeight: '700',
      },
      iconb1: {
        padding: '5px 4px 5px 4px',
      },
      iconb2: {
        padding: '5px 5px 5px 5px',
      },
      iconbsm: {
          fontSize: '14px',
      }, 
      iconInfo: {
          marginLeft: '4px',
          color: theme.palette.grey[700],
      },
      iconCheck: {
          marginLeft: '4px',
          color: theme.palette.success.main,
      }
    }));
    const classes = useStyles();

    const history = useHistory()

    const [getStartedOpen, setGetStartedOpen] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [hideGoingForward, setHideGoingForward] = React.useState(true)
    const [expandedInfo, setExpandedInfo] = React.useState([])

    const backmodule = getDefaultModule(props.org, props.user)
    
    useEffect(() => {
        if(props.triggeredFromParent > 0) { setGetStartedOpen(true) }
    }, [props.triggeredFromParent])

    const handleExpansion = (key) => {
        if (expandedInfo.includes(key)) {
            setExpandedInfo(expandedInfo.filter((e) => e !== key))
        } else {
            setExpandedInfo([...expandedInfo, key])
        }
    }

    function getStepContent(step) {
        let arr = []
        if(step === 0) {
            arr = [
                {id: '00', text: 'Review personal details*', route: '/core/settings', description: 'Review and complete your personal details through the input form.',
                 icon: faTag, complete: props.user.title !== undefined && props.user.title !== null && props.user.title.length > 0 }, 
                {id: '01', text: 'Upload profile picture', route: '/core/settings/photo', description: 'Click into the image and upload a 400 by 400px profile picture.',
                 icon: faUser, complete: props.user.photoURL !== "" && props.user.photoURL !== null && props.user.photoURL !== undefined && !props.user.photoURL.includes('defaultuser') }];
        } else if (step === 1) {
            arr = [
                {id: '10', text: 'Update company details', route: '/core/admin/company', description: 'Review and complete your company details such as website, phone and e-mail.',
                 icon: faBuilding, complete: props.org.email !== undefined && props.org.email.length > 1 && props.org.phone !== undefined && props.org.phone.length > 1 && props.org.url !== undefined && props.org.url.length > 1}, 
                {id: '11', text: 'Upload company logo*', route: '/core/admin/company/logo/1', description: 'Click into the image and upload a 400 by 400px company logo',
                 icon: faStar, complete: props.org.logoURL !== "" && props.org.logoURL !== null && props.org.logoURL !== undefined && !props.org.logoURL.includes('defaultlogo')},
                {id: '12', text: 'Setup an Entity*', route: '/core/admin/company/entities/1', description: 'Click the floating pink button (bottom right) and add a legal entity.',
                 icon: faUniversity, complete: props.subs !== undefined && props.subs !== null && props.subs.length > 0},
                {id: '13', text: 'Add other users', route: '/core/admin/usermgt', description: 'Click the floating pink button (bottom right) to add additional users.',
                 icon: faUsers, complete: props.users !== undefined && props.users.filter((u) => u.active).length > 1}];
        } else if (step === 2) {
            arr = [
                ...['Admin'].includes(props.user.role) ? [
                {id: '20', text: 'Set default signers', route: '/core/admin/company/signatory/1', description: 'Click "Add Signer" to define a default signatory for an entity.',
                 icon: faSignature, complete: props.subs !== undefined && props.subs !== null && props.subs.some((s) => s.signatory.length > 0 && s.signatory[0].uid !== undefined)}] : [{}],
                {id: '21', text: 'Create Legal Template*', route: '/core/legal/templates/' + (['sales','procurement','partnerships','people','corporate'].includes(props.module) ? props.module : backmodule), description: 'Select a module and then create a draft template through the pink floating button (bottom right)',
                 icon: faFileContract, complete: props.orgAgrTemplates !== undefined && props.orgAgrTemplates !== null && props.orgAgrTemplates.some((o) => o.module === props.module || props.module === undefined)}, 
                {id: '22', text: 'Activate Legal Template*', route: '/core/legal/templates/' + (['sales','procurement','partnerships','people','corporate'].includes(props.module) ? props.module : backmodule), description: 'Click into a draft template, clear any open edits and then click the status chip ("Draft") > "activate this version".',
                 icon: faToggleOn, complete: props.orgAgrTemplates !== undefined && props.orgAgrTemplates !== null && props.orgAgrTemplates.some((o) => (o.module === props.module || props.module === undefined) && o.active)}];
        } else if (step === 3) {
            arr = [
                {id: '30', text: 'Create an agreement', route: '/core/' + (['sales','procurement','partnerships','people','corporate'].includes(props.module) ? props.module : backmodule) + '/new', description: 'Select or create the counterparty. Then proceed to select the template and potentially add any exhibits.',
                 icon: faFileAlt, 
                 complete: props.rels.filter((r) => (r.relationshipType === 'corporate' && props.module === 'corporate') || 
                    (r.relationshipType === 'partnership' && props.module === 'partnerships') || 
                    (r.relationshipType === 'people' && props.module === 'people') || 
                    (r.relationshipType === 'b2b' && props.module === 'sales' && r.primaryOrgID === props.user.orgID) || 
                    (r.relationshipType === 'b2b' && props.module === 'procurement' && r.secondaryOrgID === props.user.orgID)).length > 0}]
        } 
        return arr
    }

    const getSteps = () => {
        let s = []
        s = [
            'Configure Personal Details', 
            ...['Admin'].includes(props.user.role) ? ['Configure Company Details'] : [], 
            ...['Admin', 'Legal'].includes(props.user.role) ? ['Configure Legal Foundation'] : [],
            'Create an Agreement'
        ];
        return s
    }

    const toggleGetStartedOpen = () => {
        if(!getStartedOpen) {
            let stepset = false;
            getSteps().forEach((step, i) => {
                let s = 
                    ['Admin'].includes(props.user.role) ? i : 
                    ['Legal'].includes(props.user.role) && i > 0 ? i + 1 :
                    i > 0 ? i + 2 : i;

                if(!stepset && getStepContent(s).filter((s) => s.text !== undefined).some((si) => !si.complete)) {
                    setActiveStep(i);
                    stepset = true;
                }
            })
            if(!stepset) { setActiveStep(['Admin'].includes(props.user.role) ? 4 : ['Legal'].includes(props.user.role) ? 3 : 2) }
        }
        setGetStartedOpen(!getStartedOpen);
    }

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleCompleteGetStarted = () => {
        if(hideGoingForward) {
            let introComplete = props.user.introComplete || {}
            introComplete.dashboard = true
            props.updateIntroComplete(props.user._id, introComplete)
        }
        setGetStartedOpen(false);
    }

    const handleStepChange = (prevOrNext) => {
        let path =
            prevOrNext === 'next' && props.step === "0_0" ? // UserSettings => Next => UserPhoto
                "/core/settings/photo" : 
            prevOrNext === 'prev' && props.step === "0_0" ? // UserSettings => Back => Dashboard
                "/core/" + (['sales','procurement','partnerships','people','corporate'].includes(props.module) ? props.module : backmodule) + "/dashboard" :
            prevOrNext === 'next' && props.step === "0_1" && ['Admin'].includes(props.user.role) ? // UserPhoto => Next => OrgSettings
                "/core/admin/company" :
            prevOrNext === 'next' && props.step === "0_1" && ['Legal'].includes(props.user.role) ? // UserPhoto => Next => Templates
                "/core/legal/templates" :
            prevOrNext === 'next' && props.step === "0_1" ? // UserPhoto => Next => NewAg
                "/core/" + (['sales','procurement','partnerships','people','corporate'].includes(props.module) ? props.module : backmodule) + "/new" :
            prevOrNext === 'prev' && props.step === "0_1" ? // UserPhoto => Back => UserSettings
                "/core/settings" :
            prevOrNext === 'next' && props.step === "1_0" ? // OrgSettings => Next => OrgLogo
                "/core/admin/company/logo/1" :
            prevOrNext === 'prev' && props.step === "1_0" ? // OrgSettings => Back => UserPhoto
                "/core/settings/photo" :
            prevOrNext === 'next' && props.step === "1_1" ? // OrgLogo => Next => Entities
                "/core/admin/company/entities/1" :
            prevOrNext === 'prev' && props.step === "1_1" ? // OrgLogo => Back => OrgSettings
                "/core/admin/company" :
            prevOrNext === 'next' && props.step === "1_2" ? // Entities => Next => UserMgt
                "/core/admin/usermgt" :
            prevOrNext === 'prev' && props.step === "1_2" ? // Entities => Back => OrgLogo
                "/core/admin/company/logo/1" :
            prevOrNext === 'next' && props.step === "1_3" ? // UserMgt => Next => Signatory
                "/core/admin/company/signatory/1" :
            prevOrNext === 'prev' && props.step === "1_3" ? // UserMgt => Back => Entities
                "/core/admin/company/entities/1" :
            prevOrNext === 'next' && props.step === "2_0" ? // Signatory => Next => Templates 
                "/core/legal/templates" :
            prevOrNext === 'prev' && props.step === "2_0" ? // Signatory => Back => UserMgt
                "/core/admin/usermgt" :
            prevOrNext === 'next' && props.step === "2_1" ? // Templates => Next => NewAg 
                "/core/" + (['sales','procurement','partnerships','people','corporate'].includes(props.module) ? props.module : backmodule) + "/new" :
            prevOrNext === 'prev' && props.step === "2_1" && ['Admin'].includes(props.user.role) ? // Templates => Back => Signatory
                "/core/admin/company/signatory/1" :
            prevOrNext === 'prev' && props.step === "2_1" ? // Templates => Back => UserPhoto
                "/core/settings/photo" :
            prevOrNext === 'next' && props.step === "3_0" ? // NewAg => Next => Dashboard
                "/core/" + (['sales','procurement','partnerships','people','corporate'].includes(props.module) ? props.module : backmodule) + "/dashboard" :
            prevOrNext === 'prev' && props.step === "3_0" && ['Legal', 'Admin'].includes(props.user.role) ? // NewAg => Back => Templates
                "/core/legal/templates" :
            prevOrNext === 'prev' && props.step === "3_0" ? // NewAg => Back => UserPhoto
                "/core/settings/photo" :
                "/core/" + (['sales','procurement','partnerships','people','corporate'].includes(props.module) ? props.module : backmodule) + "/dashboard"

        history.push(path)
    }

    return (
        <div>
            {!props.fromDashboard ?
            <Box className={classes.snackBox} style={{boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 24px 0px', border: '0px'}}>
                <Box mt={1} align="center">
                    <Grid container direction="row" alignItems="center" justify="space-between">
                        <Grid item xs={12}>
                            <Typography align="center" className={classes.bold}>Getting Started</Typography>
                        </Grid>
                    </Grid>
                    
                </Box>
                <Box mt={1} mb={1}>
                    <List component="nav" dense>
                    {getStepContent(
                        (['0_0', '0_1'].includes(props.step) ? 0 :
                         ['1_0', '1_1', '1_2', '1_3'].includes(props.step) ? 1 :
                         ['2_0', '2_1', '2_4'].includes(props.step) ? 2 :
                         ['3_0'].includes(props.step) ? 3 : 0)
                    ).slice(
                        (['0_0', '1_0', '2_0', '3_0'].includes(props.step) ? 0 :
                         ['0_1', '1_1', '2_1'].includes(props.step) ? 1 : 
                         ['1_2'].includes(props.step) ? 2 : 
                         ['1_3'].includes(props.step) ? 3 : 
                         ['2_4'].includes(props.step) ? 4 : 
                         0),
                        (['0_0', '1_0', '2_0'].includes(props.step) ? 1 :
                         ['0_1', '1_1', '3_0'].includes(props.step) ? 2 :
                         ['1_2'].includes(props.step) ? 3 : 
                         ['1_3', '2_1'].includes(props.step) ? 4 : 
                         ['2_4'].includes(props.step) ? 5 : 
                         1)
                    ).filter((s) => s.text !== undefined).map((item, i) => (
                        <React.Fragment key={"_" + i}>
                        <ListItem button
                        className={item.complete ? classes.complete : null}
                        style={{textAlign: 'center'}}
                        onClick={e => handleExpansion(item.id)}>
                          <ListItemText primary={
                            <span style={{fontSize: '14px', fontWeight: '500'}}>{item.text} <FontAwesomeIcon icon={item.complete ? faCheck : faInfoCircle} className={item.complete ? classes.iconCheck : classes.iconInfo} /></span>
                            } />
                        </ListItem>
                        <Collapse in={expandedInfo.includes(item.id)} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                            <ListItem>
                                <ListItemText primary={item.description} />
                            </ListItem>
                            </List>
                        </Collapse>
                        </React.Fragment>
                    ))}
                    </List>
                </Box>
                <Box>
                    <Grid container direction="row" alignItems="center" justify="space-between">
                        <Grid item xs={4}>
                            <Button size="small" color="secondary" variant="text" onClick={e => handleStepChange('prev')}>
                                <FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Back
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Box align="center" style={{marginBottom: '5px'}}>
                            <IconButton size="small" className={classes.iconb1} component={RouterLink} 
                            to={"/core/" + (['sales','procurement','partnerships','people','corporate'].includes(props.module) ? props.module : backmodule) + "/dashboard"}>
                                <FontAwesomeIcon icon={faHome} className={classes.iconbsm} />
                            </IconButton>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box align="right">
                            <Button size="small" color="secondary" variant="text" onClick={e => handleStepChange('next')}>
                                Next&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faArrowRight} />
                            </Button>
                            </Box>

                            {/*props.step !== "3_0" ?
                            <IconButton size="small" className={classes.iconb2} component={RouterLink} 
                            to={
                                props.step === "0_0" ? "/core/settings/photo" : 
                                props.step === "0_1" && ['Admin'].includes(props.user.role) ? "/core/admin/company" :
                                props.step === "0_1" && ['Legal'].includes(props.user.role) ? "/core/legal/templates" :
                                props.step === "0_1" ? "/core/" + backmodule + "/new" :
                                props.step === "1_0" ? "/core/admin/company/logo/1" :
                                props.step === "1_1" ? "/core/admin/company/entities/1" :
                                props.step === "1_2" ? "/core/admin/usermgt" :
                                props.step === "1_3" ? "/core/admin/company/signatory/1" :
                                props.step === "2_0" ? "/core/legal/templates" :
                                props.step === "2_1" ? "/core/" + backmodule + "/new" :
                                //props.step === "2_1" ? "/core/legal/certifications" :
                                //props.step === "2_4" ? "/core/" + backmodule + "/new" :
                                "/core/" + backmodule + "/dashboard"}>
                                <FontAwesomeIcon icon={faArrowRight} className={classes.iconbsm} />
                            </IconButton>
                            : <span>&nbsp;&nbsp;&nbsp;</span>*/}
                        </Grid>
                    </Grid>
                </Box>
                
            </Box>
            :getStartedOpen ? 
            <Backdrop
            style={{zIndex: '2000'}}
            open={getStartedOpen}
            >
            <ClickAwayListener onClickAway={toggleGetStartedOpen}>
            <Box className={classes.snackBox}>

                <Box mt={2} mb={0} ml={2} mr={2}>
                    <Typography variant="h5">Get Started</Typography>
                </Box>

                <Stepper activeStep={activeStep} orientation="vertical">
                    {getSteps().map((label, index) => (
                    <Step key={label}>
                        <StepLabel><Typography className={classes.stepLabel}>{label}</Typography></StepLabel>
                        <StepContent>
                        <List component="nav" dense>
                        {getStepContent(
                            ['Admin'].includes(props.user.role) ? index : 
                            ['Legal'].includes(props.user.role) && index > 0 ? index + 1 :
                            index > 0 ? index + 2 : index
                        ).filter((s) => s.text !== undefined).map((item, i) => (
                            <ListItem button key={index + "_" + i}
                            className={item.complete ? classes.complete : null}
                            component={RouterLink}
                            to={item.route}>
                            <ListItemIcon style={{minWidth: '32px'}} className={item.complete ? classes.complete : null}>
                                <FontAwesomeIcon icon={item.complete ? faCheck : item.icon} /></ListItemIcon>
                                <ListItemText primary={item.text} />
                            </ListItem>
                        ))}
                        </List>
                        <Box mt={1}>
                            <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.button}
                                disableElevation
                            >
                                <FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Back
                            </Button>&nbsp;&nbsp;
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                className={classes.button}
                                disableElevation
                                disabled={
                                    !props.anyRelExists && (
                                    (activeStep === 0 && !getStepContent(0)[0].complete) ||
                                    (['Admin'].includes(props.user.role) && activeStep === 1 && 
                                        (/*!getStepContent(1)[0].complete || */!getStepContent(1)[1].complete || !getStepContent(1)[2].complete)) ||
                                    (((['Admin'].includes(props.user.role) && activeStep === 2) ||
                                      (['Legal'].includes(props.user.role) && activeStep === 1)) && (!getStepContent(2)[1].complete || !getStepContent(2)[2].complete))/* ||
                                    (((['Admin'].includes(props.user.role) && activeStep === 3) ||
                                      (['Legal'].includes(props.user.role) && activeStep === 2) ||
                                    (!['Admin','Legal'].includes(props.user.role) && activeStep === 1)) && (!getStepContent(3)[0].complete))*/)}
                            >Next&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} /></Button>
                        </Box>
                        </StepContent>
                    </Step>
                    ))}
                    <Step>
                        <StepLabel><Typography className={classes.stepLabel}>Complete onboarding</Typography></StepLabel>
                        <StepContent>

                            <Paper square elevation={0}>
                                <Box pl={2} pr={1}>
                                    <Typography color="secondary" className={classes.bold} style={{marginTop: '20px'}}>You're done!</Typography>
                                    <Box mt={1}>
                                    <FormControlLabel
                                        control={<Checkbox size="small" checked={hideGoingForward} onChange={e => setHideGoingForward(!hideGoingForward)} name="hide" />}
                                        label={<Typography variant="body2">Hide "Get Started" guide</Typography>}
                                    />
                                    </Box>
                                </Box>

                                <Box mt={3} mb={1}>
                                <Button
                                    size="small"
                                    onClick={handleBack}
                                    className={classes.button}
                                    disableElevation
                                >Back
                                </Button>
                                <Button onClick={handleCompleteGetStarted} 
                                size="small" variant="contained" color="secondary" className={classes.button} disableElevation>
                                    Complete
                                </Button>
                                </Box>
                            </Paper>

                        </StepContent>

                    </Step>
                </Stepper>
                {/*activeStep === getSteps().length && (
                )*/}
            </Box>
            </ClickAwayListener>
            </Backdrop>
            :''}

            {props.fromDashboard ? 
            <FABMagic
            userRole={'na'}
            signable={false}
            editMode={'na'}
            templating={false}
            fabType= {"getstarted" }
            click={toggleGetStartedOpen}
            getStartedOpen={getStartedOpen}
            iconType={"getstarted"}
            />
            :''}

        </div>
    );
}

export default GetStarted