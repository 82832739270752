function convertBlocksToPlain(blocks, withSFs) {
  
    // Create plain version of blocks
    let eMap = blocks.entityMap;
    let newPlain = [];
    let mayNeedToDeleteNext = false;
    //let blocksPushed = 0;
    //let sfData = []
    
    blocks.blocks.slice().reverse().forEach((b) => { // for each block of text
        let plainBlock = b.text;
        let sfs = b.inlineStyleRanges;
        b.entityRanges.slice().reverse().forEach((er) => { // for each entity within the block
          if(['PROPOSEDDEL', 'PROPOSEDDELSECONDARY', 'REJECTPADD', 'REJECTPADDSEC'].includes(eMap[er.key].type)) { // If proposed delete or rejected insertions
              var result = plainBlock.split(''); // change string to array of characters
              result.splice(er.offset, er.length); // remove specified characters
              plainBlock = result.join(''); // recreate the string from new array
              if(withSFs && sfs.length > 0) { // Adjust SmartField location as appropriate for deleted text
                let newSfs = []
                sfs.forEach((sf,i) => { 

                    if(sf.offset + sf.length > er.offset) {

                        let offsetAdjustment = 
                            (er.offset + er.length) <= sf.offset ? er.length : // The entity is entirely before the SF
                            er.offset >= sf.offset ? 0 : // The entity is entirely after the start of the SF
                            er.length - (er.offset + er.length - sf.offset) // The entity is overlapping with the start, partial adjustment is required

                        let lengthAdjustment = er.length - offsetAdjustment

                        if(sf.length - lengthAdjustment > 0) { // Only if the SF still exists after adjustment
                            newSfs.push({...sf, offset: sf.offset - offsetAdjustment, length: sf.length - lengthAdjustment})
                        }

                    } else {
                        newSfs.push(sf)
                    }
                })
                sfs = newSfs;
              }
          }
        })

        if(withSFs && sfs.length > 0) { // There are smartfields, insert <sf></sf> in reverse order
            sfs
            .sort((a,b) => (a.offset < b.offset) ? 1 : ((b.offset < a.offset) ? -1 : 0))
            .forEach((sf) => {
                plainBlock = [
                    plainBlock.slice(0, sf.offset), 
                    '<' + sf.style + '>', 
                    plainBlock.slice(sf.offset, sf.offset + sf.length), 
                    '</' + sf.style + '>',
                    plainBlock.slice(sf.offset + sf.length)].join('');

            })
        }

        if(b.text !== "" && plainBlock === "") { // This is a (proposed) deleted block
            mayNeedToDeleteNext = true;
        } else if(b.text === "" && mayNeedToDeleteNext) { // This is an empty block after a (proposed) deleted block
            mayNeedToDeleteNext = false;
        } else { // This is a normal block
            mayNeedToDeleteNext = false;
            if(b.type === 'clauseTitle') { plainBlock = '<title>' + plainBlock + '</title>' }
            else if(b.type === 'secTitle') { plainBlock = '<secTitle>' + plainBlock + '</secTitle>' }
            else if(b.type === 'ssecTitle') { plainBlock = '<ssecTitle>' + plainBlock + '</ssecTitle>' }
            newPlain.push(plainBlock);
        }
    })
    
    return newPlain.reverse();
}
  
export { convertBlocksToPlain }