import React, { useEffect } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { trunc, getAppropriateIcon, getActorsForModule } from '../../utils';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Avatar, Badge, Box, Button, Card, CardContent, Container, Grid,
         List, ListItem, Typography, makeStyles, withStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { CleanClause, FAB, GetStarted, Header, InformationalTooltip, Labels, SearchField, TableEditable, ThumbAgr, ThumbRelationship, 
         ThumbTodo } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake, faUserFriends, faUser, faBuilding, faShoppingCart, faThumbsUp, faPlay, faPlus, 
         faFileContract, faTag, faMagic, faArrowLeft, faCog, faArrowRight, faHandSparkles } from "@fortawesome/free-solid-svg-icons";

import defaultLogo from '../../assets/img/defaultlogo.png';
import defaultUser from '../../assets/img/defaultuser.png';

const axios = require('axios');
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const StyledDot = withStyles(theme => ({
  badge: { left: 1, top: 1, },
}))(Badge);

const StyledDotRight = withStyles(theme => ({
  badge: { right: 3, top: 3, },
}))(Badge);

function Dashboard(props) {

  const useStyles = makeStyles(theme => ({
    outerFlexGrid: {
      minHeight: '80vh',
      [theme.breakpoints.only('xs')]: { paddingTop: '110px', paddingBottom: '80px', },
      [theme.breakpoints.only('sm')]: { paddingTop: '110px', paddingBottom: '80px', },
      [theme.breakpoints.up('md')]: { paddingTop: '130px', paddingBottom: '80px', },
    },
    /*
    thumbContainer : {
      [theme.breakpoints.only('xs')]: { width: '312px' },
    },*/
    thumbContainerRelevant: {
      [theme.breakpoints.only('xs')]: { width: '324px' },
      [theme.breakpoints.only('sm')]: { width: '454px' },
      [theme.breakpoints.up('md')]: { width: '900px' },
      //overflowY: 'auto',
    },
    thumbContainerInsights: {
      [theme.breakpoints.only('xs')]: { width: '312px' },
      [theme.breakpoints.only('sm')]: { width: '444px' },
    },
    thumbContainer: {
      [theme.breakpoints.only('xs')]: { width: '312px' },
      [theme.breakpoints.only('sm')]: { width: '444px' },
    },
    gridRelevant: {
      //[theme.breakpoints.only('xs')]: { width: '1892px' },
      //[theme.breakpoints.only('sm')]: { width: '2672px', },
      //[theme.breakpoints.up('md')]: { width: '2672px',  },
      padding: '20px 6px 20px 6px', 
      margin: '0px'
    },
    padBoth: {
      marginTop: '30px',
      marginBottom: '30px',
    },
    padDown: {
      marginBottom: '30px',
    },
    gridForThumb:{
      [theme.breakpoints.only('xs')]: { paddingLeft: '6px', paddingRight: '6px', paddingBottom: '12px' },
      [theme.breakpoints.only('sm')]: { paddingLeft: '6px', paddingRight: '6px' },
      [theme.breakpoints.up('md')]: { paddingLeft: '10px', paddingRight: '10px' },
    },
    thumbSkelet: {
      [theme.breakpoints.only('xs')]: { width: '144px', height: '160px' },
      [theme.breakpoints.only('sm')]: { width: '126px', height: '170px' },
      [theme.breakpoints.up('md')]: { width: '200px', height: '190px' },
      borderRadius: '10px',
    },
    searchSkelet: {
      [theme.breakpoints.only('xs')]: { width: '260px', height: '55px' },
      [theme.breakpoints.only('sm')]: { width: '400px', height: '55px' },
      [theme.breakpoints.up('md')]: { width: '440px', height: '55px' },
    },
    showXS: {
      [theme.breakpoints.down('xs')]: { display: 'inline' },
      [theme.breakpoints.up('sm')]: { display: 'none' },
    },
    doneIcon: {
      fontSize: '30px', 
      margin: '30px 20px 20px 20px', 
      color: theme.palette.grey[400]
    },
    doneFont: {
      [theme.breakpoints.only('xs')]: { fontSize: '11px' },
    },
    hideOnXs: {
      [theme.breakpoints.only('xs')]: { display: 'none' },
      [theme.breakpoints.up('sm')]: { display: 'inline-block' },
    },
    miniAvatar: {
      width: '25px',
      height: '25px',
      marginRight: '10px',
    },
    dashCard: {
      [theme.breakpoints.only('xs')]: { width: '144px', height: '204px', marginBottom: '6px', },
      [theme.breakpoints.only('sm')]: { width: '200px', height: '210px', marginBottom: '16px', },
      [theme.breakpoints.up('md')]: { width: '200px', height: '210px', marginBottom: '16px', },
      marginBottom: '16px'
    },
    dashCardHover: {
      [theme.breakpoints.only('xs')]: { width: '144px', height: '204px', marginBottom: '6px', },
      [theme.breakpoints.only('sm')]: { width: '200px', height: '210px', marginBottom: '16px', },
      [theme.breakpoints.up('md')]: { width: '200px', height: '210px', marginBottom: '16px', },
      marginBottom: '16px',
      '&:hover': {
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 24px 0px',
      }
    },
    dashCardContent: {
      paddingBottom: '0px', 
      marginBottom: '0px'
    },
    insight: {
      [theme.breakpoints.only('xs')]: { fontSize: '13px', },
      marginTop: '12px',
      fontWeight: '700',
      color: theme.palette.secondary.main,
    },
    insightDesc: {
      [theme.breakpoints.only('xs')]: { fontSize: '11px', },
    },
    padTopOnXS: {
      [theme.breakpoints.only('xs')]: { paddingTop: '19px' },
    },
    bold: {
      fontWeight: '700'
    },
    clausesBox: {
      border: '1px solid #e0e0e0',
      borderRadius: '10px',
    },
    ticketLength: {
      [theme.breakpoints.only('xs')]: { fontSize: '50px', fontWeight: '500' },
      fontSize: '72px'
    },
    miniButton: {
      [theme.breakpoints.only('xs')]: { fontSize: '12px' },
      marginTop: '10px',
      fontSize: '13px',
      fontWeight: '300',
      color: theme.palette.grey[900],
      padding: '1px 2px 1px 2px',
      '&:hover': {
        backgroundColor: '#ffffff',
        color: theme.palette.primary.main,
        fontWeight: '700',
      },
    }
  }));
  const classes = useStyles();
  const history = useHistory()

  const [searchOptions, setSearchOptions] = React.useState([]);
  const [relevantRels, setRelevantRels] = React.useState([]);
  const [relevantTickets, setRelevantTickets] = React.useState([]);
  const [relevantAgrs, setRelevantAgrs] = React.useState([])
  const [agrMetrics, setAgrMetrics] = React.useState(null);
  const [triggeredFromParent, setTriggeredFromParent] = React.useState(0);
  const [triggeredFromParentModules, setTriggeredFromParentModules] = React.useState(0);
  const [searchResults, setSearchResults] = React.useState([]);
  const [searchResultsType, setSearchResultsType] = React.useState(null)
  const [doneSearching, setDoneSearching] = React.useState(false)
  const [dashAgFilter, setDashAgFilter] = React.useState(null)
  const [relSliceStart, setRelSliceStart] = React.useState(0)

  useEffect(() => {

    let unmounted = false;
    let sos = [] // searchoptions

    if(!props.isLoading && props.user.email !== undefined) {

      // Filter and assign relevantRels (based on props.module)
      let rr = []
      if(props.module === 'sales' && props.rels !== undefined) {
        rr = props.rels.filter((r) => r.primaryOrgID === props.user.orgID && !r.deactive && (r.relationshipType === 'b2b'))
      } else if (props.module === 'procurement' && props.rels !== undefined) {
        rr = props.rels.filter((r) => r.secondaryOrgID === props.user.orgID && !r.deactive && (r.relationshipType === 'b2b'))
      } else if (props.module === 'partnerships' && props.rels !== undefined) {
        rr = props.rels.filter((r) =>  !r.deactive && r.relationshipType === 'partnership')
      } else if (props.module === 'corporate' && props.rels !== undefined) {
        rr = props.rels.filter((r) =>  !r.deactive && r.relationshipType === 'corporate')
      } else if (props.module === 'people' && props.rels !== undefined) {
        rr = props.rels.filter((r) =>  !r.deactive && r.relationshipType === 'people')
      } else if (props.module === 'counterparty' && props.rels !== undefined) { // show all relevant Rels for CPTY user
        rr = props.rels.filter((r) => !r.deactive && 
          props.user !== undefined && props.user.role !== undefined && (!props.user.role.startsWith('ClientFor') || props.user.role.substring(10) === r.primaryCptyID || props.user.role.substring(10) === r.secondaryCptyID))
      }

      // Filter all cpents for this module and add to the search results (does not includes Network cpties)
      props.cpents.filter((cpe) => cpe.entityType === getActorsForModule(props.module, true)).forEach((cpe) => {
        sos.push({
          category: 'Counterparties',
          title: cpe.legalName,
          description: cpe.shortName,
          route: '/core/' + props.module + '/masterdata/edit/' + cpe._id,
          entID: cpe._id,
          relID: props.rels.filter((r) => r.primaryOrgID === cpe.parentOrgID || r.secondaryOrgID === cpe.parentOrgID)[0] !== undefined ?
            props.rels.filter((r) => r.primaryOrgID === cpe.parentOrgID || r.secondaryOrgID === cpe.parentOrgID)[0]._id : null,
          logoURL: cpe.logoURL,
        });
      })

      // Loop through all relevant relationships - and add to the search results
      rr.forEach((r) => {

        let cporgid = r.primaryOrgID === props.user.orgID ? r.secondaryOrgID : r.primaryOrgID
        let cpent = props.cpents.filter((cp) => cp.parentOrgID === cporgid || cp.orgID === cporgid)[0]
        let relLogo = cpent !== undefined ? cpent.logoURL : props.org.logoURL
        
        sos.push({
          category: 'Relationships',
          title: (cpent !== undefined ? cpent.shortName : r.name) + (r.deactive ? ' - deactive' : ''),
          description: r.name + ' ' + (cpent !== undefined ? (cpent.shortName + ' ' + cpent.legalName) : '') + ' relationship',
          route: '/core/' + props.module + '/relationship/' + r._id + '/overview',
          relID: r._id,
          logoURL: relLogo,
        });
      })

      //For all template- agreement types, include them in the search results
      let atids = []
      props.orgAgrTemplates.forEach((oat) => {
        if(!atids.some((atid) => atid === oat.agrTypeID)) {
          atids.push(oat.agrTypeID)
          sos.push({
            category: 'Agreements',
            title: props.agrTypes.filter((at) => at._id === oat.agrTypeID)[0].fullName[0],
            description: props.agrTypes.filter((at) => at._id === oat.agrTypeID)[0].shortName,
            route: null,
            agrTypeID: oat.agrTypeID,
            logoURL: null,
            icon: faFileContract,
          })
        }
      })
      sos.push({
        category: 'Agreements',
        title: 'View all Agreements',
        description: 'View all contracts agreements',
        route: null,
        agrTypeID: 'all',
        logoURL: null,
        icon: faFileContract,
      })

      // Add all labels to the search results
      props.orgLabel.filter((ol) => ol.modules.includes(props.module)).forEach((ol) => {
        if(!ol.deactive) {
          sos.push({
            category: 'Labels',
            title: ol.name + ' - agreements',
            description: ol.desc + ' agreements',
            route: '/core/' + props.module + '/dashboard/label/agr/' + ol._id,
            logoURL: null,
            icon: faTag,
          })
          sos.push({
            category: 'Labels',
            title: ol.name + ' - relationships',
            description: ol.desc + ' relationships',
            route: '/core/' + props.module + '/dashboard/label/rel/' + ol._id,
            logoURL: null,
            icon: faTag,
          })
        } else {
          sos.push({
            category: 'Labels',
            title: ol.name + ' - reactivate label',
            description: ol.desc,
            route: '/core/' + props.module + '/dashboard/label/deactive/' + ol._id,
            logoURL: null,
            icon: faCog,
          })
        }
      })

      // Add all smart fields to the search options and then finalize the searchOptions variable
      axios.get('/sf/osfs/' + props.user.orgID + "/" + props.module) // PULL ALL ORG SMARTFIELDS FOR THAT MODULE
      .then(function(res) {

        // Filter out the "auto" smartfields managed by CANVEO
        res.data.data.filter((osf) => osf.orgID !== 'CANVEO' || osf.type !== 'auto').forEach((osf) => {
          
          if(osf.type === 'clause-topic' && osf.reusable && 
          (osf.orgID !== 'CANVEO' || !res.data.data.some((o) => o.defaultValue1 === osf.defaultValue1 && o.orgID !== 'CANVEO'))) {
            sos.push({
              category: 'Topics',
              title: osf.name,
              description: osf.defaultValue1,
              osfid: osf.defaultValue1,
              route: null,
              logoURL: null,
              icon: faHandSparkles,
            })
          } else if (osf.type !== 'clause-topic') {
            sos.push({
              category: 'Smart Fields',
              title: osf.name,
              description: osf.question,
              osfid: osf._id,
              route: null,
              logoURL: null,
              icon: faMagic,
            })
          }
        })

        if (!unmounted) { setSearchOptions(sos) }

      }).catch(function(err) { console.log(err) })
      
      if(props.searchtype !== undefined && props.val1 !== undefined && props.val2 !== undefined) { // Section to search and then display results

        if(['sf_rel', 'sf_ent'].includes(props.searchtype) && props.val1 !== undefined && props.val2 !== undefined && props.subs !== undefined && props.subs !== null && props.subs.length > 0) {

          let subs = []
          props.subs.forEach((s) => { subs.push(s._id) })
          // Pull Agreements for this counterparty or relid
          let axiosQuery = ['sf_rel'].includes(props.searchtype) ? axios.get('/agr/agrs/' + props.val2) : axios.post('/agr/agrssubscp', { cpid: props.val2, subs: subs})
          axiosQuery.then((res) => {
            let aids = [], ags = []
            ags = res.data.data.filter((a) => a.agrStatus !== 'Deactive');
            ags.forEach((ag) => { aids.push(ag._id) });

            if(aids.length > 0) {

              axios.post('/sf/getsfs', { aids: aids, osfid: props.val1 })
              .then((ressf) => {

                let sfs = ressf.data.data;

                ags.forEach((ag, i) => { // for Each agrID , search for the latest AV that you own.
                  axios.get('/agr/agrvs/' + ag._id)
                  .then((resav) => {
                    let av = resav.data.data.filter((rav) => (rav.owner === "primary" && subs.includes(ag.primaryCptyID)) || (rav.owner === "secondary" &&  subs.includes(ag.secondaryCptyID)))[0]

                    if(av !== undefined && sfs.length > 0) { // You have smart fields for these agrs
                      sfs.filter((sf) => sf.agrid === ag._id).forEach((sf) => {
                        axios.get('/clause/protect/sclause/' + sf.singleClauseID)
                        .then((rescls) => {
                          let cl = rescls.data.data.filter((c) => av.clauses.includes(c._id))[0]
                          if(cl !== undefined) {
                            setSearchResults(searchResults => [...searchResults, {
                              ag: ag,
                              cl: cl,
                              sfref: sf.ref,
                            }])
                          }
                        }).catch((err) => console.log(err))
                      })
                    } else if(av !== undefined) { // Otherwise to check against topics
                      axios.post('/clause/clauses', { clauses: av.clauses, clauseType: props.val1 })
                      .then((rescls) => {
                        rescls.data.data.forEach((c) => {
                          setSearchResults(searchResults => [...searchResults, {
                            ag: ag,
                            cl: c,
                            sfref: props.val1,
                          }])
                        })
                      }).catch((err) => console.log(err))
                    }

                    if(ags.length === i + 1) {
                      setSearchResultsType('cl')
                      setDoneSearching(true)
                    }
                    
                  }).catch((err) => console.log(err))
                })
              }).catch((err) => console.log(err))

            } else {
              setDoneSearching(true)
            }

          }).catch((err) => console.log(err))

        } else if(['label'].includes(props.searchtype) && props.val1 !== 'deactive') {

          axios.get('/orglabel/orglabellinkolid/' + props.user.orgID + '/' + props.val2) // Pull all org label links
          .then((res) => {

            let relScope = [], relArray = []
            res.data.data.forEach((oll) => {
              if(oll.relID !== undefined && oll.relID !== null && oll.relID !== '' && oll.relID.length > 10 && 
              rr.filter((r) => r._id === oll.relID)[0] !== undefined) {
                relScope.push(rr.filter((r) => r._id === oll.relID)[0])
                relArray.push(oll.relID);
              }
            })

            if(props.val1 === 'rel') { // Looking for all relationships - end of Search
              setSearchResultsType('rel')
              setSearchResults(relScope)
              setDoneSearching(true)
            } else { // Looking for all agreements - continue to dig
              let agrArray = [] 
              res.data.data.filter((oll) => oll.agrID !== undefined && oll.agrID !== null && oll.agrID.length > 10).forEach((oll) => {
                agrArray.push(oll.agrID)
              })

              // now pull all agrs with a post query based on relArray and agrArray, limit to most recent 100
              axios.post('/agr/agrsrelsagrs', {
                agrs: agrArray,
                rels: relArray,
              })
              .then((resags) => { // Looking for all agreements - end of Search

                setSearchResultsType('agr')
                setSearchResults(resags.data.data)
                setDoneSearching(true)

              }).catch((err) => console.log(err))              

            }
          }).catch((err) => console.log(err))

        } else {
          setDoneSearching(true)
        }

      } else if(props.rels.length > 0 && props.subs.length > 0) { // Section to get relevant agreements
        let ents = [], rs = []
        rr.forEach((r) => { rs.push(r._id) })
        props.subs.forEach((s) => { ents.push(s._id) })
        axios.post('/agr/agrsrelevant', {
          lastLoginDate: props.user.lastLoginDate,
          entities: ents,
          rels: rs,
          uid: props.user._id,
          orgid: props.user.orgID,
        })
        .then(function(resags) {
          if (!unmounted) {
            setRelevantRels(rr)
            setRelevantAgrs(resags.data.data.enrichedAgrs); 
            setRelevantTickets(resags.data.data.ticks);
            setAgrMetrics({
              agrsByMonth: resags.data.data.agrsByMonth,
              avgCycleTime: Math.round(resags.data.data.avgCycleTime * 100) / 100,
              doneRatio: Math.round(resags.data.data.doneRatio * 100, 0),
              fourWeeksRatio: Math.round(resags.data.data.fourWeeksRatio * 100, 0),
            })
          }   
        }).catch(function(err) { console.log(err) })
      } else {
        if (!unmounted) { setAgrMetrics({}) }
      }
    }

    return () => { unmounted = true };

  }, [props.isLoading, props.certs, props.agrTypes, props.cpents, props.network, props.orgAgrTemplates, 
      props.rels, props.user, props.module, props.subs, props.org.logoURL, props.orgLabel, props.searchtype,
      props.val1, props.val2]);


  useEffect(() => { // Close the intro guide, if you've seen an ag created by this user

    if((props.org !== undefined && props.user !== undefined && props.user._id !== undefined && // the user has not completed the intro yet
      (props.user.introComplete === undefined || props.user.introComplete === null || !props.user.introComplete.dashboard)) && 
      // AND
      ((props.org.clientType === 'legalOrg' && props.user.role === 'Admin') || // the user is an Admin from a legalOrg
      (relevantAgrs !== undefined && relevantAgrs !== null && // OR the user has created an REL or an AGR
          (props.rels.some((ra) => ra.creationBy === props.user._id) || relevantAgrs.some((ra) => ra.creationBy === props.user._id)))
    )) {

      handleCloseIntroGuide()
    }

  },[props.org, props.user, relevantAgrs])

  const dashboardAlreadyVisited = (props.module === 'sales' && props.userSession.dashboardVisits.sales > 0) ||
    (props.module === 'procurement' && props.userSession.dashboardVisits.procurement > 0) ||
    (props.module === 'partnerships' && props.userSession.dashboardVisits.partnerships > 0) ||
    (props.module === 'corporate' && props.userSession.dashboardVisits.corporate > 0) ||
    (props.module === 'people' && props.userSession.dashboardVisits.people > 0) ||
    (props.module === 'counterparty' && props.userSession.dashboardVisits.counterparty > 0)

  const whiteLabel = props.module === 'counterparty' && props.rels !== undefined && props.rels !== null &&
    props.rels.length === 1 && props.rels[0].whiteLabel !== undefined && props.user !== undefined && props.user.role === 'Counterparty' ? props.rels[0].whiteLabel : null
  const whiteLabelLoading = props.rels === undefined || props.rels === null || props.isLoading === undefined || props.isLoading

  const RenderRelevantRels = () => {
    return (
      <Grid container spacing={0} className={classes.thumbContainer}>
        {relevantRels
          .sort((a,b) => (a.lastUpdateDate < b.lastUpdateDate) ? 1 : ((b.lastUpdateDate < a.lastUpdateDate) ? -1 : 0))
          .slice(relSliceStart, relSliceStart + 4)
          .map((rel, i) => (
            <Grid item className={classes.gridForThumb} key={i}
            xs={relevantRels.length === 1 ? 12 : 6} 
            sm={relevantRels.length > 3 ? 3 : relevantRels.length === 3 ? 4 : relevantRels.length === 2 ? 6 : 3}>
              <ThumbRelationship 
                logo={
                  props.org.clientType === 'legalOrg' && (
                  (props.user.role === 'Admin' && rel.primaryOrgID === props.user.orgID)/* ||
                  (props.user.role.startsWith('ClientFor_') && rel.secondaryOrgID === props.user.orgID)*/) ?
                    props.subs.filter((s) => s._id === rel.primaryCptyID)[0].logoURL :
                  props.org.clientType === 'legalOrg' && (
                  (props.user.role === 'Admin' && rel.secondaryOrgID === props.user.orgID)/* ||
                  (props.user.role.startsWith('ClientFor_') && rel.primaryOrgID === props.user.orgID)*/) ?
                    props.subs.filter((s) => s._id === rel.secondaryCptyID)[0].logoURL :
                  rel.primaryOrgID === props.user.orgID && (props.org.clientType !== 'legalOrg' || props.user.role.startsWith('ClientFor_')) && 
                  props.cpents.filter((c) => c._id === rel.secondaryCptyID)[0] !== undefined ? 
                    props.cpents.filter((c) => c._id === rel.secondaryCptyID)[0].logoURL :
                  rel.secondaryOrgID === props.user.orgID && (props.org.clientType !== 'legalOrg' || props.user.role.startsWith('ClientFor_')) && 
                  props.cpents.filter((c) => c._id === rel.primaryCptyID)[0] !== undefined ? 
                    props.cpents.filter((c) => c._id === rel.primaryCptyID)[0].logoURL :
                  props.module === 'people' ? defaultUser : defaultLogo}
                name={trunc(
                    props.org.clientType === 'legalOrg' ? rel.name :
                    rel.primaryOrgID === props.user.orgID ?
                    rel.name.substring(rel.name.indexOf('|') + 2) :
                    rel.name.substring(0, rel.name.indexOf('|') - 1), 20) }              
                /*logo={
                  rel.primaryOrgID === props.user.orgID && props.cpents.filter((c) => c._id === rel.secondaryCptyID)[0] !== undefined ? 
                    props.cpents.filter((c) => c._id === rel.secondaryCptyID)[0].logoURL :
                  rel.secondaryOrgID === props.user.orgID && props.cpents.filter((c) => c._id === rel.primaryCptyID)[0] !== undefined ? 
                    props.cpents.filter((c) => c._id === rel.primaryCptyID)[0].logoURL :
                  props.module === 'people' ? defaultUser : defaultLogo}
                name={
                  rel.primaryOrgID === props.user.orgID ?
                  rel.name.substring(rel.name.indexOf('|') + 2) :
                  rel.name.substring(0, rel.name.indexOf('|') - 1) }*/
                rid={rel._id}
                lastUpdate={rel.lastUpdateDate}
                deactive={rel.deactive}
                hideDot={dashboardAlreadyVisited ||
                        (props.user.lastLoginDate > rel.lastUpdateDate)}
                path={"/core/" + props.module + "/relationship/" + rel._id + "/overview"}
              />
            </Grid>
          ))
        }
      </Grid>      
    )
  }
  
  const handleCloseIntroGuide = () => {
    let introComplete = props.user.introComplete || {}
    introComplete.dashboard = true
    props.updateIntroComplete(props.user._id, introComplete)
  }

  return(
    <div>

        {// GET STARTED FAB or REGULAR "NEW" FAB
        props.user !== undefined && props.user.role !== undefined && props.user.role !== 'Counterparty' && 
        (props.user.introComplete === undefined || props.user.introComplete.dashboard === undefined || props.user.introComplete.dashboard === null || !props.user.introComplete.dashboard) && 
        props.org !== undefined && props.org.clientType !== 'legalOrg' ?
        <GetStarted 
          fromDashboard={true}
          pushDown={false}
          module={props.module}
          triggeredFromParent={triggeredFromParent} 
          user={props.user}
          org={props.org}
          subs={props.subs}
          users={props.users}
          orgAgrTemplates={props.orgAgrTemplates}
          certs={props.certs}
          cpents={props.cpents}
          anyRelExists={props.rels.length > 0}
          rels={relevantRels}
          updateIntroComplete={props.updateIntroComplete}
        />
        :
         ["Admin", "Business", "Corporate", "HR", "Legal", "Partnerships", "Procurement", "Sales"].includes(props.user.role) && (
         ['custB', 'cust','custE', 'legalOrg'].includes(props.org.clientType) || (['custS'].includes(props.org.clientType) && props.org.modules.includes(props.module))) ? 
          <FAB iconType="plus" routeTo={"/core/" + props.module + "/new/1"}
          extended buttonText="New" />: ''
        }

        <Header 
          module={props.module}
          active="Home"
          user={props.user}
          org={props.org}
          modules={
            props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
              ['sales','procurement','partnerships','people','corporate'] : 
            props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
              props.org.modules : []}
          crumbs={[{name: (props.user.role === 'Counterparty' ? "Relationships" : "Home"), to: "active"}]}
          notifications={props.notifications}
          markNotificationAsRead={props.markNotificationAsRead}
          markAllNotificationsAsRead={props.markAllNotificationsAsRead}
          triggeredFromParentModules={triggeredFromParentModules}
          whiteLabel={whiteLabel}
          whiteLabelLoading={whiteLabelLoading}
        />

        <Container maxWidth="lg">
        <Grid container direction="column" justify="flex-start" alignItems="center" className={classes.outerFlexGrid}>
      
        {props.isLoading === undefined || props.isLoading || (agrMetrics === null && !doneSearching) ? 
        (
        <Box align="center">
          <Box>
            <Skeleton variant="text" animation="wave" className={classes.searchSkelet} />
          </Box>

          <Box mt={8}>
            <Grid container spacing={0} className={classes.thumbContainer}>
              <Grid item className={classes.gridForThumb} xs={6} sm={3}>
                <Box mb={1}><Skeleton variant="text" animation="wave" width={120} /></Box>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
              </Grid>
              <Grid item className={classes.gridForThumb} xs={6} sm={3}>
                <Box mb={1}><Skeleton variant="text" animation="wave" width={120} /></Box>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
              </Grid>
              <Grid item className={classes.gridForThumb} xs={6} sm={3}>
                <Box mb={1}><Skeleton variant="text" animation="wave" width={120} /></Box>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
              </Grid>
              <Grid item className={classes.gridForThumb} xs={6} sm={3}>
                <Box mb={1}><Skeleton variant="text" animation="wave" width={120} /></Box>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
              </Grid>
            </Grid>
          </Box>
          <Box mt={6}>
            <Grid container spacing={0} className={classes.thumbContainer}>
              <Grid item className={classes.gridForThumb} xs={6} sm={3}>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
              </Grid>
              <Grid item className={classes.gridForThumb} xs={6} sm={3}>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
              </Grid>
              <Grid item className={classes.gridForThumb} xs={6} sm={3}>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
              </Grid>
              <Grid item className={classes.gridForThumb} xs={6} sm={3}>
                <Skeleton variant="rect" animation="wave" className={classes.thumbSkelet} />
              </Grid>
            </Grid>
          </Box>
        </Box>
        )
        :
        (
        <React.Fragment>
          {['custS', 'custB', 'cust', 'custE'].includes(props.org.clientType) ||
          (['legalOrg'].includes(props.org.clientType) && props.user.role === 'Admin') ? // For subscriber users
          <>

            { // User who has not completed GET STARTED ("introComplete.dashboard")
            !doneSearching && props.rels !== undefined && 
            (props.user.introComplete === undefined || props.user.introComplete.dashboard === undefined || !props.user.introComplete.dashboard || relevantRels[0] === undefined) ?
            // Scenario 1: New User, no relationship yet
            // Scenario 2: Existing user, has a relationship in a different module but not in this one
            <>
              <Typography variant="h5" color="textPrimary" align="center">
                Welcome{props.rels.length === 0 || ((props.user.lastLoginDate === undefined || props.user.lastLoginDate === null) && 
                (props.user.introComplete === undefined || props.user.introComplete.dashboard === undefined || !props.user.introComplete.dashboard)) ? "" : " back"}, {props.user.firstName}
              </Typography>
              
              {props.user.introComplete === undefined || props.user.introComplete.dashboard === undefined || !props.user.introComplete.dashboard ?
              <Box mt={4}>
                <Typography variant="body1" align="center">
                  You're new to Canveo&nbsp;&nbsp;<InformationalTooltip msg={"Throughout the application, you'll find these blue \"info\" icons. Once you hover over or click on them, useful tips will be shown to help you navigate the Canveo application and contracting process.\n\nTo complete your configuration, follow the \"Get Started\" flow and be aware of the ability to navigate between modules through the module selector (next to the Canveo logo).\n\n"} />
                </Typography>
                <Box mt={3}>
                  <Typography variant="body1" align="center" gutterBottom>
                    Canveo enables you to <span className={classes.bold}>create, negotiate, sign</span> and <span className={classes.bold}>manage</span> your contracts.
                  </Typography>
                </Box>
              </Box>
              : ''}

              <Box mt={7} mb={8} align="center">
                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                  <Grid item>
                    {props.user.role !== 'Counterparty' && 
                    props.org !== undefined && props.org.clientType !== 'legalOrg' &&
                    (props.user.introComplete === undefined || props.user.introComplete.dashboard === undefined || props.user.introComplete.dashboard === null || !props.user.introComplete.dashboard) ?
                    <ThumbTodo 
                      line1="Get Started" 
                      line2="Get up to speed quickly" 
                      color="secondary"
                      route="click"
                      click={e => setTriggeredFromParent(triggeredFromParent + 1)}
                      icon={faPlay} 
                      adminNeeded={false}
                      done={false} />
                    : 
                    <ThumbTodo 
                    line1="Create Agreement" 
                    line2="Create an agreement" 
                    color="secondary"
                    route={"/core/" + props.module + "/new"}
                    icon={faPlus} 
                    adminNeeded={false}
                    done={false} />
                    }
                  </Grid>
                  <Grid item>
                    <ThumbTodo 
                      line1="Change Module"
                      line2="Go to a different module" 
                      color="primary"
                      route="click"
                      click={e => setTriggeredFromParentModules(triggeredFromParentModules + 1)}
                      icon={
                        props.rels[0] !== undefined && props.rels[0].relationshipType === 'partnership' ? faUserFriends :
                        props.rels[0] !== undefined && props.rels[0].relationshipType === 'people' ? faUser :
                        props.rels[0] !== undefined && props.rels[0].relationshipType === 'corporate' ? faBuilding :
                        props.rels[0] !== undefined && props.rels[0].relationshipType === 'b2b' && props.rels[0].primaryOrgID === props.user.orgID ? faHandshake :
                        props.rels[0] !== undefined && props.rels[0].relationshipType === 'b2b' && props.rels[0].secondaryOrgID === props.user.orgID ? faShoppingCart : faHandshake } 
                      adminNeeded={false}
                      done={false} />
                  </Grid>
                </Grid>
              </Box>
              {/*props.rels[0] !== undefined ?
              <Box align="center">
                <Box mb={2}>
                  Your Organisation has completed onboarding.
                </Box>
                <Button variant="text" color="secondary" size="large"
                onClick={handleCloseIntroGuide}
                >Hide "Getting Started" guide</Button>
              </Box>
              :''*/}
            </>
            // End of the section for users who have not completed onboarding yet
            : // For users who have already completed onboarding

            <>
              <Grid item className={relevantRels.length === 0 ? classes.padBoth : classes.padDown}>
                {props.user !== undefined && props.user.role !== undefined && props.user.role !== 'Counterparty' && (props.user.introComplete === undefined || props.user.introComplete.dashboard === undefined || props.user.introComplete.dashboard === null || !props.user.introComplete.dashboard) ?
                <Button size="large" color="secondary" disableElevation variant="contained" onClick={e => setTriggeredFromParent(triggeredFromParent + 1)} >
                  Get Started with Canveo&nbsp;&nbsp;<FontAwesomeIcon icon={faPlay} />
                </Button>
                :
                <SearchField
                label="Search Canveo"
                placeholder={"Hi " + (props.user !== undefined ? props.user.firstName : '') + ", how can we help you today?"}
                options={searchOptions}
                module={props.module} />}
              </Grid>

              {doneSearching ?
              <Grid item>

                <Box mt={3} mb={3} align="center">

                  {props.searchtype === 'label' && props.val1 === 'deactive' && props.val2 !== undefined &&
                  props.orgLabel.filter((ol) => ol._id === props.val2)[0] !== undefined ?

                    <>
                      <Labels 
                      user={props.user}
                      level={'dash'}
                      orgLabel={props.orgLabel.filter((ol) => ol._id === props.val2)}
                      labels={[]}
                      module={props.module}
                      deleteOrgLabel={props.deleteOrgLabel}
                      updateOrgLabel={props.updateOrgLabel}
                      />

                    <Box mt={5}><Button variant="contained" color="primary" disableElevation size="large"
                    onClick={e => history.push('/core/' + props.module + '/dashboard')}><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Go Back</Button></Box>
                    </>
                  
                  :searchResults.length === 0 ?
                    <>
                    <Typography>No results were found. Go back or Try again.</Typography>
                    <Box mt={5}><Button variant="contained" color="secondary" disableElevation size="large"
                    onClick={e => history.push('/core/' + props.module + '/dashboard')}><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Go Back</Button></Box>
                    </>
                  : // You have found some results
                  <>
                    <Box mb={2}>
                      <Box>
                        <Button color="secondary" variant="text" component={RouterLink} to={"/core/" + props.module + "/dashboard"}><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Clear search</Button>
                      </Box>
                    </Box>

                    {['rel','agr'].includes(searchResultsType) ?
                    <Box mt={1} mb={4} align="center">
                      <Grid container className={classes.thumbContainerRelevant}>
                        {searchResults
                          .sort((a,b) => (a.lastUpdateDate < b.lastUpdateDate) ? 1 : ((b.lastUpdateDate < a.lastUpdateDate) ? -1 : 0))
                          .map((item, i) => (
                            <Grid className={classes.gridForThumb} 
                            style={{marginBottom: '20px'}}
                            item xs={6} md={searchResults.length > 3 ? 3 
                            : searchResults.length === 3 ? 4 
                            : searchResults.length === 2 ? 6 : 3} key={i}>
                                {searchResultsType === 'rel' ?
                                  <ThumbRelationship 
                                  logo={
                                    item.primaryOrgID === props.user.orgID && props.cpents.filter((c) => c._id === item.secondaryCptyID)[0] !== undefined ? 
                                      props.cpents.filter((c) => c._id === item.secondaryCptyID)[0].logoURL :
                                    item.secondaryOrgID === props.user.orgID && props.cpents.filter((c) => c._id === item.primaryCptyID)[0] !== undefined ? 
                                      props.cpents.filter((c) => c._id === item.primaryCptyID)[0].logoURL :
                                    props.module === 'people' ? defaultUser : defaultLogo}
                                  name={
                                      item.primaryOrgID === props.user.orgID ?
                                      item.name.substring(item.name.indexOf('|') + 2) :
                                      item.name.substring(0, item.name.indexOf('|') - 1) }
                                  rid={item._id}
                                  lastUpdate={item.lastUpdateDate}
                                  deactive={item.deactive}
                                  hideDot={true}
                                  path={"/core/" + props.module + "/relationship/" + item._id + "/overview"}
                                  />
                                :searchResultsType === 'agr' ?
                                <ThumbAgr 
                                  fromDashboard={true}
                                  ag={item}
                                  path={"/core/" + props.module + "/agreement/" + item.relID + "/" + item._id}
                                  cptyLogo={
                                    props.org !== undefined && props.org.clientType === 'legalOrg' && props.subs.filter((s) => s._id === item.primaryCptyID || s._id === item.secondaryCptyID)[0] !== undefined ? 
                                      props.subs.filter((s) => s._id === item.primaryCptyID || s._id === item.secondaryCptyID)[0].logoURL :
                                    props.cpents.filter((e) => e._id === item.primaryCptyID || e._id === item.secondaryCptyID)[0] !== undefined ?
                                      props.cpents.filter((e) => e._id === item.primaryCptyID || e._id === item.secondaryCptyID)[0].logoURL : 
                                      props.module === 'people' ? defaultUser : defaultLogo}
                                  primaryLegalName={
                                    props.subs.filter((s) => s._id === item.primaryCptyID)[0] !== undefined ? 
                                      props.subs.filter((s) => s._id === item.primaryCptyID)[0].legalName : 
                                    props.cpents.filter((e) => e._id === item.primaryCptyID)[0] !== undefined ?
                                      props.cpents.filter((e) => e._id === item.primaryCptyID)[0].legalName : "Unknown Entity"}
                                  secondaryLegalName={
                                    props.subs.filter((s) => s._id === item.secondaryCptyID)[0] !== undefined ? 
                                      props.subs.filter((s) => s._id === item.secondaryCptyID)[0].legalName : 
                                    props.cpents.filter((e) => e._id === item.secondaryCptyID)[0] !== undefined ?
                                      props.cpents.filter((e) => e._id === item.secondaryCptyID)[0].legalName : "Unknown Entity"}
                                  actionReq={((['Draft', 'Review', 'Negotiation'].includes(item.agrStatus) && (
                                    (props.subs.filter((s) => s._id === item.primaryCptyID)[0] !== undefined && item.curAVOwner === item.primaryCptyID) ||
                                    (props.subs.filter((s) => s._id === item.secondaryCptyID)[0] !== undefined && item.curAVOwner === item.secondaryCptyID)))) ||
                                    ['Execution'].includes(item.agrStatus)}
                                  hideDot={true}
                                />
                                : ''}
                            </Grid>
                          ))
                        }
                      </Grid>
                    </Box>
                    :['cl'].includes(searchResultsType) ?
                    <Box mt={1} mb={4} align="center">
                        {searchResults.map((sr, i) => (
                        <Box mb={2} key={i}>
                        <Grid container className={classes.thumbContainerRelevant} alignItems="flex-start">
                          <Grid item md={3} sm={6}>
                            <ThumbAgr 
                              fromDashboard={true}
                              ag={sr.ag}
                              path={"/core/" + props.module + "/agreement/" + sr.ag.relID + "/" + sr.ag._id + (sr.cl !== undefined && sr.cl !== null && sr.cl._id !== undefined ? ("/" + sr.cl._id) : '')}
                              cptyLogo={
                                props.org !== undefined && props.org.clientType === 'legalOrg' && props.subs.filter((s) => s._id === sr.ag.primaryCptyID || s._id === sr.ag.secondaryCptyID)[0] !== undefined ? 
                                  props.subs.filter((s) => s._id === sr.ag.primaryCptyID || s._id === sr.ag.secondaryCptyID)[0].logoURL :
                                props.cpents.filter((e) => e._id === sr.ag.primaryCptyID || e._id === sr.ag.secondaryCptyID)[0] !== undefined ?
                                  props.cpents.filter((e) => e._id === sr.ag.primaryCptyID || e._id === sr.ag.secondaryCptyID)[0].logoURL : 
                                  props.module === 'people' ? defaultUser : defaultLogo}
                              primaryLegalName={
                                props.subs.filter((s) => s._id === sr.ag.primaryCptyID)[0] !== undefined ? 
                                  props.subs.filter((s) => s._id === sr.ag.primaryCptyID)[0].legalName : 
                                props.cpents.filter((e) => e._id === sr.ag.primaryCptyID)[0] !== undefined ?
                                  props.cpents.filter((e) => e._id === sr.ag.primaryCptyID)[0].legalName : "Unknown Entity"}
                              secondaryLegalName={
                                props.subs.filter((s) => s._id === sr.ag.secondaryCptyID)[0] !== undefined ? 
                                  props.subs.filter((s) => s._id === sr.ag.secondaryCptyID)[0].legalName : 
                                props.cpents.filter((e) => e._id === sr.ag.secondaryCptyID)[0] !== undefined ?
                                  props.cpents.filter((e) => e._id === sr.ag.secondaryCptyID)[0].legalName : "Unknown Entity"}
                              actionReq={((['Draft', 'Review', 'Negotiation'].includes(sr.ag.agrStatus) && (
                                (props.subs.filter((s) => s._id === sr.ag.primaryCptyID)[0] !== undefined && sr.ag.curAVOwner === sr.ag.primaryCptyID) ||
                                (props.subs.filter((s) => s._id === sr.ag.secondaryCptyID)[0] !== undefined && sr.ag.curAVOwner === sr.ag.secondaryCptyID)))) ||
                                ['Execution'].includes(sr.ag.agrStatus)}
                              hideDot={true}
                            />

                          </Grid>
                          <Grid item md={9} sm={6}>
                            <Box align="left" pl={2} pr={2} pt={2} pb={2} className={classes.clausesBox}>
                              {sr.cl !== undefined && sr.cl !== null && sr.cl.clauseCat === 'table' ?
                              <TableEditable
                              active={false}
                              disabled={true}
                              editMode={'none'}
                              plainMode={true}
                              clause={sr.cl}
                              highlightSF={sr.sfref}
                              proposedDel={
                                  sr.cl !== null && sr.cl.pendingChange !== undefined && 
                                  sr.cl.pendingChange.filter((pc) => pc.status === 'Open' && pc.type === 'proposedDel')[0] !== undefined ?
                                    sr.cl.pendingChange.filter((pc) => pc.status === 'Open' && pc.type === 'proposedDel')[0] !== undefined : null}
                              focusClause={() => void 0}
                              curCpty={"primary"}
                              />
                              :
                              sr.cl !== undefined && sr.cl !== null && sr.cl.blocks !== undefined ?
                              <CleanClause
                                blocks={sr.cl.blocks}
                                withSmartFields={true}
                                active={true}
                                activeRef={null}
                                singleRef={sr.sfref}
                                click={null}
                              />
                              :
                              <Typography variant="body1">
                                Unable to display the clause.
                              </Typography>
                              }
                            </Box>
                          </Grid>
                        </Grid>
                        </Box>
                        ))}
                    </Box>
                    :''
                    }
                  </>
                  }

                </Box>

              </Grid>
              :
              <>
              <Grid item>
                <Box mt={2} mb={1}>
                <Grid container direction="row" className={classes.thumbContainerRelevant} align="center" mt={1} >

                          {relevantAgrs
                          .filter((ag) => dashAgFilter === null || (dashAgFilter === 'scenarioRecentlyViewed' && ag.scenarioRecentlyViewed === 1) || 
                            (dashAgFilter === 'scenarioNextActionWithUs' && ag.scenarioNextActionWithUs === 1) || (dashAgFilter === 'scenarioWaitingForCpty' && ag.scenarioWaitingForCpty === 1) ||
                            (dashAgFilter === 'scenarioPendingSignature' && ag.scenarioPendingSignature === 1))
                          .slice(0,4)
                          .map((ag, i) => (
                          //<Grid item xs={1} key={ag._id} style={{padding: '0px 6px 0px 6px'}}>
                          <Grid /*container direction="row"*/ item className={classes.gridForThumb} xs={6} md={3} key={i}>
                          {
                          <Box mb={1}>
                          <Typography variant="body2" color="textPrimary" align="center">
                            {dashAgFilter === 'scenarioRecentlyViewed' ||
                            (dashAgFilter === null && ag.scenarioRecentlyViewed && (i === 0 || i > 3 || (ag.scenarioNextActionWithUs === 0 && ag.scenarioWaitingForCpty === 0 && ag.scenarioPendingSignature === 0))) ? 
                                <Button className={classes.miniButton} onClick={e => setDashAgFilter(dashAgFilter === null ? 'scenarioRecentlyViewed' : null)}>Recently viewed</Button> :
                            dashAgFilter === 'scenarioNextActionWithUs' ||
                            (dashAgFilter === null && ag.scenarioNextActionWithUs) ? 
                                <Button className={classes.miniButton} onClick={e => setDashAgFilter(dashAgFilter === null ? 'scenarioNextActionWithUs' : null)}>Pending our action</Button> :
                            dashAgFilter === 'scenarioWaitingForCpty' ||
                            (dashAgFilter === null && ag.scenarioWaitingForCpty) ? 
                                <Button className={classes.miniButton} onClick={e => setDashAgFilter(dashAgFilter === null ? 'scenarioWaitingForCpty' : null)}>Waiting for counterparty</Button> :
                            dashAgFilter === 'scenarioPendingSignature' ||
                            (dashAgFilter === null && ag.scenarioPendingSignature) ? 
                                <Button className={classes.miniButton} onClick={e => setDashAgFilter(dashAgFilter === null ? 'scenarioPendingSignature' : null)}>Pending signature(s)</Button> :
                                <Button className={classes.miniButton} onClick={null}>Recently Updated</Button>}
                          </Typography>
                          </Box>
                          }

                          <ThumbAgr 
                          fromDashboard={true}
                          ag={ag}
                          path={"/core/" + props.module + "/agreement/" + ag.relID + "/" + ag._id}
                          cptyLogo={
                            props.org !== undefined && props.org.clientType === 'legalOrg' && props.subs.filter((s) => s._id === ag.primaryCptyID || s._id === ag.secondaryCptyID)[0] !== undefined ? 
                              props.subs.filter((s) => s._id === ag.primaryCptyID || s._id === ag.secondaryCptyID)[0].logoURL :
                            props.cpents.filter((e) => e._id === ag.primaryCptyID || e._id === ag.secondaryCptyID)[0] !== undefined ?
                              props.cpents.filter((e) => e._id === ag.primaryCptyID || e._id === ag.secondaryCptyID)[0].logoURL : 
                              props.module === 'people' ? defaultUser : defaultLogo}
                          primaryLegalName={
                            props.subs.filter((s) => s._id === ag.primaryCptyID)[0] !== undefined ? 
                              props.subs.filter((s) => s._id === ag.primaryCptyID)[0].legalName : 
                            props.cpents.filter((e) => e._id === ag.primaryCptyID)[0] !== undefined ?
                              props.cpents.filter((e) => e._id === ag.primaryCptyID)[0].legalName : "Unknown Entity"}
                          secondaryLegalName={
                            props.subs.filter((s) => s._id === ag.secondaryCptyID)[0] !== undefined ? 
                              props.subs.filter((s) => s._id === ag.secondaryCptyID)[0].legalName : 
                            props.cpents.filter((e) => e._id === ag.secondaryCptyID)[0] !== undefined ?
                              props.cpents.filter((e) => e._id === ag.secondaryCptyID)[0].legalName : "Unknown Entity"}
                          actionReq={((['Draft', 'Review', 'Negotiation'].includes(ag.agrStatus) && (
                            (props.subs.filter((s) => s._id === ag.primaryCptyID)[0] !== undefined && ag.curAVOwner === ag.primaryCptyID) ||
                            (props.subs.filter((s) => s._id === ag.secondaryCptyID)[0] !== undefined && ag.curAVOwner === ag.secondaryCptyID)))) ||
                            ['Execution'].includes(ag.agrStatus)}
                          hideDot={(props.module === 'sales' && props.userSession.dashboardVisits.sales > 0) ||
                            (props.module === 'procurement' && props.userSession.dashboardVisits.procurement > 0) ||
                            (props.module === 'partnerships' && props.userSession.dashboardVisits.partnerships > 0) ||
                            (props.module === 'corporate' && props.userSession.dashboardVisits.corporate > 0) ||
                            (props.module === 'people' && props.userSession.dashboardVisits.people > 0) ||
                            (props.module === 'counterparty' && props.userSession.dashboardVisits.counterparty > 0) ||
                            (props.user.lastLoginDate > ag.lastUpdateDate)}
                        />
                        </Grid>
                        ))}
                </Grid>
                </Box>
              </Grid>

              <Grid item>

                <Box mt={3}>

                <Grid container direction="row" className={classes.thumbContainer} align="center">
                  <Grid item className={classes.gridForThumb} xs={6} md={3}>

                    <Card variant="outlined" className={classes.dashCardHover}>
                      <CardContent className={classes.dashCardContent}>
                        <Box mb={1}>
                        <Typography variant="subtitle2" color="textPrimary" gutterBottom>Recently updated</Typography>
                        </Box>
                        <List dense>
                          {relevantRels
                          .sort((a,b) => (a.lastUpdateDate < b.lastUpdateDate) ? 1 : ((b.lastUpdateDate < a.lastUpdateDate) ? -1 : 0))
                          .slice(0,4)
                          .map((r) => (
                            <ListItem key={r._id} button onClick={e => history.push('/core/' + props.module + '/relationship/' + r._id + "/overview")}>
                              <Grid container direction="row" alignItems="center" justify="flex-start">
                                <Grid item>
                                <StyledDot color="secondary" variant="dot" anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                invisible={r.lastUpdateDate < props.user.lastLoginDate || dashboardAlreadyVisited}>
                                <Avatar className={classes.miniAvatar}
                                src={
                                  r.primaryOrgID === props.user.orgID && props.org.clientType === 'legalOrg' ?
                                    props.subs.filter((s) => s._id === r.primaryCptyID)[0].logoURL :
                                  r.secondaryOrgID === props.user.orgID && props.org.clientType === 'legalOrg' ?
                                    props.subs.filter((s) => s._id === r.secondaryCptyID)[0].logoURL : 
                                  r.primaryOrgID === props.user.orgID && props.org.clientType !== 'legalOrg' && props.cpents.filter((c) => c._id === r.secondaryCptyID)[0] !== undefined ? 
                                    props.cpents.filter((c) => c._id === r.secondaryCptyID)[0].logoURL :
                                  r.secondaryOrgID === props.user.orgID && props.org.clientType !== 'legalOrg' && props.cpents.filter((c) => c._id === r.primaryCptyID)[0] !== undefined ? 
                                    props.cpents.filter((c) => c._id === r.primaryCptyID)[0].logoURL :
                                  props.module === 'people' ? defaultUser : defaultLogo} />
                                </StyledDot>
                                </Grid>
                                <Grid item>
                                  <Box display={{ xs: 'none', md: 'block' }}>
                                  <Typography variant="body2">{
                                  trunc(r.primaryOrgID === props.user.orgID ?
                                        r.name.substring(r.name.indexOf('|') + 2) :
                                        r.name.substring(0, r.name.indexOf('|') - 1), 14)}</Typography>
                                  </Box>
                                  <Box display={{ xs: 'block', md: 'none' }}>
                                  <Typography variant="body2">{
                                  trunc(r.primaryOrgID === props.user.orgID ?
                                        r.name.substring(r.name.indexOf('|') + 2) :
                                        r.name.substring(0, r.name.indexOf('|') - 1), 4)}</Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </ListItem>
                          ))}
                          
                        </List>

                      </CardContent>
                    </Card>

                  </Grid>
                  <Grid item className={classes.gridForThumb} xs={6} md={3}>

                    <StyledDotRight color="secondary" variant="dot" 
                    invisible={relevantTickets.filter((t) => t.lastUpdateDate > props.user.lastLoginDate)[0] === undefined || dashboardAlreadyVisited}>
                    <Card variant="outlined" className={classes.dashCardHover}>
                      <CardContent className={classes.dashCardContent}>
                        <Box mb={1}>
                        <Typography variant="subtitle2" color="textPrimary">Open Tasks</Typography>
                        </Box>
                        <Box align="center">
                          {relevantTickets.length > 0 ?
                          <>
                          <Typography className={classes.ticketLength}>{relevantTickets.length}</Typography>
                          <Box mt={0}>
                            <Button color="secondary" size="small" variant="text" onClick={e => history.push('/core/' + props.module + '/tasks/approvals')}>
                              {relevantTickets.filter((t) => t.ticketType === 'Approval').length} Approvals
                            </Button>
                            <Button color="secondary" size="small" variant="text" onClick={e => history.push('/core/' + props.module + '/tasks/reviews')}>
                              {relevantTickets.filter((t) => t.ticketType === 'AgrReviewInternal').length} Reviews
                            </Button>
                          </Box>
                          </>
                          :
                          <Box align="center">
                          <FontAwesomeIcon icon={faThumbsUp} className={classes.doneIcon} />
                          <Typography color="textSecondary" variant="body2" className={classes.doneFont}>No pending tasks</Typography>
                          </Box> 
                          }
                        </Box>
                      </CardContent>
                    </Card>
                    </StyledDotRight>

                    

                  </Grid>
                  <Grid item className={classes.gridForThumb} xs={6} md={3}>

                    <Card variant="outlined" className={classes.dashCard}>
                      <CardContent className={classes.dashCardContent}>
                        <Box mb={1}>
                        <Typography variant="subtitle2" color="textPrimary">New Agreements</Typography>
                        </Box>
                        <Box align="center" className={classes.padTopOnXS}>
                          {
                          <Bar 
                            width={150}
                            height={140}
                            options={{
                              scales: { x: { stacked: true }, y: { stacked: true, ticks: { stepSize: 1 } } },
                              plugins: { legend: { display: false }, datalabels: { display: false } },
                            }}
                            data={{
                                labels: agrMetrics.agrsByMonth.labels,
                                datasets: [
                                  {
                                    label: 'In Progress',
                                    backgroundColor: 'rgba(232,0,100,1)',
                                    borderWidth: 0,
                                    data: agrMetrics.agrsByMonth.progress
                                  },
                                  {
                                    label: 'In Force',
                                    backgroundColor: 'rgba(68,175,105)',
                                    borderWidth: 0,
                                    data: agrMetrics.agrsByMonth.done
                                  }
                                ]}}
                            />
                            }
                        </Box>
                      </CardContent>
                    </Card>

                  </Grid>
                  <Grid item className={classes.gridForThumb} xs={6} md={3}>

                    <Card variant="outlined" className={classes.dashCard}>
                      <CardContent className={classes.dashCardContent}>
                        <Box mb={1}>
                        <Typography variant="subtitle2" color="textPrimary">Insights</Typography>
                        </Box>
                        <Box align="center">

                          <Typography variant="subtitle2" className={classes.insight}>{agrMetrics.avgCycleTime} days</Typography>
                          <Typography variant="body2" className={classes.insightDesc}>Create-to-Close <span className={classes.hideOnXs}>average</span><span className={classes.showXS}>avg.</span></Typography>

                          <Typography variant="subtitle2" className={classes.insight}>{agrMetrics.doneRatio}%</Typography>
                          <Typography variant="body2" className={classes.insightDesc}>Created gets closed</Typography>

                          <Typography variant="subtitle2" className={classes.insight}>{agrMetrics.fourWeeksRatio}%</Typography>
                          <Typography variant="body2" className={classes.insightDesc}>Takes over 4 weeks </Typography>

                        </Box>
                      </CardContent>
                    </Card>

                  </Grid>

                </Grid>
                </Box>

              </Grid>
              </>
              }
            </>  
          }
          </>
          // End of subscriber users
          : // Counterparty users
          <>
            <Box align="center" mt={8} pb={8}>
              <RenderRelevantRels />
              {relevantRels.length > 4 ?
              <Box align="right" mt={2} mr={1} ml={1}>
                <Button onClick={e=> setRelSliceStart(relSliceStart - 1)} color="primary"
                disabled={relSliceStart === 0}
                ><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Back</Button>
                <Button onClick={e=> setRelSliceStart(relSliceStart + 1)} color="primary"
                disabled={relSliceStart + 4 >= relevantRels.length}
                >Next&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} /></Button>
              </Box>
              : '' }
            </Box>
            
            <Box align="center" mb={8}>
              <Grid container spacing={0} className={classes.thumbContainer}>
              {relevantTickets
                .sort((a,b) => (a.lastUpdateDate < b.lastUpdateDate) ? 1 : ((b.lastUpdateDate < a.lastUpdateDate) ? -1 : 0))
                .slice(0, 4)
                .map((t, i) => (
                  <Grid item className={classes.gridForThumb} key={i}
                  xs={relevantTickets.length === 1 ? 12 : 6} 
                  sm={relevantTickets.length > 3 ? 3 : relevantTickets.length === 3 ? 4 : relevantTickets.length === 2 ? 6 : 3}>

                  <ThumbTodo
                    dash={true}
                    route={'/core/counterparty/agreement/' + t.relID + '/' + t.agrID + '/' + t.clauseID}
                    icon={getAppropriateIcon('ticket', t.ticketType)}
                    line1={relevantTickets.ticketType === 'AgrReviewInternal' ? 'Review Request' : 'Approval request'} 
                    line2={t.topic.split('|')[3] !== undefined ? trunc(t.topic.split('|')[3].trim(),23) : trunc(t.topic, 23)} />

                  </Grid>
                ))
              }
              </Grid>
            </Box>
          </>
          }
        </React.Fragment>
        )}

        </Grid>
        </Container>
    </div>
  );
}

export default Dashboard;