import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { getDefaultModule } from './utils'

import { Contact, Error, Network, SignUp } from './views-open'
import { Act, CreatePassword, Help, Login, Logout, Settings } from './views-core'
import { Company, UserMgt } from './views-core/admin';
import { Certifications, Hub, Playbook, Templates } from './views-core/legal';
import { Agreement, Analytics, Dashboard, Masterdata, New, Relationships, Relationship, Tasks } from './views-core/b2b';

import { Certs, CustomLink, Oat, Profile, Terms } from './views-network';

//import TimeMe from "timeme.js";
import { connect } from 'react-redux';
import { createRelWithAgreements, updateRel, updateRelName, // Relationships
         updateOrg, updateLogoForOrg, enableHubForOrg, fetchOrgProfileByURL, fetchNetwork, updateOrgLevel, 
         createOrgLabel, updateOrgLabel, deleteOrgLabel, // Org, OrgProfile
         createEntity, fetchCpentsByOrgID, addEntityToReducer, updateEntityInReducer, updateEntity, createCert, updateCert, deleteCert, // Entity, Certification
         fetchLoggedInUser, logoutUser, updateUser, createUser, deleteUser, updateUserRole, updateIntroComplete, fetchUsersByOrgID, updateAidForUserAgrIDs, // User
         createAgr, updateAgr, updateAgrUsers, updateAgrPriority, updateAgrNames, updateAgrStatus, createAgrExec, createAgrType, 
         deleteAgrType, updateAgrType, // Agreement, AgrExec
         createAgrVersion, createAgrVersionWithImport, updateAgrvMarkAsEdited, updateAgrvNewClauses, updateAgrvRemoveClause, // AgrVersion
         updateClauseStatus, updateClauseContentFAF, updatePendingChange, updateClauseNumbering, createComment, updateComment, // Clauses, Comments
         updateTicket, lastUpdateTicket, // Tickets
         toggleExternal, toggleOatActivation, createOrgAgrTemplate, updateOATNewClauses, updateOatNewDefaultChildren,
         updateOATDeletion, updateOatReference, updateOatURL, updateOatEffectiveProperties, updateOatAgrType, updateOATRemoveClause, 
         updateOatNames, addAgrType, updateSmartField, // Oats, Params
         createActivityLog, createNotification, markNotificationAsRead, markAllNotificationsAsRead, pushTime, // Notifications & Logging
         increaseDashboardVisits, defineSessionExpiry, defineSmartMode, toggleOatDraftFor } from './redux/actions';

const axios = require('axios');

const mapStateToProps = state => {
  return {
    activityLog: state.activityLog,
    agrExec: state.agrExec,
    agrTypes: state.agrTypes,
    certs: state.certs,
    cpents: state.cpents,
    network: state.network,
    notifications: state.notifications,
    org: state.org,
    orgAgrTemplates: state.orgAgrTemplates,
    orgLabel: state.orgLabel,
    orgProfile: state.orgProfile,
    orgProfileCerts: state.orgProfileCerts,
    orgProfileAgrTemplates: state.orgProfileAgrTemplates,
    orgProfileSubs: state.orgProfileSubs,
    rels: state.rels,
    subs: state.subs,
    user: state.user,
    users: state.users,
    userSession: state.userSession,
  }
}

const mapDispatchToProps = dispatch => ({ 
    // Users
    fetchLoggedInUser: () => dispatch(fetchLoggedInUser()),
    logoutUser: (uid) => dispatch(logoutUser(uid)),
    updateUser: (uid, firstName, lastName, title, phone, photoURL, defaultModule, agrIDs) => dispatch(updateUser(uid, firstName, lastName, title, phone, photoURL, defaultModule, agrIDs)),
    createUser: (creationBy, orgid, firstName, lastName, title, email, password, role, defaultModule, readOnly, photoURL, agrIDs, orgShortName, adminDisplayName) => dispatch(createUser(creationBy, orgid, firstName, lastName, title, email, password, role, defaultModule, readOnly, photoURL, agrIDs, orgShortName, adminDisplayName)),
    deleteUser: (uid, deletedBy, orgid, faf) => dispatch(deleteUser(uid, deletedBy, orgid, faf)),
    updateUserRole: (uid, orgid, newRole, defaultModule, readOnly, title, faf) => dispatch(updateUserRole(uid, orgid, newRole, defaultModule, readOnly, title, faf)),
    updateIntroComplete: (uid, introComplete,) => dispatch(updateIntroComplete(uid, introComplete)),
    fetchUsersByOrgID: (orgID) => dispatch(fetchUsersByOrgID(orgID)),
    updateAidForUserAgrIDs: (type, uid, aid) => dispatch(updateAidForUserAgrIDs(type, uid, aid)),
    // Relationships
    createRelWithAgreements: (relationshipType, relName, customURL, primaryCptyID, secondaryCptyID, tertiaryCptyID, curAVOwner, contributors, signatories, primaryOrgID, secondaryOrgID, invisibleFor, creationBy, deactive, deactiveReason, whiteLabel, mainAgr, exhibits) =>
      dispatch(createRelWithAgreements(relationshipType, relName, customURL, primaryCptyID, secondaryCptyID, tertiaryCptyID, curAVOwner, contributors, signatories, primaryOrgID, secondaryOrgID, invisibleFor, creationBy, deactive, deactiveReason, whiteLabel, mainAgr, exhibits)),
    updateRel: (rid, orgid, primaryCptyID, secondaryOrgID, invisibleFor, lastUpdateBy, deactive, deactiveReason) => dispatch(updateRel(rid, orgid, primaryCptyID, secondaryOrgID, invisibleFor, lastUpdateBy, deactive, deactiveReason)),
    updateRelName: (rid, name) => dispatch(updateRelName(rid, name)),
    // Agreements
    createAgr: (rid, agrTypeID, primaryCptyID, secondaryCptyID, tertiaryCptyID, contributors, signatories, agrStatus, curAVOwner, avv, sourceFileID, agrFullName, agrShortName, parentAID, effectiveDate, renewalType, effectiveTerm, expiryDate, agrPartType, priority, linkedToAID, amendment, creationBy, templateClauses, importedDoc, exhibits, activate, smartFieldObject) => 
      dispatch(createAgr(rid, agrTypeID, primaryCptyID, secondaryCptyID, tertiaryCptyID, contributors, signatories, agrStatus, curAVOwner, avv, sourceFileID, agrFullName, agrShortName, parentAID, effectiveDate, renewalType, effectiveTerm, expiryDate, agrPartType, priority, linkedToAID, amendment, creationBy, templateClauses, importedDoc, exhibits, activate, smartFieldObject)),
    updateAgr: (rid, aid, primaryCptyID, secondaryCptyID, agrStatus, curAVOwner, sourceFileID, contributors, signatories, lastUpdateBy) => dispatch(updateAgr(rid, aid, primaryCptyID, secondaryCptyID, agrStatus, curAVOwner, sourceFileID, contributors, signatories, lastUpdateBy)),
    updateAgrUsers: (aid, contributors, signatories) => dispatch(updateAgrUsers(aid, contributors, signatories)),
    updateAgrPriority: (aid, newPriority, lastUpdateBy) => dispatch(updateAgrPriority(aid, newPriority, lastUpdateBy)),
    updateAgrNames: (aid, agrFullName, agrShortName, lastUpdateBy) => dispatch(updateAgrNames(aid, agrFullName, agrShortName, lastUpdateBy)),
    updateAgrStatus: (aid, agrStatus, lastUpdateBy) => dispatch(updateAgrStatus(aid, agrStatus, lastUpdateBy)),
    createAgrExec: (relID, agrID, agrTypeID, agrVersion, primaryCpty, secondaryCpty, content, signatories, signatoryRequestID, pdfUnsignedURL, pdfSignedURL, signMethod) =>
      dispatch(createAgrExec(relID, agrID, agrTypeID, agrVersion, primaryCpty, secondaryCpty, content, signatories, signatoryRequestID, pdfUnsignedURL, pdfSignedURL, signMethod)),
    createAgrType: (relationshipType, shortName, fullName, agrPartType, orgID) => dispatch(createAgrType(relationshipType, shortName, fullName, agrPartType, orgID)),
    updateAgrType: (atid, relationshipType, shortName, fullName, agrPartType) => dispatch(updateAgrType(atid, relationshipType, shortName, fullName, agrPartType)),
    deleteAgrType: (atid) => dispatch(deleteAgrType(atid)),
    // Orgs
    updateOrg: (orgid, legalName, logoURL, url, phone, email, notifyUsers, whiteLabel, orgSettings, integrations) => dispatch(updateOrg(orgid, legalName, logoURL, url, phone, email, notifyUsers, whiteLabel, orgSettings, integrations)),
    updateLogoForOrg: (orgid, logoURL) => dispatch(updateLogoForOrg(orgid, logoURL)),
    enableHubForOrg: (orgid, hubParticipant) => dispatch(enableHubForOrg(orgid, hubParticipant)),
    fetchOrgProfileByURL: (orgurl, withTemplates) => dispatch(fetchOrgProfileByURL(orgurl, withTemplates)),
    fetchNetwork: () => dispatch(fetchNetwork()),
    updateOrgLevel: (orgID, clientType, modules) => dispatch(updateOrgLevel(orgID, clientType, modules)),
    createOrgLabel: (orgID, name, desc, modules, deactive, creationBy, applyTo) => dispatch(createOrgLabel(orgID, name, desc, modules, deactive, creationBy, applyTo)),
    updateOrgLabel: (olid, name, desc, modules, deactive, lastUpdateBy) => dispatch(updateOrgLabel(olid, name, desc, modules, deactive, lastUpdateBy)),
    deleteOrgLabel: (olid) => dispatch(deleteOrgLabel(olid)),
    // Entities
    createEntity: (orgid, parentOrgID, entityType, entityCategory, shortName, legalName, address, logoURL, primaryContact, signatory, sourceData, creationBy, faf) => dispatch(createEntity(orgid, parentOrgID, entityType, entityCategory, shortName, legalName, address, logoURL, primaryContact, signatory, sourceData, creationBy, faf)),
    updateEntity: (entid, orgid, parentOrgID, entityType, shortName, legalName, streetAndNo, city, zip, country, state, logoURL, primaryContact, signatory, isLive, lastUpdateBy) => dispatch(updateEntity(entid, orgid, parentOrgID, entityType, shortName, legalName, streetAndNo, city, zip, country, state, logoURL, primaryContact, signatory, isLive, lastUpdateBy)),
    addEntityToReducer: (entityType, entity) => dispatch(addEntityToReducer(entityType, entity)),
    updateEntityInReducer: (entityType, entity) => dispatch(updateEntityInReducer(entityType, entity)),
    fetchCpentsByOrgID: (orgID) => dispatch(fetchCpentsByOrgID(orgID)),
    // Agreement Versions
    createAgrVersion: (aid, version, owner, clauses, attachment, primaryReady, secondaryReady, duplicateClauses, freshAg, editMode) => dispatch(createAgrVersion(aid, version, owner, clauses, attachment, primaryReady, secondaryReady, duplicateClauses, freshAg, editMode)),
    createAgrVersionWithImport: (aid, avv, owner, primaryReady, secondaryReady, isEdited, editMode, iDoc, creationBy) => dispatch(createAgrVersionWithImport(aid, avv, owner, primaryReady, secondaryReady, isEdited, editMode, iDoc, creationBy)),
    updateAgrvNewClauses: (avid, newClauses, history, path) => dispatch(updateAgrvNewClauses(avid, newClauses, history, path)),
    updateAgrvRemoveClause: (avid, clid, returnAV) => dispatch(updateAgrvRemoveClause(avid, clid, returnAV)),
    updateAgrvMarkAsEdited: (avid, isEdited, primaryReady, secondaryReady) => dispatch(updateAgrvMarkAsEdited(avid, isEdited, primaryReady, secondaryReady)),
    // Clauses 
    updateClauseStatus: (clOrSclID, type, newStatus, lastUpdateBy) => dispatch(updateClauseStatus(clOrSclID, type, newStatus, lastUpdateBy)),
    updatePendingChange: (clid, pendingChange, clauseStatus, /*title,*/ clauseObject, lastUpdateBy) => dispatch(updatePendingChange(clid, pendingChange, clauseStatus, /*title,*/ clauseObject, lastUpdateBy)),
    updateClauseNumbering: (clid, numberType, numberManual, lastUpdateBy) => dispatch(updateClauseNumbering(clid, numberType, numberManual, lastUpdateBy)),
    updateClauseContentFAF: (clid, clauseStatus, blocks, plain, clauseObject, version, lastUpdateBy) => dispatch(updateClauseContentFAF(clid, clauseStatus, blocks, plain, clauseObject, version, lastUpdateBy)),
    // Comments / Tickets / Notifications
    createComment: (faf, creatorID, creatorDisplayName, orgID, entityID, comment, commentType, agrID, singleClauseID, ticketID, relID) => dispatch(createComment(faf, creatorID, creatorDisplayName, orgID, entityID, comment, commentType, agrID, singleClauseID, ticketID, relID)),
    updateComment: (cid, comment, blocks, lastUpdateBy) => dispatch(updateComment(cid, comment, blocks, lastUpdateBy)),
    updateTicket: (faf, tid, module, ticketStatus, topic, assignee, lastUpdateBy, lastUpdateDate, closedBy, closedDate, resolveReason, workflow) => dispatch(updateTicket(faf, tid, module, ticketStatus, topic, assignee, lastUpdateBy, lastUpdateDate, closedBy, closedDate, resolveReason, workflow)),
    lastUpdateTicket: (tid, lastUpdateBy) => dispatch(lastUpdateTicket(tid, lastUpdateBy)),
    createNotification: (notificationType, title, description, linkTo, isRead, uid) => dispatch(createNotification(notificationType, title, description, linkTo, isRead, uid)),
    markNotificationAsRead: (nid) => dispatch(markNotificationAsRead(nid)),
    markAllNotificationsAsRead: (uid) => dispatch(markAllNotificationsAsRead(uid)),
    pushTime: (orgID, uid, rid, aid, time, displayName, module, cpmodule, cptyType) => dispatch(pushTime(orgID, uid, rid, aid, time, displayName, module, cpmodule, cptyType)),
    // Activity Log
    createActivityLog: (logLevel, logType, logDesc, orgID, entID, relID, agrID, singleClauseID, ticketID, creationBy, isInternal, keyVal1, keyVal2, change) => 
      dispatch(createActivityLog(logLevel, logType, logDesc, orgID, entID, relID, agrID, singleClauseID, ticketID, creationBy, isInternal, keyVal1, keyVal2, change)),
    // OrgAgrTemplates, Params
    addAgrType: (newAT) => dispatch(addAgrType(newAT)),
    toggleExternal: (oatid, newExternal, draftFor, lastUpdateBy) => dispatch(toggleExternal(oatid, newExternal, draftFor, lastUpdateBy)),
    toggleOatDraftFor: (oatid, newDraftFor, lastUpdateBy) => dispatch(toggleOatDraftFor(oatid, newDraftFor, lastUpdateBy)),
    toggleOatActivation: (oatid, newActive, draftFor, lastUpdateBy) => dispatch(toggleOatActivation(oatid, newActive, draftFor, lastUpdateBy)),
    createOrgAgrTemplate: (module, blueprintID, defaultChildren, language, agrFullName, agrShortName, reference, atid, orgID, isNew, duplicateClauses, clauses, external, fileSourceID, active, draftFor, oaturl, effectiveDateType, renewalType, effectiveTerm, version, creationBy, importedDoc, oatIDtoCopySmartFields, oldOatIDs, history, path) => 
      dispatch(createOrgAgrTemplate(module, blueprintID, defaultChildren, language, agrFullName, agrShortName, reference, atid, orgID, isNew, duplicateClauses, clauses, external, fileSourceID, active, draftFor, oaturl, effectiveDateType, renewalType, effectiveTerm, version, creationBy, importedDoc, oatIDtoCopySmartFields, oldOatIDs, history, path)),
    updateOATNewClauses: (oatid, newClauses, history, path) => dispatch(updateOATNewClauses(oatid, newClauses, history, path)),
    updateOatNewDefaultChildren: (oatid, newDefaultChildren, faf) => dispatch(updateOatNewDefaultChildren(oatid, newDefaultChildren, faf)),
    updateOATRemoveClause: (oatid, clid) => dispatch(updateOATRemoveClause(oatid, clid)),
    updateOatNames: (oatid, agrFullName, agrShortName, lastUpdateBy) => dispatch(updateOatNames(oatid, agrFullName, agrShortName, lastUpdateBy)),
    updateOATDeletion: (oatid, newOrgID, creationBy) => dispatch(updateOATDeletion(oatid, newOrgID, creationBy)),
    updateOatReference: (oatid, reference, lastUpdateBy) => dispatch(updateOatReference(oatid, reference, lastUpdateBy)),
    updateOatURL: (oatid, oaturl, lastUpdateBy) => dispatch(updateOatURL(oatid, oaturl, lastUpdateBy)),
    updateOatEffectiveProperties: (oatid, effectiveDateType, renewalType, effectiveTerm, lastUpdateBy) => dispatch(updateOatEffectiveProperties(oatid, effectiveDateType, renewalType, effectiveTerm, lastUpdateBy)),
    updateOatAgrType: (oatid, agrTypeID, agrFullName, agrShortName, reference, lastUpdateBy) => dispatch(updateOatAgrType(oatid, agrTypeID, agrFullName, agrShortName, reference, lastUpdateBy)),
    updateSmartField: (sfid, osfid, ref, name, type, val1, val2, val3, createWizard, lastUpdateBy) => dispatch(updateSmartField(sfid, osfid, ref, name, type, val1, val2, val3, createWizard, lastUpdateBy)),
    //addOrgSmartField: (osf) => dispatch(addOrgSmartField(osf)),
    // Certs
    createCert: (title, description, logoURL, referenceURL, sinceDate, orgID, creationBy) => dispatch(createCert(title, description, logoURL, referenceURL, sinceDate, orgID, creationBy)),
    updateCert: (certid, title, description, logoURL, referenceURL, sinceDate, lastUpdateBy) => dispatch(updateCert(certid, title, description, logoURL, referenceURL, sinceDate, lastUpdateBy)),
    deleteCert: (certid) => dispatch(deleteCert(certid)),
    // UserSession
    increaseDashboardVisits: (module) => dispatch(increaseDashboardVisits(module)),
    defineSessionExpiry: (expiry) => dispatch(defineSessionExpiry(expiry)),
    defineSmartMode: (smartMode) => dispatch(defineSmartMode(smartMode)),
});

class Main extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
    let path = this.props.location.pathname

    this.props.fetchLoggedInUser(); // Always load User and subsequent Org data
    this.props.fetchNetwork() // Always load the network

    // CT NETWORK SECTION ****************
    let res = path.split("/");
    if(path.substr(0,9) === '/network/' && res.length < 6){ // You're in the Network ORG section
      let orgurl = path.substr(9).indexOf('/') < 0 ? path.substr(9) : path.substr(9, path.substr(9).indexOf('/'));
      this.props.fetchOrgProfileByURL(orgurl, true); // Get OrgProfile by orgurl (2nd param is "withTemplates")
    }
  }

  componentDidUpdate(prevProps) { // componentDidUpdate
    let path = this.props.location.pathname
    let ppsModule = path.startsWith('/core/sales/') ? 12 : path.startsWith('/core/procurement/') ? 18 : 
                    path.startsWith('/core/people/') ? 13 : path.startsWith('/core/corporate/') ? 16 : 
                    path.startsWith('/core/partnerships/') ? 19 : path.startsWith('/core/counterparty/') ? 19 : 0;
    let prevPath = prevProps.location.pathname
    let prevPpsModule = prevPath.startsWith('/core/sales/') ? 12 : prevPath.startsWith('/core/procurement/') ? 18 : 
                        prevPath.startsWith('/core/people/') ? 13 : prevPath.startsWith('/core/corporate/') ? 16 :
                        prevPath.startsWith('/core/partnerships/') ? 19 : prevPath.startsWith('/core/counterparty/') ? 19 : 0;

    if(path.startsWith('/home')) { window.location.assign('https://getcanveo.com/'); }

    // SECURITY REROUTING SECTION
    if(this.props.userSession !== undefined && this.props.userSession.expiry !== null && Date.now() > this.props.userSession.expiry) { 
      this.props.defineSessionExpiry(null)
      this.props.logoutUser(this.props.user.user._id);
      this.props.history.push({ pathname: '/core/login' })
    } else if((path.substr(0,12) === '/core/logout' || path.substr(0,15) === '/core/logout/cp') && prevPath.substr(0,12) !== '/core/logout' && prevPath.substr(0,15) !== '/core/logout/cp') { // The user is logging out
      this.props.logoutUser(this.props.user.user._id);
    } else if (path.substr(0,6) === '/core/' && path.substr(0,12) !== '/core/logout' && path.substr(0,11) !== '/core/login' && path.substr(0,9) !== '/core/act' && path.substr(0,20) !== '/core/createpassword' &&
      (this.props.user.user === undefined || this.props.user.user.email === undefined || !this.props.user.user.active) && !this.props.user.pending){
      // The user is inside core and should be logged in, HOWEVER, the user isn't logged in and no longer loading
      this.props.history.push({ pathname: '/core/login' })
    } else if (path.substr(0,11) === '/core/admin' && this.props.user.user.email !== undefined && this.props.user.user.role !== 'Admin') {
      // The user is inside /core/admin and logged in but user role is NOT 'Admin'
      this.props.history.push({ pathname: '/core/settings' })
    } else if (path.substr(0,11) === '/core/legal' && this.props.user.user.email !== undefined && !['Admin', 'Legal'].includes(this.props.user.user.role)) {
      // The user is inside /core/legal and logged in but user role is NOT 'Admin' or 'Legal'
      this.props.history.push({ pathname: '/core/settings' })
    } else if (path.substr(0,11) === '/core/sales' && this.props.user.user.email !== undefined && !['Admin', 'Legal', 'Business', 'Sales'].includes(this.props.user.user.role)) {
      // The user is inside /core/sales and logged in but user role is NOT enough
      this.props.history.push({ pathname: '/core/settings' })
    } else if (path.substr(0,17) === '/core/procurement' && this.props.user.user.email !== undefined && !['Admin', 'Legal', 'Business', 'Procurement'].includes(this.props.user.user.role)) {
      // The user is inside /core/sales and logged in but user role is NOT enough
      this.props.history.push({ pathname: '/core/settings' })
    } else if (path.substr(0,18) === '/core/partnerships' && this.props.user.user.email !== undefined && !['Admin', 'Legal', 'Business', 'Partnerships'].includes(this.props.user.user.role)) {
      // The user is inside /core/sales and logged in but user role is NOT enough
      this.props.history.push({ pathname: '/core/settings' })
    } else if (path.substr(0,12) === '/core/people' && this.props.user.user.email !== undefined && !['Admin', 'Legal', 'HR'].includes(this.props.user.user.role)) {
      // The user is inside /core/people and logged in but user role is NOT enough
      this.props.history.push({ pathname: '/core/settings' })
    } else if (path.substr(0,12) === '/core/corporate' && this.props.user.user.email !== undefined && !['Admin', 'Legal', 'Corporate'].includes(this.props.user.user.role)) {
      // The user is inside /core/people and logged in but user role is NOT enough
      this.props.history.push({ pathname: '/core/settings' })
    } else if (this.props.user.user.email !== undefined && this.props.user.user.role === 'Counterparty' && 
      (path.substr(0,12) === '/core/sales/' || path.substr(0,18) === '/core/procurement/' || path.substr(0,19) === '/core/partnerships/' ||
       path.substr(0,13) === '/core/people/' || path.substr(0,16) === '/core/corporate/')) {
      // The user is logged in as a counterparty user but accessing a subscriber-only route
      this.props.history.push({ pathname: '/core/counterparty/dashboard' })
    } else if((this.props.user.user.email !== undefined && this.props.user.user.role !== 'Counterparty' && !this.props.user.user.role.startsWith('ClientFor_')) && 
      path.substr(0,18) === '/core/counterparty'){
      // The user is logged in as a Non-Cpty but accessing a Cpty route
      this.props.history.push({ pathname: '/core/settings' })
    } else if((path === '/' || (path.substr(0,11) === '/core/login' && path.substr(0,12) !== '/core/loginp')) && this.props.user !== undefined && this.props.user.user !== undefined && this.props.user.user.role !== undefined &&
      this.props.org !== undefined && this.props.org.org !== undefined && this.props.org.org.clientType !== undefined && 
      (this.props.userSession !== undefined && this.props.userSession.expiry !== null && Date.now() < this.props.userSession.expiry)) {
      // The user is at the root / or at /core/login but the user is already logged in - reroute to appropriate dashboard
      let newpath = "/core/" + getDefaultModule(this.props.org.org, this.props.user.user) + "/dashboard"
      this.props.history.push({ pathname: newpath })
    }

    if(path.substr(0,9) === '/network/'){ // You're in the Network ORG section
      let orgurl = path.substr(9).indexOf('/') < 0 ? path.substr(9) : path.substr(9, path.substr(9).indexOf('/'));
      let prevorgurl = prevPath.substr(9).indexOf('/') < 0 ? prevPath.substr(9) : prevPath.substr(9, prevPath.substr(9).indexOf('/'));
      if (prevPath.substr(0,9) !== '/network/' || // moving into NETWORK from somewhere else - load orgProfile withTemplates (true)
         (prevPath.substr(0,9) === '/network/' && orgurl !== prevorgurl)) { // staying in Network but changing orgurl
        this.props.fetchOrgProfileByURL(orgurl, true); // Get OrgProfile by orgurl
      } 
    }

    if(ppsModule !== 0 && prevPath.substr(prevPpsModule, 10) === 'dashboard' && // moving away from a dashboard
       (path.substr(ppsModule, 10) !== 'dashboard' || (path.substr(ppsModule, 10) === 'dashboard' && ppsModule !== prevPpsModule))){  
      this.props.increaseDashboardVisits(prevPath.substr(6, prevPpsModule - 7));
    }

    // User is moving out of an agreement, clear any open clause locks for that user for that aid
    if(prevPath.substr(prevPpsModule, 9) === 'agreement' && prevPath.substr(prevPpsModule, 59) !== path.substr(ppsModule, 59) &&
    this.props.user !== undefined && this.props.user.user !== undefined && this.props.user.user._id !== undefined) {
      let aid = prevPath.substr(prevPpsModule + 11 + prevPath.substr(prevPpsModule + 10).indexOf('/'))
      axios.get('/clause/protect/clearlocks/' + this.props.user.user._id + "/" + aid)
    }

    window.scrollTo(0, 0); // scroll to top when navigating
    if(window.location.href.indexOf("getcanveo.com") > -1 && path !== prevPath) { ReactGA.pageview(window.location.pathname + window.location.search); } // register new path for google analytics
  }

  render () {

    // OPEN PAGES ***
    const ErrorPage = ({match}) => {
      return(<Error 
        origin={match.params.origin} 
        user={this.props.user.user}
        org={this.props.org.org}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead} 
        />); 
    };

    const SignUpActionPage = ({match}) => {
      return(<SignUp 
        action={match.params.action} 
        email={match.params.email}
        user={this.props.user.user}
        users={this.props.users.users}
        org={this.props.org.org}
        subs={this.props.subs.subs}
        network={this.props.network.network}
        isLoading={
          this.props.agrTypes.pending || this.props.user.pending || this.props.network.pending ||
          this.props.org.pending || this.props.users.pending || this.props.agrTypes.pending ||
          this.props.subs.pending || this.props.cpents.pending ||
          this.props.rels.pending || this.props.notifications.pending || 
          this.props.orgAgrTemplates.pending || this.props.certs.pending
        }
        fetchLoggedInUser={this.props.fetchLoggedInUser}
        updateUserRole={this.props.updateUserRole}
        deleteUser={this.props.deleteUser}
        createEntity={this.props.createEntity}
        createNotification={this.props.createNotification}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead}
        />); // currently only being used for /signup/done
    };

    const ContactActionPage = ({match}) => {
      return(<Contact 
        action={match.params.action} 
        id={match.params.id}
        loading={this.props.user.pending || this.props.network.pending || this.props.agrTypes.pending ||
          this.props.network.network.length < 1 || this.props.agrTypes.agrTypes.length < 1}
        user={this.props.user.user}
        org={this.props.org.org}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead}
        />);
    };

    // CORE PAGES ***

    const MasterdataAction = ({match}) => {
      return(<Masterdata 
        module={match.params.module}
        action={match.params.action} 
        eid={match.params.eid}
        user={this.props.user.user} 
        org={this.props.org.org} 
        rels={this.props.rels.rels}
        cpents={this.props.cpents.cpents}
        cpentsPending={this.props.cpents.pending}
        cpentsError={this.props.cpents.error}
        updateEntity={this.props.updateEntity}
        updateUser={this.props.updateUser}
        updateLogoForOrg={this.props.updateLogoForOrg}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead} />);
    };

    const CreatePasswordString = ({match}) => {
      return(<CreatePassword
        randomstring={match.params.randomstring} />);
    }

    const ActPage = ({match}) => {
      return(<Act
        action={match.params.action}
        val1={match.params.val1}
        val2={match.params.val2}
        val3={match.params.val3}
        val4={match.params.val4}
        val5={match.params.val5}
        user={this.props.user.user}
        userPending={this.props.user.pending}
        userError={this.props.user.error}
        loading={this.props.user.pending || this.props.network.pending || this.props.agrTypes.pending || 
          this.props.org.pending || this.props.users.pending || this.props.cpents.pending ||
          this.props.subs.pending || this.props.rels.pending || this.props.orgAgrTemplates.pending ||
          this.props.notifications.pending || this.props.certs.pending || 
          this.props.network.network.length < 1 || this.props.agrTypes.agrTypes.length < 1 ||
          this.props.orgLabel.pending }
        updateClauseStatus={this.props.updateClauseStatus}
        />
      );
    }

    const SettingsPage = ({match}) => {
      return(<Settings
        pageType={match.params.pageType}
        user={this.props.user.user}
        fetchUsersByOrgID={this.props.fetchUsersByOrgID}
        org={this.props.org.org}
        userPending={this.props.user.pending}
        userError={this.props.user.error}
        updateUser={this.props.updateUser}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead} />
      );
    }

    const HelpPage = ({match}) => {
      return(<Help 
        type={match.params.type} 
        user={this.props.user.user} 
        org={this.props.org.org}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead} />);
    };

    const CompanyAction = ({match}) => {
      return(<Company 
        action={match.params.action} 
        entid={match.params.entid}
        user={this.props.user.user} 
        users={this.props.users.users}
        org={this.props.org.org} 
        orgPending={this.props.org.pending}
        orgError={this.props.org.error}
        clearOrgError={this.props.clearOrgError}
        network={this.props.network.network}
        cpents={this.props.cpents.cpents}
        agrTypes={this.props.agrTypes.agrTypes}
        subs={this.props.subs.subs}
        subsPending={this.props.subs.pending}
        subsError={this.props.subs.error}
        clearSubsError={this.props.clearSubsError}
        updateOrg={this.props.updateOrg}
        updateOrgLevel={this.props.updateOrgLevel}
        updateEntity={this.props.updateEntity}
        createEntity={this.props.createEntity}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead} />);
    };

    const UserMgtAction = ({match}) => {
      return(<UserMgt 
        action={match.params.action} 
        uid={match.params.uid} 
        user={this.props.user.user} 
        users={this.props.users.users}
        org={this.props.org.org} 
        subs={this.props.subs.subs}
        createUser={this.props.createUser}
        deleteUser={this.props.deleteUser}
        updateUserRole={this.props.updateUserRole}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead} />);
    };

    const TasksAction = ({match}) => {
      return (<Tasks
        module={match.params.module}
        type={match.params.type}
        user={this.props.user.user} 
        org={this.props.org.org}
        users={this.props.users.users}
        rels={this.props.rels.rels}
        updateTicket={this.props.updateTicket}
        lastUpdateTicket={this.props.lastUpdateTicket}
        createActivityLog={this.props.createActivityLog}
        updateClauseStatus={this.props.updateClauseStatus}
        createComment={this.props.createComment}
        updateComment={this.props.updateComment}
        createNotification={this.props.createNotification}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead}
      />);
    }

    const TemplatesAction = ({match}) => {
      return(<Templates
        module={match.params.module}
        action={match.params.action} 
        oatid={match.params.oatid}
        clid={match.params.clid}
        agrTypes={this.props.agrTypes.agrTypes}
        orgAgrTemplates={this.props.orgAgrTemplates.orgAgrTemplates}
        orgAgrTemplatesPending={this.props.orgAgrTemplates.pending}
        user={this.props.user.user} 
        users={this.props.users.users}
        org={this.props.org.org}
        subs={this.props.subs.subs}
        updateClauseContentFAF={this.props.updateClauseContentFAF}
        createAgrType={this.props.createAgrType}
        updateAgrType={this.props.updateAgrType}
        deleteAgrType={this.props.deleteAgrType}
        updateClauseStatus={this.props.updateClauseStatus}
        updatePendingChange={this.props.updatePendingChange}
        updateClauseNumbering={this.props.updateClauseNumbering}
        updateOATNewClauses={this.props.updateOATNewClauses}
        updateOatNewDefaultChildren={this.props.updateOatNewDefaultChildren}
        updateOatNames={this.props.updateOatNames}
        updateOatReference={this.props.updateOatReference}
        updateOatURL={this.props.updateOatURL}
        updateOatEffectiveProperties={this.props.updateOatEffectiveProperties}
        updateOatAgrType={this.props.updateOatAgrType}
        addAgrType={this.props.addAgrType}
        toggleExternal={this.props.toggleExternal}
        toggleOatDraftFor={this.props.toggleOatDraftFor}
        toggleOatActivation={this.props.toggleOatActivation}
        createOrgAgrTemplate={this.props.createOrgAgrTemplate} 
        createComment={this.props.createComment}
        updateComment={this.props.updateComment}
        updateTicket={this.props.updateTicket}
        lastUpdateTicket={this.props.lastUpdateTicket}
        updateOATDeletion={this.props.updateOATDeletion}
        updateOATRemoveClause={this.props.updateOATRemoveClause}
        updateSmartField={this.props.updateSmartField}
        defineSmartMode={this.props.defineSmartMode}
        userSession={this.props.userSession}
        createNotification={this.props.createNotification}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead}
      />);
    };

    const PlaybookAction = ({match}) => {
      return(<Playbook
        module={match.params.module}
        action={match.params.action} 
        user={this.props.user.user} 
        org={this.props.org.org}
        userSession={this.props.userSession}
        createNotification={this.props.createNotification}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead}
      />);
    };

    const DashboardPage = ({match}) => {
      return(<Dashboard 
        module={match.params.module}
        searchtype={match.params.searchtype}
        val1={match.params.val1}
        val2={match.params.val2}
        userSession={this.props.userSession}
        isLoading={
          this.props.agrTypes.pending || this.props.user.pending ||
          this.props.network.pending ||
          this.props.org.pending || this.props.users.pending || this.props.agrTypes.pending ||
          this.props.subs.pending || this.props.cpents.pending ||
          this.props.rels.pending || this.props.notifications.pending || 
          this.props.orgAgrTemplates.pending || this.props.certs.pending
        }
        certs={this.props.certs.certs}
        user={this.props.user.user}
        users={this.props.users.users}
        org={this.props.org.org}
        rels={this.props.rels.rels}
        subs={this.props.subs.subs}
        cpents={this.props.cpents.cpents}
        orgLabel={this.props.orgLabel.orgLabel}
        updateOrgLabel={this.props.updateOrgLabel}
        deleteOrgLabel={this.props.deleteOrgLabel}
        agrTypes={this.props.agrTypes.agrTypes}
        orgAgrTemplates={this.props.orgAgrTemplates.orgAgrTemplates}
        network={this.props.network.network}
        updateIntroComplete={this.props.updateIntroComplete}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead} />
      ); 
    }

    const RelationshipsPage = ({match}) => {
      return (<Relationships
        module={match.params.module} 
        user={this.props.user.user}
        org={this.props.org.org}
        rels={this.props.rels.rels}
        subs={this.props.subs.subs}
        cpents={this.props.cpents.cpents}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead} />        
      )
    }

    const AnalyticsPage = ({match}) => {
      return (<Analytics
        module={match.params.module}
        isLoading={
          this.props.users.pending || this.props.org.pending || this.props.notifications.pending || // generic loading (didMount)
          this.props.agrTypes.pending || this.props.subs.pending || this.props.cpents.pending || 
          this.props.network.pending || this.props.orgAgrTemplates.pending || this.props.certs.pending || this.props.rels.pending ||
          this.props.orgLabel.orgLabel.pending
        }
        user={this.props.user.user}
        users={this.props.users.users}
        org={this.props.org.org}
        orgLabel={this.props.orgLabel.orgLabel}
        rels={this.props.rels.rels}
        subs={this.props.subs.subs}
        cpents={this.props.cpents.cpents}
        agrTypes={this.props.agrTypes.agrTypes}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead} />        
      )
    }

    const NewPage = ({match}) => {
      return(<New 
        module={match.params.module}
        cporgid={match.params.cporgid}
        aid={match.params.aid}
        user={this.props.user.user}
        users={this.props.users.users}
        org={this.props.org.org}
        network={this.props.network.network}
        subs={this.props.subs.subs}
        cpents={this.props.cpents.cpents}
        rels={this.props.rels.rels}
        agrTypes={this.props.agrTypes.agrTypes}
        updateAgrvMarkAsEdited={this.props.updateAgrvMarkAsEdited}
        orgAgrTemplates={this.props.orgAgrTemplates.orgAgrTemplates}
        createRelWithAgreements={this.props.createRelWithAgreements}
        createAgr={this.props.createAgr}
        updateAgrPriority={this.props.updateAgrPriority}
        updateEntity={this.props.updateEntity}
        addEntityToReducer={this.props.addEntityToReducer}
        updateEntityInReducer={this.props.updateEntityInReducer}
        addAgrType={this.props.addAgrType}
        updateLogoForOrg={this.props.updateLogoForOrg}
        updateUser={this.props.updateUser}
        fetchCpentsByOrgID={this.props.fetchCpentsByOrgID}
        createNotification={this.props.createNotification}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead} />
    ); 
      }

    const RelationshipPage = ({match}) => {
      return(<Relationship
        module={match.params.module}
        rid={match.params.rid}
        page={match.params.page}
        snackid={match.params.snackid}
        searchchip={match.params.searchchip}
        user={this.props.user.user}
        users={this.props.users.users}
        org={this.props.org.org}
        orgPending={this.props.org.pending}
        subs={this.props.subs.subs}
        cpents={this.props.cpents.cpents}
        agrTypes={this.props.agrTypes.agrTypes}
        orgLabel={this.props.orgLabel.orgLabel}
        updateRel={this.props.updateRel}
        updateRelName={this.props.updateRelName}
        updateAgr={this.props.updateAgr}
        createOrgLabel={this.props.createOrgLabel}
        updateOrgLabel={this.props.updateOrgLabel}
        createComment={this.props.createComment}
        createActivityLog={this.props.createActivityLog}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead}
      />); 
    }

    const AgreementPage = ({match}) => {
      return (<Agreement
        module={match.params.module}
        rid={match.params.rid}
        aid={match.params.aid} 
        clid={match.params.clid}
        agrExec={this.props.agrExec.agrExec}
        org={this.props.org.org}
        user={this.props.user.user}
        users={this.props.users.users}
        rels={this.props.rels.rels}
        subs={this.props.subs.subs}
        cpents={this.props.cpents.cpents}
        agrTypes={this.props.agrTypes.agrTypes}
        isLoading={
          this.props.users.pending || this.props.org.pending || this.props.notifications.pending || // generic loading (didMount)
          this.props.agrTypes.pending || this.props.subs.pending || this.props.cpents.pending || 
          this.props.network.pending || this.props.orgAgrTemplates.pending || this.props.certs.pending || this.props.rels.pending
        }
        updateClauseStatus={this.props.updateClauseStatus}
        updatePendingChange={this.props.updatePendingChange}
        updateClauseNumbering={this.props.updateClauseNumbering}
        updateClauseContentFAF={this.props.updateClauseContentFAF}
        updateTicket={this.props.updateTicket}
        lastUpdateTicket={this.props.lastUpdateTicket}
        createComment={this.props.createComment}
        updateComment={this.props.updateComment}
        notifications={this.props.notifications.notifications}
        createAgrVersion={this.props.createAgrVersion}
        createAgrVersionWithImport={this.props.createAgrVersionWithImport}
        updateAgrPriority={this.props.updateAgrPriority}
        updateAgrNames={this.props.updateAgrNames}
        updateAgrStatus={this.props.updateAgrStatus}
        updateAgrvNewClauses={this.props.updateAgrvNewClauses}
        updateAgrvRemoveClause={this.props.updateAgrvRemoveClause}
        updateAgrvMarkAsEdited={this.props.updateAgrvMarkAsEdited}
        updateAidForUserAgrIDs={this.props.updateAidForUserAgrIDs}
        createActivityLog={this.props.createActivityLog}
        createNotification={this.props.createNotification}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead}
        updateAgr={this.props.updateAgr}
        updateAgrUsers={this.props.updateAgrUsers}
        createAgrExec={this.props.createAgrExec}
        updateSmartField={this.props.updateSmartField}
        defineSmartMode={this.props.defineSmartMode}
        //orgSmartField={this.props.orgSmartField.orgSmartField}
        //addOrgSmartField={this.props.addOrgSmartField}
        userSession={this.props.userSession}
        fetchUsersByOrgID={this.props.fetchUsersByOrgID}
        pushTime={this.props.pushTime}
      />);
    }

    // NETWORK PAGES ***

    const ProfilePage = ({match}) => {
      return(<Profile
        orgurl={match.params.orgurl}
        user={this.props.user.user}
        org={this.props.org.org}
        rels={this.props.rels.rels}
        orgProfileCerts={this.props.orgProfileCerts.orgProfileCerts}
        orgProfile={this.props.orgProfile.orgProfile}
        orgProfileSubs={this.props.orgProfileSubs.orgProfileSubs}
        orgProfilePending={this.props.orgProfile.pending}
        orgProfileError={this.props.orgProfile.error}
        orgProfileAgrTemplates={this.props.orgProfileAgrTemplates.orgProfileAgrTemplates}
        agrTypes={this.props.agrTypes.agrTypes}
        network={this.props.network.network}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead}
      />
      ); 
    }

    const OatPage = ({match}) => {
      return(<Oat
        orgurl={match.params.orgurl}
        oat={match.params.oat}
        user={this.props.user.user}
        org={this.props.org.org}
        orgProfile={this.props.orgProfile.orgProfile}
        orgProfilePending={this.props.orgProfile.pending}
        orgProfileError={this.props.orgProfile.error}
        orgProfileAgrTemplates={this.props.orgProfileAgrTemplates.orgProfileAgrTemplates}
        orgProfileAgrTemplatesPending={this.props.orgProfileAgrTemplates.pending}
        agrTypes={this.props.agrTypes.agrTypes}
        network={this.props.network.network}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead}
      />); 
    }

    const TermsPage = ({match}) => {
      return(<Terms
        orgurl={match.params.orgurl}
        cat={match.params.cat}
        user={this.props.user.user}
        org={this.props.org.org}
        orgProfile={this.props.orgProfile.orgProfile}
        orgProfileCerts={this.props.orgProfileCerts.orgProfileCerts}
        orgProfilePending={this.props.orgProfile.pending}
        orgProfileError={this.props.orgProfile.error}
        orgProfileAgrTemplates={this.props.orgProfileAgrTemplates.orgProfileAgrTemplates}
        agrTypes={this.props.agrTypes.agrTypes}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead}
      />);
    }

    const CertsPage = ({match}) => {
      return(<Certs
        orgurl={match.params.orgurl}
        user={this.props.user.user}
        org={this.props.org.org}
        orgProfileCerts={this.props.orgProfileCerts.orgProfileCerts}
        orgProfile={this.props.orgProfile.orgProfile}
        orgProfilePending={this.props.orgProfile.pending}
        orgProfileError={this.props.orgProfile.error}
        orgProfileAgrTemplates={this.props.orgProfileAgrTemplates.orgProfileAgrTemplates}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead}
      />); 
    }

    const CustomLinkPage = ({match}) => {
      return(<CustomLink
        orgurl={match.params.orgurl}
        customURL={match.params.customurl}
        uid={match.params.uid}
        aid={match.params.aid}
        clid={match.params.clid}
        loading={this.props.user.pending || this.props.network.pending || this.props.agrTypes.pending || 
                 this.props.org.pending || this.props.users.pending || this.props.cpents.pending ||
                 this.props.subs.pending || this.props.rels.pending || this.props.orgAgrTemplates.pending ||
                 this.props.notifications.pending || this.props.certs.pending || 
                 this.props.network.network.length < 1 || this.props.agrTypes.agrTypes.length < 1}
      />); 
    }

    const LoginPage = ({match}) => {
      return(<Login
        orgurl={match.params.orgurl}
        user={this.props.user.user}
        org={this.props.org.org}
        orgProfileCerts={this.props.orgProfileCerts.orgProfileCerts}
        orgProfile={this.props.orgProfile.orgProfile}
        orgProfilePending={this.props.orgProfile.pending}
        orgProfileError={this.props.orgProfile.error}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead}
      />); 
    }

    const LoginWithRoute = ({match}) => { // accesible through /core/loginp/... , the variables will define the target path post login
      return(<Login
        type={match.params.type}
        val1={match.params.val1}
        val2={match.params.val2}
        val3={match.params.val3}
        val4={match.params.val4}
        val5={match.params.val5}
        user={this.props.user.user}
        org={this.props.org.org}
        orgProfileCerts={this.props.orgProfileCerts.orgProfileCerts}
        orgProfile={this.props.orgProfile.orgProfile}
        orgProfilePending={this.props.orgProfile.pending}
        orgProfileError={this.props.orgProfile.error}
        notifications={this.props.notifications.notifications}
        markNotificationAsRead={this.props.markNotificationAsRead}
        markAllNotificationsAsRead={this.props.markAllNotificationsAsRead}
        loading={this.props.user.pending || this.props.network.pending || this.props.agrTypes.pending || 
          this.props.org.pending || this.props.users.pending || this.props.cpents.pending ||
          this.props.subs.pending || this.props.rels.pending || this.props.orgAgrTemplates.pending ||
          this.props.notifications.pending || this.props.certs.pending || 
          this.props.network.network.length < 1 || this.props.agrTypes.agrTypes.length < 1}
      />); 
    }

    return (
      <div>
        <Switch>
            <Route path="/error/:origin?" component={ErrorPage} />
            <Route path='/signup/:action?/:email?' component={SignUpActionPage} />
            <Route path="/contact/:action/:id?" component={ContactActionPage} />
            <Route path="/(contact|contactus)" component={() => 
              <Contact
                user={this.props.user.user}
                org={this.props.org.org}
                notifications={this.props.notifications.notifications}
                markNotificationAsRead={this.props.markNotificationAsRead}
                markAllNotificationsAsRead={this.props.markAllNotificationsAsRead} /> } />
            <Route exact path="/network" component={() => 
              <Network
                user={this.props.user.user}
                org={this.props.org.org}
                network={this.props.network.network}
                notifications={this.props.notifications.notifications}
                markNotificationAsRead={this.props.markNotificationAsRead}
                markAllNotificationsAsRead={this.props.markAllNotificationsAsRead} /> } />

            <Route exact path='/network/:orgurl/terms/:cat?' component={TermsPage} />
            <Route exact path='/network/:orgurl/certs' component={CertsPage} />
            <Route exact path='/network/:orgurl/login' component={LoginPage} />
            <Route exact path='/network/:orgurl/:customurl/:uid/:aid/:clid?' component={CustomLinkPage} />
            <Route exact path='/network/:orgurl/:oat' component={OatPage} />
            <Route exact path='/network/:orgurl' component={ProfilePage} />

            <Route path='/(core/login|)' component={Login} />
            <Route path='/core/loginp/:type/:val1?/:val2?/:val3?/:val4?/:val5?' component={LoginWithRoute} />
            <Route exact path ='/core/logout/:cat?' component={({match}) => <Logout cat={match.params.cat} user={this.props.user.user} /> } />
            <Route path='/core/createpassword/:randomstring' component={CreatePasswordString} />
            <Route path='/core/act/:action/:val1?/:val2?/:val3?/:val4?/:val5?' component={ActPage} />
            
            <Route path ='/core/settings/:pageType?' component={SettingsPage} />
            <Route path='/core/help/:type' component={HelpPage} />

            <Route path='/core/admin/company/:action?/:entid?' component={CompanyAction} />
            {/*
            <Route exact path='/core/admin/company' component={() => 
              <Company 
                user={this.props.user.user}
                users={this.props.users.users}
                org={this.props.org.org}
                subs={this.props.subs.subs}
                updateOrg={this.props.updateOrg}
                updateOrgLevel={this.props.updateOrgLevel}
                orgPending={this.props.org.pending}
                orgError={this.props.org.error}
                clearOrgError={this.props.clearOrgError}
                subsPending={this.props.subs.pending}
                subsError={this.props.subs.error}
                clearSubsError={this.props.clearSubsError}
                notifications={this.props.notifications.notifications}
                markNotificationAsRead={this.props.markNotificationAsRead}
                markAllNotificationsAsRead={this.props.markAllNotificationsAsRead} />} />*/}

            <Route path='/core/legal/templates/:module?/:action?/:oatid?/:clid?' component={TemplatesAction} />
            <Route exact path ='/core/legal/certifications' component={() => 
              <Certifications
                user={this.props.user.user}
                org={this.props.org.org}
                certs={this.props.certs.certs}
                createCert={this.props.createCert}
                updateCert={this.props.updateCert}
                deleteCert={this.props.deleteCert}
                notifications={this.props.notifications.notifications}
                markNotificationAsRead={this.props.markNotificationAsRead}
                markAllNotificationsAsRead={this.props.markAllNotificationsAsRead}
                /> } />
            <Route path='/core/legal/playbook/:module?' component={PlaybookAction} />
            <Route exact path ='/core/legal/hub' component={() => 
              <Hub
                user={this.props.user.user}
                org={this.props.org.org}
                enableHubForOrg={this.props.enableHubForOrg}
                notifications={this.props.notifications.notifications}
                markNotificationAsRead={this.props.markNotificationAsRead}
                markAllNotificationsAsRead={this.props.markAllNotificationsAsRead}
                /> } />

            <Route exact path='/core/admin/usermgt' component={() => 
              <UserMgt 
                user={this.props.user.user}
                users={this.props.users.users}
                org={this.props.org.org}
                subs={this.props.subs.subs}
                usersError={this.props.users.error}
                clearUsersError={this.props.clearUsersError}
                updateUserRole={this.props.updateUserRole}
                notifications={this.props.notifications.notifications}
                markNotificationAsRead={this.props.markNotificationAsRead}
                markAllNotificationsAsRead={this.props.markAllNotificationsAsRead} />} />
            <Route path='/core/admin/usermgt/:action/:uid' component={UserMgtAction} />

            <Route exact path ='/core/:module(counterparty|sales|procurement|partnerships|people|corporate)/dashboard/:searchtype?/:val1?/:val2?' component={DashboardPage} />
            <Route exact path ='/core/:module(sales|procurement|partnerships|people|corporate)/relationships' component={RelationshipsPage} />
            <Route exact path ='/core/:module(sales|procurement|partnerships|people|corporate)/analytics' component={AnalyticsPage} />
            <Route path='/core/:module(sales|procurement|partnerships|people|corporate)/new/:cporgid?/:aid?' component={NewPage} />
            <Route path='/core/:module(counterparty|sales|procurement|partnerships|people|corporate)/relationship/:rid/:page/:snackid?/:searchchip?' component={RelationshipPage} />
            <Route exact path='/core/:module(counterparty|sales|procurement|partnerships|people|corporate)/agreement/:rid/:aid/:clid?' component={AgreementPage} />
            <Route path='/core/:module(sales|procurement|partnerships|people|corporate)/masterdata/:action?/:eid?' component={MasterdataAction} />
            <Route path='/core/:module(sales|procurement|partnerships|people|corporate)/tasks/:type?' component={TasksAction} />

            <Redirect to="/error" />

        </Switch>
      </div>
    )
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
