import React, { useEffect } from 'react';
import { prices } from '../../assets/static/prices';
import { countries } from '../../assets/static/countries'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import theme from '../../theme/theme';
import { trunc, countryToFlag, getCcyByISO, getTaxTypesByCountry, randomString as generateRandomString, 
         calculateExpiryDate, getActorsForModule } from '../../utils';
import { Link as RouterLink, Redirect, useHistory } from 'react-router-dom';
import { Avatar, Box, Button, Card, CardContent, CardActions, CircularProgress, Container, 
         FormControl, FormGroup, FormLabel, FormControlLabel, Grid, IconButton, InputAdornment, 
         Link, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, ListItemSecondaryAction, 
         Radio, RadioGroup, Snackbar, Switch, TextField, Typography,
         makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ErrorMsg, FAB, GetStarted, Header, UploadImg, HeaderSub, SelectUsers, SkeletonCustom, InformationalTooltip } from '../../components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faArrowCircleLeft, faGlobe, faCog, faStar, faUniversity, faCheck, faExclamation,
         faSignature, faUserPlus, faTrash, faCaretRight, faTimes, faSync, faBan, faArrowAltCircleUp, faPen, 
         faFileInvoiceDollar, faEllipsisH, faEnvelope, faPaintBrush, faFillDrip, faExclamationCircle, faImage } from "@fortawesome/free-solid-svg-icons";
import { EntityForm, CompanyContactForm } from '../../components/forms';

import hubspot from '../../assets/img/hubspot.png';
import salesforce from '../../assets/img/salesforce.png';

const axios = require('axios');
const dayjs = require('dayjs')

function Company(props) {

  const useStyles = makeStyles(theme => ({
    outerFlexGrid: {
      minHeight: '80vh',
      paddingTop: '30px',
      paddingBottom: '100px',
    },
    pr15: {
      paddingRight: '15px',
    },
    pl15: {
      paddingLeft: '15px'
    },
    bold: {
      fontWeight: '700',
    },
    error: {
      color: theme.palette.error.main
    },
    errorButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.primary.contrastText
    },
    successButton: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.primary.contrastText
    },
    grey600: {
      color: theme.palette.grey[600]
    },
    globeIcon: {
      color: theme.palette.primary.main,
      fontSize: '20px',
      marginRight: '15px',
      marginTop: '3px',
    },
    orgurl: {
      '&:hover': {
        textDecoration: 'none',
        borderBottom: '1px dotted ' + theme.palette.primary.main
      }
    },
    boxResizing: {
      [theme.breakpoints.only('xs')]: { width: '320px' },
      [theme.breakpoints.up('sm')]: { width: '500px' },
    },
    nested: {
      paddingLeft: theme.spacing(4),
      margin: '0px',
    },
    listItemLevel1: {
      fontSize: '16px',
      fontWeight: '700',
      color: theme.palette.grey[900],
    },
    listItemLevel2: {
      fontWeight: '400',
      fontSize: '14px',
      color: theme.palette.grey[700],
    },
    listItemLevel2Undefined: {
      fontWeight: '400',
      fontSize: '14px',
      color: theme.palette.grey[400],
    },
    delB: {
      padding: '2px 10px 2px 10px',
      color: theme.palette.grey[600],
    },
    avSuccess: {
      backgroundColor: theme.palette.success.main,
    },
    avError: {
      backgroundColor: theme.palette.error.main,
    },
    avPending: {
      backgroundColor: theme.palette.warning.main,
    },
    invIcon: {
      color: theme.palette.grey[800],
      fontSize: '24px',
    },
    invIconB: {
      padding: '14px 17px 14px 17px'
    },
    subsButL: {
      padding: '2px 15px 2px 15px',
      marginRight: 'auto',
    },
    subsButR: {
      padding: '2px 15px 2px 15px',
      color: theme.palette.grey[600],
    },
    errorMsg: {
      color: theme.palette.error.main,
    },
    cancelButton: {
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      }
    },
    subsCard: {
      [theme.breakpoints.only('xs')]: { width: '320px' },
      [theme.breakpoints.up('sm')]: { width: '500px' },
      padding: '10px',
    },
    editIcon: {
      color: theme.palette.primary.main,
      fontSize: '12px',
      padding: '3px'
    },
    editIconB: {
      marginLeft: '10px',
    },
    adornIcon: {
      color: theme.palette.primary.main,
      paddingRight: '8px',
    },
    colorPicker:{
      marginTop: '20px',
      width: '80px', 
      height: '80px', 
      borderRadius: '40px', 
      border: '1px solid #e0e0e0',
      '&:hover': {
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 24px 0px',
        cursor: 'pointer',
      }
    },
    sampleButton: {
      fontWeight: '500',
      backgroundColor: props.org !== undefined && props.org !== null && props.org.whiteLabel !== undefined && 
        props.org.whiteLabel !== null && props.org.whiteLabel.color !== undefined ? props.org.whiteLabel.color : theme.palette.secondary.main,
      color: props.org !== undefined && props.org !== null && props.org.whiteLabel !== undefined && 
      props.org.whiteLabel !== null && props.org.whiteLabel.color !== undefined ? props.org.whiteLabel.contrastColor : theme.palette.secondary.contrastText,
      width: '130px',
      padding: '6px 15px 6px 15px',
      borderRadius: '8px',
      cursor: 'pointer',
    },
    importBox: {
      maxWidth: '600px',
    },
    errIcon: {
      color: theme.palette.error.main,
    },
    warnIcon: {
      color: theme.palette.warning.main,
    }
  }));
  const classes = useStyles();

  const stripe = useStripe();
  const elements = useElements();

  const history = useHistory()

  const [formError, setFormError] = React.useState('');  
  const [activeEntity, setActiveEntity] = React.useState((props.entid !== undefined && !['1','2', '3', '4'].includes(props.entid)) ? props.subs.filter(sub => sub._id === props.entid)[0] : {});
  const [confirmedNavigate, setConfirmedNavigate] = React.useState(false);
  const [signatoryUser, setSignatoryUser] = React.useState(null);
  const [addingSignatoryForEntity, setAddingSignatoryForEntity] = React.useState(null)
  const [snack, setSnack] = React.useState(props.entid !== undefined && props.entid !== null && ['2', '3', '4'].includes(props.entid.toString()) ? props.entid.toString() : null);
  const [stripePayMethod, setStripePayMethod] = React.useState(null)
  const [stripeProducts, setStripeProducts] = React.useState([])
  const [stripeSubs, setStripeSubs] = React.useState([])
  const [stripeInvoices, setStripeInvoices] = React.useState([])
  const [stripeAddress, setStripeAddress] = React.useState(null)
  const [stripeTaxids, setStripeTaxids] = React.useState([])
  const [stripeEmail, setStripeEmail] = React.useState(null)
  const [stripeNewEmail, setStripeNewEmail] = React.useState(null)
  const [upgrading, setUpgrading] = React.useState(props.entid === 'upgrade' ? true : false)
  const [canceling, setCanceling] = React.useState(false)
  const [payMethod, setPayMethod] = React.useState(false)
  const [addressUpdating, setAddressUpdating] = React.useState(false)
  const [emailUpdating, setEmailUpdating] = React.useState(false)
  const [cardElement, setCardElement] = React.useState(null);
  const [cardError, setCardError] = React.useState(null)
  const [cardComplete, setCardComplete] = React.useState(null)
  const [loading, setLoading] = React.useState(false);
  const [freezeEntities, setFreezeEntities] = React.useState(false)
  const [whiteLabelColorChange, setWhiteLabelColorChange] = React.useState(null)
  const [historicalImport, setHistoricalImport] = React.useState(null)
  const [newClientLogo, setNewClientLogo] = React.useState(null)
  const [logoUpdating, setLogoUpdating] = React.useState(false)

  const modules = props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
                    ['sales','procurement','partnerships','people','corporate'] : 
                  props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
                    props.org.modules : [] 

  useEffect(() => {
    let unmounted = false;

    if(props.action === 'subscription' && props.org.stripecusid !== undefined) {

      axios.get('/billing/stripe-customer/' + props.org.stripecusid)
      .then(function(rescus) {
        if(!unmounted) { 
          setStripeSubs(rescus.data.data.subs) 
          setStripeAddress(rescus.data.data.address !== undefined ? rescus.data.data.address : {})
          setStripeTaxids(rescus.data.data.taxids !== undefined ? rescus.data.data.taxids : [])
          setStripeEmail(rescus.data.data.email)
          setStripeNewEmail(rescus.data.data.email)
        }
        if(rescus.data.data.payment_method_id !== null) {
          axios.get('/billing/stripe-paymethod/' + rescus.data.data.payment_method_id) // Pull Payment method
          .then(function(respay) {
            if (!unmounted) { setStripePayMethod(respay.data.data) } // assign payment method
          }).catch(function(err) { console.log("error retrieving payment method from stripe") })
        }

        rescus.data.data.products.forEach((prod) => { // Loop through all products for this customer
          axios.get('/billing/stripe-product/' + prod.product) // Pull product detail
          .then(function(resprod) {
            if (!unmounted) { setStripeProducts(prods => [...prods, {...resprod.data.data, qty: prod.qty}]) } // assign product detail
          }).catch(function(err) { console.log("error retrieving product from stripe", prod) })
        })

      }).catch(function(err) { console.log("error retrieving customer from stripe") })

      axios.get('/billing/stripe-invoices/' + props.org.stripecusid) // Pull invoices
      .then(function(resinvs) {
        if (!unmounted) { setStripeInvoices(resinvs.data.data) } // assign invoices
      }).catch(function(err) { console.log("error retrieving customer from stripe") })

    } else if(props.action === 'entities') {
      let p = prices.filter((p) => p.clientType === props.org.clientType)[0]
      setFreezeEntities(p !== undefined && props.subs !== undefined && props.subs.filter((s) => s.isLive).length >= p.maxEntities)
    }
    return () => { unmounted = true };

  }, [props.action, props.org.stripecusid, props.org.clientType, props.subs])

  const handleCardInputChange = (e) => {
    const cardEl = elements.getElement(CardElement);

    if(e.error !== undefined) {
      setCardError(e.error.message)
    } else {
      setCardError(null)
      if(e.complete) { 
        setCardComplete(true) 
        setCardElement(cardEl)
      }
    }
  }

  const handleUpdatePayMethod = (e) => {
    setLoading(true)
    stripe
      .createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: props.org.shortName,
        },
      })
      .then((result) => {
        
        axios.put('/billing/protect/attachpaymethod', {
          stripecusid: props.org.stripecusid,
          paymethodid: result.paymentMethod.id,
        })
        .then(function(respm) {
          
          axios.put('/billing/protect/updatepaymethod', {
            stripecusid: props.org.stripecusid,
            paymethodid: respm.data.data.id,
          })
          .then(function(resapm) {
            setLoading(false)
            history.push('/core/admin/company/subscription/3') // forward to get a snack

          }).catch(function(err) { console.log(err) })
        }).catch(function(err) { console.log(err) })
      }).catch((err) => { console.log(err) })
  }

  const handleSearchChange = (value) => {
    setActiveEntity((value !== undefined && value !== null) ? value : {});
  }

  /*
  const handlePricingOpen = () => {
    setPricingOpen(true);
  }

  const handlePricingClose = () => {
    setPricingOpen(false)
  }*/

  const handleUpgradeAccount = (newLevel) => {
    if(['Business','Premium'].includes(newLevel)){
      props.updateOrgLevel(props.org._id, newLevel === 'Business' ? 'custB' : 'cust', [])
      axios.post('/mail/upgrade', {
        recipient: props.user.email,
        newLevel: newLevel,
      }).then((res) => {
        history.push('/core/admin/company/subscription/done')
      }).catch((err) => console.log(err))
    }
  }

  const callbackEntityAddressUpdatingForm = (child) => {

    let taxChanges = [], subChanges = [];

    if(stripeAddress.country !== child.country) { // Establish new tax ids per country (if applicable & provided)
      
      stripeTaxids.forEach((stid) => { // clear taxids in Stripe
        taxChanges.push({stripeid: stid.id, action: 'delete'})
      })

      child.taxids.forEach((ctid) => {
        if(ctid.value !== '') { taxChanges.push({stripeid: 'na', action: 'insert', type: ctid.type, value: ctid.value}) }
      })

      if(child.country === 'GB') { // need to attach a tax code to the subscription(s)
        stripeSubs.forEach((s) => { subChanges.push({ id: s, newRates: ['txr_1IOrbtGTo1OR8YkMghiIxPjL']}) })
      } else if (stripeAddress.country === 'GB') { // need to remove the tax code from the subscription(s)
        stripeSubs.forEach((s) => { subChanges.push({ id: s, newRates: []}) })
      }

    } else { // check if tax ids were changed

      child.taxids.forEach((ctid) => {
        let stripeComparable = stripeTaxids.filter((stid) => stid.type === ctid.type)[0]
        if(ctid.id !== undefined && stripeComparable !== undefined && stripeComparable.value !== ctid.value) { // to be updated
          taxChanges.push({stripeid: ctid.id, action: 'update', type: ctid.type, value: ctid.value})
        } else if (ctid.id === undefined && stripeComparable === undefined && ctid.value !== '') { // to be newly added
          taxChanges.push({stripeid: 'na', action: 'insert', type: ctid.type, value: ctid.value})
        } else if(ctid.id !== undefined && ctid.value === '') { // to be deleted
          taxChanges.push({stripeid: ctid.id, action: 'delete'})
        }
      })
    }

    axios.put('/billing/protect/update-stripe-addresstax', {
      cusid: props.org.stripecusid,
      city: child.city,
      country: child.country,
      streetAndNo: child.streetAndNo,
      address2: child.address2,
      zip: child.zip,
      state: child.state,
      taxExempt: child.country !== 'GB' ? "reverse" : "none",
      taxChanges: taxChanges,
      subChanges: subChanges,
    }).then((res) => {
      setAddressUpdating(false);
      history.push('/core/admin/company/subscription/1') // Push to reload the information
    }).catch((err) => console.log(err));

  }

  const handleStripeEmailConfirm = (newval) => {
    if(stripeNewEmail !== stripeEmail) {
      axios.put('/billing/protect/update-stripe-email', {
        cusid: props.org.stripecusid,
        email: stripeNewEmail,
      }).then((res) => {
        setStripeEmail(stripeNewEmail);
        setEmailUpdating(false);
      }).catch((err) => console.log(err));
    }
  }

  const handleStripeEmailUpdate = (newval) => {
    setStripeNewEmail(newval)
  }

  const callbackEntityForm = (child) => {
    if(props.subs.filter(sub => sub.legalName.trim().toLowerCase() === child.legalName.trim().toLowerCase())[0] !== undefined) {
      setFormError('This entity already exists, choose a different legal name');
    } else {
      props.createEntity(
        props.org._id, // orgid
        '', // parentOrgID
        props.org !== undefined && props.org.clientType === 'legalOrg' ? 'LegalClient' : 'Subsidiary', // entityType
        child.entityCategory, // entityCategory
        child.shortName.trim(), // shortName
        child.legalName.trim(), // legalName
        [{
          addressType: 'billto',
          streetAndNo: child.streetAndNo !== undefined && child.streetAndNo !== null ? child.streetAndNo.trim() : '',
          address2: child.address2 !== undefined && child.address2 !== null ? child.address2.trim() : '',
          zip: child.zip !== undefined && child.zip !== null ? child.zip.trim() : '', 
          city: child.city !== undefined && child.city !== null ? child.city.trim() : '',
          state: child.state !== undefined && child.state !== null ? child.state.trim() : '',
          country: child.country
        }], // address
        props.org !== undefined && props.org.clientType === 'legalOrg' && newClientLogo !== null ? newClientLogo : props.org.logoURL, // logoURL
        {}, // primaryContact
        {}, // empty signatory 
        null, // sourceData
        props.user._id, // creationBy
        false // faf
      );
      setConfirmedNavigate(true);
    }
  }

  const confirmDeactivate = () => {
    let e = props.subs.filter((entity) => entity._id === props.entid)[0]

    props.updateEntity(
      e._id, // entid, 
      e.orgID, // orgid, 
      e.parentOrgID, // parentOrgID, 
      e.entityType, // entityType, 
      e.shortName, // shortName, 
      e.legalName, // legalName, 
      e.address, // address, 
      e.logoURL, // logoURL, 
      e.primaryContact, // primary Contact
      e.signatory, // empty signatory  
      !e.isLive, // isLive, 
      props.user._id, // lastUpdateBy
    )
    setActiveEntity({});

    history.push('/core/admin/company/entities/1');
  }

  const callbackEntityUpdateForm = (child) => {
    if(props.subs.filter(sub => sub.legalName.trim().toLowerCase() === child.legalName.trim().toLowerCase() && props.entid !== sub._id)[0] !== undefined) {
      setFormError('This entity already exists, choose a different legal name');
    } else {
      props.updateEntity(
        props.entid, // entid, 
        props.org._id, // orgid, 
        '', // parentOrgID, 
        activeEntity.entityType, // entityType, 
        child.shortName.trim(), // shortName, 
        child.legalName.trim(), // legalName, 
        [{
          addressType: 'billto', 
          streetAndNo: child.streetAndNo !== undefined && child.streetAndNo !== null ? child.streetAndNo.trim() : '',
          address2: child.address2 !== undefined && child.address2 !== null ? child.address2.trim() : '',
          zip: child.zip !== undefined && child.zip !== null ? child.zip.trim() : '', 
          city: child.city !== undefined && child.city !== null ? child.city.trim() : '',
          state: child.state !== undefined && child.state !== null ? child.state.trim() : '',
          country: child.country
        }], // address, 
        activeEntity.logoURL, // logoURL, 
        activeEntity.primaryContact, // primary Contact
        activeEntity.signatory, // empty signatory  
        true, // isLive, 
        props.user._id, // lastUpdateBy
      )
    }
  }

  const callbackLogoUploadFormClient = (child) => {
    if(activeEntity._id !== undefined) {
      props.updateEntity(
        activeEntity._id, // entid, 
        props.org._id, // orgid, 
        activeEntity.parentOrgID, // parentOrgID, 
        activeEntity.entityType, // entityType, 
        activeEntity.shortName, // shortName, 
        activeEntity.legalName, // legalName, 
        activeEntity.address, // address, 
        child, // logoURL, 
        activeEntity.primaryContact, // primary Contact
        activeEntity.signatory, // empty signatory  
        activeEntity.isLive, // isLive, 
        props.user._id, // lastUpdateBy
      )
    } else {
      setNewClientLogo(child)
    }
  }

  const callbackLogoUploadForm = (child) => {

    // Update the logo for all subs
    props.subs.forEach((s) => {

      props.updateEntity(
        s._id, // entid, 
        props.org._id, // orgid, 
        s.parentOrgID, // parentOrgID, 
        s.entityType, // entityType, 
        s.shortName, // shortName, 
        s.legalName, // legalName, 
        s.address, // address, 
        child, // logoURL, 
        s.primaryContact, // primary Contact
        s.signatory, // empty signatory  
        s.isLive, // isLive, 
        props.user._id, // lastUpdateBy
      )
    })

    if(props.org.whiteLabel !== undefined && props.org.whiteLabel !== null && 
      props.org.whiteLabel.color !== undefined && props.org.whiteLabel.contrastColor !== undefined) {
      updateWhiteLabelForRelationships(props.org._id, { orgName: props.org.shortName, logoURL: child, color: props.org.whiteLabel.color, contrastColor: props.org.whiteLabel.contrastColor })
    }

    props.updateOrg(props.org._id, props.org.legalName, child, props.org.url, 
      props.org.phone, props.org.email, props.org.notifyUsers, 
      props.org.whiteLabel !== undefined ? props.org.whiteLabel : null, 
      props.org.orgSettings !== undefined ? props.org.orgSettings : {},
      props.org.integrations !== undefined ? props.org.integrations : {});

  }

  const callbackCompanyContactForm = (child) => {
    if(child.legalName !== props.org.legalName) { // The legal name changed - also need to update Stripe Billing
      axios.put('/billing/protect/update-stripe-name', {
        cusid: props.org.stripecusid,
        name: child.legalName,
      })
    }
    props.updateOrg(props.org._id, child.legalName, props.org.logoURL, child.url, 
      child.phone, child.email, props.org.notifyUsers, 
      props.org.whiteLabel !== undefined ? props.org.whiteLabel : null, 
      props.org.orgSettings !== undefined ? props.org.orgSettings : {},
      props.org.integrations !== undefined ? props.org.integrations : {});
  }

  const handleUserSearchChange = (value) => {
    setSignatoryUser((value !== undefined && value !== null) ? value : {});
  }

  const handleSignatorySubmit = () => {
    if(signatoryUser !== null & signatoryUser !== undefined && signatoryUser._id !== undefined && 
        addingSignatoryForEntity !== undefined && addingSignatoryForEntity !== null) {

      let e = props.subs.filter((entity) => entity._id === addingSignatoryForEntity)[0]
      if(e !== undefined) {
        let signa = e.signatory;
        if(signa[0] === undefined || signa[0].uid === undefined) { signa = [] } // clean any empty object that gets created initially
        signa.push({
          uid: signatoryUser._id,
          firstName: signatoryUser.firstName,
          lastName: signatoryUser.lastName,
          displayName: signatoryUser.displayName,
          email: signatoryUser.email,
        })
        props.updateEntity(
          e._id, // entid, 
          e.orgID, // orgid, 
          e.parentOrgID, // parentOrgID, 
          e.entityType, // entityType, 
          e.shortName, // shortName, 
          e.legalName, // legalName, 
          e.address, // address, 
          e.logoURL, // logoURL, 
          e.primaryContact, // primary Contact
          signa, // signatory 
          e.isLive, // isLive, 
          props.user._id, // lastUpdateBy
        )
      }
    }
  }

  const handleRemoveSigner = (subid, uid) => {
    let e = props.subs.filter((entity) => entity._id === subid)[0]
    if(e !== undefined) {
      let signa = e.signatory.filter((s) => s.uid !== uid);
      props.updateEntity(
        e._id, // entid, 
        e.orgID, // orgid, 
        e.parentOrgID, // parentOrgID, 
        e.entityType, // entityType, 
        e.shortName, // shortName, 
        e.legalName, // legalName, 
        e.address, // address, 
        e.logoURL, // logoURL, 
        e.primaryContact, // primary Contact
        signa, // signatory 
        e.isLive, // isLive, 
        props.user._id, // lastUpdateBy
      )
    }
  }

  const confirmCancel = () => {
    
    stripeSubs.forEach((sub, i) => {      
      axios.delete('/billing/protect/cancelsub/' + sub)
      .then(function(res) {
        if(stripeSubs.length === (i + 1)) {          
          axios.post('/mail/cancelaccount', {
            recipient: props.user.email,
            date: dayjs.unix(res.data.data.current_period_end).format("MMM D, YYYY"),
          })
          history.push('/core/admin/company/subscription/4')
        }
      }).catch(function(err){ console.log(err) })
    })
  }

  const handleSettingsChange = (setting, newVal) => {

    console.log("setting, newVal", setting, newVal)
    let newSettings = props.org.orgSettings !== undefined && props.org.orgSettings !== null ? props.org.orgSettings : {}
    let newWhiteLabel = props.org.whiteLabel !== undefined ? props.org.whiteLabel : null

    if(setting === 'headerInPdf') {
      newSettings = {...newSettings, headerInPdf: newVal === 'headerInPdf'}

    } else if(setting === 'whiteLabelLevel') {
      newSettings = {...newSettings, whiteLabelLevel: newVal}
      newWhiteLabel = newWhiteLabel === null || newWhiteLabel.color === undefined || newWhiteLabel.contrastColor === undefined ? 
        { color: '#E80064', contrastColor: "#FFFFFF" } : newWhiteLabel

      updateWhiteLabelForRelationships(props.org._id, { 
        orgName: props.org.shortName, 
        logoURL: props.org.logoURL, 
        color: newWhiteLabel.color, 
        contrastColor: newWhiteLabel.contrastColor,
        whiteLabelLevel: newVal
      })

    }

    props.updateOrg(props.org._id, props.org.legalName, props.org.logoURL, props.org.url, 
      props.org.phone, props.org.email, props.org.notifyUsers, newWhiteLabel, newSettings,
      props.org.integrations !== undefined ? props.org.integrations : {});

  }

  const handleInstallIntegration = (newVal, integration) => {

    if(newVal && ['hubspot', 'salesforce'].includes(integration)) {

      axios.get('/integration/protect/install/' + integration)
      .then((res) => { // rerouting to the Integration Install page
        window.location.assign(res.data.data)
      }).catch((err) => console.log("err", err))

    } else if (['hubspot', 'salesforce'].includes(integration) && !newVal) { // De-install integration

      let newIntegrations = props.org.integrations !== undefined && props.org.integrations !== null ? props.org.integrations : {}
      if(['hubspot'].includes(integration)) {
        delete newIntegrations.hubspot
      } else if(['salesforce'].includes(integration)) {
        delete newIntegrations.salesforce
      }

      props.updateOrg(props.org._id, props.org.legalName, props.org.logoURL, props.org.url, 
        props.org.phone, props.org.email, props.org.notifyUsers, props.org.whiteLabel, props.org.orgSettings,
        newIntegrations);

    }
  }

  const toggleWhiteLabelColorChange = () => {
    if(whiteLabelColorChange === null && props.org !== undefined && props.org !== null &&
      props.org.whiteLabel !== undefined && props.org.whiteLabel !== null && props.org.whiteLabel.color !== undefined && props.org.whiteLabel.contrastColor !== undefined){
      setWhiteLabelColorChange({ color: props.org.whiteLabel.color, contrastColor: props.org.whiteLabel.contrastColor })
    } else if(whiteLabelColorChange !== null) {
      setWhiteLabelColorChange(null)
    }
  }

  const handleWhiteLabelColorSubmit = () => {
    if(whiteLabelColorChange === undefined || whiteLabelColorChange === null) { return }
    let newColor = whiteLabelColorChange.color
    let newContrastColor = whiteLabelColorChange.contrastColor
    if(!newColor.startsWith('#')) { newColor = '#' + newColor }
    if(!newContrastColor.startsWith('#')) { newContrastColor = '#' + newContrastColor }
    if(/^#[0-9A-F]{6}$/i.test(newColor) && /^#[0-9A-F]{6}$/i.test(newContrastColor) && props.org.orgSettings !== undefined &&
    props.org.orgSettings !== null && ['email', 'full'].includes(props.org.orgSettings.whiteLabelLevel)) {

      updateWhiteLabelForRelationships(props.org._id, { 
        orgName: props.org.shortName, 
        logoURL: props.org.logoURL, 
        color: newColor, 
        contrastColor: newContrastColor,
        whiteLabelLevel: props.org.orgSettings.whiteLabelLevel
      })
      props.updateOrg(props.org._id, props.org.legalName, props.org.logoURL, props.org.url, 
        props.org.phone, props.org.email, props.org.notifyUsers, { color: newColor, contrastColor: newContrastColor }, 
        props.org.orgSettings,
        props.org.integrations !== undefined ? props.org.integrations : {});

    } else {
      setWhiteLabelColorChange({...whiteLabelColorChange, error: 'Please insert valid HEX values'})
    }
  }

  const updateWhiteLabelForRelationships = (orgID, newWhiteLabel) => {

    // pull all ids for relationships that need to be updated
    axios.get('/rel/relswl/' + orgID)
    .then(function(res) {
      let bulkWrites = []

      if(res.data.data !== undefined) {
        res.data.data.forEach((rid) => {
          bulkWrites.push({
            "filter": { "_id": rid }, 
            "update": { $set: {
              "whiteLabel": newWhiteLabel,
            } },
            "upsert": true
          })
        })
      }
      axios.put('/rel/relswl', { bulkWrites: bulkWrites})
    })
  }

  const handleImportSubmit = () => {

    let rels = [], agrs = [], orgs = [], ents = [], err = [], warn = [];
    let creationDate = new Date().toISOString();

    if(historicalImport !== undefined && historicalImport !== null && historicalImport.value !== undefined &&
    historicalImport.value !== null && historicalImport.value !== '') {

      historicalImport.value.split('\n').forEach((ta, i) => {
        let agrValues = ta.split('|')

        if(agrValues.length === 17) {

          let fileName = '', module = '', ourEntID = '', entityCategory = '', shortName = '', legalName = '', logoURL = null,
              streetAndNo = '', address2 = '', city = '', zip = '', state = '', country = '', agrFullName = '', agrShortName = '',
              agrTypeID = '', effectiveDate = '', renewalType = '', effectiveTerm = {};

          // File Name validation
          if(agrValues[0] !== undefined && agrValues[0] !== null && agrValues[0] !== '' && agrValues[0].endsWith('.pdf')) { fileName = agrValues[0]; } 
          else { err.push('Unable to import line ' + (i+1) + ' - invalid file name.') }

          // Module validation
          if(agrValues[1] !== undefined && agrValues[1] !== null && agrValues[1] !== '' && 
          ['sales', 'procurement', 'partnerships', 'people', 'corporate'].includes(agrValues[1])) { module = agrValues[1]; } 
          else { err.push('Unable to import line ' + (i+1) + ' - invalid module.') }

          // Entity validation
          if(agrValues[2] !== undefined && agrValues[2] !== null && agrValues[2] !== '' && 
          props.subs.some((s) => s.legalName.toLowerCase() === agrValues[2].trim().toLowerCase())) { ourEntID = props.subs.filter((s) => s.legalName.toLowerCase() === agrValues[2].trim().toLowerCase())[0]._id; } 
          else { err.push('Unable to import line ' + (i+1) + ' - invalid entity.') }

          // Entity Category validation
          if(agrValues[3] !== undefined && agrValues[3] !== null && agrValues[3] !== '') { entityCategory = agrValues[3]; } 
          else { err.push('Unable to import line ' + (i+1) + ' - invalid entity category.') }

          // Checking if the counterparty shortName is valid
          if(agrValues[4] !== undefined && agrValues[4] !== null && agrValues[4] !== '') { shortName = agrValues[4].trim(); }  
          else { err.push('Unable to import line ' + (i+1) + ' - invalid company name.') }

          // Checking if the counterparty shortName is valid
          if(agrValues[5] !== undefined && agrValues[5] !== null && agrValues[5] !== '') { legalName = agrValues[5].trim(); }  
          else { err.push('Unable to import line ' + (i+1) + ' - invalid legal name.') }

          // Checking if the counterparty already exists
          if(props.network.some((n) => n.shortName.toLowerCase() === shortName.toLowerCase())) {
            warn.push('To investigate (line ' + (i+1) + '): shortName "' + shortName + '" already exists in the network')
          } else if(props.network.some((n) => n.legalName.toLowerCase() === legalName.toLowerCase())) {
            warn.push('To investigate (line ' + (i+1) + '): legalName "' + legalName + '" already exists in the network')
          } else if(props.cpents.some((c) => ((module === 'sales' && c.entityType === 'Customer') || (module === 'procurement' && c.entityType === 'Supplier') || 
          (module === 'partnerships' && c.entityType === 'Partner') || (module === 'people' && c.entityType === 'Employee') || (module === 'corporate' && c.entityType === 'Corporate')) 
          && c.shortName.toLowerCase() === shortName.toLowerCase())) {
            warn.push('To investigate (line ' + (i+1) + '): shortName "' + shortName + '" already exists in the master data')
          } else if(props.cpents.some((c) => ((module === 'sales' && c.entityType === 'Customer') || (module === 'procurement' && c.entityType === 'Supplier') || 
          (module === 'partnerships' && c.entityType === 'Partner') || (module === 'people' && c.entityType === 'Employee') || (module === 'corporate' && c.entityType === 'Corporate')) 
          && c.legalName.toLowerCase() === legalName.toLowerCase())) {
            warn.push('To investigate (line ' + (i+1) + '): legalName "' + legalName + '" already exists in the master data')
          }

          // Logo File Name validation - can be empty or needs to be valid
          if(agrValues[6] !== undefined && agrValues[6] !== null && 
          (agrValues[6].endsWith('.png') || agrValues[6].endsWith('.jpg') || agrValues[6].endsWith('.jpeg'))) { logoURL = agrValues[6]; } 
          else if(agrValues[6] !== '') { err.push('Unable to import line ' + (i+1) + ' - invalid logo file name.') }

          // streetAndNo validation
          if(agrValues[7] !== undefined && agrValues[7] !== null && agrValues[7] !== '') { streetAndNo = agrValues[7]; } 
          else { err.push('Unable to import line ' + (i+1) + ' - invalid street address.') }

          // address2 validation
          if(agrValues[8] !== undefined && agrValues[8] !== null) { address2 = agrValues[8]; } 
          else { err.push('Unable to import line ' + (i+1) + ' - invalid address line 2.') }

          // city validation
          if(agrValues[9] !== undefined && agrValues[9] !== null && agrValues[9] !== '') { city = agrValues[9]; } 
          else { err.push('Unable to import line ' + (i+1) + ' - invalid city') }

          // state validation
          if(agrValues[10] !== undefined && agrValues[10] !== null) { zip = agrValues[10]; } 
          else { err.push('Unable to import line ' + (i+1) + ' - invalid state.') }

          // country validation
          if(agrValues[11] !== undefined && agrValues[11] !== null && countries.some((country) => country.code === agrValues[11])) { country = agrValues[11]; } 
          else { err.push('Unable to import line ' + (i+1) + ' - invalid country') }

          // Agreement Type validation
          if(agrValues[12] !== undefined && agrValues[12] !== null && props.agrTypes.some((at) => at.fullName.includes(agrValues[12]))) { 
            agrTypeID = props.agrTypes.filter((at) => at.fullName.includes(agrValues[12]))[0]._id;
            agrFullName = props.agrTypes.filter((at) => at.fullName.includes(agrValues[12]))[0].fullName[0]
            agrShortName = props.agrTypes.filter((at) => at.fullName.includes(agrValues[12]))[0].shortName
          } 
          else { err.push('Unable to import line ' + (i+1) + ' - invalid Agreement Type') }

          // Effective Date validation and definition
          if(agrValues[13] === '') { 
            effectiveDate = null;
          } else if(agrValues[13] !== undefined && agrValues[13] !== null && agrValues[13] !== '') { 
            effectiveDate = new Date(agrValues[13] * 24 * 60 * 60 * 1000);
            effectiveDate.setFullYear(effectiveDate.getFullYear() - 70);
            effectiveDate.setDate(effectiveDate.getDate() - 1);
          } else { err.push('Unable to import line ' + (i+1) + ' - invalid effective date') }

          // Renewal Type validation
          if(agrValues[14] !== undefined && agrValues[14] !== null && ['', 'auto', 'manual', 'none'].includes(agrValues[14])) { renewalType = agrValues[14] === '' ? 'none' : agrValues[14]; } 
          else { err.push('Unable to import line ' + (i+1) + ' - invalid renewal type.') }

          // Effective Term validation
          if(agrValues[15] !== undefined && agrValues[15] !== null && agrValues[16] !== undefined && agrValues[16] !== null) {
            effectiveTerm = {
              isCustom: false,
              initial: agrValues[15].indexOf("_") > 0 && (agrValues[15].indexOf("_") < agrValues[15].length - 1) ? agrValues[15] : null,
              renewal: agrValues[16].indexOf("_") > 0 && (agrValues[16].indexOf("_") < agrValues[16].length - 1) ? agrValues[16] : null,
            }; 
          } else { err.push('Unable to import line ' + (i+1) + ' - invalid effective Term.') }

          // START FILLING UP THE ARRAYS FOR IMPORT
          // Create the ORG, if there are multiple agreements for one Org then reuse based on cpOrgID
          let cpOrgID = generateRandomString(5)
          if(orgs.filter((o) => o.shortName.toLowerCase() === shortName.toLowerCase())[0] !== undefined) {
            cpOrgID = orgs.filter((o) => o.shortName.toLowerCase() === shortName.toLowerCase())[0].cpOrgID;
            shortName = orgs.filter((o) => o.shortName.toLowerCase() === shortName.toLowerCase())[0].shortName;
            logoURL = orgs.filter((o) => o.shortName.toLowerCase() === shortName.toLowerCase())[0].logoURL;
          } else {
            orgs.push({
              cpOrgID: cpOrgID,
              shortName: shortName,
              legalName: legalName,
              logoURL: logoURL,
              clientType: 'Counterparty',
              creationDate: creationDate,
            })
          }

          // Create the ENTITY, if there are multiple agreements for one Ent (in the same module), then reuse based on cpEntID
          let cpEntID = generateRandomString(5)
          let entityType = getActorsForModule(module, true, true)
          if(ents.filter((e) => e.legalName.toLowerCase() === legalName.toLowerCase() && e.entityType === entityType)[0] !== undefined) {
            cpEntID = ents.filter((e) => e.legalName.toLowerCase() === legalName.toLowerCase() && e.entityType === entityType)[0].cpEntID;
          } else {
            ents.push({
              cpEntID: cpEntID,
              shortName: shortName,
              legalName: legalName,
              orgID: props.user.orgID,
              parentOrgID: cpOrgID,
              entityType: entityType,
              entityCategory: entityCategory,
              address: [
                {
                  addressType: 'billto',
                  streetAndNo: streetAndNo,
                  address2: address2,
                  zip: zip,
                  city: city,
                  state: state,
                  country: country,
                }],
              logoURL: logoURL,
              isLive: true,
              creationBy: props.user._id,
              creationDate: creationDate,
            })
          }

          // Create the RELATIONSHIP, for each ORG for each module
          let relID = generateRandomString(5)
          let relationshipType = ['sales','procurement'].includes(module) ? 'b2b' : ['partnerships'].includes(module) ? 'partnership' : module
          let relName = ['procurement'].includes(module) ? (shortName + ' | ' + props.org.shortName) : (props.org.shortName + ' | ' + shortName)
          let primOrgID = ['procurement'].includes(module) ? cpOrgID : props.user.orgID
          let secOrgID = ['procurement'].includes(module) ? props.user.orgID : cpOrgID
          let primCptyID = ['procurement'].includes(module) ? cpEntID : ourEntID
          let secCptyID = ['procurement'].includes(module) ? ourEntID : cpEntID

          if(rels.filter((r) => r.relationshipType === relationshipType && r.primaryOrgID === primOrgID && r.secondaryOrgID === secOrgID)[0] !== undefined) {
            relID = rels.filter((r) => r.relationshipType === relationshipType && r.primaryOrgID === primOrgID && r.secondaryOrgID === secOrgID)[0].relID;
          } else {
            rels.push({
              relID: relID,
              relationshipType: relationshipType,
              name: relName,
              customURL: '',
              primaryCptyID: primCptyID,
              secondaryCptyID: secCptyID,
              primaryOrgID: primOrgID,
              secondaryOrgID: secOrgID,
              invisibleFor: 'none',
              whitelabel: props.org.whiteLabel !== undefined && props.org.whiteLabel !== null && props.org.whiteLabel.color !== undefined && props.org.whiteLabel.contrastColor !== undefined ?
                { orgName: props.org.shortName, logoURL: props.org.logoURL, color: props.org.whiteLabel.color, contrastColor: props.org.whiteLabel.contrastColor }: null,
              creationBy: props.user._id,
              creationDate: creationDate,
              deactive: false,
              deactiveReason: 'None',
            })
          }
          
          agrs.push({
            fileName: fileName,
            module: module,
            relID: relID,
            primaryCptyID: primCptyID,
            secondaryCptyID: secCptyID,
            agrTypeID: agrTypeID,
            agrFullName: agrFullName,
            agrShortName: agrShortName,
            effectiveDate: effectiveDate,
            renewalType: renewalType,
            effectiveTerm: effectiveTerm,
            expiryDate: calculateExpiryDate(effectiveDate, renewalType, effectiveTerm),
            creationBy: props.user._id,
            creationDate: creationDate,
          })

        } else {
          err.push('Unable to import line ' + (i+1) + ' - insufficient fields.')
        }
      })

      if(err.length > 0 || warn.length > 0) {
        setHistoricalImport({...historicalImport, err: err, warn: warn})

      } else { // All good, send to the backend
        /*
        console.log("orgs", orgs)
        console.log("ents", ents)
        console.log("rels", rels)
        console.log("agrs", agrs)*/
        
        axios.post('/import/historical', {
          orgs: orgs,
          ents: ents,
          rels: rels,
          agrs: agrs,
        }).then(function(res) {
          setHistoricalImport({...historicalImport, completed:true})

        }).catch(function(err){ console.log("err in import", err) })
      }
    }
  }

  const RenderSnack = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
        open={Boolean(snack)}
        autoHideDuration={6000}
        onClose={e => setSnack(null)}
        message={
          snack === '2' ?
            <Typography variant="subtitle2">The entity was succesfully created.</Typography>
          :snack === '3' ?
            <Typography variant="subtitle2">The payment method was updated.</Typography>
          :snack === '4' ?
            <Typography variant="subtitle2">Your subscription is being terminated.</Typography>
          : "Something happened"}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={e => setSnack(null)}>
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </React.Fragment>
        }
      />
    )
  }

  switch(props.action) {
    case 'new': 
      if(confirmedNavigate) {
        return (<Redirect to="/core/admin/company/entities/2" push={true} />)
      } else {
        return (
          <div>
            <Header module="admin" 
              active="Company"
              crumbs={[
                {name: "Company Settings", to: "/core/admin/company"}, 
                {name: "Entities", to: "/core/admin/company/entities/1"}, 
                {name: "New", to: "active"} ]}
              user={props.user} 
              org={props.org}
              modules={modules}
              withSubHead={true}
              notifications={props.notifications}
              markNotificationAsRead={props.markNotificationAsRead}
              markAllNotificationsAsRead={props.markAllNotificationsAsRead}
           />
            
            <HeaderSub
              buttons={[
                {active: false, icon: faCog, name: "Settings", to: "/core/admin/company"},
                {active: false, icon: faStar, name: "Branding", to: "/core/admin/company/logo/1"},
                {active: true, icon: faUniversity, name: (props.org !== undefined && props.org.clientType === 'legalOrg' ? "Clients" : "Entities"), to: "/core/admin/company/entities/1"},
                {active: false, icon: faSignature, name: "Signatory", to: "/core/admin/company/signatory/1"},
                {active: false, icon: faSync, name: "Subscription", to: "/core/admin/company/subscription/1"},
              ]} />

            <Container maxWidth="lg">
              <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
                <Grid item>
                  <Box mb={1} mt={2}>
                    <Typography align="center" variant="h4" className={classes.bold}>
                        You are adding a new {props.org !== undefined && props.org.clientType === 'legalOrg' ? "client" : "entity"}
                    </Typography>
                    <Box mb={2} mt={2} align="center">
                      <Button component={RouterLink} to="/core/admin/company/entities/1" className={classes.grey600}>
                        <FontAwesomeIcon icon={faArrowCircleLeft} className={classes.pr15} color={theme.palette.grey[600]} />
                        {props.org !== undefined && props.org.clientType === 'legalOrg' ? "Client" : "Entity"} search
                      </Button>
                    </Box>
                    { formError !== '' ? 
                      <Typography variant="subtitle1" align="center" color="error">
                        {formError}
                      </Typography>
                    : '' }
                  </Box>
                </Grid>
      
                <Grid item>
                  <Box mb={5} mt={1}>
                  {props.org !== undefined && props.org.clientType === 'legalOrg' && newClientLogo === null ?
                  <Box mt={0} mb={3} align="center">                
                      <UploadImg 
                        type="logo" 
                        parentCallback={callbackLogoUploadFormClient}
                        initialImg={newClientLogo} />
                  </Box>
                  :
                  <EntityForm 
                      parentCallback={callbackEntityForm}
                      type={props.org !== undefined && props.org.clientType === 'legalOrg' ? 'new-client' : 'new'}
                      initialValues={{
                        entityCategory: 'Company',
                        shortName: props.org !== undefined && props.org.clientType === 'legalOrg' ? '' : props.org.shortName, 
                        legalName: '', 
                        streetAndNo: '',
                        address2: '',
                        city: '',
                        zip: '',
                        state: '',
                        country: '', }} />
                  }
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </div>
        )
      }

    case 'deactivate' :
      if(confirmedNavigate || activeEntity === undefined) {
        return (<Redirect to="/core/admin/company/entities/1" push={true} />)
      } else {

        return(
          <div>

            <Header module="admin" 
              active="Company"
              crumbs={[
                {name: "Company Settings", to: "/core/admin/company"}, 
                {name: "Entities", to: "/core/admin/company/entities/1"}, 
                {name: "Deactivate", to: "active"} ]}
              user={props.user}
              org={props.org}
              modules={modules}
              withSubHead={true}
              notifications={props.notifications}
              markNotificationAsRead={props.markNotificationAsRead}
              markAllNotificationsAsRead={props.markAllNotificationsAsRead} />
            
            <HeaderSub
              buttons={[
                {active: false, icon: faCog, name: "Settings", to: "/core/admin/company"},
                {active: false, icon: faStar, name: "Branding", to: "/core/admin/company/logo/1"},
                {active: true, icon: faUniversity, name:  (props.org !== undefined && props.org.clientType === 'legalOrg' ? "Clients" : "Entities"), to: "/core/admin/company/entities/1"},
                {active: false, icon: faSignature, name: "Signatory", to: "/core/admin/company/signatory/1"},
                {active: false, icon: faSync, name: "Subscription", to: "/core/admin/company/subscription/1"},
              ]} />

            <Container maxWidth="lg">
              <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
                <Grid item container alignItems="center" justify="center">
                  <Box mb={5} mt={3}>
                    <FontAwesomeIcon icon={faExclamationTriangle} size="5x" color={activeEntity.isLive ? theme.palette.error.main : theme.palette.success.main} />
                  </Box>
                </Grid>
                <Grid item container alignItems="center" justify="center">
                  <Typography align="center" variant="body1">
                    Are you sure you want to {activeEntity.isLive ? "deactivate" : "reactivate"} the following entity?
                  </Typography>              
                </Grid>
                <Grid item container direction="column" alignItems="center" justify="center">

                  <Box mt={6} mb={6}>
                    <Typography align="center" variant="h5">
                      {activeEntity.shortName}
                    </Typography>
                    <Typography align="center" color="textSecondary" variant="body1">
                      {activeEntity.legalName}
                    </Typography>
                  </Box>

                </Grid>
                <Grid item container alignItems="center" justify="center">
                  <Box mb={5}>
                    <Button variant="contained" disableElevation
                      className={activeEntity.isLive ? classes.errorButton : classes.successButton} onClick={confirmDeactivate}>
                      Confirm
                    </Button>
                    &nbsp;&nbsp;
                    <Button variant="outlined" className={classes.error}
                      component={RouterLink} to="/core/admin/company/entities/1">
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </Grid>    
            </Container>


          </div>

        )
      }

    case 'edit' :
      if(confirmedNavigate || activeEntity === undefined) {
        return (<Redirect to="/core/admin/company/entities/1" push={true} />)
      } else {

        return(
          <div>

            <Header module="admin" 
              active="Company"
              crumbs={[
                {name: "Company Settings", to: "/core/admin/company"}, 
                {name: "Entities", to: "/core/admin/company/entities/1"}, 
                {name: "Edit", to: "active"} ]}
              user={props.user}
              org={props.org}
              modules={modules}
              withSubHead={true}
              notifications={props.notifications}
              markNotificationAsRead={props.markNotificationAsRead}
              markAllNotificationsAsRead={props.markAllNotificationsAsRead} />
            
            <HeaderSub
              buttons={[
                {active: false, icon: faCog, name: "Settings", to: "/core/admin/company"},
                {active: false, icon: faStar, name: "Branding", to: "/core/admin/company/logo/1"},
                {active: true, icon: faUniversity, name: (props.org !== undefined && props.org.clientType === 'legalOrg' ? "Clients" : "Entities"), to: "/core/admin/company/entities/1"},
                {active: false, icon: faSignature, name: "Signatory", to: "/core/admin/company/signatory/1"},
                {active: false, icon: faSync, name: "Subscription", to: "/core/admin/company/subscription/1"},
              ]} />

            <Container maxWidth="lg">
              <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
                <Grid item>
                  <Box mb={1} mt={2}>
                    <Typography align="center" variant="h4" className={classes.bold}>
                        Edit an existing entity
                    </Typography>
                    <Box mb={2} mt={2} align="center">
                      <Button component={RouterLink} to="/core/admin/company/entities/1" className={classes.grey600}>
                        <FontAwesomeIcon icon={faArrowCircleLeft} className={classes.pr15} color={theme.palette.grey[600]} />
                        Entity search
                      </Button>
                      {props.org !== undefined && props.org.clientType === 'legalOrg' ?
                      <>
                      <Button onClick={e => setLogoUpdating(!logoUpdating)} className={classes.grey600}>
                        {logoUpdating ? "Update Entity" : "Update Logo"}
                        <FontAwesomeIcon icon={logoUpdating ? faUniversity : faImage} className={classes.pl15} color={theme.palette.grey[600]} />
                      </Button>
                      </>
                      : '' }
                    </Box>
                    { formError !== '' ? 
                      <Typography variant="subtitle1" align="center" color="error">
                        {formError}
                      </Typography>
                    : '' }
                  </Box>
                </Grid>

                { (props.orgPending || props.subsPending) ? 
                <Grid item container alignItems="center" justify="center">
                  <Box mt={3} mb={10} align="center">
                    <SkeletonCustom type="form" />
                  </Box>
                </Grid>
                : 
                (
                  (props.orgError || props.subsError) !== null ? 
                  (
                    props.orgError !== null ? 
                    (<ErrorMsg clearError={props.clearOrgError} msg="Unable to update the company" />)
                    :
                    (<ErrorMsg clearError={props.clearSubsError} msg="Unable to update the entity" />)
                  )
                  :props.org !== undefined && props.org.clientType === 'legalOrg' && logoUpdating ?
                  <Box mt={3} mb={3} align="center">                
                      <UploadImg 
                        type="logo" 
                        parentCallback={callbackLogoUploadFormClient}
                        initialImg={activeEntity.logoURL} />
                  </Box>
                  :
                    (
                    <Grid item>
                      <Box mb={5} mt={1}>
                      <EntityForm 
                          parentCallback={callbackEntityUpdateForm}
                          type={
                            activeEntity.isLive && props.org !== undefined && props.org.clientType === 'legalOrg' ? 'update-client' :
                            activeEntity.isLive ? 'update' : 'disabled'}
                          initialValues={{
                            entityCategory: activeEntity.entityCategory !== undefined ? activeEntity.entityCategory : 'Company',
                            shortName: activeEntity.shortName, 
                            legalName: activeEntity.legalName, 
                            streetAndNo: activeEntity.address.filter((a) => a.addressType === 'billto')[0] !== undefined ?
                              activeEntity.address.filter((a) => a.addressType === 'billto')[0].streetAndNo : '',
                            address2: activeEntity.address.filter((a) => a.addressType === 'billto')[0] !== undefined && 
                              activeEntity.address.filter((a) => a.addressType === 'billto')[0].address2 !== undefined ?
                              activeEntity.address.filter((a) => a.addressType === 'billto')[0].address2 : '',
                            city: activeEntity.address.filter((a) => a.addressType === 'billto')[0] !== undefined ?
                              activeEntity.address.filter((a) => a.addressType === 'billto')[0].city : '',
                            zip: activeEntity.address.filter((a) => a.addressType === 'billto')[0] !== undefined ?
                              activeEntity.address.filter((a) => a.addressType === 'billto')[0].zip : '',
                            state: activeEntity.address.filter((a) => a.addressType === 'billto')[0] !== undefined ?
                              activeEntity.address.filter((a) => a.addressType === 'billto')[0].state : '',
                            country: activeEntity.address.filter((a) => a.addressType === 'billto')[0] !== undefined ?
                              activeEntity.address.filter((a) => a.addressType === 'billto')[0].country : ''}} />
                      </Box>
                      <Box mt={2} align="center">
                        <Button 
                          variant="outlined" 
                          color="secondary" 
                          component={RouterLink}
                          to={"/core/admin/company/deactivate/" + activeEntity._id}>
                          {activeEntity.isLive ? "Deactivate" : "Reactivate"} entity
                        </Button>
                      </Box>
                    </Grid>
                    )
                  )
                  }
              </Grid>
            </Container>
          </div>

        )
      }
      case 'logo' :
  
        return(
          <div>
            <Header module="admin" 
              active="Company"
              crumbs={[
                {name: "Company Settings", to: "/core/admin/company"}, 
                {name: "Logo", to: "active"} ]}
              user={props.user}
              org={props.org}
              modules={modules}
              withSubHead={true}
              notifications={props.notifications}
              markNotificationAsRead={props.markNotificationAsRead}
              markAllNotificationsAsRead={props.markAllNotificationsAsRead} />

            <HeaderSub
              buttons={[
                {active: false, icon: faCog, name: "Settings", to: "/core/admin/company"},
                {active: true, icon: faStar, name: "Branding", to: "/core/admin/company/logo/1"},
                {active: false, icon: faUniversity, name: (props.org !== undefined && props.org.clientType === 'legalOrg' ? "Clients" : "Entities"), to: "/core/admin/company/entities/1"},
                {active: false, icon: faSignature, name: "Signatory", to: "/core/admin/company/signatory/1"},
                {active: false, icon: faSync, name: "Subscription", to: "/core/admin/company/subscription/1"},
              ]} />

            <Container maxWidth="lg">
              {props.user.role !== 'Counterparty' && (props.user.introComplete === undefined || props.user.introComplete.dashboard === undefined || props.user.introComplete.dashboard === null || !props.user.introComplete.dashboard ) &&
              props.org !== undefined && props.org.clientType !== 'legalOrg' ?
                  <GetStarted 
                  fromDashboard={false}
                  step={"1_1"}
                  pushDown={true}
                  module={props.module}
                  user={props.user}
                  org={props.org}
                  subs={props.subs}
                  />
              :''}
              
              <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
                <Grid item>
                  <Box mb={5} mt={2}>
                    <Typography align="center" variant="h4" className={classes.bold}>
                        Company Branding&nbsp;&nbsp;<InformationalTooltip msg={"Update your logo by clicking on it. You can only upload a round version of the logo, typically this is the same as your company profile logo on platforms such as LinkedIn and Twitter." + 
                        "\n\nSelect the white label level, Email white labeling is possible for all Enterprise Accounts and Full white labeling is on request." +
                        (props.org.whiteLabel !== undefined && props.org.whiteLabel !== null && props.org.whiteLabel.color !== undefined ? "\n\nYou can update your company colors by clicking on it, or by clicking on the \"Sample Button\". The accent color will be your primary company color and the contrast color will be a color that works well on top of the accent color (typically white or black)." : "")} />
                    </Typography>
                  </Box>
                </Grid>
      
              
                { (props.orgPending || props.subsPending) ? 
                <Grid item container alignItems="center" justify="center">
                  <Box mt={3} mb={10} align="center">
                    <SkeletonCustom type="form" />
                  </Box>
                </Grid>
                : 
                (
                  (props.orgError || props.subsError) !== null ? 
                  (
                    props.orgError !== null ? 
                    (<ErrorMsg clearError={props.clearOrgError} msg="Unable to update the company" />)
                    :
                    (<ErrorMsg clearError={props.clearSubsError} msg="Unable to update the entity" />)
                  ):
                  (
                  <Grid item>
                    
                    <Box mt={0} mb={3} align="center">                
                      <UploadImg 
                        type="logo" 
                        parentCallback={callbackLogoUploadForm}
                        initialImg={props.org.logoURL} />
                    </Box>

                    <Box align="center" mt={3}>
                      <Box align="center" mb={3}>
                        <FormControl>
                          <FormLabel>PDF Branding</FormLabel>
                          <RadioGroup row name="headerInPdf"
                          value={props.org !== undefined && props.org.orgSettings !== undefined && props.org.orgSettings !== null && props.org.orgSettings.headerInPdf ?
                          "headerInPdf": "none"}
                          onChange={e => handleSettingsChange('headerInPdf', e.target.value)}
                          >
                            <FormControlLabel value={"none"} control={<Radio />} label="None" />
                            <FormControlLabel value={"headerInPdf"} control={<Radio />} label={(['legalOrg'].includes(props.org.clientType) ? "Client" : "Our") + " Logo + Address"}
                            disabled={props.org === undefined || !['custE', 'legalOrg'].includes(props.org.clientType)} />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box align="center" mb={3}>
                        <FormControl>
                          <FormLabel>White Label counterparty experience</FormLabel>
                          <RadioGroup name="whiteLabel"
                          row value={props.org !== undefined && props.org.orgSettings !== undefined && props.org.orgSettings !== null && props.org.orgSettings.whiteLabelLevel !== undefined ?
                          props.org.orgSettings.whiteLabelLevel : "none"}
                          onChange={e => handleSettingsChange('whiteLabelLevel', e.target.value)}
                          >
                            <FormControlLabel value="none" control={<Radio />} label="None" />
                            <FormControlLabel value="email" control={<Radio />} label="Email"
                            disabled={props.org === undefined || !['custE', 'legalOrg'].includes(props.org.clientType)} />
                            <FormControlLabel value="full" control={<Radio />} label="Full" 
                              disabled={props.org === undefined || !['custE', 'legalOrg'].includes(props.org.clientType) || props.org.orgSettings === undefined || props.org.orgSettings === null || props.org.orgSettings.whiteLabel !== "full"} />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      {/*props.org === undefined || !['custE', 'legalOrg'].includes(props.org.clientType) || props.org.orgSetting === undefined || props.org.orgSetting === null || props.org.orgSetting.whiteLabel !== "full" ?
                      <>
                      <Typography>Further white label functions are not yet enabled for your company.</Typography>
                      <Button color="secondary" variant="text" component={RouterLink} to="/contact">Contact Canveo to Upgrade</Button>
                      </> : ''*/}
                    </Box>

                    
                    {whiteLabelColorChange !== null && props.org !== undefined && props.org !== null &&
                    ['custE'].includes(props.org.clientType) ?
                    <Box align="center" mt={4}>
                      <Typography variant="body2">Define company colors</Typography>
                      <Button color="primary" variant="text" onClick={toggleWhiteLabelColorChange}>Cancel</Button>
                      {whiteLabelColorChange.error !== undefined && whiteLabelColorChange.error !== null ?
                      <Box mt={1}>
                        <Typography color="error" variant="subtitle2">{whiteLabelColorChange.error}</Typography>
                      </Box>
                      :''}
                      <Box mt={3} style={{width: '180px'}}>
                        <TextField 
                          fullWidth
                          variant="outlined"
                          id="color"
                          label="Accent Color"
                          placeholder="Accent Color (hex)"
                          name="color"
                          value={whiteLabelColorChange.color}
                          onChange={e => setWhiteLabelColorChange({...whiteLabelColorChange, color: e.target.value})}
                          InputProps={{
                          startAdornment: (
                              <InputAdornment position="start">
                              <FontAwesomeIcon icon={faFillDrip} className={classes.adornIcon} />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Box mt={2} mb={2}>
                        <TextField 
                          fullWidth
                          variant="outlined"
                          id="color"
                          label="Contrast Color"
                          placeholder="Contrast Color (hex)"
                          name="color"
                          value={whiteLabelColorChange.contrastColor}
                          onChange={e => setWhiteLabelColorChange({...whiteLabelColorChange, contrastColor: e.target.value})}
                          InputProps={{
                          startAdornment: (
                              <InputAdornment position="start">
                              <FontAwesomeIcon icon={faPaintBrush} className={classes.adornIcon} />
                              </InputAdornment>
                            ),
                          }}
                        />
                        </Box>
                        <Button variant="contained" color="primary" disableElevation fullWidth
                        onClick={handleWhiteLabelColorSubmit}>Submit</Button>
                      </Box>
                    </Box>
                    :props.org.whiteLabel !== undefined && props.org.whiteLabel !== null && 
                    props.org.whiteLabel.color !== undefined && props.org.whiteLabel.contrastColor !== undefined && 
                    props.org !== undefined && props.org !== null && ['custE'].includes(props.org.clientType) ?
                    <Box align="center" mt={4}>
                      <Typography variant="body2">Define company colors</Typography>
                      <Typography variant="body2">(click to edit)</Typography>
                      <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item>
                          <Box align="center" style={{width: '120px'}} mt={3}>
                            <b>Accent</b>
                            <div style={{backgroundColor: props.org.whiteLabel.color}} className={classes.colorPicker} 
                            onClick={toggleWhiteLabelColorChange} />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box align="center" style={{width: '120px'}} mt={3}>
                            <b>Contrast</b>
                            <div style={{backgroundColor: props.org.whiteLabel.contrastColor}} className={classes.colorPicker}
                            onClick={toggleWhiteLabelColorChange} />
                          </Box>
                        </Grid>
                      </Grid>
                      <Box mt={3}>
                        <div className={classes.sampleButton} onClick={toggleWhiteLabelColorChange}>Sample Button</div>
                      </Box>

                    </Box>
                    :''}
                    
                  </Grid>
                  )
                )
                }
              </Grid>
              </Container>
          </div>

        )

    case 'signatory' :
  
        return(
          <div>
            <Header module="admin" 
              active="Company"
              crumbs={[
                {name: "Company Settings", to: "/core/admin/company"}, 
                {name: "Default Signatory", to: "active"} ]}
              user={props.user}
              org={props.org}
              modules={modules}
              withSubHead={true}
              notifications={props.notifications}
              markNotificationAsRead={props.markNotificationAsRead}
              markAllNotificationsAsRead={props.markAllNotificationsAsRead} />

            <HeaderSub
              buttons={[
                {active: false, icon: faCog, name: "Settings", to: "/core/admin/company"},
                {active: false, icon: faStar, name: "Branding", to: "/core/admin/company/logo/1"},
                {active: false, icon: faUniversity, name: (props.org !== undefined && props.org.clientType === 'legalOrg' ? "Clients" : "Entities"), to: "/core/admin/company/entities/1"},
                {active: true, icon: faSignature, name: "Signatory", to: "/core/admin/company/signatory/1"},
                {active: false, icon: faSync, name: "Subscription", to: "/core/admin/company/subscription/1"},
              ]} />

            <Container maxWidth="lg">
              {props.user.role !== 'Counterparty' && (props.user.introComplete === undefined || props.user.introComplete.dashboard === undefined || props.user.introComplete.dashboard === null || !props.user.introComplete.dashboard )  &&
               props.org !== undefined && props.org.clientType !== 'legalOrg' ?
                  <GetStarted 
                  fromDashboard={false}
                  step={"2_0"}
                  pushDown={true}
                  module={props.module}
                  user={props.user}
                  org={props.org}
                  subs={props.subs}
                  />
              :''}
              <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
                <Grid item>
                  <Box mb={5} mt={2}>
                    <Typography align="center" variant="h4" className={classes.bold}>
                        Default Signers&nbsp;&nbsp;<InformationalTooltip msg={"Define default signers for each legal entity of your company.\n\nDefault signers will automatically be inserted as a signer for agreements created for that specific legal entity. Signers can be removed and/or additional signers can be added during the agreement negotiation."} />
                    </Typography>
                  </Box>
                </Grid>
      
              
                { (props.orgPending || props.subsPending) ? 
                <Grid item container alignItems="center" justify="center">
                  <Box mt={3} mb={10} align="center">
                    <SkeletonCustom type="form" />
                  </Box>
                </Grid>
                : 
                (
                  (props.orgError || props.subsError) !== null ? 
                  (
                    props.orgError !== null ? 
                    (<ErrorMsg clearError={props.clearOrgError} msg="Unable to update the company" />)
                    :
                    (<ErrorMsg clearError={props.clearSubsError} msg="Unable to update the entity" />)
                  )
                  :
                  props.org !== undefined && props.org.clientType === 'legalOrg' ?
                  <Box align="center">

                    <Typography>Not applicable for Legal Orgs.</Typography>

                  </Box>
                  :
                  (
                    <Box align="center">
                    <Box className={classes.boxResizing}>
                    {addingSignatoryForEntity !== null ?
                    
                    <>
                    <Typography variant="h6" align="center">Add signer for {props.subs.filter((s) => s._id === addingSignatoryForEntity)[0] !== undefined ? props.subs.filter((s) => s._id === addingSignatoryForEntity)[0].legalName : "Unknown"}</Typography>
                    <Box mt={4} mb={2}>
                      <SelectUsers
                          users={props.users !== undefined ? props.users.filter((u) => u.active) : []}
                          parentCall={handleUserSearchChange}
                          label="Select Signatory"
                      />
                    </Box>
                    <Box className={classes.boxResizing}>
                          <Button
                            color="primary"
                            disableElevation
                            variant="contained"
                            fullWidth
                            onClick={handleSignatorySubmit}
                          >Submit</Button>
                    </Box>
                    <Box mt={2} align="right">
                      <Button color="primary" size="small" variant="text" onClick={e=>setAddingSignatoryForEntity(null)}>Cancel</Button>
                    </Box>
                    </>
                    :
                    <List disablePadding>
                      {props.subs
                          .filter((s) => s.isLive)
                          .sort((a,b) => (a.shortName > b.shortName) ? 1 : ((b.shortName > a.shortName) ? -1 : 0))
                          .map((sub) => (
                            <Box key={sub._id} mt={3}>
                            <ListItem>
                              <ListItemText disableTypography
                                primary={<Typography className={classes.listItemLevel1}>
                                  {trunc(sub.legalName, 35)}&nbsp;&nbsp;
                                  {sub.address.filter((a) => a.addressType === 'billto')[0] !== undefined && sub.address.filter((a) => a.addressType === 'billto')[0].country !== undefined ? countryToFlag(sub.address.filter((a) => a.addressType === 'billto')[0].country) : ""}</Typography>}
                              />
                              <ListItemSecondaryAction>
                                <Button color="primary" edge="end" size="small" onClick={e => setAddingSignatoryForEntity(sub._id)}>
                                  Add Signer&nbsp;&nbsp;<FontAwesomeIcon icon={faUserPlus} />
                                </Button>
                              </ListItemSecondaryAction>
                            </ListItem>

                            <List component="div" disablePadding>
                            {sub.signatory.length > 0 ?
                            sub.signatory
                            .sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0))
                            .map((s,i) => (

                              <ListItem className={classes.nested} key={sub._id + '_' + s.uid + '_' + i}>
                                  <ListItemText disableTypography 
                                      primary={
                                      <Typography className={props.users.filter((u) => u._id === s.uid)[0] !== undefined ? classes.listItemLevel2 : classes.listItemLevel2Undefined}>
                                        <FontAwesomeIcon icon={faCaretRight}/>&nbsp;&nbsp;{
                                          props.users.filter((u) => u._id === s.uid)[0] !== undefined ?
                                          props.users.filter((u) => u._id === s.uid)[0].displayName : "To be defined"}
                                      </Typography>} />
                              <ListItemSecondaryAction>
                                {props.users.filter((u) => u._id === s.uid)[0] !== undefined ? 
                                <Button edge="end" size="small" className={classes.delB} onClick={e=>handleRemoveSigner(sub._id, s.uid)}>
                                  Remove&nbsp;&nbsp;<FontAwesomeIcon icon={faTrash} />
                                </Button>
                                : '' }
                              </ListItemSecondaryAction>
                              </ListItem>

                            ))
                            :
                            <ListItem className={classes.nested} key={sub._id + '_none'}>
                              <ListItemText disableTypography 
                                  primary={
                                  <Typography className={classes.listItemLevel2Undefined}>
                                    <FontAwesomeIcon icon={faCaretRight}/>&nbsp;&nbsp;{"To be defined"}
                                  </Typography>} />
                            </ListItem>
                            }
                            </List>
                            </Box>
                          ))
                      }
                      
                    </List>
                    }
                    </Box>
                    </Box>
                  )
                )
                }
              </Grid>
              </Container>
          </div>

        )

      case 'subscription' :
  
        return(
          <div>
            <Header module="admin" 
              active="Company"
              crumbs={[
                {name: "Company Settings", to: "/core/admin/company"}, 
                {name: "Subscription", to: "active"} ]}
              user={props.user}
              org={props.org}
              modules={modules}
              withSubHead={true}
              notifications={props.notifications}
              markNotificationAsRead={props.markNotificationAsRead}
              markAllNotificationsAsRead={props.markAllNotificationsAsRead} />

            <HeaderSub
              buttons={[
                {active: false, icon: faCog, name: "Settings", to: "/core/admin/company"},
                {active: false, icon: faStar, name: "Branding", to: "/core/admin/company/logo/1"},
                {active: false, icon: faUniversity, name: (props.org !== undefined && props.org.clientType === 'legalOrg' ? "Clients" : "Entities"), to: "/core/admin/company/entities/1"},
                {active: false, icon: faSignature, name: "Signatory", to: "/core/admin/company/signatory/1"},
                {active: true, icon: faSync, name: "Subscription", to: "/core/admin/company/subscription/1"},
              ]} />

            <Container maxWidth="lg">
              <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
                <Grid item>
                  <Box mb={5} mt={2}>
                    <Typography align="center" variant="h4" className={classes.bold}>
                      Subscription details&nbsp;&nbsp;<InformationalTooltip msg={"This page shows the account level and subscription details for your company.\n\nUpdate your payment information, tax id or billing address details by click on the respective \"pen icon\".\n\nFor any billing related questions please contact us via ar@getcanveo.com."} />
                    </Typography>
                  </Box>
                </Grid>
              
                {(props.orgPending || props.subsPending) ? 

                <Grid item container alignItems="center" justify="center">
                  <Box mt={3} mb={10} align="center">
                    <SkeletonCustom type="form" />
                  </Box>
                </Grid>
                
                :props.entid === 'done' ?
                <Box mt={2} mb={8} align="center">
                  <Typography align="center">Thanks for your business, your upgrade is being processed.</Typography>
                  <Box mt={6} align="center">
                    <Button color="secondary" variant="contained" disableElevation onClick={e => history.push('/core/admin/company/subscription/1')}>Got it</Button>
                  </Box>

                </Box>
                :!upgrading && !payMethod && !emailUpdating && !addressUpdating && !canceling ?

                <Box mt={0} mb={8} align="center">
                  <Box>                        
                    <Card variant="outlined" className={classes.subsCard}>
                      <CardContent>
                        <Typography align="center">
                        Account Level: <b>{
                          props.org.clientType === 'custS' ? "Starter" :
                          props.org.clientType === 'custB' ? "Business" :
                          props.org.clientType === 'cust' ? "Premium" :
                          props.org.clientType === 'custE' ? "Enterprise" : "Not Defined"}</b>
                        </Typography>
                        <Typography align="center">
                          Subscription: <b>{
                            stripeProducts.length > 0 ? 
                              (stripeProducts.filter((p) => p.active && p.qty).map((prod, i) => (i > 0 ? ", " : "") + (prod.qty > 1 ? (prod.qty + " x ") : '') + prod.name )) : 
                              "Not Defined"}</b>                      
                        </Typography>
                        <Typography align="center">
                          Payment Method: <b>{
                            stripePayMethod !== null && stripePayMethod.hasCard ? 
                              stripePayMethod.brand[0].toUpperCase() + stripePayMethod.brand.slice(1).toLowerCase() + " **" + stripePayMethod.last4 : 
                              "Not Defined"}</b>
                              <IconButton size="small" className={classes.editIconB} onClick={e => setPayMethod(true)}>
                                <FontAwesomeIcon icon={faPen} className={classes.editIcon} />
                              </IconButton>
                        </Typography>
                        <Typography>
                          Billing Email: <b>{stripeEmail !== undefined && stripeEmail !== null ? stripeEmail : "Not Defined"}</b>
                            <IconButton size="small" className={classes.editIconB} onClick={e => setEmailUpdating(true)}>
                              <FontAwesomeIcon icon={faPen} className={classes.editIcon} />
                            </IconButton>
                        </Typography>
                        <Typography>
                          Billing Address: <b>{stripeAddress !== null && stripeAddress.country !== undefined ? 
                            trunc((stripeAddress.line1 + ", " + (stripeAddress.line2 !== '' ? (stripeAddress.line2 + ", ") : '') + stripeAddress.city + ", " + stripeAddress.country), 30) : "Not Defined"}</b>
                            <IconButton size="small" className={classes.editIconB} onClick={e => setAddressUpdating(true)}>
                              <FontAwesomeIcon icon={faPen} className={classes.editIcon} />
                            </IconButton>
                        </Typography>
                        <Typography>
                          Tax ID: <b>{stripeTaxids.length > 0 ? (stripeTaxids.map((sti, i) => (i > 0 ? ", " : "") + sti.value)) : 
                          stripeAddress !== null && stripeAddress.country !== undefined && getTaxTypesByCountry(stripeAddress.country).length === 0 ? 
                          "Not Applicable" : "Not Defined"}</b>
                            <IconButton size="small" className={classes.editIconB} onClick={e => setAddressUpdating(true)}>
                              <FontAwesomeIcon icon={faPen} className={classes.editIcon} />
                            </IconButton>
                        </Typography>

                      </CardContent>
                      <CardActions>
                          <Button onClick={e => setUpgrading(true)} disabled={props.org.stripecusid === undefined || stripeProducts.length === 0}
                            variant="text" color="primary" className={classes.subsButL}><FontAwesomeIcon icon={faArrowAltCircleUp} />&nbsp;&nbsp;Upgrade Subscription</Button>
                          <Button onClick={e => setCanceling(true)} disabled={props.org.stripecusid === undefined || stripeProducts.length === 0}
                            variant="text" className={classes.subsButR}><FontAwesomeIcon icon={faBan} />&nbsp;&nbsp;Cancel Subscription</Button>
                      </CardActions>
                    </Card>
                  </Box>

                  {props.org !== undefined && props.org !== null && ['cust','custE'].includes(props.org.clientType) ?
                  <Box mt={2}>
                    <Card variant="outlined" className={classes.subsCard}>

                      <Grid container direction="row">
                        <Grid item xs={12}>
                            <FormGroup name="integrations">
                            <Box align="center" mt={2} mb={2}>
                              <Box align="left" mt={1} mb={1} style={{width: '140px'}}>
                              <FormControlLabel 
                              control={
                                <Switch
                                checked={props.org !== undefined && props.org.integrations !== undefined && props.org.integrations !== null && 
                                  props.org.integrations.salesforce !== undefined && props.org.integrations.salesforce !== null ? true : false}
                                onChange={e => handleInstallIntegration(e.target.checked, 'salesforce')} />} 
                              label={<img src={salesforce} alt="Salesforce" width="60" style={{margin: '7px 5px 0px 5px'}} />}
                              />
                              </Box>
                              <Box align="left" mt={1} mb={1} style={{width: '140px'}}>
                              <FormControlLabel 
                              control={
                                <Switch
                                checked={props.org !== undefined && props.org.integrations !== undefined && props.org.integrations !== null && 
                                  props.org.integrations.hubspot !== undefined && props.org.integrations.hubspot !== null ? true : false}
                                onChange={e => handleInstallIntegration(e.target.checked, 'hubspot')} />} 
                              label={<img src={hubspot} alt="Hubspot" width="70" style={{margin: '7px 5px 0px 5px'}} />}
                              />
                              </Box>
                            </Box>
                            </FormGroup>
                        </Grid>
                      </Grid>


                    </Card>
                  </Box>
                  :''}


                  <Box mt={4} mb={0} align="center">
                    <Box className={classes.boxResizing}>
                    {stripeInvoices.length > 0 ?
                    <List>
                      {stripeInvoices
                      .sort((a,b) => (a.creationDate < b.creationDate) ? 1 : ((b.creationDate < a.creationDate) ? -1 : 0))
                      .slice(0, 10)
                      .map((inv) => (
                        <ListItem key={inv.invid}>
                          <ListItemAvatar>
                            {['paid'].includes(inv.status) ?
                            <Avatar className={classes.avSuccess}><FontAwesomeIcon icon={faCheck} /></Avatar>
                            :['draft', 'open'].includes(inv.status) ?
                            <Avatar className={classes.avPending}><FontAwesomeIcon icon={faEllipsisH} /></Avatar>
                            :['uncollectible'].includes(inv.status) ?
                            <Avatar className={classes.avError}><FontAwesomeIcon icon={faExclamation} /></Avatar>
                            : // void
                            <Avatar><FontAwesomeIcon icon={faBan} /></Avatar>
                            }
                          </ListItemAvatar>
                          <ListItemText 
                            primary={
                              <Typography style={{fontWeight: '700'}}>
                                <span>{getCcyByISO(inv.ccy) + inv.amountDue}</span>&nbsp;&nbsp;&nbsp;
                                <span style={
                                  ['paid'].includes(inv.status) ? {color: theme.palette.success.main} :
                                  ['draft', 'open'].includes(inv.status) ? {color: theme.palette.warning.main} :
                                  ['uncollectible'].includes(inv.status) ? {color: theme.palette.error.main} :
                                    {color: theme.palette.grey[600]} }>{inv.status[0].toUpperCase() + inv.status.slice(1)}</span>
                              </Typography>}
                            secondary={dayjs.unix(inv.creationDate).format("MMM D, YYYY h:mm A")}
                          />
                          {inv.hostedInvoiceURL !== null ?
                          <ListItemSecondaryAction>
                            <IconButton edge="end" className={classes.invIconB} onClick={() => window.open(inv.hostedInvoiceURL, "_blank")}>
                              <FontAwesomeIcon icon={faFileInvoiceDollar} className={classes.invIcon} />
                            </IconButton>
                          </ListItemSecondaryAction>
                          : '' }
                        </ListItem>
                      ))}
                    </List>
                    :
                    <Box mt={5}>
                    <Typography align="center">There are no Invoices yet.</Typography>
                    </Box>
                    }
                    </Box>
                  </Box>

                </Box>

                :addressUpdating ?

                <Box mt={2} mb={8} align="center">

                  <Box align="center">

                    <EntityForm 
                      parentCallback={callbackEntityAddressUpdatingForm}
                      type='billing'
                      hideRadioCategory={true}
                      initialValues={{
                        entityCategory: 'Company',
                        shortName: (props.org.shortName), 
                        legalName: (props.org.legalName), 
                        streetAndNo: stripeAddress.line1,
                        address2: stripeAddress.line2,
                        city: stripeAddress.city,
                        zip:  stripeAddress.postal_code,
                        state:  stripeAddress.state,
                        country: stripeAddress.country,
                        taxids: stripeTaxids }} />
                    
                  </Box>
                  <Box mt={1}>
                      <Button color="primary" variant="text" onClick={e => setAddressUpdating(false)}>Cancel</Button>
                  </Box>
                </Box>

                :emailUpdating ?

                <Box mt={2} mb={8} align="center">

                  <Box className={classes.boxResizing} align="center">
                    <Box mb={4}>
                      <Typography variant="subtitle1" className={classes.bold}>Update your billing Email</Typography>
                    </Box>

                    <TextField 
                      fullWidth
                      variant="outlined"
                      id="email"
                      label="Billing email address"
                      placeholder="Your company name (optional)"
                      name="email"
                      autoComplete="email"
                      value={stripeNewEmail}
                      onChange={e => handleStripeEmailUpdate(e.target.value)}
                      InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                          <FontAwesomeIcon icon={faEnvelope} className={classes.adornIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    
                    <Box mt={3}>
                      <Button onClick={handleStripeEmailConfirm}
                        color="primary" variant="contained" disableElevation fullWidth >
                        {loading ? <CircularProgress size={24} /> : ''}
                        Update Email address
                      </Button>
                    </Box>
                    <Box mt={1}>
                      <Button color="primary" variant="text" onClick={e => setEmailUpdating(false)}>Cancel</Button>
                    </Box>
                  </Box>
                </Box>

                :payMethod ?

                <Box mt={2} mb={8} align="center">

                  <Box className={classes.boxResizing} align="center">
                    <Box mb={4}>
                      <Typography variant="subtitle1" className={classes.bold}>Provide credit card details</Typography>
                    </Box>
                    <CardElement
                      onChange={(e) => handleCardInputChange(e)}
                      options={{
                        iconStyle: 'solid',
                        style: {
                          base: {
                            iconColor: theme.palette.primary.main,
                            fontSize: '16px',
                            color: theme.palette.grey[800],
                            '::placeholder': {
                              color: theme.palette.grey[500],
                            },
                          },
                          invalid: {
                            color: theme.palette.error.main,
                          },
                        },
                      }}
                    />
                    {cardError !== null ?
                    <Box align="center" mt={1}>
                      <Typography variant="body2" align="left" className={classes.errorMsg}>{cardError}</Typography>
                    </Box> : ''}
                    <Box mt={4}>
                      <Button onClick={handleUpdatePayMethod}
                        color="primary" variant="contained" disableElevation fullWidth 
                        disabled={!cardComplete || cardError !== null || loading}>
                        {loading ? <CircularProgress size={24} /> : ''}
                        Update Payment Method
                      </Button>
                    </Box>
                    <Box mt={1}>
                      <Button color="primary" variant="text" onClick={e => setPayMethod(false)}>Cancel</Button>
                    </Box>
                  </Box>
                </Box>

                :canceling ?

                <Box mt={3} mb={8} align="center">

                  <Box className={classes.boxResizing} align="center">

                  <Typography align="center" variant="h6">We're sorry to see you go.</Typography>
                  <Box mt={4}>
                    <Typography align="center">If you confirm the cancelation, then you and your users will not be able to acccess your account anymore. Please make sure that you download all the information that you would like to retain before you cancel. If you need help, don't hesitate to contact Canveo Support.</Typography>
                  </Box>
                  <Box mt={2}>
                    <Typography align="center" variant="subtitle1">A cancelation will be effective at the end of the current billing period.</Typography>
                  </Box>

                  <Box mt={6}>
                    <Grid container direction="row" spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <Button onClick={e => setCanceling(false)}
                          variant="contained" size="small" color="primary" disableElevation fullWidth>Go Back</Button>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Button component={RouterLink} to="/contact/support"
                          variant="contained" size="small" color="primary" disableElevation fullWidth>Contact Us</Button>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Button onClick={confirmCancel} className={classes.cancelButton}
                          variant="contained" size="small" color="primary" disableElevation fullWidth>Confirm Cancelation</Button>
                      </Grid>
                    </Grid>
                  </Box>

                  </Box>

                </Box>

                :upgrading ?

                <Box mt={3} mb={8} align="center">

                  {['custS','custB'].includes(props.org.clientType) ?
                  <>
                  <Typography variant="h6" style={{fontWeight: '300'}}>
                    Your currrent account level is&nbsp;
                    <b>{props.org.clientType === 'custS' ? "Starter" : props.org.clientType === 'custB' ? "Business" : "Unknown"}</b>
                  </Typography>
                  <Typography variant="h6" style={{fontWeight: '300'}}>
                    You can upgrade to&nbsp;
                    <b>{props.org.clientType === 'custS' ? "Business" : props.org.clientType === 'custB' ? "Business Premium" : "Unknown"}</b>
                  </Typography>
                  <Box mt={9}>
                    <Button size="large" color="secondary" variant="contained" disableElevation onClick={e => handleUpgradeAccount(props.org.clientType === 'custS' ? 'Business' : 'Premium')}>
                      Upgrade to {props.org.clientType === 'custS' ? "Business" : props.org.clientType === 'custB' ? "Business Premium" : "Unknown"}
                    </Button>
                  </Box>

                  <Box mt={1}>
                    <Button color="secondary" variant="text" onClick={()=> window.open("https://getcanveo.com/pricing.html", "_blank")/*handlePricingOpen*/}>View Details</Button>
                    <Button color="secondary" variant="text" onClick={e => setUpgrading(false)}>Nevermind</Button>
                  </Box>
                  </>
                  
                  :props.org.clientType === 'cust' ?
                  <>
                  <Typography variant="h6" style={{fontWeight: '300'}}>Your currrent account level is <b>Premium</b></Typography>
                  <Typography variant="h6" style={{fontWeight: '300'}}>You can upgrade to <b>Enterprise</b> through Canveo Support</Typography>
                  <Box mt={9}>
                    <Button size="large" color="secondary" variant="contained" disableElevation component={RouterLink} to="/contact/upgrade">
                      Contact Canveo Support
                    </Button>
                  </Box>
                  <Box mt={1}>
                    <Button color="secondary" variant="text" onClick={()=> window.open("https://getcanveo.com/pricing.html", "_blank")/*handlePricingOpen*/}>View Details</Button>
                    <Button color="secondary" variant="text" onClick={e => setUpgrading(false)}>Nevermind</Button>
                  </Box>
                  </>
                  :props.org.clientType === 'custE' ?
                  <>
                  <Typography variant="h6" style={{fontWeight: '300'}}>You currrent account level is <b>Enterprise</b></Typography>
                  <Typography variant="h6" style={{fontWeight: '300'}}>You are not able to upgrade any further.</Typography>
                  <Box mt={9}>
                    <Button size="large" color="secondary" variant="contained" disableElevation onClick={e => setUpgrading(false)}>
                      Okay, Got it
                    </Button>
                  </Box>
                  </>
                  :
                  <>
                  <Typography>You are not able to upgrade.</Typography>
                  </>
                  }

                </Box>
                : ''
                }
              </Grid>
              </Container>
              {/*
              <DialogPricing
                open={pricingOpen}
                handlePricingClose={handlePricingClose}
                fromCompanyScreen={true}
                currentTier={props.org.clientType}
                defaultUsers={props.users.filter((u) => u.active).length}
                ccy={getCcyByCountry(stripeAddress !== undefined && stripeAddress !== null && stripeAddress.country !== undefined && stripeAddress.country.length === 2 ? props.org.countryHQ : navigator.language.slice(-2)).symbol}
              />*/}
              <RenderSnack />
          </div>

        )
  

    case 'entities' :
  
        return(
          <div>
            <Header module="admin" 
              active="Company"
              crumbs={[
                {name: "Company Settings", to: "/core/admin/company"}, 
                {name: "Entities", to: "active"} ]}
              user={props.user}
              org={props.org}
              modules={modules}
              withSubHead={true}
              notifications={props.notifications}
              markNotificationAsRead={props.markNotificationAsRead}
              markAllNotificationsAsRead={props.markAllNotificationsAsRead} />

            <HeaderSub
              buttons={[
                {active: false, icon: faCog, name: "Settings", to: "/core/admin/company"},
                {active: false, icon: faStar, name: "Branding", to: "/core/admin/company/logo/1"},
                {active: true, icon: faUniversity, name: (props.org !== undefined && props.org.clientType === 'legalOrg' ? "Clients" : "Entities"), to: "/core/admin/company/entities/1"},
                {active: false, icon: faSignature, name: "Signatory", to: "/core/admin/company/signatory/1"},
                {active: false, icon: faSync, name: "Subscription", to: "/core/admin/company/subscription/1"},
              ]} />

            <Container maxWidth="lg">

              {props.user.role !== 'Counterparty' && (props.user.introComplete === undefined || props.user.introComplete.dashboard === undefined || props.user.introComplete.dashboard === null || !props.user.introComplete.dashboard )  &&
               props.org !== undefined && props.org.clientType !== 'legalOrg' ?
                  <GetStarted 
                  fromDashboard={false}
                  step={"1_2"}
                  pushDown={false}
                  module={props.module}
                  user={props.user}
                  org={props.org}
                  subs={props.subs}
                  />
              :''}
              
              <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
                <Grid item>
                  <Box mb={5} mt={2}>
                    <Typography align="center" variant="h4" className={classes.bold}>                      
                    Manage {props.org !== undefined && props.org.clientType === 'legalOrg' ? "Clients" : "Entities"}&nbsp;&nbsp;<InformationalTooltip msg={"Create a new legal entity for your company by clicking on the \"plus\" button at the bottom right of the screen.\n\nYou can update or deactivate existing legal entities by searching them and clicking \"edit\"."} />
                    </Typography>
                  </Box>
                </Grid>
      
              
                { (props.orgPending || props.subsPending) ? 
                <Grid item container alignItems="center" justify="center">
                  <Box mt={3} mb={10} align="center">
                    <SkeletonCustom type="form" />
                  </Box>
                </Grid>
                : 
                (
                  (props.orgError || props.subsError) !== null ? 
                  (
                    props.orgError !== null ? 
                    (<ErrorMsg clearError={props.clearOrgError} msg="Unable to update the company" />)
                    :
                    (<ErrorMsg clearError={props.clearSubsError} msg="Unable to update the entity" />)
                  ):
                  (
                  <React.Fragment>
                  
                  <FAB
                    iconType="plus"
                    routeTo="/core/admin/company/new/1"
                    disabled={freezeEntities} />
  
                  <Grid item>
                    <Box mt={2} mb={5} align="center">
                      <Grid item container xs={9} sm={7} md={5} justify="center" alignItems="stretch">
                        {props.subs.length === 0 ?
                        <Box mt={3} mb={3}>
                        <Button 
                          variant="contained" 
                          disableElevation
                          color="secondary"
                          component={RouterLink} to={"/core/admin/company/new/1"}>
                          Create a new entity
                        </Button>
                        </Box>
                        :
                        <>
                        <Autocomplete
                          id="combo-box-demo"
                          options={props.subs
                            .filter((s) => (props.org !== undefined && props.org.clientType === 'legalOrg' && s.entityType === 'LegalClient') || 
                              (props.org !== undefined && props.org.clientType !== 'legalOrg' && s.entityType === 'Subsidiary'))
                            .sort((a,b) => (a.legalName > b.legalName) ? 1 : ((b.legalName > a.legalName) ? -1 : 0))}
                          onChange={(e, value) => handleSearchChange(value)}
                          getOptionLabel={option => option.legalName}
                          renderOption={option => (
                            option.isLive ? 
                            <Typography>{option.legalName}</Typography> :
                            <Typography color="textSecondary">{option.legalName} (deactive)</Typography>
                          )}
                          style={{ width: '100%', marginBottom: '25px' }}
                          renderInput={params => (
                            <TextField 
                              {...params} 
                              label={props.org !== undefined && props.org.clientType === 'legalOrg' ? "Search client" : "Search entity"}
                              variant="outlined" 
                              style={{textAlign: 'left'}}
                              fullWidth />
                          )}
                        />

                        <Button 
                          variant="contained" 
                          disableElevation
                          color="primary" 
                          disabled={activeEntity._id === undefined}
                          component={RouterLink} to={"/core/admin/company/edit/" + activeEntity._id}
                          fullWidth>
                          Edit
                        </Button>
                        </>
                        }
                      </Grid>
                    </Box>
                    {freezeEntities ?
                    <Box align="center">
                      <Typography variant="subtitle1" color="textSecondary">
                        An upgrade is required to add additional entities.
                        {props.user.role === 'Admin' ?
                        <Button variant="text" color="secondary" component={RouterLink} to="/core/admin/company/subscription/upgrade">Upgrade</Button>
                        :''}
                      </Typography>
                    </Box>
                    : '' }
                  </Grid>
                  </React.Fragment>
                  )
                )
                }
              </Grid>
              </Container>
              <RenderSnack />
          </div>

        )

    case 'entity':
    default:
      if(confirmedNavigate) {
        return (<Redirect to="/core/admin/company" push={true} />)
      } else {

        return(
          <div>
            <div>
              <Header module="admin" 
                active="Company"
                crumbs={[{name: "Company Settings", to: "active"} ]}
                user={props.user}
                org={props.org}
                modules={modules}
                withSubHead={true}
                notifications={props.notifications}
                markNotificationAsRead={props.markNotificationAsRead}
                markAllNotificationsAsRead={props.markAllNotificationsAsRead} />

              <HeaderSub
                buttons={[
                  {active: true, icon: faCog, name: "Settings", to: "/core/admin/company"},
                  {active: false, icon: faStar, name: "Branding", to: "/core/admin/company/logo/1"},
                  {active: false, icon: faUniversity, name: (props.org !== undefined && props.org.clientType === 'legalOrg' ? "Clients" : "Entities"), to: "/core/admin/company/entities/1"},
                  {active: false, icon: faSignature, name: "Signatory", to: "/core/admin/company/signatory/1"},
                  {active: false, icon: faSync, name: "Subscription", to: "/core/admin/company/subscription/1"},
                ]} />
      
              <Container maxWidth="lg">
              {props.user.role !== 'Counterparty' && (props.user.introComplete === undefined || props.user.introComplete.dashboard === undefined || props.user.introComplete.dashboard === null || !props.user.introComplete.dashboard )  &&
               props.org !== undefined && props.org.clientType !== 'legalOrg' ?
                  <GetStarted 
                  fromDashboard={false}
                  step={"1_0"}
                  pushDown={true}
                  module={props.module}
                  user={props.user}
                  org={props.org}
                  subs={props.subs}
                  />
              :''}

              <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
                <Grid item>
                  <Box mb={3} mt={2}>
                    <Typography align="center" variant="h4" className={classes.bold}>
                        Company Settings
                    </Typography>
                  </Box>
                </Grid>
              
                { (props.orgPending || props.subsPending) ? 
                <Grid item container alignItems="center" justify="center">
                  <Box mt={3} mb={10} align="center">
                    <SkeletonCustom type="form" />
                  </Box>
                </Grid>
                : historicalImport !== null && historicalImport.isActive && historicalImport.completed ?
                <Box align="center" mt={10} mb={10}>
                  Done, check your email for logging output and perform a hard refresh
                </Box>
                : historicalImport !== null && historicalImport.isActive ?

                <Box align="center">
                  {(historicalImport.err !== undefined && historicalImport.err !== null && historicalImport.err.length > 0) ||
                  (historicalImport.warn !== undefined && historicalImport.warn !== null && historicalImport.warn.length > 0) ?
                  <Box mb={3} className={classes.importBox} align="left">
                    <List dense={true}>
                      {historicalImport.err.concat(historicalImport.warn)
                      .map((e, i) => (
                        <ListItem key={i}>
                          <ListItemIcon><FontAwesomeIcon icon={historicalImport.err.includes(e) ? faExclamationCircle : faExclamationTriangle} 
                          className={historicalImport.err.includes(e) ? classes.errIcon : classes.warnIcon} /></ListItemIcon>
                          <ListItemText primary={e} />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                  :''}
                  <Box align="left" className={classes.importBox} mt={2}>
                    <TextField
                      variant="outlined"
                      label="Import the CSV (pipe delimited)"
                      placeholder="Copy-and-paste the CSV"
                      multiline
                      rows={10}
                      value={historicalImport.value}
                      onChange={e => setHistoricalImport({...historicalImport, value: e.target.value, err: [], warn: []})}
                      fullWidth
                    />
                    <Box mt={2}>
                      <Button color="primary" variant="contained" disableElevation fullWidth onClick={handleImportSubmit}
                      disabled={historicalImport === null || historicalImport.value === '' ||
                      (historicalImport.err !== undefined && historicalImport.err !== null && historicalImport.err.length > 0) ||
                      (historicalImport.warn !== undefined && historicalImport.warn !== null && historicalImport.warn.length > 0)}>Submit</Button>
                    </Box>
                  </Box>
                </Box>
                :
                (
                  (props.orgError || props.subsError) !== null ? 
                  (
                    props.orgError !== null ? 
                    (<ErrorMsg clearError={props.clearOrgError} msg="Unable to update the company" />)
                    :
                    (<ErrorMsg clearError={props.clearSubsError} msg="Unable to update the entity" />)
                  ):
                  (
                  <Grid item>

                    <Box mb={4} mt={2}>
                      <Grid container direction="row" alignItems="center" justify="center">
                        <Grid item>
                        <FontAwesomeIcon icon={faGlobe} className={classes.globeIcon} />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle2">
                            <Link href={"https://app.getcanveo.com/network/" + props.org.orgurl} className={classes.orgurl}>app.getcanveo.com/network/{props.org.orgurl}</Link>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
    
                    <CompanyContactForm 
                      parentCallback={callbackCompanyContactForm}
                      type='update'
                      users={props.users !== undefined ? props.users.filter((u) => u.active) : []}
                      initialValues={{
                        shortName: props.org.shortName, 
                        legalName: props.org.legalName, 
                        url: props.org.url, 
                        phone: props.org.phone, 
                        email: props.org.email,
                        primaryContactID: props.org.primaryContactID,
                      }} />

                      {props.user !== undefined && props.user !== null && props.user.email !== undefined && props.user.email.endsWith('support@getcanveo.com') ?
                      <Box mt={3} mb={3} align="center">
                        <Button size="large" color="secondary" variant="text" onClick={e => setHistoricalImport({value: '', isActive: true, err: [], warn: []})}>Historical Import</Button>
                      </Box>
                      : '' }
                    
                  </Grid>
                  )
                )
                }
              </Grid>
              </Container>
                      
            </div>
      
          </div>
        );
      }
  }
}

export default Company;