import * as ActionTypes from '../ActionTypes';

const axios = require('axios');

export function fetchActivityLogByRelID(relid) {
  return dispatch => {
      dispatch(fetchActivityLogPending());

      axios.get('/logging/protect/albyrelid/' + relid)
      .then(function(res) {
        dispatch(fetchActivityLogSuccess(res.data.data));
      })
      .catch(function(err) {
        dispatch(fetchActivityLogError(err));
      })
  }
}

export function fetchActivityLogByAgrID(agrid) {
  return dispatch => {
      dispatch(fetchActivityLogPending());

      axios.get('/logging/protect/albyagrid/' + agrid)
      .then(function(res) {
        dispatch(fetchActivityLogSuccess(res.data.data));
      })
      .catch(function(err) {
        dispatch(fetchActivityLogError(err));
      })
  }
}

export const fetchActivityLogPending = () => ({
    type: ActionTypes.FETCH_ACTIVITYLOG_PENDING
});

export const fetchActivityLogError = (error) => ({
    type: ActionTypes.FETCH_ACTIVITYLOG_ERROR,
    payload: error
});

export const fetchActivityLogSuccess = (alog) => ({
    type: ActionTypes.FETCH_ACTIVITYLOG_SUCCESS,
    payload: alog
});

export const createActivityLog = (logLevel, logType, logDesc, orgID, entID, relID, agrID, singleClauseID, ticketID, creationBy, isInternal, keyVal1, keyVal2, change) => {
  return dispatch => { // FAF
    let creationDate = new Date().toISOString();
    axios.post('/logging/al', {
        logLevel: logLevel,
        logType: logType,
        logDesc: logDesc, 
        orgID: orgID,
        entID: entID,
        relID: relID, 
        agrID: agrID,
        singleClauseID: singleClauseID,
        ticketID: ticketID,
        creationBy: creationBy,
        creationDate: creationDate,
        isInternal: isInternal,
        keyVal1: keyVal1,
        keyVal2: keyVal2,
        change: change,
    })     
  }
};