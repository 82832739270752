import React, { useEffect } from 'react';
import { getSmartFieldValue, trunc, smartfieldValueValidation, convertLabelToTitle } from '../utils'
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { Avatar, Box, Button, Checkbox, Chip, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, 
         FormControlLabel, Grid, Grow,
         IconButton, InputLabel, FormControl, MenuItem, Select, 
         TextareaAutosize, TextField, Typography,
         makeStyles } from '@material-ui/core';
import { InformationalTooltip, SmartFieldEditable } from '.'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faChevronDown, faChevronUp, faInfoCircle, faMinusCircle, faPen, faSearch, faTimes, faUnlink } from "@fortawesome/free-solid-svg-icons";  

import { sftypes } from '../assets/static/sftypes';
import ctLogo from '../assets/img/ct-logo.png';

const axios = require('axios');
const filter = createFilterOptions();

/*
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});*/

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

const DialogTitle = (props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h6" style={{fontWeight: '700'}}>{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" style={{position: 'absolute', right: '8px', top: '10px'}} onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} style={{fontSize: '20px', padding: '0px 3px 0px 3px'}} />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

const DialogSmartField = (props) => {

    const useStyles = makeStyles(theme => ({
        cancelButton: {
            marginRight: 'auto',
        },
        mlittle: {
            marginTop: '4px',
            marginBottom: '4px',
        },
        editButton: {
            paddingRight: '13px',
            paddingLeft: '13px',
        },
        deleteButton: {
            paddingRight: '13px',
            paddingLeft: '13px',
            color: theme.palette.error.main,
        },
        iconForButton: {
            padding: '0px 8px 0px 0px',
        },
        menuBold: {
            fontWeight: '700',
            marginRight: '10px',
        },
        avatarOption: {
            width: '15px',
            height: '15px',
            marginLeft: '20px',
        },
        delIcon: {
            fontSize: '14px',
            padding: '3px',
        },
        textAreaAuto: {
            //[theme.breakpoints.only('xs')]: { width: '240px' },
            //[theme.breakpoints.up('sm')]: { width: '420px' },
            width: 'calc(100% - 30px)',
            fontSize: '16px',
            fontWeight: '300',
            padding: '15px',
            border: '1px solid' + theme.palette.grey[400],
            color: theme.palette.grey[900],
            borderRadius: '10px',
            minHeight: '25px',
        },
        switchGroup: {
            display: 'inline',
            marginLeft: '30px',
        },
        buttonActive: {
            [theme.breakpoints.only('xs')]: { 
                padding: '2px 5px 2px 5px',
                fontSize: '12px'
            },
            padding: '2px 18px 2px 18px',
            fontSize: '14px',
            fontWeight: '700',
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            '&:hover':{
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
            }
        },
        button: {
            [theme.breakpoints.only('xs')]: { 
                padding: '2px 5px 2px 5px',
                fontSize: '12px'
            },
            padding: '2px 18px 2px 18px',
            fontSize: '14px',
            fontWeight: '700',
            color: theme.palette.grey[600],
            backgroundColor: '#eeeeee',
            '&:hover':{
                color: theme.palette.grey[800],
                backgroundColor: '#dddddd',
            }
        },
        primaryChip: {
            border: '0px solid' + theme.palette.primary.main, 
            margin: '2px 2px 2px 0px',
            fontWeight: '500',
            fontSize: '16px',
            color: theme.palette.grey[900],
            backgroundColor: '#eeeeee',
            "& .MuiChip-deleteIcon": { color: theme.palette.primary.main, '&:hover': { color: theme.palette.grey[800] } },
        },
        chipFont: {
            padding: '5px', 
            fontWeight: '400'
        },
        stdChip: {
            [theme.breakpoints.down('xs')]: { fontSize: '10px', padding: '1px 4px 1px 4px', margin: '0px 3px 3px 0px', },
            [theme.breakpoints.only('sm')]: { fontSize: '11px', padding: '1px 4px 1px 4px', margin: '0px 8px 8px 0px', },
            [theme.breakpoints.only('md')]: { fontSize: '12px', padding: '1px 6px 1px 6px', margin: '0px 8px 8px 0px', },
            [theme.breakpoints.up('lg')]: { fontSize: '12px', padding: '1px 6px 1px 6px', margin: '0px 8px 8px 0px',  },
            fontWeight: '400',
            borderColor: theme.palette.grey[600],
        },
        stdChipInactive: {
            [theme.breakpoints.down('xs')]: { fontSize: '10px', padding: '1px 4px 1px 4px', margin: '0px 3px 3px 0px', },
            [theme.breakpoints.only('sm')]: { fontSize: '11px', padding: '1px 4px 1px 4px', margin: '0px 8px 8px 0px', },
            [theme.breakpoints.only('md')]: { fontSize: '12px', padding: '1px 6px 1px 6px', margin: '0px 8px 8px 0px', },
            [theme.breakpoints.up('lg')]: { fontSize: '12px', padding: '1px 6px 1px 6px', margin: '0px 8px 8px 0px',  },
            fontWeight: '400',
            color: theme.palette.grey[200],
            backgroundColor: theme.palette.grey[400],
        },
        chipIcon: {
            padding: '0px 2px 0px 5px',
        },
    }));
    const classes = useStyles();

    const [activeSmartField, setActiveSmartField] = React.useState(null)
    const [smartFieldStaging, setSmartFieldStaging] = React.useState(null)
    const [orgSmartField, setOrgSmartField] = React.useState(null)
    const [orgSmartFields, setOrgSmartFields] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [editing, setEditing] = React.useState(false)
    const [newlyCreating, setNewlyCreating] = React.useState(false)
    const [errMsg, setErrMsg] = React.useState(null)
    const [editingCustomListOptions, setEditingCustomListOptions] = React.useState(false)
    const [newOption, setNewOption] = React.useState('')
    const [showSFDetail, setShowSFDetail] = React.useState(false)
    const [smartClauseType, setSmartClauseType] = React.useState('topic')
    const [clauseTypes, setClauseTypes] = React.useState([])
    const [activeTopic, setActiveTopic] = React.useState(null)

    useEffect(() => {

        if(props.open && props.openSmartField !== null && /*props.openSmartField.ref !== 'clause' && */
        ['create'].includes(props.openSmartField.type)){

            axios.get('/sf/osfs/' + props.user.orgID + "/" + props.module + (props.openSmartField.ref === 'clause' ? "/topic" : "")) // PULL ALL ORG SMARTFIELDS FOR THAT MODULE
            .then(function(res) {
                setOrgSmartFields(res.data.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))
                setErrMsg(null)
                setLoading(false)
                setClauseTypes(props.clauseTypes)

            }).catch(function(err) { console.log(err) })

        } else if(props.open && props.openSmartField !== null && ['view'].includes(props.openSmartField.type)) {
            axios.get('/sf/sf/' + props.openSmartField._id)
            .then(function(ressf) {

                if(ressf.data.data[0].osfid !== undefined && ressf.data.data[0].osfid !== null && ressf.data.data[0].osfid !== '') {
                    axios.get('/sf/osf/' + ressf.data.data[0].osfid)
                    .then(function(res) {
        
                        setOrgSmartField(res.data.data) 
                        setActiveSmartField(ressf.data.data[0])
                        setErrMsg(null)
                        setLoading(false)
    
                    }).catch(function(err) { console.log(err) })

                } else { // The smart field does not have an OSF yet. May have been created pre-migration, or created but unfinished
                    let sf = ressf.data.data[0];
                    setSmartFieldStaging({
                        name: sf.name,
                        type: sf.type,
                        question: '',
                        defaultValue1: sf.val1,
                        defaultValue2: sf.val2,
                        guidance: '',
                        createWizard: true,
                        reusable: false,
                    })
                    setNewlyCreating(!props.templating ? false : true)
                    setActiveSmartField(sf)
                    setErrMsg(null)
                    setLoading(false)

                }

            }).catch(function(err) { console.log(err) })

        } else if(props.open && props.openSmartField !== null && ['view-topic'].includes(props.openSmartField.type)) {

            axios.get('/sf/osfs/' + props.user.orgID + "/" + props.module + "/topic") // PULL TOPIC ORG SMARTFIELD FOR THAT MODULE
            .then(function(res) {
                setActiveTopic(props.openSmartField.ref)
                setOrgSmartFields(res.data.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))
                setErrMsg(null)
                setLoading(false)

            }).catch(function(err) { console.log(err) })

        } else if(!props.open) {
            setErrMsg(null)
            setLoading(true)

        }

    }, [props.open, props.openSmartField, props.module, props.templating, props.user.orgID])

    const closeDialog = (creationCompleted) => {
        
        setActiveSmartField(null)
        setSmartFieldStaging(null)
        setOrgSmartField(null)
        setEditing(false)
        setNewlyCreating(false)
        setErrMsg(null)
        setEditingCustomListOptions(false)
        setNewOption('')
        setShowSFDetail(false)
        setSmartClauseType('topic')

        if(props.open && props.openSmartField !== null && props.openSmartField.ref !== 'clause' &&
        ['create'].includes(props.openSmartField.type) && !creationCompleted) {
            // Early termination of a new creation - this needs to unlink the smartfield
            props.handleUnlink()
        } else {
            props.parentCallToClose()
        }
    }

    const handleUnlink = () => {
        setSmartFieldStaging(null)
        props.handleUnlink()
    }

    const handleEditingToggle = (switchingOn) => {
        setEditing(switchingOn)
        setSmartFieldStaging(
        switchingOn && !props.templating && props.cleanMode ?
        { // Editing a smartfield post calculation
            name: activeSmartField.name,
            type: activeSmartField.type,
            question: null,
            defaultValue1: activeSmartField.val1,
            defaultValue2: activeSmartField.val2,
            guidance: null,
            createWizard: null,
            reusable: null,
        }
        :switchingOn ? 
        { // Editing an orgSmartField inside the Templating area
            name: orgSmartField.name,
            type: orgSmartField.type,
            question: orgSmartField.question,
            defaultValue1: orgSmartField.defaultValue1,
            defaultValue2: orgSmartField.defaultValue2,
            guidance: orgSmartField.guidance,
            createWizard: orgSmartField.createWizard,
            reusable: orgSmartField.reusable,
        } : null)
    }

    const handleSubmitNewOption = () => {

        let optSelected = smartFieldStaging.defaultValue2;

        let optSelectedText = smartFieldStaging.defaultValue1 !== undefined && smartFieldStaging.defaultValue1 !== null && Array.isArray(smartFieldStaging.defaultValue1) &&
            smartFieldStaging.defaultValue1.split('|')[optSelected !== null & optSelected !== '' && !isNaN(optSelected) ? optSelected : '']
        
        if(optSelectedText !== '' && optSelectedText > newOption) { optSelected = optSelected + 1 } // Add one, if new option is inserted before

        let nv1 = smartFieldStaging.defaultValue1 === "" ? newOption :
            smartFieldStaging.defaultValue1.concat('|' + newOption); // concat at the end
        let opts = nv1.split('|');
        let newOpts = opts.sort().join('|');
        
        setSmartFieldStaging({ ...smartFieldStaging,
            defaultValue1: newOpts,
            defaultValue2: optSelected,
        })

        setNewOption('')
    }

    const handleRemoveCurrentListOption = (idx) => {

        //let optSelected = smartFieldStaging.defaultValue2;
        let opts = smartFieldStaging.defaultValue1.split('|');
        if (idx > -1 && opts[idx] !== undefined) { opts.splice(idx, 1); } // Remove from Array

        let newOpts = opts.sort().join('|');
        
        setSmartFieldStaging({ ...smartFieldStaging,
            defaultValue1: newOpts,
            defaultValue2: 
                smartFieldStaging.defaultValue2 === idx ? '' :
                smartFieldStaging.defaultValue2 > idx ? smartFieldStaging.defaultValue2 -1 : smartFieldStaging.defaultValue2,
        })

    }

    const handleSmartFieldFind = (value) => {

        if(value._id !== undefined) { // Selecting an existing Smartfield

            props.executeSmartFieldUpdate(
                props.openSmartField._id,
                value._id, // osfid
                props.openSmartField.ref, 
                value.name, 
                value.type, 
                value.defaultValue1, 
                value.defaultValue2, 
                '', 
                value.createWizard)

            if(value.name === "Agreement Effective Date" && props.templating) { // Need to update the orgAgrTemplate
                props.specialHandlingUpdate('updateEdt', 'smartfield')

            } else {
                closeDialog(true)
            }

        } else {

            let sf = 
                props.smartFields.filter((s) => s.ref === props.openSmartField.ref)[0] !== undefined ?
                    props.smartFields.filter((s) => s.ref === props.openSmartField.ref)[0] :
                props.openSmartField.sf !== undefined && props.openSmartField.sf !== null && props.openSmartField.sf.type !== undefined ?
                    props.openSmartField.sf :
                    null

            setSmartFieldStaging({ // Creating a new Smartfield
                name: value.inputValue,
                type: sf !== null ? sf.type : '',
                question: '',
                defaultValue1: sf !== null ? sf.val1 : '',
                defaultValue2: sf !== null ? sf.val2 : '',
                guidance: '',
                module: props.module,
                createWizard: true,
                reusable: false,
            })
        }
    }

    const handleSmartFieldTypeChange = (e) => {
        setSmartFieldStaging({...smartFieldStaging, type: e.target.value, defaultValue1: ['date-stamp', 'auto', 'date-period', 'number', 'amount', 'list-standard'].includes(e.target.value) ? '' : smartFieldStaging.defaultValue1})
    }

    const handleSmartFieldChange = (sfid, valnumber, newval) => {
        if(valnumber === 'val1') {
            setSmartFieldStaging({...smartFieldStaging, defaultValue1: newval})
        } else if(valnumber === 'val2') {
            setSmartFieldStaging({...smartFieldStaging, defaultValue2: newval})
        }
    }

    const handleClauseTypeChange = (newValue) => {
        let newcts = []
        newValue.forEach((nv) => { newcts.push(nv.defaultValue1) })
        setClauseTypes(newcts)
    }

    const handleCreateUpdateSmartClause = () => {
        props.callbackOptions(props.openSmartField.clid, 'applyClauseTopics', clauseTypes)
        closeDialog(true)
    }

    const handleCreateUpdateSmartField = () => {

        // Validate the input values, unless they're empty and you're in templating mode (ie. you can leave blank)
        if(((smartFieldStaging.defaultValue1 !== null && smartFieldStaging.defaultValue1 !== '') || 
        (smartFieldStaging.defaultValue2 !== null && smartFieldStaging.defaultValue2 !== '') || !props.templating) &&
        !smartfieldValueValidation(smartFieldStaging.type, smartFieldStaging.defaultValue1, smartFieldStaging.defaultValue2)) {

            setErrMsg("The smart field value is not valid for this type")

        // Updating the smart field values post-calculation
        } else if(!props.templating && props.cleanMode && smartfieldValueValidation(smartFieldStaging.type, smartFieldStaging.defaultValue1, smartFieldStaging.defaultValue2)) {
            let newVal3 = getSmartFieldValue(smartFieldStaging.type, smartFieldStaging.defaultValue1, smartFieldStaging.defaultValue2)
            props.executeSmartFieldUpdate(
                activeSmartField._id,
                activeSmartField.osfid, // osfid
                activeSmartField.ref, 
                activeSmartField.name, 
                smartFieldStaging.type, 
                smartFieldStaging.defaultValue1, 
                smartFieldStaging.defaultValue2, 
                newVal3, 
                activeSmartField.createWizard
            )
            setActiveSmartField({...activeSmartField, type: smartFieldStaging.type, val1: smartFieldStaging.defaultValue1, val2: smartFieldStaging.defaultValue2, val3: newVal3})
            setEditing(false)
            setErrMsg(null)

        } else if(props.openSmartField !== null && props.openSmartField._id !== undefined &&
        props.openSmartField.ref !== undefined &&
        smartFieldStaging !== null && smartFieldStaging.name !== '' &&
        (!orgSmartFields.some((osf) => osf.name === smartFieldStaging.name) || editing) &&
        smartFieldStaging.type !== '') {

            if(!editing) { // CREATING A NEW SMARTFIELD

                axios.post('/sf/osf', {
                    orgID: props.user.orgID,
                    module: props.module,
                    name: smartFieldStaging.name,
                    type: smartFieldStaging.type,
                    question: smartFieldStaging.question,
                    defaultValue1: smartFieldStaging.defaultValue1,
                    defaultValue2: smartFieldStaging.defaultValue2,
                    guidance: smartFieldStaging.guidance,
                    createWizard: smartFieldStaging.createWizard,
                    reusable: smartFieldStaging.reusable,
                }).then((res) => {
    
                    props.executeSmartFieldUpdate(
                        props.openSmartField._id,
                        res.data.data._id, // osfid
                        props.openSmartField.ref, 
                        smartFieldStaging.name, 
                        smartFieldStaging.type, 
                        smartFieldStaging.defaultValue1, 
                        smartFieldStaging.defaultValue2, 
                        '', 
                        smartFieldStaging.createWizard)

                    setErrMsg(null)
                    closeDialog(true)
    
                }).catch((err) => { console.log(err) })

            } else { // EDITING AN EXISTING SMARTFIELD

                axios.put('/sf/osf/' + orgSmartField._id, {
                    name: smartFieldStaging.name,
                    type: smartFieldStaging.type,
                    question: smartFieldStaging.question,
                    defaultValue1: smartFieldStaging.defaultValue1,
                    defaultValue2: smartFieldStaging.defaultValue2,
                    guidance: smartFieldStaging.guidance,
                    createWizard: smartFieldStaging.createWizard,
                    reusable: smartFieldStaging.reusable,
                }).then((res) => {
    
                    props.executeSmartFieldUpdate(
                        props.openSmartField._id,
                        orgSmartField._id, // osfid
                        props.openSmartField.ref, 
                        smartFieldStaging.name, 
                        smartFieldStaging.type, 
                        smartFieldStaging.defaultValue1, 
                        smartFieldStaging.defaultValue2, 
                        '', 
                        smartFieldStaging.createWizard)

                    setErrMsg(null)
                    setOrgSmartField(res.data.data)
                    setEditing(false)
    
                }).catch((err) => { console.log(err) })

            }

        } else {

            let err = 
                smartFieldStaging === null || smartFieldStaging.name === '' ? 
                    'Please provide a name for the smart field' :
                (orgSmartFields.some((osf) => osf.name === smartFieldStaging.name) && !editing) ?
                    'This smartfield (name) already exists, choose a different one' :
                smartFieldStaging.type !== '' ?
                    'Select a type for the smart field' :
                    'Unable to create the smart field, please try again'
            setErrMsg(err);
        }
    }

    return (
        <Dialog
        open={props.open}
        scroll="paper"
        TransitionComponent={Transition}
        keepMounted
        onClose={e => closeDialog(false)}
        fullWidth={true}
        maxWidth="sm"
        >
        <DialogTitle onClose={e => closeDialog(false)}>
            {props.openSmartField !== null && props.openSmartField.ref !== 'clause' && props.openSmartField.type === 'create' && 
            props.openSmartField.text !== undefined && props.openSmartField.text !== null ?
                ("Create Smart Field: \"" + trunc(props.openSmartField.text, 30) + "\"") :
            props.openSmartField !== null && props.openSmartField.type === 'view' && activeSmartField !== null ?
                ("Smart Field: " + activeSmartField.name) :
            props.openSmartField !== null && props.openSmartField.ref === 'clause' && props.openSmartField.type === 'create' ?
                <>Create Smart Clause&nbsp;&nbsp;<InformationalTooltip msg="Apply a topic to this clause, this will enable guidance for business users during the agreement negotiation. To update the guidance or create additional topics, visit the Playbook section inside the Legal Module." /></> : 
            props.openSmartField !== null && props.openSmartField.type === 'view-topic' && activeTopic !== null ?
                <>Topic Guidance: {convertLabelToTitle(activeTopic)}</> :
                "Smart Fields"}
        </DialogTitle>

        <DialogContent id="msgContainer">

            <Box 
            mt={2} 
            mb={props.openSmartField !== null && props.openSmartField.type === 'view' && 
            activeSmartField !== null && orgSmartField !== null && !editing ? 1 : 2} 
            align="center">

                {loading ?
                <Box mt={7} mb={7} align="center">
                    <CircularProgress size={24} />
                </Box>
                : // VIEW AN EXISTING SMARTFIELD
                props.openSmartField !== null && props.openSmartField.type === 'view' && 
                activeSmartField !== null && orgSmartField !== null && !editing ?

                <Box pr={3} pl={3}>

                    <Box pl={3} pr={3} pt={3} pb={3} mb={1} style={{backgroundColor: '#f6f6f6', borderRadius: '10px'}}>
                        <Typography>
                        {orgSmartField !== null && orgSmartField.orgID !== props.user.orgID &&
                         orgSmartField.orgID !== 'CANVEO' ? 
                            '(This is a smart field managed by the counterparty)' :
                        orgSmartField.guidance !== undefined && orgSmartField.guidance !== null && orgSmartField.guidance !== '' ?
                        orgSmartField.guidance.split('\n').map((g, i) => (
                            <React.Fragment key={i}>{g}<br/></React.Fragment> 
                        ))
                            :'(No internal guidance)'}
                        </Typography>
                    </Box>
                    {!props.templating && props.cleanMode ? 
                    <Box pl={3} pr={3} pt={3} pb={3} mb={1} style={{backgroundColor: '#f6f6f6', borderRadius: '10px'}}>

                        <Typography><b>Value captured: </b>{activeSmartField.val3 !== undefined && activeSmartField.val3 !== null && activeSmartField.val3 !== '' ? 
                        activeSmartField.val3 : '(none)'}</Typography>

                    </Box>
                    :''}
                    <Collapse in={showSFDetail && orgSmartField !== null && orgSmartField.orgID === props.user.orgID}>
                    <Box pl={3} pr={3} pt={3} pb={3} mb={1} style={{backgroundColor: '#f6f6f6', borderRadius: '10px'}}>
                        <Typography><span className={classes.menuBold}>Default Type:</span>{orgSmartField.type.charAt(0).toUpperCase() + orgSmartField.type.slice(1)}</Typography>
                        <Typography><span className={classes.menuBold}>Default Value:</span>{getSmartFieldValue(orgSmartField.type, orgSmartField.defaultValue1, orgSmartField.defaultValue2)}</Typography>
                    </Box>
                    </Collapse>
                </Box>

                : // VIEW A TOPIC GUIDANCE
                props.openSmartField !== null && ['view-topic'].includes(props.openSmartField.type) ?
                <Box pr={3} pl={3} mt={3} mb={3}>


                    <Box>
                        {// Show all clause topic chips (to the extent you have any)
                        props.clauseTypes
                        .map((ct, i) => (
                            <Chip
                            key={i}
                            label={convertLabelToTitle(ct)}
                            icon={<FontAwesomeIcon icon={faInfoCircle} className={classes.chipIcon} />}
                            clickable
                            color="primary"
                            size="small"
                            className={activeTopic !== null && ct === activeTopic ? classes.stdChip : classes.stdChipInactive}
                            onClick={e => setActiveTopic(ct)}
                            />
                        ))}
                    </Box>
                    
                    <Box mt={3} pl={3} pr={3}>
                        {orgSmartFields.filter((osf) => osf.orgID !== 'CANVEO' && osf.defaultValue1 === activeTopic)[0] !== undefined ?
                        <>
                        {!orgSmartFields.filter((osf) => osf.orgID !== 'CANVEO' && osf.defaultValue1 === activeTopic)[0].reusable ?
                        <Typography align="center" variant="subtitle2" color="textSecondary" style={{marginBottom: '25px'}}>Note: this topic has been archived by Legal / Admin</Typography>
                        :''}
                        {orgSmartFields.filter((osf) => osf.orgID !== 'CANVEO' && osf.defaultValue1 === activeTopic)[0].guidance.split('\n').map((guidancePar, i) => (
                        <Typography align="left" key={i}>{guidancePar}&nbsp;</Typography>
                        ))}
                        </>
                        :
                        <Typography align="center">No guidance provided</Typography>
                        }
                    </Box>


                </Box>
                : // CREATE A NEW SMARTFIELD or EDIT AN EXISTING SMARTFIELD
                (props.openSmartField !== null && props.openSmartField.type === 'create') || newlyCreating ||
                editing ?
                    
                <Box pr={3} pl={3}>

                    {smartFieldStaging === null ? 

                        <>
                        {props.openSmartField !== null && props.openSmartField.ref === 'clause' ?
                        <Box mt={6} mb={6}> 
                            {// SWITCHING BETWEEN DIFFERENT SMART CLAUSE FEATURES
                            /*
                            <Box className={classes.switchGroup}>
                                <Button size="small" variant="text" onClick={e => setSmartClauseType('topic')}
                                className={smartClauseType === 'topic' ? classes.buttonActive : classes.button}
                                style={{borderRadius: '10px 0px 0px 10px'}}
                                color={"default"}>
                                    Clause topic
                                </Button>
                                <Button size="small" variant="text" onClick={e => setSmartClauseType('decision')}
                                className={smartClauseType === 'decision' ? classes.buttonActive : classes.button}
                                style={{borderRadius: '0px 0px 0px 0px'}}
                                color={"default"}>
                                    Clause decision
                                </Button>
                                <Button size="small" variant="text" onClick={e => setSmartClauseType('options')}
                                className={smartClauseType === 'options' ? classes.buttonActive : classes.button}
                                style={{borderRadius: '0px 10px 10px 0px'}}
                                color={"default"}>
                                    Clause options
                                </Button>
                            </Box>
                            */}
                        
                            <Box>
                            {smartClauseType === 'topic' ?
                            <>
                                <Box align="left" mt={6}>
                                    <Autocomplete
                                    multiple
                                    options={orgSmartFields !== undefined && orgSmartFields !== null ? 
                                        orgSmartFields.filter((osf) => osf.type === 'clause-topic' && (osf.reusable || clauseTypes.includes(osf.defaultValue1))) : []}
                                    getOptionLabel={(option) => option.name}
                                    value={orgSmartFields.filter((osf) => osf.type === 'clause-topic' && clauseTypes.includes(osf.defaultValue1))}
                                    onChange={(event, newValue) => {
                                        handleClauseTypeChange(newValue)
                                    }}
                                    //defaultValue={[top100Films[13]]}
                                    filterSelectedOptions
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip variant="outlined" {...getTagProps({ index })} className={classes.primaryChip}
                                            label={<Typography className={classes.chipFont}>{option.name}</Typography>}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" style={{width: '100%'}} label="Clause Topic" placeholder="Select clause topic(s)" color="primary" />
                                    )}
                                    />
                                </Box>
                            </>
                            :smartClauseType === 'decision' ?
                            <>
                                Decision: question<br/>
                                Option 1: includes<br/>
                                Option 2: excludes
                            </>
                            :smartClauseType === 'options' ?
                            <>
                                Options: question<br/>
                                Title 1 | Title 2 | Title 3<br/>
                                Short 1 | Short 2 | Short 3<br/>
                                Clause 1 | Clause 2 | Clause 3
                                Default
                            </>
                            : ''}
                            </Box>

                            <Box mt={2}>
                            <Button fullWidth
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={handleCreateUpdateSmartClause}
                            >
                            {smartClauseType === 'topic' && clauseTypes.length > 1 ? "Apply Topics" :
                            smartClauseType === 'topic' ? "Apply Topic" :
                            smartClauseType === 'decision' ? "Apply Decision" :
                            smartClauseType === 'options' ? "Create Clause Options" : "Create Smart Clause"}
                            </Button>
                            </Box>
                        </Box>
                        :
                        <Box  mt={7} mb={7}>
                            <Autocomplete // SMART FIELD
                            onChange={(e, newValue) => {
                                handleSmartFieldFind(newValue)
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                if (props.templating && params !== undefined && params.inputValue !== '' && 
                                !filtered.some((f) => f.name.includes(params.inputValue))) {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    name: `Create "${params.inputValue}" as a new Smart Field`,
                                });
                                }
                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            options={
                                orgSmartFields
                                .filter((osf) => osf.reusable) // Only show reusable smart fields
                                .filter((osf) => !['clause-topic'].includes(osf.type))
                                .filter((osf) => props.templating || osf.name !== "Agreement Effective Date") // Only show Effective Date for Templates
                                .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))}
                            getOptionLabel={(option) => {
                                if (typeof option === 'string') { return option; } // Value selected with enter, right from the input
                                if (option !== undefined && option.inputValue) { return option.inputValue; } // Add "xxx" option created dynamically
                                return option.name; // Regular option
                            }}
                            renderOption={(option) => (
                                <React.Fragment>
                                    <Typography variant={option._id !== undefined ? "body1" : "subtitle1"} color="textPrimary">
                                        {option.name}
                                    </Typography>
                                    {option.orgID === 'CANVEO' ? 
                                    <Avatar alt={"Canveo Managed"} src={ctLogo} className={classes.avatarOption} /> : ''}
                                </React.Fragment>
                            )}
                            style={{ width: '100%' }}
                            freeSolo
                            renderInput={(params) => (
                                <TextField {...params} 
                                label={"Find or Create Smart Field..."} 
                                placeholder="Smart Field Name"
                                variant="outlined" 
                                fullWidth
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password',
                                }} />
                            )}
                            />
                        </Box>
                        }
                        </>

                    :smartFieldStaging !== null ?

                        <>
                        {errMsg !== null ?
                        <Box mb={2}>
                            <Typography color="error" variant="subtitle1" align="center">{errMsg}</Typography>
                        </Box>
                        :''}
                        {props.templating ?
                        <Box>
                            <TextField fullWidth 
                            disabled={editing}
                            variant="outlined" 
                            label="Smart Field Name" 
                            value={smartFieldStaging.name}
                            placeholder={"Provide name"}
                            onChange={e => setSmartFieldStaging({...smartFieldStaging, name: e.target.value})} />
                        </Box>
                        :''}
                        {props.templating ?
                        <Box mt={2}>
                            <TextField fullWidth multiline
                            variant="outlined" 
                            label="Question for Agreement Wizard" 
                            value={smartFieldStaging.question}
                            placeholder={"e.g. What is the " + smartFieldStaging.name + " for this agreement?"}
                            onChange={e => setSmartFieldStaging({...smartFieldStaging, question: e.target.value})} />
                        </Box>
                        :''}
                        <Box mt={2} align="left">
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel style={{backgroundColor: '#ffffff', padding: '0px 2px 0px 2px'}}>Smart Field Type</InputLabel>
                                <Select
                                value={smartFieldStaging.type}
                                onChange={e => handleSmartFieldTypeChange(e)}
                                renderValue={selected => {
                                    let s =  sftypes.filter((tc) => tc.value === selected)[0] !== undefined ? 
                                        sftypes.filter((tc) => tc.value === selected)[0].type : ""
                                    return <span>{s}</span>;
                                }}
                                >
                                {sftypes
                                .filter((tc) => props.templating || tc.value !== 'auto')
                                .map((tc,i) => (
                                    <MenuItem key={i} value={tc.value}><span className={classes.menuBold}>{tc.type}</span>{tc.desc}</MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                        </Box>
                        {props.templating && smartFieldStaging.type === 'list-custom' ?
                        <Box mt={1}>
                            <Box align="center" mb={0}>
                                <Button size="small" variant="text" color={editingCustomListOptions? "secondary" : "primary"} onClick={e => setEditingCustomListOptions(!editingCustomListOptions)}>
                                    {editingCustomListOptions ? 
                                        <>Finish managing custom list options&nbsp;&nbsp;<FontAwesomeIcon icon={faChevronUp} /></> :
                                        <>Create or edit custom list options&nbsp;&nbsp;<FontAwesomeIcon icon={faChevronDown} /></>}
                                </Button>
                            </Box>

                            <Box mb={1}>
                            <Collapse in={editingCustomListOptions}>
                                {smartFieldStaging.defaultValue1 !== undefined && smartFieldStaging.defaultValue1 !== null &&
                                smartFieldStaging.defaultValue1.length !== 0 ?
                                smartFieldStaging.defaultValue1.split('|').map((clo, i) => (
                                    <Grid container direction="row" key={i} justify="center" alignItems="center">
                                    <Grid item>
                                        <Typography>{clo}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={e => handleRemoveCurrentListOption(i)}>
                                            <FontAwesomeIcon icon={faMinusCircle} className={classes.delIcon} />
                                        </IconButton>
                                    </Grid>
                                    </Grid>
                                )):''}
                                <Box mt={1} mb={2}>
                                    <Grid container direction="row" justify="center" alignItems="center">
                                    <Grid item>
                                        <TextField 
                                        size="small"
                                        variant="outlined"
                                        value={newOption}
                                        onChange={e => setNewOption(e.target.value)} />
                                    </Grid>
                                    <Grid item>
                                        <Box ml={1}>
                                        <Button variant="contained" disableElevation color="primary" size="small" onClick={handleSubmitNewOption}>Insert new option</Button>
                                        </Box>
                                    </Grid>
                                    </Grid>
                                </Box>
                            </Collapse>
                            </Box>

                        </Box>
                        :''}
                        <Box mt={2}>
                            {smartFieldStaging !== null && smartFieldStaging.type !== null && smartFieldStaging.type !== '' ?
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Grid item>
                                    <Box mr={1} ml={1}>
                                        <Typography color="textPrimary" variant="subtitle1">Default Value:</Typography>
                                    </Box>                                            
                                </Grid>
                                <Grid item>
                                    <SmartFieldEditable                        
                                    fromClause={true}
                                    type={smartFieldStaging.type}
                                    val1={smartFieldStaging.defaultValue1}
                                    val2={smartFieldStaging.defaultValue2}
                                    onFieldChange={handleSmartFieldChange} />
                                </Grid>
                            </Grid>
                            :''}
                        </Box>
                        {props.templating ?
                        <Box mt={2}>
                            
                            <TextareaAutosize
                            className={classes.textAreaAuto}
                            name="addComment"
                            rowsMin={2}
                            rowsMax={15}
                            placeholder={"Provide Internal guidance for business users..."}
                            value={smartFieldStaging.guidance}
                            onChange={e => setSmartFieldStaging({...smartFieldStaging, guidance: e.target.value})}                                        
                            />
                        </Box>
                        :''}
                        {props.templating ?
                        <Box mt={2}>
                            <Box align="left" style={{maxWidth: '340px'}}>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={smartFieldStaging.createWizard}
                                    onChange={e => setSmartFieldStaging({...smartFieldStaging, createWizard: e.target.checked})}
                                    name="createWizard"
                                    color="primary"
                                />
                                }
                                label="Include in New Agreement Wizard"
                            />
                            </Box>
                        </Box>
                        :''}
                        {props.templating ?
                        <Box mt={1}>
                            <Box align="left" style={{maxWidth: '340px'}}>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={smartFieldStaging.reusable}
                                    onChange={e => setSmartFieldStaging({...smartFieldStaging, reusable: e.target.checked})}
                                    name="reusable"
                                    color="primary"
                                />
                                }
                                label="Save Smart Field for use across templates"
                            />
                            </Box>
                        </Box>
                        :''}
                        <Box mt={2}>
                            <Button fullWidth
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={handleCreateUpdateSmartField}
                            >{!props.templating ? "Update ": editing ? "Change " : "Create "}Smart Field</Button>
                        </Box>

                        </>     

                    :''}
                </Box>

                :''
                }

            </Box>            

        </DialogContent>

        <DialogActions className={classes.mlittle}>
            <Button onClick={e => closeDialog(false)} variant="text" color="primary" className={classes.cancelButton}>
                Close
            </Button>

            {props.openSmartField !== null ?
            <>

                {// Button to update the field after re-calculation
                !props.templating && props.cleanMode && !editing &&
                orgSmartField !== null && orgSmartField.orgID === props.user.orgID && 
                ['Admin', 'Legal'].includes(props.user.role) ?
                <Button onClick={e => handleEditingToggle(true)} variant="text" color="primary" className={classes.editButton}>
                <FontAwesomeIcon icon={faPen} className={classes.iconForButton} />Update
                </Button>
                :// Button to update the orgSmartField inside Templating
                props.templating && props.openSmartField !== null && props.openSmartField.type === 'view' && !editing &&
                orgSmartField !== null && orgSmartField.orgID !== 'CANVEO' &&
                ['Admin', 'Legal'].includes(props.user.role) ?
                <Button onClick={e => handleEditingToggle(true)} variant="text" color="primary" className={classes.editButton}>
                    <FontAwesomeIcon icon={faPen} className={classes.iconForButton} />
                    Edit
                </Button>
                :// Button to go back to the previous screen
                /*props.templating && props.openSmartField !== null && props.openSmartField.type === 'view' && */
                editing && orgSmartField !== null && orgSmartField.orgID !== 'CANVEO' &&
                ['Admin', 'Legal'].includes(props.user.role) ?
                <Button onClick={e => handleEditingToggle(false)} variant="text" color="primary" className={classes.editButton}>
                    <FontAwesomeIcon icon={faArrowLeft} className={classes.iconForButton} />
                    Back
                </Button>
                :''}

                {!editing && 
                orgSmartField !== null && orgSmartField.orgID === props.user.orgID ?
                <Button onClick={e => setShowSFDetail(!showSFDetail)} variant="text" color="primary" className={classes.editButton}>
                    {showSFDetail ? "Hide " : <FontAwesomeIcon icon={faSearch} className={classes.iconForButton} />}Details
                </Button>
                :''}

                {['Admin', 'Legal'].includes(props.user.role) && 
                (orgSmartField === null || ['CANVEO', props.user.orgID].includes(orgSmartField.orgID)) &&
                (props.openSmartField !== null && props.openSmartField.ref !== 'clause' && props.openSmartField.type !== 'view-topic') ?
                <Button onClick={handleUnlink} variant="text" className={classes.deleteButton}>
                    <FontAwesomeIcon icon={faUnlink} className={classes.iconForButton} />
                    Unlink
                </Button>
                :''}
            </>
            : ''}
        </DialogActions>

    </Dialog>

    )
}

export default DialogSmartField