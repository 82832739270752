import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { getDefaultModule } from '../utils'
import { AppBar, Box, Breadcrumbs, Button, Grid, IconButton,
         Slide, Toolbar, Tooltip, Typography,
         makeStyles, useScrollTrigger } from '@material-ui/core';
import { DialogShareURL, Logo, MenuPopFilters, MenuPopAvatar, MenuBottomModule, MenuPopModules, 
         MenuPopNotifications, MenuTabsModule } from '.';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faSignInAlt, faShareAlt, faEnvelope, faTimes, faClone } from "@fortawesome/free-solid-svg-icons";

// HIDE ON SCROLL ***
function HideOnScrollAll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
    )
}

function Header(props) {

  const useStyles = makeStyles(theme => ({
    appBar: {
      backgroundColor: theme.palette.primary.contrastText,
      boxShadow: 'none'
    },
    exAppBar: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    exitAppBar: {
      [theme.breakpoints.only('xs')]: { height: '65px' },
      [theme.breakpoints.only('sm')]: { height: '70px', },
      [theme.breakpoints.up('md')]: { height: '80px', },
      backgroundColor: 'transparent',
      zIndex: '100'
    },
    mainToolBar: {
      [theme.breakpoints.down('sm')]: { boxShadow: '0px 0px 0px 0px rgba(30, 30, 30, 0.0)', },
      [theme.breakpoints.up('md')]: { boxShadow: '0px 1px 2px -1px rgba(30, 30, 30, 0.3)', },
      zIndex: '100'
    },
    mainToolBarHome: {
      [theme.breakpoints.only('xs')]: { height: '65px' },
      [theme.breakpoints.only('sm')]: { height: '70px', },
      [theme.breakpoints.up('md')]: { height: '80px', },
      zIndex: '100'
    },
    mainToolBarOnlyShadow: {
      boxShadow: '0px 1px 2px -1px rgba(30, 30, 30, 0.3)',
      //borderBottom: '1px solid #e0e0e0',
      zIndex: '100'
    },
    bclink: {
      color: theme.palette.primary.main,
      fontWeight: '700',
      textDecoration: 'none',
      fontSize: '13px',
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    bcactive: {
      fontSize: '13px',
      fontWeight: '400',
      marginTop: '2px',
      color: theme.palette.grey[600]
    },
    headIcon: {
      fontSize: '18px',
    },
    exitIconB: {
      position: 'fixed',
      left: 'auto',
      [theme.breakpoints.down('xs')]: { top: '6px', right: '15px', },
      [theme.breakpoints.up('sm')]: { top: '10px', right: '30px', padding: '10px 12px 10px 12px' },
    },
    exitIcon: {
      fontSize: '26px',
      padding: '8px 11px 8px 11px'
    },
    hidden: {
      display: 'none',
    },
    logoPadding: {
      paddingTop: '4px',
    },
    logoPaddingWithRight: {
      [theme.breakpoints.only('xs')]: { paddingTop: '3px' },
      [theme.breakpoints.up('sm')]: { paddingTop: '4px' },
      paddingRight: '20px',
    },
    breadcrumbPadding: {
      paddingTop: '0px',
    },
    homeHeadPrimaryButton: {
      marginTop: '3px',
      padding: '3px 12px 3px 12px',
    }, 
    signInIcon: {
      paddingLeft: '6px',
    },
    rectChipNoButton: {
      [theme.breakpoints.only('xs')]: { fontSize: '11px', padding: '3px 8px 3px 8px', margin: '0px 5px 0px 5px', },
      color: props.org !== undefined && props.org !== null && props.org.orgSettings !== undefined && ['full'].includes(props.org.orgSettings.whiteLabelLevel) &&
        props.whiteLabel !== undefined && props.whiteLabel !== null && props.whiteLabel.color !== undefined ? 
          props.whiteLabel.color : theme.palette.secondary.main,
      backgroundColor: 'transparent',
      borderRadius: '20px',
      fontSize: '13px',
      fontWeight: '600',
      padding: '3px 11px 3px 11px',
      margin: '0px 5px 0px 5px',
    },
    rectChip: {
      //[theme.breakpoints.only('xs')]: { fontSize: '11px', padding: '3px 8px 3px 8px', margin: '2px 5px 0px 5px', },
      [theme.breakpoints.only('xs')]: { fontSize: '11px', padding: '3px 6px 3px 6px', margin: '2px 3px 0px 3px', },
      color: theme.palette.grey[700],
      backgroundColor: 'transparent',
      borderRadius: '20px',
      fontSize: '13px',
      fontWeight: '600',
      padding: '3px 11px 3px 11px',
      margin: '1px 5px 0px 5px',
      '&:hover': {
        cursor: 'pointer',
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.grey[700],
      }
    },
    filterActiveIcon: {
      padding: '0px 8px 0px 2px',
      marginBottom: '-2px',      
    },
  }));
  const classes = useStyles();

  const history = useHistory()

  const [dialogShareOpen, setDialogShareOpen] = React.useState(false);

  const handleDialogShareOpen = (e) => {
    setDialogShareOpen(true)
  }

  const handleDialogShareClose = () => {
    setDialogShareOpen(false)
  }

  const pushToRoute = () => {
    history.push(props.exitTo);
  }

  const RenderLoginHead = ({hideNotifications}) => {
    return (
      <>
        {!hideNotifications ?
        <Grid item>

          <Box mr={2} id="introNotifications">
            <MenuPopNotifications 
              uid={props.user._id}
              userRole={props.user.role}
              notifications={props.notifications !== undefined ? props.notifications : []}
              markNotificationAsRead={props.markNotificationAsRead}
              markAllNotificationsAsRead={props.markAllNotificationsAsRead}
            />
          </Box>

        </Grid>
        : '' }
        <Grid item>
          
          <MenuPopAvatar
            module={props.module}
            user={props.user}
            modules={props.modules}
            whiteLabelLoading={props.whiteLabelLoading}
          />

        </Grid>
      </>
    )
  }

  const myAppBar = (
    <React.Fragment>

      {(props.user !== undefined && props.user.role !== undefined && 
       (['Admin', 'Business', 'Corporate', 'HR', 'Legal', 'Partnerships', 'Procurement', 'Sales', 'Counterparty'].includes(props.user.role) ||
       props.user.role.startsWith("ClientFor_"))) &&
       !props.whiteLabelLoading ?
      <Box display={{ xs: 'block', md: 'none' }}>
        <MenuBottomModule
          module={props.module} 
          active={props.active}
          org={props.org}
          user={props.user}
        />
      </Box> 
      : '' }

      <HideOnScrollAll {...props}>
      <AppBar 
          position="fixed" 
          className={classes.appBar}>

        <Toolbar className={props.withSubHead ? classes.mainToolBarOnlyShadow : classes.mainToolBar}>
          <Grid container 
            direction="row" 
            justify="space-between" 
            alignItems="center">
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid item className={classes.logoPadding}>
                  <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item>
                      <Logo
                        whiteLabel={props.whiteLabel}
                        whiteLabelLoading={props.whiteLabelLoading}
                        text={false}
                        to={props.user !== undefined && props.user.email !== undefined && 
                            ['corporate', 'sales', 'procurement', 'partnerships', 'people', 'counterparty'].includes(props.module) ? 
                            ("/core/" + props.module + "/dashboard") : ("/core/" + getDefaultModule(props.org, props.user) + "/dashboard")}
                      />
                    </Grid>

                    {props.pageType !== 'Agreement' || !props.templating ?
                    <Box display={props.active === "Home" ? {xs: 'block'} : { xs: 'none', sm: 'block' }}>
                    <Grid item>
                        
                      <MenuPopModules 
                        user={props.user}
                        module={props.module}
                        modules={props.modules} 
                        triggeredFromParentModules={props.triggeredFromParentModules}
                        whiteLabel={props.whiteLabel}
                        whiteLabelLoading={props.whiteLabelLoading}
                        //hideDropdown={props.pageType === 'Agreement' && !props.templating}
                      />

                    </Grid>
                    </Box>
                    :''}

                  </Grid>
                </Grid>
                <Grid item>
                  <Box ml={2} mr={2} display={{ xs: 'none', md: 'block' }}>
                    {props.crumbs !== undefined && props.crumbs !== null && props.crumbs.length > 0 &&
                    props.crumbs.some((c) => c.name !== undefined && c.name !== null && c.name.toLowerCase().startsWith("loading")) ?
                    <Skeleton variant="rect" animation="wave" width={100} height={12} />
                    :
                    <Breadcrumbs className={classes.breadcrumbPadding}
                      separator={<FontAwesomeIcon icon={faChevronRight} style={{fontSize: '9px'}} />} 
                      aria-label="breadcrumb"
                    >
                      {props.crumbs !== undefined ? 
                        props.crumbs.map((crumb, i) => (
                          (crumb.to === "active") ?
                          (<Typography color="textPrimary" className={classes.bcactive} key={i}>{crumb.name}</Typography>)
                          :
                          (<RouterLink color="inherit" to={crumb.to} className={classes.bclink} key={i}>{crumb.name}</RouterLink>)
                        )) : ''
                      }
                    </Breadcrumbs>
                    }
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
            {
            (['counterparty', 'corporate', 'sales', 'procurement', 'partnerships', 'people'].includes(props.module) && 
              props.pageType === 'Agreement') || props.templating ?
              
              <Grid container direction="row" alignItems="center" justify="flex-end">

                {['Draft', 'Review', 'Negotiation'].includes(props.agrStatus) || 
                ['Draft', 'Review', 'Negotiation'].includes(props.parentAgrStatus) ||
                (props.templating && props.templateInDraft) ?
                <>
                
                <Grid item>

                  <Box className={props.plainMode ? classes.hidden : ''}>
                  <Grid item>
                    <Grid container direction="row">

                      {(['Negotiation'].includes(props.agrStatus) || ['Negotiation'].includes(props.parentAgrStatus)) ||
                      (props.templating && props.oatVersions !== undefined && props.oatVersions !== null && props.oatVersions.length > 1) ?
                      <Box display={{ xs: 'none', sm: 'block' }}>
                        <Typography className={classes.rectChip} onClick={e => props.handleDiffDialogOpen()}>
                          <FontAwesomeIcon icon={faClone} className={classes.filterActiveIcon} />Compare
                        </Typography> 
                      </Box>
                      :''}

                      <Box>

                        <MenuPopFilters 
                        handleToggleFilter={props.handleToggleFilter}
                        cptyName={props.cptyName}
                        toggleFilter={props.toggleFilter}
                        showChangesFilter={!props.laterVersionOwnedByCpty &&
                          (['Negotiation'].includes(props.agrStatus) || ['Negotiation'].includes(props.parentAgrStatus))}
                        showClauseLockFilter={props.org !== undefined ? ['custE', 'legalOrg'].includes(props.org.clientType) : false}
                        />

                      </Box>
                    </Grid>
                  </Grid>
                  </Box>
                  
                </Grid>
                </>
                : '' }

                <Grid item>

                  {!props.templating ? 
                  <Tooltip title="Agreement Status" arrow>
                  <Box className={classes.rectChipNoButton}>
                    {props.agrStatus === "InForce" || props.parentAgrStatus === 'InForce' ? "In Effect" : 
                    props.agrStatus !== 'Exhibit' ? props.agrStatus: props.parentAgrStatus}
                  </Box>
                  </Tooltip>
                  :''}
                  
                </Grid>

                {props.user !== undefined && props.user !== null && props.user.email !== undefined &&
                !props.whiteLabelLoading ?
                  <RenderLoginHead hideNotifications={true} />               
                :props.user !== undefined && props.user !== null && props.user._id === undefined && 
                !props.whiteLabelLoading ?
                  <Grid item>

                    <Box>
                      <IconButton component={RouterLink} to="/core/login">
                        <FontAwesomeIcon icon={faSignInAlt} className={classes.headIcon} />
                      </IconButton>
                    </Box>

                  </Grid>
                :''}
              </Grid>
              :
              (props.user !== undefined && props.user.role !== undefined && // NON AGREEMENT HEADER
              (['Admin', 'Business', 'Corporate', 'HR', 'Legal', 'Partnerships', 'Procurement', 'Sales', 'Counterparty'].includes(props.user.role) ||
              props.user.role.startsWith("ClientFor_"))) &&
              !props.whiteLabelLoading ?

              <Grid container direction="row" alignItems="center" justify="flex-end">

                <Grid item>

                  <Box display={{ xs: 'none', md: 'block' }}>
                    {props.module === 'legal' && props.templating ?
                    ''  
                    :
                    <MenuTabsModule 
                      module={props.module} 
                      active={props.active}
                      org={props.org}
                      user={props.user}
                    />
                    }
                  </Box>  
                  
                </Grid>

                { (props.user !== undefined && props.user !== null && props.user.email !== undefined) ?
                (
                  <RenderLoginHead />
                ) 
                : 
                <Grid item>

                  <Box>
                    <IconButton component={RouterLink} to="/core/login">
                      <FontAwesomeIcon icon={faSignInAlt} className={classes.headIcon} />
                    </IconButton>
                  </Box>

                </Grid>}
              </Grid>
              :
              (props.module === 'network') ?
              <Grid item>

                <Box ml={0}>
                  <IconButton onClick={handleDialogShareOpen}>
                    <FontAwesomeIcon icon={faShareAlt} className={classes.headIcon} />
                  </IconButton>
                </Box>

              </Grid>
              :
              (props.module === 'open') ?
              <Grid item>

                <Box ml={0}>
                  <IconButton component={RouterLink} to="/contact">
                    <FontAwesomeIcon icon={faEnvelope} className={classes.headIcon} />
                  </IconButton>
                </Box>

              </Grid>
              : '' }
              
            </Grid>
          </Grid>
        </Toolbar>
        </AppBar>
        </HideOnScrollAll>
      </React.Fragment>
  )

  const homeAppBar = (
    <React.Fragment>
    <HideOnScrollAll {...props}>
    <AppBar 
      position="fixed" 
      className={classes.appBar}>
    <Toolbar className={classes.mainToolBarHome} >

      <Grid container 
        direction="row" 
        justify="space-between" 
        alignItems="center">
        <Grid item>
          <Grid container direction="row" alignItems="center">
            <Grid item className={classes.logoPaddingWithRight}>
              <Logo
                whiteLabel={props.whiteLabel}
                whiteLabelLoading={props.whiteLabelLoading}
                text={false}
                to={"/"} />
            </Grid>
          </Grid>
        </Grid>
        { (props.user !== undefined && props.user !== null && props.user.email !== undefined) ?
        (
        <Grid item>
          <Box>
          <Grid container direction="row" alignItems="center">
            <RenderLoginHead />
          </Grid>
          </Box>
        </Grid>
        ) 
        : 
        <Grid item>

          <Box>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Button color="primary"
                    className={classes.homeHeadPrimaryButton}
                    component={RouterLink} to="/core/login">
                    Sign in
                    <FontAwesomeIcon icon={faSignInAlt} className={classes.signInIcon} />
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        }
      </Grid>
    </Toolbar>
    </AppBar>
    </HideOnScrollAll>
    </React.Fragment>
  )

  const exitAppBar = (
    <AppBar 
      position="fixed" 
      className={classes.exAppBar}>

      <Toolbar className={classes.exitAppBar}>

      <IconButton
        onClick={props.exitClick !== null && props.exitClick !== undefined ? props.exitClick : pushToRoute} 
        className={classes.exitIconB}>
        <FontAwesomeIcon icon={faTimes} className={classes.exitIcon} />
      </IconButton>

      </Toolbar>
    </AppBar>
  )

  return (
    <div>
      {
      props.openOnly ? 
        <>
        {homeAppBar}
        </>
      :
      props.exitOnly ? 
        exitAppBar 
      :
      <>
        {myAppBar}
        {props.module === 'network' ?
        <DialogShareURL
          parentCallToClose={handleDialogShareClose}
          open={dialogShareOpen}
          orgProfile={props.orgProfile} />
        : '' }
      </>
      }

    </div>
  )
}

export default Header