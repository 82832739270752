import { createStore, combineReducers, applyMiddleware } from 'redux';

import { ActivityLog, AgrExec, AgrTypes, Certs, Network, Notifications, 
        Org, OrgAgrTemplates, OrgProfile, OrgProfileAgrTemplates, OrgProfileCerts, 
        OrgProfileSubs, OrgLabel, OrgSmartField,
        Rels, Subs, Cpents, User, Users, UserSession } from './reducers';
        
import thunk from 'redux-thunk';
//import logger from 'redux-logger';

const createRootReducer = () => combineReducers({
    activityLog: ActivityLog,
    agrExec: AgrExec,
    agrTypes: AgrTypes,
    certs: Certs,
    cpents: Cpents,
    network: Network,
    notifications: Notifications,
    org: Org,
    orgAgrTemplates: OrgAgrTemplates,
    orgLabel: OrgLabel,
    orgProfile: OrgProfile,
    orgProfileAgrTemplates: OrgProfileAgrTemplates,
    orgProfileCerts: OrgProfileCerts,
    orgProfileSubs: OrgProfileSubs,
    orgSmartField: OrgSmartField,
    rels: Rels,
    subs: Subs,
    user: User,
    users: Users,
    userSession: UserSession,
})

export const ConfigureStore = () => {
  const store = createStore(
    createRootReducer(), // root reducer with router state
    applyMiddleware(
      thunk, 
      //logger, 
    )
  );

  return store
}