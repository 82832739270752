import React, { useEffect } from 'react';
import { trunc, stripPlain, getClauseTitleIndication, randomString as generateRandomString, convertPlainToBlocks,
         didBlocksChange, convertBlocksToPlain } from '../utils'
//import { Element, scroller } from 'react-scroll'
import { Box, Button, Card, Chip, CircularProgress, Dialog, DialogActions, DialogContent, 
         Grid, IconButton, Slide, Typography, Tooltip,
         makeStyles } from '@material-ui/core';
import { InformationalTooltip, NegoCardEditor, TableEditable } from '.';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronCircleLeft, faChevronCircleRight, faFont, faHeading, faPlusCircle, faTable, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";  

const axios = require('axios');

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = (props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h6" style={{fontWeight: '700'}}>{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" style={{position: 'absolute', right: '8px', top: '10px'}} onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} style={{fontSize: '20px', padding: '0px 3px 0px 3px'}} />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

function RenderFlatCard({clauseCat, text, type, isDialogOpener}) {
    
    let style = isDialogOpener ? 
    {
        maxWidth: '450px', 
        padding: '5px 15px 5px 15px',
        margin: '5px 0px 5px 0px',
        backgroundColor: '#f6f6f6',
    }
    :
    {
        maxWidth: '450px', 
        padding: '5px 15px 5px 15px',
        margin: '5px 0px 5px 0px',
    }

    return (
        <Card variant="outlined" style={style}>
        <Box mt={1} mb={1} align="left">
        
            <Typography 
            variant={
                //type === 'insert' && clauseCat === 'sec' ? "h5" : 
                //type === 'insert' && clauseCat === 'ssec' ? "h6" : 
                type !== 'insert' && clauseCat === 'sec' ? "subtitle1" : type !== 'insert' && clauseCat === 'ssec' ? "subtitle2" : "body2"} 
            align={['table'].includes(clauseCat) && type === 'insert' ? "center" : "left"}
            color={['sec','ssec'].includes(clauseCat) || type === 'insert' ? "textPrimary" : "textSecondary"}>
                {['table'].includes(clauseCat) && type === 'insert' ? 
                    <><br/>(Table)<br/><br/></>
                :['table'].includes(clauseCat) ?
                    <>(Table)</> 
                :type === 'insert' ?
                    text.map((t,i) => (<React.Fragment key={i}><span 
                        style={t.includes('<title>') ? {fontWeight: '700'} : 
                               t.includes('<ssecTitle>') ? {fontWeight: '600', fontSize: '19px'} :
                               t.includes('<secTitle>') ? {fontWeight: '700', fontSize: '22px'} :
                        {}}>{stripPlain(t)}</span><br/></React.Fragment>)):
                    text}
            </Typography>

        </Box>
        </Card>
    )
}

function Task(props){

    const { id, index, clauseCat, text, dialogOpenerID } = props;

    return (
       <Draggable draggableId={id} key={id} index={index}>

         {(provided) => (
            <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps} 
            align="center" mb={0}>
            <RenderFlatCard
                clauseCat={clauseCat} 
                text={text} 
                type={'order'} 
                isDialogOpener={id === dialogOpenerID}
            />
            </Box>
        )}

       </Draggable>
    )
}

function Column(props){
    const { id, list, dragType, dialogOpenerID } = props;
 
    return (
 
        <Droppable droppableId={id}>
        {provided => (
 
           <div {...provided.droppableProps} ref={provided.innerRef}>
 
             {list.map((c,index)=>{
                return <Task 
                            id={c._id} 
                            key={index} 
                            index={index} 
                            clauseCat={c.clauseCat}
                            text={['sec', 'ssec', 'cl', 'cl1', 'cl2', 'cl3', 'cl4', 'preamble'].includes(c.clauseCat) && c.plain[0] !== undefined ? 
                                trunc(getClauseTitleIndication(c.plain),75) : ''}
                            underlyingCls={c.underlyingCls}
                            dragType={dragType}
                            dialogOpenerID={dialogOpenerID}
                        />
             })}
 
            {provided.placeholder}
           </div>
         )
        }
        </Droppable>
    )
}

const DialogClauseEditMode = (props) => {

    const useStyles = makeStyles(theme => ({
        cancelButton: {
            marginRight: 'auto',
        },
        mlittle: {
            marginTop: '4px',
            marginBottom: '4px',
        },
        sec: {
            fontWeight: '700',
            fontSize: '18px'
        },
        ssec: {
            fontWeight: '700',
            fontSize: '16px'
        },
        cl: {
            fontWeight: '400',
            fontSize: '14px'
        },
        chipDefault1: {
            [theme.breakpoints.down('xs')]: { fontSize: '10px', padding: '1px 3px 1px 5px', margin: '0px 2px 0px 2px', },
            [theme.breakpoints.only('sm')]: { fontSize: '11px', padding: '1px 3px 1px 5px', margin: '0px 2px 0px 2px', },
            [theme.breakpoints.only('md')]: { fontSize: '12px', padding: '1px 5px 1px 7px', margin: '0px 3px 0px 3px', },
            [theme.breakpoints.up('lg')]: { fontSize: '12px', padding: '1px 5px 1px 7px', margin: '0px 3px 0px 3px',  },
            fontWeight: '400',
            color: theme.palette.grey[700],
        },
        iconInChip: {
            color: theme.palette.success.main,
            fontSize: '10px',
        },
        iconB: {
            fontSize: '13px',
        },
        iconBB: {
            borderTop: '1px solid #F0F0F0'
        },
    }));
    const classes = useStyles();

    const [cls, setCls] = React.useState([]);
    const [levelCls, setLevelCls] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [movedCls, setMovedCls] = React.useState([]);
    const [insertStaging, setInsertStaging] = React.useState([])
    const [activeClause, setActiveClause] = React.useState(null)
    const [optionClicked, setOptionClicked] = React.useState({option: null, key: null})
    const [blockStyleButton, setBlockStyleButton] = React.useState(null)
    const [avClauses, setAvClauses] = React.useState([])

    useEffect(() => {
        if(props.open){

            let axiosQuery = props.templating ? 
                axios.get('/template/oat/' + props.user.orgID + '/' + props.oatoravid) :
                axios.get('/agr/agrv/' + props.oatoravid)

            axiosQuery.then((res) => {
                let avcs = res.data.data.clauses;

                axios.post('/clause/clauses', { clauses: avcs }) // Need to get the relevant clauses
                .then(function(rescls) {
                    let orderedCls = rescls.data.data.filter((c) => c.clauseStatus !== 'Deleted').sort((a,b) => avcs.indexOf(a._id) - avcs.indexOf(b._id))

                    if(props.type !== null && ['moveClause', 'moveTable', 'insert'].includes(props.type.type)) {
                        setCls(orderedCls)

                    } else if (props.type !== null && ['levelClause'].includes(props.type.type)) {
                        setLevelCls(orderedCls)
                    }
                    setAvClauses(avcs)
                    setLoading(false)

                }).catch(function(err) { console.log(err) })

            }).catch(function(err) { console.log(err) })
        }
    }, [props.open, props.type, props.avv])

    const closeDialog = () => {
        setLoading(false)
        props.parentCallToClose(null)
    }

    const toggleLevel = (direction, clid) => {
        let newLevelCls = levelCls
        let idx = newLevelCls.findIndex((c) => c._id === clid)
        if(idx > -1) {
            let newClauseCat =
                newLevelCls[idx].clauseCat === 'sec' && direction === 'clause' ? 'cl1' :
                newLevelCls[idx].clauseCat === 'ssec' && direction === 'clause' ? 'cl2' :
                newLevelCls[idx].clauseCat === 'cl1' && direction === 'head' ? 'sec' :
                newLevelCls[idx].clauseCat === 'cl2' && direction === 'head' ? 'ssec' :
                newLevelCls[idx].clauseCat === 'sec' && direction === 'right' ? 'ssec' :
                newLevelCls[idx].clauseCat === 'ssec' && direction === 'left' ? 'sec' :
                newLevelCls[idx].clauseCat === 'cl1' && direction === 'right' ? 'cl2' :
                newLevelCls[idx].clauseCat === 'cl2' && direction === 'right' ? 'cl3' :
                newLevelCls[idx].clauseCat === 'cl2' && direction === 'left' ? 'cl1' :
                newLevelCls[idx].clauseCat === 'cl3' && direction === 'left' ? 'cl2' : 
                newLevelCls[idx].clauseCat === 'cl3' && direction === 'right' ? 'cl4' : 
                newLevelCls[idx].clauseCat === 'cl4' && direction === 'left' ? 'cl3' : 
                'cl1'

            newLevelCls[idx] = {...newLevelCls[idx], clauseCat: newClauseCat, updated: true }
            setLevelCls([...newLevelCls])
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };
    
    const onDragEnd = (result) => {
        if (!result.destination) { return; }
        if (result.destination.index === result.source.index) { return; }
        if(cls.findIndex((c) => c.clauseCat === 'preamble') > -1 && result.destination.index < 1) { return; } // if you drop before a preamble

        let cl = cls.filter((c) => c._id === result.draggableId)[0]

        setMovedCls(mcs => [...mcs, cl.singleClauseID]) // log for the clause or parent of section

        if(cl !== undefined && cl.underlyingCls !== undefined) {
            cl.underlyingCls.forEach((c) => {
                setMovedCls(mcs => [...mcs, c.singleClauseID]) // log for potential underlyings
            })
        }

        const newCls = reorder(cls, result.source.index, result.destination.index);
        setCls([...newCls]);
    }

    const handleConfirm = () => {
        setLoading(true);
        let newcls = []

        let filteredInsertStaging = insertStaging.filter((is) => 
            (['table'].includes(is.clauseCat) && is.clauseObject.head !== undefined && is.clauseObject.rows !== undefined) ||
            (['sec', 'ssec', 'cl','cl1','cl2','cl3','cl4','preamble'].includes(is.clauseCat) && is.blocks !== null && is.blocks !== undefined &&
            is.blocks.blocks !== null && is.blocks.blocks !== undefined && is.blocks.blocks.some((b) => b.text !== '')))

        if(props.type !== null && props.type.type === 'insert' && filteredInsertStaging.length > 0) {
            let newAvcs = avClauses
            let keepTrackOfNewIDs = []

            filteredInsertStaging.forEach((is, i) => {

                // Ignore posting the new clause if it's empty
                    
                let creationDate = new Date().toISOString();
                let newClauseCat = is.clauseCat // fallback option for clause

                let newBlocks = is.clauseCat === 'table' ? {} : is.blocks;
                let newPlain = is.clauseCat === 'table' ? [] : convertBlocksToPlain(is.blocks)
                let newClauseObject = ['table'].includes(newClauseCat) ? is.clauseObject: {}
                let cts = []
                let newSclID = generateRandomString(20);

                axios.post('/clause/protect/clause', {
                    singleClauseID: newSclID, 
                    lockedBy: '', 
                    lockedByOrg: {type: 'none'},
                    clauseTypes: cts,
                    title: null, 
                    blocks: newBlocks,
                    plain: newPlain,
                    clauseObject: newClauseObject,
                    version: 1, 
                    clauseStatus: ['sec','ssec','cl','cl1','cl2','cl3','cl4','preamble'].includes(newClauseCat) ? 'Open' : 'Accepted', // insert as accepted for a sec/subsec title or table
                    commentStatus: 'None',
                    approvalStatus: 'None',
                    internalWorkflow: [],
                    clauseCat: newClauseCat,
                    pendingChange: [],
                    creationBy: props.user._id,
                    creationDate: creationDate,
                })
                .then(function (res) { // res includes the newly inserted clause

                    if(!props.templating) { props.handleActivityLog('clause', 'clInserted', newSclID, '', props.avv, '', {}) }

                    if(res.data.data._id !== undefined) { // it was newly added
                        keepTrackOfNewIDs.push({ isKey: is.key, newID: res.data.data._id})
                    }

                    if(keepTrackOfNewIDs.length === filteredInsertStaging.length) { // You're done - now push the new AVCS with path to reroute

                        // Build out the new avcs
                        filteredInsertStaging.forEach((ist, no) => {
                            let idxToInsert = ist.clauseCat === 'preamble' ? -1 : // insert Preamble at the start
                                no - 1 > -1 && keepTrackOfNewIDs.filter((ktni) => filteredInsertStaging[no-1].cid === ist.cid && ktni.isKey === filteredInsertStaging[no-1].key)[0] !== undefined ?
                                newAvcs.findIndex((na) => na === keepTrackOfNewIDs.filter((ktni) => filteredInsertStaging[no-1].cid === ist.cid && ktni.isKey === filteredInsertStaging[no-1].key)[0].newID) :
                                newAvcs.findIndex((na) => na === ist.cid)
                            if(keepTrackOfNewIDs.filter((ktni) => ktni.isKey === ist.key)[0] !== undefined && (idxToInsert > -1 || ist.clauseCat === 'preamble')) {
                                newAvcs.splice(idxToInsert + 1, 0, keepTrackOfNewIDs.filter((ktni) => ktni.isKey === ist.key)[0].newID)
                            }
                        })
                        handleNewClausesWithEditedFlagChange(newAvcs)
                    }

                }).catch(function (err) { console.log(err); })
            })

        } else if(props.type !== null && props.type.type === 'levelClause') {
            levelCls.filter((c) => c.updated).forEach((c) => {
                let newBlocks = {}, nBlocks = []
                c.blocks.blocks.forEach((b) => {
                    if(['sec'].includes(c.clauseCat) && ['clauseTitle', 'ssecTitle'].includes(b.type)) {
                        b.type = 'secTitle';
                    } else if(['ssec'].includes(c.clauseCat) && ['clauseTitle', 'secTitle'].includes(b.type)) {
                        b.type = 'ssecTitle';
                    } else if(['cl1','cl2','cl3','cl4'].includes(c.clauseCat) && ['ssecTitle', 'secTitle'].includes(b.type)) {
                        b.type = 'clauseTitle';
                    }
                    nBlocks.push(b)
                })
                newBlocks = {...c.blocks, blocks: nBlocks}
                let newPlain = convertBlocksToPlain(newBlocks)
                //console.log("newBlocks", newBlocks)
                //console.log("newPlain", newPlain)
                //console.log("clauseCat", c.clauseCat)
                
                axios.put('/clause/protect/clcat/' + c._id, {
                    clauseCat: c.clauseCat,
                    blocks: newBlocks,
                    plain: newPlain,
                })
            })

            props.history.push(props.path)
            closeDialog()

        } else if (props.type.type !== 'insert') {
            cls.forEach((c) => {
                newcls.push(c._id)
                if(c.underlyingCls !== undefined && c.underlyingCls.length > 0) {
                    c.underlyingCls.forEach((uc) => {
                        newcls.push(uc._id);
                    })
                }
            })
            if(!props.templating) {
                movedCls.forEach((mc) => {
                    props.handleActivityLog('clause', 'clMoved', mc, '', props.avv, props.user.displayName, { }) // logLevel, logType, singleClauseID, ticketID, keyVal1, keyVal2
                })
            }
    
            //props.updateAgrvNewClauses(props.oatoravid, newcls, props.history, props.path)
            handleNewClausesWithEditedFlagChange(newcls)
            closeDialog()
        } else {
            setLoading(false)
        }
    }

    const handleNewClausesWithEditedFlagChange = (newcls) => {

        // Need to first mark the main AV as updated (to avoid potential race conditions)
        if(props.templating) {
            props.updateAgrvNewClauses(props.oatoravid, newcls, props.history, props.path)
            
        } else {
            let mainAV = props.agrsAvs.filter((aav) => aav.isMain)[0] !== undefined ? 
            props.agrsAvs.filter((aav) => aav.isMain)[0].avid : null

            if(mainAV !== null) {
                axios.put('/agr/agrvedited/' + mainAV, {
                    isEdited: true,
                    primaryReady: false, 
                    secondaryReady: false,
                })
                .then(function (res) { // success - you will get the updated agrV from: res.data.data
                    // Now update the current AV with the newcls array
                    props.updateAgrvNewClauses(props.oatoravid, newcls, props.history, props.path)

                }).catch(function (err) { console.log(err) })
            }
        }
    }

    const handleInsert = (level, cid) => {

        let initialBlocks = null
        let clauseCat = 'cl4'
        let clauseObject = {}

        if(level === 'preamble') {
            clauseCat = 'preamble';
        
        } else if(level === 'sec') { 
            initialBlocks = convertPlainToBlocks('<secTitle></secTitle>');
            clauseCat = 'sec';

        } else if(level === 'ssec') { 
            initialBlocks = convertPlainToBlocks('<ssecTitle></ssecTitle>'); 
            clauseCat = 'ssec'

        } else if(level === 'clause') {
            // check if there is a previously inserted cl for this 
            let tempArr = insertStaging.filter((is) => is.cid === cid)
            if(tempArr.length > 0) { // There was already something inserted after this clause, utilize the latest for clauseCat determination
                let relevantIS = tempArr[tempArr.length - 1];
                clauseCat = relevantIS.clauseCat === 'sec' ? 'cl2' :
                            relevantIS.clauseCat === 'ssec' ? 'cl3' :
                            relevantIS.clauseCat

            } else if(cls.filter((c) => c._id === cid)[0] !== undefined) { // This clause is being inserted after an existing cl/sec/table
                clauseCat =  
                    cls.filter((c) => c._id === cid)[0].clauseCat === 'sec' ? 'cl2' :
                    cls.filter((c) => c._id === cid)[0].clauseCat === 'ssec' ? 'cl3' :
                    cls.filter((c) => c._id === cid)[0].clauseCat === 'preamble' ? 'cl1' :
                    ['cl','cl1','cl2','cl3','cl4'].includes(cls.filter((c) => c._id === cid)[0].clauseCat) ? cls.filter((c) => c._id === cid)[0].clauseCat : 'cl4'

            }

        } else if(level === 'table') {
            clauseObject = {} 
            clauseCat = 'table'
        }

        setInsertStaging(is => [...is, { 
            key: generateRandomString(10), 
            level: level, 
            cid: cid,
            blocks: initialBlocks,
            clauseCat: clauseCat,
            clauseObject: clauseObject,
        }])

    }

    const handleRemove = (iskey) => {
        setInsertStaging(insertStaging.filter((is) => is.key !== iskey))
    }

    const updateClauseBlocks = (blocks) => {

        // If the blocks didnt yet (fully) exist, or if they were changed, then update inside state
        if(activeClause !== null && insertStaging.filter((is) => is.key === activeClause)[0] !== undefined &&
        (insertStaging.filter((is) => is.key === activeClause)[0].blocks === undefined ||
        insertStaging.filter((is) => is.key === activeClause)[0].blocks === null || 
        insertStaging.filter((is) => is.key === activeClause)[0].blocks[0] === undefined ||
        insertStaging.filter((is) => is.key === activeClause)[0].blocks[0].entityRanges === undefined ||
        didBlocksChange(insertStaging.filter((is) => is.key === activeClause)[0].blocks, blocks))) {
            let iss = insertStaging
            let index = iss.findIndex((is) => is.key === activeClause)
            iss[index] = {...iss[index], blocks: blocks }
            setInsertStaging(iss)
        }
    }

    const handleFocusNewClause = (activeID) => {
        setActiveClause(activeID)
    }

    const negoCallBackOptions = (child, action, optionalHint) =>  {
        // Check for two events: clickhandled and a need for blockStyleButton to change
        if(['clickHandled'].includes(action)) { // Never filter
            setOptionClicked({option: null, key: null})
        } else if(['blockStyleButton'].includes(action) && blockStyleButton !== optionalHint) {
            setBlockStyleButton(optionalHint)
        }
    }

    const handleTableUpdate = (tableKey, newTable, change) => {

        let iss = insertStaging
        let index = iss.findIndex((is) => is.key === tableKey)
        iss[index] = {...iss[index], clauseObject: newTable }
        setInsertStaging(iss)
    
    }

    const RenderInsertNegoCard = (is,i) => {
        return (
            <React.Fragment key={is.key + "_" + i}>
            {is.level === 'table' ? 
            <Box mt={1} mb={2}>
                <TableEditable
                newTable={true}
                tableKey={is.key}
                active={true}
                focusClause={e => handleFocusNewClause(is.key)}
                editMode={'full'}
                curCpty={props.curCpty}
                proposedDel={null}
                handleTableUpdate={handleTableUpdate}
                clause={{ clauseObject: { 
                    head: [
                        { colKey: generateRandomString(5), value: "" }, 
                        { colKey: generateRandomString(5), value: "" }
                    ], 
                    rows: [ 
                        { rowKey: generateRandomString(5), cols: [{cellKey: generateRandomString(5), value: ""}, {cellKey: generateRandomString(5), value: ""}] },
                        { rowKey: generateRandomString(5), cols: [{cellKey: generateRandomString(5), value: ""}, {cellKey: generateRandomString(5), value: ""}] }
                    ]
                }}}
                />
                <Box mt={0} align="right">
                    <Tooltip title="Remove Table">
                    <IconButton onClick={e => handleRemove(is.key)}>
                        <FontAwesomeIcon icon={faTrash} className={classes.iconB} />
                    </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            :
            <Box align="left" className={['sec', 'ssec'].includes(is.level) ? "customEditor-section" : "customEditor"}
            style={{boxShadow: 'rgba(0, 0, 0, 0.05) 0px 3px 24px 0px, rgba(0, 0, 0, 0.02) 0px 0px 0px 1px', maxWidth: '470px'}}>
                <NegoCardEditor 
                key={is.key}
                clid={is.key}
                curCpty={props.curCpty}
                user={props.user}
                content={is.blocks === null ? {} : is.blocks}
                editMode={'full'}
                focusClause={e => handleFocusNewClause(is.key)}
                updateClauseBlocks={updateClauseBlocks}
                callbackOptions={is.key === activeClause ? negoCallBackOptions : () => void 0}
                reviewChangesMode={false}
                optionClicked={optionClicked.key === is.key && optionClicked.option !== null ? { option: optionClicked.option } : { option: null }}
                disableEditing={false}     
                isNew={is.blocks === null ? true : false}
                />
                <Box pr={1} align="right" className={classes.iconBB}>
                    {['clauseTitle', 'clausePar'].includes(blockStyleButton) && ['clause'].includes(is.level) && is.key === activeClause ? 
                    <Tooltip title={['clausePar'].includes(blockStyleButton) ? "Change from Title to Normal Text" : "Make Clause Title"} arrow>
                    <IconButton onClick={e => setOptionClicked({option: 'insertTitle', key: activeClause})}>
                        <FontAwesomeIcon icon={['clausePar'].includes(blockStyleButton) ? faFont : faHeading} className={classes.iconB} />
                    </IconButton>
                    </Tooltip>
                    :''}

                    <Tooltip title="Remove">
                    <IconButton onClick={e => handleRemove(is.key)}>
                        <FontAwesomeIcon icon={faTrash} className={classes.iconB} />
                    </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            }
            </React.Fragment>
        )
    }

    return (
        <Dialog
        open={props.open}
        scroll="paper"
        TransitionComponent={Transition}
        keepMounted
        onClose={closeDialog}
        fullWidth={true}
        maxWidth="sm"
        >
        {props.type !== null && props.type.type === 'insert' ?
            <DialogTitle onClose={closeDialog}>
                Insert Clauses&nbsp;&nbsp;
                <InformationalTooltip msg={"Canveo agreements consist of a collection of individual clauses.\n\nTo insert a new clause, scroll to the appropriate location and click one of the insert options:\n\n+ Clause: inserts an individual clause.\n+ Section: inserts a section title.\n+ Subsection: inserts a subsection title.\n+ Table: inserts a table.\n\nYou can insert more then one new \"block\" at the same time and complete the insertion by clicking \"Confirm insertion\"."} />
            </DialogTitle>
        :props.type !== null && props.type.type === 'levelClause' ? 
            <DialogTitle onClose={closeDialog}>
                Re-level clauses
            </DialogTitle>
        :props.type !== null ? // else (reordering)
            <DialogTitle onClose={closeDialog}>
                Reorder {props.type.type === 'moveTable' ? "Table" : props.type.type === 'moveClause' ? "Clauses" : props.type.type === 'subsection' ? "Sub Sections" : props.type.type === 'section' ? "Sections" : ""}
            </DialogTitle>
        :''
        }

        <DialogContent>

            <Box mt={2} mb={2}>
            {loading ? 
            <Box mt={10} mb={10} align="center">
                <CircularProgress size={24} disableShrink />
            </Box>
            :
            props.type !== null && props.type.type === 'insert' ?
                <Box align="center">
                {// Show Preamble editor if this is being newly inserted
                insertStaging.filter((is) => is.cid === 'preamble')[0] !== undefined ?
                    <Box mt={0} mb={2}>
                        {RenderInsertNegoCard(insertStaging.filter((is) => is.cid === 'preamble')[0], 'preamble')}
                    </Box>
                :// Include ability to insert a Preamble, if it doesnt yet exist
                !cls.some((c) => c.clauseCat === 'preamble' && c.clauseStatus !== 'Deleted') ?
                    <Box mt={0} mb={2}>
                        <Chip clickable icon={<FontAwesomeIcon icon={faPlusCircle} className={classes.iconInChip} />}
                            label={"Insert Preamble"}
                            size="small"
                            variant={'outlined'}
                            color={'default'}
                            className={classes.chipDefault1} 
                            onClick={e => handleInsert('preamble', 'preamble')} 
                        />
                    </Box>
                :''}
                {cls
                //.filter((c) => props.type !== undefined && props.type !== null && c._id === props.type.clid)
                .map((c) => (
                    <React.Fragment key={"edit_" + c._id}>
                    <RenderFlatCard
                        clauseCat={c.clauseCat} 
                        text={c.plain}
                        //text={props.type !== undefined && props.type !== null && c._id === props.type.clid ? c.plain : [trunc(getClauseTitleIndication(c.plain), 50)]}
                        type={props.type !== null ? props.type.type : ''}
                        isDialogOpener={props.type !== undefined && props.type !== null && c._id === props.type.clid}
                    />
                    {insertStaging.filter((is) => is.cid === c._id).map((is, i) => (
                        RenderInsertNegoCard(is,i)
                    ))
                    }
                    <Box mt={2} mb={2}>
                        <Chip clickable icon={<FontAwesomeIcon icon={faPlusCircle} className={classes.iconInChip} />}
                            label={"Clause"}
                            size="small"
                            variant={'outlined'}
                            color={'default'}
                            className={classes.chipDefault1} 
                            onClick={e => handleInsert('clause', c._id)} 
                        />
                        <Chip clickable icon={<FontAwesomeIcon icon={faHeading} className={classes.iconInChip} />}
                            label={"Section"}
                            size="small"
                            variant={'outlined'}
                            color={'default'}
                            className={classes.chipDefault1} 
                            onClick={e => handleInsert('sec', c._id)} 
                        />
                        <Chip clickable icon={<FontAwesomeIcon icon={faHeading} className={classes.iconInChip} />}
                            label={"Subsection"}
                            size="small"
                            variant={'outlined'}
                            color={'default'}
                            className={classes.chipDefault1} 
                            onClick={e => handleInsert('ssec', c._id)} 
                        />
                        <Chip clickable icon={<FontAwesomeIcon icon={faTable} className={classes.iconInChip} />}
                            label={"Table"}
                            size="small"
                            variant={'outlined'}
                            color={'default'}
                            className={classes.chipDefault1} 
                            onClick={e => handleInsert('table', c._id)} 
                        />
                    </Box>
                    </React.Fragment>
                ))}
                </Box>
            :props.type !== null && props.type.type === 'levelClause' ?
                levelCls.map((c) => (
                <Grid direction="row" container justify="space-between" alignItems="center" key={c._id}>
                    <Grid item>
                        <IconButton disabled={!['ssec','cl2','cl3','cl4'].includes(c.clauseCat)} onClick={e => toggleLevel('left', c._id)}>
                            <FontAwesomeIcon icon={faChevronCircleLeft} />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Box style={{width: '260px'}} align="left">
                        <Typography align="left" className={c.clauseCat === 'sec' ? classes.sec : c.clauseCat === 'ssec' ? classes.ssec : classes.cl}
                        style={
                            ['table', 'cl4'].includes(c.clauseCat) ? {marginLeft: '60px'} : 
                            ['cl3'].includes(c.clauseCat) ? {marginLeft: '40px'} :
                            ['ssec', 'cl2'].includes(c.clauseCat) ? {marginLeft: '20px'} : {marginLeft: '0px'}}>
                        {c.plain[0] !== undefined ? trunc(stripPlain(c.plain[0]), 24) : c.clauseCat === 'table' ? 'table' : '..' }
                        </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <IconButton disabled={!['sec','cl1','cl2','cl3'].includes(c.clauseCat)} onClick={e => toggleLevel('right', c._id)}>
                            <FontAwesomeIcon icon={faChevronCircleRight} />
                        </IconButton>
                        <IconButton disabled={!['sec','ssec','cl1','cl2'].includes(c.clauseCat)} onClick={e => toggleLevel(['sec','ssec'].includes(c.clauseCat) ? 'clause' : 'head', c._id)}>
                            <FontAwesomeIcon icon={['sec','ssec'].includes(c.clauseCat) ? faFont : faHeading} style={{fontSize: '16px', padding: '4px 5px 4px 5px'}} />
                        </IconButton>
                    </Grid>
                </Grid>
                ))
            :
            <DragDropContext onDragEnd={onDragEnd}>

                <Column id="1" list={cls} dragType={props.type !== null ? props.type.type : ''} dialogOpenerID={props.type !== null ? props.type.clid : ''} />

            </DragDropContext>
            }
            </Box>

        </DialogContent>

        <DialogActions className={classes.mlittle}>
            <Button onClick={closeDialog} variant="text" color="primary" className={classes.cancelButton}
            disabled={loading}>
                Close
            </Button>
            <Button color={"primary"} variant="contained" disableElevation onClick={handleConfirm}
                    style={{marginLeft: 'auto'}} 
                disabled={loading || 
                (props.type !== null && props.type.type === 'insert' && (insertStaging === null || insertStaging.length < 1))}>
                {loading ? <CircularProgress size={20} />: ''}
                {props.type !== null && props.type.type === 'insert' ? <>Confirm insertion&nbsp;&nbsp;<FontAwesomeIcon icon={faCheck} /></>
                :props.type !== null && props.type.type === 'levelClause' ? "Confirm new level" : "Confirm new order"}
            </Button>
        </DialogActions>
    </Dialog>

    )
}

export default DialogClauseEditMode