import React from 'react';
import theme from '../theme/theme';
import { useHistory } from 'react-router-dom';
import { prices } from '../assets/static/prices';
import { Button, Box, Grid, List, ListItem, ListItemText, ListItemIcon, Typography,
         makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const PricingContent = (props) => {

  const useStyles = makeStyles(theme => ({
    fullFlexGrid: {
        height: '100vh',
    },
    super: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '50px',
    },
    sub: {
      fontWeight: '500',
    },
    padRight: {
      fontWeight: '500',
      paddingRight: '15px',
    },
    primaryIcon: {
      color: theme.palette.primary.main,
    },
    greyIcon: {
      color: theme.palette.grey[400],
    },
    wideButton: {
      fontWeight: '700',
      borderRadius: '50px',
      padding: '8px 20px 8px 20px',
      marginTop: '30px',
      width: '180px',
    },
    greyer: {
      color: theme.palette.grey[700],
    },
    bold: {
      fontWeight: '700'
    },
    boxResizing: {
      [theme.breakpoints.only('xs')]: { width: '300px', },
      [theme.breakpoints.up('sm')]: { width: '500px', },
    }
  }));
  const classes = useStyles();

  const history = useHistory()

  const handleClick = (e, type) => {
    if(['business','premium'].includes(type)){ 
      if(!props.fromSignupScreen) {
        history.push('/signup/' + type)
        props.handlePricingClose()
      } else {
        props.handleSelection(type)
      }

    } else { 
      history.push(props.fromCompanyScreen ? '/contact/upgrade' : '/contact/demo') 
      props.handlePricingClose()
    }
  }

  const getConfigurationDesc = (type, custType) => {
    let s = ''
    if(type === 'usersInc') {
      s = custType === 'Enterprise' ? "Unlimited users" :
        "Includes " + (props.sliderValue > prices.filter((p) => p.custType === custType)[0].usersBase ? props.sliderValue : prices.filter((p) => p.custType === custType)[0].usersBase) + " users (Max " + prices.filter((p) => p.custType === custType)[0].maxUsers + ")"
    } else if(type === 'userPrice') {
      s = custType === 'Enterprise' ? "User profiles (Sales, Procurement, Legal, etc.)" : 
        "Each additional user: " + props.ccy + prices.filter((p) => p.custType === custType)[0].currencies[0].addUserAmt;
    
    } else if(type === 'maxEnts') {
      s = prices.filter((p) => p.custType === custType)[0].maxEntities === 1 ? 
            "Set up 1 legal entity" : 
          prices.filter((p) => p.custType === custType)[0].maxEntities < 9999999 ?
            (prices.filter((p) => p.custType === custType)[0].maxEntities + " legal entities") :
            "Set up unlimited legal entities"
    } else if(type === 'maxTemplates') {
      s = prices.filter((p) => p.custType === custType)[0].maxTemplates === 1 ? 
            "Set up 1 legal template" : 
          prices.filter((p) => p.custType === custType)[0].maxTemplates < 9999999 ?
            ("Set up " + prices.filter((p) => p.custType === custType)[0].maxTemplates + " legal templates") :
            "Set up unlimited legal templates"
    } else if(type === 'maxContracts') {
      s = prices.filter((p) => p.custType === custType)[0].agrsPerMonth === 1 ? 
            "Negotiate 1 contract / month" : 
          prices.filter((p) => p.custType === custType)[0].agrsPerMonth < 9999999 ?
            ("Negotiate " + prices.filter((p) => p.custType === custType)[0].agrsPerMonth + " contracts / month") :
            "Negotiate unlimited contracts / month"
    }
    return s
  }

  const whatsincluded = [
    /*{
      custType: 'Starter',
      list: [
        //"Ideal for sole proprietors",
        getConfigurationDesc('maxEnts', 'Starter'),
        getConfigurationDesc('maxTemplates', 'Starter'),
        getConfigurationDesc('maxContracts', 'Starter'),
        "Track changes, Clause History and Version Compare",
        "Free e-signatures",
        "Unlimited counterparty users",
        "Standard support",
        "Select one module",
      ]
    },*/
    {
      custType: 'Business',
      list: [
        //"Ideal for small businesses",
        getConfigurationDesc('usersInc', 'Business'),
        getConfigurationDesc('userPrice', 'Business'),
        getConfigurationDesc('maxEnts', 'Business'),
        getConfigurationDesc('maxTemplates', 'Business'),
        getConfigurationDesc('maxContracts', 'Business'),
        "Track changes, Clause History and Version Compare",
        "Internal comments and approval workflows",
        "Free integrated e-signatures",
        "Unlimited counterparty users",
        "Standard support",
        "Includes all Modules",
      ]
    },
    {
      custType: 'Business Premium',
      list: [
        //"Ideal for medium sized businesses",
        getConfigurationDesc('usersInc', 'Business Premium'),
        getConfigurationDesc('userPrice', 'Business Premium'),
        getConfigurationDesc('maxEnts', 'Business Premium'),
        getConfigurationDesc('maxTemplates', 'Business Premium'),
        getConfigurationDesc('maxContracts', 'Business Premium'),
        "Track changes, Clause History and Version Compare",
        "Internal comments and approval workflows",
        "Free integrated e-signatures",
        "Unlimited counterparty users",
        "Configure counterparty edit rights",
        "Configure signing order",
        "Offline signing",
        "Publish and manage your online terms",
        "Premium support",
        "Includes all Modules",
      ]
    },
    {
      custType: 'Enterprise',
      list: [
        //"Ideal for enterprise",
        getConfigurationDesc('usersInc', 'Enterprise'),
        getConfigurationDesc('userPrice', 'Enterprise'),
        "Read-only business users",
        getConfigurationDesc('maxEnts', 'Enterprise'),
        getConfigurationDesc('maxTemplates', 'Enterprise'),
        getConfigurationDesc('maxContracts', 'Enterprise'),
        "Track changes, Clause History and Version Compare",
        "Internal comments and approval workflows",
        "Free integrated e-signatures",
        "Unlimited counterparty users",
        "Configure counterparty edit rights",
        "Configure signing order",
        "Offline signing",
        "Publish and manage your online terms",
        "Platinum support",
        "Includes all Modules",
        "Counterparty Viewed notifications",
        "Legal guardrails for business users",
        "Integrated playbook",
        "Intelligent Legal Assistive Layer",
      ]
    },
  ]

  return (
    <div>
    <Grid container direction="row" justify="center" alignItems="flex-start" className={classes.fullFlexGrid}>
        <Grid item xs={12} sm={6} md={4} 
        style={props.sliderValue > prices.filter((p) => p.custType === 'Business')[0].maxUsers || (props.currentTier !== undefined && ['cust','custE'].includes(props.currentTier)) ? 
        { color: theme.palette.grey[400]} : {}}>
        <Grid container direction="column" justify="center" alignItems="center">
            <Box mt={2}>
            <Typography align="center" variant="subtitle1" className={classes.greyer}>
            BUSINESS
            </Typography>
            </Box>
            <Grid item container direction="row" justify="center" alignItems="flex-start">
            <Grid item>
                <Box className={classes.super}>{props.ccy}</Box>
            </Grid>
            <Grid item>
                {props.sliderValue > prices.filter((p) => p.custType === 'Business')[0].maxUsers || (props.currentTier !== undefined && ['cust','custE'].includes(props.currentTier)) ?
                <Typography align="center" variant="h1" className={classes.padRight}>
                n/a
                </Typography>
                :
                <Typography align="center" variant="h1" className={classes.padRight}>
                {Math.max(prices.filter((p) => p.custType === 'Business')[0].currencies[0].amount,
                prices.filter((p) => p.custType === 'Business')[0].currencies[0].amount + 
                ((props.sliderValue - prices.filter((p) => p.custType === 'Business')[0].usersBase)
                    * prices.filter((p) => p.custType === 'Business')[0].currencies[0].addUserAmt))}
                </Typography>
                }
            </Grid>
            </Grid>
            <Grid item>
            <Typography variant="body2" align="center" className={classes.sub}>per month</Typography>
            </Grid>
            <Grid item>
            <Box mt={3}>
            <List dense>
                {whatsincluded.filter((wi) => wi.custType === 'Business')[0].list.map((li, i) => (
                <ListItem key={"Business_"+ i}>
                    <ListItemIcon>
                    <FontAwesomeIcon icon={faCheck} className={props.sliderValue > prices.filter((p) => p.custType === 'Business')[0].maxUsers || (props.currentTier !== undefined && ['cust','custE'].includes(props.currentTier)) ? classes.greyIcon : classes.primaryIcon} />
                    </ListItemIcon>
                    <ListItemText primary={li} />
                </ListItem>
                ))}
            </List>
            </Box>
            </Grid>
            {!props.fromCompanyScreen ? 
            <Grid item>
            <Box mb={2}>
            <Button onClick={e => handleClick(e, 'business')}
            disabled={props.sliderValue > prices.filter((p) => p.custType === 'Business')[0].maxUsers || (props.currentTier !== undefined && ['cust','custE'].includes(props.currentTier))}
            variant="contained" disableElevation color="primary" className={classes.wideButton}>
                {props.fromSignupScreen ? "Select" : "Sign Up"}
            </Button>
            </Box>
            </Grid>
            :''}
            
        </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4}
        style={props.sliderValue > prices.filter((p) => p.custType === 'Business Premium')[0].maxUsers || (props.currentTier !== undefined && ['custE'].includes(props.currentTier)) ? 
        { color: theme.palette.grey[400]} : {}}>
        <Grid container direction="column" justify="center" alignItems="center">
            <Box mt={2}>
            <Typography align="center" variant="subtitle1" className={classes.greyer}>
            BUSINESS PREMIUM
            </Typography>
            </Box>
            <Grid item container direction="row" justify="center" alignItems="flex-start">
            <Grid item>
                <Box className={classes.super}>{props.ccy}</Box>
            </Grid>
            <Grid item>
                {props.sliderValue > prices.filter((p) => p.custType === 'Business Premium')[0].maxUsers || (props.currentTier !== undefined && ['custE'].includes(props.currentTier)) ?
                <Typography align="center" variant="h1" className={classes.padRight}>
                n/a
                </Typography>
                :
                <Typography align="center" variant="h1" className={classes.padRight}>
                {Math.max(prices.filter((p) => p.custType === 'Business Premium')[0].currencies[0].amount,
                prices.filter((p) => p.custType === 'Business Premium')[0].currencies[0].amount + 
                ((props.sliderValue - prices.filter((p) => p.custType === 'Business Premium')[0].usersBase)
                    * prices.filter((p) => p.custType === 'Business Premium')[0].currencies[0].addUserAmt))}
                </Typography>
                }
            </Grid>
            </Grid>
            <Grid item>
            <Typography variant="body2" align="center" className={classes.sub}>per month</Typography>
            </Grid>
            <Grid item>
            <Box mt={3}>
            <List dense>
                {whatsincluded.filter((wi) => wi.custType === 'Business Premium')[0].list.map((li, i) => (
                <ListItem key={"Premium_"+ i}>
                    <ListItemIcon>
                    <FontAwesomeIcon icon={faCheck} className={props.sliderValue > prices.filter((p) => p.custType === 'Business Premium')[0].maxUsers || (props.currentTier !== undefined && ['custE'].includes(props.currentTier)) ? classes.greyIcon : classes.primaryIcon} />
                    </ListItemIcon>
                    <ListItemText primary={li} />
                </ListItem>
                ))}
            </List>
            </Box>
            </Grid>
            {!props.fromCompanyScreen ? 
            <Grid item>
            <Box mb={2}>
            <Button onClick={e => handleClick(e, 'premium')}
            disabled={props.sliderValue > prices.filter((p) => p.custType === 'Business Premium')[0].maxUsers || (props.currentTier !== undefined && ['custE'].includes(props.currentTier))}
            variant="contained" disableElevation color="primary" className={classes.wideButton}>
                {props.fromSignupScreen ? "Select" : "Sign Up"}
            </Button>
            </Box>
            </Grid>
            :''}
        </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
        <Grid container direction="column" justify="center" alignItems="center">
            <Box mt={2}>
            <Typography align="center" variant="subtitle1" className={classes.greyer}>
            ENTERPRISE
            </Typography>
            </Box>
            <Grid item container direction="row" justify="center" alignItems="flex-start">
                <Grid item>
                <Box mt={4} mb={4} style={{paddingBottom: '18px'}}>
                    <Typography align="center" variant="h5">CONTACT US</Typography>
                </Box>
                </Grid>
            </Grid>
        </Grid>
        <Grid item>
            <Box mt={3}>
            <List dense>
                {whatsincluded.filter((wi) => wi.custType === 'Enterprise')[0].list.map((li, i) => (
                <ListItem key={"Enterprise_"+ i}>
                    <ListItemIcon>
                    <FontAwesomeIcon icon={faCheck} className={classes.primaryIcon} />
                    </ListItemIcon>
                    <ListItemText primary={li} />
                </ListItem>
                ))}
            </List>
            </Box>
        </Grid>
        {!props.fromCompanyScreen ? 
        <Grid item>
            <Box onClick={e => history.push('/contact/demo')}
            align="center" mb={2}>
            <Button variant="contained" disableElevation color="secondary" className={classes.wideButton}>
            Contact Us
            </Button>
            </Box>
        </Grid>
        :''}
        </Grid>
    </Grid>
    </div>
  )
}

export default PricingContent