
function getUsersWhoLockAClause(clauses, users, uid, baseArray) {

    let uEditing = baseArray // Users, aside from yourself, who have a lock on a clause
    let CLAUSE_LOCK_TIME = 30 * 60 * 1000; // in ms => ie. 30 mins - Updated in clauseController 2x, AgreementEditor and this function

    clauses
    .filter((c) => c.lockedBy !== undefined && c.lockedBy !== null && c.lockedBy !== '' && c.lockedBy !== uid &&
    (Date.now() - new Date(c.lastUpdateDate).getTime()) < CLAUSE_LOCK_TIME)
    .forEach((c) => {
        if(users.filter((u) => u._id === c.lockedBy)[0] !== undefined &&
        !uEditing.some((u) => u === users.filter((u) => u._id === c.lockedBy)[0].displayName)) {
            uEditing.push(users.filter((u) => u._id === c.lockedBy)[0].displayName)
        }
    })

    return uEditing;

}
export { getUsersWhoLockAClause }