import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Divider, Grid, Typography, Link, makeStyles } from '@material-ui/core';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn, faTwitter, faYoutube, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer = (props) => {

    const useStyles = makeStyles(theme => ({
        divider: {
          backgroundColor: theme.palette.grey[100],
        },
        footerLeft: {
          [theme.breakpoints.down('xs')]: { justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px' },
          [theme.breakpoints.up('sm')]: { justifyContent: 'flex-start' },
        },
        footerRight: {
          [theme.breakpoints.down('xs')]: { justifyContent: 'center', paddingBottom: '10px' },
          [theme.breakpoints.up('sm')]: { justifyContent: 'flex-end' },
        },
        footerLink: {
          textDecoration: 'none',
          color: theme.palette.grey[600],
          '&:hover': {
            color: theme.palette.primary.main
          },
        },
        iconLink: {
            '&:hover': {
                color: theme.palette.secondary.main
            },
        },
    }));
    const classes = useStyles();

    return (
        <React.Fragment>
        
        <Container maxWidth="lg">
            <Grid container justify="center" alignItems="center">
            <Box width="100%" mb={1} mt={4}>
                <Divider className={classes.divider} />
                <Grid container direction="row" justify="center" alignItems="center">    
                    <Grid item xs={12} sm={6}>

                    <Grid container alignItems="center"
                        className={classes.footerLeft} >
                        <Box m={1}>
                        <Link href="https://www.linkedin.com/company/canveo" className={classes.iconLink}>
                            <FontAwesomeIcon icon={faLinkedinIn} size="1x" />
                        </Link>
                        </Box>
                        <Box m={1}>
                        <Link href="https://twitter.com/getcanveo" className={classes.iconLink}>
                            <FontAwesomeIcon icon={faTwitter} size="1x" />
                        </Link>
                        </Box>
                        <Box m={1}>
                        <Link href="https://www.youtube.com/channel/UCk5T2k-IOVCSit5xTAukS5g" className={classes.iconLink}>
                            <FontAwesomeIcon icon={faYoutube} size="1x" />
                        </Link>
                        </Box>
                        <Box m={1}>
                        <Link href="https://fb.me/getcanveo" className={classes.iconLink}>
                            <FontAwesomeIcon icon={faFacebookF} size="1x" />
                        </Link>
                        </Box>
                        <Box m={1}>
                        <Link component={RouterLink} to="/contact" className={classes.iconLink}>
                            <FontAwesomeIcon icon={faEnvelope} color="primary" size="1x" />
                        </Link>
                        </Box>
                    </Grid>

                    </Grid>

                    <Grid item xs={12} sm={6}>
                    <Grid container alignItems="center"
                        className={classes.footerRight} >
                        <Typography variant="body2">
                        <Box m={1} component={RouterLink} to="/network/canveo/msa" className={classes.footerLink}>
                            Terms of Use
                        </Box>
                        <Box m={1} component={RouterLink} to="/network/canveo/privacy-policy" className={classes.footerLink}>
                            Privacy Policy
                        </Box>
                        </Typography>
                    </Grid>
                </Grid>
                </Grid>
            </Box>
            </Grid>
        </Container>
        </React.Fragment>
    );
}

export default Footer