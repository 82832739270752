import { FETCH_NOTIFICATIONS_PENDING, FETCH_NOTIFICATIONS_DONE, FETCH_NOTIFICATIONS_SUCCESS, FETCH_NOTIFICATIONS_ERROR, UPDATE_NOTIFICATION } from '../ActionTypes';

export const Notifications = (state  = { 
    pending: false,
    error: null,
    notifications: []
}, action) => {
switch(action.type) {
        case FETCH_NOTIFICATIONS_PENDING: 
            return {
                ...state,
                pending: true
            }
        case FETCH_NOTIFICATIONS_DONE: 
            return {
                ...state,
                pending: false
            }
        case FETCH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                notifications: action.payload
            }
        case FETCH_NOTIFICATIONS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }
        case UPDATE_NOTIFICATION:
            // Find the index of the notification to replace with the updated one
            var newnot = action.payload
            var newNotifications = state.notifications
            var index = null;
            for (var i=0; i<state.notifications.length; i++) {
                if ( state.notifications[i]._id === newnot._id ) {
                    index = i;
                    break;
                }
            }
            newNotifications[index] = newnot
            return { 
                ...state, 
                notifications: newNotifications};
        default: 
            return state;
    }
};