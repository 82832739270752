import React from 'react';
import theme from '../theme/theme';
import PropTypes from 'prop-types'; // https://reactjs.org/docs/typechecking-with-proptypes.html
import { Button, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, 
         Slide, Snackbar, TextField, Typography,
         makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faCopy } from "@fortawesome/free-solid-svg-icons";
import copy from 'copy-to-clipboard';

const axios = require('axios');

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const DialogShareURL = (props) => {

  const useStyles = makeStyles(theme => ({
    iconInButton: {
        marginRight: '10px',
    }
  }));
  const classes = useStyles();

  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackMsg, setSnackMsg] = React.useState('Succesfully completed');
  const [emailAddress, setEmailAddress] = React.useState(null);
  const [emailError, setEmailError] = React.useState(false);
  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false)

  const getPageURL = () => {
    let pageurl = 'https://app.getcanveo.com/network'; //canveo.co
    if(props.orgProfile !== undefined) {
      pageurl = 'https://app.getcanveo.com/network/' + props.orgProfile.orgurl
    }
    return pageurl;
  }

  const handleShareCopy = () => {
    copy(getPageURL());
    setSnackMsg("Successfully copied to your clipboard")
    setSnackOpen(true);
    props.parentCallToClose();
  }

  const handleShareEmail = () => {
    setSubmitting(true);
    setEmailError(false);
    setNameError(false)
    if(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress) && name.length > 3){
      axios.post('/mail/shareurl/' + emailAddress, {
        userFullName: name,
        orgShortName: props.orgProfile !== undefined ? props.orgProfile.shortName : 'n/a',
        pageURL: getPageURL()
      })
      .then(function (res) { // got a msg from the server
        setSnackMsg("The link was sent to the email address provided")
        setSnackOpen(true);
        setSubmitting(false);
        props.parentCallToClose();
      })
      .catch(function (err) { // err in the server request
        setSnackMsg("There was an error sharing sending the email")
        setSnackOpen(true);
        setSubmitting(false);
        props.parentCallToClose();
      })  
      setName('');
      setEmailAddress('');
      
    } else {
      if(name.length < 4){ setNameError(true); }
      if(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress) === false) { setEmailError(true); }
      setSubmitting(false);
    }
  }

  const handleEmailChange = event => {
    setEmailAddress(event.target.value);
  };

  const handleNameChange = event => {
    setName(event.target.value);
  }

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') { return; }
    setSnackOpen(false);
  };

return (
    <React.Fragment>
    <Dialog
    open={props.open}
    scroll="paper"
    TransitionComponent={Transition}
    keepMounted
    onClose={props.parentCallToClose}
    >
        <DialogTitle style={{textAlign: 'center'}}>{"Share or Copy link"}</DialogTitle>
        <DialogContent>
          
          <Typography align="center" variant="subtitle2">
            Send link to recipient
          </Typography>
          
          <Box width={250} mt={2} mb={1}>
            <TextField 
              type="email"
              variant="outlined"
              label="Email address of recipient"
              error={emailError}
              placeholder="Email address of recipient"
              fullWidth
              onChange={handleEmailChange} />
          </Box>
          <Box width={250} mt={1} mb={1}>
            <TextField 
              type="text"
              variant="outlined"
              label="Your full name"
              error={nameError}
              placeholder="Your own full name"
              fullWidth
              onChange={handleNameChange} />
          </Box>
          <Box width={250} mb={2}>
          <Button 
            onClick={handleShareEmail} 
            fullWidth 
            variant="contained" 
            disableElevation 
            color="primary"
            disabled={submitting}>
            <FontAwesomeIcon icon={faEnvelope} className={classes.iconInButton} />
            { submitting && (<CircularProgress size={24} /> )}
            { submitting ? 'Sending...' : 'Send email' }
          </Button>
          </Box>
          

        </DialogContent>
        <DialogActions>
          <Button onClick={props.parentCallToClose} color="default" disableElevation 
            style={{marginRight: 'auto', width: '120px', backgroundColor: theme.palette.grey[200]}}>
            Cancel
          </Button>

          <Button onClick={handleShareCopy} variant="contained" color="primary" disableElevation style={{width: '120px'}}>
            <FontAwesomeIcon icon={faCopy} className={classes.iconInButton} />
            Copy URL
          </Button>
        </DialogActions>

    </Dialog>

    <Snackbar
        open={snackOpen}
        TransitionComponent={SlideTransition}
        message={snackMsg}
        autoHideDuration={3000}
        onClose={handleSnackClose}
    />
    </React.Fragment>

  )
}

DialogShareURL.propTypes = {
  open: PropTypes.bool,
  parentCallToClose: PropTypes.func,
  orgProfile: PropTypes.object
};

export default DialogShareURL