import React from 'react';
import PropTypes from 'prop-types'; // https://reactjs.org/docs/typechecking-with-proptypes.html
import { Avatar, TextField, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import defaultUser from '../assets/img/defaultuser.png';

const SelectUsers = (props) => {

    const useStyles = makeStyles(theme => ({
        avatarOption: {
            width: '30px',
            height: '30px',
            marginRight: '20px',
        },
    }));
    const classes = useStyles();

    const [selectVal, setSelectVal] = React.useState(props.initialVal !== undefined ? props.initialVal : undefined)

    const handleSearchChange = (value) => {
        setSelectVal(value);
        props.parentCall((value !== undefined && value !== null) ? value : {})
    }

    return (

        <Autocomplete
            style={{ width: '100%' }}
            options={props.users.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0))}
            value={selectVal || null}
            onChange={(e, value) => handleSearchChange(value)}
            getOptionLabel={option => (option.displayName !== undefined ? option.displayName : '')}
            renderOption={option => (
                <React.Fragment>
                <Avatar alt={option.displayName} src={option.photoURL !== undefined && option.photoURL !== '' ? option.photoURL : defaultUser} className={classes.avatarOption} />
                {option.displayName + (option.title !== undefined && option.title !== null && option.title !== '' ? (", " + option.title) : '')}
                </React.Fragment>
            )}
            renderInput={params => (
                <TextField
                {...params}
                style={{ width: '100%' }}
                label={props.label}
                variant="outlined"
                inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                />
            )}
        />

    );
}

SelectUsers.propTypes = {
  users: PropTypes.array,
  label: PropTypes.string,
  initialVal: PropTypes.object,
};

export default SelectUsers