import { getClauseTitleIndication } from '.'

function getTOC(clauses, avClauses, plainMode) { // agrOrParentInExecForce 

    let tocCreation = [], l1no = 0, l2no = 0, l3no = 0 /*, firstSecOrSsec = false;*/
    let hasPreamble = ['preamble'].includes(clauses.sort((a,b) => avClauses.indexOf(a._id) - avClauses.indexOf(b._id))[0].clauseCat) ||
                    (clauses.sort((a,b) => avClauses.indexOf(a._id) - avClauses.indexOf(b._id))[0].title === '' &&
                    ['cl', 'cl1', 'cl2', 'cl3', 'cl4'].includes(clauses.sort((a,b) => avClauses.indexOf(a._id) - avClauses.indexOf(b._id))[0].clauseCat) &&
                    clauses.some((c) => ['sec','ssec'].includes(c.clauseCat))) ? true : false;
    clauses
    .filter((c) => (c.clauseStatus !== 'proposedDel' || !(plainMode)) && c.clauseStatus !== 'Deleted') // agrOrParentInExecForce
    .sort((a,b) => avClauses.indexOf(a._id) - avClauses.indexOf(b._id))
    .forEach((c, i) => {

        let title = i === 0 && hasPreamble ? "Preamble" :
                    c.title === null && c.plain[0] !== undefined ? getClauseTitleIndication(c.plain) : // Should be able to DECOM from here, once all agrs are migrated.
                    c.title !== null && c.title !== '' ? c.title :
                    ['cl', 'cl1', 'cl2', 'cl3', 'cl4'].includes(c.clauseCat) ? getClauseTitleIndication(c.plain) :
                    ['sec'].includes(c.clauseCat) ? "Section" :
                    ['ssec'].includes(c.clauseCat) ? "Sub section" :
                    ['table'].includes(c.clauseCat) ? "Table" : ''

        if(!['None', 'Manual'].includes(c.numberType)) {
            if(['sec', 'cl1'].includes(c.clauseCat)) { 
                let toAdd = 0; /* Add any cls (e.g. cl, cl2, cl3) bf sec / cl1 */
                if(l1no === 0 && l2no !== 0) { toAdd = l2no }
                else if (l1no === 0 && l2no === 0 && l3no !== 0) { toAdd = l3no }
                l1no = l1no + 1 + toAdd;
                l2no = 0; 
                l3no = 0; 
            } else if(['ssec','cl2'].includes(c.clauseCat) && l2no === 0) { l2no = l2no + l3no + 1; l3no = 0; }
            else if(['ssec','cl2'].includes(c.clauseCat)) { l2no = l2no + 1; l3no = 0; }
            else if(['cl', 'cl3' ,'table'].includes(c.clauseCat) && !(hasPreamble && i === 0)) { l3no = l3no + 1; }
        }

        let no = c.title === null ? '' : 
                c.numberType === 'Manual' ? c.numberManual :
                c.numberType === 'None' /*|| (hasPreamble && !firstSecOrSsec)*/ ? '' :
                (l1no !== 0 ? (l1no + ".") : '') + (l2no !== 0 ? (l2no + ".") : '') + (l3no !== 0 ? (l3no + ".") : '');
        
        let level = (i === 0 && hasPreamble) || ['sec','cl1'].includes(c.clauseCat) ? 1 : 
            ['ssec', 'cl2'].includes(c.clauseCat) ? 2 : 
            ['cl', 'cl3','table'].includes(c.clauseCat) ? 3 : 
            ['cl4'].includes(c.clauseCat) ? 4 : 1 

        tocCreation.push({ id: c._id, level: level, no: no, title: title, sclid: c.singleClauseID})
    })

    return tocCreation

}

export { getTOC }