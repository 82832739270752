import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types'; // https://reactjs.org/docs/typechecking-with-proptypes.html
import { Button, makeStyles } from '@material-ui/core';

const MenuTabsModule = (props) => {

    const useStyles = makeStyles(theme => ({
        tabButton: {
            background: 'transparent',
            borderRadius: '0px',
            border: 'none',
            color: theme.palette.grey[700],
            margin: '0px auto 0',
            display: 'inline',
            fontSize: '13px',
            fontWeight: '400',
            outline: 'none',
            transition: '0.3s',
            padding: '22px',
            borderBottom: '2px solid white',
            borderTop: '2px solid transparent',
            '&:hover': {
                backgroundColor: 'transparent',
                borderBottom: '2px solid' + theme.palette.primary.main,
            },
            '&:active' : {
                background: theme.palette.primary.main,
                color: 'white',
                transition: 'none'      
            }
        },
        activeTabButton: {
            background: 'transparent',
            borderRadius: '0px',
            border: 'none',
            color: theme.palette.grey[700],
            margin: '0px auto 0',
            display: 'inline',
            fontSize: '13px',
            fontWeight: '400',
            outline: 'none',
            transition: '0.3s',
            padding: '22px',
            borderBottom: '2px solid' + theme.palette.primary.main,
            borderTop: '2px solid transparent',
            '&:hover': {
                backgroundColor: 'transparent',
                borderBottom: '2px solid' + theme.palette.primary.main,
            },
            '&:active' : {
                background: theme.palette.primary.main,
                color: 'white',
                transition: 'none'      
            }
        }
    }));
    const classes = useStyles();

    if(['partnerships', 'procurement', 'sales', 'corporate', 'people'].includes(props.module)) {
        return (
        <React.Fragment>
            <Button 
                className={props.active === 'Home' ? classes.activeTabButton : classes.tabButton} 
                component={RouterLink} 
                to={"/core/" + props.module + "/dashboard"}>Home</Button>
            <Button 
                className={props.active === 'Relationships' ? classes.activeTabButton : classes.tabButton} 
                component={RouterLink} 
                to={"/core/" + props.module + "/relationships"}>Relationships</Button>
            <Button 
                className={props.active === 'Tasks' ? classes.activeTabButton : classes.tabButton} 
                component={RouterLink} 
                to={"/core/" + props.module + "/tasks"}>Tasks</Button>
            {props.user !== undefined && ['Legal', 'Admin'].includes(props.user.role) ?
            <Button 
                className={props.active === 'Analytics' ? classes.activeTabButton : classes.tabButton} 
                component={RouterLink} 
                to={"/core/" + props.module + "/analytics"}>Analytics</Button>
            :''}
        </React.Fragment>
        )
    } else if(props.module === "admin") {
        return (
        <React.Fragment>
            <Button 
                className={props.active === 'Company' ? classes.activeTabButton : classes.tabButton} 
                component={RouterLink} 
                to="/core/admin/company">Company</Button>
            <Button 
                className={props.active === 'Users' ? classes.activeTabButton : classes.tabButton} 
                component={RouterLink} 
                to="/core/admin/usermgt">Users</Button>
        </React.Fragment>
        )
    } else if(props.module === "legal") {
        return (
        <React.Fragment>
            <Button 
                className={props.active === 'Templates' ? classes.activeTabButton : classes.tabButton} 
                component={RouterLink} 
                to="/core/legal/templates">Templates</Button>
            {props.org !== undefined && props.org.clientType !== 'legalOrg' ?
            <Button 
                className={props.active === 'Terms' ? classes.activeTabButton : classes.tabButton} 
                component={RouterLink} 
                to="/core/legal/templates/terms/terms">Terms</Button>
            :''}
            <Button 
                className={props.active === 'Playbook' ? classes.activeTabButton : classes.tabButton} 
                component={RouterLink} 
                to="/core/legal/playbook/sales">Playbook</Button>
            {props.org !== undefined && props.org.clientType !== 'legalOrg' ?
            <Button 
                className={props.active === 'Certifications' ? classes.activeTabButton : classes.tabButton} 
                component={RouterLink} 
                to="/core/legal/certifications">Certifications</Button>
            :''}
            {/*
            <Button 
                className={props.active === 'Hub' ? classes.activeTabButton : classes.tabButton} 
                component={RouterLink} 
                to="/core/legal/hub">The Hub</Button>*/}
        </React.Fragment>
        )
    } else {
        return null
    }

}

MenuTabsModule.propTypes = {
  module: PropTypes.string.isRequired
};

export default MenuTabsModule
