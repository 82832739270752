import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";  

const ChipUserType = (props) => {

  const useStyles = makeStyles(theme => ({
    pillStd: {
        width: '200px',
        fontWeight: '500',
    },
    pillTiny: {
        width: '120px',
        fontWeight: '500',
    },
    pillTinyLegal: {
        width: '120px',
        backgroundColor: theme.palette.tertiary.main,
        color: theme.palette.tertiary.contrastText
    },
    pillTinyCpty: {
      width: '120px',
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.primary.contrastText
    },
    pillLegal: {
        width: '200px',
        backgroundColor: theme.palette.tertiary.main,
        color: theme.palette.tertiary.contrastText
    },
    pillCpty: {
      width: '200px',
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.primary.contrastText
    },
    iconUser: {
        color: theme.palette.tertiary.contrastText,
        marginLeft: '8px',
        fontSize: '11px',
    }
  }));
  const classes = useStyles();

  let size = 'medium'

  if(props.type==='tiny'){
      size = 'small'
  }

  return (
    <Chip 
      size={size}
      label={
      <>
        {props.role !== undefined && props.role !== null && props.role.startsWith("ClientFor_") ? "Legal Client" : props.role}
        {props.readOnly ? <FontAwesomeIcon icon={faEye} className={classes.iconUser} /> : ''}
      </>}
      color={props.role === 'Admin' ? "secondary" : "primary"}
      className={
        props.type === 'tiny' && props.role === 'Legal' ? classes.pillTinyLegal : 
        props.type !== 'tiny' && props.role === 'Legal' ? classes.pillLegal :
        props.type === 'tiny' && props.role === 'Counterparty' ? classes.pillTinyCpty : 
        props.type !== 'tiny' && props.role === 'Counterparty' ? classes.pillCpty :
        props.type ==='tiny' ? classes.pillTiny : classes.pillStd}
      /*icon={<FontAwesomeIcon 
        icon={props.role === 'Admin' ? faUserTie :
              props.role === 'Legal' ? faUserTie : faUser}
        className={classes.iconUser} />}*/
    />
  )
};

export default ChipUserType