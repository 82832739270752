import React, { useEffect, useRef } from 'react';
import { randomString as generateRandomString, countryToFlag, getAcronym, getSmartFieldValue, smartfieldValueValidation,
         calculateExpiryDate } from '../../utils'
import { Avatar, Box, Button, Card, CardContent, Checkbox, CircularProgress, Container, IconButton, 
         FormControl, FormControlLabel, FormGroup, Grid, 
         List, ListItem, ListItemAvatar, ListItemText, MobileStepper,
         Step, Stepper, StepButton, StepContent, TextField, Typography, makeStyles } from '@material-ui/core';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { AgreementStructure, CardCpty, DialogImportDoc, GetStarted, SmartFieldEditable, ThumbAgr, UploadImg, InformationalTooltip } from '../../components';
import { EntityForm } from '../../components/forms';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faChevronLeft, faChevronRight, faProjectDiagram, faBuilding, faUser, faPlus, faUpload, faTimes, faExclamationTriangle, faFilePdf, faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faHubspot, faSalesforce } from '@fortawesome/free-brands-svg-icons';

import { prices } from '../../assets/static/prices';
import { countries } from '../../assets/static/countries';

import pdf from '../../assets/img/pdf.png';
import defaultLogo from '../../assets/img/defaultlogo.png';
import defaultUser from '../../assets/img/defaultuser.png';
import hubspot_icon from '../../assets/img/hubspot_icon.png';
import salesforce_icon from '../../assets/img/salesforce_icon.png';


const axios = require('axios');
const filter = createFilterOptions();

function NewRelationship(props) {

  const useStyles = makeStyles(theme => ({
    outerFlexGrid: {
      minHeight: '80vh',
      paddingTop: '80px',
      paddingBottom: '80px',
    },
    bold: {
      fontWeight: '700',
    },
    stepper: {
      backgroundColor: theme.palette.primary.contrastText
    },
    activeTemplateOption: {
      fontSize: '14px',
      //borderRadius: '20px',
      border: '0px',
      fontWeight: '600',
      padding: '5px 20px 5px 20px',
      //margin: '0px 10px 0px 10px', 
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    inactiveTemplateOption: {
      fontSize: '14px',
      border: '0px',
      fontWeight: '600',
      //borderRadius: '20px',
      padding: '5px 20px 5px 20px',
      //margin: '0px 10px 0px 10px', 
      backgroundColor: '#f3f3f3',
      color: theme.palette.grey[600],
      '&:hover': {
        color: theme.palette.grey[900],
        backgroundColor: '#dddddd',
        cursor: 'pointer',
      },
    },
    cardSmartField: {
      width: '320px', 
      margin: '10px 0px 10px 0px',
      padding: '10px 20px 0px 20px',
    },
    exButton: {
      color: theme.palette.grey[500],
      fontWeight: '600',
      fontSize: '15px',
      padding: '4px 25px 4px 25px',
    },
    plusIcon: {
      color: theme.palette.grey[500],
      paddingRight: '15px',
      fontSize: '12px',
    },
    warnIcon: {
      marginRight: '10px',
      color: theme.palette.primary.main,
    },
    sameEntity: {
      color: theme.palette.grey[900],
      fontWeight: '700',
    },
    diffEntity: {
      color: theme.palette.grey[600],
      fontWeight: '400',
    },
    input: {
      display: 'none',
    },
    agrShortName: {
      marginTop: '5px',
      color: theme.palette.grey[900],
      fontWeight: '700',
    },
    agrFullName: {
        color: theme.palette.grey[800],
        fontSize: '13px',
    },
    uploadIcon: {
      color: theme.palette.primary.main,
      fontSize: '16px',
      marginTop: '15px',
    },
    uploadLink: {
      color: theme.palette.primary.main,
      fontSize: '15px',
      fontWeight: '700',
      '&:hover': {
        color: theme.palette.secondary.main,
        cursor: 'pointer',
      },
    },
    networkIcon: {
      color: theme.palette.primary.main,
      fontSize: '11px',
      padding: '0px 15px 0px 20px'
    },
    cptyIcon: {
      color: theme.palette.secondary.main,
      fontSize: '11px',
      padding: '0px 15px 0px 20px'
    },
    cptySpan: {
      fontSize: '12px',
      color: theme.palette.secondary.main,
    },
    networkSpan: {
      fontSize: '12px',
      color: theme.palette.primary.main,
    },
    exitIconB: {
      position: 'fixed',
      left: 'auto',
      backgroundColor: theme.palette.primary.contrastText,
      [theme.breakpoints.down('xs')]: { top: '4px', right: '15px', },
      [theme.breakpoints.up('sm')]: { top: '7px', right: '30px', },
    },
    exitIcon: {
      fontSize: '26px',
      padding: '7px 11px 7px 11px',
    },
    boxResizing: {
      [theme.breakpoints.only('xs')]: { width: '320px' },
      [theme.breakpoints.only('sm')]: { width: '520px' },
      [theme.breakpoints.only('md')]: { width: '700px' },
      [theme.breakpoints.up('lg')]: { width: '700px' }, 
      overflowX: 'auto',
      scrollBehavior: 'smooth',
    },
    boxResizing2: {
      [theme.breakpoints.only('xs')]: { width: '320px' },
      [theme.breakpoints.only('sm')]: { width: '520px' },
      [theme.breakpoints.only('md')]: { width: '520px' },
      [theme.breakpoints.up('lg')]: { width: '520px' },
    },
    chevIconB: {
      padding: '10px 15px 10px 15px',
    },
    cancelButton: {
      marginRight: '10px',
      backgroundColor: '#f3f3f3'
    },
    pl1: {
      paddingLeft: '10px'
    },
    sfErrorIcon: {
      fontSize: '12px',
      color: theme.palette.error.main,
      marginLeft: '15px',
    },
    sfWarningIcon: {
      fontSize: '12px',
      color: theme.palette.grey[600],
      marginLeft: '15px',
    },
    uploadPDF: {
      fontSize: '54px',
      color: theme.palette.grey[700],
      padding: '10px 18px 10px 18px',
    },
    pdfIcon: {
      display: 'block',
      width: '30px',
      margin: '30px 0px 10px 0px',
    },
    integrationImg: {
      cursor: 'pointer'
    }
  }));
  const classes = useStyles();

  const history = useHistory()
  const ref = useRef(null);
  const scroll = (scrollOffset) => { ref.current.scrollLeft += scrollOffset; };

  const [activeStep, setActiveStep] = React.useState(0);
  const [activeSFStep, setActiveSFStep] = React.useState(0);
  const [completedSF, setCompletedSF] = React.useState({})
  const [activeTemplateOption, setActiveTemplateOption] = React.useState({main: 'company', exhibit: 'company'});
  const [activeRel, setActiveRel] = React.useState(null)
  const [agrSelected, setAgrSelected] = React.useState(null)
  const [agrsPush, setAgrsPush] = React.useState([]);
  const [activeCptyOrg, setActiveCptyOrg] = React.useState(null);
  const [activeCptyEnt, setActiveCptyEnt] = React.useState(null);
  const [activePerson, setActivePerson] = React.useState(null);
  const [activeOwnEnt, setActiveOwnEnt] = React.useState({ shortName: props.org.shortName, logoURL: props.org.logoURL })
  const [orgOptions, setOrgOptions] = React.useState([]);
  const [cptyEntOptions, setCptyEntOptions] = React.useState([]);
  const [logoEditing, setLogoEditing] = React.useState(false);
  const [addingExhibit, setAddingExhibit] = React.useState(false);
  const [importOpen, setImportOpen] = React.useState(null);
  const [iDoc, setIDoc] = React.useState(null)
  const [activeAgr, setActiveAgr] = React.useState(null); // For adding an exhibit to an agreement
  const [activeAgrs, setActiveAgrs] = React.useState(null);
  const [isCustomURL, setIsCustomURL] = React.useState(true);
  const [isWhiteLabeled, setIsWhiteLabeled] = React.useState(null)
  const [errMsg, setErrMsg] = React.useState(null)
  const [relationshipType, setRelationshipType] = React.useState('error')
  const [cptyType, setCptyType] = React.useState('counterparty')
  //const [exitPage, setExitPage] = React.useState(props.cporgid !== undefined && props.cporgid !== null && ['2', '1'].includes(props.cporgid.toString()) ? 'dashboard' : 'relationships');
  const [exitPage, setExitPage] = React.useState('dashboard');
  const [addEntityToReducerOnExit, setAddEntityToReducerOnExit] = React.useState(false)
  const [updateEntityOnExitType, setUpdateEntityOnExitType] = React.useState(null)
  const [orderedExhibits, setOrderedExhibits] = React.useState(null)
  const [submitting, setSubmitting] = React.useState(false)
  const [loading, setLoading] = React.useState(false);
  const [freezeAgrCreation, setFreezeAgrCreation] = React.useState(false);
  const [smartFields, setSmartFields] = React.useState([]);
  const [configuringSmartFields, setConfiguringSmartFields] = React.useState(false)
  const [potentialEntity, setPotentialEntity] = React.useState(null)
  const [attachment, setAttachment] = React.useState({title:''})
  const [integrationSearch, setIntegrationSearch] = React.useState({platform: null, query: ''})
  const [integrationSearchResults, setIntegrationSearchResults] = React.useState(null)

  useEffect(() => {
    const fetchCpentsByOrgID = props.fetchCpentsByOrgID
    let unmounted = false;

    if(props.network !== undefined && props.network.length > 0 && props.module !== undefined && 
       props.subs !== undefined && props.subs.length > 0 && props.org !== undefined && props.org !== null) {
      let oos = []
      let ents = (props.module === 'sales' ? props.cpents.filter((c) => c.isLive && c.entityType === 'Customer') : 
                  props.module === 'procurement' ? props.cpents.filter((c) => c.isLive && c.entityType === 'Supplier') : 
                  props.module === 'partnerships' ? props.cpents.filter((c) => c.isLive && c.entityType === 'Partner') : 
                  props.module === 'people' ? props.cpents.filter((c) => c.isLive && c.entityType === 'Employee') :
                  props.module === 'corporate' ? props.cpents.filter((c) => c.isLive && c.entityType === 'Corpcp') : []);

      ents.forEach((e) => { // Add Org from internal master data
        if(!oos.some((o) => o._id === e.parentOrgID) && e.isLive) { // Not already added due to having multiple affiliates
          oos.push({shortName: e.shortName, _id: e.parentOrgID, logoURL: e.logoURL, isNetwork: false})
        }
      })

      if(['sales', 'procurement', 'partnerships'].includes(props.module)) { // Only include network orgs for B2B & partnerships
        props.network.filter((n) => n._id !== props.org._id).forEach((n) => { // Add Org from the Network
          if(!oos.some((o) => o.shortName === n.shortName)) { // Not already having an org with the same name
            oos.push({...n, isNetwork: true});
          }
        })
      }

      if (!unmounted) {
        setOrgOptions(oos.sort((a,b) => (a.shortName > b.shortName) ? 1 : ((b.shortName > a.shortName) ? -1 : 0)))
        setRelationshipType(['sales', 'procurement'].includes(props.module) ? 'b2b' : 
                            props.module === 'partnerships' ? 'partnership' : 
                            props.module === 'people' ? 'people' : 
                            props.module === 'corporate' ? 'corporate' : 'error')
        setCptyType(props.module === 'sales' ? 'customer' : 
                    props.module === 'procurement' ? 'supplier' : 
                    props.module === 'partnerships' ? 'partner' : 
                    props.module === 'people' ? 'employee' : 
                    props.module === 'corporate' ? 'corpcp' : 
                    'counterparty')
        if(props.subs.filter((s) => s.isLive).length === 1) { setActiveOwnEnt(props.subs.filter((s) => s.isLive)[0]) }
      }

      if(props.aid === undefined && ['custS', 'custB', 'cust'].includes(props.org.clientType)) {
        // need to retrieve # agrs created this month
        let maxAgrs = prices.filter((p) => p.clientType === props.org.clientType)[0].agrsPerMonth;
        let subs = [], usrs = []
        props.subs.forEach((s) => { subs.push(s._id) })
        props.users.forEach((u) => { usrs.push(u._id) }) 
        axios.post('/agr/agrqty', {
          subs: subs,
          users: usrs,
        })
        .then((res) => {
          if (!unmounted) { setFreezeAgrCreation(res.data.data !== undefined && res.data.data >= maxAgrs) }
        }).catch((err) => console.log(err))
      }

      if(props.aid === undefined && 
        props.cporgid !== undefined && props.cporgid !== null && props.cporgid !== '' && props.cporgid.length > 2 && oos.filter((o) => o._id === props.cporgid)[0] !== undefined) {

        if (!unmounted) {
          setExitPageBasedOnOIDAID(props.cporgid, null)
          handleCptyOrgChange(oos.filter((o) => o._id === props.cporgid)[0])
        }

      } else if (props.aid !== undefined && props.aid !== null && props.aid !== '') {

        axios.get('/agr/agr/' + props.aid) // Need to get the relevant Agreement
        .then(function(resag) {
          let agr = resag.data.data; // ASSIGN agr

          axios.get('/entity/protect/entity/' + props.cporgid) // cporgid === actually an entity id if you're having an props.aid
          .then(function(resent) {

            let cpent = resent.data.data
            let cporg = oos.filter((o) => (cpent.entityType === 'Subsidiary' && o._id === cpent.orgID) || (cpent.entityType !== 'Subsidiary' && o._id === cpent.parentOrgID))[0]

            if(cporg !== undefined && !unmounted) {
              setExitPageBasedOnOIDAID(cporg._id, props.aid)
              setActiveCptyEnt(cpent)
              setActiveOwnEnt(props.subs.filter((s) => s._id === agr.primaryCptyID || s._id === agr.secondaryCptyID)[0])
              setActiveCptyOrg(cporg)
              setActiveAgr(agr)
              setActiveStep(2)
              setAddingExhibit(true)
            }

          }).catch(function(err) { console.log("err fetching cp ent for exhibit creation", err) })

          // USE EFFECT Section to retrieve any exhibit/parent agrs
          axios.get('/agr/agrtied/' + props.aid) //  + '/' + (agr.parentAID !== undefined && agr.parentAID !== null && agr.parentAID !== '' ? agr.parentAID : 'na')
          .then(function(res) { if (!unmounted) { setActiveAgrs(res.data.data) } })
          .catch(function(err) { console.log("err fetching sibblings") })

          // Need to select any subagrs (if exist) so that they can be shown in the exhibit list &  you know max prio
        }).catch(function(err) { console.log("err fetching aid for exhibit creation") }) 
      } else if (props.cporgid !== undefined && props.cporgid !== null && props.cporgid.toString() === '2') {
        handleCptyOrgChange({ shortName: '', toBeCreated: true });
      }

    }

    return () => { // unmounting
      unmounted = true 
      if(history.action === 'POP' && !history.location.pathname.startsWith('/core/' + props.module + '/new')) {
        fetchCpentsByOrgID(props.user.orgID)
      }
    };
    
  }, [props.module, props.network, props.subs, props.org._id, props.users, props.user.orgID, props.cporgid, props.aid, props.org, props.cpents,
      history.action, history.location.pathname, props.fetchCpentsByOrgID])

  const getAutoValue = (autoType) => {
    let s = '(unknown value)';

    if(autoType === 'org-shortName') {
      s = activeOwnEnt.shortName;
    } else if(autoType === 'cpty-shortName') {
      s = activeCptyEnt.shortName;
    } else if(autoType === 'org-legalName') {
      s = activeOwnEnt.legalName;
    } else if(autoType === 'cpty-legalName') {
      s = activeCptyEnt.legalName;
    } else if(autoType === 'org-address') {
      s = (activeOwnEnt.address[0].streetAndNo + ", " + 
        (activeOwnEnt.address[0].address2 !== '' ? (activeOwnEnt.address[0].address2 + ", ") : '') +
        getAutoValue('org-city') + ' ' + activeOwnEnt.address[0].zip +
        (activeOwnEnt.address[0].state !== undefined && activeOwnEnt.address[0].state !== null && activeOwnEnt.address[0].state !== '' ?
          (' ' + activeOwnEnt.address[0].state) : '') 
        + ", " +
        getAutoValue('org-country'))
    } else if(autoType === 'cpty-address') {
      s = (activeCptyEnt.address[0].streetAndNo + ", " + 
        (activeCptyEnt.address[0].address2 !== '' ? (activeCptyEnt.address[0].address2 + ", ") : '') +
        getAutoValue('cpty-city') + ' ' + activeCptyEnt.address[0].zip +
        (activeCptyEnt.address[0].state !== undefined && activeCptyEnt.address[0].state !== null && activeCptyEnt.address[0].state !== '' ?
          (' ' + activeCptyEnt.address[0].state) : '') 
        + ", " +
        getAutoValue('cpty-country'))
    } else if(autoType === 'org-city') {
      s = activeOwnEnt.address[0].city;
    } else if(autoType === 'cpty-city') {
      s = activeCptyEnt.address[0].city;
    } else if(autoType === 'org-country') {
      s = (countries.filter((c) => c.code === activeOwnEnt.address[0].country)[0] !== undefined ? countries.filter((c) => c.code === activeOwnEnt.address[0].country)[0].label : activeOwnEnt.address[0].country)
    } else if(autoType === 'cpty-country') {
      s = (countries.filter((c) => c.code === activeCptyEnt.address[0].country)[0] !== undefined ? countries.filter((c) => c.code === activeCptyEnt.address[0].country)[0].label : activeCptyEnt.address[0].country)
    }  

    return s

  }

  const handleExit = () => {
    if(activeCptyEnt !== null && activeCptyEnt !== undefined) {
      let activeEnt = {...activeCptyEnt, toBeEdited: false }
      if(addEntityToReducerOnExit) { 
        props.addEntityToReducer(cptyType.charAt(0).toUpperCase() + cptyType.slice(1), activeEnt) // first param is entityType (ie. cptyType Capitalized first letter)
      }
      if(updateEntityOnExitType === 'logos') {
        if (activeCptyOrg.logoEditing) {
          props.updateLogoForOrg(activeCptyOrg._id, activeCptyOrg.logoURL);
          cptyEntOptions.forEach((cpe) => {
            props.updateEntity(
              cpe._id, // entid, 
              cpe.orgID, // orgid, 
              cpe.parentOrgID, // parentOrgID, 
              cpe.entityType, // entityType, 
              cpe._id === activeEnt._id ? activeEnt.shortName : cpe.shortName,
              cpe._id === activeEnt._id ? activeEnt.legalName : cpe.legalName, // legalName, 
              cpe._id === activeEnt._id ? activeEnt.address : cpe.address, // address, 
              activeCptyOrg.logoURL, // logoURL, 
              cpe._id === activeEnt._id ? activeEnt.primaryContact : cpe.primaryContact, // primaryContact, 
              cpe._id === activeEnt._id ? activeEnt.signatory : cpe.signatory, // empty signatory 
              cpe.isLive, // isLive, 
              props.user._id, // lastUpdateBy
            )
          })
        }
  
      } else if(updateEntityOnExitType === 'updateCpEnt') {
        //todo: scenario where a counterparty = person and is being updated/edited through the "NEW" flow (e.g. name), need to upate the user record
        props.updateEntity(
          activeEnt._id, // entid, 
          activeEnt.orgID, // orgid, 
          activeEnt.parentOrgID, // parentOrgID, 
          activeEnt.entityType, // entityType, 
          activeEnt.shortName, // shortName
          activeEnt.legalName, // legalName, 
          activeEnt.address, // address, 
          activeEnt.logoURL, // logoURL, 
          activeEnt.primaryContact, // primaryContact, 
          activeEnt.signatory, // empty signatory 
          true, // isLive, 
          props.user._id, // lastUpdateBy
        )
      }
    }
    setActiveCptyEnt(null)
    setActiveCptyOrg(null)
    setActivePerson(null)
    setCptyEntOptions([])
    setErrMsg(null)
    history.push('/core/' + props.module + '/' + exitPage)
  }

  const handleNext = () => {
    setLogoEditing(false);
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSubmitting(false)
    setLoading(false)
    setErrMsg(null)
  };

  const handleBack = () => {

    setErrMsg(null)
    setLoading(false)
    setSubmitting(false)

    // If you just created an Org - and go back from the BaseAgrSelect (activeStep 1) => Add the entity to the reducer, to reload the flow
    if(activeCptyOrg !== null && activeCptyOrg.wasCreated && activeStep === 1) { props.addEntityToReducer(cptyType.charAt(0).toUpperCase() + cptyType.slice(1), activeCptyEnt) }
    else if(activeCptyOrg !== null && activeCptyOrg.toBeCreated) { setActiveCptyOrg(null) }
    else { setActiveStep(prevActiveStep => prevActiveStep - 1); }
  };

  const handleAgr = (event, ag) => {
    if(!addingExhibit) {
      setAgrSelected(ag);
    }
  };

  const setExitPageBasedOnOIDAID = (orgid, aid) => {
    let r = props.rels.filter((r) => (
      (['sales'].includes(props.module) && r.secondaryOrgID === orgid && r.relationshipType === 'b2b') ||
      (['procurement'].includes(props.module) && r.primaryOrgID === orgid && r.relationshipType === 'b2b') ||
      (['people'].includes(props.module) && r.secondaryOrgID === orgid && r.relationshipType === 'people') ||
      (['partnerships'].includes(props.module) && (r.primaryOrgID === orgid || r.secondaryOrgID === orgid) && r.relationshipType === 'partnership') ||
      (['corporate'].includes(props.module) && (r.primaryOrgID === orgid || r.secondaryOrgID === orgid) && r.relationshipType === 'corporate')
    ))[0]

    if(r !== undefined && aid !== null) { setExitPage('agreement/' + r._id + '/' + aid) }
    else if(r !== undefined) { setExitPage('relationship/' + r._id + '/overview') }
    else { setExitPage('dashboard') }
  }

  const handleCptyOrgChange = (value) => {

    let eos = []
    if(value !== null && !value.isNetwork && value._id !== undefined) {
      eos = (props.module === 'sales' ? props.cpents.filter((c) => c.isLive && c.entityType === 'Customer') : 
            props.module === 'procurement' ? props.cpents.filter((c) => c.isLive && c.entityType === 'Supplier') : 
            props.module === 'partnerships' ? props.cpents.filter((c) => c.isLive && c.entityType === 'Partner') : 
            props.module === 'people' ? props.cpents.filter((c) => c.isLive && c.entityType === 'Employee') :
            props.module === 'corporate' ? props.cpents.filter((c) => c.isLive && c.entityType === 'Corpcp') : [])
            .filter((e) => e.parentOrgID === value._id)
      setCptyEntOptions(eos)

    } else if (value !== null && value.isNetwork && value._id !== undefined) { // Get entities for a Network Org
      axios.get('/entity/subsidiaries/' + value._id)
      .then(function(res) {
        setCptyEntOptions(res.data.data.filter((e) => e.isLive))
        if(res.data.data.filter((e) => e.isLive).length === 1) { handleCptyEntChange(res.data.data.filter((e) => e.isLive)[0]) }
      })
      .catch(function(err) { console.log("err fetching ents for network org") })
    
    } else if (value === null) {
      setActiveCptyEnt(null)
      setActiveOwnEnt({ shortName: props.org.shortName, logoURL: props.org.logoURL })
      setCptyEntOptions([])
    }
    if(eos.length === 1) { 
      handleCptyEntChange(eos[0]); 
      // If the only entity is a person - then also pull the person(user) detail
      if(eos[0].entityCategory === 'Person'){ 
        axios.get('/org/org/' + value._id) // Need to pull org by ID to get the matching email of the person
        .then(function(resorg) {
          axios.get('/user/protect/user/mail/' + resorg.data.data.email) // Get matching user
          .then(function(resusr) {
            setActivePerson(resusr.data.data)
          })
          .catch(function(err) { console.log("err fetching user")})
        })
        .catch(function(err) { console.log("err fetching org")})
      }
    } else { // if there are multiple options - reset
      setActiveCptyEnt(null)
    }
    if(value !== undefined && value !== null && value.toBeCreated) { setActiveCptyEnt(null) } // if creating a new ORG, reset the ENT (if applicable)
    setActiveCptyOrg(value)
    setErrMsg(null)
  }

  const handleCptyEntChange = (value) => {
    if(value !== undefined && value !== null && value.address !== undefined) {
      let valCountry = value.address.filter((a) => a.addressType === 'billto')[0] !== undefined ? value.address.filter((a) => a.addressType === 'billto')[0].country : null
      // Automatically select your own entity (where possible)
      if(props.org !== undefined && props.org.clientType === 'legalOrg' && // If a Legal Org, then always select the existing relationship (if available)
      props.rels.some((r) => [r.primaryCptyID, r.secondaryCptyID].includes(value._id))) {
        let r = props.rels.filter((r) => [r.primaryCptyID, r.secondaryCptyID].includes(value._id))[0]
        if(r !== undefined) { setActiveOwnEnt(props.subs.filter((s) => [r.primaryCptyID, r.secondaryCptyID].includes(s._id))[0]) }
      } else if (props.org !== undefined && props.org.clientType !== 'legalOrg' && (activeOwnEnt === null || activeOwnEnt._id === undefined) && props.subs.filter((s) => s.isLive).length === 1) { // If only one active entity
        setActiveOwnEnt(props.subs.filter((s) => s.isLive)[0]);
      } else if(props.org !== undefined && props.org.clientType !== 'legalOrg' && 
        (activeOwnEnt === null || activeOwnEnt._id === undefined) && value.address !== undefined && valCountry !== undefined && valCountry !== null &&
        props.subs.filter((s) => s.isLive && s.address !== undefined && s.address.filter((a) => a.addressType === 'billto')[0] !== undefined && 
        s.address.filter((a) => a.addressType === 'billto')[0].country === valCountry)[0] !== undefined)
      { // If no own cpty yet, automatically select a corresponding one from the same country (if exists)
        setActiveOwnEnt(props.subs.filter((s) => s.address !== undefined && s.address.filter((a) => a.addressType === 'billto')[0] !== undefined && 
          s.address.filter((a) => a.addressType === 'billto')[0].country === valCountry)[0]);
      }
    }
    setActiveCptyEnt(value)
  }

  const callbackEntityForm = (child) => {
    setSubmitting(true)
    setErrMsg(null)

    let org = 
      child.parentOrg !== undefined && child.parentOrg !== null ? child.parentOrg :
      activeCptyOrg !== undefined && activeCptyOrg !== null && activeCptyOrg.logoURL !== undefined && !activeCptyOrg.toBeCreated ? 
          activeCptyOrg :
      activeCptyOrg !== undefined && activeCptyOrg !== null && activeCptyOrg.toBeCreated && 
      orgOptions.filter((oo) => !oo.isNetwork && oo.shortName.toLowerCase() === child.shortName.trim().toLowerCase())[0] !== undefined ? 
          orgOptions.filter((oo) => !oo.isNetwork && oo.shortName.toLowerCase() === child.shortName.trim().toLowerCase())[0] : null

    let creationDate = new Date().toISOString();
    let addr =  [{
      streetAndNo: child.streetAndNo !== undefined && child.streetAndNo !== null ? child.streetAndNo.trim() : '',
      address2: child.address2 !== undefined && child.address2 !== null ? child.address2.trim() : '',
      zip: child.zip !== undefined && child.zip !== null ? child.zip.trim() : '',
      city: child.city !== undefined && child.city !== null ? child.city.trim() : '',
      country: child.country,
      state: child.state !== undefined && child.state !== null ? child.state.trim() : '',
      addressType: 'billto',
    }]
    let parentHasLogo = org !== null && org.logoURL !== undefined && org.logoURL !== '';
    let displayName = activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.entityCategory === 'Person' ? child.firstName.trim() + ' ' + child.lastName.trim() : ''

    // Entity EDITING: Update the activeEnt and in the "cptyEntOptions" array
    if(child !== undefined && child !== null && (props.network.filter((n) => n.shortName.trim() === child.shortName.trim())[0] !== undefined || 
    props.network.filter((n) => n.legalName.trim() === child.legalName.trim())[0] !== undefined)){
      // This is not entirely complete, as network orgs will only have the legalname on an org level and not necessarily all underlying entities
      setErrMsg('This company already exists in the Canveo network, go back and select that one.')
      setSubmitting(false)
      setLoading(false)

    } else if(activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.toBeEdited){
      // Update the Active Entity detail
      setActiveCptyEnt({ ...activeCptyEnt,
        logoURL: parentHasLogo ? activeCptyOrg.logoURL : activeCptyEnt.entityCategory === 'Person' ? defaultUser : defaultLogo,
        entityCategory: child.entityCategory,
        shortName: activeCptyEnt.entityCategory === 'Person' ? displayName : child.shortName.trim(), 
        legalName: activeCptyEnt.entityCategory === 'Person' ? displayName : child.legalName.trim(), 
        address: addr
      })

      let cpos = cptyEntOptions
      let index = cpos.findIndex((c) => c._id === activeCptyEnt._id)
      cpos[index] = {...cpos[index], 
        shortName: activeCptyEnt.entityCategory === 'Person' ? displayName : child.shortName.trim(), 
        legalName: activeCptyEnt.entityCategory === 'Person' ? displayName : child.legalName.trim(), 
        country: child.country,
        address: addr }

      if(activeCptyEnt.entityCategory === 'Person') {
        setActivePerson({ ...activePerson, title: child.title.trim() })
      }

      setUpdateEntityOnExitType('updateCpEnt')
      setCptyEntOptions(cpos);
      setLogoEditing(true)

    } else if (org === null) { // You're creating a new org w/ entity - create, then attach to activeOrg, activeEnt and entOptions
      let displayName = child.entityCategory === 'Person' ? child.firstName.trim() + ' ' + child.lastName.trim() : ''
      if(props.cpents.filter((c) => c.orgID === props.org._id && c.entityType === (cptyType.charAt(0).toUpperCase() + cptyType.slice(1)) &&
        ((child.entityCategory === 'Person' && c.legalName.trim().toLowerCase() === displayName.toLowerCase()) ||
         (child.entityCategory !== 'Person' && c.legalName.trim().toLowerCase() === child.legalName.trim().toLowerCase())))[0] === undefined){
          axios.post('/org/org', { // Create a cpty/shadow org first
            shortName: child.entityCategory === 'Person' ? displayName : child.shortName.trim(),
            legalName: child.entityCategory === 'Person' ? displayName : child.legalName.trim(),
            orgurl: '',
            logoURL: child.entityCategory === 'Person' ? defaultUser : defaultLogo,
            clientType: 'cpty',
            creationDate: creationDate,
            email: child.entityCategory === 'Person' ? child.email.toLowerCase().trim() : '',
          })
          .then(function (resorg) { // success
            axios.post('/entity/protect/entity', {
              orgID: props.org._id, 
              parentOrgID: resorg.data.data._id,
              entityType: cptyType.charAt(0).toUpperCase() + cptyType.slice(1),
              entityCategory: child.entityCategory,
              shortName: child.entityCategory === 'Person' ? displayName : child.shortName.trim(), 
              legalName: child.entityCategory === 'Person' ? displayName : child.legalName.trim(), 
              address: addr, 
              logoURL: child.entityCategory === 'Person' ? defaultUser : defaultLogo,
              primaryContact: {},
              signatory: {},
              isLive: true,
              sourceData: child.sourceData !== undefined && child.sourceData !== null && 
                child.sourceData.source !== undefined && child.sourceData.sourceID !== undefined ? child.sourceData : null,
              creationBy: props.user._id,
              creationDate: creationDate,
              lastUpdateBy: props.user._id,
              lastUpdateDate: creationDate,
            })
            .then(function (resent) { // got a msg from the server
    
              if(child.entityCategory === 'Person') { // This is a Person
    
                axios.post('/user/protect/user', {
                  orgID: resorg.data.data._id,
                  firstName: child.firstName.trim(),
                  lastName: child.lastName.trim(),
                  displayName: displayName.trim(),
                  email: child.email.toLowerCase().trim(),
                  password: generateRandomString(20),
                  title: child.title.trim(),
                  role: 'Counterparty',
                  defaultModule: 'counterparty',
                  photoURL: defaultUser,
                  creationBy: props.user._id,
                  creationDate: new Date().toISOString(),
                  active: true,
                })
                .then(function (resuser) { // got a msg from the server
    
                    props.createNotification(
                        'Welcome new User', // notificationType
                        'Welcome to Canveo', // title 
                        child.firstName.trim() + ', we\'re excited to have you on board', // description
                        '/core/help/welcome', // linkTo
                        false, // isRead
                        resuser.data.data._id, // uid
                    );
                    setActivePerson({...resuser.data.data, wasCreated: true})
                    setSubmitting(false)
                    setLoading(false)
    
                }).catch(function (err) { setErrMsg("Unable to create the user, it may already exist") })
    
              }
    
              let oos = orgOptions;
              oos.push({shortName: child.entityCategory === 'Person' ? displayName : child.shortName.trim(), _id: resorg.data.data._id, logoURL: child.entityCategory === 'Person' ? defaultUser : defaultLogo, isNetwork: false})
              setOrgOptions(oos.sort((a,b) => (a.shortName > b.shortName) ? 1 : ((b.shortName > a.shortName) ? -1 : 0)))
              setActiveCptyOrg({...resorg.data.data, wasCreated: true})
              setActiveCptyEnt(resent.data.data);
              setCptyEntOptions([resent.data.data]); // Add newly created to Entity Options
              if(props.subs.length === 1) { setActiveOwnEnt(props.subs[0]) }
              setAddEntityToReducerOnExit(true)
              setLogoEditing(true);
              setSubmitting(false)
              setLoading(false)
              
            }).catch(function(err) { setErrMsg("Unable to create the entity, it may already exist, refresh your page and retry") })
          }).catch(function(err) { setErrMsg("Unable to create the company, it may already exist, refresh your page and retry") })

      } else {
        // NOTE: Special handling on this particular errMsg - utilizing PotentialEntity
        setErrMsg("Unable to create the entity, it already exists")
        setPotentialEntity(props.cpents.filter((c) => c.orgID === props.org._id && c.entityType === (cptyType.charAt(0).toUpperCase() + cptyType.slice(1)) &&
          ((child.entityCategory === 'Person' && c.legalName.trim().toLowerCase() === displayName.toLowerCase()) ||
          (child.entityCategory !== 'Person' && c.legalName.trim().toLowerCase() === child.legalName.trim().toLowerCase())))[0])
        setSubmitting(false)
        setLoading(false)
      }

    } else if((activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.toBeCreated) ||
      org !== null) { // You're creating a new entity - so attach it to the "cptyEntOptions" array
      // Check (same as above) whether the entity alredy exists and may be inactive
      let pEnt = props.cpents.filter((c) => c.orgID === props.org._id && c.entityType === (cptyType.charAt(0).toUpperCase() + cptyType.slice(1)) &&
        ((child.entityCategory === 'Person' && c.legalName.trim().toLowerCase() === displayName.toLowerCase()) ||
        (child.entityCategory !== 'Person' && c.legalName.trim().toLowerCase() === child.legalName.trim().toLowerCase())))[0]
      if(pEnt !== undefined){

        setErrMsg("Unable to create the entity, it already exists")
        setPotentialEntity(pEnt)
        setSubmitting(false)
        setLoading(false)

      } else {
        
        axios.post('/entity/protect/entity', {
          orgID: props.org._id, 
          parentOrgID: org._id,
          entityType: cptyType.charAt(0).toUpperCase() + cptyType.slice(1),
          entityCategory: child.entityCategory,
          shortName: org.shortName, 
          legalName: child.legalName.trim(), 
          address: addr, 
          logoURL: parentHasLogo ? org.logoURL : defaultLogo,
          primaryContact: {},
          signatory: {},
          isLive: true,
          sourceData: child.sourceData !== undefined && child.sourceData !== null && 
            child.sourceData.source !== undefined && child.sourceData.sourceID !== undefined ? child.sourceData : null,
          creationBy: props.user._id,
          creationDate: creationDate,
          lastUpdateBy: props.user._id,
          lastUpdateDate: creationDate,
        })
        .then(function (res) { // got a msg from the server
          setActiveCptyOrg(org)
          setActiveCptyEnt(res.data.data);
          if(props.subs.length === 1) { setActiveOwnEnt(props.subs[0]) }
          setCptyEntOptions(cpo => [...cpo, res.data.data]); // Add newly created to Entity Options
          setAddEntityToReducerOnExit(true)
          handleNext();

        }).catch(function(err) { setErrMsg("Unable to create the new entity, it may already exist") })

      }

    }
  }

  const retryEntityForm = () => {
    setSubmitting(false)
    setErrMsg(null)
  }

  const callbackLogoUploadForm = (child) => {
    let cpes = cptyEntOptions;
    cpes.forEach((cpe) => {
      let index = cpes.findIndex((c) => c._id === cpe._id)
      cpes[index] = {...cpes[index], logoURL: child}
    })
    setCptyEntOptions(cpes)
    setActiveCptyEnt({...activeCptyEnt, logoURL: child})
    setActiveCptyOrg({...activeCptyOrg, logoEditing: true, logoURL: child})
    if(activeCptyEnt.entityCategory === 'Person') { setActivePerson({...activePerson, photoEditing: true, photoURL: child}) }
    setUpdateEntityOnExitType('logos')
    handleNext();
  }

  const handleCardCptyRequest = (action, counterpart, value) => {
    if(action === 'newent') {
      let isCpty = (['sales', 'partnerships', 'people', 'corporate'].includes(props.module) && counterpart === 'secondary') || 
                   (['procurement'].includes(props.module) && counterpart === 'primary')
      if (!isCpty && props.subs.filter((s) => s._id === value)[0] !== undefined) {
        setActiveOwnEnt(props.subs.filter((s) => s._id === value)[0])
      } else if (isCpty && cptyEntOptions.filter((e) => e._id === value)[0] !== undefined) {
        setActiveCptyEnt(cptyEntOptions.filter((e) => e._id === value)[0])
      } 
    }
  }

  const setEditing = (entid) => {
    setActiveCptyEnt({ ...activeCptyEnt, toBeEdited: true })
    setActiveStep(0);
  }

  const handleExhibitRemove = (id) => {
    if(orderedExhibits !== null) { setOrderedExhibits([...orderedExhibits.filter(oe => oe.agrComponentID !== id && oe.atid !== id)]) }
    let oatidToRemove = agrsPush.filter(ap => ap.agrComponentID === id || ap.atid === id)[0] !== undefined ? agrsPush.filter(ap => ap.agrComponentID === id || ap.atid === id)[0].oatid : '';
    setAgrsPush([...agrsPush.filter(ap => ap.agrComponentID !== id && ap.atid !== id)]);
    setSmartFields(smartFields.filter((sf) => sf.oatid !== oatidToRemove));
    //if(activeAgr !== null && orderedExhibits.filter((oe) => oe.isNewAfterTheFact).length === 0) { setAddingExhibit(true) }
  }

  const orderSmartFields = (sfs) => {
    // Exact ordering is complicated (need to pull entire agreement with clauses to compare against the sf's singleClauseID AND to find ordering within clause)
    let orderedSfs = [], sclidsCompleted = []
    // First insert the Effective date
    if(sfs.filter((sf) => sf.name === "Agreement Effective Date")[0] !== undefined) {
      orderedSfs.push(sfs.filter((sf) => sf.name === "Agreement Effective Date")[0])
    }
    // Current "proxy" ordering: first order by creationTimeStamp, then loop and regroup based on singleClauseID
    sfs
    .filter((sf) => sf.name !== "Agreement Effective Date")
    .sort((a,b) => (a.creationDate > b.creationDate) ? 1 : ((b.creationDate > a.creationDate) ? -1 : 0))
    .forEach((sf, i) => {
      if(!sclidsCompleted.some((sclid) => sclid === sf.singleClauseID)){
        orderedSfs = orderedSfs.concat(sfs.filter((s) => s.singleClauseID === sf.singleClauseID && s.name !== "Agreement Effective Date"))
        sclidsCompleted.push(sf.singleClauseID)
      }
    })
    return orderedSfs
  }

  const handleExhibitSelect = (oatid, importedDoc, isAttachment) => {
    
    let isImport = (importedDoc !== null)
    let oat = props.orgAgrTemplates.filter((oat) => oat._id === oatid)[0]
    let atid = 
      isAttachment ? props.agrTypes.filter((agrt) => (agrt.fullName.some((f) => f.toLowerCase() === "agreement exhibit")))[0]._id :
      isImport ? importedDoc.data.agrTypeID : oat.agrTypeID
    
    let newAP = { 
      oatid: isImport || isAttachment ? null : oatid,  
      oat: isImport || isAttachment ? null : oat,
      importedDoc: isImport ? importedDoc : null,
      attachment: isAttachment ? attachment : null,
      atid: atid,
      shortName: 
        isAttachment ? 
            getAcronym(attachment.title) : 
        !isImport && oat.agrShortName !== undefined && oat.agrShortName !== null && oat.agrShortName !== '' ? 
            oat.agrShortName : 
        props.agrTypes.filter((at) => at._id === atid)[0] !== undefined ? 
            props.agrTypes.filter((at) => at._id === atid)[0].shortName :
        isImport && importedDoc.data.atPushToReducer !== undefined && importedDoc.data.atPushToReducer[0] !== undefined && 
        importedDoc.data.atPushToReducer[0].shortName !== undefined ? 
            importedDoc.data.atPushToReducer[0].shortName : 
        isImport && importedDoc.data.agrTitle !== '' ? 
            getAcronym(importedDoc.data.agrTitle) : 'TBD', // create shortname abbreviation
      fullName: 
        isAttachment ? 
            attachment.title :
        !isImport && oat.agrFullName !== undefined && oat.agrFullName !== null && oat.agrFullName !== '' ? 
            oat.agrFullName : 
        isImport && importedDoc.data.agrTitle !== '' ? 
            importedDoc.data.agrTitle :
            props.agrTypes.filter((at) => at._id === atid)[0].fullName[0],
      isMain: false,
      isNew: true,
      isNewAfterTheFact: activeAgr !== null ? true : false,
      existing: [],
      priority: (agrsPush.length) * 10,
      createFrom: isAttachment ? 'attachment' : isImport ? 'upload' : 'template'
    }

    let apush = [newAP]

    if(isImport && importedDoc.data.exhibits !== undefined && importedDoc.data.exhibits.length > 0) {
      importedDoc.data.exhibits.forEach((iDocEx, i) => { // Also push all imported exhibits
        apush.push({ 
          oatid: null,  
          oat: null,
          importedDoc: { data: {
            clauses: iDocEx.clauses,
            numberType: importedDoc.data.numberType,
            exhibits: [],
          } },
          agrComponentID: iDocEx.agrComponentID, 
          atid: iDocEx.agrTypeID,
          shortName: props.agrTypes.filter((at) => at._id === iDocEx.agrTypeID)[0] !== undefined ?
            props.agrTypes.filter((at) => at._id === iDocEx.agrTypeID)[0].shortName : getAcronym(iDocEx.agrTitle),
          fullName: iDocEx.agrTitle,
          isMain: false,
          isNew: true,
          isNewAfterTheFact: activeAgr !== null ? true : false,
          existing: [],
          priority: (1 + i + (agrsPush !== null ? agrsPush.length : 0)) * 10,
          createFrom: 'upload'
        })
      })
    } 
    
    let newsfs = []
    axios.get('/sf/osfs/' + props.user.orgID + "/" + props.module) // PULL ALL ORG SMARTFIELDS FOR THAT MODULE
    .then(function(resosf) {
      let osfs = resosf.data.data;
      
      apush.forEach((ap, i) => { // For every selected MainAgr / Default Exhibit
        axios.get('/sf/sf/' + (ap.oatid === null ? 'none' : ap.oatid))
        
        .then(function(ressfs) {

          ressfs.data.data
          .filter((sf) => sf.ref !== 'deleted')
          .filter((sf) => sf.name !== 'Agreement Effective Date')
          .forEach((sf) => { // For every smartfield in the agreement
            let s = sf;
            let o = s.osfid !== undefined && s.osfid !== null && s.osfid !== '' && osfs.filter((osf) => osf._id === s.osfid)[0] !== undefined ? 
              osfs.filter((osf) => osf._id === s.osfid)[0] : null

            // Only include if NOT automatic smartfield AND NOT already included
            s.includeInWizard = s.type !== 'auto' && s.createWizard &&
              !newsfs.some((newsf) => sf.osfid !== undefined && sf.osfid !== null && newsf.osfid === sf.osfid && newsf.includeInWizard)
            s.question = o !== null ? o.question : ''
            s.guidance = o !== null ? o.guidance : ''
            s.val1 = o !== null ? o.defaultValue1 : s.val1
            s.val2 = o !== null ? o.defaultValue2 : s.val2
            newsfs.push(s) // Push the enriched smartfield into the array
          })

          if(i + 1 === apush.length) { // You're done with pulling smartfields for all applicable agrs
            
            setAgrsPush(agrsPush.concat(apush)) // push ap into agrsPush
            if(orderedExhibits !== null) { setOrderedExhibits(orderedExhibits.concat(apush)) }
            setSmartFields(smartFields.concat(newsfs));
            setActiveTemplateOption({...activeTemplateOption, exhibit: 'company'})
            setAttachment({title: ''})
            //setOrderedExhibits(oes)
            setAddingExhibit(false);
            setIDoc(null)
            setLoading(false);

          }
        }).catch((err) => console.log(err))
      })
    }).catch((err) => console.log(err))
  }

  const handleBaseAgrSelect = (oatid, importedDoc) => {

    let existingRel = props.rels.filter((r) => r.relationshipType === relationshipType &&
                       ((['sales', 'people'].includes(props.module) && r.primaryOrgID === props.org._id && r.secondaryOrgID === activeCptyOrg._id) ||
                        (['procurement'].includes(props.module) && r.primaryOrgID === activeCptyOrg._id && r.secondaryOrgID === props.org._id) ||
                        (['partnerships', 'corporate'].includes(props.module) && ((r.primaryOrgID === props.org._id && r.secondaryOrgID === activeCptyOrg._id) || (r.primaryOrgID === activeCptyOrg._id && r.secondaryOrgID === props.org._id)))
                       ))[0]
    
    if(oatid !== null || importedDoc !== null) { // User has changed something

      let isImport = (importedDoc !== null)
      let atid = isImport ? importedDoc.data.agrTypeID : props.orgAgrTemplates.filter((oat) => oat._id === oatid)[0].agrTypeID
      let oat = props.orgAgrTemplates.filter((oat) => oat._id === oatid)[0] 

      let newAP = {
        oatid: isImport ? null : oatid,
        oat: isImport ? null : oat,
        importedDoc: isImport ? importedDoc : null,
        atid: atid,
        shortName:
          props.agrTypes.filter((at) => at._id === atid)[0] !== undefined ? // Default to the short name of the agreement type
            props.agrTypes.filter((at) => at._id === atid)[0].shortName :
          !isImport && oat.agrShortName !== undefined && oat.agrShortName !== null && oat.agrShortName !== '' ? 
            oat.agrShortName :
          isImport && importedDoc.data.atPushToReducer !== undefined && importedDoc.data.atPushToReducer[0] !== undefined && 
          importedDoc.data.atPushToReducer[0].shortName !== undefined ? 
            importedDoc.data.atPushToReducer[0].shortName : 
          isImport && importedDoc.data.agrTitle !== '' ? getAcronym(importedDoc.data.agrTitle) : 'TBD', // create shortname abbreviation
        fullName: !isImport && oat.agrFullName !== undefined && oat.agrFullName !== null && oat.agrFullName !== '' ? 
            oat.agrFullName : 
          isImport && importedDoc.data.agrTitle !== '' ? 
            importedDoc.data.agrTitle :
          props.agrTypes.filter((at) => at._id === atid)[0] !== undefined ?
            props.agrTypes.filter((at) => at._id === atid)[0].fullName[0] : 'Contractual Agreement',
        isMain: true,
        existing: [],
        createFrom: isImport ? 'upload' : 'template'
      }

      let apush = [newAP];

      if(!isImport && oat.defaultChildren.length > 0) {
        oat.defaultChildren
        .filter((dc) => props.orgAgrTemplates.some((oat) => oat.active && oat.blueprintID === dc))
        .forEach((oatbp) => {
          let atidEX = props.orgAgrTemplates.filter((o) => o.blueprintID === oatbp)[0].agrTypeID
          let oatc = props.orgAgrTemplates.filter((o) => o.blueprintID === oatbp)[0]
          let newAPEX = { 
            oatid: oatc._id,
            oat: oatc,
            importedDoc: null,
            atid: atidEX,
            agrComponentID: generateRandomString(5),
            shortName: oatc.agrShortName !== undefined && oatc.agrShortName !== null && oatc.agrShortName !== '' ? 
              oatc.agrShortName : props.agrTypes.filter((at) => at._id === atidEX)[0].shortName,
            fullName: oatc.agrFullName !== undefined && oatc.agrFullName !== null && oatc.agrFullName !== '' ? 
              oatc.agrFullName :props.agrTypes.filter((at) => at._id === atidEX)[0].fullName[0],
            isMain: false,
            isNew: true,
            isNewAfterTheFact: activeAgr !== null ? true : false,
            existing: [],
            priority: (apush.length) * 10,
            createFrom: 'template'
          }
          apush.push(newAPEX);
        })
      } else if(isImport && importedDoc.data.exhibits !== undefined && importedDoc.data.exhibits.length > 0) {
        importedDoc.data.exhibits.forEach((iDocEx, i) => { // Also push all imported exhibits
          apush.push({ 
            oatid: null,  
            oat: null,
            importedDoc: { data: {
              clauses: iDocEx.clauses,
              numberType: importedDoc.data.numberType,
              exhibits: [],
            } },
            agrComponentID: iDocEx.agrComponentID, 
            atid: iDocEx.agrTypeID,
            shortName: props.agrTypes.filter((at) => at._id === iDocEx.agrTypeID)[0] !== undefined ?
              props.agrTypes.filter((at) => at._id === iDocEx.agrTypeID)[0].shortName : getAcronym(iDocEx.agrTitle),
            fullName: iDocEx.agrTitle,
            isMain: false,
            isNew: true,
            isNewAfterTheFact: false,
            existing: [],
            priority: (i + 1) * 10,
            createFrom: 'upload'
          })
        })
      }

      let newsfs = []
      axios.get('/sf/osfs/' + props.user.orgID + "/" + props.module) // PULL ALL ORG SMARTFIELDS FOR THAT MODULE
      .then(function(resosf) {
        let osfs = resosf.data.data;

        apush.forEach((ap, i) => { // For every selected MainAgr / Default Exhibit

          axios.get('/sf/sf/' + (ap.oatid === null ? 'none' : ap.oatid)) // Pull all smartfields
          .then(function(ressfs) {
            
            ressfs.data.data
            .filter((sf) => sf.ref !== 'deleted')
            .filter((sf) => sf.name !== 'Agreement Effective Date' || ap.isMain)
            .forEach((sf) => { // For every smartfield in the agreement
              let s = sf;
              let o = s.osfid !== undefined && s.osfid !== null && s.osfid !== '' && osfs.filter((osf) => osf._id === s.osfid)[0] !== undefined ? 
                osfs.filter((osf) => osf._id === s.osfid)[0] : null

              // Only include if NOT automatic smartfield AND NOT already included
              s.includeInWizard = s.type !== 'auto' && s.createWizard &&
                !newsfs.some((newsf) => sf.osfid !== undefined && sf.osfid !== null && newsf.osfid === sf.osfid && newsf.includeInWizard)
              s.question = o !== null ? o.question : ''
              s.guidance = o !== null ? o.guidance : ''
              s.val1 = o !== null ? o.defaultValue1 : s.val1
              s.val2 = o !== null ? o.defaultValue2 : s.val2
              
              // Push all smartfields - unless you're an Agreement Effective Date inside an Exhibit
              newsfs.push(s) // Push the enriched smartfield into the array
            })

            // Need to verify if a Wizard Question is required for the Effective Date
            if(ap.isMain && ap.oat !== null && ap.oat.effectiveDateType !== undefined && ap.oat.effectiveDateType === 'agrcreation'
            && !ressfs.data.data.some((sf) => sf.name === "Agreement Effective Date")){

              // The effective Date needs to be set upon creation of the agreement, and no smartfield exists
              let s = { _id: generateRandomString(10), name: "Agreement Effective Date" }
              let o = osfs.filter((osf) => osf.name === "Agreement Effective Date")[0] !== undefined ? 
                osfs.filter((osf) => osf.name === "Agreement Effective Date")[0] : null

              s.isArtificial = true; // this one will before submission removed at the end
              s.oatid = ap.oat._id;
              s.includeInWizard = true;
              s.question = o !== null ? o.question : ''
              s.guidance = o !== null ? o.guidance : ''
              s.type = 'date-stamp'
              s.val1 = ''
              s.val2 = ''
              newsfs.push(s)
            }

            if(i + 1 === apush.length) { // You're done with pulling smartfields for all applicable agrs

              if(existingRel !== undefined && existingRel._id !== undefined){ // There was already a relationship

                axios.get('/agr/agrs/' + existingRel._id)
                .then(function(res) { // Load any existing similar agreement Types

                  let idx = apush.findIndex((ap) => ap.isMain)
                  apush[idx] = {...apush[idx], existing: res.data.data.filter((agDB) => agDB.agrTypeID === atid && agDB.agrStatus !== 'Deactive') }

                  setSmartFields(newsfs);
                  setAgrsPush(apush)
                  setActiveRel(existingRel)
                  setIDoc(null)
                  handleNext()
                  setLoading(false);
                })
                .catch(function(err) { console.log("err", err) })
          
              } else { // This is a new relationship
                if(activeCptyEnt.entityType !== 'Subsidiary' && props.org !== undefined && props.org !== null &&
                  props.org.whiteLabel !== undefined && props.org.whiteLabel !== null && 
                  props.org.whiteLabel.color !== undefined && props.org.whiteLabel.contrastColor !== undefined &&
                  props.org.orgSettings !== undefined && ['email','full'].includes(props.org.orgSettings.whiteLabelLevel)) { setIsWhiteLabeled(true) } 
                else { setIsWhiteLabeled(null) }
                setSmartFields(newsfs);
                setAgrsPush(apush)
                setIDoc(null)
                handleNext()
                setLoading(false);
              }
            }
          }).catch((err) => console.log(err))
        })
      }).catch(function(err) { console.log(err) })

    } else {
      handleNext()
      setLoading(false);
    }

  }

  const handleTemplateSelect = (type, oatid) => {
    setLoading(true);
    handleAgr(type, oatid)
    if(type === 'agr') { handleBaseAgrSelect(oatid, null) }
    else if(type === 'exh') { handleExhibitSelect(oatid, null, false) } 
  }

  const handleImportClose = () => {
    setImportOpen(null);
  };

  const handleNewExhibitOrder = (exhibits) => {

    if(exhibits.some((e) => e.isNewAfterTheFact)) {
      setOrderedExhibits(exhibits)
    } else {
      let aps = agrsPush.filter((ap) => ap.isMain)
      exhibits.forEach((e, i) => { aps.push({...e, priority: ((i+10)*10)}) })
      setAgrsPush([...aps]);
    }
  }

  const handleFinalizeImport = (impDoc) => {
    setLoading(true);
    setIDoc(impDoc)
    handleImportClose();
    if(addingExhibit) {
      handleExhibitSelect(null, impDoc, false)
    } else {
      handleBaseAgrSelect(null, impDoc)
    }
  }

  const handleClearUpload = (docType) => {
    setIDoc(null)
    if(docType === 'main') {
      setAgrsPush([])
    }
  }

  const handleCreateExhibit = () => {

    let smartFieldPush = [] // smartFieldPush is the object to change / update all values inside the agreement
    smartFields.forEach((sf) => {
      smartFieldPush.push({ 
        oatid: sf.oatid, 
        singleClauseID: sf.singleClauseID, 
        ref: sf.ref, 
        newV: sf.type === 'auto' ? getAutoValue(sf.val1) : getSmartFieldValue(sf.type, sf.val1, sf.val2)
      })
    })

    let smartFieldObject = {
        sfs: smartFields,
        sfPush: smartFieldPush,
    }

    // Set latest AV for Agreement to NOT READY TO SIGN
    axios.get('/agr/agrvlatest/' + activeAgr._id)
    .then(function(resavs) {
      props.updateAgrvMarkAsEdited(resavs.data.data._id, true, false, false)

      let newOrderedEX = []
      if(orderedExhibits !== null) { newOrderedEX = orderedExhibits;
      } else {
        activeAgrs.filter((aa) => aa.agrStatus === 'Exhibit').forEach((aa) => { newOrderedEX.push(aa); })
        agrsPush.forEach((ap) => { newOrderedEX.push(ap) })
      }

      newOrderedEX.forEach((noe, i) => {
        let priority = 10 + (i*10);
        if(noe.isNewAfterTheFact) {
          
          props.createAgr(
            activeAgr.relID, // rid 
            noe.atid, // agrTypeID
            activeAgr.primaryCptyID, // primaryCptyID
            activeAgr.secondaryCptyID, // secondaryCptyID
            '', // tertiaryCptyID 
            [], // contributors
            [], // signatories
            'Exhibit', // agrStatus
            activeAgr.curAVOwner, // curAVOwner
            resavs.data.data.version, // avv
            noe.attachment !== undefined && noe.attachment !== null && noe.attachment.title !== undefined ?
                noe.attachment :
            noe.importedDoc !== null ? 
                noe.importedDoc.data.docURL : '', // sourceFileID
            noe.fullName, // agrFullName 
            noe.shortName, // agrShortName
            activeAgr._id, // parentAID
            null, // effectiveDate
            'none', // renewalType
            null, // effectiveTerm
            null, // expiryDate
            'Supplement', // agrPartType
            priority, // priority
            '', // linkedToAID
            {}, // amendment
            props.user._id, // creationBy 
            noe.oat !== undefined && noe.oat !== null ? noe.oat.clauses : [], // templateClauses
            noe.importedDoc, // importedDoc
            [], // exhibits
            true, // activate
            smartFieldObject, // smartFieldObject: sfs, sfsPush
          )

        } else if(priority !== noe.priority) {
          props.updateAgrPriority(noe._id, priority, props.user._id)
        }
      })
      
      // Send to Respective Agreement page
      history.push(`/core/` + props.module + `/agreement/` + activeAgr.relID + `/` + activeAgr._id);

    }).catch(function(err) { console.log(err) })

  }

  const handleCreateAgreement = () => {

    let smartFieldPush = [] // smartFieldPush is the object to change / update all values inside the agreement
    smartFields.filter((sf) => !sf.isArtificial).forEach((sf) => {
      smartFieldPush.push({ 
        oatid: sf.oatid, 
        singleClauseID: sf.singleClauseID, 
        ref: sf.ref, 
        newV: sf.type === 'auto' ? getAutoValue(sf.val1) : getSmartFieldValue(sf.type, sf.val1, sf.val2)
      })
    })

    // add smartFieldObjects to each inside agrsPush
    let aPush = agrsPush
    aPush.forEach((ap) => {
      ap.smartFieldObject = {
        sfs: smartFields.filter((sf) => sf.oatid === ap.oatid && !sf.isArtificial),
        sfPush: smartFieldPush.filter((sf) => sf.oatid === ap.oatid),
      }
    })
    
    if(!aPush.some((ap) => ap.oatid === undefined && ap.importedDoc === null)){ // got to have either a Template or an Upload

      // If to be Edited - ensure that changes are pushed
      if(activeCptyEnt.toBeEdited) {
        props.updateEntity(
          activeCptyEnt._id, // entid, 
          activeCptyEnt.orgID, // orgid, 
          activeCptyEnt.parentOrgID, // parentOrgID, 
          activeCptyEnt.entityType, // entityType, 
          activeCptyEnt.shortName, // legalName, 
          activeCptyEnt.legalName, // legalName, 
          activeCptyEnt.address, // address, 
          activeCptyEnt.logoURL, // logoURL, 
          activeCptyEnt.primaryContact, // primaryContact, 
          activeCptyEnt.signatory, // empty signatory 
          true, // isLive, 
          props.user._id, // lastUpdateBy
        )
      }

      // If logo to be changed across entities
      if (activeCptyOrg.logoEditing) {
        props.updateLogoForOrg(activeCptyOrg._id, activeCptyOrg.logoURL);
        cptyEntOptions.filter((cpe) => (cpe._id !== activeCptyEnt._id || !activeCptyEnt.toBeEdited)).forEach((cpe) => {
          props.updateEntity(
            cpe._id, // entid, 
            cpe.orgID, // orgid, 
            cpe.parentOrgID, // parentOrgID, 
            cpe.entityType, // entityType, 
            cpe.shortName, // shortName
            cpe.legalName, // legalName 
            cpe.address, // address, 
            activeCptyOrg.logoURL, // logoURL, 
            cpe.primaryContact, // primaryContact, 
            cpe.signatory, // empty signatory 
            cpe.isLive, // isLive, 
            props.user._id, // lastUpdateBy
          )
        })
      }

      // If photo to be changed for user
      if(activeCptyEnt.entityCategory === 'Person' && activePerson !== undefined && activePerson !== null && (activePerson.photoEditing || activeCptyEnt.toBeEdited)) {
        props.updateUser(activePerson._id, activePerson.firstName, activePerson.lastName, activePerson.title, activePerson.phone, activePerson.photoURL, 'counterparty', activePerson.agrIDs);
      }

      if(props.cpents.filter((c) => c._id === activeCptyEnt._id)[0] === undefined) {
        props.addEntityToReducer(cptyType.charAt(0).toUpperCase() + cptyType.slice(1), activeCptyEnt) // first param is entityType (ie. cptyType Capitalized first letter)
      }

      // Park any exhibits under the Main Agreement and define the OAT
      let mainAgr = aPush.filter((ap) => ap.isMain)[0]
      mainAgr.exhibits = aPush.filter((ap) => !ap.isMain)

      // Need to update the effective date parameters to the MainAgr
      mainAgr.effectiveDate = // Set the date as provided in the Wizard, when applicable and when it is a valid date
          mainAgr.oat !== undefined && mainAgr.oat !== null && mainAgr.oat.effectiveDateType !== undefined &&
          ['agrcreation', 'smartfield'].includes(mainAgr.oat.effectiveDateType) &&
          smartFields.filter((sf) => sf.name === "Agreement Effective Date")[0] !== undefined && 
          !isNaN(Date.parse(smartFields.filter((sf) => sf.name === "Agreement Effective Date")[0].val1)) ?
              smartFields.filter((sf) => sf.name === "Agreement Effective Date")[0].val1 : 
              null
      mainAgr.renewalType = 
          mainAgr.oat !== undefined && mainAgr.oat !== null && mainAgr.oat.renewalType !== undefined ?
              mainAgr.oat.renewalType :
              'none'
      mainAgr.effectiveTerm = 
          mainAgr.oat !== undefined && mainAgr.oat !== null && mainAgr.oat.effectiveTerm !== undefined ?
              mainAgr.oat.effectiveTerm :
              null
      mainAgr.expiryDate = calculateExpiryDate(mainAgr.effectiveDate, mainAgr.renewalType, mainAgr.effectiveTerm)

      if(activeRel !== null & activeRel !== undefined && activeRel !== ''){ // REL Exists: Add selected agreements under the respective Rel

        let orgIsPrimary = ['partnerships', 'sales', 'people', 'corporate'].includes(props.module) && activeRel.secondaryOrgID !== props.org._id ? true : false
        let cbs = [], sigs = []
        cbs.push({ uid: props.user._id, entity: orgIsPrimary ? "primary" : "secondary", email: props.user.email.toLowerCase().trim() }) // Set creator of agreement as initial contributor
        // Add counterparty as cpty contributors and signatories if it's a person
        if(activeCptyEnt.entityCategory === 'Person') { 
          cbs.push({ uid: activePerson._id, entity: orgIsPrimary ? "secondary" : "primary", email: activePerson.email})
          sigs.push({ uid: activePerson._id, entity: orgIsPrimary ? "secondary" : "primary", entityName: activePerson.displayName, email: activePerson.email})
        }

        // if the rel is deactive - then ensure to hide visibility for the other side
        if(activeRel.deactive) { axios.put('/rel/relvisibility/' + activeRel._id, { invisibleFor: orgIsPrimary ? 'secondary' : 'primary' }) }
        
        props.createAgr(
          activeRel._id, // rid 
          mainAgr.atid, // agrTypeID
          orgIsPrimary ? activeOwnEnt._id : activeCptyEnt._id, // primaryCptyID
          orgIsPrimary ? activeCptyEnt._id : activeOwnEnt._id, // secondaryCptyID
          '', // tertiaryCptyID 
          cbs, // contributors
          sigs, // signatories
          'Draft', // agrStatus
          activeOwnEnt._id, // curAVOwner
          null, // avv
          mainAgr.importedDoc !== null ? mainAgr.importedDoc.data.docURL : '', // sourceFileID
          mainAgr.fullName, // agrFullName 
          mainAgr.shortName, // agrShortName
          '', //parentAID
          mainAgr.effectiveDate, // effectiveDate
          mainAgr.renewalType, // renewalType
          mainAgr.effectiveTerm, // effectiveTerm
          mainAgr.expiryDate, // expiryDate
          'Contract', // agrPartType
          0, // priority
          '', // linkedToAID
          {}, // amendment
          props.user._id, // creationBy 
          mainAgr.oat !== undefined && mainAgr.oat !== null ? mainAgr.oat.clauses : [], // templateClauses
          mainAgr.importedDoc, // importedDoc
          mainAgr.exhibits, // exhibits
          true, // activate
          mainAgr.smartFieldObject, // smartFieldObject: sfs, sfPush
        )

        // Send to Respective Relationship page
        history.push(`/core/` + props.module + `/relationship/` + activeRel._id + `/overview`);

      } else { // REL Does not exist: Need to create a new Relationship w/ Agreements

        let cbs = [], sigs = []
        cbs.push({ uid: props.user._id, entity: ['partnerships', 'sales', 'people', 'corporate'].includes(props.module) ? "primary" : "secondary", email: props.user.email }) // Set creator of agreement as initial contributor
        // Add counterparty as cpty contributors and signatories if it's a person
        if(activeCptyEnt.entityCategory === 'Person') { 
          cbs.push({ uid: activePerson._id, entity: ['partnerships', 'sales', 'people', 'corporate'].includes(props.module) ? "secondary" : "primary", email: activePerson.email })
          sigs.push({ uid: activePerson._id, entity: ['partnerships', 'sales', 'people', 'corporate'].includes(props.module) ? "secondary" : "primary", email: activePerson.email })
        }

        props.createRelWithAgreements(
          relationshipType, // relationshipType
          (['partnerships', 'sales', 'people', 'corporate'].includes(props.module) && props.org.clientType === 'legalOrg' ?
              activeOwnEnt.shortName :
           ['partnerships', 'sales', 'people', 'corporate'].includes(props.module) ? 
              props.org.shortName : 
              activeCptyOrg.shortName) + 
          ' | ' + 
          (['partnerships', 'sales', 'people', 'corporate'].includes(props.module) ? 
              activeCptyOrg.shortName : 
            props.org.clientType === 'legalOrg' ?
              activeOwnEnt.shortName :
              props.org.shortName), // relName
          isCustomURL ? generateRandomString(20) : '', // customURL
          ['partnerships', 'sales', 'people', 'corporate'].includes(props.module) ? activeOwnEnt._id : activeCptyEnt._id, // primaryCptyID
          ['partnerships', 'sales', 'people', 'corporate'].includes(props.module) ? activeCptyEnt._id : activeOwnEnt._id, // secondaryCptyID
          '', // tertiaryCptyID
          activeOwnEnt._id, // curAVOwner
          cbs, // contributors
          sigs, // signatories
          ['partnerships', 'sales', 'people', 'corporate'].includes(props.module) ? props.user.orgID : activeCptyOrg._id, // primaryOrgID, 
          ['partnerships', 'sales', 'people', 'corporate'].includes(props.module) ? activeCptyOrg._id : props.user.orgID, // secondaryOrgID, 
          ['partnerships', 'sales', 'people', 'corporate'].includes(props.module) ? 'secondary' : 'primary', // invisibleFor
          props.user._id, // creationBy, 
          false, // deactive
          'None', // deactiveReason,
          isWhiteLabeled ?
            {
              orgName: ['legalOrg'].includes(props.org.clientType) ? activeOwnEnt.shortName : props.org.shortName,
              logoURL: ['legalOrg'].includes(props.org.clientType) ? activeOwnEnt.logoURL : props.org.logoURL,
              color: props.org.whiteLabel.color,
              contrastColor: props.org.whiteLabel.contrastColor,
              whiteLabelLevel: props.org.orgSettings !== undefined && props.org.orgSettings !== null &&
                props.org.orgSettings.whiteLabelLevel !== undefined ? props.org.orgSettings.whiteLabelLevel : 'none',
            }
            : null, // whiteLabel
          mainAgr, // mainAgr
          mainAgr.exhibits, // exhibits
        )
        history.push(`/core/` + props.module + `/relationships`);

      }
    }
  }

  const toggleConfiguringSmartFields = () => {
    if(!configuringSmartFields) {
      window.scrollTo(0, 0);
    }
    setConfiguringSmartFields(!configuringSmartFields);
  }

  const handleSmartFieldChange = (sfid, valnumber, newval) => {
    let sfs = smartFields
    let osfid = smartFields.filter((sf) => sf._id === sfid)[0] !== undefined && smartFields.filter((sf) => sf._id === sfid)[0].osfid !== undefined ? 
      smartFields.filter((sf) => sf._id === sfid)[0].osfid : null

    // Update all smartfields with the same OSFID (provided they have one)
    let arrToBeUpdated = osfid === null ? sfs.filter((sf) => sf._id === sfid) : sfs.filter((sf) => sf.osfid === osfid)

    arrToBeUpdated.forEach((sf) => { // For every SF that has this OSFID
      
      if(valnumber === 'val1') { sf.val1 = newval }
      else if(valnumber === 'val2') { sf.val2 = newval }

    })
    setSmartFields([...sfs])
  }

  const handleImportOpening = (type) => {
    setActiveTemplateOption(type === 'main' ? {...activeTemplateOption, main:'upload'} : {...activeTemplateOption, exhibit: 'upload'});
    setImportOpen(true)
  }

  const handlePDFAttaching = (type) => {
    setActiveTemplateOption({...activeTemplateOption, exhibit: 'attachment'})
  }

  const handleUploadChange = (e) => {
    if(e.target !== undefined && e.target.files !== undefined) {
        setAttachment({ ...attachment, file: e.target.files[0] })
    }
  }

  const handleIntegrationCancel = () => {
    setIntegrationSearch({platform: null, query: ''})
    setIntegrationSearchResults(null)
    setErrMsg(null)
  }

  const handleIntegrationSearch = (intSearch) => {
    setLoading(true)

    if(intSearch !== undefined && intSearch !== null && 
      intSearch.platform !== undefined && intSearch.platform !== null &&
      intSearch.query !== undefined && intSearch.query !== null && intSearch.query.length > 2) {

      axios.post('/integration/protect/entity/search', { 
        searchString: intSearch.query, 
        integration: intSearch.platform,
        orgID: props.org._id, 
        refreshToken: 
          ['hubspot'].includes(intSearch.platform) ? props.org.integrations.hubspot :
          ['salesforce'].includes(intSearch.platform) ? props.org.integrations.salesforce.refreshToken : null,
        instanceURL: ['salesforce'].includes(intSearch.platform) ? props.org.integrations.salesforce.instanceURL : null
      }).then((res) => {

        setIntegrationSearchResults(res.data.data)
        setLoading(false)

      }).catch((err) => console.log(err))
    }
  }

  const handleIntegrationSelect = (sourceInfo) => {
    setLoading(true)
    let source = // used for the error msg
      sourceInfo !== undefined && sourceInfo !== null && ['hubspot'].includes(sourceInfo.integration) ? 'Hubpspot' :
      sourceInfo !== undefined && sourceInfo !== null && ['salesforce'].includes(sourceInfo.integration) ? 'Salesforce' : 'the Source System'
    
    if(integrationSearch !== undefined && integrationSearch !== null && 
      integrationSearch.platform !== undefined && integrationSearch.platform !== null &&
      integrationSearch.query !== undefined && integrationSearch.query !== null &&
      integrationSearch.query.length > 2) {

      axios.post('/integration/protect/entity/single', { 
        integration: sourceInfo.integration,
        sourceID: sourceInfo.sourceID,
        orgID: props.org._id, 
        refreshToken: ['salesforce'].includes(sourceInfo.integration) ? props.org.integrations.salesforce.refreshToken : props.org.integrations.hubspot,
        instanceURL: ['salesforce'].includes(sourceInfo.integration) ? props.org.integrations.salesforce.instanceURL : null
      }).then((res) => {

        let intent = res.data.data !== undefined && res.data.data !== null ? res.data.data : {}

        if(props.cpents.some((cpe) => cpe.sourceData !== undefined && cpe.sourceData !== null && cpe.sourceData.sourceID === intent.id)) {

          setErrMsg('This entity was already imported from ' + source + ', cancel and select it instead')
          setLoading(false)

        } else if(intent !== undefined && intent !== null &&
          intent.name !== undefined && intent.name !== null) { // Proceed to import the entity from Source System

          let parentOrg = null;

          if(intent.parentID !== undefined && intent.parentID !== null &&
            props.cpents.some((cpe) => cpe.sourceData !== undefined && cpe.sourceData !== null && cpe.sourceData.sourceID === intent.parentID)) {
            // The parent was already imported from the Source, add under the parent
            let parentEnt = props.cpents.filter((cpe) => cpe.sourceData !== undefined && cpe.sourceData !== null && cpe.sourceData.sourceID === intent.parentID)[0]  
            parentOrg = { shortName: parentEnt.shortName, _id: parentEnt.parentOrgID, logoURL: parentEnt.logoURL, isNetwork: false }          
            setActiveCptyOrg(parentOrg)  
          }

          callbackEntityForm({
            entityCategory: 'Company',
            shortName: intent.name,
            legalName: intent.name,
            streetAndNo: intent.address !== undefined && intent.address !== null ?
              intent.address : '[address]',
            address2: intent.address2 !== undefined ? intent.address2 : '',
            city: intent.city !== undefined && intent.city !== null ? intent.city : '[city]',
            zip: intent.zip !== undefined && intent.zip !== null ? intent.zip : '[zip]',
            state: intent.state !== undefined ? intent.state : '',
            country: intent.country !== undefined && intent.country !== null &&
              countries.filter((c) => c.label.trim().toLowerCase() === intent.country.trim().toLowerCase())[0] !== undefined ? 
                countries.filter((c) => c.label.trim().toLowerCase() === intent.country.trim().toLowerCase())[0].code : 'US',
            sourceData: { source: sourceInfo.integration, sourceID: sourceInfo.sourceID },
            parentOrg: parentOrg,
          })

        } else {
          setErrMsg('Unable to import this record from ' + source)
          setLoading(false)
        }
        
      }).catch((err) => {
        setErrMsg('Unable to select this record from ' + source)
        setLoading(false)
      })
    }

  }

  const handleStepSF = (step) => {
    setActiveSFStep(step)
  }

  const handleNextSF = () => {
    const newCompletedSF = completedSF;
    newCompletedSF[activeSFStep] = true;
    setCompletedSF(newCompletedSF);
    setActiveSFStep(activeSFStep + 1)
  }

  const handleBackSF = () => {
    setActiveSFStep(activeSFStep - 1)
  }

  const allSmartFieldsAreValid = (sfArray) => {
    let r = true;
    sfArray.forEach((sf) => { if(sf.val1 !== undefined && sf.val1 !== null && sf.val1 !== '' && !smartfieldValueValidation(sf.type, sf.val1, sf.val2)) { r = false } })
    return r;
  }

  const RenderThumbAgs = ({type, exclude}) => {

    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>
          <IconButton onClick={() => scroll(-480)}>
            <FontAwesomeIcon icon={faChevronLeft} className={classes.chevIconB} />
          </IconButton>
        </Grid>
        <Grid item>
        <Box display="flex" className={classes.boxResizing} ref={ref}>
          {loading ?
          <Box align="center" mt={10} mb={5} width="100%">
            <CircularProgress size={24} />
          </Box>
          :
          props.orgAgrTemplates
          .filter((oat) => addingExhibit || (!['60bfc1ac6cf95aa0cae4cf16','60db7133ceba02d7da951b92'].includes(oat.agrTypeID))) // exclude "Agreement Exhibit" for main body selection (both UAT and PROD ID)
          .filter((oat) => oat.active && oat.module.includes(props.module))
          .filter((oat) => !exclude.some((e) => (e.agrTypeID === oat.agrTypeID || e.atid === oat.agrTypeID)))
          .sort((a,b) => (props.agrTypes.filter((at) => at._id === a.agrTypeID)[0].fullName[0] > props.agrTypes.filter((at) => at._id === b.agrTypeID)[0].fullName[0]) ? 1 : ((props.agrTypes.filter((at) => at._id === b.agrTypeID)[0].fullName[0] > props.agrTypes.filter((at) => at._id === a.agrTypeID)[0].fullName[0]) ? -1 : 0))
          .map((oat) => (
              <Box ml={1} mr={1} mt={4} mb={5} key={oat._id}>
                <ThumbAgr 
                  ag={{
                    oatid: oat._id,
                    agrStatus: 'ActiveSelection',
                    agrFullName: props.agrTypes.filter((at) => at._id === oat.agrTypeID)[0] !== undefined ? props.agrTypes.filter((at) => at._id === oat.agrTypeID)[0].fullName[0] : '',
                    lastUpdateDate: oat.lastUpdateDate,
                  }}
                  handleSelection={handleTemplateSelect}
                  cptyLogo={props.org.logoURL}
                  primaryLegalName={oat.reference !== undefined && oat.reference !== null && oat.reference !== '' ? oat.reference : 'DEFAULT'}
                  secondaryLegalName={'Version ' + oat.version.substr(0,3)}
                  actionReq={!props.external && !props.active && ['Template','Terms'].includes(oat.draftFor)}
                  fromLegal={true}
                  hideDot={true}
                  selectType={type}
                />
              </Box>
          ))}
        </Box>
        </Grid>
        <Grid item>
          <IconButton onClick={() => scroll(480)}>
            <FontAwesomeIcon icon={faChevronRight} className={classes.chevIconB} />
          </IconButton>
        </Grid>
      </Grid> 
    )   
  }

  const RenderUploadSelection = ({type}) => {
    return (
      <>
        {iDoc !== null || (type === 'main' && agrsPush.filter((ap) => ap.isMain && ap.createFrom === 'upload')[0] !== undefined) ?
        <Box mt={6} mb={6}>
          <Card variant="outlined" style={{width: '160px', height: '160px'}}>
            <CardContent>
                <Typography variant="h6" align="center" className={classes.agrShortName} gutterBottom>
                    {iDoc !== null && props.agrTypes.filter((at) => at._id === iDoc.data.agrTypeID)[0] !== undefined ?
                      props.agrTypes.filter((at) => at._id === iDoc.data.agrTypeID)[0].shortName : 
                    agrsPush.filter((ap) => ap.isMain && ap.createFrom === 'upload')[0] !== undefined ?
                      agrsPush.filter((ap) => ap.isMain && ap.createFrom === 'upload')[0].shortName : 'DOC'}
                </Typography>
                <Typography variant="body1" align="center" className={classes.agrFullName}>
                    {iDoc !== null && props.agrTypes.filter((at) => at._id === iDoc.data.agrTypeID)[0] !== undefined ?
                      props.agrTypes.filter((at) => at._id === iDoc.data.agrTypeID)[0].fullName[0] : 
                    agrsPush.filter((ap) => ap.isMain && ap.createFrom === 'upload')[0] !== undefined ?
                      agrsPush.filter((ap) => ap.isMain && ap.createFrom === 'upload')[0].fullName : 'Agreement'}
                </Typography>
                <FontAwesomeIcon icon={faUpload} className={classes.uploadIcon} />
            </CardContent>
          </Card>
          <Box mt={2} align="center"><Button color="primary" onClick={e => handleClearUpload(type)}>Clear upload</Button></Box>
        </Box>
        :
        <Box mt={9} mb={9}>
          <Box mt={2} mb={2} className={classes.uploadLink} align="center" onClick={e => setImportOpen(true)}>
            Click to import a template
          </Box>
        </Box>
        }
      </>
    )
  }

  const RenderCptyThumbs = () => {
    return (
      <Grid container direction="row" alignItems="center" justify="center" spacing={2}>
        <Grid item>
          <CardCpty
            cpty={['sales', 'partnerships', 'people', 'corporate'].includes(props.module) ? activeOwnEnt : activeCptyEnt}
            type="primary"
            extraBreak={
              (['sales', 'partnerships', 'people', 'corporate'].includes(props.module) &&
               (activeOwnEnt === null || activeOwnEnt.address === undefined || activeOwnEnt.address.filter((a) => a.addressType === 'billto')[0].address2 === '') &&
               activeCptyEnt !== null && activeCptyEnt.address !== undefined && activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0].address2 !== '') ||
              (['procurement'].includes(props.module) && 
               (activeCptyEnt === null || activeCptyEnt.address === undefined || activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0].address2 === '') &&
               activeOwnEnt !== null && activeOwnEnt.address !== undefined && activeOwnEnt.address.filter((a) => a.addressType === 'billto')[0].address2 !== '') ? true : false}
            switch={activeAgr !== null ? [] : ['sales', 'partnerships', 'people', 'corporate'].includes(props.module) ? props.subs.filter((s) => s.isLive && ((props.org.clientType === 'legalOrg' && s.entityType === 'LegalClient' && (props.rels.filter((r) => [r.primaryCptyID, r.secondaryCptyID].includes(activeCptyEnt._id)).length === 0 || props.rels.some((r) => [r.primaryCptyID, r.secondaryCptyID].includes(activeCptyEnt._id) && [r.primaryCptyID, r.secondaryCptyID].includes(s._id)))) || (props.org.clientType !== 'legalOrg' && s.entityType === 'Subsidiary'))) : cptyEntOptions}
            edit={activeAgr !== null ? null : // If it's for a Exhibit scenario - no editing
              ['sales', 'partnerships', 'people', 'corporate'].includes(props.module) ? null : // If it's a SUB - no editing
              (activeCptyOrg !== undefined && activeCptyOrg !== null && activeCptyOrg.isNetwork) || 
              (activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.entityType === 'Subsidiary') ? null : // If it's a network Subscriber - no editing
              (activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt !== undefined && activeCptyEnt.toBeCreated)  ||
              (activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt !== undefined && activeCptyEnt._id !== undefined) ? 
                e => setEditing(activeCptyEnt._id) : null} // If it's new to be created or existing to be edited - Set "toBeEdited" and move to pg.0
            parentCallBack={handleCardCptyRequest}
          />
        </Grid>
        <Grid item>
          <CardCpty
            cpty={['sales', 'partnerships', 'people', 'corporate'].includes(props.module) ? activeCptyEnt : activeOwnEnt}
            type="secondary"
            extraBreak={
              (['procurement'].includes(props.module) &&
               (activeOwnEnt === null || activeOwnEnt.address === undefined || activeOwnEnt.address.filter((a) => a.addressType === 'billto')[0].address2 === '') &&
               activeCptyEnt !== null && activeCptyEnt.address !== undefined && activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0].address2 !== '') ||
              (['sales', 'partnerships', 'people', 'corporate'].includes(props.module) && 
               (activeCptyEnt === null || activeCptyEnt.address === undefined || activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0].address2 === '') &&
               activeOwnEnt !== null && activeOwnEnt.address !== undefined && activeOwnEnt.address.filter((a) => a.addressType === 'billto')[0].address2 !== '') ? true : false}
            switch={activeAgr !== null ? [] : ['sales', 'partnerships', 'people', 'corporate'].includes(props.module) ? cptyEntOptions : props.subs.filter((s) => s.isLive && ((props.org.clientType === 'legalOrg' && s.entityType === 'LegalClient') || (props.org.clientType !== 'legalOrg' && s.entityType === 'Subsidiary')))}
            edit={activeAgr !== null ? null : // If it's for a Exhibit scenario - no editing
              ['procurement'].includes(props.module) ? null : // If it's a SUB - no editing
              (activeCptyOrg !== undefined && activeCptyOrg !== null && activeCptyOrg.isNetwork) || 
              (activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.entityType === 'Subsidiary') ? null : // If it's a network Subscriber - no editing
              (activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt !== undefined && activeCptyEnt.toBeCreated) || 
              (activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt !== undefined && activeCptyEnt._id !== undefined) ? 
                e => setEditing(activeCptyEnt._id) : null} // If it's new to be created or existing to be edited - Set "toBeEdited" and move to pg.0
            parentCallBack={handleCardCptyRequest}
          />
        </Grid>
      </Grid>      
    )
  }

  const RenderTemplateOption = ({type}) => {
    let to = type === 'main' ? activeTemplateOption.main : activeTemplateOption.exhibit
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>
          <Button style={{borderRadius: '20px 0px 0px 20px', borderRight: '1px solid #ffffff'}}
          className={to === 'company' ? classes.activeTemplateOption : classes.inactiveTemplateOption}
          color={to === 'company' ? "primary" : "default"} variant={to === 'company' ? "contained" : "text"} 
          disableElevation onClick={e => setActiveTemplateOption(type === 'main' ? {...activeTemplateOption, main:'company'} : {...activeTemplateOption, exhibit: 'company'})}>Company template</Button>
        </Grid>
        <Grid item>
          <Button style={/*type === 'exhibit' ? {borderRadius: '0px 0px 0px 0px', borderRight: '1px solid #ffffff'} : */{borderRadius: '0px 20px 20px 0px'}}
          className={to === 'upload' ? classes.activeTemplateOption : classes.inactiveTemplateOption}
          color={to === 'upload' ? "primary" : "default"} variant={to === 'upload' ? "contained" : "text"} 
          disableElevation
          onClick={e => handleImportOpening(type)}>Import a template</Button>
        </Grid>
        {/*type === 'exhibit' ?
        <Grid item>
          <Button style={{borderRadius: '0px 20px 20px 0px'}}
          className={to === 'attachment' ? classes.activeTemplateOption : classes.inactiveTemplateOption}
          color={to === 'attachment' ? "primary" : "default"} variant={to === 'attachment' ? "contained" : "text"} 
          disableElevation
          onClick={e => handlePDFAttaching(type)}>Insert a PDF</Button>
        </Grid>
        :''*/}
      </Grid>
    )
  }

  return(
    <div>
      <IconButton
        onClick={handleExit}
        className={classes.exitIconB}>
        <FontAwesomeIcon icon={faTimes} className={classes.exitIcon} />
      </IconButton>

      <Container maxWidth="lg">
        <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
          <Grid item>
            <Box mb={2} mt={2}>
              <Typography align="center" variant="h4" className={classes.bold}>
                  {activeAgr !== null ? "Add an Exhibit" : "Create a new Agreement"}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box mb={2}>
              <MobileStepper
                variant="dots"
                steps={3}
                position="static"
                activeStep={activeStep}
                className={classes.stepper}
                nextButton={
                  <Button size="small" onClick={handleNext} 
                    disabled={
                      (activeStep === 0 && (activeCptyOrg === null || activeCptyEnt === null)) ||
                      (activeStep === 1 && (activeCptyOrg === null || activeCptyEnt === null || 
                        ((activeTemplateOption.main === 'company' && (agrSelected === undefined || agrSelected === null || agrSelected === '') && agrsPush.filter((ap) => ap.isMain && ap.createFrom === 'template')[0] === undefined) ||
                         (activeTemplateOption.main === 'upload' && iDoc === null && agrsPush.filter((ap) => ap.isMain && ap.createFrom === 'upload')[0] === undefined)))) ||
                      (activeStep === 2)}>
                    Next&nbsp;<FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                }
                backButton={
                  <Button size="small" 
                  onClick={
                    activeStep === 0 && !logoEditing && (activeCptyEnt === undefined || activeCptyEnt === null || !activeCptyEnt.toBeEdited) &&
                    (activeCptyOrg === null || !activeCptyOrg.toBeCreated) ? 
                        handleExit : 
                    addingExhibit ? 
                        e => setAddingExhibit(false) :
                    configuringSmartFields ?
                        e => setConfiguringSmartFields(false) :
                        handleBack} 
                  disabled={activeAgr !== null || (activeStep === 0 && activeCptyEnt !== null && activeCptyEnt !== undefined && (activeCptyEnt.toBeEdited || logoEditing))}>
                    <FontAwesomeIcon icon={faChevronLeft} />&nbsp;Back
                  </Button>
                }
              />
            </Box>
          </Grid>           

        { activeStep === 0 ? (

          <Grid container direction="column" justify="flex-start" alignItems="stretch">

            {props.aid !== undefined && props.aid !== null && (activeAgr === null || activeAgrs === null) ?
            <Box mt={5} mb={5} align="center">
                <CircularProgress disableShrink size={30} />
            </Box>
            :
             (activeCptyOrg !== undefined && activeCptyOrg !== null && activeCptyOrg.wasCreated) ||
             (activeCptyOrg !== undefined && activeCptyOrg !== null && activeCptyOrg.toBeCreated) ||
             (activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.toBeCreated) ||
             (activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.toBeEdited) ?
            <Box mt={0}>
              <Typography align="center" variant="h6">
                {((activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.toBeEdited) ?
                "Edit the " : "Create a new ") + (cptyType === 'corpcp' ? 'counterparty' : cptyType)}&nbsp;&nbsp;
                <InformationalTooltip msg={logoEditing ?
                  "Upload a (square) logo of your counterparty for enhanced usability and recognition. You can typically find and download a logo quickly by web-searching \"Company name Logo\"." :
                  "When you create (or edit) a company, the company name would be their common business name (for example \"Google\") while the Legal name would be their legal entity name (for example \"Google LLC\")."} />
              </Typography>
              <Box align="left" mt={3}>

                {errMsg !== null ? 
                  <Box mb={3}>
                  <Typography align="center" variant="subtitle1" color="error">{errMsg}</Typography> 
                  {potentialEntity !== null ?
                  <Box mt={2} align="center">
                    <Button onClick={potentialEntity.isLive ? e => handleCptyOrgChange(null) : e => history.push('/core/' + props.module + '/masterdata/edit/' + potentialEntity._id)}
                    color="secondary" variant="contained" disableElevation>
                      {(potentialEntity.isLive ? "Select " : "Reactivate ") + potentialEntity.legalName}
                    </Button>
                  </Box>
                  :''}
                  <Box mt={2} align="center">
                    <Button variant="contained" disableElevation size="small" color="secondary" onClick={retryEntityForm}>Clear error and retry</Button>
                  </Box>
                  </Box>
                  : ''}

                {!logoEditing ? 

                <>
                <EntityForm 
                  submitting={submitting}
                  parentCallback={callbackEntityForm}
                  type={'newAgr'}
                  hideRadioCategory={props.module === 'people' ? true : false}
                  editEntity={(activeCptyEnt !== undefined && activeCptyEnt !== null && (activeCptyEnt.toBeCreated || activeCptyEnt.toBeEdited))}
                  initialValues={{
                    entityCategory: activeCptyEnt !== undefined && activeCptyEnt !== null && 
                      activeCptyEnt.entityCategory !== undefined && activeCptyEnt.entityCategory !== null ? activeCptyEnt.entityCategory : 
                      props.module === 'people' ? 'Person' : 'Company',
                    shortName: activeCptyOrg.shortName, // formData.shortName, 
                    legalName: activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.legalName !== undefined ? 
                      activeCptyEnt.legalName : '', // formData.legalName, 
                    streetAndNo: activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.address !== undefined && 
                      activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0] !== undefined ? 
                        activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0].streetAndNo : '', // formData.streetAndNo,
                    address2: activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.address !== undefined && 
                      activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0] !== undefined && 
                      activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0].address2 !== undefined ? 
                        activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0].address2 : '', // formData.address2,
                    city: activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.address !== undefined && 
                      activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0] !== undefined ? 
                        activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0].city : '', // formData.city,
                    zip: activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.address !== undefined && 
                      activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0] !== undefined ? 
                        activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0].zip : '', // formData.zip,
                    state: activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.address !== undefined && 
                      activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0] !== undefined ? 
                        activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0].state : '', // formData.state,
                    country: activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.address !== undefined && 
                      activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0] !== undefined ? 
                        activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0].country : '',
                    firstName: activePerson !== undefined && activePerson !== null && activePerson.firstName !== undefined ? activePerson.firstName : '',
                    lastName: activePerson !== undefined && activePerson !== null && activePerson.lastName !== undefined ? activePerson.lastName : '',
                    email: activePerson !== undefined && activePerson !== null && activePerson.email !== undefined ? activePerson.email : '',
                    title: activePerson !== undefined && activePerson !== null && activePerson.title !== undefined ? activePerson.title : '',
                    }}
                />
                <Box mt={1} align="center">
                  <Button color="primary" onClick={e => handleCptyOrgChange(null)}>Select a different {(cptyType === 'corpcp' ? 'counterparty' : cptyType)}</Button>
                </Box>
                </>

                :

                <>
                <Box mt={2} mb={3} align="center">                
                  <UploadImg 
                    type={activeCptyEnt.entityCategory !== undefined && activeCptyEnt.entityCategory === 'Person' ? "avatar" : "logo"}
                    parentCallback={callbackLogoUploadForm}
                    initialImg={activeCptyEnt.logoURL} />
                </Box>
                <Box mt={1} align="center">
                  <Button color="primary" onClick={handleNext}>Skip this step</Button>
                </Box>
                </>

                }

              </Box>
            </Box>
            :
            <Box mt={0}>
              {props.user.role !== 'Counterparty' && (props.user.introComplete === undefined || props.user.introComplete.dashboard === undefined || props.user.introComplete.dashboard === null || !props.user.introComplete.dashboard )  &&
               props.org !== undefined && props.org.clientType !== 'legalOrg' ?
              <GetStarted 
                  fromDashboard={false}
                  step={"3_0"}
                  pushDown={true}
                  module={props.module}
                  user={props.user}
                  org={props.org}
                  rels={props.rels}
                  cpents={props.cpents}
                  />
              :''}
              <Typography align="center" variant="h6">
                  Select {props.org !== undefined && props.org.clientType === 'legalOrg' ? "the" : "your"} {(cptyType === 'corpcp' ? 'counterparty' : cptyType)}&nbsp;&nbsp;
                  <InformationalTooltip msg={"To create a new " + (cptyType === 'corpcp' ? 'counterparty' : cptyType) + ", start typing the company name of the new supplier and click \"Create [...] as a new...\".\n\nTo select a " + (cptyType === 'corpcp' ? 'counterparty' : cptyType) + " that is already created: Start typing and the available counterparties will show.\n\nSimilarly, you can select or create an additional legal entity of your counterparty.\n\nThe options that show as \"Canveo Network\" are companies that have a Canveo account, these do not need to be created by you and can be selected as counterparties if applicable."} />
              </Typography>

              {loading ?
              <Box align="center" mt={10} mb={5} width="100%">
                <CircularProgress size={24} />
              </Box>
              :
              props.subs.length === 0 ?
              <Box mt={5} mb={5}>
              <Typography align="center">Please set up an entity for your organisation in the Admin module</Typography>
              </Box>
              :
              props.org !== undefined && props.org.modules !== undefined && !['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) && !props.org.modules.includes(props.module) ?
              <Box mt={5} mb={5}>
              <Typography align="center">You need to upgrade to be able to create agreements inside this module</Typography>
              </Box>
              :freezeAgrCreation ?
              <Box mt={5} mb={5} align="center">
                <Typography variant="subtitle1" color="textSecondary">
                  An upgrade is required to create additional agreements this month.
                  {props.user.role === 'Admin' ?
                  <Button variant="text" color="secondary" component={RouterLink} to="/core/admin/company/subscription/upgrade">Upgrade</Button>
                  :''}
                </Typography>
              </Box>
              :['legalOrg'].includes(props.org.clientType) && !props.subs.some((s) => s.isLive && ['LegalClient'].includes(s.entityType)) ?
              <Box mt={5} mb={5} align="center">
                <Typography variant="subtitle1" color="textSecondary">
                  There is no client setup yet.
                  {props.user.role === 'Admin' ?
                  <Button variant="text" color="secondary" component={RouterLink} to="/core/admin/company/entities/1">Create Client</Button>
                  :''}
                </Typography>
              </Box>
              :integrationSearch !== undefined && integrationSearch !== null && integrationSearch.platform !== null && 
              integrationSearch.query !== undefined && integrationSearch.query !== null ?
              <Box align="center" mt={4}>
                <Grid item xs={12} sm={8} md={6}>
                  {errMsg !== null ?
                  <Box mb={3}><Typography color="error">{errMsg}</Typography></Box>
                  :''}
                  <form onSubmit={e => handleIntegrationSearch(integrationSearch)}>
                  <Box align="left">
                    <TextField
                      label={
                        ['hubspot'].includes(integrationSearch.platform) ? "Search Hubspot..." :
                        ['salesforce'].includes(integrationSearch.platform) ? "Search Salesforce..." : "Search"}
                      placeholder="Search by name or domain"
                      fullWidth
                      variant="outlined"
                      value={integrationSearch.query}
                      onChange={e => setIntegrationSearch({...integrationSearch, query: e.target.value})}
                    />
                    <Box mt={2}>
                      <Button color="primary" variant="contained" disableElevation fullWidth 
                      type="submit"
                      disabled={integrationSearch === undefined || integrationSearch === null || integrationSearch.platform === null || 
                        integrationSearch.query === undefined || integrationSearch.query === null || integrationSearch.query.length < 3}>Search</Button>
                    </Box>
                  </Box>
                  </form>

                  <Box mt={1} align="right">
                    <Button color="primary" variant="text" onClick={handleIntegrationCancel}><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Cancel</Button>
                  </Box>

                  {integrationSearchResults !== null ?
                  <Box mt={3}>

                    {integrationSearchResults.total > 0 && integrationSearchResults.results !== undefined &&
                    integrationSearchResults.results !== null && integrationSearchResults.results.length > 0 ?
                    <Box mb={3}>
                      <List>
                        {integrationSearchResults.results.map((sr) => (
                        <ListItem key={sr.sourceID} button onClick={e => handleIntegrationSelect({integration: sr.source, sourceID: sr.sourceID})}>
                          <ListItemAvatar>
                            <Avatar src={['hubspot'].includes(integrationSearch.platform) ? hubspot_icon : salesforce_icon} />
                          </ListItemAvatar>
                          <ListItemText 
                          primary={<Typography className={classes.bold}>
                            {sr.name !== undefined && sr.name !== null ? sr.name : "Unknown Company"}</Typography>}
                          secondary={
                          <>
                          {['hubspot'].includes(integrationSearch.platform) ? "Hubspot" : "Salesforce"}&nbsp;ID:&nbsp;
                          {sr.sourceID !== undefined && sr.sourceID !== null ? sr.sourceID : "n/a"}
                          {sr.domain !== undefined && sr.domain !== null ? <>&nbsp;({sr.domain})</> : ""}
                          </>} />
                        </ListItem>
                        ))}
                        
                      </List>
                    </Box>
                    :''}

                    <Typography color="textSecondary" variant="body2" align="center">
                      {integrationSearchResults.total} results{
                        integrationSearchResults.total === 0 ? ", please retry" :
                        integrationSearchResults.total > 10 ? ", refine your search" : ""}
                    </Typography>

                  </Box>
                  :''}
                </Grid>
              </Box>
              :
              <Box align="center" mt={4}>
              <Grid item xs={12} sm={8} md={6}>
                <Box align="left">
                <Autocomplete // THE ORG AUTOCOMPLETE
                  value={activeCptyOrg}
                  onChange={(e, newValue) => {
                    if (typeof newValue === 'string') {
                      handleCptyOrgChange({ shortName: newValue, new: true });
                    } else if (newValue && newValue.inputValue && (newValue.isHubspot || newValue.isSalesforce)) {
                      setIntegrationSearch({platform: newValue.isHubspot ? 'hubspot' : 'salesforce', query: newValue.inputValue})
                      handleIntegrationSearch({platform: newValue.isHubspot ? 'hubspot' : 'salesforce', query: newValue.inputValue})
                    } else if (newValue && (newValue.inputValue || newValue.inputValue === '')) { // Create a new value from the user input
                      handleCptyOrgChange({ shortName: newValue.inputValue, toBeCreated: true });
                    } else { handleCptyOrgChange(newValue); } // mouseSelect
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(params.inputValue !== '' && params.inputValue.length > 1 ? options : options.filter((o) => !o.isNetwork), params);
                    // Suggest the creation of a new value
                    if(params.inputValue !== '' && !filtered.some((f) => f.shortName.toLowerCase() === params.inputValue.toLowerCase())) {
                      filtered.push({
                        inputValue: params.inputValue,
                        shortName: `Create "${params.inputValue}" as a new ${(cptyType === 'corpcp' ? 'counterparty' : cptyType)}`,
                      });
                      if(props.org !== undefined && props.org !== null && props.org.integrations !== undefined && props.org.integrations !== null &&
                      props.org.integrations.hubspot !== undefined && props.org.integrations.hubspot !== null) { 
                        filtered.push({ // When you have a hubspot integration, also show the hubspot search option
                          inputValue: params.inputValue,
                          shortName: `Search "${params.inputValue}" inside Hubspot`,
                          isHubspot: true,
                        });
                      }
                      if(props.org !== undefined && props.org !== null && props.org.integrations !== undefined && props.org.integrations !== null &&
                      props.org.integrations.salesforce !== undefined && props.org.integrations.salesforce !== null && 
                      props.org.integrations.salesforce.instanceURL !== undefined && props.org.integrations.salesforce.instanceURL !== null) { 
                        filtered.push({ // When you have a salesforce integration, also show the salesforce search option
                          inputValue: params.inputValue,
                          shortName: `Search "${params.inputValue}" inside Salesforce`,
                          isSalesforce: true,
                        });
                      }
                    } else if (params.inputValue === '') {
                      filtered.unshift({
                        isNew: true,
                        inputValue: params.inputValue,
                        shortName: `Create a new ${(cptyType === 'corpcp' ? 'counterparty' : cptyType)}`,
                      });
                    }
                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  options={orgOptions || []}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') { return option; } // Value selected with enter, right from the input
                    if (option.inputValue) { return option.inputValue; } // Add "xxx" option created dynamically
                    return option.shortName; // Regular option
                  }}
                  renderOption={(option) => (
                    <Typography 
                      variant={option._id !== undefined ? "body1" : "subtitle1"}
                      //color={option._id !== undefined ? "textPrimary" : "primary"}>
                      color="textPrimary">
                      {option.shortName}
                      {option.isNetwork || option.isHubspot || option.isSalesforce ? 
                      <>
                      <FontAwesomeIcon 
                      icon={option.isHubspot ? faHubspot : option.isSalesforce ? faSalesforce : faProjectDiagram} 
                      className={option.isHubspot || option.isSalesforce ? classes.cptyIcon : classes.networkIcon} />
                      <span className={option.isHubspot || option.isSalesforce ? classes.cptySpan : classes.networkSpan}>
                        {option.isHubspot ? "Hubspot" : option.isSalesforce ? "Salesforce" : "Canveo Network"}
                      </span>
                      </>
                      :
                      <>
                      <FontAwesomeIcon icon={props.module === 'people' ? faUser : faBuilding} className={classes.cptyIcon} />
                      <span className={classes.cptySpan}>{
                      option.isNew ? "Create" :
                      cptyType === 'corpcp' ? 'Counterparty' : (cptyType.charAt(0).toUpperCase() + cptyType.slice(1))}</span>
                      </>
                      }
                    </Typography>
                  )}
                  style={{ width: '100%' }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} label={"Search or create " + (cptyType === 'corpcp' ? 'counterparty' : cptyType) + "..."} variant="outlined" fullWidth
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }} />
                  )}
                />
                </Box> 
              </Grid>

              {activeCptyOrg !== null && cptyEntOptions.length > 0 && (activeCptyEnt === null || activeCptyEnt.entityCategory !== 'Person') ?
              <Grid item xs={12} sm={8} md={6}>
                <Box align="left" mt={2}>

                  <Autocomplete // THE ENTITY
                    value={activeCptyEnt}
                    onChange={(e, newValue) => {
                      if (typeof newValue === 'string') {
                        handleCptyEntChange({ legalName: newValue, new: true });
                      } else if (newValue && newValue.inputValue) { // Create a new value from the user input
                        handleCptyEntChange({ legalName: newValue.inputValue, toBeCreated: true });
                      } else { handleCptyEntChange(newValue); } // mouseSelect
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      /*if (params.inputValue !== '' && !activeCptyOrg.isNetwork) { // Suggest the creation of a new value
                        filtered.unshift({
                          inputValue: params.inputValue,
                          legalName: `Setup "${params.inputValue}" as a new legal entity`,
                        });
                      }*/

                      if(params.inputValue !== '' && !activeCptyOrg.isNetwork && 
                      !filtered.some((f) => f.legalName.toLowerCase() === params.inputValue.toLowerCase()) &&
                      props.org !== undefined && props.org.clientType !== 'legalOrg') {
                        filtered.unshift({
                          inputValue: params.inputValue,
                          legalName: `Setup "${params.inputValue}" as a new legal entity`,
                        });
                      }                      
                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={cptyEntOptions || []}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') { return option; } // Value selected with enter, right from the input
                      if (option.inputValue) { return option.inputValue; } // Add "xxx" option created dynamically
                      return (option.legalName + // Regular option
                        (option.address !== undefined && option.address[0] !== undefined && option.address[0].country !== undefined ? " " + countryToFlag(option.address[0].country) : ''));
                    }}
                    renderOption={(option) => (
                      <Typography 
                        variant={option._id !== undefined ? "body1" : "subtitle1"}
                        color={"textPrimary"}>
                        {option.legalName + 
                        (option.address !== undefined && option.address[0] !== undefined && option.address[0].country !== undefined ? " " + countryToFlag(option.address[0].country) : '')} 
                      </Typography>
                    )}
                    style={{ width: '100%' }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField {...params} 
                      label={activeCptyEnt !== null && activeCptyEnt.entityCategory !== undefined && activeCptyEnt.entityCategory === 'Person' ? "Full legal name" : "Select legal entity"} 
                      variant="outlined" fullWidth />
                    )}
                  />

                </Box>
              </Grid>
              : '' }

              <Grid item xs={12} sm={8} md={6}>
                <Box mt={2}>
                <Button 
                  disableElevation
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={activeCptyOrg === null || activeCptyEnt === null}
                  onClick={handleNext}
                  >Next</Button>
                </Box>
              </Grid>

              </Box>
            }
            </Box>
            }
          </Grid>

        ) : ''}


        { activeStep === 1 ? (

          <Grid container direction="column" justify="flex-start" alignItems="stretch">

            <Box mt={0}>
              <Box align="center" mt={4}>

                <RenderCptyThumbs />

              </Box>

              {activeOwnEnt === undefined || activeOwnEnt === null || activeOwnEnt._id === undefined ?
              <Box mt={6}>
                <Typography align="center" variant="h6">Select your {props.org !== undefined && props.org.clientType === 'legalOrg' ? "client" : "own entity"} to continue&nbsp;&nbsp;
                <InformationalTooltip msg={"Select the appropriate legal entity for your company in this agreement. You can click the \"Select\" button to review the options.\n\nIf a certain legal entity is not yet setup then request your admin to create it."} /></Typography>
              </Box>
              : 
              <Box mt={6} align="center">

                <Box align="center" mb={1}>
                  <RenderTemplateOption type="main" />
                </Box>

                {loading ? 
                <Box mt={10} mb={10}><CircularProgress size={30} /></Box>
                :
                activeTemplateOption.main === 'company' && props.orgAgrTemplates.filter((oat) => oat.active && oat.module.includes(props.module)).length < 1 ?
                <Box align="center" mt={5} mb={5}>
                  <Typography>There are no company templates active yet for this module.</Typography>
                  {['Admin', 'Legal'].includes(props.user.role) ? 
                  <Box mt={3}><Button color="secondary" variant="text" component={RouterLink} to={"/core/legal/templates/" + props.module}>To Templates Module</Button></Box>
                  :
                  <Typography>Contact your Legal or Admin team to setup a template</Typography>
                  }
                </Box>
                :
                activeTemplateOption.main === 'company' ?
                <RenderThumbAgs type={"agr"} exclude={[]} />
                :
                <RenderUploadSelection type="main" />
                }

              </Box>
              }

            </Box>

          </Grid>

        ) : ''}

        { activeStep === 2 && (props.aid === undefined || props.aid === null || activeAgrs !== null) ? (

        <Grid container direction="column" justify="flex-start" alignItems="stretch">

          {// 3a. ie. first stage (before smart fields)
          !configuringSmartFields ?
          
          <Box mt={0}>

            <Box align="center" mt={0}>

              {addingExhibit ? 
              <Box mt={2}>
                <Typography align="center" variant="h6">
                  Add an exhibit to the agreement
                </Typography>

                <Box align="center" mt={4} mb={1}>
                  <RenderTemplateOption type="exhibit" />
                </Box>

                {activeTemplateOption.exhibit === 'company' ?
                
                  <RenderThumbAgs type="exh" exclude={
                    activeAgrs !== null ? activeAgrs.filter((a) => !['60bfc1ac6cf95aa0cae4cf16','60db7133ceba02d7da951b92'].includes(a.agrTypeID)) : 
                    agrsPush !== null ? agrsPush.filter((a) => !['60bfc1ac6cf95aa0cae4cf16','60db7133ceba02d7da951b92'].includes(a.atid)) : 
                    []} />

                :activeTemplateOption.exhibit === 'attachment' ?
                  
                  <Box mt={5} mb={5} style={{maxWidth: '400px'}}>
                  
                    <Box mb={2}>
                    <Typography align="center">Insert a PDF as an exhibit&nbsp;&nbsp;<InformationalTooltip msg={"Provide the title of the PDF attachment.\n\nYou can optionally upload the PDF now. You will be able to (re)upload a PDF during the negotiation stage."}/></Typography>
                    </Box>
                    <TextField
                      fullWidth
                      label="Exhibit Title"
                      variant="outlined"
                      value={attachment.title}
                      onChange={e => setAttachment({ ...attachment, title: e.target.value })}
                    />
                    <Box mt={2} mb={2} align="center">
                      {attachment.file !== undefined && attachment.file !== null ?
                      <>
                        <img src={pdf} className={classes.pdfIcon} />
                        <Button onClick={e => setAttachment({...attachment, file: null})}
                        color="primary" variant="text">Clear Upload</Button>
                      </>
                      :
                      <>
                      <input type="file" id="icon-button-file" hidden
                          accept=".pdf"
                          onChange={handleUploadChange} />
                      <label htmlFor="icon-button-file">
                          <IconButton component="span">
                              <FontAwesomeIcon icon={faFilePdf} className={classes.uploadPDF} />
                          </IconButton>
                      </label>
                      </>
                      }
                    </Box>
                    <Button onClick={e => handleExhibitSelect(null, null, true)}
                      color="primary" variant="contained" 
                      disabled={attachment === undefined || attachment === null || attachment.title === undefined || attachment.title === null || attachment.title === ''}
                      disableElevation fullWidth>
                      Next&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} />
                    </Button>

                  </Box>

                : // Otherwise show the import
                  <RenderUploadSelection type="exhibit" />
                }

              </Box>
              :
              <>
              <Box align="center" mt={4} mb={5}>

                <RenderCptyThumbs />

              </Box>
              {// There are already existing similar agreements
              activeAgr === null && agrsPush !== null && agrsPush.filter((ap) => ap.isMain)[0] !== undefined && agrsPush.filter((ap) => ap.isMain)[0].existing.length > 0 ?
              <Box mb={5} mt={1}>
              <Typography variant="body2"><FontAwesomeIcon icon={faInfoCircle} className={classes.warnIcon} />Existing similar agreements:<br/><br/>
                {agrsPush.filter((ap) => ap.isMain)[0].existing.map((ex) => (
                    <span key={ex._id} style={{display: 'block'}}>
                      {ex.agrShortName + " between "}
                      <span className={activeOwnEnt._id === ex.primaryCptyID || activeCptyEnt._id === ex.primaryCptyID ? classes.sameEntity : classes.diffEntity}>
                        {props.subs.filter((s) => s._id === ex.primaryCptyID)[0] !== undefined ? 
                            props.subs.filter((s) => s._id === ex.primaryCptyID)[0].legalName :
                         props.cpents.filter((c) => c._id === ex.primaryCptyID)[0] !== undefined ? 
                            props.cpents.filter((c) => c._id === ex.primaryCptyID)[0].legalName : "Unknown"}
                      </span>
                      {" and "} 
                      <span className={activeOwnEnt._id === ex.secondaryCptyID || activeCptyEnt._id === ex.secondaryCptyID ? classes.sameEntity : classes.diffEntity}>
                        {props.subs.filter((s) => s._id === ex.secondaryCptyID)[0] !== undefined ? 
                            props.subs.filter((s) => s._id === ex.secondaryCptyID)[0].legalName :
                         props.cpents.filter((c) => c._id === ex.secondaryCptyID)[0] !== undefined ? 
                            props.cpents.filter((c) => c._id === ex.secondaryCptyID)[0].legalName : "Unknown"}
                      </span>
                      {' (' + (ex.agrStatus === "InForce" ? "In Effect" : ex.agrStatus) + ')'}
                    </span>
                ))}
              </Typography>
              </Box>
              : // It is a new relationship for a non-CT Subscriber / counterparty - ask the login setting
              activeAgr === null && activeRel === null && activeCptyEnt !== null && activeCptyEnt.entityType !== 'Subsidiary' ?
              <Box mb={4} mt={2} align="center">

                <Box align="left" style={{maxWidth: '390px'}}>

                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={isCustomURL} onChange={e => setIsCustomURL(!isCustomURL)} name="isCustomURL" />}
                      label={
                        <Typography>
                          Access via a unique individualized link (url)&nbsp;&nbsp;<InformationalTooltip msg={"Decide how your " + cptyType + " will access the agreement(s) for this relationship.\n\nEnabling this checkbox allows users to access via a link that is sent to them via email, the link is tokenized and tailored to each specific user and agreement.\n\nIf not enabled, then the user will be required to create a password and login.\n\nA unique link will be more user-friendly while a username and password will be more secure."} />
                        </Typography>}
                    />
                    {isWhiteLabeled !== null ?
                      <FormControlLabel
                        control={<Checkbox checked={isWhiteLabeled} onChange={e => setIsWhiteLabeled(!isWhiteLabeled)} name="isWhiteLabeled" />}
                        label={
                          <Typography>
                            Enable company branding for the relationship&nbsp;&nbsp;<InformationalTooltip msg={"Decide whether you want to enable company branding (\"white labeling\") for this relationship.\n\nEnabling this checkbox will ensure that emails and (depending on your company setting) the Canveo screens accessible for your counterparty will hold your brand configuration (including your logo and your accent color).\n\nIf not enabled, then the default Canveo logo and styling will be applied."} />
                          </Typography>}
                      />
                    :''}
                    
                  </FormGroup>
                </FormControl>


                  {/*
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      <Typography variant="subtitle1" color="textPrimary" gutterBottom>How {cptyType} users can access the relationship:&nbsp;&nbsp;<InformationalTooltip msg={"Decide how your " + cptyType + " will access the agreement(s) for this relationship.\n\nA unique link will be more user-friendly while a username and password will be more secure.\n\nNote that the unique link is tokenized and tailored to each specific user and agreement."} /></Typography></FormLabel>
                    <RadioGroup name="loginType" value={loginType} onChange={e => setLoginType(e.target.value)}>
                      <FormControlLabel value="password" control={<Radio />} 
                        label={<Typography variant="body1">Only after creating a username / password</Typography>} />
                      <FormControlLabel value="customURL" control={<Radio />} 
                        label={<Typography variant="body1">Through a unique and individualized link (url)</Typography>} />
                    </RadioGroup>
                  </FormControl>
                  */}
                </Box>

              </Box>
              : 
              '' 
              }

              <AgreementStructure
                agrShortName={
                  props.agrTypes.filter((at) => agrsPush.filter((ap) => ap.isMain)[0] !== undefined && at._id === agrsPush.filter((ap) => ap.isMain)[0].atid)[0] !== undefined ?
                    props.agrTypes.filter((at) => agrsPush.filter((ap) => ap.isMain)[0] !== undefined && at._id === agrsPush.filter((ap) => ap.isMain)[0].atid)[0].shortName :
                  activeAgr === null ? agrsPush.filter((ap) => ap.isMain)[0].shortName : activeAgr.agrShortName}
                agrFullName={
                  props.agrTypes.filter((at) => agrsPush.filter((ap) => ap.isMain)[0] !== undefined && at._id === agrsPush.filter((ap) => ap.isMain)[0].atid)[0] !== undefined ?
                    props.agrTypes.filter((at) => agrsPush.filter((ap) => ap.isMain)[0] !== undefined && at._id === agrsPush.filter((ap) => ap.isMain)[0].atid)[0].fullName[0] :
                  activeAgr === null ? agrsPush.filter((ap) => ap.isMain)[0].fullName : activeAgr.agrFullName}
                activeAgrs={
                  //orderedExhibits !== null && activeAgrs !== null ?
                    //activeAgrs.concat(orderedExhibits)
                  orderedExhibits !== null ?
                    orderedExhibits : 
                  agrsPush.filter((ap) => !ap.isMain).length > 0 && activeAgrs !== null && activeAgrs.length > 0 ? 
                    activeAgrs.concat(agrsPush.filter((ap) => !ap.isMain)) :
                  agrsPush.filter((ap) => !ap.isMain).length > 0 ? 
                    agrsPush.filter((ap) => !ap.isMain) :
                    activeAgrs !== null && activeAgrs.length > 0 ? activeAgrs : []}
                reordering={false}
                parentCallToClose={null}
                parentCallToConfirm={handleNewExhibitOrder}
                parentCallToRemove={handleExhibitRemove}
              />

              {activeAgr === null || agrsPush.filter((ap) => !ap.isMain).length < 1 ?
              <Box mt={2} mb={4}>
                <Button className={classes.exButton} onClick={e=> setAddingExhibit(true)}>
                  <FontAwesomeIcon icon={faPlus} className={classes.plusIcon} />Add Exhibit</Button>
              </Box>
              : '' }

              <Grid item xs={12} sm={8} md={6}>
                <Box mt={activeAgr === null ? 2 : 5}>
                <Button 
                  disableElevation
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={activeCptyOrg === null || activeCptyEnt === null || (activeAgr !== null && agrsPush.filter((ap) => ap.isNewAfterTheFact).length === 0)}
                  onClick={!configuringSmartFields && smartFields.filter((sf) => sf.includeInWizard).length > 0 ? toggleConfiguringSmartFields :
                    activeAgr === null ? handleCreateAgreement : handleCreateExhibit}
                  >{!configuringSmartFields && smartFields.filter((sf) => sf.includeInWizard).length > 0 ? "Proceed to Smart Fields" :
                  activeAgr === null ? "Create Agreement" : "Add exhibit to agreement"}</Button>
                </Box>
              </Grid>
            </>
            }
            </Box>
          </Box>
          :
          // 3b. ie. second stage (the smart fields)
          configuringSmartFields ?
          <Box mt={0} align="center">

            <Box align="left" className={classes.boxResizing2}>
            <Stepper activeStep={activeSFStep} orientation="vertical" nonLinear>
            {orderSmartFields(smartFields.filter((sf) => sf.includeInWizard)).map((sf, i) => (
              <Step key={sf._id}>
                <StepButton onClick={e => handleStepSF(i)} completed={completedSF[i]}>
                <Typography variant="h6" className={classes.pl1} align="left">
                  {sf.name !== '' ? sf.name : '(Name undefined)'}
                  {smartfieldValueValidation(sf.type, sf.val1, sf.val2) ? "" : <FontAwesomeIcon icon={faExclamationTriangle} className={activeSFStep === i ? classes.sfErrorIcon : classes.sfWarningIcon} />}
                </Typography>
                </StepButton>
                <StepContent>
                <Box pl={2} pt={2} pb={2} pr={2}>
                  {// SmartField Question
                  sf.question !== '' ?
                  <Box mt={1} mb={1}><Typography variant="subtitle1" className={classes.bold}>{sf.question}</Typography></Box>
                  : ''}

                  {// SmartField Question
                  sf.guidance !== '' ?
                  <Box><Typography variant="body1" color="textSecondary">{sf.guidance.split('\n').map((g, i) => ( <React.Fragment key={i}>{g}<br/></React.Fragment> ))}</Typography></Box>
                  : ''}

                  <Box mt={sf.question !== '' || sf.guidance !== '' ? 2 : 1}>
                    <SmartFieldEditable
                      fromClause={false}
                      sfid={sf._id}
                      type={sf.type}
                      val1={sf.val1}
                      val2={sf.val2}
                      onFieldChange={handleSmartFieldChange}
                    />
                  </Box>

                  <Box mt={2} mb={1}>
                    <Button
                      disableElevation
                      variant="contained"
                      disabled={activeSFStep === 0}
                      onClick={handleBackSF}
                      className={classes.cancelButton}
                    >
                      Back
                    </Button>
                    <Button
                      disableElevation
                      variant="contained"
                      color="primary"
                      disabled={ // You're at the last smartfield and there is an invalid one
                        ((activeSFStep === smartFields.filter((sf) => sf.includeInWizard).length - 1 && activeAgr === null) ||
                         (activeSFStep === smartFields.filter((sf) => sf.includeInWizard).length - 1 && activeAgr !== null)) &&
                         !allSmartFieldsAreValid(smartFields.filter((sf) => sf.includeInWizard))
                      }
                      onClick={
                        activeSFStep === smartFields.filter((sf) => sf.includeInWizard).length - 1 && activeAgr === null ?
                            handleCreateAgreement :
                        activeSFStep === smartFields.filter((sf) => sf.includeInWizard).length - 1 && activeAgr !== null ?
                            handleCreateExhibit :
                            handleNextSF}
                    >
                      {activeSFStep === smartFields.filter((sf) => sf.includeInWizard).length - 1 && activeAgr === null ?
                          'Create Agreement' : 
                      activeSFStep === smartFields.filter((sf) => sf.includeInWizard).length - 1 && activeAgr !== null ?
                          "Add exhibit to agreement" :
                          'Next'}
                    </Button>
                  </Box>
                </Box>
                </StepContent>
              </Step>
            ))}
            </Stepper>
            </Box>

          </Box>
          :''}
        </Grid>

        ) : ''}

        </Grid>

      </Container>

      <DialogImportDoc
        importOpen={importOpen}
        handleImportClose={handleImportClose}
        handleFinalizeImport={handleFinalizeImport}
        agrTypes={props.agrTypes}
        relType={relationshipType}
        orgAgrTemplates={[]}
        org={props.org}
        user={props.user}
        relevantSection={""}
        action={"new-agr"}
      />

    </div>
  );
}

export default NewRelationship;
