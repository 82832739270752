import React from 'react';
import theme from '../../theme/theme';
import { taxes } from '../../assets/static/taxes';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Grid, InputAdornment, FormControl, FormControlLabel, 
         RadioGroup, Radio, TextField, makeStyles, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { countries } from '../../assets/static/countries';
import { ErrorMsg } from '../';
import { getTaxTypesByCountry } from '../../utils/getTaxTypesByCountry';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faCity, faSign, faLandmark, faMapSigns, faRoad, faUser, 
         faEnvelope, faHashtag, faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";

const axios = require('axios');

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

const EntityForm = (props) => {

  const useStyles = makeStyles(theme => ({
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    showLink: {
      margin: '-10px 0px -5px 0px',
      fontWeight: '600',
      color: theme.palette.primary.main,
      '&:hover' : {
        cursor: 'pointer',
        color: theme.palette.secondary.main,
      }
    },
    mapIcon: {
      color: theme.palette.secondary.main,
      marginRight: '12px',
    }
  }));
  const classes = useStyles();

  const [country, setCountry] = React.useState(countries.filter((c) => c.code === props.initialValues.country)[0] !== undefined ? countries.filter((c) => c.code === props.initialValues.country)[0] : null)
  const [taxids, setTaxIDs] = React.useState(props.initialValues.taxids !== undefined && props.initialValues.taxids !== null && props.initialValues.taxids.length > 0 ? props.initialValues.taxids : [])
  const [countryError, setCountryError] = React.useState('');
  const [userError, setUserError] = React.useState('');
  const [entityCategory, setEntityCategory] = React.useState(props.initialValues.entityCategory !== undefined ? props.initialValues.entityCategory : 'Company');
  const [hideAddress2, setHideAddress2] = React.useState(props.initialValues.address2 !== undefined && props.initialValues.address2 !== null && props.initialValues.address2 !== '' ? false : true);

  const handleCategoryChange = (event) => {
    setEntityCategory(event.target.value);
  };

  const handleCountryChange = (value) => {
    setTaxIDs([]) // clear the tax ids
    setCountry(value);
  }

  const handleTaxIDsChange = (type, value) => {

    let tids = taxids
    if(tids.filter((t) => t.type === type)[0] === undefined) {
      tids.push({type: type, value: value});
    } else {
      let idx = tids.findIndex((t) => t.type === type)
      tids[idx] = {type: type, value: value};
    }

    setTaxIDs([...tids])
  }

  const handleTaxIDEval = (type, value) => {
    let newVal = value;
    newVal = newVal.toUpperCase() // alpha to Upper
    newVal = newVal.replace(/\s/g, '') // replace any spaces
    if(taxes.filter((t) => t.type === type)[0] !== undefined) {
      let taxFormat = taxes.filter((t) => t.type === type)[0].ex
      if(taxFormat.match(/^([A-Z]{2})/) && !newVal.match(/^([A-Z]{2})/) && taxFormat.length - 2 === newVal.length) {
        newVal = taxFormat.substr(0,2) + newVal;
      }
    }

    if(newVal !== value) {
      let tids = taxids
      let idx = tids.findIndex((t) => t.type === type)
      tids[idx] = {type: type, value: newVal};
      setTaxIDs([...tids])
    }
  }

  let valSchema = Yup.object({
    shortName: Yup.string()
      .min(2, 'The company name is too short')
      .max(40, 'The company name is too long')
      .required('Company name is required'),
    legalName: Yup.string()
      .min(4, 'The legal name is too short')
      .max(50, 'The legal name is too long')
      .required('Legal name is required'),
    streetAndNo: Yup.string()
      .min(4, 'The address line is too short')
      .max(50, 'The address is too long') // c/o F10 FinTech Incubator and Accelerator, Förrlibuckstrasse 10
      .required('Address is required'),
    address2: Yup.string()
      .min(3, 'The address line is too short')
      .max(50, 'The address is too long'),
    city: Yup.string()
      .min(3, 'The city is too short')
      .max(40, 'The city is too long')
      .required('City is required'),
    zip: Yup.string()
      .min(3, 'The ZIP is too short')
      .max(15, 'The ZIP is too long')
      .required('ZIP is required'),
    state: Yup.string()
      .min(1, 'The State is too short')
      .max(30, 'The State is too long')
  })
  if(entityCategory === 'Person') { 
    valSchema = Yup.object({
      streetAndNo: Yup.string()
        .min(4, 'The address line is too short')
        .max(50, 'The address is too long') // c/o F10 FinTech Incubator and Accelerator, Förrlibuckstrasse 10
        .required('Address is required'),
      address2: Yup.string()
        .min(3, 'The address line is too short')
        .max(50, 'The address is too long'),
      city: Yup.string()
        .min(3, 'The city is too short')
        .max(40, 'The city is too long')
        .required('City is required'),
      zip: Yup.string()
        .min(3, 'The ZIP is too short')
        .max(15, 'The ZIP is too long')
        .required('ZIP is required'),
      state: Yup.string()
        .min(1, 'The State is too short')
        .max(30, 'The State is too long'),
      firstName:  Yup.string()
        .min(2, 'The first name is too short')
        .max(35, 'The first name is too long')
        .required('First name is required'),
      lastName: Yup.string()
        .min(2, 'The last name is too short')
        .max(40, 'The last name is too long')
        .required('Last name is required'),
      email:  Yup.string()
        .email('Invalid email address')
        .required('Email address is required'),
      title: Yup.string()
        .min(2, 'The title is too short')
        .max(40, 'The title is too long')
        .required('A title is required'),
      })
  }

  const formik = useFormik({
    initialValues: {
      entityCategory: entityCategory,
      shortName: props.initialValues.shortName,
      legalName: props.initialValues.legalName,
      streetAndNo: props.initialValues.streetAndNo,
      address2: props.initialValues.address2,
      city: props.initialValues.city,
      zip: props.initialValues.zip,
      state: props.initialValues.state,
      firstName: props.initialValues.firstName,
      lastName: props.initialValues.lastName,
      email: props.initialValues.email,
      title: props.initialValues.title,
    },
    validationSchema: valSchema,
    onSubmit: values => {
      setCountryError('');
      setUserError('');
      if(country !== null && country.code !== undefined && country.code.length === 2){
        values.country = country.code
        values.taxids = taxids
        values.entityCategory = entityCategory
        if(entityCategory === "Person" && ['newAgr'].includes(props.type)) { // First check that the email address not already exists

          axios.get('/user/protect/user/mail/' + values.email)
          .then(function(res) {

            if(res.data.data !== undefined && res.data.data !== null && res.data.data.role === 'Counterparty') {
              setUserError("This user is already setup for a different organisation, please contact canveo support for help.")
            } else if (res.data.data !== undefined && res.data.data !== null) {
              setUserError("This user is setup for a Canveo customer, please contact canveo support for help.")
            } else { // User not found -

              props.parentCallback(values);

            }

          }).catch(function(err) { console.log(err) }) 

        } else {
          props.parentCallback(values);
        }
      } else {
        setCountryError('Please select a country');
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
    <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
      {userError !== '' ?
      (
      <Grid item container xs={12} sm={8} md={6}>
        <Box mt={2} mb={2} align="center">
          <ErrorMsg msg={userError} />
        </Box>
      </Grid>
      )
      :
      countryError !== '' ?
      (
      <Grid item container xs={12} sm={8} md={6}>
        <Box mt={2} mb={2} align="center">
          <ErrorMsg msg={countryError} />
        </Box>
      </Grid>
      ) 
      :''}
      {!['new', 'new-client', 'update', 'update-client','updatecp','signup'].includes(props.type) && !props.editEntity && !props.hideRadioCategory ?
      <Grid item container xs={12} sm={8} md={6}>
        <Box align="center" width="100%">
        <FormControl component="fieldset">
          <RadioGroup row name="entityCategory" value={entityCategory} onChange={handleCategoryChange}>
          <FormControlLabel value="Company" control={<Radio color="primary" />} label="Company" />
            <FormControlLabel value="Person" control={<Radio color="primary" />} label="Person" />
          </RadioGroup>
        </FormControl>
        </Box>
      </Grid>
      : '' }
      {entityCategory === 'Person' ?
      <>
      <Grid item container xs={12} sm={8} md={6}>
        <Grid item container xs={6} style={{paddingRight: '20px'}}>
          <TextField
              required
              disabled={props.type === 'disabled'}
              style={{backgroundColor: theme.palette.primary.contrastText}}
              fullWidth
              variant="outlined"
              id="firstName"
              label={"First Name(s)"}
              placeholder={"e.g. Jonathan Robert"}
              name="firstName"
              autoComplete="firstName"
              {...formik.getFieldProps('firstName')}
              error={formik.touched.firstName && formik.errors.firstName ? true : false}
              helperText={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : ''}
              InputProps={{
                  startAdornment: (
                  <InputAdornment position="start">
                      <FontAwesomeIcon icon={faUser} color={theme.palette.grey[600]} />
                  </InputAdornment>
                  ),
              }}
          />
        </Grid>
        <Grid item container xs={6}>
          <TextField
            required
            disabled={props.type === 'disabled'}
            style={{backgroundColor: theme.palette.primary.contrastText}}
            fullWidth
            variant="outlined"
            id="lastName"
            label={"Last Name"}
            placeholder={"e.g. Williams"}
            name="lastName"
            autoComplete="lastName"
            {...formik.getFieldProps('lastName')}
            error={formik.touched.lastName && formik.errors.lastName ? true : false}
            helperText={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : ''}
          />
        </Grid>
      </Grid>
      </>
      :!['billing','new', 'update', 'disabled'].includes(props.type) ?
      <Grid item container xs={12} sm={8} md={6}>
        <TextField
            required
            disabled={props.type === 'disabled'}
            style={{backgroundColor: theme.palette.primary.contrastText}}
            fullWidth
            variant="outlined"
            id="shortName"
            label={['newAgr','signup'].includes(props.type) && entityCategory === 'Company' ? "Company Name" : "Entity Name"}
            placeholder={['newAgr','signup'].includes(props.type) && entityCategory === 'Company' ? "Company name" : "Short name (e.g. ABC US)"}
            name="shortName"
            autoComplete="sname"
            {...formik.getFieldProps('shortName')}
            error={formik.touched.shortName && formik.errors.shortName ? true : false}
            helperText={formik.touched.shortName && formik.errors.shortName ? formik.errors.shortName : ''}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <FontAwesomeIcon icon={faBuilding} color={theme.palette.grey[600]} />
                </InputAdornment>
                ),
            }}
        />
      </Grid>
      :''}
      {entityCategory !== 'Person' && props.type !== 'billing' ?
      <Grid item container xs={12} sm={8} md={6}>
        <TextField
            required
            disabled={props.type === 'disabled'}
            fullWidth
            variant="outlined"
            id="legalName"
            label={"Legal Name"}
            placeholder={"Legal entity name" + (['newAgr','signup'].includes(props.type) ? "" : " (e.g. ABC LLC)")}
            name="legalName"
            autoComplete="legalname"
            {...formik.getFieldProps('legalName')}
            error={formik.touched.legalName && formik.errors.legalName ? true : false}
            helperText={formik.touched.legalName && formik.errors.legalName ? formik.errors.legalName : ''}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <FontAwesomeIcon icon={faLandmark} color={theme.palette.grey[600]} />
                </InputAdornment>
                ),
            }}     
        />
      </Grid>
      :props.type !== 'billing' ?
      <>
      <Grid item container xs={12} sm={8} md={6}>
        <TextField
            required
            disabled={['disabled', 'update', 'update-client', 'updatecp'].includes(props.type) || props.editEntity}
            fullWidth
            variant="outlined"
            id="email"
            label={"Email address"}
            placeholder={"name@domain.com"}
            name="email"
            autoComplete="email"
            {...formik.getFieldProps('email')}
            error={formik.touched.email && formik.errors.email ? true : false}
            helperText={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <FontAwesomeIcon icon={faEnvelope} color={theme.palette.grey[600]} />
                </InputAdornment>
                ),
            }}     
        />
      </Grid>
      <Grid item container xs={12} sm={8} md={6}>
      <TextField
          required
          disabled={props.type === 'disabled'}
          fullWidth
          variant="outlined"
          id="title"
          label={"Title"}
          placeholder={"Corporate title"}
          name="title"
          autoComplete="title"
          {...formik.getFieldProps('title')}
          error={formik.touched.title && formik.errors.title ? true : false}
          helperText={formik.touched.title && formik.errors.title ? formik.errors.title : ''}
          InputProps={{
              startAdornment: (
              <InputAdornment position="start">
                  <FontAwesomeIcon icon={faLandmark} color={theme.palette.grey[600]} />
              </InputAdornment>
              ),
          }}     
      />
      </Grid>
      </>
      : ''}

      <Grid item container xs={12} sm={8} md={6}>
        <TextField
            required
            disabled={props.type === 'disabled'}
            fullWidth
            variant="outlined"
            id="streetAndNo"
            label="Address Line 1"
            placeholder="Street and number"
            name="streetAndNo"
            autoComplete="streetAndNo"
            {...formik.getFieldProps('streetAndNo')}
            error={formik.touched.streetAndNo && formik.errors.streetAndNo ? true : false}
            helperText={formik.touched.streetAndNo && formik.errors.streetAndNo ? formik.errors.streetAndNo : ''}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <FontAwesomeIcon icon={faRoad} color={theme.palette.grey[600]} />
                </InputAdornment>
                ),
            }}     
        />
      </Grid>
      <Grid item container xs={12} sm={8} md={6}>
        {hideAddress2 ? 
        <Box width="100%">
          <Typography variant="body2" align="right" onClick={e => setHideAddress2(false)} className={classes.showLink}>+ Address line 2</Typography>
        </Box>
        :
        <TextField
            disabled={props.type === 'disabled'}
            fullWidth
            variant="outlined"
            id="address2"
            label="Address Line 2"
            placeholder="Address line 2"
            name="address2"
            autoComplete="address2"
            {...formik.getFieldProps('address2')}
            error={formik.touched.address2 && formik.errors.address2 ? true : false}
            helperText={formik.touched.address2 && formik.errors.address2 ? formik.errors.address2 : ''}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <FontAwesomeIcon icon={faSign} color={theme.palette.grey[600]} />
                </InputAdornment>
                ),
            }}     
        />
        }
      </Grid>
      <Grid item container xs={12} sm={8} md={6}>
        <TextField
            required
            disabled={props.type === 'disabled'}
            fullWidth
            variant="outlined"
            id="city"
            label="City"
            placeholder="City"
            name="city"
            autoComplete="city"
            {...formik.getFieldProps('city')}
            error={formik.touched.city && formik.errors.city ? true : false}
            helperText={formik.touched.city && formik.errors.city ? formik.errors.city : ''}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <FontAwesomeIcon icon={faCity} color={theme.palette.grey[600]} />
                </InputAdornment>
                ),
            }}     
        />
      </Grid>
      <Grid item container xs={12} sm={8} md={6}>
        <Grid direction="row" container>
          <Grid item xs={6} style={{paddingRight: '8px'}}>
            <TextField
              required
              disabled={props.type === 'disabled'}
              fullWidth
              variant="outlined"
              id="zip"
              label="ZIP code"
              placeholder="ZIP code"
              name="zip"
              autoComplete="zip"
              {...formik.getFieldProps('zip')}
              error={formik.touched.zip && formik.errors.zip ? true : false}
              helperText={formik.touched.zip && formik.errors.zip ? formik.errors.zip : ''}
              InputProps={{
                  startAdornment: (
                  <InputAdornment position="start">
                      <FontAwesomeIcon icon={faMapSigns} color={theme.palette.grey[600]} />
                  </InputAdornment>
                  ),
              }}     
            />
          </Grid>
          <Grid item xs={6} style={{paddingLeft: '8px'}}>
            <TextField
              disabled={props.type === 'disabled'}
              fullWidth
              variant="outlined"
              id="state"
              label="State/Province"
              placeholder="State / Province / Region"
              name="state"
              autoComplete="state"
              {...formik.getFieldProps('state')}
              error={formik.touched.state && formik.errors.state ? true : false}
              helperText={formik.touched.state && formik.errors.state ? formik.errors.state : ''}
              InputProps={{
                  startAdornment: (
                  <InputAdornment position="start">
                      <FontAwesomeIcon icon={faMapMarkedAlt} color={theme.palette.grey[600]} />
                  </InputAdornment>
                  ),
              }}     
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={8} md={6}>

        <Autocomplete
          disabled={props.type === 'disabled'}
          style={{ width: '100%' }}
          options={countries}
          classes={{
            option: classes.option,
          }}
          value={country}
          onChange={(e, value) => handleCountryChange(value)}
          autoHighlight
          getOptionLabel={(option) => (option.label !== undefined ? option.label : '')}
          renderOption={(option) => (
            <React.Fragment>
              <span>{countryToFlag(option.code)}</span>
              {option.label} ({option.code})
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField {...params} 
            label={"Country"}
            variant="outlined"
            inputProps={{ // to avoid autosuggest leakage, include this in the text field and remove "id" or make it non-obvious
              ...params.inputProps,
              autoComplete: 'new-password',
            }} />
          )}
        />

      </Grid>

      {(['signup'].includes(props.type) || props.type === 'billing') && country !== null && getTaxTypesByCountry(country.code).length > 0 ?
      
        getTaxTypesByCountry(country.code).map((tt,i) => {
          return (
            <Grid item container xs={12} sm={8} md={6} key={tt.type}>
              <TextField
                  fullWidth
                  variant="outlined"
                  value={taxids.filter((tid) => tid.type === tt.type)[0] !== undefined ? taxids.filter((tid) => tid.type === tt.type)[0].value : ''}
                  onChange={(e) => handleTaxIDsChange(tt.type, e.target.value)}
                  onBlur={(e) => handleTaxIDEval(tt.type, e.target.value)}
                  label={tt.type.substring(3).toUpperCase() + " ID"}
                  placeholder={"Tax number (optional)"}
                  helperText={"Format as: " + tt.ex}
                  InputProps={{
                      startAdornment: (
                      <InputAdornment position="start">
                          <FontAwesomeIcon icon={faHashtag} color={theme.palette.grey[600]} />
                      </InputAdornment>
                      ),
                  }}
              />
          </Grid>
          );
        })

      :''}
      <Grid item container xs={12} sm={8} md={6}>
        <Button
          fullWidth
          disableElevation
          type={"submit"}
          variant="contained"
          color="primary"
          disabled={props.submitting ||
          //countryError !== '' || userError !== '' || // uncommenting will lock the form after the error comes up
          (['newAgr','signup'].includes(props.type) && 
          ((entityCategory === 'Person' && (
            formik.values.firstName === '' ||
            formik.values.lastName === '' ||
            formik.values.email === '' ||
            formik.values.streetAndNo === '' ||
            formik.values.city === '' ||
            formik.values.zip === '' ||
            country === '' || country === null
           )) 
           ||
           (entityCategory !== 'Person' && (
            formik.values.shortName === '' ||
            formik.values.legalName === '' ||
            formik.values.streetAndNo === '' ||
            formik.values.city === '' ||
            formik.values.zip === '' ||
            country === '' || country === null))))}
          >{['billing'].includes(props.type) ? "Update Billing Details" :
            ['newAgr', 'signup'].includes(props.type) ? "Next" : "Submit"}
        </Button>
      </Grid>
    </Grid>

    </form>
  );
};

export default EntityForm
