import * as ActionTypes from '../ActionTypes';

const axios = require('axios');

// SUBSIDIARIES

export function fetchSubsByOrgID(orgid, isCptyAccount) {
  return dispatch => {
      dispatch(fetchSubsPending());
      axios.get('/entity/' + (isCptyAccount? 'protect/subsforparent/' : 'subsidiaries/' ) + orgid)
      .then(function(res) {
        dispatch(fetchSubsSuccess(res.data.data));
      })
      .catch(function(err) {
        dispatch(fetchSubsError(err));
      })
  }
}

export const fetchSubsPending = () => ({
    type: ActionTypes.FETCH_SUBS_PENDING
});

export const fetchSubsError = (error) => ({
    type: ActionTypes.FETCH_SUBS_ERROR,
    payload: error
});

export const fetchSubsSuccess = (entities) => ({
    type: ActionTypes.FETCH_SUBS_SUCCESS,
    payload: entities
});

export const addSubs = (entity) => ({
  type: ActionTypes.ADD_SUBS,
  payload: entity
});

export const removeSubs = (entid) => ({
  type: ActionTypes.REMOVE_SUBS,
  payload: entid
});


// CPTY ENTITIES
export function fetchCpentsByOrgID(orgid) {
  return dispatch => {
    dispatch(fetchCpentsPending());
    axios.get('/entity/protect/cpents/' + orgid) // get all counterparties subscriber & non-subscriber
    .then(function(res) {

      dispatch(fetchCpentsSuccess(res.data.data));

      /*
      let nonSubscriberCpents = res.data.data;
      axios.get('/rel/rels/' + orgid)
      .then(function(resrel) {
        let relsToSubscribers = resrel.data.data.filter((rel) => nonSubscriberCpents.every((c) => c.parentOrgID !== rel.primaryOrgID && c.parentOrgID !== rel.secondaryOrgID))
        if(relsToSubscribers.length === 0) { dispatch(fetchCpentsSuccess(nonSubscriberCpents)); }
        else {
          let relCounter = 0;
          let subscriberCpents = [] // an empty temp array for CT subscribers as partners
          relsToSubscribers.forEach((rel) => {
            // Get entities for counterparty ORG
            axios.get('/entity/subsidiaries/' + (rel.primaryOrgID === orgid ? rel.secondaryOrgID : rel.primaryOrgID))
            .then(function(ressubs) {
              relCounter = relCounter + 1;
              subscriberCpents = subscriberCpents.concat(ressubs.data.data)
              if(relsToSubscribers.length === relCounter) {
                dispatch(fetchCpentsSuccess(nonSubscriberCpents.concat(subscriberCpents)));
              }
            })
            .catch(function(err) { dispatch(fetchCpentsError(err)); })
          })
        }
      })
      .catch(function(err) { dispatch(fetchCpentsError(err)); })*/
    })
    .catch(function(err) {
      dispatch(fetchCpentsError(err));
    })
  }
}

export const fetchCpentsPending = () => ({
    type: ActionTypes.FETCH_CPENTS_PENDING
});

export const fetchCpentsError = (error) => ({
    type: ActionTypes.FETCH_CPENTS_ERROR,
    payload: error
});

export const fetchCpentsSuccess = (entities) => ({
    type: ActionTypes.FETCH_CPENTS_SUCCESS,
    payload: entities
});

export const addCpent = (entity) => ({
  type: ActionTypes.ADD_CPENT,
  payload: entity
});

export const removeCpent = (entid) => ({
  type: ActionTypes.REMOVE_CPENT,
  payload: entid
});

// OTHER FUNCTIONS

export const updateEntity = (entid, orgid, parentOrgID, entityType, shortName, legalName, address, 
                             logoURL, primaryContact, signatory, isLive, lastUpdateBy) => {
  // address => [{addressType, streetAndNo, zip, city, state, country}]                               
  
  return dispatch => {
    if(['Subsidiary', 'LegalClient'].includes(entityType)) { dispatch(fetchSubsPending()); } 
    else { dispatch(fetchCpentsPending()); }
    
    let lastUpdateDate = new Date().toISOString();
    axios.put('/entity/protect/entity/' + entid, {
      parentOrgID: parentOrgID,
      shortName: shortName,
      legalName: legalName,
      address: address,
      logoURL: logoURL,
      primaryContact: primaryContact,
      signatory: signatory,
      isLive: isLive,
      lastUpdateBy: lastUpdateBy,
      lastUpdateDate: lastUpdateDate,
    })
    .then(function (res) { // success
      if(['Subsidiary', 'LegalClient'].includes(entityType)) { dispatch(fetchSubsByOrgID(orgid)) } 
      else { dispatch(fetchCpentsByOrgID(orgid)) }
      
    })
    .catch(function (err) { // err
      if(['Subsidiary', 'LegalClient'].includes(entityType)) { dispatch(fetchSubsError(err)) } 
      else { dispatch(fetchCpentsError(err)) }
    })       
  }
};

export const addEntityToReducer = (entityType, entity) => {
  return dispatch => {
    dispatch(addCpent(entity))
  }
}

export const updateEntityInReducer = (entityType, entity) => {
  return dispatch => {
    if(!['Subsidiary', 'LegalClient'].includes(entityType)) {
      dispatch(updCpent(entity));
    }
  }
}

export const updCpent = (ent) => ({
  type: ActionTypes.UPDATE_CPENT,
  payload: ent
});

export const createEntity = (orgid, parentOrgID, entityType, entityCategory, shortName, legalName, address,
                             logoURL, primaryContact, signatory, sourceData, creationBy, faf) => {
  // address => [{addressType, streetAndNo, zip, city, state, country}]
  return dispatch => {
    
    let creationDate = new Date().toISOString();
    axios.post('/entity/protect/entity', {
      orgID: orgid, 
      parentOrgID: parentOrgID,
      entityType: entityType,
      entityCategory: entityCategory,
      shortName: shortName, 
      legalName: legalName, 
      address: address, 
      logoURL: logoURL,
      primaryContact: primaryContact,
      signatory: signatory,
      isLive: true,
      sourceData: sourceData,
      creationBy: creationBy,
      creationDate: creationDate,
      lastUpdateBy: creationBy,
      lastUpdateDate: creationDate,
    })
    .then(function (res) { // got a msg from the server
      if(res.data.success && !faf) { // successfully added
        if(!['Subsidiary', 'LegalClient'].includes(entityType)) { dispatch(addCpent(res.data.data)) }
        else { dispatch(addSubs(res.data.data)) }
      } else if(!faf) { // something went wrong while adding
        if(!['Subsidiary', 'LegalClient'].includes(entityType)) { dispatch(fetchCpentsError(res.message)) }
        else { dispatch(fetchSubsError(res.message)) }
      }
    })
    .catch(function (err) { // err in the server request
      if(!['Subsidiary', 'LegalClient'].includes(entityType) && !faf) { dispatch(fetchCpentsError(err)) }
      else if(!faf) { dispatch(fetchSubsError(err)) }
    })       
  }
};

export const deleteEntity = (entid, entityType) => {
  return dispatch => {
    axios.delete('/entity/protect/entity/' + entid)
    .then(function(res) {
      dispatch(removeCpent(entid))
    })
    .catch(function(err) {
      dispatch(fetchCpentsError(err))
    })
  }
}

// ORGPROFILE SUBS

export function fetchOrgProfileSubsByOrgID(orgid) {
  return dispatch => {
      dispatch(fetchOrgProfileSubsPending());
      axios.get('/entity/subsidiaries/' + orgid)
      .then(function(res) {
        dispatch(fetchOrgProfileSubsSuccess(res.data.data));
      })
      .catch(function(err) {
        dispatch(fetchOrgProfileSubsError(err));
      })
  }
}

export const fetchOrgProfileSubsPending = () => ({
    type: ActionTypes.FETCH_ORGPROFILESUBS_PENDING
});

export const fetchOrgProfileSubsError = (error) => ({
    type: ActionTypes.FETCH_ORGPROFILESUBS_ERROR,
    payload: error
});

export const fetchOrgProfileSubsSuccess = (entities) => ({
    type: ActionTypes.FETCH_ORGPROFILESUBS_SUCCESS,
    payload: entities
});