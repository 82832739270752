const axios = require('axios');

// CLAUSES

//export const updateClauseFAF = (clid, clauseTypes, clauseStatus, commentStatus, approvalStatus, internalWorkflow, title, blocks, plain, clauseObject, version, pendingChange, numberType, numberManual, lastUpdateBy) => {
export const updateClauseContentFAF = (clid, clauseStatus, blocks, plain, clauseObject, version, lastUpdateBy) => {
  return dispatch => {
    // FAF : Fire & Forget
    let lastUpdateDate = new Date().toISOString();

    axios.put('/clause/protect/clcontent/' + clid, {
      clauseStatus: clauseStatus,
      blocks: blocks, 
      plain: plain, 
      clauseObject: clauseObject,
      version: version,
      lastUpdateBy: lastUpdateBy,
      lastUpdateDate: lastUpdateDate,
    })
  }
};

export const updatePendingChange = (clid, pendingChange, clauseStatus, /*title,*/ clauseObject, lastUpdateBy) => {
  return dispatch => { // FAF : Fire & Forget
    let lastUpdateDate = new Date().toISOString();
    axios.put('/clause/protect/clchange/' + clid, {
      pendingChange: pendingChange,
      clauseStatus: clauseStatus,
      //title: title,
      clauseObject: clauseObject,
      lastUpdateBy: lastUpdateBy,
      lastUpdateDate: lastUpdateDate,
    })
  }
};

export const updateClauseNumbering = (clid, numberType, numberManual, lastUpdateBy) => {
  return dispatch => { // FAF : Fire & Forget
    let lastUpdateDate = new Date().toISOString();
    axios.put('/clause/protect/clnumbering/' + clid, {
      numberType: numberType,
      numberManual: numberManual,
      lastUpdateBy: lastUpdateBy,
      lastUpdateDate: lastUpdateDate,
    })
  }
}

export const updateClauseStatus = (clOrSclID, type, newStatus, lastUpdateBy) => { // FAF
  return dispatch => {
    let lastUpdateDate = new Date().toISOString();

    axios.put('/clause/protect/clause/' + clOrSclID, {
      type: type,
      newStatus: newStatus,
      lastUpdateBy: lastUpdateBy,
      lastUpdateDate: lastUpdateDate,
    })
  }
};


// ***** CLAUSE TYPE ACTIONS *****
/*
export function fetchClauseTypes() {
  return dispatch => {
    dispatch(fetchClauseTypesPending());
    axios.get('/clausetype/clausetypes')
    .then(function(res) { dispatch(fetchClauseTypesSuccess(res.data.data)); })
    .catch(function(err) { dispatch(fetchClauseTypesError(err)); })
  }
}

export const fetchClauseTypesPending = () => ({
  type: ActionTypes.FETCH_CLAUSETYPES_PENDING
});

export const fetchClauseTypesError = (error) => ({
  type: ActionTypes.FETCH_CLAUSETYPES_ERROR,
  payload: error
});

export const fetchClauseTypesSuccess = (clauseTypes) => ({
  type: ActionTypes.FETCH_CLAUSETYPES_SUCCESS,
  payload: clauseTypes
});

// CLAUSE TYPE CLASSIFICATION PUSH

export const createClauseTypeClassification = (clauseString, labels, singleClauseID, classifyBy) => {
  return dispatch => {

    let classifyDate = new Date().toISOString();
    axios.post('/clausetype/clausetypeclassification', {
      clauseString: clauseString,
      labels: labels,
      singleClauseID: singleClauseID,
      classifyBy: classifyBy,
      classifyDate: classifyDate,
    })
  }
};*/