import React from 'react';
import { trunc } from '../utils'
import { Menu, MenuItem, ListItemText, ListItemIcon, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleQuestion, faComments, faExclamationCircle, faFilter, faLock, faUserEdit, } from "@fortawesome/free-solid-svg-icons";

const MenuPopFilters = (props) => {

    const useStyles = makeStyles(theme => ({
        iconPrimary: {
            color: theme.palette.primary.main
        },
        rectChipReverse: {
            //[theme.breakpoints.only('xs')]: { fontSize: '11px', padding: '3px 8px 3px 8px', margin: '2px 5px 0px 5px', },
            [theme.breakpoints.only('xs')]: { fontSize: '11px', padding: '3px 6px 3px 6px', margin: '2px 3px 0px 3px', },
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.secondary.main,
            borderRadius: '20px',
            fontSize: '13px',
            fontWeight: '600',
            padding: '3px 11px 3px 11px',
            margin: '1px 5px 0px 5px',
            '&:hover': {
              cursor: 'pointer',
            },
        },
        rectChip: {
            [theme.breakpoints.only('xs')]: { fontSize: '11px', padding: '3px 6px 3px 6px', margin: '2px 3px 0px 3px', },
            color: theme.palette.grey[700],
            backgroundColor: 'transparent',
            borderRadius: '20px',
            fontSize: '13px',
            fontWeight: '600',
            padding: '3px 11px 3px 11px',
            margin: '1px 5px 0px 5px',
            '&:hover': {
              cursor: 'pointer',
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.grey[700],
            }
        },
        filterActiveIcon: {
            padding: '0px 6px 0px 2px',
            marginBottom: '-2px',      
        },
    }));
    const classes = useStyles();

    const menuWidth = 275

    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleApply = (filter) => {
        props.handleToggleFilter(null, filter)
        handleClose()
    }

    return (
        <React.Fragment>

            <Tooltip title={['clause','changes','internalcomments','lockedByOrg','apprequest','appassignee'].includes(props.toggleFilter) ? "Deactivate Filter" : ""} arrow>
                <Typography 
                className={['clause','changes','internalcomments','lockedByOrg','apprequest','appassignee'].includes(props.toggleFilter) ? classes.rectChipReverse : classes.rectChip} 
                onClick={['clause','changes','internalcomments','lockedByOrg','apprequest','appassignee'].includes(props.toggleFilter) ? e => props.handleToggleFilter(e, null) : handleClick}>
                <FontAwesomeIcon icon={faFilter} className={classes.filterActiveIcon} />
                {['clause'].includes(props.toggleFilter) ? 
                    "Open Issues" :
                ['changes'].includes(props.toggleFilter) ? 
                    trunc("Changed by " + props.cptyName, 20) :
                ['internalcomments'].includes(props.toggleFilter) ? 
                    "Internal comments" :
                ['lockedByOrg'].includes(props.toggleFilter) ?
                    "Clause lock" :
                ['apprequest'].includes(props.toggleFilter) ?
                    "Approval requests" :
                ['appassignee'].includes(props.toggleFilter) ?
                    "Approval assignee" :
                    "Apply Filter" }
                </Typography> 
            </Tooltip>

            <Menu
                id="avatar-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                keepMounted
                disableAutoFocusItem
                open={Boolean(anchorEl)}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            >

                <MenuItem style={{width: menuWidth}} onClick={e => handleApply('apprequest')}>
                <ListItemIcon><FontAwesomeIcon icon={faCircleQuestion} className={classes.iconPrimary} /></ListItemIcon>
                <ListItemText>Approval Request by me</ListItemText>
                </MenuItem>

                <MenuItem style={{width: menuWidth}} onClick={e => handleApply('appassignee')}>
                <ListItemIcon><FontAwesomeIcon icon={faCircleCheck} className={classes.iconPrimary} /></ListItemIcon>
                <ListItemText>Approval Request for me</ListItemText>
                </MenuItem>

                {props.showChangesFilter ?
                <MenuItem style={{width: menuWidth}} onClick={e => handleApply('changes')}>
                <ListItemIcon><FontAwesomeIcon icon={faUserEdit} className={classes.iconPrimary} /></ListItemIcon>
                <ListItemText>{trunc("Changed by " + props.cptyName, 20)}</ListItemText>
                </MenuItem>
                :''}

                <MenuItem style={{width: menuWidth}} onClick={e => handleApply('clause')}>
                <ListItemIcon><FontAwesomeIcon icon={faExclamationCircle} className={classes.iconPrimary} /></ListItemIcon>
                <ListItemText>Open Issues</ListItemText>
                </MenuItem>



                <MenuItem style={{width: menuWidth}} onClick={e => handleApply('internalcomments')}>
                <ListItemIcon><FontAwesomeIcon icon={faComments} className={classes.iconPrimary} /></ListItemIcon>
                <ListItemText>With internal comments</ListItemText>
                </MenuItem>

                {props.showClauseLockFilter ? 
                <MenuItem style={{width: menuWidth}} onClick={e => handleApply('lockedByOrg')}>
                <ListItemIcon><FontAwesomeIcon icon={faLock} className={classes.iconPrimary} /></ListItemIcon>
                <ListItemText>With a clause lock</ListItemText>
                </MenuItem>
                :''}

            </Menu>

        </React.Fragment>      
    )

}

export default MenuPopFilters