function convertPlainToBlocksComment(plain) {
  
  let jsonString = '{ "blocks": [';
  let plainPars = []

  if(typeof plain === "string"){
    plainPars = plain.split(/\r?\n/) // split into array based on newLine
  } else { // is already an Array
    plainPars = plain;
  }

  plainPars.forEach((p) => { // eliminating any tabs (\t), carriage return (\r) ; escape double quote (\\\")

    jsonString = jsonString + `
    { 
      "text": "` + p.replace(/\t/g,'').replace(/\r/g,'').replace(/"/g,'\\"') + `", 
      "type": "unstyled"
    },`

  })
  jsonString = jsonString.substring(0, jsonString.length - 1); // remove the last comma
  jsonString = jsonString + `], 
    "entityMap": { }
  }`

  return JSON.parse(jsonString);
}

export { convertPlainToBlocksComment }