function processLongString(str, wordLength, totalLength) {
    let res = [], lengthCaptured = 0;
    let temp = str.split(' ')
    temp.forEach((w) => {
        if(lengthCaptured < totalLength) {
            let toPush = 
                (lengthCaptured + w.length > totalLength) ? (w.substr(0, totalLength - lengthCaptured - 1) + '..') :
                (w.length > wordLength) ? (w.substr(0, wordLength-1) + '..') : w;
            res.push(toPush)
            lengthCaptured = lengthCaptured + toPush.length
        }
    })
    return res.join(' ')
}
export { processLongString }