import React, { useEffect } from 'react';
import theme from '../theme/theme';
import { useHistory } from 'react-router-dom';
import { randomString as generateRandomString, getTimeIndicationForDate, sendApprovalEmailToUser, trunc, stripPlain, 
         convertBlocksToPlainComment, convertPlainToBlocksComment, getRecipientModule } from '../utils'
import { Avatar, Badge, Button, Box, CircularProgress, Chip, Dialog, DialogActions, DialogContent, 
         FormControl, FormControlLabel, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Radio, RadioGroup,
         Slide, Typography, makeStyles, withStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { AvatarInitials, CommentDisplayBox, InformationalTooltip, MentionEditor, NegoCardEditor, SelectUsers, TableEditable } from '.';
import { UserForm } from './forms'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck, faCaretRight, faThumbsUp, faThumbsDown, faQuestion, faSlash, 
         faExclamationCircle, faArrowLeft, faPlusCircle, faPaperPlane, faUserCheck, faChevronUp, 
         faChevronDown, faExternalLinkAlt, faArrowRight } from "@fortawesome/free-solid-svg-icons";  

const axios = require('axios');

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = (props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h6" style={{fontWeight: '700'}}>{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" style={{position: 'absolute', right: '8px', top: '10px'}} onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} style={{fontSize: '20px', padding: '0px 3px 0px 3px'}} />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

const DialogNegoApprovals = (props) => {

  const useStyles = makeStyles(theme => ({
    cancelButton: {
        marginRight: 'auto',
        //color: theme.palette.grey[600],
    },
    mlittle: {
        marginTop: '4px',
        marginBottom: '4px',
    },
    chipDefault: {
        marginLeft: '10px',
        paddingLeft: '5px',
        paddingRight: '5px',
    },
    chipGrey: {
        marginLeft: '10px',
        paddingLeft: '5px',
        paddingRight: '5px',
        backgroundColor: theme.palette.grey[600],
    },
    chipError: {
        marginLeft: '10px',
        paddingLeft: '5px',
        paddingRight: '5px',
        backgroundColor: theme.palette.error.main,
        color: theme.palette.primary.contrastText,
    },
    chipSuccess: {
        marginLeft: '10px',
        paddingLeft: '5px',
        paddingRight: '5px',
        backgroundColor: theme.palette.success.main,
        color: theme.palette.primary.contrastText,
    },
    userAv: {
        backgroundColor: theme.palette.grey[800],
        [theme.breakpoints.only('xs')]: { 
            height: '40px',
            width: '40px',
            marginTop: '10px',
            marginBottom: '10px',
            marginLeft: '8px',
            marginRight: '8px',
        },
        [theme.breakpoints.only('sm')]: { 
            height: '50px',
            width: '50px',
            marginTop: '10px',
            marginBottom: '10px',
            marginLeft: '12px',
            marginRight: '12px',
        },
        [theme.breakpoints.up('md')]: { 
            height: '60px',
            width: '60px',
            marginTop: '25px',
            marginBottom: '25px',
            marginLeft: '18px',
            marginRight: '18px',
        },
    },
    userAvDelete: {
        backgroundColor: theme.palette.grey[800],
        boxSizing: 'border-box',
        [theme.breakpoints.only('xs')]: { 
            height: '40px',
            width: '40px',
            marginTop: '10px',
            marginBottom: '10px',
            marginLeft: '8px',
            marginRight: '8px',
        },
        [theme.breakpoints.only('sm')]: { 
            height: '50px',
            width: '50px',
            marginTop: '10px',
            marginBottom: '10px',
            marginLeft: '12px',
            marginRight: '12px',
        },
        [theme.breakpoints.up('md')]: { 
            height: '60px',
            width: '60px',
            marginTop: '25px',
            marginBottom: '25px',
            marginLeft: '18px',
            marginRight: '18px',
        },
        '&:hover': {
            border: '3px solid' + theme.palette.error.main,
            cursor: 'pointer',
        }
    },
    userAvList: {
        backgroundColor: theme.palette.tertiary.main,
        [theme.breakpoints.only('xs')]: { 
            height: '40px',
            width: '40px',
            marginRight: '10px',
        },
        [theme.breakpoints.up('sm')]: { 
            height: '60px',
            width: '60px',
            marginRight: '25px',
        },
    },
    approvedAvGreyList: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.grey[600],
        [theme.breakpoints.only('xs')]: { 
            height: '40px',
            width: '40px',
            marginRight: '10px',
        },
        [theme.breakpoints.up('sm')]: { 
            height: '60px',
            width: '60px',
            marginRight: '25px',
        },
    },
    approvedAvSuccessList: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.success.main,
        [theme.breakpoints.only('xs')]: { 
            height: '40px',
            width: '40px',
            marginRight: '10px',
        },
        [theme.breakpoints.up('sm')]: { 
            height: '60px',
            width: '60px',
            marginRight: '25px',
        },
    },
    approvedAvErrorList: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.error.main,
        [theme.breakpoints.only('xs')]: { 
            height: '40px',
            width: '40px',
            marginRight: '10px',
        },
        [theme.breakpoints.up('sm')]: { 
            height: '60px',
            width: '60px',
            marginRight: '25px',
        },
    },
    plusAv: {
        [theme.breakpoints.only('xs')]: { padding: '0px' },
        padding: '5px',
    },
    plusAvLarge: {
        padding: '10px',
    },
    approvedAv: {
        //color: theme.palette.primary.contrastText,
        //backgroundColor: theme.palette.primary.main,
        color: theme.palette.grey[100],
        backgroundColor: theme.palette.grey[500],
        [theme.breakpoints.only('xs')]: { 
            height: '40px',
            width: '40px',
            marginTop: '10px',
            marginBottom: '10px',
            marginLeft: '8px',
            marginRight: '8px',
        },
        [theme.breakpoints.only('sm')]: { 
            height: '50px',
            width: '50px',
            marginTop: '10px',
            marginBottom: '10px',
            marginLeft: '12px',
            marginRight: '12px',
        },
        [theme.breakpoints.up('md')]: { 
            height: '60px',
            width: '60px',
            marginTop: '25px',
            marginBottom: '25px',
            marginLeft: '18px',
            marginRight: '18px',
        },
    },
    approvedAvApproved: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.success.main,
        [theme.breakpoints.only('xs')]: { 
            height: '40px',
            width: '40px',
            marginTop: '10px',
            marginBottom: '10px',
            marginLeft: '8px',
            marginRight: '8px',
        },
        [theme.breakpoints.only('sm')]: { 
            height: '50px',
            width: '50px',
            marginTop: '10px',
            marginBottom: '10px',
            marginLeft: '12px',
            marginRight: '12px',
        },
        [theme.breakpoints.up('md')]: { 
            height: '60px',
            width: '60px',
            marginTop: '25px',
            marginBottom: '25px',
            marginLeft: '18px',
            marginRight: '18px',
        },
    },
    approvedAvGrey: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.grey[600],
        [theme.breakpoints.only('xs')]: { 
            height: '40px',
            width: '40px',
            marginTop: '10px',
            marginBottom: '10px',
            marginLeft: '8px',
            marginRight: '8px',
        },
        [theme.breakpoints.only('sm')]: { 
            height: '50px',
            width: '50px',
            marginTop: '10px',
            marginBottom: '10px',
            marginLeft: '12px',
            marginRight: '12px',
        },
        [theme.breakpoints.up('md')]: { 
            height: '60px',
            width: '60px',
            marginTop: '25px',
            marginBottom: '25px',
            marginLeft: '18px',
            marginRight: '18px',
        },
    },
    arrowIcon: {
        color: theme.palette.grey[500],
        [theme.breakpoints.only('xs')]: { padding: '10px 2px 10px 2px', },
        [theme.breakpoints.up('sm')]: { padding: '5px', }
    },
    boxResizing: {
        [theme.breakpoints.only('xs')]: { width: '260px', },
        [theme.breakpoints.only('sm')]: { width: '400px' },
        [theme.breakpoints.up('md')]: { width: '480px', }
    },
    boxFormSizing: {
        //maxWidth: '400px',
        [theme.breakpoints.only('xs')]: { width: '260px' },
        [theme.breakpoints.up('sm')]: { width: '400px' }    
    },
    boxFormSizingAlignsWithCardComment: {
        //maxWidth: '400px',
        [theme.breakpoints.only('xs')]: { width: '260px' },
        [theme.breakpoints.up('sm')]: { width: '400px' }    
    },
    errorButton: {
        backgroundColor: theme.palette.error.main,
        '&:hover' : {
            backgroundColor: theme.palette.error.dark,
        }
    },
    successButton: {
        backgroundColor: theme.palette.success.main,
        '&:hover' : {
            backgroundColor: theme.palette.success.dark,
        }
    },
    buttonResponse: {
        paddingLeft: '12px',
        paddingRight: '12px',
    },
    higlightPrimary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontWeight: '600',
        paddingLeft: '2px',
        paddingRight: '2px',
        marginRight: '10px',
    },
    warnIcon: {
        color: theme.palette.primary.main,
        paddingRight: '15px',
    },
    warnIconGrey: {
        color: theme.palette.grey[600],
        paddingRight: '15px',
    },
    draftBox: {
        fontSize: '13px',
        //lineHeight: '0.9', 
        fontWeight: '300'
    },
    topicText: {
        fontWeight: '300',
    },
    bold: {
        fontWeight: '700',
    },
    cardPrimaryCpty: {
        borderRadius: '0px',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
        border: '1px solid' + theme.palette.grey[400],
        borderLeft: '4px solid ' + theme.palette.primary.main,
        marginBottom: '5px',
        padding: '20px',
        minWidth: '220px',
        maxWidth: '400px',
    },
    additionalGridItem: {
        [theme.breakpoints.only('xs')]: { width: '260px' },
        width: '400px',
    },
}));
const classes = useStyles();

const history = useHistory()

const StyledBadge = withStyles(theme => ({
    badge: {
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.only('xs')]: { right: 10, top: 13, },
      [theme.breakpoints.only('sm')]: { right: 14, top: 19, },
      [theme.breakpoints.up('md')]: { right: 20, top: 29, },
      padding: '0 4px',
    },
}))(Badge);

const StyledBadgeDelete = withStyles(theme => ({
    badge: {
      backgroundColor: theme.palette.error.main,
      [theme.breakpoints.only('xs')]: { right: 10, top: 13, },
      [theme.breakpoints.only('sm')]: { right: 14, top: 19, },
      [theme.breakpoints.up('md')]: { right: 20, top: 29, },
      padding: '0 4px',
      '&:hover' : {
        backgroundColor: theme.palette.error.dark,
        cursor: 'pointer'
      }
    },
}))(Badge);

const StyledBadgeApprove = withStyles(theme => ({
    badge: {
      backgroundColor: theme.palette.success.main,
      [theme.breakpoints.only('xs')]: { right: 10, top: 13, },
      [theme.breakpoints.only('sm')]: { right: 14, top: 19, },
      [theme.breakpoints.up('md')]: { right: 20, top: 29, },
      padding: '0 4px',
    },
}))(Badge);

const StyledBadgeGrey = withStyles(theme => ({
    badge: {
      backgroundColor: theme.palette.grey[600],
      [theme.breakpoints.only('xs')]: { right: 10, top: 13, },
      [theme.breakpoints.only('sm')]: { right: 14, top: 21, },
      [theme.breakpoints.up('md')]: { right: 20, top: 29, },
      padding: '0 4px',
    },
}))(Badge);

const [potentialApprovers, setPotentialApprovers] = React.useState([]);
const [stagedApproval, setStagedApproval] = React.useState({});
const [stagedChange, setStagedChange] = React.useState(null)
const [addApprovalOpen, setAddApprovalOpen] = React.useState(false);
const [addApproverOpen, setAddApproverOpen] = React.useState(null);
const [ticketDetailOpen, setTicketDetailOpen] = React.useState(null);
const [userValue, setUserValue] = React.useState({});
const [tickets, setTickets] = React.useState(null);
const [comments, setComments] = React.useState(null);
const [commentValue, setCommentValue] = React.useState('');
const [topicValue, setTopicValue] = React.useState('');
const [clause, setClause] = React.useState(null);
const [clauseDetail, setClauseDetail] = React.useState(false); // Boolean for showing the clause content if coming from approval screen
const [orgurl, setOrgurl] = React.useState(null);
const [addCollab, setAddCollab] = React.useState(null);
const [submitError, setSubmitError] = React.useState(null)
const [newlyCreated, setNewlyCreated] = React.useState([])
const [updating, setUpdating] = React.useState(null)
const [loading, setLoading] = React.useState(true);

const curCpty = (props.rel !== undefined && props.rel !== null && props.rel.primaryOrgID === props.user.orgID) || props.templating ? "primary" : "secondary"

useEffect(() => {
    let unmounted = false;

    if(props.open && clause === null && props.clause !== 'agreement') {
        // Section to assign the appropriate list of potential approvers (for SelectUsers Autocomplete)
        let usrs = props.users.concat(newlyCreated)
                    .filter((usr) => (usr.active) && (usr._id !== props.user._id) &&
                                        ((props.module === 'sales' && ['Admin', 'Business', 'Legal', 'Sales'].includes(usr.role)) ||
                                         (props.module === 'procurement' && ['Admin', 'Business', 'Legal', 'Procurement'].includes(usr.role)) ||
                                         (props.module === 'partnerships' && ['Admin', 'Business', 'Legal', 'Partnerships'].includes(usr.role)) ||
                                         (props.module === 'corporate' && ['Admin', 'Legal', 'Corporate'].includes(usr.role)) ||
                                         (props.module === 'people' && ['Admin', 'Legal', 'HR'].includes(usr.role)) ||
                                         (props.module === 'counterparty' && ['Counterparty'].includes(usr.role)) ||
                                         (props.module === 'counterparty' && ['Admin'].includes(usr.role) && props.user.role.startsWith('ClientFor_')) ||
                                         (usr.role.startsWith('ClientFor_') && props.rel !== undefined && props.rel !== null && [props.rel.primaryCptyID, props.rel.secondaryCptyID].includes(usr.role.substring(10)))
                                        )) // temp array with all org users except for deleted, currently active/requesting and diff module

        if(tickets === null /*tickets.length === 0 && comments.length === 0*/) {
            // Pull all tickets for this singleClause
            axios.get('/ticket/ticketsscl/' + props.clause.singleClauseID)
            .then((rest) => {

                // Pull all comments for this singleClause
                axios.get('/comment/commentsscl/' + props.clause.singleClauseID + '/' + props.user.orgID)
                .then((res) => {

                    axios.get('/clause/protect/clause/' + props.clause._id)
                    .then((resc) => {

                        if(!unmounted) {
                            let toRemove = []
                            if(stagedApproval.users !== undefined) { stagedApproval.users.forEach((app) => { toRemove.push(app) }) }
                            setTickets(rest.data.data.sort((a,b) => (a.lastUpdateDate < b.lastUpdateDate) ? 1 : ((b.lastUpdateDate < a.lastUpdateDate) ? -1 : 0)));
                            setComments(res.data.data.filter((c) => c.commentType === "ticket")) 
                            if(props.ticketFromApprovalScreen !== null) {
                                let t = rest.data.data.filter((t) => t._id === props.ticketFromApprovalScreen)[0]
                                if(t.workflow !== undefined && t.workflow !== null && t.workflow.length > 0) {
                                    t.workflow.forEach((item) => toRemove.push(item.uid));
                                }
                                setTicketDetailOpen(t);
                                setClauseDetail(true)
                                setLoading(false)
                            } else {
                                setLoading(false)
                            }
                            setClause(resc.data.data)
                            setPotentialApprovers(usrs.filter((u) => !toRemove.includes(u._id) && !u.email.endsWith('support@getcanveo.com') && 
                                (!props.templating || ['Admin', 'Legal'].includes(u.role))));
                        }
                    }).catch(function(err) { console.log(err) })
                }).catch(function(err) { console.log(err) })
            }).catch(function(err) { console.log(err) })
        }

        // SECTION TO ASSING THE ORGURL IF THE USER IS CPTY - MAY NEED THIS FOR THE SIGNATORY EMAIL
        if(props.open && props.rel !== undefined && props.rel !== null) {
            let oid = props.user.role === 'Counterparty' && props.user.orgID === props.rel.primaryOrgID ? props.rel.secondaryOrgID :
                    props.user.role === 'Counterparty' && props.user.orgID === props.rel.secondaryOrgID ? props.rel.primaryOrgID :
                    props.user.role !== 'Counterparty' ? props.user.orgID : 'notfound'
            axios.get('/org/org/' + oid)
            .then(function (res) {
                if (!unmounted) { setOrgurl(res.data.data.orgurl) }
            })
        }
    } else if (props.open && clause === null && props.clause === 'agreement') {

        let aids = []
        axios.get('/agr/agrtied/' + props.agrID)
        .then(function(res) {
            res.data.data.forEach((a) => { aids.push(a._id) }) // fill "aids"

            axios.post('/ticket/ticketstied', { aids: aids, orgID: props.user.orgID }) // Pull all Tickets for all AGs
            .then(function(rest) {

                if (!unmounted) { 
                    let ts = []
                    rest.data.data.filter((t) => t.ticketType === 'Approval').forEach((t) => {
                        //if(props.clauses.filter((c) => c.singleClauseID === t.singleClauseID)[0] !== undefined){
                        ts.push(t); // only include tickets that are applicable to a clause that's (still) inside the agreement
                        //}
                    })
                    setTickets(ts.sort((a,b) => (a.lastUpdateDate < b.lastUpdateDate) ? 1 : ((b.lastUpdateDate < a.lastUpdateDate) ? -1 : 0)))
                    setComments([])
                    setClause({})
                    setLoading(false)
                }
            }).catch(function(err) { if (!unmounted) { console.log(err) } })
        }).catch(function(err) { if (!unmounted) { console.log(err) } })
    }

    return () => { unmounted = true };

}, [props.open, props.rel, props.clause] /*[stagedApproval, newlyCreated, props.open, props.user, props.users, clause, props.clause, comments, tickets, 
    props.module, props.ticketFromApprovalScreen, props.rel, props.agrID]*/);

const closeDialog = () => {

    if(newlyCreated.length > 0) {
        props.fetchUsersByOrgID(props.user.orgID)
    }
    props.parentCallToClose()
    setStagedApproval({})
    setPotentialApprovers([])
    setStagedChange(null);
    setAddApprovalOpen(false)
    setAddApproverOpen(null)
    setTicketDetailOpen(null)
    setLoading(true)
    setTickets(null)
    setClause(null)
    setComments(null)
    setUserValue({});
    setCommentValue('');
    setTopicValue('');
}

const toggleClauseDetail = () => {
    setClauseDetail(!clauseDetail);
}

const handleAddApprovalOpen = () => {
    setAddApprovalOpen(true);
}

const handleAddApprovalClose = () => {
    setAddApprovalOpen(false);
}

const handleAddApproverOpen = (pos) => {
    setAddApproverOpen(pos);
}

const handleAddApproverClose = () => {
    setAddApproverOpen(null);
}

const handleCancelApproval = () => {
    setTopicValue('');
    setCommentValue('');
    setStagedApproval({});
    setAddApprovalOpen(false);
    handleAddApproverClose();
}

const handleTicketDetailOpen = (e, tid) => {
    let tick = tickets.filter((t) => t._id === tid)[0]
    if(props.clause === 'agreement') {
        setLoading(true)
        //let clid = props.clauses.filter((c) => c.singleClauseID === tick.singleClauseID)[0]._id
        let clid = tick.clauseID
        axios.get('/clause/protect/clause/' + clid)
        .then((rescl) => {

            // Pull all comments for this singleClause
            axios.get('/comment/commentsscl/' + tick.singleClauseID + '/' + props.user.orgID)
            .then((res) => {

                let usrs = props.users.concat(newlyCreated)
                    .filter((usr) => (usr.active) && (usr._id !== props.user._id) &&
                                        ((props.module === 'sales' && ['Admin', 'Business', 'Legal', 'Sales'].includes(usr.role)) ||
                                         (props.module === 'procurement' && ['Admin', 'Business', 'Legal', 'Procurement'].includes(usr.role)) ||
                                         (props.module === 'partnerships' && ['Admin', 'Business', 'Legal', 'Partnerships'].includes(usr.role)) ||
                                         (props.module === 'corporate' && ['Admin', 'Legal', 'Corporate'].includes(usr.role)) ||
                                         (props.module === 'people' && ['Admin', 'Legal', 'HR'].includes(usr.role)) ||
                                         (props.module === 'counterparty' && ['Counterparty'].includes(usr.role)) ||
                                         (props.module === 'counterparty' && ['Admin'].includes(usr.role) && props.user.role.startsWith('ClientFor_')) ||
                                         (usr.role.startsWith('ClientFor_') && [props.rel.primaryCptyID, props.rel.secondaryCptyID].includes(usr.role.substring(10)))
                                        )) // temp array with all org users except for deleted, currently active/requesting and diff module

                let toRemove = []
                if(tick.workflow !== undefined && tick.workflow !== null && tick.workflow.length > 0) {
                    tick.workflow.forEach((item) => toRemove.push(item.uid));
                }
                setPotentialApprovers(usrs.filter((u) => !toRemove.includes(u._id) && !u.email.endsWith('support@getcanveo.com') && 
                    (!props.templating || ['Admin', 'Legal'].includes(u.role))))
                setTicketDetailOpen(tick);
                setComments(res.data.data.filter((c) => c.commentType === "ticket")) 
                setClauseDetail(true)
                setClause(rescl.data.data)
                setLoading(false)

            }).catch(function(err) { console.log(err) })

        }).catch(function(err) { console.log(err) })

    } else {
        setTicketDetailOpen(tick);
    }
}

const handleTicketDetailClose = () => {
    setCommentValue('');
    setTicketDetailOpen(null);
}

const handleCommentChangeSubmit = (isBlocks, newComment) => {

    let plainComment = isBlocks ? convertBlocksToPlainComment(newComment) : newComment;
    let blocksComment = isBlocks ? newComment : convertPlainToBlocksComment(newComment);

    props.updateComment(updating, plainComment, blocksComment, props.user._id) // Update the DATABASE

    let idx = comments.findIndex((c) => c._id === updating)
    setComments([
        ...comments.slice(0,idx),
        {
            ...comments[idx],
            comment: plainComment,
            blocks: blocksComment,
            lastUpdateDate: new Date().toISOString(),
        },
        ...comments.slice(idx + 1)
    ])
    setUpdating(null)
}

const handleDeleteComment = (cid) => {
    props.updateComment(cid, '___DELETED___', null, props.user._id) // Update the DATABASE
    let cs = comments;
    let idx = comments.findIndex((c) => c._id === cid)
    cs[idx] = {...cs[idx], comment: '___DELETED___', lastUpdateDate: new Date().toISOString() }
    setComments([...cs])
}

/*
const handleCommentAction = (type, cid) => {

    if(type === 'edit') {
        setUpdating(cid)
        setCommentValue(comments.filter((c) => c._id === cid)[0].comment)
    } else if (type === 'delete') {
        
        props.updateComment(cid, '___DELETED___', null, props.user._id) // Update the DATABASE
        let cs = comments;
        let idx = comments.findIndex((c) => c._id === cid)
        cs[idx] = {...cs[idx], comment: '___DELETED___', lastUpdateDate: new Date().toISOString() }
        setComments([...cs])
    }

}*/

const handleChangeApproverSubmit = () => {

    let lastUpdateDate = new Date().toISOString();
    let wf = stagedChange.workflow;
    let idx = wf.findIndex((item) => item.uid === ticketDetailOpen.assignee); // && item.response !== 'Approved'
    let newAssignee = ticketDetailOpen.assignee
    if(stagedChange.radioOption === 'reassignapprover') {
        wf[idx] = { uid: userValue._id, assignedDate: lastUpdateDate }
        newAssignee = userValue._id;

    } else if(stagedChange.radioOption === 'insertbefore') {
        let tempWf = []
        stagedChange.workflow.forEach((item) => {
            if(item.uid === ticketDetailOpen.assignee) {
                tempWf.push({uid: userValue._id, assignedDate: lastUpdateDate}) // insert new user
                tempWf.push({uid: ticketDetailOpen.assignee }) // current assignee, clearing assignedDate
            } else {
                tempWf.push(item);
            }
        })
        wf = tempWf;
        newAssignee = userValue._id;

    } else if(stagedChange.radioOption === 'insertafter') {
        wf.splice(idx + 1, 0, {uid: userValue._id})
    }

    props.updateTicket(
        true, // FAF
        stagedChange.tid, // ticketID
        ticketDetailOpen.module, // module
        'Assigned', // ticketStatus 
        ticketDetailOpen.topic, // topic
        newAssignee, // assignee
        props.user._id, // lastUpdateBy 
        lastUpdateDate, // lastUpdateDate
        '', // closedBy
        '', // closedDate
        'None', // resolveReason - didn't get resolved
        wf // workflow
    )

    // Update local 'tickets'
    let ticks = tickets
    const index = ticks.findIndex(t => t._id === stagedChange.tid)
    ticks[index] = { ...ticks[index], 
        assignee: newAssignee !== '' ? newAssignee : ticketDetailOpen.assignee, // assignee
        ticketStatus: 'Assigned', 
        lastUpdateBy: props.user._id,
        lastUpdateDate: lastUpdateDate,
        workflow: wf,
    }

    if(newAssignee !== ticketDetailOpen.assignee) { // Email and Notify the new Assignee if applicable
        let sa = ticketDetailOpen.topic.split('|')
        let recModule = getRecipientModule(props.user, props.users.concat(newlyCreated).filter((u) => u._id === newAssignee)[0], props.module, props.rel)
        sendApprovalEmailToUser (
            'approvalrequest', // type
            recModule, // module 
            ['templating','terms'].includes(ticketDetailOpen.relID) ? 
                { 
                    isTemplating: true, 
                    isTerms: ['terms'].includes(ticketDetailOpen.relID), 
                    oatid: ticketDetailOpen.agrVersion, 
                } : 
                props.rel, // rel
            orgurl, // orgurl
            ticketDetailOpen.agrID, // agrID
            clause, // clause
            props.users.concat(newlyCreated).filter((u) => u._id === newAssignee)[0], // recipient
            sa[2].trim(), // cptyLegalName
            sa[1].trim(), // agrFullName
            props.users.concat(newlyCreated).filter((u) => u._id === ticketDetailOpen.creationBy)[0].displayName, // creatorFullName
            ticks[index], // ticket
            {
                reassign: true,
                comment: 'The approval request has been re-assigned by ' + props.user.displayName + ' to you.',
            }, // commentOrDecision
            comments, // allComments
        )
        props.createNotification(
            'Approval Request', // notificationType
            'Approval Request', // title
            props.users.concat(newlyCreated).filter((u) => u._id === props.user._id)[0].displayName + ' requests your approval on ' + ticketDetailOpen.topic, // description
            props.templating ? 
                '/core/' + props.module + '/tasks/approvals' : 
                '/core/' + (props.user.role === 'Counterparty' ? 'counterparty' : recModule) + '/agreement/' + props.rel._id + '/' + props.agrID + '/' + clause.singleClauseID, // linkTo
            /*props.user.role !== 'Counterparty' ? 
                ('/core/' + props.module + '/tasks/approvals') : 
                ('/core/counterparty/agreement/' + props.rel._id + '/' + props.agrID),*/ // linkTo 
            false, // isRead
            newAssignee, // uid
        )
        // Add a cancelled comment for tracking purposes
        handleAddCommentSubmit('===REASSIGNED===', false);
    }

    setTickets([...ticks]);
    setTicketDetailOpen(ticks[index]); // update the current ticket that is open.
    setStagedChange(null);
    setUserValue({})
}

const handleAddApproverSubmit = () => {
    let usrs = stagedApproval.users
    const index = usrs.findIndex(u => u === addApproverOpen);
    usrs.splice(index + 1, 0, userValue._id);
    setStagedApproval({
        ...stagedApproval,
        users: usrs
    })
    setUserValue({})
    handleAddApproverClose();
}

/*
const handleCommentChange = (event) => {
    setCommentValue(event.target.value);
}

const handleTopicChange = (event) => {
    setTopicValue(event.target.value);
}*/

const handleApproverSelect = (child) => {
    setUserValue((child !== undefined && child !== null) ? child : {});
}

/*
const handleAddApprovalSubmit = () => {
    if(userValue !== {} && userValue !== null && userValue !== undefined && topicValue !== null && commentValue !== '' && commentValue !== null){
        setStagedApproval({
            // agrFullName is only known if you come from the Agr Page (not if you come through Tasks)
            topic: topicValue + ' | ' + (props.agrFullName !== null ? (props.agrFullName + " | ") : '') + props.cptyName + " | " + (clause.clauseCat === 'table' ? 'table' : trunc(stripPlain(clause.plain[0]))),
            comment: commentValue,
            users: [userValue._id]
        })
        setUserValue({})
        handleAddApprovalClose()
    }
}*/

const handleRemoveUser = (e, idx) => {
    let usrs = stagedApproval.users
    //const index = usrs.findIndex(u => u === uid);
    usrs.splice(idx, 1);
    if(usrs.length === 0) { 
        setStagedApproval({}) // empty out
        setTopicValue('')
    } else {
        setStagedApproval({
            ...stagedApproval,
            users: usrs
        })
    }
}

const handleAddApprover = (e, pos) => {
    handleAddApproverOpen(pos)
}

const handleApprove = (e, tid) => {

    // Update the ticket as appropriate
    let lastUpdateDate = new Date().toISOString();
    let wf = ticketDetailOpen.workflow;
    let reassign = false;
    let reassigned = false;
    let newAssignee = ''; // keep track of the new assignee
    let newTicketStatus = 'In Progress';
    let newResolveReason = 'None';
   
    wf.forEach((w) => { // iterate over the workflow
        if (reassign && !reassigned) { // workflow item is for the new assignee
            w.assignedDate = lastUpdateDate
            newAssignee = w.uid;
            reassigned = true;
        } else if(w.uid === props.user._id && !reassign && !reassigned && w.response !== 'Approved') { // worfklow item is for the current approver
            w.responseDate = lastUpdateDate
            w.response = 'Approved'
            reassign = true;
        }
    })

    if(newAssignee === '') { // there is no new assignee, we're done
        newTicketStatus = 'Resolved'
        newResolveReason = 'Approved'
    }

    // Update ticket for the Approval workflow
    props.updateTicket(
       true, // FAF
       tid, // ticketID
       ticketDetailOpen.module, // module
       newTicketStatus, // ticketStatus 
       ticketDetailOpen.topic, // topic
       newAssignee !== '' ? newAssignee : ticketDetailOpen.assignee, // assignee
       props.user._id, // lastUpdateBy 
       lastUpdateDate, // lastUpdateDate
       newAssignee !== '' ? '' : props.user._id, // closedBy
       newAssignee !== '' ? '' : lastUpdateDate, // closedDate
       newResolveReason, // resolveReason - didn't get resolved
       wf // workflow
    )

    // Update local 'tickets'
    let ticks = tickets
    const index = ticks.findIndex(t => t._id === tid)
    ticks[index] = { ...ticks[index], 
        assignee: newAssignee !== '' ? newAssignee : ticketDetailOpen.assignee, // assignee
        ticketStatus: newTicketStatus, 
        lastUpdateBy: props.user._id,
        lastUpdateDate: lastUpdateDate,
        closedBy: newAssignee !== '' ? props.user._id : '', // closedBy
        closedDate: newAssignee !== '' ? lastUpdateDate : '', // closedDate
        resolveReason: newResolveReason, 
        workflow: wf,
    }

    let allComments = commentValue.length > 0 ?
        comments.concat({
            creationDate: new Date().toISOString(),
            ticketID: tid,
            comment: commentValue,
            creatorDisplayName: props.user.displayName,
            creatorID: props.user._id,
        }) : comments
    
    let sa = ticketDetailOpen.topic.split('|')
    let nextAssignee = ''

    if(newAssignee !== '') { // Email the new Assignee if applicable
        sendApprovalEmailToUser (
            'approvalrequest', // type
            getRecipientModule(props.user, props.users.concat(newlyCreated).filter((u) => u._id === newAssignee)[0], props.module, props.rel), // module
            ['templating','terms'].includes(ticketDetailOpen.relID) ? 
                { 
                    isTemplating: true, 
                    isTerms: ['terms'].includes(ticketDetailOpen.relID), 
                    oatid: ticketDetailOpen.agrVersion, 
                } : 
                props.rel, // rel
            orgurl, // orgurl
            ticketDetailOpen.agrID, // agrID
            clause, // clause
            props.users.concat(newlyCreated).filter((u) => u._id === newAssignee)[0], // recipient
            sa[2].trim(), // cptyLegalName
            sa[1].trim(), // agrFullName
            props.users.concat(newlyCreated).filter((u) => u._id === ticketDetailOpen.creationBy)[0].displayName, // creatorFullName
            ticks[index], // ticket
            {
                reassign: true,
                comment: 'The request has been approved by ' + props.user.displayName + ' and is now assigned to you.',
            }, // commentOrDecision
            allComments, // allComments
        )
        nextAssignee = ' It is now assigned to ' + props.users.concat(newlyCreated).filter((u) => u._id === newAssignee)[0].displayName + '.'
    }

    let recModule = getRecipientModule(props.user, props.users.concat(newlyCreated).filter((u) => u._id === ticketDetailOpen.creationBy)[0], props.module, props.rel)
    // Email the creator on the decision
    sendApprovalEmailToUser (
        'approvaldecision', 
        recModule, // module
        ['templating','terms'].includes(ticketDetailOpen.relID) ? 
            { 
                isTemplating: true, 
                isTerms: ['terms'].includes(ticketDetailOpen.relID), 
                oatid: ticketDetailOpen.agrVersion, 
            } : 
            props.rel, // rel
        orgurl, // orgurl
        ticketDetailOpen.agrID, // agrID
        clause, // clause
        props.users.concat(newlyCreated).filter((u) => u._id === ticketDetailOpen.creationBy)[0], 
        sa[2].trim(), 
        sa[1].trim(), 
        props.user.displayName, 
        ticks[index], // ticket
        {
            decision: true,
            outcome: 'approved',
            comment: 'The request has been approved by ' + props.user.displayName + '.' + nextAssignee,
        },
        allComments, // allComments
    )

    setTickets([...ticks]);
    setTicketDetailOpen(ticks[index]); // update the current ticket that is open.

    // If a comment was included, then ensure to post it
    if(commentValue.length > 0) {
        handleAddCommentSubmit(commentValue, false);
    }

    // Add an approved comment for tracking purposes
    handleAddCommentSubmit('===APPROVED===', false);

    // Create notification for the creator
    props.createNotification(
        'Request Approved', // notificationType
        newAssignee !== '' ? 'Approval Proceeds' : 'Approval Received', // title
        props.users.filter((u) => u._id === props.user._id)[0].displayName + ' approved ' + ticketDetailOpen.topic, // description
        props.templating ? 
            '/core/' + props.module + '/tasks/approvals' : 
            '/core/' + (props.user.role === 'Counterparty' ? 'counterparty' : recModule) + '/agreement/' + props.rel._id + '/' + props.agrID + '/' + ticketDetailOpen.singleClauseID, // linkTo
        /*props.user.role !== 'Counterparty' ? 
            ('/core/' + props.module + '/tasks/approvals') : 
            ('/core/counterparty/agreement/' + props.rel._id + '/' + props.agrID), // linkTo */
        false, // isRead
        ticketDetailOpen.creationBy, // uid
    )

    // Check to see if the overall clause Approval status can be updated
    let changeTo = 'Approved'; // Where to update the clause status to?

    // check if clause approval status need to be changed, by seeing if any other ticket/approval is open
    tickets.filter((t) => t.singleClauseID === ticketDetailOpen.singleClauseID).forEach((t) => { // Filter on scl in case of "Agreement" ticket overview
        if(t._id !== tid && (t.resolveReason === 'Rejected')){ 
            changeTo = 'Rejected'; // if another ticket is rejected, stick to rejected
        } else if(changeTo !== 'Rejected' && t._id !== tid && (t.ticketStatus === 'Unassigned' || t.ticketStatus === 'Assigned' || t.ticketStatus === 'In Progress')){
            changeTo = 'Open'; // otherwise, if another ticket is still open, change to Open
        }
    })
    if(newAssignee !== '' && changeTo !== 'Rejected') { // there is another approver in this ticket workflow, so still in progress
        changeTo = 'Open'
    } 
    if(changeTo !== clause.approvalStatus) { // If needs to be changed, then change it
        props.toggleCommentApprovalStatus(changeTo, 'approval', clause.singleClauseID)
    }

    handleTicketDetailClose()
}

const handleReject = (e, tid) => {

    // Update the ticket as appropriate
    let lastUpdateDate = new Date().toISOString();
    let wf = ticketDetailOpen.workflow;
    let hasUpdated = false;
    let lastIndex = -1;
   
    wf.forEach((w,i) => { // iterate over the workflow
        if(w.uid === props.user._id && (w.response === undefined || w.response === null) && !hasUpdated) { // worfklow item is for the current approver
            w.responseDate = lastUpdateDate
            w.response = 'Rejected'
            hasUpdated = true;
        } else if(w.uid === props.user._id) {
            lastIndex = i;
        }
    })

    if(!hasUpdated && lastIndex > -1){ // There was/were already response(s) for this user, overwrite the last one
        wf[lastIndex].responseDate = lastUpdateDate;
        wf[lastIndex].response = 'Rejected';
    }

    // Update ticket for the Approval workflow
    props.updateTicket(
       true, // FAF
       tid, // ticketID
       ticketDetailOpen.module, // module
       'Resolved', // ticketStatus 
       ticketDetailOpen.topic, // topic
       ticketDetailOpen.assignee, // assignee
       props.user._id, // lastUpdateBy 
       lastUpdateDate, // lastUpdateDate
       props.user._id, // closedBy
       lastUpdateDate, // closedDate
       'Rejected', // resolveReason - didn't get resolved
       wf // workflow
    )

    // Update local 'tickets'
    let ticks = tickets
    const index = ticks.findIndex(t => t._id === tid)
    ticks[index] = { ...ticks[index], 
        ticketStatus: 'Resolved', 
        lastUpdateBy: props.user._id,
        lastUpdateDate: lastUpdateDate,
        closedBy: props.user._id, // closedBy
        closedDate: lastUpdateDate, // closedDate
        resolveReason: 'Rejected', 
        workflow: wf,
    }

    let allComments = commentValue.length > 0 ?
        comments.concat({
            creationDate: new Date().toISOString(),
            ticketID: tid,
            comment: commentValue,
            creatorDisplayName: props.user.displayName,
            creatorID: props.user._id,
        }) : comments

    let sa = ticketDetailOpen.topic.split('|')
    let recModule = getRecipientModule(props.user, props.users.filter((u) => u._id === ticketDetailOpen.creationBy)[0], props.module, props.rel)
    // Email the creator on the decision
    sendApprovalEmailToUser (
        'approvaldecision', 
        recModule, // module
        ['templating','terms'].includes(ticketDetailOpen.relID) ? 
            { 
                isTemplating: true, 
                isTerms: ['terms'].includes(ticketDetailOpen.relID), 
                oatid: ticketDetailOpen.agrVersion, 
            } : 
            props.rel, // rel
        orgurl, // orgurl
        ticketDetailOpen.agrID, // agrID
        clause, // clause
        props.users.filter((u) => u._id === ticketDetailOpen.creationBy)[0], 
        sa[2].trim(), 
        sa[1].trim(), 
        props.user.displayName, 
        ticks[index], // ticket
        {
            decision: true,
            outcome: 'rejected',
            comment: 'The request has been rejected by ' + props.user.displayName + '.',
        },
        allComments, // allComments
    )

    setTickets([...ticks]);
    setTicketDetailOpen(ticks[index]); // update the current ticket that is open.

    // If a comment was included, then ensure to post it
    if(commentValue.length > 0) {
        handleAddCommentSubmit(commentValue, false);
    }

    // Add a rejected comment for tracking purposes
    handleAddCommentSubmit('===REJECTED===', false);

    // Create notification for the creator
    props.createNotification(
        'Request Rejected', // notificationType
        'Request Rejected', // title
        props.users.filter((u) => u._id === props.user._id)[0].displayName + ' rejected ' + ticketDetailOpen.topic, // description
        props.templating ? 
            '/core/' + props.module + '/tasks/approvals' : 
            '/core/' + (props.user.role === 'Counterparty' ? 'counterparty' : recModule) + '/agreement/' + props.rel._id + '/' + props.agrID + '/' + ticketDetailOpen.singleClauseID, // linkTo
        /*props.user.role !== 'Counterparty' ? 
            ('/core/' + props.module + '/tasks/approvals') : 
            ('/core/counterparty/agreement/' + props.rel._id + '/' + props.agrID), // linkTo */
        false, // isRead
        ticketDetailOpen.creationBy, // uid
    )

    if('Rejected' !== clause.approvalStatus) { // If needs to be changed, then change it
        props.toggleCommentApprovalStatus('Rejected', 'approval', clause.singleClauseID)
    }

    handleTicketDetailClose()
}

// Only applicable if you're on page and opening from the sideBar
const handleScroll = (e, sclid) => {
    // you get the single clause id, need to find the clid since that's the ref of the element
    if(props.clauses !== undefined && props.clauses !== null && props.clauses.filter((c) => c.singleClauseID === sclid)[0] !== undefined) {
        props.scrollTo(props.clauses.filter((c) => c.singleClauseID === sclid)[0]._id)
        closeDialog()
    } else {
        // the clause may no longer be in the agreement
    }

}

const handleAddCommentSubmit = (comment, fromUser) => {

    if(comment.length > 0){

        let creationDate = new Date().toISOString();
        axios.post('/comment/comment', {
            creatorID: props.user._id, // creatorID
            creatorDisplayName: props.users.filter((u) => u._id === props.user._id)[0].displayName, // creatorDisplayName
            orgID: props.users.filter((u) => u._id === props.user._id)[0].orgID, // orgID
            entityID: ['templating','terms'].includes(ticketDetailOpen.relID) ? '' : props.entID, // entityID
            creationDate: creationDate, // creationDate
            comment: comment, // comment
            commentType: 'ticket', // commentType
            agrID: ticketDetailOpen.agrID, // agrID
            singleClauseID: clause.singleClauseID, // singleClauseID
            ticketID: ticketDetailOpen._id, // ticketID
            relID: ['templating','terms'].includes(ticketDetailOpen.relID) ? '' : props.rel._id, // relID 
        }).then((res) => {

            // Add comment to array
            setComments(comments => [...comments, res.data.data])

            let recipient = props.user._id === ticketDetailOpen.assignee ? 
                props.users.concat(newlyCreated).filter((u) => u._id === ticketDetailOpen.creationBy)[0] : 
                props.users.concat(newlyCreated).filter((u) => u._id === ticketDetailOpen.assignee)[0]

            let recModule = getRecipientModule(props.user, recipient, props.module, props.rel)

            // Create notification for recipient
            props.createNotification(
                'Request Reply', // notificationType
                'Reply on Approval request', // title
                props.user.displayName + ' replied on the approval request: ' + ticketDetailOpen.topic, // description
                props.templating ? 
                    '/core/' + props.module + '/tasks/approvals' : 
                    '/core/' + (props.user.role === 'Counterparty' ? 'counterparty' : recModule) + '/agreement/' + props.rel._id + '/' + props.agrID + '/' + ticketDetailOpen.singleClauseID, // linkTo
                /*props.user.role !== 'Counterparty' ? 
                    ('/core/' + props.module + '/tasks/approvals') : 
                    ('/core/counterparty/agreement/' + props.rel._id + '/' + props.agrID), // linkTo */
                false, // isRead
                recipient._id, // uid
            )

            // Send email to recipient of comment - with comment
            if(fromUser) {
                let sa = ticketDetailOpen.topic.split('|')
                sendApprovalEmailToUser (
                    'replyonapproval', 
                    recModule, // module
                    ['templating','terms'].includes(ticketDetailOpen.relID) ? 
                        { 
                            isTemplating: true, 
                            isTerms: ['terms'].includes(ticketDetailOpen.relID), 
                            oatid: ticketDetailOpen.agrVersion, 
                        } : 
                        props.rel, // rel
                    orgurl, // orgurl
                    ticketDetailOpen.agrID, // agrID
                    clause, // clause
                    recipient, 
                    sa[2].trim(), 
                    sa[1].trim(), 
                    props.user.displayName, 
                    ticketDetailOpen, 
                    res.data.data,
                    comments.concat(res.data.data)) // allComments
            }

            // Find the local ticket
            let ticks = tickets
            const index = ticks.findIndex(t => t._id === ticketDetailOpen._id)
            let lastUpdateDate = new Date().toISOString();

            // Update ticket status to "in progress" IF the assignee replies
            if(ticketDetailOpen.ticketStatus === 'Assigned' && props.user._id === ticketDetailOpen.assignee && fromUser) {

                props.updateTicket(
                    //props.ticketFromApprovalScreen ? false : true, // FAF
                    true, // FAF
                    ticketDetailOpen._id, // ticketID
                    ticketDetailOpen.module, // module
                    'In Progress', // ticketStatus 
                    ticketDetailOpen.topic, // topic
                    ticketDetailOpen.assignee, // assignee
                    props.user._id, // lastUpdateBy 
                    lastUpdateDate, // lastUpdateDate
                    ticketDetailOpen.closedBy, // closedBy
                    ticketDetailOpen.closedDate, // closedDate
                    ticketDetailOpen.resolveReason, // resolveReason - didn't get resolved
                    ticketDetailOpen.workflow // workflow
                )
                // Update local 'tickets'
                ticks[index] = { ...ticks[index], 
                    ticketStatus: 'In Progress', 
                    lastUpdateBy: props.user._id,
                    lastUpdateDate: lastUpdateDate,
                }

            } else {
                // update Ticket lastupdatedate / by
                props.lastUpdateTicket(ticketDetailOpen._id, props.user._id)
                // Update local 'tickets'
                ticks[index] = { ...ticks[index], 
                    lastUpdateBy: props.user._id,
                    lastUpdateDate: lastUpdateDate,
                }
            }

            setTickets([...ticks]);
            setTicketDetailOpen(ticks[index]); // update the current ticket that is open.

            // Empty comment field
            setCommentValue('');            

        }).catch((err) => console.log(err))

    }
}

const handleConfirmChanges = () => {
    let workflow = []
    if(stagedApproval.users.length > 0) {
        let assDate = new Date().toISOString();
        stagedApproval.users.forEach((u) => {
            if(props.user.role === 'Counterparty') { 
                props.updateAidForUserAgrIDs('insert', u, props.parentAID !== undefined && props.parentAID !== null && props.parentAID !== "" ? props.parentAID : props.agrID); 
            } // push AID to give access for user
            workflow.push({
                uid: u,
                assignedDate: assDate,
            })
            assDate = null; // assign to null for next assignees
        })

        // todo from here: when you are creating an approval request for a Tempalte, need to set fields appropriate and agrID to "bp_%blueprintID%"
        let recModule = getRecipientModule(props.user, props.users.concat(newlyCreated).filter((u) => u._id === stagedApproval.users[0])[0], props.module, props.rel)
        let creationDate = new Date().toISOString();
        axios.post('/ticket/ticket', {
            //module: todo: props.module,
            module: recModule,
            ticketStatus: 'Assigned',
            ticketType: 'Approval',
            orgID: props.user.orgID,
            relID: props.templating && (props.activeOat.external || props.activeOat.draftFor === 'Terms') ? 'terms' : props.templating ? 'templating' : props.rel._id,
            entID: props.templating ? '' : props.entID,
            agrID: props.templating ? ("bp_" + props.blueprintID) : props.agrID,
            agrVersion: props.templating ? (props.activeOat._id) : props.avID,
            singleClauseID: clause.singleClauseID,
            clauseID: clause._id,
            topic: stagedApproval.topic,
            assignee: stagedApproval.users[0],
            creationBy: props.user._id,
            creationDate: creationDate,
            lastUpdateBy: props.user._id,
            lastUpdateDate: creationDate,
            closedBy: '',
            closedDate: '',
            resolveReason: 'None',
            workflow: workflow,
        })
        .then(function (res) { // got a msg from the server
            
            // Now create the comment for this specific ticket
            axios.post('/comment/comment', {
                creatorID: props.user._id, // creatorID
                creatorDisplayName: props.user.displayName, // creatorDisplayName
                orgID: props.user.orgID, // orgID
                entityID: props.templating ? '' : props.entID, // entityID
                creationDate: creationDate, // creationDate
                comment: stagedApproval.comment, // comment
                commentType: 'ticket', // commentType
                agrID: props.templating ? ("bp_" + props.blueprintID) : props.agrID, // agrID
                singleClauseID: clause.singleClauseID, // singleClauseID
                ticketID: res.data.data._id, // ticketID
                relID: props.templating ? '' : props.rel._id, // relID 
            }).then((resc) => {

                props.createNotification(
                    'Approval Request', // notificationType
                    'Approval Request', // title
                    props.user.displayName + ' requests your approval on ' + stagedApproval.topic, // description
                    props.templating ? 
                        '/core/' + props.module + '/tasks/approvals' : 
                        '/core/' + (props.user.role === 'Counterparty' ? 'counterparty' : recModule) + '/agreement/' + props.rel._id + '/' + props.agrID + '/' + clause.singleClauseID, // linkTo
                    /*props.user.role !== 'Counterparty' ? 
                        ('/core/' + props.module + '/tasks/approvals') : 
                        ('/core/counterparty/agreement/' + props.rel._id + '/' + props.agrID), // linkTo */
                    false, // isRead
                    stagedApproval.users[0], // uid
                )
        
                let sa = stagedApproval.topic.split('|')
                sendApprovalEmailToUser (
                    'approvalrequest', 
                    recModule, // module 
                    props.templating ? 
                        { 
                            isTemplating: true, 
                            isTerms: (props.activeOat.external || props.activeOat.draftFor === 'Terms'), 
                            oatid: props.activeOat._id 
                        } : 
                        props.rel, // rel
                    orgurl, // orgurl
                    props.agrID, // agrID
                    clause, // clause
                    props.users.concat(newlyCreated).filter((u) => u._id === stagedApproval.users[0])[0], 
                    sa[2].trim(), 
                    sa[1].trim(), 
                    props.user.displayName, 
                    res.data.data, // the ticket
                    resc.data.data, // the comment
                    []) // allComments
        
                // If the clause approvalStatus !== 'Open' then ensure to set it to Open (since a new one is being created)
                if(clause.approvalStatus !== 'Open') {
                    props.toggleCommentApprovalStatus('Open', 'approval', clause.singleClauseID)
                }
        
                closeDialog(); // close the dialog

            }).catch(function(err) { console.log(err) })
        }).catch(function(err) { console.log(err) })
    }
}

const handleChangeApprovers = (e, tid) => {
    setStagedChange({tid: tid, workflow: ticketDetailOpen.workflow, radioOption: 'none'})
}

const handleCancelApprovalRequest = (e, tid) => {

    // Find out if the approval status for the clause needs to be changed
    let changeClauseApprovalStatus = true;
    // Check if clause approval status need to be changed, by seeing if any other ticket/approval is open
    tickets.forEach((t) => {
        if(t._id !== tid && (t.ticketStatus !== 'Cancelled' && t.ticketStatus !== 'Resolved' && t.ticketStatus !== 'Closed')){
            changeClauseApprovalStatus = false;
        }
    })
    if(changeClauseApprovalStatus) { // If needs to be changed, then change it
        props.toggleCommentApprovalStatus('None', 'approval', clause.singleClauseID)
    }

    // Update the ticket and set to Cancel
    let lastUpdateDate = new Date().toISOString();
    props.updateTicket(
        true, // FAF
        tid, // ticketID
        ticketDetailOpen.module, // module
        'Cancelled', // ticketStatus 
        ticketDetailOpen.topic, // topic
        ticketDetailOpen.assignee, // assignee
        props.user._id, // lastUpdateBy 
        lastUpdateDate, // lastUpdateDate
        props.user._id, // closedBy
        lastUpdateDate, // closedDate
        'None', // resolveReason - didn't get resolved
        ticketDetailOpen.workflow // workflow
    )

    // Update local 'tickets'
    let ticks = tickets
    const index = ticks.findIndex(t => t._id === tid)
    ticks[index] = { ...ticks[index], 
        ticketStatus: 'Cancelled', 
        lastUpdateBy: props.user._id,
        lastUpdateDate: lastUpdateDate,
        closedBy: props.user._id,
        closedDate: lastUpdateDate,
        resolveReason: 'None', 
    }
    setTickets([...ticks]);
    setTicketDetailOpen(ticks[index]); // update the current ticket that is open.

    // Add a cancelled comment for tracking purposes
    handleAddCommentSubmit('===CANCELLED===', false);

    let recipient = props.users.concat(newlyCreated).filter((u) => u._id === ticketDetailOpen.assignee)[0]
    // Create notification for the assignee
    props.createNotification(
        'Request Cancelled', // notificationType
        'Approval Request Cancelled', // title
        props.user.displayName + ' cancels the request on ' + ticketDetailOpen.topic, // description
        props.templating ? 
            '/core/' + props.module + '/tasks/approvals' : 
            '/core/' + (props.user.role === 'Counterparty' ? 'counterparty' : getRecipientModule(props.user,recipient,props.module, props.rel)) + '/agreement/' + props.rel._id + '/' + props.agrID + '/' + ticketDetailOpen.singleClauseID, // linkTo
        /*props.user.role !== 'Counterparty' ? 
            ('/core/' + props.module + '/tasks/approvals') : 
            ('/core/counterparty/agreement/' + props.rel._id + '/' + props.agrID), // linkTo */
        false, // isRead
        ticketDetailOpen.assignee, // uid
    )
}

const handleReopenApprovalRequest = (e, tid) => {
    
    // Find out if the approval status for the clause needs to be changed
    if(clause.approvalStatus !== 'Open') { // If needs to be changed, then change it
        props.toggleCommentApprovalStatus('Open', 'approval', clause.singleClauseID)
    }

    // Update the ticket and set to Assigned
    let lastUpdateDate = new Date().toISOString();
    props.updateTicket(
        true, // FAF
        tid, // ticketID
        ticketDetailOpen.module, // module
        'Assigned', // ticketStatus 
        ticketDetailOpen.topic, // topic
        ticketDetailOpen.assignee, // assignee
        props.user._id, // lastUpdateBy 
        lastUpdateDate, // lastUpdateDate
        null, // closedBy
        null, // closedDate
        'None', // resolveReason - didn't get resolved
        ticketDetailOpen.workflow // workflow
    )

    // Update local 'tickets'
    let ticks = tickets
    const index = ticks.findIndex(t => t._id === tid)
    ticks[index] = { ...ticks[index], 
        ticketStatus: 'Assigned', 
        lastUpdateBy: props.user._id,
        lastUpdateDate: lastUpdateDate,
        closedBy: null,
        closedDate: null,
        resolveReason: 'None', 
    }
    setTickets([...ticks]);
    setTicketDetailOpen(ticks[index]); // update the current ticket that is open.

    // Add a cancelled comment for tracking purposes
    handleAddCommentSubmit('===REOPENED===', false);

    let recipient = props.users.concat(newlyCreated).filter((u) => u._id === ticketDetailOpen.assignee)[0]
    // Create notification for the assignee
    props.createNotification(
        'Request Reopened', // notificationType
        'Approval Request Reopened', // title
        props.user.displayName + ' reopened the request on ' + ticketDetailOpen.topic, // description
        props.templating ? 
            '/core/' + props.module + '/tasks/approvals' : 
            '/core/' + (props.user.role === 'Counterparty' ? 'counterparty' : getRecipientModule(props.user,recipient,props.module, props.rel)) + '/agreement/' + props.rel._id + '/' + props.agrID + '/' + ticketDetailOpen.singleClauseID, // linkTo
        /*props.user.role !== 'Counterparty' ? 
            '/core/' + props.module + '/tasks/approvals' : ('/core/counterparty/agreement/' + props.rel._id + '/' + props.agrID), // linkTo */
        false, // isRead
        ticketDetailOpen.assignee, // uid
    )
}

const handleCommentSubmit = (isBlocks, comment, newStatus) => {

    let commentIsValid = comment !== null && comment !== undefined && ((!isBlocks && comment !== '') || (isBlocks && comment !== {} && comment.blocks !== undefined && comment.blocks.some((b) => b.text !== '')))

    if(Boolean(ticketDetailOpen) && commentIsValid) {
        handleAddCommentSubmit(convertBlocksToPlainComment(comment), true)

    } else if(userValue !== {} && userValue !== null && userValue !== undefined && topicValue !== null && commentIsValid){
        setStagedApproval({
            // agrFullName is only known if you come from the Agr Page (not if you come through Tasks)
            topic: 
                topicValue + ' | ' + 
                (props.agrFullName !== null ? (props.agrFullName + " | ") : ' Agreement | ') + 
                (props.templating && (props.activeOat.external || props.activeOat.draftFor === 'Terms') ? 'Terms' : props.templating ? 'Template' : props.cptyName) + " | " + 
                (clause.clauseCat === 'table' ? 'table' : trunc(stripPlain(clause.plain[0]), 50)),
            comment: isBlocks ? convertBlocksToPlainComment(comment) : comment,
            users: [userValue._id]
        })
        setUserValue({})
        handleAddApprovalClose()
    }
}

const callbackUserForm = (child) => {

    if (props.users.concat(newlyCreated).filter((u) => u.email === child.email)[0] === undefined) { // Does not yet exist in the userBase

        axios.get('/user/protect/user/mail/' + child.email)
        .then(function(res) {
            // User found, craft warning message
            //if(res.data.data.role === 'Counterparty') {
            if(res.data.data !== undefined && res.data.data !== null && res.data.data.role === 'Counterparty') {
                setSubmitError("This user is already setup for a different organisation, please contact canveo support for help.")
            //} else {
            } else if (res.data.data !== undefined && res.data.data !== null) {
                setSubmitError("This user is setup for a Canveo customer, please contact canveo support for help.")
            } else { // User not found - thus add
                let aid = props.parentAID !== undefined && props.parentAID !== null && props.parentAID !== "" ? props.parentAID : props.agrID

                axios.post('/user/protect/user', {
                  orgID: props.user.orgID,
                  firstName: child.firstName,
                  lastName: child.lastName,
                  displayName: child.firstName + ' ' + child.lastName,
                  email: child.email.toLowerCase().trim(),
                  password: generateRandomString(20),
                  title: child.title,
                  role: 'Counterparty',
                  defaultModule: 'counterparty',
                  photoURL: '',
                  creationBy: props.user._id,
                  creationDate: new Date().toISOString(),
                  active: true,
                  agrIDs: [aid],
                })
                .then(function (resuser) { // got a msg from the server
    
                    props.createNotification(
                        'Welcome new User', // notificationType
                        'Welcome to Canveo', // title 
                        child.firstName + ', we\'re excited to have you on board', // description
                        '/core/help/welcome', // linkTo
                        false, // isRead
                        resuser.data.data._id, // uid
                    );
    
                    setNewlyCreated([...newlyCreated, resuser.data.data])
                    handleApproverSelect(resuser.data.data)
                    setAddCollab(null)
                })
                .catch(function (err) { setSubmitError("Something went wrong while creating the user, contact canveo support if the issue persists") })
            }
        })
        .catch(function(err) { console.log(err) }) 

    } else {
        setSubmitError("This user (email address) is already created, you can select it instead.")
    }
}

const RenderAvatar = ({uid, type, noMargins, removeClick}) => { // Same in DialogReview
    return (
        props.users.concat(newlyCreated).filter((usr) => usr._id === uid)[0] !== undefined ? 
        <Avatar src={props.users.concat(newlyCreated).filter((usr) => usr._id === uid)[0].photoURL} 
        className={removeClick !== undefined && removeClick !== null ? classes.userAvDelete :type === "flow" ? classes.userAv : type === "list" ? classes.userAvList : ''}
        onClick={removeClick !== undefined && removeClick !== null ? e => removeClick() : null}
        style={noMargins ? { marginLeft: '0px', marginRight: '0px'} : {}}
        >
        {props.users.concat(newlyCreated).filter((usr) => usr._id === uid)[0].firstName.substr(0,1) + props.users.concat(newlyCreated).filter((usr) => usr._id === uid)[0].lastName.substr(0,1)}
        </Avatar>
        : '' 
    )
}

const RenderFlow = ({t, workflow}) => {
    return (
        <Grid item container direction="row" justify="center" alignItems="center">
            <Grid item>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Grid item>
                        {t.ticketStatus === 'Cancelled' ?
                        <StyledBadgeGrey color="secondary" badgeContent={<FontAwesomeIcon icon={faSlash} />}>
                            <RenderAvatar uid={t.creationBy} type="flow" />
                        </StyledBadgeGrey>
                        :
                        <RenderAvatar uid={t.creationBy} type="flow" />
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <FontAwesomeIcon icon={faCaretRight} className={classes.arrowIcon} />
            </Grid>
            <Grid item>
                <Grid container direction="row" justify="center" alignItems="center">
                        
                    {workflow.map((u,i) => (
                        <Grid item key={i}>
                        {u.uid === t.assignee && t.ticketStatus !== 'Cancelled' && t.ticketStatus !== 'Resolved' && (u.response === undefined || u.response === null) &&
                        workflow.filter((wf) => wf.uid === t.assignee && (wf.response === undefined || wf.response === null))[0]._id === u._id ?
                        <StyledBadge color="secondary" badgeContent={<FontAwesomeIcon icon={faQuestion} />}>
                            <RenderAvatar uid={u.uid} type="flow" />
                        </StyledBadge>
                        :
                        u.response !== undefined && u.response === 'Approved' ?
                        <StyledBadgeApprove color="secondary" badgeContent={<FontAwesomeIcon icon={faThumbsUp} />}>
                            <RenderAvatar uid={u.uid} type="flow" />
                        </StyledBadgeApprove>
                        :
                        u.response !== undefined && u.response === 'Rejected' ?
                        <StyledBadgeDelete color="secondary" badgeContent={<FontAwesomeIcon icon={faThumbsDown} />}>
                            <RenderAvatar uid={u.uid} type="flow" />
                        </StyledBadgeDelete>
                        :
                        <RenderAvatar uid={u.uid} type="flow" />
                        }

                        </Grid>  
                    ))}

                </Grid>
            </Grid>
            <Grid item>
                <FontAwesomeIcon icon={faCaretRight} className={classes.arrowIcon} />
            </Grid>
            <Grid item>
                <Avatar className={t.ticketStatus === 'Cancelled' ? classes.approvedAvGrey : 
                                   t.ticketStatus === 'Resolved' && t.resolveReason === 'Approved' ? classes.approvedAvApproved :
                                   classes.approvedAv}>
                    <FontAwesomeIcon icon={faCheck} />
                </Avatar>
            </Grid>
        </Grid>
    )
}

return (
    <Dialog
    open={props.open}
    scroll="paper"
    TransitionComponent={Transition}
    keepMounted
    onClose={closeDialog}
    fullWidth={true}
    maxWidth="sm"
    >
        <DialogTitle onClose={closeDialog}>
            {"Internal Approvals"}
            {(!Boolean(ticketDetailOpen) && !addApprovalOpen && !Boolean(addApproverOpen) && props.clause !== 'agreement' &&
            clause !== null && !['None', 'Approved', 'Rejected'].includes(clause.approvalStatus)) && !props.plainMode ?
                <Chip size="small"
                label={clause.approvalStatus}
                color={"primary"}
                className={clause.approvalStatus === 'Open' ? classes.chipDefault : classes.chipGrey} />
            :
            Boolean(ticketDetailOpen) && !props.plainMode ?
                <Chip size="small"
                label={['Approved','Rejected'].includes(ticketDetailOpen.resolveReason) ? ticketDetailOpen.resolveReason : ticketDetailOpen.ticketStatus} 
                color={"primary"} 
                className={ticketDetailOpen.ticketStatus === 'Cancelled' ? classes.chipGrey :
                    ticketDetailOpen.resolveReason === 'Approved' ? classes.chipSuccess :
                    ticketDetailOpen.resolveReason === 'Rejected' ? classes.chipError :
                           classes.chipDefault} />
            :
            <>&nbsp;&nbsp;
            <InformationalTooltip msg={"Raising an approval request will send an email to the assigned approver. The email will include the current version of the clause and the latest comment(s) on the approval thread.\n\nThe recipient can directly approve or reject the request from the email or access the agreement to insert any comments or questions."} /></>}
            
        </DialogTitle>

        <DialogContent id="msgContainer">
            <Box /*ml={1} mr={1}*/
            mt={addApproverOpen ? 8 : Boolean(ticketDetailOpen) ? 2 : addApprovalOpen || stagedApproval.topic !== undefined ? 4 : 
                tickets !== null && tickets.filter((t) => t.orgID === props.user.orgID).length > 2 ? 4 : 7} 
            mb={addApproverOpen ? 8 : addApprovalOpen || stagedApproval.topic !== undefined ? 4 : stagedChange !== null ? 3 : 
                tickets !== null && tickets.filter((t) => t.orgID === props.user.orgID).length > 2 ? 4 : 7}>
            
            {loading ?
                <Box align="center">
                <CircularProgress size={34} />
                </Box>
            :
            addCollab ?
            <Grid item xs={12}>
                {submitError !== null ? 
                <Box mt={3} align="center"><Typography color="error" variant="subtitle1">{submitError}</Typography></Box> : '' }

                <Box mt={3} mb={1}>
                <UserForm 
                    parentCallback={callbackUserForm}
                    type="cptyuser"
                    initialValues={{
                        firstName: '', 
                        lastName: '',
                        role: 'Counterparty',
                        title: '',
                        email: '' }} />
                </Box>
            </Grid>
            :
            addApprovalOpen ?
            (
                <Grid container direction="column" justify="flex-start" alignItems="center">
                    <Grid item>
                        <Box className={classes.boxFormSizing} align="left" mt={1} mb={1}>
                            {props.user.role === 'Counterparty' || potentialApprovers.length > 0 ?
                            <SelectUsers
                                users={potentialApprovers}
                                label="Select approver"
                                initialVal={userValue || null}
                                parentCall={handleApproverSelect}
                            />
                            : '' }
                            {props.user.role === 'Counterparty' ?
                            <Box mt={1} align="center">
                                <Button variant="text" color="secondary" onClick={e => setAddCollab(true)}>Add a collaborator</Button>
                            </Box>
                            : '' }
                        </Box>
                    </Grid>
                    {/*
                    <Grid item>
                        <Box className={classes.boxFormSizing} align="left">
                            <TextField
                            variant="outlined"
                            name="topic"
                            onChange={handleTopicChange}
                            value={topicValue}
                            label="Subject"
                            placeholder="Add subject..."
                            fullWidth
                            />
                        </Box>
                    </Grid>
                    */}
                    <Grid item>
                        {/*
                        <Box className={classes.boxFormSizing} align="left" mt={0}>
                            <TextareaAutosize
                            className={classes.textAreaAuto}
                            name="addComment"
                            rowsMin={4}
                            rowsMax={15}
                            placeholder="Add comment for approver..."
                            value={commentValue}
                            onChange={handleCommentChange}
                            />
                        </Box>*/}
                        <Grid direction="row" container justify={"center"} alignItems="flex-start">
                            <Grid item className={classes.boxFormSizing}>
                            <MentionEditor
                                user={props.user}
                                users={[]}
                                handleCommentSubmit={handleCommentSubmit}
                                editorType={"clean"}
                                curCpty={"primary"}
                                isPublic={false}
                                isApprovalStyling={true}
                            />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
            :
            stagedApproval.topic !== undefined ? 
            (
            <>
                <Grid container direction="column" justify="flex-start" alignItems="center">
                    {/*<Grid item>
                        <Box mb={2} align="center">
                            <Typography align="center" variant="h5">
                                {stagedApproval.topic.split('|')[0].trim()}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box mb={2} align="center">
                            <Typography align="left" variant="body2" className={classes.comment}>
                                {stagedApproval.comment.split('\n').map((cpar,i) => ( <React.Fragment key={i}>{cpar}<br/></React.Fragment> ))}
                            </Typography>
                        </Box>
                    </Grid>*/}

                    {!Boolean(addApproverOpen) ? 
                    (
                    <Grid item>
                        <Grid container direction="row" alignItems="center" justify="center">
                            <Grid item>
                                <RenderAvatar uid={props.user._id} type="flow" noMargins={stagedApproval !== undefined && stagedApproval.users !== undefined && stagedApproval.users.length > 1} />
                            </Grid>
                            <Grid item>
                                <IconButton onClick={e => handleAddApprover(e, 'start')} disabled={potentialApprovers === undefined || potentialApprovers === null || potentialApprovers.length === 0}>
                                    <FontAwesomeIcon icon={faPlusCircle} className={classes.plusAv} />
                                </IconButton>
                            </Grid>
                            {
                            stagedApproval.users.length > 0 ? (
                            stagedApproval.users.map((uid, i) => (
                            <React.Fragment key={i}>
                            <Grid item>
                                    <Grid item>
                                        <RenderAvatar uid={uid} type="flow" noMargins={stagedApproval !== undefined && stagedApproval.users !== undefined && stagedApproval.users.length > 1} 
                                        removeClick={e => handleRemoveUser(e, i)}
                                        />
                                    </Grid>                               
                            </Grid>
                            <Grid item>
                                <IconButton onClick={e => handleAddApprover(e, uid)} disabled={potentialApprovers === undefined || potentialApprovers === null || potentialApprovers.length === 0}>
                                    <FontAwesomeIcon icon={faPlusCircle} className={classes.plusAv} />
                                </IconButton>
                            </Grid>
                            </React.Fragment>
                            ))
                            ): '' 
                            }
                            <Grid item>
                                <Avatar className={classes.approvedAv} style={stagedApproval !== undefined && stagedApproval.users !== undefined && stagedApproval.users.length > 1 ? { margin: '0px'} : {}}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Avatar>
                            </Grid>
                        </Grid>
                        <Box>
                        <Grid item>
                            <Box align="center">
                                <Box align="right" display={{ xs: 'none', sm: 'inline-block' }}>
                                <div style={{width: '10px', marginTop: '6px'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                    <g fill={curCpty === "primary" ? theme.palette.primary.main : theme.palette.secondary.main}><polygon points={"40 0, 100 100, 100 0"}/></g>
                                </svg>
                                </div>
                                </Box>
                                <Box className={classes.cardPrimaryCpty} mt={2} mb={3} style={curCpty === "primary" ? {display:'inline-block'} : {display:'inline-block', borderLeft: '4px solid ' + theme.palette.secondary.main}}>
                                <Typography align="left" variant="body1">
                                    {stagedApproval.comment.split('\n').map((cpar,i) => ( <React.Fragment key={i}>{cpar}<br/></React.Fragment> ))}
                                </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        </Box>
                    </Grid>
                    )
                    :
                    ( // addApproverOpen
                    <Grid item>
                        <Box className={classes.boxFormSizing} align="left" mt={3} mb={3}>
                            <SelectUsers
                                users={potentialApprovers}
                                label="Select approver"
                                initialVal={userValue || null}
                                parentCall={handleApproverSelect}
                            />
                        </Box>

                    </Grid>
                    )}
                </Grid>
            </>
            )
            :
            Boolean(ticketDetailOpen) ?
            (
                <>
                <Grid container direction="column" justify="flex-start" alignItems="center">
                    <Grid item>
                        {props.clause === 'agreement' && tickets !== null && tickets.filter((t) => t.orgID === props.user.orgID).length > 1 ?
                        <Box mb={2} align="center">
                            <Button color="primary" variant="text" onClick={e => handleTicketDetailOpen(e, tickets.filter((t) => t.orgID === props.user.orgID)[tickets.filter((t) => t.orgID === props.user.orgID).findIndex((t) => t._id === ticketDetailOpen._id) > 0 ? 
                                    tickets.filter((t) => t.orgID === props.user.orgID).findIndex((t) => t._id === ticketDetailOpen._id) - 1 : 
                                    tickets.filter((t) => t.orgID === props.user.orgID).length - 1]._id)}>
                                <FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Previous Ticket</Button>&nbsp;&nbsp;
                            <Button color="primary" variant="text" onClick={e => handleTicketDetailOpen(e, tickets.filter((t) => t.orgID === props.user.orgID)[tickets.filter((t) => t.orgID === props.user.orgID).findIndex((t) => t._id === ticketDetailOpen._id) < tickets.filter((t) => t.orgID === props.user.orgID).length - 1 ? 
                                    tickets.filter((t) => t.orgID === props.user.orgID).findIndex((t) => t._id === ticketDetailOpen._id) + 1 : 
                                    0]._id)}>
                                Next Ticket&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} /></Button>
                        </Box>
                        :''
                        //.
                        }
                        <Box mt={0} mb={2}>
                            <RenderFlow t={ticketDetailOpen} workflow={stagedChange !== undefined && stagedChange !== null && stagedChange.workflow !== undefined ? stagedChange.workflow : ticketDetailOpen.workflow} />
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box align="left" width={300} mb={(props.ticketFromApprovalScreen === null && props.clause !== 'agreement') || stagedChange !== null ? 3 : 0}>
                        {!['templating','terms'].includes(ticketDetailOpen.relID) ?
                        <Typography align="center" variant="subtitle2" className={classes.bold}>
                            Counterparty: <span className={classes.topicText}>{trunc(ticketDetailOpen.topic.split('|')[2].trim(), 28)}</span>
                        </Typography>
                        :''}
                        <Typography align="center" variant="subtitle2" className={classes.bold}>
                            {['terms'].includes(ticketDetailOpen.relID) ? "Terms" :
                             ['templating'].includes(ticketDetailOpen.relID) ? "Template" : "Agreement"}: <span className={classes.topicText}>{trunc(ticketDetailOpen.topic.split('|')[1].trim(), 28)}</span>
                        </Typography>
                        </Box>
                    </Grid>

                    {// If you are staging changes to the approval workflow
                    stagedChange !== null ?
                    <>
                    <Box className={classes.boxFormSizing} mt={1}>
                    <FormControl component="fieldset">
                    <RadioGroup name="loginType" value={stagedChange.radioOption} onChange={e => setStagedChange({...stagedChange, radioOption: e.target.value})}>
                        <FormControlLabel value="reassignapprover" control={<Radio />} 
                            label={<Typography variant="body1">Reassign to a different user</Typography>} />
                        <FormControlLabel value="insertbefore" control={<Radio />} 
                            label={<Typography variant="body1">Insert approver before {ticketDetailOpen.assignee === props.user._id ? "me" : "assignee"}</Typography>} />
                        <FormControlLabel value="insertafter" control={<Radio />} 
                            label={<Typography variant="body1">Insert approver after {ticketDetailOpen.assignee === props.user._id ? "me" : "assignee"}</Typography>} />

                        </RadioGroup>
                    </FormControl>
                    </Box>
                    <Box className={classes.boxFormSizing} align="left" mt={3} mb={3}>
                        <SelectUsers
                            users={potentialApprovers.filter((pa) => pa._id !== ticketDetailOpen.creationBy && (!['terms', 'templating'].includes(ticketDetailOpen.relID) || ['Admin', 'Legal'].includes(pa.role)))}
                            label="Select new approver"
                            initialVal={userValue || null}
                            parentCall={handleApproverSelect}
                        />
                    </Box>
                    </>
                    :(props.ticketFromApprovalScreen !== null || props.clause === 'agreement') && clause !== null ?
                    (
                        <>
                        {clauseDetail ?
                        (
                            <Grid item>
                                <Box align="center" mt={3} mb={1}>
                                    {/*
                                    <Typography align="center" variant="subtitle1" gutterBottom className={classes.bold}>
                                        {clause.title}
                                    </Typography>*/}
                                    <Box align="left" className={classes.draftBox}>
                                    {clause.clauseCat === 'table' ?
                                    <TableEditable
                                    active={false}
                                    disabled={true}
                                    editMode={'none'}
                                    clause={clause}
                                    proposedDel={clause.pendingChange.filter((pc) => pc.status === 'Open' && pc.type === 'proposedDel')[0] !== undefined ?
                                        clause.pendingChange.filter((pc) => pc.status === 'Open' && pc.type === 'proposedDel')[0].changeOrg : null}
                                    focusClause={() => void 0}
                                    curCpty={"primary"}
                                    />
                                    :
                                    <NegoCardEditor 
                                    clid={clause._id}
                                    curCpty={"primary"}
                                    user={props.user}
                                    content={clause !== undefined ? clause.blocks : {}}
                                    parentCallback={() => void 0}
                                    callbackOptions={() => void 0}
                                    reviewChangesMode={false}
                                    activeClause={false}
                                    optionClicked={null}
                                    focusClause={() => void 0}
                                    disableEditing={true} />
                                    }
                                    </Box>

                                </Box>
                            </Grid>
                        ) : '' }
                        <Box align="center" mt={0} mb={3}>
                            <Button
                                size="small"
                                color="primary"
                                className={classes.buttonResponse}
                                onClick={toggleClauseDetail}
                            >{clauseDetail ? "Hide clause" : "Show clause"}&nbsp;&nbsp;<FontAwesomeIcon icon={clauseDetail ? faChevronUp : faChevronDown} /></Button>

                            <Button
                                size="small"
                                color="primary"
                                className={classes.buttonResponse}
                                onClick={
                                    ['templating', 'terms'].includes(ticketDetailOpen.relID) ? // push to template
                                        e => history.push('/core/legal/templates/' + ticketDetailOpen.module + (['terms'].includes(ticketDetailOpen.relID) ? '/edit-terms/' : '/edit/') + ticketDetailOpen.agrVersion + "/" + ticketDetailOpen.singleClauseID) :
                                    props.clause === 'agreement' && ticketDetailOpen.agrID === props.agrID ? // only scroll if you're on the page (and not in an exhbiit)
                                        e => handleScroll(e, ticketDetailOpen.singleClauseID) :
                                        e => history.push("/core/" + ticketDetailOpen.module + "/agreement/" + props.rel._id + "/" + ticketDetailOpen.agrID + "/" + ticketDetailOpen.singleClauseID)
                                }
                                /*component={RouterLink}
                                to={['templating', 'terms'].includes(ticketDetailOpen.relID) ?
                                         :
                                        }*/
                            >{['terms'].includes(ticketDetailOpen.relID) ?
                                    "View Terms" :
                              ['templating'].includes(ticketDetailOpen.relID) ?
                                    "View Template" :
                                    "Go to clause"}&nbsp;&nbsp;<FontAwesomeIcon icon={props.clause === 'agreement' ? faArrowRight : faExternalLinkAlt} /> </Button>
                        </Box>
                        </>
                    )
                    : ''}
                </Grid>
                {stagedChange === null ?
                <Grid container direction="column" justify="flex-start" alignItems="stretch">
                    <Grid item>

                        {(props.user._id === ticketDetailOpen.creationBy || props.user._id === ticketDetailOpen.assignee) && 
                            ticketDetailOpen.ticketStatus !== 'Cancelled' && 
                            (ticketDetailOpen.resolveReason !== 'Approved') ?
                        (
                        <Grid item>
                            <Box mb={3}>
                            <Grid direction="row" container justify="flex-start" alignItems="flex-start">
                                <Grid item>
                                    <Box display={{ xs: 'none', sm: 'block' }}>
                                    <AvatarInitials
                                    displayName={props.user !== undefined && props.user !== null && props.user.displayName !== undefined ? props.user.displayName : '?'}
                                    photoURL={props.user !== undefined && props.user.photoURL !== undefined && props.user.photoURL !== '' ? props.user.photoURL : null }
                                    variant={'left'}
                                    />
                                    </Box>
                                </Grid>
                                <Grid item className={classes.additionalGridItem}>
                                <MentionEditor
                                    user={props.user}
                                    users={[]}
                                    handleCommentSubmit={handleCommentSubmit}
                                    editorType={"additional"}
                                    curCpty={curCpty}
                                    isPublic={false}
                                />
                                </Grid>
                            </Grid>
                            </Box>
                            {/*
                            <Grid container direction="column" alignItems={props.user._id === ticketDetailOpen.assignee ? "flex-end" : "flex-start"} justify="flex-start">
                                <Grid item>
                                    <Box className={classes.boxFormSizingAlignsWithCardComment} align="left" mt={0} mb={0}>
                                        <TextareaAutosize
                                        className={classes.textAreaAuto}
                                        name="addComment"
                                        rowsMin={2}
                                        rowsMax={15}
                                        placeholder="Add reply..."
                                        value={commentValue}
                                        onChange={handleCommentChange}                                        
                                        />
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box mb={2}>
                                    <Button color={"primary"} variant="contained" disableElevation size="small"
                                    onClick={updating !== undefined && updating !== null ? e => handleUpdateCommentSubmit(commentValue) : e => handleAddCommentSubmit(commentValue, true)} 
                                    className={classes.boxFormSizingAlignsWithCardComment}>
                                        {updating !== undefined && updating !== null ? "Update Comment" : "Add Comment"}
                                    </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            */}
                        </Grid>
                        ) : '' }

                        {comments
                        .sort((a,b) => (a.creationDate < b.creationDate) ? 1 : ((b.creationDate < a.creationDate) ? -1 : 0))
                        .filter((c) => c.ticketID === ticketDetailOpen._id).map((c) => (
                            <CommentDisplayBox
                                key={c._id}
                                c={c}
                                align={c.creatorID === props.user._id ? 'left' : 'right'}
                                user={props.users.filter((u) => u._id === c.creatorID)[0]}
                                //updating={updating !== undefined && updating !== null && updating === c._id}
                                updating={c._id === updating ? updating : null}
                                canEdit={c.creatorID === props.user._id && 
                                    ['Unassigned', 'Assigned', 'In Progress'].includes(ticketDetailOpen.ticketStatus) ? true : false}
                                color={['terms', 'templating'].includes(ticketDetailOpen.relID) ? "primary" : curCpty}
                                setUpdating={setUpdating}
                                handleDeleteComment={handleDeleteComment}
                                handleCommentChangeSubmit={handleCommentChangeSubmit}
                            />
                        ))

                        /*comments
                        .sort((a,b) => (a.creationDate < b.creationDate) ? 1 : ((b.creationDate < a.creationDate) ? -1 : 0))
                        .filter((c) => c.ticketID === ticketDetailOpen._id).map((c) => (
                            <Grid item key={c._id}>

                            {['===CANCELLED===', '===APPROVED===', '===REJECTED===', '===REOPENED===', '===REASSIGNED==='].includes(c.comment) ?
                            (
                                <Box width="100%" align="center" mt={1} mb={1}>
                                    <Typography align="center" variant="subtitle2" color="textSecondary" className={classes.smallOnXS}>
                                        {c.comment === '===CANCELLED===' ? "Cancelled" :
                                        c.comment === '===APPROVED===' ? "Approved" :
                                        c.comment === '===REJECTED===' ? "Rejected" : 
                                        c.comment === '===REOPENED===' ? "Reopened" : 
                                        c.comment === '===REASSIGNED===' ? "Reassigned" : "Updated" } on {dayjs(c.creationDate).format('MMM D, YYYY h:mm A')} by {c.creatorDisplayName}
                                    </Typography>
                                </Box>
                            )
                            :
                            (
                                <Box align={c.creatorID === ticketDetailOpen.creationBy ? "left" : "right"} mt={0}>
                                    <CardComment
                                        comment={c}
                                        editOptions={c.creatorID === props.user._id && 
                                            ['Unassigned', 'Assigned', 'In Progress'].includes(ticketDetailOpen.ticketStatus) ? true : false}
                                        handleCommentAction={handleCommentAction}
                                        updating={updating !== undefined && updating !== null && updating === c._id}
                                        secondary={c.creatorID !== ticketDetailOpen.creationBy ? true : false}
                                    />
                                </Box>
                            )}

                            </Grid>
                        ))*/}

                    </Grid>
                </Grid>
                :''}
                </>
            )
            :
            ( 
            // The landing page
            <React.Fragment>

                {props.clause !== 'agreement' && tickets !== null && !props.plainMode &&
                tickets.filter((t) => t.orgID !== props.user.orgID).length > 0 ?
                <Box mb={3} mt={1}>
                <Typography variant="subtitle2" align="center" color={tickets.filter((t) => t.orgID !== props.user.orgID && !['Cancelled', 'Resolved', 'Closed'].includes(t.ticketStatus)).length > 0 ? "textPrimary" : "textSecondary"}>
                    <FontAwesomeIcon icon={faExclamationCircle} className={
                        tickets.filter((t) => t.orgID !== props.user.orgID && !['Cancelled', 'Resolved', 'Closed'].includes(t.ticketStatus)).length > 0 ?
                        classes.warnIcon : classes.warnIconGrey} />
                    {tickets.filter((t) => t.orgID !== props.user.orgID && !['Cancelled', 'Resolved', 'Closed'].includes(t.ticketStatus)).length > 0 ?
                    "NOTE: Counterparty approvals are in progress"
                    :
                    "NOTE: Counterparty approvals were closed"}
                </Typography>
                </Box>
                : '' }

                {tickets !== null &&
                tickets.filter((t) => t.orgID === props.user.orgID).length > 0 ? 
                (
                <Grid container direction="column" justify="center" alignItems="center">
                    <Grid item>
                        <Box mb={2}>
                            <Typography variant="body2" color="textSecondary" align="center">
                                Click on the approval for more detail
                            </Typography>
                        </Box>
                    </Grid>

                    <List>
                    {tickets
                    .filter((t) => t.orgID === props.user.orgID)
                    .map((t) => (

                        <ListItem key={t._id} button onClick={e => handleTicketDetailOpen(e, t._id)} className={classes.boxResizing}>
                            <ListItemAvatar>
                                {t.ticketStatus === 'Cancelled' ?
                                    <Avatar className={classes.approvedAvGreyList}><FontAwesomeIcon icon={faSlash} /></Avatar>
                                :
                                t.ticketStatus === 'Resolved' && t.resolveReason === 'Approved' ?
                                    <Avatar className={classes.approvedAvSuccessList}><FontAwesomeIcon icon={faCheck} /></Avatar>
                                :
                                t.ticketStatus === 'Resolved' && t.resolveReason === 'Rejected' ?
                                    <Avatar className={classes.approvedAvErrorList}><FontAwesomeIcon icon={faTimes} /></Avatar>
                                :
                                    <RenderAvatar uid={t.assignee} type="list" />
                                }
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <>
                                    <span className={classes.bold}>{props.users.filter((u) => u._id === t.assignee)[0] !== undefined ? 
                                    props.users.filter((u) => u._id === t.assignee)[0].displayName : 'Unknown'}:</span>
                                    <span>{trunc(t.topic.split('|')[3], 44 - props.users.filter((u) => u._id === t.assignee)[0].displayName.length)}</span>
                                    </>}
                                secondary={
                                    <>
                                    {((t.ticketStatus === 'Assigned' || t.ticketStatus === 'In Progress') && t.assignee === props.user._id) ?
                                        <span className={classes.higlightPrimary}>Pending your action</span> : 
                                        <span>{t.ticketStatus === 'Resolved' ? t.resolveReason : t.ticketStatus} - </span>}
                                        Last update: {getTimeIndicationForDate(t.lastUpdateDate)}
                                    </>}
                            />
                        </ListItem>
                    ))}
                    </List>
                </Grid>
                ) 
                :!props.plainMode ?
                (
                <Box mt={4} mb={4}>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item>
                        <Grid container direction="column" justify="center" alignItems="center">
                            <Grid item>
                                <RenderAvatar uid={props.user._id} type="flow" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <FontAwesomeIcon icon={faCaretRight} className={classes.arrowIcon} style={{marginRight: '10px'}} />
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" justify="center" alignItems="center">
                            <Grid item>
                                <IconButton onClick={handleAddApprovalOpen} disabled={potentialApprovers === undefined || potentialApprovers === null || potentialApprovers.length === 0}>
                                    <FontAwesomeIcon icon={faPlusCircle} className={classes.plusAvLarge} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <FontAwesomeIcon icon={faCaretRight} className={classes.arrowIcon} style={{marginLeft: '10px'}} />
                    </Grid>
                    <Grid item>
                        <Avatar className={classes.approvedAv}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Avatar>
                    </Grid>
                </Grid>
                </Box>
                )
                : // Applicable for plainMode (Exec/InForce) when there have been no approvals
                <Box mt={10} mb={10}>
                    <Typography align="center">There are no approvals for this clause.</Typography>
                    {!props.templating ?
                    <Typography align="center">There may have been counterparty approvals.</Typography>
                    :''}
                </Box>
                }
            </React.Fragment>
            )}
            </Box>

        </DialogContent>

        <DialogActions className={classes.mlittle}>
            {stagedChange !== null ?
            <Button onClick={e => setStagedChange(null)} variant="text" color="primary" className={classes.cancelButton}>
                <FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Back
            </Button>
            : addCollab ? 
            <Button onClick={e => setAddCollab(null)} variant="text" color="primary" className={classes.cancelButton}>
                <FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Back
            </Button>
            : addApprovalOpen || (!Boolean(ticketDetailOpen) && stagedApproval.topic !== undefined && Boolean(addApproverOpen)) ? 
            
            <Button onClick={addApprovalOpen ? handleAddApprovalClose : handleAddApproverClose} variant="text" color="primary" className={classes.cancelButton}>
                <FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Back
            </Button>

            : !Boolean(ticketDetailOpen) && stagedApproval.topic !== undefined && !Boolean(addApproverOpen) ?

            <Button onClick={handleCancelApproval} variant="text" color="primary" className={classes.cancelButton}>
                <FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Cancel
            </Button>

            /*: updating !== undefined && updating !== null && Boolean(ticketDetailOpen) ?

            <Button onClick={e => setUpdating(null)} variant="text" color="primary" className={classes.cancelButton}>
                <FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Back
            </Button>
            */
            : Boolean(ticketDetailOpen) && !props.ticketFromApprovalScreen ?

            <Button onClick={handleTicketDetailClose} variant="text" color="primary" className={classes.cancelButton}>
                <FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Back
            </Button>
            : '' }
            
            {/*addApprovalOpen ? 

            <Button onClick={handleAddApprovalSubmit} color="primary" variant="contained" disableElevation disabled={addCollab}>
                Submit
            </Button>

            :*/
            stagedChange !== null ?

            <Button onClick={handleChangeApproverSubmit} 
            disabled={userValue === undefined || userValue === null || userValue._id === undefined ||
            stagedChange === null || stagedChange.radioOption === undefined || stagedChange.radioOption === null || stagedChange.radioOption === 'none'} color="primary" variant="contained" disableElevation>
                    Change Approver&nbsp;&nbsp;<FontAwesomeIcon icon={faPaperPlane} />
            </Button>
            
            :
            Boolean(ticketDetailOpen) && ticketDetailOpen.creationBy === props.user._id && ticketDetailOpen.ticketStatus !== 'Cancelled' && ticketDetailOpen.ticketStatus !== 'Resolved' ?
            
            <>
                <Button color="primary" variant="text" disableElevation 
                onClick={e => handleChangeApprovers(e, ticketDetailOpen._id)}
                >Edit<Box display={{ xs: 'none', sm: 'block' }}>&nbsp;Approver</Box>
                </Button>
                <Button color="primary" variant="text" disableElevation 
                onClick={e => handleCancelApprovalRequest(e, ticketDetailOpen._id)}
                >Cancel Request<Box display={{ xs: 'none', sm: 'block' }}>&nbsp;&nbsp;<FontAwesomeIcon icon={faTimes} /></Box>
                </Button>
            </>  
            : 
            Boolean(ticketDetailOpen) && ticketDetailOpen.assignee === props.user._id && ticketDetailOpen.ticketStatus !== 'Cancelled' ?
            <React.Fragment>

                {!['Rejected','Approved'].includes(ticketDetailOpen.resolveReason) ?
                <Button color="primary" variant="text" disableElevation 
                onClick={e => handleChangeApprovers(e, ticketDetailOpen._id)}
                >Edit<Box display={{ xs: 'none', sm: 'block' }}>&nbsp;Approver</Box>
                </Button>
                : '' }
                
                {ticketDetailOpen.resolveReason !== 'Rejected' ? 
                <Button color="primary" variant="contained" disableElevation 
                onClick={e => handleReject(e, ticketDetailOpen._id)} 
                className={classes.errorButton}>
                    Reject
                </Button> : '' }
                {ticketDetailOpen.resolveReason !== 'Approved' ? 
                <Button color="primary" variant="contained" disableElevation 
                onClick={e => handleApprove(e, ticketDetailOpen._id)} 
                className={classes.successButton}>
                    Approve
                </Button> : '' }
                
            </React.Fragment>

            :
            Boolean(ticketDetailOpen) && ticketDetailOpen.creationBy === props.user._id && ticketDetailOpen.ticketStatus === 'Cancelled' && !props.plainMode ?

                <Button color="primary" variant="text" disableElevation 
                onClick={e => handleReopenApprovalRequest(e, ticketDetailOpen._id)}
                >Reopen Request
                </Button>

            : 
            !Boolean(ticketDetailOpen) && stagedApproval.topic !== undefined && Boolean(addApproverOpen) ?

            <Button onClick={handleAddApproverSubmit} color="primary" variant="contained" disableElevation>
                    Add Approver&nbsp;&nbsp;<FontAwesomeIcon icon={faUserCheck} />
            </Button>
            
            : !Boolean(ticketDetailOpen) && stagedApproval.topic !== undefined && !Boolean(addApproverOpen) ?
            
            <Button onClick={handleConfirmChanges} color="primary" variant="contained" disableElevation>
                    Send Approval<Box display={{ xs: 'none', sm: 'inline' }}>&nbsp;Request</Box>&nbsp;&nbsp;<FontAwesomeIcon icon={faPaperPlane} />
            </Button>
            
            : !Boolean(ticketDetailOpen) && props.clause !== 'agreement' && !props.plainMode && !addApprovalOpen &&
            props.user.role !== 'Counterparty' &&
            (potentialApprovers === undefined || potentialApprovers === null || potentialApprovers.length === 0) && !loading ?

                <Typography variant="body2" style={{marginRight: '10px'}}>No potential approver available</Typography>

            : !Boolean(ticketDetailOpen) && props.clause !== 'agreement' && !props.plainMode && !addApprovalOpen && !loading ?
            
            <Button onClick={handleAddApprovalOpen} color="primary" variant="text" disableElevation disabled={potentialApprovers === undefined || potentialApprovers === null || (potentialApprovers.length === 0 && props.user.role !== 'Counterparty')}>
                    Add Approval&nbsp;&nbsp;<FontAwesomeIcon icon={faPlusCircle} />
            </Button>
            :
            ''
            }
            
        </DialogActions>

    </Dialog>

  )
}

export default DialogNegoApprovals
