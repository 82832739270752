import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { trunc } from '../../utils'
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Avatar, Box, Button, Chip, Container, Grid, IconButton, Typography,
         Table, TableBody, TableContainer, TableCell, TableFooter, TableHead, TableRow, TablePagination, TableSortLabel,
         makeStyles } from '@material-ui/core';
import { Header, ThumbRelationship, FAB } from '../../components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faStepBackward, faStepForward } from "@fortawesome/free-solid-svg-icons";         

import defaultLogo from '../../assets/img/defaultlogo.png';
import defaultUser from '../../assets/img/defaultuser.png';

const dayjs = require('dayjs')

// Table head
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const headCells = [
    { id: 'name', disablePadding: true, label: 'Relationship Name' },
    { id: 'lastupdate', disablePadding: false, label: 'Last Updated' },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.id === 'lastupdate' ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={
              headCell.id === 'lastupdate' ? classes.xshide : '' }
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// Table pagination
function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <React.Fragment>
    <Box display={{ xs: 'none', sm: 'block' }}>
    <Grid container direction="row">
      <Grid item xs={3}>
        <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
          <FontAwesomeIcon icon={faStepBackward} style={{fontSize: '14px'}} />
        </IconButton>
      </Grid>
      <Grid item xs={3}>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
          <FontAwesomeIcon icon={faChevronLeft} style={{fontSize: '14px'}} />
        </IconButton>
      </Grid>
      <Grid item xs={3}>
        <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
          <FontAwesomeIcon icon={faChevronRight} style={{fontSize: '14px'}} />
        </IconButton>
      </Grid>
      <Grid item xs={3}>
        <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
          <FontAwesomeIcon icon={faStepForward} style={{fontSize: '14px'}} />
        </IconButton>
      </Grid>
    </Grid>
    </Box>
    <Box display={{ xs: 'block', sm: 'none' }}>
        <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
          <FontAwesomeIcon icon={faChevronRight} style={{fontSize: '14px'}} />
        </IconButton>
    </Box>
    </React.Fragment>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

// Table data
function createData(name, deactive, isProcurement, counterpartyID, lastupdate, ) {
  return { name, deactive, isProcurement, counterpartyID, lastupdate };
}

// Rest of the "real" component
function Relationships(props) {

  const useStyles = makeStyles(theme => ({
    outerFlexGrid: {
      minHeight: '80vh',
      paddingTop: '100px'
    },
    table: {
      [theme.breakpoints.only('xs')]: { width: '300px', marginTop: '10px' },
      [theme.breakpoints.only('sm')]: { width: '540px' },
      [theme.breakpoints.up('md')]: { width: '860px' },
    },
    titleBox : {
      [theme.breakpoints.only('xs')]: { width: '300px', textAlign: 'center' },
      [theme.breakpoints.only('sm')]: { width: '540px' },
      [theme.breakpoints.up('md')]: { width: '860px', paddingTop: '20px' },
    },
    thumbContainer : {
      [theme.breakpoints.only('xs')]: { width: '312px' },
    },
    gridForThumb:{
      [theme.breakpoints.only('xs')]: { paddingLeft: '6px', paddingRight: '6px', paddingBottom: '12px' },
      [theme.breakpoints.only('sm')]: { paddingLeft: '6px', paddingRight: '6px' },
      [theme.breakpoints.up('md')]: { paddingLeft: '10px', paddingRight: '10px' },
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    xshide: {
      [theme.breakpoints.only('xs')]: { display: 'none' },
    },
    tcell: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    tableAv: {
      height: '30px',
      width: '30px',
      marginRight: '20px',
    },
    greyChip: {
      backgroundColor: theme.palette.grey[400],
      fontWeight: '500',
      color: theme.palette.primary.contrastText,
      marginLeft: '15px',
    },
    /*mainDiv: {
      backgroundColor: theme.palette.grey[1],
      minHeight: '100vh'
    }*/
  }));
  const classes = useStyles();

  const history = useHistory()

  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [relevantRels, setRelevantRels] = React.useState([]);

  useEffect(() => {
  
    if(props.rels !== undefined && props.rels.length > 0) {

      let relRels = []
      if(props.module === 'sales') {
        relRels = props.rels.filter((r) => r.primaryOrgID === props.user.orgID && !r.deactive && (r.relationshipType === 'b2b'))
      } else if (props.module === 'procurement') {
        relRels = props.rels.filter((r) => r.secondaryOrgID === props.user.orgID && !r.deactive && (r.relationshipType === 'b2b'))
      } else if (props.module === 'partnerships') {
        relRels = props.rels.filter((r) =>  !r.deactive && r.relationshipType === 'partnership')
      } else if (props.module === 'corporate') {
        relRels = props.rels.filter((r) =>  !r.deactive && r.relationshipType === 'corporate')
      } else if (props.module === 'people') {
        relRels = props.rels.filter((r) =>  !r.deactive && r.relationshipType === 'people')
      }

      relRels
      .forEach((r) => {
        setRows(rows => [...rows, createData(
          r.name, 
          r.deactive,
          r.secondaryOrgID === props.user.orgID,
          props.org !== undefined && props.org.clientType === 'legalOrg' && r.primaryOrgID === props.user.orgID ?
              r.primaryCptyID :
          props.org !== undefined && props.org.clientType === 'legalOrg' && r.secondaryOrgID === props.user.orgID ?
              r.secondaryCptyID :
          r.secondaryOrgID === props.user.orgID ? 
              r.primaryCptyID : 
              r.secondaryCptyID,
          dayjs(r.lastUpdateDate).format('YYYY-MM-DD HH:mm'))]);
      })
      setRelevantRels(relRels)
      if(relRels.length > 5) { setRowsPerPage(10) }
    }
  }, [props.rels, props.user.orgID, props.module]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleRowClick = (event, name) => {
    let rid = props.rels.filter((r) => r.name === name)[0]._id;
    // ALSO UPDATE IN THUMBNAILCARDCOMPONENT
    let path = `/core/` + props.module + `/relationship/` + rid + `/overview`;
    history.push(path);
  }

  return(
    <div /*className={classes.mainDiv}*/>
      <div>
        {["Admin", "Business", "Corporate", "HR", "Legal", "Partnerships", "Procurement", "Sales"].includes(props.user.role) && (
         ['custB', 'cust','custE'].includes(props.org.clientType) || (['custS'].includes(props.org.clientType) && props.org.modules.includes(props.module)) ||
         (['legalOrg'].includes(props.org.clientType) && props.user.role === 'Admin')) ? 
        <FAB iconType="plus" routeTo={"/core/" + props.module + "/new"} extended buttonText="New" />
        : '' }
        <Header module={props.module}
          active="Relationships"
          crumbs={[{name: "Home", to: "/core/" + props.module + "/dashboard"}, {name: "Relationships", to: "active"} ]}
          user={props.user}
          org={props.org}
          modules={
            props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
              ['sales','procurement','partnerships','people','corporate'] : 
            props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
              props.org.modules : [] }
          notifications={props.notifications}
          markNotificationAsRead={props.markNotificationAsRead}
          markAllNotificationsAsRead={props.markAllNotificationsAsRead}  />

        <Container maxWidth="lg">
        <Grid container direction="column" justify="flex-start" alignItems="center" className={classes.outerFlexGrid}>
          {(relevantRels !== undefined && Object.keys(relevantRels).length > 0) ? 
          (
          <React.Fragment>
          <Grid item>
            <Box align="left" mt={1} className={classes.titleBox}>
            <Typography variant="subtitle2">
              Recently updated
            </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box mt={1} mb={4} align="center">
              <Grid container className={classes.thumbContainer}>
                {(relevantRels !== undefined && Object.keys(relevantRels).length > 0) ? 
                relevantRels
                  .sort((a,b) => (a.lastUpdateDate < b.lastUpdateDate) ? 1 : ((b.lastUpdateDate < a.lastUpdateDate) ? -1 : 0))
                  .slice(0, 4)
                  .map((rel, i) => (
                    <Grid className={classes.gridForThumb}
                    item xs={6} sm={relevantRels.length > 3 ? 3 
                    : relevantRels.length === 3 ? 4 
                    : relevantRels.length === 2 ? 6 : 3} key={i}>
                        <ThumbRelationship 
                        logo={
                          rel.primaryOrgID === props.user.orgID && props.org.clientType === 'legalOrg' ?
                            props.subs.filter((s) => s._id === rel.primaryCptyID)[0].logoURL :
                          rel.secondaryOrgID === props.user.orgID && props.org.clientType === 'legalOrg' ?
                            props.subs.filter((s) => s._id === rel.secondaryCptyID)[0].logoURL :
                          rel.primaryOrgID === props.user.orgID && props.org.clientType !== 'legalOrg' && props.cpents.filter((c) => c._id === rel.secondaryCptyID)[0] !== undefined ? 
                            props.cpents.filter((c) => c._id === rel.secondaryCptyID)[0].logoURL :
                          rel.secondaryOrgID === props.user.orgID && props.org.clientType !== 'legalOrg' && props.cpents.filter((c) => c._id === rel.primaryCptyID)[0] !== undefined ? 
                            props.cpents.filter((c) => c._id === rel.primaryCptyID)[0].logoURL :
                          props.module === 'people' ? defaultUser : defaultLogo}
                        name={trunc(
                            props.org.clientType === 'legalOrg' ? rel.name :
                            rel.primaryOrgID === props.user.orgID ?
                            rel.name.substring(rel.name.indexOf('|') + 2) :
                            rel.name.substring(0, rel.name.indexOf('|') - 1), 20) }
                        rid={rel._id}
                        lastUpdate={rel.lastUpdateDate}
                        deactive={rel.deactive}
                        hideDot={true}
                        path={"/core/" + props.module + "/relationship/" + rel._id + "/overview"}
                        />
                    </Grid>
                  )) : ''
                }
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Box align="left" className={classes.titleBox}>
            <Typography variant="subtitle2">
              Browse relationships
            </Typography>
            </Box>
          </Grid>
          <Grid item>
          <Box mb={10}>
          <TableContainer>
            <Table className={classes.table} aria-label="custom pagination table">
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>

                {stableSort(rows, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row,i) => {

                    return (
                      <TableRow 
                        hover
                        key={i}
                        className={classes.tcell}
                        onClick={event => handleRowClick(event, row.name)}>
                        <TableCell component="th" scope="row">
                          <Grid container direction="row" justify="flex-start" alignItems="center">
                            <Grid item>
                              <Avatar alt="logo" className={classes.tableAv} src={
                                props.org.clientType === 'legalOrg' && props.subs.filter((s) => s._id === row.counterpartyID)[0] !== undefined ?
                                  props.subs.filter((s) => s._id === row.counterpartyID)[0].logoURL :
                                props.cpents.filter((c) => c._id === row.counterpartyID)[0] !== undefined ?
                                  props.cpents.filter((c) => c._id === row.counterpartyID)[0].logoURL : defaultLogo } />
                            </Grid>
                            <Grid item>
                              {row.name}
                              {row.deactive ?
                              <Chip size="small" label="Deactive" className={classes.greyChip} /> : '' }
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell align="right" className={classes.xshide}>{row.lastupdate}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
                
              </TableBody>
              <TableFooter>
              <TableRow>
                <TablePagination
                    style={{width: '300px', overflowX: 'hidden'}}
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={2}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          </Box>

          </Grid>
          </React.Fragment>
          )
          :
          (
            <Box mt={10} mb={10} align="center">

                <Typography variant="h6">
                  You don't have any relationships yet.                  
                </Typography>
                <Box mt={6}>
                  {(props.module === 'sales' && props.cpents.filter((c) => c.entityType === 'Customer').length === 0) ||
                   (props.module === 'procurement' && props.cpents.filter((c) => c.entityType === 'Supplier').length === 0) ||
                   (props.module === 'partnerships' && props.cpents.filter((c) => c.entityType === 'Partner').length === 0) ||
                   (props.module === 'people' && props.cpents.filter((c) => c.entityType === 'Employee').length === 0) ||
                   (props.module === 'corporate' && props.cpents.filter((c) => c.entityType === 'Corpcp').length === 0) ?
                   <Button color="secondary" disableElevation variant="contained" size="large" 
                   component={RouterLink} to={"/core/" + props.module + "/new"}>
                     Setup {props.module === 'sales' ? "a Customer" : 
                            props.module === 'procurement' ? "a Supplier" : 
                            props.module === 'partnerships' ? "a Partner" : 
                            props.module === 'people' ? "an Employee" : 
                            props.module === 'corporate' ? "a Corporate Counterparty" :  "a Counterparty" }</Button>
                   :
                   <Button color="secondary" disableElevation variant="contained" size="large" 
                   component={RouterLink} to={"/core/" + props.module + "/new"}>Create Relationship</Button>
                  }
                </Box>

            </Box>
          )}
        </Grid>
        </Container>
                
      </div>

    </div>
  );
}

export default Relationships;