import React from 'react';
import { Box, Container, FormControl, FormLabel, FormControlLabel, Grid, Radio, RadioGroup, Typography,
         makeStyles } from '@material-ui/core';
import { DrawerCore, FAB, GetStarted, Header, InformationalTooltip, ErrorMsg, UploadImg, SkeletonCustom } from '../components';
import { UserForm } from '../components/forms';
import { getDefaultModule } from '../utils'

function Settings(props) {

  const useStyles = makeStyles(theme => ({
    outerFlexGrid: {
      minHeight: '80vh',
      paddingTop: '100px',
      paddingBottom: '100px',
    },
    root: {
      display: 'flex',
    },
    header: {
        zIndex: 1400
    },
    content: {
        flexGrow: 1,
    },
  }));
  const classes = useStyles();

  const callbackUserForm = (child) => {
    props.updateUser(props.user._id, child.firstName, child.lastName, child.title, child.phone, props.user.photoURL, props.user.defaultModule, props.user.agrIDs);
    props.fetchUsersByOrgID(props.user.orgID)
  }

  const callbackAvatarUploadForm = (child) => {
    props.updateUser(props.user._id, props.user.firstName, props.user.lastName, props.user.title, props.user.phone, child, props.user.defaultModule, props.user.agrIDs);
    props.fetchUsersByOrgID(props.user.orgID)
  }

  const handleDefaultModuleChange = (e) => {
    props.updateUser(props.user._id, props.user.firstName, props.user.lastName, props.user.title, props.user.phone, props.user.photoURL, e.target.value, props.user.agrIDs);
  }

  return(
    <div className={classes.root}>
      <Header module="account" 
        crumbs={[{name: "Settings", to: "active"} ]}
        org={props.org}
        user={props.user} 
        modules={
          props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
            ['sales','procurement','partnerships','people','corporate'] : 
          props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
            props.org.modules : [] }
        notifications={props.notifications}
        markNotificationAsRead={props.markNotificationAsRead}
        markAllNotificationsAsRead={props.markAllNotificationsAsRead}
        className={classes.header} />

      <DrawerCore 
        page={['photo', 'settings'].includes(props.pageType) ? props.pageType : 'personal'}
        pageType='settings'
        isCpty={props.user !== undefined && props.user !== null && props.user.role !== undefined &&
            (props.user.role === 'Counterparty' || props.user.role.startsWith('ClientFor_'))}
      />

      <FAB iconType="arrowLeft" routeTo={"/core/" + getDefaultModule(props.org, props.user) + "/dashboard"} extended buttonText="Dashboard" />

      <Container maxWidth="lg" className={classes.content}>

      {!props.userPending &&
      props.pageType !== 'settings' && 
      props.user.role !== 'Counterparty' && (props.user.introComplete === undefined || props.user.introComplete.dashboard === undefined || props.user.introComplete.dashboard === null || !props.user.introComplete.dashboard ) &&
      props.org !== undefined && props.org.clientType !== 'legalOrg' ?
          <GetStarted 
          fromDashboard={false}
          step={props.pageType === 'photo' ? "0_1" : "0_0"}
          pushDown={true}
          module={props.module}
          user={props.user}
          org={props.org}
          />
      :''}
      
      <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
        <Grid item>
          <Box mb={5} mt={2}>
            <Typography align="center" variant="h4">
                Account Settings&nbsp;&nbsp;<InformationalTooltip msg={"Update personal details under \"Personal\". The phone number is required for Advanced and Qualified signing.\n\nUpload your profile photo under \"Photo\".\n\nDefine your default module under \"Settings\". The default module will configure your default homepage (dashboard), for example after logging in."} />
            </Typography>
          </Box>
        </Grid>

        { props.userPending ? 
        <Grid item container alignItems="center" justify="center">
          <Box mt={5} mb={10} align="center">
            <SkeletonCustom type="form" />
          </Box>
        </Grid>
        : 
        (
          props.userError !== null ? 
          (
            <ErrorMsg msg="Unable to update account settings" />
          ):
          (
          <Grid item>

            {props.pageType === 'settings' && props.user.role !== 'Counterparty' ?

              <Box mt={1} mb={3} align="center">

                <FormControl component="fieldset">
                  <FormLabel component="legend">Select your default Module:</FormLabel>
                  <RadioGroup row name="defaultModule" 
                  value={props.user.defaultModule !== undefined && (['Admin', 'Legal'].includes(props.user.role)
                  || (['Business', 'Sales'].includes(props.user.role) && props.user.defaultModule === 'sales')
                  || (['Business', 'Procurement'].includes(props.user.role) && props.user.defaultModule === 'procurement')
                  || (['Business', 'Partnerships'].includes(props.user.role) && props.user.defaultModule === 'partnerships')
                  || (['HR'].includes(props.user.role) && props.user.defaultModule === 'people')
                  || (['Corporate'].includes(props.user.role) && props.user.defaultModule === 'corporate')) ? props.user.defaultModule : null}
                  onChange={handleDefaultModuleChange}>
                    {['Admin', 'Legal', 'Business', 'Sales'].includes(props.user.role) ?
                    <FormControlLabel value="sales" control={<Radio />} label="Customers" /> :''}
                    {['Admin', 'Legal', 'Business', 'Procurement'].includes(props.user.role) ?
                    <FormControlLabel value="procurement" control={<Radio />} label="Suppliers" /> :''}
                    {['Admin', 'Legal', 'Business', 'Partnerships'].includes(props.user.role) ?
                    <FormControlLabel value="partnerships" control={<Radio />} label="Partners" /> :''}
                    {['Admin', 'Legal', 'HR'].includes(props.user.role) ?
                    <FormControlLabel value="people" control={<Radio />} label="Employees" /> :''}
                    {['Admin', 'Legal', 'Corporate'].includes(props.user.role) ?
                    <FormControlLabel value="corporate" control={<Radio />} label="Corporate" /> :''}
                  </RadioGroup>
                </FormControl>

              </Box>
            
            :props.pageType === 'photo' ?
              <Box mt={1} mb={3} align="center">                
                <UploadImg 
                  type="avatar" 
                  parentCallback={callbackAvatarUploadForm}
                  initialImg={props.user.photoURL} />
              </Box>
            :
              <UserForm 
                parentCallback={callbackUserForm}
                type="update"
                initialValues={{
                  title: props.user.title,
                  firstName: props.user.firstName, 
                  lastName: props.user.lastName,
                  role: props.user.role,
                  email: props.user.email,
                  phone: props.user.phone }} />
            }
            
          </Grid>
          )
        )
        }
      </Grid>
      </Container>

    </div>
  );
}

export default Settings;