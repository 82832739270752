import { countries } from '../assets/static/countries';
import { states } from '../assets/static/states';

function findNumbers (s) {
    let r = s.match(/^\d+|\d+\b|\d+(?=\w)/g);
    return r
}

function endsWithOneOffArray(s, arr) {
    let r = false;
    arr.forEach((a) => { if(s.endsWith(a)) { r = true; } })
    return r;
}

function getPeriodForString(s) {
    let r = null;
    if(endsWithOneOffArray(s, ['mins', 'minute', 'minutes'])) {
        r = 'minutes';
    } else if(endsWithOneOffArray(s, ['hour', 'hours'])) {
        r = 'hours';
    } else if(endsWithOneOffArray(s, ['business day', 'business days'])) {
        r = 'busdays';
    } else if(endsWithOneOffArray(s, ['day', 'days'])) {
        r = 'days';
    } else if(endsWithOneOffArray(s, ['week', 'weeks'])) {
        r = 'weeks';
    } else if(endsWithOneOffArray(s, ['month', 'months'])) {
        r = 'months';
    } else if(endsWithOneOffArray(s, ['year', 'years'])) {
        r = 'years';
    } 
    return r;
}

function greatPatternFinder(string) {

    let breakdown = { type: 'string-text', val1: (string !== undefined && string !== null ? string.trim() : ''), val2: '', val3: ''} // Default option if no match can be found
    let s = (string !== undefined && string !== null ? string.trim().toLowerCase() : '');

    if(countries.some((c) => c.label.toLowerCase() === s) || 
       states.some((st) => st.label.toLowerCase() === s)) {
        // STANDARD LIST: part of a standard list of options
        let listType = 
            countries.some((c) => c.label.toLowerCase() === s) ? 'country' :
            //legallocations.some((ll) => ll.glaw.toLowerCase() === s) ? 'glaw' : 
            states.some((st) => st.label.toLowerCase() === s) ? 'state' : 'none';
        let listVal = 
            countries.filter((c) => c.label.toLowerCase() === s)[0] !== undefined ? countries.filter((c) => c.label.toLowerCase() === s)[0].label :
            //legallocations.filter((ll) => ll.glaw.toLowerCase() === s)[0] !== undefined ? legallocations.filter((ll) => ll.glaw.toLowerCase() === s)[0].glaw :
            states.filter((st) => st.label.toLowerCase() === s)[0] !== undefined ? states.filter((st) => st.label.toLowerCase() === s)[0].label : '';

        if(listType !== 'none' && listVal !== '') {
            breakdown = {
                type: 'list-standard',
                val1: listType,
                val2: listVal,
                val3: '',
            }
        }

    } else if (!isNaN(s) && !isNaN(parseFloat(s))) {
        // NUMBER: is a number
        breakdown = {
            type: 'number',
            val1: s,
            val2: '',
            val3: '',
        }

    } else if (s.match(/^(usd|eur|jpy|gbp|aud|cad|chf|cny|sek|nzd|mxn|sgd|hkd|nok|krw|try|inr|brl|zar|rub|ars|\$|€|£)/) &&
        s.match(/^(usd|eur|jpy|gbp|aud|cad|chf|cny|sek|nzd|mxn|sgd|hkd|nok|krw|try|inr|brl|zar|rub|ars|\$|€|£)\s?([0-9]([0-9,]?)*)(\.\d{2})?|([0-9]([0-9,]?))(\.\d{2})?(\s?)/)) {
        // AMOUNT: Starts with Currency and then a Number

        let ccy = 'USD', newS = s, multiplier = 1
        if(s.match(/^(usd|eur|jpy|gbp|aud|cad|chf|cny|sek|nzd|mxn|sgd|hkd|nok|krw|try|inr|brl|zar|rub|ars)/)) {
            ccy = s.substring(0,3)
            newS = s.substring(3)
        } else if (s.match(/^(\$|€|£)/)) {
            ccy = s.startsWith('$') ? 'USD' : s.startsWith('€') ? 'EUR' : s.startsWith('£') ? 'GBP' : 'USD';
            newS = s.substring(1)
        }

        if(s.endsWith(' thousand') || s.endsWith(' million') || s.endsWith(' bn') || s.endsWith(' billion')) {
            multiplier = s.endsWith(' thousand') ? 1000 : s.endsWith(' million') ? 1000000 : s.endsWith(' bn') || s.endsWith(' billion') ? 1000000000 : 1;
            newS = s.endsWith(' thousand') ? newS.substring(0, newS.length - 9) : s.endsWith(' million') ? newS.substring(0, newS.length - 8) : s.endsWith(' bn') ? newS.substring(0, newS.length - 3) : s.endsWith(' billion') ? newS.substring(0, newS.length - 8) : newS
        }

        breakdown = {
            type: 'amount',
            val1: ccy.toUpperCase(),
            val2: !isNaN(newS) && !isNaN(parseFloat(newS)) ? newS * multiplier : newS,
            val3: '',
        }

    } else if (s.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/)){
        // EMAIL: Capture email address
        breakdown = {
            type: 'email',
            val1: s,
            val2: '',
            val3: '',
        }

    } else if (s.match(/(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/)) {
        // EMAIL: Capture email address
        breakdown = {
            type: 'link',
            val1: s,
            val2: '',
            val3: '',
        }

    } else if (s.match(/^\d{2}([./-])\d{2}\1\d{4}$/) || // DD/MM/YYYY or DD-MM-YYYY or DD.MM.YYYY whereby DD and MM can be interchanged
        s.match(/^\d{1,2}\s(?:jan(?:uary)?|feb(?:ruary)?|mar(?:ch)?|apr(?:il)?|may?|jun(?:e)?|jul(?:y)?|aug(?:ust)?|sep(?:tember)?|oct(?:ober)?|nov(?:ember)?|dec(?:ember)?) (?:19[7-9]\d|2\d{3})(?=\D|$)/)
    ){
        // DATE: various date formats - now break down the date and transform to DD/MM/YYYY
        let d = null;
        if(s.match(/^\d{2}([./-])\d{2}\1\d{4}$/)) {
            let day = parseInt(s.substring(3,5)) > 12 ? s.substring(3,5) : s.substring(0,2); // assumes ROW format unless proven otherwise
            let mon = parseInt(s.substring(3,5)) > 12 ? s.substring(0,2) : s.substring(3,5);
            d = (s.substring(6) + "/" + mon + "/" + day)
        } else {
            let mon = 
                s.includes("jan") ? "01" : s.includes("feb") ? "02" : s.includes("mar") ? "03" : s.includes("apr") ? "04" : s.includes("may") ? "05" :
                s.includes("jun") ? "06" : s.includes("jul") ? "07" : s.includes("aug") ? "08" : s.includes("sep") ? "09" : s.includes("oct") ? "10" :
                s.includes("nov") ? "11" : s.includes("dec") ? "12" : "01";
            d = s.substring(s.length - 4) + "/" + mon + "/" + s.substring(0,2)
        }
        breakdown = {
            type: 'date-stamp',
            val1: d,
            val2: '',
            val3: '',
        }

    } else if(findNumbers(s) !== null && findNumbers(s).length === 1 && endsWithOneOffArray(s, ['mins', 'minute', 'minutes', 'hour', 'hours', 'day', 'days', 'week', 'weeks', 'month', 'months', 'year', 'years'])) {
        // PERIOD: String has a number and ends with a period indicator
        breakdown = {
            type: 'date-period',
            val1: parseInt(findNumbers(s)[0]),
            val2: getPeriodForString(s),
            val3: '',
        }
    }

    return breakdown
}
export { greatPatternFinder }