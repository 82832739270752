import { FETCH_ACTIVITYLOG_PENDING, FETCH_ACTIVITYLOG_SUCCESS, FETCH_ACTIVITYLOG_ERROR, ADD_ACTIVITYLOG } from '../ActionTypes';

export const ActivityLog = (state  = { 
        pending: false,
        error: null,
        activityLog: []
    }, action) => {
    switch(action.type) {
        case FETCH_ACTIVITYLOG_PENDING: 
            return {
                ...state,
                pending: true
            }
        case FETCH_ACTIVITYLOG_SUCCESS:
            return {
                ...state,
                pending: false,
                activityLog: action.payload
            }
        case FETCH_ACTIVITYLOG_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }
        case ADD_ACTIVITYLOG:
            var al = action.payload;
            return { 
                ...state, 
                pending: false,
                activityLog: state.activityLog.concat(al)};
        default: 
            return state;
    }
};