import { getHtmlForClause } from '.'

const axios = require('axios');
const dayjs = require('dayjs')

function sendApprovalEmailToUser (type, module, rel, orgurl, agrID, clause, recipient, cptyLegalName, 
    agrFullName, creatorFullName, ticket, commentOrDecision, allComments) {

    const baseURL = ['https://getcanveo.com', 'https://www.getcanveo.com'].includes(window.location.origin) ? 'https://app.getcanveo.com' : window.location.origin

    let isCustomLink = rel !== undefined && rel.customURL !== undefined && rel.customURL.length > 10
    let needsToCreatePassword = recipient.lastLoginDate === undefined && (recipient.password === undefined || recipient.password.length === 20)

    let viewLink = 
        rel !== undefined && rel.isTemplating && !needsToCreatePassword ? // Scenario 0: Templating approval request
            (baseURL + '/core/loginp/_o/' + recipient._id + '/' + module + '/' + (rel.isTerms ? 'terms' : 'template') + '/' + rel.oatid + '/' + clause.singleClauseID) : 
        recipient.role === 'Counterparty' && isCustomLink ? // Scenario 1: Cpty & CustomLink
            (baseURL + '/network/' + orgurl + '/' + rel.customURL + '/' + recipient._id + '/' + agrID + '/' + clause.singleClauseID) : 
        needsToCreatePassword ? // Scenario 2: Create Password
            (baseURL + '/core/createpassword/' + recipient.password) 
        : // Scenario 3: Fallback - view agreement through Login
            (baseURL + '/core/loginp/_a/' + recipient._id + '/' + module + '/' + rel._id + '/' + agrID + '/' + ticket.singleClauseID)    
    
    let viewText = 
        rel !== undefined && rel.isTemplating && !needsToCreatePassword ? // Scenario 0: Templating approval request
            ('View ' + (rel.isTerms ? 'Terms' : 'Template')) :
        recipient.role === 'Counterparty' && isCustomLink ? // Scenario 1: Cpty & CustomLink
            'View Agreement' : 
        needsToCreatePassword ? // Scenario 2: Create Password
            'Create Password'
        : // Scenario 3: Fallback - view agreement through Login
            'View Agreement'

    let approveLink = (baseURL + '/core/act/_ta/' + ticket._id + '/' + recipient._id + '/' + ticket.singleClauseID + '/' + agrID)
    let rejectLink = (baseURL + '/core/act/_tr/' + ticket._id + '/' + recipient._id + '/' + ticket.singleClauseID + '/' + agrID)

    let emailBody = ''
    // PART 1: The comment, or decision
    if(['approvalrequest', 'approvaldecision', 'replyonapproval'].includes(type)) {

        if(commentOrDecision.reassign || commentOrDecision.decision) { // simple decision or re-assignment msg
            emailBody = emailBody + `<div style="margin: 40px 0px 10px 0px; padding: 0px; font-size: 14px; color: #6d6a7a; text-align: center;">` + commentOrDecision.comment + `</div>`
        } else { // potentially breakdown comment into paragraphs with <br/>
            emailBody = emailBody + `<div style="margin: 40px 0px 10px 0px; padding: 0px 0px 0px 20px; border-left: 4px solid #f3f3f3; font-size: 14px; color: #6d6a7a; text-align: left;">`;

            commentOrDecision.comment.split('\n').forEach((cpar, i) => { 
                emailBody = emailBody + cpar + (i !== (commentOrDecision.comment.split('\n').length - 1) ? '<br/>' : '')
            })

            emailBody = emailBody + `</div>`
        }
    }
    // PART 2: Buttons: View Agreement, Reject Request, Approve Request
    let whiteLabel = rel !== undefined && rel !== null && rel.whiteLabel !== undefined && rel.whiteLabel !== null &&
        rel.whiteLabel.color !== undefined && rel.whiteLabel.contrastColor !== undefined && recipient.role === 'Counterparty' ? rel.whiteLabel : null;

    let viewColorFont = 
        (whiteLabel !== null && recipient._id === ticket.assignee ?
            whiteLabel.color : 
        whiteLabel !== null ? 
            whiteLabel.contrastColor :
        recipient._id === ticket.assignee ?
            '#7243dd' : '#ffffff')
    let viewColorBack = 
        (whiteLabel !== null && recipient._id === ticket.assignee ?
            whiteLabel.contrastColor : 
        whiteLabel !== null ? 
            whiteLabel.color :
        recipient._id === ticket.assignee ? 
            '#ffffff' : '#E80064')

    emailBody = emailBody + `
        <table width="100%" cellspacing="0" cellpadding="0"><tr><td align="center" style="padding: 30px 0px 20px 0px;">
        <table cellspacing="10" cellpadding="0">
        <tr>`
            +
            (recipient._id === ticket.assignee ?
            `
            <td>
                <table cellspacing="0" cellpadding="0"><tr>
                <td style="border-radius: 6px; background-color: #44AF69;">
                <a href="` + approveLink + `" target="_blank" 
                style="padding: 4px 20px;border: 1px solid #44AF69;border-radius: 6px;font-size: 14px;color: #ffffff;text-decoration: none;font-weight: bold;display: inline-block;">
                    Approve
                </a>
                </td>
                </tr></table>
            </td>
            <td>
                <table cellspacing="0" cellpadding="0"><tr>
                <td style="border-radius: 6px; background-color: #D0252E;">
                <a href="` + rejectLink + `" target="_blank" 
                style="padding: 4px 20px;border: 1px solid #D0252E;border-radius: 6px;font-size: 14px;color: #ffffff;text-decoration: none;font-weight: bold;display: inline-block;">
                    Reject
                </a>
                </td>
                </tr></table>
            </td>` : ``) 
            +
        `</tr>
        <tr>
            <td colspan="2" align="center">
                <table cellspacing="10" cellpadding="0"><tr>
                <td style="border-radius: 6px; background-color: ` + viewColorBack + `;">
                <a href="` + viewLink + `" target="_blank" 
                style="padding: 4px 20px;border: 1px solid ` + viewColorBack + `;border-radius: 6px;font-size: 14px;color: ` + viewColorFont + `;text-decoration: none;font-weight: bold;display: inline-block;">
                ` + viewText + `
                </a>
                </td>
                </tr></table>
            </td>
        </tr>
        </table>
        </td></tr></table>`    

    // PART 3: HTML version of the clause with redlines
    emailBody = emailBody + getHtmlForClause(clause); 

    // PART 4: Get most recent three comments and include in the email body
    if(allComments.length > 0) {
        emailBody = emailBody + `
            <table width="100%" cellspacing="0" cellpadding="0"><tr><td align="center">
            <div style="max-width: 320px; margin: 30px 0px 40px 0px;">
            <p style="text-align: center; font-size: 14px; margin: 20px 0px 20px 0px;"><b>Recent comments</b></p>`
        
        allComments
        .sort((a,b) => (a.creationDate < b.creationDate) ? 1 : ((b.creationDate < a.creationDate) ? -1 : 0))
        .filter((c) => c.ticketID === ticket._id && c.comment !== '___DELETED___')
        .slice(0,3)
        .forEach((c) => {

            let isLeft = recipient._id === c.creatorID
            let isPrimaryColor = (c.orgID === rel.primaryOrgID) || (rel !== undefined && rel.isTemplating)

            if(['===CANCELLED===', '===APPROVED===', '===REJECTED===', '===REOPENED===', '===REASSIGNED==='].includes(c.comment)) {

                emailBody = emailBody + `
                    <div style="width: 100%; margin-top: 20px; margin-bottom: 20px">
                        <p style="text-align: center; color: #827f8f; font-size: 13px"><b>` +
                        ((c.comment === '===CANCELLED===' ? "Cancelled" : c.comment === '===APPROVED===' ? "Approved" :
                        c.comment === '===REJECTED===' ? "Rejected" : c.comment === '===REOPENED===' ? "Reopened" : 
                        c.comment === '===REASSIGNED===' ? "Reassigned" : "Updated")
                        +
                        ` on ` +
                        dayjs(c.creationDate).format('MMM D, YYYY h:mm A') + 
                        ` by ` + 
                        c.creatorDisplayName) +
                        `</b></p>
                    </div>`

            } else {

                emailBody = emailBody + `
                    <div style="max-width: 300px; padding: 5px 20px 10px 20px; text-align: left; margin-bottom: 10px; border-radius: 0px; border: 1px solid #e0e0e0; color: #4d4b59; font-size: 12px;` +
                    (isLeft ? 
                        'border-top-right-radius: 10px; border-bottom-right-radius: 10px; border-left: 4px solid ' + (isPrimaryColor ? '#7243dd' : '#E80064') + ';' : 
                        'border-top-left-radius: 10px; border-bottom-left-radius: 10px; border-right: 4px solid ' + (isPrimaryColor ? '#7243dd' : '#E80064') + ';')
                    + `">
                        <p><b>` + c.creatorDisplayName + `</b>
                        <span style="color: #827f8f; font-size: 10px; margin-left: 10px">` + dayjs(c.creationDate).format('MMM D, YYYY h:mm A') + `</span></p>`;

                    c.comment.split('\n').forEach((cpar) => { 
                        emailBody = emailBody + '<p>' + cpar + '</p>'
                    
                    })
                
                emailBody = emailBody + `
                    </div>`;
            }
        })
        emailBody = emailBody + `</div></td></tr></table>`
    }
    if (type === 'approvalrequest') {
        axios.post('/mail/approvalrequest', {
            recipient: recipient.email,
            creatorFullName: creatorFullName,
            agrFullName: agrFullName,
            cptyName: cptyLegalName,
            emailBody: emailBody,
            isTemplating: rel !== undefined && rel.isTemplating,
            isTerms: rel !== undefined && rel.isTerms,
            whiteLabel: whiteLabel,
        })
    } else if (type === 'replyonapproval') {
        axios.post('/mail/replyonapproval', {
            recipient: recipient.email,
            creatorFullName: creatorFullName,
            agrFullName: agrFullName,
            cptyName: cptyLegalName,
            emailBody: emailBody,
            isTemplating: rel !== undefined && rel.isTemplating,
            isTerms: rel !== undefined && rel.isTerms,
            whiteLabel: whiteLabel,
        })
    } else if (type === 'approvaldecision') {
        axios.post('/mail/approvaldecision', {
            recipient: recipient.email,
            creatorFullName: creatorFullName,
            agrFullName: agrFullName,
            cptyName: cptyLegalName,
            decision: commentOrDecision.outcome,
            emailBody: emailBody,
            isTemplating: rel !== undefined && rel.isTemplating,
            isTerms: rel !== undefined && rel.isTerms,
            whiteLabel: whiteLabel,
        })
    }
}

export { sendApprovalEmailToUser }