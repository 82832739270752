import React from 'react';
import { trunc, getTimeIndicationForDate } from '../utils'
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'; // https://reactjs.org/docs/typechecking-with-proptypes.html
import { Avatar, Badge, Box, Card, CardContent, Typography,
         makeStyles, withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake, faShieldAlt, faShoppingCart, faUserFriends, faUser, faBuilding } from "@fortawesome/free-solid-svg-icons";

const StyledDot = withStyles(theme => ({
  badge: {
    right: 3,
    top: 3,
  },
}))(Badge);

const ThumbRelationship = (props) => {

  const useStyles = makeStyles(theme => ({
    card: {
        [theme.breakpoints.only('xs')]: { width: '144px' },
        [theme.breakpoints.only('sm')]: { width: '126px' },
        [theme.breakpoints.up('md')]: { width: '200px' },
        '&:hover' : {
          boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 24px 0px',
          cursor: 'pointer',
        }
    },
    logo: {
        margin: '10px 10px 20px 10px',
        [theme.breakpoints.only('xs')]: { height: '50px', width: '50px' },
        [theme.breakpoints.only('sm')]: { height: '50px', width: '50px', },
        [theme.breakpoints.up('md')]: { height: '60px', width: '60px' },
    },
    updateFont: {
        [theme.breakpoints.only('xs')]: { paddingLeft: '5px', paddingRight: '5px', paddingBottom : '0px', },
        [theme.breakpoints.only('sm')]: { paddingLeft: '5px', paddingRight: '5px', paddingBottom : '5px', },
        [theme.breakpoints.up('md')]: { paddingLeft: '7px', paddingRight: '7px', paddingBottom : '5px', },
    },
    deactiveFont: {
      [theme.breakpoints.only('xs')]: { color: theme.palette.grey[400], paddingLeft: '5px', paddingRight: '5px', paddingBottom : '0px', },
      [theme.breakpoints.only('sm')]: { color: theme.palette.grey[400], paddingLeft: '5px', paddingRight: '5px', paddingBottom : '5px', },
      [theme.breakpoints.up('md')]: { color: theme.palette.grey[400], paddingLeft: '10px', paddingRight: '10px', paddingBottom : '5px', },
    },
    salesAv: {
      margin: '10px 10px 20px 10px',
      [theme.breakpoints.only('xs')]: { height: '50px', width: '50px' },
      [theme.breakpoints.only('sm')]: { height: '50px', width: '50px', },
      [theme.breakpoints.up('md')]: { height: '60px', width: '60px' },
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    procAv: {
      margin: '10px 10px 20px 10px',
      [theme.breakpoints.only('xs')]: { height: '50px', width: '50px' },
      [theme.breakpoints.only('sm')]: { height: '50px', width: '50px', },
      [theme.breakpoints.up('md')]: { height: '60px', width: '60px' },
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    partnerAv: {
      margin: '10px 10px 20px 10px',
      [theme.breakpoints.only('xs')]: { height: '50px', width: '50px' },
      [theme.breakpoints.only('sm')]: { height: '50px', width: '50px', },
      [theme.breakpoints.up('md')]: { height: '60px', width: '60px' },
      backgroundColor: theme.palette.tertiary.main,
      color: theme.palette.secondary.contrastText,
    },
    privAv: {
      margin: '10px 10px 20px 10px',
      [theme.breakpoints.only('xs')]: { height: '50px', width: '50px' },
      [theme.breakpoints.only('sm')]: { height: '50px', width: '50px', },
      [theme.breakpoints.up('md')]: { height: '60px', width: '60px' },
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.secondary.contrastText,
    },
    singleLine: {
      [theme.breakpoints.only('xs')]: { fontSize: '12px' },
      [theme.breakpoints.only('sm')]: { fontSize: '13px' },
      [theme.breakpoints.up('md')]: { fontSize: '16px' }, 
      fontWeight: '600',
    }
  }));
  const classes = useStyles();

  const history = useHistory()

  return (
    <StyledDot color="secondary" variant="dot" invisible={props.hideDot}>
    <Card variant="outlined" className={classes.card} onClick={event => history.push(props.path)}>
        <CardContent align="center">
            {props.logo === "Sales" ? 
              <Avatar alt="Sales" className={classes.salesAv}><FontAwesomeIcon icon={faHandshake} /></Avatar>
            :
            props.logo === "Procurement" ? 
              <Avatar alt="Procurement" className={classes.procAv}><FontAwesomeIcon icon={faShoppingCart} /></Avatar>
            :
            props.logo === "Partnership" ? 
              <Avatar alt="Partnership" className={classes.partnerAv}><FontAwesomeIcon icon={faUserFriends} /></Avatar>
            :
            props.logo === "People" ? 
              <Avatar alt="People" className={classes.procAv}><FontAwesomeIcon icon={faUser} /></Avatar>
            :
            props.logo === "Corporate" ? 
              <Avatar alt="Corporate" className={classes.salesAv}><FontAwesomeIcon icon={faBuilding} /></Avatar>
            :
            props.logo === "Privacy" ? 
              <Avatar alt="Privacy" className={classes.privAv}><FontAwesomeIcon icon={faShieldAlt} /></Avatar>
            :
              <Avatar src={props.logo !== "" ? props.logo : "https://storage.cloud.google.com/ctimages/defaultlogo.png"} alt="Company logo" className={classes.logo} />
            }

            {props.terms ?
            <Box mt={2} mb={0}>
              <Typography variant="subtitle1" className={classes.singleLine}>{props.name}</Typography>
            </Box>
            :
            <>
            <Box display={{ xs: 'none', md: 'block' }}>
                <Typography variant="subtitle2">
                    {trunc(props.name, 20)}
                </Typography>
            </Box>

            <Typography variant="body2" color="textSecondary" className={props.deactive ? classes.deactiveFont : classes.updateFont}>
                {props.deactive ? "Deactivated: " : "Last update: "}
                {getTimeIndicationForDate(props.lastUpdate)}
            </Typography>
            </>
            }

        </CardContent>

    </Card>
    </StyledDot>
  );
}

ThumbRelationship.propTypes = {
  rid: PropTypes.string,
  name: PropTypes.string,
  lastUpdate: PropTypes.string,
  logo: PropTypes.string,
};

export default ThumbRelationship