import { FETCH_SUBS_PENDING, FETCH_SUBS_SUCCESS, FETCH_SUBS_ERROR, ADD_SUBS, REMOVE_SUBS, UPDATE_SUBS,
         FETCH_CPENTS_PENDING, FETCH_CPENTS_SUCCESS, FETCH_CPENTS_ERROR, ADD_CPENT, REMOVE_CPENT, UPDATE_CPENT,
         FETCH_ORGPROFILESUBS_PENDING, FETCH_ORGPROFILESUBS_ERROR, FETCH_ORGPROFILESUBS_SUCCESS } from '../ActionTypes';

// SUBS
export const Subs = (state  = { 
    pending: false,
    error: null,
    subs: []
}, action) => {
switch(action.type) {
        case FETCH_SUBS_PENDING: 
            return {
                ...state,
                pending: true
            }
        case FETCH_SUBS_SUCCESS:
            return {
                ...state,
                pending: false,
                subs: action.payload
            }
        case FETCH_SUBS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }
        case ADD_SUBS:
            var sub = action.payload;
            return { 
                ...state, 
                subs: state.subs.concat(sub)};
        case REMOVE_SUBS:
            var entid = action.payload;
            return { 
                ...state, 
                subs: state.subs.filter(({ _id }) => _id !== entid)};
        case UPDATE_SUBS:
            // Find the index of the entitiy to replace with the updated one
            var newent = action.payload
            var newEnts = state.subs
            var index = null;
            for (var i=0; i<state.subs.length; i++) {
                if ( state.subs[i]._id === newent._id ) {
                    index = i;
                    break;
                }
            }
            newEnts[index] = newent
            return { 
                ...state, 
                subs: newEnts};
        default: 
            return state;
    }
};

// CPTY ENTITIES
export const Cpents = (state  = { 
    pending: false,
    error: null,
    cpents: []
}, action) => {
switch(action.type) {
        case FETCH_CPENTS_PENDING: 
            return {
                ...state,
                pending: true
            }
        case FETCH_CPENTS_SUCCESS:
            return {
                ...state,
                pending: false,
                cpents: action.payload
            }
        case FETCH_CPENTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }
        case ADD_CPENT:
            var cpent = action.payload;
            return { 
                ...state, 
                cpents: state.cpents.concat(cpent)};
        case REMOVE_CPENT:
            var entid = action.payload;
            return { 
                ...state, 
                cpents: state.cpents.filter(({ _id }) => _id !== entid)};
        case UPDATE_CPENT:
            // Find the index of the entitiy to replace with the updated one
            var newent = action.payload
            var newEnts = state.cpents
            var index = null;
            for (var i=0; i<state.cpents.length; i++) {
                if ( state.cpents[i]._id === newent._id ) {
                    index = i;
                    break;
                }
            }
            newEnts[index] = newent
            return { 
                ...state, 
                cpents: newEnts};
        default: 
            return state;
    }
};

// ORGPROFILESUBS
export const OrgProfileSubs = (state  = { 
    pending: false,
    error: null,
    orgProfileSubs: []
}, action) => {
switch(action.type) {
        case FETCH_ORGPROFILESUBS_PENDING: 
            return {
                ...state,
                pending: true
            }
        case FETCH_ORGPROFILESUBS_SUCCESS:
            return {
                ...state,
                pending: false,
                orgProfileSubs: action.payload
            }
        case FETCH_ORGPROFILESUBS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }
        default: 
            return state;
    }
};