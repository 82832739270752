import React, { useEffect } from 'react';
import { trunc, stripPlain } from '../utils'
import { Avatar, Box, Button, Collapse, Dialog, DialogActions, DialogContent, 
         Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Slide, Typography,
         makeStyles } from '@material-ui/core';
import { NegoCardEditor, TableEditable } from '.';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSeedling, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";  

import defaultLogo from '../assets/img/defaultlogo.png';

const axios = require('axios');
const dayjs = require('dayjs')

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = (props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h6" style={{fontWeight: '700'}}>{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" style={{position: 'absolute', right: '8px', top: '10px'}} onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} style={{fontSize: '20px', padding: '0px 3px 0px 3px'}} />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});


const DialogClauseHistory = (props) => {

    const useStyles = makeStyles(theme => ({
        cancelButton: {
            marginRight: 'auto',
        },
        mlittle: {
            marginTop: '4px',
            marginBottom: '4px',
        },
        editorBox: {
            maxWidth: '260px',
            fontSize: '14px',
            fontWeight: '300',
        },
        seedAvatar: {
            backgroundColor: theme.palette.grey[800],
        }
    }));
    const classes = useStyles();

    const [actLog, setActLog] = React.useState([])
    const [expanded, setExpanded] = React.useState([])
    const [firstClause, setFirstClause] = React.useState({})
    const [latestClause, setLatestClause] = React.useState({})

    useEffect(() => {
        if(props.open && props.singleClauseID !== undefined){
            axios.get('/logging/protect/albysclid/' + props.singleClauseID) // pull clause activity log for singleClauseID
            .then(function(res1) {
                let alog = [], preval = {};
                
                res1.data.data
                .filter((al) => !al.isInternal || al.orgID === props.user.orgID)
                .sort((a,b) => (a.creationDate < b.creationDate) ? 1 : ((b.creationDate < a.creationDate) ? -1 : 0))
                .forEach((al) => { // Ordered DESC by creationDate
                    if(al.logType === 'clNewVersion' && preval.logType === 'clNewVersion' && al.logDesc === preval.logDesc &&
                    alog[alog.length - 1] !== undefined && alog[alog.length - 1].change !== undefined && al.change !== undefined && al.change.new !== undefined) {

                        alog[alog.length - 1].change.old = al.change.old // Merge the newer change into the older one
                    } else if(al.logType === 'clNewTitle' && preval.logType === 'clNewTitle' && al.logDesc === preval.logDesc &&
                    alog[alog.length - 1] !== undefined && alog[alog.length - 1].change !== undefined && al.change !== undefined && al.change.new !== undefined) {
    
                        alog[alog.length - 1].change.old = al.change.old // Merge the newer clause title into the older one                        
                    } else if(al.logType === 'clTableUpdate' && preval.logType === 'clTableUpdate' && al.logDesc === preval.logDesc &&
                    alog[alog.length - 1] !== undefined && alog[alog.length - 1].change !== undefined && al.change !== undefined && al.change.new !== undefined) {

                        alog[alog.length - 1].change.old = al.change.old // Merge the newer clause title into the older one
                    } else {
                        alog.push(al)
                    }
                    preval = al;
                })
                setActLog(alog)
                // expand the first expandable item
                if(alog.length > 0 && (['clNewVersion', 'clNewTitle', 'clNumberChange', 'clDeleted', 'clAgrChangesApproved'].includes(alog[0].logType) || 
                (['clRestored','clTableUpdate'].includes(alog[0].logType) && alog[0].change !== undefined))){
                    setExpanded(exp => [...exp, alog[0]._id])
                }

            }).catch(err => console.log(err))

            axios.get('/clause/protect/sclause/' + props.singleClauseID) // pull clauses for singleClauseID
            .then(function(res2) {
                setFirstClause(res2.data.data.sort((a,b) => (a.creationDate > b.creationDate) ? 1 : ((b.creationDate > a.creationDate) ? -1 : 0))[0])
                setLatestClause(res2.data.data.sort((a,b) => (a.creationDate < b.creationDate) ? 1 : ((b.creationDate < a.creationDate) ? -1 : 0))[0])
            }).catch(err => console.log(err))
        }
    }, [props.open, props.singleClauseID, props.user.orgID])

    const closeDialog = () => {
        props.parentCallToClose()
    }

    const handleExpandCollapse = (id) => {
        if(expanded.includes(id)) {
            setExpanded(expanded.filter((e) => e !== id))
        } else {
            setExpanded(exp => [...exp, id]);
        }
    }

    return (
        <Dialog
        open={props.open}
        scroll="paper"
        TransitionComponent={Transition}
        keepMounted
        onClose={closeDialog}
        fullWidth={true}
        maxWidth="sm"
        >
        <DialogTitle onClose={closeDialog}>
            {latestClause !== undefined && latestClause !== null && ['cl','cl1','cl2','cl3','cl4','preamble'].includes(latestClause.clauseCat) ? "Clause " :
            latestClause !== undefined && latestClause !== null && latestClause.clauseCat === 'sec' ? "Section " :
            latestClause !== undefined && latestClause !== null && latestClause.clauseCat === 'ssec' ? "Sub section " : ""}
            History{latestClause !== undefined && latestClause !== null && latestClause !== {} &&
                            latestClause.title !== undefined && latestClause.title !== null && latestClause.title !== '' ? 
                                (": " + trunc(latestClause.title,33)) : 
                            latestClause !== undefined && latestClause !== null && latestClause !== {} &&
                            latestClause.clauseCat === 'table' ? 
                                ": (Table) " :
                            latestClause !== undefined && latestClause !== null && latestClause !== {} &&
                            latestClause.plain !== undefined && latestClause.plain[0] !== '' ?
                                (": " + trunc(stripPlain(latestClause.plain[0]),33)) : '' }
        </DialogTitle>

        <DialogContent id="msgContainer">

        <List>

            {actLog
              .map((item) => 

                <React.Fragment key={item._id}>
                <ListItem button={(['clNewVersion', 'clNewTitle', 'clNumberChange', 'clRestored', 'clTableUpdate', 'clAgrChangesApproved'].includes(item.logType) && item.change !== undefined) || ['clDeleted'].includes(item.logType) }
                    onClick={(['clNewVersion', 'clNewTitle', 'clNumberChange', 'clRestored', 'clTableUpdate', 'clAgrChangesApproved'].includes(item.logType) && item.change !== undefined) || ['clDeleted'].includes(item.logType) ? 
                        e => handleExpandCollapse(item._id): null}>
                    <ListItemAvatar>
                    <Avatar src={props.primaryCpty._id === item.entID ? props.primaryCpty.logoURL :
                                 props.secondaryCpty._id === item.entID ? props.secondaryCpty.logoURL : defaultLogo} 
                      alt="logo"/>
                    </ListItemAvatar>
                    <ListItemText 
                        primary={<Typography variant="subtitle2">{item.logDesc}</Typography>} 
                        secondary={dayjs(item.creationDate).format('MMM D, YYYY h:mm A')} />
                    {(['clNewVersion', 'clNewTitle', 'clNumberChange', 'clRestored', 'clTableUpdate', 'clAgrChangesApproved'].includes(item.logType) && item.change !== undefined) || ['clDeleted'].includes(item.logType) ?
                    <FontAwesomeIcon icon={expanded.includes(item._id) ? faChevronUp: faChevronDown} />
                    : '' }
                </ListItem>
                <Collapse in={expanded.includes(item._id)} timeout="auto" unmountOnExit>

                    {item.logType === 'clDeleted' ?
                    <Box mt={2} mb={2} mr={3} ml={3}>
                        {latestClause !== undefined && latestClause !== null && latestClause.plain !== undefined && (['preamble'].includes(latestClause.clauseCat) || latestClause.clauseCat.startsWith('cl')) ?
                         latestClause.plain.map((par,i) => (
                            <Typography key={i} variant="body2">{stripPlain(par)}</Typography>
                         ))
                         :
                         latestClause !== undefined && latestClause !== null && latestClause.plain !== undefined && ['sec','ssec'].includes(latestClause.clauseCat) ?
                            <Typography variant="subtitle1" align="center">Section title: "{latestClause.title}"</Typography>
                         :''}
                    </Box>
                    :['clTableUpdate'].includes(item.logType) && item.change !== undefined && item.change.old !== undefined && item.change.new !== undefined &&
                    item.change.old.head !== undefined && item.change.new.head !== undefined ?
                    <Box mt={2} mb={2} mr={3} ml={3}>

                        <Box>
                            <Typography variant="subtitle2" align="left" gutterBottom>From:</Typography>
                            <TableEditable
                            active={false}
                            disabled={true}
                            editMode={'none'}
                            proposedDel={null}
                            focusClause={() => void 0}
                            curCpty={"primary"}
                            //diffColor={"red"}
                            clause={{clauseObject: item.change.old}}
                            />
                        </Box>

                        <Box mt={2}>
                            <Typography variant="subtitle2" align="left" gutterBottom>To:</Typography>
                            <TableEditable
                            active={false}
                            disabled={true}
                            editMode={'none'}
                            proposedDel={null}
                            focusClause={() => void 0}
                            curCpty={"primary"}
                            //diffColor={"green"}
                            clause={{clauseObject: item.change.new}}
                            />
                        </Box>

                    </Box>
                    :
                    <Grid container direction="row" alignItems="flex-start" justify="center">
                        <Grid item xs={12} sm={6}>
                            {['clNewTitle', 'clNumberChange'].includes(item.logType) && item.change !== undefined ?
                            <Box align="center" mt={1} mb={2}>
                                <Typography variant="subtitle2">From</Typography>
                                <Box align="center" className={classes.editorBox} mt={1} mb={1}>{item.change.old}</Box>
                            </Box>
                            :['clNewVersion','clRestored', 'clAgrChangesApproved'].includes(item.logType) && item.change !== undefined && item.change.old !== undefined ?
                            <Box align="center" mt={1} mb={2}>
                                <Typography variant="subtitle2">From</Typography>
                                <Box align="left" className={classes.editorBox} mt={1} mb={1}>
                                    <NegoCardEditor
                                    clid={item._id}
                                    curCpty={"primary"}
                                    user={props.user}
                                    content={item.change !== undefined ? item.change.old : {}}
                                    parentCallback={() => void 0}
                                    callbackOptions={() => void 0}
                                    reviewChangesMode={false}
                                    activeClause={false}
                                    optionClicked={null}
                                    focusClause={() => void 0}
                                    disableEditing={true} />
                                </Box>
                            </Box>
                            :''}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {['clNewTitle', 'clNumberChange'].includes(item.logType) && item.change !== undefined ?
                            <Box align="center" mt={1} mb={2}>
                                <Typography variant="subtitle2">To</Typography>
                                <Box align="center" className={classes.editorBox} mt={1} mb={1}>{item.change.new}</Box>
                            </Box>
                            :['clNewVersion','clRestored', 'clAgrChangesApproved'].includes(item.logType) && item.change !== undefined && item.change.new !== undefined ?
                            <Box align="center" mt={1} mb={2}>
                                <Typography variant="subtitle2">To</Typography>
                                <Box align="left" className={classes.editorBox} mt={1} mb={1}>
                                    <NegoCardEditor 
                                    clid={item._id}
                                    curCpty={"primary"}
                                    user={props.user}
                                    content={item.change !== undefined ? item.change.new : {}}
                                    parentCallback={() => void 0}
                                    callbackOptions={() => void 0}
                                    reviewChangesMode={false}
                                    activeClause={false}
                                    optionClicked={null}
                                    focusClause={() => void 0}
                                    disableEditing={true} />
                                </Box>
                            </Box>
                            : '' }
                        </Grid>
                    </Grid>
                    }

                </Collapse>
                </React.Fragment>

                )}

                <ListItem key="creation">
                    <ListItemAvatar>
                    <Avatar className={classes.seedAvatar}><FontAwesomeIcon icon={faSeedling} /></Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                        primary={<Typography variant="subtitle2">Clause created</Typography>} 
                        secondary={firstClause !== undefined && firstClause !== null && firstClause !== {} &&
                            firstClause.creationDate !== undefined ? dayjs(firstClause.creationDate).format('MMM D, YYYY h:mm A') : ''} />
                </ListItem>
            </List>

        </DialogContent>

        <DialogActions className={classes.mlittle}>
            <Button onClick={closeDialog} variant="text" color="primary" className={classes.cancelButton}>
                Close
            </Button>
        </DialogActions>

    </Dialog>

    )
}

export default DialogClauseHistory