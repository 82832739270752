import { getTOC, stripPlain } from '.'

const getAgrContentForPDF = (priority, attachmentURL, agrTitle, avClauses, clauses) => {

    let clauseContent = []
    let toc = clauses.length > 0 && avClauses.length > 0 ? getTOC(clauses, avClauses, true) : []

    toc.forEach((t, i) => {
        let cl = clauses.filter((c) => c._id === t.id)[0]; // Assign cl

        // Define content - where applicable
        let ctitle = ''
        let ccontent = ''
        if(['sec','ssec','cl','cl1','cl2','cl3','cl4','preamble'].includes(cl.clauseCat)) { 
            cl.plain.forEach((par) => { 
                if(par.startsWith('<title>') || par.startsWith('<secTitle>') || par.startsWith('<ssecTitle>')) { ctitle = stripPlain(par) } 
                else { ccontent = ccontent + par + '\n'; }
            }) 
        }
        ccontent = ['sec','ssec','cl','cl1','cl2','cl3','cl4','preamble'].includes(cl.clauseCat) ? ccontent.substring(0, ccontent.length - 1) : '';

        clauseContent.push({
            clauseCat: cl.clauseCat,
            //no: t.no,
            title: i === 0 && cl.title === 'Preamble' ? '' : ctitle,
            content: ccontent,
            clauseObject: cl.clauseObject,
        })
    })

    // Fill the Agreement Content variable for PDF and AgrExec creation
    let agrContent = {
        priority: priority,
        attachmentURL: attachmentURL !== null ? attachmentURL : '',
        agrTitle: agrTitle,
        clauseContent: clauseContent,
    }
    
    return agrContent
}

export { getAgrContentForPDF }