import { FETCH_ORGAGRTEMPLATES_PENDING, FETCH_ORGAGRTEMPLATES_SUCCESS, FETCH_ORGAGRTEMPLATES_ERROR, 
    UPDATE_ORGAGRTEMPLATE, ADD_ORGAGRTEMPLATE, DELETE_ORGAGRTEMPLATE,
    FETCH_ORGPROFILEAGRTEMPLATES_PENDING, FETCH_ORGPROFILEAGRTEMPLATES_SUCCESS, FETCH_ORGPROFILEAGRTEMPLATES_ERROR 
} from '../ActionTypes';

export const OrgAgrTemplates = (state  = { 
    pending: false,
    error: null,
    orgAgrTemplates: []
}, action) => {
switch(action.type) {
        case FETCH_ORGAGRTEMPLATES_PENDING: 
            return {
                ...state,
                pending: true
            }
        case FETCH_ORGAGRTEMPLATES_SUCCESS:
            return {
                ...state,
                pending: false,
                orgAgrTemplates: action.payload
            }
        case FETCH_ORGAGRTEMPLATES_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }
        case UPDATE_ORGAGRTEMPLATE:
            // Find the index of the OrgAgrTemplates to replace with the updated one
            var newoat = action.payload
            var newOats = state.orgAgrTemplates
            var index = null;
            for (var i=0; i<state.orgAgrTemplates.length; i++) {
                if ( state.orgAgrTemplates[i]._id === newoat._id ) {
                    index = i;
                    break;
                }
            }
            newOats[index] = newoat
            return { 
                ...state, 
                pending: false,
                orgAgrTemplates: newOats};
        case ADD_ORGAGRTEMPLATE:
            var oat = action.payload;
            return { 
                ...state, 
                pending: false,
                orgAgrTemplates: state.orgAgrTemplates.concat(oat)};
        case DELETE_ORGAGRTEMPLATE:
            var oatid = action.payload;
            return { 
                ...state, 
                pending: false,
                orgAgrTemplates: state.orgAgrTemplates.filter(({ _id }) => _id !== oatid)};
        default: 
            return state;
    }
};

export const OrgProfileAgrTemplates = (state  = { 
    pending: false,
    error: null,
    orgProfileAgrTemplates: []
}, action) => {
switch(action.type) {
        case FETCH_ORGPROFILEAGRTEMPLATES_PENDING: 
            return {
                ...state,
                pending: true
            }
        case FETCH_ORGPROFILEAGRTEMPLATES_SUCCESS:
            return {
                ...state,
                pending: false,
                orgProfileAgrTemplates: action.payload
            }
        case FETCH_ORGPROFILEAGRTEMPLATES_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }
        default: 
            return state;
    }
};