import React from 'react';
import { trunc } from '../utils';
import { useHistory } from 'react-router-dom';
import { Avatar, Box, Card, CardContent, Grid, Typography,
         makeStyles } from '@material-ui/core';
import { Logo } from '.';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const ThumbTodo = (props) => {

  const useStyles = makeStyles(theme => ({
    card: {
        [theme.breakpoints.only('xs')]: { width: '144px', height: '155px' },
        [theme.breakpoints.only('sm')]: { width: '126px', height: '155px' },
        [theme.breakpoints.up('md')]: { width: '200px', height: '175px' },
        '&:hover' : {
          boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 24px 0px',
          cursor: 'pointer',
        }
    },
    cardDash: {
      [theme.breakpoints.only('xs')]: { width: '144px', height: '175px' },
      [theme.breakpoints.only('sm')]: { width: '200px', height: '175px' },
      [theme.breakpoints.up('md')]: { width: '200px', height: '180px' },
      '&:hover' : {
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 24px 0px',
        cursor: 'pointer',
      }
    },
    cardAdmin: {
      [theme.breakpoints.only('xs')]: { width: '144px', height: '155px' },
      [theme.breakpoints.only('sm')]: { width: '126px', height: '155px' },
      [theme.breakpoints.up('md')]: { width: '200px', height: '175px' },
    },
    updateFont: {
        [theme.breakpoints.up('md')]: { paddingLeft: '10px', paddingRight: '10px', paddingBottom : '5px', },
    },
    updateDone: {
      [theme.breakpoints.up('md')]: { paddingLeft: '10px', paddingRight: '10px', paddingBottom : '5px', },
      color: theme.palette.grey[400],
    },
    todoFont: {
      [theme.breakpoints.down('sm')]: { fontSize: '12px' },
    },
    doneFont: {
      [theme.breakpoints.down('sm')]: { fontSize: '12px' },
      color: theme.palette.grey[400],
    },
    line0font: {
      fontSize: '12px'
    },
    dashTicketIcon: {
      [theme.breakpoints.only('xs')]: { height: '25px', width: '25px', margin: '20px 5px 20px 5px' },
      [theme.breakpoints.only('sm')]: { height: '30px', width: '30px', margin: '20px 5px 20px 5px' },
      [theme.breakpoints.up('md')]: { height: '35px', width: '35px', margin: '25px 5px 30px 5px' },
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.contrastText,
    },
    dashTicketLogo: {
      [theme.breakpoints.only('xs')]: { height: '25px', width: '25px', margin: '20px 5px 20px 5px' },
      [theme.breakpoints.only('sm')]: { height: '30px', width: '30px', margin: '20px 5px 20px 5px' },
      [theme.breakpoints.up('md')]: { height: '35px', width: '35px', margin: '25px 5px 30px 5px' },
    },
    primAv: {
      margin: '10px 10px 20px 10px',
      [theme.breakpoints.only('xs')]: { height: '50px', width: '50px' },
      [theme.breakpoints.only('sm')]: { height: '50px', width: '50px', },
      [theme.breakpoints.up('md')]: { height: '60px', width: '60px' },
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.contrastText,
    },
    secAv: {
      margin: '10px 10px 20px 10px',
      [theme.breakpoints.only('xs')]: { height: '50px', width: '50px' },
      [theme.breakpoints.only('sm')]: { height: '50px', width: '50px', },
      [theme.breakpoints.up('md')]: { height: '60px', width: '60px' },
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    tertAv: {
      margin: '10px 10px 20px 10px',
      [theme.breakpoints.only('xs')]: { height: '50px', width: '50px' },
      [theme.breakpoints.only('sm')]: { height: '50px', width: '50px', },
      [theme.breakpoints.up('md')]: { height: '60px', width: '60px' },
      backgroundColor: theme.palette.tertiary.main,
      color: theme.palette.secondary.contrastText,
    },
    admAv: {
      margin: '10px 10px 20px 10px',
      [theme.breakpoints.only('xs')]: { height: '50px', width: '50px' },
      [theme.breakpoints.only('sm')]: { height: '50px', width: '50px', },
      [theme.breakpoints.up('md')]: { height: '60px', width: '60px' },
      backgroundColor: theme.palette.grey[500],
      color: theme.palette.secondary.contrastText,
    },
    sucAv: {
      margin: '10px 10px 20px 10px',
      [theme.breakpoints.only('xs')]: { height: '50px', width: '50px' },
      [theme.breakpoints.only('sm')]: { height: '50px', width: '50px', },
      [theme.breakpoints.up('md')]: { height: '60px', width: '60px' },
      backgroundColor: theme.palette.success.main,
      color: theme.palette.secondary.contrastText,
    },
    iconPrimary: {
      color: theme.palette.primary.main,
      fontSize: '40px',
      margin: '24px 0px 28px 0px'
    },
    iconSecondary: {
      color: theme.palette.secondary.main,
      fontSize: '40px',
      margin: '24px 0px 28px 0px'
    },
    iconTertiary: {
      color: theme.palette.tertiary.main,
      fontSize: '40px',
      margin: '24px 0px 28px 0px'
    },
    iconGrey: {
      color: theme.palette.grey[800],
      fontSize: '40px',
      margin: '24px 0px 28px 0px'
    }
  }));
  const classes = useStyles();

  const history = useHistory()

  const handleThumbClick = (event) => {
    if(props.route === "click") { 
      props.click();
    } else if(!props.adminNeeded) {
      history.push(props.route);
    }
  }

  return (
    <Card variant="outlined" className={props.dash ? classes.cardDash : props.adminNeeded ? classes.cardAdmin : classes.card} 
      onClick={e => (props.route === "upload" ? '' : handleThumbClick(e))}>
        <CardContent align="center">
            {props.dashTicket ?
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item>
                <Avatar className={classes.dashTicketLogo} src={props.logo} />
              </Grid>
              <Grid item>
                <Avatar className={classes.dashTicketIcon}><FontAwesomeIcon icon={props.icon} /></Avatar>
              </Grid>
            </Grid>
            :
            props.iconOnly && props.icon === "Logo" ?
            <Box style={{margin: '24px 0px 25px 0px'}}>
            <Logo size="l" to="/home" />
            </Box>
            :props.iconOnly ?
            <FontAwesomeIcon icon={props.icon} className={
              props.iconColor === "secondary" ? classes.iconSecondary : 
              props.iconColor === "tertiary" ? classes.iconTertiary : 
              props.iconColor === "grey" ? classes.iconGrey : 
                classes.iconPrimary} />
            :
            <Avatar className={props.done ? classes.sucAv : props.adminNeeded ? classes.admAv : 
                props.color === "secondary" ? classes.secAv : props.color === "tertiary" ? classes.tertAv : classes.primAv}>
                <FontAwesomeIcon icon={props.done ? faCheck : props.icon} />
            </Avatar>
            }

            {props.line0 !== undefined && props.line0 !== null && props.line0 !== '' ?
              <>
                <Box display={{ xs: 'none', sm: 'inline-block' }}>
                  <Typography variant="body2" className={classes.line0font}>{props.line0}</Typography>
                </Box>
                <Box display={{ xs: 'inline-block', sm: 'none' }}>
                  <Typography variant="body2" className={classes.line0font}>{trunc(props.line0, 44)}</Typography>
                </Box>
              </>
            :
            <>
            <Typography variant="subtitle2" className={props.done ? classes.doneFont : classes.todoFont}>
                {props.line1}
            </Typography>

            {props.dash ?
            <Typography variant="body2" color="textSecondary" className={classes.updateFont}>
              {props.line2}
            </Typography>
            :
            <Box display={{ xs: 'none', md: 'block' }}>
            <Typography variant="body2" color="textSecondary" className={props.done ? classes.updateDone : classes.updateFont}>
                {props.adminNeeded ? "Ask your admin" : props.line2}
            </Typography>
            </Box>
            }
            </>
            }
        </CardContent>

    </Card>
  );
}

export default ThumbTodo