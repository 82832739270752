function convertBlocksToPlainComment(blocks) {
  
    let newComment = '';
    
    blocks.blocks.forEach((b,i) => { // for each block of text
        newComment = newComment + b.text + (blocks.blocks.length === i + 1 ? '' : '\n');
    })
    
    return newComment;
}
  
export { convertBlocksToPlainComment }