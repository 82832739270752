import React from 'react';
import PropTypes from 'prop-types'; // https://reactjs.org/docs/typechecking-with-proptypes.html
import { Chip, makeStyles } from '@material-ui/core';

const ChipStatus = (props) => {

  const useStyles = makeStyles(theme => ({
    chipGrey: {
        [theme.breakpoints.down('sm')]: { fontSize: '11px' },
        [theme.breakpoints.up('md')]: { fontSize: '12px', paddingLeft: '5px', paddingRight: '5px' },
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.primary.contrastText,
        fontWeight: '400',
    },
    chipGreyGreen: {
        [theme.breakpoints.down('sm')]: { fontSize: '11px' },
        [theme.breakpoints.up('md')]: { fontSize: '12px', paddingLeft: '5px', paddingRight: '5px' },
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.success.main,
        fontWeight: '400',
    },
    chipGreyRed: {
        [theme.breakpoints.down('sm')]: { fontSize: '11px' },
        [theme.breakpoints.up('md')]: { fontSize: '12px', paddingLeft: '5px', paddingRight: '5px' },
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.error.main,
        fontWeight: '400',
    },
    chipPrimary: {
        [theme.breakpoints.down('sm')]: { fontSize: '11px' },
        [theme.breakpoints.up('md')]: { fontSize: '12px', paddingLeft: '5px', paddingRight: '5px' },
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontWeight: '400',
    },
  }));
  const classes = useStyles();
  
      return (
          <Chip
          label={props.title}
          size="small"
          color={"primary"}
          variant="default"
          className={
            props.color === 'grey' ? classes.chipGrey : 
            props.color === 'greygreen' ? classes.chipGreyGreen : 
            props.color === 'greyred' ? classes.chipGreyRed : 
            classes.chipPrimary}
        />   
      )
}

ChipStatus.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
};

export default ChipStatus