import React, { useEffect } from 'react';
import theme from '../theme/theme';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { trunc, pad, getAgrContentForPDF, getEditModeOption, randomString as generateRandomString, 
         getUsersWhoLockAClause, onlyUnique, greatPatternFinder, getSmartFieldValue, 
         convertBlocksToPlain, calculateExpiryDate, getRecipientModule } from '../utils';
import { Avatar, Button, Box, Card, CardContent, Checkbox, Chip, Dialog, DialogActions, DialogContent, Divider,
         FormControl, FormControlLabel, Grid, IconButton, 
         List, ListItem, ListItemAvatar, ListItemIcon, ListItemText,
         Menu, MenuItem, MobileStepper, RadioGroup, Radio,
         Slide, TextareaAutosize, TextField, Tooltip, Typography,
         makeStyles, CircularProgress, InputBase } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DialogImportDoc, InformationalTooltip, SelectUsers, ThumbTodo } from '.';
import { UserForm } from './forms'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck,
         faCircle, faCheckCircle, faThumbsUp, faCaretDown, faComment, faUserEdit, faUserCheck,
         faExternalLinkAlt, faExclamationTriangle, faFileExport, faFileImport, faFilePdf, faFileUpload, 
         faPlusCircle, faArrowLeft, faPaperPlane, faArrowRight, faTimesCircle, faUserPlus, faUserTimes, 
         faEye, faSignature, faUpload, faDownload, faPen, faUndo, faClock, faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faHubspot, faSalesforce } from '@fortawesome/free-brands-svg-icons';

import { countries } from '../assets/static/countries';

import pdf from '../assets/img/pdf.png';
import docx from '../assets/img/docx.png';
import flag_eu from '../assets/img/flag_eu.png';
import flag_ch from '../assets/img/flag_ch.png';
import hubspot from '../assets/img/hubspot.png';
import salesforce from '../assets/img/salesforce.png';

const axios = require('axios');
const download = require("js-file-download")

const icon = <FontAwesomeIcon icon={faCircle} />;
const checkedIcon = <FontAwesomeIcon icon={faCheckCircle} />;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = (props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h6" style={{fontWeight: '700'}}>{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" style={{position: 'absolute', right: '8px', top: '10px'}} onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} style={{fontSize: '20px', padding: '0px 3px 0px 3px'}} />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

function StyledCheckbox(props) {
  return (
    <Checkbox
      disableRipple
      checkedIcon={<FontAwesomeIcon icon={faThumbsUp} style={{fontSize: '30px'}} />}
      icon={<FontAwesomeIcon icon={faSquare} style={{fontSize: '30px', color: '#827f8f'}} />}
      {...props} />
  );
}

function Task(props){

    const { id, index, signer, userIsPrimary, user, /* userIsReadOnly, isCpty, userOrgID, */
            handleRemoveSignatory, sigQuality, signMethod, handleUserDetailUpdate, requestToUpdatePhone, /*uid,*/ agrStatus } = props;

    return (
       <Draggable draggableId={id} key={id} index={index} isDragDisabled={user.role === 'Counterparty'}>

         {(provided) => (
            <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps} 
            align="center" mb={0}>

                <Grid container direction="row" alignItems="center" justify="center">
                    <Grid item xs={1}><Box pt={4} pr={1} pl={1} align="right">{index + 1}.</Box></Grid>
                    <Grid item xs={10} sm={6}>
                        <RenderSignBox 
                            key={signer._id} 
                            signer={signer} 
                            boxType={(signer.orgID === user.orgID && userIsPrimary) || (signer.orgID !== user.orgID && !userIsPrimary) ? 'primary' : 'secondary'} 
                            handleRemoveSignatory={handleRemoveSignatory}
                            userIsPrimary={userIsPrimary} 
                            user={user}
                            sigQuality={sigQuality}
                            signMethod={signMethod}
                            handleUserDetailUpdate={handleUserDetailUpdate}
                            requestToUpdatePhone={requestToUpdatePhone}
                            agrStatus={agrStatus}
                            isDragging={true} />
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </Box>
        )}

       </Draggable>
    )
}

function Column(props){
    const { id, list, userIsPrimary, user,/* userIsReadOnly, isCpty, userOrgID, uid,*/ handleRemoveSignatory, sigQuality, 
            handleUserDetailUpdate, requestToUpdatePhone, agrStatus } = props;
 
    return (
 
        <Droppable droppableId={id}>
        {provided => (
 
           <div {...provided.droppableProps} ref={provided.innerRef}>
             {list
             .map((signer,index)=>{
                return <Task 
                            id={signer._id} 
                            key={index} 
                            index={index}
                            signer={signer}
                            userIsPrimary={userIsPrimary}
                            user={user}
                            //userIsReadOnly={userIsReadOnly}
                            //isCpty={isCpty} 
                            //userOrgID={userOrgID}
                            //uid={uid}
                            handleRemoveSignatory={handleRemoveSignatory}
                            sigQuality={sigQuality}
                            handleUserDetailUpdate={handleUserDetailUpdate}
                            requestToUpdatePhone={requestToUpdatePhone}
                            agrStatus={agrStatus}
                        />
             })}
 
            {provided.placeholder}
           </div>
         )
        }
        </Droppable>
    )
}

const RenderSignBox = ({boxType, signer, user, userIsPrimary, handleRemoveSignatory, sigQuality, signMethod,
    handleUserDetailUpdate, requestToUpdatePhone, agrStatus, isDragging}) => {

    const useStyles = makeStyles(theme => ({
        cardWrapper: {
            position: 'relative', 
            width: '200px',
        },
        cardPrimaryNoHover: {
            width: '200px',
            border: '0px',
            borderRadius: '8px 8px 0px 0px',
            backgroundColor: '#f6f6f6',
            borderBottom: '2px solid ' + theme.palette.primary.main,
        },
        cardSecondaryNoHover: {
            width: '200px',
            border: '0px',
            borderRadius: '8px 8px 0px 0px',
            backgroundColor: '#f6f6f6',
            borderBottom: '2px solid ' + theme.palette.secondary.main,
        },
        cardContent: {
            marginTop: '13px',
            "&:first-child": {
                paddingTop: '10px',
            },
            "&:last-child": {
                paddingBottom: '10px',
            }
        },
        avPhoto: {
            position: 'absolute',
            top: '-20px',
            left: '80px',
            height: '40px',
            width: '40px',
            backgroundColor: theme.palette.grey[800],
            fontWeight: '500',
            fontSize: '16px',
        },
        removeIconPrimary: {
            position: 'absolute', 
            top: '5px', 
            right: '5px',
            fontSize: '14px',
            color: theme.palette.primary.main,
            padding: '4px 3px 4px 3px',
            '&:hover': {
                color: theme.palette.primary.dark,
                cursor: 'pointer',
            }
        },
        removeIconSecondary: {
            position: 'absolute', 
            top: '5px', 
            right: '5px',
            fontSize: '14px',
            color: theme.palette.secondary.main,
            padding: '4px 3px 4px 3px',
            '&:hover': {
                color: theme.palette.secondary.dark,
                cursor: 'pointer',
            }
        },
        bold: {
            fontWeight: '700',
        },
        lighterGrey: {
            color: theme.palette.grey[600],
        },
        lighterGreyWithHover: {
            color: theme.palette.grey[600],
            '&:hover': {
                color: theme.palette.primary.main,
                fontWeight: '400',
                cursor: 'pointer',
            }
        },
        missingDetail: {
            color: theme.palette.error.main,
            fontWeight: '700',
            cursor: 'pointer',
            '&:hover': {
                color: theme.palette.error.dark,
                textDecoration: 'underline'
            }
        },
        missingDetailNoLink: {
            color: theme.palette.error.main,
            fontWeight: '700',
        },
        notificationSent: {
            color: theme.palette.success.main,
            fontWeight: '700',
        },
        phoneNoInput: {
            width: '120px',
            fontSize: '13px',
            border: '1px solid' + theme.palette.grey[400],
            borderRadius: '10px',
            padding: '1px 10px 1px 10px',
            backgroundColor: theme.palette.primary.contrastText,
        },
    }));
    const classes = useStyles();

    const [changingPhoneNumberForUID, setChangingPhoneNumberForUID] = React.useState(null)
    const [newMobileNumber, setNewMobileNumber] = React.useState("+")
    const [newEntityName, setNewEntityName] = React.useState(signer.entityName)
    const [notificationEmailSent, setNotificationEmailSent] = React.useState(false)
    const [hoverEntityName, setHoverEntityName] = React.useState(false)
    const [changingEntityNameForUID, setChangingEntityNameForUID] = React.useState(null)

    const isCpty = user.role === 'Counterparty'
    const userIsReadOnly = user.readOnly

    const handleMobileNumberUpdate = (newVal) => {
        let stripped = newVal
        stripped = stripped.replace(/[^\d]/g, "")
        setNewMobileNumber("+" + stripped)
    }

    const handleMobileNumberSubmit = () => {
        if(newMobileNumber.length > 6 && newMobileNumber.length < 16 && changingPhoneNumberForUID !== null) {
            handleUserDetailUpdate('phone', changingPhoneNumberForUID, newMobileNumber)
            setChangingPhoneNumberForUID(null);
            setNewMobileNumber("+");
        }
    }

    const handleEntityNameChangeSubmit = () => {
        if(newEntityName.length > 2 && newEntityName.length < 80 && changingEntityNameForUID !== null) {
            handleUserDetailUpdate('entityName', changingEntityNameForUID, newEntityName)
            setChangingEntityNameForUID(null)
        }
    }

    const handleRequestToUpdatePhone = (requesteeUID, requestorUID) => {
        setNotificationEmailSent(true)
        requestToUpdatePhone(requesteeUID, requestorUID) // sends to parent function
    }

    return (
    <Box align="center" className={classes.cardWrapper} mt={4}>
        <Card variant="outlined" 
        className={
            boxType === 'primary' ? classes.cardPrimaryNoHover :
            boxType === 'secondary' ? classes.cardSecondaryNoHover : null}>
        <Avatar src={signer.photoURL} className={classes.avPhoto}>{signer.firstName[0] + signer.lastName[0]}</Avatar>
        <CardContent  className={classes.cardContent}>

            {// user is a subscriber and not read only or editing someone on the other side
            // user is a cpty and editing someone on their side
            (!isCpty && (!userIsReadOnly || (userIsPrimary && boxType === 'secondary') || (!userIsPrimary && boxType === 'primary'))) ||
            (isCpty && ((userIsPrimary && boxType === 'primary') || (!userIsPrimary && boxType === 'secondary'))) ?
                <FontAwesomeIcon icon={faTimesCircle} className={boxType === 'primary' ? classes.removeIconPrimary : classes.removeIconSecondary} 
                onClick={e => handleRemoveSignatory(boxType, signer._id)} />
            : '' }

            <Tooltip title={!isDragging ? signer.email : ""} placement={boxType === 'primary' ? "left" : "right"}>
                <Typography variant="subtitle2" className={classes.bold}>{trunc(signer.displayName,22)}</Typography>
            </Tooltip>

            {changingEntityNameForUID === signer._id ?
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item>
                    <IconButton style={{padding: '5px 5px 5px 5px'}} onClick={e => setChangingEntityNameForUID(null)}>
                        <FontAwesomeIcon icon={faArrowLeft} style={{fontSize: '14px'}} />
                    </IconButton>
                </Grid>
                <Grid item>
                    <InputBase 
                    value={newEntityName}
                    onChange={e => setNewEntityName(e.target.value)}
                    className={classes.phoneNoInput}
                    placeholder={"Entity Name..."}
                    />
                </Grid>
                <Grid item>
                    <IconButton style={{padding: '5px 5px 5px 5px', color: theme.palette.primary.main}} onClick={e => handleEntityNameChangeSubmit()}>
                        <FontAwesomeIcon icon={faCheck} style={{fontSize: '14px'}} />
                    </IconButton>
                </Grid>
            </Grid>
            :
            <div onMouseEnter={e => setHoverEntityName(true)} onMouseLeave={e => setHoverEntityName(false)}>
            <Typography variant="body2" className={!isDragging ? classes.lighterGreyWithHover : classes.lighterGrey} onClick={!isDragging ? e => setChangingEntityNameForUID(signer._id) : null}>
                {trunc(signer.entityName,20)}
                {hoverEntityName && !isDragging ? <>&nbsp;<FontAwesomeIcon icon={faPen} style={{fontSize: '10px'}} /></> : ''}
            </Typography>
            </div>
            }

            <Typography variant="body2" className={classes.lighterGrey} gutterBottom>{trunc((signer.title !== undefined ? signer.title : '(no title provided)'),22)}</Typography>
            {/*<Typography variant="body2" gutterBottom className={classes.lighterGrey}>{trunc(signer.email,26)}</Typography>*/}

            {changingPhoneNumberForUID === signer._id ?
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item>
                    <IconButton style={{padding: '5px 5px 5px 5px'}} onClick={e => setChangingPhoneNumberForUID(null)}>
                        <FontAwesomeIcon icon={faArrowLeft} style={{fontSize: '14px'}} />
                    </IconButton>
                </Grid>
                <Grid item>
                    <InputBase 
                    value={newMobileNumber}
                    onChange={e => handleMobileNumberUpdate(e.target.value)}
                    className={classes.phoneNoInput}
                    placeholder={"Phone number..."}
                    />
                </Grid>
                <Grid item>
                    <IconButton style={{padding: '5px 5px 5px 5px', color: theme.palette.primary.main}} onClick={e => handleMobileNumberSubmit()}>
                        <FontAwesomeIcon icon={faCheck} style={{fontSize: '14px'}} />
                    </IconButton>
                </Grid>
            </Grid>
            : 
            sigQuality.startsWith('QES') && signMethod === 'skribble' ?
            <Typography variant="subtitle2" gutterBottom className={classes.lighterGrey}><a href="https://www.skribble.com/en-eu/enable-qes/" target="_blank" rel="noopener noreferrer" className="seclink">Skribble</a> QES required</Typography>
            :
            sigQuality.startsWith('QES') ?
            <Typography variant="subtitle2" gutterBottom className={classes.lighterGrey}>QES verification required</Typography>
            : // Signer needs to update phone, but cannot inform yet, since it's a cpty and the agr is still in Draft
            (sigQuality.startsWith('AES') &&
                (signer.phone === undefined || signer.phone === null || signer.phone === '') && user.orgID !== signer.orgID && ['Draft'].includes(agrStatus)) ?
            <Typography variant="body2" gutterBottom className={classes.lighterGrey}>Phone# to be provided</Typography>
            : // Signer needs to update phone, the user has notified the signer
            sigQuality.startsWith('AES') &&
            (signer.phone === undefined || signer.phone === null || signer.phone === '') && user._id !== signer._id && notificationEmailSent ?
            <Typography variant="body2" gutterBottom className={classes.notificationSent}>Notification email sent!</Typography>
            : // Signer needs to update phone, the user can notify the signer
            sigQuality.startsWith('AES') &&
            (signer.phone === undefined || signer.phone === null || signer.phone === '') && user._id !== signer._id ?
            <Typography variant="body2" gutterBottom className={classes.missingDetail} onClick={e => handleRequestToUpdatePhone(signer._id, user._id)}>Request to provide phone#</Typography>
            : // Signer needs to update phone, the user is the signer and can update
            sigQuality.startsWith('AES') &&
            (signer.phone === undefined || signer.phone === null || signer.phone === '') && user._id === signer._id ?
            <Typography variant="body2" gutterBottom className={classes.missingDetail} onClick={e => setChangingPhoneNumberForUID(signer._id)}>Provide Phone Number&nbsp;&nbsp;<FontAwesomeIcon icon={faPen} style={{fontSize: '10px'}} /></Typography>
            : // Phone is needed and available, show it
            sigQuality.startsWith('AES') && user._id !== signer._id ? 
            <Typography variant="body2" gutterBottom className={classes.lighterGrey}>{"Phone number provided"}</Typography>
            : // Phone is needed and available, show it
            sigQuality.startsWith('AES') && user._id === signer._id ? 
            <Typography variant="body2" gutterBottom className={classes.lighterGrey}>{trunc("+" + signer.phone,26)}</Typography>
            :'' // Phone is not needed, don't show
            }
        </CardContent>
        </Card>
    </Box>
    )
}

const DialogAgrAction = (props) => {

  const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: '700',
    },
    addButton: {
        fontWeight: '600',
        fontSize: '13px',
        padding: '4px 25px 4px 25px',
    },
    plusIcon: {
        paddingRight: '12px',
        fontSize: '12px',
    },
    cardWrapper: {
        position: 'relative', 
        width: '200px',
    },
    cardPrimaryNoHover: {
        width: '200px',
        borderRadius: '3px',
        borderBottom: '2px solid ' + theme.palette.primary.main,
    },
    cardPrimaryCpty: {
        width: '200px',
        borderRadius: '3px',
        borderBottom: '2px solid ' + theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.error.main,
            textDecoration: 'line-through',
            cursor: 'pointer',
        },
    },
    cardSecondaryNoHover: {
        width: '200px',
        borderRadius: '3px',
        borderBottom: '2px solid ' + theme.palette.secondary.main,
    },
    cardSecondaryCpty: {
        width: '200px',
        borderRadius: '3px',
        borderBottom: '2px solid ' + theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.error.main,
            textDecoration: 'line-through',
            cursor: 'pointer',
        },
    },
    cardContent: {
        marginTop: '10px',
        "&:first-child": {
            paddingTop: '10px',
        },
        "&:last-child": {
            paddingBottom: '10px',
        }
    },
    avPhoto: {
        position: 'absolute',
        top: '-20px',
        left: '80px',
        height: '40px',
        width: '40px',
        backgroundColor: theme.palette.grey[800],
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        color: theme.palette.grey[700],
    },
    expandOpen: {
        transform: 'rotate(180deg)',
        color: theme.palette.grey[700],
    },
    fullWidth: {
        width: '100%',
    },
    signBox: {
        maxWidth: '340px',
        borderRadius: '10px',
        border: '1px solid ' + theme.palette.grey[400],
        padding: '10px 30px 10px 30px',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    commentBox: {
        maxWidth: '422px',
    },
    mlittle: {
        marginTop: '4px',
        marginBottom: '4px',
    },
    /*plusAv: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.grey[500],
      height: '40px',
      width: '40px',
      marginTop: '10px',
      marginBottom: '10px',
      '&:hover' : {
        backgroundColor: theme.palette.grey[700],
        cursor: 'pointer'
      }
    },*/
    plusAvLarge: {
        padding: '10px',
    },
    approvedAv: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '60px',
      width: '60px',
      marginTop: '10px',
      marginBottom: '10px',
    },
    userAv: {
      height: '60px',
      width: '60px',
      marginTop: '10px',
      marginBottom: '10px',
    },
    initialsAv: {
      height: '60px',
      width: '60px',
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.primary.contrastText,
      marginTop: '10px',
      marginBottom: '10px',
    },
    arrowIcon: {
      color: theme.palette.grey[500],
      [theme.breakpoints.only('xs')]: { padding: '5px 25px 5px 25px', },
      [theme.breakpoints.up('sm')]: { padding: '5px 25px 5px 25px' }
    },
    stepper: {
        backgroundColor: theme.palette.primary.contrastText
    },
    stepperHide: {
        display: 'none',
    },
    pdfIcon: {
        margin: '0px 40px 0px 40px',
        height: '120px',
        width: 'auto',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    pdfIconS: {
        height: '60px',
        width: 'auto',
    },
    menuOption: {
        fontSize: '14px',
        fontWeight: '500',
    },
    menuOption2: {
        fontWeight: '500',
        paddingTop: '7px',
        paddingBottom: '7px',
        width: '260px'
    },
    menuOption3: {
        width: '260px',
        paddingTop: '2px',
        paddingBottom: '2px',
        paddingLeft: '20px',
    },
    sigQualityBox: {
        width: '260px',
        cursor: 'pointer',
    },
    sigQualityCaret: {
        color: theme.palette.grey[700],
        marginLeft: '15px'
    },
    delButton: {
        color: theme.palette.error.main
    },
    retrieveWarn: {
        color: theme.palette.warning.main,
        fontSize: '36px',
        paddingBottom: '20px',
    },
    retrieveError: {
        color: theme.palette.error.main,
        fontSize: '36px',
        paddingBottom: '20px',
    },
    uploadPDF: {
        fontSize: '54px',
        color: theme.palette.grey[700],
        padding: '10px 18px 10px 18px',
    },
    addCollabBox: {
        border: '1px solid #c4c4c4', 
        width: 'calc(100% - 2px);', 
        height: '42px', 
        borderRadius: '10px', 
        padding: '6px 0px 6px 0px',
    },
    textAreaAuto: {
        [theme.breakpoints.only('xs')]: { width: 'calc(100% - 40px)' },
        [theme.breakpoints.up('sm')]: { width: '360px' },
        //width: '374px',
        //width: '100%',
        fontSize: '16px',
        fontWeight: '300',
        padding: '20px',
        border: '1px solid' + theme.palette.grey[400],
        borderRadius: '10px',
        minHeight: '15px',
    },
    selectResizing: {
        [theme.breakpoints.only('xs')]: { maxWidth: '240px' },
        [theme.breakpoints.up('sm')]: { maxWidth: '400px' },
    },
    primaryChip: {
        border: '0px solid' + theme.palette.primary.main, 
        margin: '2px 2px 2px 0px',
        fontWeight: '500',
        fontSize: '16px',
        color: theme.palette.grey[900],
        backgroundColor: '#eeeeee',
        "& .MuiChip-deleteIcon": { color: theme.palette.primary.main, '&:hover': { color: theme.palette.grey[800] } },
    },
    secondaryChip: {
        border: '0px solid' + theme.palette.secondary.main, 
        margin: '2px 2px 2px 0px',
        fontWeight: '500',
        fontSize: '16px',
        color: theme.palette.grey[900],
        backgroundColor: '#eeeeee',
        "& .MuiChip-deleteIcon": { color: theme.palette.secondary.main, '&:hover': { color: theme.palette.grey[800] } },
    },
    chipAv: {
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.primary.contrastText,
    },
    chipFont: {
        padding: '5px', 
        fontWeight: '400'
    },
    optionEmail: {
        color: theme.palette.grey[600],
        fontWeight: '300',
        fontSize: '15px',
        marginLeft: '10px',
    },
    semiBold: {
        fontWeight: '400',
    },
    flagAv: {
        width: '18px',
        height: '18px',
        display: 'inline',
    },
    menuGrouping: {
        margin: '8px 0px 5px 16px',
        //fontSize: '15px',
        fontWeight: '500',
        //color: theme.palette.grey[600],
    },
    lighter: {
        color: theme.palette.grey[500],
        marginLeft: '10px',
        fontSize: '13px',
        fontWeight: '400',
    },
    reminderInput: {
        width: '35px',
        fontWeight: '700',
        fontSize: '22px',
    },
    cancelReminders: {
        marginLeft: '20px',
        fontSize: '14px',
        padding: '4px 7px 4px 7px',
    },
    switchGroup: {
        display: 'inline',
    },
    buttonActive: {
        [theme.breakpoints.only('xs')]: { 
            padding: '2px 5px 2px 5px',
            fontSize: '14px'
        },
        padding: '2px 18px 2px 18px',
        fontSize: '14px',
        fontWeight: '700',
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        '&:hover':{
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
        }
    },
    buttonActiveSec: {
        [theme.breakpoints.only('xs')]: { 
            padding: '2px 5px 2px 5px',
            fontSize: '14px'
        },
        padding: '2px 18px 2px 18px',
        fontSize: '14px',
        fontWeight: '700',
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        '&:hover':{
            color: theme.palette.secondary.contrastText,
            backgroundColor: theme.palette.secondary.main,
        }
    },
    button: {
        [theme.breakpoints.only('xs')]: { 
            padding: '2px 5px 2px 5px',
            fontSize: '14px'
        },
        padding: '2px 18px 2px 18px',
        fontSize: '14px',
        fontWeight: '700',
        color: theme.palette.grey[600],
        backgroundColor: '#eeeeee',
        '&:hover':{
            color: theme.palette.grey[800],
            backgroundColor: '#dddddd',
        }
    },
    integrationImg: {
        cursor: 'pointer'
    }
}));
const classes = useStyles();

const history = useHistory()

const [activeAgr, setActiveAgr] = React.useState(null) // required since it pulls the latest contributors / signatories
const [sign, setSign] = React.useState(false);
const [ownUsers, setOwnUsers] = React.useState([])
const [cptyUsers, setCptyUsers] = React.useState([])
const [primaryCollaborators, setPrimaryCollaborators] = React.useState([])
const [secondaryCollaborators, setSecondaryCollaborators] = React.useState([])
const [addingCollaborator, setAddingCollaborator] = React.useState(null)
const [deletingCollaborator, setDeletingCollaborator] = React.useState(null)
const [primarySignatories, setPrimarySignatories] = React.useState([])
const [secondarySignatories, setSecondarySignatories] = React.useState([])
const [addingSignatory, setAddingSignatory] = React.useState(null);
const [creatingSignatory, setCreatingSignatory] = React.useState(false);
const [collabUpdate, setCollabUpdate] = React.useState(false)
const [signatoryUpdate, setSignatoryUpdate] = React.useState(false)
const [requestComment, setRequestComment] = React.useState('');
const [expandCollab, setExpandCollab] = React.useState(true);
const [expandSignatories, setExpandSignatories] = React.useState(false);
const [expandMessage, setExpandMessage] = React.useState(false);
//const [userValue, setUserValue] = React.useState(null)
const [subavs, setSubavs] = React.useState([])
const [subagsContent, setSubAgsContent] = React.useState([])
const [subClauses, setSubClauses] = React.useState([])
const [activeAVClauses, setActiveAVClauses] = React.useState(null)
const [activeClauses, setActiveClauses] = React.useState(null)
const [submitError, setSubmitError] = React.useState(null);
const [orgurl, setOrgurl] = React.useState('notfound');
const [loadingPDF, setLoadingPDF] = React.useState(true)
const [loading, setLoading] = React.useState(true)
const [anchorEl, setAnchorEl] = React.useState(null);
const [anchorElSig, setAnchorElSig] = React.useState(null);
const [editMode, setEditMode] = React.useState('full');
const [docType, setDocType] = React.useState('pdf')
const [signingOrder, setSigningOrder] = React.useState(false);
const [avsToDelete, setAvsToDelete] = React.useState([]);
const [offlineSigned, setOfflineSigned] = React.useState({selected: false, file: null, radioDigitization: 'none'})
const [importOpen, setImportOpen] = React.useState(false)
const [iDoc, setIDoc] = React.useState(null)
const [needUserFetching, setNeedUserFetching] = React.useState(false);
const [usersEditing, setUsersEditing] = React.useState([])
const [blockSigning, setBlockSigning] = React.useState(false)
const [reminders, setReminders] = React.useState(null)
const [pdfHeader, setPdfHeader] = React.useState(null)
const [signMethod, setSignMethod] = React.useState('hellosign')
const [integrationUsers, setIntegrationUsers] = React.useState(null)
const [selectIntegrationUsers, setSelectIntegrationUsers] = React.useState(null)
const [importLog, setImportLog] = React.useState([])
const [viewImportLog, setViewImportLog] = React.useState(false)

/*
const signMethod = props.rel !== undefined && props.rel !== null &&
    [props.rel.secondaryOrgID, props.rel.primaryOrgID].includes('5feb52c5bd05300026acb349') ? 'swisscom' : 'skribble'*/

useEffect(() => {

    if(props.open && props.trigger !== null && props.user !== undefined && props.rel !== undefined && props.agr !== undefined &&
    props.primaryCpty !== undefined && props.primaryCpty !== null && props.primaryCpty.legalName !== undefined && 
    props.secondaryCpty !== undefined && props.secondaryCpty !== null && props.secondaryCpty.legalName !== undefined) {
        
        let otherOrgID = (props.userIsPrimary ? props.rel.secondaryOrgID : props.rel.primaryOrgID);

        axios.get('/agr/agr/' + props.agr._id) // Need to get the relevant Agreement
        .then(function(resag) {
            let agr = resag.data.data; // ASSIGN agr

            axios.get('/user/protect/users/' + otherOrgID) // Get users for CPTY org
            .then(function (resusr) {

                // SECTION TO DEFINE ALL CPTY AND SELF USERS
                let cpus = resusr.data.data.filter((u) => u.active && !u.email.endsWith('support@getcanveo.com') &&
                            ((props.cptyModule === 'sales' && ['Admin', 'Business', 'Counterparty', 'Legal', 'Sales'].includes(u.role)) ||
                            (props.cptyModule === 'procurement' && ['Admin', 'Business', 'Counterparty', 'Legal', 'Procurement'].includes(u.role)) ||
                            (props.cptyModule === 'partnerships' && ['Admin', 'Business', 'Counterparty', 'Legal', 'Partnerships'].includes(u.role)) ||
                            (props.cptyModule === 'corporate' && ['Admin', 'Counterparty', 'Legal', 'Corporate'].includes(u.role)) ||
                            (props.cptyModule === 'people' && ['Admin', 'Counterparty', 'Legal', 'HR'].includes(u.role)) ||
                            (u.role.startsWith("ClientFor_") && 
                                ((u.role.substring(10) === props.rel.primaryCptyID) || (u.role.substring(10) === props.rel.secondaryCptyID))
                            )));

                let ownus = props.users.filter((u) => u.active && !u.email.endsWith('support@getcanveo.com') &&
                            ((props.module === 'sales' && ['Admin', 'Business', 'Counterparty', 'Legal', 'Sales'].includes(u.role)) ||
                            (props.module === 'procurement' && ['Admin', 'Business', 'Counterparty', 'Legal', 'Procurement'].includes(u.role)) ||
                            (props.module === 'partnerships' && ['Admin', 'Business', 'Counterparty', 'Legal', 'Partnerships'].includes(u.role)) ||
                            (props.module === 'corporate' && ['Admin', 'Counterparty', 'Legal', 'Corporate'].includes(u.role)) ||
                            (props.module === 'people' && ['Admin', 'Counterparty', 'Legal', 'HR'].includes(u.role)) ||
                            (props.module === 'counterparty' && props.org !== undefined && props.org.clientType !== 'legalOrg') ||
                            (props.module === 'counterparty' && props.org !== undefined && props.org.clientType === 'legalOrg' && ['Admin'].includes(u.role)) ||
                            (props.org !== undefined && props.org.clientType === 'legalOrg' && u.role.startsWith("ClientFor_") && 
                                ((u.role.substring(10) === props.rel.primaryCptyID) || (u.role.substring(10) === props.rel.secondaryCptyID)))));

                let primcbs = [], seccbs = []
                // SECTION TO DEFINE THE INITIAL CONTRIBUTORS
                agr.contributors.forEach((c) => {
                    // a user may have been deleted, in that case the contributors need to be updated upon closure
                    if(c === undefined || c === null || ownus.concat(cpus).filter((u) => u._id === c.uid)[0] === undefined ||
                    ownus.concat(cpus).filter((u) => u._id === c.uid)[0] === null || !ownus.concat(cpus).filter((u) => u._id === c.uid)[0].active) {
                        setCollabUpdate(true);
                    } else if(c.entity === "primary" && (
                    (props.userIsPrimary && ownus.filter((u) => u._id === c.uid)[0] !== undefined) ||
                    (!props.userIsPrimary && cpus.filter((u) => u._id === c.uid)[0] !== undefined))) {
                        primcbs.push(ownus.concat(cpus).filter((u) => u._id === c.uid)[0])
                    } else if (c.entity === "secondary" && (
                    (!props.userIsPrimary && ownus.filter((u) => u._id === c.uid)[0] !== undefined) ||
                    (props.userIsPrimary && cpus.filter((u) => u._id === c.uid)[0] !== undefined))) {
                        seccbs.push(ownus.concat(cpus).filter((u) => u._id === c.uid)[0])
                    }
                })

                let primsigs = [], secsigs = [], sorder = false;
                // SECTION TO DEFINE THE INITIAL SIGNATORIES
                if(agr.signatories !== undefined) {
                    agr.signatories.forEach((s) => {
                        let u = ownus.concat(cpus).filter((u) => u._id === s.uid)[0];
                        
                        if(u === undefined || u === null || !u.active) {
                            setSignatoryUpdate(true); // a user may have been deleted, in that case the signers need to be updated upon closure
                        } else { // If a user is not deleted yet, then define the entityName in the array
                            u.entityName = 
                                s.entityName !== undefined && s.entityName !== null && s.entityName.length > 2 ? 
                                    s.entityName : // If the signer entity was already defined, use that one
                                (props.userIsPrimary && ownus.filter((u) => u._id === s.uid)[0] !== undefined) ||
                                (!props.userIsPrimary && cpus.filter((u) => u._id === s.uid)[0] !== undefined) ?
                                    props.primaryCpty.legalName : // If the signer is part of primary - use that entity name as the default
                                    props.secondaryCpty.legalName  // If the signer is part of secondary - use that entity name as the default
                        }
                        
                        if(s.entity === "primary" && (
                        (props.userIsPrimary && ownus.filter((u) => u._id === s.uid)[0] !== undefined) ||
                        (!props.userIsPrimary && cpus.filter((u) => u._id === s.uid)[0] !== undefined))) {
                            u.order = s.order;
                            primsigs.push(u)
                        } else if (s.entity === "secondary" && (
                        (!props.userIsPrimary && ownus.filter((u) => u._id === s.uid)[0] !== undefined) ||
                        (props.userIsPrimary && cpus.filter((u) => u._id === s.uid)[0] !== undefined))) {
                            u.order = s.order;
                            secsigs.push(u)
                        }
                        if(s.order !== undefined && s.order !== null && !isNaN(s.order)){ sorder = true; }
                    })
                }

                // If no signatories defined for the AGR - predefine based on the Entity config (assuming entity has default signatory setup)
                if(primsigs.length  === 0 && props.primaryCpty.signatory !== undefined && props.primaryCpty.signatory !== null &&
                    props.primaryCpty.signatory.length > 0 && props.primaryCpty.signatory[0].uid !== undefined && 
                    ownus.concat(cpus).filter((u) => u._id === props.primaryCpty.signatory[0].uid && u.active)[0] !== undefined &&
                    agr.agrStatus === 'Draft') {
                    props.primaryCpty.signatory.forEach((s) => { // Add each signer
                        let defaultPrimSigner = ownus.concat(cpus).filter((u) => u._id === s.uid && u.active)[0]
                        if(defaultPrimSigner !== undefined && defaultPrimSigner !== null) {
                            defaultPrimSigner.entityName = props.primaryCpty.legalName
                            primsigs.push(defaultPrimSigner);
                        }
                    })
                    setSignatoryUpdate(true);
                } // Same as above but for the secondary cpty
                if(secsigs.length  === 0 && props.secondaryCpty.signatory !== undefined && props.secondaryCpty.signatory !== null &&
                    props.secondaryCpty.signatory.length > 0 && props.secondaryCpty.signatory[0].uid !== undefined && 
                    ownus.concat(cpus).filter((u) => u._id === props.secondaryCpty.signatory[0].uid && u.active)[0] !== undefined && 
                    agr.agrStatus === 'Draft') {
                    props.secondaryCpty.signatory.forEach((s) => { // Add each signer
                        let defaultSecSigner = ownus.concat(cpus).filter((u) => u._id === s.uid && u.active)[0]
                        if(defaultSecSigner !== undefined && defaultSecSigner !== null) {
                            defaultSecSigner.entityName = props.secondaryCpty.legalName
                            secsigs.push(defaultSecSigner);
                        }
                    })
                    setSignatoryUpdate(true);
                }

                if(props.trigger === "sign") { 
                    setSign(true) 
                    setExpandCollab(false);
                    setExpandSignatories(true);
                } 

                if((['sign', 'cpty'].includes(props.trigger) && (
                    ['Execution', 'InForce'].includes(agr.agrStatus) ||
                    (props.userIsPrimary && agr.curAVOwner !== agr.primaryCptyID) ||
                    (!props.userIsPrimary && agr.curAVOwner !== agr.secondaryCptyID)))) {
                    // if you are not the owner anymore (e.g. someone on your side sent it already), you cannot send / initiate signing - so push to rel page
                    let path = "/core/" + props.module + "/relationship/" + props.rel._id + "/overview/5";        
                    if(props.user.role === 'Counterparty') { path = "/core/counterparty/relationship/" + props.rel._id + "/overview/5" } 
                    history.push(path)
                }
                setActiveAgr(agr);
                setOwnUsers(ownus.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0)));
                setCptyUsers(cpus.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0)));
                setPrimaryCollaborators(primcbs);
                setSecondaryCollaborators(seccbs);
                setPrimarySignatories(primsigs)
                setSecondarySignatories(secsigs)
                setSigningOrder(sorder)
                setLoading(false)

                if(props.user.role !== 'Counterparty' && ['custB', 'cust','custE', 'legalOrg'].includes(props.org.clientType) &&
                ((props.userIsPrimary && !['Subsidiary','LegalClient'].includes(props.secondaryCpty.entityType)) || (!props.userIsPrimary && !['Subsidiary','LegalClient'].includes(props.primaryCpty.entityType)))){
                    setEditMode('edit')
                }
            })
            .catch(function(err) { console.log(err); })
        })
        .catch(function(err) { console.log(err); })

        // SECTION TO DECIDE IF THERE IS A PDFHEADER AND THE SIGNMETHOD
        if(props.users.some((u) => u._id === props.agr.creationBy) && props.org !== undefined &&
        ['custB','cust','custE', 'legalOrg'].includes(props.org.clientType)) { // Decide on whether creator is in the current user ORG

            let cp = props.userIsPrimary ? props.primaryCpty : props.secondaryCpty;
            defineOrgParams(props.org, cp)

        } else { // the cpty org is the creator of the agreement

            axios.get('/org/org/' + otherOrgID)
            .then(function (res) {
                let otherOrg = res.data.data
                
                let cp = props.userIsPrimary ? props.secondaryCpty : props.primaryCpty;
                defineOrgParams(otherOrg, cp)

            }).catch((err) => console.log("err pulling other org", err))
        }
    }

    // SECTION TO PREPARE FOR AGRVERSION AND ITS POTENTIAL EXHIBITS
    if(props.open && props.agrs !== undefined && props.user !== undefined && props.rel !== undefined && 
        !['collab', 'retrieve'].includes(props.trigger)) {

        axios.get('/agr/agrv/' + props.activeAgrVersion._id) // pull the AgrVersion from the Database to ensure the latest data (e.g. avClauses)
        .then(function(res) { 
            let actAV = res.data.data
            if(actAV.isEdited) { setBlockSigning(true) }

            axios.post('/clause/clauses', { clauses: actAV.clauses })
            .then(function(rescls) {
                // Warn if any other user is locking a clause
                let uEditing = getUsersWhoLockAClause(rescls.data.data, props.users, props.user._id, [])
                setUsersEditing(ue => [...ue, ...uEditing])
                // Filter any "Deleted clauses" from the avcs
                let actavcs = actAV.clauses;
                rescls.data.data.filter((cls) => cls.clauseStatus === 'Deleted').forEach((c) => { actavcs = actavcs.filter((aa) => aa !== c._id); })
                setActiveAVClauses(actavcs)
                setActiveClauses(rescls.data.data.filter((c) => c.clauseStatus !== 'Deleted'))
                setLoadingPDF(false)
            }).catch(function(err) { console.log(err) })

        })
        .catch(function(err) { console.log("err loading the key AV") })

        // Preparation work for Exhibits (if applicable, and only run it once)
        if(props.agrs.filter((a) => a.parentAID === props.activeAgrVersion.agrID).length > 0) { // if there are Child Agreements
            // Pull the relevant AV for each Exhibit and store in an Array
            props.agrs.filter((a) => a.parentAID === props.activeAgrVersion.agrID && a.agrStatus === 'Exhibit')
            .sort((a,b) => (a.priority > b.priority ? 1 : a.priority < b.priority ? -1 : 0))
            .forEach((sa) => {
                axios.get('/agr/agrvlatestowner/' + sa._id + '/' + (props.user.orgID === props.rel.primaryOrgID ? "primary" : "secondary")) // agrid, owner
                .then(function(resav) {
                    // Pull all content in order to be able to generate the PDF
                        
                    let avObject = resav.data.data;
                    axios.post('/clause/clauses', { clauses: avObject.clauses })
                    .then(function(rescls) {
                        // Add any locks that may exist
                        let uEditing = getUsersWhoLockAClause(rescls.data.data, props.users, props.user._id, usersEditing)
                        setUsersEditing(ue => [...ue, ...uEditing])

                        // Filter any "Deleted clauses" from the avcs
                        let avoavcs = avObject.clauses;
                        rescls.data.data.filter((cls) => cls.clauseStatus === 'Deleted').forEach((c) => { avoavcs = avoavcs.filter((aa) => aa !== c._id); })

                        let subavo = avObject
                        subavo = {...subavo, clauses: avoavcs}

                        let subcls = rescls.data.data.filter((c) => c.clauseStatus !== 'Deleted')
                        let subAgrContent = getAgrContentForPDF(
                            sa.priority, 
                            sa.sourceFileID === 'attachment' && avObject.attachment !== undefined && avObject.attachment !== null ? avObject.attachment : null, 
                            sa.agrFullName, 
                            avoavcs, 
                            subcls)
                        setSubAgsContent(subagsContent => [...subagsContent, subAgrContent])
                        setSubavs(subavs => [...subavs, subavo]);
                        setSubClauses(subClauses => [...subClauses.concat(subcls)])

                    }).catch(function(err) { console.log(err) })

                }).catch(function(err) { console.log("err loading the AV") })
            })
        }
    } else if (['retrieve'].includes(props.trigger) && props.activeAgrVersion !== undefined && props.activeAgrVersion !== null &&
    props.agrs !== undefined && props.agrs !== null && props.agrs.length > 0 && avsToDelete.length === 0) {

        // retrieve AVs created after yours - those will need to be delete
        props.agrs.forEach((a) => {
            axios.get('/agr/agrvs/' + a._id)
            .then((res) => {
                res.data.data.filter((av) => av.version > props.activeAgrVersion.version).forEach((avtd) => {
                    setAvsToDelete(avstd => [...avstd, avtd])
                })
            }).catch((err) => console.log(err))
        })
    }

    // SECTION TO ASSING THE ORGURL IF THE USER IS CPTY - MAY NEED THIS FOR THE SIGNATORY EMAIL
    if(props.open) {
        let oid = props.user.role === 'Counterparty' && props.user.orgID === props.rel.primaryOrgID ? props.rel.secondaryOrgID :
                  props.user.role === 'Counterparty' && props.user.orgID === props.rel.secondaryOrgID ? props.rel.primaryOrgID :
                  props.user.role !== 'Counterparty' ? props.user.orgID : 'notfound'
        axios.get('/org/org/' + oid)
        .then(function (res) {
            setOrgurl(res.data.data.orgurl)
        })
    }
    
}, [props.open, props.trigger, props.user, props.rel, props.agr, props.userIsPrimary, props.editMode,
    props.activeAgrVersion, props.agrs, props.cptyModule, props.module, props.primaryCpty, props.secondaryCpty, props.users,
    avsToDelete.length, props.org.clientType/*, usersEditing, history*/]);

const isCustomLink = props.rel !== undefined && props.rel !== null && props.rel.customURL !== undefined && props.rel.customURL !== null && props.rel.customURL.length > 10
const baseURL = ['https://getcanveo.com', 'https://www.getcanveo.com'].includes(window.location.origin) ? 'https://app.getcanveo.com' : window.location.origin
const cptySignReady = !props.edited && props.rel !== null && 
                        props.user.orgID !== undefined && props.rel !== undefined && props.activeAgrVersion !== undefined && 
                        props.activeAgrVersion !== null &&
                        ((props.user.orgID === props.rel.primaryOrgID && props.activeAgrVersion.secondaryReady) ||
                        (props.user.orgID === props.rel.secondaryOrgID && props.activeAgrVersion.primaryReady))

const defineOrgParams = (org, cp) => { // A function triggered if Either CPTY has orgSettings (preference for the Creator of the Agr)
    if(org.orgSettings !== undefined && org.orgSettings.headerInPdf) {
        
        setPdfHeader({ // Set PDF Header for current active Org
            logoURL: cp.logoURL,
            legalName: cp.legalName,
            streetAndNo: cp.address[0].streetAndNo,
            address2: cp.address[0].address2,
            zipCity: 
                cp.address[0].zip + 
                (cp.address[0].state !== undefined && cp.address[0].state !== null && cp.address[0].state !== '' ?
                    (" " + cp.address[0].state) : '') +
                ", " + cp.address[0].city,
            country: (countries.filter((c) => c.code === cp.address[0].country)[0] !== undefined ? countries.filter((c) => c.code === cp.address[0].country)[0].label : '')
        })
    }

    if(org.orgSettings !== undefined && org.orgSettings.signMethod !== undefined && org.orgSettings.signMethod !== null) {
        setSignMethod(org.orgSettings.signMethod) // Set SignMethod for current active Org
    }
}

const handleSigningOrderChange = () => {
    let psigners = [], ssigners = [];

    if(signingOrder) { // Changing from Signing Order to no Signing order - remove the order fields
        primarySignatories.concat(secondarySignatories).forEach((signer) => {
            if(signer.orgID === props.rel.primaryOrgID) {
                psigners.push({...signer, order: null})
            } else if(signer.orgID === props.rel.secondaryOrgID) {
                ssigners.push({...signer, order: null})
            } 
        })
    } else { // Changing from no Signing Order to Signing order - define the default order fields
        secondarySignatories.concat(primarySignatories).forEach((signer, i) => {
            if(signer.orgID === props.rel.primaryOrgID) {
                psigners.push({...signer, order: i})
            } else if(signer.orgID === props.rel.secondaryOrgID) {
                ssigners.push({...signer, order: i})
            } 
        })
    }
    setPrimarySignatories(psigners);
    setSecondarySignatories(ssigners);
    setSigningOrder(!signingOrder)
    setSignatoryUpdate(true);
}

const handleEditModeOpen = (event) => {
    setAnchorEl(event.currentTarget);
}

const handleEditModeClose = () => {
    setAnchorEl(null);
}

const handleSigQualityOpen = (event) => {
    setAnchorElSig(event.currentTarget);
}

const handleSigQualityClose = () => {
    setAnchorElSig(null);
}

const handleEditModeSelect = (val) => {
    setEditMode(val)
    if(val === 'none') { setSign(true) }
    handleEditModeClose()
}

const handleSigQualitySelect = (val) => {

    axios.put('/agr/agrsigquality/' + props.agr._id, {
        sigQuality: val,
        lastUpdateBy: props.user._id,
        lastUpdateDate: new Date().toISOString(),
    })
    .then(function (res) {

        setActiveAgr(res.data.data)
        handleSigQualityClose()

    }).catch(function (err) { console.log(err) })
}

const closeDialog = () => {
    setSign(false)
    setAddingCollaborator(null)
    setAddingSignatory(null)
    setDeletingCollaborator(null)
    setSubAgsContent([])
    handleDBUserUpdate() // If collaborators have changed - need to update in DB
    handleExpandButton('collab')
    setLoading(true)
    setAvsToDelete([])
    setUsersEditing([])
    setSelectIntegrationUsers(null)
    if(needUserFetching) { props.fetchUsersByOrgID(props.user.orgID) } // refetch users, to ensure newly added are pulled
    props.parentCallToClose()
}

const handleExpandButton = (button) => {
    if(button === 'collab') {
        setExpandCollab(true);
        setExpandSignatories(false);
        setExpandMessage(false);
    } else if(button === 'signatories') {
        setExpandCollab(false);
        setExpandSignatories(true);
        setExpandMessage(false);
    } else if(button === 'message') {
        setExpandCollab(false);
        setExpandSignatories(false);
        setExpandMessage(true);
    }
}

function getDBUserArray(type) {

    let newarr = []
    if(type === 'collab') {
        primaryCollaborators.forEach((pc) => { newarr.push({entity: "primary", uid: pc._id, email: pc.email}) })
        secondaryCollaborators.forEach((sc) => { newarr.push({entity: "secondary", uid: sc._id, email: sc.email}) })
    } else if (type === 'signat') {
        primarySignatories.forEach((ps) => { newarr.push({entity: "primary", entityName: ps.entityName, uid: ps._id, email: ps.email, phone: ps.phone, order: ps.order}) })
        secondarySignatories.forEach((ss) => { newarr.push({entity: "secondary", entityName: ss.entityName, uid: ss._id, email: ss.email, phone: ss.phone, order: ss.order}) })
    }
    return newarr;
} 

const handleDBUserUpdate = () => {
    if(collabUpdate || signatoryUpdate) {
        props.updateAgrUsers(props.agr._id, getDBUserArray('collab'), getDBUserArray('signat'))
    }
}

const handleCollaborators = (cpty, value, add) => {
    setCollabUpdate(true);
    if(cpty === "primary") {
        if(add) { setPrimaryCollaborators(primCollabs => [...primCollabs, value]); }
        else { setPrimaryCollaborators(value) }
    } else if (cpty === "secondary") {
        if(add) { setSecondaryCollaborators(secCollabs => [...secCollabs, value]); }
        else { setSecondaryCollaborators(value) }
    }
}

const handleRequestCommentChange = (event) => {
    setRequestComment(event.target.value);
}

const handleSearchChange = (value) => {
    //setUserValue((value !== undefined && value !== null && value._id !== undefined) ? value : null);
    if(value !== undefined && value !== null && value._id !== undefined) {
        handleAddSignatory(value)
    }

}

const handleAddSignatory = (uv) => {
    if(uv !== undefined && uv !== null && uv !== '') {
        // If there is a signing order , attach to the end - if no signing order assign null
        uv.order = signingOrder ? primarySignatories.concat(secondarySignatories).length : null;
        if(addingSignatory === "primary") {
            uv.entityName = props.primaryCpty.legalName
            setPrimarySignatories(ps => [...ps, uv]);
        } else if (addingSignatory === "secondary") {
            uv.entityName = props.secondaryCpty.legalName
            setSecondarySignatories(ss => [...ss, uv]);
        }
        //setUserValue(null);
    }
    setAddingSignatory(null);
    setSignatoryUpdate(true);
}

const handleRevokeCollab = (uv, action) => {
    
    // Delete from collaborators array - if any
    if(primaryCollaborators.filter((pc) => pc._id === uv._id)[0] !== undefined) {
        setPrimaryCollaborators(pcs => pcs.filter((pc) => pc._id !== uv._id))
        setCollabUpdate(true);
    } else if(secondaryCollaborators.filter((pc) => pc._id === uv._id)[0] !== undefined) {
        setSecondaryCollaborators(scs => scs.filter((sc) => sc._id !== uv._id))
        setCollabUpdate(true);
    } 

    // Delete from signatories array - if any
    if(primarySignatories.filter((s) => s._id === uv._id)[0] !== undefined || secondarySignatories.filter((s) => s._id === uv._id)[0] !== undefined) {
        handleRemoveSignatory(primarySignatories.filter((s) => s._id === uv._id)[0] !== undefined ? "primary" : "secondary", uv._id)
    }

    // Delete the user
    if(action === 'delete') {
        let deletedDate = new Date().toISOString();
        axios.put('/user/protect/userdel/' + uv._id, {
          uid: uv._id,
          deletedBy: props.user._id,
          deletedDate: deletedDate,
        })
        if(props.org !== undefined && props.org.clientType === 'legalOrg' && ownUsers.some((ou) => ou._id === uv._id)) {
            setOwnUsers(ou => ou.filter((u) => u._id !== uv._id))
        } else {
            setCptyUsers(cpu => cpu.filter((u) => u._id !== uv._id))
        }
    } else if (action === 'revoke') { // keep user but remove aid from agrIDs
        props.updateAidForUserAgrIDs(
            'delete',
            uv._id, 
            props.agr._id); // delete AID from user access
        
        if(props.org !== undefined && props.org.clientType === 'legalOrg' && ownUsers.some((ou) => ou._id === uv._id)) {
            let ous = ownUsers
            let index = ous.findIndex((o) => o._id === uv._id)
            let newAids = (ous[index].agrIDs !== undefined && ous[index].agrIDs !== null ? ous[index].agrIDs.filter((ai) => ai !== props.agr._id) : [])
            ous[index] = {...ous[index], agrIDs: newAids}
            setOwnUsers([...ous])
        } else {
            let cpus = cptyUsers
            let index = cpus.findIndex((c) => c._id === uv._id)
            let newAids = (cpus[index].agrIDs !== undefined && cpus[index].agrIDs !== null ? cpus[index].agrIDs.filter((ai) => ai !== props.agr._id) : [])
            cpus[index] = {...cpus[index], agrIDs: newAids}
            setCptyUsers([...cpus])
        }
    }
    setDeletingCollaborator(null)
}

const handleRemoveSignatory = (cpty, uid) => {

    if(primarySignatories.concat(secondarySignatories).some((s) => s.order !== null)) { // There is a signing order, need to redefine it following the removal
        let signerToRemove = primarySignatories.concat(secondarySignatories).filter((s) => s._id === uid)[0]
        let newPSigners = [], newSSigners = []

        primarySignatories.forEach((s) => { // Recreate PrimarySigners Array
            if(s.order > signerToRemove.order) { s.order = (s.order - 1) } // Adjust the order if appropriate
            if(s._id !== uid) { newPSigners.push(s) }
        })

        secondarySignatories.forEach((s) => { // Recreate PrimarySigners Array
            if(s.order > signerToRemove.order) { s.order = (s.order - 1) } // Adjust the order if appropriate
            if(s._id !== uid) { newSSigners.push(s) }
        })
        setPrimarySignatories(newPSigners);
        setSecondarySignatories(newSSigners);

    } else if(cpty === "primary") {
        setPrimarySignatories(ps => ps.filter((s) => s._id !== uid))
    } else if (cpty === "secondary") {
        setSecondarySignatories(ss => ss.filter((s) => s._id !== uid))
    }
    setSignatoryUpdate(true);
}

const handleAddCollabFlow = () => {
    let endCollabs = primaryCollaborators.concat(secondaryCollaborators);
    let startCollabs = activeAgr.contributors
    let lname = props.userIsPrimary ? props.secondaryCpty.legalName : props.primaryCpty.legalName

    endCollabs.forEach((ec) => {
        if(startCollabs.filter((sc) => sc.email === ec.email)[0] === undefined) {
            props.updateAidForUserAgrIDs('insert', ec._id, props.agr._id); // push AID to ec.agrIDs
            let user = ownUsers.concat(cptyUsers).filter((u) => u.email === ec.email)[0]
            if(user !== undefined) {
                sendEmailToUser (
                    'newCollab', 
                    isCustomLink, 
                    user, 
                    lname, 
                    props.rel.whiteLabel !== undefined && props.rel.whiteLabel !== null && 
                    ['email','full'].includes(props.rel.whiteLabel.whiteLabelLevel) && props.rel.whiteLabel.logoURL !== undefined &&
                    user.role === 'Counterparty' ? 
                        props.rel.whiteLabel : null);
            }
        }
    })
    props.fetchUsersByOrgID(props.user.orgID) // need to fetch all users into the reducer
    closeDialog()
}

const callbackUserForm = (child) => {

    createCollab(child)
    .then((resUser) => {

        if(addingCollaborator !== null) {
            handleCollaborators(addingCollaborator, resUser, true);
            setAddingCollaborator(null);
        } else if (addingSignatory !== null) {
            setCreatingSignatory(false)
            handleAddSignatory(resUser);
        }
        setNeedUserFetching(true);

    }).catch((err) => setSubmitError(err))
    
}

async function createCollab(userInputData) {

    return new Promise(async (resolve, reject) => {

        if (!ownUsers.some(ou => ou.email === userInputData.email) && !cptyUsers.some(cu => cu.email === userInputData.email)) { // Does not yet exist in the userBase

            axios.get('/user/protect/user/mail/' + userInputData.email)
            .then(function(res) {
                // To verify if the user already exists in the database
                if(res.data.data !== undefined && res.data.data !== null && res.data.data.role === 'Counterparty') {
                    return reject("This user is already setup for a different organisation, please contact canveo support for help.")
                } else if (res.data.data !== undefined && res.data.data !== null) {
                    return reject("This user is setup for a Canveo customer, please contact canveo support for help.")
                } else { // User not found - thus add
                    axios.post('/user/protect/user', {
                        orgID: 
                            (addingCollaborator === "primary" || addingSignatory === "primary") ? props.rel.primaryOrgID : 
                            (addingCollaborator === "secondary" || addingSignatory === "secondary") ? props.rel.secondaryOrgID : 'unknown',
                        firstName: userInputData.firstName,
                        lastName: userInputData.lastName,
                        displayName: userInputData.firstName + ' ' + userInputData.lastName,
                        email: userInputData.email.toLowerCase().trim(),
                        password: generateRandomString(20),
                        title: userInputData.title,
                        role: props.org !== undefined && props.org.clientType === 'legalOrg' &&
                            (((addingCollaborator === "primary" || addingSignatory === "primary") && props.org._id === props.rel.primaryOrgID) || 
                             ((addingCollaborator === "secondary" || addingSignatory === "secondary") && props.org._id === props.rel.secondaryOrgID)) ? 
                                ('ClientFor_' + ((addingCollaborator === "primary" || addingSignatory === "primary") ? props.rel.primaryCptyID : props.rel.secondaryCptyID)) : 'Counterparty',
                        defaultModule: 'counterparty',
                        photoURL: '',
                        creationBy: props.user._id,
                        creationDate: new Date().toISOString(),
                        active: true,
                        agrIDs: [props.agr._id],
                        sourceData: userInputData.sourceData !== undefined && userInputData.sourceData !== null &&
                            userInputData.sourceData.source !== undefined && userInputData.sourceData.sourceID !== undefined ?
                                userInputData.sourceData : null
                    })
                    .then(function (resuser) { // got a msg from the server
          
                          props.createNotification(
                              'Welcome new User', // notificationType
                              'Welcome to Canveo', // title 
                              userInputData.firstName + ', we\'re excited to have you on board', // description
                              '/core/help/welcome', // linkTo
                              false, // isRead
                              resuser.data.data._id, // uid
                          );
    
                          if(resuser.data.data.role.startsWith('ClientFor_')) { // Send a welcoming email to create a password
                            axios.post('/mail/adduser/' + resuser.data.data.email, { // Send email FAF
                                userFirstName: resuser.data.data.firstName,
                                orgShortName: 
                                    props.org !== undefined && props.org.clientType !== undefined && props.rel.primaryOrgID === props.org._id ? 
                                        props.primaryCpty.shortName :
                                    props.org !== undefined && props.org.clientType !== undefined && props.rel.secondaryOrgID === props.org._id ? 
                                        props.secondaryCpty.shortName :
                                        props.org.shortName,
                                adminDisplayName: props.user.displayName,
                                randomString: resuser.data.data.password,
                            })
                          }
          
                          if(resuser.data.data.orgID === props.user.orgID) { setOwnUsers(oUsers => [...oUsers, resuser.data.data]);
                          } else { setCptyUsers(cpUsers => [...cpUsers, resuser.data.data]); }
          
                          return resolve(resuser.data.data) // resolve the promise with the newly added user

                    }).catch(function (err) { return reject("An error occurred while creating the user") })
                }
            }).catch(function(err) { return reject("An error occurred while verifying the user") }) 
    
        } else { return reject("This user (email address) is already created, you can select it instead.") }

    })

}

const handleNext = () => {
    if(expandCollab) {
        handleExpandButton('signatories');
    } else if (expandSignatories) {
        handleExpandButton('message');
    }
}

const handleBack = () => {
    if(expandCollab || expandSignatories) {
        handleExpandButton('collab');
    } else if (expandMessage) {
        handleExpandButton('signatories');
    }
}

const handleSignatoryBackButton = () => {
    setAddingSignatory(null)
    if(creatingSignatory) { setCreatingSignatory(false) } // reset creatingSignatory if applicable
}

const handleSendToCpty = () => {
    setLoading(true)

    // Create a new Agreement version
    let newFinalVersion = ''
    let newMajorVersion = ''
    let newMinorVersion = ".001"
    let newMiniVersion = ".000001"
    if(sign && cptySignReady) { // New Final Agreement version (to be Signed)
        newFinalVersion = pad(parseInt(props.activeAgrVersion.version.substring(0, 2)) + 1,2)
        newMajorVersion = ".001"
    } else { // New Major Agreement version (to be sent to cpty)
        newFinalVersion = props.activeAgrVersion.version.substring(0, 3)
        newMajorVersion = pad(parseInt(props.activeAgrVersion.version.substring(4, 6)) + 1,3)
    }
    let newAgrVersion = newFinalVersion + newMajorVersion + newMinorVersion + newMiniVersion

    let newOwner = props.user.orgID === props.rel.primaryOrgID ? "secondary" : "primary";
    let primReady = props.user.orgID === props.rel.primaryOrgID ? sign : props.edited ? false : props.activeAgrVersion.primaryReady
    let secReady = props.user.orgID === props.rel.secondaryOrgID ? sign : props.edited ? false : props.activeAgrVersion.secondaryReady

    props.createAgrVersion(
        props.activeAgrVersion.agrID,
        newAgrVersion,
        newOwner, // owner of the AV - this will be set to the CPTY
        activeAVClauses !== null ? activeAVClauses : [],
        '', // attachment - not applicable to the main body
        primReady, // primaryReady
        secReady, // secondaryReady
        (sign && cptySignReady) ? false : true, // duplicateClauses - if sending for signature false as both should access the same
        false, // freshAg
        editMode, // editMode
    );

    // Create a new agrVersion for all child agreements
    if(subavs.length > 0) {
        subavs.forEach((sav) => {
            props.createAgrVersion(
                sav.agrID,
                newAgrVersion,
                newOwner, // owner of the AV
                sav.clauses,
                sav.attachment, // attachment
                primReady, // primaryReady
                secReady, // secondaryReady
                (sign && cptySignReady) || 
                (sav.attachment !== undefined && sav.attachment !== null && sav.attachment !== '') ? 
                    false : true, // duplicateClauses - if sending for signature false as both should access the same
                false, // freshAg
                editMode, // editMode
            );
        })
    }

    // Set "CURRENT" AV for sign if applicable - above statement only creates and updates the "NEW" AV
    if(sign) {
        props.updateAgrvMarkAsEdited(
            props.activeAgrVersion._id, 
            true,
            props.user.orgID === props.rel.primaryOrgID ? sign : props.edited ? false : props.activeAgrVersion.primaryReady, 
            props.user.orgID === props.rel.secondaryOrgID ? sign : props.edited ? false : props.activeAgrVersion.secondaryReady)
    }

    // Update the agreement status to Negotiation - this will also set the "lastUpdateDate" and the "curAVOwner"
    let newAgrStatus = 
        (sign && cptySignReady) ? 'Execution' : // both are marking as ready to sign - status change to Execution
        props.agr.agrStatus === 'Draft' ? 'Review' : // sending to Cpty from "Draft"
        'Negotiation'; // Any other scenario
    
    props.updateAgr(
        props.rel._id, 
        props.agr._id, 
        props.agr.primaryCptyID,
        props.agr.secondaryCptyID,
        newAgrStatus, 
        props.user.orgID === props.rel.primaryOrgID ? props.agr.secondaryCptyID : props.agr.primaryCptyID, // curAVOwner - will be the CPTY
        props.agr.sourceFileID, 
        getDBUserArray('collab'),
        getDBUserArray('signat'),
        props.user._id)
    if(props.agr.agrStatus !== newAgrStatus) { props.handleActivityLog('agr', 'agrNewStatus', '', '', newAgrStatus, '', {}) }

    // Enable visibility of the rel if not done before yet
    if(props.rel.invisbleFor === undefined || props.rel.invisbleFor !== 'none'){ axios.put('/rel/relvisibility/' + props.rel._id, { invisbleFor: 'none' }) }

    // Log activity
    props.handleActivityLog(
        'agr',
        (sign && cptySignReady) ? 'agrExecutionFlowStarted' : sign ? 'agrMarkedReadyForSignature' : 'agrSentToCounterparty',
        '', '', props.user.displayName,  '', {})

    // Add comment if not empty
    if(requestComment !== '' && requestComment.length > 1) {
        props.createComment(
          true, // FAF
          props.user._id, // creatorID
          props.user.displayName, // creatorDisplayName
          props.user.orgID, // orgID
          props.user.orgID === props.rel.primaryOrgID ? props.agr.primaryCptyID : props.agr.secondaryCptyID, // entityID
          requestComment, 
          'agr', // commentType
          props.agr._id, // agrID
          '', // singleClauseID
          'penturn_' + newAgrVersion, // ticketID
          props.rel._id, // relID
        )
    }

    // Create notifications for users specified in recipients
    let recipients = primaryCollaborators.concat(secondaryCollaborators).filter((u) => u._id !== props.user._id)
    let cpName = (props.userIsPrimary ? props.secondaryCpty.legalName : props.primaryCpty.legalName)

    recipients.forEach((r) => {
        props.createNotification(
          (sign && cptySignReady ? 'Execution flow initiated' : sign ? 'CPTY Ready to Sign' : 'CPTY Agreement returned'), // notificationType
          (sign && cptySignReady ? 'Execution flow initiated' : sign ? 'Ready to Sign' : 'Agreement ' + (r.orgID === props.user.orgID ? 'sent ' : '') + 'for review'), // title
          (sign && cptySignReady && r.orgID === props.user.orgID ?
            props.user.displayName + ' has also marked the ' + props.agr.agrShortName + ' with ' + cpName + ' as ready to sign' :
           sign && cptySignReady && r.orgID !== props.user.orgID ? 
            props.org.shortName + ' is also ready to sign the ' + props.agr.agrShortName :
           sign && !cptySignReady && r.orgID === props.user.orgID ?  
            props.user.displayName + ' has sent the ' + props.agr.agrShortName + ' with ' + cpName + ' and marked it as ready to sign' :
           sign && !cptySignReady && r.orgID !== props.user.orgID  ? 
            props.org.shortName + ' has sent the ' + props.agr.agrShortName + ' and marked it as ready to sign' :
           r.orgID === props.user.orgID ?
            props.user.displayName + ' has sent the ' + props.agr.agrShortName + ' with ' + cpName + ' for their review' :
            props.org.shortName + ' is sending the ' + props.agr.agrShortName + ' for your review'), // description
           r.orgID === props.user.orgID ? 
            '/core/' + props.module + '/agreement/' + props.rel._id + '/' + props.agr._id :
            '/core/' + props.cptyModule + '/agreement/' + props.rel._id + '/' + props.agr._id, // linkTo 
          false, // isRead
          r._id, // uid
        )
    })

    // Check whether aid needs to be pushed for any Counterparty Collaborator or Signer
    let cptyUsersWithAID = []
    primaryCollaborators.concat(secondaryCollaborators).concat(primarySignatories).concat(secondarySignatories)
    .filter((user) => user.role === 'Counterparty')
    .forEach((user) => {
        if(user.agrIDs !== undefined && user.agrIDs !== null && !user.agrIDs.some((aid) => aid === props.agr._id) && !cptyUsersWithAID.some((cpuserid) => cpuserid === user._id)){ 
            cptyUsersWithAID.push(user._id)
            props.updateAidForUserAgrIDs('insert', user._id, props.agr._id) // If not already existing push AID to collab.agrIDs
        }
    })

    // Configure Reminder workflows
    let reminderUpdateDate = new Date().toISOString()
    // Clear any old reminders for aid
    axios.put('/reminder/reminders', { aid: props.agr._id, endDate: reminderUpdateDate, endReason: 'penTurn' })
    .then((resremu) => {
        if(reminders !== null && !isNaN(reminders)) { // Configure new reminders as appropriate
            let rems = []
            if(['Review', 'Negotiation'].includes(newAgrStatus)) {
                let recips = props.userIsPrimary ? secondaryCollaborators : primaryCollaborators;
                recips.forEach((r) => {
                    rems.push({
                        reminderType: 'penTurn',
                        creationBy: props.user._id,
                        creationDate: reminderUpdateDate, 
                        aid: props.agr._id,
                        recipient: r._id,
                        interval: { amt: parseInt(reminders), duration: 'days' },
                        endReason: 'none',
                    })
                })
            }
            if(rems.length > 0) { axios.post('/reminder/reminders', { rems: rems }) }
        }
    })

    if (sign && cptySignReady) {

        let agrContent = []
        agrContent.push(getAgrContentForPDF(props.agr.priority, null, props.agr.agrFullName, activeAVClauses, activeClauses)) // create ags array from main body
        agrContent = agrContent.concat(subagsContent.sort((a,b) => (a.priority > b.priority ? 1 : a.priority < b.priority ? -1 : 0))) // concat any subags into the array

        let filename = generateRandomString(20) + '.pdf';

        let psigTAG = [], ssigTAG = [], psigREQ = [], ssigREQ = []
        primarySignatories.forEach((psign) => { 
            // Push to array used for the Signature "text" tags
            psigTAG.push({
                entityName: psign.entityName !== undefined && psign.entityName !== null &&
                    psign.entityName.length > 2 ? psign.entityName : props.primaryCpty.legalName,
                name: psign.displayName,
                title: psign.title,
                email_address: psign.email}) 
            // Push to array used for the Signature request - ORDER NEEDS TO ALIGN WITH TEXT TAGS!
            psigREQ.push({
                //name: psign.displayName + ' (' + psign.title + ')' + (props.primaryCpty.entityCategory !== 'Person' ? (', ' + props.primaryCpty.legalName): ''),
                first_name: psign.firstName,
                last_name: psign.lastName,
                name: psign.firstName + ' ' + psign.lastName,
                email_address: psign.email,
                phone: psign.phone,
                order: psign.order })
        })
        secondarySignatories.forEach((ssign) => {
            // Push to array used for the Signature "text" tags
            ssigTAG.push({
                entityName: ssign.entityName !== undefined && ssign.entityName !== null &&
                    ssign.entityName.length > 2 ? ssign.entityName : props.secondaryCpty.legalName,
                name: ssign.displayName,
                title: ssign.title,
                email_address: ssign.email}) 
            // Push to array used for the Signature request - ORDER NEEDS TO ALIGN WITH TEXT TAGS!
            ssigREQ.push({
                //name: ssign.displayName + ' (' + ssign.title + ')' + (props.secondaryCpty.entityCategory !== 'Person' ? (', ' + props.secondaryCpty.legalName): ''), 
                first_name: ssign.firstName,
                last_name: ssign.lastName,
                name: ssign.firstName + ' ' + ssign.lastName,
                email_address: ssign.email,
                phone: ssign.phone,
                order: ssign.order })
        })

        // Creation of the PDF
        axios.post('/export/pdf', {
            filename: filename,
            agrContent: agrContent,
            primarySigners: psigTAG,
            secondarySigners: ssigTAG,
            header: pdfHeader,
            b64: ['skribble', 'swisscom'].includes(signMethod),
            signPlaceholder: signMethod === 'swisscom',
            format: 
                (props.primaryCpty !== undefined && props.primaryCpty !== undefined && props.primaryCpty.entityType !== undefined &&
                 ['Subsidiary','LegalClient'].includes(props.primaryCpty.entityType) && props.primaryCpty.address[0] !== undefined && ['CA','US'].includes(props.primaryCpty.address[0].country)) ||
                (props.primaryCpty !== undefined && props.primaryCpty !== undefined && props.primaryCpty.entityType !== undefined && props.primaryCpty.entityType !== 'Subsidiary' &&
                 props.secondaryCpty !== undefined && props.secondaryCpty !== undefined && props.secondaryCpty.entityType !== undefined &&
                 ['Subsidiary','LegalClient'].includes(props.secondaryCpty.entityType) && props.secondaryCpty.address[0] !== undefined && ['CA','US'].includes(props.secondaryCpty.address[0].country)) ?
                    'LETTER' : 'A4',
        })
        .then(function (res) { // the msg was sent

            // RAISE FOR SIGNATURE
            axios.post('/signing/protect/' + signMethod + '/request', { // signMethod
                agrShortName: props.agr.agrShortName,
                primarySignatory: {
                    legalName: props.primaryCpty.legalName,
                    signers: psigREQ,
                },
                secondarySignatory: {
                    legalName: props.secondaryCpty.legalName,
                    signers: ssigREQ,
                },
                filename: filename,
                b64: res.data.data.b64,
                signBlocks: res.data.data.signBlocks,
                quality: activeAgr !== undefined && activeAgr !== null && activeAgr.sigQuality !== undefined ? activeAgr.sigQuality : 'SES',
            })
            .then(function (ressign) { // the msg was sent

                // Post the agrExec to our Database
                props.createAgrExec(
                    props.rel._id, // relID, 
                    props.agr._id, // agrID, 
                    props.agr.agrTypeID, // agrTypeID, 
                    newAgrVersion, // agrVersion, 
                    props.primaryCpty !== undefined && props.primaryCpty !== null && props.primaryCpty.address !== undefined && props.primaryCpty.address.filter((a) => a.addressType === 'billto')[0] !== undefined ?
                        {
                            shortName: props.primaryCpty.shortName,
                            legalName: props.primaryCpty.legalName,
                            streetAndNo: props.primaryCpty.address.filter((a) => a.addressType === 'billto')[0].streetAndNo,
                            zip: props.primaryCpty.address.filter((a) => a.addressType === 'billto')[0].zip,
                            state: props.primaryCpty.address.filter((a) => a.addressType === 'billto')[0].state,
                            city: props.primaryCpty.address.filter((a) => a.addressType === 'billto')[0].city,
                            country: props.primaryCpty.address.filter((a) => a.addressType === 'billto')[0].country,
                        } // primaryCpty, 
                        :
                        {},
                    props.secondaryCpty !== undefined && props.secondaryCpty !== null && props.secondaryCpty.address !== undefined && props.secondaryCpty.address.filter((a) => a.addressType === 'billto')[0] !== undefined ?
                        {
                            shortName: props.secondaryCpty.shortName,
                            legalName: props.secondaryCpty.legalName,
                            streetAndNo: props.secondaryCpty.address.filter((a) => a.addressType === 'billto')[0].streetAndNo,
                            zip: props.secondaryCpty.address.filter((a) => a.addressType === 'billto')[0].zip,
                            state: props.secondaryCpty.address.filter((a) => a.addressType === 'billto')[0].state,
                            city: props.secondaryCpty.address.filter((a) => a.addressType === 'billto')[0].city,
                            country: props.secondaryCpty.address.filter((a) => a.addressType === 'billto')[0].country,
                        }
                        :
                        {}, // secondaryCpty, 
                    agrContent, // content
                    ressign.data.data.signatures, // signatories
                    ressign.data.data.id, // signatoryRequestID
                    signMethod === 'swisscom' ? ressign.data.data.pdfUnsignedURL : '', // pdfUnsignedURL
                    '', // pdfSignedURL
                    signMethod,
                )

                // Create notifications and emails for all signatories
                primarySignatories.concat(secondarySignatories).forEach((signer) => {
                    let mod = (primarySignatories.includes(signer) && props.user.orgID === props.rel.primaryOrgID) || (secondarySignatories.includes(signer) && props.user.orgID === props.rel.secondaryOrgID) ? 
                        props.module : props.cptyModule
                    let lname = primarySignatories.includes(signer) ? props.secondaryCpty.legalName : props.primaryCpty.legalName

                    props.createNotification(
                        'Signature Request', // notificationType
                        'Signature Request', // title
                        'Your signature is requested for the ' + props.agr.agrShortName + ' with ' + lname, // description
                        '/core/' + mod + '/agreement/' + props.rel._id + '/' + props.agr._id, // linkTo 
                        false, // isRead
                        signer._id, // uid
                    )
                    // Send an email too
                    if(signer.order === undefined || signer.order === null || isNaN(signer.order) || signer.order === 0) {
                        sendEmailToUser(
                            "signRequest", 
                            isCustomLink, 
                            signer, 
                            lname, 
                            props.rel.whiteLabel !== undefined && props.rel.whiteLabel !== null && 
                            ['email','full'].includes(props.rel.whiteLabel.whiteLabelLevel) && props.rel.whiteLabel.logoURL !== undefined &&
                            signer.role === 'Counterparty' ? 
                                props.rel.whiteLabel : null);
                    }
                })

                // Create emails for all collaborators that are not also signatories
                primaryCollaborators.concat(secondaryCollaborators).forEach((c) => {
                    if(primarySignatories.concat(secondarySignatories).filter((s) => s._id === c._id)[0] === undefined){
                        sendEmailToUser(
                            "signFYI", 
                            isCustomLink, 
                            c, 
                            '', 
                            props.rel.whiteLabel !== undefined && props.rel.whiteLabel !== null && 
                            ['email','full'].includes(props.rel.whiteLabel.whiteLabelLevel) && props.rel.whiteLabel.logoURL !== undefined &&
                            c.role === 'Counterparty' ? 
                                props.rel.whiteLabel : null);
                    }
                })

                // Smart field (re)calculation when the agreement is going to exec/inForce
                let sfUpdateDate = new Date().toISOString();
                props.agrs.forEach((a) => { // For each agreement part (Main Body / Exhibit)
                    // Pull the smartfields for this agreement part
                    axios.get('/sf/sf/' + a._id)
                    .then(function(ressfs) {
                        let bulkWrites = []
                        let sfs = ressfs.data.data;
                        sfs.forEach((sf) => {
                            // get applicable clause for this sf
                            let cl = activeClauses.concat(subClauses).filter((c) => c.singleClauseID === sf.singleClauseID)[0]
                            if(cl !== undefined && ['Deleted', 'proposedDel'].includes(cl.clauseStatus)) {
                                axios.put('/sf/sfunlink', { // send request to unlink from the DB if the clause is in Deleted/proposedDel status
                                    ref: sf.ref,
                                    singleClauseID: sf.singleClauseID,
                                    lastUpdateBy: props.user._id,
                                    lastUpdateDate: sfUpdateDate,
                                })

                            } else if(cl !== undefined) {
                                // Prep work for standard clauses / sections
                                let newPlain = convertBlocksToPlain(cl.blocks, true).join('\n')                                
                                let startIdx = newPlain.indexOf("<" + sf.ref + ">") + ("<" + sf.ref + ">").length
                                let endIdx = newPlain.indexOf("</" + sf.ref + ">")
                                
                                // Prep work for table clauses with smart fields
                                let tableCell = null
                                if(cl.clauseCat === 'table') {
                                    // SF is in the head
                                    tableCell = cl.clauseObject.head.filter((h) => h.sf === sf.ref)[0] !== undefined ?
                                        cl.clauseObject.head.filter((h) => h.sf === sf.ref)[0] : null
                                    //tableValue = cl.clauseObject.head.filter((h) => h.sf === sf.ref)[0] !== undefined && !cl.clauseObject.invisibleHead ? 
                                    //    cl.clauseObject.head.filter((h) => h.sf === sf.ref)[0].value : null
                                    if(tableCell === null) { // SF is in the table body
                                        cl.clauseObject.rows.forEach((row) => {
                                            if(row.cols.filter((c) => c.sf === sf.ref)[0] !== undefined) {
                                                tableCell = row.cols.filter((c) => c.sf === sf.ref)[0];
                                            }
                                        })
                                    }
                                }
                                
                                if((cl.clauseCat === 'table' && tableCell !== null && (
                                    tableCell.change === undefined || tableCell.change === null || tableCell.change.type === undefined ||
                                    !['deleteRow','deleteCol'].includes(tableCell.change.type))) || 
                                   (newPlain.indexOf("<" + sf.ref + ">") > -1 && endIdx > -1)) {

                                    let value = tableCell !== null && tableCell.value !== undefined ? tableCell.value : newPlain !== undefined && newPlain !== null ? newPlain.substring(startIdx, endIdx) : 'unknown'

                                    // re-initalizes SF values, based on the text found inside the clause
                                    let breakdown = greatPatternFinder(value)
                                    let val3 = getSmartFieldValue(breakdown.type, breakdown.val1, breakdown.val2)

                                    // Push a bulk update write to an array - note to be slightly manipulated in the backend (to make the _id work)
                                    bulkWrites.push({
                                        "filter": { "_id": sf._id }, 
                                        "update": { $set: {
                                            "type": breakdown.type,
                                            "val1": breakdown.val1,
                                            "val2": breakdown.val2,
                                            "val3": val3,                                        
                                            "lastUpdateBy": 'sf-recalculation',
                                            "lastUpdateDate": sfUpdateDate,
                                        } },
                                        "upsert": false
                                    })

                                    // If this is the main body and the smart field is the agreement effective date, updated based on the recalculation
                                    if(a.parentAID === '' && sf.name === "Agreement Effective Date") {

                                        let newExpDate = 
                                            calculateExpiryDate(breakdown.val1, a.renewalType, a.effectiveTerm) !== null ? 
                                                calculateExpiryDate(breakdown.val1, a.renewalType, a.effectiveTerm) :
                                                a.expiryDate

                                        axios.put('/agr/agreffective/' + a._id, {
                                            effectiveDate: breakdown.val1,
                                            renewalType: a.renewalType,
                                            effectiveTerm: a.effectiveTerm,
                                            expiryDate: newExpDate,
                                            lastUpdateBy: props.user._id,
                                            lastUpdateDate: new Date().toISOString(),
                                        })
                                    }

                                } else {
                                    axios.put('/sf/sfunlink', { // send request to unlink from the DB if the clause is entirely redlined
                                        ref: sf.ref,
                                        singleClauseID: sf.singleClauseID,
                                        lastUpdateBy: props.user._id,
                                        lastUpdateDate: sfUpdateDate,
                                    })
                                }
                            }
                        })

                        // FAF The bulk update of smartfields for this agreement
                        if(bulkWrites.length > 0) {
                            axios.put('/sf/smartfields', { bulkWrites: bulkWrites })
                        }

                    }).catch((err) => console.log("err pulling SFS", err))
                })

                //props.parentCallToClose()
                history.push('/core/' + props.module + '/agreement/' + props.rel._id + '/' + props.agr._id)
                setLoading(false)

            }).catch(function (err) { console.log("err in signature request", err); }) 

        }).catch(function (err) { console.log("err creating PDF"); })

    } else { // Not all parties are ready to sign - ie. simply turn the pen

        let recipients = props.userIsPrimary ? secondaryCollaborators : primaryCollaborators
        let recipientsFyi = props.userIsPrimary ? primaryCollaborators : secondaryCollaborators
        let lname = props.userIsPrimary ? props.primaryCpty.legalName : props.secondaryCpty.legalName
        let lnameFyi = props.userIsPrimary ? props.secondaryCpty.legalName : props.primaryCpty.legalName

        // Inform the counterparty collaborators
        recipients.forEach((recipient) => {
            sendEmailToUser(
                newAgrStatus === 'Review' ? "informCptyUser" : "penTurn", 
                isCustomLink, 
                recipient, 
                lname,
                props.rel.whiteLabel !== undefined && props.rel.whiteLabel !== null && 
                ['email','full'].includes(props.rel.whiteLabel.whiteLabelLevel) && props.rel.whiteLabel.logoURL !== undefined &&
                recipient.role === 'Counterparty' ? 
                    props.rel.whiteLabel : null);
        })
        // Inform your own collaborators (except for yourself)
        recipientsFyi.filter((r) => r._id !== props.user._id)
        .forEach((recipientFyi) => {
            sendEmailToUser(
                newAgrStatus === 'Review' ? "informCptyUserFyi" : "penTurnFyi", 
                isCustomLink, 
                recipientFyi, 
                lnameFyi, 
                props.rel.whiteLabel !== undefined && props.rel.whiteLabel !== null && 
                ['email','full'].includes(props.rel.whiteLabel.whiteLabelLevel) && props.rel.whiteLabel.logoURL !== undefined &&
                recipientFyi.role === 'Counterparty' ? 
                    props.rel.whiteLabel : null)
        })

        setLoading(false);
        // Now forward to relationship overview page or "cpty thank you page"
        let path = "/core/" + props.module + "/relationship/" + props.rel._id + "/overview/2";        
        if(props.user.role === 'Counterparty') { path = "/core/counterparty/relationship/" + props.rel._id + "/cptydone/2"; } 
        history.push(path)
    }

}

function sendEmailToUser (type, isCustomLink, recipient, cptyLegalName, whiteLabel) {

    let individualizedlink = baseURL + '/network/' + orgurl + '/' + props.rel.customURL + '/' + recipient._id + '/' + props.agr._id;
    let createPassword = recipient.lastLoginDate === undefined && (recipient.password === undefined || recipient.password.length === 20)
    let linkmod = 
        recipient.role === 'Counterparty' ? 
            'counterparty' : 
        recipient.orgID === props.user.orgID ?
            getRecipientModule(props.user, recipient, props.module, props.rel) :
        recipient.role.startsWith('ClientFor_') ? 'counterparty': // applicable when a cpty initiates signing and the (first) signer is a ClientFor_
            props.cptyModule

    let relpagelogin = (baseURL + '/core/loginp/_a/' + recipient._id + '/' + linkmod + '/' + props.rel._id + '/' + props.agr._id)

    let emailmsg = '';

    let buttonName = isCustomLink && recipient.role === 'Counterparty' ? 
                        'View Agreement' :
                    createPassword ?
                        'Create Password' :
                        'View Agreement';

    let buttonLink = isCustomLink && recipient.role === 'Counterparty' ? 
                        individualizedlink : 
                    createPassword ?
                        baseURL + `/core/createpassword/` + recipient.password :
                        relpagelogin;

    if (['informCptyUser', 'informCptyUserFyi', 'penTurn', 'penTurnFyi', 'signRequest', 'signFYI', 'newCollab'].includes(type)) { // done 7/17

        // Create first BOLD sentence of email
        if(['informCptyUser', 'informCptyUserFyi', 'penTurn', 'penTurnFyi'].includes(type)) {
            let senderInfo = props.user.displayName + (['informCptyUser', 'penTurn'].includes(type) ? ` (` + cptyLegalName + `)` : ``)
            let recipientInfo = (['informCptyUser', 'penTurn'].includes(type) ? `you` : cptyLegalName)
            let sendType1 = (['informCptyUser', 'informCptyUserFyi'].includes(type) ? ' has shared a ' : ' has turned the ')
            let sendType2 = (['informCptyUser', 'informCptyUserFyi'].includes(type) ? ' with ' : ' over to ')

            emailmsg = `<p><b>` + senderInfo + sendType1 + props.agr.agrFullName + sendType2 + recipientInfo + `</b></p>`

        } else if(['signRequest'].includes(type)) {
            emailmsg = `<p><b>Your signature is requested for the ` + props.agr.agrFullName + ` with ` + cptyLegalName + `</b></p>`
            
        } else if(['signFYI'].includes(type)) {
            emailmsg = `<p><b>The ` + props.agr.agrFullName + ` between ` + props.primaryCpty.legalName + ` and ` + props.secondaryCpty.legalName + ` has been routed for signature</b></p>`

        } else if(['newCollab'].includes(type)) {
            emailmsg = `<p><b>` + props.user.displayName + ` has added you as a collaborator for the ` + props.agr.agrFullName + ` with ` + cptyLegalName + `</b></p>`

        }

        // Include a note that the user's org is ready to sign (if applicable and only on pen turn)
        if(['penTurn', 'penTurnFyi'].includes(type) && sign) {
            emailmsg = emailmsg + `<p><b>` + (props.userIsPrimary ? props.primaryCpty.shortName : props.secondaryCpty.shortName) + ` is ready to sign the current version of the agreement.</b></p>`
        }

        // Include a comment (if any and if recipient is CPTY)
        if(requestComment !== undefined && requestComment !== null && requestComment !== '' && requestComment.length > 0 && recipient.orgID !== props.user.orgID) {
            emailmsg = emailmsg + `<div style="margin: 40px 0px 40px 0px; padding: 0px 0px 0px 20px; border-left: 4px solid #f3f3f3; font-size: 14px; color: #6d6a7a; text-align: left;">`;
            requestComment.split('\n').forEach((cpar, i) => { emailmsg = emailmsg + cpar + (i !== (requestComment.split('\n').length - 1) ? '<br/>' : '') })    
            emailmsg = emailmsg + `</div>`
        }

        // Create third sentence, leading to the button
        emailmsg = emailmsg +  
            (isCustomLink && recipient.role === 'Counterparty' ? 
                `<p>Access the agreement through your unique relationship page` + (whiteLabel !== null ? `` : ` on Canveo`) + `.</p>` :
                `<p>Access the agreement by ` 
                + (createPassword ? 
                    `creating your password and then <a href="` + relpagelogin + `" style="color:#E80064; text-decoration: none; font-weight: bold;"><span style="color:#E80064">logging in</span></a>.` : 
                    `logging in` + (whiteLabel !== null ? `.` : ` to Canveo.`))
                + `</p>`)

        // Send the message with Agreement to the CPTY via Canveo - Fire and Forget
        axios.post('/mail/' + (
                ['informCptyUser', 'informCptyUserFyi'].includes(type) ? 'cptyagrshare' : 
                ['penTurn', 'penTurnFyi'].includes(type) ? 'cptypenturn' : 
                ['signRequest'].includes(type) ? 'signrequest' :
                ['signFYI'].includes(type) ? 'signfyi' :
                ['newCollab'].includes(type) ? 'addcollab' : 'error'), {
            recipient: recipient.email,
            senderFullName: props.user.displayName,
            message: emailmsg,
            withWhom: (
                ['informCptyUser', 'penTurn'].includes(type) ? `you` : 
                ['informCptyUserFyi', 'penTurnFyi'].includes(type) ? cptyLegalName : ''),
            buttonName: buttonName,
            buttonLink: buttonLink,
            whiteLabel: whiteLabel,
        })

    } else if (type === 'retrievefyi'){
        
        emailmsg = `<p><b>The ` + props.agr.agrFullName + ` between ` + props.primaryCpty.legalName + ` and ` + props.secondaryCpty.legalName + ` has been retrieved by ` + props.user.displayName + `</b></p>`

        if(requestComment !== undefined && requestComment !== null && requestComment !== '' && requestComment.length > 0 && recipient.orgID !== props.user.orgID) {
            emailmsg = emailmsg + `<div style="margin: 40px 0px 20px 0px; padding: 0px 0px 0px 20px; border-left: 4px solid #f3f3f3; font-size: 14px; color: #6d6a7a; text-align: left;">`;
            requestComment.split('\n').forEach((cpar, i) => { emailmsg = emailmsg + cpar + (i !== (requestComment.split('\n').length - 1) ? '<br/>' : '') })    
            emailmsg = emailmsg + `</div>`
        }

        axios.post('/mail/retrievefyi', {
            recipient: recipient.email,
            message: emailmsg,
            whiteLabel: whiteLabel,
        })
    }
}

const generateDoc = (type) => { // generatePDF, generateDOCX
    setLoadingPDF(true)
    setLoading(true)

    let agrContent = []
    agrContent.push(getAgrContentForPDF(props.agr.priority, null, props.agr.agrFullName, activeAVClauses, activeClauses)) // create ags array from main body
    agrContent = agrContent.concat(subagsContent.sort((a,b) => (a.priority > b.priority ? 1 : a.priority < b.priority ? -1 : 0))) // concat any subags into the array
    
    let psigTAG = [], ssigTAG = []
    psigTAG.push({
        entityName: props.primaryCpty.legalName,
        name: '',
        title: '',
        email_address: ''}) 
    ssigTAG.push({
        entityName: props.secondaryCpty.legalName,
        name: '',
        title: '',
        email_address: ''}) 

    // trigger creation of the PDF
    axios.post('/export/' + type, {
        filename: generateRandomString(20) + '.' + type,
        agrContent: agrContent,
        primarySigners: psigTAG,
        secondarySigners: ssigTAG,
        header: pdfHeader,
        format: 
            (props.primaryCpty !== undefined && props.primaryCpty !== undefined && props.primaryCpty.entityType !== undefined &&
            ['Subsidiary','LegalClient'].includes(props.primaryCpty.entityType) && props.primaryCpty.address[0] !== undefined && ['CA','US'].includes(props.primaryCpty.address[0].country)) ||
            (props.primaryCpty !== undefined && props.primaryCpty !== undefined && props.primaryCpty.entityType !== undefined && props.primaryCpty.entityType !== 'Subsidiary' &&
             props.secondaryCpty !== undefined && props.secondaryCpty !== undefined && props.secondaryCpty.entityType !== undefined &&
             ['Subsidiary','LegalClient'].includes(props.secondaryCpty.entityType) && props.secondaryCpty.address[0] !== undefined && ['CA','US'].includes(props.secondaryCpty.address[0].country)) ?
                'LETTER' : 'A4',
    })
    .then(function (res) { // the msg was sent

        axios.get(type === 'pdf' ? ('/signing/protect/download/' + res.data.data) : ('/export/download/' + res.data.data), {
            responseType: 'arraybuffer'
        })
        .then(function (res) { 
            download(res.data, props.downloadFileName + "." + type); 
            setLoadingPDF(false) 
            setLoading(false)
            closeDialog()
        }) // Download right away when ready
        .catch(function (err) { console.log("erroring out", err) }) // err in the server request

    })
    .catch(function(err) { console.log(err) })
}

const confirmRetrieve = () => {

    props.updateAgr( // Update the curAVOwner to SELF
        props.rel._id, 
        props.agr._id, 
        props.agr.primaryCptyID,
        props.agr.secondaryCptyID,
        props.agr.agrStatus === 'Review' ? 'Draft' : 'Negotiation', 
        props.user.orgID === props.rel.primaryOrgID ? props.agr.primaryCptyID : props.agr.secondaryCptyID, // curAVOwner - will be yourself
        props.agr.sourceFileID, 
        props.agr.contributors,
        props.agr.signatories,
        props.user._id)


    // End any open reminders
    axios.put('/reminder/reminders', { aid: props.agr._id, endDate: new Date().toISOString(), endReason: 'penTurn' })

    // Get all comments for all AVS
    let aids = []
    avsToDelete.forEach((avtd) => aids.push(avtd.agrID))
    axios.post('/comment/comments', { aids: aids })
    .then((res) => {
        avsToDelete.forEach((av, i) => {
            // Find and DELETE the clause comments that were created by the CPTY, after this new AGR Version was created
            let csToDelete = res.data.data.filter((c) => c.agrID === av.agrID && c.commentType === 'clause' && c.orgID !== props.user.orgID && c.creationDate > av.creationDate)
            csToDelete.forEach((ctd) => {
                props.updateComment(ctd._id, '___DELETED___', null, props.user._id)
            })

            // send put msg to delete all activitylogs for this avv (activityLog KeyVal1)
            axios.put('/logging/protect/alsdelete', { agrID: av.agrID, avv: av.version })

            axios.put('/agr/agrvdelete/' + av._id, {  agrID: av.agrID }).then((res) => { // Then delete the AGRV itself
                if(avsToDelete.length === (i + 1)) {
                    props.agr.contributors.forEach((c) => {
                        if(c.email !== props.user.email) {
                            let user = ownUsers.concat(cptyUsers).filter((u) => u.email === c.email)[0]
                            if(user !== undefined) {
                                sendEmailToUser (
                                    'retrievefyi', 
                                    null, 
                                    user, 
                                    null, 
                                    props.rel.whiteLabel !== undefined && props.rel.whiteLabel !== null && 
                                    ['email','full'].includes(props.rel.whiteLabel.whiteLabelLevel) && props.rel.whiteLabel.logoURL !== undefined &&
                                    user.role === 'Counterparty' ? 
                                        props.rel.whiteLabel : null)
                            }

                        }
                    })
                    props.handleActivityLog('agr', 'agrRetrieved', '', '', props.user.displayName, '', {})
                    let path = "/core/" + props.module + "/agreement/" + props.rel._id + "/" + props.agr._id;
                    history.push(path);
                }
            }).catch((err) => console.log(err))
        })
    })
}

const handleFinalizeImport = (iDoc) => {
    setImportOpen(false);
    setIDoc(iDoc)
}


const handleTriggerImport = (type) => {
    setImportOpen(true);
}

const handleSubmitOfflineSigned = () => {
    setLoadingPDF(true);
    const formData = new FormData();        
    formData.append('file', offlineSigned.file, generateRandomString(20)); // appending file
    axios.post('/upload/protect/pdf', formData, {}) // Upload PDF and get url
    .then(res => {

        let newAgrVersion = pad(parseInt(props.activeAgrVersion.version.substring(0, 2)) + 1,2) + ".001.001.000001";

        let avcs = [], agrContent = []; // default for radioDigitization === 'none'
        if(['curav', 'none'].includes(offlineSigned.radioDigitization)) {
            if(['curav'].includes(offlineSigned.radioDigitization)) { // For current AV - update respective variables
                avcs = props.activeAgrVersion.clauses;
                agrContent.push(getAgrContentForPDF(props.agr.priority, null, props.agr.agrFullName, activeAVClauses, activeClauses)) // create ags array from main body
                agrContent = agrContent.concat(subagsContent.sort((a,b) => (a.priority > b.priority ? 1 : a.priority < b.priority ? -1 : 0))) // concat any subags into the array
            }
            // Create a new Agreement version
            props.createAgrVersion(
                props.activeAgrVersion.agrID,
                newAgrVersion, // avv
                props.activeAgrVersion.owner, // owner of the AV - this will be set to the CPTY
                avcs, // avClauses
                '', // attachment
                true, // primaryReady
                true, // secondaryReady
                false, // duplicateClauses - if sending for signature false as both should access the same
                false, // freshAg
                'full', // editMode
            );

            if(subavs.length > 0) { // establish new AVs for potential subags too
                subavs.forEach((sav) => {
                    props.createAgrVersion(
                        sav.agrID,
                        newAgrVersion,
                        sav.owner, // owner of the AV
                        ['curav'].includes(offlineSigned.radioDigitization) ? sav.clauses : [],
                        '', // attachment
                        true, // primaryReady
                        true, // secondaryReady
                        false, // duplicateClauses - if sending for signature false as both should access the same
                        false, // freshAg
                        'full', // editMode
                    );
                })
            }

        } else if (offlineSigned.radioDigitization === 'newav') {
            
            // For new av - create from iDoc
            props.createAgrVersionWithImport(
                props.activeAgrVersion.agrID, // aid
                newAgrVersion, // avv
                props.activeAgrVersion.owner, // owner
                true, // primaryReady
                true, // secondaryReady
                false, // isEdited
                'full', // editMode 
                iDoc, // iDoc
                props.user._id, // creationBy
            )
            agrContent = [iDoc.data]

        }

        // Post the agrExec to our Database
        props.createAgrExec(
            props.rel._id, // relID, 
            props.agr._id, // agrID, 
            props.agr.agrTypeID, // agrTypeID, 
            newAgrVersion, // agrVersion, 
            {}, // primaryCpty, 
            {}, // secondaryCpty, 
            agrContent, // content
            [], // signatories
            "offline", // signatoryRequestID
            '', // pdfUnsignedURL
            res.data.docURL, // pdfSignedURL
            "offline",
        )

        props.updateAgr(
            props.rel._id, 
            props.agr._id, 
            props.agr.primaryCptyID,
            props.agr.secondaryCptyID,
            'InForce', 
            props.user.orgID, // curAVOwner - will be self
            'signedoffline', 
            getDBUserArray('collab'),
            [],
            props.user._id)
        
        props.handleActivityLog('agr', 'agrNewStatus', '', '', 'InForce', '', {})

        setLoadingPDF(false);
        history.push('/core/' + props.module + '/agreement/' + props.rel._id + "/" + props.agr._id)

    }).catch(err => console.log(err))
    
}

const requestToUpdatePhone = (requesteeUID, requestorUID) => {

    let recipient = ownUsers.concat(cptyUsers).filter((u) => u._id === requesteeUID)[0]
    let sender = ownUsers.concat(cptyUsers).filter((u) => u._id === requestorUID)[0]

    let individualizedlink = baseURL + '/network/' + orgurl + '/' + props.rel.customURL + '/' + recipient._id + '/phoneupdatingforsig';
    let createPassword = recipient.lastLoginDate === undefined && (recipient.password === undefined || recipient.password.length === 20)
    let relpagelogin = (baseURL + '/core/loginp/_ph/' + recipient._id + '/settings')

    let buttonName = isCustomLink && recipient.role === 'Counterparty' ? 
                        'Update Phone Number' :
                    createPassword ?
                        'Create Password' :
                        'Update Phone Number';

    let buttonLink = isCustomLink && recipient.role === 'Counterparty' ? 
                        individualizedlink : 
                    createPassword ?
                        baseURL + `/core/createpassword/` + recipient.password :
                        relpagelogin;

    if(recipient !== undefined && sender !== undefined) {
        axios.post('/mail/requestphoneupdate', {
            recipient: recipient.email,
            senderName: sender.displayName,
            agrFullName: props.agr.agrFullName,
            primaryCpty: props.primaryCpty.legalName,
            secondaryCpty: props.secondaryCpty.legalName,
            buttonName: buttonName,
            buttonLink: buttonLink,
        })
    }
}

const handleUserDetailUpdate = (type, uid, newval) => {

    let primIdx = primarySignatories.findIndex((u) => u._id === uid)
    let secIdx = secondarySignatories.findIndex((u) => u._id === uid)

    if(type === 'phone') {

        axios.put('/user/protect/phone/' + uid, { phone: newval })
        .then((res) => {

            let newUser = res.data.data;

            if(primIdx !== -1) { // Update the signer arrays locally
                let psigners = primarySignatories
                newUser.entityName = 
                    psigners[primIdx].entityName !== undefined && psigners[primIdx] !== null ?
                        psigners[primIdx].entityName : props.primaryCpty.legalName // Merge in any earlier updated EntityName field
                psigners[primIdx] = newUser;
                setPrimarySignatories([...psigners])
            } else if(secIdx !== -1) {
                let ssigners = secondarySignatories
                newUser.entityName = 
                    ssigners[secIdx].entityName !== undefined && ssigners[secIdx] !== null ?
                        ssigners[secIdx].entityName : props.secondaryCpty.legalName // Merge in any earlier updated EntityName field
                ssigners[secIdx] = newUser;
                setSecondarySignatories([...ssigners])
            }

            let ownIdx = ownUsers.findIndex((u) => u._id === newUser._id)
            let cpIdx = cptyUsers.findIndex((u) => u._id === newUser._id)

            if(ownIdx !== -1) { // Update the own / cpty users arrays locally
                let ousers = ownUsers
                ousers[ownIdx] = newUser;
                setOwnUsers([...ousers])
            } else if(cpIdx !== -1) {
                let cpusers = cptyUsers
                cpusers[cpIdx] = newUser;
                setCptyUsers([...cpusers])
            }

            setNeedUserFetching(true); // need to fetch the users upon dialog closure, since the phone got updated for the current user
            setSignatoryUpdate(true); // updates the AGR signers array upon closure

        }).catch((err) => { console.log(err) })

    } else if(type === "entityName") {

        if(primIdx !== -1) { // Update the signer arrays locally
            let psigners = primarySignatories
            psigners[primIdx].entityName = newval;
            setPrimarySignatories([...psigners])
        } else if(secIdx !== -1) {
            let ssigners = secondarySignatories
            ssigners[secIdx].entityName = newval;
            setSecondarySignatories([...ssigners])
        }
        setSignatoryUpdate(true)
    }
}

const handleUploadChange = (e) => {
    if(e.target !== undefined && e.target.files !== undefined) {
        setOfflineSigned({ ...offlineSigned, file: e.target.files[0] })
    }
}

const handleReminderInput = (val, type) => {
    if(type === 'update' && (/^[0-9]+$/.test(val) || val === '')){
        setReminders(val)
    } else if(type === 'blur' && (['', '0'].includes(val) || val.length > 2)) {
        setReminders('7')
    }
}

const handleIntegrationImport = () => {

    setLoading(true)

    let usersToImport = integrationUsers.filter((u) => u.selected && u.email !== undefined && u.email !== null &&
        u.firstname !== undefined && u.firstname !== null && u.lastname !== undefined && u.lastname !== null)

    if(usersToImport.length > 0) {

        let importLogTemp = [], usersAdded = []

        usersToImport.forEach((utt) => {
            createCollab({
                email: utt.email.trim().toLowerCase(),
                firstName: utt.firstname.trim(),
                lastName: utt.lastname.trim(),
                title: utt.jobtitle !== undefined && utt.jobtitle !== null ? utt.jobtitle : 'Collaborator',
                sourceData: 
                    ['hubspot'].includes(selectIntegrationUsers) ?
                        { source: 'hubspot', sourceID: utt.id, portalID: utt.portalId } :
                    ['salesforce'].includes(selectIntegrationUsers) ?
                        { source: 'salesforce', sourceID: utt.id } : null
            })
            .then((resUser) => {

                importLogTemp.push('Successfully imported: ' + utt.firstname + ' ' + utt.lastname)

                handleCollaborators(addingCollaborator, resUser, true);
                setNeedUserFetching(true)

                if(importLogTemp.length === usersToImport.length) { 
                    setImportLog(importLog.concat(importLogTemp))
                    setAddingCollaborator(null);
                    setSelectIntegrationUsers(null)
                    setLoading(false)
                }
        
            }).catch((err) => { 
                
                importLogTemp.push('Error importing: ' + utt.firstname + ' ' + utt.lastname + ' (' + err + ')')
                if(importLogTemp.length === usersToImport.length) { 
                    setImportLog(importLog.concat(importLogTemp))
                    setAddingCollaborator(null);
                    setSelectIntegrationUsers(null)
                    setLoading(false)
                }
            
            })
        })  

    } else { // No user was selected for import, just go back to the main collab sccreen
        setAddingCollaborator(null);
        setSelectIntegrationUsers(null)
    }

}

const handleIntegrationUserSelect = (email) => {

    let newIntUsers = integrationUsers;
    let idx = newIntUsers.findIndex((u) => u.email === email)
    newIntUsers[idx].selected = !newIntUsers[idx].selected
    setIntegrationUsers([...newIntUsers])
    
}

const handleIntegrationUserSearch = (sourceID, source) => {

    setLoading(true)

    axios.post('/integration/protect/users', { 
        integration: source,
        sourceID: sourceID, 
        orgID: props.org._id, 
        refreshToken: ['salesforce'].includes(source) ? props.org.integrations.salesforce.refreshToken : props.org.integrations.hubspot,
        instanceURL: ['salesforce'].includes(source) ? props.org.integrations.salesforce.instanceURL : null,
    }).then((res) => {
        
        setIntegrationUsers(res.data.data.filter((u) => u.email !== undefined && u.email !== null &&
            u.firstname !== undefined && u.firstname !== null && u.lastname !== undefined && u.lastname !== null))
        setSelectIntegrationUsers(source)
        setLoading(false)

    }).catch((err) => console.log(err))

}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
}

const onDragEnd = (result) => {
    if (!result.destination) { return; }
    if (result.destination.index === result.source.index) { return; }

    let ol = secondarySignatories.concat(primarySignatories).sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
    const newSignatories = reorder(ol, result.source.index, result.destination.index);
    // Loop through the ordered list of signatories and assign to the respective objects
    let psigners = [], ssigners = [];
    newSignatories.forEach((signer, i) => {
        if(signer.orgID === props.rel.primaryOrgID) {
            psigners.push({...signer, order: i})
        } else if(signer.orgID === props.rel.secondaryOrgID) {
            ssigners.push({...signer, order: i})
        } 
    })
    setPrimarySignatories([...psigners]);
    setSecondarySignatories([...ssigners]);
    setSignatoryUpdate(true);
}

const RenderAddSignerButton = ({type}) => {
    return (
    <>
        {props.user.role !== 'Counterparty' || (props.userIsPrimary && type === 'primary') ||  (!props.userIsPrimary && type === 'secondary') ?
        <Box align="center" mt={2} mb={2}>
            <Button className={classes.addButton} color={type} onClick={e => setAddingSignatory(type)}>
              <FontAwesomeIcon icon={faPlusCircle} className={classes.plusIcon} />Add signer for {trunc(type === 'primary' ? props.primaryCpty.shortName : props.secondaryCpty.shortName, 10)}</Button>
        </Box>
        : '' }   
    </>     
    )
}

return (
  <div>
    <Dialog
    open={props.open}
    scroll="paper"
    TransitionComponent={Transition}
    keepMounted
    onClose={closeDialog}
    fullWidth={true}
    maxWidth="sm"
    >

    <DialogTitle onClose={closeDialog}>
        {props.trigger === "export" ? <>Export Agreement&nbsp;&nbsp;<InformationalTooltip msg={"Any open edits will be \"accepted\" in the exported agreement."} /></> :
        props.trigger === "offlinesigning" ? <>Offline Signing&nbsp;&nbsp;<InformationalTooltip msg={"Export the agreement in case you want to sign this agreement outside the Canveo using the \"To be signed\" button.\n\nIn case you have signed an agreement outside of Canveo, you can import it into Canveo using the \"Signed offline\" button."} /></> :
        props.trigger === "sign" ? "Initiate signing" :
        props.trigger === "cpty" ? "Send to counterparty" : 
        props.trigger === "collab" ? "Add a collaborator" : 
        props.trigger === "retrieve" ? "Retrieve the latest version" : "" }
    </DialogTitle>

    <DialogContent id="msgContainer">

    {loading ?
    <Box mt={6} mb={6} align="center">

        <CircularProgress size={30} />

    </Box>
    :
    props.trigger === "retrieve" ?
    <Box mt={5} mb={5} align="center" width={"100%"}>
        {avsToDelete.length < props.agrs.length ?
        <Box mt={3} mb={3}>
            <CircularProgress size={30} />
        </Box>
        :
        <>
        
        {props.agrs.length === avsToDelete.length && avsToDelete.filter((av) => av.isEdited)[0] === undefined ?
        <Box align="center" mb={1}>
            <FontAwesomeIcon icon={faExclamationTriangle} className={classes.retrieveWarn} /> 
            <Typography>Your counterparty has not yet edited the agreement - include a message to inform your counterparty why you are retrieving the agreement.</Typography>
        </Box>
        :
        <Box align="center">
            <FontAwesomeIcon icon={faExclamationTriangle} className={classes.retrieveError} /> 
            <Typography><span style={{fontWeight: '700'}}>Your counterparty has already edited the agreement.</span><br/><br/>Once you retrieve the agreement all their changes will be lost. Include a message to inform your counterparty why you are retrieving the agreement.</Typography>
        </Box>
        }

        <Box align="left" mt={3} mb={0} className={classes.commentBox}>
            <TextField
            variant="outlined"
            name="addComment"
            onChange={handleRequestCommentChange}
            label="Add comment"
            placeholder="Add note for counterparty..."
            multiline
            fullWidth
            rows="4"
            />
        </Box>
        </>
        }
    </Box>
    :
    props.trigger === "offlinesigning" ?
    <Box 
      mt={offlineSigned !== null && offlineSigned.selected ? 4 : 6} 
      mb={offlineSigned !== null && offlineSigned.selected ? 4 : 8} align="center">

        {loadingPDF ? 
        <CircularProgress size={30} />
        :offlineSigned !== null && offlineSigned.selected ?
        <Box>
            <Typography align="center" variant="h5">Upload the signed agreement</Typography>
            <Typography align="center" variant="body2" color="textSecondary">Click on the icon to upload a PDF</Typography>
            {offlineSigned !== undefined && offlineSigned !== null && offlineSigned.file !== undefined && offlineSigned.file !== null ?
            <Box mt={3} align="center">
            <img src={pdf} alt="PDF uploaded" className={classes.pdfIconS} />
            <Box mt={1}>
                <Button size="small" variant="text" color="secondary" onClick={e => setOfflineSigned({...offlineSigned, file: null})}>Clear upload</Button>
            </Box>
            </Box>
            :
            <>
            <input type="file" id="icon-button-file"
                accept=".pdf"
                onChange={handleUploadChange} hidden />
            <label htmlFor="icon-button-file">
                <IconButton component="span"  style={{marginTop: '15px'}}>
                    <FontAwesomeIcon icon={faFilePdf} className={classes.uploadPDF} />
                </IconButton>
            </label>
            </>
            }
            <Box mt={3}>
                <Typography variant="h5">Digitize the signed agreement</Typography>
            </Box>
            <Box mt={3}>
                {iDoc === null ?
                <FormControl component="fieldset">
                    <RadioGroup name="loginType" value={offlineSigned.radioDigitization} onChange={e => setOfflineSigned({...offlineSigned, radioDigitization: e.target.value})}>
                      <FormControlLabel value="curav" control={<Radio />} 
                        label={<Typography variant="body1">Use the current agreement version as digitized document</Typography>} />
                      <FormControlLabel value="newav" disabled={subavs.length > 0} control={<Radio />} 
                        label={<Typography variant="body1">Import a new agreement version</Typography>} />
                      <FormControlLabel value="none" control={<Radio />} 
                        label={<Typography variant="body1">Do not digitize</Typography>} />

                    </RadioGroup>
                </FormControl>
                :
                <Box align="center">
                <FontAwesomeIcon icon={faFileUpload} className={classes.uploadPDF} />
                <Box mt={1}>
                    <Button size="small" variant="text" color="secondary" onClick={e => setIDoc(null)}>Clear import</Button>
                </Box>
                </Box>
                }
            </Box>
        </Box>
        :
        <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
            <Grid item>
                <ThumbTodo
                line1={"To be signed"}
                line2="Export for offline signing" 
                click={e=> generateDoc('pdf')}
                route="click"
                icon={faFileExport} 
                adminNeeded={false}
                done={false} />
            </Grid>
            <Grid item>
                <ThumbTodo
                line1={"Signed offline"}
                line2="Upload signed agreement" 
                click={e => setOfflineSigned({...offlineSigned, selected: true})}
                route="click"
                icon={faFileImport} 
                adminNeeded={false}
                done={false} />
            </Grid>
        </Grid>
        }
    </Box>
    :
    props.trigger === "export" ?
    <Box mt={8} mb={8} align="center">

        {loadingPDF || pdf === null || pdf === undefined || docx === null || docx === undefined ? 
        <Box mt={6} mb={6}>
            <CircularProgress size={30} />
        </Box>
        :
        <Grid container direction="row" alignItems="center" justify="center">
            <Grid item>

            <Tooltip title="Download to PDF" arrow placement="bottom" open={docType === 'pdf'}>
            <img src={pdf} alt="Download to PDF" className={classes.pdfIcon} onClick={e => setDocType('pdf')} />
            </Tooltip>
            
            </Grid>

            {props.user.role !== 'Counterparty' ? 
            <Grid item>

            <Tooltip title="Download to MS Word" arrow placement="bottom" open={docType === 'docx'}>
            <img src={docx} alt="Download to MS Word" className={classes.pdfIcon} onClick={e => setDocType('docx')} />
            </Tooltip>

            </Grid>
            : ''}
        </Grid>
        }

    </Box>
    :
    props.trigger === 'cpty' || props.trigger === 'sign' || props.trigger === 'collab' ?

        <Box mt={2} mb={4}>
            {props.trigger !== "sign" && props.trigger !== 'collab' ? 
            <Box align="center" mb={3} >
                <Box style={{width: '50px'}}>
                    <MobileStepper
                    variant="dots"
                    steps={3}
                    position="static"
                    activeStep={expandCollab ? 0 :
                                expandSignatories ? 1 : 
                                expandMessage ? 2 : 3}
                    className={classes.stepper}
                    />
                </Box>
            </Box>
            : <Box mb={1}>&nbsp;</Box> }

            {viewImportLog && importLog.length > 0 ? 
            <Grid item xs={12}>
                <Box mt={5} mb={7} align="center">
                    <Typography variant="h5" className={classes.bold}>Import Log</Typography>
                    <Box mt={2}>
                    {importLog.map((il, i) => (
                        <Typography key={i} variant="body2" style={il.startsWith('Succ') ? { color: theme.palette.success.main } : {color: theme.palette.error.main}}>
                        {il}
                        </Typography>
                    ))}
                    </Box>
                </Box>
            </Grid>
            :
            addingSignatory !== null || addingCollaborator !== null || deletingCollaborator !== null ?
            <Grid container direction="row" alignItems="flex-start" justify="space-between">
                <Grid item xs={12}>
                    <Typography variant="h5" className={classes.bold} align="center">
                    {addingSignatory !== null ? "Add a signer" :
                     addingCollaborator !== null && props.trigger === "collab" ? "Add a collaborator now" :
                     addingCollaborator ? "Create a collaborator" : 
                     deletingCollaborator !== null ? "Revoke access for a collaborator" : "Create a user"}                        
                    </Typography>
                    {addingCollaborator !== null || (addingSignatory !== null && creatingSignatory) ?
                    <Box mt={2} align="center">
                        {props.org !== undefined && props.org.clientType === 'legalOrg' ?
                            <Box className={classes.switchGroup}>
                                <Button size="small" variant="text" onClick={(addingSignatory !== null && creatingSignatory) ? e => setAddingSignatory("primary") : e => setAddingCollaborator("primary")}
                                className={addingCollaborator === "primary" || addingSignatory === "primary" ? classes.buttonActive : classes.button}
                                style={{borderRadius: '10px 0px 0px 10px'}}
                                color={"default"}>
                                    {trunc(props.primaryCpty.legalName, 20)}
                                </Button>
                                <Button size="small" variant="text" onClick={(addingSignatory !== null && creatingSignatory) ? e => setAddingSignatory("secondary") : e => setAddingCollaborator("secondary")}
                                className={addingCollaborator === "secondary" || addingSignatory === "secondary" ? classes.buttonActiveSec : classes.button}
                                style={{borderRadius: '0px 10px 10px 0px'}}
                                color={"default"}>
                                    {trunc(props.secondaryCpty.legalName,20)}
                                </Button>
                            </Box>
                            :
                            <Typography variant="subtitle2" align="center" color="textSecondary">
                                {addingCollaborator === 'primary' || addingSignatory === 'primary' ? props.primaryCpty.legalName :
                                addingCollaborator === 'secondary' || addingSignatory === 'secondary' ? props.secondaryCpty.legalName : ''}
                            </Typography> 
                        }
                    </Box>
                    :deletingCollaborator !== null ? 
                    <Box mt={2}>
                    <Typography variant="subtitle2" align="center" color="textSecondary">
                        Revoke access to this agreement or delete the user entirely
                    </Typography> 
                    </Box>
                    : '' }
                </Grid>

                {loading ?
                <Box mt={6} mb={6} align="center">

                    <CircularProgress size={30} />

                </Box>
                :selectIntegrationUsers !== null && integrationUsers !== null ?
                <Grid item xs={12}>
                <Box mt={integrationUsers.length === 0 ? 6 : 2} mb={integrationUsers.length === 0 ? 6 : 1} align="center">
                    {integrationUsers.length === 0 ?
                    <Typography>No users found associated with this {selectIntegrationUsers} account</Typography>
                    :
                    <Box style={{width: '80%'}}>
                        <List>
                            {integrationUsers
                            .filter((iu) => !cptyUsers.some((cpu) => cpu.email === iu.email.trim().toLowerCase()))
                            .sort((a,b) => (a.firstname > b.firstname ? 1 : a.firstname < b.firstname ? -1 : 0))
                            .map((iu, i) => (
                            <ListItem key={iu.id} button onClick={e => handleIntegrationUserSelect(iu.email)}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={iu.selected ? faSquareCheck : faSquare} style={iu.selected ? {color: theme.palette.secondary.main, fontSize: '20px'} : {fontSize: '20px'}} />
                                </ListItemIcon>
                                <ListItemText 
                                primary={
                                    <Typography variant="body1" className={classes.semiBold}>
                                    {trunc((iu.firstname + ' ' + iu.lastname), 20)}<span className={classes.optionEmail}>{trunc(iu.email,20)}</span>
                                    </Typography>} 
                                secondary={trunc(iu.jobtitle !== undefined && iu.jobtitle !== null ? iu.jobtitle : 'Collaborator', 35)} />
                            </ListItem>
                            ))}
                        </List>
                        <Box mt={2}>
                            <Button fullWidth color="primary" variant="contained" disableElevation
                            onClick={handleIntegrationImport}
                            >Import from {selectIntegrationUsers}&nbsp;&nbsp;<FontAwesomeIcon icon={['hubspot'].includes(selectIntegrationUsers) ? faHubspot : faSalesforce} /></Button>
                        </Box>
                    </Box>
                    }

                </Box>
                </Grid>
                :!creatingSignatory && (
                 (deletingCollaborator === "primary" && props.primaryCpty.entityType !== 'Subsidiary') ||
                 (deletingCollaborator === "secondary" && props.secondaryCpty.entityType !== 'Subsidiary')) ?
                <Grid item xs={12}>
                    <Box mt={4} mb={0}>

                        {// For legalOrgs, ability to remove any clients
                        props.org.clientType === 'legalOrg' ?
                        <Box mb={3}>
                        {ownUsers
                        .filter((ou) => ou.role.startsWith("ClientFor_") && ((props.userIsPrimary && props.primaryCpty._id === ou.role.substring(10)) || (!props.userIsPrimary && props.secondaryCpty._id === ou.role.substring(10))))
                        .sort((a,b) => (a.displayName > b.displayName ? 1 : a.displayName < b.displayName ? -1 : 0))
                        .map((cpu) => (
                            <Box mt={1} key={cpu._id}>
                            <Grid container justify="space-between" alignItems="center" direction="row">
                                <Grid item>
                                    <Typography align="left">{cpu.displayName}</Typography>
                                </Grid>
                                <Grid item>
                                    <Button size="small" variant="outlined" onClick={e => handleRevokeCollab(cpu, 'delete')} className={classes.delButton}>Delete</Button>
                                </Grid>
                            </Grid>
                            </Box>
                        ))}
                        <Box mt={3}><Divider /></Box>
                        </Box>
                        :''}

                        {cptyUsers.sort((a,b) => (a.displayName > b.displayName ? 1 : a.displayName < b.displayName ? -1 : 0))
                        .map((cpu) => (
                            <Box mt={1} key={cpu._id}>
                            <Grid container justify="space-between" alignItems="center" direction="row">
                                <Grid item>
                                    <Typography align="left">{cpu.displayName}</Typography>
                                </Grid>
                                <Grid item>
                                    {cpu.agrIDs !== undefined && cpu.agrIDs !== null && cpu.agrIDs.some((aid) => aid === props.agr._id) ?
                                    <Button size="small" variant="outlined" onClick={e => handleRevokeCollab(cpu, 'revoke')}>Revoke</Button> 
                                    : '' }
                                    &nbsp;&nbsp;
                                    <Button size="small" variant="outlined" onClick={e => handleRevokeCollab(cpu, 'delete')} className={classes.delButton}>Delete</Button>
                                </Grid>
                            </Grid>
                            </Box>
                        ))}
                    </Box>
                </Grid>
                :
                (!creatingSignatory) && (
                 (addingSignatory === "primary" && props.primaryCpty.entityType === 'Subsidiary') ||
                 (addingSignatory === "secondary" && props.secondaryCpty.entityType === 'Subsidiary') ||
                 (ownUsers.length > 0 && ((props.userIsPrimary && addingSignatory === "primary") || (!props.userIsPrimary && addingSignatory === "secondary"))) ||
                 (cptyUsers.length > 0 && ((props.userIsPrimary && addingSignatory === "secondary") || (!props.userIsPrimary && addingSignatory === "primary")))) ?
                <Grid item xs={12}>
                    <Box mt={6} mb={6}>
                    <SelectUsers
                        users={(addingSignatory === "primary" && props.userIsPrimary) ||
                               (addingSignatory === "secondary" && !props.userIsPrimary) ? 
                                    ownUsers
                                    .filter((ou) => !primarySignatories.concat(secondarySignatories).some((signer) => signer._id === ou._id))
                                    .filter((u) => 
                                        (props.org !== undefined && props.org.clientType === 'legalOrg' && // OwnUsers if a LegalOrg
                                        u.role.startsWith("ClientFor_") && ((u.role.substring(10) === props.rel.primaryCptyID) || (u.role.substring(10) === props.rel.secondaryCptyID))
                                        ) || // OwnUsers if not a LegalOrg
                                        (props.org !== undefined && props.org.clientType !== 'legalOrg' && (
                                        (['Admin', 'Legal', 'Counterparty'].includes(u.role)) ||
                                        (['Business', 'Sales'].includes(u.role) && props.module === 'sales') ||
                                        (['Business', 'Procurement'].includes(u.role) && props.module === 'procurement') ||
                                        (['Business', 'Partnerships'].includes(u.role) && props.module === 'partnerships') ||
                                        (['Corporate'].includes(u.role) && props.module === 'corporate') ||
                                        (['HR'].includes(u.role) && props.module === 'people'))))
                                    .sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0)) :
                               (addingSignatory === "primary" && !props.userIsPrimary) ||
                               (addingSignatory === "secondary" && props.userIsPrimary) ||
                               deletingCollaborator !== null ? 
                                    cptyUsers
                                    .filter((cu) => !primarySignatories.concat(secondarySignatories).some((signer) => signer._id === cu._id))
                                    .filter((u) => (['Admin', 'Legal', 'Counterparty'].includes(u.role)) ||
                                    (['Business', 'Sales'].includes(u.role) && props.module === 'sales') ||
                                    (['Business', 'Procurement'].includes(u.role) && props.module === 'procurement') ||
                                    (['Business', 'Partnerships'].includes(u.role) && props.module === 'partnerships') ||
                                    (['Corporate'].includes(u.role) && props.module === 'corporate') ||
                                    (['HR'].includes(u.role) && props.module === 'people'))
                                    .sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0)) : []}
                        parentCall={handleSearchChange}
                        label={deletingCollaborator !== null ? "Select user" : "Select Signer"} />

                    {/*<Box mt={2}>
                    <Button onClick={e => handleAddSignatory(userValue)} disabled={userValue === null}
                            fullWidth variant="contained" color="primary" disableElevation>Submit</Button>
                    </Box>*/}
                    
                    {(addingSignatory === "primary" && props.primaryCpty.entityType !== 'Subsidiary') ||
                     (addingSignatory === "secondary" && props.secondaryCpty.entityType !== 'Subsidiary') ? 
                    <Box align="center" mt={2}>
                    <Button color={addingSignatory} onClick={e => setCreatingSignatory(true)}>
                        <FontAwesomeIcon icon={faPlusCircle} />&nbsp;&nbsp;Create a signer for {addingSignatory === "primary" ? trunc(props.primaryCpty.legalName, 25) : trunc(props.secondaryCpty.legalName, 25)}
                    </Button>
                    </Box>
                    : '' }

                    </Box>
                </Grid>
                :
                <>
                <Grid item xs={12}>
                    {submitError !== null ? 
                    <Box mt={addingCollaborator !== null || addingSignatory !== null || deletingCollaborator !== null ? 1 : 3} align="center">
                        <Typography color="error" variant="subtitle1">{submitError}</Typography>
                    </Box> : '' }

                    <Box mt={addingCollaborator !== null || addingSignatory !== null || deletingCollaborator !== null ? 1 : 3} mb={1}>
                        <UserForm 
                            parentCallback={callbackUserForm}
                            type="cptyuser"
                            initialValues={{
                                firstName: '', 
                                lastName: '',
                                role: 'Counterparty',
                                title: '',
                                email: '' }} />
                    </Box>
                    {props.trigger === "collab" ? 
                    <Box align="center" mt={2}>
                    <Button size="small" color="primary" onClick={e => setAddingCollaborator(null)}>View existing collaborators</Button>
                    </Box>
                    : '' }

                    {props.org !== undefined && props.org !== null && props.org.integrations !== undefined && props.org.integrations !== null &&
                    ((props.org.integrations.hubspot !== undefined && props.org.integrations.hubspot !== null) ||
                     (props.org.integrations.salesforce !== undefined && props.org.integrations.salesforce !== null)) && // Org has an integration
                    ((props.userIsPrimary && props.secondaryCpty !== undefined && props.secondaryCpty !== null &&
                      props.secondaryCpty.sourceData !== undefined && props.secondaryCpty.sourceData !== null &&
                      props.secondaryCpty.sourceData.source !== undefined && props.secondaryCpty.sourceData.source !== null &&
                      props.secondaryCpty.sourceData.sourceID !== undefined && props.secondaryCpty.sourceData.sourceID !== null) || // Sec ent is from a source
                     (!props.userIsPrimary && props.primaryCpty !== undefined && props.primaryCpty !== null &&
                      props.primaryCpty.sourceData !== undefined && props.primaryCpty.sourceData !== null &&
                      props.primaryCpty.sourceData.source !== undefined && props.primaryCpty.sourceData.source !== null &&
                      props.primaryCpty.sourceData.sourceID !== undefined && props.primaryCpty.sourceData.sourceID !== null) // Prim ent is from a source
                    ) ?

                        <Box mt={3} align="center">
                        <img src={props.userIsPrimary && ['hubspot'].includes(props.secondaryCpty.sourceData.source) ? hubspot : salesforce} alt="Source Account" 
                        width={props.userIsPrimary && ['hubspot'].includes(props.secondaryCpty.sourceData.source) ? "65" : "55"} className={classes.integrationImg} 
                        onClick={e => handleIntegrationUserSearch(
                            props.userIsPrimary ? props.secondaryCpty.sourceData.sourceID : props.primaryCpty.sourceData.sourceID, // sourceID
                            props.userIsPrimary ? props.secondaryCpty.sourceData.source : props.primaryCpty.sourceData.source // source
                        )} />
                        </Box>

                    :'' }
                    
                </Grid>
                </>}

            </Grid>
            :
            <Grid container direction="row" alignItems="flex-start" justify="center">

                {usersEditing.length > 0 ? 
                <Box mt={0} mb={3} align="center" className={classes.fullWidth}>
                    <FontAwesomeIcon icon={faExclamationTriangle} className={classes.retrieveError} />
                    <Typography color="error" variant="subtitle2">{usersEditing.filter(onlyUnique).join(' and ') + (usersEditing.filter(onlyUnique).length === 1 ? " is " : " are ") + "currently editing this agreement."}</Typography>
                    <Typography color="error" variant="subtitle2">Sending the agreement to the counterparty will delete the latest edits. To preserve, please ask the user{usersEditing.filter(onlyUnique).length === 1 ? " to finalize his/her work" : "(s) to finalize their work"}.</Typography>
                </Box>
                : '' }

                { expandCollab ?
                <>
                <Box className={classes.fullWidth}>
                <Typography variant="h5" className={classes.bold} align="center" >Add collaborators&nbsp;&nbsp;<InformationalTooltip msg={"Collaborators are kept up-to-date on events related to the agreement. You can select internal and counterparty collaborators through the search boxes.\n\nIf a counterparty is not yet on the Canveo network, you can create new collaborators and manage access through the respective buttons at the bottom left. Counterparties can also create collaborators themselves and all counterparty users are free of charge.\n\nNote that being a collaborator is independent of whether a user is able to access the agreement."}/></Typography>
                </Box>
                <Box mt={2} className={classes.fullWidth}>
                <Typography variant="body2" display="block" align="center">
                    {props.trigger === 'collab' ? 
                    "New collaborator will receive an email to access the agreement" :
                    "Collaborators will be informed when you send the agreement"}</Typography>
                </Box>

                <Box mb={2} className={classes.fullWidth}>

                {props.trigger !== "collab" || props.userIsPrimary ?
                <Grid item xs={12}>

                    {props.userIsPrimary || (!props.userIsPrimary && cptyUsers !== undefined && cptyUsers !== null && cptyUsers.length > 0) ?
                    <Box mb={2} mt={5}>
                    <Autocomplete
                        multiple
                        disableClearable
                        openOnFocus
                        onChange={(e, value) => handleCollaborators("primary", value, false)}
                        options={(props.userIsPrimary && ownUsers !== undefined && ownUsers !== null) ? ownUsers : 
                                 (!props.userIsPrimary && cptyUsers !== undefined && cptyUsers !== null) ? cptyUsers : []}
                        value={primaryCollaborators}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.displayName}
                        renderOption={(option, { selected }) => 
                        <React.Fragment>
                            <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                            />
                            <Typography variant="body1" className={classes.semiBold}>
                            {trunc(option.displayName + " (" + option.title + ")", 34)}<span className={classes.optionEmail}>{trunc(option.email,20)}</span>
                            </Typography>
                        </React.Fragment>
                        }
                        style={{width: '100%'}} 
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" {...getTagProps({ index })} className={classes.primaryChip}
                                label={<Typography className={classes.chipFont}>{trunc(option.displayName,22)}</Typography>}
                                avatar={<Avatar src={option.photoURL} className={classes.chipAv} style={{color: '#ffffff'}} >{option.firstName[0] + option.lastName[0]}</Avatar>}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" label={props.primaryCpty.shortName} style={{width: '100%'}} placeholder="Add collaborators" color="primary" />
                        )}
                    />
                    </Box>
                    :
                    <Box align="center" mt={3} mb={2} className={classes.addCollabBox}>
                        <Button color="primary" variant="text" disableElevation size="large" onClick={e => setAddingCollaborator("primary")}>Create a collaborator for {props.primaryCpty.legalName}&nbsp;&nbsp;<FontAwesomeIcon icon={faUserPlus} /></Button>
                    </Box>
                    }

                </Grid>
                : '' }
                {props.trigger !== "collab" || !props.userIsPrimary ?
                <Grid item xs={12}>

                    {!props.userIsPrimary || (props.userIsPrimary && cptyUsers !== undefined && cptyUsers !== null && cptyUsers.length > 0) ?
                    <Box mt={props.trigger === "collab" ? 3 : 0} mb={props.trigger === "collab" ? 2 : 1}>
                    <Autocomplete
                        multiple
                        disableClearable
                        openOnFocus
                        onChange={(e, value) => handleCollaborators("secondary", value, false)}
                        options={(props.userIsPrimary && cptyUsers !== undefined && cptyUsers !== null) ? cptyUsers : 
                                 (!props.userIsPrimary && ownUsers !== undefined && ownUsers !== null) ? ownUsers : []}
                        value={secondaryCollaborators}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.displayName}
                        renderOption={(option, { selected }) => 
                        <React.Fragment>
                            <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                            />
                            <Typography variant="body1" className={classes.semiBold}>
                            {trunc(option.displayName + " (" + option.title, 33)})<span className={classes.optionEmail}>{trunc(option.email,22)}</span>
                            </Typography>
                        </React.Fragment>
                        }
                        style={{width: '100%'}} 
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" {...getTagProps({ index })} className={classes.secondaryChip}
                                label={<Typography className={classes.chipFont}>{trunc(option.displayName,22)}</Typography>}
                                avatar={<Avatar src={option.photoURL} className={classes.chipAv} style={{color: '#ffffff'}}>{option.firstName[0] + option.lastName[0]}</Avatar>}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" label={props.secondaryCpty.shortName} style={{width: '100%'}} placeholder="Add collaborators" color="secondary" />
                        )}
                    />
                    </Box>
                    :
                    <Box align="center" mt={2} mb={1} className={classes.addCollabBox}>
                        <Button color="secondary" variant="text" disableElevation size="large" onClick={e => setAddingCollaborator("secondary")}>Create a collaborator for {props.secondaryCpty.legalName}&nbsp;&nbsp;<FontAwesomeIcon icon={faUserPlus} /></Button>
                    </Box>
                    }

                </Grid>
                : '' }
                </Box>
                </>
                :
                expandSignatories ?
                <>
                <Typography variant="h5" className={classes.bold} align="center" >Add signers&nbsp;&nbsp;<InformationalTooltip msg={"Signers will be asked to sign the final agreement through the integrated signing workflow.\n\nSigners can be defined early on but are looped in only when both parties have marked the agreement as ready to sign.\n\nA signing order may be defined as well as a different signing standard as per EU and Swiss law."}/></Typography>

                <Box mb={0} mt={['cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 0 : 2} className={classes.fullWidth}>

                {['cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 

                <Box align="center" mt={1} mb={2}>
                    
                    <Box className={classes.sigQualityBox} align="center">
                    <Typography variant="body1" onClick={handleSigQualityOpen}>
                        {activeAgr !== undefined && activeAgr !== null && activeAgr.sigQuality !== undefined && activeAgr.sigQuality === "SES" ? "Standard Signature (SES)" :
                        activeAgr !== undefined && activeAgr !== null && activeAgr.sigQuality !== undefined  && activeAgr.sigQuality === "AES" ? "Advanced Signature (" + activeAgr.sigQuality + ")" :
                        activeAgr !== undefined && activeAgr !== null && activeAgr.sigQuality !== undefined  && activeAgr.sigQuality.startsWith("QES") ? "Qualified Signature (" + activeAgr.sigQuality + ")" :
                            "Select Signature Quality"}
                        <FontAwesomeIcon icon={faCaretDown} className={classes.sigQualityCaret} />
                    </Typography>
                    </Box>
                    <Menu
                        anchorEl={anchorElSig}
                        keepMounted
                        open={Boolean(anchorElSig)}
                        onClose={handleSigQualityClose}
                    >
                        <MenuItem onClick={e => handleSigQualitySelect('SES')} className={classes.menuOption2}>Standard Signature (SES)</MenuItem>
                        {['skribble', 'swisscom'].includes(signMethod) ?
                        <MenuItem onClick={e => handleSigQualitySelect('AES')} className={classes.menuOption2}>Advanced Signature (AES)</MenuItem>:''}
                        {['skribble', 'swisscom'].includes(signMethod) ?
                        <Typography className={classes.menuGrouping}>Qualified Signature (QES)</Typography>: ''}
                        {['skribble', 'swisscom'].includes(signMethod) ?
                        <MenuItem onClick={e => handleSigQualitySelect('QES-EU')} className={classes.menuOption3}>
                            <ListItemAvatar>
                                <Avatar src={flag_eu} style={{width: '20px', height: '20px'}} />
                            </ListItemAvatar>
                            <ListItemText>EU<span className={classes.lighter}>eIDAS</span></ListItemText>
                        </MenuItem>:''}
                        {['skribble', 'swisscom'].includes(signMethod) ?
                        <MenuItem onClick={e => handleSigQualitySelect('QES-CH')} className={classes.menuOption3}>
                            <ListItemAvatar>
                                <Avatar src={flag_ch} style={{width: '20px', height: '20px'}} />
                            </ListItemAvatar>
                            <ListItemText>Switzerland<span className={classes.lighter}>ZertES</span></ListItemText>
                        </MenuItem>:''}
                    </Menu>

                </Box>
                : '' }

                {signingOrder ? 
                <Grid item container direction="column">
                    <Box mt={2} mb={2}>
                    <DragDropContext onDragEnd={onDragEnd}>

                        <Column id="1" 
                        list={secondarySignatories.concat(primarySignatories).sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))}
                        userIsPrimary={props.userIsPrimary}
                        user={props.user}
                        handleRemoveSignatory={handleRemoveSignatory}
                        sigQuality={activeAgr.sigQuality}
                        handleUserDetailUpdate={handleUserDetailUpdate}
                        requestToUpdatePhone={requestToUpdatePhone}
                        agrStatus={props.agr.agrStatus} />

                    </DragDropContext>
                    </Box>
                    <Box mb={1}>
                        <Box mt={1}>
                        <RenderAddSignerButton type={'primary'} />
                        </Box>
                        <Box mt={1}>
                        <RenderAddSignerButton type={'secondary'} />
                        </Box>
                    </Box>
                </Grid>
                :
                <Grid item container direction="row">
                    <Grid item xs={12} sm={6}>
                        <Box align="center" mt={2}>
                        {primarySignatories.length > 0 ?
                            primarySignatories.map((ps) => (

                                <RenderSignBox key={ps._id} signer={ps} boxType='primary' handleRemoveSignatory={handleRemoveSignatory}
                                userIsPrimary={props.userIsPrimary} 
                                user={props.user}
                                sigQuality={activeAgr.sigQuality}
                                signMethod={signMethod}
                                handleUserDetailUpdate={handleUserDetailUpdate}
                                requestToUpdatePhone={requestToUpdatePhone}
                                agrStatus={props.agr.agrStatus} />

                            ))
                        : '' }
                        </Box>
                        <Box mt={primarySignatories.length > 0 ? 0 : 4}>
                        <RenderAddSignerButton type={'primary'} />
                        </Box>
                    </Grid>
                    <Grid item  xs={12} sm={6}>
                        <Box align="center" mt={2}>
                        {secondarySignatories.length > 0 ?
                            secondarySignatories.map((ss) => (

                                <RenderSignBox key={ss._id} signer={ss} boxType='secondary' handleRemoveSignatory={handleRemoveSignatory}
                                userIsPrimary={props.userIsPrimary} 
                                user={props.user}
                                sigQuality={activeAgr.sigQuality}
                                signMethod={signMethod}
                                handleUserDetailUpdate={handleUserDetailUpdate}
                                requestToUpdatePhone={requestToUpdatePhone}
                                agrStatus={props.agr.agrStatus} />

                            ))
                        : '' }
                        </Box>
                        <Box mt={secondarySignatories.length > 0 ? 0 : 4}>
                        <RenderAddSignerButton type={'secondary'} />
                        </Box>
                    </Grid>

                </Grid>
                }

                {props.user.role === 'Counterparty' && props.trigger === "sign" && cptySignReady ? 
                  <Box align="center" mt={3} mb={0}>
                    <Typography variant="body1" align="center" style={{color: '#2b2936'}}>
                      By continuing, you agree to {props.whiteLabel !== undefined && props.whiteLabel !== null ? "the Canveo" :"our"}&nbsp;<span className="cookieconsent"><RouterLink to="/network/canveo/msa" target="_blank">Terms of Use <FontAwesomeIcon icon={faExternalLinkAlt} style={{fontSize: '12px'}} /></RouterLink></span>
                    </Typography>
                  </Box>
                : ''}
                </Box>
                </>
                :
                expandMessage ?
                <>
                <Typography variant="h5" className={classes.bold} align="center" >Message to counterparty&nbsp;&nbsp;<InformationalTooltip msg={"Send a personal note to your counterparty, which will be included in the informational email to the collaborators.\n\nCanveo premium and enterprise subscribers can define the edit access of the counterparty (unless it is already on the Canveo network).\n\nBoth parties can mark an agreement as \"ready to sign\". This allows the other party to initiate signing until they make another change to the agreement. If there are open issues when the agreement is sent for signing, then these will be assumed as \"accepted\" in the final agreement that will be sent for signing."}/></Typography>

                <Box mb={0} className={classes.fullWidth}>

                    {props.user.role !== 'Counterparty' && ['cust','custE','legalOrg'].includes(props.org.clientType) &&
                    !(props.userIsPrimary && ['Subsidiary','LegalClient'].includes(props.secondaryCpty.entityType)) && !(!props.userIsPrimary && ['Subsidiary','LegalClient'].includes(props.primaryCpty.entityType)) ?
                    <Grid item xs={12}>
                        <Box mb={0} mt={3} align="center">
                            <Box align="left" className={classes.signBox} aria-controls="simple-menu" aria-haspopup="true" onClick={handleEditModeOpen}>
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <Grid item xs={4} sm={2}>
                                        <FontAwesomeIcon icon={editMode === 'none' ? faEye : editMode === 'comment' ? faComment : editMode === 'edit' ? faUserCheck : editMode === 'full' ? faUserEdit : faCaretDown}
                                        style={props.userIsPrimary ? {fontSize: '26px', color: theme.palette.primary.main, margin: '9px 0px 11px 0px'}: {fontSize: '26px', color: theme.palette.secondary.main, margin: '9px 0px 11px 0px'}} />
                                    </Grid>
                                    <Grid item xs={8} sm={10}>
                                        <Typography variant="subtitle2" style={{marginLeft: '0px', lineHeight: 1.3 }}>
                                            <Box display={{ xs: 'none', sm: 'block' }}>
                                            {getEditModeOption(editMode, 'Counterparty', false)}<FontAwesomeIcon icon={faCaretDown} style={{marginLeft: '10px'}} />
                                            </Box>
                                            <Box display={{ xs: 'block', sm: 'none' }}>
                                            {getEditModeOption(editMode, 'Counterparty', true)}<FontAwesomeIcon icon={faCaretDown} style={{marginLeft: '10px'}} />
                                            </Box>
                                            
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleEditModeClose}
                            >
                                <MenuItem onClick={e => handleEditModeSelect('none')} className={classes.menuOption}>{getEditModeOption('none', 'Counterparty')}</MenuItem>
                                <MenuItem onClick={e => handleEditModeSelect('comment')} className={classes.menuOption}>{getEditModeOption('comment', 'Counterparty')}</MenuItem>
                                <MenuItem onClick={e => handleEditModeSelect('edit')} className={classes.menuOption}>{getEditModeOption('edit', 'Counterparty')}</MenuItem>
                                <MenuItem onClick={e => handleEditModeSelect('full')} className={classes.menuOption}>{getEditModeOption('full', 'Counterparty')}</MenuItem>
                            </Menu>
                        </Box>
                    </Grid>
                    : '' }
                    <Grid item xs={12}>
                        <Box mb={0} mt={props.user.role !== 'Counterparty' && ['cust','custE','legalOrg'].includes(props.org.clientType) && 
                        !(props.userIsPrimary && ['Subsidiary','LegalClient'].includes(props.secondaryCpty.entityType)) && !(!props.userIsPrimary && ['Subsidiary','LegalClient'].includes(props.primaryCpty.entityType)) ? 1 : 3} align="center">
                        <Tooltip arrow title={"Once both parties are ready, the agreement can be sent for e-signature."}>
                            <Box align="left" className={classes.signBox}>
                            <FormControlLabel
                                onChange={e => setSign(!sign)}
                                control={<StyledCheckbox checked={sign} color={props.userIsPrimary ? "primary" : "secondary"} />}
                                label={
                                    <Typography variant="subtitle2" style={{marginLeft: sign ? '20px' : '24px', lineHeight: 1.3, color: sign ? '' : '#827f8f'}}>
                                    {(props.userIsPrimary ? props.primaryCpty.shortName : props.secondaryCpty.shortName) + " is "}<span>ready to sign</span>{" this version of the agreement"}
                                    </Typography>}
                                labelPlacement="end" />
                            </Box>
                        </Tooltip>
                        </Box>
                    </Grid>
                    <Box align="center">
                    <Box align="center" mt={props.user.role !== 'Counterparty' ? 1 : 2} mb={0}>

                        <TextareaAutosize
                        className={classes.textAreaAuto}
                        name="addComment"
                        rowsMin={4}
                        rowsMax={15}
                        placeholder="Add a note to your counterparty..."
                        onChange={handleRequestCommentChange}                                        
                        />

                    </Box>
                    {reminders !== null ?
                    <Tooltip arrow title={"Automatic email reminders will be sent until the agreement is returned."}>
                    <Box mt={1} style={{width: '270px'}}>
                        Remind every&nbsp;
                        <InputBase 
                        value={reminders} 
                        className={classes.reminderInput} 
                        inputProps={{style: { textAlign: 'center' }}}
                        onChange={e => handleReminderInput(e.target.value, 'update')}
                        onBlur={e => handleReminderInput(e.target.value, 'blur')}
                        />
                        &nbsp;day{reminders === '1' ? '' : 's'}
                        <IconButton size="small" className={classes.cancelReminders} onClick={e => setReminders(null)}><FontAwesomeIcon icon={faTimes} /></IconButton>
                    </Box>
                    </Tooltip>
                    :''}
                    </Box>

                    {sign && primarySignatories.length === 0 && secondarySignatories.length === 0 ?
                        <Box align="center" mt={3}>
                            <Typography align="center" color="error" variant="subtitle1">Please select at least one signer.</Typography>
                            <Box mt={2}>
                            <Button variant="contained" disableElevation color="primary" onClick={handleBack}>Select Signer(s)</Button>
                            </Box>
                        </Box>
                    : ''}

                    {props.user.role === 'Counterparty' ? 
                      <Box align="left" mt={3} mb={0}>
                        <Typography variant="body1" align="center" style={{color: '#2b2936'}}>
                          By continuing, you agree to {props.whiteLabel !== undefined && props.whiteLabel !== null ? "the Canveo" :"our"}&nbsp;<span className="cookieconsent"><RouterLink to="/network/canveo/msa" target="_blank">Terms of Use <FontAwesomeIcon icon={faExternalLinkAlt} style={{fontSize: '12px'}} /></RouterLink></span>
                        </Typography>
                      </Box>
                      : ''}

                </Box>
                </>
                : '' }

            </Grid>
            }

        </Box>

    : '' }

    {(props.trigger === 'sign' || sign) && blockSigning && cptySignReady ? 
    <Box align="center">
        <Typography color="error" variant="body2">You are no longer able to initiate signing, another user may have made edits.</Typography>
        <Button variant="text" color="secondary" size="small" onClick={e => history.push('/core/' + props.module + '/agreement/' + props.rel._id + '/' + props.agr._id)}>Reload agreement</Button>
    </Box>
    :''}

    </DialogContent>

    <DialogActions className={classes.mlittle}>
        {viewImportLog || addingSignatory !== null ?
        <Button onClick={viewImportLog ? e => setViewImportLog(false) : e => handleSignatoryBackButton()} color="primary" variant="text" style={{marginRight: 'auto'}}><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Back</Button>                            
        :['collab','cpty'].includes(props.trigger) && addingCollaborator !== null ?
        <Button onClick={e => { setAddingCollaborator(null); setSelectIntegrationUsers(null); }} color="primary" variant="text" style={{marginRight: 'auto'}}><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Back</Button>
        :['collab','cpty'].includes(props.trigger) && deletingCollaborator !== null ?
        <Button onClick={e => setDeletingCollaborator(null)} color="primary" variant="text" style={{marginRight: 'auto'}}><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Back</Button>
        :props.trigger === "cpty" && (!expandCollab || expandSignatories || expandMessage) ?
        <Button disableElevation color="primary" variant="text" onClick={handleBack} style={{marginRight: 'auto'}}>
            <FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Back
        </Button>
        :props.primaryCpty !== undefined && props.primaryCpty !== null && props.secondaryCpty !== undefined && props.secondaryCpty !== null &&
        (props.primaryCpty.entityType !== 'Subsidiary' || props.secondaryCpty.entityType !== 'Subsidiary') &&
        ['collab','cpty'].includes(props.trigger) ?
        <Box style={{marginRight: 'auto'}}>
            <Button color={
                props.org !== undefined && ((['legalOrg'].includes(props.org.clientType) && props.primaryCpty.orgID === props.org._id) ||
                (['cpty'].includes(props.org.clientType) && props.primaryCpty.parentOrgID === props.org._id)) ? 
                    "primary" :
                props.org !== undefined && ((['legalOrg'].includes(props.org.clientType) && props.secondaryCpty.orgID === props.org._id) ||
                (['cpty'].includes(props.org.clientType) && props.secondaryCpty.parentOrgID === props.org._id)) ? 
                    "secondary" :
                props.secondaryCpty.entityType !== 'Subsidiary' ? "secondary" : "primary"} variant="text" disableElevation 
                onClick={e => setAddingCollaborator(
                    props.org !== undefined && ((['legalOrg'].includes(props.org.clientType) && props.primaryCpty.orgID === props.org._id) ||
                    (['cpty'].includes(props.org.clientType) && props.primaryCpty.parentOrgID === props.org._id)) ? 
                        "primary" :
                    props.org !== undefined && ((['legalOrg'].includes(props.org.clientType) && props.secondaryCpty.orgID === props.org._id) ||
                    (['cpty'].includes(props.org.clientType) && props.secondaryCpty.parentOrgID === props.org._id)) ? 
                        "secondary" :
                    props.secondaryCpty.entityType !== 'Subsidiary' ? "secondary" : "primary")}>
                <Box display={{ xs: 'none', sm: 'block' }}><FontAwesomeIcon icon={faUserPlus} />&nbsp;&nbsp;</Box>Create<Box display={{ xs: 'none', sm: 'block' }}>&nbsp;collaborator</Box>
            </Button>
            {props.user.role !== 'Counterparty' && (cptyUsers.length > 0 || (props.org !== undefined && props.org.clientType === 'legalOrg' && ownUsers.some((ou) => ou.role.startsWith("ClientFor_")))) ? 
            <Button color={props.secondaryCpty.entityType !== 'Subsidiary' ? "secondary" : "primary"} variant="text" style={{marginLeft: '3px'}} disableElevation onClick={e => setDeletingCollaborator(props.secondaryCpty.entityType !== 'Subsidiary' ? "secondary" : "primary")}>
                <Box display={{ xs: 'none', sm: 'block' }}><FontAwesomeIcon icon={faUserTimes} />&nbsp;&nbsp;</Box>Revoke<Box display={{ xs: 'none', sm: 'block' }}>&nbsp;access</Box>
            </Button>
            : ''}
            {importLog.length > 0 ?
            <Button color={props.secondaryCpty.entityType !== 'Subsidiary' ? "secondary" : "primary"} variant="text" 
            style={importLog.some((il) => il.startsWith("Error")) ? {marginLeft: '3px', color: theme.palette.error.main} : {marginLeft: '3px', color: theme.palette.success.main}} disableElevation onClick={e => setViewImportLog(true)}>
                <Box display={{ xs: 'none', sm: 'block' }}><FontAwesomeIcon icon={importLog.some((il) => il.startsWith("Error")) ? faExclamationTriangle : faCheck} />&nbsp;&nbsp;Import&nbsp;</Box>Log
            </Button>
            :''}
        </Box>
        : ''}

        {expandSignatories && ['cust', 'custE', 'legalOrg'].includes(props.org.clientType) ?
        <FormControlLabel 
            control={<Checkbox size="small" color="primary" checked={signingOrder} onChange={handleSigningOrderChange} />}
            disabled={!['cust', 'custE', 'legalOrg'].includes(props.org.clientType)} 
            label={<Typography variant="subtitle2">Signing order</Typography>}
        />
        :''}

        {props.trigger === "retrieve" ?
        <Button disableElevation color="primary" variant="contained" onClick={confirmRetrieve} disabled={requestComment.length < 5}>
            Retrieve&nbsp;&nbsp;<FontAwesomeIcon icon={faUndo} />
        </Button>
        :
         props.trigger === "export" ?
        <Button disableElevation color="primary" variant="contained" onClick={e=> generateDoc(docType)} disabled={loading}>
            Download&nbsp;&nbsp;<FontAwesomeIcon icon={faDownload} />
        </Button>
        :props.trigger === "collab" && !addingCollaborator ?
        <Button disableElevation color="primary" variant="contained" onClick={handleAddCollabFlow}
        disabled={!collabUpdate}>
            Update collaborators
        </Button>
        :props.trigger === "sign" && cptySignReady ? 
        <Button disableElevation color="primary" variant="contained" onClick={handleSendToCpty}
        disabled={((primarySignatories.length === 0 && secondarySignatories.length === 0) || addingSignatory || loading || blockSigning)}>
            Initiate signing&nbsp;&nbsp;<FontAwesomeIcon icon={faSignature} />
        </Button>
        :!['retrieve', 'offlinesigning'].includes(props.trigger) && !expandMessage ?
        <Button disableElevation color="primary" variant="contained" onClick={handleNext}
            disabled={addingCollaborator !== null || addingSignatory !== null || deletingCollaborator !== null ||
                    (expandCollab && (primaryCollaborators.length < 1 || secondaryCollaborators.length < 1))}>Next&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} /></Button>
        :!['retrieve', 'offlinesigning'].includes(props.trigger) ?
        <>
        {reminders === null && ['cust','custE','legalOrg'].includes(props.org.clientType) ?
        <Button disableElevation color="primary" variant="text" onClick={e => setReminders(7)}>
            <FontAwesomeIcon icon={faClock} />&nbsp;&nbsp;Reminders
        </Button>
        :''}&nbsp;
        <Button disableElevation color="primary" variant="contained" onClick={handleSendToCpty}
                disabled={loading || primaryCollaborators.length < 1 || secondaryCollaborators.length < 1 ||
                    (sign && ((primarySignatories.length === 0 && secondarySignatories.length === 0) ||  
                              (blockSigning && cptySignReady)))}>
            {sign && cptySignReady ? <>Initiate signing&nbsp;&nbsp;<FontAwesomeIcon icon={faSignature} /></> : <>Send<Box display={{ xs: 'none', sm: 'block' }}>&nbsp;to counterparty</Box>&nbsp;&nbsp;<FontAwesomeIcon icon={faPaperPlane} /></>}
            {loading && !(sign && cptySignReady) ? <CircularProgress size={20} disableShrink /> : ''}
        </Button>
        </>
        :props.trigger === 'offlinesigning' && offlineSigned !== null && offlineSigned.selected ? 
        <Button disableElevation color="primary" variant="contained" onClick={['newav'].includes(offlineSigned.radioDigitization) && iDoc === null ? handleTriggerImport : handleSubmitOfflineSigned}    
            disabled={offlineSigned.file === undefined || offlineSigned.file === null || 
                offlineSigned.radioDigitization === undefined || offlineSigned.radioDigitization === null} >
                    {['newav'].includes(offlineSigned.radioDigitization) && iDoc === null ? "Proceed to Import Screen" : "Submit"}&nbsp;&nbsp;<FontAwesomeIcon icon={faUpload} /></Button>
        : '' }
    </DialogActions>
    </Dialog>

    <DialogImportDoc
        importOpen={importOpen}
        handleImportClose={e => setImportOpen(false)}
        handleFinalizeImport={handleFinalizeImport}
        agrTypes={props.agrTypes}
        relType={props.rel !== null ? props.rel.relationshipType : ''}
        orgAgrTemplates={[]}
        org={props.org}
        user={props.user}
        relevantSection={""}
        action={"new-version"}
    />

  </div>)
}

export default DialogAgrAction