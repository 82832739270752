function getHtmlForClause(clause) {

    let s = ''
    let title = null

    if(clause.clauseCat === 'table') {
        let pDelCpty = clause.pendingChange !== undefined && clause.pendingChange.length > 0 && clause.pendingChange.filter((pc) => pc.status === 'Open' && ['proposedDel'].includes(pc.type))[0] !== undefined ?
            clause.pendingChange.filter((pc) => pc.status === 'Open' && ['proposedDel'].includes(pc.type))[0].changeOrg : null

        // opening the main div - TABLE
        s = s + '<div style="margin: 0px; font-size: 14px; color: #4d4b59; text-align: center; width: 100%;">';

        if(title !== null) { s = s + title }

        s = s + '<table style="border-collapse: collapse; border: 1px solid #e0e0e0;" align="center">' // start of the table

        if(!clause.clauseObject.invisibleHead) {
            s = s + '<tr>' // start of the table head
            clause.clauseObject.head.forEach((h) => {
                s = s + '<td' + tableChangeStyle(h.change, pDelCpty) + '><b>' + 
                    (h.change !== undefined && h.change !== null && h.change.type !== undefined && h.change.type === 'change' &&
                    h.change.oldVal !== undefined && h.change.oldVal !== null && h.change.oldVal !== '' ?
                        '<span style="text-decoration: line-through; margin-right: 5px">' + h.change.oldVal + '</span>' : '') + 
                    h.value + '</b></td>'
            })
            s = s + '</tr>' // end of the table head    
        }

        clause.clauseObject.rows.forEach((row) => { // For every table row
            s = s + '<tr>' // start of the table row

            row.cols.forEach((col) => { // for every column in the table row
                s = s + '<td ' + tableChangeStyle(col.change, pDelCpty) + '>' + 
                    (col.change !== undefined && col.change !== null && col.change.type !== undefined && col.change.type === 'change' &&
                    col.change.oldVal !== undefined && col.change.oldVal !== null && col.change.oldVal !== ''  ?
                        '<span style="text-decoration: line-through; margin-right: 5px">' + col.change.oldVal + '</span>' : '') + 
                    col.value + '</td>'
            })

            s = s + '</tr>' // end of the table row
        })

        s = s + '</table>' // end of the table

    } else {

        // opening the main div - REGULAR CLAUSE
        s = s + '<div style="margin: 0px; padding: 20px 30px 20px 30px; border: 1px solid #e0e0e0; border-radius: 10px; font-size: 14px; color: #4d4b59; text-align: left;">';


        //if(title !== null) { s = s + title }

        // insert the clause text, based on blocks being broken down with it's respective redlines
        let emap = clause.blocks.entityMap
        clause.blocks.blocks.forEach((b) => {

            s = s + '<p>' + 
                (b.type === 'secTitle' ? '<b style="font-size: 24px;">' :
                 b.type === 'ssecTitle' ? '<b style="font-size: 20px;">' :
                 b.type === 'clauseTitle' ? '<b>' : '');

            if(b.entityRanges === undefined || b.entityRanges.length === 0){
                s = s + regularSpan(b.text)
            } else {
                let cursor = 0;
                b.entityRanges
                .sort((a,b) => (a.offset > b.offset) ? 1 : ((b.offset > a.offset) ? -1 : 0))
                .forEach((er, i) => { // loop through all entityRanges (key, length, offset) - key refers to emap, e.g. emap[key].type gives 'PROPOSEDADD'

                    // Anything from cursor, till current offset - regularSpan
                    s = s + regularSpan(b.text.substring(cursor, er.offset));

                    // Anything for current entity, assign appropriate span
                    s = s + redlineSpan(b.text.substring(er.offset, er.offset + er.length), emap[er.key].type)

                    // Re-assign cursor
                    cursor = er.offset + er.length;

                })
                // RegularSpan for anything after the last entity
                if(b.text.length > cursor) {
                    s = s + regularSpan(b.text.substring(cursor, b.text.length));
                }
            }

            s = s + (['secTitle','ssecTitle','clauseTitle'].includes(b.type) ? '</b>' : '') + '</p>';

        })
    }

    // closing the main div
    s = s + '</div>';
    
    return s

}

function regularSpan(string){
    let ss = '<span>' + string + '</span>';
    return ss
}

function redlineSpan(string, type){
    let ss = ''
    let color = ['PROPOSEDADD', 'PROPOSEDDEL', 'REJECTPADD', 'REJECTPDEL'].includes(type) ? '#7243dd' : '#E80064'
    let cpColor = color === '#7243dd' ? '#E80064' : '#7243dd' 
        
    if(['PROPOSEDADD', 'PROPOSEDADDSECONDARY'].includes(type)) {
        ss = '<span style="color: ' + color + '; border-bottom: 1px dotted ' + color + '; padding: 2px 0">' + string + '</span>';
    } else if(['PROPOSEDDEL', 'PROPOSEDDELSECONDARY'].includes(type)) {
        ss = '<span style="color: ' + color + '; text-decoration: line-through; padding: 2px 0">' + string + '</span>'
    } else if(['REJECTPADD', 'REJECTPADDSEC'].includes(type)){
        ss = `<span style="color: ` + cpColor + `; text-decoration: line-through;">
              <span style="color: ` + color + `; padding: 2px 0">
              ` + string + `
              </span></span>`;     
    } else if(['REJECTPDEL', 'REJECTPDELSEC'].includes(type)){
        ss = '<span style="border-bottom: 1px dotted ' + cpColor + '; padding: 2px 0">' + string + '</span>'
    }

    return ss
}

function tableChangeStyle(change, pDelCpty) {
    let changeStyle = 
        // Insertion by cpty
        pDelCpty === null && change !== undefined && change !== null && change.cpty !== undefined && ['primary', 'secondary'].includes(change.cpty) &&
        ['insertTable', 'insertCol', 'insertRow', 'change'].includes(change.type) ? 
            (' style="color: ' + (change.cpty === 'primary' ? '#7243dd;' : '#E80064;') + 'padding: 2px 5px 2px 5px; border: 1px solid #e0e0e0; text-align: left;"') :
        // Proposed deletion by cpty
        pDelCpty !== null || (change !== undefined && change !== null && change.cpty !== undefined && ['primary', 'secondary'].includes(change.cpty) &&
        ['deleteCol', 'deleteRow'].includes(change.type)) ? 
            (' style="color: ' + (pDelCpty === 'primary' || change.cpty === 'primary' ? '#7243dd;' : '#E80064;') + 'padding: 2px 5px 2px 5px; border: 1px solid #e0e0e0; text-decoration: line-through; text-align: left;"') :
            (' style="padding: 2px 5px 2px 5px; border: 1px solid #e0e0e0; text-align: left;"')

    return changeStyle
}

export { getHtmlForClause }