import React from 'react';
import { Box, Fab, Menu, MenuItem, Tooltip,
         makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck, faCheckDouble, faEllipsisH } from "@fortawesome/free-solid-svg-icons";

const RedlineControls = (props) => {

    const useStyles = makeStyles(theme => ({
        fab1: {
            [theme.breakpoints.down('sm')]: { right: '15px', bottom: '270px', },
            [theme.breakpoints.up('md')]: { right: '30px', bottom: '281px', },
            margin: 0,
            top: 'auto',
            left: 'auto',
            position: 'fixed',
            fontSize: '13px',
            fontWeight: '700',
            zIndex: 1000,
            backgroundColor: theme.palette.success.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.success.light,
            },
        },
        fab2: {
            [theme.breakpoints.down('sm')]: { right: '15px', bottom: '220px', },
            [theme.breakpoints.up('md')]: { right: '30px', bottom: '231px', },
            margin: 0,
            top: 'auto',
            left: 'auto',
            position: 'fixed',
            fontSize: '13px',
            fontWeight: '700',
            zIndex: 1000,
            backgroundColor: theme.palette.error.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.error.light,
            },
        },
        fab2accept: {
            [theme.breakpoints.down('sm')]: { right: '15px', bottom: '220px', },
            [theme.breakpoints.up('md')]: { right: '30px', bottom: '231px', },
            margin: 0,
            top: 'auto',
            left: 'auto',
            position: 'fixed',
            fontSize: '13px',
            fontWeight: '700',
            zIndex: 1000,
            backgroundColor: theme.palette.success.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.success.light,
            },
        },
        fab3: {
            [theme.breakpoints.down('sm')]: { right: '15px', bottom: '174px', },
            [theme.breakpoints.up('md')]: { right: '30px', bottom: '185px', },
            margin: 0,
            top: 'auto',
            left: 'auto',
            position: 'fixed',
            fontSize: '13px',
            fontWeight: '700',
            zIndex: 1000,
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.grey[600],
            '&:hover': {
                backgroundColor: theme.palette.grey[100],
            },
        },
        fab4: {
            [theme.breakpoints.down('sm')]: { right: '15px', bottom: '128px', },
            [theme.breakpoints.up('md')]: { right: '30px', bottom: '139px', },
            margin: 0,
            top: 'auto',
            left: 'auto',
            position: 'fixed',
            fontSize: '13px',
            fontWeight: '700',
            zIndex: 1000,
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.grey[600],
            '&:hover': {
                backgroundColor: theme.palette.grey[100],
            },
        },
        fab5: {
            [theme.breakpoints.down('sm')]: { right: '15px', bottom: '78px', }, /* right: '14px', bottom: '78px', */
            [theme.breakpoints.up('md')]: { right: '30px', bottom: '93px', }, /* right: '24px', bottom: '83px', */
            margin: 0,
            top: 'auto',
            left: 'auto',
            position: 'fixed',
            fontSize: '13px',
            fontWeight: '700',
            zIndex: 1000,
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.grey[600],
            '&:hover': {
                backgroundColor: theme.palette.grey[100],
            },
        },
        iconSuc: {
            color: theme.palette.success.main,
            paddingRight: '14px',
        },
        iconErr: {
            color: theme.palette.error.main,
            paddingRight: '16px',
            paddingLeft: '2px'
        },
        mainSpan: {
            display: 'block', 
            margin: '0px', 
            padding: '0px', 
            lineHeight: '16px'
        },
        hintSpan: {
            display: 'block',
            fontSize: '11px', 
            fontWeight: '400', 
            margin: '0px', 
            padding: '0px', 
            lineHeight: '14px'
        },
        fabexit: {
            [theme.breakpoints.down('sm')]: { width: '50px', height: '50px', bottom: '20px', right: '10px', },
            [theme.breakpoints.up('md')]: { width: '60px', height: '60px', bottom: '20px', right: '20px', },
            margin: 0,
            top: 'auto',
            left: 'auto',
            position: 'fixed',
            zIndex: 2000,
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.grey[600],
            '&:hover': {
                backgroundColor: theme.palette.grey[100],
            },
        },
    }));
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleAcceptRejectAllClick = (type) => {
        handleMenuClose()
        props.click(type)
    }

    const handleMenuOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
    <div>
        {['redline', 'redlineMnml', 'redlineLtdAccept', 'redlineLtdReject'].includes(props.type) ?
        <>
            <Box>
                {!['redlineMnml','redlineLtdReject'].includes(props.type) && !Boolean(anchorEl) ?
                <Fab size="small" variant="extended" className={props.type === 'redlineLtdAccept' ? classes.fab2accept : classes.fab1} 
                style={{width: '125px', height: '38px'}} onClick={e => props.click('accept')}>
                    <Box>
                        <span className={classes.mainSpan}>Accept Change</span>
                        {props.hint !== null && props.hint.aHint !== null ?
                        <span className={classes.hintSpan}>{props.hint.aHint}</span>
                        : ''}
                    </Box>
                </Fab>
                : '' }

                {!['redlineMnml','redlineLtdAccept'].includes(props.type) && !Boolean(anchorEl) ?
                <Fab size="small" variant="extended" className={classes.fab2} 
                style={{width: '125px', height: '38px'}} onClick={e => props.click('reject')}>
                    <Box>
                        <span className={classes.mainSpan}>Reject Change</span>
                        {props.hint !== null && props.hint.rHint !== null ?
                        <span className={classes.hintSpan}>{props.hint.rHint}</span>
                        : ''}
                    </Box>
                </Fab>
                : '' }

                {!['redlineMnml'].includes(props.type) ? 
                <>
                <Fab size="small" variant="extended" className={classes.fab3} style={{width: '125px'}}onClick={handleMenuOpen}>
                    More&nbsp;&nbsp;<FontAwesomeIcon icon={faEllipsisH} />
                </Fab>
                <Menu
                id="clausemore-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                >
                    {!props.templating ?
                    <MenuItem onClick={e => handleAcceptRejectAllClick('acceptagr')} disabled={props.editMode !== 'full'}>
                        <FontAwesomeIcon icon={faCheckDouble} className={classes.iconSuc} />Accept agreement changes
                    </MenuItem>
                    : '' }
                    
                    <MenuItem onClick={e => handleAcceptRejectAllClick('acceptall')} disabled={props.editMode !== 'full'}>
                        <FontAwesomeIcon icon={faCheck} className={classes.iconSuc} />Accept clause changes
                    </MenuItem>

                    {!props.isTable ?
                    <MenuItem onClick={e => handleAcceptRejectAllClick('rejectall')} disabled={props.editMode !== 'full'}>
                        <FontAwesomeIcon icon={faTimes} className={classes.iconErr} />Reject clause changes
                    </MenuItem>
                    :''}
                </Menu>
                </>
                : '' }

                {!['redlineMnml'].includes(props.type) && !Boolean(anchorEl) ? 
                <Fab size="small" variant="extended" className={classes.fab4} style={{width: '125px'}} onClick={e => props.click('left')}>
                    Previous Change
                </Fab>
                : '' }

                {!Boolean(anchorEl) ? 
                <Fab size="small" variant="extended" className={classes.fab5} style={{width: '125px'}} onClick={e => props.click('right')}>
                    Next Change
                </Fab>
                : '' }


                {props.templating ? // This deactivates the clause
                <Tooltip title={"Close to continue"} placement="left">
                    <Fab color={"secondary"} className={classes.fabexit} onClick={e => props.click('toggleActivateClause')} variant={"round"}>
                        <FontAwesomeIcon icon={faTimes} />
                    </Fab>
                </Tooltip>
                :''}
            </Box>
        </>
        : '' }
    </div>
    );
}

export default RedlineControls