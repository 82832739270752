import React, { useEffect } from 'react';
import theme  from '../../theme/theme';
import { trunc } from '../../utils';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useHistory } from 'react-router-dom';
import { FixedSizeList } from 'react-window';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Tooltip, 
         Legend, Filler, ArcElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar, Line, Doughnut } from 'react-chartjs-2';
import { CSVLink } from "react-csv";
import { Avatar, Box, Button, Chip, Collapse, Container, FormControl, FormControlLabel, Grid, IconButton, 
         MenuItem, Radio, RadioGroup, Select, Switch, TextField, Typography,
         makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DrawerCore, Header, SkeletonCustom } from '../../components';
import { faChevronDown, faChevronUp, faArrowRight, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import defaultLogo from '../../assets/img/defaultlogo.png';

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Tooltip, Legend, 
                 Filler, ArcElement, ChartDataLabels);

const axios = require('axios');

function Analytics(props) {

  const useStyles = makeStyles(theme => ({
    root: { // mainDiv
      display: 'flex',
    },
    outerFlexGrid: {
        minHeight: '80vh',
        paddingTop: '100px',
        paddingBottom: '20px',
    },
    header: {
        zIndex: 1400
    },
    chartBox1Smaller: {
        position: 'relative', 
        [theme.breakpoints.only('xs')]: { width: '240px' },
        [theme.breakpoints.only('sm')]: { width: '360px' },
        [theme.breakpoints.up('md')]: { width: '610px' },
    },
    chartBox1: {
        position: 'relative', 
        [theme.breakpoints.only('xs')]: { width: '300px' },
        [theme.breakpoints.only('sm')]: { width: '560px' },
        [theme.breakpoints.only('md')]: { width: '810px' },
        [theme.breakpoints.up('lg')]: { width: '960px' },
    },
    selectPillActive: {
        [theme.breakpoints.only('xs')]: { padding: '5px 10px 5px 10px', fontSize: '12px' },
        padding: '5px 20px 5px 20px',
        borderRadius: '0px',
        margin: '0px',
        fontWeight: '600',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.primary.contrastText,
        }
    },
    selectPillInactive: {
        [theme.breakpoints.only('xs')]: { padding: '5px 10px 5px 10px', fontSize: '12px' },
        padding: '5px 20px 5px 20px',
        borderRadius: '0px',
        margin: '0px',
        fontWeight: '600',
        backgroundColor: '#f6f6f6',
        color: theme.palette.grey[600],
        '&:hover': {
          backgroundColor: '#e0e0e0',
          color: theme.palette.grey[600],
        }
    },
    chipFont: {
        padding: '5px', 
        fontWeight: '400',
        fontSize: '13px',
    },
    bold: {
        fontWeight: 700,
    },
    statusFont: {
        fontWeight: 700,
        color: '#ffffff', 
        padding: '1px 3px 1px 3px',
    },
    hoverGrid: {
        '&:hover': {
            backgroundColor: '#f6f6f6',
        }
    }
  }));
  const classes = useStyles();

  const history = useHistory();

  const [loading, setLoading] = React.useState(true)
  const [advancedSettings, setAdvancedSettings] = React.useState(false)
  const [page, setPage] = React.useState('agreements')
  //const [page, setPage] = React.useState('smartfields')
  const [currentChart, setCurrentChart] = React.useState('agrStatus')
  //const [currentChart, setCurrentChart] = React.useState('smartTopics')
  const [period, setPeriod] = React.useState('monthly')
  //const [period, setPeriod] = React.useState('quarterly')
  const [includeExhibits, setIncludeExhibits] = React.useState(false)
  const [subsSelected, setSubsSelected] = React.useState(props.subs.filter((s) => s.isLive))
  const [orgLabelsSelected, setOrgLabelsSelected] = React.useState(null) // Filter that applies on the chart level (ie. the Autcomplete)
  const [agrTypesSelected, setAgrTypesSelected] = React.useState([])
  const [chartLabels, setChartLabels] = React.useState(null)
  const [chartDatasets, setChartDatasets] = React.useState(null)
  const [allAgrs, setAllAgrs] = React.useState([])
  const [filteredAgrs, setFilteredAgrs] = React.useState([])
  const [periodFilter, setPeriodfilter] = React.useState(null)
  const [statusFilter, setStatusFilter] = React.useState(null)
  const [labelFilter, setLabelFilter] = React.useState(null) // Filter that applies on the virtualized table (ie. the "chip")
  const [typeFilter, setTypeFilter] = React.useState(null)
  const [relevantRels, setRelevantRels] = React.useState(null)
  const [orgLabelLinks, setOrgLabelLinks] = React.useState(null)
  const [orgSmartFields, setOrgSmartFields] = React.useState(null)
  const [triggerUpdate, setTriggerUpdate] = React.useState(0)
  const [activeOrgSmartField, setActiveOrgSmartField] = React.useState(null)
  const [periods, setPeriods] = React.useState(null)
  const [periodFilterStart, setPeriodFilterStart] = React.useState(null)
  const [periodFilterEnd, setPeriodFilterEnd] = React.useState(null)
  const [thresholdReached, setThresholdReached] = React.useState(false)
  const [csv, setCsv] = React.useState(null)

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

  useEffect(() => { // (1) Initialize RelevantRels (2) pull all orgLabelLinks (3) pull all orgSmartFields
    
    let unmounted = false;

    if(!props.isLoading && props.orgLabel !== undefined && props.orgLabel !== null && orgLabelLinks === null && 
    props.rels !== undefined && props.rels.length > 0 && props.user !== undefined && props.user.orgID !== undefined &&
    props.module !== undefined) {

        let rrels = []
        let olFilter = props.orgLabel.filter((ol) => !ol.deactive && ol.modules.includes(props.module))

        props.rels
        .filter((r) => !r.deactive && (
            (['sales'].includes(props.module) && r.relationshipType === 'b2b' && r.primaryOrgID === props.user.orgID) ||
            (['procurement'].includes(props.module) && r.relationshipType === 'b2b' && r.secondaryOrgID === props.user.orgID) ||
            (['partnerships'].includes(props.module) && r.relationshipType === 'partnership') ||
            (['people', 'corporate'].includes(props.module) && r.relationshipType === props.module)))
        .forEach((r) => { rrels.push(r._id) })

        axios.post('/orglabel/orglabellinkall/' + props.user.orgID, { // Pull orgLabelLinks
            orgLabels: olFilter,
            rels: rrels,
        })
        .then((res) => {

            axios.get('/sf/osfs/' + props.user.orgID + '/' + props.module) // Pull orgSmartFields
            .then((resOSF) => {


                let monthIdx = months.findIndex((mo) => mo === months[new Date().getMonth()])

                let startPeriod = months[monthIdx] + "-" + (new Date().getFullYear() - 2)
                let endPeriod = months[monthIdx] + "-" + (new Date().getFullYear())
                let years = [new Date().getFullYear() - 2, new Date().getFullYear() - 1, new Date().getFullYear()]
                let ps = []

                years.forEach((y, i) => {
                    let moArray = i === 0 ? months.slice(monthIdx) : i + 1 === years.length ? months.slice(0, monthIdx + 1) : months
                    moArray.forEach((m) => { ps.push(m + "-" + y) })
                })

                if(!unmounted) {
                    setPeriods(ps)
                    setPeriodFilterStart(startPeriod)
                    setPeriodFilterEnd(endPeriod)
                    
                    setOrgSmartFields(resOSF.data.data)
                    setRelevantRels(rrels)
                    setOrgLabelLinks(res.data.data)
                    setOrgLabelsSelected(olFilter.slice(0,5))    
                }

            }).catch((err) => { console.log(err) })
        }).catch((err) => { console.log(err) })
    }

    return () => { unmounted = true };

  }, [orgLabelLinks])

  useEffect(() => { // pull all open tickets created by or assigned to the current user
    
    let unmounted = false;

    if(['agreements', 'time', 'cycles', 'smartfields'].includes(page) && subsSelected !== undefined && subsSelected.length > 0 && 
    props.user !== undefined && props.user.orgID !== undefined && relevantRels !== null && orgLabelLinks !== null) {

        let ents = [], rels = []
        subsSelected.forEach((s) => { ents.push(s._id) })
        
        relevantRels
        .filter((r) => !['agrLabel','timeLabel','cyclesLabel'].includes(currentChart) || orgLabelLinks.some((o) => o.relID === r))
        .forEach((r) => { rels.push(r) })

        axios.post(
            ['agreements', 'cycles', 'smartfields'].includes(page) ?
                '/agr/agrsanalytics' :
            ['time'].includes(page) ?
                '/logging/protect/timeanalytics' :
                '/agr/agrsanalytics',
            ['agreements', 'cycles', 'smartfields'].includes(page) ?
                { 
                    entities: ents, 
                    rels: rels, 
                    page: page,
                    currentChart: currentChart,
                    includeExhibits: includeExhibits,                     
                    osfref: 
                        activeOrgSmartField !== undefined && activeOrgSmartField !== null && ['smartFields'].includes(currentChart) ? 
                            activeOrgSmartField._id : 
                        activeOrgSmartField !== undefined && activeOrgSmartField !== null && ['smartTopics'].includes(currentChart) ?
                            activeOrgSmartField.defaultValue1 :
                            null,
                } :
            ['time'].includes(page) ?
                { orgID: props.user.orgID, rels: rels, isRole: currentChart === 'timeRole', isType: currentChart === 'timeType', isLabel: currentChart === 'timeLabel' } :
                { entities: ents, rels: rels, includeExhibits: includeExhibits, isExpiry: currentChart === 'agrExpiry' })
        .then((res) => {
            let agrs = res.data.data.agrs; // relevant for Agreements
            let tReached = res.data.data.thresholdReached;
            let lastExpiryDate = new Date(res.data.data.lastExpiryDate); // relevant for Agreements
            let times = res.data.data.times; // relevant for Time

            let loopDate = new Date();

            let cLabels = [], startedPushing = false;
            let yrs = 
                ['smartfields'].includes(page) && ['smartFields'].includes(currentChart) ?
                    [] :
                ['agrExpiry'].includes(currentChart) ?
                    [loopDate.getFullYear() - 2, loopDate.getFullYear() - 1, loopDate.getFullYear(), loopDate.getFullYear() + 1, loopDate.getFullYear() + 2, loopDate.getFullYear() + 3, loopDate.getFullYear() + 4, loopDate.getFullYear() + 5] : 
                    [loopDate.getFullYear() - 2, loopDate.getFullYear() - 1, loopDate.getFullYear()]
            let subyears = period === 'monthly' ? [1,2,3,4,5,6,7,8,9,10,11,12] : ['Q1','Q2','Q3','Q4']
            let tempLoopDate = ['agrExpiry'].includes(currentChart) ? lastExpiryDate : loopDate;
            let cursy = period === 'monthly' ? tempLoopDate.getMonth() + 1 : 
                [0,1,2].includes(tempLoopDate.getMonth()) ? 'Q1' :
                [3,4,5].includes(tempLoopDate.getMonth()) ? 'Q2' :
                [6,7,8].includes(tempLoopDate.getMonth()) ? 'Q3' :
                [9,10,11].includes(tempLoopDate.getMonth()) ? 'Q4' : 'Q1'

            let cDatasets = []

            if(['agreements'].includes(page) && ['agrStatus', 'agrExpiry'].includes(currentChart)) {
                cDatasets = [
                    {
                        label: 'Draft',
                        backgroundColor: getColor('Draft', true),
                        borderWidth: 0,
                        data: []
                    },
                    {
                        label: 'Review',
                        backgroundColor: getColor('Review', true),
                        borderWidth: 0,
                        data: []
                    },
                    {
                        label: 'Negotiation',
                        backgroundColor: getColor('Negotiation', true),
                        borderWidth: 0,
                        data: []
                    },
                    {
                        label: 'Execution',
                        backgroundColor: getColor('Execution', true),
                        borderWidth: 0,
                        data: []
                    },
                    {
                        label: 'InForce',
                        backgroundColor: getColor('InForce', true),
                        borderWidth: 0,
                        borderRadius: {
                            topLeft: includeExhibits ? 0 : 5,
                            topRight: includeExhibits ? 0 : 5,
                        },
                        data: []
                    },
                    {
                        label: 'Exhibit',
                        backgroundColor: getColor('Exhibit', true),
                        borderWidth: 0,
                        borderRadius: {
                            topLeft: 5,
                            topRight: 5,
                        },
                        data: []
                    }
                ]
            } else if (['agreements'].includes(page) && ['agrType'].includes(currentChart)) {
                let aTypes = [...new Set(agrs.map(item => item.agrTypeID))];
                
                aTypes.filter((at) => at !== undefined && at !== null && at !== '').forEach((at, i) => {
                    if(props.agrTypes.filter((a) => a._id === at)[0] !== undefined) {
                        cDatasets.push({
                            label: props.agrTypes.filter((a) => a._id === at)[0].shortName,
                            agrTypeID: at,
                            backgroundColor: getColor('Random_' + i, true),
                            borderWidth: 0,
                            data: []
                        })
                    }
                })

            } else if(['agreements', 'time'].includes(page) && ['agrLabel', 'timeLabel'].includes(currentChart)) {

                orgLabelsSelected.forEach((olf, i) => {
                    cDatasets.push({
                        label: olf.name,
                        orgLabelID: olf._id,
                        backgroundColor: getColor('Random_' + i, true),
                        borderWidth: 0,
                        data: []
                    })
                })

                if(['timeLabel'].includes(currentChart)) {
                    times.forEach((t, i) => {
                        times[i].item = orgLabelLinks.filter((oll) => oll.relID === t.rid)[0] !== undefined ? 
                            orgLabelLinks.filter((oll) => oll.relID === t.rid)[0].orgLabelID : null;
                    })

                }

            } else if(['time'].includes(page) && ['timeRole', 'timeType'].includes(currentChart)) {
                
                let categories = [] // roles or agrTypes

                times.forEach((t, i) => {
                    let item = 
                        ['timeRole'].includes(currentChart) && props.users.filter((u) => u._id === t.uid)[0] !== undefined && 
                        props.users.filter((u) => u._id === t.uid)[0].role !== undefined && props.users.filter((u) => u._id === t.uid)[0].role.startsWith("ClientFor_") ?
                            "Client User" :
                        ['timeRole'].includes(currentChart) && props.users.filter((u) => u._id === t.uid)[0] !== undefined && 
                        props.users.filter((u) => u._id === t.uid)[0].role !== undefined ?
                            props.users.filter((u) => u._id === t.uid)[0].role : 
                        ['timeType'].includes(currentChart) && props.agrTypes.filter((at) => at._id === t.agrTypeID)[0] !== undefined &&
                        props.agrTypes.filter((at) => at._id === t.agrTypeID)[0].shortName !== undefined ?
                            props.agrTypes.filter((at) => at._id === t.agrTypeID)[0].shortName :
                            null;
                        
                    if(item !== undefined && item !== null) {
                        if(['timeRole'].includes(currentChart) &&
                        props.users.filter((u) => u._id === t.uid)[0] !== undefined && !categories.some((c) => c === item)) {
                            categories.push(
                                props.users.filter((u) => u._id === t.uid)[0].role.startsWith("ClientFor_") ?
                                    "Client User" :
                                    props.users.filter((u) => u._id === t.uid)[0].role)
                        } else if(['timeType'].includes(currentChart) &&
                        props.agrTypes.filter((at) => at._id === t.agrTypeID)[0] !== undefined && !categories.some((c) => c === item)) {
                            categories.push(props.agrTypes.filter((at) => at._id === t.agrTypeID)[0].shortName)
                        }
                        times[i].item = item;
                    }
                })

                categories.forEach((r,i) => {
                    cDatasets.push({
                        label: r,
                        backgroundColor: getColor('Random_' + i, true),
                        borderWidth: 0,
                        borderRadius: {
                            topLeft: i + 1 === categories.length ? 5 : 0,
                            topRight: i + 1 === categories.length ? 5 : 0,
                        },
                        data: []
                    })
                })
                
            } else if(['time'].includes(page) && ['timeCompany'].includes(currentChart)) {
                cDatasets.push({
                    label: "Hours",
                    backgroundColor: getColor("Negotiation", true),
                    borderWidth: 0,
                    borderRadius: {
                        topLeft: 5,
                        topRight: 5,
                    },
                    data: []
                })
            } else if(['cycles'].includes(page)) {

                ['Draft-to-Review', 'Review-to-Negotiation', 'Negotiation-to-Execution', 'Execution-to-InForce']
                .forEach((cycle,i) => {
                    cDatasets.push({
                        label: cycle,
                        borderColor: getColor(cycle, true),
                        backgroundColor: getColor(cycle, true),
                        tension: 0.2,
                        fill: true,
                        data: []
                    })
                })
            } else if(['smartfields'].includes(page) && ['smartFields'].includes(currentChart)) {

                let sfValues = [], sfColors = [], sfData = []

                let agrsFiltered = agrs
                    .filter((a) => a.year > parseInt(periodFilterStart.substring(4)) || (a.year === parseInt(periodFilterStart.substring(4)) && ((a.month - 1) >= months.findIndex((m) => m === periodFilterStart.substring(0,3)))))
                    .filter((a) => a.year < parseInt(periodFilterEnd.substring(4)) || (a.year === parseInt(periodFilterEnd.substring(4)) && ((a.month - 1) <= months.findIndex((m) => m === periodFilterEnd.substring(0,3)))))
    
                agrsFiltered
                .forEach((aws) => { // agrs with SFS data
                    if(!sfValues.some((sv) => sv === aws.val3)) { sfValues.push(aws.val3) }
                })

                sfValues.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0)).forEach((sfv, i) => {
                    cLabels.push(sfv)
                    sfColors.push(getColor("Random_" + i, true))
                    sfData.push(agrsFiltered.filter((a) => a.val3 === sfv).length)
                })

                cDatasets.push({
                    label: 'dataset',
                    backgroundColor: sfColors,
                    hoverBorderColor: sfColors,
                    data: sfData,
                    hoverOffset: 4,
                    cutout: '20%', 
                })

            } else if(['smartfields'].includes(page) && ['smartTopics'].includes(currentChart)) {
             
                cDatasets.push({
                    label: "Clauses",
                    backgroundColor: getColor("Draft-to-Review", true),
                    borderWidth: 0,
                    borderRadius: {
                        topLeft: 5,
                        topRight: 5,
                    },
                    data: []
                })
                cDatasets.push({
                    label: "Agreements",
                    backgroundColor: getColor("Negotiation", true),
                    borderWidth: 0,
                    borderRadius: {
                        topLeft: 5,
                        topRight: 5,
                    },
                    data: []
                })

            }

            yrs.forEach((yr, yrC) => {
                subyears.forEach((sy, syC) => {
                    if((startedPushing || 
                        (['agreements', 'cycles', 'smartfields'].includes(page) && agrs.filter((a) => a.year === yr && (a.month === sy || a.quarter === sy)).length > 0) ||
                        (['time'].includes(page) && times.filter((t) => t.year === yr && (t.month === sy || t.quarter === sy))[0] !== undefined)) &&
                    //((!['agrExpiry'].includes(currentChart) && (yr < loopDate.getFullYear() || sy <= cursy)) ||
                    ((!['agrExpiry'].includes(currentChart) && (yr < loopDate.getFullYear() || sy <= cursy) && 
                        !(period === 'quarterly' && yrC === 0 && syC === 0)) || // skip the first quarter for a quarterly chart (since it may be incomplete)
                     (['agrExpiry'].includes(currentChart) && (yr < lastExpiryDate.getFullYear() || (yr === lastExpiryDate.getFullYear() && sy <= cursy)))
                    )) {
                        cLabels.push((period === 'monthly' ? months[sy - 1] : sy ) + "-" + yr)
                        cDatasets.forEach((cd, i) => {
                            if(['agreements'].includes(page) && ['agrStatus', 'agrExpiry'].includes(currentChart)) {
                                cDatasets[i].data.push(agrs.filter((a) => a.year === yr && (a.month === sy || a.quarter === sy) && 
                                    a.agrStatus === cd.label).length)
                            } else if(['agreements'].includes(page) && ['agrType'].includes(currentChart)) {
                                cDatasets[i].data.push(agrs.filter((a) => a.year === yr && (a.month === sy || a.quarter === sy) && 
                                    a.agrTypeID === cd.agrTypeID).length)
                            } else if(['agreements'].includes(page) && ['agrLabel'].includes(currentChart)) {
                                cDatasets[i].data.push(agrs.filter((a) => a.year === yr && (a.month === sy || a.quarter === sy) && 
                                    orgLabelLinks.filter((oll) => oll.relID === a.relID)[0] !== undefined &&
                                    orgLabelLinks.filter((oll) => oll.relID === a.relID)[0].orgLabelID === cd.orgLabelID).length)
                            } else if(['time'].includes(page) && ['timeCompany', 'timeRole', 'timeType', 'timeLabel'].includes(currentChart)) {
                                let sum = 0
                                times
                                .filter((t) => (['timeCompany'].includes(currentChart) || t.item === cd.label || (['timeLabel'].includes(currentChart) && 
                                    t.item === cd.orgLabelID)) && t.year === yr && (t.month === sy || t.quarter === sy))
                                .forEach((t) => { sum = sum + t.hours; })
                                //cDatasets[i].data.push(sum)
                                cDatasets[i].data.push((Math.round(sum * 100) / 100))
                            } else if(['cycles'].includes(page)) {

                                let calcArray = []

                                agrs
                                .filter((a) => !['cyclesLabel', 'cyclesType'].includes(currentChart) || 
                                    (['cyclesLabel'].includes(currentChart) &&
                                        orgLabelLinks.filter((oll) => oll.relID === a.relID && orgLabelsSelected.some((olf) => olf._id === oll.orgLabelID))[0] !== undefined) ||
                                    (['cyclesType'].includes(currentChart) && agrTypesSelected.some((ats) => ats._id === a.agrTypeID))
                                )
                                .filter((a) => a.year === yr && (a.month === sy || a.quarter === sy) &&
                                (
                                    (cd.label === 'Draft-to-Review' && a.cycleDraftToReview !== null) ||
                                    (cd.label === 'Review-to-Negotiation' && a.cycleReviewToNego !== null) ||
                                    (cd.label === 'Negotiation-to-Execution' && a.cycleNegoToExec !== null) ||
                                    (cd.label === 'Execution-to-InForce' && a.cycleExecToInForce !== null)
                                ))
                                .forEach((a) => {
                                    calcArray.push(
                                        cd.label === 'Draft-to-Review' ? a.cycleDraftToReview :
                                        cd.label === 'Review-to-Negotiation' ? a.cycleReviewToNego : 
                                        cd.label === 'Negotiation-to-Execution' ? a.cycleNegoToExec :
                                        cd.label === 'Execution-to-InForce' ? a.cycleExecToInForce : 0
                                    )
                                })

                                cDatasets[i].data.push(
                                    calcArray.length > 0 ?
                                        (Math.round((calcArray.reduce((partial_sum, a) => partial_sum + a, 0) / calcArray.length) * 1000) / 1000)
                                        : 0)
                                
                            } else if(['smartfields'].includes(page) && ['smartTopics'].includes(currentChart)) {

                                let allTopics = agrs
                                    .filter((a) => a.year === yr && (a.month === sy || a.quarter === sy))
                                    //.filter((a) => a.year > parseInt(periodFilterStart.substring(4)) || (a.year === parseInt(periodFilterStart.substring(4)) && ((a.month - 1) >= months.findIndex((m) => m === periodFilterStart.substring(0,3)))))
                                    //.filter((a) => a.year < parseInt(periodFilterEnd.substring(4)) || (a.year === parseInt(periodFilterEnd.substring(4)) && ((a.month - 1) <= months.findIndex((m) => m === periodFilterEnd.substring(0,3)))))

                                let uniqueAgrs = []

                                allTopics.forEach((at) => { if(!uniqueAgrs.some((ua) => ua === at.id)) { uniqueAgrs.push(at.id) } })
                                cDatasets[i].data.push(i === 0 ? allTopics.length : uniqueAgrs.length)
                            }
                        })
                        startedPushing = true;
                    }
                })
            })

            // prepare CSV
            let csvH = [], csvD = []

            if(['agreements'].includes(page)) {
                csvH = [
                    //{ label: "Agreement ID", key: "agreementID" },
                    { label: "Creation Date", key: "creationDate" },
                    { label: "Creation Year", key: "creationYear" },
                    { label: "Creation Quarter", key: "creationQuarter" },
                    { label: "Creation Month", key: "creationMonth" },
                    { label: "Expiry Date", key: "expiryDate" },
                    { label: "Agr Type Full", key: "agrTypeFullName" },
                    { label: "Agr Type Short", key: "agrTypeShortName" },
                    { label: "Agr Status", key: "agrStatus" },
                    { label: "Prim Cpty Name", key: "primaryCptyName" },
                    { label: "Prim Cpty Legal Name", key: "primaryCptyLegalName" },
                    { label: "Prim Cpty Country", key: "primaryCptyCountry" },
                    { label: "Sec Cpty Name", key: "secondaryCptyName" },
                    { label: "Sec Cpty Legal Name", key: "secondaryCptyLegalName" },
                    { label: "Sec Cpty Country", key: "secondaryCptyCountry" },
                    { label: "Rel Name", key: "relationshipName" },
                    { label: "Rel Type", key: "relationshipType" }
                ];
                
                agrs.forEach((a) => {
                    let primcp = props.subs.filter((s) => s._id === a.primaryCptyID)[0] !== undefined ? props.subs.filter((s) => s._id === a.primaryCptyID)[0] : props.cpents.filter((cpe) => cpe._id === a.primaryCptyID)[0] !== undefined ? props.cpents.filter((cpe) => cpe._id === a.primaryCptyID)[0] : null
                    let seccp = props.subs.filter((s) => s._id === a.secondaryCptyID)[0] !== undefined ? props.subs.filter((s) => s._id === a.secondaryCptyID)[0] : props.cpents.filter((cpe) => cpe._id === a.secondaryCptyID)[0] !== undefined ? props.cpents.filter((cpe) => cpe._id === a.secondaryCptyID)[0] : null
                    csvD.push({
                        agreementID: a.id,
                        creationDate: a.creationDate,
                        creationYear: a.year,
                        creationQuarter: a.quarter,
                        creationMonth: a.month,
                        expiryDate: a.expiryDate !== undefined && a.expiryDate !== null ? a.expiryDate : "",
                        agrTypeFullName: props.agrTypes.filter((at) => at._id === a.agrTypeID)[0] !== undefined ? props.agrTypes.filter((at) => at._id === a.agrTypeID)[0].fullName[0] : "",
                        agrTypeShortName: props.agrTypes.filter((at) => at._id === a.agrTypeID)[0] !== undefined ? props.agrTypes.filter((at) => at._id === a.agrTypeID)[0].shortName : "",
                        agrStatus: a.agrStatus,
                        primaryCptyName: primcp !== null ? primcp.shortName : "",
                        primaryCptyLegalName: primcp !== null ? primcp.legalName : "",
                        primaryCptyCountry: primcp !== null  && primcp.address[0] !== undefined && primcp.address[0].country !== undefined ? primcp.address[0].country : "", 
                        secondaryCptyName: seccp !== null ? seccp.shortName : "",
                        secondaryCptyLegalName: seccp !== null ? seccp.legalName : "",
                        secondaryCptyCountry: seccp !== null  && seccp.address[0] !== undefined && seccp.address[0].country !== undefined ? seccp.address[0].country : "",
                        relationshipName: props.rels.filter((r) => r._id === a.relID)[0] !== undefined ? props.rels.filter((r) => r._id === a.relID)[0].name : "",
                        relationshipType: props.rels.filter((r) => r._id === a.relID)[0] !== undefined ? props.rels.filter((r) => r._id === a.relID)[0].relationshipType : "",
                    })
                })
            }

            if (!unmounted) { 
                setCsv({ headers: csvH, data: csvD})
                setChartLabels(cLabels)
                setChartDatasets(cDatasets)
                setAllAgrs(agrs)
                setThresholdReached(tReached)
                setTypeFilter(null)
                setLabelFilter(null)
                setPeriodfilter(null)
                setStatusFilter(null)
                setLoading(false)
            }

        }).catch((err) => { console.log(err) })

    } else if (subsSelected !== undefined && subsSelected.length === 0) { // When you're new and don't have any subs
        if (!unmounted) { setLoading(false) }
    }

    return () => { unmounted = true };

  }, [relevantRels, orgLabelLinks, page, triggerUpdate, /*loading,*/ props.subs, props.module]);

  useEffect(() => { // Define Filtered Agrs for the virtualized table

    if(['agreements'].includes(page)) {
        setFilteredAgrs(allAgrs.filter((a) => 
            (periodFilter === null || periodFilter === (months[a.month -1] + "-" + a.year) || periodFilter === (a.quarter + "-" + a.year)) &&
            (statusFilter === null || statusFilter === a.agrStatus) && 
            (typeFilter === null || typeFilter === a.agrTypeID) &&
            (labelFilter === null || (
                orgLabelLinks.filter((oll) => oll.relID === a.relID)[0] !== undefined &&
                orgLabelLinks.filter((oll) => oll.relID === a.relID)[0].orgLabelID === labelFilter
            ))))
    }

  }, [periodFilter, statusFilter, typeFilter, labelFilter, allAgrs]);

  const switchPage = (newPage) => {
      setLoading(true)
      if(page !== newPage){
            setCurrentChart(
                newPage === 'agreements' ? 'agrStatus' :
                newPage === 'time' ? 'timeCompany' :
                newPage === 'cycles' ? 'cyclesEntity' :
                newPage === 'smartfields' ? 'smartFields' :
                'agrStatus')
            setPeriod(
                newPage === 'agreements' ? 'monthly' :
                newPage === 'time' ? 'monthly' :
                newPage === 'cycles' ? 'quarterly' :
                newPage === 'smartfields' ? 'quarterly' :
                'monthly')
            setIncludeExhibits(newPage === 'smartfields')
      }
      setPage(newPage)
      setAdvancedSettings(false)
      setActiveOrgSmartField(null)
  }

  const handleConfirmUpdate = () => {
    setLoading(true); // Loading change will trigger the UseEffect
    setTriggerUpdate(triggerUpdate + 1)
  }

  const handleCurrentChartChange = (newChart) => {
    setLoading(true);
    setCurrentChart(newChart)
    setAdvancedSettings(['cycles'].includes(page))
    if(['smartfields'].includes(page)) { setActiveOrgSmartField(null) }
    setTriggerUpdate(triggerUpdate + 1)
  }

  const handleActiveOSF = (newVal) => {
    setLoading(true)
    setActiveOrgSmartField(newVal)
    setTriggerUpdate(triggerUpdate + 1)
  }

  const handlePeriodFilterChange = (variant, val) => {
    setLoading(true)
    if(variant === 'start') { setPeriodFilterStart(val) }
    else if(variant === 'end') { setPeriodFilterEnd(val) }
    setTriggerUpdate(triggerUpdate + 1)
    
  }

  const handleChartClick = (e, el) => {
      
    if(el.length > 0 && chartDatasets[el[0].datasetIndex] !== undefined  &&
    chartDatasets[el[0].datasetIndex].label !== undefined && el[0].index !== undefined &&
    chartLabels[el[0].index] !== undefined) {

        setPeriodfilter(chartLabels[el[0].index])
        if(['agrStatus', 'agrExpiry'].includes(currentChart)) {
            setStatusFilter(chartDatasets[el[0].datasetIndex].label)
        } else if (['agrType'].includes(currentChart)) {
            setTypeFilter(chartDatasets[el[0].datasetIndex].agrTypeID)
        } else if (['agrLabel'].includes(currentChart)) {
            setLabelFilter(chartDatasets[el[0].datasetIndex].orgLabelID)
        }
    }
  }

  const colors = [
      //{ rgb: 'rgba(114,67,221)', hex: '#7243dd' }, // primary
      { rgb: 'rgba(126,80,223', hex: '#7E50DF' }, // primary historic - 0
      { rgb: 'rgba(232,0,100)', hex: '#E80064' }, // secondary
      { rgb: 'rgba(253,231,76)', hex: '#FDE74C' }, // yellow
      { rgb: 'rgba(91,192,235)', hex: '#5BC0EB' }, // cyan
      { rgb: 'rgba(167,153,183)', hex: '#A799B7' }, // grape
      { rgb: 'rgba(238,123,48)', hex: '#EE7B30' }, // pumpkin - 5
      { rgb: 'rgba(0,108,103)', hex: '#006C67' }, // greenish
      //{ rgb: 'rgba(124,198,254)', hex: '#7CC6FE' }, // bright blue
      { rgb: 'rgba(224,129,223)', hex: '#e081df' }, // pink purpleish
      { rgb: 'rgba(193,211,127)', hex: '#C1D37F' }, // yellow green
      { rgb: 'rgba(249,212,187)', hex: '#F9D4BB' }, // apricot
      { rgb: 'rgba(226,192,68)', hex: '#E2C044' }, // mustard - 10
      { rgb: 'rgba(182,201,187)', hex: '#B6C9BB' }, // ash grey
      { rgb: 'rgba(20,70,160)', hex: '#1446A0' }, // tertiary
      { rgb: 'rgba(38,255,230)', hex: '#26FFE6' }, // turqoise
      { rgb: 'rgba(195,111,9)', hex: '#C36F09' }, // orange brownish
      { rgb: 'rgba(248,189,196)', hex: '#F8BDC4' }, // pink -15
      { rgb: 'rgba(68,175,22)', hex: '#44AF69' }, // success
      { rgb: 'rgba(208,37,46)', hex: '#D0252E' }, // error
      { rgb: 'rgba(230,130,163)', hex: '#e682a3' }, // light secondary
      { rgb: 'rgba(161,205,136)', hex: '#a1cd88' }, // light success
      { rgb: 'rgba(210,210,210)', hex: '#d2d2d2' }, // med grey - 20     
      { rgb: 'rgba(211,234,249)', hex: '#d3eaf9' } // neutral blue 
  ]

  const getColor = (key, isRGB) => {

    let color = '#000000';
    if(['Draft'].includes(key) && isRGB) { color = colors[20].rgb; }
    else if(['Draft'].includes(key) && !isRGB) { color = colors[20].hex }
    else if(['Review'].includes(key) && isRGB) { color = colors[18].rgb }
    else if(['Review'].includes(key) && !isRGB) { color = colors[18].hex }
    else if(['Negotiation', 'Negotiation-to-Execution'].includes(key) && isRGB) { color = colors[1].rgb }
    else if(['Negotiation', 'Negotiation-to-Execution'].includes(key) && !isRGB) { color = colors[1].hex }
    else if(['Execution'].includes(key) && isRGB) { color = colors[19].rgb }
    else if(['Execution'].includes(key) && !isRGB) { color = colors[19].hex }
    else if(['InForce','Execution-to-InForce'].includes(key) && isRGB) { color = colors[16].rgb }
    else if(['InForce','Execution-to-InForce'].includes(key) && !isRGB) { color = colors[16].hex }
    else if(['Exhibit'].includes(key) && isRGB) { color = colors[21].rgb }
    else if(['Exhibit'].includes(key) && !isRGB) { color = colors[21].hex }
    else if(['Draft-to-Review'].includes(key) && isRGB) { color = colors[0].rgb }
    else if(['Draft-to-Review'].includes(key) && !isRGB) { color = colors[0].hex }
    else if(['Review-to-Negotiation'].includes(key) && isRGB) { color = colors[2].rgb }
    else if(['Review-to-Negotiation'].includes(key) && !isRGB) { color = colors[2].hex }

    else if(key.startsWith('Random_') && key.substring(7) !== undefined &&
    parseInt(key.substring(7)) !== undefined && !isNaN(parseInt(key.substring(7))) && 
    colors[parseInt(key.substring(7)) % 18] !== undefined) {
        let c = colors[parseInt(key.substring(7)) % 18]
        color = isRGB ? c.rgb : c.hex
    }
    return color;
  }

  const renderRow = (p) => {

    const { index, style } = p;

    return (
        <div style={style}>
        <Grid direction="row" container key={index} justify="space-between" alignItems="center" className={classes.hoverGrid}>
            <Grid item>
                <Grid direction="row" container justify="flex-start" alignItems="center">
                    <Grid item>
                        <Box mt={2} mb={2} mr={2} ml={2}>
                        <Avatar
                        src={['sales', 'partnerships', 'people', 'corporate'].includes(props.module) &&
                        props.cpents.filter((c) => c._id === filteredAgrs[index].secondaryCptyID)[0] !== undefined ?
                            props.cpents.filter((c) => c._id === filteredAgrs[index].secondaryCptyID)[0].logoURL :
                        ['procurement', 'partnerships'].includes(props.module) &&
                        props.cpents.filter((c) => c._id === filteredAgrs[index].primaryCptyID)[0] !== undefined ?
                            props.cpents.filter((c) => c._id === filteredAgrs[index].primaryCptyID)[0].logoURL :
                            defaultLogo}
                        />
                        </Box>
                    </Grid>
                    <Grid item>
                        <Typography>
                            <span className={classes.bold}>
                            {props.agrTypes.filter((at) => at._id === filteredAgrs[index].agrTypeID)[0] !== undefined ?
                                props.agrTypes.filter((at) => at._id === filteredAgrs[index].agrTypeID)[0].fullName[0] :
                                "Unknown agreement type"}&nbsp;&nbsp;
                            </span>
                            {['sales', 'partnerships', 'people', 'corporate'].includes(props.module) &&
                            props.cpents.filter((c) => c._id === filteredAgrs[index].secondaryCptyID)[0] !== undefined ?
                                props.cpents.filter((c) => c._id === filteredAgrs[index].secondaryCptyID)[0].legalName :
                            ['procurement', 'partnerships'].includes(props.module) &&
                            props.cpents.filter((c) => c._id === filteredAgrs[index].primaryCptyID)[0] !== undefined ?
                                props.cpents.filter((c) => c._id === filteredAgrs[index].primaryCptyID)[0].legalName :
                                "Unknown Counterparty"}</Typography>
                        <Typography variant="body2" color="textSecondary">
                            <span className={classes.statusFont} style={{backgroundColor: getColor(filteredAgrs[index].agrStatus, false)}}>{filteredAgrs[index].agrStatus}</span>
                            &nbsp;&nbsp;
                            {['agrExpiry'].includes(currentChart) ? "Expires" : "Created"}: {months[filteredAgrs[index].month - 1] + "-" + filteredAgrs[index].year}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Box mr={2} ml={2}>
                <Button variant="text" color="primary" disableElevation onClick={e => history.push('/core/' + props.module + '/agreement/' + filteredAgrs[index].relID + "/" + filteredAgrs[index].id)}
                >View&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight}/></Button>
                </Box>
            </Grid>
        </Grid>
        </div>
    )
  }

  return (

    <div className={classes.root}>

        <Header 
          module={props.module}
          active="Analytics"
          user={props.user} 
          org={props.org}
          modules={
            props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
              ['sales','procurement','partnerships','people','corporate'] : 
            props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
              props.org.modules : [] }
          
          crumbs={[
            {name: "Home", to: "/core/" + props.module + "/dashboard"},
            {name: "Analytics", to: "active"}
          ]}
          withSubHead={true}
          notifications={props.notifications}
          markNotificationAsRead={props.markNotificationAsRead}
          markAllNotificationsAsRead={props.markAllNotificationsAsRead}
          className={classes.header}
        />

        <DrawerCore 
          page={page}
          sections={[]}
          pageType='analytics'
          module={props.module}
          switchPage={switchPage}
        />

        <Container maxWidth="lg">
        <Grid container direction="column" justify="flex-start" alignItems="center" className={classes.outerFlexGrid}>

        {loading || chartLabels === null || chartDatasets === null?
        <Box align="center">
            <SkeletonCustom type="chart" />
        </Box>
        :
        <Box>
            <Box mb={3}>
              {thresholdReached ?
              <Box align="center" mb={3}>
                <Typography variant="body2"><span className={classes.bold}>Note:</span> Your query reached the agreement threshold and may not be complete</Typography>
              </Box>
              :''}
              <Grid container direction="row" alignItems='center' justify="space-between">
                <Grid item>

                    <Button color="primary" variant="contained" disableElevation style={{borderRadius: '10px 0px 0px 10px', borderRight: '1px solid #ffffff'}}
                    className={
                        (['agreements'].includes(page) && currentChart === 'agrStatus') ||
                        (['time'].includes(page) && currentChart === 'timeCompany') ||
                        (['cycles'].includes(page) && currentChart === 'cyclesEntity') ||
                        (['smartfields'].includes(page) && currentChart === 'smartFields') ? classes.selectPillActive : classes.selectPillInactive} 
                    onClick={e => handleCurrentChartChange(
                        ['agreements'].includes(page) ? 'agrStatus' :
                        ['time'].includes(page) ? 'timeCompany' : 
                        ['cycles'].includes(page) ? 'cyclesEntity' :
                        ['smartfields'].includes(page) ? 'smartFields' :
                        'agrStatus')}>{
                            ['agreements'].includes(page) ? "By Status" :
                            ['time'].includes(page) ? "Hours spent" : 
                            ['cycles'].includes(page) ? "By Entity" : 
                            ['smartfields'].includes(page) ? "Smart Fields" :
                            'By Status'}</Button>

                    {!['cycles', 'smartfields'].includes(page) ?
                    <Button color="primary" variant="contained" disableElevation style={{borderRight: '1px solid #ffffff'}}
                    className={
                        (['agreements'].includes(page) && currentChart === 'agrType') ||
                        (['time'].includes(page) && currentChart === 'timeRole') ? classes.selectPillActive : classes.selectPillInactive}
                    onClick={e => handleCurrentChartChange(
                        ['agreements'].includes(page) ? 'agrType' :
                        ['time'].includes(page) ? 'timeRole' : 
                        'agrType')}>{
                            ['agreements'].includes(page) ? "By Type" :
                            ['time'].includes(page) ? "By Role" : 
                            'By Type'}</Button>
                    :''}

                    {!['smartfields'].includes(page) ? 
                    <Button color="primary" variant="contained" disableElevation style={{borderRadius: '0px 0px 0px 0px', borderRight: '1px solid #ffffff'}}
                    className={
                        (['agreements'].includes(page) && currentChart === 'agrExpiry') ||
                        (['time'].includes(page) && currentChart === 'timeType') ||
                        (['cycles'].includes(page) && currentChart === 'cyclesType') ? classes.selectPillActive : classes.selectPillInactive}
                    onClick={e => handleCurrentChartChange(
                        ['agreements'].includes(page) ? 'agrExpiry' :
                        ['time'].includes(page) ? 'timeType' : 
                        ['cycles'].includes(page) ? 'cyclesType' :
                        'agrExpiry')}>{
                            ['agreements'].includes(page) ? "By Expiry" :
                            ['time', 'cycles'].includes(page) ? "By Type" : 
                            'By Expiry'}</Button>
                    :''}

                    <Button color="primary" variant="contained" disableElevation style={{borderRadius: '0px 10px 10px 0px'}}
                    className={
                        (['agreements'].includes(page) && currentChart === 'agrLabel') ||
                        (['time'].includes(page) && currentChart === 'timeLabel') ||
                        (['cycles'].includes(page) && currentChart === 'cyclesLabel') || 
                        (['smartfields'].includes(page) && currentChart === 'smartTopics') ? classes.selectPillActive : classes.selectPillInactive}
                    onClick={e => handleCurrentChartChange(
                        ['agreements'].includes(page) ? 'agrLabel' :
                        ['time'].includes(page) ? 'timeLabel' : 
                        ['cycles'].includes(page) ? 'cyclesLabel' :
                        ['smartfields'].includes(page) ? 'smartTopics' :
                            'agrLabel')}>{
                        ['smartfields'].includes(page) ?
                            "Smart Topics" :
                            "By Label"}</Button>
                            
                </Grid>
                <Grid item>
                    <Box align="right">
                    <Button size="small" variant="text" color="primary" onClick={e => setAdvancedSettings(!advancedSettings)}
                    >Advanced&nbsp;&nbsp;<FontAwesomeIcon icon={advancedSettings? faChevronUp : faChevronDown} /></Button>
                    </Box>
                </Grid>
              </Grid>
              <Collapse in={advancedSettings}>
                <Box pt={2} pb={2} pl={2} pr={2} mt={1} style={{backgroundColor: '#f6f6f6', borderRadius: '10px'}} className={classes.chartBox1}>
                <Grid container direction="row" alignItems='center' justify="space-between">
                    <Grid item>
                        <Box style={{width: '280px'}}>
                        <Autocomplete
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        disabled={['time'].includes(page) && !['timeLabel'].includes(currentChart)}
                        options={
                            ['agrLabel', 'timeLabel', 'cyclesLabel'].includes(currentChart) ?
                                props.orgLabel.filter((ol) => !ol.deactive && ol.modules.includes(props.module)) :
                            ['cyclesType'].includes(currentChart) ?
                                props.agrTypes :
                                props.subs.filter((s) => s.isLive).sort((a,b) => (a.legalName > b.legalName) ? 1 : ((b.legalName > a.legalName) ? -1 : 0))}
                        getOptionLabel={(option) => 
                            ['agrLabel', 'timeLabel', 'cyclesLabel'].includes(currentChart) ?
                                option.name :
                            ['cyclesType'].includes(currentChart) ?
                                trunc(option.fullName[0], 22) + ' (' + option.shortName + ')' :
                                option.legalName}
                        value={
                            ['agrLabel', 'timeLabel', 'cyclesLabel'].includes(currentChart) ?
                                orgLabelsSelected :
                            ['cyclesType'].includes(currentChart) ?
                                agrTypesSelected :
                                subsSelected}
                        onChange={(e, newValue) => { 
                            ['agrLabel', 'timeLabel', 'cyclesLabel'].includes(currentChart) ?
                                setOrgLabelsSelected(newValue) :
                            ['cyclesType'].includes(currentChart) ?
                                setAgrTypesSelected(newValue) :
                                setSubsSelected(newValue) }}
                        renderInput={(params) => (
                            <TextField {...params} placeholder={
                                ['agrLabel', 'timeLabel', 'cyclesLabel'].includes(currentChart) ? 
                                    "Labels" :
                                ['cyclesType'].includes(currentChart) ?
                                    "Types" :
                                    "Entities"} variant="outlined" size="small" fullWidth />
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip {...getTagProps({ index })} color="primary"
                                label={<Typography className={classes.chipFont}>{trunc(
                                        ['agrLabel', 'timeLabel', 'cyclesLabel'].includes(currentChart) ? 
                                            option.name : 
                                        ['cyclesType'].includes(currentChart) ?
                                            option.shortName :
                                            option.legalName,15)}</Typography>} />
                            ))
                        }
                        />
                        </Box>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                        style={{marginRight: '14px'}}
                        disabled={['agrExpiry'].includes(currentChart) || ['time', 'cycles'].includes(page)}
                        control={<Switch color="primary" checked={includeExhibits} onChange={e => setIncludeExhibits(!includeExhibits)} name="includeExhibits" />}
                        label={<Typography variant="subtitle2">Include Exhibits</Typography>}
                        />
                    </Grid>
                    <Grid item>
                        <RadioGroup row value={period} onChange={e => setPeriod(e.target.value)} disabled={['smartFields'].includes(currentChart)}>
                        <FormControlLabel value="monthly" control={<Radio color="primary" />} label={<Typography variant="subtitle2">Monthly</Typography>} />
                        <FormControlLabel value="quarterly" control={<Radio color="primary" />} label={<Typography variant="subtitle2">Quarterly</Typography>} />
                        </RadioGroup>
                    </Grid>
                </Grid>
                <Box align="right" mt={1}><Button variant="contained" disableElevation color="primary" onClick={handleConfirmUpdate}
                >Update&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} /></Button></Box>
                </Box>
              </Collapse>
              {['smartfields'].includes(page) && orgSmartFields !== undefined && orgSmartFields !== null && orgSmartFields.length > 0 ?
              <Box mt={3} align="center">
                    <Box align="left">
                    <Grid direction="row" container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                            options={
                                orgSmartFields
                                .filter((osf) => 
                                    (['smartTopics'].includes(currentChart) && osf.type === 'clause-topic' && 
                                        (osf.orgID !== 'CANVEO' || (osf.orgID === 'CANVEO' && !orgSmartFields.some((o) => o.type === 'clause-topic' && o.orgID !== 'CANVEO' && o.defaultValue1 === osf.defaultValue1)))) ||
                                    (['smartFields'].includes(currentChart) && osf.type !== 'clause-topic'))
                                .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                            }
                            getOptionLabel={(option) => option.name}
                            value={activeOrgSmartField}
                            onChange={(e, newValue) => { handleActiveOSF(newValue)}}
                            renderInput={(params) => (
                                <TextField {...params} placeholder={['smartTopics'].includes(currentChart) ? "Topic" : "Smart Field"} variant="outlined" fullWidth />
                            )} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            {['smartFields'].includes(currentChart) ?
                            <FormControl fullWidth>
                            <Select
                            value={periodFilterStart}
                            onChange={e => handlePeriodFilterChange('start', e.target.value)}
                            variant="outlined"
                            >
                            {periods.map((p, i) => ( <MenuItem key={i} value={p}>{p.substring(4) + "-" + p.substring(0,3)}</MenuItem> ))}
                            </Select>
                            </FormControl>
                            :''}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            {['smartFields'].includes(currentChart) ?
                            <FormControl fullWidth>
                            <Select
                            value={periodFilterEnd}
                            onChange={e => handlePeriodFilterChange('end', e.target.value)}
                            variant="outlined"
                            >
                            {periods.map((p, j) => ( <MenuItem key={j} value={p}>{p.substring(4) + "-" + p.substring(0,3)}</MenuItem> ))}
                            </Select>
                            </FormControl>
                            :''}
                        </Grid>
                    </Grid>
                    </Box>

              </Box>
              : ''}
            </Box>
            {
            (['smartfields'].includes(page) && activeOrgSmartField === null) ||
            ((['smartfields'].includes(page) && activeOrgSmartField !== null && chartDatasets[0] !== undefined && 
            chartDatasets[0].backgroundColor !== undefined && chartDatasets[0].backgroundColor.length === 0)) ?
            <Box align="center" mt={10} mb={10}>
                <Typography variant="body2" color="textSecondary">
                {//['smartTopics'].includes(currentChart) ?
                //    "Coming soon." :
                ['smartfields'].includes(page) && activeOrgSmartField === null ? 
                    "Select a " + (['smartTopics'].includes(currentChart) ? "topic" : "smart field") + "."
                :
                    "Change the filter critria."
                }
                </Typography>
            </Box>
            :
            ['agreements', 'time', 'cycles', 'smartfields'].includes(page) && 
            ['agrStatus', 'agrType', 'agrExpiry', 'agrLabel', 
             'timeCompany', 'timeRole', 'timeType', 'timeLabel',
             'cyclesEntity', 'cyclesType', 'cyclesLabel',
             'smartFields', 'smartTopics'].includes(currentChart) ? 
            <>
                <Box style={{width: '100%'}} align="center">
                    {['agrStatus'].includes(currentChart) ? <Box mb={1}><Typography variant="body2" color="textSecondary" align="right">Quantity of agreements by Status</Typography></Box> :
                    ['agrType'].includes(currentChart) ? <Box mb={1}><Typography variant="body2" color="textSecondary" align="right">Quantity of agreements by Agreement Type</Typography></Box> :
                    ['agrExpiry'].includes(currentChart) ? <Box mb={1}><Typography variant="body2" color="textSecondary" align="right">Quantity of agreements by Expiry Date</Typography></Box> :
                    ['agrLabel'].includes(currentChart) ? <Box mb={1}><Typography variant="body2" color="textSecondary" align="right">Quantity of agreements by Label</Typography></Box> :
                    ['timeCompany'].includes(currentChart) ? <Box mb={1}><Typography variant="body2" color="textSecondary" align="right">Hours spent by the entire company</Typography></Box> :
                    ['timeRole'].includes(currentChart) ? <Box mb={1}><Typography variant="body2" color="textSecondary" align="right">Hours spent by user role</Typography></Box> :
                    ['timeType'].includes(currentChart) ? <Box mb={1}><Typography variant="body2" color="textSecondary" align="right">Hours spent by Agreement Type</Typography></Box> :
                    ['timeLabel'].includes(currentChart) ? <Box mb={1}><Typography variant="body2" color="textSecondary" align="right">Hours spent by Label</Typography></Box> :
                    ['cyclesEntity'].includes(currentChart) ? <Box mb={1}><Typography variant="body2" color="textSecondary" align="right">Cycle time (in days) with Entity filter</Typography></Box> :
                    ['cyclesType'].includes(currentChart) ? <Box mb={1}><Typography variant="body2" color="textSecondary" align="right">Cycle time (in days) with Agreement Type filter</Typography></Box> :
                    ['cyclesLabel'].includes(currentChart) ? <Box mb={1}><Typography variant="body2" color="textSecondary" align="right">Cycle time (in days) with Label filter</Typography></Box> :
                    ['smartFields'].includes(currentChart) ? <Box mb={1}><Typography variant="body2" color="textSecondary" align="right">Quantity of smart field values (In Force only)</Typography></Box> :
                    ['smartTopics'].includes(currentChart) ? <Box mb={1}><Typography variant="body2" color="textSecondary" align="right">Quantity of smart topics (In Force only)</Typography></Box> :
                    ''}
                    <Box className={
                        ['smartfields'].includes(page) && ['smartFields'].includes(currentChart) ?
                            classes.chartBox1Smaller :
                            classes.chartBox1}>
                        {['smartfields'].includes(page) && ['smartFields'].includes(currentChart) ? 

                        <Doughnut 
                        options={{
                            plugins: {
                                legend: { display: true, position: 'top' },
                                datalabels: { //display: true, 
                                    color: '#FFFFFF',
                                    display: function(context) { return context.dataset.data[context.dataIndex] !== 0; } }
                            },
                        }}
                        data={{
                            labels: chartLabels,
                            datasets: chartDatasets
                        }}
                        />
                        :
                        
                        ['cycles'].includes(page) ?
                        <Line
                        options={{
                            aspectRatio: 1.5,
                            scales: { 
                                x: { 
                                    grid: { display: false }
                                },
                                y: { 
                                    stacked: true, 
                                    ticks: { stepSize: 10 },
                                    grid: { borderDash: [1, 1] }                            
                                } 
                            },
                            plugins: {
                                legend: { display: true, position: 'bottom' },
                                datalabels: { display: false }
                            },
                        }}
                        data={{
                            labels: chartLabels,
                            datasets: chartDatasets
                        }}
                        />
                        :
                        <Bar
                        options={{
                            aspectRatio: 1.5,
                            scales: { 
                                x: { 
                                    stacked: !['smartfields'].includes(page) || !['smartTopics'].includes(currentChart),
                                    grid: { display: false },
                                    ticks: { color: (c) => {
                                        return ['agrExpiry'].includes(currentChart) && c !== undefined && 
                                            c.tick !== undefined && c.tick.label !== undefined && (
                                                (period === 'monthly' &&
                                                    ((parseInt(c.tick.label.substring(4)) < new Date().getFullYear()) ||
                                                    ((parseInt(c.tick.label.substring(4)) === new Date().getFullYear()) && 
                                                    (months.findIndex((m) => m === c.tick.label.substring(0,3)) < new Date().getMonth())))) ||
                                                (period === 'quarterly' && 
                                                    ((parseInt(c.tick.label.substring(3)) < new Date().getFullYear()) ||
                                                    ((parseInt(c.tick.label.substring(3)) === new Date().getFullYear()) && (
                                                    (c.tick.label.substring(0,2) === 'Q1' && [3,4,5,6,7,8,9,10,11].includes(new Date().getMonth())) ||
                                                    (c.tick.label.substring(0,2) === 'Q2' && [6,7,8,9,10,11].includes(new Date().getMonth())) ||
                                                    (c.tick.label.substring(0,2) === 'Q3' && [9,10,11].includes(new Date().getMonth())))/* ||
                                                    (c.tick.label.substring(0,2) === 'Q4' && [0,1,2,3,4,5,6,7,8].includes(new Date().getMonth()))*/
                                                )))) ? '#e9e9e9' : '#666666';
                                    } }
                                },
                                y: { 
                                    stacked: !['smartfields'].includes(page) || !['smartTopics'].includes(currentChart), 
                                    ticks: { stepSize: 10 },
                                    grid: { borderDash: [1, 1] }                            
                                } 
                            },
                            plugins: {
                                legend: { display: !['timeCompany'].includes(currentChart), position: 'bottom' },
                                datalabels: { //display: true, 
                                    color: '#FFFFFF',
                                    display: function(context) { return context.dataset.data[context.dataIndex] !== 0; } }
                            },
                            barPercentage: !['smartfields'].includes(page) || !['smartTopics'].includes(currentChart) ? 1.0 : 0.9,
                            onClick: function(e, el) { handleChartClick(e, el) }
                        }}
                        data={{
                            labels: chartLabels,
                            datasets: chartDatasets
                        }}
                        />
                        }
                    </Box>
                </Box>
                {['agreements'].includes(page) ?
                <>
                <Box mt={4} ml={1}>
                    <Grid direction="row" container justify="space-between" alignItems="center">
                        <Grid item>                        
                            <Typography variant="body2">
                                <span className={classes.bold}>{filteredAgrs.length} items</span>&nbsp;&nbsp;
                                {periodFilter === null && statusFilter === null && typeFilter === null && labelFilter === null ?
                                    "- click a bar to filter the list"
                                :''}
                            </Typography>
                        </Grid>
                        <Grid item>
                            {statusFilter !== null ?
                            <Chip color="primary" onDelete={e => setStatusFilter(null)} size="small" style={{margin: '2px'}}
                            label={<Typography className={classes.chipFont}>{statusFilter}</Typography>} />                                
                            :''}
                            {typeFilter !== null ?
                            <Chip color="primary" onDelete={e => setTypeFilter(null)} size="small" style={{margin: '2px'}}
                            label={<Typography className={classes.chipFont}>{
                                props.agrTypes.filter((at) => at._id === typeFilter)[0] !== undefined ?
                                props.agrTypes.filter((at) => at._id === typeFilter)[0].shortName : 'filter'}</Typography>} />                                
                            :''}
                            {labelFilter !== null ?
                            <Chip color="primary" onDelete={e => setLabelFilter(null)} size="small" style={{margin: '2px'}}
                            label={<Typography className={classes.chipFont}>{
                                props.orgLabel.filter((ol) => ol._id === labelFilter)[0] !== undefined ?
                                props.orgLabel.filter((ol) => ol._id === labelFilter)[0].name : 'filter'}</Typography>} />                                
                            :''}
                            {periodFilter !== null ?
                            <Chip color="primary" onDelete={e => setPeriodfilter(null)} size="small" style={{margin: '2px'}}
                            label={<Typography className={classes.chipFont}>{periodFilter}</Typography>} />
                            :''}
                        </Grid>
                        <Grid item>
                        <CSVLink data={csv.data.filter((d) => filteredAgrs.some((fa) => fa.id === d.agreementID))}
                        /*data={csv.data}*/ headers={csv.headers} 
                        filename={"canveo_analytics_download_" + new Date().toISOString() + ".csv"}>
                            <IconButton><FontAwesomeIcon icon={faDownload} style={{fontSize: '16px', color: theme.palette.grey[900]}} /></IconButton>
                        </CSVLink>
                        </Grid>
                    </Grid>
                </Box>
                <Box style={{height: '500px'}} mt={2}>
                <AutoSizer>
                {({height, width}) => (
                    <FixedSizeList
                    height={height}
                    width={width}
                    itemSize={80}
                    itemCount={filteredAgrs.length}
                    overscanCount={5}
                    >
                    {renderRow}
                    </FixedSizeList>
                    )}
                </AutoSizer>
                </Box>
                </>
                :''}
            </>
            :
            <Box mt={10} mb={10} align="center">
                Work in progress.
            </Box>
            }
        </Box>
        }

        </Grid>
        </Container>

    </div>

  )
}

export default Analytics;