import React from 'react';
import theme from '../../theme/theme';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, TextField,
         makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faKey, faUserCircle, faTag, faEyeSlash, faEye, faPhone } from "@fortawesome/free-solid-svg-icons";
import { ChipUserType } from '../';
import SelectUserRole from '../SelectUserRole';

const UserForm = (props) => {

  const useStyles = makeStyles(theme => ({
    paddingRightShrinking: {
      [theme.breakpoints.down('xs')]: { paddingRight: '10px' },
      [theme.breakpoints.up('sm')]: { paddingRight: '20px' },
    },
    infoIcon: {
      color: theme.palette.grey[600],
      marginLeft: '15px',
    }
  }));
  const classes = useStyles();

  let valSchema = Yup.object({
    title: Yup.string()
      .max(50, 'The title too long')
      .required('The title is required'),
    firstName: Yup.string()
      .max(20, 'First name is too long')
      .required('First name is required'),
    lastName: Yup.string()
      .max(40, 'Last name is too long')
      .required('Last name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email address is required'),
  })
  if(props.signup && !props.oauth) { 
    valSchema = Yup.object({
        title: Yup.string()
          .max(50, 'The title too long')
          .required('The title is required'),
        firstName: Yup.string()
          .max(20, 'First name is too long')
          .required('First name is required'),
        lastName: Yup.string()
          .max(40, 'Last name is too long')
          .required('Last name is required'),
        email: Yup.string()
          .email('Invalid email address')
          .required('Email address is required'),
        password: Yup.string()
          .required('Password is required') 
          .min(8, 'Your password must be at least 8 characters')
      })
  } else if (['disabled', 'newcontact'].includes(props.type)) {
    valSchema = Yup.object({
        firstName: Yup.string()
          .max(20, 'First name is too long')
          .required('First name is required'),
        lastName: Yup.string()
          .max(40, 'Last name is too long')
          .required('Last name is required'),
        email: Yup.string()
          .email('Invalid email address')
          .required('Email address is required'),
    })
  } else if (['update'].includes(props.type)) {

    valSchema = Yup.object({
      title: Yup.string()
        .max(50, 'The title too long')
        .required('The title is required'),
      firstName: Yup.string()
        .max(20, 'First name is too long')
        .required('First name is required'),
      lastName: Yup.string()
        .max(40, 'Last name is too long')
        .required('Last name is required'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Email address is required'),
      phone: Yup.string()
        .max(16, 'The phone number is too long')
    })
  }

  const formik = useFormik({
    initialValues: {
      title: props.initialValues.title,
      email: props.initialValues.email,
      firstName: props.initialValues.firstName,
      lastName: props.initialValues.lastName,
      password: (props.hidePassword !== 'clear' ? props.initialValues.password : ''),
      phone: "+" + (props.initialValues.phone !== undefined && props.initialValues.phone !== null ? 
          String(props.initialValues.phone).replace(/[^\d]/g, "") : ''),
    },
    validationSchema: valSchema,
    onSubmit: values => {
      values.role = userRole
      values.readOnly = readOnly
      values.phone = values.phone.replace(/[^\d]/g, "")
      props.parentCallback(values);
    },
  });

  const [userRole, setUserRole] = React.useState(props.initialValues.role);
  const [showPassword, setShowPassword] = React.useState(false)
  const [readOnly, setReadOnly] = React.useState(props.initialValues.readOnly)

  const handleChangeUserRole = (event) => {
    setUserRole(event.target.value)
  }

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const emailField = (
          <Grid item container xs={12} sm={props.type === 'cptyuser' ? 12 : 8} md={props.type === 'cptyuser' ? 10 : 6}>
          <TextField
            required
            fullWidth
            disabled={props.type === 'update' || props.oauth || props.type === 'disabled'}
            style={props.type ==='update' ? {backgroundColor: theme.palette.grey[100]} : {backgroundColor: theme.palette.primary.contrastText}}
            variant="outlined"
            id="email"
            label="Email Address"
            placeholder="Email address"
            name="email"
            autoComplete="email"
            {...formik.getFieldProps('email')}
            error={formik.touched.email && formik.errors.email ? true : false}
            helperText={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faEnvelope} color={props.type === 'update' ? theme.palette.grey[400] : theme.palette.grey[600]} />
                </InputAdornment>
              ),
            }}          
          />
        </Grid>)

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
    <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
      <Grid item container xs={12} sm={8} md={6} alignItems="center" justify="center">
        {props.type === 'update' ?
        (
        <Box mb={2}>
          <ChipUserType role={userRole !== undefined ? userRole : "Unknown"} type="standard" />
        </Box>
        )
        : '' }
        {props.type === 'new' ?
        (
          <SelectUserRole
            initialVal={props.initialValues.role} 
            handleChangeUserRole={handleChangeUserRole} 
            modules={props.modules}
            adminOnly={props.adminOnly}
          />

        ) : '' }
      </Grid>

      {props.type === 'new' ?
      <Grid item container xs={12} sm={8} md={6} alignItems="center" justify="center">

        <FormControlLabel
          control={<Checkbox checked={readOnly} onChange={e => setReadOnly(!readOnly)} name="readOnly" />}
          disabled={['Admin'].includes(userRole)}
          label={"Disable Editing"}
        />

      </Grid>
      : '' }

      { !props.signup ? emailField : '' }
             
      <Grid item container xs={12} sm={props.type === 'cptyuser' ? 12 : 8} md={props.type === 'cptyuser' ? 10 : 6} direction="row">        
        <Grid item container xs={6} className={classes.paddingRightShrinking}>
          <TextField
            required
            disabled={props.type === 'disabled'}
            fullWidth
            variant="outlined"
            id="firstName"
            label="First Name"
            placeholder="First name"
            name="firstName"
            autoComplete="fname"
            {...formik.getFieldProps('firstName')}
            error={formik.touched.firstName && formik.errors.firstName ? true : false}
            helperText={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : ''}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faUserCircle} color={theme.palette.grey[600]} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item container xs={6}>
          <TextField
            required
            disabled={props.type === 'disabled'}
            fullWidth
            variant="outlined"
            id="lastName"
            label="Last Name"
            placeholder="Last name"
            name="lastName"
            autoComplete="lname"
            {...formik.getFieldProps('lastName')}
            error={formik.touched.lastName && formik.errors.lastName ? true : false}
            helperText={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : ''}            
          />
        </Grid>
      </Grid>

      { !['disabled', 'newcontact'].includes(props.type) ? 
        <Grid item container xs={12} sm={props.type === 'cptyuser' ? 12 : 8} md={props.type === 'cptyuser' ? 10 : 6}>
          <TextField
            required
            fullWidth
            disabled={props.type === 'disabled'}
            style={props.type === 'disabled' ? {backgroundColor: theme.palette.grey[100]} : {backgroundColor: theme.palette.primary.contrastText}}
            variant="outlined"
            id="title"
            label="Corporate Title"
            placeholder="Title"
            name="title"
            autoComplete="title"
            {...formik.getFieldProps('title')}
            error={formik.touched.title && formik.errors.title ? true : false}
            helperText={formik.touched.title && formik.errors.title ? formik.errors.title : ''}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faTag} 
                    color={props.type === 'disabled' ? theme.palette.grey[400] : theme.palette.grey[600]} />
                </InputAdornment>
              ),
            }}          
          />
        </Grid>
      : '' }

      { props.signup ? emailField : '' }

      { props.signup && !props.oauth && props.hidePassword !== 'hide' ?
      (
      <Grid item container xs={12} sm={8} md={6}>
        <TextField
          required
          fullWidth
          variant="outlined"
          id="password"
          label="Password"
          placeholder="Your password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          autoComplete="new-password"
          {...formik.getFieldProps('password')}
          error={formik.touched.password && formik.errors.password ? true : false}
          helperText={formik.touched.password && formik.errors.password ? formik.errors.password : ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faKey} color={props.type === 'update' ? theme.palette.grey[400] : theme.palette.grey[600]} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={e => handleToggleShowPassword()}
                  onMouseDown={handleMouseDownPassword}
                  size="small"
                >
                  {showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                </IconButton>
              </InputAdornment>
            )
          }}          
        />
      </Grid>
      ) : '' }

      {['update'].includes(props.type) ?
      (
      <Grid item container xs={12} sm={8} md={6}>
        <TextField
          required
          fullWidth
          variant="outlined"
          id="phone"
          label="Phone Number"
          placeholder="Your Phone Number"
          name="phone"
          type={'text'}
          {...formik.getFieldProps('phone')}
          error={formik.touched.phone && formik.errors.phone ? true : false}
          helperText={formik.touched.phone && formik.errors.phone ? formik.errors.phone : ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faPhone} color={theme.palette.grey[600]} />
              </InputAdornment>
            ),
          }}          
        />
      </Grid>
      ) : '' }
      
      <Grid item container xs={12} sm={props.type === 'cptyuser' ? 12 : 8} md={props.type === 'cptyuser' ? 10 : 6}>
        <Button
          fullWidth
          disabled={props.type === 'disabled' || (
            props.signup && ((!props.oauth && (
              formik.values.firstName === '' ||
              formik.values.lastName === '' ||
              formik.values.email === '' ||
              formik.values.title === '' ||
              formik.values.password === ''
            )) || (props.oauth && (
              formik.values.firstName === '' ||
              formik.values.lastName === '' ||
              formik.values.email === '' ||
              formik.values.title === ''
            ))
            )
          )}
          disableElevation
          type="submit"
          variant="contained"
          color="primary"
          >{props.signup ? "Next" : "Submit"}
        </Button>
      </Grid>
    </Grid>

    </form>
  );
};

export default UserForm