import React from 'react';
//import { getAcronym } from '../utils'
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { Box, TextField, Typography } from '@material-ui/core';

const filter = createFilterOptions();

const SelectAgrType = (props) => {
  /*const useStyles = makeStyles(theme => ({
    modules: {
      display: 'inline-block',
      fontSize: '11px',
      color: theme.palette.grey[600],
      marginLeft: '10px',
    },
  }));
  const classes = useStyles();*/

  return (
      <div>
        <Autocomplete // AGR TYPE
        value={props.newAgrType}
        onChange={(e, newValue) => {
            props.handleAgrTypeSelectChange(newValue, props.agrComponentID)
        }}
        filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params !== undefined && params.inputValue !== '' && !filtered.some((f) => f.fullName.includes(params.inputValue))) {
                filtered.push({
                inputValue: params.inputValue,
                fullName: [`Create "${params.inputValue}" as a new Agreement Type`],
                });
            }
            return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={props.agrTypeOptions}
        getOptionLabel={(option) => {
            if (typeof option === 'string') { return option; } // Value selected with enter, right from the input
            if (option !== undefined && option.inputValue) { return option.inputValue; } // Add "xxx" option created dynamically
            return option.fullName[0]; // Regular option
        }}
        renderOption={(option) => (
            <Typography 
            variant={option._id !== undefined ? "body1" : "subtitle1"}
            color="textPrimary">
            {option.fullName[0]}
            </Typography>
        )}
        style={{ width: '100%' }}
        freeSolo
        renderInput={(params) => (
            <TextField {...params} label={"Select Agreement Type"} variant="outlined" fullWidth
            inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
            }} />
        )}
        />

        {/*this.state.newAgrType !== null && this.state.newAgrType !== undefined && 
        this.state.newAgrType.inputValue !== undefined && this.state.newAgrType.inputValue !== null ? */
        props.newAgrType !== null && props.newAgrType !== undefined && 
        props.newAgrType.inputValue !== undefined && props.newAgrType.inputValue !== null ?
        <Box mt={2} /*pl={3} pr={3}*/>

        <TextField
        fullWidth
        label="Short name"
        variant="outlined"
        value={props.newAgrTypeShortName}
        onChange={e => props.handleAgrTypeShortNameChange(e.target.value, props.agrComponentID)}
        />

        </Box>
        : '' }
    </div>
    );
}

export default SelectAgrType