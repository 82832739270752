import React from 'react';
import theme from '../theme/theme';
import { Editor, EditorState,
         convertFromRaw, convertToRaw, Modifier, RichUtils,
         CompositeDecorator, getDefaultKeyBinding, KeyBindingUtil } from 'draft-js';
import { CleanClause, RedlineControls, SmartFields } from '.'

const dayjs = require('dayjs')

const { hasCommandModifier, isOptionKeyCommand } = KeyBindingUtil;

const eMap = {entityMap: {
  "proposedAdd": { type: 'PROPOSEDADD', mutability: 'MUTABLE', },
  "rejectPAdd": { type: 'REJECTPADD', mutability: 'MUTABLE', },
  "proposedDel": { type: 'PROPOSEDDEL', mutability: 'MUTABLE', },
  "rejectPDel": { type: 'REJECTPDEL', mutability: 'MUTABLE', },
  "proposedAddSecondary": { type: 'PROPOSEDADDSECONDARY', mutability: 'MUTABLE', },
  "rejectPAddSec": { type: 'REJECTPADDSEC', mutability: 'MUTABLE', },
  "proposedDelSecondary": { type: 'PROPOSEDDELSECONDARY', mutability: 'MUTABLE', },
  "rejectPDelSec": { type: 'REJECTPDELSEC', mutability: 'MUTABLE', },
}}

class NegoCardEditor extends React.Component {
  constructor(props) {
    super(props);
    const decorator = new CompositeDecorator([
      { strategy: PaddStrategy, component: PaddSpan },
      { strategy: RPaddStrategy, component: RPaddSpan },
      { strategy: PdelStrategy, component: PdelSpan },
      { strategy: RPdelStrategy, component: RPdelSpan },
      { strategy: PaddSecStrategy, component: PaddSecSpan },
      { strategy: RPaddSecStrategy, component: RPaddSecSpan },
      { strategy: PdelSecStrategy, component: PdelSecSpan },
      { strategy: RPdelSecStrategy, component: RPdelSecSpan },
    ]);

    let eState
    if(props.isNew){ // Create empty editor
      eState = EditorState.createEmpty(decorator)
    } else { // insert existing content into editor, add / update the entityMap if necessary
      if(props.content.entityMap === undefined || props.content.entityMap === null || props.content.entityMap === {}){
        Object.assign(props.content, eMap)
      }
      eState = EditorState.createWithContent(convertFromRaw(props.content), decorator)
    }
    const cState = eState.getCurrentContent();
    let lastUpdateDate = new Date().toISOString();
    const cStateWithE1 = (props.curCpty === "primary" || props.curCpty === "template" ? 
      cState.createEntity("PROPOSEDADD", "MUTABLE", {"rev": false, "user": props.user.displayName, "org": props.ownOrgName, "time": lastUpdateDate}) : 
      cState.createEntity("PROPOSEDADDSECONDARY", "MUTABLE", {"rev": false, "user": props.user.displayName, "org": props.ownOrgName,"time": lastUpdateDate}))
    const addKey = cStateWithE1.getLastCreatedEntityKey();
    const cStateWithE2 = (props.curCpty === "primary" || props.curCpty === "template" ? 
      cStateWithE1.createEntity("PROPOSEDDEL", "MUTABLE", {"rev": false, "user": props.user.displayName, "org": props.ownOrgName,"time": lastUpdateDate}) : 
      cStateWithE1.createEntity("PROPOSEDDELSECONDARY", "MUTABLE", {"rev": false, "user": props.user.displayName, "org": props.ownOrgName,"time": lastUpdateDate}))
    const delKey = cStateWithE2.getLastCreatedEntityKey();
    const cStateWithE3 = (props.curCpty === "primary" || props.curCpty === "template" ? 
      cStateWithE2.createEntity("REJECTPADDSEC", "MUTABLE", {"rev": false, "user": props.user.displayName, "org": props.ownOrgName,"time": lastUpdateDate}) : 
      cStateWithE2.createEntity("REJECTPADD", "MUTABLE", {"rev": false, "user": props.user.displayName, "org": props.ownOrgName,"time": lastUpdateDate}))
    const rejaddKey = cStateWithE3.getLastCreatedEntityKey();
    const cStateWithE4 = (props.curCpty === "primary" || props.curCpty === "template" ? 
      cStateWithE3.createEntity("REJECTPDELSEC", "MUTABLE", {"rev": false, "user": props.user.displayName, "org": props.ownOrgName,"time": lastUpdateDate}) : 
      cStateWithE3.createEntity("REJECTPDEL", "MUTABLE", {"rev": false, "user": props.user.displayName, "org": props.ownOrgName,"time": lastUpdateDate}))
    const rejdelKey = cStateWithE4.getLastCreatedEntityKey();
    const cStateWithE5 = (props.curCpty === "primary" || props.curCpty === "template" ? 
      cStateWithE4.createEntity("PROPOSEDADDSECONDARY", "MUTABLE", {"rev": true, "user": props.user.displayName, "org": props.ownOrgName,"time": lastUpdateDate}) : 
      cStateWithE4.createEntity("PROPOSEDADD", "MUTABLE", {"rev": true, "user": props.user.displayName, "org": props.ownOrgName,"time": lastUpdateDate}))
    const cptyAddKey = cStateWithE5.getLastCreatedEntityKey();
    const cStateWithE6 = (props.curCpty === "primary" || props.curCpty === "template" ? 
      cStateWithE5.createEntity("PROPOSEDDELSECONDARY", "MUTABLE", {"rev": true, "user": props.user.displayName, "org": props.ownOrgName,"time": lastUpdateDate}) : 
      cStateWithE5.createEntity("PROPOSEDDEL", "MUTABLE", {"rev": true, "user": props.user.displayName, "org": props.ownOrgName,"time": lastUpdateDate}))
    const cptyDelKey = cStateWithE6.getLastCreatedEntityKey();
    
    this.state = {
      editorState: eState,
      addEntityKey: addKey,
      delEntityKey: delKey,
      raddEntityKey: rejaddKey,
      rdelEntityKey: rejdelKey,
      cptyAddEntityKey: cptyAddKey,
      cptyDelEntityKey: cptyDelKey,
      readOnly: false,
      hasFocus: false,
      callBackStatus: null,
      redlineControls: {
        type: 'closeRedlines',
        hint: null
      },
      smartFieldHandling: {
        enableAdd: false,
        active: null, // active smartfield
        reconcile: null, // This reconcile relates to the SmartField reconciliation (as oppososed to collaborative editing)
        order: [],
      },
    };

    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.handleReturn = this.handleReturn.bind(this);
    this.handleBeforeInput = this.handleBeforeInput.bind(this);
    this.handlePastedText = this.handlePastedText.bind(this);
    this.onDraftEditorCut = this.onDraftEditorCut.bind(this)
    this.handleFocus = this.handleFocus.bind(this);
    this.handleClick = this.handleClick.bind(this)
    this.getEntitiesForSelection = this.getEntitiesForSelection.bind(this);
    this.createSmartField = this.createSmartField.bind(this);
    this.unlinkSmartField = this.unlinkSmartField.bind(this);
    this.myKeyBinding = this.myKeyBinding.bind(this)
    this.redlineRequest = this.redlineRequest.bind(this)
    this.handleSmartFieldOpening = this.handleSmartFieldOpening.bind(this)

    this.onChange = editorState => { // Potentially send one more update from the editor to the parent
      if((!editorState.getSelection().hasFocus && !this.props.reconcileNeeded) || // On-opening and on-blurring and not twice when reconcileneeded changes
      (this.props.active && this.props.cleanMode)) { // Or run call to parents when you unlink an SF in cleanMode 
        this.props.updateClauseBlocks(convertToRaw(editorState.getCurrentContent())) // sent to parent
      }
      this.setState({editorState}); // update state
    }
  }

  componentDidUpdate(prevProps) {
    
    const editorState = this.state.editorState;
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    let res = this.getEntitiesForSelection(contentState, selectionState) // get all entities/smartfields for the block and selection

    if(prevProps.disableEditing !== this.props.disableEditing){ this.setState({ readOnly: this.props.disableEditing }) }
    else if(prevProps.reconcileNeeded !== this.props.reconcileNeeded && this.props.reconcileNeeded && this.props.content !== undefined) { // A reconciliation is needed
      this.props.callbackOptions(this.props.clid, "reconcileHandled", null) // Send "reconcile handled" to parent - to avoid an infinite loop
      // Complete the reconciliation of the editing content (e.g. when changes are made by someone else that need to be shown)
      let content = this.props.content
      if(content.entityMap === undefined || content.entityMap === null || content.entityMap === {}){ Object.assign(content, eMap) }
      let newContentState = convertFromRaw(content);
      let b = newContentState.getFirstBlock().getKey();
      let newEditorState = EditorState.push(editorState, newContentState)
      let newSelectionState = newEditorState.getSelection()
      newSelectionState = newSelectionState.merge({ // Move cursor to the start of the first block
        anchorKey: b, 
        anchorOffset: 0, 
        focusKey: b, 
        focusOffset: 0,
        isBackward: false });
      newEditorState = EditorState.forceSelection(newEditorState, newSelectionState)
      this.onChange(newEditorState); // Execute the reconcilation

    } else if(this.props.clstatus === 'proposedDel') {
      this.redlineRequest("closeRedlines", null)

    } else if(this.props.optionClicked !== null && Boolean(this.props.optionClicked.option) && // Regardless of whether it has focus or not
      ['focusStart', 'focusEnd'].includes(this.props.optionClicked.option)) { // Handle focus change Requests.

        this.props.callbackOptions(this.props.clid, "clickHandled", null) // Send "click handled" to parent - to avoid an infinite loop
        let bkey = ['focusEnd'].includes(this.props.optionClicked.option) ? res.blocks[res.blocks.length - 1] : res.blocks[0];
        let pos = ['focusEnd'].includes(this.props.optionClicked.option) ? contentState.getLastBlock().getLength() : 0;
        window.scrollBy({ top: ['focusEnd'].includes(this.props.optionClicked.option) ? -200 : 200, behavior: 'smooth'});
        let newSelectionState = selectionState.merge({ anchorKey: bkey, anchorOffset: pos, focusKey: bkey, focusOffset: pos, isBackward: false });
        let newEditorState = EditorState.forceSelection(editorState, newSelectionState)
        this.onChange(newEditorState)
      
    } else if(this.state.hasFocus && !(prevProps.reconcileNeeded && !this.props.reconcileNeeded)) {

      if(this.props.optionClicked !== null && !Boolean(this.props.optionClicked.option)) { // No option was clicked
        // Determine which smartfield controls apply
        if(this.props.smartMode) {
          if(!selectionState.isCollapsed() && ['notfound'].includes(res.sfMsg) && !this.state.smartFieldHandling.enableAdd) {
            // if you have zero and not collapsed - enable create SmartField
            this.setState({ smartFieldHandling: { enableAdd: true, active: null, reconcile: null, order: res.sfOrder }})
          } else if(((selectionState.isCollapsed() && ['notfound'].includes(res.sfMsg)) || ['multiple', 'multiblock'].includes(res.sfMsg)) && 
          ((this.state.smartFieldHandling.enableAdd) || this.state.smartFieldHandling.active !== null || this.state.smartFieldHandling.reconcile !== null)) {
            // Toggle back, don't allow to create if you have multiple or no selection at all
            this.setState({ smartFieldHandling: { enableAdd: false, active: null, reconcile: null, order: res.sfOrder }})
          } else if(['found'].includes(res.sfMsg) && (this.state.smartFieldHandling.active === null || (this.state.smartFieldHandling.active !== undefined && this.state.smartFieldHandling.active.ref !== res.sfs[0].style))) {
            // if you found one - activate it
            this.setState({ smartFieldHandling: { enableAdd: false, active: {ref: res.sfs[0].style, text: res.sfs[0].text}, reconcile: null, order: res.sfOrder }})
          } else if(res.sfOrder.length !== 0 && this.state.smartFieldHandling.order.length === 0) {
            // If you haven't defined the order yet, but there is one
            this.setState({ smartFieldHandling: { enableAdd: false, active: null, reconcile: null, order: res.sfOrder }})
          }
        }

        // Determine which redline controls apply
        if(res.selectionEntities.length > 0){
          let eType = []
          res.selectionEntities.forEach((e) => { eType.push(e.type) })
          this.handleRedlineControlsByEntityTypes(eType) // The user clicks into a redline
        } else if (res.blockEntities.length > 0) { 
          this.handleRedlineControlsByEntityTypes(['minimal']) // The user clicks outside of a redline but there is at least one entity
        } else { 
          this.redlineRequest("closeRedlines", null)
        }

        // Determine whether clause title/regular font button applies
        let blockMap = contentState.getBlockMap()
        let firstBlock = contentState.getFirstBlock()
        let startKey = selectionState.getStartKey()
        let endKey = selectionState.getEndKey()
        let startBlock = contentState.getBlockForKey(startKey);
        if(//blockMap.size > 1 && // there are at least two blocks
          blockMap.size > 0 && // there is at least one block
          !blockMap.some((cb) => cb.getType() === 'clauseTitle') && // clause has no title yet
          firstBlock.getKey() === startKey && // your selection is in the first block
          firstBlock.getKey() === endKey &&
          firstBlock.getLength() < 100) {
          this.props.callbackOptions(this.props.clid, "blockStyleButton", 'clauseTitle') // This could become a clause title
        } else if(startKey === endKey && startBlock !== undefined && startBlock.getType() === 'clauseTitle') {
          this.props.callbackOptions(this.props.clid, "blockStyleButton", 'clausePar') // This is a clause title, offer regular font option
        } else {
          this.props.callbackOptions(this.props.clid, "blockStyleButton", null)
        }

      } else if(this.props.optionClicked !== null && Boolean(this.props.optionClicked.option)) { // AN OPTION WAS CLICKED "FROM ABOVE"
        let click = this.props.optionClicked.option // only 'insertTitle'
        this.props.callbackOptions(this.props.clid, "clickHandled", null) // Send "click handled" to parent - to avoid an infinite loop

        if(['insertTitle'].includes(click)){
          let newEditorState = RichUtils.toggleBlockType(editorState, 'clauseTitle')
          this.onChange(newEditorState);
        }
      }
    } else if(res.sfOrder.length !== 0 && this.state.smartFieldHandling.order.length === 0) { // For disabled editors - set the smartfield order once
      //let res = this.getEntitiesForSelection(contentState, selectionState)
      this.setState({ smartFieldHandling: { enableAdd: false, active: null, reconcile: null, order: res.sfOrder }})
    }
  }

  unlinkSmartField(sf) {
    // TURN OFF ACTIVE SMART FIELD FOR SELECTION
    let editorState = this.state.editorState;
    let newContentState = editorState.getCurrentContent();
    let newSelectionState = editorState.getSelection()
    let deselectBlock = '', deselectPos = ''

    let converted = convertToRaw(newContentState)
    converted.blocks.forEach((b) => {
      b.inlineStyleRanges.forEach((iStyle) => { 

        if(iStyle.style === sf) {
          deselectBlock = b.key
          deselectPos = iStyle.offset + iStyle.length

          newSelectionState = newSelectionState.merge({ 
            anchorKey: b.key, 
            anchorOffset: iStyle.offset, 
            focusKey: b.key, 
            focusOffset: iStyle.offset + iStyle.length,
            isBackward: false }); 

          newContentState = Object.keys(smartfieldStyleMap)
            .reduce((newContentState, sf) => {
              return Modifier.removeInlineStyle(newContentState, newSelectionState, sf)
            }, editorState.getCurrentContent());
        }

      })
    })

    let newEditorState = EditorState.push(
      editorState,
      newContentState,
      'change-inline-style'
    );

    // Now "deselect" by forcing a selection on the end of the original selection
    if(deselectBlock !== '' && deselectPos !== '') {
      newSelectionState = newSelectionState.merge({ anchorKey: deselectBlock, anchorOffset: deselectPos, focusKey: deselectBlock, focusOffset: deselectPos, isBackward: false });
      newEditorState = EditorState.forceSelection(newEditorState, newSelectionState)  
    }

    this.onChange(newEditorState);
  }

  handleSmartFieldOpening(sfTag) {
    this.setState({ smartFieldHandling: { 
      enableAdd: false, 
      active: 
        this.state.smartFieldHandling.active !== undefined && 
        this.state.smartFieldHandling.active !== null && 
        this.state.smartFieldHandling.active.ref !== undefined && 
        this.state.smartFieldHandling.active.ref === sfTag ? 
            null : 
            {ref: sfTag, text: null, open: true}, 
      reconcile: null, 
      order: this.state.smartFieldHandling.order }})
  }

  redlineRequest(type, hint) {
    if(this.state.redlineControls.type !== type || 
      (this.state.redlineControls.hint === null && hint !== null) ||
      (this.state.redlineControls.hint !== null && hint !== null && 
        (this.state.redlineControls.hint.aHint !== hint.aHint || 
         this.state.redlineControls.hint.rHint !== hint.rHint))){
      this.setState({redlineControls: { type: type, hint: hint }})
    }
  }

  createSmartField() {
    const { editorState } = this.state;
    let newSelectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    let startKey = newSelectionState.getStartKey()
    let startOffset = newSelectionState.getStartOffset()
    let endKey = newSelectionState.getEndKey()
    let endOffset = newSelectionState.getEndOffset()

    if(startKey !== endKey) { return; } // MULTIBLOCK SELECTION - NOT POSSIBLE FOR SMARTFIELDS

    // Retrieve which SFs are already taken and determine earliest available SF to use
    let existingSFs = [], targetSF = null, tempSF = {}
    contentState.getBlockMap().forEach((cb) => {
      let bKey = cb.getKey()
      cb.getCharacterList().forEach((cmd, i) => {
        if(cmd.getStyle().size > 0) {
          let arr = cmd.getStyle().toArray()
          if(tempSF.key !== arr[0]) { // If the found SF "arr[0]" differs from tempSF
            if(tempSF.key !== undefined) { existingSFs.push(tempSF) } // push the old one
            tempSF = { bKey: bKey, key: arr[0], startPos: i, endPos: i } // reinitilize the new one
          } else {
            tempSF.endPos = i // otherwise - keep updating the endPos
          }
        }
      })
    })
    if(tempSF.key !== undefined) { existingSFs.push(tempSF) } // push the last Smartfield

    if(existingSFs.length > 0) {
      ['sf1','sf2','sf3','sf4','sf5','sf6','sf7','sf8','sf9','sf10','sf11','sf12','sf13','sf14', 'sf15'].sort().forEach((sf) => {
        if(targetSF === null && !existingSFs.some((esf) => esf.key === sf)) { targetSF = sf } // Take the first available SF
      })
    } else { targetSF = 'sf1'; } // No existing SmartFields yet

    if(targetSF === null) { return; } // ALL SMARTFIELDS ARE TAKEN - TODO WARN END USER
    
    if(existingSFs.filter((esf) => startKey === esf.bKey && (
      (startOffset <= esf.startPos && endOffset >= esf.startPos) || // selection overlaps start of the entity
      (startOffset >= esf.startPos && startOffset <= esf.endPos)))[0] !== undefined) { // selection overlaps with end of the entity
      return // DISABLE OVERWRITING AN EXISTING ENTITY
    }

    // Check if the selection starts with spaces or ends with spaces, in which case you need to adjust (ie. trim the selection)
    let block = contentState.getBlockForKey(startKey);
    let aOffset = startOffset + block.getText().substring(startOffset, endOffset).search(/\S/)
    let fOffset = endOffset - block.getText().substring(startOffset, endOffset).split("").reverse().join("").search(/\S/)
    newSelectionState = newSelectionState.merge({ 
      anchorKey: startKey, 
      anchorOffset: aOffset, 
      focusKey: startKey, 
      focusOffset: fOffset, 
      isBackward: false });

    // TURN OFF ACTIVE SMART FIELD FOR SELECTION
    const nextContentState = Object.keys(smartfieldStyleMap)
      .reduce((contentState, sf) => {
        return Modifier.removeInlineStyle(contentState, newSelectionState, sf)
      }, editorState.getCurrentContent());

    let nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      'change-inline-style'
    );

    const currentStyle = editorState.getCurrentInlineStyle();

    // If the color is being toggled on, apply it.
    if (!currentStyle.has(targetSF)) {
      nextEditorState = RichUtils.toggleInlineStyle(
        nextEditorState,
        targetSF
      );
    }

    this.onChange(nextEditorState);
  }

  changeSelection(editorState, selectionState, nextEntity) {
    if(nextEntity !== null) {
      let updatedSelection = selectionState.merge({ 
        anchorKey: nextEntity.blockKey, 
        anchorOffset: nextEntity.startPos, 
        focusKey: nextEntity.blockKey, 
        focusOffset: nextEntity.endPos,
        isBackward: false });
      this.onChange(EditorState.forceSelection(editorState, updatedSelection));
      this.handleRedlineControlsByEntityTypes([nextEntity.type])
    } else {
      this.onChange(EditorState.moveSelectionToEnd(editorState))
      this.redlineRequest("closeRedlines", null)
    }
    return
  }

  handleRedlineControlsByEntityTypes(eTypes) {

    if(eTypes.includes('minimal')) { // Minimal Redline capabilities
      this.redlineRequest("redlineMnml", null)

    } else if(['full'].includes(this.props.editMode) || ( // FULL EDITING, OR PARTIAL EDITING WITH APPLICABLE REDLINES
      ['edit'].includes(this.props.editMode) && ((this.props.curCpty === 'primary' && // primary in edit mode can only accept secondary's add's or del's, or cpty's rejections of our primary redlines
      eTypes.length === eTypes.filter((t) => ['PROPOSEDADDSECONDARY', 'PROPOSEDDELSECONDARY'].includes(t)).length) ||
      (this.props.curCpty === 'secondary' && // secondary in edit mode can only accept primary's add's or del's
      eTypes.length === eTypes.filter((t) => ['PROPOSEDADD', 'PROPOSEDDEL'].includes(t)).length)
      )
    )){ // Full Redline capabilities
      this.redlineRequest("redline", {aHint: this.handleRedlineHint('accept', eTypes), rHint: this.handleRedlineHint('reject', eTypes)})

    } else if(
      ['edit'].includes(this.props.editMode) && ((this.props.curCpty === 'primary' && // primary in edit mode can only accept secondary's add's or del's, or cpty's rejections of our primary redlines
      eTypes.length === eTypes.filter((t) => ['REJECTPADD', 'REJECTPDEL'].includes(t)).length) ||
      (this.props.curCpty === 'secondary' && // secondary in edit mode can only accept primary's add's or del's
      eTypes.length === eTypes.filter((t) => ['REJECTPADDSEC', 'REJECTPDELSEC'].includes(t)).length)
      )) { // Accept Redline capabilities
      this.redlineRequest("redlineLtdAccept", {aHint: this.handleRedlineHint('accept', eTypes), rHint: null})

    } else if(
      ['edit'].includes(this.props.editMode) && ((this.props.curCpty === 'primary' && // primary in edit mode can only reject primary's add's or del's, or cpty's rejections of our secondary redlines
      eTypes.length === eTypes.filter((t) => ['PROPOSEDADD', 'PROPOSEDDEL', 'REJECTPADDSEC', 'REJECTPDELSEC'].includes(t)).length) ||
      (this.props.curCpty === 'secondary' && // secondary in edit mode can only reject secondary's add's or del's
      eTypes.length === eTypes.filter((t) => ['PROPOSEDADDSECONDARY', 'PROPOSEDDELSECONDARY', 'REJECTPADD', 'REJECTPDEL'].includes(t)).length)
      )) { // Reject Redline capabilities
      this.redlineRequest("redlineLtdReject", {aHint: null, rHint: this.handleRedlineHint('reject', eTypes)})

    } else if(['edit'].includes(this.props.editMode)) { // Edit mode and a mixed bag of entityTypes
      this.redlineRequest("redlineMnml", null) // Minimal Redline capabilities

    }
  }

  handleRedlineHint(button, eTypes) { // button is accept or reject, entityTypes are the ones selected, then you need this.props.curCpty

    let hint = null
    if((button === 'accept' && eTypes.filter((t) => ['PROPOSEDADD', 'PROPOSEDADDSECONDARY', 'REJECTPDEL', 'REJECTPDELSEC'].includes(t)).length > 0) ||
       (button === 'reject' && this.props.curCpty === 'primary' && eTypes.filter((t) => ['PROPOSEDDEL'].includes(t)).length > 0) ||
       (button === 'reject' && this.props.curCpty === 'secondary' && eTypes.filter((t) => ['PROPOSEDDELSECONDARY'].includes(t)).length > 0)) {
      hint = 'Inserts text';
    }

    if((button === 'accept' && eTypes.filter((t) => ['PROPOSEDDEL', 'PROPOSEDDELSECONDARY', 'REJECTPADD', 'REJECTPADDSEC'].includes(t)).length > 0) ||
       (button === 'reject' && this.props.curCpty === 'primary' && eTypes.filter((t) => ['PROPOSEDADD'].includes(t)).length > 0) ||
       (button === 'reject' && this.props.curCpty === 'secondary' && eTypes.filter((t) => ['PROPOSEDADDSECONDARY'].includes(t)).length > 0)) {
      hint = hint !== null ? 'conflict' : 'Deletes text';
    }

    if((button === 'reject' && eTypes.filter((t) => ['REJECTPDEL', 'REJECTPDELSEC'].includes(t)).length > 0) ||
       (button === 'reject' && this.props.curCpty === 'primary' && eTypes.filter((t) => ['PROPOSEDADDSECONDARY'].includes(t)).length > 0) ||
       (button === 'reject' && this.props.curCpty === 'secondary' && eTypes.filter((t) => ['PROPOSEDADD'].includes(t)).length > 0)) {
      hint = hint !== null ? 'conflict' : 'Strikes text';
    }

    if((button === 'reject' && eTypes.filter((t) => ['REJECTPADD', 'REJECTPADDSEC'].includes(t)).length > 0)) {
      hint = hint !== null ? 'conflict' : 'Un-strikes text';
    }

    if((button === 'reject' && this.props.curCpty === 'primary' && eTypes.filter((t) => ['PROPOSEDDELSECONDARY'].includes(t)).length > 0) ||
       (button === 'reject' && this.props.curCpty === 'secondary' && eTypes.filter((t) => ['PROPOSEDDEL'].includes(t)).length > 0)) {
      hint = hint !== null ? 'conflict' : 'Updates redline';
    }

    return hint === 'conflict' ? null : hint;

  }

  handleReviewDecision(decision, entity, nextEntity, contentState, selectionState, lastEntity, nextBlockToDeleteMaybe){

    let updatedSelection = selectionState.merge({ 
      anchorKey: entity.blockKey, 
      anchorOffset: entity.startPos, 
      focusKey: entity.blockKey, 
      focusOffset: entity.endPos,
      isBackward: false });

    if(entity.type !== null && (
      (decision === 'accept' && ['PROPOSEDADD', 'PROPOSEDADDSECONDARY', 'REJECTPDEL', 'REJECTPDELSEC'].includes(entity.type)) ||
      (decision === 'reject' && ['PROPOSEDDEL'].includes(entity.type) && this.props.curCpty === "primary") ||
      (decision === 'reject' && ['PROPOSEDDELSECONDARY'].includes(entity.type) && this.props.curCpty === "secondary"))){
      // Formalize text to regular font
      const newContentState = Modifier.applyEntity(contentState, updatedSelection, null, );
      const newEditorState = EditorState.push(this.state.editorState, newContentState)
      this.changeSelection(newEditorState, updatedSelection, (lastEntity ? null : nextEntity));

    } else if((decision === 'accept' && entity.type !== null && ['PROPOSEDDEL', 'PROPOSEDDELSECONDARY', 'REJECTPADD', 'REJECTPADDSEC'].includes(entity.type)) ||
      (decision === 'reject' && entity.type !== null && this.props.curCpty === 'primary' && ['PROPOSEDADD'].includes(entity.type)) ||
      (decision === 'reject' && entity.type !== null && this.props.curCpty === 'secondary' && ['PROPOSEDADDSECONDARY'].includes(entity.type))) {
      // Delete text / redline
      let newEditorState;

      if(this.isWholeBlock(contentState, entity.blockKey, entity.endPos - entity.startPos)) {
        newEditorState = this.removeBlockFromBlockMap(this.state.editorState, entity.blockKey)
        if(nextBlockToDeleteMaybe !== null) {
          newEditorState = this.removeBlockFromBlockMap(newEditorState, nextBlockToDeleteMaybe)
        }
      } else {
        const newContentState = Modifier.replaceText(contentState, updatedSelection, '');
        newEditorState = EditorState.push(this.state.editorState, newContentState)
        this.pushSmartFieldReconciliation(newContentState) // when accepting/rejecting that results in loss of text
      }
      this.changeSelection(newEditorState, updatedSelection, nextEntity === null || lastEntity ? null : {
        blockKey: nextEntity.blockKey, 
        startPos: entity.blockKey !== nextEntity.blockKey || entity.startPos > nextEntity.startPos ? nextEntity.startPos : nextEntity.startPos - (entity.endPos - entity.startPos),
        endPos: entity.blockKey !== nextEntity.blockKey || entity.startPos > nextEntity.startPos ? nextEntity.endPos : nextEntity.endPos - (entity.endPos - entity.startPos),
        type: nextEntity.type });

    } else if (decision === 'reject' && entity.type !== null && (
      (['PROPOSEDDEL'].includes(entity.type) && this.props.curCpty === "secondary") ||
      (['PROPOSEDDELSECONDARY'].includes(entity.type) && this.props.curCpty === "primary"))) {
      // Apply rejected proposed del key 
      const newContentState = Modifier.applyEntity(contentState, updatedSelection, this.state.rdelEntityKey, );
      const newEditorState = EditorState.push(this.state.editorState, newContentState)
      this.changeSelection(newEditorState, updatedSelection, (lastEntity ? null : nextEntity));

    } else if (decision === 'reject' && entity.type !== null && (
      (['PROPOSEDADD'].includes(entity.type) && this.props.curCpty === "secondary") ||
      (['PROPOSEDADDSECONDARY'].includes(entity.type) && this.props.curCpty === "primary"))) {
      // Apply rejected proposed add key
      const newContentState = Modifier.applyEntity(contentState, updatedSelection, this.state.raddEntityKey, );
      const newEditorState = EditorState.push(this.state.editorState, newContentState)
      this.changeSelection(newEditorState, updatedSelection, (lastEntity ? null : nextEntity));

    } else if(decision === 'reject' && entity.type !== null && ['REJECTPADD', 'REJECTPADDSEC', 'REJECTPDEL', 'REJECTPDELSEC'].includes(entity.type)) {
      // Apply appropriate key for updated redline
      let newKey = 
        this.props.curCpty === 'primary' && ['REJECTPADD'].includes(entity.type) ? this.state.addEntityKey :
        this.props.curCpty === 'secondary' && ['REJECTPADD'].includes(entity.type) ? this.state.cptyAddEntityKey :

        this.props.curCpty === 'primary' && ['REJECTPADDSEC'].includes(entity.type) ? this.state.cptyAddEntityKey :
        this.props.curCpty === 'secondary' && ['REJECTPADDSEC'].includes(entity.type) ? this.state.addEntityKey :

        this.props.curCpty === 'primary' && ['REJECTPDEL'].includes(entity.type) ? this.state.delEntityKey :
        this.props.curCpty === 'secondary' && ['REJECTPDEL'].includes(entity.type) ? this.state.cptyDelEntityKey :

        this.props.curCpty === 'primary' && ['REJECTPDELSEC'].includes(entity.type) ? this.state.cptyDelEntityKey :
        this.props.curCpty === 'secondary' && ['REJECTPDELSEC'].includes(entity.type) ? this.state.delEntityKey : null

      if(newKey !== null) {
        const newContentState = Modifier.applyEntity(contentState, updatedSelection, newKey, );
        const newEditorState = EditorState.push(this.state.editorState, newContentState)
        this.changeSelection(newEditorState, updatedSelection, (lastEntity ? null : nextEntity));
      }
    }
  }

  isWholeBlock(contentState, blockKey, length) {
    let b = false;
    let block = contentState.getBlockForKey(blockKey);
    // If the block is complete, but there is still another block before or after then return true
    // If there is no block before or after then you would remove the last block in the editor
    if(block.getLength() === length && (contentState.getBlockBefore(blockKey) !== undefined || contentState.getBlockAfter(blockKey) !== undefined)) { b = true; }
    return b;
  }

  removeBlockFromBlockMap(editorState, blockKey) {
    var contentState = editorState.getCurrentContent();
    var blockMap = contentState.getBlockMap();
    var newBlockMap = blockMap.remove(blockKey)
    var newContentState = contentState.merge({ blockMap: newBlockMap })
    var newEditorState = EditorState.push(editorState, newContentState, 'remove-range')
    return newEditorState
  }

  myKeyBinding(e) { // http://gcctech.org/csc/javascript/javascript_keycodes.htm
    let editorState = this.state.editorState
    let contentState = editorState.getCurrentContent();
    let selectionState = editorState.getSelection();

    if (e.keyCode === 8 && isOptionKeyCommand(e)) { /* Option + Backspace (backward) */
      return 'backspaceOption';
    } else if (e.keyCode === 8) { /* Backspace (backward) */
      return 'backspace';
    } else if (e.keyCode === 46) { /* Delete (forward) */
      return 'delete';
    } else if (e.keyCode === 88 && hasCommandModifier(e)){ /* Cmd + X (cut) */
      return 'cut-to-clipboard';
    } else if (hasCommandModifier(e) && /* Cmd */
                (e.keyCode === 66 /* b */|| 
                 e.keyCode === 85 /* u */|| 
                 e.keyCode === 73 /* i */|| 
                 e.keyCode === 74 /* j */)){
      return 'ignore';
    } else if ([37,38].includes(e.keyCode) && // arrow left 37, arrow up 38 - at the very start of the clause
    selectionState.isCollapsed() && // There was no selection
    contentState.getFirstBlock().getKey() === selectionState.getStartKey() && selectionState.getStartOffset() === 0){ 
      return 'leftUp'
    } else if ([39,40].includes(e.keyCode) && // arrow right 39, arrow up 40 - at the very end of the clause
    selectionState.isCollapsed() && // There was no selection
    contentState.getLastBlock().getKey() === selectionState.getStartKey() && selectionState.getStartOffset() === contentState.getLastBlock().getLength()){
      return 'rightDown'
    }
    return getDefaultKeyBinding(e);
  }

  handleKeyCommand(command, editorState) {

    if(command === 'ignore') { return 'handled'; }
    let newEditorState = editorState
    let newSelectionState = newEditorState.getSelection();
    let newContentState = newEditorState.getCurrentContent();
    let blockKey = newSelectionState.getFocusKey();
    let block = newContentState.getBlockForKey(blockKey);

    if(!newSelectionState.isCollapsed()) { // There was a selection

      let blocksToDelete  = [] // array for blocks to Delete
      let res = this.getEntitiesForSelection(newContentState, newSelectionState)
      
      if (res.selectionEntities.length > 0) { // There are entities inside the selection
        let selStart = newSelectionState.getStartOffset()
        let selStartKey = newSelectionState.getStartKey()
        let selEnd = newSelectionState.getEndOffset()
        let selEndKey = newSelectionState.getEndKey()
        let previousBlockWasDeleted = false;

        res.blocksInSelection.forEach((b) => { // .slice().reverse()

          let block = newContentState.getBlockForKey(b);
          let startBlockScope = 0;
          let endBlockScope = block.getLength();

          if(b === selStartKey) { startBlockScope = selStart; } 
          if(b === selEndKey) { endBlockScope = selEnd; }

          if(!res.selectionEntities.some((se) => se.blockKey === b) && block.getLength() > 0){ // No entity exists in this block - PDel the selected part
            newSelectionState = newSelectionState.merge({ anchorKey: b, anchorOffset: startBlockScope, focusKey: b, focusOffset: endBlockScope, isBackward: false });
            newContentState = Modifier.applyEntity(newContentState, newSelectionState, this.state.delEntityKey, );
            newEditorState = EditorState.push(newEditorState, newContentState)
            previousBlockWasDeleted = false;

          } else if(block.getLength() === 0 && previousBlockWasDeleted) {
            newEditorState = this.removeBlockFromBlockMap(newEditorState, b);
            newContentState = newEditorState.getCurrentContent()

          } else if (block.getLength() > 0) { // At least one entity exists inside this block

            let subtract = 0;
            let newStart = startBlockScope;

            let relevantEntities = res.selectionEntities.filter((e) => e.blockKey === b);
            relevantEntities.forEach((e, i) => { // Loop through the entities inside the block

              if(newStart < e.startPos - subtract) { // PDel selection before the entity starts and after the prev. entity ends
                newSelectionState = newSelectionState.merge({ anchorKey: b, anchorOffset: newStart, focusKey: b, focusOffset: e.startPos - subtract, isBackward: false });
                newContentState = Modifier.applyEntity(newContentState, newSelectionState, this.state.delEntityKey, );
                newEditorState = EditorState.push(newEditorState, newContentState)
              }
 
              if((e.type === 'PROPOSEDADD' && this.props.curCpty === 'primary') || 
              (e.type === 'PROPOSEDADDSECONDARY' && this.props.curCpty === 'secondary')) { // DELETE a PADD for SELF
                if(e.startPos === startBlockScope && e.endPos === endBlockScope && 
                   startBlockScope === 0 && endBlockScope === block.getLength() && newContentState.getBlocksAsArray().length > 1) { // PADD spans entire block - therefore remove block
                  newEditorState = this.removeBlockFromBlockMap(newEditorState, b);
                  newContentState = newEditorState.getCurrentContent()
                  previousBlockWasDeleted = true;
                } else {
                  newSelectionState = newSelectionState.merge({ anchorKey: b, anchorOffset: e.startPos - subtract, focusKey: b, focusOffset: e.endPos - subtract, isBackward: false });
                  newContentState = Modifier.replaceText(newContentState, newSelectionState, '');
                  newEditorState = EditorState.push(editorState, newContentState)
                  subtract = subtract + (e.endPos - e.startPos)
                  previousBlockWasDeleted = false;
                }
              } else if((e.type === 'PROPOSEDADD' && this.props.curCpty === 'secondary') || // Attempting to delete Cpty's proposed Add
              (e.type === 'PROPOSEDADDSECONDARY' && this.props.curCpty === 'primary')){ // Reject PAdd
                newSelectionState = newSelectionState.merge({ anchorKey: b, anchorOffset: e.startPos - subtract, focusKey: b, focusOffset: e.endPos - subtract, isBackward: false });
                newContentState = Modifier.applyEntity(newContentState, newSelectionState, this.state.raddEntityKey, );
                newEditorState = EditorState.push(newEditorState, newContentState)
              
              } else { // If any different entity: ignore and jump to the next
                newStart = e.endPos
                previousBlockWasDeleted = false;
              }

              if(e.endPos < endBlockScope) { // Delete selection after the entity ends but before the next starts or until end of block
                let endCut = (relevantEntities[i+1] !== undefined ? relevantEntities[i+1].startPos - subtract : endBlockScope - subtract)
                if(e.endPos - subtract !== endCut){
                  newSelectionState = newSelectionState.merge({ anchorKey: b, anchorOffset: e.endPos - subtract, focusKey: b, focusOffset: endCut, isBackward: false });
                  newContentState = Modifier.applyEntity(newContentState, newSelectionState, this.state.delEntityKey, );
                  newEditorState = EditorState.push(newEditorState, newContentState)
                }
                newStart = endCut;
              }

            })

          }
        })

        this.pushSmartFieldReconciliation(newContentState) // when typing over a selection - with potential loss of text

        // Now "deselect" by forcing a selection on the end of the original selection
        newSelectionState = newSelectionState.merge({ anchorKey: selStartKey, anchorOffset: selStart, focusKey: selStartKey, focusOffset: selStart, isBackward: false });
        newEditorState = EditorState.forceSelection(newEditorState, newSelectionState)

      } else { // There were no entities inside the selection
        let onlyEmptyBlocks = true;
        res.blocksInSelection.forEach((b) => { // pull empty blocks to delete
          let block = newContentState.getBlockForKey(b);
          if(block.getLength() === 0) { blocksToDelete.push(b) } 
          else { onlyEmptyBlocks = false }
        })

        if (onlyEmptyBlocks) { // Delete only empty blocks
          blocksToDelete.slice().reverse().forEach((b) => {
            newEditorState = this.removeBlockFromBlockMap(newEditorState, b);
          })
        } else { // There is more selected than only empty blocks

          // If the character before and after the selection is a space, then update selection to include the last space
          if(block.getText().substr(newSelectionState.getStartOffset() - 1, 1) === ' ' && block.getText().substr(newSelectionState.getEndOffset(), 1) === ' ') {
            newSelectionState = newSelectionState.merge({ 
              focusOffset: (newSelectionState.getFocusOffset() === newSelectionState.getEndOffset()) ? newSelectionState.getEndOffset() + 1 : newSelectionState.getFocusOffset(),
              anchorOffset: (newSelectionState.getAnchorOffset() === newSelectionState.getEndOffset()) ? newSelectionState.getEndOffset() + 1 : newSelectionState.getAnchorOffset(),
            });
          }
          newContentState = Modifier.applyEntity(newContentState, newSelectionState, this.state.delEntityKey, /* proposedDelete */ );

          newEditorState = EditorState.push(newEditorState, newContentState);
          newSelectionState = newSelectionState.merge({ focusKey: newSelectionState.getAnchorKey(), focusOffset: newSelectionState.getAnchorOffset() });
          newEditorState = EditorState.forceSelection(newEditorState, newSelectionState)
        }

      }

      this.onChange(newEditorState); // Complete actions for the scenarios where there was a selection
      if (command === 'cut-to-clipboard'){ document.execCommand("copy"); }
      return 'handled';

    } else if (['backspace', 'backspaceOption', 'delete'].includes(command)) { // There was no selection and backspace/delete was hit
      let currentOffset = (newSelectionState.getEndOffset());
      let newAnchorOffset, newFocusOffset, newOffset;
      if (command === 'backspace' || command === 'backspaceOption') {
        newAnchorOffset = (currentOffset - 1);
        newFocusOffset = currentOffset;
        newOffset = newAnchorOffset;
      } else if (command === 'delete') {
        newAnchorOffset = currentOffset;
        newFocusOffset = (currentOffset + 1)
        newOffset = newFocusOffset;
      }
      // Retrieve the entity to determine next step, first get the block
      if(block.getLength() === 0) { // this is an empty block (ie. empty paragraph) - just delete it
        if(newContentState.getBlockBefore(blockKey) === undefined && newContentState.getBlockAfter(blockKey) === undefined){
          return 'handled'
        }
        newEditorState = this.removeBlockFromBlockMap(newEditorState, blockKey);
        this.onChange(newEditorState);
        return 'handled';
      } else if(newAnchorOffset >= block.getLength() && command === 'delete'){ // trying to delete beyond the last character of the paragraph
        if(!['clauseTitle','secTitle','ssecTitle'].includes(block.getType()) && newContentState.getBlockAfter(blockKey) !== undefined
          /*(newContentState.getBlockAfter(blockKey) !== undefined && newContentState.getBlockAfter(blockKey).getLength() === 0) || (
          newContentState.getBlockAfter(blockKey) !== undefined && newContentState.getBlockAfter(blockKey).getLength() > 0)*/) {
          return 'not-handled';
        } else { // e.g. if getBlockAfter results in undefined
          return 'handled';
        }
      } else if(command === 'backspace' && // Trying to backspace and merge a regular font block into a clauseTitle
        currentOffset === 0 && !['clauseTitle','secTitle','ssecTitle'].includes(block.getType()) &&
        newContentState.getBlockBefore(blockKey) !== undefined && ['clauseTitle','secTitle','ssecTitle'].includes(newContentState.getBlockBefore(blockKey).getType())) {
        return 'handled'
      }
      const entityKey = block.getEntityAt(newAnchorOffset);
      if(entityKey !== null && newAnchorOffset >= 0){
        if((newContentState.getEntity(entityKey).getType() === 'PROPOSEDADD' && this.props.curCpty === 'primary')
          || (newContentState.getEntity(entityKey).getType() === 'PROPOSEDADDSECONDARY' && this.props.curCpty === 'secondary')) { // Attempting to delete proposedAdd for self
          // Replace character with nothing (ie. delete character) - and set the cursor

          newSelectionState = newSelectionState.merge({ anchorKey: blockKey, anchorOffset: newAnchorOffset, focusKey: blockKey, focusOffset: newFocusOffset, isBackward: false });
          newContentState = Modifier.replaceText(newContentState, newSelectionState, '');
          this.pushSmartFieldReconciliation(newContentState)
          newEditorState = EditorState.push(newEditorState, newContentState)
          this.onChange(newEditorState);
          return 'handled';
        } else if((newContentState.getEntity(entityKey).getType() === 'PROPOSEDADD' && this.props.curCpty === 'secondary') || // Attempting to delete Cpty's proposed Add
          (newContentState.getEntity(entityKey).getType() === 'PROPOSEDADDSECONDARY' && this.props.curCpty === 'primary')) {

          // Step 1. Select the character and push 'raddEntityKey'
          newSelectionState = newSelectionState.merge({ anchorKey: blockKey, anchorOffset: newAnchorOffset, focusKey: blockKey, focusOffset: newFocusOffset, isBackward: false });
          newContentState = Modifier.applyEntity(newContentState, newSelectionState, this.state.raddEntityKey, /* rejected insertion */ );
          newEditorState = EditorState.push(newEditorState, newContentState)
          this.onChange(newEditorState);
          // Step 2. Change focus on the character before
          newSelectionState = newSelectionState.merge({ 
            anchorKey: blockKey, 
            anchorOffset: newOffset, 
            focusKey: blockKey,
            focusOffset: newOffset,
            isBackward: false });
          this.onChange(EditorState.forceSelection(newEditorState, newSelectionState));
          return 'handled';

        } else if (['PROPOSEDDEL', 'PROPOSEDDELSECONDARY', 'REJECTPADD', 'REJECTPDEL', 'REJECTPADDSEC', 'REJECTPDELSEC'].includes(newContentState.getEntity(entityKey).getType())) { 
          // Attempting to delete proposedDelete or rejected entity
          // if delete - move cursor to the right - if backspace - move cursor to the left
          let charBeforeSelection = newSelectionState.merge({ 
            anchorKey: blockKey, 
            anchorOffset: newOffset, 
            focusKey: blockKey,
            focusOffset: newOffset,
            isBackward: false });
          this.onChange(EditorState.forceSelection(newEditorState, charBeforeSelection));
          return 'handled';
        }

      } else if (newAnchorOffset >= 0) { // Attempting to delete regular content
        // Step 1. Select the character and push 'proposedDelete'
        newSelectionState = newSelectionState.merge({ anchorKey: blockKey, anchorOffset: newAnchorOffset, focusKey: blockKey, focusOffset: newFocusOffset, isBackward: false });
        newContentState = Modifier.applyEntity(newContentState, newSelectionState, this.state.delEntityKey, /* proposedDelete */ );
        newEditorState = EditorState.push(newEditorState, newContentState)
        this.onChange(newEditorState);
        // Step 2. Change focus on the character before
        newSelectionState = newSelectionState.merge({ 
          anchorKey: blockKey, 
          anchorOffset: newOffset, 
          focusKey: blockKey,
          focusOffset: newOffset,
          isBackward: false });
        this.onChange(EditorState.forceSelection(newEditorState, newSelectionState));
        return 'handled';
      }
    } else if(['leftUp'].includes(command)) { // Toggle to the previous Clause
      this.props.callbackOptions(this.props.clid, "previousClause", null)
      document.activeElement.blur();

    } else if(['rightDown'].includes(command)) { // Toggle to the next Clause
      this.props.callbackOptions(this.props.clid, "nextClause", null)
      document.activeElement.blur();

    }
    return 'not-handled';
  }

  handleReturn(e, editorState){
    const selectionState = editorState.getSelection();
    let contentState = editorState.getCurrentContent();

    //let sf = this.getSmartFieldForSelection(contentState, selectionState);
    let res = this.getEntitiesForSelection(contentState, selectionState)
    let focusKey = selectionState.getFocusKey();
    let focusOffset = selectionState.getFocusOffset();

    if(!selectionState.isCollapsed()) {
      return 'handled' // There was a selection before pressing enter
    } else if(['clauseTitle','secTitle','ssecTitle'].includes(contentState.getBlockForKey(selectionState.getFocusKey()).getType()) || res.sfMsg === 'found') { 
      
      // Enter special handling
      let newEditorState = editorState
      let block = contentState.getBlockForKey(selectionState.getFocusKey())
      
      // SPECIAL HANDLING 1: Pressing "Enter" inside a Smart Field
      if(res.sfMsg === 'found') {

        let newSelectionState = selectionState.merge({ 
          anchorKey: focusKey, 
          anchorOffset: res.sfs[0].offset, 
          focusKey: focusKey, 
          focusOffset: res.sfs[0].offset + res.sfs[0].length,
          isBackward: false }); 
    
        let newContentState = Object.keys(smartfieldStyleMap) // Unlink the smart field
        .reduce((contentState, sf) => {
          return Modifier.removeInlineStyle(contentState, newSelectionState, sf)
        }, editorState.getCurrentContent());
    
        newEditorState = EditorState.push( editorState, newContentState, 'change-inline-style' );
    
        // Now reverting selection to where you were before unlinking
        newSelectionState = newSelectionState.merge({ anchorKey: focusKey, anchorOffset: focusOffset, focusKey: focusKey, focusOffset: focusOffset, isBackward: false });
        newEditorState = EditorState.forceSelection(newEditorState, newSelectionState)
  
        newContentState = Modifier.splitBlock( newEditorState.getCurrentContent(), newSelectionState )
        newEditorState = EditorState.push(newEditorState, newContentState)

        this.pushSmartFieldReconciliation(newContentState)
      }

      // SPECIAL HANDLING 2: Pressing Enter at the end of a clauseTitle Block - change the new block to regular
      if(['clauseTitle','secTitle','ssecTitle'].includes(block.getType()) && block.getLength() === selectionState.getFocusOffset()) {

        // Manually insert a new "regular font" block and move the selection there
        let newContentState = Modifier.splitBlock(contentState, selectionState);
        newEditorState = EditorState.push(newEditorState, newContentState, 'split-block') // Create new block
        newEditorState = RichUtils.toggleBlockType(newEditorState, 'clausePar') // Then change to "regular font"
      }

      this.onChange(newEditorState);
      return 'handled'

    }
  }

  handleClick(click) {

    if(['acceptagr','toggleActivateClause'].includes(click)) { // AGREEMENT LEVEL CLICKS
      this.props.callbackOptions(this.props.clid, click, null)

    } else if(['right', 'left', 'reject', 'accept', 'acceptall', 'rejectall'].includes(click)) { // REDLINE CONTROLS
      const editorState = this.state.editorState;
      const contentState = editorState.getCurrentContent();
      const selectionState = editorState.getSelection();
      let res = this.getEntitiesForSelection(contentState, selectionState) // get all entities for the block and selection
      
      if (res.blockEntities.length > 0) { // You have at least one entity selected

        if (['left', 'right'].includes(click) || (['accept', 'reject'].includes(click) && selectionState.isCollapsed())) {
          this.changeSelection(
            editorState, 
            selectionState, 
            click === 'left' ? res.prevEntity : res.nextEntity)

        } else if(['accept', 'reject'].includes(click) && res.selectionEntities.length === 1 && !selectionState.isCollapsed()) {
          let nextBlockToDeleteMaybe = null // Determines whether the next block is empty and may need to be deleted
          let idx = res.blocks.findIndex((b) => b === res.selectionEntities[0].blockKey); // get next blockKey
          let nextBlock = contentState.getBlockForKey(res.blocks[idx + 1]);
          if(nextBlock !== undefined && nextBlock !== null && nextBlock.getLength() === 0 && res.blocks.length > 2) {
            nextBlockToDeleteMaybe = res.blocks[idx + 1];
          }

          this.handleReviewDecision(
            click, // decision 
            res.selectionEntities[0], // entity
            res.nextEntity, // nextEntity 
            contentState, 
            selectionState,
            res.blockEntities.length === 1, // lastEntity
            nextBlockToDeleteMaybe,
          )
          
        } else if((['accept', 'reject'].includes(click) && res.selectionEntities.length > 1 && !selectionState.isCollapsed()) ||
                  ['acceptall', 'rejectall'].includes(click)) {

          let newEditorState = editorState;
          let newContentState = contentState;
          let updatedSelection = selectionState;
          let updatableEntities = []
          if(['acceptall', 'rejectall'].includes(click)) {
            updatableEntities = res.blockEntities
          } else {
            updatableEntities = res.selectionEntities
          }

          updatableEntities.slice().reverse().forEach((e) => { // Loop through all entities and handle accordingly

            updatedSelection = updatedSelection.merge({ anchorKey: e.blockKey, anchorOffset: e.startPos, focusKey: e.blockKey, focusOffset: e.endPos, isBackward: false });
            if((['accept', 'acceptall'].includes(click) && ['PROPOSEDADD','PROPOSEDADDSECONDARY','REJECTPDEL','REJECTPDELSEC'].includes(e.type)) ||
              (['reject', 'rejectall'].includes(click) && (['PROPOSEDDEL','PROPOSEDDELSECONDARY', 'REJECTPADD', 'REJECTPADDSEC', 'REJECTPDEL', 'REJECTPDELSEC'].includes(e.type) ||
              (['PROPOSEDADDSECONDARY'].includes(e.type) && this.props.curCpty === 'primary') || (['PROPOSEDADD'].includes(e.type) && this.props.curCpty === 'secondary')))) {

              let newEnt = 
                (['accept', 'acceptall'].includes(click) && ['PROPOSEDADD','PROPOSEDADDSECONDARY','REJECTPDEL','REJECTPDELSEC'].includes(e.type)) ||
                  (['reject', 'rejectall'].includes(click) && (
                  (['PROPOSEDDEL'].includes(e.type) && this.props.curCpty === 'primary') ||
                  (['PROPOSEDDELSECONDARY'].includes(e.type) && this.props.curCpty === 'secondary'))) ? null :
                ['reject', 'rejectall'].includes(click) && (
                  (['PROPOSEDADDSECONDARY'].includes(e.type) && this.props.curCpty === 'primary') ||
                  (['PROPOSEDADD'].includes(e.type) && this.props.curCpty === 'secondary')) ? this.state.raddEntityKey :
                ['reject', 'rejectall'].includes(click) && (
                  (['PROPOSEDDELSECONDARY'].includes(e.type) && this.props.curCpty === 'primary') ||
                  (['PROPOSEDDEL'].includes(e.type) && this.props.curCpty === 'secondary')) ? this.state.rdelEntityKey :
                ['reject', 'rejectall'].includes(click) && (
                  (['REJECTPADD'].includes(e.type) && this.props.curCpty === 'primary') ||
                  (['REJECTPADDSEC'].includes(e.type) && this.props.curCpty === 'secondary')) ? this.state.addEntityKey :
                ['reject', 'rejectall'].includes(click) && (
                  (['REJECTPADD'].includes(e.type) && this.props.curCpty === 'secondary') ||
                  (['REJECTPADDSEC'].includes(e.type) && this.props.curCpty === 'primary')) ? this.state.cptyAddEntityKey :
                ['reject', 'rejectall'].includes(click) && (
                    (['REJECTPDEL'].includes(e.type) && this.props.curCpty === 'primary') ||
                    (['REJECTPDELSEC'].includes(e.type) && this.props.curCpty === 'secondary')) ? this.state.delEntityKey :
                ['reject', 'rejectall'].includes(click) && (
                    (['REJECTPDEL'].includes(e.type) && this.props.curCpty === 'secondary') ||
                    (['REJECTPDELSEC'].includes(e.type) && this.props.curCpty === 'primary')) ? this.state.cptyDelEntityKey : null;

              newContentState = Modifier.applyEntity(newContentState, updatedSelection, newEnt, );
              newEditorState = EditorState.push(newEditorState, newContentState)
            
            } else if((['accept', 'acceptall'].includes(click) && ['PROPOSEDDEL','PROPOSEDDELSECONDARY','REJECTPADD','REJECTPADDSEC'].includes(e.type)) ||
            (['reject', 'rejectall'].includes(click) && ((['PROPOSEDADD'].includes(e.type) && this.props.curCpty === 'primary') || (['PROPOSEDADDSECONDARY'].includes(e.type) && this.props.curCpty === 'secondary')))) { 

              if(this.isWholeBlock(newContentState, e.blockKey, e.endPos - e.startPos)) {
                newEditorState = this.removeBlockFromBlockMap(newEditorState, e.blockKey)
                // Check whether the next block is empty and may need to be deleted
                let idx = res.blocks.findIndex((b) => b === e.blockKey); // get next blockKey
                let nextBlock = contentState.getBlockForKey(res.blocks[idx + 1]);
                if(nextBlock !== undefined && nextBlock !== null && nextBlock.getLength() === 0) {
                  newEditorState = this.removeBlockFromBlockMap(newEditorState, res.blocks[idx + 1])                    
                }
                newContentState = newEditorState.getCurrentContent()
              } else {
                newContentState = Modifier.replaceText(newContentState, updatedSelection, '');
                newEditorState = EditorState.push(newEditorState, newContentState)
              }
            }
          })
          
          this.pushSmartFieldReconciliation(newContentState)
          // Final push of all changes for "accept / reject all"
          this.onChange(EditorState.push(newEditorState, newContentState));
          
          if(['acceptall', 'rejectall'].includes(click)) {
            this.redlineRequest("closeRedlines", null)
          }
        }
      }
    } else if (['createSmartField', 'createSmartClause'].includes(click) || click.startsWith('unlinkSmartField_')) { // SMARTFIELD RELATED CLICKS

      if (['createSmartField'].includes(click)) {
        this.createSmartField();

      } else if (click.startsWith('unlinkSmartField_') && click.substr(17) !== undefined && click.substr(17).length > 2){
        this.unlinkSmartField(click.substr(17));

      }
    }
  }

  handleFocus() {
    this.setState({ hasFocus: true, readOnly: this.props.disableEditing })
    this.props.focusClause();

    if(this.props.content.blocks === undefined || this.props.content.blocks.some((b) => b.entityRanges !== undefined && b.entityRanges.length === 0)) {
      this.redlineRequest("closeRedlines", null)
    }
  }

  handleBeforeInput(chars, editorState){ 
    let newSelectionState = editorState.getSelection();
    let newContentState = editorState.getCurrentContent();
    let newEditorState = editorState;
    let hasSelection = !newSelectionState.isCollapsed()
    
    if(!newSelectionState.isCollapsed()) { // There was a selection before entering an insertion
      // Need to ensure that no existing entity is part of the selection
      let res = this.getEntitiesForSelection(newContentState, newSelectionState)
      if (res.selectionEntities.filter((e) => (this.props.curCpty === 'primary' && e.type !== 'PROPOSEDADD') || (this.props.curCpty === 'secondary' && e.type !== 'PROPOSEDADDSECONDARY')).length > 0) {
        return 'handled' // there is an entity in the selection that cannot be deleted, so ignore / handled
      } else {

        let selStart = newSelectionState.getStartOffset()
        let selStartKey = newSelectionState.getStartKey()
        let selEnd = newSelectionState.getEndOffset()
        let selEndKey = newSelectionState.getEndKey()

        res.blocksInSelection.slice().reverse().forEach((b) => {
          let subtractFromPDel = 0;
          let blockLength = newContentState.getBlockForKey(b).getLength()
          res.selectionEntities.filter((e) => e.blockKey === b).slice().reverse().forEach((e) => { // Delete PAdd
            subtractFromPDel = subtractFromPDel + (e.endPos - e.startPos)
            newSelectionState = newSelectionState.merge({ anchorKey: e.blockKey, anchorOffset: e.startPos, focusKey: e.blockKey, focusOffset: e.endPos, isBackward: false });
            newContentState = Modifier.replaceText(newContentState, newSelectionState, '');
            newEditorState = EditorState.push(editorState, newContentState)
          })
          // Now PDel the rest of the block
          let delStart = (b === selStartKey ? selStart : 0);
          let delEnd = (b === selEndKey ? selEnd - subtractFromPDel : blockLength - subtractFromPDel)
          newSelectionState = newSelectionState.merge({ anchorKey: b, anchorOffset: delStart, focusKey: b, focusOffset: delEnd, isBackward: false });
          newContentState = Modifier.applyEntity(newContentState, newSelectionState, this.state.delEntityKey, );
          newEditorState = EditorState.push(newEditorState, newContentState)
        })
      }
      
      // Step 2. Then "deselect" by forcing a selection on the anchorPoint of the original selection
      newSelectionState = newSelectionState.merge({ focusKey: newSelectionState.getAnchorKey(), focusOffset: newSelectionState.getAnchorOffset() });
      newEditorState = EditorState.forceSelection(newEditorState, newSelectionState)
      // Step 3. Now insert the character that was entered
      let blockKey = newSelectionState.getAnchorKey();
      let block = newContentState.getBlockForKey(blockKey);
      let iStyle = 
        newSelectionState.getFocusOffset() > 0 &&
        block.getInlineStyleAt(newSelectionState.getFocusOffset() - 1) !== undefined && block.getInlineStyleAt(newSelectionState.getFocusOffset() - 1).toArray() !== undefined &&
        block.getInlineStyleAt(newSelectionState.getFocusOffset() - 1).toArray().length > 0 ? 
            block.getInlineStyleAt(newSelectionState.getFocusOffset() - 1) :
        hasSelection && block.getInlineStyleAt(newSelectionState.getStartOffset()) !== undefined && block.getInlineStyleAt(newSelectionState.getStartOffset()).toArray() !== undefined &&
        block.getInlineStyleAt(newSelectionState.getStartOffset()).toArray().length > 0 ? 
            block.getInlineStyleAt(newSelectionState.getStartOffset()) : ''

      newSelectionState = newEditorState.getSelection();
      newContentState = Modifier.insertText(newContentState, newSelectionState,chars, iStyle, this.state.addEntityKey);
      newEditorState = EditorState.push(newEditorState, newContentState)
      this.pushSmartFieldReconciliation(newContentState)
      this.onChange(newEditorState);
      return 'handled'

    } else if (newSelectionState.isCollapsed()) { // There was no selection
      
      let blockKey = newSelectionState.getFocusKey();
      let block = newContentState.getBlockForKey(blockKey);
      const entityKey = block.getEntityAt(newSelectionState.getFocusOffset());
      const entityKeyMinusOne = block.getEntityAt(newSelectionState.getFocusOffset() - 1);
      // If you're typing inside a ProposedDel of CPTY then Block - if you typing against it then it's ok
      if (entityKey !== null && (
            (newContentState.getEntity(entityKey).getType() === 'PROPOSEDDEL' && this.props.curCpty === "secondary") ||
            (newContentState.getEntity(entityKey).getType() === 'PROPOSEDDELSECONDARY' && this.props.curCpty === "primary") ||
            ['REJECTPADD', 'REJECTPDEL', 'REJECTPADDSEC', 'REJECTPDELSEC'].includes(newContentState.getEntity(entityKey).getType())) &&
         (entityKeyMinusOne !== null && (
            (newContentState.getEntity(entityKeyMinusOne).getType() === 'PROPOSEDDEL' && this.props.curCpty === "secondary") || 
            (newContentState.getEntity(entityKeyMinusOne).getType() === 'PROPOSEDDELSECONDARY' && this.props.curCpty === "primary") ||
            ['REJECTPADD', 'REJECTPDEL', 'REJECTPADDSEC', 'REJECTPDELSEC'].includes(newContentState.getEntity(entityKeyMinusOne).getType()))) &&
          entityKey === entityKeyMinusOne) {
        return 'handled';
      }

      let iStyle = // Ideally the one behind you, alternatively the one in front of you
          block.getInlineStyleAt(newSelectionState.getFocusOffset() - 1) !== undefined && 
          block.getInlineStyleAt(newSelectionState.getFocusOffset() - 1).toArray() !== undefined &&
          block.getInlineStyleAt(newSelectionState.getFocusOffset() - 1).toArray().length > 0 ? 
              block.getInlineStyleAt(newSelectionState.getFocusOffset() - 1) : 
          block.getInlineStyleAt(newSelectionState.getFocusOffset()) !== undefined && 
          block.getInlineStyleAt(newSelectionState.getFocusOffset()).toArray() !== undefined &&
          block.getInlineStyleAt(newSelectionState.getFocusOffset()).toArray().length > 0 ? 
              block.getInlineStyleAt(newSelectionState.getFocusOffset()) : ''

      newContentState = Modifier.insertText(newContentState,newSelectionState,chars, iStyle, this.state.addEntityKey);
      newEditorState = EditorState.push(editorState, newContentState)
      this.onChange(newEditorState);
      return 'handled'
    } 
    return 'not-handled';
  }

  handlePastedText(text, html, editorState) {
    let newSelectionState = editorState.getSelection();
    let newContentState = editorState.getCurrentContent();
    let newEditorState = editorState;

    let blockKey = newSelectionState.getFocusKey();
    let block = newContentState.getBlockForKey(blockKey);
    const entityKey = block.getEntityAt(newSelectionState.getFocusOffset());
    const entityKeyMinusOne = block.getEntityAt(newSelectionState.getFocusOffset() - 1);
    // If you're typing inside a restricted entity then Block - if you typing against it (or inbetween two) then it's ok
    if (entityKey !== null && 
        (['PROPOSEDDEL','PROPOSEDDELSECONDARY','REJECTPADD', 'REJECTPDEL', 'REJECTPADDSEC', 'REJECTPDELSEC'].includes(newContentState.getEntity(entityKey).getType())) &&
        entityKeyMinusOne !== null && newSelectionState.getFocusOffset() > 0 && 
        (['PROPOSEDDEL','PROPOSEDDELSECONDARY','REJECTPADD', 'REJECTPDEL', 'REJECTPADDSEC', 'REJECTPDELSEC'].includes(newContentState.getEntity(entityKeyMinusOne).getType())) &&
        entityKey === entityKeyMinusOne) {
      return 'handled';
    }

    if(!newSelectionState.isCollapsed()) { // There was a selection before entering an insertion
      let res = this.getEntitiesForSelection(newContentState, newSelectionState)
      if (res.selectionEntities.length > 0) { // there is an entity in the selection , so ignore / or simply delete / overwrite
        let toBeBlocked = false // if you find an non-overwriteable entity OR a non-entity
        res.blocksInSelection.forEach((block) => {
          if(newContentState.getBlockForKey(block).getLength() > 0 && res.selectionEntities.filter((se) => se.blockKey === block)[0] === undefined) {
            // There is text but no entity inside this block
            toBeBlocked = true;
          } else if (newContentState.getBlockForKey(block).getLength() > 0 && res.selectionEntities.filter((se) => se.blockKey === block && 
            ((se.type !== 'PROPOSEDADD' && this.props.curCpty === "primary") ||
             (se.type !== 'PROPOSEDADDSECONDARY' && this.props.curCpty === "secondary")))[0] !== undefined) {
            // There is an entity different from a overwriteable entity
            toBeBlocked = true;
          } else if(newContentState.getBlockForKey(block).getLength() > 0 && res.selectionEntities.filter((se) => se.blockKey === block)[0] !== undefined &&
          res.selectionEntities.filter((se) => se.blockKey === block && 
          ((se.type !== 'PROPOSEDADD' && this.props.curCpty === "primary") ||
           (se.type !== 'PROPOSEDADDSECONDARY' && this.props.curCpty === "secondary")))[0] === undefined) {
            // there are only overwriteable entities - check whether they (together) take up the whole block or not
            let blockEntities = res.selectionEntities.filter((se) => se.blockKey === block && 
          ((se.type === 'PROPOSEDADD' && this.props.curCpty === "primary") ||
           (se.type === 'PROPOSEDADDSECONDARY' && this.props.curCpty === "secondary")))

           blockEntities.sort((a,b) => (a.startPos > b.startPos) ? 1 : ((b.startPos > a.startPos) ? -1 : 0))
           .forEach((be, i) => {
             // For each entity inside the block
             if(i === 0 && be.startPos !== 0) { toBeBlocked = true; } // first block and not starting at 0
             else if(i + 1 === blockEntities.length && be.endPos !== newContentState.getBlockForKey(block).getLength()) { toBeBlocked = true } // last block and not finishing at the end
             else if(i > 0 && blockEntities[i-1].endPos !== be.startPos) { toBeBlocked = true } // a block in the middle not starting where the previous left off
           })
          }
        })
        if(toBeBlocked) {
          return 'handled'
        } else {
          return 'not-handled'
        }
      } 
      // Step 1. First apply the "proposedDelete" entity to the Selection
      newContentState = Modifier.applyEntity(newContentState, newSelectionState, this.state.delEntityKey, /* proposedDelete */ );
      newEditorState = EditorState.push(newEditorState, newContentState)
      this.onChange(newEditorState);
      // Step 2. Then "deselect" by forcing a selection on the endPoint of the original selection
      newSelectionState = newSelectionState.merge({ focusKey: newSelectionState.getAnchorKey(), focusOffset: newSelectionState.getAnchorOffset() });
      newEditorState = EditorState.forceSelection(newEditorState, newSelectionState)
      this.onChange(newEditorState);
    }

    // If you are at the end of a smartfield, select the style from pos-1, otherwise select current style
    let iStyle = block.getInlineStyleAt(newSelectionState.getStartOffset()).size === 0 && block.getInlineStyleAt(newSelectionState.getStartOffset() - 1).size === 1 ?
      block.getInlineStyleAt(newSelectionState.getStartOffset() - 1) : block.getInlineStyleAt(newSelectionState.getStartOffset())

    // Step 3. (or Step 1. if there was no selection) Now insert the character that was entered
    if(text.split(/\r?\n/).length > 1) { // the pasted text consists of multiple paragraphs
      text.split(/\r?\n/).reverse().forEach((par, i) => {
        newContentState = Modifier.insertText(
          newContentState,
          newSelectionState,
          par, iStyle, this.state.addEntityKey /* proposedAdd */);
        
        if(text.split(/\r?\n/).length > i + 1){
          newContentState = Modifier.splitBlock(
            newContentState,
            newSelectionState,
          );
        }
      })
    } else { // the pasted text consists of a single piece of text
      newContentState = Modifier.insertText(
        newContentState,
        newSelectionState,
        text, iStyle, this.state.addEntityKey /* proposedAdd */);
    }
    this.onChange(EditorState.push(newEditorState, newContentState));
    return 'handled'
  }

  onDraftEditorCut(editor, e) { // This is a cut from the Browser controls ("Edit > Cut") or from the iPhone / Android controls
    e.preventDefault() // Work around since original controls do not work with "redlines"
    let newEditorState = this.state.editorState
    let newContentState = this.state.editorState.getCurrentContent()
    let selectionState = this.state.editorState.getSelection()

    // Copy the selected text to the clipboard
    document.execCommand("copy")
    // Change the selection to a "proposed Del"
    newContentState = Modifier.applyEntity(newContentState, selectionState, this.state.delEntityKey, );
    newEditorState = EditorState.push(newEditorState, newContentState)
    // Deselect to start of the selection
    selectionState = selectionState.merge({ anchorKey: selectionState.getStartKey(), anchorOffset: selectionState.getStartOffset(), focusKey: selectionState.getStartKey(), focusOffset: selectionState.getStartOffset(), isBackward: false });
    newEditorState = EditorState.forceSelection(newEditorState, selectionState)
    this.onChange(newEditorState);
  }

  pushSmartFieldReconciliation(contentState) {
    let currentSFs = []
    let converted = convertToRaw(contentState)
    converted.blocks.forEach((b) => {
      b.inlineStyleRanges.forEach((iStyle) => { if(!currentSFs.some((sf) => sf === iStyle.style)) { currentSFs.push(iStyle.style) } } )
    })
    this.setState({smartFieldHandling: {...this.state.smartFieldHandling, reconcile: currentSFs}})
  }

  getEntitiesForSelection(contentState, selectionState) {

    let converted = convertToRaw(contentState)
    let ent = {}, blockEntities = [], selectionEntities = [], entsStart = false, inSelection = false, nextEntity = null, 
        prevEntity = null, blocksInSelection = [], blocks = [], sfOrder = [], sfs = [];

    let startKey = selectionState.getStartKey();
    let startOffset = selectionState.getStartOffset();
    let endKey = selectionState.getEndKey();
    let endOffset = selectionState.getEndOffset();
    let sameBlock = (startKey === endKey)

    converted.blocks.forEach((bl) => { // Check each block

      blocks.push(bl.key);

      if(startKey === bl.key) { inSelection = true; }
      if(inSelection) { blocksInSelection.push(bl.key) } 
      if(endKey === bl.key) { inSelection = false }

      // Loop through all entities
      bl.entityRanges.forEach((er) => {

        if(!entsStart && ent.key !== undefined) { prevEntity = ent }

        ent = { 
          blockKey: bl.key,
          startPos: er.offset,
          endPos: er.offset + er.length,
          key: er.key, 
          type: converted.entityMap[er.key].type 
        }
        blockEntities.push(ent)

        let curBlockIdx = converted.blocks.findIndex((b) => b.key === bl.key);

        if((startKey !== endKey && converted.blocks.findIndex((b) => b.key === startKey) < curBlockIdx && 
                                   converted.blocks.findIndex((b) => b.key === endKey) > curBlockIdx) || // a block inbetween start & finish
            (bl.key === startKey && (er.offset + er.length) > startOffset && (er.offset < endOffset || !sameBlock)) || // startBlock and in scope
            (bl.key === endKey && (er.offset + er.length > startOffset || !sameBlock) && (er.offset < endOffset)) // endBlock and in scope
            ){ // Entity (partially) in scope

          entsStart = true;

          if(selectionState.isCollapsed()) { prevEntity = ent; nextEntity = ent; } // Set the next/prev Entity to where the user has clicked into
          selectionEntities.push({ 
            blockKey: bl.key,
            startPos: bl.key === startKey ? Math.max(er.offset, startOffset) : er.offset,
            endPos: bl.key === endKey ? Math.min(er.offset + er.length, endOffset) : er.offset + er.length,
            key: er.key, 
            type: converted.entityMap[er.key].type 
          })

        } else if (entsStart && nextEntity === null) {
          nextEntity = ent;
        }
      })

      // Loop through all smartfields
      bl.inlineStyleRanges.forEach((isr) => {
        sfOrder.push(isr.style);
        let curBlockIdx = converted.blocks.findIndex((b) => b.key === bl.key);
        if((startKey !== endKey && converted.blocks.findIndex((b) => b.key === startKey) < curBlockIdx && 
                                   converted.blocks.findIndex((b) => b.key === endKey) > curBlockIdx) || // a block inbetween start & finish
            (bl.key === startKey && (isr.offset + isr.length) > startOffset && (isr.offset < endOffset || !sameBlock)) || // startBlock and in scope
            (bl.key === endKey && (isr.offset + isr.length > startOffset || !sameBlock) && (isr.offset < endOffset)) // endBlock and in scope
            ){ // SmartField (partially) in scope

            sfs.push({...isr, text: bl.text.substr(isr.offset, isr.length)})
        }
      })
    })

    // If not found yet assign prev or nextEntity
    if(prevEntity === null) { prevEntity = blockEntities[blockEntities.length -1] }
    if(nextEntity === null) { nextEntity = blockEntities[0] }

    return { 
      selectionEntities: selectionEntities, 
      blockEntities: blockEntities, 
      prevEntity: prevEntity,
      nextEntity: nextEntity,
      blocksInSelection: blocksInSelection,
      blocks: blocks,
      sfOrder: sfOrder,
      sfs: sfs,
      sfMsg: startKey !== endKey ? 'multiblock' : sfs.length === 0 ? 'notfound' : sfs.length === 1 ? 'found' : 'multiple'
    }
  }

  render() {
    return (
      <div className={"NegoCardEditor-editor"}>
        {this.props.active && !this.props.disableEditing ?
        <RedlineControls
          type={this.state.redlineControls.type}
          hint={this.state.redlineControls.hint}
          editMode={this.props.editMode}
          templating={this.props.templating}
          click={this.handleClick}
        />
        :''}

        {
        this.props.smartMode &&
        this.props.clstatus !== 'proposedDel' && 
        ((this.props.smartFields !== undefined && this.props.smartFields.length > 0) || 
         (this.props.clauseTypes !== undefined && this.props.clauseTypes.length > 0) || 
         (this.props.templating)) ?
        <div style={this.props.cleanMode ? {padding: '0px 0px 10px 0px'} : {}}>
        <SmartFields
          updateSmartField={this.props.updateSmartField}
          clid={this.props.clid}
          sclid={this.props.sclid}
          user={this.props.user}
          oatID={this.props.oatID}
          agrID={this.props.agrID}
          smartFieldHandling={this.state.smartFieldHandling}
          smartMode={this.props.smartMode}
          cleanMode={this.props.cleanMode}
          handleClick={this.handleClick}
          callbackOptions={this.props.callbackOptions}
          smartFields={this.props.smartFields}
          clauseTypes={this.props.clauseTypes}
          templating={this.props.templating}
          module={this.props.module}
          active={this.props.active}
        />
        </div>
        : '' }

        {this.props.cleanMode ?
        <CleanClause
          blocks={this.props.content}
          withSmartFields={this.props.smartMode}
          active={this.props.templating ? true : this.props.active}
          activeRef={this.state.smartFieldHandling.active !== undefined && this.state.smartFieldHandling.active !== null && this.state.smartFieldHandling.active.ref !== undefined ? this.state.smartFieldHandling.active.ref : null}
          singleRef={null}
          click={this.handleSmartFieldOpening}
        />
        :
        <Editor
          blockStyleFn={getBlockStyle}
          customStyleMap={this.props.smartMode ? smartfieldStyleMap : smartfieldStyleMapHidden}
          readOnly={this.state.readOnly}
          editorState={this.state.editorState}
          handleKeyCommand={this.handleKeyCommand}
          handleReturn={this.handleReturn}
          handleBeforeInput={this.handleBeforeInput}
          handlePastedText={this.handlePastedText}
          stripPastedStyles={true}
          keyBindingFn={this.myKeyBinding}
          onChange={this.onChange}
          onFocus={this.handleFocus}
          onCut={this.onDraftEditorCut}
          ref="editor"
        />
        }
      </div>
    );
  }
}

function getBlockStyle(block) {

  /*** Classifications ***
  - agrTitle
  - exhTitle
  - secTitle
  - subsecTitle
  - clauseStartPar (generally holds title)
  - clausePar
  - list
  - unclassified
  */

  switch (block.getType()) {
      case 'secTitle': return 'NegoCardEditor-secTitle';
      case 'ssecTitle': return 'NegoCardEditor-ssecTitle';
      case 'clauseTitle': return 'NegoCardEditor-clauseTitle';
      case 'clausePar': default: return 'NegoCardEditor-clausePar';
  }
}

const smartfieldStyleMapHidden = {
  sf1: { },
  sf2: { },
  sf3: { },
  sf4: { },
  sf5: { },
  sf6: { },
  sf7: { },
  sf8: { },
  sf9: { },
  sf10: { },
  sf11: { },
  sf12: { },
  sf13: { },
  sf14: { },
  sf15: { },
};

const smartfieldStyleMap = {
  sf1: { backgroundColor: '#ffefbe' }, // #fef4cf
  sf2: { backgroundColor: '#ffefbe' },
  sf3: { backgroundColor: '#ffefbe' },
  sf4: { backgroundColor: '#ffefbe' },
  sf5: { backgroundColor: '#ffefbe' },
  sf6: { backgroundColor: '#ffefbe' },
  sf7: { backgroundColor: '#ffefbe' },
  sf8: { backgroundColor: '#ffefbe' },
  sf9: { backgroundColor: '#ffefbe' },
  sf10: { backgroundColor: '#ffefbe' },
  sf11: { backgroundColor: '#ffefbe' },
  sf12: { backgroundColor: '#ffefbe' },
  sf13: { backgroundColor: '#ffefbe' },
  sf14: { backgroundColor: '#ffefbe' },
  sf15: { backgroundColor: '#ffefbe' },
};

function PaddStrategy(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'PROPOSEDADD'
      );
    },
    callback
  );
}

function RPaddStrategy(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'REJECTPADD'
      );
    },
    callback
  );
}

function PdelStrategy(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'PROPOSEDDEL'
      );
    },
    callback
  );
}

function RPdelStrategy(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'REJECTPDEL'
      );
    },
    callback
  );
}

function PaddSecStrategy(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'PROPOSEDADDSECONDARY'
      );
    },
    callback
  );
}

function RPaddSecStrategy(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'REJECTPADDSEC'
      );
    },
    callback
  );
}

function PdelSecStrategy(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'PROPOSEDDELSECONDARY'
      );
    },
    callback
  );
}

function RPdelSecStrategy(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'REJECTPDELSEC'
      );
    },
    callback
  );
}

const PaddSpan = (props) => {
  let data = props.contentState.getEntity(props.entityKey).data;
  return (
    <span
      style={styles.proposedAdd}
      className="primaryAddSelection"
      data-offset-key={props.offsetKey}
      title={data !== undefined && data.user !== undefined && data.time !== undefined ? data.user + (data.org !== undefined && data.org !== null && data.org !== '' ? (' (' + data.org + ')') : '') + " on " + dayjs(data.time).format('MMM D, YYYY h:mm A') : ""}
    >
      {props.children}
    </span>
  );
};

const RPaddSpan = (props) => {
  let data = props.contentState.getEntity(props.entityKey).data;
  return (
    <span style={styles.rejProposedAddOuter}>
    <span
      style={styles.rejProposedAdd}
      className="rejPrimaryAddSelection"
      data-offset-key={props.offsetKey}
      title={data !== undefined && data.user !== undefined && data.time !== undefined ? "Insertion rejected by " + data.user + (data.org !== undefined && data.org !== null && data.org !== '' ? (' (' + data.org + ')') : '') +  " on " + dayjs(data.time).format('MMM D, YYYY h:mm A') : ""}
    >
      {props.children}
    </span>
    </span>
  );
};

const PdelSpan = (props) => {
  let data = props.contentState.getEntity(props.entityKey).data;
  return (
    <span
      style={styles.proposedDel}
      className="primaryDelSelection"
      data-offset-key={props.offsetKey}
      title={data !== undefined && data.user !== undefined && data.time !== undefined ? data.user + (data.org !== undefined && data.org !== null && data.org !== '' ? (' (' + data.org + ')') : '') +  " on " + dayjs(data.time).format('MMM D, YYYY h:mm A') : ""}
    >
      {props.children}
    </span>
  );
};

const RPdelSpan = (props) => {
  let data = props.contentState.getEntity(props.entityKey).data;
  //<span style={styles.rejProposedDelOuter}>
  //</span>
  return (
    
    <span
      style={styles.rejProposedDel}
      className="rejPrimaryDelSelection"
      data-offset-key={props.offsetKey}
      title={data !== undefined && data.user !== undefined && data.time !== undefined ? "Deletion rejected by " + data.user + (data.org !== undefined && data.org !== null && data.org !== '' ? (' (' + data.org + ')') : '') +  " on " + dayjs(data.time).format('MMM D, YYYY h:mm A') : ""}
    >
      {props.children}
    </span>
  );
};

const PaddSecSpan = (props) => {
  let data = props.contentState.getEntity(props.entityKey).data;
  return (
    <span
      style={styles.proposedAddSecondary}
      className="secondaryAddSelection"
      data-offset-key={props.offsetKey}
      title={data !== undefined && data.user !== undefined && data.time !== undefined ? data.user + (data.org !== undefined && data.org !== null && data.org !== '' ? (' (' + data.org + ')') : '') +  " on " + dayjs(data.time).format('MMM D, YYYY h:mm A') : ""}
    >
      {props.children}
    </span>
  );
};

const RPaddSecSpan = (props) => {
  let data = props.contentState.getEntity(props.entityKey).data;
  return (
    <span style={styles.rejProposedAddSecondaryOuter}>
    <span
      style={styles.rejProposedAddSecondary}
      className="rejSecondaryAddSelection"
      data-offset-key={props.offsetKey}
      title={data !== undefined && data.user !== undefined && data.time !== undefined ? "Insertion rejected by " + data.user + (data.org !== undefined && data.org !== null && data.org !== '' ? (' (' + data.org + ')') : '') +  " on " + dayjs(data.time).format('MMM D, YYYY h:mm A') : ""}
    >
      {props.children}
    </span>
    </span>
  );
};

const PdelSecSpan = (props) => {
  let data = props.contentState.getEntity(props.entityKey).data;
  return (
    <span
      style={styles.proposedDelSecondary}
      className="secondaryDelSelection"
      data-offset-key={props.offsetKey}
      title={data !== undefined && data.user !== undefined && data.time !== undefined ? data.user + (data.org !== undefined && data.org !== null && data.org !== '' ? (' (' + data.org + ')') : '') +  " on " + dayjs(data.time).format('MMM D, YYYY h:mm A') : ""}
    >
      {props.children}
    </span>
  );
};

const RPdelSecSpan = (props) => {
  let data = props.contentState.getEntity(props.entityKey).data;
  return (
    <span
      style={styles.rejProposedDelSecondary}
      className="rejSecondaryDelSelection"
      data-offset-key={props.offsetKey}
      title={data !== undefined && data.user !== undefined && data.time !== undefined ? "Deletion rejected by " + data.user + (data.org !== undefined && data.org !== null && data.org !== '' ? (' (' + data.org + ')') : '') +  " on " + dayjs(data.time).format('MMM D, YYYY h:mm A') : ""}
    >
      {props.children}
    </span>
  );
};

const styles = {
  proposedAdd: {
    color: theme.palette.primary.main,
    borderBottom: '1px dotted ' + theme.palette.primary.main,
    padding: '2px 0',
  },
  rejProposedAdd: {
    color: theme.palette.primary.main,
    padding: '2px 0',
  },
  rejProposedAddOuter: {
    color: theme.palette.secondary.main,
    textDecoration: 'line-through',
  },
  proposedDel: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    textDecoration: 'line-through',
    padding: '2px 0',
  },
  rejProposedDel: {
    color: theme.palette.grey[800],
    borderBottom: '1px dotted ' + theme.palette.secondary.main,
    padding: '2px 0',
  },
  proposedAddSecondary: {
    color: theme.palette.secondary.main,
    borderBottom: '1px dotted ' + theme.palette.secondary.main,
    padding: '2px 0',
  },
  rejProposedAddSecondary: {
    color: theme.palette.secondary.main,
    padding: '2px 0',
  },
  rejProposedAddSecondaryOuter: {
    color: theme.palette.primary.main,
    textDecoration: 'line-through',
  },
  proposedDelSecondary: {
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    textDecoration: 'line-through',
    padding: '2px 0',
  },
  rejProposedDelSecondary: {
    color: theme.palette.grey[800],
    borderBottom: '1px dotted ' + theme.palette.primary.main,
    padding: '2px 0',
  },
};

export default NegoCardEditor