import { FETCH_CERTS_PENDING, FETCH_CERTS_DONE, FETCH_CERTS_SUCCESS, FETCH_CERTS_ERROR, ADD_CERT, 
         UPDATE_CERT, REMOVE_CERT,
         FETCH_ORGPROFILECERTS_PENDING, FETCH_ORGPROFILECERTS_SUCCESS, FETCH_ORGPROFILECERTS_ERROR } from '../ActionTypes';

// CERTS
export const Certs = (state  = { 
    pending: false,
    error: null,
    certs: []
}, action) => {
switch(action.type) {
        case FETCH_CERTS_PENDING: 
            return {
                ...state,
                pending: true
            }
        case FETCH_CERTS_DONE: 
            return {
                ...state,
                pending: false
            }
        case FETCH_CERTS_SUCCESS:
            return {
                ...state,
                pending: false,
                certs: action.payload
            }
        case FETCH_CERTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }
        case ADD_CERT:
            var cert = action.payload;
            return { 
                ...state, 
                certs: state.certs.concat(cert)};
        case UPDATE_CERT:
            // Find the index of the cert to replace with the updated one
            var newcert = action.payload
            var newCerts = state.certs
            var index = null;
            for (var i=0; i<state.certs.length; i++) {
                if ( state.certs[i]._id === newcert._id ) {
                    index = i;
                    break;
                }
            }
            newCerts[index] = newcert
            return { 
                ...state, 
                certs: newCerts};
        case REMOVE_CERT:
            var certid = action.payload;
            return { 
                ...state, 
                certs: state.certs.filter(({ _id }) => _id !== certid)};
        default: 
            return state;
    }
};


// ORGPROFILE CERTS
export const OrgProfileCerts = (state  = { 
    pending: false,
    error: null,
    orgProfileCerts: []
}, action) => {
switch(action.type) {
        case FETCH_ORGPROFILECERTS_PENDING: 
            return {
                ...state,
                pending: true,
            }
        case FETCH_ORGPROFILECERTS_SUCCESS:
            return {
                ...state,
                pending: false,
                orgProfileCerts: action.payload
            }
        case FETCH_ORGPROFILECERTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }

        default: 
            return state;
    }
};