import React, { useEffect } from 'react';
import theme from '../../theme/theme';
import { countryToFlag, trunc } from '../../utils';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { AccordionActions, Avatar, Box, Button, Card, CardContent, Checkbox, Chip, ClickAwayListener, Container, Divider, Grid, Grow, IconButton,
         List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, MenuList, MenuItem, Paper, Snackbar, TextField, Typography, Popper,
         InputAdornment, makeStyles, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';         
import { Timeline, TimelineItem, TimelineContent, TimelineOppositeContent, TimelineSeparator, 
         TimelineConnector } from '@material-ui/lab';
import { CardComment, ChipStatus, Header, DrawerCore, FAB, FABMagic, InformationalTooltip, Labels,
         SkeletonCustom, ThumbAgr, ThumbTodo } from '../../components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faSeedling, faChevronDown, faSignOutAlt, faComment, faTimes, 
         faFont, faTag, faFilter, faBuilding } from "@fortawesome/free-solid-svg-icons";

import defaultLogo from '../../assets/img/defaultlogo.png';
import defaultUser from '../../assets/img/defaultuser.png';

const axios = require('axios');
const dayjs = require('dayjs')

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': { borderBottom: 0, },
    '&:before': { display: 'none', },
    '&$expanded': { margin: 'auto', },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: theme.palette.grey[100],
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': { minHeight: 56, },
  },
  content: {
    '&$expanded': { margin: '12px 0', },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

function Relationship(props) {
  
  const useStyles = makeStyles(theme => ({
    root: { // mainDiv
      //backgroundColor: theme.palette.grey[1],
      //minHeight: '100vh',
      display: 'flex',
    },
    outerFlexGrid: {
        minHeight: '80vh',
        paddingTop: '100px',
        paddingBottom: '100px',
    },
    header: {
        zIndex: 1400
    },
    content: {
        flexGrow: 1,
    },
    seedIcon: {
      [theme.breakpoints.only('xs')]: {  fontSize: '16px' },
      fontSize: '32px',
      paddingTop: '3px',
      color: theme.palette.grey[400],
    },
    logoAvatarTiny: {
      [theme.breakpoints.only('xs')]: {  height: '15px', width: '15px', marginTop: '4px' },
      height: '30px',
      width: '30px',
      border: '5px solid ' + theme.palette.primary.contrastText,
    },
    logoAvatar: {
      height: '100px',
      width: '100px',
    },
    logoAvatarWithPointer: {
      height: '100px',
      width: '100px',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    deletedIconB: {
      fontSize: '36px',
    },
    bold: {
      fontWeight: '700',
    },
    secondary: {
      color: theme.palette.secondary.main,
    },
    tableSMMD: {
      [theme.breakpoints.down('md')]: { width: '560px', height: '480px' },
      [theme.breakpoints.up('lg')]: { display: 'none' },
    },
    tableLGUP: {
      [theme.breakpoints.down('md')]: { display: 'none' },
      [theme.breakpoints.up('lg')]: { width: '880px', height: '480px' },
      margin: '0px',
      padding: '0px'
    },
    boxSizer: {
      [theme.breakpoints.only('xs')]: { width: '100%' },
      [theme.breakpoints.up('sm')]: { width: '550px' }        
    },
    primIcon: {
      color: theme.palette.primary.main,
      paddingRight: '15px',
    },
    warnIcon: {
      color: theme.palette.error.main,
      paddingRight: '15px',
    },
    greyIcon: {
      color: theme.palette.grey[500],
      paddingRight: '15px',
    },
    disabledFont: {
      color: theme.palette.grey[500],
    },
    boxResizing: {
      [theme.breakpoints.only('xs')]: { width: '320px' },
      [theme.breakpoints.only('sm')]: { width: '560px' },
      [theme.breakpoints.up('md')]: { width: '630px' },
    },
    paperResizing: {
      overflowX: 'hidden',
      [theme.breakpoints.only('xs')]: {  width: '320px', height: '480px', },
      [theme.breakpoints.only('sm')]: { width: '560px', height: '480px', },
      [theme.breakpoints.only('md')]: { width: '560px', height: '480px', },
      [theme.breakpoints.up('lg')]: { width: '880px', height: '480px', },
    },
    smallOnXS: {
      [theme.breakpoints.only('xs')]: { fontSize: '10px' },
      color: theme.palette.grey[800],
      paddingTop: '3px',
    },
    dateBox: {
      [theme.breakpoints.only('xs')]: {  width: '55px', paddingTop: '5px' },
      [theme.breakpoints.only('sm')]: { width: '120px', paddingTop: '5px' },
      [theme.breakpoints.up('md')]: { width: '130px', paddingTop: '5px' },
    },
    dateFont: {
      [theme.breakpoints.only('xs')]: { fontSize: '10px' },
      [theme.breakpoints.only('sm')]: { fontSize: '12px' },
    },
    avBox: {
      [theme.breakpoints.only('xs')]: {  width: '30px' },
      width: '50px'
    },
    commentBox: {
      [theme.breakpoints.only('xs')]: { width: '200px', },
      width: '300px', 
      paddingBottom: '20px'
    },
    gridBlock: {
      [theme.breakpoints.down('md')]: { width: '320px' },
      [theme.breakpoints.up('lg')]: { width: '420px' },
    },
    card: {
      [theme.breakpoints.only('xs')]: { marginBottom: '30px' },
      width: '200px',
      '&:hover' : {
        boxShadow: '0px 3px 10px -1px rgba(112, 61, 220, 0.3)',
        cursor: 'pointer',
      }
    },
    chipBox: {
      [theme.breakpoints.only('xs')]: { width: '100px' },
      [theme.breakpoints.only('sm')]: { width: '100px' },
      [theme.breakpoints.up('md')]: { width: '150px' },
    },
    ticketTopic: {
      [theme.breakpoints.only('xs')]: { fontSize: '12px', paddingTop: '2px' },
      [theme.breakpoints.only('sm')]: { fontSize: '14px' },
      [theme.breakpoints.up('md')]: { fontSize: '14px' },
    },
    tinyExpand: {
      fontSize: '14px'
    },
    ttTitle: {
      [theme.breakpoints.only('xs')]: { width: '90px', fontSize: '12px' },
      [theme.breakpoints.up('sm')]: { width: '140px', fontSize: '14px' },
      fontWeight: '600',
      lineHeight: '1.8',
    },
    ttDesc: {
      [theme.breakpoints.only('xs')]: { width: '200px', fontSize: '12px', },
      [theme.breakpoints.up('sm')]: { width: '360px', fontSize: '14px' },
      fontWeight: '300',
      lineHeight: '1.8',
    },
    thumbContainer : {
      [theme.breakpoints.only('xs')]: { width: '312px' },
      [theme.breakpoints.only('sm')]: { width: '446px' },
      [theme.breakpoints.up('md')]: { width: '446px' },
    },
    thumbContainer2 : {
      [theme.breakpoints.only('xs')]: { width: '312px' },
      [theme.breakpoints.only('sm')]: { width: '446px' },
      [theme.breakpoints.up('md')]: { width: '669px' },
    },
    /*
    gridForThumb:{
      [theme.breakpoints.only('xs')]: { paddingLeft: '6px', paddingRight: '6px', paddingBottom: '12px' },
      [theme.breakpoints.only('sm')]: { paddingLeft: '6px', paddingRight: '6px' },
      [theme.breakpoints.up('md')]: { paddingLeft: '10px', paddingRight: '10px' },
      marginBottom: '20px',
    },*/
    errorMsg: {
      color: theme.palette.error.main,
    },
    bigbutton: { 
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      fontWeight: '500',
      padding: '0.7em 2.4em',
      margin: '0px 0px 0px 0px',
      borderRadius: '0.15em',
      boxShadow: 'inset 0 -0.6em 0 -0.35em rgba(0,0,0,0.17)',
      fontSize: '20px',
      '&:hover': {
        backgroundColor: '#D70061',
        cursor: 'pointer',
      },
    },
    pushDownXS: {
      [theme.breakpoints.only('xs')]: { marginTop: '40px' }
    },
    resizeBit: {
      [theme.breakpoints.only('xs')]: { width: '240px' },
      [theme.breakpoints.up('sm')]: { width: '480px' },
    },
    thumbContainerRelevant: {
      [theme.breakpoints.only('xs')]: { width: '324px' },
      [theme.breakpoints.only('sm')]: { width: '454px' },
      [theme.breakpoints.up('md')]: { width: '900px' },
      overflowY: 'auto',
    },
    gridForThumb:{
      [theme.breakpoints.only('xs')]: { paddingLeft: '6px', paddingRight: '6px', paddingBottom: '12px' },
      [theme.breakpoints.only('sm')]: { paddingLeft: '6px', paddingRight: '6px' },
      [theme.breakpoints.up('md')]: { paddingLeft: '10px', paddingRight: '10px' },
    },
    adornIcon: {
      color: theme.palette.grey[600],
      padding: '0px 5px 0px 5px',
      //marginRight: '10px',
    },
    multiSelectChip: {
      //backgroundColor: '#f3f3f3',
      //color: theme.palette.grey[900]
      backgroundColor: theme.palette.grey[800],
      margin: '2px',
      fontWeight: '400',
      color: theme.palette.primary.contrastText,
      border: '0px', 
      "& .MuiChip-deleteIcon": { color: theme.palette.primary.contrastText, '&:hover': { color: '#e0e0e0' } },
    },
    logoIconL: {
      height: '100px',
      borderRadius: '50px',
      width: 'auto',
    }
    /*
    filterInput: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.grey[800],
      fontWeight: '400',
      fontSize: '12px',
    }*/
  }));
  const classes = useStyles();

  const history = useHistory()

  const [activeRel, setActiveRel] = React.useState(null)
  const [relAgs, setRelAgs] = React.useState(null)
  const [relTicks, setRelTicks] = React.useState(null)
  const [relComms, setRelComms] = React.useState(null);
  const [relMenuOpen, setRelMenuOpen] = React.useState(false);
  const [deactiveAgrs, setDeactiveAgrs] = React.useState(false);
  const [deactiveChecked, setDeactiveChecked] = React.useState([]);
  const [comment, setComment] = React.useState('');
  const [agrFilter, setAgrFilter] = React.useState(props.searchchip !== undefined && props.searchchip !== null && props.searchchip.split(',').length > 0 ?
    {freefilter: '', 
    entities: props.searchchip.split(',').filter((sc) => sc.startsWith('cp_'))[0] !== undefined ? props.searchchip.split(',').filter((sc) => sc.startsWith('cp_'))[0].substring(3) : '', 
    agrtypes: props.searchchip.split(',').filter((sc) => sc.startsWith('at_'))[0] !== undefined ? props.searchchip.split(',').filter((sc) => sc.startsWith('at_'))[0].substring(3) : ''}: {freefilter: '', entities: [], agrtypes: []})
  const [primaryEntities, setPrimaryEntities] = React.useState([]);
  const [secondaryEntities, setSecondaryEntities] = React.useState([]);
  const [accExpanded, setAccExpanded] = React.useState(false);
  const [relName, setRelName] = React.useState(null);
  const [activeEnt, setActiveEnt] = React.useState(null);
  const [activeEntPage, setActiveEntPage] = React.useState(null)
  const [snack, setSnack] = React.useState(props.snackid !== undefined && props.snackid !== null && props.snackid !== 0 && ['1', '2', '3', '4', '5'].includes(props.snackid.toString()) ? props.snackid.toString() : null);
  const [changingRelName, setChangingRelName] = React.useState(false);
  const [customEmail, setCustomEmail] = React.useState(null)

  const anchorRef = React.useRef(null);
  const whiteLabel = activeRel !== null && activeRel.whiteLabel !== undefined && activeRel.whiteLabel !== null && activeRel.whiteLabel.color !== undefined &&
    props.user !== undefined && props.user.role === 'Counterparty' ? activeRel.whiteLabel : null
  const whiteLabelLoading = activeRel === null

  useEffect(() => {
    let unmounted = false;

    if(props.user !== undefined && props.agrTypes.length > 0 && props.cpents !== undefined){
      axios.get('/rel/rel/' + props.user.orgID + '/' + props.rid)
      .then(function(resrel) {
        let rel = resrel.data.data

        axios.get('/agr/agrs/' + props.rid) // Need to get the relevant Agreements for the Relationship
        .then(function(resags) {
          let relAgs = resags.data.data;
          let primEnts = [], secEnts = []
          
          relAgs
          .filter(((a) => (props.user.role !== 'Counterparty' || props.user.agrIDs.some((aid) => aid === a._id)) && // For cpty users only accessible agrs
                      !['Deactive', 'Exhibit'].includes(a.agrStatus) && ((!['Request', 'Draft'].includes(a.agrStatus)) || // Agreement is not deactive AND for Draft or Request only when...
                      (a.curAVOwner === a.primaryCptyID && props.user.orgID === rel.primaryOrgID) || // The agr owner AND User is primary or
                      (a.curAVOwner === a.secondaryCptyID && props.user.orgID === rel.secondaryOrgID)))) // The agr owner AND User is secondary
          .sort((a,b) => (a.lastUpdateDate < b.lastUpdateDate) ? 1 : (b.lastUpdateDate < a.lastUpdateDate) ? -1 : 0)
          .forEach((a)=> {
    
            let primary = props.user.orgID === rel.primaryOrgID && props.subs.filter((s) => s._id === a.primaryCptyID)[0] !== undefined ?
                            props.subs.filter((s) => s._id === a.primaryCptyID)[0] :
                          props.user.orgID === rel.secondaryOrgID && props.cpents.filter((c) => c._id === a.primaryCptyID)[0] !== undefined ?
                            props.cpents.filter((c) => c._id === a.primaryCptyID)[0] : { legalName: 'Unknown' }
            let secondary = props.user.orgID === rel.secondaryOrgID && props.subs.filter((s) => s._id === a.secondaryCptyID)[0] !== undefined ?
                              props.subs.filter((s) => s._id === a.secondaryCptyID)[0] :
                            props.user.orgID === rel.primaryOrgID && props.cpents.filter((c) => c._id === a.secondaryCptyID)[0] !== undefined ?
                              props.cpents.filter((c) => c._id === a.secondaryCptyID)[0] : { legalName: 'Unknown' }
            
            if(primEnts.filter((pe) => pe._id === a.primaryCptyID).length < 1) { 
              primEnts.push({ _id: a.primaryCptyID, country: primary.address !== undefined && primary.address.filter((a) => a.addressType === 'billto')[0] !== undefined ? 
                primary.address.filter((a) => a.addressType === 'billto')[0].country : '', legalName: primary.legalName })
            }
            if(secEnts.filter((pe) => pe._id === a.secondaryCptyID).length < 1) { 
              secEnts.push({ _id: a.secondaryCptyID, country: secondary.address !== undefined && secondary.address.filter((a) => a.addressType === 'billto')[0] !== undefined ? 
                secondary.address.filter((a) => a.addressType === 'billto')[0].country : '', legalName: secondary.legalName })
            }
            /*
            if (!unmounted) { 

              setRows(rows => [...rows, createData(
                a._id, // id
                a.agrShortName !== undefined && a.agrShortName !== '' ? a.agrShortName :
                props.agrTypes.filter((at) => at._id === a.agrTypeID)[0] !== undefined ?
                    props.agrTypes.filter((at) => at._id === a.agrTypeID)[0].shortName : '', // agrShortName
                a.agrFullName !== undefined && a.agrFullName !== '' ? a.agrFullName :
                props.agrTypes.filter((at) => at._id === a.agrTypeID)[0] !== undefined ?
                    trunc(props.agrTypes.filter((at) => at._id === a.agrTypeID)[0].fullName[0], 30) : 'Unknown agreement', // agrFullName
                a.agrStatus, // agrStatus
                trunc(primary.legalName, 24), // primaryLegalName
                trunc(secondary.legalName, 24), // secondaryLegalName
                dayjs(a.lastUpdateDate).format('MMM D, YYYY'), // lastUpdateDate
                a.primaryCptyID, // primaryCptyID
                a.secondaryCptyID, // secondaryCptyID
              )])
            }
            */
          })
          if (!unmounted) { 
            setActiveRel(rel)
            setPrimaryEntities(primEnts);
            setSecondaryEntities(secEnts);
            //setFilteredAgs(relAgs.filter((a) => a.agrStatus !== 'Exhibit'))
            setRelAgs(relAgs)
            setRelName([{type: 'primary', value: rel.name.substring(0, rel.name.indexOf('|') - 1)}, {type: 'secondary', value: rel.name.substring(rel.name.indexOf('|') + 2)}])
          }
        }).catch(function(err) { console.log(err) })
      }).catch(function(err) { console.log(err) })

      axios.get('/ticket/ticketsrel/' + props.rid) // Need to get the relevant Agreements for the Relationship
      .then(function(resticks) {
        if (!unmounted) { setRelTicks(resticks.data.data) }
      })
      .catch(function(err) { console.log(err) })

      axios.get('/comment/commentsrel/' + props.rid) // Need to get the relevant Agreements for the Relationship
      .then(function(rescomms) {
        axios.get('/logging/protect/albyrelid/' + props.rid)
        .then(function(resal) {
          let comms = rescomms.data.data
          resal.data.data
          .filter((r) => r.logLevel === 'rel' && r.logType !== 'relCreated')
          .forEach((ral) => {
            comms.push({orgID: ral.orgID, creatorDisplayName: ral.keyVal1, comment: ral.logDesc, commentType: 'rel', al: true})
          })
          if (!unmounted) { setRelComms(comms) }

        }).catch(function(err) { console.log(err) })
      }).catch(function(err) { console.log(err) })
    }
    
    return () => { unmounted = true };

  }, [props.rid, props.user, props.org, props.agrTypes, props.cpents, props.subs]);

  /*useEffect(() => {
    if(props.searchchip !== undefined && props.searchchip !== null && props.searchchip !== '') {
      let ents = [], ats = []
      props.searchchip.split(',').forEach((sc) => {
        if(sc.startsWith('at_')) {
          ats.push(sc.substring(3))
        } else if(sc.startsWith('cp_')) {
          ents.push(sc.substring(3))
        }
      })
      setAgrFilter({freefilter: '', entities: ents, agrtypes: ats})
    }
  }, [props.searchchip])*/

  const handleAccChange = (panel) => (event, isExpanded) => {
    setAccExpanded(isExpanded ? panel : false);
  };

  const handleDeactiveToggle = (value) => () => {
    const currentIndex = deactiveChecked.indexOf(value);
    const newChecked = [...deactiveChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setDeactiveChecked(newChecked);
  };

  const toggleDeactiveAgrs = () => {
    setDeactiveAgrs((prevOpen) => !prevOpen)
  }

  const handleRelMenuToggle = () => {
    setRelMenuOpen((prevOpen) => !prevOpen);
  };

  const handleRelMenuClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setRelMenuOpen(false);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  }

  const toggleChangingRelName = () => {
    setRelMenuOpen(false);
    setChangingRelName(!changingRelName);
  }

  const handleRelNameUpdate = () => {
    if(relName !== null) {
      let n = relName.filter((r) => r.type === 'primary')[0].value + ' | ' + relName.filter((r) => r.type === 'secondary')[0].value
      props.updateRelName(activeRel._id, n)  
    }
  }

  const handleChangeRelName = (type, value) => {
    let rn = relName.filter((r) => r.type !== type)
    rn.push({type: type, value: value})
    setRelName(rn)
  }

  const handleCommentSubmit = () => {
    if(comment.length > 0) {
      props.createComment(
        true, // FAF
        props.user._id, // creatorID
        props.user.displayName, // creatorDisplayName
        props.user.orgID, // orgID
        '', // entityID
        comment, // comment
        'rel', // commentType
        '', // agrID
        '', // singleClauseID
        '', // ticketID 
        props.rid, // relID
      )
      setRelComms([...relComms, {
        creatorID: props.user._id, // creatorID
        creatorDisplayName: props.user.displayName, // creatorDisplayName
        orgID: props.user.orgID, // orgID
        comment: comment, // comment
        commentType: 'rel', // commentType
        relID: props.rid, // relID
        creationDate: new Date().toISOString(), // creationDate
      }])
      setComment('')
    }
  }

  const deactivateRelationship = () => {

    // Deactivate the relationship
    props.updateRel(
      props.rid, 
      props.user.orgID,
      activeRel.primaryCptyID, 
      activeRel.secondaryOrgID, 
      activeRel.invisibleFor, // invisibleFor
      props.user._id,
      !activeRel.deactive, // deactive
      activeRel.deactive ? 'None' : 'Deactivation', // deactiveReason
    )
    props.createActivityLog(
      'rel', // logLevel, 
      activeRel.deactive ? 'relReactivated' : 'relDeactivated', // logType, 
      'Relationship ' + (activeRel.deactive ? 'reactivated' : 'deactivated') + ' by ' + props.user.displayName, // logDesc, 
      props.user.orgID, // orgID
      '', // entID, 
      activeRel._id, // relID, 
      '', // agrID, 
      '', // singleClauseID, 
      '', // ticketID, 
      props.user._id, // creationBy, 
      false, // isInternal, 
      props.user.displayName, // keyVal1, 
      '', // keyVal2
      {}, // change
    )
    setRelMenuOpen(false)
  }

  const clearActiveEnt = () => {
    setActiveEnt(null)
    setActiveEntPage(null)
  }

  const reactivateAgreements = () => {

    deactiveChecked.forEach((ag) => {

      props.updateAgr(
        activeRel._id, // rid 
        ag, // aid 
        relAgs.filter((a) => a._id === ag)[0].primaryCptyID, // primaryCptyID
        relAgs.filter((a) => a._id === ag)[0].secondaryCptyID, // secondaryCptyID
        relAgs.filter((a) => a._id === ag)[0].parentAID !== '' ? 'Exhibit' : 'Draft', // agrStatus
        relAgs.filter((a) => a._id === ag)[0].curAVOwner, // curAVOwner
        relAgs.filter((a) => a._id === ag)[0].sourceFileID, // sourceFileID 
        [], // contributors
        [], // signatories
        props.user._id, // lastUpdateBy
      )
      if(ag.parentAID === ''){ // If it's the parent, log the new status
        props.createActivityLog(
          'agr', // logLevel, 
          'agrNewStatus', // logType, 
          'Agreement status changed to: Draft', // logDesc, 
          props.user.orgID, // orgID
          relAgs.filter((a) => a._id === ag)[0].curAVOwner, // entID, 
          activeRel._id, // relID, 
          ag, // agrID, 
          '', // singleClauseID, 
          '', // ticketID, 
          props.user._id, // creationBy, 
          false, // isInternal, 
          'Draft', // keyVal1, 
          '', // keyVal2
          {}, // change
        )
      }
    })
    
    let path = "/core/" + props.module + "/relationship/" + props.rid + "/overview/4";
    history.push(path);
  }

  const RenderLogo = ({variant}) => {
    if(variant === 'primary') {
      return (
        <Avatar src={activeRel !== null && activeRel.primaryOrgID === props.org._id ? props.org.logoURL : 
                     activeRel !== null && activeRel.secondaryOrgID === props.org._id &&
                     props.cpents.filter((c) => c._id === activeRel.primaryCptyID)[0] !== undefined ?
                        props.cpents.filter((c) => c._id === activeRel.primaryCptyID)[0].logoURL :
                     props.module === 'people' ? defaultUser : defaultLogo} 
          alt="logo" className={classes.logoAvatar} />
      )
    } else if (variant === 'secondary') {
      return (
        <Avatar src={activeRel !== null && activeRel.secondaryOrgID === props.org._id ? props.org.logoURL : 
                     activeRel !== null && activeRel.primaryOrgID === props.org._id &&
                     props.cpents.filter((c) => c._id === activeRel.secondaryCptyID)[0] !== undefined ?
                        props.cpents.filter((c) => c._id === activeRel.secondaryCptyID)[0].logoURL :
                     props.module === 'people' ? defaultUser : defaultLogo}
          alt="logo" className={classes.logoAvatar} />
      )
    }
  }

  const handleFilterChange = (changeType, newVal) => {

    let newAgFilter = agrFilter;

    if(changeType === 'freefilter') {
      newAgFilter = {...newAgFilter, freefilter: newVal }
    } else if(changeType === 'entities') {
      let ids = []
      newVal.forEach((ent) => { ids.push(ent._id) })
      newAgFilter = {...newAgFilter, entities: ids }
    } else if(changeType === 'agrtypes') {
      let ids = []
      newVal.forEach((at) => { ids.push(at._id) })
      newAgFilter = {...newAgFilter, agrtypes: ids }
    }
    setAgrFilter(newAgFilter)

  }

  /*
  const RenderAgrTable = ({showRows}) => {
    return (
      <Paper elevation={0} className={classes.paperResizing}>        
        <Box className={classes.tableSMMD}>
          <VirtualizedTable
            rowCount={showRows.length}
            onRowClick={({e, rowData}) => handleRowClick(e, rowData)}
            rowGetter={({ index }) => showRows[index]}
            sortDir={sortDir}
            sortBy={sortBy}
            createSortHandler={createSortHandler}

            columns={[
              {
                width: 60,
                label: 'Type',
                dataKey: 'agrShortName',
              },
              {
                width: 100,
                label: 'Status',
                dataKey: 'agrStatus',
              },
              {
                width: 200,
                label: (['procurement', 'sales'].includes(props.module) ? 'Seller' : 
                        ['partnerships'].includes(props.module) ? 'Partner 1' : 
                        ['people'].includes(props.module) ? 'Company' : 'Counterparty 1'),
                dataKey: 'primaryLegalName',
              },
              {
                width: 200,
                label: (['procurement', 'sales'].includes(props.module) ? 'Buyer' : 
                        ['partnerships'].includes(props.module) ? 'Partner 2' : 
                        ['people'].includes(props.module) ? 'Employee' : 'Counterparty 2'),
                dataKey: 'secondaryLegalName',
              },
            ]}
          />
        </Box>

        <Box className={classes.tableLGUP}>
          <VirtualizedTable
            rowCount={showRows.length}
            onRowClick={({e, rowData}) => handleRowClick(e, rowData)}
            rowGetter={({ index }) => showRows[index]}
            sortDir={sortDir}
            sortBy={sortBy}
            createSortHandler={createSortHandler}
            columns={[
              {
                width: 60,
                label: 'Type',
                dataKey: 'agrShortName',
              },
              {
                width: 220,
                label: 'Agreement',
                dataKey: 'agrFullName',
              },
              {
                width: 90,
                label: 'Status',
                dataKey: 'agrStatus',
              },
              {
                width: 185,
                label: (['procurement', 'sales'].includes(props.module) ? 'Seller' : 
                        ['partnerships'].includes(props.module) ? 'Partner 1' : 
                        ['people'].includes(props.module) ? 'Company' : 'Counterparty 1'),
                dataKey: 'primaryLegalName',
              },
              {
                width: 185,
                label: (['procurement', 'sales'].includes(props.module) ? 'Buyer' : 
                        ['partnerships'].includes(props.module) ? 'Partner 2' : 
                        ['people'].includes(props.module) ? 'Employee' : 'Counterparty 2'),
                dataKey: 'secondaryLegalName',
              },
              {
                width: 140,
                label: 'Last Update',
                dataKey: 'lastUpdateDate',
              },
            ]}
          />
        </Box>
      </Paper>      
    )    
  }*/

  const RenderTicketAcc = ({showTickets}) => {
    if(showTickets.length > 0) {
      return (
        showTickets
        .sort((a,b) => (a.creationDate < b.creationDate) ? 1 : ((b.creationDate < a.creationDate) ? -1 : 0))
        .map((st) => (
          <Accordion key={st._id} square expanded={accExpanded === st._id} onChange={handleAccChange(st._id)}>
            <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} className={classes.tinyExpand} />}> 
              <Box className={classes.chipBox}>
              <ChipStatus 
                title={st.resolveReason === 'Approved' || st.resolveReason === 'Rejected' ? st.resolveReason : st.ticketStatus} 
                color={st.ticketStatus === 'Resolved' || st.ticketStatus === 'Cancelled' || st.ticketStatus === 'Closed' ? "grey" : "primary"} />
              </Box>
              <Typography className={classes.ticketTopic}>{st.topic}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              
              <Grid container direction="column" justify="center">
                <Grid item container direction="row">
                  <Grid item className={classes.ttTitle}>Type:</Grid>
                  <Grid item className={classes.ttDesc}>
                    {st.ticketType === 'AgrReviewInternal' ? "Internal Review" :
                    st.ticketType === 'AgrReviewExternal' ? "Negotiation Review" :
                    st.ticketType === 'AgrRequest' ? "Agreement Request" : st.ticketType}</Grid>
                </Grid>
                <Grid item container direction="row">
                  <Grid item className={classes.ttTitle}>Creation:</Grid>
                  <Grid item className={classes.ttDesc}>
                    On {dayjs(st.creationDate).format('MMM D, YYYY h:mm A')} by {props.users.filter((u) => u._id === st.creationBy) !== undefined ? props.users.filter((u) => u._id === st.creationBy)[0].displayName : "Unknown"}</Grid>
                </Grid>
                <Grid item container direction="row">
                  <Grid item className={classes.ttTitle}>Last Update:</Grid>
                  <Grid item className={classes.ttDesc}>
                    On {dayjs(st.lastUpdateDate).format('MMM D, YYYY h:mm A')} by {props.users.filter((u) => u._id === st.lastUpdateBy) !== undefined ? props.users.filter((u) => u._id === st.lastUpdateBy)[0].displayName : "Unknown"}</Grid>
                </Grid>
                <Grid item container direction="row">
                  <Grid item className={classes.ttTitle}>Assignee:</Grid>
                  <Grid item className={classes.ttDesc}>
                    {props.users.filter((u) => u._id === st.assignee) !== undefined ? props.users.filter((u) => u._id === st.assignee)[0].displayName : "Unknown"}</Grid>
                </Grid>
              </Grid>

            </AccordionDetails>
            <Divider />
            <AccordionActions>
              {st.agrID !== undefined && st.agrID !== null && st.agrID !== '' ?
                <Button size="small" component={RouterLink} to={"/core/" + props.module + "/agreement/" + activeRel._id + "/" + st.agrID}>Agreement</Button> : '' }
              <Button size="small" component={RouterLink} to={"/core/" + props.module + "/tasks"} color="primary">Tasks</Button>
            </AccordionActions>
          </Accordion>
        ))
      )
    } else {
      return (<Typography align="center">There are no tickets (yet)</Typography>)
    }
  }

  const handleFreeTrialClick = () => {
    axios.post('/mail/freetrialfyi', {
      uid: props.user._id,
      uname: props.user.displayName,
      utitle: props.user.title,
      uemail: props.user.email,
      cemail: customEmail !== null ? customEmail : 'not defined',
      orgid: props.org._id,
      orgName: props.org.shortName,
      relName: activeRel.name
    })

    axios.post('/mail/freetrialstarted', {
      email: customEmail !== null ? customEmail : props.user.email,
      customLink: '/network/' + props.org._id + '/signup/' + props.user._id + "/cpty/" + Math.round(new Date(new Date().setMonth(new Date().getMonth()+3)).getTime())
    })

    history.push('/signup/cpty' + (customEmail === null ? "" : ("/" + customEmail)))
  }

  const handleLogout = () => {
    axios.get('/auth/logout')
    .then(function (res) {
        history.push('/core/logout');
    })
    .catch(function (err) { console.log("error logging out", err) })
  }

  const handlePricingLink = () => {
    const win = window.open("https://getcanveo.com/pricing.html", "_blank");
    win.focus();
  }

  // Overall rendering of the page
  return(
    <div className={classes.root}>
      
      <Header module={props.module}
        active="Relationships"
        crumbs={relName !== null && activeRel !== null ? [
          {name: "Home", to: "/core/" + props.module + "/dashboard"},
          /*...props.user !== undefined && props.user.role !== undefined && props.user.role !== 'Counterparty' && !props.user.role.startsWith('ClientFor_') ? 
          [{name: "Relationships", to: "/core/" + props.module + "/relationships"}] : [],*/
          {name: props.org._id === activeRel.primaryOrgID ? trunc(relName.filter((rn) => rn.type === 'secondary')[0].value, 11) : trunc(relName.filter((rn) => rn.type === 'primary')[0].value, 11), 
             to: "/core/" + props.module + "/relationship/" + props.rid + "/overview"},
          {name: props.page === 'cptydone' ? "Thank you" : props.page !== undefined ? (props.page.charAt(0).toUpperCase() + props.page.slice(1)) : "unknown",
             to: "active"}] : []}
        user={props.user}
        org={props.org}
        modules={
          props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
            ['sales','procurement','partnerships','people','corporate'] : 
          props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
            props.org.modules : [] }
        notifications={props.notifications}
        markNotificationAsRead={props.markNotificationAsRead}
        markAllNotificationsAsRead={props.markAllNotificationsAsRead}
        className={classes.header}
        whiteLabel={whiteLabel}
        whiteLabelLoading={whiteLabelLoading} />

      {props.user.role !== 'Counterparty' ?
      <DrawerCore 
        page={props.page} 
        rid={props.rid}
        sections={[]}
        pageType='relationship'
        isCustomer={['Admin', 'Business', 'Corporate', 'HR', 'Legal', 'Partnerships', 'Procurement', 'Sales'].includes(props.user.role)}
        module={props.module}
        whiteLabelLoading={whiteLabelLoading}
      />
      : '' }
      
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
        open={activeRel !== null && props.orgPending === false && relAgs !== null && Boolean(snack)}
        autoHideDuration={10000}
        onClose={e => setSnack(null)}
        message={
          snack === '1' ? 
          <>
          <Typography variant="subtitle2">Your signature response has been recorded.</Typography>
          <Typography variant="subtitle2">It may take a few minutes to process.</Typography>
          </> 
          :snack === '2' ?
          <Typography variant="subtitle2">The agreement was sent to your counterparty.</Typography>
          :snack === '3' ?
          <Typography variant="subtitle2">You do not have access to this agreement (anymore).</Typography>
          :snack === '4' ? 
          <>
          <Typography variant="subtitle2">The agreement was recovered.</Typography>
          <Typography variant="subtitle2">Only the last owner will be able to see it.</Typography>
          </>
          :snack === '5' ? 
          <Typography variant="subtitle2">The agreement was already sent to your counterparty.</Typography>
          : "Something happened"}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={e => setSnack(null)}>
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </React.Fragment>
        }
      />
      
      <Container maxWidth="lg" className={classes.content}>
      <Grid container direction="column" justify="flex-start" alignItems="center" className={classes.outerFlexGrid}>

      { props.page === 'overview' && activeRel !== null ?
        (
        <React.Fragment>
          {activeRel !== null /*&& !activeRel.deactive*/ && props.user.role !== 'Counterparty' &&
           ["Admin", "Business", "Corporate", "HR", "Legal", "Partnerships", "Procurement", "Sales"].includes(props.user.role) && (
           ['custB', 'cust','custE'].includes(props.org.clientType) || (['custS'].includes(props.org.clientType) && props.org.modules.includes(props.module)) ||
           (['legalOrg'].includes(props.org.clientType) && props.user.role === 'Admin')) ?
          (
          <Box>
            <FAB iconType="plus" routeTo={"/core/" + props.module + "/new/" + (props.user.orgID === activeRel.primaryOrgID ? activeRel.secondaryOrgID : activeRel.primaryOrgID)}
            extended buttonText="New" />
          </Box>
          ): '' }

          <Grid item container direction="row" justify="center" alignItems="center" spacing={0}>
            <Grid item xs={5} sm={4}>
              <Box align="center" mt={4} mb={4}>
                <Avatar src={
                     props.org !== undefined && props.org.clientType === 'legalOrg' &&  
                     props.user.orgID === activeRel.primaryOrgID && props.subs.filter((c) => c._id === activeRel.primaryCptyID)[0] !== undefined ?
                       props.subs.filter((c) => c._id === activeRel.primaryCptyID)[0].logoURL :
                     props.org !== undefined && props.org.clientType === 'legalOrg' &&  
                     props.user.orgID === activeRel.secondaryOrgID && props.subs.filter((c) => c._id === activeRel.secondaryCptyID)[0] !== undefined ?
                       props.subs.filter((c) => c._id === activeRel.secondaryCptyID)[0].logoURL :
                     props.user.orgID === activeRel.primaryOrgID && props.cpents.filter((c) => c._id === activeRel.secondaryCptyID)[0] !== undefined ?
                       props.cpents.filter((c) => c._id === activeRel.secondaryCptyID)[0].logoURL :
                     props.user.orgID === activeRel.secondaryOrgID && props.cpents.filter((c) => c._id === activeRel.primaryCptyID)[0] !== undefined ?
                       props.cpents.filter((c) => c._id === activeRel.primaryCptyID)[0].logoURL :
                     props.module === 'people' ? defaultUser: defaultLogo}
                  alt="logo" className={['Admin', 'Business', 'Corporate', 'HR', 'Legal', 'Partnerships', 'Procurement', 'Sales'].includes(props.user.role) ? classes.logoAvatarWithPointer : classes.logoAvatar}
                  ref={anchorRef}
                  aria-controls={relMenuOpen ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={['Admin', 'Business', 'Corporate', 'HR', 'Legal', 'Partnerships', 'Procurement', 'Sales'].includes(props.user.role) ? handleRelMenuToggle : null} />
                <Popper open={relMenuOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      <Paper variant="outlined">
                        <ClickAwayListener onClickAway={handleRelMenuClose}>
                          <MenuList id="menu-list-grow">

                            {relAgs.filter((a) => ['Request', 'Review', 'Negotiation', 'Execution', 'InForce'].includes(a.agrStatus)).length > 0 ?
                            <MenuItem className={classes.disabledFont}>
                            <FontAwesomeIcon icon={faExclamationTriangle} className={classes.greyIcon} />
                              {"Deactivate Relationship"}</MenuItem>
                            :
                            <MenuItem onClick={deactivateRelationship}>
                            <FontAwesomeIcon icon={faExclamationTriangle} className={classes.warnIcon} />
                            {activeRel.deactive ? "Reactivate Relationship" : "Deactivate Relationship"}</MenuItem>
                            }
                            <MenuItem onClick={toggleChangingRelName}>
                            <FontAwesomeIcon icon={faFont} className={classes.primIcon} />
                              {"Change Relationship Name"}</MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Box>
            </Grid>
            
          </Grid>

          <Grid item>
            <Box mt={2} mb={4} align="center" className={classes.boxResizing}>
              {changingRelName ? 
            
              <Box align="center" mb={3}>
                <Box align="left" style={{width: '320px'}}>
                  <Box mb={4}>
                  <Typography align="center" variant="subtitle2">Relationship names are shared between counterparties:</Typography>
                  </Box>
                  <Grid container direction="row">
                    <Grid item>
                      <TextField
                        id="primary-name"
                        variant="outlined"
                        label="Part 1"
                        disabled={props.org._id === activeRel.primaryOrgID}
                        defaultValue={relName.filter((rn) => rn.type === 'primary')[0].value}
                        onBlur={e => handleChangeRelName('primary', e.target.value)}
                        style={{width: '150px', marginRight: '10px'}}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        id="secondary-name"
                        variant="outlined"
                        label="Part 2"
                        disabled={props.org._id !== activeRel.primaryOrgID}
                        defaultValue={relName.filter((rn) => rn.type === 'secondary')[0].value}
                        onBlur={e => handleChangeRelName('secondary', e.target.value)}
                        style={{width: '150px', marginLefft: '10px'}}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={3} style={{width: '320px'}}>
                  <Button variant="contained" color="primary" fullWidth disableElevation onClick={handleRelNameUpdate}>Submit</Button>
                  <Box mt={1}>
                    <Button variant="text" color="primary" onClick={toggleChangingRelName}>Cancel</Button>
                  </Box>
                </Box>
              </Box>
              :
              activeRel !== null && activeRel.deactive ?
              (
                <Typography>
                  This Relationship has been deactivated.
                </Typography>
              )
              :
              relAgs !== null && relAgs.length === 0 ?
              (
                <Typography>
                  There are no agreements available.
                </Typography>
              )
              :
              activeRel !== null && relAgs !== null ?
              (
              <>
              {props.user !== undefined && props.user.role !== 'Counterparty' && !props.user.role.startsWith('ClientFor_') ?
              <Box mb={5} mt={0}>
                <Labels 
                user={props.user}
                level={'rel'}
                relID={activeRel._id}
                orgLabel={props.orgLabel.filter((ol) => !ol.deactive)}
                labels={[]}
                module={props.module}
                createOrgLabel={props.createOrgLabel}
                updateOrgLabel={props.updateOrgLabel}
                />
              </Box>
              :''}
              <Box align="center" mb={2} mt={0}>
                <Typography variant="subtitle2">
                  {props.user.role === 'Counterparty' ? 
                    "Agreements" : 
                    <>Recently updated&nbsp;&nbsp;<InformationalTooltip msg={"This overview page shows the three most recently updated agreements for this relationship.\n\nFor older ones navigate to \"Agreements\"."} /></>}
                </Typography>
              </Box>
              <Grid container direction="row" justify={props.user.role === 'Counterparty' && 
              relAgs.filter(((a) => (props.user.role !== 'Counterparty' || props.user.agrIDs.some((aid) => aid === a._id)) &&
              !['Deactive', 'Exhibit'].includes(a.agrStatus) && (!['Request', 'Draft'].includes(a.agrStatus) ||
              (a.curAVOwner === a.primaryCptyID && props.user.orgID === activeRel.primaryOrgID) ||  // COPY FROM BELOW
              (a.curAVOwner === a.secondaryCptyID && props.user.orgID === activeRel.secondaryOrgID)))).length > 3 ? "flex-start" : "center"} alignItems="flex-start" spacing={2}>
                {
                activeRel !== null ?
                relAgs
                .filter(((a) => (props.user.role !== 'Counterparty' || props.user.agrIDs.some((aid) => aid === a._id)) &&
                    !['Deactive', 'Exhibit'].includes(a.agrStatus) && (!['Request', 'Draft'].includes(a.agrStatus) || // Agreement is not deactive and not a Request / Draft or
                    (a.curAVOwner === a.primaryCptyID && props.user.orgID === activeRel.primaryOrgID) || // The agr owner AND User is primary or
                    (a.curAVOwner === a.secondaryCptyID && props.user.orgID === activeRel.secondaryOrgID)))) // The agr owner AND User is secondary
                .sort((a,b) => (a.lastUpdateDate < b.lastUpdateDate) ? 1 : ((b.lastUpdateDate < a.lastUpdateDate) ? -1 : 0))
                .slice(0, props.user.role === 'Counterparty' ? relAgs.length : 3)
                .map((ag) => (
                  <Grid item key={ag._id}
                    xs={12}
                    sm={relAgs.filter(((a) => (props.user.role !== 'Counterparty' || props.user.agrIDs.some((aid) => aid === a._id)) &&
                      !['Deactive', 'Exhibit'].includes(a.agrStatus) && ((!['Request', 'Draft'].includes(a.agrStatus)) || // See above
                      (a.curAVOwner === a.primaryCptyID && props.user.orgID === activeRel.primaryOrgID) ||
                      (a.curAVOwner === a.secondaryCptyID && props.user.orgID === activeRel.secondaryOrgID)))).length === 1 ? 12 :
                      relAgs.filter(((a) => (props.user.role !== 'Counterparty' || props.user.agrIDs.some((aid) => aid === a._id)) &&
                      !['Deactive', 'Exhibit'].includes(a.agrStatus) && ((!['Request', 'Draft'].includes(a.agrStatus)) || // See above
                      (a.curAVOwner === a.primaryCptyID && props.user.orgID === activeRel.primaryOrgID) ||
                      (a.curAVOwner === a.secondaryCptyID && props.user.orgID === activeRel.secondaryOrgID)))).length === 2 ? 4 : 4 }>
                    <ThumbAgr 
                      ag={ag}
                      path={"/core/" + props.module + "/agreement/" + props.rid + "/" + ag._id}
                      cptyLogo={props.user.orgID === activeRel.primaryOrgID && props.cpents.filter((c) => c._id === activeRel.secondaryCptyID)[0] !== undefined ?
                                  props.cpents.filter((c) => c._id === activeRel.secondaryCptyID)[0].logoURL :
                                props.user.orgID === activeRel.secondaryOrgID && props.cpents.filter((c) => c._id === activeRel.primaryCptyID)[0] !== undefined ?
                                  props.cpents.filter((c) => c._id === activeRel.primaryCptyID)[0].logoURL :
                                props.module === 'people' ? defaultUser: defaultLogo}
                      primaryLegalName={
                        activeRel.primaryOrgID === props.org._id && props.subs.filter((s) => s._id === ag.primaryCptyID)[0] !== undefined ?
                          props.subs.filter((s) => s._id === ag.primaryCptyID)[0].legalName : 
                        activeRel.secondaryOrgID === props.org._id && props.cpents.filter((c) => c._id === ag.primaryCptyID)[0] !== undefined ?
                          props.cpents.filter((c) => c._id === ag.primaryCptyID)[0].legalName : "Unknown Entity"}
                      secondaryLegalName={
                        activeRel.secondaryOrgID === props.org._id && props.subs.filter((s) => s._id === ag.secondaryCptyID)[0] !== undefined ?
                          props.subs.filter((s) => s._id === ag.secondaryCptyID)[0].legalName : 
                        activeRel.primaryOrgID === props.org._id && props.cpents.filter((c) => c._id === ag.secondaryCptyID)[0] !== undefined ?
                          props.cpents.filter((c) => c._id === ag.secondaryCptyID)[0].legalName : "Unknown Entity"}
                      actionReq={['Execution', 'Request'].includes(ag.agrStatus) || (['Draft', 'Review', 'Negotiation'].includes(ag.agrStatus) &&
                        ((activeRel.primaryOrgID === props.org._id && ag.curAVOwner === ag.primaryCptyID) /* User is primary and ag owner is secondary */
                        || (activeRel.secondaryOrgID === props.org._id && ag.curAVOwner === ag.secondaryCptyID))) /* User is secondary and ag owner is primary */ }
                      hideDot={true}
                    />
                  </Grid>
                )) : ''
                }

              </Grid>
              </>
              ) : ''}
            </Box>
              
          </Grid>

        </React.Fragment>
        ): ''
      }

      { props.page === 'agreements' && activeRel !== null ?
        (
          <Grid container direction="column" justify="center" alignItems="center">
            {activeRel !== null && !activeRel.deactive && props.user.role !== 'Counterparty' &&
           ["Admin", "Business", "Corporate", "HR", "Legal", "Partnerships", "Procurement", "Sales"].includes(props.user.role) && (
           ['custB', 'cust','custE'].includes(props.org.clientType) || (['custS'].includes(props.org.clientType) && props.org.modules.includes(props.module))) ?
            (
            <Box display={{ xs: 'none', md: 'block' }}>
              <FAB iconType="plus" routeTo={"/core/" + props.module + "/new/" + (props.user.orgID === activeRel.primaryOrgID ? activeRel.secondaryOrgID : activeRel.primaryOrgID)}
              extended buttonText="New" smUp={true} />
            </Box>
            ): '' }

            <Grid item xs={12}>
              <Box mb={3}>
              <Typography variant="h4" align="center" color="textPrimary" className={classes.bold}>
                Agreements&nbsp;&nbsp;<InformationalTooltip msg={"Filter agreements within this relationship using any of the three \"filter boxes\".\n\nThe free text filter, allows you to search on name and status of the agreement.\n\nThe Entity filter allows you to find agreements for a specific legal entity.\n\nThe Agreement Type filter allows you to find specific types of agreements."} />
              </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
            {!deactiveAgrs && relAgs !== null ?

            <Box mt={1} mb={4} align="center">
              <Box mb={5}>
                <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={2}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Free search"
                      onChange={e => handleFilterChange("freefilter", e.target.value)}
                      placeholder="Filter by typing..."
                      InputProps={{
                        //className: classes.filterInput,
                        startAdornment: (
                          <InputAdornment position="start">
                            <FontAwesomeIcon icon={faFilter} className={classes.adornIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                    multiple
                    id="tags-outlined"
                    //disableCloseOnSelect
                    onChange={(e, value) => handleFilterChange("entities", value)}
                    value={props.cpents.filter((cpe) => agrFilter.entities.includes(cpe._id))}
                    options={props.cpents
                      .filter((cpe) => (props.user.orgID === activeRel.primaryOrgID && (cpe.orgID === activeRel.secondaryOrgID || cpe.parentOrgID === activeRel.secondaryOrgID)) ||
                        (props.user.orgID === activeRel.secondaryOrgID && (cpe.orgID === activeRel.primaryOrgID || cpe.parentOrgID === activeRel.primaryOrgID)))
                      .sort((a,b) => (a.legalName > b.legalName) ? 1 : ((b.legalName > a.legalName) ? -1 : 0))
                    }
                    //defaultValue={props.cpents.filter((cpe) => agrFilter.entities.includes(cpe))}
                    getOptionLabel={(option) => option.legalName}
                    filterSelectedOptions
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                          <Chip variant="default" color="primary" {...getTagProps({ index })} 
                          className={classes.multiSelectChip}
                          label={trunc(option.legalName,13)}
                          />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        label="Legal Entity"
                        placeholder="Filter by Entity..."
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                            <InputAdornment position="start">
                              <FontAwesomeIcon icon={faBuilding} className={classes.adornIcon} />
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                    multiple
                    id="tags-outlined"
                    //disableCloseOnSelect
                    onChange={(e, value) => handleFilterChange("agrtypes", value)}
                    value={props.agrTypes.filter((at) => agrFilter.agrtypes.includes(at._id))}
                    options={props.agrTypes
                      .sort((a,b) => (a.fullName[0] > b.fullName[0]) ? 1 : ((b.fullName[0] > a.fullName[0]) ? -1 : 0))}
                    //defaultValue={props.agrTypes.filter((at) => agrFilter.agrtypes.includes(at))}
                    //value={props.agrTypes.filter((at) => agrFilter.agrtypes.includes(at._id))}
                    getOptionLabel={(option) => option.fullName[0]}
                    filterSelectedOptions
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                          <Chip variant="default" color="primary" {...getTagProps({ index })} 
                          className={classes.multiSelectChip}
                          label={trunc(option.shortName + ": " + option.fullName[0],13)}
                          />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        label="Agreement Type"
                        placeholder="Filter by Agreement Type..."
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                            <InputAdornment position="start">
                              <FontAwesomeIcon icon={faTag} className={classes.adornIcon} />
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Grid container className={classes.thumbContainerRelevant}>
                {relAgs
                .filter((ra) => !['Exhibit', 'Deactive'].includes(ra.agrStatus))
                .filter((ra) => agrFilter.freefilter === '' || ra.agrFullName.toLowerCase().includes(agrFilter.freefilter.toLowerCase()) || ra.agrShortName.toLowerCase().includes(agrFilter.freefilter.toLowerCase()) ||
                  ra.agrStatus.toLowerCase().includes(agrFilter.freefilter.toLowerCase()) ||
                  (ra.agrStatus === 'InForce' && ['in effect'].includes(agrFilter.freefilter.toLowerCase())))
                .filter((ra) => agrFilter.entities.length === 0 || agrFilter.entities.includes(ra.primaryCptyID) ||  agrFilter.entities.includes(ra.secondaryCptyID))
                .filter((ra) => agrFilter.agrtypes.length === 0 || agrFilter.agrtypes.includes(ra.agrTypeID))
                .sort((a,b) => (a.lastUpdateDate < b.lastUpdateDate) ? 1 : ((b.lastUpdateDate < a.lastUpdateDate) ? -1 : 0))
                .slice(0,8)
                .map((ag, i) => (
                    <Grid className={classes.gridForThumb} 
                    style={{marginBottom: '20px'}}
                    item xs={6} md={3} /*md={filteredAgs.length > 3 ? 3 
                    : filteredAgs.length === 3 ? 4 
                    : filteredAgs.length === 2 ? 6 : 3}*/ key={i}>
                        
                        <ThumbAgr 
                        fromDashboard={true}
                        ag={ag}
                        path={"/core/" + props.module + "/agreement/" + ag.relID + "/" + ag._id}
                        cptyLogo={props.cpents.filter((e) => e._id === ag.primaryCptyID || e._id === ag.secondaryCptyID)[0] !== undefined ?
                          props.cpents.filter((e) => e._id === ag.primaryCptyID || e._id === ag.secondaryCptyID)[0].logoURL : 
                          props.module === 'people' ? defaultUser : defaultLogo}
                        primaryLegalName={
                          props.subs.filter((s) => s._id === ag.primaryCptyID)[0] !== undefined ? 
                            props.subs.filter((s) => s._id === ag.primaryCptyID)[0].legalName : 
                          props.cpents.filter((e) => e._id === ag.primaryCptyID)[0] !== undefined ?
                            props.cpents.filter((e) => e._id === ag.primaryCptyID)[0].legalName : "Unknown Entity"}
                        secondaryLegalName={
                          props.subs.filter((s) => s._id === ag.secondaryCptyID)[0] !== undefined ? 
                            props.subs.filter((s) => s._id === ag.secondaryCptyID)[0].legalName : 
                          props.cpents.filter((e) => e._id === ag.secondaryCptyID)[0] !== undefined ?
                            props.cpents.filter((e) => e._id === ag.secondaryCptyID)[0].legalName : "Unknown Entity"}
                        actionReq={((['Draft', 'Review', 'Negotiation'].includes(ag.agrStatus) && (
                          (props.subs.filter((s) => s._id === ag.primaryCptyID)[0] !== undefined && ag.curAVOwner === ag.primaryCptyID) ||
                          (props.subs.filter((s) => s._id === ag.secondaryCptyID)[0] !== undefined && ag.curAVOwner === ag.secondaryCptyID)))) ||
                          ['Execution'].includes(ag.agrStatus)}
                        hideDot={true}
                        />

                    </Grid>
                ))}
              </Grid>
            </Box>

            :relAgs !== null ?
            (
              <>
              <Box mt={2} mb={2}>
              <Typography variant="subtitle1" align="center" color="textSecondary">
                Recover Agreement(s) from trash bin
              </Typography>
              </Box>
              <List>
                {relAgs
                .filter((a) => a.agrStatus === 'Deactive' && (props.subs.some((s) => s._id === a.curAVOwner) || props.user.role !== 'Counterparty') && 
                (a.parentAID === '' || (relAgs.filter((ra) => ra._id === a.parentAID)[0] !== undefined && ['Draft', 'Review', 'Negotiation'].includes(relAgs.filter((ra) => ra._id === a.parentAID)[0].agrStatus))))
                .map((a) => (
                
                  <ListItem key={a._id} role={undefined} dense button onClick={handleDeactiveToggle(a._id)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={deactiveChecked.indexOf(a._id) !== -1}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={a.agrShortName + " - " +
                    (props.user.orgID === activeRel.primaryOrgID && 
                     props.subs.filter((s) => s._id === a.primaryCptyID)[0] !== undefined && props.cpents.filter((c) => c._id === a.secondaryCptyID)[0] !== undefined ? 
                        props.subs.filter((s) => s._id === a.primaryCptyID)[0].legalName + " - " + props.cpents.filter((c) => c._id === a.secondaryCptyID)[0].legalName
                      :
                     props.user.orgID === activeRel.secondaryOrgID && 
                     props.cpents.filter((c) => c._id === a.primaryCptyID)[0] !== undefined && props.subs.filter((s) => s._id === a.secondaryCptyID)[0] !== undefined ?
                        props.cpents.filter((c) => c._id === a.primaryCptyID)[0].legalName + " - " + props.subs.filter((s) => s._id === a.secondaryCptyID)[0].legalName
                      : "Unknown legal entities") +
                     " (deactivated on " + dayjs(a.lastUpdateDate).format('MMM D, YYYY h:mm A') + ")"
                    } />
                  </ListItem>

                ))}
              </List>

              <Box mt={3} mb={2} align="center">
                <Button color="primary" onClick={toggleDeactiveAgrs}>
                  Cancel
                </Button>
                &nbsp;&nbsp;
                <Button color="primary" variant="contained" disableElevation onClick={reactivateAgreements}>
                  Recover
                </Button>
              </Box>
              </>
            ):''}
            
            {//For now disable restoring of agreements - to be tested thoroughly upon return
            /*!deactiveAgrs && relAgs !== null && relAgs.filter((a) => a.agrStatus === 'Deactive' && // at least one deactive Agr exists
              ((props.subs.some((s) => s._id === a.curAVOwner)).length > 0 || props.user.role !== 'Counterparty')) ?
              <Box align="center" mt={1}>
                <IconButton className={classes.deletedIconB} onClick={toggleDeactiveAgrs}>
                  <FontAwesomeIcon icon={faTrashRestore} />
                </IconButton>
              </Box>
              : '' */}
            </Grid>
            
          </Grid>
        ): ''
      }

      { props.page === 'entities' && activeRel !== null ?
        (
          <Grid item xs={12}>

              <Box mb={5}>
              <Typography variant="h4" align="center" color="textPrimary" className={classes.bold}>
                Contracting Entities
              </Typography>
              </Box>

              {activeEnt !== null && activeEnt !== '' ?
              <>              
                <FABMagic 
                    userRole={props.user.role}
                    reviewTicketsExist={false}
                    reviewTicketsActive={false}
                    fabType= {"standard"}
                    click={clearActiveEnt}
                    iconType={"back"}
                />

                <Box align="center" mt={0} mb={5}>
                  <Typography variant="subtitle1">
                  {props.subs.filter((s) => s._id === activeEnt)[0] !== undefined ? 
                    props.subs.filter((s) => s._id === activeEnt)[0].legalName : 
                    "Entity Overview" } in the relationship "{props.org._id === activeRel.primaryOrgID ? relName.filter((rn) => rn.type === 'secondary')[0].value : relName.filter((rn) => rn.type === 'primary')[0].value}":</Typography>
                </Box>

                {activeEntPage === null ?
                (
                <Grid container direction="row" justify="center" alignItems="flex-start">

                  <Grid item container direction="column" xs={12} sm={6} justify="center" alignItems="center" className={classes.gridBlock}>
                    <Grid item xs={12}>
                      <Card variant="outlined" className={classes.card} onClick={e => history.push('/core/' + props.module + '/relationship/' + activeRel._id + '/agreements')}>
                      <CardContent align="center">
                        <Box ml={3} mr={3} mt={2} mb={3} align="center">
                          <Typography variant={"h1"}>{relAgs.filter((a) => (a.primaryCptyID === activeEnt || a.secondaryCptyID === activeEnt) && /*rows.some((r) => r.id === a._id) &&*/ !['Deactive', 'Exhibit'].includes(a.agrStatus)).length}</Typography>
                        </Box>
                        <Typography align="center">Agreements</Typography>
                      </CardContent>
                      </Card>
                    </Grid>
                  </Grid>

                  <Grid item container direction="column" xs={12} sm={6} justify="center" alignItems="center" className={classes.gridBlock}>
                    <Grid item xs={12}>
                      <Card variant="outlined" className={classes.card} onClick={e => setActiveEntPage('entityticks')}>
                      <CardContent align="center">
                        <Box ml={3} mr={3} mt={2} mb={3} align="center">
                          <Typography variant={"h1"}>{relTicks !== null ? relTicks.filter((t) => t.entID === activeEnt).length : 0}</Typography>
                        </Box>
                        <Typography align="center">Tickets</Typography>
                      </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
                )
                :
                activeEntPage === 'entityagrs' ?
                (
                  "Go to agreements"
//                  <RenderAgrTable showRows={rows.filter((r) => r.primaryCptyID === activeEnt || r.secondaryCptyID === activeEnt)} />
                )
                :
                activeEntPage === 'entityticks' ?
                (
                  <RenderTicketAcc showTickets={relTicks !== null ? relTicks.filter((t) => t.entID === activeEnt) : []} />
                )
                :
                activeEntPage === 'entityhist' ?
                (
                  "activity history"
                )
                : '' }

              </>
              :
              <Grid container direction="row" justify="center" alignItems="flex-start">

                <Grid item container direction="column" xs={12} md={6} justify="center" alignItems="center" className={classes.gridBlock}>
                  <Grid item xs={12}>
                    <Box ml={3} mr={3} mt={3} mb={3}>
                      <RenderLogo variant="primary" />
                    </Box>
                  </Grid>
                  <Grid item>
                    <List component="nav">
                      {primaryEntities
                      .sort((a,b) => (a.legalName > b.legalName) ? 1 : ((b.legalName > a.legalName) ? -1 : 0))
                      .map((pe) => (
                        <ListItem button key={pe._id} /*component={RouterLink}*/
                          onClick={
                            activeRel.primaryOrgID === props.user.orgID ? 
                                e => setActiveEnt(pe._id) : 
                            activeRel.secondaryOrgID === props.user.orgID && props.cpents.filter((c) => c._id === pe._id)[0] !== undefined &&
                            props.cpents.filter((c) => c._id === pe._id)[0].entityType === 'Subsidiary' ?
                                e => history.push("/network/" + props.cpents.filter((c) => c._id === pe._id)[0].orgID) :
                            activeRel.secondaryOrgID === props.user.orgID && props.cpents.filter((c) => c._id === pe._id)[0] !== undefined &&
                            props.cpents.filter((c) => c._id === pe._id)[0].entityType !== 'Subsidiary' ?
                                e => history.push("/core/" + props.module + "/masterdata/edit/" + pe._id) :
                                e => history.push("/core/" + props.module + "/relationship/" + activeRel._id + "/entities")}>
                          <ListItemAvatar>
                            <>{pe.country !== undefined ? countryToFlag(pe.country) : ""}</>
                          </ListItemAvatar>
                          <ListItemText primary={<Typography variant="body2">{trunc(pe.legalName, 28)}</Typography>} />
                        </ListItem>
                      ))}
                    </List>
                    
                  </Grid>
                </Grid>

                <Grid item container direction="column" xs={12} md={6} justify="center" alignItems="center" className={classes.gridBlock}>
                  <Grid item xs={12}>
                    <Box ml={3} mr={3} mt={3} mb={3}>
                      <RenderLogo variant="secondary" />
                    </Box>
                  </Grid>
                  <Grid item>
                    <List component="nav">
                      {secondaryEntities
                      .sort((a,b) => (a.legalName > b.legalName) ? 1 : ((b.legalName > a.legalName) ? -1 : 0))
                      .map((se) => (
                        <ListItem button 
                        key={se._id} 
                        onClick={
                          activeRel.secondaryOrgID === props.user.orgID ? 
                              e => setActiveEnt(se._id) : 
                          activeRel.primaryOrgID === props.user.orgID && props.cpents.filter((c) => c._id === se._id)[0] !== undefined &&
                          props.cpents.filter((c) => c._id === se._id)[0].entityType === 'Subsidiary' ?
                              e => history.push("/network/" + props.cpents.filter((c) => c._id === se._id)[0].orgID) :
                          activeRel.primaryOrgID === props.user.orgID && props.cpents.filter((c) => c._id === se._id)[0] !== undefined &&
                          props.cpents.filter((c) => c._id === se._id)[0].entityType !== 'Subsidiary' ?
                              e => history.push("/core/" + props.module + "/masterdata/edit/" + se._id) :
                              e => history.push("/core/" + props.module + "/relationship/" + activeRel._id + "/entities")}>
                          <ListItemAvatar>
                            <>{se.country !== undefined ? countryToFlag(se.country) : ""}</>
                          </ListItemAvatar>
                          <ListItemText primary={<Typography variant="body2">{trunc(se.legalName, 28)}</Typography>} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>

              </Grid>
              }

         
          </Grid>
        ): ''
      }

      { props.page === 'activity' && activeRel !== null && relComms !== null ?
        (
          <Grid item xs={12}>

              <Box mb={3}>
              <Typography variant="h4" align="center" color="textPrimary" className={classes.bold}>
                Activity History
              </Typography>
              </Box>


              {!activeRel.deactive ?
                <Box 
                  width="100%" 
                  align={'center'} 
                  mb={5}
                  mt={4}>
                  <Box className={classes.boxSizer} align="left">
                      <TextField
                      variant="outlined"
                      name="addComment"
                      onChange={handleCommentChange}
                      label="Add comment"
                      placeholder="Reply to thread..."
                      multiline
                      fullWidth
                      rows={3}
                      />
                  </Box>
                  <Box mt={1} className={classes.boxSizer}>
                    <Button
                      fullWidth
                      disableElevation
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                    >
                      Submit comment
                    </Button>
                  </Box>
                </Box>
              : '' }

              <Timeline>

              {relComms
              .filter((c) => c.commentType === 'rel')
              .reverse()
              .map((comm, i) => 
                <TimelineItem key={i}>
                  <TimelineOppositeContent>
                    
                    <Box className={classes.dateBox}>
                    <Typography variant="body2" color="textSecondary" className={classes.dateFont}>
                      {dayjs(comm.creationDate).format('MMM D, YYYY h:mm A')}
                    </Typography>
                    </Box>
                   
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <Box className={classes.avBox} align="center">
                    <Avatar src={
                      props.org._id === comm.orgID ? 
                        props.org.logoURL : 
                      activeRel.primaryOrgID === props.user.orgID && comm.orgID === activeRel.secondaryOrgID &&
                      props.cpents.filter((c) => c._id === activeRel.secondaryCptyID)[0] !== undefined ?
                        props.cpents.filter((c) => c._id === activeRel.secondaryCptyID)[0].logoURL :
                      activeRel.secondaryOrgID === props.user.orgID && comm.orgID === activeRel.primaryOrgID &&
                      props.cpents.filter((c) => c._id === activeRel.primaryCptyID)[0] !== undefined ?
                        props.cpents.filter((c) => c._id === activeRel.primaryCptyID)[0].logoURL :
                      props.module === 'people' ? defaultUser : defaultLogo}
                      alt="logo" 
                      className={classes.logoAvatarTiny} />
                    </Box>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>

                    <Box className={classes.commentBox}>

                      {comm.al ? 
                      <Typography variant="subtitle2">{comm.comment}</Typography>
                      :
                      <CardComment
                        comment={comm}
                        secondary={!(comm.orgID === activeRel.primaryOrgID)}
                        historyPage={true}
                      />
                      }

                    </Box>

                  </TimelineContent>
                </TimelineItem>
                )}

                <TimelineItem key="relCreationDate">
                  <TimelineOppositeContent>
                    
                    <Box className={classes.dateBox} >
                    <Typography variant="body2" color="textSecondary" className={classes.dateFont}>
                      {dayjs(activeRel.creationDate).format('MMM D, YYYY h:mm A')}
                    </Typography>
                    </Box>
                   
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <Box className={classes.avBox} align="center">
                    
                    <FontAwesomeIcon icon={faSeedling} className={classes.seedIcon} />

                    </Box>
                  </TimelineSeparator>
                  <TimelineContent>

                    <Box className={classes.commentBox}>
                    
                      <Typography align="left" variant="subtitle2" className={classes.smallOnXS}>
                        Relationship initiated 
                      </Typography>
                    
                    </Box>

                  </TimelineContent>
                </TimelineItem>

                
              </Timeline>

         
          </Grid>
        ): ''
      }

      { props.page === 'cptydone' && 
      props.org !== undefined && props.org !== null && props.org.orgSettings !== undefined && ['full'].includes(props.org.orgSettings.whiteLabelLevel) &&
      activeRel !== null && whiteLabel !== null ?

        <Grid item xs={12}>

          <Box mt={8} mb={6} align="center">
              
            <img src={whiteLabel.logoURL} alt="Canveo" className={classes.logoIconL} />
              
          </Box>
          <Box mt={8} mb={0}>
            <Typography variant="h4" align="center" gutterBottom className={classes.bold}>
              Thanks for using our negotiation platform<span className={classes.secondary}>.</span>
            </Typography>
            <Box mt={2} align="center">
            <Typography variant="h6" gutterBottom>Powered by <span className={classes.bold}>Canveo<span className={classes.secondary}>.</span></span></Typography>
            </Box>
          </Box>

          <Box mt={8} mb={5} align="center">
            <Grid item container direction="row" justify="center" alignItems="flex-start" className={classes.thumbContainer}>
              <Grid item xs={6} sm={6} className={classes.gridForThumb}>
                <ThumbTodo
                  route={"/contact/support"} 
                  dash={true}
                  icon={"Logo"} 
                  iconOnly={true}
                  iconColor={"secondary"}
                  line1={"Leave Feedback"} 
                  line2={"Let us know your thoughts"} />
              </Grid>
              <Grid item xs={6} sm={6} className={classes.gridForThumb}>
                <ThumbTodo
                  route={"click"}
                  click={handleLogout} 
                  dash={true}
                  icon={faSignOutAlt} 
                  iconOnly={true}
                  iconColor={"grey"}
                  line1={"Exit Application"} 
                  line2={"Thanks for using us"} />
              </Grid>
            </Grid>
          </Box>
            

        
        </Grid>
      : props.page === 'cptydone' && activeRel !== null ?

        <Grid item xs={12}>

          <Box mt={8} mb={0}>
            <Typography variant="h3" align="center" gutterBottom className={classes.bold}>
              Thanks for using Canveo<span className={classes.secondary}>.</span>
            </Typography>
          </Box>

          <Box mt={2} mb={6} align="center">
            <Typography variant="h6" gutterBottom>Try Canveo <span className={classes.bold}>1 month for free<span className={classes.secondary}>.</span></span></Typography>
            <Box mt={8} className={classes.resizeBit}>
              
              <Grid container direction="row" alignItems="center" justify="center">
                <Grid item xs={12} sm={7}>
                  <Box style={{width: '250px'}}>
                  <TextField
                    label="Your email"
                    variant="outlined"
                    defaultValue={props.user.email}
                    onBlur={e => props.user.email !== e.target.value ? setCustomEmail(e.target.value) : setCustomEmail(null)}
                    fullWidth
                  />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={5} className={classes.pushDownXS}>
                  <span className={classes.bigbutton} onClick={handleFreeTrialClick}>Try Canveo</span>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box mt={10} mb={5} align="center">
            <Grid item container direction="row" justify="center" alignItems="flex-start" className={classes.thumbContainer2}>
              <Grid item xs={6} sm={6} md={4} className={classes.gridForThumb}>
                <ThumbTodo
                  route={"/contact/support"} 
                  dash={true}
                  icon={faComment} 
                  iconOnly={true}
                  iconColor={"secondary"}
                  line1={"Leave Feedback"} 
                  line2={"Let us know your thoughts"} />
              </Grid>
              <Grid item xs={6} sm={6} md={4} className={classes.gridForThumb}>
                <ThumbTodo
                  route={"click"} 
                  click={handlePricingLink}
                  dash={true}
                  icon={faTag} 
                  iconOnly={true}
                  iconColor={"primary"}
                  line1={"View pricing"} 
                  line2={"For after your free trial"} />
              </Grid>
              <Grid item xs={6} sm={6} md={4} className={classes.gridForThumb}>
                <ThumbTodo
                  route={"click"}
                  click={handleLogout} 
                  dash={true}
                  icon={faSignOutAlt} 
                  iconOnly={true}
                  iconColor={"grey"}
                  line1={"Exit Application"} 
                  line2={"Thanks for using Canveo"} />
              </Grid>
            </Grid>
          </Box>
            

        
        </Grid>
      : '' }

      { (activeRel === null || props.orgPending === true || relAgs === null) ? // still loading
        (
          <Grid item container alignItems="center" justify="center">

            <SkeletonCustom type="relationship" />

          </Grid>
        )
        : ''
      }
      
      </Grid>
      </Container>
    </div>
  );
}

export default Relationship;
