function clauseOpenCheck (blocks, commentStatus, approvalStatus, pendingChange, clauseObject) {
    let hasEntity = false;

    if(commentStatus === 'Open' || approvalStatus === 'Open'){
        hasEntity = true;
    } else if (pendingChange !== undefined && pendingChange.length > 0 && 
        pendingChange.filter((pc) => pc.status === 'Open') !== undefined) {
        hasEntity = true;
    } else if(blocks.blocks !== undefined && blocks.blocks !== null && blocks.blocks.length > 0) {
        blocks.blocks.forEach((block) => { // Check for all blocks if there is any open entity
            if(block.entityRanges.length > 0) { hasEntity = true }
        })
    } else if(clauseObject !== undefined && clauseObject !== null && clauseObject.head !== undefined) { // check table for any open change

        clauseObject.head.forEach((h) => {
            if(h.change !== undefined && h.change !== null && h.change.type !== undefined) { hasEntity = true; }
        })

        if(!hasEntity && clauseObject.rows !== undefined) {
            clauseObject.rows.forEach((r) => {
                r.cols.forEach((c) => {
                    if(c.change !== undefined && c.change !== null && c.change.type !== undefined) { hasEntity = true; }
                })
            })
        } 
    }
    return hasEntity
}

export { clauseOpenCheck }