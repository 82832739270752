import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';

import logoIcon from '../assets/img/ct-logo.png';

const Logo = (props) => {

    const useStyles = makeStyles(theme => ({
        logoIcon: {
            height: '30px',
            [theme.breakpoints.only('xs')]: { height: '24px', borderRadius: '12px', },
            [theme.breakpoints.up('sm')]: { height: '30px', borderRadius: '15px', },
            width: 'auto',
        },
        logoIconL: {
            height: '38px',
            borderRadius: '19px',
            width: 'auto',
        },
    }));
    const classes = useStyles();

    return (
    <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>
            {!props.whiteLabelLoading ?
            <RouterLink to={props.to}>
                <img src={
                    props.org !== undefined && props.org !== null && props.org.orgSettings !== undefined && ['full'].includes(props.org.orgSettings.whiteLabelLevel) &&
                    props.whiteLabel !== undefined && props.whiteLabel !== null && props.whiteLabel.logoURL !== undefined && props.whiteLabel.logoURL !== null ? 
                        props.whiteLabel.logoURL : logoIcon} alt="Canveo" className={props.size === 'l' ? classes.logoIconL : classes.logoIcon} />
            </RouterLink>
            :
            <Skeleton variant="circle" animation="wave" width={35} height={35} />
            }
        </Grid>
    </Grid>
    );
}

export default Logo
