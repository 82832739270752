import React from 'react';
import theme from '../../theme/theme';
import { randomString } from '../../utils'
import { prices } from '../../assets/static/prices';
import PropTypes from 'prop-types';
import { Link as RouterLink, Redirect, useHistory } from 'react-router-dom';
import { Avatar, Box, Button, Checkbox, CircularProgress, Container, Dialog, FormControlLabel,
         Grid, IconButton, InputAdornment,
         List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, MobileStepper, 
         TextField, Typography,
         makeStyles } from '@material-ui/core';
import { Header, FAB, GetStarted, ErrorMsg, UploadImg, ChipUserType, SelectUserRole, InformationalTooltip } from '../../components';
import { UserForm } from '../../components/forms';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faEnvelope, faExclamationTriangle, faTag, faTimes, 
         faUser, faUserCircle, faUserTie } from "@fortawesome/free-solid-svg-icons";

import defaultUser from '../../assets/img/defaultuser.png';

const axios = require('axios');
const dayjs = require('dayjs')

function SimpleDialog(props) {
  const useStyles = makeStyles(theme => ({
    deleteButton: {
      width: '120px',
      color: theme.palette.error.main, 
      boxShadow: '0px 1px 4px 0px rgba(208,37,46,0.3)',
      '&:hover': {
        backgroundColor: theme.palette.primary.contrastText,
        boxShadow: '0px 1px 4px 2px rgba(208,37,46,0.3)',
      },
    },
    closeButton: {
      position: 'absolute',
      right: '10px',
      top: '10px',
      color: theme.palette.grey[500],
    },
    infoIcon: {
      color: theme.palette.grey[600],
      marginLeft: '15px',
    }
  }));
  const classes = useStyles();  
  const { onClose, selectedValue, readOnly, open, handleChangeUserRole, handleChangeReadOnly,
    handleChangeUserTitle, handleSubmitUserChange, modules, userRole, adminOnly } = props;

  const handleClose = () => {    
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth maxWidth="xs">
      <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
        <FontAwesomeIcon icon={faTimes} />
      </IconButton>

      <Box mt={3} mb={1} mr={6} ml={6} align="center">

        <Avatar alt={props.activeUser.displayName} src={props.activeUser.photoURL} style={{width: '70px', height: '70px'}}></Avatar>
        <Box mt={1}>
          <Typography align="center">
            {props.activeUser.displayName}
            {props.activeUser.title !== undefined && props.activeUser.title !== null && props.activeUser.title !== '' ? ', ' + props.activeUser.title : ''}
          </Typography>
          <Typography align="center" color="textSecondary" variant="body2" gutterBottom>
            {props.activeUser.email}
          </Typography>
        </Box>
        {userRole === undefined || userRole === null || !userRole.startsWith("ClientFor_") ?
        <>
        <Box mt={2}>
          <Typography variant="body2">
            Update user role or title
          </Typography>
        </Box>
        <Box mt={2} mb={1} align="left">
          <SelectUserRole 
            initialVal={userRole} 
            handleChangeUserRole={handleChangeUserRole} 
            modules={modules}
            adminOnly={adminOnly}
          />
        </Box>
        <Box mt={2} mb={1}>
          <FormControlLabel
            disabled={userRole === 'Admin'}
            control={<Checkbox checked={readOnly} onChange={handleChangeReadOnly} name="readOnly" />}
            label={<>Disable Editing</>}
          />
        </Box>
        </>
        :''}
        <Box mt={2} mb={1} align="left">
          <TextField
              required
              defaultValue={props.activeUser.title}
              onChange={e => handleChangeUserTitle(e)}
              fullWidth
              variant="outlined"
              id="title"
              label="Title"
              placeholder="Title"
              name="title"
              autoComplete="title"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faTag} color={theme.palette.grey[600]} />
                  </InputAdornment>
                ),
              }}          
            />
        </Box>
        <Box mt={2} mb={1}>
          <Button
            fullWidth
            onClick={e => handleSubmitUserChange(e)}
            disableElevation
            type="submit"
            variant="contained"
            color="primary"
            >Submit
          </Button>
        </Box>
        <Box mt={2}>
          <Typography variant="body2">
            or
          </Typography>
        </Box>
        <Box mt={2} mb={3}>
          <Button className={classes.deleteButton} 
            component={RouterLink} to={"/core/admin/usermgt/delete/" + props.activeUser._id}>
            Delete user
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function RenderIcon({type}) {
  if(type ==='Admin'){
    return (<FontAwesomeIcon icon={faUserTie} color={theme.palette.secondary.main} size="lg" />);
  } else if (['Business', 'Sales', 'Procurement', 'Partnerships', 'HR', 'Corporate'].includes(type)){
    return (<FontAwesomeIcon icon={faUser} color={theme.palette.primary.main} size="lg" />);
  } else if (type === 'Legal'){
    return (<FontAwesomeIcon icon={faUserTie} color={theme.palette.tertiary.main} size="lg" />);
  } else {
    return (<FontAwesomeIcon icon={faUser} color={theme.palette.primary.main} size="lg" />);
  }
}

function UserMgt(props) {

  const useStyles = makeStyles(theme => ({
    outerFlexGrid: {
      minHeight: '80vh',
      paddingTop: '100px',
      paddingBottom: '80px',
    },
    container: {
      [theme.breakpoints.down('sm')]: { paddingLeft: '20px', paddingRight: '20px' },
      [theme.breakpoints.only('md')]: { paddingLeft: '40px', paddingRight: '40px' },
      [theme.breakpoints.up('lg')]: { paddingLeft: '60px', paddingRight: '60px' },
    },
    stepper: {
      backgroundColor: theme.palette.primary.contrastText
    },
    avatar: {
      width: '80px',
      height: '80px',
      marginTop: '10px'
    },
    mtAndWide: {
      marginTop: '30px',
      width: '250px'
    },
    primary: {
      color: theme.palette.primary.main
    },
    error: {
      color: theme.palette.error.main
    },
    errorButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.primary.contrastText
    },
    greyIcon: {
      color: theme.palette.grey[600],
      fontSize: '20px'
    },
    list: {
      marginBottom: '60px',
    },
    bold: {
      fontWeight: '700',
    }
  }));
  const classes = useStyles();

  const history = useHistory()

  const [open, setOpen] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);  
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeUser, setActiveUser] = React.useState((props.uid !== undefined) ? props.users.filter(usr => usr._id === props.uid)[0] : {});
  const [userRole, setUserRole] = React.useState(null)
  const [readOnly, setReadOnly] = React.useState((props.uid !== undefined) && props.users.filter(usr => usr._id === props.uid)[0] !== undefined ? 
    props.users.filter(usr => usr._id === props.uid)[0].readOnly : false)
  const [userTitle, setUserTitle] = React.useState(null)
  const [formError, setFormError] = React.useState('');
  const [confirmedNavigate, setConfirmedNavigate] = React.useState(false);
  const [formData, setFormData] = React.useState({
    firstName: '',
    lastName: '',
    role: props.org !== undefined && ['custE'].includes(props.org.clientType) ? 'Business' : 'Admin',
    readOnly: false,
    title: '',
    email: '',
    photoURL: '',
    userDataDone: false,
  })
  const modules = props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
                    ['sales','procurement','partnerships','people','corporate'] : 
                  props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
                    props.org.modules : [] 

  const handleChangeUserRole = (event) => {
    if(event.target.value === 'Admin') { setReadOnly(false) }
    setUserRole(event.target.value)
  }

  const handleChangeReadOnly = () => {
    setReadOnly(!readOnly)
  }

  const handleChangeUserTitle = (event) => {
    setUserTitle(event.target.value)
  }

  const handleSubmitUserChange = (event) => {

    let defaultModule = // Update the default Module if the new userRole does not allow for the current defaultModule
      ['Business'].includes(userRole) && !['sales', 'procurement', 'partnerships'].includes(activeUser.defaultModule) ? 'sales' : 
      ['Corporate'].includes(userRole) && !['corporate'].includes(activeUser.defaultModule) ? 'corporate' : 
      ['HR'].includes(userRole) && !['people'].includes(activeUser.defaultModule) ? 'people' :
      ['Procurement'].includes(userRole) && !['procurement'].includes(activeUser.defaultModule) ? 'procurement' : 
      ['Partnerships'].includes(userRole) && !['partnerships'].includes(activeUser.defaultModule) ? 'partnerships' : 
      ['Sales'].includes(userRole) && !['sales'].includes(activeUser.defaultModule) ? 'sales' : 
      activeUser.defaultModule
      
    props.updateUserRole(activeUser._id, props.user.orgID, userRole, defaultModule, readOnly, userTitle, false);
    handleClose();
  }

  const handleClickOpenEdit = (e, user) => {
    setActiveUser(user)
    setUserRole(user.role)
    setUserTitle(user.title)
    setReadOnly(user.readOnly)
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    if(activeStep === 0) {
      history.push('/core/admin/usermgt')
    } else {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitting(true);
    const { firstName, lastName, title, email, 
            role, readOnly, photoURL, userDataDone } = formData

    let defaultModule = 
      ['Counterparty'].includes(role) ? 'counterparty' :
      ['Admin', 'Business', 'Legal', 'Sales'].includes(role) ? 'sales' :
      ['Corporate'].includes(role) ? 'corporate' :
      ['HR'].includes(role) ? 'people' :
      ['Procurement'].includes(role) ? 'procurement' :
      ['Partnerships'].includes(role) ? 'partnerships' : 'sales'

    // Tests on the client side
    if(!userDataDone){
      setFormError('Please go back to complete the personal information of the new user');
      setSubmitting(false);
    } else if(props.users.filter(usr => usr.email === email)[0] !== undefined) {
      setFormError('This user already exists, choose a different email address');
      setSubmitting(false);
    } else {
      // Now send to server side for submission / validation
 
      axios.get('/user/protect/user/mail/' + email)
      .then(function(res) { // User found, craft warning message
        if(res.data.data !== undefined && res.data.data !== null && res.data.data.role === 'Counterparty') {
          setFormError("This user is already setup as a counterparty user, please contact canveo support for help.")
          setSubmitting(false);
        } else if (res.data.data !== undefined && res.data.data !== null) {
          setFormError("This user is setup for another Canveo subscriber, please contact canveo support for help.")
          setSubmitting(false);
        } else { // User not found - thus add
          let string = randomString(20);

          props.createUser(
            props.user._id,
            props.user.orgID, 
            firstName,
            lastName,
            title,
            email,
            string,
            role,
            defaultModule,
            readOnly,
            photoURL,
            [], // agrIDs
            props.org.shortName, // orgShortName
            props.user.displayName, // adminDisplayName
          );   
        
          setConfirmedNavigate(true);
        }
      }).catch(function(err) { console.log(err) })
    }
  } 

  const callbackUserForm = (child) => {
    setFormError('');
    setFormData({ ...formData, 
      firstName: child.firstName !== undefined && child.firstName !== null ? child.firstName.trim() : '', 
      lastName: child.lastName !== undefined && child.lastName !== null ? child.lastName.trim() : '', 
      role: child.role,
      readOnly: child.readOnly,
      title: child.title !== undefined && child.title !== null ? child.title.trim() : '',
      email: child.email !== undefined && child.email !== null ? child.email.trim().toLowerCase() : '',
      userDataDone: true });
    handleNext();
  }

  const callbackAvatarUploadForm = (child) => {
    setFormData({ ...formData, 
      photoURL: child });
    handleNext();
  }

  const confirmDelete = () => {
    props.deleteUser(props.uid, props.user._id, props.user.orgID, false);
    setConfirmedNavigate(true);
  }

  switch(props.action) {
    case 'new': 
      if(confirmedNavigate) {
        return (<Redirect to="/core/admin/usermgt" push={true} />)
      } else {
    
        return (
          <div>
            <Header module="admin"
              active="Users"
              crumbs={[{name: "User Management", to: "/core/admin/usermgt"}, {name: "New user", to: "active"} ]}
              user={props.user}
              org={props.org}
              modules={modules}
              notifications={props.notifications}
              markNotificationAsRead={props.markNotificationAsRead}
              markAllNotificationsAsRead={props.markAllNotificationsAsRead} />
    
            <Container maxWidth="lg" className={classes.container}>
            <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
              <Grid item>
                <Box mb={4} mt={2}>
                  <Typography align="center" variant="h4" className={classes.bold}>
                      You are adding a new user&nbsp;&nbsp;<InformationalTooltip msg={"Canveo Enterprise customers can configure different user roles (e.g. Legal, Business or Sales users).\n\n Further, Enterprise customers can \"disable editing\" for a user profile other than Admin or Legal.\n\nSuch a user can create agreements from internal templates, configure smart fields, view and comment on agreements but not edit agreements. The user can send agreements to counterparties and add but not remove internal signers."} />
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box mb={4}>
                  <MobileStepper
                    variant="dots"
                    steps={3}
                    position="static"
                    activeStep={activeStep}
                    className={classes.stepper}
                    nextButton={
                      <Button size="small" onClick={handleNext} disabled={activeStep === 2}>
                        Next&nbsp;<FontAwesomeIcon icon={faChevronRight} />
                      </Button>
                    }
                    backButton={
                      <Button size="small" onClick={handleBack}>               
                        <FontAwesomeIcon icon={faChevronLeft} />&nbsp;Back
                      </Button>
                    }
                  />
                </Box>

              </Grid>

              { (activeStep === 0) ? (
  
              <Grid item>

                <Box mb={3}>
                <UserForm
                  adminOnly={props.org !== undefined && !['custE'].includes(props.org.clientType)}
                  parentCallback={callbackUserForm}
                  type="new"
                  modules={modules}
                  initialValues={{
                    firstName: formData.firstName, 
                    lastName: formData.lastName,
                    email: formData.email.toLowerCase(),
                    role: formData.role,
                    readOnly: formData.readOnly,
                    title: formData.title }} />
                </Box>
                
              </Grid>

              ) : '' }
              { (activeStep === 1) ? (

                  formData.photoURL !== '' ?
                  (
                    <Grid item>
                      <Box mt={2} mb={3} align="center">                
                        <UploadImg 
                          type="avatar" 
                          parentCallback={callbackAvatarUploadForm}
                          initialImg={formData.photoURL} />
                      </Box>
                    </Grid>
                  ) : 
                  (
                    <Grid item>
                      <Box mt={1} mb={3} align="center">                
                        <UploadImg 
                          type="avatar" 
                          parentCallback={callbackAvatarUploadForm} 
                          initialImg={null}
                        />
                      </Box>
                      <Box mb={7} align="center">
                        <Button variant="outlined" color="primary" disableElevation 
                          onClick={handleNext} >
                          Skip this step
                        </Button>
                      </Box>
                    </Grid>
                  )

              ) : '' }
              { (activeStep === 2) ? (

              <Grid item>

                    <Grid item container direction="column" justify="center" alignItems="center" spacing={2}>
                      <Grid item>
                        <Box mt={0} mb={1} ml={4} mr={4}>
                          <Typography variant="body1" align="center">
                            Please review the information, further instructions will be sent to the user on how to get started:
                          </Typography>
                          { formError !== '' ? 
                            <Typography variant="subtitle1" align="center" color="error">
                              {formError}
                            </Typography>
                          : '' }
                        </Box>
                      </Grid>                   
                      <Grid item>
                        <Box height={100}>
                          <Avatar alt="User name" src={formData.photoURL !== undefined && formData.photoURL !== '' ? formData.photoURL : defaultUser}
                            className={classes.avatar} />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box mb={3}>
                          <ChipUserType role={formData.role} readOnly={formData.readOnly} />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box minWidth={240}>
                          <Grid item container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item>
                              <FontAwesomeIcon icon={faUserCircle} className={classes.greyIcon} />
                            </Grid>
                            <Grid item>
                              <Typography variant="subtitle2">
                                {(formData.firstName !== '' && formData.lastName !== '') ? 
                                  (formData.firstName + ' ' + formData.lastName ) : 
                                  'Not yet provided'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box minWidth={240}>
                          <Grid item container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item>
                              <FontAwesomeIcon icon={faEnvelope} className={classes.greyIcon} />
                            </Grid>
                            <Grid item>
                              <Typography variant="subtitle2">
                                {formData.email !== '' ? formData.email : 'Not yet provided'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item>

                        <Box mb={5} align="center">
                          <Button variant="contained" color="primary" disableElevation 
                            onClick={handleSubmit}
                            disabled={submitting}
                            className={classes.mtAndWide}>
                            { submitting && (<CircularProgress size={24} /> )}
                            { submitting ? 'Submitting...' : 'Looks good, submit' }
                          </Button>
                        </Box>

                      </Grid>
                    </Grid>

              </Grid>

              ) : '' }

            </Grid>
            </Container>
          </div>
        )
      }

    case 'delete' :

      if(confirmedNavigate || activeUser === undefined) {
        return (<Redirect to="/core/admin/usermgt" push={true} />)
      } else {
      
        return (
          <div>
            <Header module="admin"
              active="Users"
              crumbs={[{name: "User Management", to: "/core/admin/usermgt"}, {name: "Delete user", to: "active"} ]}
              user={props.user}
              org={props.org}
              modules={modules}
              notifications={props.notifications}
              markNotificationAsRead={props.markNotificationAsRead}
              markAllNotificationsAsRead={props.markAllNotificationsAsRead} />
    
            <Container maxWidth="lg" className={classes.container}>
            <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
              <Grid item container alignItems="center" justify="center">
                <Box mb={5} mt={3}>
                  <FontAwesomeIcon icon={faExclamationTriangle} size="5x" className={classes.error} />
                </Box>
              </Grid>
              <Grid item container alignItems="center" justify="center">
                <Typography align="center" variant="body1">
                  Are you sure you want to delete the following user?
                </Typography>              
              </Grid>
              <Grid item container direction="column" alignItems="center" justify="center">

                <Box mt={5}>
                  <Avatar alt={activeUser.displayName} src={activeUser.photoURL} style={{width: '70px', height: '70px'}}></Avatar>
                </Box>
                <Box mt={2} mb={6}>
                  <Typography align="center" variant="h5">
                    {activeUser.displayName}
                  </Typography>
                  <Typography align="center" color="textSecondary" variant="body1">
                    {activeUser.role}
                  </Typography>
                </Box>

              </Grid>
              <Grid item container alignItems="center" justify="center">
                <Box mb={5}>
                  <Button variant="contained" disableElevation
                    className={classes.errorButton} onClick={confirmDelete}>
                    Confirm
                  </Button>
                  &nbsp;&nbsp;
                  <Button variant="outlined" className={classes.error}
                    component={RouterLink} to="/core/admin/usermgt">
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
            </Container>
          </div>
        )
      }    

    default:

      return(
        <div>
          <Header module="admin"
            active="Users"
            crumbs={[{name: "User Management", to: "active"} ]}
            user={props.user}
            org={props.org}
            modules={modules}
            notifications={props.notifications}
            markNotificationAsRead={props.markNotificationAsRead}
            markAllNotificationsAsRead={props.markAllNotificationsAsRead} />
  
          <Container maxWidth="lg" className={classes.container}>
          {props.user.role !== 'Counterparty' && (props.user.introComplete === undefined || props.user.introComplete.dashboard === undefined || props.user.introComplete.dashboard === null || !props.user.introComplete.dashboard )  &&
           props.org !== undefined && props.org.clientType !== 'legalOrg' ?
              <GetStarted 
              fromDashboard={false}
              step={"1_3"}
              pushDown={false}
              module={props.module}
              user={props.user}
              org={props.org}
              users={props.users}
              />
          :''}
          <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
            <Grid item>
              <Box mb={5} mt={2}>
                <Typography align="center" variant="h4" className={classes.bold}>
                    User Management&nbsp;&nbsp;<InformationalTooltip msg={"Create a new user by clicking on the Add User button at the bottom right of the screen.\n\nUpdate or Delete existing users by clicking on the specific user item."} />
                </Typography>
              </Box>
            </Grid>

            {
            props.usersError !== null ? 
            (
              <ErrorMsg clearError={props.clearUsersError} msg={props.usersError.message} />
            ) : 
            (
              <React.Fragment>

                <FAB // Custom FAB Component
                  iconType="userplus"
                  routeTo="/core/admin/usermgt/new/start"
                  disabled={
                    (props.org.clientType === 'custB' && (props.org.stripecusid === undefined || props.org.stripecusid === null || props.org.stripecusid === '') && props.users.filter((u) => u.active).length  >= 2) ||
                    (props.org.clientType === 'custS' && props.users.filter((u) => u.active).length >= prices.filter((p) => p.custType === 'Starter')[0].maxUsers) ||
                    (props.org.clientType === 'custB' && props.users.filter((u) => u.active).length >= prices.filter((p) => p.custType === 'Business')[0].maxUsers) ||
                    (props.org.clientType === 'cust' && props.users.filter((u) => u.active).length >= prices.filter((p) => p.custType === 'Business Premium')[0].maxUsers)} />
      
                <Grid item container alignItems="center" justify="center">
      
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <List component="nav" className={classes.list}>
                      {props.users
                      .filter((u) => u.active)
                      .sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0))
                      .map(function(usr, i){
                        return (
                          <ListItem key={i} button onClick={(e) => handleClickOpenEdit(e, usr)}>
                            <ListItemAvatar>
                              <Avatar alt="User Name" src={usr.photoURL !== undefined && usr.photoURL !== '' ? usr.photoURL : defaultUser}></Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={usr.displayName}
                              secondary={"Member since: " + (usr.creationDate !== undefined ? dayjs(usr.creationDate).format('MMM D, YYYY') : "unknown")}
                            />
                            <ListItemSecondaryAction>
                              <Box display={{ xs: 'none', sm: 'block' }}>
                                <ChipUserType role={usr.role} readOnly={usr.readOnly} type="tiny" />
                              </Box>
                              <Box display={{ xs: 'block', sm: 'none' }}>
                                <RenderIcon type={usr.role} />
                              </Box>
                            </ListItemSecondaryAction>
                          </ListItem>
                        )
                      })}          
                    </List>
                  </Grid>

                  {(props.org.clientType === 'custS' && props.users.filter((u) => u.active).length >= prices.filter((p) => p.custType === 'Starter')[0].maxUsers) ||
                   (props.org.clientType === 'custB' && props.users.filter((u) => u.active).length >= prices.filter((p) => p.custType === 'Business')[0].maxUsers) ||
                   (props.org.clientType === 'cust' && props.users.filter((u) => u.active).length >= prices.filter((p) => p.custType === 'Business Premium')[0].maxUsers) ?
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Box mt={0} mb={5} align="center">
                      <Typography variant="subtitle1" color="textSecondary">
                        An upgrade is required to add additional users.
                        <Button variant="text" color="secondary" component={RouterLink} to="/core/admin/company/subscription/1">Upgrade to {props.org.clientType === 'custS' ? "Business" : props.org.clientType === 'custB' ? "Premium" : "Enterprise"}</Button>
                      </Typography>
                    </Box>
                  </Grid>
                  : // Free Trial or AppSumo customers
                  (props.org.clientType === 'custB' && (props.org.stripecusid === undefined || props.org.stripecusid === null || props.org.stripecusid === '') && props.users.filter((u) => u.active).length  >= 2) ?
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Box mt={0} mb={5} align="center">
                      <Typography variant="subtitle1" color="textSecondary">A subscription is not found yet.</Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Contact Canveo Support to add additional users.
                        <Button variant="text" color="secondary" component={RouterLink} to="/contact/support">Contact Canveo Support</Button>
                      </Typography>
                    </Box>
                  </Grid>
                  : '' }
      
                </Grid>
              </React.Fragment>
            )
            }
  
          </Grid>
          </Container>
          <SimpleDialog open={open} onClose={handleClose} adminOnly={props.org !== undefined && !['custE'].includes(props.org.clientType)}
            activeUser={activeUser} handleSubmitUserChange={handleSubmitUserChange}
            handleChangeUserRole={handleChangeUserRole} handleChangeUserTitle={handleChangeUserTitle} 
            handleChangeReadOnly={handleChangeReadOnly} readOnly={readOnly} modules={modules} userRole={userRole !== null ? userRole : activeUser.role} />    
        </div>
      );

    }

}

export default UserMgt;