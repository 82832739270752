function getEditModeOption(val, side, isXS) {
    let s = '';

    if(val === 'none' && isXS) { s = 'Read-only' }
    else if(val === 'none') { s = 'Read-only - ' + side + ' can only read and sign the agreement' }
    if(val === 'comment' && isXS) { s = 'Comments-only' }
    else if(val === 'comment') { s = 'Comments-only - ' + side + ' can add comments' }
    if(val === 'edit' && isXS) { s = 'Limited editing' }
    else if(val === 'edit') { s = 'Limited editing - ' + side + ' can make changes, and accept *' + (side === 'Counterparty' ? 'your' : 'counterparty') + '* edits only' }
    if(val === 'full' && isXS) { s = 'Full editing' }
    else if(val === 'full') { s = 'Full editing - ' + side + ' can make changes, and also accept or reject all edits' }
    return s;
}

export { getEditModeOption }