export const prices = [
    { 
        label: 'Canveo Starter', 
        custType: 'Starter',
        clientType: 'custS',
        currencies: [
            {amount: 12, addUserAmt: 9, ccy: 'GBP', symbol: '£', stripeid: 'na', stripeid_test: 'na', stripeuid: 'na', stripeuid_test: 'na' /*stripeid: 'price_1ILUh3GTo1OR8YkMjNg9DbN3', stripeuid: 'price_1ILUjpGTo1OR8YkMMJwQodj3'*/},
            {amount: 12, addUserAmt: 9, ccy: 'EUR', symbol: '€', stripeid: 'na', stripeid_test: 'na', stripeuid: 'na', stripeuid_test: 'na' /*stripeid: 'price_1ILUiAGTo1OR8YkMvfiMbm0m', stripeuid: 'price_1ILUkAGTo1OR8YkMz1gCMQzy'*/},
            {amount: 12, addUserAmt: 9, ccy: 'CHF', symbol: 'CHF', stripeid: 'na', stripeid_test: 'na', stripeuid: 'na', stripeuid_test: 'na' /*stripeid: 'price_1ILUhYGTo1OR8YkMcPQSyb73', stripeuid: 'price_1ILUkVGTo1OR8YkMXEbbDY30'*/},
            {amount: 12, addUserAmt: 9, ccy: 'USD', symbol: '$', stripeid: 'na', stripeid_test: 'na', stripeuid: 'na', stripeuid_test: 'na' /*stripeid: 'price_1ILUgRGTo1OR8YkMOfvnJ5mL', stripeuid: 'price_1ILUkvGTo1OR8YkMUfuEsKan'*/},
        ],
        usersBase: 1,
        maxUsers: 2,
        agrsPerMonth: 3,
        maxTemplates: 3,
        maxEntities: 1,
    },
    {
        label: 'Canveo Business', 
        custType: 'Business', 
        clientType: 'custB',
        currencies: [
            {amount: 59, addUserAmt: 29, ccy: 'GBP', symbol: '£', stripeid: 'price_1IRlv7GTo1OR8YkMFjhRXDyh', stripeuid: 'price_1IRluMGTo1OR8YkMlc0dxiix', stripeid_test: 'price_1ILUqgGTo1OR8YkM6eOOQyqW', stripeuid_test: 'price_1ILUs4GTo1OR8YkMWh5rHTsw'},
            {amount: 59, addUserAmt: 29, ccy: 'EUR', symbol: '€', stripeid: 'price_1IRlvGGTo1OR8YkMqvbSpAA7', stripeuid: 'price_1IRlucGTo1OR8YkMcXGIihyP', stripeid_test: 'price_1ILUqIGTo1OR8YkMQPu3fTSe', stripeuid_test: 'price_1ILUrbGTo1OR8YkMijGM2mZY'},
            {amount: 59, addUserAmt: 29, ccy: 'CHF', symbol: 'CHF', stripeid: 'price_1IRlvOGTo1OR8YkMm24euJp0', stripeuid: 'price_1IRluTGTo1OR8YkMo9Dj6dwh', stripeid_test: 'price_1ILUpuGTo1OR8YkMjyA3k4rE', stripeuid_test: 'price_1ILUrqGTo1OR8YkMTX3sUpPB'},
            {amount: 59, addUserAmt: 29, ccy: 'USD', symbol: '$', stripeid: 'price_1Jk6RhGTo1OR8YkMqtscsHTO', stripeuid: 'price_1Jk6SPGTo1OR8YkMdDIegLGY', stripeid_test: 'price_1ILUpUGTo1OR8YkMOSXcVcfg', stripeuid_test: 'price_1ILUrIGTo1OR8YkMSZ3AgOVI' },
        ],
        usersBase: 2,
        maxUsers: 5,
        agrsPerMonth: 2,
        maxTemplates: 5,
        maxEntities: 1,
    },
    { 
        label: 'Canveo Business Premium', 
        custType: 'Business Premium', 
        clientType: 'cust',
        currencies: [
            {amount: 699, addUserAmt: 69, ccy: 'GBP', symbol: '£', stripeid: 'price_1IRltdGTo1OR8YkMUzasK2en', stripeuid: 'price_1IRlrGGTo1OR8YkMsp0g3jkj', stripeid_test: 'price_1ILUvhGTo1OR8YkMPpYELSQ4', stripeuid_test: 'price_1ILUxWGTo1OR8YkM5M64LhAj'},
            {amount: 699, addUserAmt: 69, ccy: 'EUR', symbol: '€', stripeid: 'price_1IRltYGTo1OR8YkMHTVA4JcR', stripeuid: 'price_1IRlt4GTo1OR8YkMhzTAzbK8', stripeid_test: 'price_1ILUvxGTo1OR8YkMoMX7hhYb', stripeuid_test: 'price_1ILUwnGTo1OR8YkMGkR1vUA3'},
            {amount: 699, addUserAmt: 69, ccy: 'CHF', symbol: 'CHF', stripeid: 'price_1IRltPGTo1OR8YkMjTcBKnIO', stripeuid: 'price_1IRlrPGTo1OR8YkMf2V7DpyC', stripeid_test: 'price_1ILUwDGTo1OR8YkM6cdMRS7Q', stripeuid_test: 'price_1ILUx2GTo1OR8YkMvtpK8hwR'},
            {amount: 699, addUserAmt: 69, ccy: 'USD', symbol: '$', stripeid: 'price_1IRlu0GTo1OR8YkMbSmm2XGk', stripeuid: 'price_1IRltAGTo1OR8YkMpxMYmQp8', stripeid_test: 'price_1ILUvUGTo1OR8YkMtqMm8fEx', stripeuid_test: 'price_1ILUwYGTo1OR8YkMjSXx9VPV'},
        ],
        usersBase: 8,
        maxUsers: 15,
        agrsPerMonth: 5,
        maxTemplates: 9999999,
        maxEntities: 9999999,
    },
    { 
        label: 'Canveo Enterprise', 
        custType: 'Enterprise', 
        clientType: 'custE',
        currencies: [
            {amount: 'TBD', addUserAmt: 'TBD', ccy: 'GBP', symbol: '£', stripeid: 'todo', stripeuid: 'todo'},
            {amount: 'TBD', addUserAmt: 'TBD', ccy: 'EUR', symbol: '€', stripeid: 'todo', stripeuid: 'todo'},
            {amount: 'TBD', addUserAmt: 'TBD', ccy: 'CHF', symbol: 'CHF', stripeid: 'todo', stripeuid: 'todo'},
            {amount: 'TBD', addUserAmt: 'TBD', ccy: 'USD', symbol: '$', stripeid: 'todo', stripeuid: 'todo'},
        ],
        usersBase: 10,
        maxUsers: 9999999,
        agrsPerMonth: 9999999,
        maxTemplates: 9999999,
        maxEntities: 9999999,
    },
];