import { INCREASE_DASHBOARD_VISITS, DEFINE_SESSION_EXPIRY, DEFINE_SMART_MODE } from '../ActionTypes';

// ORG
export const UserSession = (state  = { 
    dashboardVisits: { sales: 0, procurement: 0, partnerships: 0, counterparty: 0, people: 0, corporate: 0 },
    smartMode: { templating: true, agreement: true },
    expiry: null,
}, action) => {
switch(action.type) {
        case INCREASE_DASHBOARD_VISITS: 
            return {
                ...state,
                dashboardVisits:  {
                    sales: state.dashboardVisits.sales + (action.payload === 'sales' ? 1 : 0), 
                    procurement: state.dashboardVisits.procurement + (action.payload === 'procurement' ? 1 : 0), 
                    partnerships: state.dashboardVisits.partnerships + (action.payload === 'partnerships' ? 1 : 0), 
                    counterparty: state.dashboardVisits.counterparty + (action.payload === 'counterparty' ? 1 : 0),
                    corporate: state.dashboardVisits.corporate + (action.payload === 'corporate' ? 1 : 0), 
                    people: state.dashboardVisits.people + (action.payload === 'people' ? 1 : 0)
                }
            }
        case DEFINE_SESSION_EXPIRY:
            return {
                ...state,
                expiry: action.payload
            }
        case DEFINE_SMART_MODE:
            return {
                ...state,
                smartMode: action.payload
            }
        default: 
            return state;
    }
};