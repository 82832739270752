import * as ActionTypes from '../ActionTypes';
import { convertBlocksToPlain, convertPlainToBlocks, randomString as generateRandomString } from '../../utils';
import { lastUpdateRel, createActivityLog } from '.';
import { updateOATNewClauses } from './templateActions';

const axios = require('axios');

export const fetchAgrsError = (error) => ({
    type: ActionTypes.FETCH_AGRS_ERROR,
    payload: error
});

export const updateAgr = (rid, aid, primaryCptyID, secondaryCptyID, agrStatus, curAVOwner, sourceFileID, contributors, signatories, lastUpdateBy) => {
  return dispatch => {
    
    let lastUpdateDate = new Date().toISOString();
    axios.put('/agr/agr/' + aid, {
      primaryCptyID: primaryCptyID, 
      secondaryCptyID: secondaryCptyID,
      agrStatus: agrStatus, 
      curAVOwner: curAVOwner,
      sourceFileID: sourceFileID,
      contributors: contributors, 
      signatories: signatories,
      lastUpdateBy: lastUpdateBy,
      lastUpdateDate: lastUpdateDate,
    })
    .then(function (res) { // success
        dispatch(lastUpdateRel(rid, lastUpdateBy));
    })
    .catch(function (err) { // err
        dispatch(fetchAgrsError(err))
    })       
  }
};

export const updateAgrUsers = (aid, contributors, signatories) => {
  return dispatch => {    
    axios.put('/agr/agrusers/' + aid, {
      contributors: contributors, 
      signatories: signatories,
    })
  }
};

export const updateAgrPriority = (aid, newPriority, lastUpdateBy) => {
  return dispatch => {    
    let lastUpdateDate = new Date().toISOString();
    axios.put('/agr/agrprio/' + aid, {
      priority: newPriority, 
      lastUpdateBy: lastUpdateBy,
      lastUpdateDate: lastUpdateDate
    })
  }
};

export const updateAgrNames = (aid, agrFullName, agrShortName, lastUpdateBy) => {
  return dispatch => {    
    let lastUpdateDate = new Date().toISOString();
    axios.put('/agr/agrnames/' + aid, {
      agrFullName: agrFullName, 
      agrShortName: agrShortName,
      lastUpdateBy: lastUpdateBy,
      lastUpdateDate: lastUpdateDate
    })
  }
};

export const updateAgrStatus = (aid, agrStatus, lastUpdateBy) => {
  return dispatch => {    
    let lastUpdateDate = new Date().toISOString();
    axios.put('/agr/agrstatus/' + aid, {
      agrStatus: agrStatus, 
      lastUpdateBy: lastUpdateBy,
      lastUpdateDate: lastUpdateDate
    })
  }
};

export const createAgr = (rid, agrTypeID, primaryCptyID, secondaryCptyID, tertiaryCptyID, contributors, signatories, agrStatus, curAVOwner, avv, sourceFileID, agrFullName, 
                          agrShortName, parentAID, effectiveDate, renewalType, effectiveTerm, expiryDate, 
                          agrPartType, priority, linkedToAID, amendment, creationBy, templateClauses, importedDoc, exhibits, activate, smartFieldObject) => {
  // contributors && signatories: [{ uid, entity }]
  // amendment: { amendType, amendForAID }
  // exhibits: [{ atid, importedDoc, agrFullName, agrShortName, oat }]
  // smartFieldObject: { sfs[], sfPush[] }
  return dispatch => {

    let newAVV = (avv !== null ? avv : "00.001.001.000001")
    let creationDate = new Date().toISOString();
    let isAttachment = sourceFileID !== undefined && sourceFileID !== null && sourceFileID.title !== undefined;

    axios.post('/agr/agr', { // Create the agreement
      relID: rid, 
      agrTypeID: agrTypeID, 
      primaryCptyID: primaryCptyID, 
      secondaryCptyID: secondaryCptyID, 
      tertiaryCptyID: tertiaryCptyID,
      contributors: contributors,
      signatories: signatories,
      agrStatus: agrStatus, 
      curAVOwner: curAVOwner,
      sourceFileID: isAttachment ? 'attachment' : sourceFileID, 
      agrFullName: agrFullName,
      agrShortName: agrShortName, 
      parentAID: parentAID,
      effectiveDate: effectiveDate,
      renewalType: renewalType,
      effectiveTerm: effectiveTerm,
      expiryDate: expiryDate,
      agrPartType: agrPartType,   
      priority: priority,
      linkedToAID: linkedToAID,
      amendment: amendment,
      creationBy: creationBy,
      creationDate: creationDate,
      lastUpdateBy: creationBy,
      lastUpdateDate: creationDate,
    })
    .then(function (resag) { // got a msg from the server
      if(resag.data.success) { // successfully created agreement
        dispatch(createActivityLog('agr', 'agrNewStatus', 'Agreement status changed to: ' + agrStatus, '', curAVOwner, rid, 
                                   resag.data.data._id, '', '', creationBy, false, agrStatus, '', {}))
         
        dispatch(lastUpdateRel(rid, creationBy)) // update the REL "lastUpdate"

        if(exhibits.length > 0) { // if the agreement has any exhibits then trigger the same createAgr function for each.
          exhibits.forEach((e) => {
            dispatch(createAgr( // createAgr for exhibit
              rid, // rid
              e.atid, // agrTypeID 
              primaryCptyID, // primaryCptyID
              secondaryCptyID, // secondaryCptyID
              tertiaryCptyID, // tertiaryCptyID
              [], // contributors
              [], // signatories
              'Exhibit', // agrStatus
              curAVOwner, // curAVOwner
              newAVV, // avv
              e.importedDoc !== null && e.importedDoc.data !== null ? e.importedDoc.data.docURL : '', // sourceFileID
              e.fullName, // agrFullName
              e.shortName, // agrShortName
              resag.data.data._id, // parentAID 
              null, // effectiveDate
              'none', // renewalType
              null, // effectiveTerm
              null, // expiryDate
              e.agrPartType, // agrPartType
              e.priority, // priority
              '', // linkedToAID
              {}, // amendment
              creationBy, // creationBy
              e.oat !== null && e.oat.clauses !== undefined && e.oat.clauses !== null ? e.oat.clauses : [], // templateClauses
              e.importedDoc, // importedDoc
              [], // exhibits 
              activate, // activate
              e.smartFieldObject // smartFieldObject
            ))
          })
        }

        // Create an empty Agreement version
        axios.post('/agr/agrv', {
          agrID: resag.data.data._id,
          version: newAVV,
          owner: secondaryCptyID === curAVOwner ? "secondary" : "primary",
          clauses: [], // empty for now
          creationDate: creationDate,
          editMode: 'full'
        })
        .then(function (resav) { // got a msg from the server
          if(resav.data.success) { // successfully created the agrVersion
            if(isAttachment) { // Create the agreement from an attachment

              if(sourceFileID.file === undefined || sourceFileID.file === null){
                // Subscenario 1: Attachment is not yet attached - assign a placeholder value
                axios.put('/agr/agrvattach/' + resav.data.data._id, { attachment: 'placeholder' })
                .catch(function(err) { dispatch(fetchAgrsError(err)); })

              } else {
                // Subscenario 2: Attachment is already attached
                const formData = new FormData(); // upload attachment  
                formData.append('file', sourceFileID.file, generateRandomString(20)); // appending file
                axios.post('/upload/protect/pdfattach', formData, {}) // Upload PDF and get url
                .then(resupload => {

                  axios.put('/agr/agrvattach/' + resav.data.data._id, { attachment: resupload.data.docURL })
                  .catch(function(err) { dispatch(fetchAgrsError(err)); })

                }).catch(function(err) { dispatch(fetchAgrsError(err)); })
              }

            } else if(importedDoc === null) { // Create the agreement from a company template (OAT)
              axios.post('/clause/clauses', { clauses: templateClauses }) // Fetch all template clauses
              .then(function(resclauses) {
                let bulkWrites = [], bulkWritesSF = []

                resclauses.data.data
                .sort((a,b) => templateClauses.indexOf(a._id) - templateClauses.indexOf(b._id))
                .forEach((cl) => {
                  let sclid = generateRandomString(20);
                  let clblocks = ['sec','ssec','cl', 'cl1', 'cl2', 'cl3', 'cl4', 'preamble'].includes(cl.clauseCat) ? cl.blocks : {};
                  let clplain = ['sec','ssec','cl', 'cl1', 'cl2', 'cl3', 'cl4', 'preamble'].includes(cl.clauseCat) ? cl.plain : [];
                  let clobject = ['table'].includes(cl.clauseCat) ? cl.clauseObject : {};

                  // Copy over any SmartFields if applicable
                  if(['sec','ssec','cl', 'cl1', 'cl2', 'cl3', 'cl4', 'preamble', 'table'].includes(cl.clauseCat) && smartFieldObject !== undefined && smartFieldObject !== null &&
                  smartFieldObject.sfs !== undefined && smartFieldObject.sfs.length > 0 && smartFieldObject.sfs.filter((sf) => sf.singleClauseID === cl.singleClauseID)[0] !== undefined) {
                    // Part 1. Are there any smartFields for this singleClauseID - if so, iterate
                    smartFieldObject.sfs.filter((sf) => sf.singleClauseID === cl.singleClauseID).forEach((oldsf) => {
                      bulkWritesSF.push( // push new smartfield with updated AID, SCLID and values dependent on whether they were changed (sfPush)
                        { insertOne :
                          {
                            "document" :
                            {
                              "osfid": oldsf.osfid,
                              "agrid": resag.data.data._id,
                              "oatid": '',
                              "singleClauseID": sclid,
                              "ref": oldsf.ref,
                              "name": oldsf.name,
                              "type": oldsf.type,
                              "val1": oldsf.val1,
                              "val2": oldsf.val2,
                              "val3": oldsf.val3,
                              "createWizard": oldsf.createWizard,
                              "creationBy": creationBy,
                              "creationDate": creationDate,
                              "lastUpdateBy": creationBy,
                              "lastUpdateDate": creationDate,
                            }
                          }
                        })
                    })
                    // Part 2. Are there any smartfields that require a change to the clause content (type 'auto' OR sfs changed by user)
                    if(smartFieldObject.sfPush.filter((sfp) => sfp.singleClauseID === cl.singleClauseID).length > 0) {
                      if(['sec','ssec','cl', 'cl1', 'cl2', 'cl3', 'cl4', 'preamble'].includes(cl.clauseCat)) { // For clause/preamble ensure to update blocks
                        let newBlocks = []

                        clblocks.blocks.forEach((block) => {
                          if(block.inlineStyleRanges.length > 0) {
                            let newText = block.text;
                            let newISRs = []
                            let offsetAdjustment = 0;
                            block.inlineStyleRanges
                            .sort((a,b) => (a.offset > b.offset) ? 1 : ((b.offset > a.offset) ? -1 : 0)) // Ensure to sort by offset
                            .forEach((isr) => {
                              if(smartFieldObject.sfPush.filter((sfp) => sfp.singleClauseID === cl.singleClauseID && sfp.ref === isr.style)[0] !== undefined) {
                                // there is an actual change required here
                                let sfp = smartFieldObject.sfPush.filter((sfp) => sfp.singleClauseID === cl.singleClauseID && sfp.ref === isr.style)[0]
                                newText = newText.substr(0, isr.offset + offsetAdjustment) + sfp.newV + newText.substr(isr.offset + offsetAdjustment + isr.length); // remove old and insert the new Value
                                newISRs.push({
                                  length: sfp.newV.length,
                                  offset: isr.offset + offsetAdjustment,
                                  style: isr.style,
                                  _id: isr._id
                                })
                                offsetAdjustment = offsetAdjustment + sfp.newV.length - isr.length; // Need to adjust the offset for future ISR generations
                              }
                            })
                            newBlocks.push({
                              _id: block._id,
                              type: block.type,
                              key: block.key,
                              text: newText,
                              inlineStyleRanges: newISRs,
                              entityRanges: block.entityRanges,
                            })
                          } else {
                            newBlocks.push(block)
                          }
                        })
                        clblocks.blocks = newBlocks
                        clplain = convertBlocksToPlain(clblocks)
                        
                      } else if(['table'].includes(cl.clauseCat)) { // for a table, ensure to update the clauseObject
                        let newHead = [], newRows = []
                        cl.clauseObject.head.forEach((h) => { newHead.push({colKey: h.colKey, value: h.value}) })
                        cl.clauseObject.rows.forEach((r) => {
                          let cols = []
                          r.cols.forEach((c) => { 
                            let newVal = smartFieldObject.sfPush.filter((sfp) => sfp.singleClauseID === cl.singleClauseID && sfp.ref === c.sf)[0] !== undefined ?
                              smartFieldObject.sfPush.filter((sfp) => sfp.singleClauseID === cl.singleClauseID && sfp.ref === c.sf)[0].newV : c.value
                            cols.push({ cellKey: c.cellKey, value: newVal, sf: c.sf !== undefined && c.sf !== null ? c.sf : null }) 
                          })
                          newRows.push({rowKey: r.rowKey, cols: cols});
                        })
                        clobject = { head: newHead, rows: newRows, invisibleHead: cl.clauseObject.invisibleHead }
                      }
                    }
                  }

                  bulkWrites.push(
                    { insertOne :
                      {
                        "document" :
                        {
                            "singleClauseID": sclid, 
                            "clauseCat": cl.clauseCat,
                            "lockedBy": '', // newly created PSID
                            "lockedByOrg": cl.lockedByOrg !== undefined && cl.lockedByOrg !== null && cl.lockedByOrg.type !== undefined ? cl.lockedByOrg : {type: 'none'},
                            "clauseTypes": cl.clauseTypes, 
                            "title": cl.title, 
                            "blocks": clblocks, 
                            "plain": clplain, 
                            "clauseObject": ['table'].includes(cl.clauseCat) ? clobject : {}, // clauseObject
                            "version": 1, // version  
                            "clauseStatus": 'Accepted', // clauseStatus
                            "commentStatus": 'None', // commentStatus
                            "approvalStatus": 'None', // approvalStatus
                            "internalWorkflow": [], // internalWorkflow
                            "numberType": cl.clauseCat === 'preamble' ? 'None' : cl.numberType !== undefined ? cl.numberType : 'Auto',
                            "numberManual": cl.numberManual !== undefined ? cl.numberManual : '',
                            "creationBy": creationBy,
                            "creationDate": creationDate,
                        }
                      }
                    })
                })

                axios.post('/clause/protect/clauses', { bulkWrites: bulkWrites })
                .then((res) => {
                  if(bulkWritesSF.length > 0) { axios.post('/sf/smartfields', { bulkWrites: bulkWritesSF }) }
                  
                  if(res.data.success) {
                    let avcs = []
                    res.data.data.insertedIds.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0)).forEach((id) => { avcs.push(id._id) })
                    dispatch(updateAgrvNewClauses(resav.data.data._id, avcs));
                  } else { dispatch(fetchAgrsError("Unable to update the AV")); }
  
                }).catch(function(err) { dispatch(fetchAgrsError(err)); })
              }).catch(function(err) { dispatch(fetchAgrsError(err)); })

            } else { // OTHERWISE (importedDoc is not null) => Create from ImportedDoc

              dispatch(importDocxForAV(importedDoc, resav.data.data._id, null, creationBy))
              
            }

          } else { dispatch(fetchAgrsError(resav.message)) } // something went wrong while adding
        })
      } else { dispatch(fetchAgrsError(resag.message)) } // something went wrong while adding
    })
    .catch(function (err) { dispatch(fetchAgrsError(err)) }) // err in the server request
  }
};

export const importDocxForAV = (importedDoc, avid, oatid, creationBy) => {

  return dispatch => {

    let creationDate = new Date().toISOString();

    let bulkWrites = []
    importedDoc.data.clauses
    .forEach((c) => {

      let tempString = c.clausepar;
      if(c.title !== undefined && c.title !== null && c.title !== '') {
        let plainTitle = 
          (['sec'].includes(c.cat) ? '<secTitle>' : ['ssec'].includes(c.cat) ? '<ssecTitle>' : '<title>') + 
          c.title + 
          (['sec'].includes(c.cat) ? '</secTitle>' : ['ssec'].includes(c.cat) ? '</ssecTitle>' : '</title>');
        //tempString  = plainTitle + (['sec','ssec'].includes(c.cat) ? '' : ('\n' + tempString));
        tempString  = plainTitle + (tempString !== '' ? ('\n' + tempString) : '');
      }

      let newBlocks = ['sec', 'ssec', 'cl', 'cl1', 'cl2', 'cl3', 'cl4', 'preamble'].includes(c.cat) ? convertPlainToBlocks(tempString) : {};
      let newPlain = ['sec', 'ssec', 'cl', 'cl1', 'cl2', 'cl3', 'cl4', 'preamble'].includes(c.cat) ? tempString.split("\n") : []
      let newClauseObject = ['table'].includes(c.cat) ? c.clauseObject : {}

      if(['sec', 'ssec', 'cl', 'cl1', 'cl2', 'cl3', 'cl4', 'preamble', 'table'].includes(c.cat)) {

        bulkWrites.push({ insertOne :
          {
            "document" :
            {
                "singleClauseID": generateRandomString(20), 
                "clauseCat": c.cat,
                "lockedBy": '',
                "lockedByOrg": c.lockedByOrg !== undefined && c.lockedByOrg !== null && c.lockedByOrg.type !== undefined ? c.lockedByOrg : {type: 'none'},
                "clauseTypes": c.clauseTypes !==  undefined && c.clauseTypes !==  null ? c.clauseTypes : [], 
                "title": null, 
                "blocks": newBlocks,
                "plain": newPlain, 
                "clauseObject": newClauseObject, // clauseObject
                "version": 1, // version  
                "clauseStatus": 'Accepted', // clauseStatus
                "commentStatus": 'None', // commentStatus
                "approvalStatus": 'None', // approvalStatus
                "internalWorkflow": [], // internalWorkflow
                "numberType": (c.cat === 'preamble' || (importedDoc.data.numberType !== undefined && importedDoc.data.numberType === 'Manual' && c.numberManual === '')) ? 'None' : importedDoc.data.numberType !== undefined ? importedDoc.data.numberType : 'Auto',
                "numberManual": importedDoc.data.numberType !== undefined && importedDoc.data.numberType === 'Manual' && c.numberManual !== undefined ?
                  c.numberManual : '',
                "creationBy": creationBy,
                "creationDate": creationDate,
            }
          }
        })
      }
    })

    axios.post('/clause/protect/clauses', {
      bulkWrites: bulkWrites, 
    })
    .then((res) => {

      if(res.data.success) {
        let avcs = []
        res.data.data.insertedIds.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0)).forEach((id) => {
          avcs.push(id._id)
        })

        if(avid !== null && oatid === null) {
          dispatch(updateAgrvNewClauses(avid, avcs))
        } else if (avid === null && oatid !== null) {
          dispatch(updateOATNewClauses(oatid, avcs))
        }

      } else { dispatch(fetchAgrsError("Unable to update the AV")); }

    })
    .catch(function(err) { dispatch(fetchAgrsError(err)); })

  }
}

// ***** AGREEMENT ACTIONS *****

export const fetchAgrPending = () => ({
  type: ActionTypes.FETCH_AGR_PENDING
});

export const fetchAgrError = (error) => ({
  type: ActionTypes.FETCH_AGR_ERROR,
  payload: error
});

// ***** AGREEMENT TYPE ACTIONS *****

export function fetchAgrTypes(orgid) {
  return dispatch => {
    dispatch(fetchAgrTypesPending());
    axios.get('/agrtype/agrtypes/' + orgid)
    .then(function(res) { dispatch(fetchAgrTypesSuccess(res.data.data)); })
    .catch(function(err) { dispatch(fetchAgrTypesAgrsError(err)); })
  }
}

export const createAgrType = (relationshipType, shortName, fullName, agrPartType, orgID) => {
  return dispatch => {    
    axios.post('/agrtype/protect/agrtype', {
      relationshipType: relationshipType,
      shortName: shortName,
      fullName: fullName,
      agrPartType: agrPartType,
      orgID: orgID,
    })
    .then(function (res) { // got a msg from the server
      if(res.data.success) { // successfully added
        dispatch(addAgrType(res.data.data))
        
      } else { // something went wrong while adding
        dispatch(fetchAgrTypesAgrsError(res.message))
      }
    })
    .catch(function (err) { // err in the server request
      dispatch(fetchAgrTypesAgrsError(err))
    })       
  }
};

export const updateAgrType = (atid, relationshipType, shortName, fullName, agrPartType) => {
  return dispatch => {    
    axios.put('/agrtype/protect/agrtypeupdate/' + atid, {
      relationshipType: relationshipType,
      shortName: shortName,
      fullName: fullName,
      agrPartType: agrPartType,
    })
    .then(function (res) { // got a msg from the server
      if(res.data.success) { // successfully added
        dispatch(updAgrType(res.data.data))
        
      } else { // something went wrong while adding
        dispatch(fetchAgrTypesAgrsError(res.message))
      }
    })
    .catch(function (err) { // err in the server request
      dispatch(fetchAgrTypesAgrsError(err))
    })       
  }
};
    
export const deleteAgrType = (atid) => {

  return dispatch => {
    axios.delete('/agrtype/protect/agrtype/' + atid)
    .then(function(res) {
      dispatch(removeAgrType(atid));
    })
    .catch(function(err) {
      dispatch(fetchAgrTypesAgrsError(err))
    })
  }
}

export const removeAgrType = (atid) => ({
  type: ActionTypes.REMOVE_AGRTYPE,
  payload: atid
});

export const fetchAgrTypesPending = () => ({
  type: ActionTypes.FETCH_AGRTYPES_PENDING
});

export const fetchAgrTypesAgrsError = (error) => ({
  type: ActionTypes.FETCH_AGRTYPES_ERROR,
  payload: error
});

export const fetchAgrTypesSuccess = (agrTypes) => ({
  type: ActionTypes.FETCH_AGRTYPES_SUCCESS,
  payload: agrTypes
});

export const updAgrType = (at) => ({
  type: ActionTypes.UPDATE_AGRTYPE,
  payload: at
});

export const addAgrType = (at) => ({
  type: ActionTypes.ADD_AGRTYPE,
  payload: at
});

// ***** AGREEMENT VERSIONS ACTIONS *****

export const fetchAgrVersionsError = (error) => ({
  type: ActionTypes.FETCH_AGRVERSIONS_ERROR,
  payload: error
});

export const createAgrVersion = (aid, version, owner, clauses, attachment, primaryReady, secondaryReady, duplicateClauses, freshAg, editMode) => {
  return dispatch => {
    let creationDate = new Date().toISOString();
    axios.post('/agr/agrvwcls/' + (duplicateClauses ? "duplicate" : "keep") + "/" + (freshAg ? "fresh" : "aged"), {
      agrID: aid,
      version: version,
      owner: owner,
      clauses: clauses,
      attachment: attachment,
      primaryReady: primaryReady,
      secondaryReady: secondaryReady,
      creationDate: creationDate,
      editMode: editMode,
    })
  }
};

export const createAgrVersionWithImport = (aid, avv, owner, primaryReady, secondaryReady, isEdited, editMode, iDoc, creationBy) => {
  return dispatch => {

    axios.post('/agr/agrv', {
      agrID: aid,
      version: avv,
      owner: owner,
      clauses: [],
      primaryReady: primaryReady,
      secondaryReady: secondaryReady,
      openOnPenTurn: 0,
      isEdited: isEdited,
      editMode: editMode,
    })
    .then((res) => {
      dispatch(importDocxForAV(iDoc, res.data.data._id, null, creationBy))      
    })
    .catch((err) => {
      dispatch(fetchAgrVersionsError(err))
    })
  }
}

export const updateAgrvAddClause = (avid, clid, returnAV) => {
  return dispatch => {
    axios.put('/agr/agrv/' + avid, {
      newcl: clid,
    })
    .then(function (res) { // success
      // succesful update - you will get the updated agrV from: res.data.data
    })
    .catch(function (err) { // err
      dispatch(fetchAgrVersionsError(err))
    })       
  }
};

export const updateAgrvRemoveClause = (avid, clid, returnAV) => {
  return dispatch => {
    axios.put('/agr/agrvdel/' + avid, {
      delcl: clid,
    })
    .then(function (res) { // success
      // succesful update - you will get the updated agrV from: res.data.data
    })
    .catch(function (err) { // err
      dispatch(fetchAgrVersionsError(err))
    })       
  }
};

export const updateAgrvNewClauses = (avid, newClauses, history, path) => {
  return dispatch => {
    axios.put('/agr/agrvclauses/' + avid, {
      clauses: newClauses,
    })
    .then(function (res) { // success
      // succesful update - you will get the updated agrV from: res.data.data
      if(history !== undefined && history !== null && path !== undefined && path !== null) { history.push(path) }
    })
    .catch(function (err) { // err
      dispatch(fetchAgrVersionsError(err))
    })       
  }
};

export const updateAgrvMarkAsEdited = (avid, isEdited, primaryReady, secondaryReady) => {
  return dispatch => {
    axios.put('/agr/agrvedited/' + avid, {
      isEdited: isEdited,
      primaryReady: primaryReady, 
      secondaryReady: secondaryReady
    })
    .then(function (res) { // success
      // succesful update - you will get the updated agrV from: res.data.data
    })
    .catch(function (err) { // err
      dispatch(fetchAgrVersionsError(err))
    })
  }
};

// ***** AGREEMENT EXEC ACTIONS *****

export const fetchAgrExecPending = () => ({
  type: ActionTypes.FETCH_AGREXEC_PENDING
});

export const fetchAgrExecError = (error) => ({
  type: ActionTypes.FETCH_AGREXEC_ERROR,
  payload: error
});

export const fetchAgrExecSuccess = (agrExec) => ({
  type: ActionTypes.FETCH_AGREXEC_SUCCESS,
  payload: agrExec
});

export const createAgrExec = (relID, agrID, agrTypeID, agrVersion, primaryCpty, secondaryCpty, content, signatories, 
  signatoryRequestID, pdfUnsignedURL, pdfSignedURL, signMethod) => {
  return dispatch => {
    dispatch(fetchAgrExecPending())
    let creationDate = new Date().toISOString();
    axios.post('/agr/agrexecnew', {
      relID: relID,
      agrID: agrID,
      agrTypeID: agrTypeID,
      agrVersion: agrVersion,
      primaryCpty: primaryCpty, // object
      secondaryCpty: secondaryCpty, // object
      content: content, // object
      creationDate: creationDate,
      signatories: signatories, // array of objects
      signatoryRequestID: signatoryRequestID,
      pdfUnsignedURL: pdfUnsignedURL, 
      pdfSignedURL: pdfSignedURL,
      signMethod: signMethod,
    })
    .then(function (res) { // got a msg from the server
      if(res.data.success) { // successfully added
        dispatch(fetchAgrExecSuccess(res.data.data))
      } else { // something went wrong while adding
        dispatch(fetchAgrExecError(res.message));
      }
    })
    .catch(function (err) { // err in the server request
      dispatch(fetchAgrExecError(err))
    })
  }
};