import React from 'react';
import { Container, Grid,
         makeStyles } from '@material-ui/core';
import { DialogCertificate, DrawerCore, Header, ThumbCert } from '../components'

function Certs(props) {

  const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    outerFlexGrid: {
        minHeight: '80vh',
        paddingTop: '100px',
        paddingBottom: '60px',
    },
    gridBox: {
      padding: '10px',
    },
  }));
  const classes = useStyles();

  const [certOpen, setCertOpen] = React.useState(false);
  const [activeCert, setActiveCert] = React.useState({})

  const handleViewClick = (e, certid) => {
    setActiveCert(props.orgProfileCerts.filter((c) => c._id === certid)[0]);
    setCertOpen(true)
  }

  const handleCloseDialog = () => {
    setCertOpen(false);
    setActiveCert({});
  }

  return(
    <div className={classes.root}>
        <Header 
          module="network"
          user={props.user}
          org={props.org}
          modules={
            props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
              ['sales','procurement','partnerships','people','corporate'] : 
            props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
              props.org.modules : [] }
          orgProfile={props.orgProfile}
          searchOptions={props.network}
          crumbs={[
            {name: "Home", to: "/network" },
            {name: (props.orgProfile.shortName !== undefined)? props.orgProfile.shortName : "", to: "/network/" + props.orgurl},
            {name: "Standards & Certifications", to: "active"} 
          ]}
          notifications={props.notifications}
          markNotificationAsRead={props.markNotificationAsRead}
          markAllNotificationsAsRead={props.markAllNotificationsAsRead}
        />

        <DrawerCore
          agreements={[]} // props.orgProfileAgrTemplates 
          page="certifications" 
          showCerts={props.orgProfileCerts.length > 0}
          rid=""
          orgurl={props.orgurl}
          customurl={props.customurl}
          sections={[]} 
          pageType='profile' 
          userLoggedIn={props.user !== undefined && props.user.email !== undefined}
        />

        <Container maxWidth="lg">
        <Grid container direction="column" justify="flex-start" alignItems="center" className={classes.outerFlexGrid}>
        
          <Grid container direction="row">
            {props.orgProfileCerts.map((cert) => (
            <Grid item xs={12} sm={6} md={4} className={classes.gridBox} key={cert._id}>
              <ThumbCert
                cert={cert}
                type="view"
                click={handleViewClick}
              />
            </Grid>
            ))}
          </Grid>

        </Grid>
        </Container>

        <DialogCertificate
          open={certOpen}
          parentCallToClose={handleCloseDialog}
          cert={activeCert}
        />
        
    </div>
  )
}
  
export default Certs