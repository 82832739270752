import React, { useEffect } from 'react';
import theme from '../../theme/theme';
import PropTypes from 'prop-types';
import { Link as RouterLink, Redirect, useHistory } from 'react-router-dom';
import { Avatar, Box, Button, CircularProgress, Container, Grid,  
         List, ListItem, ListItemText, Tabs, Tab, Typography,
         makeStyles } from '@material-ui/core';
import { Header, ErrorMsg, UploadImg, ThumbTodo } from '../../components';
import { EntityForm } from '../../components/forms';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faHandshake, faPlus } from "@fortawesome/free-solid-svg-icons";

const axios = require('axios');

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Masterdata(props) {

  const useStyles = makeStyles(theme => ({
    outerFlexGrid: {
      minHeight: '80vh',
      paddingTop: '100px',
      paddingBottom: '100px',
    },
    error: {
      color: theme.palette.error.main
    },
    errorButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.primary.contrastText
    },
    successButton: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.primary.contrastText
    },
    mtAndWide: {
      marginTop: '30px',
      width: '250px'
    },
    container: {
      [theme.breakpoints.down('sm')]: { paddingLeft: '20px', paddingRight: '20px' },
      [theme.breakpoints.only('md')]: { paddingLeft: '40px', paddingRight: '40px' },
      [theme.breakpoints.up('lg')]: { paddingLeft: '60px', paddingRight: '60px' },
    },
    boxResizing: {
      [theme.breakpoints.only('xs')]: { width: '280px' },
      [theme.breakpoints.only('sm')]: { width: '340px' },
      [theme.breakpoints.up('md')]: { width: '400px' },
    }
  }));
  const classes = useStyles();

  const history = useHistory()

  const activeCust = (props.eid !== undefined) ? props.cpents.filter(c => c._id === props.eid)[0] : {}
  const activeRel = activeCust !== undefined && activeCust !== null && activeCust.parentOrgID !== undefined ? props.rels.filter((r) => 
    (props.module === 'sales' && r.relationshipType === 'b2b' && r.primaryOrgID === props.org._id && r.secondaryOrgID === activeCust.parentOrgID) ||
    (props.module === 'procurement' && r.relationshipType === 'b2b' && r.primaryOrgID === activeCust.parentOrgID && r.secondaryOrgID === props.org._id) ||
    (props.module === 'partnerships' && r.relationshipType === 'b2b' && ((r.primaryOrgID === props.org._id && r.secondaryOrgID === activeCust.parentOrgID) || (r.primaryOrgID === activeCust.parentOrgID && r.secondaryOrgID === props.org._id))) ||
    (props.module === 'people' && r.relationshipType === 'people' && r.primaryOrgID === props.org._id && r.secondaryOrgID === activeCust.parentOrgID) ||
    (props.module === 'corporate' && r.relationshipType === 'corporate' && r.primaryOrgID === props.org._id && r.secondaryOrgID === activeCust.parentOrgID))[0] : null
  
  const [activePerson, setActivePerson] = React.useState({})
  const [tabValue, setTabValue] = React.useState(0);
  const entityType = (
    props.module === 'sales' ? "Customer" : 
    props.module === 'procurement' ? "Supplier" : 
    props.module === 'partnerships' ? "Partner" : 
    props.module === 'corporate' ? "Corpcp" : 
    props.module === 'people'? "Employee" : "Unknown")

  useEffect(() => {

    if(activeCust !== undefined && activeCust !== null && activeCust.entityCategory === 'Person' && !['Subsidiary','LegalClient'].includes(activeCust.entityType)) {
      // Load person detail
      axios.get('/org/org/' + activeCust.parentOrgID) // Need to pull org by ID to get the matching email of the person
      .then(function(resorg) {
        axios.get('/user/protect/user/mail/' + resorg.data.data.email) // Get matching user
        .then(function(resusr) {
          setActivePerson(resusr.data.data)
        })
        .catch(function(err) { console.log("err fetching user")})
      })
      .catch(function(err) { console.log("err fetching org")})
    }
  }, [activeCust])

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const callbackEntityUpdateForm = (child) => {
    let displayName = activeCust.entityCategory === 'Person' ? child.firstName.trim() + ' ' + child.lastName.trim() : ''

    props.updateEntity(
      props.eid, // entid, 
      props.org._id, // orgid, 
      activeCust.parentOrgID, // parentOrgID, 
      activeCust.entityType, // entityType, 
      activeCust.entityCategory === 'Person' ? displayName : child.shortName.trim(), // shortName, 
      activeCust.entityCategory === 'Person' ? displayName : child.legalName.trim(), // legalName, 
      [{
        addressType: 'billto', 
        streetAndNo: child.streetAndNo !== undefined && child.streetAndNo !== null ? child.streetAndNo.trim() : '',
        address2: child.address2 !== undefined && child.address2 !== null ? child.address2.trim() : '',
        zip: child.zip !== undefined && child.zip !== null ? child.zip.trim() : '',
        city: child.city !== undefined && child.city !== null ? child.city.trim() : '',  
        state: child.state !== undefined && child.state !== null ? child.state.trim() : '', 
        country: child.country
      }], // address, 
      activeCust.logoURL, // logoURL, 
      activeCust.primaryContact, // primaryContact, 
      activeCust.signatory, // empty signatory 
      true, // isLive, 
      props.user._id, // lastUpdateBy
    )
    if(activeCust.entityCategory === 'Person') { // Update the user detail - ie. title
      props.updateUser(activePerson._id, child.firstName, child.lastName, child.title, activePerson.phone, activePerson.photoURL, 'counterparty', activePerson.agrIDs);
    }
  }

  const callbackLogoUpdateForm = (child) => {
    // Update the logo for the entity

    props.updateEntity(
      props.eid, // entid, 
      props.org._id, // orgid, 
      activeCust.parentOrgID, // parentOrgID, 
      entityType, // entityType, 
      activeCust.shortName, // shortName, 
      activeCust.legalName, // legalName, 
      activeCust.address, // address, 
      child, // logoURL, 
      activeCust.primaryContact, // primary Contact
      activeCust.signatory, // empty signatory  
      true, // isLive, 
      props.user._id, // lastUpdateBy
    )

    // Update the logo for any subsidiaries    
    props.cpents.filter((ent) => ent.parentOrgID === activeCust.parentOrgID && ent._id !== activeCust._id).forEach((e) => {
      props.updateEntity(
        e._id, // entid, 
        e.orgID, // orgid, 
        e.parentOrgID, // parentOrgID, 
        e.entityType, // entityType, 
        e.shortName, // shortName, 
        e.legalName, // legalName, 
        e.address, // address, 
        child, // logoURL, 
        e.primaryContact, // primary Contact
        e.signatory, // empty signatory  
        e.isLive, // isLive, 
        props.user._id, // lastUpdateBy
      )
    })

    if(activeCust.entityCategory === 'Person') { // Update the user detail - ie. photoURL
      props.updateUser(activePerson._id, activePerson.firstName, activePerson.lastName, activePerson.title, activePerson.phone, child, 'counterparty', activePerson.agrIDs);
    }

    // Update the logo for the parentOrgID
    props.updateLogoForOrg(activeCust.parentOrgID, child);
  }

  const confirmDeactivate = () => {

    props.updateEntity(
      activeCust._id, // entid, 
      activeCust.orgID, // orgid, 
      activeCust.parentOrgID, // parentOrgID, 
      activeCust.entityType, // entityType, 
      activeCust.shortName, // shortName, 
      activeCust.legalName, // legalName, 
      activeCust.address, // address, 
      activeCust.logoURL, // logoURL, 
      activeCust.primaryContact, // primary Contact
      activeCust.signatory, // empty signatory  
      !activeCust.isLive, // isLive, 
      props.user._id, // lastUpdateBy
    )
    history.push('/core/' + props.module + '/relationships');
  }

  switch(props.action) {

    case 'deactivate' :
      if(activeCust === undefined) {
        return (<Redirect to={"/core/" + props.module + "/relationships"} push={true} />)
      } else {

        return(
          <div>
            <Header module={props.module}
            active="Relationships"
            crumbs={[
              {name: "Relationships", to: "/core/" + props.module + "/relationships"}, 
              {name: ((activeCust.isLive ? "Deactivate" : "Reactivate") + " " + (entityType === 'Corpcp' ? 'Counterparty' : entityType)), to: "active"}
            ]}
            user={props.user}
            org={props.org}
            modules={
              props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
                ['sales','procurement','partnerships','people','corporate'] : 
              props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
                props.org.modules : [] }
            notifications={props.notifications}
            markNotificationAsRead={props.markNotificationAsRead}
            markAllNotificationsAsRead={props.markAllNotificationsAsRead} />

            <Container maxWidth="lg" className={classes.container}>
              <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
                <Grid item container alignItems="center" justify="center">
                  <Box mb={5} mt={3}>
                    <FontAwesomeIcon icon={faExclamationTriangle} size="5x" color={activeCust.isLive ? theme.palette.error.main : theme.palette.success.main} />
                  </Box>
                </Grid>
                <Grid item container alignItems="center" justify="center">
                  <Typography align="center" variant="body1">
                    Are you sure you want to {activeCust.isLive ? "deactivate" : "reactivate"} the following {entityType === 'Corpcp' ? 'counterparty' : entityType.toLowerCase()} ?
                  </Typography>              
                </Grid>
                <Grid item container direction="column" alignItems="center" justify="center">

                  <Box mt={5}>
                    <Avatar alt="logo" src={activeCust.logoURL} height="80" width="80" />
                  </Box>
                  <Box mt={3} mb={6}>
                    <Typography align="center" variant="h5">
                      {activeCust.shortName}
                    </Typography>
                    <Typography align="center" color="textSecondary" variant="body1">
                      {activeCust.legalName}
                    </Typography>
                  </Box>

                </Grid>
                <Grid item container alignItems="center" justify="center">
                  <Box mb={5}>
                    <Button variant="contained" disableElevation
                      className={activeCust.isLive ? classes.errorButton : classes.successButton} onClick={confirmDeactivate}>
                      Confirm
                    </Button>
                    &nbsp;&nbsp;
                    <Button variant="outlined" className={classes.error}
                      component={RouterLink} to={"/core/" + props.module + "/relationships"}>
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </Grid>    
            </Container>
          </div>
        )
      }

    case 'edit' :
      if(activeCust === undefined) {
        return (<Redirect to={"/core/" + props.module + "/relationships"} push={true} />)
      } else {

        return (
          <div>
            <Header module={props.module}
            active="Relationships"
            crumbs={[
              {name: "Relationships", to: "/core/" + props.module + "/relationships"}, 
              {name: "Edit " + (entityType === 'Corpcp' ? 'Counterparty' : entityType), to: "active"} ]}
            user={props.user}
            org={props.org}
            modules={
              props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
                ['sales','procurement','partnerships','people','corporate'] : 
              props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
                props.org.modules : [] }
            notifications={props.notifications}
            markNotificationAsRead={props.markNotificationAsRead}
            markAllNotificationsAsRead={props.markAllNotificationsAsRead} />

            <Container maxWidth="lg" className={classes.container}>
              <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.outerFlexGrid}>
                {activeCust !== undefined && activeCust.parentOrgID !== undefined ?
                <>
                <Grid item>
                  <Box mb={3} mt={2}>
                    <Typography align="center" variant="h5">
                        {activeCust.legalName}
                    </Typography>
                  </Box>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                  <Grid item>
                    <ThumbTodo 
                      line1="Create Agreement" 
                      line2="Create a new Agreement" 
                      color="secondary"
                      route={"/core/" + props.module + "/new/" + activeCust.parentOrgID}
                      icon={faPlus} 
                      adminNeeded={false}
                      done={false} />
                  </Grid>
                  {activeRel !== undefined && activeRel !== null && activeRel._id !== undefined ?
                  <Grid item>
                    <ThumbTodo 
                      line1="View Relationship"
                      line2="Go to Relationship Page" 
                      color="primary"
                      route={"/core/" + props.module + "/relationship/" + activeRel._id + "/overview"}
                      icon={faHandshake} 
                      adminNeeded={false}
                      done={false} />
                  </Grid> : ''}
                </Grid>
                </>
                :''}
                <Grid item>
                  <Box mb={3} mt={4}>
                    <Typography align="center" variant="h5">
                        Edit {(entityType === 'Corpcp' ? 'Counterparty' : entityType)} record
                    </Typography>
                  </Box>
                </Grid>
                {['Subsidiary','LegalClient'].includes(activeCust.entityType) ?
                <Box align="center" mt={5} mb={5}>
                  <Typography>You do not have permission to edit this record</Typography>
                </Box>
                :
                (
                <>
                <Grid item container alignItems="center" justify="center">

                  <Box mb={3}>
                    <Tabs
                      value={tabValue}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={handleTabChange}
                    >
                      <Tab label="Entity" {...a11yProps(0)} />
                      <Tab label={activeCust.entityCategory === 'Person' ? "Photo" : "Logo"} {...a11yProps(1)} />
                      {activeCust.entityCategory !== 'Person' ? 
                      <Tab label="Affiliates" {...a11yProps(2)} />
                      : '' }
                    </Tabs>
                  </Box>

                </Grid>

                { props.cpentsPending ? 
                  <Grid item container alignItems="center" justify="center">
                    <Box mt={8} mb={10} align="center">
                      <CircularProgress size="80px" />
                    </Box>
                  </Grid>
                  : 
                  (
                    props.cpentsError !== null ? 
                    (
                      <ErrorMsg msg={props.cpentsError.message} />
                    )
                    :activeCust.entityCategory !== 'Person' || (activePerson !== undefined && activePerson !== null && activePerson._id !== undefined) ?
                    (                
                      <Grid item>

                        <TabPanel value={tabValue} index={0}>

                          <EntityForm 
                            parentCallback={callbackEntityUpdateForm}
                            type={activeCust.isLive ? 'updatecp' : 'disabled'}
                            initialValues={{
                              entityCategory: activeCust.entityCategory !== undefined ? activeCust.entityCategory : '',
                              shortName: activeCust.shortName !== undefined ? activeCust.shortName : '', 
                              legalName: activeCust.legalName !== undefined ? activeCust.legalName : '', 
                              streetAndNo: activeCust.address.filter((a) => a.addressType === 'billto')[0] !== undefined ?
                                activeCust.address.filter((a) => a.addressType === 'billto')[0].streetAndNo : '',
                              address2: activeCust.address.filter((a) => a.addressType === 'billto')[0] !== undefined &&
                                activeCust.address.filter((a) => a.addressType === 'billto')[0].address2 !== undefined ?
                                activeCust.address.filter((a) => a.addressType === 'billto')[0].address2 : '',
                              city: activeCust.address.filter((a) => a.addressType === 'billto')[0] !== undefined ?
                                activeCust.address.filter((a) => a.addressType === 'billto')[0].city : '',
                              zip: activeCust.address.filter((a) => a.addressType === 'billto')[0] !== undefined ?
                                activeCust.address.filter((a) => a.addressType === 'billto')[0].zip : '',
                              state: activeCust.address.filter((a) => a.addressType === 'billto')[0] !== undefined ?
                                activeCust.address.filter((a) => a.addressType === 'billto')[0].state : '',
                              country: activeCust.address.filter((a) => a.addressType === 'billto')[0] !== undefined ?
                                activeCust.address.filter((a) => a.addressType === 'billto')[0].country : '',
                              firstName: activePerson !== undefined && activePerson !== null && activePerson.firstName !== undefined ? activePerson.firstName : '',
                              lastName: activePerson !== undefined && activePerson !== null && activePerson.lastName !== undefined ? activePerson.lastName : '',
                              email: activePerson !== undefined && activePerson !== null && activePerson.email !== undefined ? activePerson.email : '',
                              title: activePerson !== undefined && activePerson !== null && activePerson.title !== undefined ? activePerson.title : '',
                            }} />

                          <Box mt={2} align="center">
                            <Button 
                              variant="outlined"
                              component={RouterLink}
                              to={"/core/" + props.module + "/masterdata/deactivate/" + activeCust._id}
                              color="secondary"
                              className={classes.mtAndWide}
                            >{(activeCust.isLive ? "Deactivate" : "Reactivate") + " " + (entityType === 'Corpcp' ? 'Counterparty' : entityType)}</Button>
                          </Box>

                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>

                          <Box mt={2} mb={3} align="center">
                            <Box mb={2}>Note: Logo changes apply to all affiliates</Box>
                            {activeCust.isLive ?               
                            <UploadImg 
                              type={activeCust.entityCategory === 'Person' ? "avatar" : "logo"} 
                              parentCallback={callbackLogoUpdateForm}
                              initialImg={activeCust.logoURL} />
                            :
                            <Typography variant="body2">This {(entityType === 'Corpcp' ? 'Counterparty' : entityType).toLowerCase()} was deactivated</Typography>
                            }
                          </Box>

                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>

                          <Box mt={2} mb={3} align="center" > 
                            <List className={classes.boxResizing}>
                              { props.module === 'sales' && props.cpents.filter((c) => c.entityType === 'Customer' && c.parentOrgID === activeCust.parentOrgID && c._id !== activeCust._id).length > 0 ?
                                (
                                props.cpents
                                .filter((c) => c.entityType === 'Customer' && c.parentOrgID === activeCust.parentOrgID && c._id !== activeCust._id)
                                .map((c) => (
                                <ListItem button component={RouterLink} to={"/core/" + props.module + "/masterdata/edit/" + c._id} key={c._id}>
                                  <ListItemText primary={(!c.isLive ? '( DEACTIVE ) ' : '') + c.shortName} secondary={c.legalName} />
                                </ListItem>
                                )))
                                :
                                props.module === 'sales' ?
                                <Typography align="center">There are no Customer affiliates</Typography>
                                : '' }

                              { props.module === 'procurement' && props.cpents.filter((c) => c.entityType === 'Supplier' && c.parentOrgID === activeCust.parentOrgID && c._id !== activeCust._id).length > 0 ?
                                (
                                props.cpents
                                .filter((c) => c.entityType === 'Supplier' && c.parentOrgID === activeCust.parentOrgID && c._id !== activeCust._id)
                                .map((c) => (
                                <ListItem button component={RouterLink} to={"/core/" + props.module + "/masterdata/edit/" + c._id} key={c._id}>
                                  <ListItemText primary={(!c.isLive ? '( DEACTIVE ) ' : '') + c.shortName} secondary={c.legalName} />
                                </ListItem>
                                )))
                                :
                                props.module === 'procurement' ?
                                <Typography align="center">There are no Supplier affiliates</Typography>
                                : '' }

                              { props.module === 'partnerships' && props.cpents.filter((c) => c.entityType === 'Partner' && c.parentOrgID === activeCust.parentOrgID && c._id !== activeCust._id).length > 0 ?
                                (
                                props.cpents
                                .filter((c) => c.entityType === 'Partner' && c.parentOrgID === activeCust.parentOrgID && c._id !== activeCust._id)
                                .map((c) => (
                                <ListItem button component={RouterLink} to={"/core/" + props.module + "/masterdata/edit/" + c._id} key={c._id}>
                                  <ListItemText primary={(!c.isLive ? '( DEACTIVE ) ' : '') + c.shortName} secondary={c.legalName} />
                                </ListItem>
                                )))
                                :
                                props.module === 'partnerships' ?
                                <Typography align="center">There are no Partner affiliates</Typography>
                                : '' }

                              { props.module === 'corporate' && props.cpents.filter((c) => c.entityType === 'Corpcp' && c.parentOrgID === activeCust.parentOrgID && c._id !== activeCust._id).length > 0 ?
                                (
                                props.cpents
                                .filter((c) => c.entityType === 'Corpcp' && c.parentOrgID === activeCust.parentOrgID && c._id !== activeCust._id)
                                .map((c) => (
                                <ListItem button component={RouterLink} to={"/core/" + props.module + "/masterdata/edit/" + c._id} key={c._id}>
                                  <ListItemText primary={(!c.isLive ? '( DEACTIVE ) ' : '') + c.shortName} secondary={c.legalName} />
                                </ListItem>
                                )))
                                :
                                props.module === 'corporate' ?
                                <Typography align="center">There are no Corporate affiliates</Typography>
                                : '' }
                              
                            </List>
                          </Box>

                        </TabPanel>
                      
                      </Grid>       
                    )
                    : ''
                  )
                }
                </>
                )}
              </Grid>

            </Container>

          </div>
        )
      }
    
    default:

      return (<Redirect to={"/core/" + props.module + "/relationships"} push={true} />)

  }
}

export default Masterdata;