import React, { useEffect } from 'react';
import theme from '../theme/theme';
import { randomString as genKey } from '../utils'
import { makeStyles } from '@material-ui/core/styles';
import { InputBase, Table, TableBody, TableCell, TableContainer, TableRow, 
         Tooltip, Paper, IconButton } from '@material-ui/core';
import { RedlineControls, SmartFields } from '.';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeading, faMinusCircle, faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles({
  headInput: {
      fontWeight: '700',
      fontSize: '14px',
      padding: '13px 11px 13px 11px',
      margin: '0px',
  },
  rowInput: {
      fontSize: '14px',
      margin: '0px',
      padding: '13px 11px 13px 11px',
  },
  tablePaper: {
    borderRadius: '3px',
  },
  table: {
    boxShadow: '0px',
  },
  tinyIconSuccess: {
    fontSize: '12px',
    color: theme.palette.success.main,
    padding: '2px',
  },
  tinyIcon: {
    fontSize: '12px',
    color: theme.palette.grey[600],
    padding: '2px',
  },
  tinyIconError: {
    fontSize: '12px',
    color: theme.palette.error.main,
    padding: '2px',
  },
  tinyIconDisabled: {
    fontSize: '12px',
    color: theme.palette.grey[400],
    padding: '2px',
  },
  lastEditColTop: {
    backgroundColor: '#f3f3f3',
  },
  lastEditCol: {
    backgroundColor: '#f3f3f3',
    borderLeft: '1px solid #e0e0e0',
  },
  tableHead: {
    fontWeight: '700',
    fontSize: '14px',
  },
  tableCell: {
    fontSize: '14px',
  },
  noBottomBorder: {
    borderBottom: '0px',
    fontSize: '14px',
  },
  noBottomBorderEditCol: {
    borderBottom: '0px',
    padding: '2px 3px 2px 3px',
    backgroundColor: '#f3f3f3',
    borderLeft: '1px solid #e0e0e0',
  },
  hide: {
    display: 'none',
  },
  redFont: {
    color: theme.palette.error.main,
    textDecoration: 'line-through',
  },
  greenFont: {
    color: theme.palette.success.main,
    borderBottom: '1px dotted ' + theme.palette.success.main,    
  },
  plainFont: {
    color: theme.palette.grey[700],
  },
  primaryAdd: {
    color: theme.palette.primary.main,
    borderBottom: '1px dotted ' + theme.palette.primary.main,
  },
  secondaryAdd: {
    color: theme.palette.secondary.main,
    borderBottom: '1px dotted ' + theme.palette.secondary.main,
  },
  primaryDel: {
    color: theme.palette.primary.main,
    textDecoration: 'line-through',
  },
  secondaryDel: {
    color: theme.palette.secondary.main,
    textDecoration: 'line-through',
  },
  headButtons: {
    padding: '2px 10px 2px 10px',
    backgroundColor: '#f3f3f3',
  }
});

export default function TableEditable(props) {

  const classes = useStyles();

  const getSFOrder = () => {
    let sfOrder = []
    let t = table !== null ? table : props.clause !== undefined && props.clause !== null && props.clause.clauseObject !== undefined ? props.clause.clauseObject : null
    if(t !== undefined && t !== null) {
      t.head.forEach((h) => { sfOrder.push(h.colKey)} )
      t.rows.forEach((r) => { r.cols.forEach((c) => { sfOrder.push(c.cellKey) } ) })
    }
    return sfOrder
  }

  const [table, setTable] = React.useState(props.clause !== undefined && props.clause !== null ? props.clause.clauseObject : null)
  const [activeCell, setActiveCell] = React.useState(null)
  const [redlineControls, setRedlineControls] = React.useState({ type: 'closeRedlines', hint: null })
  const [redlineSelection, setRedlineSelection] = React.useState(null)
  const [isNewlyInsertedBy, setIsNewlyInsertedBy] = React.useState(props.clause !== undefined && props.clause !== null && props.clause.clauseObject !== undefined && props.clause.clauseObject !== null && 
    props.clause.clauseObject.head !== undefined && props.clause.clauseObject.head.filter((h) => h.change !== undefined && h.change !== null && h.change.type === 'insertTable')[0] !== undefined ?
    props.clause.clauseObject.head.filter((h) => h.change !== undefined && h.change !== null && h.change.type === 'insertTable')[0].change.cpty : null)
  const [smartFieldHandling, setSmartFieldHandling] = React.useState({
    enableAdd: false,
    active: null,
    reconcile: null,
    order: getSFOrder(),
  })

  useEffect(() => {
    if(!props.active || props.proposedDel !== null) { // Deactivate when deactivating
      setActiveCell(null)
      setRedlineSelection(null)
    }
  }, [props.active, props.proposedDel])

  useEffect(() => { // Reconcile if a refresh / new table comes from the top (ie. someone else updated it)
    const callbackOptions = props.callbackOptions
    if(props.reconcileNeeded) {
      callbackOptions(props.clause._id, 'reconcileHandled', null)
      setTable(props.clause.clauseObject)
    }

  }, [props.reconcileNeeded, props.callbackOptions, props.clause])

  useEffect(() => {
    if(props.isImport) {
      setTable(props.clause !== undefined && props.clause !== null ? props.clause.clauseObject : null)
    }

  }, [props.clause, props.isImport])

  const updateNewTable = (oldTable, newTable, changeType) => {
    //let oldTable = table
    setTable(newTable)

    if(!props.newTable) { // When updating an existing table - FAF to DB
      props.updateClauseContentFAF( // Update clause in DB (Fire & Forget)
        props.clause._id, // clid 
        props.clause.clauseStatus, // clauseStatus
        props.clause.blocks, // blocks
        props.clause.plain, // plain
        newTable, // clauseObject
        props.clause.version + 1, // version
        props.user._id // lastUpdateBy
      )
      if(!props.templating && !['enableSmartField'].includes(changeType)) { 
        let clChange = { old: oldTable, new: newTable }
        props.handleActivityLog('clause', 'clTableUpdate', props.clause.singleClauseID, '', props.avv, changeType, clChange) // logLevel, logType, singleClauseID, ticketID, keyVal1, keyVal2, change
      }
      props.callbackOptions(null, 'wasEdited', null) // Update the AV as appropriate

    }
    props.handleTableUpdate(props.tableKey, newTable) // Update parent State
  }

  const handleClick = (click) => {

    let firstChange = null, lastChange = null, nextChange = null, prevChange = null, foundCell = false, skipNewSelection = false;

    if(['toggleActivateClause'].includes(click)) {
      props.callbackOptions(props.clause._id, click, null)

    } else if((redlineSelection !== null && redlineSelection.selection === 'entireTable') || 
    ['acceptall', 'rejectall'].includes(click)) { // clause level click

      if(['acceptall', 'accept'].includes(click)) { // accepting table level
        updateBulk('insertTable', 'table', 'accept', null)
        setActiveCell(null)
        setRedlineSelection(null)
        setRedlineControls({ type: 'closeRedlines', hint: null })
        setIsNewlyInsertedBy(null)

      } else if(['reject'].includes(click)) { // rejecting a table (insertion) => proposedDel
        props.handleClauseMoreOptions('delete', props.clause._id)
        props.callbackOptions(null, 'wasEdited', null) // make sure you update the AV to edited
        setActiveCell(null)
        setRedlineSelection(null)
        setRedlineControls({ type: 'closeRedlines', hint: null })

      } else if(['rejectall'].includes(click)) {
        updateBulk('rejectall', 'table', 'reject', null)
        setActiveCell(null)
        setRedlineSelection(null)
        setRedlineControls({ type: 'closeRedlines', hint: null })
        setIsNewlyInsertedBy(null)

      }

    } else if(['right', 'left', 'accept', 'reject'].includes(click)) {

      let cursor = redlineSelection !== null ? redlineSelection.cursor : activeCell

      // Find next and previous change
      let headChanges = table.invisibleHead ? [] : table.head.filter((h) => h.change !== undefined && h.change !== null && h.change.type !== undefined)
      let rowChanges = []

      table.rows.forEach((r) => {
        let thisRowChanges = r.cols.filter((rc) => rc.change !== undefined && rc.change !== null && rc.change.type !== undefined);
        rowChanges = rowChanges.concat(thisRowChanges)
      })

      headChanges.forEach((hc) => {
        lastChange = hc.colKey
        if(firstChange === null) { firstChange = hc.colKey }
        if(hc.colKey === cursor) { // If you find the cell
          foundCell = true; 
          if(['accept', 'reject'].includes(click)) { 
            let colIdx = table.head.findIndex((h) => h.colKey === hc.colKey)
            if(['insertCol', 'deleteCol'].includes(hc.change.type)) {
              updateBulk(hc.change.type, colIdx, click, hc.change.cpty)
              skipNewSelection = true;
            } else {
              updateField('head', colIdx, hc.change.oldVal !== undefined ? hc.change.oldVal : null, hc.value, click) 
              if(headChanges.length === 1 && rowChanges.length === 0) { skipNewSelection = true; }
            }
          }
        }
        else if (!foundCell) { prevChange = hc.colKey; } // Keep updating prevChange until you find the redlineSelection 
        else if (foundCell && nextChange === null) { nextChange = hc.colKey; } // NextChange is the first after you find the Cell

      })

      rowChanges.forEach((rc) => {
        lastChange = rc.cellKey
        if(firstChange === null) { firstChange = rc.cellKey }
        if(rc.cellKey === cursor) { // If you find the cell
          foundCell = true; 
          if(['accept', 'reject'].includes(click)) { 
            let rowIdx = 0
            table.rows.forEach((r, i) => { if(r.cols.some((c) => c.cellKey === rc.cellKey)) { rowIdx = i } })
            let colIdx = table.rows[rowIdx].cols.findIndex((c) => c.cellKey === rc.cellKey)
            if(['insertCol', 'insertRow', 'deleteCol', 'deleteRow'].includes(rc.change.type)) {
              updateBulk(rc.change.type, ['insertCol', 'deleteCol'].includes(rc.change.type) ? colIdx : rowIdx, click, rc.change.cpty)
              skipNewSelection = true;
            } else {
              updateField(rowIdx, colIdx, rc.change.oldVal !== undefined ? rc.change.oldVal : null, rc.value, click) 
              if(headChanges.length === 0 && rowChanges.length === 1) { skipNewSelection = true; }
            }

          }
        }
        else if (!foundCell) { prevChange = rc.cellKey; } // Keep updating prevChange until you find the redlineSelection 
        else if (foundCell && nextChange === null) { nextChange = rc.cellKey; } // NextChange is the first after you find the Cell
      })

    } else if (['createSmartField'].includes(click) || click.startsWith('unlinkSmartField_')) {
      if(['createSmartField'].includes(click)) {
        let rowIdx = -1
        table.rows.forEach((r, i) => { 
          if(r.cols.some((c) => c.cellKey === activeCell || (activeCell === null && c.cellKey === redlineSelection.cursor))) { rowIdx = i }
        })

        let colIdx = table.rows[rowIdx].cols.findIndex((c) => c.cellKey === activeCell || (activeCell === null && c.cellKey === redlineSelection.cursor))
        if(rowIdx > -1 && colIdx !== undefined && colIdx > -1) {
          updateField(rowIdx, colIdx, null, null, 'enableSmartField')
          setSmartFieldHandling({
            enableAdd: false,
            active: { ref: activeCell !== null ? activeCell : redlineSelection.cursor, text: (table.rows[rowIdx] !== undefined && table.rows[rowIdx].cols[colIdx] !== undefined && table.rows[rowIdx].cols[colIdx].value !== undefined ? table.rows[rowIdx].cols[colIdx].value : '') },
            reconcile: null,
            order: getSFOrder(),
          })
        }
      } else if(click.startsWith('unlinkSmartField_') && click.substr(17) !== undefined && click.substr(17).length > 2) {
        let rowIdx = -1, colIdx = -1
        table.rows.forEach((r, ri) => {
          r.cols.forEach((c, ci) => {
            if(c.cellKey === click.substr(17)) {
              rowIdx = ri;
              colIdx = ci;
            }
          })
        })
        if(rowIdx > -1 && colIdx !== undefined && colIdx > -1) {
          updateField(rowIdx, colIdx, null, null, 'disableSmartField')
          setSmartFieldHandling({
            enableAdd: false,
            active: null,
            reconcile: null,
            order: getSFOrder(),
          })
        }
      }

    }

    // Update Active Cell
    if(!skipNewSelection &&
      (prevChange !== null || nextChange !== null || ['left', 'right'].includes(click))) {

      handleCellFocus(
        'redlineClick', // focusType
        null, // row
        ['left'].includes(click) && prevChange === null ? lastChange : // Go to the end
        ['left'].includes(click) && prevChange !== null ? prevChange : // Go to the previous change
        nextChange === null ? firstChange : // Go to the start
        nextChange !== null ? nextChange : // Go to the next Change
        redlineSelection !== null ? redlineSelection.cursor : // cell
        null // sf
      )
    } else if(skipNewSelection) {
      setActiveCell(null)
      setRedlineSelection(null)
      setRedlineControls({ type: 'closeRedlines', hint: null })
    }
  }

  const handleCellFocus = (focusType, row, cell, sf) => {
    if(!props.active) {
      props.focusClause();
    } else {

      if(!props.disabled && !props.plainMode) { // Only focus (activate / show redline controls) if not disabled/plainMode

        let relevantCell = null, rowIdx = -1, colIdx = -1

        if(table.head.filter((h) => h.colKey === cell)[0] !== undefined) { 
          relevantCell = table.head.filter((h) => h.colKey === cell)[0]
          colIdx = table.head.findIndex((h) => h.colKey === cell)
        } else {
          table.rows.forEach((r,i) => {
            if(r.cols.filter((c) => c.cellKey === cell)[0] !== undefined) {
              relevantCell = r.cols.filter((c) => c.cellKey === cell)[0]
              colIdx = r.cols.findIndex((c) => c.cellKey === cell)
              rowIdx = i;
            }
          })
        }

        if(relevantCell !== null && relevantCell.change !== undefined && relevantCell.change !== null && 
          relevantCell.change.type !== undefined && props.proposedDel === null) {
          
          // There is a CHANGE for this cell
          if(['insertTable','change', 'insertRow', 'insertCol', 'deleteRow', 'deleteCol'].includes(relevantCell.change.type) && ['full', 'edit'].includes(props.editMode)) {
            // When a user has inserted a row or col and then clicks into it (to make further edits)
            let clickOwnerScenario = ['mouseClick', 'tabButton'].includes(focusType) && ['insertTable','insertRow', 'insertCol', 'change'].includes(relevantCell.change.type) && relevantCell.change.cpty === props.curCpty

            setRedlineControls({ 
              type:
                clickOwnerScenario ? 'redlineMnml' : 
                ['full'].includes(props.editMode) ? 'redline' :
                (['edit'].includes(props.editMode) && props.curCpty === relevantCell.change.cpty) ? 'redlineLtdReject' :
                (['edit'].includes(props.editMode) && props.curCpty !== relevantCell.change.cpty) ? 'redlineLtdAccept' : 'closeRedlines',
              hint: { 
                aHint: 
                  ['insertTable'].includes(relevantCell.change.type) ? 'Inserts table' : 
                  ['insertRow'].includes(relevantCell.change.type) ? 'Inserts row' :
                  ['insertCol'].includes(relevantCell.change.type) ? 'Inserts column' :
                  ['deleteRow'].includes(relevantCell.change.type) ? 'Deletes row' :
                  ['deleteCol'].includes(relevantCell.change.type) ? 'Deletes column' :
                  'Inserts new value', 
                rHint: 
                  ['insertTable'].includes(relevantCell.change.type) ? 'Strikes table' : 
                  ['insertRow'].includes(relevantCell.change.type) && relevantCell.change.cpty === props.curCpty ? 'Deletes row' :
                  ['insertRow'].includes(relevantCell.change.type) && relevantCell.change.cpty !== props.curCpty ? 'Strikes row' :
                  ['insertCol'].includes(relevantCell.change.type) && relevantCell.change.cpty === props.curCpty ? 'Deletes column' :
                  ['insertCol'].includes(relevantCell.change.type) && relevantCell.change.cpty !== props.curCpty ? 'Strikes column' :
                  ['deleteRow'].includes(relevantCell.change.type) ? 'Inserts row' :
                  ['deleteCol'].includes(relevantCell.change.type) ? 'Inserts column' :
                  'Reverts value'
              }})

            setRedlineSelection(
            clickOwnerScenario ? null : 
            {
              selection:
                ['insertTable'].includes(relevantCell.change.type) ? 'entireTable' :
                ['insertRow', 'deleteRow'].includes(relevantCell.change.type) && rowIdx !== -1 ? ('row_' + rowIdx) :
                ['insertCol', 'deleteCol'].includes(relevantCell.change.type) && colIdx !== -1 ? ('col_' + colIdx) :
                cell,
              cursor: cell
            })

            setActiveCell(clickOwnerScenario ? cell : null)

          }

        } else if(relevantCell !== undefined && props.proposedDel === null) { // No change, allow to edit
          setActiveCell(cell)
          setRedlineSelection(null)
          setRedlineControls({type: 'closeRedlines', hint: null})

        }

        // SMART FIELD HANDLING
        setSmartFieldHandling({
          enableAdd: relevantCell !== null && (relevantCell.sf === undefined || relevantCell.sf === null) && rowIdx > -1 ? true : false,
          active: relevantCell !== null && relevantCell.sf !== undefined && relevantCell.sf !== null && rowIdx > -1 ? {ref: relevantCell.sf} : null,
          reconcile: null,
          order: getSFOrder(),
        })

      }
    }

  }

  const updateBulk = (changeType, scope, decision, changeCpty) => {
    let oldTable = table;
    let newHead = [], newRows = []

    // Confirmed removal from the Table
    // OR rejection of OWN insertion
    if((['deleteCol', 'deleteRow'].includes(changeType) && decision === 'accept') ||
    (['insertCol', 'insertRow'].includes(changeType) && decision === 'reject' && 
    changeCpty === props.curCpty)) { 
      handleForceDelete(['deleteRow', 'insertRow'].includes(changeType) ? "row" : "col", scope)

    } else { // You're not force removing, but simply manipulating

      table.head.forEach((h, i) => {
        newHead.push({
          colKey: h.colKey, 
          value: h.value, 
          change: 
            (decision === 'accept' && ( // Accept insertion - clears the change
              (['insertTable'].includes(changeType)) ||
              (['insertCol'].includes(changeType) && scope === i))) ? {} :
            (decision === 'reject' &&  // Reject deletion - clears the change
              (['deleteCol'].includes(changeType) && scope === i)) ? {} :
            (decision === 'reject' && ( // Reject insertion - changes to proposedDel
              (['insertCol'].includes(changeType) && scope === i))) ? { type: 'deleteCol', cpty: props.curCpty } :
            h.change // otherwise fall back to existing change
        })
      })

      table.rows.forEach((r,h) => {
        let newCells = []
        r.cols.forEach((c,j) => {
          newCells.push({
            cellKey: c.cellKey, 
            value: c.value, 
            sf: c.sf,
            change: 
              (decision === 'accept' && ( // Accept insertion - clears the change
                (['insertTable'].includes(changeType)) ||
                (['insertCol'].includes(changeType) && scope === j) ||
                (['insertRow'].includes(changeType) && scope === h))) ? {} :
              (decision === 'reject' && ( // Reject deletion - clears the change
                (['deleteCol'].includes(changeType) && scope === j) ||
                (['deleteRow'].includes(changeType) && scope === h))) ? {} :
              (decision === 'reject' && ( // Reject insertion - changes to proposedDel
                (['insertCol'].includes(changeType) && scope === j) ||
                (['insertRow'].includes(changeType) && scope === h))) ? { type: ['insertCol'].includes(changeType) ? 'deleteCol' : 'deleteRow', cpty: props.curCpty } :
              c.change // otherwise fall back to existing change
          })
        })
        newRows.push({
          rowKey: r.rowKey,
          cols: newCells,
        })
      })
      updateNewTable(oldTable, {...table, head: newHead, rows: newRows }, changeType)
    }
  }

  const updateField = (row, col, oldVal, newVal, changeType) => {
    const oldTable = JSON.parse(JSON.stringify(table))
    let tHead = table.head;
    let tRows = table.rows;
    let key, sf = null;
    if(row === "head") { 
      key = tHead[col].colKey;
      tHead[col] = {
        colKey: key, 
        value: changeType === 'reject' ? oldVal : newVal, // rejection simply reverts the old and new value
        change: ['accept', 'reject'].includes(changeType) ? {} : { 
          type: changeType,
          cpty: props.curCpty, 
          oldVal: oldVal,
        }}
    } else { 
      key = tRows[row].cols[col].cellKey
      sf = ['enableSmartField'].includes(changeType) ? key : 
        ['disableSmartField'].includes(changeType) ? null : 
        tRows[row].cols[col].sf !== undefined && tRows[row].cols[col].sf !== null ? tRows[row].cols[col].sf : null

      tRows[row].cols[col] = {
        cellKey: key, 
        value: 
          ['enableSmartField', 'disableSmartField'].includes(changeType) ? tRows[row].cols[col].value :
          changeType === 'reject' ? oldVal : newVal, // rejection simply reverts the old and new value
        sf: sf, 
        change: 
          ['accept', 'reject'].includes(changeType) ? {} : 
          ['enableSmartField', 'disableSmartField'].includes(changeType) ? tRows[row].cols[col].change :
            { 
              type: changeType,
              cpty: props.curCpty, 
              oldVal: oldVal,
            }}
    }
    updateNewTable(oldTable, {...table, head: tHead, rows: tRows }, changeType)
    setActiveCell(null) // On Blur - deactivate activeCell
  }

  const handleInsert = (type, idx) => {
    let oldTable = table
    let invisibleHead = table.invisibleHead
    let tHead = table.head;
    let tRows = table.rows;
    let ac = null // activeCell

    let change = { 
      type: 
        props.newTable || Boolean(isNewlyInsertedBy) ? 'insertTable' :
        type === 'row' ? 'insertRow' : 'insertCol',
      cpty: (props.curCpty !== undefined ? props.curCpty : null), 
      oldVal: '' 
    }

    if(type === "row" && idx === "head") {
      invisibleHead = false;
      tHead.forEach((th,i) => {
        th.value = "";
        th.change = th.change !== undefined && th.change !== null && th.change.type !== undefined && th.change.type === 'deleteCol' ? th.change : null;
        //th.change = {};
        if(i === 0) { ac = th.colKey }
      })
      
    } else if(type === "row") { // insert row
      let newCols = []
      tHead.forEach((th, i) => { 
        let k = genKey(5)
        newCols.push({cellKey: k, value: "", change: change}) 
        if(i === 0) { ac = k }
      })
      let newRows = []
      tRows.forEach((tr, i) => {
        newRows.push(tr)
        if(i === idx) { newRows.push({ rowKey: genKey(5), cols: newCols })}
      })
      tRows = newRows;
      //tRows.splice(idx + 1, 0, { rowKey: genKey(5), cols: newCols } ) 
    } else { // insert column
      let newHead = []
      let k = genKey(5)
      tHead.forEach((th,i) => {
        newHead.push(th)
        if(i === idx) { newHead.push({ colKey: k, value: "", change: change })}
      })
      tHead = newHead;
      //tHead.splice(idx + 1, 0, { colKey: k, value: "", change: change })
      ac = k;
      let newRows = []
      tRows.forEach((row, i) => {
        let newCols = []
        let k = genKey(5);
        if(table.invisibleHead && i === 0) { ac = k }
        row.cols.forEach((rc, j) => {
          newCols.push(rc);
          if(j === idx) { newCols.push({ cellKey: k, value: "", change: change })}
        })
        newRows.push({rowKey: row.rowKey, cols: newCols})

        //row.cols.splice(idx + 1, 0, { cellKey: k, value: "", change: change })
        //newRows.push({rowKey: row.rowKey, cols: row.cols})
      })
      tRows = newRows
    }
    updateNewTable(oldTable, {...table, invisibleHead: invisibleHead, head: tHead, rows: tRows }, idx === "head" ? 'insertHead' : 'insertRow')
    setActiveCell(ac)
  }

  function getCustomCellStyle(rowKey, cellKey) {

    let style = {}
    let partOfRowOrCol = false
    let rowIdx = rowKey === "head" ? "head" : table.rows.findIndex((r) => r.rowKey === rowKey)
    let colIdx = rowKey === "head" ? table.head.findIndex((c) => c.cellKey === cellKey) : table.rows[rowIdx].cols.findIndex((c) => c.cellKey === cellKey)

    if(redlineSelection !== null && redlineSelection.selection.startsWith('row_')) {
      let rowNo = parseInt(redlineSelection.selection.substr(4))
      
      if(table.rows[rowNo].cols.some((c) => c.cellKey === cellKey)) { partOfRowOrCol = true; }

    } else if(redlineSelection !== null && redlineSelection.selection.startsWith('col_')) {
      let colNo = parseInt(redlineSelection.selection.substr(4))
      if(table.head[colNo].colKey === cellKey) { partOfRowOrCol = true; }
      else {
        table.rows.forEach((r) => {
          if(r.cols[colNo].cellKey === cellKey) { partOfRowOrCol = true; }
        })
      }
    } else if (redlineSelection !== null && redlineSelection.selection === 'entireTable') {
      partOfRowOrCol = true;
    }

    if(redlineSelection !== null && redlineSelection.selection !== 'entireTable' &&
      redlineSelection.selection !== redlineSelection.cursor && redlineSelection.cursor === cellKey) {
      style = {...style, backgroundColor: '#ebebeb'} // If cursor needs to be highlighted because you selected a row/col

    } else if(activeCell === cellKey) { 
      style = {...style, backgroundColor: rowKey === "head" ? '#f9f9f9' : '#ffffff', padding: '0px 0px 0px 0px'}

    } else if((redlineSelection !== null && redlineSelection.selection === cellKey) || partOfRowOrCol) {
      style = {...style, backgroundColor: '#f3f3f3'}

    } else if(rowKey === "head") {
      style = {...style, backgroundColor: '#f9f9f9'}

    } else if(
      (props.smartMode && table.rows[rowIdx].cols[colIdx] !== undefined && 
      table.rows[rowIdx].cols[colIdx].sf !== undefined && table.rows[rowIdx].cols[colIdx].sf !== null &&
      (!props.plainMode || (props.cleanMode && props.active))) || 
      (props.highlightSF !== undefined && props.highlightSF !== null && props.highlightSF !== '' && 
      table.rows[rowIdx] !== undefined && table.rows[rowIdx].cols !== undefined && table.rows[rowIdx].cols[colIdx] !== undefined &&
      table.rows[rowIdx].cols[colIdx].sf === props.highlightSF)) {

      style = {...style, backgroundColor: '#ffefbe'} // Highlight any SmartField cells

    }

    return style
  }

  const handleForceDelete = (type, idx) => {
    let oldTable = table
    let tHead = table.head;
    let tRows = table.rows;
    let invisibleHead = table.invisibleHead
    let changeType = ''

    if(type === "row" && idx !== "head") {
      let newRows = []
      tRows.forEach((tr, i) => { if(idx !== i) { newRows.push(tr) } })
      tRows = newRows;
      //tRows.splice(idx, 1);
      changeType = 'deleteRow'
    } else if(type === "col") {
      changeType = 'deletecol'
      let newHead = []
      tHead.forEach((th, i) => { if(idx !== i) { newHead.push(th) } })
      tHead = newHead
      //tHead.splice(idx, 1)
      let newRows = []
      tRows.forEach((row) => {
        let newCols = [];
        row.cols.forEach((rc, j) => { if(idx !== j) { newCols.push(rc) } })
        newRows.push({ rowKey: row.rowKey, cols: newCols })
        //row.cols.splice(idx, 1) ;
        //newRows.push({ rowKey: row.rowKey, cols: row.cols })
      })
      tRows = newRows
    }
    updateNewTable(oldTable, {...table, invisibleHead: invisibleHead, head: tHead, rows: tRows }, changeType)
    // Push SmartField Reconciliation
    let sfReconcile = []
    tRows.forEach((tr) => {
      tr.cols.forEach((tc) => {
        if(tc.sf !== undefined && tc.sf !== null) {
          sfReconcile.push(tc.sf);
        }
      })
    })
    setSmartFieldHandling({
      enableAdd: false,
      active: null,
      reconcile: sfReconcile,
      order: getSFOrder(),
    })
  }

  const isPossibleToTabInto = (c) => { // To verify whether you can "tab" into this Head/Cell
    let r = false;
    if(c.change === undefined || c.change === null || c.change.type === undefined || 
      (['insertTable', 'insertRow', 'insertCol'].includes(c.change.type) && c.change.cpty === props.curCpty)){
      r = true;
    }
    return r;
  }

  const handleTabDetection = (e) => {
    
    if(e.key === 'Tab' && !props.newTable) {
      e.preventDefault()
      let foundCell = false, prevCell = null, nextCell = null, firstCell = null, lastCell = null;

      if(!table.head.invisibleHead){
        table.head.forEach((h) => {
          let iptti = isPossibleToTabInto(h);
          if(activeCell === h.colKey) { foundCell = true } // Finding the ActiveCell
          else if(foundCell && nextCell === null && iptti) { nextCell = {row: 'head', cell: h.colKey } } // Finding the NextCell
          if(activeCell !== h.colKey && !foundCell && iptti) { prevCell = {row: 'head', cell: h.colKey } } // Finding the PrevCell
          if(firstCell === null && iptti) { firstCell = {row: 'head', cell: h.colKey }  } // Finding the FirstCell
          if(iptti) { lastCell = {row: 'head', cell: h.colKey } } // Found the LastCell
        })
      }
      table.rows.forEach((r) => {
        r.cols.forEach((c) => {
          let iptti = isPossibleToTabInto(c);
          if(activeCell === c.cellKey) { foundCell = true } // Finding the ActiveCell
          else if(foundCell && nextCell === null && iptti) { nextCell = { row: r.rowKey, cell: c.cellKey } } // Finding the NextCell
          if(activeCell !== c.cellKey && !foundCell && iptti) { prevCell = { row: r.rowKey, cell: c.cellKey } } // Finding the PrevCell
          if(firstCell === null && iptti) { firstCell = { row: r.rowKey, cell: c.cellKey }  } // Finding the FirstCell
          if(iptti) { lastCell = { row: r.rowKey, cell: c.cellKey } } // Found the LastCell
        })
      })

      // Check if you updated the cell and whether it needs to be changed in state
      if(table.head.filter((h) => h.colKey === activeCell)[0] !== undefined &&
      table.head.filter((h) => h.colKey === activeCell)[0].value !== e.target.value){
        let headIdx = table.head.findIndex((h) => h.colKey === activeCell)

        updateField("head", headIdx, table.head[headIdx].value, e.target.value, 
        props.newTable || isNewlyInsertedBy === props.curCpty ? 
            'insertTable' : 
        table.head[headIdx].change !== undefined && table.head[headIdx].change !== null && table.head[headIdx].change.type !== undefined && ['insertRow', 'insertCol'].includes(table.head[headIdx].change.type) ? 
            table.head[headIdx].change.type : 
            'change')

      } else {

        table.rows.forEach((row, rowIdx) => {
          if(row.cols.filter((c) => c.cellKey === activeCell)[0] !== undefined &&
          row.cols.filter((c) => c.cellKey === activeCell)[0].value !== e.target.value) {
            let colIdx = row.cols.findIndex((c) => c.cellKey === activeCell)

            updateField(rowIdx, colIdx, row.cols[colIdx].value, e.target.value, 
            props.newTable || isNewlyInsertedBy === props.curCpty ? 
                'insertTable' : 
            row.cols[colIdx].change !== undefined && row.cols[colIdx].change !== null && row.cols[colIdx].change.type !== undefined && ['insertRow', 'insertCol'].includes(row.cols[colIdx].change.type) ? 
                row.cols[colIdx].change.type : 
                'change')
            
          }
        })
      }

      if(!e.shiftKey && nextCell !== null) { handleCellFocus('tabButton', nextCell.row, nextCell.cell, null) }
      else if(!e.shiftKey && nextCell === null && firstCell !== null) { handleCellFocus('tabButton', firstCell.row, firstCell.cell, null) }
      else if(e.shiftKey && prevCell !== null) { handleCellFocus('tabButton', prevCell.row, prevCell.cell, null) }
      else if(e.shiftKey && prevCell === null && lastCell !== null) { handleCellFocus('tabButton', lastCell.row, lastCell.cell, null) }

    }
  }

  const handleDelete = (type, idx) => {
    let oldTable = table;
    if(type === "row" && idx === "head") {
      let tHead = table.head;
      let tRows = table.rows;
      let invisibleHead = table.invisibleHead
      invisibleHead = true;
      updateNewTable(oldTable, {...table, invisibleHead: invisibleHead, head: tHead, rows: tRows }, 'hideHead')

    } else if(props.newTable || isNewlyInsertedBy === props.curCpty) {
      handleForceDelete(type, idx)

    } else { // proposed delete
      let changeType = type === "row" ? "insertRow" : "insertCol"
      let curCpty = 
        type === 'row' && table.rows[idx] !== undefined && table.rows[idx].cols.filter((c) => c.change !== undefined && c.change !== null && c.change.type === 'insertRow')[0] !== undefined ?
            table.rows[idx].cols.filter((c) => c.change !== undefined && c.change !== null && c.change.type === 'insertRow')[0].change.cpty :
        type === 'col' && table.head[idx] !== undefined && table.head[idx].change !== undefined && table.head[idx].change !== null && table.head[idx].change.type === 'insertCol' ?
            table.head[idx].change.cpty : null
      updateBulk(changeType, idx, 'reject', curCpty)
      setActiveCell(null)
      setRedlineSelection(null)
      setRedlineControls({ type: 'closeRedlines', hint: null })
    }
  }

  const RenderPlainValue = (type, value, change) => {

    return (
      <>
      {// Display of the OLD value (if applicable)
      change !== undefined && change !== null && ['change'].includes(change.type) &&
      change.oldVal !== undefined && change.oldVal !== null && change.oldVal !== '' &&
      !['red','green','none'].includes(props.diffColor) && !props.plainMode && props.proposedDel === null ?
      <span className={change.cpty === 'primary' ? classes.primaryDel : classes.secondaryDel} style={{display: 'block'}}>
        {change.oldVal.split('\n').map((cval, i) => <React.Fragment key={i}>{cval}<br/></React.Fragment>)}
      </span>
      :''}
      <span className={ // Display of the NEW value
        ['red'].includes(props.diffColor) ? // old table
            classes.redFont : 
        ['green'].includes(props.diffColor) ? // new table
            classes.greenFont :
        ['none'].includes(props.diffColor) ?
            classes.plainFont :
        props.plainMode ?
          null :
        change !== undefined && change !== null && ['insertTable', 'insertRow', 'insertCol', 'change'].includes(change.type) && change.cpty === 'primary' &&
        props.proposedDel === null ?
            classes.primaryAdd : 
        change !== undefined && change !== null && ['insertTable', 'insertRow', 'insertCol', 'change'].includes(change.type) && change.cpty === 'secondary' &&
        props.proposedDel === null ?
            classes.secondaryAdd : 
        (change !== undefined && change !== null && ['reject', 'deleteRow', 'deleteCol'].includes(change.type) && change.cpty === 'primary') ||
        (props.proposedDel === 'primary') ?
            classes.primaryDel : 
        (change !== undefined && change !== null && ['reject', 'deleteRow', 'deleteCol'].includes(change.type) && change.cpty === 'secondary') ||
        (props.proposedDel === 'secondary') ?
            classes.secondaryDel : ''}>

          {// Display (no value) for proposed deleted rows/cols that are empty
          (value === undefined || value === null || value === "") && 
          change !== undefined && change !== null && change.type !== undefined && ['deleteCol', 'deleteRow'].includes(change.type) ? 
              "(no value)" :
          value !== undefined && value !== null ? // Regular display of value
              value.split('\n').map((cval, i) => <React.Fragment key={i}>{cval}<br/></React.Fragment>)
          :''}

      </span>
      </>
    )
  }

  return (
    <div>
      {props.active && props.smartMode && 
      props.clause.clauseStatus !== 'proposedDel' &&
      ((props.smartFields !== undefined && props.smartFields.length > 0) || props.templating) ?
      <div>
      <SmartFields
        updateSmartField={props.updateSmartField}
        clid={props.clause._id}
        sclid={props.clause.singleClauseID}
        user={props.user}
        oatID={props.oatID}
        agrID={props.agrID}
        smartFieldHandling={smartFieldHandling}
        cleanMode={props.cleanMode}
        smartMode={props.smartMode}
        handleClick={handleClick}
        smartFields={props.smartFields}
        clauseTypes={props.clauseTypes}
        templating={props.templating}
        module={props.module}
        active={props.active}
        callbackOptions={props.callbackOptions}
      />
      </div>
      :''
      }

      <Paper variant="outlined" className={classes.tablePaper}>
        {// A table exists and is ready to render
        table !== undefined && table !== null && table.head !== undefined && table.rows !== undefined ?
        <TableContainer>
          <Table className={classes.table} /*style={{minWidth: ((table.head.length * 104) + 46 + 'px')}}*/>
            <TableBody>
            <TableRow className={// Show when (1) Active (2) not Proposed Deleted (3) Not newly inserted
              props.active && props.proposedDel === null && (!Boolean(isNewlyInsertedBy) || isNewlyInsertedBy === props.curCpty) &&
              !props.plainMode && !props.disabled ? '' : classes.hide}>
              {// Buttons at the top of the table to insert / delete columns
              table.head.map((h, i) => (
                <TableCell key={"col_" + i} className={classes.headButtons} padding="none">
                    <Tooltip title="Insert Column">
                      <span>
                      <IconButton tabIndex="-1" size="small" 
                      onClick={e => handleInsert("col", i)} //disabled={['primary', 'secondary'].includes(props.proposedDel)}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} className={classes.tinyIconSuccess} />
                      </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title={"Delete Column"}>
                      <span>
                      <IconButton tabIndex="-1" size="small" 
                      disabled={table.head.length === 1 || 
                        (table.head[i].change !== undefined && table.head[i].change !== null && 
                        table.head[i].change.type !== undefined && ['deleteCol'].includes(table.head[i].change.type))}
                      onClick={e => handleDelete("col", i)}
                      >
                          <FontAwesomeIcon icon={faTimesCircle} className={table.head.length === 1 ||
                            (table.head[i].change !== undefined && table.head[i].change !== null && 
                              table.head[i].change.type !== undefined && ['deleteCol'].includes(table.head[i].change.type)) ? classes.tinyIconDisabled : classes.tinyIconError} />
                      </IconButton>
                      </span>
                    </Tooltip>
                </TableCell>
              ))}
              <TableCell key={"lastEmptyFirstRow"} className={classes.lastEditColTop} padding="none">
                <span className={table.invisibleHead ? '' : classes.hide}>
                <Tooltip title="Show Heading">
                  <span>
                  <IconButton tabIndex="-1" size="small" 
                  onClick={e => handleInsert("row", "head")}
                  >
                      <FontAwesomeIcon icon={faHeading} className={classes.tinyIcon} />
                  </IconButton>
                  </span>
                </Tooltip>
                </span>
              </TableCell>
            </TableRow>

            <TableRow className={table.invisibleHead ? classes.hide : ''}>
              {table.head
              .filter((h) => h.change === null || h.change === undefined || !['deleteCol'].includes(h.change.type) || (!['red','green','none'].includes(props.diffColor) && !props.plainMode))
              .map((head, i) => (
                <TableCell key={head.colKey} padding="none"
                onClick={e => handleCellFocus('mouseClick', 'head', head.colKey, null)}
                className={classes.tableHead} 
                style={getCustomCellStyle("head", head.colKey)}>

                  {props.newTable || activeCell === head.colKey  ? 
                    // Display the cell input field
                    <InputBase multiline
                    placeholder={props.active && props.proposedDel === null &&
                                  !props.plainMode && !props.disabled ? "Header" : ""}
                    name={"head_" + i} 
                    defaultValue={head.value === "" ? "" : head.value} 
                    onFocus={activeCell !== head.colKey ? e => handleCellFocus('mouseClick', 'head', head.colKey, null) : null}
                    onBlur={e => (e.target.value !== head.value ? updateField("head", i, head.value, e.target.value, props.newTable || isNewlyInsertedBy === props.curCpty ? 'insertTable' : head.change !== undefined && head.change !== null && head.change.type !== undefined && ['insertRow', 'insertCol'].includes(head.change.type) ? head.change.type : 'change') : e => setActiveCell(null))} 
                    autoFocus={activeCell === head.colKey}
                    className={classes.headInput}
                    style={ // If a new table and input is no longer empty, give it the color of the cpty
                      props.newTable && !(head.value === undefined || head.value === null || head.value === '') ? 
                          { color: props.curCpty === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main } :
                      !props.newTable ?
                          { border: '1px dotted' + theme.palette.grey[500] } : {}}
                    onKeyDown={e => handleTabDetection(e)}
                    />
                  :
                    // Display the plain cell value
                    <div style={{margin: '12px 12px 12px 12px'}}>{ RenderPlainValue("head", head.value, head.change) }</div>
                  }
                </TableCell>
              ))}
              <TableCell key={"lastEmptyHead"} padding="none" className={
                // Show when (1) Active (2) not Proposed Deleted (3) Not newly inserted
                props.active && props.proposedDel === null && (!Boolean(isNewlyInsertedBy) || isNewlyInsertedBy === props.curCpty) &&
                !props.plainMode && !props.disabled ? classes.lastEditCol : classes.hide}>
                <Tooltip title="Hide Header">
                  <span>
                  <IconButton tabIndex="-1" size="small" 
                  onClick={e => handleDelete("row", "head")}
                  >
                      <FontAwesomeIcon icon={faMinusCircle} className={classes.tinyIcon} />
                  </IconButton>
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>          

            {// Render all table rows
            table.rows
            .filter((r) => r.cols.some((c) => c.change === null || c.change === undefined || !['deleteRow'].includes(c.change.type) || (!['red','green','none'].includes(props.diffColor) && !props.plainMode)))
            .map((row, r) => (
              <TableRow key={row.rowKey}>
                {// For each cell in the row, render
                row.cols
                .filter((c) => c.change === null || c.change === undefined || !['deleteCol'].includes(c.change.type) || (!['red','green','none'].includes(props.diffColor) && !props.plainMode))
                .map((col, c) => (
                  <TableCell key={"row_" + r + "_col_" + c} padding="none"
                  className={r === table.rows.filter((r) => r.cols.some((c) => c.change === null || c.change === undefined || !['deleteRow'].includes(c.change.type) || (!['red','green','none'].includes(props.diffColor) && !props.plainMode))).length - 1 ? 
                    classes.noBottomBorder : classes.tableCell}
                  onClick={e => handleCellFocus('mouseClick', row.rowKey, col.cellKey, null)}
                  style={getCustomCellStyle(row.rowKey, col.cellKey)}
                  width={(100 / row.cols.filter((c) => c.change === null || c.change === undefined || !['deleteCol'].includes(c.change.type) || (!['red','green','none'].includes(props.diffColor) && !props.plainMode)).length) + "%"}>
                    
                    {props.newTable || activeCell === col.cellKey ? 
                      // Display the cell input field
                      <InputBase multiline
                      placeholder={props.active && props.proposedDel === null &&
                                  !props.plainMode && !props.disabled? "Cell" : ""}
                      name={"cell_" + c} 
                      defaultValue={col.value === "" ? "" : col.value} 
                      onFocus={activeCell !== col.cellKey ? e => handleCellFocus('mouseClick', row.rowKey, col.cellKey, null) : null}
                      onBlur={e => (e.target.value !== col.value ? 
                        updateField(r, c, col.value, e.target.value, props.newTable || isNewlyInsertedBy === props.curCpty ? 'insertTable' : col.change !== undefined && col.change !== null && col.change.type !== undefined && ['insertRow', 'insertCol'].includes(col.change.type) ? col.change.type : 'change') : e => setActiveCell(null))} 
                      autoFocus={activeCell === col.cellKey}
                      className={classes.rowInput}
                      style={ // If a new table and input is no longer empty, give it the color of the cpty
                        props.newTable && !(col.value === undefined || col.value === null || col.value === '') ? 
                            { color: props.curCpty === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main } :
                        !props.newTable ?
                            { border: '1px dotted' + theme.palette.grey[500] } : {}}
                      onKeyDown={e => handleTabDetection(e)}
                      />
                    : 
                      // Display the plain cell value
                      <div style={{margin: '10px 12px 10px 12px'}}>{ RenderPlainValue("cell", col.value, col.change) }</div>
                    }
                  </TableCell>
                ))}
                <TableCell key={"EditCell_" + r} padding="none"
                className={
                  // Show when (1) Active (2) not Proposed Deleted (3) Not newly inserted
                  props.active && props.proposedDel === null &&  (!Boolean(isNewlyInsertedBy) || isNewlyInsertedBy === props.curCpty) &&
                  !props.plainMode && !props.disabled && r === table.rows.length - 1 ?
                      classes.noBottomBorderEditCol :
                  props.active && props.proposedDel === null &&  (!Boolean(isNewlyInsertedBy) || isNewlyInsertedBy === props.curCpty) &&
                  !props.plainMode && !props.disabled ?
                      classes.lastEditCol :
                      classes.hide}>
                  <Tooltip title="Insert Row">
                    <span>
                    <IconButton tabIndex="-1" size="small" 
                    onClick={e => handleInsert("row", r)}
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className={classes.tinyIconSuccess} />
                    </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Delete Row">
                    <span>
                    <IconButton tabIndex="-1" size="small" 
                    onClick={e => handleDelete("row", r)}
                    disabled={table.rows.length === 1 || (
                      table.rows[r] !== undefined && table.rows[r].cols.some((c) => c.change !== undefined && c.change !== null && c.change.type !== undefined && ['deleteRow'].includes(c.change.type)))}>
                        <FontAwesomeIcon icon={faTimesCircle} className={table.rows.length === 1 || (
                      table.rows[r] !== undefined && table.rows[r].cols.some((c) => c.change !== undefined && c.change !== null && c.change.type !== undefined && ['deleteRow'].includes(c.change.type))) ? 
                          classes.tinyIconDisabled : classes.tinyIconError} />
                    </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
        :''}
      </Paper>
      {props.active && props.proposedDel === null &&
      !props.plainMode && !props.disabled &&
      !props.newTable ?
          <RedlineControls
            type={redlineControls.type}
            hint={redlineControls.hint}
            editMode={props.editMode}
            templating={props.templating}
            isTable={true}
            click={handleClick}
          />
      :''}

    </div>
  );
}