function userFriendlyAVV(avv) {

  let r = ''
  if(avv !== undefined && avv !== null && avv.length === 8) { // For template versions
    r = parseInt(avv.substr(0,3)) + "." + parseInt(avv.substr(4,4));
  } else if(avv !== undefined && avv !== null && avv !== '') {
    //r = parseInt(avv.substr(0,2)) + "." + parseInt(avv.substr(3,3)) + (parseInt(avv.substr(7,3)) > 1 ? ("." + parseInt(avv.substr(7,3))) : '');
    r = parseInt(avv.substr(0,2)) + "." + parseInt(avv.substr(3,3)) + "." + parseInt(avv.substr(7,3));
  }
  return r

}
export { userFriendlyAVV }