import React from 'react';
import { Avatar, Box, Card, CardContent, Typography,
         makeStyles } from '@material-ui/core';

const dayjs = require('dayjs')

const ThumbCert = (props) => {

  const useStyles = makeStyles(theme => ({
    card: {
        '&:hover' : {
          boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 24px 0px',
          cursor: 'pointer',
        }
    },
    logoAvatar: {
        width: '80px',
        height: '80px',
    }
  }));
  const classes = useStyles();

  return (
    <Card variant="outlined" className={classes.card}  onClick={e => props.click(e, props.cert._id)}>
        <CardContent>
            <Box align="center" mt={2} mb={2}>
            <Avatar src={props.cert.logoURL} className={classes.logoAvatar} />
            </Box>
            <Typography variant="subtitle1" align="center">
            {props.cert.title}
            </Typography>
            <Typography align="center" color="textSecondary" variant="body1">
            Since: {dayjs(props.cert.sinceDate).format('MMM D, YYYY')}
            </Typography>
        </CardContent>
    </Card>
  );
}

export default ThumbCert
