import React, { useEffect }  from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Chip, Grid, makeStyles } from '@material-ui/core';
import { DialogSmartField } from '.'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDot, faHandSparkles, faInfoCircle, faMagic } from "@fortawesome/free-solid-svg-icons";
import { greatPatternFinder, convertLabelToTitle } from '../utils'

const axios = require('axios');

const SmartFields = (props) => {

    const useStyles = makeStyles(theme => ({
        mainBox: {
            width: '100%', 
            marginTop: '0px',
            marginBottom: '0px',
        },
        stdChip: {
            [theme.breakpoints.down('xs')]: { fontSize: '10px', padding: '1px 4px 1px 4px', margin: '0px 3px 3px 0px', },
            [theme.breakpoints.only('sm')]: { fontSize: '11px', padding: '1px 4px 1px 4px', margin: '0px 8px 8px 0px', },
            [theme.breakpoints.only('md')]: { fontSize: '12px', padding: '1px 6px 1px 6px', margin: '0px 8px 8px 0px', },
            [theme.breakpoints.up('lg')]: { fontSize: '12px', padding: '1px 6px 1px 6px', margin: '0px 8px 8px 0px',  },
            fontWeight: '400',
            borderColor: theme.palette.grey[600],
        },
        stdChipInactive: {
            [theme.breakpoints.down('xs')]: { fontSize: '10px', padding: '1px 4px 1px 4px', margin: '0px 3px 3px 0px', },
            [theme.breakpoints.only('sm')]: { fontSize: '11px', padding: '1px 4px 1px 4px', margin: '0px 8px 8px 0px', },
            [theme.breakpoints.only('md')]: { fontSize: '12px', padding: '1px 6px 1px 6px', margin: '0px 8px 8px 0px', },
            [theme.breakpoints.up('lg')]: { fontSize: '12px', padding: '1px 6px 1px 6px', margin: '0px 8px 8px 0px',  },
            fontWeight: '400',
            color: theme.palette.grey[200],
            backgroundColor: theme.palette.grey[400],
        },
        stdChipCreateDisabled: {
            [theme.breakpoints.down('xs')]: { fontSize: '10px', padding: '1px 4px 1px 4px', margin: '0px 3px 3px 0px', },
            [theme.breakpoints.only('sm')]: { fontSize: '11px', padding: '1px 4px 1px 4px', margin: '0px 8px 8px 0px', },
            [theme.breakpoints.only('md')]: { fontSize: '12px', padding: '1px 6px 1px 6px', margin: '0px 8px 8px 0px', },
            [theme.breakpoints.up('lg')]: { fontSize: '12px', padding: '1px 6px 1px 6px', margin: '0px 8px 8px 0px',  },
            fontWeight: '400',
            color: theme.palette.grey[500],
            borderColor: theme.palette.grey[500],
        },     
        chipIcon: {
            padding: '0px 2px 0px 5px',
        },
        wideGrid: {
            padding: '5px 0px 5px 0px',
        },
        chipContainer: {
            width: '100%',
            height: 'auto',
        },
        hide: {
            display: 'none',
        },
        background: {
            position: 'absolute',
            top: '3px',
            left: 'auto',
            bottom: '0',
            right: '0',
            zIndex: '-1',
            overflow: 'hidden',
            fontSize: '12px',
            fontWeight: '400',
            color: theme.palette.grey[500],
         }
    }));
    const classes = useStyles();

    const history = useHistory()

    const [activeSmartField, setActiveSmartField] = React.useState(null);
    const [openSmartField, setOpenSmartField] = React.useState(null)
    const [smartFields, setSmartFields] = React.useState(props.smartFields)

    const handleOpenSmartField = (ref, type) => {
        if(type === 'field') {
            let sf = smartFields.filter((s) => s.ref === ref)[0];
            setActiveSmartField(ref)
            setOpenSmartField({_id: sf._id, ref: ref, type: 'view'})
        } else if (type === 'topic') {
            setOpenSmartField({ref: ref, type: 'view-topic'})
        }
    }
    
    useEffect(() => {

        if(props.smartFieldHandling.reconcile !== null  && Boolean(props.smartFieldHandling.reconcile) &&
        Array.isArray(props.smartFieldHandling.reconcile)) { // a request to reconcile from the inside editor
            let newSFs = []
            smartFields.forEach((sf) => {
                if(!props.smartFieldHandling.reconcile.some((s) => s === sf.ref)) {
                    axios.put('/sf/sfunlink', { // send request to unlink from the DB
                        ref: sf.ref,
                        singleClauseID: props.sclid,
                        lastUpdateBy: props.user._id,
                        lastUpdateDate: new Date().toISOString(),
                    })
                } else {
                    newSFs.push(sf);
                }
            })
            setSmartFields(newSFs);
        }

    }, [props.smartFieldHandling.reconcile, /*smartFields,*/ props.user, props.sclid])

    useEffect(() => {

        if(props.smartMode && props.smartFieldHandling !== null && // A (new) active Smartfield 
        Boolean(props.smartFieldHandling.active) && /*props.clid === props.smartFieldHandling.clid &&*/ 
        props.smartFieldHandling.active.ref !== undefined && 
        (activeSmartField === null || activeSmartField.active === null || activeSmartField !== props.smartFieldHandling.active.ref)) {

            let sf = props.smartFieldHandling.active;
            if(smartFields.filter((s) => s.ref === sf.ref)[0] !== undefined) { // SmartField found
                if(sf.open) { handleOpenSmartField(sf.ref, 'field') } 
                else { setOpenSmartField(null) }
                setActiveSmartField(sf.ref)
            } else if(!sf.open) { // Smartfield is new - try to understand through the great patternfinder
                let breakdown = greatPatternFinder(sf.text);

                // Push the newly created smartfield
                let creationDate = new Date().toISOString();
                axios.post('/sf/sf', {
                    agrid: props.agrID,
                    oatid: props.oatID,
                    singleClauseID: props.sclid,
                    ref: sf.ref,
                    name: '',
                    type: breakdown.type,
                    val1: breakdown.val1,
                    val2: breakdown.val2,
                    val3: breakdown.val3,
                    createWizard: true,
                    creationBy: props.user._id,
                    creationDate: creationDate,
                }).then((res) => {
                    setSmartFields(sfs => [...sfs, res.data.data]);
                    setActiveSmartField(sf.ref)
                    setOpenSmartField({_id: res.data.data._id, ref: sf.ref, sf: res.data.data, type: 'create', text: sf.text}) // Opens the dialog

                }).catch((err) => { console.log("err", err) })
            }
        } else if(props.smartMode && (activeSmartField !== null || openSmartField !== null)) {
            setActiveSmartField(null);
            setOpenSmartField(null);
        }

    }, [props.smartFieldHandling, props.agrID, props.sclid, props.oatID, props.user._id, props.smartMode])

    const specialHandlingUpdate = (updateType, newVal) => {
        // Special handling for smart fields (e.g. setting or unlinking the Agreement Effective Date should update the Template)

        if(['updateEdt'].includes(updateType)) {

            axios.put('/template/protect/oatedt/' + props.oatID, {
                effectiveDateType: newVal, 
                lastUpdateBy: props.user._id,
                lastUpdateDate: new Date().toISOString()
            })
            .then(function (res) { // Done updating - now reroute
                history.push("/core/legal/templates/" + props.module + "/edit/" + props.oatID + "/" + props.sclid)

            }).catch(function (err) { console.log(err) })
        }

    }

    const handleUnlink = () => {
        let sf = smartFields.filter((s) => s.ref === activeSmartField)[0];
        let sfs = smartFields.filter((s) => s.ref !== activeSmartField)
        
        executeSmartFieldUpdate(
            sf._id, // sf object
            sf.osfid, // osfid
            'deleted', // updRef
            sf.name, // updName
            sf.type, // updType
            sf.val1, // updVal1
            sf.val2, // updVal2
            sf.val3, // updVal3
            sf.createWizard, // updCreateWizard
        )

        props.handleClick('unlinkSmartField_' + activeSmartField)

        if(sf.name === "Agreement Effective Date" && props.templating) { // Unlinking the Smartfield for Effective date - now also revert the OrgAgrTemplate characteristic to the default
            specialHandlingUpdate('updateEdt', 'signoff')
        } else {
            setActiveSmartField(null)
            setSmartFields(sfs)
            setOpenSmartField(null)
        }
    }

    const executeSmartFieldUpdate = (sfid, osfid, updRef, updName, updType, updVal1, updVal2, updVal3, updCreateWizard) => {
        
        props.updateSmartField(
            sfid, // sfid
            osfid, // osfid
            updRef, // ref
            updName, // name
            updType, // type
            updVal1, // val1
            updVal2, // val2
            updVal3, // val3
            updCreateWizard, // createWizard
            props.user._id, // lastUpdateBy
        )

        let idx = smartFields.findIndex((s) => /*s.ref === updRef || (updRef === 'deleted' && */s._id === sfid)
        let temparr = [...smartFields];

        temparr[idx] = { ...temparr[idx],
            ref: updRef, // ref
            name: updName, // name
            type: updType, // type
            val1: updVal1, // val1
            val2: updVal2, // val2
            val3: updVal3, // val3
            createWizard: updCreateWizard, // createWizard
        };

        setSmartFields(temparr)
    }

    return (
        <div className={props.active && (props.templating || smartFields.filter((sf) => sf.ref !== 'deleted').length > 0 || (props.clauseTypes !== undefined && props.clauseTypes.length > 0)) ?
            classes.mainBox : classes.hide}>
            <Grid container direction="row" spacing={0} className={classes.wideGrid} alignItems="flex-start" justify="flex-start">
                <Grid item xs={12}>
                    {smartFields !== null || (props.clauseTypes !== undefined && props.clauseTypes.length > 0)?
                    <>
                    <Box className={classes.chipContainer} pb={1}>

                    <Box style={props.clauseTypes.length > 0 || smartFields.length > 0 ? {position: 'relative'} : {display: 'inline'}}>
                        {// Show create smart clause when you don't have any
                        (props.templating && /*smartFields.filter((sf) => sf.ref === 'clause').length === 0 &&*/ !props.cleanMode) ?
                        <Chip
                        icon={<FontAwesomeIcon icon={faHandSparkles} className={classes.chipIcon} />}
                        label="Smart Clause"
                        clickable
                        //color="primary"
                        size="small"
                        variant="outlined" 
                        classes={{ root: classes.stdChip, disabled: classes.stdChipCreateDisabled }}
                        disabled={false}
                        onClick={e => setOpenSmartField({clid: props.clid, ref: 'clause', type: 'create'})} // createSmartClause
                        style={{fontWeight: '400'}}
                        />
                        : '' }

                        {// Show all clause topic chips (to the extent you have any)
                        props.clauseTypes
                        .map((ct, i) => (
                            <Chip
                            key={i}
                            label={convertLabelToTitle(ct)}
                            icon={<FontAwesomeIcon icon={faInfoCircle} className={classes.chipIcon} />}
                            clickable
                            color="primary"
                            size="small"
                            className={classes.stdChip}
                            onClick={e => handleOpenSmartField(ct, 'topic')}
                            />
                        ))}

                        <div className={classes.background}>Topics</div>

                    </Box>

                    <Box style={props.clauseTypes.length > 0 || smartFields.length > 0 ? {position: 'relative'} : {display: 'inline'}}>
                        {// Show create smartfield when you don't have any or when you are asked to show it through "enableAdd"
                        (props.templating && /*smartFields.filter((sf) => sf.ref !== 'deleted').length === 0 &&*/ !props.cleanMode) || 
                        (props.templating && props.smartFieldHandling !== null && props.smartFieldHandling.enableAdd) ||
                        (!props.templating && smartFields.filter((sf) => sf.ref !== 'deleted').length > 0 && props.smartFieldHandling !== null && props.smartFieldHandling.enableAdd) ?
                        <Chip
                        icon={<FontAwesomeIcon icon={faMagic} className={classes.chipIcon} />}
                        label="Smart Field"
                        clickable
                        //color="primary"
                        size="small"
                        variant="outlined" 
                        classes={{ root: classes.stdChip, disabled: classes.stdChipCreateDisabled }}
                        disabled={props.smartFieldHandling === null || !props.smartFieldHandling.enableAdd}
                        onClick={e => props.handleClick('createSmartField')}
                        style={{fontWeight: '400'}}
                        />
                        : '' }

                        {// Show all smartfield chips (to the extent you have any)
                        smartFields
                        .filter((sf) => sf.ref !== 'deleted')
                        .sort((a,b) => props.smartFieldHandling.order.indexOf(a.ref) - props.smartFieldHandling.order.indexOf(b.ref))
                        .map((sf, i) => (
                            <Chip
                            key={i}
                            label={sf.name !== '' ? sf.name : '(Name undefined)'}
                            icon={<FontAwesomeIcon icon={faCircleDot} className={classes.chipIcon} />}
                            clickable
                            color="primary"
                            size="small"
                            className={activeSmartField !== null && activeSmartField !== sf.ref ? classes.stdChipInactive : classes.stdChip}
                            onClick={e => handleOpenSmartField(sf.ref, 'field')}
                            />
                        ))}

                        <div className={classes.background}>Parameters</div>
                    </Box>

                    </Box>
                    </>
                    :''}
                </Grid>
            </Grid>

            <DialogSmartField
                open={Boolean(openSmartField)}
                openSmartField={openSmartField}
                user={props.user}
                oatID={props.oatID}
                smartFields={smartFields !== null ? smartFields : []}
                clauseTypes={props.clauseTypes}
                cleanMode={props.cleanMode}
                parentCallToClose={e => setOpenSmartField(null)}
                handleUnlink={handleUnlink}
                executeSmartFieldUpdate={executeSmartFieldUpdate}
                specialHandlingUpdate={specialHandlingUpdate}
                callbackOptions={props.callbackOptions}
                templating={props.templating}
                module={props.module}
            />
        </div>
    );
}

export default SmartFields