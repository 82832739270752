import React from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { getDefaultModule } from '../utils';
import PropTypes from 'prop-types'; // https://reactjs.org/docs/typechecking-with-proptypes.html
import { Avatar, Menu, MenuItem, ListItemText, ListItemIcon, makeStyles, IconButton, CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faSignOutAlt, faQuestionCircle, faTimes, faGaugeSimple, faEllipsisV, faCommentDots } from "@fortawesome/free-solid-svg-icons";

const axios = require('axios');

const MenuPopAvatar = (props) => {

    const useStyles = makeStyles(theme => ({
        iconPrimary: {
            color: theme.palette.primary.main
        },
        avImg: {
            width: 30,
            height: 30,
            backgroundColor: theme.palette.grey[800],
            cursor: 'pointer',
            marginLeft: '10px',
        },
        specialMenuOption: {
            fontSize: '13px',
            fontWeight: '500',
            color: theme.palette.primary.main,
        },
        iconB: {
            fontSize: '18px',
            padding: '11px 17px 11px 17px',
        },
    }));
    const classes = useStyles();

    const menuWidth = props.user.role === 'Counterparty' ? 210 : 180

    const [anchorEl, setAnchorEl] = React.useState(null); 
    const [logoutNavigate, setLogoutNavigate] = React.useState(false);
    const [loadingLogout, setLoadingLogout] = React.useState(false);
  
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setLoadingLogout(true)

        axios.get('/clause/protect/clearlocks/' + props.user._id)

        axios.get('/auth/logout')
        .then(function (res) {
            setLogoutNavigate(true);
        })
        .catch(function (err) {
            console.log("error logging out", err)
        })
    }

    if(logoutNavigate) {
        return (<Redirect to="/core/logout" push={true} />)

    } else {

        return (
            <React.Fragment>

                {props.user.role === 'Counterparty' ? 
                <IconButton className={classes.iconB} onClick={handleClick}>
                    <FontAwesomeIcon icon={faEllipsisV} />
                </IconButton>
                :
                <Avatar alt="User name" aria-controls="avatar-menu" aria-haspopup="true" 
                className={classes.avImg}
                style={props.user !== undefined && props.user !== null && props.user.photoURL !== '' ? {backgroundColor: '#ffffff'} : {}}
                src={(props.user !== undefined) ? props.user.photoURL : ''} onClick={handleClick} />
                }

                <Menu
                    id="avatar-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    disableAutoFocusItem
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {['open', 'account', 'network', 'legal', 'admin'].includes(props.module) ?
                    <MenuItem onClick={handleClose} component={RouterLink} style={{width: menuWidth}}
                    to={props.user !== undefined && props.user.email !== undefined && 
                        ['corporate', 'sales', 'procurement', 'partnerships', 'people', 'counterparty'].includes(props.module) ? 
                          ("/core/" + props.module + "/dashboard") : ("/core/" + getDefaultModule(props.org, props.user) + "/dashboard")}>
                    <ListItemIcon><FontAwesomeIcon icon={faGaugeSimple} className={classes.iconPrimary} /></ListItemIcon>
                    <ListItemText>Dashboard</ListItemText>
                    </MenuItem>
                    : ''}
                    {!props.user.customLink ?
                    <MenuItem onClick={handleClose} component={RouterLink} to="/core/settings" style={{width: menuWidth}}>
                        <ListItemIcon><FontAwesomeIcon icon={faCog} className={classes.iconPrimary} /></ListItemIcon>
                        <ListItemText>Account</ListItemText>
                    </MenuItem>
                    : '' }
                    <MenuItem onClick={handleClose} component={RouterLink} to="/core/help/overview" style={{width: menuWidth}}>
                        <ListItemIcon><FontAwesomeIcon icon={faQuestionCircle} className={classes.iconPrimary} /></ListItemIcon>
                        <ListItemText>Help</ListItemText>
                    </MenuItem>
                    {props.user.role === 'Counterparty' ?
                    <MenuItem onClick={handleClose} component={RouterLink} to="/contact/support" style={{width: menuWidth}}>
                        <ListItemIcon><FontAwesomeIcon icon={faCommentDots} className={classes.iconPrimary} /></ListItemIcon>
                        <ListItemText>Give Feedback</ListItemText>
                    </MenuItem>
                    : ''}
                    <MenuItem onClick={handleLogout}>
                        <ListItemIcon><FontAwesomeIcon icon={!props.user.customLink ? faSignOutAlt : faTimes} className={classes.iconPrimary} /></ListItemIcon>
                        <ListItemText>{!props.user.customLink ? "Sign out" : "Exit"} {loadingLogout ? <CircularProgress size={14} /> :''}</ListItemText>
                    </MenuItem>
                </Menu>

            </React.Fragment>      
        )
    }

}

MenuPopAvatar.propTypes = {
  module: PropTypes.string.isRequired,
  user: PropTypes.object
};

export default MenuPopAvatar