import React from 'react'
import AvatarEditor from 'react-avatar-editor'
import { Avatar, Box, Button, CircularProgress, IconButton, Grid, Slider, Typography,
         makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faImage, faSearchPlus, faSyncAlt } from "@fortawesome/free-solid-svg-icons";

function UploadImg (props) {

  const useStyles = makeStyles(theme => ({
    greyIcon: {
      color: theme.palette.grey[600],
    },
    mtAndWide: {
      marginTop: '30px',
      width: '250px'
    },
    input: {
      display: 'none',
    },
    iconSize: {
      fontSize: '120px',
    },
    iconButtonSize: {
      padding: 30
    },
    avatarEdit: {
      margin: '40px 0px 40px 0px',
      height: '150px',
      width: '150px',
      //backgroundColor: theme.palette.grey[800],
    },
    avatarOrgEdit: {
      margin: '40px 0px 40px 0px',
      height: '150px',
      width: '150px',
    }
  }));
  const classes = useStyles();

  const editor = React.createRef();
  const [zoom, setZoom] = React.useState(1.0); // default zoom is 0% (range -50% to +50%)
  const [rotate, setRotate] = React.useState(0);
  const [submitting, setSubmitting] = React.useState(false);
  const [img, setImg] =  React.useState(null);

  const handleZoomChange = (event, newValue) => {
    setZoom(newValue);
  };

  const handleRotateChange = (event) => {
    setRotate(rotate+90);
  };

  const clearUpload = () => {
    setImg(null);
  }

  const handleUploadChange = (e) => {
    setImg(e.target.files[0]);
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitting(true);
    var formData = new FormData()
    var dataURL = editor.current.getImageScaledToCanvas().toDataURL();
    var image = dataURItoBlob(dataURL);
    formData.append('image', image, generateRandomString(20));

    fetch(`/upload/image`, {
      method: 'POST',
      body: formData
    })
    .then(res => res.json())
    .then(images => {
      setSubmitting(false);
      setImg(null) // newly added : 2-SEP 2020
      props.parentCallback(images.imageUrl);
    })
  }
    
  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type:mimeString});
  }

  function generateRandomString(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    
    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    
    return text;
  }

  function RenderIconButton({type}) {
    if(type === 'avatar') {
      return (<FontAwesomeIcon icon={faUserCircle} className={classes.iconSize} />)
    } else {
      return (<FontAwesomeIcon icon={faImage} className={classes.iconSize} />)
    }
  }
  
  return (
    <div>

      <Typography align="center" variant="body2">
      {props.type === 'avatar' ? 
      'Upload a photo (400px by 400px)' : 
      'Upload a logo (400px by 400px)'}
      </Typography>
      {img !== null ?
      <Button size="small" variant="text" color="primary" onClick={clearUpload}>Clear Upload and retry</Button>
      :
      props.initialImg !== null ?
      (
      <Typography align="center" variant="body2">
        (Click to edit)
      </Typography>
      ) : '' }

      { img === null ? 
      
        (
          <div>
            <input type="file" accept="image/*" id="icon-button-file"
              onChange={handleUploadChange}
              className={classes.input} />
            <label htmlFor="icon-button-file">
              {props.initialImg !== null ?
              (
                <Avatar src={props.initialImg} alt="Current Image" 
                className={props.type === 'avatar' ? classes.avatarEdit : classes.avatarOrgEdit} />
              )
              :
              (
              <IconButton component="span" className={classes.iconButtonSize}>
                <RenderIconButton type={props.type} />
              </IconButton>
              )
              }
              
            </label>
          </div>
            
        ) 
        : 
        (
        <div>

          <AvatarEditor
            image={img}
            width={200}
            height={200}
            border={50}
            color={props.type === 'avatar' ? [255, 255, 255, 0.7] : [126, 80, 223, 0.7]} // RGBA
            scale={zoom}
            borderRadius={100}
            rotate={rotate}
            ref={editor}
          />        
          <Box width={320}>
            <Grid container direction="row" alignItems="center" justify="center">
                <Grid item xs={2}>
                    <FontAwesomeIcon icon={faSearchPlus}  className={classes.greyIcon} />
                </Grid>
                <Grid item xs={8}>
                    <Slider
                      value={zoom}
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="off"
                      step={0.005}
                      //min={props.type === 'avatar' ? 1.0 : 0.5}
                      min={0.8}
                      max={1.5}
                      onChange={handleZoomChange}
                    />
                </Grid>
                <Grid item xs={2}>
                  { props.type === 'avatar' ? 
                  (
                  <IconButton color="primary" onClick={handleRotateChange}>
                    <FontAwesomeIcon icon={faSyncAlt} />
                  </IconButton>
                  ) : '' }
                </Grid>
            </Grid>        
          </Box>
          
          <Button variant="contained" color="primary" disableElevation 
            onClick={handleSubmit}
            disabled={submitting}
            className={classes.mtAndWide}>
            { submitting && (<CircularProgress size={24} /> )}
            { submitting ? 'Submitting...' : 'Submit' }
          </Button>
        </div>
        )}

    </div>
  )
}
export default UploadImg