import * as ActionTypes from '../ActionTypes';

const axios = require('axios');

export function fetchNotificationsByUID(uid) {
  return dispatch => {
      dispatch(fetchNotificationsPending());

      axios.get('/notification/notifications/' + uid)
      .then(function(res) {
        dispatch(fetchNotificationsSuccess(res.data.data));
      })
      .catch(function(err) {
        dispatch(fetchNotificationsError(err));
      })
  }
}

export const fetchNotificationsPending = () => ({
    type: ActionTypes.FETCH_NOTIFICATIONS_PENDING
});

export const fetchNotificationsDone = () => ({
    type: ActionTypes.FETCH_NOTIFICATIONS_DONE
});

export const fetchNotificationsError = (error) => ({
    type: ActionTypes.FETCH_NOTIFICATIONS_ERROR,
    payload: error
});

export const fetchNotificationsSuccess = (nots) => ({
    type: ActionTypes.FETCH_NOTIFICATIONS_SUCCESS,
    payload: nots
});

// Changed to FAF
export const createNotification = (notificationType, title, description, linkTo, isRead, uid) => {
  return dispatch => {
    let creationDate = new Date().toISOString();
    axios.post('/notification/notification', {
        notificationType: notificationType,
        title: title,
        description: description,
        linkTo: linkTo,
        isRead: isRead,
        creationDate: creationDate,
        uid: uid,        
    })
  }
};

export const markNotificationAsRead = (nid) => {
  return dispatch => {
    
    axios.put('/notification/notification/' + nid)
    .then(function (res) { // success
      // succesful update - you will get the updated notification
      dispatch(updNotification(res.data.data))
    })
    .catch(function (err) { // err
      dispatch(fetchNotificationsError(err))
    })       
  }
};

export const updNotification = (noti) => ({
  type: ActionTypes.UPDATE_NOTIFICATION,
  payload: noti
});

export const markAllNotificationsAsRead = (uid) => {
    return dispatch => {
      
      axios.put('/notification/notifications/' + uid)
      .then(function (res) { // success
        // succesful update - repull all updated notifications
        dispatch(fetchNotificationsByUID(uid))
      })
      .catch(function (err) { // err
        dispatch(fetchNotificationsError(err))
      })       
    }
  };