import * as ActionTypes from '../ActionTypes';
import { fetchOrgProfileAgrTemplatesByOrgID, fetchOrgProfileSubsByOrgID, fetchOrgProfileCertsByOrgID } from '.';
import { prices } from '../../assets/static/prices';
import { getCcyByCountry } from '../../utils';

const axios = require('axios');

// ORG ACTIONS

export function fetchOrgByID(orgid) {
    return dispatch => {
        dispatch(fetchUserOrgPending());

        axios.get('/org/org/' + orgid)
        .then(function (res) {
            dispatch(fetchUserOrgSuccess(res.data.data));
        })
        .catch(function (err) {
            dispatch(fetchUserOrgError(err));
        })       
   }
}

export const updateOrg = (orgid, legalName, logoURL, url, phone, email, notifyUsers, whiteLabel, orgSettings, integrations) => {
  return dispatch => {
    dispatch(fetchUserOrgPending());
    
    axios.put('/org/protect/org/' + orgid, {
      legalName: legalName,
      logoURL: logoURL, 
      url: url, 
      phone: phone, 
      email: email,
      notifyUsers: notifyUsers,
      whiteLabel: whiteLabel,
      orgSettings: orgSettings,
      integrations: integrations,
    })
    .then(function (res) { // success
      dispatch(fetchOrgByID(orgid))
    })
    .catch(function (err) { // err
      dispatch(fetchUserOrgError(err))
    })
  }
};

export const updateOrgLevel = (orgID, clientType, modules) => {
  return dispatch => {

    axios.put('/org/protect/orglevel/' + orgID, {
      clientType: clientType,
      modules: modules,
    })
    .then(function (res) { // success
      dispatch(fetchOrgByID(orgID))
      dispatch(updateStripeBillingQty(orgID))
    })
    .catch(function (err) { // err
      dispatch(fetchUserOrgError(err))
    })
  }
}

export const updateLogoForOrg = (orgid, logoURL) => {
  return dispatch => {
    axios.put('/org/protect/orglogo/' + orgid, {
      logoURL: logoURL, 
    })
  }
}

export const enableHubForOrg = (orgid, hubParticipant) => {
  return dispatch => {
    axios.put('/org/protect/hub/' + orgid, {
      hubParticipant: hubParticipant, 
    })
    .then(function (res) { // success
      dispatch(fetchOrgByID(orgid))
    })
    .catch(function (err) { // err
      dispatch(fetchUserOrgError(err))
    })
  }
}

export const fetchUserOrgPending = () => ({
  type: ActionTypes.FETCH_USERORG_PENDING
});

export const fetchUserOrgError = (error) => ({
  type: ActionTypes.FETCH_USERORG_ERROR,
  payload: error
});

export const fetchUserOrgSuccess = (org) => ({
  type: ActionTypes.FETCH_USERORG_SUCCESS,
  payload: org
});

// ORG PROFILE ACTIONS

export function fetchOrgProfileByURL(orgurl, withTemplates) {
  return dispatch => {
      dispatch(fetchOrgProfilePending());

      axios.get('/org/orgurl/' + orgurl) // orgurl can be the url or the _id
      .then(function (res) {
          if(withTemplates){
            dispatch(fetchOrgProfileAgrTemplatesByOrgID(res.data.data._id));
          }
          dispatch(fetchOrgProfileSubsByOrgID(res.data.data._id));
          dispatch(fetchOrgProfileCertsByOrgID(res.data.data._id));
          dispatch(fetchOrgProfileSuccess(res.data.data));
      })
      .catch(function (err) {
          dispatch(fetchOrgProfileError(err));
      })       
 }
}

export const fetchOrgProfilePending = () => ({
  type: ActionTypes.FETCH_ORGPROFILE_PENDING
});

export const fetchOrgProfileError = (error) => ({
  type: ActionTypes.FETCH_ORGPROFILE_ERROR,
  payload: error
});

export const fetchOrgProfileSuccess = (orgProfile) => ({
  type: ActionTypes.FETCH_ORGPROFILE_SUCCESS,
  payload: orgProfile
});

// NETWORK ACTIONS

export function fetchNetwork() {
  return dispatch => {
      dispatch(fetchNetworkPending());

      axios.get('/org/custorgs')
      .then(function(res) {
        dispatch(fetchNetworkSuccess(res.data.data))
      })
      .catch(function(err) {
        dispatch(fetchNetworkError(err));
      })
 }
}

export const fetchNetworkPending = () => ({
  type: ActionTypes.FETCH_NETWORK_PENDING
});

export const fetchNetworkError = (error) => ({
  type: ActionTypes.FETCH_NETWORK_ERROR,
  payload: error
});

export const fetchNetworkSuccess = (org) => ({
  type: ActionTypes.FETCH_NETWORK_SUCCESS,
  payload: org
});

export const updateStripeBillingQty = (orgid) => {
  return dispatch => {

    axios.get('/org/org/' + orgid) // Pull org for stripecusid
    .then(function (res) {
      let org = res.data.data
      axios.get('/user/protect/users/' + orgid) // Pull org users for newqty
      .then(function(resusrs) {

        let newqty = resusrs.data.data.filter((u) => u.active && !u.email.endsWith('@getcanveo.com')).length

        // Do not update Stripe for AppSumo / Free Trial customers adding a second user without a Stripe Subscription
        // changed on Jan/27 2022
        if(newqty > 2 && org.stripecusid !== undefined && org.stripecusid !== null && org.stripecusid !== '' && ['custE', 'legalOrg'].includes(org.clientType)) {
        //if(newqty > 2 || (org.stripecusid !== undefined && org.stripecusid !== null && org.stripecusid !== '') || org.clientType !== 'custB') {

          axios.get('/billing/stripe-customer/' + org.stripecusid)
          .then(function(rescus) {
            //let stripepm = rescus.data.data.payment_method_id;
            let stripeprods = rescus.data.data.products;
            let sub = rescus.data.data.subs[0];
            let country = rescus.data.data.address !== undefined && rescus.data.data.address !== null && rescus.data.data.address.country !== undefined ?
              rescus.data.data.address.country : 'GB'

            let tier = 
              org.clientType === 'custS' ? 'Starter' : 
              org.clientType === 'custB' ? 'Business' : 
              org.clientType === 'cust' ? 'Business Premium' : 'Other';

            if(tier !== 'Other') {

              // Define current situation as per Stripe
              let spBase = null, spUser = null
              stripeprods.forEach((sp) => {
                prices.forEach((p) => {
                  if(p.currencies.filter((c) => c.stripeid === sp.price || c.stripeid_test === sp.price)[0] !== undefined){
                    spBase = sp;
                  } else if (p.currencies.filter((c) => c.stripeuid === sp.price || c.stripeuid_test === sp.price)[0] !== undefined) {
                    spUser = sp;
                  }
                })
              })

              // Define current situation as per Canveo
              let price = prices.filter((p) => p.custType === tier)[0]

              let isProd = (window.location.href.indexOf("getcanveo.com") > -1 && window.location.href.indexOf("test.getcanveo.com") === -1) || window.location.href.indexOf('canveo.oa.r.appspot.com') > -1

              let cvBase = price !== undefined && price !== null && price.currencies !== undefined && price.currencies.filter((c) => c.ccy === getCcyByCountry(country).ccy)[0] && isProd ?
                              price.currencies.filter((c) => c.ccy === getCcyByCountry(country).ccy)[0].stripeid : 
                          price !== undefined && price !== null && price.currencies !== undefined && price.currencies.filter((c) => c.ccy === getCcyByCountry(country).ccy)[0] && !isProd ?
                              price.currencies.filter((c) => c.ccy === getCcyByCountry(country).ccy)[0].stripeid_test : null
              let cvUser = price !== undefined && price !== null && price.currencies !== undefined && price.currencies.filter((c) => c.ccy === getCcyByCountry(country).ccy)[0] && isProd ?
                              price.currencies.filter((c) => c.ccy === getCcyByCountry(country).ccy)[0].stripeuid :
                          price !== undefined && price !== null && price.currencies !== undefined && price.currencies.filter((c) => c.ccy === getCcyByCountry(country).ccy)[0] && !isProd ?
                              price.currencies.filter((c) => c.ccy === getCcyByCountry(country).ccy)[0].stripeuid_test : null

              let subItems = []
              // Start comparing and updating
              if(cvBase !== null && cvUser !== null) {

                // Comparing and acting on the base subscription
                if(spBase === undefined || spBase === null) { // There is no base subscription item yet, create base subscription item

                  subItems.push({
                    type: 'create',
                    price: cvBase,
                    quantity: 1,
                  })

                } else if (spBase.price !== cvBase) { // There is a base that needs to be updated, update base subscription item with new base

                  subItems.push({
                    type: 'update',
                    id: spBase.si,
                    price: cvBase,
                    quantity: 1,
                  })

                }

                // Comparing and acting on the additional user subscription
                if((spUser === undefined || spUser === null) && price.usersBase < newqty) { // There is no user subscription item yet and there should be one, create user subscription item

                  subItems.push({
                    type: 'create',
                    price: cvUser,
                    quantity: Math.max(0,newqty - price.usersBase)
                  })
    
                } else if (spUser !== undefined && spUser !== null && 
                  (spUser.price !== cvUser || spUser.qty !== newqty - price.usersBase)) { // There is a user s.i. that needs to be updated, update user subscription item with new user s.i. and price

                  subItems.push({
                    type: 'update',
                    id: spUser.si,
                    price: cvUser,
                    quantity: Math.max(0,newqty - price.usersBase)
                  })

                }
              }

              if(subItems.length > 0) {
                axios.put('/billing/protect/updatesubscription', {
                  sub: sub,
                  subItems: subItems,
                })
              }
            }
          }).catch(function(err) { dispatch(fetchUserOrgError(err)); })
        }
      }).catch(function(err) { dispatch(fetchUserOrgError(err)); })
    }).catch(function (err) { dispatch(fetchUserOrgError(err)); })
  }
};

// ***** ORG CONFIG ACTIONS *****
/*
export function fetchOrgConfigsByOrgID(orgid) {
  return dispatch => {
    dispatch(fetchOrgConfigsPending());
    axios.get('/orgconfig/orgconfig/' + orgid)
    .then(function(res) { dispatch(fetchOrgConfigsSuccess(res.data.data)); })
    .catch(function(err) { dispatch(fetchOrgConfigsError(err)); })
  }
}

export const fetchOrgConfigsPending = () => ({
  type: ActionTypes.FETCH_ORGCONFIGS_PENDING
});

export const fetchOrgConfigsError = (error) => ({
  type: ActionTypes.FETCH_ORGCONFIGS_ERROR,
  payload: error
});

export const fetchOrgConfigsSuccess = (orgConfigs) => ({
  type: ActionTypes.FETCH_ORGCONFIGS_SUCCESS,
  payload: orgConfigs
});

export const fetchOrgConfigsDone = () => ({
  type: ActionTypes.FETCH_ORGCONFIGS_DONE
});

export const createOrgConfig = (orgID, configType, module, paramID, clauseTypeLabel, config) => {
  return dispatch => {
    dispatch(fetchOrgConfigsPending())
    axios.post('/orgconfig/orgconfig', {
      orgID: orgID,
      configType: configType,
      module: module,
      paramID: paramID,
      clauseTypeLabel: clauseTypeLabel,
      config: config,
    })
    .then(function (res) { // got a msg from the server
      if(res.data.success) { // successfully added
        dispatch(addOrgConfig(res.data.data));
        dispatch(fetchOrgConfigsDone())
      } else { // something went wrong while adding
        dispatch(fetchOrgConfigsError(res.message));
      }
    })
    .catch(function (err) { // err in the server request
      dispatch(fetchOrgConfigsError(err))
    })       
  }
};

export const addOrgConfig = (orgConfig) => ({
  type: ActionTypes.ADD_ORGCONFIG,
  payload: orgConfig
});

export const updateOrgConfig = (ocid, module, paramID, clauseTypeLabel, config) => {
  return dispatch => {
    
    axios.put('/orgconfig/orgconfig/' + ocid, {
        module: module, 
        paramID: paramID, 
        clauseTypeLabel: clauseTypeLabel, 
        config: config,
    })
    .then(function (res) { // success
      // succesful update - you will get the updated cert
      dispatch(updOrgConfig(res.data.data))
    })
    .catch(function (err) { // err
      dispatch(fetchOrgConfigsError(err))
    })       
  }
};

export const updOrgConfig = (orgConfig) => ({
  type: ActionTypes.UPDATE_ORGCONFIG,
  payload: orgConfig
});
*/

// ORG LABEL ACTIONS

export function fetchOrgLabelsByOrgID(orgid) {
  return dispatch => {
    dispatch(fetchOrgLabelsPending());
    axios.get('/orglabel/orglabel/' + orgid)
    .then(function(res) { dispatch(fetchOrgLabelsSuccess(res.data.data)); })
    .catch(function(err) { dispatch(fetchOrgLabelsError(err)); })
  }
}

export const fetchOrgLabelsPending = () => ({
  type: ActionTypes.FETCH_ORGLABELS_PENDING
});

export const fetchOrgLabelsError = (error) => ({
  type: ActionTypes.FETCH_ORGLABELS_ERROR,
  payload: error
});

export const fetchOrgLabelsSuccess = (orgLabels) => ({
  type: ActionTypes.FETCH_ORGLABELS_SUCCESS,
  payload: orgLabels
});

export const fetchOrgLabelsDone = () => ({
  type: ActionTypes.FETCH_ORGLABELS_DONE
});

export const createOrgLabel = (orgID, name, desc, modules, deactive, creationBy, applyTo) => {
  // applyTo is an object { type: rel/agr, id: relID or agrID }
  return dispatch => {
    let creationDate = new Date().toISOString();
    dispatch(fetchOrgLabelsPending())
    axios.post('/orglabel/orglabel', {
      orgID: orgID,
      name: name,
      desc: desc,
      modules: modules,
      deactive: deactive,
      creationBy: creationBy,
      creationDate: creationDate,
      lastUpdateBy: creationBy,
      lastUpdateDate: creationDate,
    })
    .then(function (res) { // got a msg from the server
      if(res.data.success) { // successfully added
        if(applyTo !== undefined && applyTo !== null && applyTo.type !== undefined) {
          axios.post('/orglabel/orglabellink', {
            orgID: orgID,
            orgLabelID: res.data.data._id,
            relID: applyTo.type === 'rel' ? applyTo.id : '',
            agrID: applyTo.type === 'agr' ? applyTo.id : '',
          }).then(function(resol) {
  
            dispatch(addOrgLabel(res.data.data));
            dispatch(fetchOrgLabelsDone())
  
          }).catch(function (err) { // err in the server request
            dispatch(fetchOrgLabelsError(err))
          })
        } else { // Just create the orglabel, no further application
          dispatch(addOrgLabel(res.data.data));
          dispatch(fetchOrgLabelsDone())
        }
        
      } else { // something went wrong while adding
        dispatch(fetchOrgLabelsError(res.message));
      }
    })
    .catch(function (err) { // err in the server request
      dispatch(fetchOrgLabelsError(err))
    })       
  }
};

export const addOrgLabel = (orgLabel) => ({
  type: ActionTypes.ADD_ORGLABEL,
  payload: orgLabel
});

export const updateOrgLabel = (olid, name, desc, modules, deactive, lastUpdateBy) => {
  return dispatch => {
    let lastUpdateDate = new Date().toISOString();

    axios.put('/orglabel/orglabel/' + olid, {
      name: name,
      desc: desc,
      modules: modules,
      deactive: deactive,
      lastUpdateBy: lastUpdateBy,
      lastUpdateDate: lastUpdateDate,
    })
    .then(function (res) { // success
      // succesful update - you will get the updated cert
      dispatch(updOrgLabel(res.data.data))
    })
    .catch(function (err) { // err
      dispatch(fetchOrgLabelsError(err))
    })       
  }
};

export const updOrgLabel = (orgLabel) => ({
  type: ActionTypes.UPDATE_ORGLABEL,
  payload: orgLabel
});

export const deleteOrgLabel = (olid) => {
  return dispatch => {
    axios.delete('/orglabel/orglabel/' + olid)
    .then(function(res) {
      dispatch(removeOrgLabel(olid));
    })
    .catch(function(err) {
      dispatch(fetchOrgLabelsError(err))
    })
  }
}

export const removeOrgLabel = (olid) => ({
  type: ActionTypes.REMOVE_ORGLABEL,
  payload: olid
});