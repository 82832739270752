import React, { useEffect } from 'react';
import { randomString as generateRandomString, getRecipientModule } from '../utils'
import { Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, 
         Grid, IconButton, TextField, Slide, Typography,
         makeStyles } from '@material-ui/core';
import { InformationalTooltip } from '.';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faThumbsUp, faSignature } from "@fortawesome/free-solid-svg-icons";  

import defaultLogo from '../assets/img/defaultlogo.png';

const axios = require('axios');
const dayjs = require('dayjs')

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = (props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h6" style={{fontWeight: '700'}}>{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" style={{position: 'absolute', right: '8px', top: '10px'}} onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} style={{fontSize: '20px', padding: '0px 3px 0px 3px'}} />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

const DialogAgrActivity = (props) => {

    const useStyles = makeStyles(theme => ({
        cancelButton: {
            marginRight: 'auto',
        },
        mlittle: {
            marginTop: '4px',
            marginBottom: '4px',
        },
        datestamp: {
            display: 'block',
            fontSize: '13px',
            margin: '9px 0px 9px 0px',
            color: theme.palette.grey[800],
        },
        commentAuthor: {
            margin: '9px 0px 0px 0px',
            fontWeight: '700',
            fontSize: '13px',
            color: theme.palette.grey[900],
        },
        commentPrimary: {
            borderRadius: '0px',
            borderLeft: '4px solid ' + theme.palette.primary.main,
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            border: '1px solid' + theme.palette.grey[400],
            padding: '10px 15px 10px 15px',
            margin: '6px 0px 10px 0px',
            display: 'block',
            fontWeight: '300',
            fontSize: '15px',
            color: theme.palette.grey[900],
        },
        commentSecondary: {
            borderRadius: '0px',
            borderLeft: '4px solid ' + theme.palette.secondary.main,
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            border: '1px solid' + theme.palette.grey[400],
            padding: '10px 15px 10px 15px',
            margin: '10px 0px 10px 0px',
            display: 'block',
            fontWeight: '300',
            fontSize: '15px',
            color: theme.palette.grey[900],
        },
        avatarMiniLogo: {
            height: '40px',
            width: '40px',
        },
        logNote: {
            display: 'block',
            margin: '9px 0px 9px 0px',
            fontSize: '14px',
            color: theme.palette.grey[900],
            fontWeight: '400',
        },
        historyGrid: {
            [theme.breakpoints.only('xs')]: { width: '320px', },
            [theme.breakpoints.only('sm')]: { width: '480px', },
            [theme.breakpoints.only('md')]: { width: '480px', },
            [theme.breakpoints.up('lg')]: { width: '480px', },
        },
        closeIconB: {
            position: 'absolute', 
            left: 'auto', 
            right: '30px', 
            top: '90px'
        },
        successIcon: {
            color: theme.palette.primary.main,
            fontSize: '26px',
            marginBottom: '20px',
        }
    }));
    const classes = useStyles();

    const [agrHistory, setAgrHistory] = React.useState(null)
    const [addComment, setAddComment] = React.useState('')
    const [msgSent, setMsgSent] = React.useState(false);  

    useEffect(() => {
        if(props.open){

            axios.get('/logging/protect/albyagrid/' + props.agrID) // Pull history on an agreement level
            .then(function(resl) {
                let hist = []
                resl.data.data
                .filter((l) => ['agrNewName', 'agrRequested', 'agrRequestRejected', 'agrRequestAccepted', 'agrSentToCounterparty',
                                'agrMarkedReadyForSignature', 'agrExecutionFlowStarted', 'agrExecutionFlowCanceled', 'agrSigned', 'agrViewed',
                                'agrChangesApproved', 'agrRetrieved', 'agrFullyExecuted', 'agrVersionRestored', 'clDeleted', 'pdfChanged'].includes(l.logType))
                .filter((l) => !l.isInternal || l.orgID === props.user.orgID)
                .forEach((l) => { hist.push({id: l._id, type: 'log', logType: l.logType, note: l.logDesc, orgID: l.orgID, creationDate: l.creationDate}) })

                axios.get('/comment/comments/' + props.agrID) // Pull all comments on an agreement level
                .then(function(resc) {
                resc.data.data
                .filter((c) => c.commentType === 'agr')
                .forEach((c) => { hist.push({id: c._id, type: 'comm', logType: '', note: c.comment, orgID: c.orgID, creationDate: c.creationDate, creationBy: c.creatorDisplayName}) })
                setAgrHistory(hist)

                }).catch(function(err) { console.log(err) })
            }).catch(function(err) { console.log(err) })
            
        }
    }, [props.open, props.agrID, props.user.orgID])

    const handleSendMessage = () => {
        if(addComment !== undefined && addComment !== null && addComment.length > 1) {

            if(props.agrStatus !== 'Draft' && props.parentAgrStatus !== 'Draft') {
                // Pull the collabs for the agrID - agrID may be the parentAID (if applicable)
                axios.get('/agr/agr/' + props.agrID) // Need to get the relevant Agreement
                .then(function(resag) {
                    resag.data.data.contributors.forEach((c) => {
                        if(c.uid !== props.user._id) {
        
                            let m = (props.user.orgID === props.rel.primaryOrgID && c.entity === "primary") || 
                                    (props.user.orgID === props.rel.secondaryOrgId && c.entity === "secondary") ? props.module : props.cptyModule
                            
                            props.createNotification(
                                'Agreement comment', // notificationType
                                'Comment on agreement', // title
                                props.user.displayName + ' commented on the ' + props.agrFullName, // description
                                '/core/' + m + '/agreement/' + props.rid + '/' + props.agrID, // linkTo 
                                false, // isRead
                                c.uid, // uid
                            )
                            
                            let baseURL = ['https://getcanveo.com', 'https://www.getcanveo.com'].includes(window.location.origin)  ? 'https://app.getcanveo.com' : window.location.origin
                            let individualizedlink = baseURL + '/network/canveo/' + props.rel.customURL + '/' + c.uid + '/' + props.agrID;

                            let linkmod = 
                                c.role === 'Counterparty' ? 
                                    'counterparty' : 
                                c.orgID === props.user.orgID ?
                                    getRecipientModule(props.user, c, props.module, props.rel) :
                                    props.cptyModule

                            let relpagelogin = baseURL + '/core/loginp/_a/' + c._id + '/' + linkmod + '/' + props.rid + '/' + props.agrID + '/agractivity_';

                            let isCustomLink = props.rel !== undefined && props.rel !== null && props.rel.customURL !== undefined && props.rel.customURL !== null && props.rel.customURL.length > 10
                            let loginParagraph = ((isCustomLink && ((c.entity === 'primary' && !['Subsidiary','LegalClient'].includes(props.primaryCpty.entityType)) || 
                                                                (c.entity === 'secondary' && !['Subsidiary','LegalClient'].includes(props.secondaryCpty.entityType)))) ?  
                            `<p>You can access the agreement through your unique relationship page.</p>` :
                            `<p>You can access the agreement by logging in to your unique relationship page.</p>`)

                            let buttonName = "View Agreement"

                            let buttonLink = (isCustomLink && ((c.entity === 'primary' && !['Subsidiary','LegalClient'].includes(props.primaryCpty.entityType)) || 
                                             (c.entity === 'secondary' && !['Subsidiary','LegalClient'].includes(props.secondaryCpty.entityType)))) ?
                                                individualizedlink :
                                                relpagelogin
            
                            axios.post('/mail/replyonagreement', {
                                recipient: c.email,
                                creatorFullName: props.user.displayName,
                                agrFullName: props.agrFullName,
                                primaryCptyName: props.primaryCpty.legalName,
                                secondaryCptyName: props.secondaryCpty.legalName,
                                comment: addComment,
                                loginParagraph: loginParagraph,
                                buttonName: buttonName,
                                buttonLink: buttonLink,
                            })
                        }
                    })


                }).catch(function(err) { console.log(err) })
            }
            // post comment to agr
            props.createComment(
                true, // FAF
                props.user._id, // creatorID
                props.user.displayName, // creatorDisplayName
                props.user.orgID, // orgID - take primary, otherwise take user orgID if applicable, otherwise empty
                props.user.orgID === props.rel.primaryOrgID ? 
                    props.primaryCpty._id : props.secondaryCpty._id, // entityID
                addComment, // comment
                'agr', // commentType
                props.agrID, // agrID
                '', // singleClauseID
                '', // ticketID 
                props.rid, // relID
            )
            // Add c to agrHistory.
            let creationDate = new Date().toISOString();
            setAgrHistory(agrHistory => [...agrHistory, 
                {id: generateRandomString(20), type: 'comm', note: addComment, orgID: props.user.orgID, creationDate: creationDate, creationBy: props.user.displayName}]);
            setMsgSent(true);
            setAddComment('');
        }
    }

    const handleCommentChange = (e) => {
        setAddComment(e.target.value)
    }

    const closeDialog = () => {
        props.parentCallToClose()
    }

    return (
        <Dialog
        open={props.open}
        scroll="paper"
        TransitionComponent={Transition}
        keepMounted
        onClose={closeDialog}
        fullWidth={true}
        maxWidth="sm"
        //style={{maxWidth: '600px'}}
        >
        <DialogTitle onClose={closeDialog}>
            Agreement Activity and Comments&nbsp;&nbsp;<InformationalTooltip msg={"This view shows an agreement level audit log.\n\nYou can send a message to your counterparty collaborators, the notificiation email will include a link to access the agreement."} />
        </DialogTitle>

        <DialogContent id="msgContainer">

        <Box align="center" mb={3}>

            {agrHistory === null ?
            <Box mt={3}>
                <CircularProgress size={54} disableShrink />
            </Box>
            :
            <>
            <Box mt={3} mb={agrHistory !== null && agrHistory.length > 0 ? 6 : 5} className={classes.historyGrid}>

            {!msgSent && props.agrStatus !== 'Draft' ?
            <>
            <Box className={classes.boxSizer} align="left">
                <TextField
                variant="outlined"
                name="addComment"
                color={props.user.orgID === props.rel.primaryOrgID ? "primary" : "secondary"}
                onBlur={e => handleCommentChange(e)}
                //value={addComment}
                label="Send message"
                placeholder="Send message to the counterparty collaborators..."
                multiline
                fullWidth
                rows={4}
                />
            </Box>
            <Box className={classes.boxSizer} mt={1}>
                <Button fullWidth disableElevation variant="contained" size="small" color={props.user.orgID === props.rel.primaryOrgID ? "primary" : "secondary"}
                onClick={handleSendMessage}>Send message</Button>
            </Box>
            </>
            :props.agrStatus !== 'Draft' ?
            <Box align="center">
                <FontAwesomeIcon icon={faThumbsUp} className={classes.successIcon} />
                <Typography variant="subtitle1" style={{fontWeight: '700'}}>Your message was sent.</Typography>
                <Button color="primary" variant="text" onClick={closeDialog}>Back to Agreement</Button>
                <Button color="primary" variant="text" onClick={e => setMsgSent(false)}>Add another message</Button>
            </Box>
            :''}

            </Box>
            {agrHistory !== null && agrHistory.length > 0 ?
            agrHistory.sort((a,b) => (a.creationDate < b.creationDate) ? 1 : ((b.creationDate < a.creationDate) ? -1 : 0))
            .map((h) => (
                h.type === 'comm' ?
                <Box mt={2} key={h.id} align="center">
                <Box align="left" className={classes.historyGrid}>
                <Grid container direction="row" alignItems="flex-start" justify="flex-start" key={h.id}>
                    <Grid item xs={2}>
                    <Avatar alt="Counterparty" className={classes.avatarMiniLogo}
                        src={
                        ['Subsidiary','LegalClient'].includes(props.primaryCpty.entityType) && props.primaryCpty.orgID === h.orgID ? props.primaryCpty.logoURL :
                        !['Subsidiary','LegalClient'].includes(props.primaryCpty.entityType) && props.primaryCpty.parentOrgID === h.orgID ? props.primaryCpty.logoURL :
                        ['Subsidiary','LegalClient'].includes(props.secondaryCpty.entityType) && props.secondaryCpty.orgID === h.orgID ? props.secondaryCpty.logoURL :
                        !['Subsidiary','LegalClient'].includes(props.secondaryCpty.entityType) && props.secondaryCpty.parentOrgID === h.orgID ? props.secondaryCpty.logoURL :
                        defaultLogo} />
                    </Grid>
                    <Grid item xs={4}>
                    <Typography className={classes.datestamp}>{dayjs(h.creationDate).format('MMM D, YYYY h:mm A')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography className={classes.commentAuthor}>{h.creationBy}:</Typography>
                    <span className={h.orgID === props.rel.primaryOrgID ? classes.commentPrimary : classes.commentSecondary}>{h.note}</span>
                    </Grid>
                </Grid>
                </Box>
                </Box>
                :
                <Box mt={2} key={h.id} align="center">
                <Box align="left" className={classes.historyGrid}>
                <Grid container direction="row" alignItems="flex-start" justify="flex-start" key={h.id}>
                    <Grid item xs={2}>
                    {['agrFullyExecuted', 'agrSigned'].includes(h.logType) ? 
                    <Avatar>
                        <FontAwesomeIcon icon={faSignature} /> 
                    </Avatar>
                    :
                    <Avatar alt="Counterparty" className={classes.avatarMiniLogo}
                        src={
                        ['Subsidiary','LegalClient'].includes(props.primaryCpty.entityType) && props.primaryCpty.orgID === h.orgID ? props.primaryCpty.logoURL :
                        !['Subsidiary','LegalClient'].includes(props.primaryCpty.entityType) && props.primaryCpty.parentOrgID === h.orgID ? props.primaryCpty.logoURL :
                        ['Subsidiary','LegalClient'].includes(props.secondaryCpty.entityType) && props.secondaryCpty.orgID === h.orgID ? props.secondaryCpty.logoURL :
                        !['Subsidiary','LegalClient'].includes(props.secondaryCpty.entityType) && props.secondaryCpty.parentOrgID === h.orgID ? props.secondaryCpty.logoURL :
                        defaultLogo} />
                    }
                    </Grid>
                    <Grid item xs={4}>
                    <Typography className={classes.datestamp}>{dayjs(h.creationDate).format('MMM D, YYYY h:mm A')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <span className={classes.logNote}>{h.note}</span>
                    </Grid>
                </Grid>
                </Box>
                </Box>
            ))
            :
            <Box align="center" mt={0} mb={4}>
                <Typography align="center" color="textSecondary">There has been no activity for this agreement yet</Typography>
            </Box>
            }
            </>
            }
            </Box>

        </DialogContent>

        <DialogActions className={classes.mlittle}>
            <Button onClick={closeDialog} variant="text" color="primary" className={classes.cancelButton}>
                Close
            </Button>
        </DialogActions>

    </Dialog>

    )
}

export default DialogAgrActivity