function stripPlain(plain) {
  
    let stripped = plain === undefined || plain === null ? '' :
                   plain
                    .replace('<title>','')
                    .replace('</title>','')
                    .replace('<secTitle>','')
                    .replace('</secTitle>','')
                    .replace('<ssecTitle>','')
                    .replace('</ssecTitle>','')
  
    return stripped;
}
  
export { stripPlain }