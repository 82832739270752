import React from 'react';
import PropTypes from 'prop-types'; // https://reactjs.org/docs/typechecking-with-proptypes.html
import { Link as RouterLink } from 'react-router-dom';
import { Box, Fab, Slide, Typography, makeStyles, useScrollTrigger } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faPen, faPlus, faSearch, faUserPlus, faFileContract, faHandshake, faSave, faArrowLeft, faHandPointUp, faSignature } from "@fortawesome/free-solid-svg-icons";

// HIDE ON SCROLL ***
function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <React.Fragment>
      <Box display={{ xs: 'block', md: 'none' }}>
        <Slide appear={false} direction="up" in={!trigger}>
          {children}
        </Slide>
      </Box>
      <Box display={{ xs: 'none', md: 'block' }}>
        {children}
      </Box>
    </React.Fragment>
    )
}

const FAB = (props) => {

    const useStyles = makeStyles(theme => ({
        fab: {
            [theme.breakpoints.only('xs')]: { width: '40px', height: '40px', bottom: '70px', right: '10px', },
            [theme.breakpoints.only('sm')]: { width: '50px', height: '50px', bottom: '70px', right: '10px', },
            [theme.breakpoints.up('md')]: { width: '60px', height: '60px', bottom: '20px', right: '20px', },        
            margin: 0,
            top: 'auto',
            right: '20px',
            left: 'auto',
            position: 'fixed',
            zIndex: 100,
            '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
            },
        },
        fabCenter: {
            bottom: '32px', 
            left: '0', 
            right: '0', 
            margin: '0 auto',
            position: 'fixed',
            width: '60px', 
            height: '60px',
            zIndex: 1000,
            '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
            },
        },
        fabExtended: {
            [theme.breakpoints.down('sm')]: { height: '50px', bottom: '70px', right: '10px', borderRadius: '25px', padding: '0px 20px 0px 20px',},
            [theme.breakpoints.up('md')]: { height: '60px', bottom: '20px', right: '20px', borderRadius: '30px', padding: '0px 30px 0px 30px', },
            margin: 0,
            top: 'auto',
            left: 'auto',
            position: 'fixed',
            zIndex: 1000,
            '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
            },
            transition: theme.transitions.create("all", {
                easing: theme.transitions.easing.easeIn,
                duration: '0.2s'
            })
        },
        fabExtendedBottom: {
            [theme.breakpoints.down('sm')]: { height: '50px', bottom: '20px', right: '20px', borderRadius: '25px', padding: '0px 30px 0px 30px',},
            [theme.breakpoints.up('md')]: { height: '60px', bottom: '20px', right: '20px', borderRadius: '30px', padding: '0px 30px 0px 30px', },
            margin: 0,
            top: 'auto',
            left: 'auto',
            position: 'fixed',
            zIndex: 1000,
            '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
            },
        },
        fabFont: {
            [theme.breakpoints.down('sm')]: { fontSize: '15px' },
            [theme.breakpoints.up('md')]: { fontSize: '18px' },
            paddingRight: '13px',
            fontWeight: '600',
        }
    }));
    const classes = useStyles();

    return (
        props.routeTo !== undefined ? 
        (
        <HideOnScroll {...props}>
        <Fab
            disabled={props.disabled}
            color={props.iconType === 'save' && !props.extended ? "primary" : "secondary"}
            className={
                //props.extendedBottom ? classes.fabExtendedBottom :
                props.extended ? classes.fabExtended : props.centerShift ? classes.fabCenter : classes.fab}
            component={RouterLink}
            to={props.routeTo}
            variant={props.extended ? "extended" : "round"}
            >
            {props.extended ? 
            (
            <Typography className={classes.fabFont}>
                {props.buttonText}
            </Typography>
            ): '' }
            <FontAwesomeIcon icon={
                //props.iconType === 'dashboard' ? faTachometer : 
                props.iconType === 'pen' ? faPen : 
                props.iconType === 'lock' ? faLock :
                props.iconType === 'plus' ? faPlus :
                props.iconType === 'hand' ? faHandPointUp : 
                props.iconType === 'save' ? faSave :
                props.iconType === 'search' ? faSearch :
                props.iconType === 'handshake' ? faHandshake :
                props.iconType === 'contract' ? faFileContract :
                props.iconType === 'arrowLeft' ? faArrowLeft :
                props.iconType === 'userplus' ? faUserPlus : faPlus} />
        </Fab>
        </HideOnScroll>
        )
        :
        (
        <HideOnScroll {...props}>
        <Fab 
            disabled={props.disabled}
            color={props.iconType === 'save' && !props.extended ? "primary" : "secondary"}
            className={props.extended ? classes.fabExtended : props.centerShift ? classes.fabCenter : classes.fab}
            onClick={props.click}
            variant={props.extended ? "extended" : "round"}
            >
            {props.extended ? 
            (
            <Typography className={classes.fabFont}>
                {props.buttonText}
            </Typography>
            ): '' }
            <FontAwesomeIcon icon={props.iconType === 'pen' ? faPen : 
                                  props.iconType === 'lock' ? faLock :
                                  props.iconType === 'plus' ? faPlus :
                                  props.iconType === 'hand' ? faHandPointUp : 
                                  props.iconType === 'save' ? faSave :
                                  props.iconType === 'search' ? faSearch :
                                  props.iconType === 'handshake' ? faHandshake :
                                  props.iconType === 'contract' ? faFileContract :
                                  props.iconType === 'arrowLeft' ? faArrowLeft :
                                  props.iconType === 'signature' ? faSignature :
                                  props.iconType === 'userplus' ? faUserPlus : faPlus} />
        </Fab>
        </HideOnScroll>
        )
    );
}

FAB.propTypes = {
  routeTo: PropTypes.string,
  iconType: PropTypes.string,
  extended: PropTypes.bool,
  buttonText: PropTypes.string,
};

export default FAB
