import React, { useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Box, CircularProgress, Grid, Typography,
         makeStyles } from '@material-ui/core';

function Logout(props) {

  const useStyles = makeStyles(theme => ({
    clink: {
      color: theme.palette.primary.main,
      fontWeight: '500',
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.secondary.main,
      },
      display: 'inline',
    },

  }));
  const classes = useStyles();  

  const history = useHistory()

  useEffect(() => {

    if(props.user.email === undefined) { history.push('/core/login'); } // Now Forward to login
    
  }, [props.user, history]);

    return(
    <div>

        <Grid container justify="center" alignItems="center" style={{ minHeight: '95vh' }}>
            <Grid container direction="column" justify="center" alignItems="center" spacing={2} style={{ margin: 0, width: '100%', }}>

            <Box mb={5}>
                <CircularProgress size={44} />
            </Box>
        
            <Typography variant="body2">
            You are being {props.cat === 'cp' ? "exited from the application" : "logged out"}
            </Typography>

            <Typography variant="body2">
            If you are not being redirected, <RouterLink to="/core/login" className={classes.clink}>click here</RouterLink>
            </Typography>

            </Grid>
        </Grid>

    </div>
    );
}

export default Logout;