import React from 'react';
import theme from '../theme/theme';
import { randomString as generateRandomString, trunc, getAcronym } from '../utils'
import { ContentBlock, ContentState, CompositeDecorator, convertToRaw,
         Editor, EditorState, Modifier, RichUtils, 
         genKey, getDefaultKeyBinding, KeyBindingUtil } from 'draft-js';
import { Box, Button, Checkbox, IconButton, FormControlLabel, Grid, Typography, Dialog, Fab, LinearProgress,
         List, ListItem, ListItemText, Switch, TextField, Tooltip, withStyles } from '@material-ui/core';  
import { RadioTargetModule, SelectAgrType, TableEditable } from '.'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faArrowLeft, faTimes, 
         faUndo, faChevronCircleLeft, faChevronCircleRight, faCheck, faArrowRight, faTable } from "@fortawesome/free-solid-svg-icons";

const axios = require('axios');

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#f3f3f3',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

const { hasCommandModifier } = KeyBindingUtil;

class DialogImportDoc extends React.Component {
    constructor(props) {
        super(props);
        const decorator = new CompositeDecorator([
            {
              strategy: ClauseTitleStrategy,
              component: ClauseTitleSpan,
            },
        ]);

        const eState = EditorState.createEmpty(decorator);
        const cState = eState.getCurrentContent();
        const cStateWithClauseTitle = cState.createEntity("CLAUSETITLE", "MUTABLE");
        const clauseTitleKey = cStateWithClauseTitle.getLastCreatedEntityKey();
        
        this.state = {
            editorState: eState,
            startClassification: [],
            firstKey: null,
            clauseTitleKey: clauseTitleKey,
            uploading: { clicked: false, file: null },
            errors: [],
            warnings: [],
            tables: [],
            tableReviewMode: false,
            activeTableIdx: 1,
            viewingTableIdx: null,
            iDoc: {},
            saveClassificationArray: [],
            helpScreen: null,
            clearClassification: { type: null, ents: [] },
            setClassification: { type: null, sel: {} },
            classificationMode: false,
            errorMode: false,
            helpPopup: true,
            newAgrTypes: [],
            newAgrType: null,
            newAgrTypeShortName: null,
            newTemplateReference: 'DEFAULT',
            reviewPage: 0, // 0 => agrType, 1 => Exhibits, 2 => target module
            readyToFinalize: false,
            agrTypeError: null,
            tablePage: 0,
        };

        this.focus = () => this.refs.editor.focus();
        this.onChange = (editorState) => this.setState({editorState});

        this.handleKeyCommand = this._handleKeyCommand.bind(this);
        this.handleBeforeInput = this._handleBeforeInput.bind(this);
        this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
        this.toggleBlockType = this._toggleBlockType.bind(this);
        this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
        this.handlePastedText = this._handlePastedText.bind(this);
        this.getLeadSummary = this._getLeadSummary.bind(this)
        this.getLeadScores = this._getLeadScores.bind(this)
        this.getFirstLeaderAssumptions = this._getFirstLeaderAssumptions.bind(this)
        this.handleCompleteImport = this._handleCompleteImport.bind(this)
        this.errWarnMapping = this._errWarnMapping.bind(this)
        this.handleCorrections = this._handleCorrections.bind(this)
        this.handleFinalize = this._handleFinalize.bind(this)
        this.handleAgrTypeSelection = this._handleAgrTypeSelection.bind(this)
        this.handleExhibitReview = this._handleExhibitReview.bind(this)
        this.handleResetComponent = this._handleResetComponent.bind(this)
        this.handleUploading = this._handleUploading.bind(this)
        this.handleUploadChange = this._handleUploadChange.bind(this)
        this.handleUploadConfirm = this._handleUploadConfirm.bind(this)
        this.handleHelpScreen = this._handleHelpScreen.bind(this)
        this.handleSkipImportToggle = this._handleSkipImportToggle.bind(this)
        this.getEntities = this._getEntities.bind(this)
        this.clearEntity = this._clearEntity.bind(this)
        this.setEntity = this._setEntity.bind(this)
        this.handleAgrTypeSelectChange = this._handleAgrTypeSelectChange.bind(this)
        this.handleAgrTypeShortNameChange = this._handleAgrTypeShortNameChange.bind(this)


        this.handleScrollToBlock = this._handleScrollToBlock.bind(this)
        this.changeTargetModule = this._changeTargetModule.bind(this)
        this.handleCloseDialog = this._handleCloseDialog.bind(this)
        this.changeInvisibleHead = this._changeInvisibleHead.bind(this)
        this.handleReturnFromTable = this._handleReturnFromTable.bind(this)
        this.tableReviewAction = this._tableReviewAction.bind(this)
        this.checkIfTableAlreadyInserted = this._checkIfTableAlreadyInserted.bind(this)
    }

    componentDidUpdate(prevProps) {
      
      let editorState = this.state.editorState;
      let contentState = editorState.getCurrentContent();
      let selectionState = editorState.getSelection();
      let blocksArray = contentState.getBlocksAsArray();

      let entities = this.getEntities(editorState);
      let startKey = selectionState.getStartKey();
      let startOffset = selectionState.getStartOffset();
      let endKey = selectionState.getEndKey();
      let endOffset = selectionState.getEndOffset();

      let cc = { type: null, ents: [] }
      let sc = { type: null, sel: {} }
      
      if(this.state.startClassification !== undefined && this.state.startClassification.length > 0 &&
        blocksArray.length === 1 && blocksArray[0].getType() === 'unstyled' && blocksArray[0].getLength() === 0) { // There is only one block and it's empty and there is a startclassification

        this.handleResetComponent()
        
      } else if(['clauseStartPar1', 'clauseStartPar2', 'clauseStartPar3', 'clauseStartPar4', 'secTitle1', 'secTitle2'].includes(contentState.getBlockForKey(startKey).getType()) && (
        (selectionState.isCollapsed() && entities.filter((e) => e.blockKey === startKey && startOffset >= e.start && endOffset <= e.end)[0] !== undefined) || 
        (!selectionState.isCollapsed() && startKey === endKey && entities.filter((e) => e.blockKey === startKey && (startOffset < e.end && endOffset > e.start)).length === 1))) { 
        // No selection and focus inside the entity OR There was a selection and it contains 1 entity
        let e = selectionState.isCollapsed() ? entities.filter((e) => e.blockKey === startKey && startOffset >= e.start && endOffset <= e.end)[0] :
                !selectionState.isCollapsed() ? entities.filter((e) => e.blockKey === startKey && (startOffset <= e.end && endOffset >= e.start))[0] : null

        if((!['secTitle1', 'secTitle2'].includes(contentState.getBlockForKey(startKey).getType()) || e.entity.type !== 'CLAUSETITLE') &&
          e !== null) { cc = { type: e.entity.type, ents: [e] } }

      } else if(['clauseStartPar1', 'clauseStartPar2', 'clauseStartPar3', 'clauseStartPar4'].includes(contentState.getBlockForKey(startKey).getType()) && 
        !selectionState.isCollapsed() && startKey === endKey && entities.filter((e) => e.blockKey === startKey && (startOffset <= e.end && endOffset >= e.start)).length === 2) {
        // There was a selection and it contains both entities 
        cc = { type: 'BOTH', ents: entities.filter((e) => e.blockKey === startKey && (startOffset <= e.end && endOffset >= e.start)) }
        
      } else if(!selectionState.isCollapsed() && startKey === endKey && 
        entities.filter((e) => e.blockKey === startKey).length < 2) {
        // There was a selection and the block doesnt have one or both classifications yet

        if(entities.filter((e) => e.blockKey === startKey).length === 0 && ['clauseStartPar1', 'clauseStartPar2', 'clauseStartPar3', 'clauseStartPar4'].includes(contentState.getBlockForKey(startKey).getType()) &&
        startOffset === 0) {
          sc = { type: 'CLAUSETITLE', sel: { block: startKey, start: startOffset, end: endOffset } }

        }

      }
      if(cc.type !== this.state.clearClassification.type || (cc.ents[0] !== undefined && this.state.clearClassification.ents[0] === undefined) ||
        (cc.ents[0] === undefined && this.state.clearClassification.ents[0] !== undefined) || (cc.ents[0] !== undefined && this.state.clearClassification.ents[0] !== undefined &&
        cc.ents[0].blockKey !== this.state.clearClassification.ents[0].blockKey)) { this.setState({ clearClassification: cc}) }
      if(sc.type !== this.state.setClassification.type || sc.sel.block !== this.state.setClassification.sel.block ||
         sc.sel.start !== this.state.setClassification.sel.start || sc.sel.end !== this.state.setClassification.sel.end) { this.setState({ setClassification: sc })}
  
    }

    _getEntities(editorState, entityType = null) {
      const content = editorState.getCurrentContent();
      const entities = [];
      content.getBlocksAsArray().forEach((block) => {
        let selectedEntity = null;
        block.findEntityRanges((character) => {
            if (character.getEntity() !== null) {
              const entity = content.getEntity(character.getEntity());
              if (!entityType || (entityType && entity.getType() === entityType)) {
                selectedEntity = {
                  entityKey: character.getEntity(),
                  blockKey: block.getKey(),
                  entity: content.getEntity(character.getEntity()),
                };
                return true;
              }
            }
            return false;
        }, (start, end) => {
          entities.push({ ...selectedEntity, start, end });
        });
      });
      return entities;
    }

    _clearEntity() {
      let editorState = this.state.editorState;
      let contentState = editorState.getCurrentContent();
      let selectionState = editorState.getSelection();

      this.state.clearClassification.ents.forEach((e) => {
        selectionState = selectionState.merge({ anchorKey: e.blockKey, anchorOffset: e.start, focusKey: e.blockKey, focusOffset: e.end, isBackward: false });
        contentState = Modifier.applyEntity(contentState, selectionState, null, ); // Change to regular font
        editorState = EditorState.push(editorState, contentState)
      })
      selectionState = selectionState.merge({ anchorKey: this.state.clearClassification.ents[0].blockKey, anchorOffset: this.state.clearClassification.ents[0].start, focusKey: this.state.clearClassification.ents[0].blockKey, focusOffset: this.state.clearClassification.ents[0].start, isBackward: false });
      editorState = EditorState.forceSelection(editorState, selectionState)      
      this.onChange(editorState);
    }

    _setEntity(type) {
      const editorState = this.state.editorState;
      let contentState = editorState.getCurrentContent();
      let selectionState = editorState.getSelection();

      let s = this.state.setClassification.sel;
      selectionState = selectionState.merge({ anchorKey: s.block, anchorOffset: s.start, focusKey: s.block, focusOffset: s.end, isBackward: false });
      contentState = Modifier.applyEntity(contentState, selectionState, this.state.clauseTitleKey, ); // Change to regular font
      this.onChange(EditorState.push(editorState, contentState));

    }

    _handleCloseDialog() {
      this.handleResetComponent()
      this.props.handleImportClose()
    }

    _errWarnMapping(code) {
      let s = 'An unknown error occured'
      if(code === 'noTitle') { return 'The agreement type was not recognized - select the type' }
      else if(code === 'noClause') { return 'Any Agreement or Exhibit needs at least one clause' }
      else if(code === 'exhibit') { return 'Exhibits need to be imported separately' }
      else if(code === 'moreTitles') { return 'An agreement can only have one Title classification' }
      else if(code === 'signBlock') { return 'Signature blocks are currently appended at the end of the agreement' }
      else if(code === 'unclassified') { return 'Blocks classified as "delete" will be removed' }
      else if(code === 'missingClassification') { return 'Make sure that every block has a classification (or use "delete")' }
      else if(code === 'parNeedsClause') { return 'A clause paragraph or list item needs to be after the start of a new clause' }
      else if(code === 'preambleIssue') { return 'A preamble can only appear before the first (sub)section, table or clause' }
      else if(code === 'titleStart') { return 'Clause titles need to be at the start of the clause' }
      return s
    }

    _handleUploading = () => {
      this.setState({uploading: { clicked: true, file: null },})
    }

    _handleUploadChange = (e) => {
      if(e.target !== undefined && e.target.files !== undefined) {
        this.setState({
          uploading: { 
            clicked: true, 
            file: e.target.files[0], 
          } 
        });
      }
    }

    _handleUploadConfirm = async e => {
      e.preventDefault()
      const formData = new FormData();        
      formData.append('file', this.state.uploading.file, generateRandomString(20)); // appending file
      axios.post('/upload/protect/docx', formData, {
          onUploadProgress: (ProgressEvent) => {
            let progress = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
            this.setState(prevState => ({ uploading: { ...prevState.uploading, progress: progress } }))
          }
      }).then(res => {
          this.handlePastedText(res.data.data.text, '', this.state.editorState)
          this.setState(prevState => ({ uploading: { ...prevState.uploading, clicked: false, url: res.data.data.url } }))
      }).catch(err => console.log(err))
    }

    _handleResetComponent = () => {
      const decorator = new CompositeDecorator([
          {
            strategy: ClauseTitleStrategy,
            component: ClauseTitleSpan,
          },
      ]);
      const eState = EditorState.createEmpty(decorator);
      const cState = eState.getCurrentContent();
      const cStateWithClauseTitle = cState.createEntity("CLAUSETITLE", "MUTABLE");
      const clauseTitleKey = cStateWithClauseTitle.getLastCreatedEntityKey();
        
      this.setState({
        editorState: eState,
        startClassification: [],
        firstKey: null,
        clauseTitleKey: clauseTitleKey,
        errors: [],
        warnings: [],
        tables: [],
        iDoc: {},
        uploading: { clicked: false, file: null },
        saveClassificationArray: [],
        helpScreen: null,
        classificationMode: false,
        tableReviewMode: false,
        activeTableIdx: 1,
        viewingTableIdx: null,
        helpPopup: true,
        newAgrTypes: [],
        newAgrType: null,
        newAgrTypeShortName: null,
        newTemplateReference: 'DEFAULT',
        reviewPage: 0,
        readyToFinalize: false,
        agrTypeError: null,
        tablePage: 0,
      })
    }

    _handleHelpScreen = () => {
      this.setState({
        helpScreen: 'info',
      })
    }

    _handleCorrections() {
      this.setState({
        errors: [],
        warnings: [],
        iDoc: {},
        saveClassificationArray: [],
      })
    }

    _handleExhibitReview() {
      this.setState({reviewPage: 2});
    }

    _handleAgrTypeSelection() {

      if(['new-agr'].includes(this.props.action)) {
        this.handleFinalize()
      } else {
        this.setState({ reviewPage: this.state.iDoc.data.exhibits.length > 0 ? 1 : 2 })
      }

    }

    _handleFinalize() {

      let agrTypesToBeCreated = []

      if(this.state.newAgrType._id === undefined || this.state.newAgrType._id === null || 
        !this.props.agrTypes.some((at) => at._id === this.state.newAgrType._id)){
        agrTypesToBeCreated.push({ agrComponentID: 'mainbody', newAgrType: this.state.newAgrType, newAgrTypeShortName: this.state.newAgrTypeShortName })
      }

      let newExhibits = this.state.iDoc.data.exhibits.filter((e) => !e.skipImport)
      newExhibits.forEach((e) => { 
        e.reference = e.agrTypeID !== undefined && e.agrTypeID !== null && e.agrTypeID !== '' &&
          this.props.orgAgrTemplates.some((oat) => oat.reference !== undefined && oat.reference !== null && 
          oat.reference.trim().toLowerCase() === this.state.newTemplateReference.trim().toLowerCase() && 
          oat.module === this.state.targetModule && oat.agrTypeID === e.agrTypeID) ? 
              e.reference : 
              this.state.newTemplateReference
      })

      let iDoc = {...this.state.iDoc, 
        data: {...this.state.iDoc.data, 
          numberType: this.state.numberType, 
          targetModule: this.state.targetModule,
          reference: this.state.newTemplateReference,
          exhibits: newExhibits,
        } 
      }

      if(iDoc.data.exhibits !== undefined && iDoc.data.exhibits !== null && iDoc.data.exhibits.length > 0 && iDoc.data.exhibits.some((e) => e.newAgrType !== undefined)) {
        iDoc.data.exhibits.forEach((ex) => {
          if(ex.newAgrType._id === undefined || ex.newAgrType._id === null || !this.props.agrTypes.some((at) => at._id === ex.newAgrType._id)){
            agrTypesToBeCreated.push({ agrComponentID: ex.agrComponentID, newAgrType: ex.newAgrType, newAgrTypeShortName: ex.newAgrTypeShortName,
              isDerived: agrTypesToBeCreated.some((agtt) => agtt.newAgrType.inputValue.toLowerCase().trim() === ex.newAgrType.inputValue.toLowerCase().trim()) }) // isDerived: scenario where the same agr type is being added for two or more different exhibits, this only need to be created once
          }
        })
      }

      if(agrTypesToBeCreated.length > 0) {

        let atPushToReducer = []

        agrTypesToBeCreated
        .filter((at) => at.newAgrType !== undefined && at.newAgrType !== null && at.newAgrType.inputValue !== undefined &&  at.newAgrType.inputValue !== null &&  at.newAgrType.inputValue !== '')
        .filter((at) => !at.isDerived).forEach((attbc, i) => {

          axios.post('/agrtype/protect/agrtype', {
            relationshipType: ['b2b', 'partnership', 'people', 'corporate', 'terms'],
            shortName: attbc.newAgrTypeShortName,
            fullName: attbc.newAgrType.inputValue,
            agrPartType: 'Contract',
            orgID: this.props.org._id,
          }).then((res) => { // got a msg from the server

            if(res.data.success) { // successfully added
              
              axios.post('/mail/canveoops', { // Send notification email
                subject: "New Agreement Type created",
                message: `<p>A new Agreement type was created by ` + this.props.user.displayName + ` from ` + this.props.org.legalName + `</p>
                  <p>The new type is: <b>` + res.data.data.fullName[0] + ` (` + res.data.data.shortName + `)</b> for relationship type(s): <b>ALL OF THEM.</b></p>
                  <p>Please evaluate and determine whether the orgID needs to change to CANVEO in order to make it available across subscribers.</p>`,
              })              

              // update agrTypeID inside iDOC
              if(attbc.agrComponentID === 'mainbody') {
                iDoc = {...iDoc, 
                  data: {...iDoc.data, 
                    agrTypeID: res.data.data._id,
                  } 
                }
              } else {
                let newEx = iDoc.data.exhibits;
                let exIdx = newEx.findIndex((e) => e.agrComponentID === attbc.agrComponentID)
                newEx[exIdx].agrTypeID = res.data.data._id
                iDoc = {...iDoc, 
                  data: {...iDoc.data, 
                    exhibits: newEx,
                  } 
                }
              }
              atPushToReducer.push(res.data.data)

              // Check for the newly created AT whethere there is any other new Exhibit that needs to reuse this: ("isDerived")
              agrTypesToBeCreated
              .filter((at) => at.isDerived && at.newAgrType.inputValue.toLowerCase().trim() === attbc.newAgrType.inputValue.toLowerCase().trim())
              .forEach((derivedAT) => {
                // Update the new Exhibit with the new agr type that was created before
                let newEx = iDoc.data.exhibits;
                let exIdx = newEx.findIndex((e) => e.agrComponentID === derivedAT.agrComponentID)
                newEx[exIdx].agrTypeID = res.data.data._id
                newEx[exIdx].reference = genKey() // Also needs to create a random reference for the template as otherwise it's duplicate (since same AT, same module)
                iDoc = {...iDoc, 
                  data: {...iDoc.data, 
                    exhibits: newEx,
                  } 
                }
              })

              if(atPushToReducer.length === agrTypesToBeCreated.filter((at) => !at.isDerived).length) {

                iDoc = {...iDoc, 
                  data: {...iDoc.data, 
                    atPushToReducer: atPushToReducer,
                  } 
                }
                this.props.handleFinalizeImport(iDoc);
                this.handleResetComponent() // re-initialize
              }

            } 

          }).catch((err) => console.log("err creating the agr type", err) )
        })

      } else {

        this.props.handleFinalizeImport(iDoc);
        this.handleResetComponent() // re-initialize

      }
    }

    _handleCompleteImport() {
      const contentState = this.state.editorState.getCurrentContent();
      let converted = convertToRaw(contentState)

      let errors = [], warnings = [], clauses = [], agrTypeID = '', agrTitle = '';
      let newCl = {}, saveClassificationArray = []
      let clauseFound = false, titleFound = false

      let tKey = 0;

      let preamblePossible = true;
      let exhibitStarts = converted.blocks.filter((b) => b.type === 'exhTitle')
      let agrStarts = [converted.blocks[0]].concat(exhibitStarts)

      let agrs = []

      agrStarts.forEach((a, i) => { // For every slice of the agreement
        let soSlice = converted.blocks.findIndex((cb) => cb === a)
        let eoSlice = agrStarts[i + 1] !== undefined ? converted.blocks.findIndex((cb) => cb === agrStarts[i + 1]) : converted.blocks.length
        let slice = converted.blocks.slice(soSlice, eoSlice)

        slice.forEach((b, i) => { // For every block inside the agreement Slice

          if(b.type === 'exhTitle') { preamblePossible = true; }
  
          let titleRange = b.entityRanges.filter((er) => er.key === tKey)[0] !== undefined ? b.entityRanges.filter((er) => er.key === tKey)[0] : null;
  
          let startClause = 
            ['clauseStartPar1','clauseStartPar2','clauseStartPar3', 'clauseStartPar4'].includes(b.type) && titleRange !== null ? // if clause has a title (or only numbering), check if after the title the clause starts with . or :, if so skip that character
              titleRange.offset + titleRange.length + (b.text.startsWith(".",titleRange.offset + titleRange.length) || b.text.startsWith(":",titleRange.offset + titleRange.length) ? 1 : 0) : 0;
          let c = this.state.startClassification.filter((sc) => sc.key === b.key)[0]
  
          if(c !== undefined && b.text === c.s) { // The string was not edited
              c.wasReclassifiedFrom = (b.type !== c.classification ? c.classification : 'n/a');
              c.classification = b.type // update any potential new classification
              saveClassificationArray.push(c)
          }
  
          if(b.type === 'unstyled') { 
            
            errors.push({error: 'missingClassification', string: b.text,  blockKey: b.key})
  
          } else if(!preamblePossible && b.type === 'preamble') {
  
            errors.push({error: 'preambleIssue', string: b.text,  blockKey: b.key})
          
          } else if(b.type === 'exhTitle') { // Title of an Exhibit
    
            let at = this.props.agrTypes.filter((agrt) => (agrt.fullName.some((f) => f.toLowerCase() === b.text.toLowerCase())))[0] !== undefined ?
              this.props.agrTypes.filter((agrt) => (agrt.fullName.some((f) => f.toLowerCase() === b.text.toLowerCase())))[0] : 
              this.props.agrTypes.filter((agrt) => (agrt.fullName.some((f) => f.toLowerCase() === "agreement exhibit")))[0]

            agrTitle = b.text;
            if(at !== undefined) { agrTypeID = at._id }
  
          } else if(b.type === 'agrTitle') { // Title of the Agreement
  
            if(titleFound) { warnings.push({error:'moreTitles',string: b.text, blockKey: b.key}) } // An agreement can only have one Title classification
            else { // Define agreement type for this title
              let at = this.props.agrTypes.filter((at) => (this.state.iDoc !== undefined && this.state.iDoc.data !== undefined && at._id === this.state.iDoc.data.agrTypeID) || (at.fullName.some((f) => f.toLowerCase() === b.text.toLowerCase())))[0]
              agrTitle = b.text;
              if(at !== undefined) { agrTypeID = at._id }
            }
            titleFound = true;
  
          } else if(['secTitle1','secTitle2'].includes(b.type)) { // Preparing a new Section
  
              let title = b.text.substr(startClause).trim()
              if (title[title.length-1] === ".") { title = title.slice(0,-1); } // subtract any dot at the end of the title
              if(newCl.clID !== undefined) { // Push the previously created clause before starting on the new one
                //newCl.clauseTypes = getClauseTypes(this.props.clauseTypes, newCl.title, newCl.clausepar)
                clauses.push(newCl) 
              }
              newCl = { // Create the new clause - if you have it, add with title
                clID: i, 
                title: title.trim(), 
                clausepar: '', 
                clauseTypes: [],
                cat: ['secTitle2'].includes(b.type) ? 'ssec' : 'sec'
              } 
              preamblePossible = false;
              clauseFound = true;
  
          } else if(b.type === 'preamble') {
  
            if(newCl.clID === undefined) {
              newCl = { // Create the new clause
                clID: i, 
                //numberManual: '',
                title: '', 
                clausepar: b.text.trim(), 
                clauseTypes: [], 
                cat: 'preamble'
              }
            } else {
              let cPar = newCl.clausepar;
              newCl = { ...newCl, clausepar: cPar + '\n\n' + b.text }
            }
            clauseFound = true;
  
          } else if(['clauseStartPar1','clauseStartPar2','clauseStartPar3', 'clauseStartPar4'].includes(b.type)) { // Preparing a new clause
  
            let title = (titleRange !== null ? b.text.substr(titleRange.offset, titleRange.length).trim() : '')
            if (title[title.length-1] === ".") { title = title.slice(0,-1); } // subtract any dot at the end of the title
  
            if(newCl.clID !== undefined) { // Push the previously created clause before starting on the new one
              //newCl.clauseTypes = getClauseTypes(this.props.clauseTypes, newCl.title, newCl.clausepar)
              clauses.push(newCl) 
            }

            newCl = { // Create the new clause - if you have it, add with title
              clID: i, 
              title: title, 
              clausepar: b.text.substr(startClause).trim(), 
              clauseTypes: [], 
              cat: 
                ['clauseStartPar1'].includes(b.type) ? 'cl1' : 
                ['clauseStartPar2'].includes(b.type) ? 'cl2' : 
                ['clauseStartPar3'].includes(b.type) ? 'cl3' :
                ['clauseStartPar4'].includes(b.type) ? 'cl4' : 'cl1',
            }
            clauseFound = true; // You have found at least one clause
            preamblePossible = false;
  
          } else if(['clausePar1','clausePar2','clausePar3','clausePar4','list1','list2','list3','list4'].includes(b.type)) { // A continuation of an earlier clause
  
            if(!['cl1','cl2','cl3','cl4','sec','ssec'].includes(newCl.cat)) { errors.push({error: 'parNeedsClause', string: b.text, blockKey: b.key}) }
            else { // Attach paragraphs and lists to the clause
              let cPar = newCl.clausepar
              newCl = { ...newCl, clausepar: cPar + (newCl.clausepar === "" ? '' : ['list1', 'list2', 'list3','list4'].includes(b.type) ? '\n' : '\n\n') + b.text }
            }
 
          } else if(b.type.startsWith('table')) {
            if(newCl.clID !== undefined) { // Push the previously created clause before starting on the new one
              //newCl.clauseTypes = getClauseTypes(this.props.clauseTypes, newCl.title, newCl.clausepar)
              clauses.push(newCl) 
            }
            let idx = parseInt(b.type.substr(5)) - 1

            newCl = { // Create the new clause - if you have it, add with title
              clID: i, 
              title: '', 
              clausepar: '', 
              clauseTypes: [], 
              cat: 'table',
              clauseObject: this.state.tables[idx]
            }
            clauseFound = true;
            preamblePossible = false;

          } else if(['unclassified'].includes(b.type) && warnings.filter((w) => w === 'unclassified')[0] === undefined) { 
            
            warnings.push({error: 'unclassified', string: b.text,  blockKey: b.key}) // Unclassified blocks will be removed 
          }
  
        }) // End of Slice loop
 
        //newCl.clauseTypes = getClauseTypes(this.props.clauseTypes, newCl.title, newCl.clausepar)
        clauses.push(newCl) // final clause needs to be pushed

        if (!clauseFound) { //errors.push({error: 'noClause', string: '', blockKey: a.key}); 
          clauses.push({
            clID: "blank" + i, 
            title: '',
            clausepar: 'Clause to be inserted', 
            clauseTypes: [], 
            cat: 'cl1',
          })
        } // You need at least one clause

        let titleCut = 
            agrTitle.substring(5,12).indexOf('.') > -1 ? agrTitle.indexOf('.') :
            agrTitle.substring(5,12).indexOf(':') > -1 ? agrTitle.indexOf(':') :
            agrTitle.substring(5,12).indexOf(')') > -1 ? agrTitle.indexOf(')') :
            agrTitle.substring(5,12).indexOf('-') > -1 ? agrTitle.indexOf('-') : 
            agrTitle.substring(5,12).indexOf('–') > -1 ? agrTitle.indexOf('–') : -1;
            

        if((agrTitle.toLowerCase().startsWith('exhibit') ||
            agrTitle.toLowerCase().startsWith('schedule') ||
            agrTitle.toLowerCase().startsWith('annex') ||
            agrTitle.toLowerCase().startsWith('appendix')) && (titleCut > 4 && titleCut < 13)) {

          agrTitle = agrTitle.substring(titleCut + 1).trim()

        }

        agrs.push({ // Fill the agreement array with appropriate detail for this slice
          agrComponentID: genKey(),
          agrTypeID: agrTypeID,
          agrTitle: agrTitle,
          reference: i > 0 ? genKey() : null,
          clauses: clauses,
        })
        clauses = []; // Reinitialize key variables
        agrTypeID = ''; 
        agrTitle = '';
        newCl = {};
        clauseFound = false;

      }) // End of Agreement loop

      let iDoc = { 
          data: {
              agrTypeID: agrs[0].agrTypeID,
              agrTitle: agrs[0].agrTitle,
              clauses: agrs[0].clauses,
              exhibits: agrs[1] !== undefined ? agrs.slice(1) : [], // exhibits
              //numberType: this.state.numberType,
              docURL: "copy-and-paste",
          }, 
          message: "Doc imported!", 
          success: true
      }
      
      let newAT = iDoc.data !== undefined && iDoc.data.agrTypeID !== undefined && iDoc.data.agrTypeID !== '' && 
        this.props.agrTypes.filter((at) => at._id === iDoc.data.agrTypeID)[0] !== undefined ?
            this.props.agrTypes.filter((at) => at._id === iDoc.data.agrTypeID)[0] : null
      
      if(saveClassificationArray.length === 0) {
        saveClassificationArray.push({ filler: true })
      }

      if(['new-version'].includes(this.props.action) && errors.length === 0) {
        // For new-version (New Version import AND Offline Signing upload) go straight to finalize if there are no errors
        this.setState({
          errors: errors,
          warnings: warnings,
          errorMode: false,
          iDoc: iDoc,
          saveClassificationArray: saveClassificationArray,
          newAgrType: newAT,
        }, () => this.handleFinalize())

      } else {
        this.setState({
          errors: errors,
          warnings: warnings,
          errorMode: errors.length > 0 ? true : false,
          iDoc: iDoc,
          saveClassificationArray: saveClassificationArray,
          newAgrType: newAT,
          /*readyToFinalize: false,*/
          reviewPage: 0,
        })
      }
    }

    _getLeadScores(type, variant, leadsArray, agrSlice) {

      let shortOnes = 0, /*listYes = 0, listNo = 0,*/ level1Yes = 0, level1No = 0;
      let containsTOC = agrSlice.some((as) => as.tocIndicator === 1)
      
      leadsArray.forEach((sca) => {
        // PREPARE SECTION SCORE
        if(sca.stringLength < 70) { shortOnes = shortOnes + 1 }

        // PREPARE LEVEL 1 SCORE
        let frequence = containsTOC ? 2 : 1
        if(leadsArray.filter((subc) => subc.s.substr(0, subc.endPositionOfLead) === sca.s.substr(0, sca.endPositionOfLead)).length > frequence) {
          level1No = level1No + 1;
        } else { level1Yes = level1Yes + 1 }
      })

      return {
        sectionScore: shortOnes / leadsArray.length,
        level1Score: 
          (level1Yes / (level1Yes + level1No) * 0.4) + // 40% unique
          ((Math.min(leadsArray.length, 10) / 10) * 0.4) + // 40% having 10 items
          (type === 'numeric' ? 0.2 : 0) // 20% being numeric
      }
    }

    _getFirstLeaderAssumptions(agrSlice) {

      let firstLeaderAssumptions = []

      // Check if there is a keyword based section/first lead
      if(agrSlice.filter((ca) => ca.includesSectionKeywordAtTheStart === 1).length > 1 &&
      agrSlice.filter((ca) => ca.includesSectionKeywordAtTheStart === 1)[0].s.includes("1") &&
      agrSlice.filter((ca) => ca.includesSectionKeywordAtTheStart === 1)[1].s.includes("2")) {
        firstLeaderAssumptions.push({
          key: genKey(),
          type: "keyword", 
          variant: "keyword",
          sectionScore: 1.01,
          level1Score: 1.01,
        })
      }

      // Loop through variants (2 brackets, 1 bracket, 0 brackets) and types (numeric, alpha, roman)
      ['twobrackets', 'onebracket', 'nobrackets', 'space'].forEach((variant) => {
        let numericFirstLeads = agrSlice.filter((ca) => ca.leadFirst !== null && ca.leadSecond === null && ca.leadFirst.isNumeric && ca.leadFirst.variant === variant);
        if(numericFirstLeads.length > 0) {
          let scores = this.getLeadScores('numeric', variant, numericFirstLeads, agrSlice)
          firstLeaderAssumptions.push({
            key: genKey(),
            type: "numeric", 
            variant: variant, 
            sectionScore: scores.sectionScore,
            level1Score: scores.level1Score
          })
        }
        let romanFirstLeads = agrSlice.filter((ca) => ca.leadFirst !== null && ca.leadSecond === null && ca.leadFirst.isRoman && ca.leadFirst.variant === variant);
        if(romanFirstLeads.length > 0) {
          let scores = this.getLeadScores('roman', variant, romanFirstLeads, agrSlice)
          firstLeaderAssumptions.push({
            key: genKey(),
            type: "roman", 
            variant: variant, 
            sectionScore: scores.sectionScore,
            level1Score: scores.level1Score 
          })
        }
        let alphaFirstLeads = agrSlice.filter((ca) => ca.leadFirst !== null && ca.leadSecond === null && ca.leadFirst.isAlpha && ca.leadFirst.variant === variant);        
        if(alphaFirstLeads.length > 0) {
          let scores = this.getLeadScores('alpha', variant, alphaFirstLeads, agrSlice)
          firstLeaderAssumptions.push({
            key: genKey(),
            type: "alpha", 
            variant: variant, 
            sectionScore: scores.sectionScore,
            level1Score: scores.level1Score
          })
        }
      })

      firstLeaderAssumptions.forEach((fla, i) => {
        if(fla.sectionScore > 0.70 && firstLeaderAssumptions.filter((f) => f.sectionScore >= fla.sectionScore && f.key !== fla.key)[0] === undefined &&
        agrSlice.some((as) => as.leadSecond !== null)) {
          firstLeaderAssumptions[i] = {...firstLeaderAssumptions[i], isSection: true } // Scores at least 0.7 as a section, there is no better one, and there are children
        }
        if(firstLeaderAssumptions.filter((f) => f.level1Score >= fla.level1Score && f.key !== fla.key)[0] === undefined){ 
          firstLeaderAssumptions[i] = {...firstLeaderAssumptions[i], isLevel1: true } // Level 1 is with the highest score
        }
      })
      
      return firstLeaderAssumptions

    }

    _getLeadSummary(inputString) {

      // LEAD TYPES ie. 1. or A. or I.
      let isNumeric = inputString.match(/^(\d{1,2})(\.|\)| )/) || inputString.match(/^\((\d{1,2})\)/)
      let isAlpha = inputString.match(/^([a-zA-Z]{1,1})(\.|\))/) || inputString.match(/^\(([a-zA-Z]{1,1})\)/)
      let isRoman = inputString.match(/^([ivxIVX]{1,3})(\.|\)| )/) || inputString.match(/^\(([ivxIVX]{1,3})\)/)

      // LEAD VARIANT ie. 1. or 1) or (1)
      let variant = 
          (isNumeric || isAlpha || isRoman) && (inputString.substr(0,5).includes("(") && inputString.substr(0,5).includes(")")) ? 'twobrackets' :
          (isNumeric || isAlpha || isRoman) && (inputString.substr(0,5).includes(")")) ? 'onebracket' :
          (isNumeric || isAlpha || isRoman) && (inputString.trim().endsWith(".")) ? 'nobrackets' :
          (isNumeric || isAlpha || isRoman) && (inputString.endsWith(" ")) ? 'space' : "none";

      return {
        isNumeric: isNumeric ? true : false,
        isAlpha: isAlpha ? true : false,
        isRoman: isRoman ? true : false,
        variant: variant,
        lead: inputString.replaceAll('.', '').replaceAll('(','').replaceAll(')','').replaceAll(' ', '')
      }

    }

    _handlePastedText(text, html, editorState) {

      let newSelectionState = editorState.getSelection();
      let newContentState = editorState.getCurrentContent();
      let newEditorState = editorState;
      let blockKey = newSelectionState.getFocusKey();
      let block = newContentState.getBlockForKey(blockKey);
      const entityKey = block.getEntityAt(newSelectionState.getFocusOffset());

      if (!newSelectionState.isCollapsed()) {
        return 'handled' // if holds a selection don't paste anythng
      } else if(this.state.startClassification !== undefined && this.state.startClassification.length > 0 && text !== null){
        // There is already content - therefore paste with the entity that is in Focus
        
        if(block.getType().startsWith('table')) {
          return 'handled'
        } else if(text.split(/\r\n|\r|\n/).length > 1) { // the pasted text consists of multiple paragraphs
          text.split(/\r\n|\r|\n/).reverse().forEach((par) => {
            let p = par.replace(/\t/g,' ').trim() // replace tabs by spaces and trim paragraph

            if(p.length !== 0) {
              newContentState = Modifier.insertText(newContentState, newSelectionState, p, '', entityKey);
              newContentState = Modifier.splitBlock(newContentState, newSelectionState, );
            }
          })
        } else { // the pasted text consists of a single paragraph of text
          newContentState = Modifier.insertText(newContentState, newSelectionState, text, '', entityKey);
        }
        this.onChange(EditorState.push(newEditorState, newContentState));
        return 'handled'

      } else if(text !== null) { // There is no content yet - start classifying from scratch

        // Start with preparing the array with clean text strings, trimmed from empty strings, multiple spaces, tabs etc.
        let parsedArray = [], tables = [], htmlArray = [], cursor = 0;

        text.split(/\r\n|\r|\n/).forEach((t) => { if(t.length > 0) { parsedArray.push(t.replaceAll('\t', ' ').replace(/\s\s+/g, ' ').trim())}} );

        // Check if there are any Tables inside the import, if so : store 
        if(html !== undefined) {

          while(cursor <= html.length) {
            let strippedString = '', pushCursor = 1;
            // Capture any item that is a <hX>, <p> or <table> and strip appropriately
            if ((html.substring(cursor).startsWith('<h') && ['1','2','3','4','5','6'].includes(html.substr(cursor + 2, 1))) ||
              html.substring(cursor).startsWith('<p') || html.substring(cursor).startsWith('<table')) { // this is a paragraph
              let stringType = 
                html.substring(cursor).startsWith('<h') ? 'h' + html.substr(cursor + 2, 1) :
                html.substring(cursor).startsWith('<p') ? 'p' :
                html.substring(cursor).startsWith('<table') ? 'table' : 'unknown'
  
              let endOfFragment = 
                stringType.startsWith('h') ? html.substring(cursor).indexOf('</' + stringType + '>') + 5 : 
                stringType === 'p' ? html.substring(cursor).indexOf('</p>') + 4 :
                stringType === 'table' ? html.substring(cursor).indexOf('</table>') + 8 : html.substring(cursor).indexOf('>') + 1;
  
              strippedString = html.substr(cursor, endOfFragment)
              pushCursor = strippedString.length;
              if(['p'].includes(stringType) || stringType.startsWith('h')) { strippedString = strippedString.replace(/(<([^>]+)>)/gi, "") } // stripping p strings from internal html tags
  
              if(strippedString !== '&nbsp;') { // skip "empty" strings
                strippedString = strippedString
                  .replaceAll('&nbsp;', ' ')
                  .replace(/\s\s+/g, ' ')
                  .replaceAll('&quot;', '"')
                  .replaceAll('&amp;', '&')
                  .replaceAll('&lt;', '<')
                  .replaceAll('&gt;', '>') // replace noise
                htmlArray.push(strippedString);
              }
  
            } else if (!html.substring(cursor).startsWith('<')) { // cursor needs to be pushed to the next opening tag
              pushCursor = html.substring(cursor).indexOf('<') > -1 ? html.substring(cursor).indexOf('<') : 1;
            
            }
  
            cursor = cursor + pushCursor;
  
          }
        }

        htmlArray.filter((ha) => ha.startsWith("<table")).forEach((table,j) => {
          let tableCursor = 0, rowCounter = 0, colCounter = 0, rowSpans = [];
          let rows = [], head = [], cols = [] // target clauseObject

          // You have a new table - now loop through and find new rows
          while(tableCursor <= table.length) {
            
            let pushTableCursor = 1;
            if (table.substring(tableCursor).startsWith('<tr')) {

              let eoTrFragment = table.substring(tableCursor).indexOf('</tr>') + 5
              let trstring = table.substr(tableCursor, eoTrFragment)
              let isHead = (head.length === 0)
              // You have a new row - now loop through the new row and find new cells
              rowCounter = rowCounter + 1
              colCounter = 0
              let trCursor = 0;
              cols = []

              while(trCursor <= trstring.length) {
                let pushTrCursor = 1;

                // Loop through subsequent rowspans that may need to be inserted
                if(rowSpans.some((rs) => rs.row === rowCounter && rs.col === colCounter + 1)) { 
                  while(rowSpans.some((rs) => rs.row === rowCounter && rs.col === colCounter + 1)){ 
                    // Insert a cell for a previously defined rowspan
                    cols.push({ cellKey: genKey(5), value: '' });
                    colCounter = colCounter + 1;
                  }
                }

                if(trstring.substring(trCursor).startsWith('<td')) {
                  colCounter = colCounter + 1;
                  
                  let eoTdFragment = trstring.substring(trCursor).indexOf('</td>') + 5
                  let tdstring = trstring.substr(trCursor, eoTdFragment);

                  if(isHead) { head.push({ colKey: genKey(5), value: tdstring.replace(/(<([^>]+)>)/gi, "").trim() }) }
                  else { cols.push({ cellKey: genKey(5), value: tdstring.replace(/(<([^>]+)>)/gi, "").trim() }) }

                  if(tdstring.includes('colspan=')) { // There is a colspan
                    let csStart = tdstring.indexOf('colspan=') + 8
                    let csEnd = tdstring.substr(csStart).indexOf(' ')
                    let cs = parseInt(tdstring.substr(csStart, csEnd).replace("\"","").trim())
                    let csCounter=1;
                    while(csCounter < cs) { // add additional empty columns at the end for the colspan
                      if(isHead) { head.push({ colKey: genKey(5), value: '' }) }
                      else { cols.push({ cellKey: genKey(5), value: '' }) }
                      csCounter+=1;
                    }
                  } else if(tdstring.includes('rowspan=')) {
                    let rsStart = tdstring.indexOf('rowspan=') + 8
                    let rsEnd = tdstring.substr(rsStart).indexOf(' ')
                    let rs = parseInt(tdstring.substr(rsStart, rsEnd).replace("\"","").trim())
                    let rsCounter = 1;
                    while(rsCounter < rs) { // Insert the rowspan in an array - to be inserted in the next row(s)
                      rowSpans.push({row: rowCounter + rsCounter, col: colCounter})
                      rsCounter+=1;
                    }
                  }

                  pushTrCursor = tdstring.length > 0 ? tdstring.length : 1
                }
                trCursor = trCursor + pushTrCursor;
              }
              if(!isHead) { rows.push({ rowKey: genKey(5), cols: cols }) }

              pushTableCursor = trstring.length > 0 ? trstring.length : 1

            }
            tableCursor += pushTableCursor
          }
          tables.push({ invisibleHead: false, head: head, rows: rows })
        })

        // Initialize characteristics
        let characteristicsArray = [], agrExhibitStarts = []

        // Identify if it's a "broken" PDF where sentences need to be combined
        let longestString = parsedArray.reduce( function (a, b) { return a.length > b.length ? a : b; } );
        let cutStringRatio = parsedArray.filter((pa) => pa.length > 0.75 * longestString.length).length / parsedArray.length

        if(cutStringRatio > 0.5) {
          let newParsedArray = []
          let tempString = ''
          parsedArray.forEach((pa) => { 
            if(pa.length > 0.65 * longestString.length && // is at least 75% of the longest string
              !(pa.trim().endsWith('.') || pa.trim().endsWith(':') || pa.trim().endsWith(';')) // Does not end with dot, colon, semi-colo
            ){
              tempString = tempString === '' ? pa : tempString.trim() + ' ' + pa
            } else {
              newParsedArray.push(tempString === '' ? pa : tempString.trim() + ' ' + pa)
              tempString = ''
            }
          })
          parsedArray = newParsedArray
        }

        // STEP 1. Define characteristics for each individual string
        parsedArray.filter((s) => s !== '').forEach((s, i) => {

          // Determine the Lead (eg: 1.a.1.c) - if any
          let firstLeaderLead = s.match(/^\(?(\d{1,2}|[a-zA-Z]{1,1}|[ivxIVX]{1,3})(\.|\)| )/)
          let leadFirst = firstLeaderLead && firstLeaderLead[0] !== 'o ' ? this.getLeadSummary(firstLeaderLead[0]) : null;
          let secondLeaderLead = s.match(/^\(?(\d{1,2}|[a-zA-Z]{1,1}|[ivxIVX]{1,3})\.(\d{1,2}|[a-zA-Z]{1,1}|[ivxIVX]{1,3})(\.|\)| )/) // e.g. "1.16. text" or "16.4 text"
          let leadSecond = secondLeaderLead ? this.getLeadSummary(secondLeaderLead[0].replace(firstLeaderLead[0],'')) : null
          let thirdLeaderLead = s.match(/^\(?(\d{1,2}|[a-zA-Z]{1,1}|[ivxIVX]{1,3})\.(\d{1,2}|[a-zA-Z]{1,1}|[ivxIVX]{1,3})\.(\d{1,2}|[a-zA-Z]{1,1}|[ivxIVX]{1,3})(\.|\)| )/) // e.g. "1.16.2. text" or "16.4.2 text"
          let leadThird = thirdLeaderLead ? this.getLeadSummary(thirdLeaderLead[0].replace(secondLeaderLead[0],'')) : null
          let fourthLeaderLead = s.match(/^\(?(\d{1,2}|[a-zA-Z]{1,1}|[ivxIVX]{1,3})\.(\d{1,2}|[a-zA-Z]{1,1}|[ivxIVX]{1,3})\.(\d{1,2}|[a-zA-Z]{1,1}|[ivxIVX]{1,3})\.(\d{1,2}|[a-zA-Z]{1,1}|[ivxIVX]{1,3})(\.|\)| )/) // e.g. "1.16.2.6. text" or "16.4.2.6 text"
          let leadFourth = fourthLeaderLead ? this.getLeadSummary(fourthLeaderLead[0].replace(thirdLeaderLead[0],'')) : null
          let endPositionOfLead = 
                fourthLeaderLead ? fourthLeaderLead[0].length :
                thirdLeaderLead ? thirdLeaderLead[0].length :
                secondLeaderLead ? secondLeaderLead[0].length  :
                firstLeaderLead ? firstLeaderLead[0].length : 0

          let includesSectionKeywordAtTheStart = ( // Has a keyword art(icle), sec(tion) or clause AND has a number and is not too long 
                s.toLowerCase().trim().startsWith('sec') || s.toLowerCase().trim().startsWith('clause') || 
                s.toLowerCase().trim().startsWith('art') || s.toLowerCase().trim().startsWith('§')) && 
                s.substr(0,11).match(/\d/) !== null && s.length < 110 ? 1 : 0;

          // Agreement / Exhibit starts
          let includesExhibitAtTheStart = !firstLeaderLead && 
            (s.substring(0,11).toLowerCase().includes('exhibit') || s.substring(0,11).toLowerCase().includes('schedule') ||
             s.substring(0,11).toLowerCase().includes('annex') || s.substring(0,11).toLowerCase().includes('appendix')) ? 1 : 0

          // String classification characteristics
          let hasListIndicator = s.match(/^(\*|-|–|—|•|•|◦|∙|●|○|■)/) || s.match(/^o /) ? 1 : 0 // string starts with a list indicator
          let includesSignBlockAnnouncement = s.toLowerCase().includes('in witness whereof') || s.toLowerCase().includes('in witness thereof') ||
            s.toLowerCase().includes('in witness hereof') || s.toLowerCase().includes('in testimony whereof') || 
            s.toLowerCase().includes('by signing below') ? 1 : 0
          let includesSignBlockHint = s.toLowerCase().includes('signature') || s.toLowerCase().includes('signed') || s.toLowerCase().includes('unterschrift') || 
            s.toLowerCase().includes('name') || s.toLowerCase().includes('title') || s.toLowerCase().includes('date') || s.toLowerCase().includes('role') || s.toLowerCase().includes('place') || 
            s.includes('___') || s.includes('....') || s.includes('…') || ((s.includes('[') && s.includes(']')) || (s.includes('{') && s.includes('}'))) ? 1 : 0;
          let preambleEnds = s.toLowerCase().includes('now, therefore') || s.toLowerCase().includes('now therefore:') ||  
            s.toLowerCase().includes('agreed as follows:') || s.toLowerCase().includes('agree as follows:') ||
            s.toLowerCase().includes('hereby agrees:') || s.toLowerCase().includes('hereby agree that:') ? 1 : 0
          let tocIndicator = s.toLowerCase().trim().startsWith('table of') && 
            (s.toLowerCase().trim().includes('content') || s.toLowerCase().trim().includes('schedule')) && s.trim().length < 20 ? 1 : 0

          // Find the first dot after the lead and count words - this is used to identify clause titles, which typically end with a dot and are short (1~5 words)
          //var str = "scissors";
          var dotSpaces = [];
          for(var j=endPositionOfLead; j<s.substring(endPositionOfLead).length;j++) {
              if (s[j] === "." && s[j + 1] !== undefined && s[j+1] === " " &&
              !(j > 2 && ['e.g.','i.e.'].includes(s.substr(j-3, 4))) && // skip i.e. and e.g.
              !(j > 1 && ['eg.','ie.'].includes(s.substr(i-2, 3))) && // skip ie. and eg.
              !(j > 2 && !isNaN(s[j-3]) && s[j-2] === "." && !isNaN(s[j-1])) // skip 2.1. etc.
              ) { 
                dotSpaces.push(j); 
              }
          }

          let firstDotPositionAfterLead = dotSpaces.length > 0 ? dotSpaces[0] : -1
          //let firstDotPositionAfterLead = 
            //s.substring(endPositionOfLead).indexOf(". ") > -1 ? 
                //(endPositionOfLead + s.substring(endPositionOfLead).indexOf(". ")) : // ideally you find a dot space
            /*s.substring(endPositionOfLead).indexOf(".") > -1 ? (endPositionOfLead + s.substring(endPositionOfLead).indexOf(".")) : -1;*/ // alternatively just find a dot followed by any character as long as it's not a number

          if(firstDotPositionAfterLead > -1 && s.substr(firstDotPositionAfterLead + 1, 1) >= '0' && s.substr(firstDotPositionAfterLead + 1, 1) <= '9'){
            let newFirstDotPositionAfterLead = -1;
            var indices = [];
            for(var counter=0; counter<s.length;counter++) { if (s[counter] === ".") { indices.push(counter); } }
            indices.filter((i) => i > firstDotPositionAfterLead).forEach((newi) => {
              if(newFirstDotPositionAfterLead === -1 && // new dot was not yet found
                (s[newi + 1] === undefined || s.substr(firstDotPositionAfterLead + 1, 1) < '0' || s.substr(firstDotPositionAfterLead + 1, 1) > '9')) {
                newFirstDotPositionAfterLead = newi // this would be an appropriate dot
              }
            })
            firstDotPositionAfterLead = newFirstDotPositionAfterLead;
          }
          let hasOnlyOneDotAtTheEnd = s.substring(endPositionOfLead).trim().match(/\./g) !== null && s.substring(endPositionOfLead).trim().match(/\./g).length === 1 && s.substring(endPositionOfLead).trim().endsWith('.') ? 1 : 0
          let wordsBeforeFirstDot = firstDotPositionAfterLead > 0 ? s.substring(endPositionOfLead, firstDotPositionAfterLead).trim().split(' ').length : s.substring(endPositionOfLead, s.length).trim().split(' ').length

          // Ocassionally, clause titles end with a colon ":"
          let colonBeforeDot = s.indexOf(":") > -1 && s.indexOf(":") < s.indexOf(".") ? 1 : 0;
          let colonPosition = s.indexOf(":") > -1 ? s.indexOf(":") + 0 : -1;

          // Characteristics that can help to find Definitions, such as "quotes" and the word .. means ..
          let startsWithQuote = s.match(/^("|“)/) ? 1 : 0

          let closingQuotePosition = startsWithQuote === 1 && s.match(/^"/) && s.substring(1).indexOf("\"") > -1 ? s.split("\"", 2).join("\"").length : // find pos of 2nd "
                                     startsWithQuote === 1 && s.match(/^"/) && s.indexOf("”") > 0 ? s.indexOf("”") : // find pos of 2nd "
                                     startsWithQuote === 1 && s.match(/^“/) && s.indexOf("”") > 0 ? s.indexOf("”") : 
                                     startsWithQuote === 1 && s.match(/^“/) && s.substring(1).indexOf("\"") > -1 ? s.split("\"", 1).join("\"").length : 0; // Find index of ”
          
          let wordsBeforeMeans = firstLeaderLead === null && s.toLowerCase().includes(' means ') ? s.substring(0, s.toLowerCase().indexOf(' means ')).split(' ').length : -1

          characteristicsArray.push({
            key: genKey(),
            s: s,
            stringLength: s.length,
            stringNumber: i,
            includesExhibitAtTheStart: includesExhibitAtTheStart,
            preambleEnds: preambleEnds,
            includesSectionKeywordAtTheStart: includesSectionKeywordAtTheStart, 
            includesSignBlockAnnouncement: includesSignBlockAnnouncement,
            includesSignBlockHint: includesSignBlockHint,
            leadFirst: leadFirst,
            leadSecond: leadSecond,
            leadThird: leadThird,
            leadFourth: leadFourth,
            endPositionOfLead: endPositionOfLead,
            hasListIndicator: hasListIndicator,
            tocIndicator: tocIndicator,
            startsWithQuote: startsWithQuote,
            wordsBeforeMeans: wordsBeforeMeans,
            colonBeforeDot: colonBeforeDot,
            colonPosition: colonPosition,
            closingQuotePosition: closingQuotePosition,
            firstDotPositionAfterLead: firstDotPositionAfterLead,
            hasOnlyOneDotAtTheEnd: hasOnlyOneDotAtTheEnd,
            wordsBeforeFirstDot: wordsBeforeFirstDot, // or before the end if there is no more dot
          })
        })

        // ***************************************************
        // STEP 2 - DETECT EXHIBIT STARTS
        agrExhibitStarts.push(characteristicsArray[0].key) // Push the start of the first agreement - then detect any additional exhibits
        //let sliceHasToc = false;
        let startOfToc = null, endOfToc = null
        characteristicsArray
        .filter((ca) => (ca.stringNumber > 5 && ca.stringLength < 80 && ca.includesExhibitAtTheStart === 1 && ca.startsWithQuote === 0 && (ca.wordsBeforeMeans < 1 || ca.wordsBeforeMeans > 5)))
        .forEach((ca, i) => {
          if(startOfToc === null || endOfToc === null) {
            startOfToc = characteristicsArray.filter((cha) => cha.stringNumber < ca.stringNumber && cha.tocIndicator === 1)[0] !== undefined ?
                characteristicsArray.filter((cha) => cha.stringNumber < ca.stringNumber && cha.tocIndicator === 1)[0].stringNumber : -1
            endOfToc = startOfToc > -1 && characteristicsArray.filter((cha) => cha.stringNumber >= ca.stringNumber && (
                cha.s.trim().endsWith('.') || cha.s.trim().endsWith(':') || cha.s.trim().endsWith('"') || cha.s.trim().endsWith('”')))[0] !== undefined ?
              characteristicsArray.filter((cha) => cha.stringNumber >= ca.stringNumber && (
                cha.s.trim().endsWith('.') || cha.s.trim().endsWith(':') || cha.s.trim().endsWith('"') || cha.s.trim().endsWith('”')))[0].stringNumber : -1
          }

          if(agrExhibitStarts.length === 1) { // special handling for TOC
            // Only push "Schedule" or "Exhibit" heads when they aren't part of a TOC
            if(startOfToc === -1 || endOfToc === -1 || ca.stringNumber <= startOfToc || ca.stringNumber >= endOfToc) {
              agrExhibitStarts.push(ca.key);
            }

          } else {
            agrExhibitStarts.push(ca.key);
          }
          
        })

        // STEP 3 - for each AGREEMENT / EXHIBIT SLICE
        agrExhibitStarts.forEach((startOfSliceKey, i) => { 
          let startOfSlice = characteristicsArray.findIndex((ca) => ca.key === startOfSliceKey)
          let endOfSlice = agrExhibitStarts[i+1] !== undefined ? 
              characteristicsArray.findIndex((ca) => ca.key === agrExhibitStarts[i+1]) : characteristicsArray.length;
          let agrSlice = characteristicsArray.slice(startOfSlice, endOfSlice)

          // STEP 3a - INITIALIZE THE AGR SLICE CLASSIFICATION ALGO AND PULL THE FIRST LEADER ASSUMPTIONS FOR AGR SLICE
          let classification = 'unclassified';
          let prevStringClassification = 'unclassified';
          let skipLevelJump = false;
          let currentLevel = 0;
          let definitionLevel = null;
          let prevAnnouncesExhibitTitle = false;
          let isTOC = false;
          
          // Determine the first lead assumptions, and where the first lead starts
          let firstLeaderAssumptions = this.getFirstLeaderAssumptions(agrSlice)
          let fla = firstLeaderAssumptions.filter((f) => f.isLevel1)[0] !== undefined ? firstLeaderAssumptions.filter((f) => f.isLevel1)[0] : {}
          let keysOfFirstLeadOfAgrSlice = [], secondLeaderAssumption = {}

          agrSlice
          .filter((as) => (as.includesSectionKeywordAtTheStart && fla.type === 'keyword') || (
            as.leadFirst !== null && as.leadSecond === null && as.leadFirst.variant === fla.variant && 
            ((fla.type === 'alpha' && as.leadFirst.isAlpha) || (fla.type === 'roman' && as.leadFirst.isRoman) || (fla.type === 'numeric' && as.leadFirst.isNumeric))))
          .forEach((as) => {
            keysOfFirstLeadOfAgrSlice.push(as.key);
          })

          let preamblePossible = keysOfFirstLeadOfAgrSlice.length < 1 ? false : true;

          // STEP 3b - CLASSIFICATION ALGORITHM for each string inside the SLICE : CLASSIFY
          agrSlice.forEach((as, asCounter) => {

            // Pre processing before you can start classifying
            prevStringClassification = classification;
            
            currentLevel = // Decide on the current level
              prevStringClassification.endsWith('4') ? 4 : 
              prevStringClassification.endsWith('3') ? 3 : 
              prevStringClassification.endsWith('2') ? 2 : 
              prevStringClassification.endsWith('1') ? 1 : 0

            let currentFirstLeadAssumption = firstLeaderAssumptions.filter((f) => (as.includesSectionKeywordAtTheStart && fla.type === 'keyword') ||
              (as.leadFirst !== null && as.leadSecond === null && as.leadFirst.variant === f.variant &&
              ((f.type === 'alpha' && as.leadFirst.isAlpha) || (f.type === 'roman' && as.leadFirst.isRoman) || (f.type === 'numeric' && as.leadFirst.isNumeric))))[0]

            if(keysOfFirstLeadOfAgrSlice[0] === as.key && !isTOC) { preamblePossible = false; } // This is the First lead, so no longer classify as preamble
            if(keysOfFirstLeadOfAgrSlice.some((kofl) => kofl === as.key)) {
              definitionLevel = null; // re-initialize definitionLevel

              // If lead2 - that's the level 2, otherwise use below
              if(agrSlice.some((a) => a.leadFirst !== null && a.leadSecond !== null)) {
                secondLeaderAssumption = { type: 'secondLead' }
              } else {
                let idx = keysOfFirstLeadOfAgrSlice.findIndex((kofl) => kofl === as.key)
                let endOfSlice = keysOfFirstLeadOfAgrSlice[idx + 1] !== undefined ? agrSlice.findIndex((item) => item.key === keysOfFirstLeadOfAgrSlice[idx + 1]) : agrSlice.length
                let subSlice = agrSlice.slice(asCounter + 1, endOfSlice)
                
                let secondLeaderAssumptions = this.getFirstLeaderAssumptions(subSlice)

                // For the second lead assumption take whichever comes first in the subslice 
                secondLeaderAssumption = {}
                subSlice.forEach((ss) => {
                  if(secondLeaderAssumption.type === undefined && secondLeaderAssumptions.filter((sla) => ss.leadFirst !== null && sla.variant === ss.leadFirst.variant &&
                  ((ss.leadFirst.isAlpha && sla.type === 'alpha') || (ss.leadFirst.isRoman && sla.type === 'roman') || (ss.leadFirst.isNumeric && sla.type === 'numeric')))[0] !== undefined) {
                    secondLeaderAssumption = secondLeaderAssumptions.filter((sla) => ss.leadFirst !== null && sla.variant === ss.leadFirst.variant &&
                      ((ss.leadFirst.isAlpha && sla.type === 'alpha') || (ss.leadFirst.isRoman && sla.type === 'roman') || (ss.leadFirst.isNumeric && sla.type === 'numeric')))[0]
                  }
                })
              }
            }
            
            let isDefinition = ((as.startsWithQuote === 1 && as.closingQuotePosition > 0) || (as.wordsBeforeMeans > 0 && as.wordsBeforeMeans < 6))
            if(isDefinition && definitionLevel === null) { definitionLevel = currentLevel + 1 }
            // End of pre processing

            // AGREEMENT / EXHIBIT title
            // These will be at top of the agreement slice and shorter than 80 chars
            // An Exhibit sometimes is announced over two lines, (line1: "EXHIBIT", line2: The exhibit title)
            if(((as.leadFirst === null && as.stringLength < 80 && asCounter === 0) || prevAnnouncesExhibitTitle) && !isTOC) { // the first string or a previously announced Exhibit title
              if(i === 0) {
                classification = 'agrTitle';
              } else { // Else this may be a title or an announcement ("Exhibit A")
                let testString = as.s;
                testString = testString.replace(/exhibit/ig, '');
                testString = testString.replace(/schedule/ig, '');
                testString = testString.replace(/annex/ig, '');
                testString = testString.replace(/appendix/ig, '');
                if(testString.trim().length < 3 && agrSlice[asCounter+1] !== undefined && 
                agrSlice[asCounter+1].leadFirst === null && agrSlice[asCounter+1].s.length < 80) {
                  prevAnnouncesExhibitTitle = true;
                } else {
                  prevAnnouncesExhibitTitle = false;
                  classification = 'exhTitle'
                }
              } 
            
            // TOC
            } else if (preamblePossible && (as.tocIndicator === 1 || isTOC) && 
            !(as.s.trim().endsWith('.') || as.s.trim().endsWith(':') || as.s.trim().endsWith('"') || as.s.trim().endsWith('”'))) {
              isTOC = true;
              classification = 'unclassified'
            
            // PREAMBLE
            } else if (preamblePossible && (currentFirstLeadAssumption === undefined || !currentFirstLeadAssumption.isLevel1)) {

              classification = 'preamble';
              isTOC = false;

            // NEW SECTION OR NEW CLAUSE
            } else if (
              as.leadFirst !== null || 
              (currentFirstLeadAssumption !== undefined && currentFirstLeadAssumption.type === 'keyword' && as.includesSectionKeywordAtTheStart === 1) ||
              isDefinition
            ) {

              preamblePossible = false;
              classification = 
                isDefinition ? 'clauseStartPar' + definitionLevel :
                as.leadFourth !== null ?  'clauseStartPar4' : 
                as.leadThird !== null ?  'clauseStartPar3' : 
                as.leadSecond !== null ?  'clauseStartPar2' : 
                (currentFirstLeadAssumption !== undefined && currentFirstLeadAssumption.isLevel1) ? 
                    'clauseStartPar1' : 
                (currentFirstLeadAssumption !== undefined && !currentFirstLeadAssumption.isLevel1 &&
                  currentFirstLeadAssumption.variant !== "space") ?
                    'clauseStartPar' + (currentLevel + (skipLevelJump ? 0 : 1)) + "_skipLevelJump" :
                secondLeaderAssumption.variant !== undefined && secondLeaderAssumption.variant === as.leadFirst.variant &&
                ((secondLeaderAssumption.type === 'alpha' && as.leadFirst.isAlpha && !as.leadFirst.isRoman) || 
                 (secondLeaderAssumption.type === 'roman' && as.leadFirst.isRoman) || 
                 (secondLeaderAssumption.type === 'numeric' && as.leadFirst.isNumeric)) ?
                    'clauseStartPar2' :
                currentLevel === 4 ? 'clausePar4' :
                currentLevel === 3 ? 'clausePar3' :
                currentLevel === 2 ? 'clausePar2' : 
                currentLevel === 2 ? 'clausePar1' : 
                    'clauseStartPar1'
                                  
            // CLAUSE PARAGRAPH, LIST ITEM - FALLBACK = NEW CLAUSE
            // clause strings that start without a lead
            } else { 

              classification = 
                as.hasListIndicator && currentLevel > 0 ? ('list' + currentLevel) :
                currentLevel > 0 ? ('clausePar' + currentLevel) :
                'clauseStartPar1';

            }

            let idx = characteristicsArray.findIndex((ca) => ca.key === as.key)

            if(classification.endsWith("_skipLevelJump")) {
              skipLevelJump = true;
              classification = classification.substring(0, classification.length - 14)
            } else if(!classification.startsWith('clausePar') && !classification.startsWith('list')) {
              skipLevelJump = false;
            }
            characteristicsArray[idx] = {...as, 
              classification: classification,
              prevStringClassification: prevStringClassification 
            }

          })

          // ***************************************************
          // STEP 4. FIND AND CLASSIFY ANY SIGN BLOCKS

          if(agrSlice.filter((ca) => ca.includesSignBlockAnnouncement === 1)[0] !== undefined || agrSlice.filter((ca) => ca.includesSignBlockHint === 1).length > 2) {
            let assignSignBlockArray = []
            if(agrSlice.filter((ca) => ca.includesSignBlockAnnouncement === 1)[0] !== undefined){ // Sign Block Announcement
              let start = agrSlice.findIndex((c) => c.includesSignBlockAnnouncement === 1) + 1
              let end = agrSlice.length + 1;

              
              assignSignBlockArray = agrSlice.slice(start, end)

            } else { // Sufficient sign block hints, further analyze
                
              let startOfSearch = agrSlice.map(as => as.leadFirst !== null).lastIndexOf(1)
              startOfSearch = agrSlice.filter((a) => a.leadFirst !== null)[0] !== undefined && agrSlice.filter((a) => a.leadFirst !== null)[agrSlice.filter((a) => a.leadFirst !== null).length - 1] !== undefined ?
                agrSlice.filter((a) => a.leadFirst !== null)[agrSlice.filter((a) => a.leadFirst !== null).length - 1].stringNumber : 999999999
              if(startOfSearch > -1) {
                let signBlockScope = agrSlice.slice(startOfSearch + 1);
                let startSignBlock = false;
                if(signBlockScope.filter((sbs) => sbs.includesSignBlockHint).length > 2) {
                  signBlockScope.forEach((sbs) => {
                    if(startSignBlock) { assignSignBlockArray.push(sbs) }
                    else if(!sbs.s.trim().endsWith('.') && sbs.s.trim().length < 100){ 
                      assignSignBlockArray.push(sbs);
                      startSignBlock = true; 
                    }
                  })
                }
              }
            }

            // Now re-assign to signBlock for appropriate blocks
            assignSignBlockArray.forEach((asba) => {
              let idx = characteristicsArray.findIndex((c) => c.key === asba.key)
              if(idx > -1) { characteristicsArray[idx] = {...characteristicsArray[idx], classification: 'signBlock', level: 1 } }
            })
          }
        })

        // STEP 5A. RUN THROUGH CHARACTERISTICS ARRAY AND UPDATE CLAUSESTARTS TO SECTION OR SUBSECTION WHERE APPLICABLE
        /*
        characteristicsArray.forEach((ca, i) => {
          if(['clauseStartPar1', 'clauseStartPar2'].includes(ca.classification)) {

            let endOfSlice = characteristicsArray.findIndex((c) => c.stringNumber > ca.stringNumber && c.classification === ca.classification)
            let layer2slice = characteristicsArray.slice(i+1, endOfSlice > -1 ? endOfSlice : characteristicsArray.length + 1); // includes layer 3+
            
            if(['clauseStartPar1'].includes(ca.classification) && 
            layer2slice.some((l2s) => l2s.classification === 'clauseStartPar2') && ca.stringLength < 80) {
              ca.classification = 'secTitle1';
            } else if(['clauseStartPar2'].includes(ca.classification) && 
            layer2slice.some((l2s) => l2s.classification === 'clauseStartPar3') && ca.stringLength < 80) {
              ca.classification = 'secTitle2';
            }
          }
        })*/

        let upgradeLayer1 = false;
        characteristicsArray.forEach((ca, i) => {
          if(['clauseStartPar1', 'clauseStartPar2'].includes(ca.classification)) {
            let endOfSlice = characteristicsArray.findIndex((c) => c.stringNumber > ca.stringNumber && c.classification === ca.classification)
            let layer2slice = characteristicsArray.slice(i+1, endOfSlice > -1 ? endOfSlice : characteristicsArray.length + 1); // includes layer 3+
            if(['clauseStartPar1'].includes(ca.classification) && layer2slice.some((l2s) => l2s.classification === 'clauseStartPar2') && ca.stringLength < 80) {
              upgradeLayer1 = true;
            } else if(['clauseStartPar2'].includes(ca.classification) && layer2slice.some((l2s) => l2s.classification === 'clauseStartPar3') && ca.stringLength < 80) {
              ca.classification = 'secTitle2';
            }
          }
        })
        // If applicable, upgrade Layer 1
        if(upgradeLayer1) {
          characteristicsArray.filter((ca) => ca.classification === 'clauseStartPar1' && (ca.stringLength < 80 || 
            (ca.stringLength < 110 && !ca.s.trim().endsWith('.'))))
          .forEach((ca) => {
            ca.classification = 'secTitle1';
          })
        }

        // STEP 5B. RUN THROUGH CHARACTERISTICS ARRAY AND PROPOSE DELETE ANY SINGLE PAGENUMBERS (FOR PDF PASTING)
        characteristicsArray.filter((ca) => ca.stringLength < 3 && ca.stringLength && !isNaN(ca.s))
        .forEach((ca) => { ca.classification = 'unclassified' })

        // ***************************************************
        // STEP 6. NOW CREATE THE CONTENTBLOCKSARRAY
        let contentBlocksArray = [];
        characteristicsArray.forEach((ca) => {
          let block =  new ContentBlock({
            key: ca.key,
            type: ca.classification,
            text: ca.s,
          });
          contentBlocksArray.push(block)
        })
        
        newContentState = ContentState.createFromBlockArray(contentBlocksArray)
        let updatedSelection = newEditorState.getSelection()
        
        // STEP 7. RUN THROUGH AND ASSIGN NUMBERING AND CLAUSE TITLES
        let clauseStarts = characteristicsArray.filter((c) => ['clauseStartPar1','clauseStartPar2','clauseStartPar3','clauseStartPar4'].includes(c.classification))

        let quickDotYes = 0, quickDotNo = 0
        clauseStarts.forEach((cs) => {
          if(cs.wordsBeforeFirstDot < 7) { quickDotYes = quickDotYes + 1 }
          else { quickDotNo = quickDotNo + 1 }
        })
        let quickDotScore = (quickDotYes / (quickDotYes + quickDotNo))
        
        clauseStarts // numbering and clause title assignment
        .forEach((p) => {
          
          if(p.startsWithQuote === 1 && p.closingQuotePosition > 0) {
              updatedSelection = updatedSelection.merge({ anchorKey: p.key, anchorOffset: 0, focusKey: p.key, focusOffset: p.closingQuotePosition + 1 });
              newContentState = Modifier.applyEntity(newContentState, updatedSelection, this.state.clauseTitleKey, ); // Clause Title
        
          } else if(p.wordsBeforeMeans > 0 && p.wordsBeforeMeans < 6) {
              updatedSelection = updatedSelection.merge({ anchorKey: p.key, anchorOffset: 0, focusKey: p.key, focusOffset: p.s.toLowerCase().indexOf(' means ') });
              newContentState = Modifier.applyEntity(newContentState, updatedSelection, this.state.clauseTitleKey, ); // Clause Title

          } else if(p.colonBeforeDot === 1 && p.colonPosition < 80) {
              updatedSelection = updatedSelection.merge({ anchorKey: p.key, anchorOffset: 0, focusKey: p.key, focusOffset: p.colonPosition + 1 });
              newContentState = Modifier.applyEntity(newContentState, updatedSelection, this.state.clauseTitleKey, ); // Clause Title

          } else if((p.wordsBeforeFirstDot <= 5 || (quickDotScore > 0.7 && p.wordsBeforeFirstDot <= 9)) &&
          // Exclude any full-string titles, when the next block is a new clause
          !(characteristicsArray[characteristicsArray.indexOf(p) + 1] !== undefined && 
          ['exhTitle', 'secTitle1', 'secTitle2', 'clauseStartPar1', 'clauseStartPar2', 'clauseStartPar3', 'clauseStartPar4'].includes(characteristicsArray[characteristicsArray.indexOf(p) + 1].classification) &&
          (p.firstDotPositionAfterLead === -1 || (p.firstDotPositionAfterLead + 1 === p.stringLength)))) {

              updatedSelection = updatedSelection.merge({ anchorKey: p.key, anchorOffset: 0, focusKey: p.key, focusOffset: (p.firstDotPositionAfterLead === -1 ? p.stringLength : p.firstDotPositionAfterLead + 1) });
              newContentState = Modifier.applyEntity(newContentState, updatedSelection, this.state.clauseTitleKey, ); // Clause Title
          } else if(p.endPositionOfLead > 0 && ['clauseStartPar1', 'clauseStartPar2', 'clauseStartPar3', 'clauseStartPar4'].includes(p.classification)) {
            updatedSelection = updatedSelection.merge({ anchorKey: p.key, anchorOffset: 0, focusKey: p.key, focusOffset: p.endPositionOfLead });
            newContentState = Modifier.applyEntity(newContentState, updatedSelection, this.state.clauseTitleKey, ); // Numbering
          }
        });

        // STEP 8 - FINALIZE THE PASTING
        this.setState({startClassification: characteristicsArray, classificationMode: true, helpPopup: false, tables: tables})
        newEditorState = EditorState.push(newEditorState, newContentState)
        // DESELECT and go to the start
        let updatedSel = updatedSelection.merge({ focusKey: contentBlocksArray[0].key, focusOffset: 0, anchorKey: contentBlocksArray[0].key, anchorOffset: 0  });
        let newEditorState2 = EditorState.forceSelection(newEditorState, updatedSel)
        this.onChange(newEditorState2);
        return 'handled'
      }
    }

    _handleBeforeInput(chars, editorState) {
      let selectionState = editorState.getSelection();
      let contentState = editorState.getCurrentContent();
      let startKey = selectionState.getStartKey();
      let startBlock = contentState.getBlockForKey(startKey);
      let endKey = selectionState.getStartKey();
      let afterKey = contentState.getKeyAfter(startKey)

      if ((startKey === endKey || (endKey === afterKey)) && startBlock.getType().startsWith('table')) {
        return 'handled' // block any insertion for a table
      } 
    }

    _handleKeyCommand(command, editorState) {

      if(['prevBlock', 'nextBlock'].includes(command)){
        let newEditorState = editorState
        let newSelectionState = newEditorState.getSelection();
        let newContentState = newEditorState.getCurrentContent();
        let blockKey = newSelectionState.getFocusKey();
        let blockBefore = newContentState.getKeyBefore(blockKey)
        let blockAfter = newContentState.getKeyAfter(blockKey)

        if(command === 'prevBlock' && blockBefore !== undefined && blockBefore !== null) {
          newSelectionState = newSelectionState.merge({ 
            anchorKey: blockBefore, 
            anchorOffset: newContentState.getBlockForKey(blockBefore).getLength(), 
            focusKey: blockBefore, 
            focusOffset: newContentState.getBlockForKey(blockBefore).getLength(), 
            isBackward: false });

        } else if (command === 'nextBlock' && blockAfter !== undefined && blockAfter !== null) {
          newSelectionState = newSelectionState.merge({ 
            anchorKey: blockAfter, 
            anchorOffset: newContentState.getBlockForKey(blockAfter).getLength(), 
            focusKey: blockAfter, 
            focusOffset: newContentState.getBlockForKey(blockAfter).getLength(), 
            isBackward: false });
        }

        // Find the target span element in order to be able to scroll to it
        this.handleScrollToBlock(command === 'prevBlock' ? blockBefore : blockAfter, false)

        newEditorState = EditorState.forceSelection(newEditorState, newSelectionState)
        this.onChange(newEditorState);
        return true;

      } else if (['backspace', 'delete'].includes(command)) {

        // Merging taking place - remove entities from the second block
        let newEditorState = editorState
        let selectionState = newEditorState.getSelection();
        let newContentState = newEditorState.getCurrentContent();

        let startKey = selectionState.getStartKey();
        let startBlock = newContentState.getBlockForKey(startKey)
        let startOffset = selectionState.getStartOffset();
        let endKey = selectionState.getEndKey();
        let endOffset = selectionState.getEndOffset();
        let endBlock = newContentState.getBlockForKey(endKey)
        let beforeKey = newContentState.getKeyBefore(endKey)
        let beforeBlock = newContentState.getBlockForKey(beforeKey)
        let afterKey = newContentState.getKeyAfter(endKey)
        let afterBlock = newContentState.getBlockForKey(afterKey)

        if(startBlock.getType().startsWith('table')) { 
          // special handling of deleting of tables - only allow if you have selected everything
          if(startOffset !== 0 || (startKey === endKey && endOffset !== endBlock.getLength()) ||
          (startKey !== endKey && endOffset !== 0)) {
            return 'handled' 
          } else {

            let blockMap = newContentState.getBlockMap();
            let newBlockMap = blockMap.remove(startKey)
            newContentState = newContentState.merge({ blockMap: newBlockMap })
            newEditorState = EditorState.push(newEditorState, newContentState, 'remove-range')

            selectionState = selectionState.merge({ // reselect to be at the point where text got inserted
              anchorKey: startKey === endKey ? afterKey : endKey, 
              anchorOffset: 0, 
              focusKey: startKey === endKey ? afterKey : endKey, 
              focusOffset: 0, 
              isBackward: false 
            })
            newEditorState = EditorState.forceSelection(newEditorState, selectionState)
            this.onChange(newEditorState);

            return 'handled'
          }

        } else if((['backspace'].includes(command) && (endOffset === 0 || (startKey !== endKey))) ||
           (['delete'].includes(command) && (startKey !== endKey || endOffset === endBlock.getLength()))) {

          // If the user has selected up to the table block
          let removeBeforeTableScenario = startKey !== endKey && endBlock.getType().startsWith('table') && endOffset === 0
          // Do nothing if you are backspacing at the start of the first block OR backspacing in or after a table
          if(['backspace'].includes(command) && beforeBlock === undefined && endOffset === 0) { return 'handled' }
          else if(endBlock.getType().startsWith('table') || removeBeforeTableScenario) { return 'handled' }

          selectionState = selectionState.merge({ // select the whole later block
            anchorKey: ['backspace'].includes(command) || startKey !== endKey ? endKey : afterKey, 
            anchorOffset: 0, 
            focusKey: ['backspace'].includes(command) || startKey !== endKey ? endKey : afterKey, 
            focusOffset: ['backspace'].includes(command) || startKey !== endKey ? endBlock.getLength() : afterBlock.getLength(), 
            isBackward: false 
          })

          newContentState = Modifier.applyEntity(newContentState, selectionState, null); // Clear any entity
          newEditorState = EditorState.push(newEditorState, newContentState)

          //'delete'
          if((['backspace'].includes(command) && (endOffset === 0 && startKey === endKey)) ||
          (['delete'].includes(command) && (endOffset === endBlock.getLength() && startKey === endKey))) { // if you backspace - speed up the merge

            let removalRange = selectionState.merge({ 
              anchorKey: ['backspace'].includes(command) ? endKey : afterKey,
              anchorOffset: 0, 
              focusKey: ['backspace'].includes(command) ? endKey : afterKey, 
              focusOffset: ['backspace'].includes(command) ? endBlock.getLength() : afterBlock.getLength(),
              isBackward: false 
            });

            let blockBeforeLength = ['backspace'].includes(command) ? beforeBlock.getLength() : endBlock.getLength();
            let targetRange = selectionState.merge({ 
              anchorKey: ['backspace'].includes(command) ? beforeKey : endKey, 
              anchorOffset: blockBeforeLength, 
              focusKey: ['backspace'].includes(command) ? beforeKey : endKey, 
              focusOffset: blockBeforeLength, 
              isBackward: false 
            });

            newContentState = Modifier.moveText(newContentState, removalRange, targetRange) // Move  all text from the later block to the end of the earlier one       
            newEditorState = EditorState.push(newEditorState, newContentState)

            // Delete the block that got merged
            var blockMap = newContentState.getBlockMap();
            var newBlockMap = blockMap.remove(['backspace'].includes(command) ? endKey : afterKey)
            newContentState = newContentState.merge({ blockMap: newBlockMap })
            newEditorState = EditorState.push(newEditorState, newContentState, 'remove-range')

            selectionState = selectionState.merge({ // reselect to be at the point where text got inserted
              anchorKey: ['backspace'].includes(command) ? beforeKey : endKey, 
              anchorOffset: blockBeforeLength, 
              focusKey: ['backspace'].includes(command) ? beforeKey : endKey,
              focusOffset: blockBeforeLength, 
              isBackward: false 
            })
            newEditorState = EditorState.forceSelection(newEditorState, selectionState)

            // Push all changes - and end here
            this.onChange(newEditorState);
            return 'handled'
          } else {
            // Push all changes - and continue with default
            this.onChange(newEditorState);
          }
          
        }

      }

      if(command.startsWith("shortcut_")) {
        let cmd = command.substr(9)
        this.toggleBlockType(
          cmd === '49' ? 'agrTitle' :
          cmd === '50' ? 'preamble' :
          cmd === '51' ? 'secTitle' :
          cmd === '52' ? 'clauseStartPar' :
          cmd === '53' ? 'clausePar' :
          cmd === '54' ? 'list' :
          cmd === '55' ? 'signBlock' :
          cmd === '56' ? 'unclassified' :
          cmd === '57' ? 'toggleLeft' : 
          cmd === '48' ? 'toggleRight' : 'unclassified'
        )
        return 'handled'
      }

      const newState = RichUtils.handleKeyCommand(editorState, command);

      if (newState) {
          this.onChange(newState);
          return true;
      }
      return false;
    }

    _handleScrollToBlock(blockKey, withSelection) {
      let h = document.querySelector('[data-offset-key="' + blockKey + '-0-0"]')
      if (typeof(h) !== typeof(undefined) && h !== null){ 
        h.scrollIntoView({behavior: "smooth", block: "center"}); 
      }
      if(withSelection) {
        let newEditorState = this.state.editorState;
        let newSelectionState = newEditorState.getSelection();
        newSelectionState = newSelectionState.merge({ 
          anchorKey: blockKey, 
          anchorOffset: 0, 
          focusKey: blockKey, 
          focusOffset: 0, 
          isBackward: false });
        newEditorState = EditorState.forceSelection(newEditorState, newSelectionState)
        this.onChange(newEditorState)
        this.setState({errorMode: false})
      }
    }

    _tableReviewAction(action, idx) {

      if(['insert'].includes(action)) {

        let newEditorState = this.state.editorState
        let newContentState = newEditorState.getCurrentContent();
        let newSelectionState = newEditorState.getSelection();

        if(newSelectionState.isCollapsed()) { // There can not be a selection

          let newString = "[TABLE#" + idx + "]";
          let newStyle = 'table' + idx;

          let blockKey = newSelectionState.getFocusKey();
          let block = newContentState.getBlockForKey(blockKey)

          if(block.getLength() > 0) { // The block is empty - so then toggle it

            newSelectionState = newSelectionState.merge({ anchorKey: blockKey, anchorOffset: block.getLength(), focusKey: blockKey, focusOffset: block.getLength(), isBackward: false });
            newContentState = Modifier.splitBlock(newContentState, newSelectionState)
            let newBlock = newContentState.getBlockAfter(blockKey)
            newSelectionState = newSelectionState.merge({ anchorKey: newBlock.getKey(), anchorOffset: 0, focusKey: newBlock.getKey(), focusOffset: 0, isBackward: false });
          }
          newContentState = Modifier.insertText(newContentState, newSelectionState, newString);
          newEditorState = EditorState.push(newEditorState, newContentState)
          newEditorState = RichUtils.toggleBlockType(newEditorState, newStyle)
        }
        
        this.onChange(newEditorState)
        
      }
    }

    _checkIfTableAlreadyInserted(tableIdx) {
      let exists = false;
      let bMap = this.state.editorState.getCurrentContent().getBlockMap()
      //for(let [key, value] of bMap) {
      for(let value of bMap.values()) {
        if(value.getType() === ('table' + tableIdx)) { exists = true }
      }
      return exists
    }

    _handleReturnFromTable() {
      
      if(this.state.editorState !== undefined && this.state.editorState !== null &&
        this.state.editorState.getSelection() !== undefined && this.state.editorState.getSelection() !== null &&
        this.state.editorState.getSelection().getFocusKey() !== undefined && this.state.editorState.getSelection().getFocusKey() !== null) {
                 
          this.setState({viewingTableIdx: null}, () => this.handleScrollToBlock(this.state.editorState.getSelection().getFocusKey(), false)); 

      } else {
        this.setState({viewingTableIdx: null}); 
      }
    }

    _changeInvisibleHead() {

      let tempTables = this.state.tables;
      let newRows = [], newHead = []

      if(tempTables[this.state.viewingTableIdx - 1].invisibleHead) { 
        // You have an invisible head, but would like to change the first row to a head
        tempTables[this.state.viewingTableIdx - 1].invisibleHead = false;
        tempTables[this.state.viewingTableIdx - 1].rows.forEach((tr, i) => {
          if(i === 0) {
            tr.cols.forEach((tc) => {
              newHead.push({ colKey: tc.cellKey, value: tc.value});
            })
          } else {
            newRows.push(tr);
          }
        })
      } else {
        // You do not have an invisible head, but would like to change the current head to the first row
        tempTables[this.state.viewingTableIdx - 1].invisibleHead = true;
        let newCols = []
        tempTables[this.state.viewingTableIdx - 1].head.forEach((h) => {
          newHead.push({ colKey: genKey(5), value: ''})
          newCols.push({ cellKey: h.colKey, value: h.value })
        })
        newRows.push({ rowKey: genKey(5), cols: newCols })
        tempTables[this.state.viewingTableIdx - 1].rows.forEach((r) => {
          newRows.push(r);
        })
      }

      tempTables[this.state.viewingTableIdx - 1].head = newHead
      tempTables[this.state.viewingTableIdx - 1].rows = newRows

      this.setState({ tables: tempTables })

    }

    _changeTargetModule(newVal) {
      this.setState({ targetModule: newVal })
    }

    _mapKeyToEditorCommand(e) {
      if(!e.shiftKey && this.state.classificationMode && [38].includes(e.keyCode)) { /* arrow left	37 arrow up	38 */
        return 'prevBlock';
      } else if(!e.shiftKey && this.state.classificationMode && [40].includes(e.keyCode)) { /*arrow right	39 arrow down	40 */
        return 'nextBlock';
      } else if (e.keyCode === 8) { /* Backspace (backward) */
        return 'backspace';
      } else if (e.keyCode === 46) { /* Delete (forward) */
        return 'delete';
      } else if (hasCommandModifier(e) && /* Cmd */
                  (e.keyCode === 66 /* b */|| 
                   e.keyCode === 85 /* u */|| 
                   e.keyCode === 73 /* i */|| 
                   e.keyCode === 74 /* j */)){
        return 'ignore';
      } else if(
        (!this.state.classificationMode && hasCommandModifier(e) && e.keyCode > 47 && e.keyCode < 58) ||
        (this.state.classificationMode && e.keyCode > 47 && e.keyCode < 58)){
        return 'shortcut_' + e.keyCode
      }
      return getDefaultKeyBinding(e);
    }

    _handleSkipImportToggle(e, agrComponentID) {
      let newExhibits = this.state.iDoc.data.exhibits
      let exIdx = newExhibits.findIndex((e) => e.agrComponentID === agrComponentID)
      newExhibits[exIdx].skipImport = !e.target.checked
      this.setState({iDoc: {...this.state.iDoc, 
        data: {...this.state.iDoc.data, 
          exhibits: newExhibits
        } 
      }})
    }

    _handleAgrTypeSelectChange(newValue, agrComponentID) {

      let selfCreated = newValue !== null && typeof newValue.inputValue === 'string';

      if(agrComponentID === 'mainbody' && newValue !== null) {
        this.setState({
          iDoc: {...this.state.iDoc, 
            data: {...this.state.iDoc.data, 
              agrTypeID: selfCreated ? null : newValue._id,
            } 
          },
          newAgrType: newValue,
          newAgrTypeShortName: selfCreated ? getAcronym(newValue.inputValue) : this.state.newAgrTypeShortName
        });

      } else if(newValue !== null) { // for an exhibit
        let newExhibits = this.state.iDoc.data.exhibits
        let exIdx = newExhibits.findIndex((e) => e.agrComponentID === agrComponentID)
        newExhibits[exIdx].agrTypeID = selfCreated ? newExhibits[exIdx].agrTypeID : newValue._id
        newExhibits[exIdx].newAgrType = newValue
        newExhibits[exIdx].newAgrTypeShortName = selfCreated ? getAcronym(newValue.inputValue) : newExhibits[exIdx].newAgrTypeShortName

        this.setState({iDoc: {...this.state.iDoc, 
          data: {...this.state.iDoc.data, 
            exhibits: newExhibits
          } 
        }})
      }

    }

    _handleAgrTypeShortNameChange(newValue, agrComponentID) {
      if(agrComponentID === 'mainbody') {
        this.setState({newAgrTypeShortName: newValue})

      } else { // for an exhibit
        let newExhibits = this.state.iDoc.data.exhibits
        let exIdx = newExhibits.findIndex((e) => e.agrComponentID === agrComponentID)
        newExhibits[exIdx].newAgrTypeShortName = newValue

        this.setState({iDoc: {...this.state.iDoc, 
          data: {...this.state.iDoc.data, 
            exhibits: newExhibits
          } 
        }})
      }
      
    }

    _toggleBlockType(blockType) {
      let newEditorState = this.state.editorState
      let selectionState = newEditorState.getSelection();
      let newContentState = newEditorState.getCurrentContent();

      let converted = convertToRaw(newContentState)

      let startKey = selectionState.getStartKey();
      let endKey = selectionState.getEndKey();
      let withinSelection = false
      let agrTitleExists = false
      
      converted.blocks.forEach((bl) => { // Only toggle if the new style is different from the current style
        if(this.state.editorState.getCurrentContent().getBlockForKey(bl.key).getType() === 'agrTitle') { agrTitleExists = true; }
        if(bl.key === startKey) { withinSelection = true; }
        if(withinSelection) {
          let block = this.state.editorState.getCurrentContent().getBlockForKey(bl.key);
          if(block.getType() !== blockType && block.getType().substring(0, block.getType().length-1) !== blockType && 
          (!block.getType().startsWith('table') || blockType === 'unclassified')) { // can't change to yourself

            let newBlockType = blockType;

            if(blockType === 'toggleLeft') { // TOGGLING LEFT IF APPROPRIATE
              if(block.getType() === 'secTitle2') { newBlockType = 'secTitle1'}
              else if(block.getType() === 'clauseStartPar2') { newBlockType = 'clauseStartPar1'}
              else if(block.getType() === 'clauseStartPar3') { newBlockType = 'clauseStartPar2'}
              else if(block.getType() === 'clauseStartPar4') { newBlockType = 'clauseStartPar3'}
              else if(block.getType() === 'clausePar2') { newBlockType = 'clausePar1'}
              else if(block.getType() === 'clausePar3') { newBlockType = 'clausePar2'}
              else if(block.getType() === 'clausePar4') { newBlockType = 'clausePar3'}
              else if(block.getType() === 'list2') { newBlockType = 'list1'}
              else if(block.getType() === 'list3') { newBlockType = 'list2'}
              else if(block.getType() === 'list4') { newBlockType = 'list3'}
              else { newBlockType = null }

            } else if(blockType === 'toggleRight') { // TOGGLING RIGHT IF APPROPRIATE
              if(block.getType() === 'secTitle1') { newBlockType = 'secTitle2'}
              else if(block.getType() === 'clauseStartPar1') { newBlockType = 'clauseStartPar2'}
              else if(block.getType() === 'clauseStartPar2') { newBlockType = 'clauseStartPar3'}
              else if(block.getType() === 'clauseStartPar3') { newBlockType = 'clauseStartPar4'}
              else if(block.getType() === 'clausePar1') { newBlockType = 'clausePar2'}
              else if(block.getType() === 'clausePar2') { newBlockType = 'clausePar3'}
              else if(block.getType() === 'clausePar3') { newBlockType = 'clausePar4'}
              else if(block.getType() === 'list1') { newBlockType = 'list2'}
              else if(block.getType() === 'list2') { newBlockType = 'list3'}
              else if(block.getType() === 'list3') { newBlockType = 'list4'}
              else { newBlockType = null }

            } else if(['secTitle', 'clauseStartPar', 'clausePar', 'list'].includes(blockType)) {
              if(block.getType().endsWith('3') && ['clauseStartPar', 'clausePar', 'list'].includes(blockType)) { newBlockType = blockType + '3' }
              else if(block.getType().endsWith('2')) { newBlockType = blockType + '2' }
              else { newBlockType = blockType + '1' }
            
            } else if(['agrTitle'].includes(blockType) && !['agrTitle'].includes(block.getType()) && agrTitleExists) {
              // Toggling to agreement title but one already exists - toggle to exhibit title
              newBlockType = ['exhTitle'].includes(block.getType()) ? null : 'exhTitle'

            }

            if(newBlockType !== null) {
              selectionState = selectionState.merge({ anchorKey: bl.key, anchorOffset: block.getLength(), focusKey: bl.key, focusOffset: block.getLength(), isBackward: false });
              newEditorState = EditorState.forceSelection(newEditorState, selectionState) 
              newEditorState = RichUtils.toggleBlockType(newEditorState, newBlockType)
            }
          }
        }
        if(bl.key === endKey) { withinSelection = false; }
      })

      if(startKey !== endKey) { // reselect the multi-block selection (start to finish though)
        newContentState = newEditorState.getCurrentContent();
        selectionState = selectionState.merge({ anchorKey: startKey, anchorOffset: 0, focusKey: endKey, focusOffset: newContentState.getBlockForKey(endKey).getLength(), isBackward: false });
        newEditorState = EditorState.forceSelection(newEditorState, selectionState) 
      }

      this.onChange(newEditorState);
    }

    _toggleInlineStyle(inlineStyle) {
        this.onChange(
        RichUtils.toggleInlineStyle(
            this.state.editorState,
            inlineStyle
        )
        );
    }

    render() {
        const {editorState} = this.state;

        // If the user changes block type before entering any text, we can
        // either style the placeholder or hide it. Let's just hide it now.
        let className = 'RichEditor-editor';
        var contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }

      return (
        <Dialog 
        fullScreen 
        open={Boolean(this.props.importOpen)} 
        onClose={this.handleCloseDialog} 
        >
        {this.state.saveClassificationArray.length > 0 && (this.state.errors === undefined || this.state.errors.length === 0) ? 

        <div>

          <div style={{position: 'fixed', top: '20px', right: '30px'}}>
            <IconButton onClick={this.handleCloseDialog}>
              <FontAwesomeIcon icon={faTimes} style={{ fontSize: '32px', padding: '10px 14px 10px 14px' }} />
            </IconButton>
          </div>

          <Box mt={8} mb={6} align="center">

            <Box align="left" style={{width: '520px'}}>
              
              {this.state.reviewPage === 0 ?
              //!this.state.readyToFinalize ? 
              <>
                <Box mt={4} mb={8}>
                  <BorderLinearProgress variant="determinate" value={['new-agr'].includes(this.props.action) ? 100 : 50} />
                </Box>
                <Box mt={4} pl={3} pr={3}>
                  <Box mb={2}><Typography variant="h5">Select the Agreement Type</Typography></Box>
                  {this.state.agrTypeError !== undefined && this.state.agrTypeError !== null ? 
                  <Box mt={2} mb={3}>
                    <Typography variant="subtitle1" align="center" color="error">{this.state.agrTypeError}</Typography>
                  </Box>
                  : ''}
                  
                  <SelectAgrType 
                  agrComponentID={'mainbody'}
                  agrTypeOptions={this.props.agrTypes.sort((a,b) => (a.fullName[0] > b.fullName[0]) ? 1 : ((b.fullName[0] > a.fullName[0]) ? -1 : 0)) || []}
                  newAgrType={this.state.newAgrType !== null ? this.state.newAgrType : ''}
                  newAgrTypeShortName={this.state.newAgrTypeShortName}
                  handleAgrTypeSelectChange={this.handleAgrTypeSelectChange}
                  handleAgrTypeShortNameChange={this.handleAgrTypeShortNameChange}
                  />

                </Box>

                {!['new-agr', 'new-version'].includes(this.props.action) ?
                <Box mt={4} pl={3} pr={3}>
                  <Box mb={2}><Typography variant="h5">Provide the Template Reference</Typography></Box>
                  <TextField
                    fullWidth
                    label="Template Reference"
                    variant="outlined"
                    value={this.state.newTemplateReference}
                    onChange={e => this.setState({newTemplateReference: e.target.value})}
                  />

                </Box>
                :''}
              </>
              :this.state.reviewPage === 1 ?
              <>
              <Box mt={4} mb={8}>
                <BorderLinearProgress variant="determinate" value={75} />
              </Box>

              <Box mt={3} pl={3} pr={3} align="center">
                  <Box mb={3}><Typography variant="h5">Import Exhibits as Templates</Typography></Box>

                  {this.state.iDoc.data.exhibits.map((ex, i) => (
                  <Box mb={2} pt={2} pb={2} pl={2} pr={2} style={{borderRadius: '10px', border: '1px solid #e0e0e0'}} key={i}>
                    <Box>
                      <FormControlLabel control={<Checkbox checked={!ex.skipImport} onChange={e => this.handleSkipImportToggle(e, ex.agrComponentID)} />} label={trunc("Import \"" + ex.agrTitle,36) + "\""} />
                    </Box>
                    <Box mt={1} mb={1} style={ex.skipImport ? { display: 'none' } : {}}>
                      <SelectAgrType
                      agrComponentID={ex.agrComponentID}
                      agrTypeOptions={this.props.agrTypes.sort((a,b) => (a.fullName[0] > b.fullName[0]) ? 1 : ((b.fullName[0] > a.fullName[0]) ? -1 : 0)) || []}
                      newAgrType={ex.newAgrType !== undefined && ex.newAgrType !== null ? ex.newAgrType : ''}
                      newAgrTypeShortName={ex.newAgrTypeShortName}
                      handleAgrTypeSelectChange={this.handleAgrTypeSelectChange}
                      handleAgrTypeShortNameChange={this.handleAgrTypeShortNameChange}
                      />
                    </Box>
                  </Box>
                  ))}

              </Box>

              </>
              : // reviewPage === 2
              <>
              <Box mt={4} mb={8}>
                <BorderLinearProgress variant="determinate" value={100} />
              </Box>
              {!['new-agr', 'new-version'].includes(this.props.action) && this.state.newAgrType !== null && this.state.newAgrType !== undefined ? 
              <Box mt={4} pl={3} pr={3}>
                  <Box mb={1}>
                    <Typography variant="h5">Select the Target Module</Typography>
                  </Box>

                  <RadioTargetModule
                    relevantOat={{agrTypeID: 
                      this.state.newAgrType !== undefined && this.state.newAgrType !== null ? this.state.newAgrType._id : 'none'}}
                    relevantRef={this.state.newTemplateReference}
                    relevantSection={this.props.relevantSection}
                    targetModule={this.state.targetModule}
                    changeTargetModule={this.changeTargetModule}
                    orgAgrTemplates={this.props.orgAgrTemplates}
                    modules={
                      this.props.org !== undefined && this.props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(this.props.org.clientType) ? 
                        ['sales','procurement','partnerships','people','corporate'] : 
                      this.props.org !== undefined && this.props.org.clientType !== undefined && ['custS'].includes(this.props.org.clientType) && this.props.org.modules !== undefined ?
                        this.props.org.modules : []}
                    action={this.props.action}
                  />

              </Box>
              : '' }
              </>
              }

              <Box mt={8}>
                <Grid direction="row" container justify="space-between" alignItems="flex-start">
                  <Grid item>
                    <Button variant="text" color="primary" size="large" 
                    onClick={this.state.reviewPage > 0 && !['new-version'].includes(this.props.action) ? 
                      e => this.setState({reviewPage: this.state.reviewPage - (this.state.reviewPage === 2 && this.state.iDoc.data.exhibits.length === 0 ? 2 : 1)}) : e => this.setState({saveClassificationArray: []})}>
                      <FontAwesomeIcon icon={faArrowLeft} style={{ paddingRight: '10px' }} />
                      Go back
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" disableElevation color="primary" size="large"
                    disabled={
                      (this.state.reviewPage === 2 && !['new-agr', 'new-version'].includes(this.props.action) && 
                        (this.state.targetModule === undefined || this.state.targetModule === null || this.state.targetModule === '')) ||
                      (this.state.reviewPage === 1 &&
                        this.state.iDoc.data.exhibits.some((e) => !e.skipImport && (e.newAgrType === undefined || e.newAgrType === null || e.newAgrType === ''))) ||
                      (this.state.reviewPage === 0 && 
                        (this.state.newAgrType === undefined || this.state.newAgrType === null || this.state.newTemplateReference === ''))
                    }
                    onClick={
                      this.state.reviewPage === 2 ? () => this.handleFinalize() : 
                      this.state.reviewPage === 1 ? () => this.handleExhibitReview() : () => this.handleAgrTypeSelection()}
                    style={{width: '220px'}}>
                      {this.state.reviewPage === 2 || ['new-agr'].includes(this.props.action) ? "Finalize Import" : "Next"}
                      <FontAwesomeIcon icon={this.state.reviewPage === 2 || ['new-agr'].includes(this.props.action) ? faCheck : faArrowRight} style={{ paddingLeft: '10px' }} />
                    </Button>
                  </Grid>
                </Grid>
              </Box>

            </Box>
          </Box>

        </div>

        :
        <div className="RichEditor-root" style={this.state.helpPopup ? {overflowY:'scroll', paddingLeft: '30px'} : {overflowY:'scroll'}}>

            {this.state.helpPopup ?
            <div style={{zIndex:0, width: '330px', height: 'auto', fontSize: '15px', color: theme.palette.grey[700], position: 'absolute', left: '5px', bottom:'5px'}}>

              <Typography style={{fontWeight: '700', padding: '10px 15px 0px 20px'}}>A few tips to get you started:</Typography>

              <ol style={{paddingBottom: '15px'}}>
                <li>Copy and paste the entire agreement.</li>
                <li>Review and make corrections with the classification buttons on the right.</li>
                <li>Tables require a review and removal of the plain text.</li>
                <li>If your agreement does not get imported correctly, email us at <a href="mailto:support@getcanveo.com" className="seclink">support@getcanveo.com</a>, we'll do it for you.</li>
                <li>Ensure <a href="https://support.microsoft.com/en-us/office/control-hyphenation-7d4d2a38-b0e2-4f04-873a-0a8d48ac3923" target="_blank" rel="noopener noreferrer" className="seclink">auto-hyphenation</a> is switched off.</li>
              </ol>

            </div>
            : '' }

            {!this.state.tableReviewMode ?
            <Fab
                color="secondary"
                size="large"
                variant="extended"
                style={{ position: 'fixed', zIndex: 150, bottom: '20px', right: '37px', fontSize: '18px', fontWeight: '600', width: '218px',
                  borderRadius: '50px', padding: '26px 20px 26px 20px'}}
                onClick={this.state.helpScreen !== null ? 
                  e => this.setState({helpScreen: null}) :
                  (this.state.uploading !== undefined && this.state.uploading !== null && this.state.uploading.clicked) ?
                  e => this.setState({uploading: { clicked: false, file: null }}) :
                  this.handleCompleteImport}
            >{this.state.errorMode || this.state.errors.length > 0 ? "Retry Import" : "Complete Import"}</Fab>
            :''}

            <div className={className} onClick={this.focus} style={{width: 'calc(100% - 278px)'}}>
            
            {this.state.viewingTableIdx !== null && this.state.tables[this.state.viewingTableIdx - 1] !== undefined ?
            <Box align="center">
              <Box style={{maxWidth: '600px'}} mb={4} mt={4}>

                <TableEditable
                active={false}
                disabled={true}
                editMode={'none'}
                isImport={true}
                clause={{clauseObject: this.state.tables[this.state.viewingTableIdx - 1]}}
                proposedDel={null}
                focusClause={() => void 0}
                curCpty={"primary"}
                />

              </Box>
              <Box mb={4} align="center">
                <FormControlLabel 
                control={
                  <Switch size="small" 
                  onChange={e => this.changeInvisibleHead()} 
                  checked={this.state.tables[this.state.viewingTableIdx - 1] !== undefined && !this.state.tables[this.state.viewingTableIdx - 1].invisibleHead} 
                  color="primary" />} 
                label={<Typography variant="subtitle2">&nbsp;&nbsp;The first row of this table is a header</Typography>} 
                />
              </Box>
              <Box align="center">
                <Button size="large" color="primary" onClick={e => this.handleReturnFromTable()}><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Go Back</Button>
              </Box>
            </Box>
            :''}

            <div style={this.state.viewingTableIdx !== null ? {display:'none'} : {}}>
              <Editor
                  blockStyleFn={getBlockStyle}
                  editorState={editorState}
                  handlePastedText={this.handlePastedText}
                  handleKeyCommand={this.handleKeyCommand}
                  handleBeforeInput={this.handleBeforeInput}
                  keyBindingFn={this.mapKeyToEditorCommand}
                  onChange={this.onChange}
                  placeholder="Click here and paste the template..."
                  ref="editor"
                  spellCheck={true}
              />
            </div>

            </div>
            <div className="RichEditor-styleBox">
            {this.state.tableReviewMode ?
            <Box mt={4} ml={2} mr={2} align="center">
              <Tooltip title="Exit table review" placement="left">
                <IconButton onClick={e => this.setState({tableReviewMode: false, activeTableIdx: 1, viewingTableIdx: null})}>
                  <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: '26px', padding: '8px 11px 8px 11px' }} />
                </IconButton>
              </Tooltip>
              <Box mt={2} mb={1}><Typography variant="h5">Table Review</Typography></Box>

              <Box mt={2} mb={3}>
                <Typography align="left">Our default import includes a plain text version of any table. If you like to replace this with the table version, delete the applicable plain text, place the cursor where you want the table, and then click “insert".</Typography>
              </Box>

              <Box mb={3}>
              <span style={{fontSize: '36px', fontWeight: '700', color: theme.palette.primary.main}}>
                <FontAwesomeIcon icon={faTable} />{this.state.viewingTableIdx !== null ? <span>&nbsp;&nbsp;#{this.state.viewingTableIdx}</span>: ''}
              </span>
              </Box>

              <Grid container direction="row" alignItems="center">
                <Grid item xs={12} style={this.state.tablePage === 0 ? {display:'none'} : {}}>
                  <Box mb={1}>
                  <Button size="small" variant="text" color="primary" onClick={e => this.setState({tablePage: this.state.tablePage - 1})}><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Previous tables</Button>
                  </Box>
                </Grid>
                {this.state.tables.map((t, i) => (
                <React.Fragment key={i}>
                <Grid item xs={4} style={(i >= (this.state.tablePage * 5)) && (i < (this.state.tablePage * 5 + 5)) ? {} : {display: 'none'}}>
                  Table #{i+1}
                </Grid>
                <Grid item xs={8} style={(i >= (this.state.tablePage * 5)) && (i < (this.state.tablePage * 5 + 5)) ? {} : {display: 'none'}}>
                  <Box style={{margin: '3px 0px 3px 0px'}}>
                  <Button size="small" color="secondary" variant={this.state.viewingTableIdx === i+1 ? "contained" : "outlined"} disableElevation
                  onClick={this.state.viewingTableIdx === i+1 ?
                    e => this.handleReturnFromTable() :
                    e => this.setState({viewingTableIdx: this.state.viewingTableIdx === i+1 ? null : (i+1)})}>{this.state.viewingTableIdx === i+1 ? "Back" : "Review"}</Button>&nbsp;
                  <Button size="small" color="secondary" variant="contained" disableElevation
                  onClick={e => this.tableReviewAction('insert', i+1)}
                  disabled={this.checkIfTableAlreadyInserted(i+1) || this.state.viewingTableIdx !== null}>Insert</Button>
                  </Box>
                </Grid>
                </React.Fragment>
                ))}
                <Grid item xs={12} style={(this.state.tablePage * 5 + 5) < this.state.tables.length ? {} : {display:'none'}}>
                  <Box mt={1} mb={1}>
                  <Button size="small" variant="text" color="primary" onClick={e => this.setState({tablePage: this.state.tablePage + 1})}>Next tables&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} /></Button>
                  </Box>
                </Grid>
              </Grid>

            </Box>
            :
            <BlockStyleControls
                editorState={editorState}
                onToggle={this.toggleBlockType}
                handleImportClose={this.handleCloseDialog}
                startClassification={this.state.startClassification}
                clearClassification={this.state.clearClassification}
                setClassification={this.state.setClassification}
                errors={this.state.errors}
                warnings={this.state.warnings}
                handleResetComponent={this.handleResetComponent}
                classificationMode={this.state.classificationMode}
                toggleClassificationMode={e => this.setState({classificationMode: !this.state.classificationMode})}
                clearEntity={this.clearEntity}
                setEntity={this.setEntity}
                handleScrollToBlock={this.handleScrollToBlock}
                errWarnMapping={this.errWarnMapping}
                errorMode={this.state.errorMode}
                toggleErrorMode={e => this.setState({errorMode: !this.state.errorMode})}
                tables={this.state.tables}
                toggleTableReviewMode={e => this.setState({ tableReviewMode: !this.state.tableReviewMode })}
            />
            }
            </div>

        </div>
        }
        </Dialog>
        );
    }
}

function getBlockStyle(block) {

    /*** Classifications ***
    - agrTitle
    - exhTitle
    - secTitle
    - subsecTitle
    - clauseStartPar (generally holds title)
    - clausePar
    - list
    - unclassified
    */
  
    switch (block.getType().startsWith('table') ? 'table' : block.getType()) {
        case 'agrTitle': return 'RichEditor-agrTitle';
        case 'exhTitle': return 'RichEditor-exhTitle';
        case 'preamble': return 'RichEditor-preamble';
        case 'secTitle1': return 'RichEditor-secTitle1';
        case 'secTitle2': return 'RichEditor-secTitle2';
        case 'clauseTitle': return 'RichEditor-clauseTitle';
        case 'clauseStartPar1': return 'RichEditor-clauseStartPar1';
        case 'clauseStartPar2': return 'RichEditor-clauseStartPar2';
        case 'clauseStartPar3': return 'RichEditor-clauseStartPar3';
        case 'clauseStartPar4': return 'RichEditor-clauseStartPar4';
        case 'list1': return 'RichEditor-list1';
        case 'list2': return 'RichEditor-list2';
        case 'list3': return 'RichEditor-list3';
        case 'list4': return 'RichEditor-list4';
        case 'clausePar1': return 'RichEditor-clausePar1';
        case 'clausePar2': return 'RichEditor-clausePar2';
        case 'clausePar3': return 'RichEditor-clausePar3';
        case 'clausePar4': return 'RichEditor-clausePar4';
        case 'signBlock': return 'RichEditor-signBlock';
        case 'table': return 'RichEditor-table';
        case 'unclassified' : return 'RichEditor-unclassified';
        default: return null;
    }
}

class StyleButton extends React.Component {
    constructor() {
        super();
        this.onToggle = (e) => {
          e.preventDefault();
          this.props.onToggle(this.props.style);
        };
    }
        render() {
            let className = 'RichEditor-styleButton-' + this.props.style;
            if (this.props.active) { className += ' RichEditor-activeButton-' + this.props.style; }

            // 
            // </span>
            return (
              <Box>
                <span className={className} onMouseDown={this.onToggle}>
                  {this.props.label}
                  <span style={{marginLeft: '10px', fontSize: '10px', fontWeight: '400'}}>
                    {!this.props.classificationMode && ['MacIntel', 'Mac68K', 'MacPPC', 'iPhone', 'iPod', 'iPad'].includes(window.navigator.platform) ?
                      ('cmd + ' + this.props.cmd) :
                     !this.props.classificationMode ?
                      ('ctrl + ' + this.props.cmd) :
                     this.props.classificationMode ?
                      this.props.cmd : ''}
                  </span>
                </span>
              </Box>
            );
        }
}

const BLOCK_TYPES = [
    {label: 'Agreement Title', cmd: 1, style: 'agrTitle'},
    {label: 'Preamble', cmd: 2, style: 'preamble'},
    {label: 'Section title', cmd: 3, style: 'secTitle'},
    {label: 'New Clause', cmd: 4, style: 'clauseStartPar'},
    {label: 'Paragraph in clause', cmd: 5, style: 'clausePar'},
    {label: 'List item in clause', cmd: 6, style: 'list'},
    {label: 'Signature block', cmd: 7, style: 'signBlock'},
    {label: 'Delete', cmd: 8, style: 'unclassified'},
];

const BlockStyleControls = (props) => {
    const {editorState} = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <div className="RichEditor-controls" style={{overflowY: 'auto'}}>
        <Box align="center" mt={2} mb={1}>
        {props.errorMode || props.errors.length > 0 ?
          <Tooltip title={props.errorMode ? "Back to Classify Options" : "Back to Issue overview"} placement="left">
            <IconButton onClick={e => props.toggleErrorMode()}>
              <FontAwesomeIcon icon={props.errorMode ? faArrowLeft : faExclamationTriangle} 
              style={props.errorMode ? { fontSize: '26px', padding: '8px 10px 8px 10px' } : { fontSize: '26px', padding: '8px 8px 8px 8px', color: theme.palette.error.main }} />
            </IconButton>
          </Tooltip>
        :''}

        {!props.errorMode ?
        <Tooltip title="Exit import" placement="left">
          <IconButton onClick={props.handleImportClose}>
            <FontAwesomeIcon icon={faTimes} style={{ fontSize: '26px', padding: '8px 13px 8px 13px' }} />
          </IconButton>
        </Tooltip>
        :''}
        </Box>

        {props.errorMode ?
          <Box mt={2}>
          {props.errors.length > 0 ? 
            <Box mb={2}>
            <Typography variant="h6" style={{fontWeight: '700'}}>Issues to be resolved</Typography>
            <List dense>
            {props.errors.map((err, i) => (
              <ListItem button key={i} onClick={e => props.handleScrollToBlock(err.blockKey, true)}>
                <ListItemText
                primary={<span style={{fontWeight: '700', color: theme.palette.error.main}}>{props.errWarnMapping(err.error)}</span>}
                secondary={trunc(err.string, 50)}
                />
              </ListItem>
            ))}
            </List>
            </Box>
          : '' }

          {props.warnings.length > 0 ? 

          <>
          <Typography variant="h6" style={{fontWeight: '700'}}>Warnings</Typography>
          <List dense>
          {props.warnings.map((w, i) => (
            <ListItem button key={i} onClick={e => props.handleScrollToBlock(w.blockKey, true)}>
              <ListItemText
              primary={<span style={{fontWeight: '700', color: theme.palette.warning.main}}>{props.errWarnMapping(w.error)}</span>}
              secondary={trunc(w.string, 50)}
              />
            </ListItem>
          ))}
          </List>
          </>
          : '' }

          </Box>
        :
          <>
          {BLOCK_TYPES.map((type) =>
              <StyleButton
              key={type.label}
              active={[type.style, (type.style + '1'), (type.style + '2'), (type.style + '3')].includes(blockType) || (blockType === 'exhTitle' && type.style === 'agrTitle')}
              label={type.label}
              cmd={type.cmd}
              onToggle={props.onToggle}
              style={type.style}
              classificationMode={props.classificationMode}
              />
          )}

          {['secTitle', 'clauseStartPar', 'clausePar', 'list'].includes(blockType.substring(0, blockType.length -1)) ?
          <Box align="center" mt={1} mb={1}>
            <span style={['secTitle2', 'clauseStartPar2', 'clauseStartPar3', 'clauseStartPar4', 'clausePar2', 'clausePar3', 'clausePar4', 'list2', 'list3', 'list4'].includes(blockType) ?
                {marginRight: '5px', fontSize: '10px', fontWeight: '400'} : {marginRight: '5px', fontSize: '10px', fontWeight: '400', color: theme.palette.grey[400]}}>
              {!props.classificationMode && ['MacIntel', 'Mac68K', 'MacPPC', 'iPhone', 'iPod', 'iPad'].includes(window.navigator.platform) ?
                ('cmd + 9') :
                !props.classificationMode ?
                ('ctrl + 9') :
                props.classificationMode ?
                '9' : ''}
            </span>
            <IconButton disabled={!(['secTitle2', 'clauseStartPar2', 'clauseStartPar3', 'clauseStartPar4', 'clausePar2', 'clausePar3', 'clausePar4', 'list2', 'list3', 'list4'].includes(blockType))}
            onClick={e => props.onToggle('toggleLeft')}>
              <FontAwesomeIcon icon={faChevronCircleLeft} />
            </IconButton>

            <IconButton disabled={!(['secTitle1', 'clauseStartPar1', 'clauseStartPar2', 'clauseStartPar3', 'clausePar1', 'clausePar2', 'clausePar3', 'list1', 'list2', 'list3'].includes(blockType))}
            onClick={e => props.onToggle('toggleRight')}>
            <FontAwesomeIcon icon={faChevronCircleRight} />
            </IconButton>
            <span style={['secTitle1', 'clauseStartPar1', 'clauseStartPar2', 'clauseStartPar3', 'clausePar1', 'clausePar2', 'clausePar3', 'list1', 'list2', 'list3'].includes(blockType) ?
              {marginLeft: '5px', fontSize: '10px', fontWeight: '400'} : {marginLeft: '5px', fontSize: '10px', fontWeight: '400', color: theme.palette.grey[400]}}>
              {!props.classificationMode && ['MacIntel', 'Mac68K', 'MacPPC', 'iPhone', 'iPod', 'iPad'].includes(window.navigator.platform) ?
                ('cmd + 0') :
                !props.classificationMode ?
                ('ctrl + 0') :
                props.classificationMode ?
                '0' : ''}
            </span>
          </Box>
          :['agrTitle', 'exhTitle'].includes(blockType) ?
          <Box align="center" mt={2} mb={3}>
            <Button variant="contained" size="small" disableElevation onClick={e => props.onToggle('agrTitle')}
            style={
              ['agrTitle'].includes(blockType) ?
              { fontSize: '13px', fontWeight: '700', backgroundColor: '#E80064', color: '#ffffff', borderRadius: '15px'} :
              { fontSize: '13px', fontWeight: '700', backgroundColor: '#f3f3f3', color: '#aca9ba', borderRadius: '15px'}}>Agreement
            </Button>
            <Button variant="contained" size="small" disableElevation onClick={e => props.onToggle('exhTitle')}
            style={
              ['exhTitle'].includes(blockType) ?
              { fontSize: '13px', fontWeight: '700', backgroundColor: '#E80064', color: '#ffffff', borderRadius: '15px', marginLeft: '10px'} :
              { fontSize: '13px', fontWeight: '700', backgroundColor: '#f3f3f3', color: '#aca9ba', borderRadius: '15px', marginLeft: '10px'}}>Exhibit
            </Button>
          </Box>
          :'' }
          
          
          {(props.clearClassification.type !== null) ?
          <Box pt={['secTitle', 'clauseStartPar', 'clausePar', 'list'].includes(blockType.substring(0, blockType.length -1)) ? 1 : 2} pb={2}>
          <Button variant="contained" size="small" disableElevation onClick={e => props.clearEntity()}
          style={
            props.clearClassification.type === 'CLAUSETITLE' ?
              { fontSize: '13px', fontWeight: '700', backgroundColor: '#6aa84f', color: '#ffffff', borderRadius: '15px'} : {}}
          >
            Clear {props.clearClassification.type === 'CLAUSETITLE' ? "Title classification" : '' }
          </Button>
          </Box>
          :
          props.setClassification.type !== null ?
          <Box pt={2} pb={2}>
            {['BOTH', 'CLAUSETITLE'].includes(props.setClassification.type) ?
            <Button variant="contained" size="small" disableElevation onClick={e => props.setEntity('CLAUSETITLE')}
            style={{ fontSize: '13px', fontWeight: '700', backgroundColor: '#6aa84f', color: '#ffffff', width: '95px', borderRadius: '15px', margin: '0px 5px 0px 5px'}}
            >
              Title
            </Button>
            : ''}
          </Box>
          : '' }


          <Box mt={1} pt={2} style={{borderTop: '1px solid #DDDDDD'}}>
            {//props.startClassification !== undefined && props.startClassification.length > 0 ?
              <Box mb={1}>
              <Button onClick={props.handleResetComponent}>
              <FontAwesomeIcon icon={faUndo} style={{ color: theme.palette.primary.main, paddingRight: '10px' /*padding: '8px 6px 8px 6px'*/ }} />
              Clear and Reset
              </Button>
              </Box>
            //: ''
            }

            <Box ml={1}>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={props.classificationMode}
                  onChange={props.toggleClassificationMode}
                  name="classificationMode"
                  color="primary"
                />
              }
              label={<Typography variant="subtitle2" style={{padding: '0px 0px 0px 5px'}}>Classification Mode</Typography>}
            />
            </Box>

            {props.tables.length > 0 ? 
            <Box mt={1} align="center">
              <Button onClick={props.toggleTableReviewMode}>
                <FontAwesomeIcon icon={faTable} style={{ color: theme.palette.primary.main, paddingRight: '10px'}} />
                Table Review: {props.tables.length} Table{props.tables.length === 1 ? "" : "s"}</Button>
            </Box>
            : ''}
          </Box>
          </>
        }

        </div>
    );
};

function ClauseTitleStrategy(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'CLAUSETITLE'
      );
    },
    callback
  );
}

const ClauseTitleSpan = (props) => {
  return (<span className="clauseTitle" data-offset-key={props.offsetKey}>{props.children}</span>);
};

export default DialogImportDoc