import React from 'react';
import { Badge, Box, ListItem, ListItemText, Typography, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { TimeTrack } from '.'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons";
import theme from '../theme/theme';

const DrawerCoreRender = (props) => {

    const drawerMiniWidth = 85;

    const useStyles = makeStyles(theme => ({
        drawerIcon: {
          fontSize: '26px',
          //backgroundColor: '#ff0000',
          paddingRight: '2px',
        },
        drawerSmallIcon: {
          fontSize: '18px',
        },
        timeTrack: {
          position: 'absolute', 
          bottom: '10px',
          width: drawerMiniWidth,
          color: theme.palette.grey[600],
          fontSize: '12px',
        },
        boxWithIcon:{
          width: drawerMiniWidth,
          color: theme.palette.grey[800],
          fontSize: '12px',
          '&:hover': {
              color: theme.palette.primary.main,
              fontWeight: '700',
              cursor: 'pointer',
          }
        },
        boxWithIconActive: {
          width: drawerMiniWidth,
          color: theme.palette.primary.main,
          fontWeight: '700',
          fontSize: '12px',
        },
        boxWithIconActiveSecondary: {
            width: drawerMiniWidth,
            color: theme.palette.secondary.main,
            fontWeight: '700',
            fontSize: '12px',
        },
        activeListItem: {
            backgroundColor: theme.palette.grey[100]
        },
        listItemLevel1: {
            fontWeight: '600',
            color: theme.palette.primary.main,
        },
      }));
      const classes = useStyles();

    switch(props.type) {
        case 'mini': 
            return (
            <div>
                {props.list === null ?
                <Box>
                    {['1','2','3'].map((item, i) => (
                        <React.Fragment key={i}>
                        <Box mt={3} align="center">
                            <Skeleton variant="rect" width={30} height={30} style={{borderRadius: '10px'}} />
                        </Box>
                        <Box mt={1} align="center">
                            <Skeleton variant="rect" width={40} height={10} style={{borderRadius: '10px'}} />
                        </Box>
                        </React.Fragment>
                    ))}
                </Box>
                :
                 props.list.filter((l) => !l.hide).map((l) => (
                    <Box key={l.page} 
                        className={
                            props.page === l.page ? 
                                classes.boxWithIconActive : classes.boxWithIcon} align="center" mt={3}
                        onClick={l.click}
                        id={l.id !== undefined && l.id !== null ? l.id : l.page}>
                    <Badge color="secondary" variant="dot" invisible={!l.badge}>
                        <FontAwesomeIcon icon={l.icon} className={classes.drawerIcon} 
                        style={l.changeColor === 'secondary' ? { color: theme.palette.secondary.main } : {}}
                        />
                    </Badge>
                    <Box mt={1} style={l.changeColor === 'secondary' ? {color: theme.palette.grey[800] } : {}}>{l.title}</Box>
                    </Box>
                ))}
                {props.toTopButton && props.trigger ? 
                <Box className={classes.boxWithIcon} align="center" 
                style={{position: 'absolute', bottom: (props.module === 'legal' ? '20px' : '50px')}} 
                onClick={e => window.scrollTo(0,0)}
                >
                <FontAwesomeIcon icon={faAngleDoubleUp} className={classes.drawerSmallIcon} />
                    <Box mt={1}>To top</Box>
                </Box>
                : '' }
                {props.agrID !== undefined && props.agrID !== null && props.agrID.length > 10 && props.user !== undefined ?
                <Box className={classes.timeTrack} align="center">
                    <TimeTrack aid={props.agrID} uemail={props.user.email} uid={props.user._id} uname={props.user.displayName + ' (' + props.orgName + ')'} 
                    rid={props.rid} orgID={props.orgID} isPrimary={props.isPrimary}
                    module={props.module} cpmodule={props.cpmodule} timeBase={props.timeBase} pushTime={props.pushTime}  />
                </Box>
                : '' }
            </div>
            )
        case 'list':
        default:
            return (
            <div>
                {props.list.filter((l) => !l.hide).map((l) => (
                    <ListItem 
                    button 
                    key={l.page} 
                    className={props.page === l.page ? classes.activeListItem : ''}
                    onClick={l.click}
                    >
                    <ListItemText 
                        disableTypography 
                        primary={<Typography className={classes.listItemLevel1}>{l.title} {l.icon !== undefined && l.icon !== null ?<FontAwesomeIcon icon={l.icon} /> : ''}</Typography>} 
                    />
                    </ListItem>
                ))
                }
            </div>
            )
    }
}

export default DrawerCoreRender