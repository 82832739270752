const axios = require('axios');

// SMARTFIELDS

export const updateSmartField = (sfid, osfid, ref, name, type, val1, val2, val3, createWizard, lastUpdateBy) => {
  return dispatch => {
    // FAF : Fire & Forget
    let lastUpdateDate = new Date().toISOString();
    
    axios.put('/sf/sf/' + sfid, { 
        osfid: osfid,
        ref: ref,
        name: name,
        type: type,
        val1: val1,
        val2: val2,
        val3: val3,
        createWizard: createWizard  ,
        lastUpdateBy: lastUpdateBy,
        lastUpdateDate: lastUpdateDate,
    })
  }
};