import * as ActionTypes from '../ActionTypes';

import { fetchOrgByID, updateStripeBillingQty,
         fetchSubsByOrgID, fetchCpentsByOrgID, 
         fetchCpentsSuccess, fetchSubsSuccess,
         fetchOrgProfileSubsSuccess,
         fetchRelsByOrgID, fetchRelsSuccess,
         fetchOrgAgrTemplatesByOrgID, 
         fetchNotificationsByUID,
         fetchCertsByOrgID, fetchCertsSuccess, fetchOrgProfileCertsSuccess,
         fetchNotificationsSuccess,
         fetchUserOrgSuccess, fetchOrgProfileSuccess,
         fetchOrgAgrTemplatesSuccess, fetchOrgProfileAgrTemplatesSuccess, fetchOrgLabelsByOrgID,
         fetchAgrTypes,
         createNotification,
         defineSessionExpiry  } from '.';

const axios = require('axios');

export function fetchLoggedInUser() {
    return dispatch => {
        dispatch(fetchLoggedInUserPending());

        axios.get('/auth/loggedinuser')
        .then(function(res) {
          if(res.data.data !== null) {
            if(res.data.data.password !== null && res.data.data.password.length < 25 && (res.data.data.provider === undefined || res.data.data.provider === null)) {
              dispatch(fetchLoggedInUserSuccess({ ...res.data.data, customLink: true }));
            } else {
              dispatch(fetchLoggedInUserSuccess(res.data.data));
            }
            // now dispatch actions to get the Org Data, users and custs for the ORG
            dispatch(fetchOrgByID(res.data.data.orgID));
            dispatch(fetchUsersByOrgID(res.data.data.orgID));
            dispatch(fetchAgrTypes(res.data.data.orgID))
            dispatch(fetchCpentsByOrgID(res.data.data.orgID));
            dispatch(fetchSubsByOrgID(res.data.data.orgID, res.data.data.role === 'Counterparty'));
            dispatch(fetchRelsByOrgID(res.data.data.orgID));
            dispatch(fetchNotificationsByUID(res.data.data._id)); // by UID
            dispatch(fetchOrgAgrTemplatesByOrgID(res.data.data.orgID));
            dispatch(fetchOrgLabelsByOrgID(res.data.data.orgID))
            dispatch(fetchCertsByOrgID(res.data.data.orgID));
            dispatch(defineSessionExpiry(res.data.expiry));
          } else {
            dispatch(fetchAgrTypes('CANVEO'))
            dispatch(fetchLoggedInUserError("not logged in"))
          }
        })
        .catch(function(err) {
          dispatch(fetchAgrTypes('CANVEO'))
          dispatch(fetchLoggedInUserError(err));
        })
    }
}

export function logoutUser(uid) {
  return dispatch => {
    if(uid !== 'none') { dispatch(updateLastLogin(uid)) }
    dispatch(fetchCertsSuccess([]));
    dispatch(fetchOrgProfileCertsSuccess([]));
    dispatch(fetchSubsSuccess([]));
    dispatch(fetchCpentsSuccess([]));
    dispatch(fetchOrgProfileSubsSuccess([]));
    dispatch(fetchNotificationsSuccess([]));
    dispatch(fetchUserOrgSuccess({}));
    dispatch(fetchOrgProfileSuccess({}));
    dispatch(fetchOrgAgrTemplatesSuccess([]));
    dispatch(fetchOrgProfileAgrTemplatesSuccess([]));
    dispatch(fetchRelsSuccess([]));
    dispatch(fetchUsersSuccess([]));
    dispatch(fetchLoggedInUserSuccess({}));
  }
}

export const updateUser = (uid, firstName, lastName, title, phone, photoURL, defaultModule, agrIDs) => {
  return dispatch => {
    dispatch(fetchLoggedInUserPending());
    
    axios.put('/user/protect/user/' + uid, {
      firstName: firstName,
      lastName: lastName, 
      title: title,
      phone: phone,
      photoURL: photoURL,
      defaultModule: defaultModule,
      agrIDs: agrIDs,
    })
    .then(function (res) { // success
        axios.get('/auth/loggedinuser')
        .then(function(resusr) {
          if(res.data.data !== null) {
            dispatch(fetchLoggedInUserSuccess(resusr.data.data));
          }
        })
        .catch(function (err) { // err
          dispatch(fetchLoggedInUserError(err))
        })
    })
    .catch(function (err) { // err
      dispatch(fetchLoggedInUserError(err))
    })
  }
};

export const updateAidForUserAgrIDs = (type, uid, aid) => {

  if(type === 'insert') {
    return dispatch => {
      axios.put('/user/protect/pushaid/' + uid, {
        aid: aid,
      })      
    }
  } else if(type === 'delete') {
    return dispatch => {
      axios.put('/user/protect/revokeaid/' + uid, {
        aid: aid,
      })      
    }
  }

}

export const updateUserRole = (uid, orgid, newRole, defaultModule, readOnly, title, faf) => {
  return dispatch => {
    axios.put('/user/protect/role/' + uid, {
      role: newRole,
      defaultModule: defaultModule,
      readOnly: readOnly,
      title: title
    })
    .then(function (res) { // success
      if(!faf) { dispatch(fetchUsersByOrgID(orgid)) }
    })
    .catch(function (err) { // err
      dispatch(fetchUsersError(err))
    })       
  }
};

export const updateLastLogin = (uid) => {
  return dispatch => {

    let lastLoginDate = new Date().toISOString();
    axios.put('/user/protect/logindate/' + uid, {
      lastLoginDate: lastLoginDate
    })
  }
};

export const updateIntroComplete = (uid, introComplete) => {
  return dispatch => {
    
    axios.put('/user/protect/introcomplete/' + uid, {
      introComplete: introComplete
    })
    .then(function (res) { // success
        axios.get('/auth/loggedinuser')
        .then(function(resusr) {
          if(res.data.data !== null) {
            dispatch(fetchLoggedInUserSuccess(resusr.data.data));
          }
        })
        .catch(function (err) { // err
          dispatch(fetchLoggedInUserError(err))
        })
    })
    .catch(function (err) { // err
      dispatch(fetchLoggedInUserError(err))
    })
  }
}

export const fetchLoggedInUserPending = () => ({
  type: ActionTypes.FETCH_LOGGEDINUSER_PENDING
});

export const fetchLoggedInUserError = (error) => ({
  type: ActionTypes.FETCH_LOGGEDINUSER_ERROR,
  payload: error
});

export const fetchLoggedInUserSuccess = (user) => ({
  type: ActionTypes.FETCH_LOGGEDINUSER_SUCCESS,
  payload: user
});

export function fetchUsersByOrgID(orgid) {
  return dispatch => {
      dispatch(fetchUsersPending());

      axios.get('/user/protect/users/' + orgid)
      .then(function(res) {
        dispatch(fetchUsersSuccess(res.data.data));
      })
      .catch(function(err) {
        dispatch(fetchUsersError(err));
      })
  }
}

export const fetchUsersPending = () => ({
  type: ActionTypes.FETCH_USERS_PENDING
});

export const fetchUsersError = (error) => ({
  type: ActionTypes.FETCH_USERS_ERROR,
  payload: error
});

export const fetchUsersSuccess = (users) => ({
  type: ActionTypes.FETCH_USERS_SUCCESS,
  payload: users
});

export const createUser = (creationBy, orgid, firstName, lastName, title, email, password, role, defaultModule, readOnly, photoURL, agrIDs, orgShortName, adminDisplayName) => {
  return dispatch => {    
    let displayName = firstName + ' ' + lastName;
    let creationDate = new Date().toISOString();
    axios.post('/user/protect/user', {
      orgID: orgid,
      firstName: firstName,
      lastName: lastName,
      displayName: displayName,
      email: email.toLowerCase().trim(),
      password: password,
      title: title,
      role: role,
      defaultModule: defaultModule,
      readOnly: readOnly,
      photoURL: photoURL,
      creationBy: creationBy,
      creationDate: creationDate,
      active: true,
      agrIDs: agrIDs,
    })
    .then(function (res) { // got a msg from the server
      if(res.data.success) { // successfully added
        dispatch(createNotification(
          'Welcome new User', // notificationType
          'Welcome to Canveo', // title 
          firstName + ', we\'re excited to have you on board', // description
          '/core/help/welcome', // linkTo
          false, // isRead
          res.data.data._id, // uid
        ))
        axios.post('/mail/adduser/' + email, { // Send email FAF
          userFirstName: firstName,
          orgShortName: orgShortName,
          adminDisplayName: adminDisplayName,
          randomString: password,
        })

        if(role !== 'Counterparty') {
          dispatch(addUser(res.data.data));
          dispatch(updateStripeBillingQty(orgid)); // Also to update the stripe billing qty
        }
      } else if (role !== 'Counterparty') { // something went wrong while adding
        dispatch(fetchUsersError(res.message))
      }
    })
    .catch(function (err) { // err in the server request
      dispatch(fetchUsersError(err))
    })       
  }
};

export const addUser = (user) => ({
  type: ActionTypes.ADD_USER,
  payload: user
});

export const deleteUser = (uid, deletedBy, orgid, faf) => {
  return dispatch => {
    let deletedDate = new Date().toISOString();
    axios.put('/user/protect/userdel/' + uid, {
      uid: uid,
      deletedBy: deletedBy,
      deletedDate: deletedDate,
    })
    .then(function(res) {
      if(!faf) { 
        dispatch(fetchUsersByOrgID(orgid)) 
        dispatch(updateStripeBillingQty(orgid));
      }
    })
    .catch(function(err) {
      dispatch(fetchUsersError(err))
    })
  }
}

export const removeUser = (user) => ({
  type: ActionTypes.REMOVE_USER,
  payload: user
});