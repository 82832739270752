function getAcronym(fullString) {

    let ts = fullString
    ts = ts.replace(/\u00dc/g, "UE") // Ü
    ts = ts.replace(/\u00c4/g, "AE") // Ä
    ts = ts.replace(/\u00d6/g, "OE") // Ö
    ts = ts.replace(/\u00fc/g, "ue") // ü
    ts = ts.replace(/\u00e4/g, "ae") // ä
    ts = ts.replace(/\u00f6/g, "oe") // ö
    ts = ts.replace(/\u00df/g, "ss") // ß

    let matches = ts.match(/\b([A-Za-z])/g); // regex to capture acronym - also used inside "New.js"
    let s = matches !== null && matches.length > 1 ? matches.join('') : ts.substr(0,3).toUpperCase();

    return s;
}

export { getAcronym }