import React, { useEffect } from 'react';
import theme from '../theme/theme';
import clsx from 'clsx';
import { clauseOpenCheck, convertBlocksToPlain, stripPlain, didBlocksChange, trunc } from '../utils'
import { Avatar, Badge, Box, Chip, Grid, IconButton, 
         Typography, Tooltip, makeStyles, CircularProgress } from '@material-ui/core';
import { ChangeDecisionButtons, MenuMoreOptions, NegoCardEditor,
         TableEditable } from '.';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faComments, faThumbsUp, faHistory, faEye, faPenAlt, faRedo, faLock } from "@fortawesome/free-solid-svg-icons";
import { isAndroid } from 'react-device-detect';

const axios = require('axios');

const NegoCard = (props) => {
    
    const useStyles = makeStyles(theme => ({
        card: {
            borderRadius: '0px',
            border: '0px',
            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 3px 24px 0px, rgba(0, 0, 0, 0.02) 0px 0px 0px 1px', // OPTION 2
            color: theme.palette.grey[900],
            [theme.breakpoints.down('xs')]: { width: '330px', padding: '15px 0px 10px 0px' },
            [theme.breakpoints.only('sm')]: { width: '460px', padding: '20px 25px 15px 25px' },
            [theme.breakpoints.only('md')]: { width: '580px', padding: '20px 25px 15px 25px', margin: '15px 0px 15px 0px' },
            [theme.breakpoints.up('lg')]: { width: '700px', padding: '20px 30px 15px 30px', margin: '15px 0px 15px 0px' },
        },
        cardInactive: {
          borderRadius: '3px',
          border: '0px', 
          color: theme.palette.grey[900],
          [theme.breakpoints.down('xs')]: { width: '330px', padding: '10px 0px 10px 0px' },
          [theme.breakpoints.only('sm')]: { width: '460px', padding: '15px 25px 15px 25px' },
          [theme.breakpoints.only('md')]: { width: '580px', padding: '0px 25px 0px 25px', margin: '10px 0px 10px 0px' },
          [theme.breakpoints.up('lg')]: { width: '700px', padding: '0px 30px 0px 30px', margin: '10px 0px 10px 0px' },
        },
        cardHide: {
          display: 'none'
        },
        expand: {
            fontSize: '16px',
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            marginTop: '9px',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
            color: theme.palette.grey[400],
        },
        expandOpen: {
            transform: 'rotate(180deg)',
            color: theme.palette.grey[600],
            marginTop: '11px',
        },
        morePaddingBottom: {
          padding: '20px 20px 20px 20px',
          fontWeight: '300',
          [theme.breakpoints.down('xs')]: { fontSize: '13px' },
          [theme.breakpoints.only('sm')]: { fontSize: '14px' },
          [theme.breakpoints.up('md')]: { fontSize: '16px' },
          lineHeight: '1.5',
        },
        lessPaddingTop: {
          padding: '0px 20px 5px 20px',
          fontWeight: '300',
          [theme.breakpoints.down('xs')]: { fontSize: '13px' },
          [theme.breakpoints.only('sm')]: { fontSize: '14px' },
          [theme.breakpoints.up('md')]: { fontSize: '16px' },
          lineHeight: '1.5',
        },
        lessPaddingBottom: {
          marginTop: '0px',
          marginBottom: '0px',
          paddingBottom: '0px',
          textAlign: 'right',
        },
        chipDefault1: {
          [theme.breakpoints.down('xs')]: { fontSize: '10px', padding: '0px 4px 0px 4px', marginRight: '0px', lineHeight: '12px' },
          [theme.breakpoints.only('sm')]: { fontSize: '11px', padding: '1px 4px 1px 4px', marginRight: '0px', },
          [theme.breakpoints.only('md')]: { fontSize: '12px', padding: '1px 6px 1px 6px', marginRight: '0px', },
          [theme.breakpoints.up('lg')]: { fontSize: '12px', padding: '1px 6px 1px 6px', marginRight: '0px',  },
          fontWeight: '400',
        },
        chipDefault2: {
          [theme.breakpoints.down('xs')]: { fontSize: '10px', padding: '0px 4px 0px 4px', marginLeft: '3px', lineHeight: '12px' },
          [theme.breakpoints.only('sm')]: { fontSize: '11px', padding: '1px 4px 1px 4px', marginLeft: '8px', },
          [theme.breakpoints.only('md')]: { fontSize: '12px', padding: '1px 6px 1px 6px', marginLeft: '8px', },
          [theme.breakpoints.up('lg')]: { fontSize: '12px', padding: '1px 6px 1px 6px', marginLeft: '8px',  },
          fontWeight: '400',
        },
        iconInChip: {
            [theme.breakpoints.down('xs')]: { display: 'none', },
            fontSize: '10px',
        },
        clauseTypeBlock: {
          margin: '0px 0px 20px 0px'
        },
        clauseTypeBlockInactive: {
          margin: '30px 17px 0px 9px'
        },
        histIcon: {
          color: theme.palette.grey[600],
          padding: '11px 11px 11px 11px',
          fontSize: '14px',
          marginTop: '1px'
        },
        lockIconGrey: {
          display: 'inline',
          color: theme.palette.grey[600],
          padding: '13px',
          fontSize: '14px',
        },
        lockIconPrim: {
          display: 'inline',
          color: theme.palette.primary.main,
          padding: '13px',
          fontSize: '14px',
        },
        histIconPrim: {
          color: theme.palette.primary.main,
          padding: '11px 11px 11px 11px',
          fontSize: '14px',
          marginTop: '1px'
        },
        propDel: {
          textDecoration: 'line-through', 
          color: theme.palette.error.main
        },
        propDelPrim: {
          textDecoration: 'line-through', 
          color: theme.palette.primary.main
        },
        propDelSec: {
          textDecoration: 'line-through', 
          color: theme.palette.secondary.main
        },
        starIcon: {
          color: '#f9d978'
        },
        starBox: {
          margin: '0px 0px 0px 0px',
        },
        lockBox: {
          width: '280px',
        }
    }));
    const classes = useStyles();  

    const [blockStyleButton, setBlockStyleButton] = React.useState(null)
    const [clause, setClause] = React.useState(props.cl);
    const [active, setActive] = React.useState(props.active);
    const [isLocked, setIsLocked] = React.useState(props.cl.lockedBy !== undefined && props.cl.lockedBy !== null && props.cl.lockedBy !== '' && clause.lockedBy !== props.user._id)
    const [cleanMode, setCleanMode] = React.useState(props.plainMode ? true : false)
    const [reconcileNeeded, setReconcileNeeded] = React.useState(false) // Whether the clause needs to be refreshed (ie. collaborative editing scenario)
    const [hideCard, setHideCard] = React.useState(false)
    
    useEffect(() => {

      const updateClauseStatus = props.updateClauseStatus
      let cStatus = 'Accepted'
      if(clause.clauseStatus === 'proposedDel') { cStatus = 'proposedDel' }
      else if(clause.clauseStatus === 'Deleted') { cStatus = 'Deleted' }
      else if(clauseOpenCheck(clause.blocks, props.cl.commentStatus, props.cl.approvalStatus, clause.pendingChange, clause.clauseObject)) { cStatus = 'Open' }

      if(cStatus !== clause.clauseStatus) { // applicable when you change the clause status through the comments or approval dialog
        updateClauseStatus(
          clause._id, // singleClauseID
          'clause', // type
          cStatus, // newStatus
          props.user._id) // lastUpdateBy
      }

      setClause(cl => ({...cl, 
          clauseStatus: cStatus, 
          commentStatus: props.cl.commentStatus, 
          approvalStatus: props.cl.approvalStatus,
          internalWorkflow: props.cl.internalWorkflow }))

    }, [props.cl.clauseStatus, props.cl.commentStatus, props.cl.approvalStatus, props.cl.internalWorkflow,
        props.user._id, clause.blocks, clause.clauseObject, clause._id, clause.clauseStatus, clause.pendingChange, props.updateClauseStatus])

    useEffect(() => { // UseEffect to (re)apply the filter as appropriate

      setHideCard(clause.clauseStatus === 'Deleted' || (
        (props.toggleFilter === "clause" && ['Accepted'].includes(clause.clauseStatus) && (clause.internalWorkflow === undefined || !clause.internalWorkflow.some((i) => i.status === 'Open' && i.changeOrg === props.curCpty))) ||
        (props.toggleFilter === "changes" && (props.clauseActivityLog === undefined || props.clauseActivityLog.length === 0)) ||
        (props.toggleFilter === "internalcomments" && (!clause.internalWorkflow.some((i) => i.changeOrg === props.curCpty))) ||
        (props.toggleFilter === "apprequest" && !props.appRequestTicket) ||
        (props.toggleFilter === "appassignee" && !props.appAssigneeTicket) ||
        (props.toggleFilter === "lockedByOrg" && (clause.lockedByOrg === undefined || clause.lockedByOrg === null || clause.lockedByOrg.orgID === undefined))))

    }, [props.toggleFilter, clause.clauseStatus, clause.internalWorkflow, props.clauseActivityLog, props.curCpty])

    useEffect(() => { // UseEffect for determining and applying the clause lock
      let oldclblocks = clause.blocks

      if(active !== props.active && clause !== null && !props.laterVersionOwnedByCpty && !props.user.readOnly &&
        !props.plainMode && !['none', 'comment'].includes(props.editMode)) {

        axios.post('/clause/protect/clauselock', {
          clid: clause._id,
          lockedBy: props.active ? props.user._id : '',
          lastUpdateBy: props.user._id,
          lastUpdateDate: new Date().toISOString(),
        })
        .then((rescl) => {
          if(props.active) {
            if(rescl.data.data.clauseStatus === 'Deleted') { setHideCard(true) }
            setIsLocked(rescl.data.data.lockedBy !== undefined && rescl.data.data.lockedBy !== null && 
              rescl.data.data.lockedBy !== '' && rescl.data.data.lockedBy !== props.user._id)
            /*setClause({...clause, 
              approvalStatus: rescl.data.data.approvalStatus,
              blocks: rescl.data.data.blocks,
              clauseObject: rescl.data.data.clauseObject,
              clauseStatus: rescl.data.data.clauseStatus,
              commentStatus: rescl.data.data.commentStatus,
              lockedBy: rescl.data.data.lockedBy,
              lockedByOrg: rescl.data.data.lockedByOrg,
              pendingChange: rescl.data.data.pendingChange,
              plain: rescl.data.data.plain,
              version: rescl.data.data.version})*/
            setClause(rescl.data.data)
            if(didBlocksChange(oldclblocks, rescl.data.data.blocks) || // Only reconcile if there was a change
            rescl.data.data.clauseCat === 'table') { // Always reconcile tables upon load
              setReconcileNeeded(true)
            }

          }
          setActive(props.active)

        }).catch((err) => { console.log(err) })

      } else if(props.laterVersionOwnedByCpty || props.user.readOnly || props.plainMode || ['none', 'comment'].includes(props.editMode)) {
        setIsLocked(false);
        setActive(props.active)
      }
    }, [props.active])

    const pcPDel = clause.pendingChange !== undefined && clause.pendingChange.length > 0 && clause.pendingChange.filter((pc) => pc.status === 'Open' && ['proposedDel'].includes(pc.type))[0] !== undefined ? 
      clause.pendingChange.filter((pc) => pc.status === 'Open' && pc.type === 'proposedDel')[0] : null

    const hasComments = ['Open', 'Resolved'].includes(clause.commentStatus) || (clause.internalWorkflow !== undefined && 
      clause.internalWorkflow.filter((i) => i.changeOrg === props.curCpty && i.type === 'comment' && ['Resolved','Open'].includes(i.status))[0] !== undefined)

    const openComments = (clause.commentStatus === 'Open') || 
      (clause.internalWorkflow !== undefined && clause.internalWorkflow.filter((i) => i.changeOrg === props.curCpty && i.type === 'comment' && i.status === 'Open')[0] !== undefined) 
      //||
      //(props.plainMode && clause.commentStatus === 'Resolved') ||
      //(props.plainMode && clause.internalWorkflow !== undefined && clause.internalWorkflow.filter((i) => i.changeOrg === props.curCpty && i.type === 'comment' && i.status === 'Resolved')[0] !== undefined);

    const isLockedByOrgForThisUser = !(clause === undefined || clause === null || clause.lockedByOrg === undefined || clause.lockedByOrg === null || 
      (clause.lockedByOrg !== undefined && clause.lockedByOrg.type === 'none') ||
      (clause.lockedByOrg !== undefined && ['cpty'].includes(clause.lockedByOrg.type) && clause.lockedByOrg.orgID === props.user.orgID) ||
      (clause.lockedByOrg !== undefined && ['full'].includes(clause.lockedByOrg.type) && clause.lockedByOrg.orgID === props.user.orgID && ['Admin', 'Legal'].includes(props.user.role)) ||
      (clause.lockedByOrg !== undefined && ['internal'].includes(clause.lockedByOrg.type) && (clause.lockedByOrg.orgID !== props.user.orgID || ['Admin', 'Legal'].includes(props.user.role))))

    const disableEditing = (isLocked || isLockedByOrgForThisUser || props.laterVersionOwnedByCpty || props.user.readOnly || clause.clauseStatus === 'proposedDel' || props.plainMode || isAndroid)

    const handleClauseMoreOptions = (type, clid) => {

      if(type === 'delete') { // Delete => Proposed Delete and create a Pending

        let newStatus = 'proposedDel'
        let newChange = { status: 'Open', type: 'proposedDel', changeOrg: props.curCpty, uid: props.user._id, oldValue: clause.clauseStatus }
        let pendingChange = clause.pendingChange !== undefined && clause.pendingChange.length > 0 ? 
                            clause.pendingChange : [];
        pendingChange.push(newChange)
        
        setClause(cl => ({...cl, 
          clauseStatus: newStatus,
          pendingChange: pendingChange }))

        props.updatePendingChange(clid, pendingChange, newStatus, /*clause.title, */clause.clauseObject, props.user._id)
        props.callbackOptions(null, 'wasEdited', null)

        if(!props.templating) { props.handleActivityLog('clause', 'clProposedDelete', clause.singleClauseID, '', props.avv, props.user.displayName, {}) } // logLevel, logType, singleClauseID, ticketID, keyVal1, keyVal2

      } else if(['insert'].includes(type)) {
         props.handleClauseEditMode('insert', clid);

      } else if(['moveClause', 'moveTable', 'levelClause'].includes(type)) {
        props.handleClauseEditMode(
          ['levelClause'].includes(type) ? 'levelClause' : 
          ['moveTable'].includes(type) ? 'moveTable' : 'moveClause', clid);

      } else if(type === 'lightbulb') {
        props.callbackOptions(null, 'lightbulbOpen', null)

      } else if (type === 'reviveNumbering') {
        clauseNumberingUpdate(type)

      } else if(type === 'insertTitle') {
        props.callbackOptions(clause._id, 'insertTitle', null)

      } else if(type.startsWith("newlock_")) {

        let nl = type.substr(8)

        let newLockedByOrg = nl === 'none' ? { type: nl } : { orgID: props.user.orgID, type: nl }
        axios.put('/clause/protect/clauseorglock/' + clid, {
          lockedByOrg: newLockedByOrg,
          lastUpdateBy: props.user._id,
          lastUpdateDate: new Date().toISOString(),
        })
        .then((rescl) => {
          setClause(cl => ({...cl, lockedByOrg: newLockedByOrg }))
        }).catch((err) => { console.log(err)} )
      }

    }

    const handleTableUpdate = (tableKey, newTable) => {

      let clauseIsOpen = clauseOpenCheck(clause.blocks, clause.commentStatus, clause.approvalStatus, clause.pendingChange, newTable)
      let newStatus = ['proposedDel', 'Deleted'].includes(clause.clauseStatus) ? clause.clauseStatus : clauseIsOpen ? 'Open' : 'Accepted'

      if(newStatus !== clause.clauseStatus){
        props.updateClauseStatus(
          clause._id, // singleClauseID
          'clause', // type
          newStatus, // newStatus
          props.user._id) // lastUpdateBy
  
        setClause(cl => ({...cl, clauseStatus: newStatus, clauseObject: newTable }))
      }
    }

    const handleCleanMode = () => {
      setCleanMode(!cleanMode);
    }

    const handleClauseRefresh = () => {

      axios.post('/clause/protect/clauselock', {
        clid: clause._id,
        lockedBy: props.user._id,
        lastUpdateBy: props.user._id,
        lastUpdateDate: new Date().toISOString(),
      })
      .then((rescl) => {
        if(rescl.data.data.clauseStatus === 'Deleted') { setHideCard(true) }
        setIsLocked(rescl.data.data.lockedBy !== undefined && rescl.data.data.lockedBy !== null && rescl.data.data.lockedBy !== '' && rescl.data.data.lockedBy !== props.user._id)
        setClause(rescl.data.data)
        setReconcileNeeded(true) 

      }).catch((err) => { console.log(err)} )

    }

    const focusClause = (e) => {
      if(!active && !['none'].includes(props.editMode)) { 
        props.callbackOptions(clause._id, 'toggleActivateClause', true)
      }
    }

    const deactivateClause = (e) => {
      if(active) {
        props.callbackOptions(clause._id, 'toggleActivateClause', false)
      }
    }

    const clauseNumberingUpdate = (value) => {

      if((clause.numberType === 'Auto' && value !== props.no) || 
         (clause.numberType === 'Manual' && value !== clause.numberManual) ||
         (clause.numberType === 'None' && value !== '')) {

        let oldNo = clause.numberType !== 'Auto' ? clause.numberManual : props.no
        let noType = (value === '' ? 'None' : value === 'reviveNumbering' ? 'Auto' : 'Manual');
        let noManual = (value === 'reviveNumbering' ? '' : value);
        
        setClause(cl => ({...cl, numberType: noType, numberManual: noType === 'Auto' ? props.no : noManual })) // set noManual for auto to props.no as it doesn't update the inputBase defaultValue otherwise

        props.updateClauseNumbering(clause._id, noType, noManual, props.user._id)

        if(!props.templating) { props.handleActivityLog('clause', 'clNumberChange', clause.singleClauseID, '', props.avv, props.user.displayName, { old: oldNo, new: value }) } // logLevel, logType, singleClauseID, ticketID, keyVal1, keyVal2
      }
    }

    const updateClauseBlocks = (blocks) => {
            
      if(didBlocksChange(clause.blocks, blocks)) {

        let newPlain = convertBlocksToPlain(blocks); // Get new Plain version from blocks
        let cStatus = clause.clauseStatus
        if(clauseOpenCheck(blocks, clause.commentStatus, clause.approvalStatus, clause.pendingChange, clause.clauseObject)) { cStatus = 'Open'; } // Check for all blocks if there is any open entity
        props.updateClauseContentFAF ( // Update clause in DB (Fire & Forget)
          clause._id, // clid 
          cStatus, // clauseStatus
          blocks, // blocks
          newPlain, // plain
          clause.clauseObject, // clauseObject
          clause.version + 1, // version
          props.user._id // lastUpdateBy
        )
        if(!props.templating) { props.handleActivityLog('clause', 'clNewVersion', clause.singleClauseID, '', props.avv, '', { old: clause.blocks, new: blocks }) } // logLevel, logType, singleClauseID, ticketID, keyVal1, keyVal2

        setClause(cl => ({...cl, blocks: blocks, clauseStatus: cStatus, plain: newPlain }))
        props.callbackOptions(null, 'wasEdited', null)

      }
    }

    const negoCallBackOptions = (child, action, optionalHint) =>  {
      // An interim (mezzanine) layer to filter out any unnecessary callbacks that cause Agreement rerendering
      if(['clickHandled', 'previousClause', 'nextClause', 'acceptagr'/*, 'createSmartFields'*/].includes(action)) { // Never filter
        props.callbackOptions(child, action, optionalHint)

      } else if (['toggleActivateClause'].includes(action) && optionalHint === null) {
        deactivateClause()

      } else if (['reconcileHandled'].includes(action)) {
        setReconcileNeeded(false);

      } else if(['blockStyleButton'].includes(action) && blockStyleButton !== optionalHint) {
        setBlockStyleButton(optionalHint)

      } else if(['applyClauseTopics'].includes(action)) {
        // Update clauseTypes locally
        setClause(cl => ({...cl, clauseTypes: optionalHint }))
        // Update clauseTypes FAF
        axios.put('/clause/protect/clausetypes/' + child, { clauseTypes: optionalHint})
      }
    }

    const handlePDel = (clid, action) => { // DECISION FOR CLAUSES

      let newStatus = 'Open'
      let pendingChange = clause.pendingChange !== undefined && clause.pendingChange.length > 0 ? 
                            clause.pendingChange.filter((pc) => pc.type !== 'proposedDel') : [];

      if (action === 'delete') {
        newStatus = 'Deleted';
        props.updateAgrvRemoveClause(props.templating ? props.oatID : props.avID, clid, false) // trigger delete from avc
        if(!props.templating) { // logLevel, logType, singleClauseID, ticketID, keyVal1, keyVal2, change
          //let oldClause = ;
          let oldClause = '';
          if(['cl','cl1','cl2','cl3','cl4','preamble'].includes(clause.clauseCat)){
            clause.plain.forEach((cpar,i) => { oldClause = oldClause + (oldClause !== '' ? "\n" : '') + stripPlain(cpar); })
          } else if(['table'].includes(clause.clauseCat)) { oldClause = "[Table] " + clause.title + (clause.title !== "" ? "." : "") }
          props.handleActivityLog('agr', 'clDeleted', clause.singleClauseID, '', props.avv, props.user.displayName, { old: oldClause }) 
        } 

        props.smartFields.filter((sf) => sf.singleClauseID === clause.singleClauseID).forEach((sf) => {

          props.updateSmartField( // Delete any smartfields for this clause
            sf._id, // sfid
            sf.osfid, // osfid
            'deleted', // ref
            sf.name, // name
            sf.type, // type
            sf.val1, // val1
            sf.val2, // val2
            sf.val3, // val3
            sf.createWizard, // createWizard
            props.user._id, // lastUpdateBy
          )
        })
        setHideCard(true)
        
      }

      if (action === 'cancel') { 
        props.callbackOptions(null, 'wasEdited', null)
        if(!props.templating) { props.handleActivityLog('clause', 'clDeleteCanceled', clause.singleClauseID, '', props.avv, props.user.displayName, {}) }
      }

      setClause(cl => ({...cl, 
        clauseStatus: newStatus,
        pendingChange: pendingChange }))

      props.updatePendingChange(clid, pendingChange, newStatus, /*clause.title, */clause.clauseObject, props.user._id)
      props.callbackOptions(clid, "closeRedlines", null)

    }

    return (
      <div
      onClick={!active && (disableEditing || ['comment'].includes(props.editMode)) ? e => focusClause() : null}
      variant="outlined" 
      className={hideCard || clause.clauseStatus === 'Deleted' ? classes.cardHide : active || props.toggleFilter !== null ? classes.card : classes.cardInactive}>

        {// The clause is locked by another user - show block
        (active && isLocked) && props.users.filter((u) => u._id === clause.lockedBy)[0] !== undefined ?
        <Box mb={0} mt={3} align="center">
          <Grid direction="row" container justify="space-between" alignItems="center" className={classes.lockBox}>
            <Grid item>
              <Avatar
              src={props.users.filter((u) => u._id === clause.lockedBy)[0].photoURL}
              alt={props.users.filter((u) => u._id === clause.lockedBy)[0].displayName}
              />
            </Grid>
            <Grid item>
              <Box ml={2} mr={2} align="left">
              <Typography variant="subtitle2">{trunc(props.users.filter((u) => u._id === clause.lockedBy)[0].displayName,22)}</Typography>
              <Typography variant="body2" color="textSecondary">Currently editing this clause</Typography>
              </Box>
            </Grid>
            <Grid item>
              <IconButton className={classes.histIcon} onClick={e => handleClauseRefresh()}>
                <FontAwesomeIcon icon={faRedo} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        :''}

        <div className={classes.lessPaddingBottom}>
          {active && ['edit', 'full'].includes(props.editMode)/* && !props.laterVersionOwnedByCpty && !props.plainMode*/ ?
              <IconButton
                className={clsx(classes.expand, { [classes.expandOpen]: active })}
                onClick={active ? deactivateClause : focusClause}>
                <FontAwesomeIcon icon={faChevronDown} />
              </IconButton>
            : '' }
        </div>

        <div 
        className={(!active && props.toggleFilter !== null) || (active && ['comment'].includes(props.editMode)) ? classes.morePaddingBottom : classes.lessPaddingTop} // Give a "push down" for filtered clauses that are not active
        style={!active && props.editMode !== 'none' ? {cursor: 'pointer'} : null}>
            <>

              {active !== props.active ? <div style={{position:'fixed', zIndex: '1000', top: '80px', left: 'auto', right: '20px'}}><CircularProgress size={20} /></div> : ''}

              {clause.clauseCat !== 'table' ?
              <span className={
                clause.clauseStatus === 'proposedDel' && pcPDel !== null && pcPDel.changeOrg === 'primary' ?
                  classes.propDelPrim :
                clause.clauseStatus === 'proposedDel' && pcPDel !== null && pcPDel.changeOrg === 'secondary' ?
                  classes.propDelSec :
                clause.clauseStatus === 'proposedDel' ? 
                  classes.propDel : null}>

              <NegoCardEditor 
                clid={clause._id}
                clstatus={clause.clauseStatus}
                curCpty={props.curCpty}
                ownOrgName={props.org.shortName}
                user={props.user}
                content={clause !== undefined ? clause.blocks : {}}
                editMode={props.editMode}
                smartMode={props.smartMode}
                cleanMode={cleanMode}
                templating={props.templating}
                reconcileNeeded={reconcileNeeded} // Whether the clause blocks need to be refreshed (collaborative editing)
                focusClause={e => focusClause(e)}
                updateClauseBlocks={updateClauseBlocks}
                callbackOptions={negoCallBackOptions}
                optionClicked={props.optionClicked}
                disableEditing={disableEditing || ['none', 'comment'].includes(props.editMode)}
                active={active} 
                // SmartField related props
                smartFields={props.smartFields}
                clauseTypes={clause.clauseTypes}
                updateSmartField={props.updateSmartField}
                sclid={clause.singleClauseID}
                oatID={props.oatID}
                agrID={props.agrID}
                module={props.module}
              />
              </span>
              :
              <div style={{margin: '10px 0px 10px 0px'}}>

                <TableEditable // newTable: tableKey, handleTableUpdate
                newTable={false}
                active={active}
                focusClause={focusClause}
                editMode={props.editMode}
                templating={props.templating}
                handleClauseMoreOptions={handleClauseMoreOptions} // gives ability to propose delete
                handleActivityLog={props.handleActivityLog}
                clause={clause}
                oatID={props.oatID}
                agrID={props.agrID}
                proposedDel={pcPDel !== null ? pcPDel.changeOrg : null}
                updateClauseContentFAF={props.updateClauseContentFAF}
                user={props.user}
                curCpty={props.curCpty}
                avv={props.avv}
                module={props.module}
                handleTableUpdate={handleTableUpdate}

                disabled={['none', 'comment'].includes(props.editMode) || props.laterVersionOwnedByCpty || props.user.readOnly || isLocked || isLockedByOrgForThisUser}
                plainMode={props.plainMode}

                cleanMode={cleanMode}
                smartMode={props.smartMode}
                reconcileNeeded={reconcileNeeded}  // Whether the table needs to be refreshed (collaborative editing)
                //callbackOptions={props.callbackOptions}
                callbackOptions={negoCallBackOptions} // to clear reconcileneeded
                optionClicked={props.optionClicked}
                smartFields={props.smartFields}
                clauseTypes={clause.clauseTypes}
                updateSmartField={props.updateSmartField}
                sclid={clause.singleClauseID}
                />

              </div>
              }

              {clause.clauseStatus === 'proposedDel' && /*!props.hidePDelDecision &&*/ active ? 
                <div style={{marginTop: '30px'}}>
                  <ChangeDecisionButtons
                    variant={"proposedDelCl"}
                    laterVersionOwnedByCpty={props.laterVersionOwnedByCpty || props.user.readOnly || isLocked || isLockedByOrgForThisUser}
                    editMode={props.editMode}
                    curCpty={props.curCpty}
                    handlePDecision={handlePDel}
                    clid={clause._id}
                    relevantPendingChange={pcPDel}
                  />
                </div>
              : ''}

              {!['none'].includes(props.editMode) &&
              (active || props.toggleFilter !== null || clause.approvalStatus !== 'None' || hasComments) ?

              <div style={active ? { margin: '30px 0px 20px 0px' } : { margin: '20px 0px 10px 0px' }}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                  <Grid item>
                    
                    {active || props.toggleFilter !== null || hasComments ?
                    <Tooltip title={hasComments ? "See comments" : "Place a comment"} arrow placement="bottom">
                    <Badge color="secondary" variant="dot" 
                    invisible={props.plainMode || !hasComments || (
                      (props.clauseActivityLog === undefined || props.clauseActivityLog.filter((cal) => ['clNewCommentStatus','clNewComment'].includes(cal.logType)).length < 1) && 
                      (clause.internalWorkflow === undefined || clause.internalWorkflow.filter((i) => i.changeOrg === props.curCpty && i.lastUpdateBy !== props.user._id && i.lastUpdateDate > props.user.lastLoginDate)[0] === undefined))}>
                    <Chip label={"Comments"}
                      size="small" clickable icon={<FontAwesomeIcon icon={faComments} className={classes.iconInChip} />}
                      variant={hasComments ? 'default' : 'outlined'}
                      color={hasComments ? 'primary' : 'default'}
                      className={classes.chipDefault1} 
                      onClick={e => props.handleNegoCommentsOpen(clause._id)} 
                      style={
                        openComments ? {backgroundColor: theme.palette.primary.main} :
                        hasComments ? {backgroundColor: theme.palette.grey[500]} : {}}
                      />
                    </Badge>
                    </Tooltip>
                    :''}

                    {/*!props.templating &&*/
                    (active || props.toggleFilter !== null || clause.approvalStatus !== 'None') ? // For now Legal Templating section does not have comment / approval functionality
                    <Tooltip title={
                      (clause.approvalStatus === 'Open') ? 
                        "Internal approval is pending for one of the parties" : "Route for approval within your organization"} 
                      arrow placement="bottom">
                    <Badge color="secondary" variant="dot" invisible={props.clauseActivityLog === undefined || props.clauseActivityLog.filter((cal) => ['clNewApprovalStatus'].includes(cal.logType) && ['Open'].includes(cal.keyVal2)).length < 1}>
                    <Chip label={(clause.approvalStatus === 'Open') ? "Pending approval" : "Internal approvals"}
                      size="small" clickable icon={<FontAwesomeIcon icon={faThumbsUp} className={classes.iconInChip} />}
                      variant={clause.approvalStatus !== 'None' ? 'default' : 'outlined'}
                      color={clause.approvalStatus !== 'None' ? 'primary' : 'default'}
                      className={classes.chipDefault2}
                      style={
                        clause.approvalStatus === 'Open' && !active && props.toggleFilter === null && !hasComments ? {backgroundColor: theme.palette.primary.main, marginLeft: '0px'} :
                        clause.approvalStatus === 'Open' ? {backgroundColor: theme.palette.primary.main} :
                        clause.approvalStatus !== 'None' && !active && props.toggleFilter === null && !hasComments ? {backgroundColor: theme.palette.grey[500], marginLeft: '0px'} :
                        clause.approvalStatus !== 'None' ? {backgroundColor: theme.palette.grey[500]} : {}}
                      onClick={e => props.handleNegoApprovalsOpen(clause._id)}
                      />
                      </Badge>
                    </Tooltip>
                    : '' }

                  </Grid>
                  <Grid item style={{marginRight: '-8px'}}>

                    {active && (!props.plainMode || props.templating) && clause !== undefined &&
                    ['sec','ssec','cl','cl1','cl2','cl3','cl4','preamble'].includes(clause.clauseCat) &&
                    clause.lockedByOrg !== undefined && clause.lockedByOrg !== null && 
                    (['full','cpty'].includes(clause.lockedByOrg.type) || 
                    (['internal'].includes(clause.lockedByOrg.type) && clause.lockedByOrg.orgID === props.user.orgID)) ?
                    <Tooltip title={
                      ['full'].includes(clause.lockedByOrg.type) && clause.lockedByOrg.orgID === props.user.orgID ?
                          "This clause is locked for editing except for our internal Legal & Admin users" :
                      ['cpty'].includes(clause.lockedByOrg.type) && clause.lockedByOrg.orgID === props.user.orgID ?
                          "This clause is locked for editing by counterparty users" :
                      ['internal'].includes(clause.lockedByOrg.type) && clause.lockedByOrg.orgID === props.user.orgID ?
                          "This clause is locked for internal editing except for our Legal & Admin users" :
                      clause.lockedByOrg.orgID !== props.user.orgID ? 
                          ("This clause is locked for editing by " + props.cptyName) :
                          "This clause is locked for editing"} arrow>
                      <div className={isLockedByOrgForThisUser ? classes.lockIconPrim : classes.lockIconGrey}>
                        <FontAwesomeIcon icon={faLock} style={{marginBottom: '-2px'}} />
                      </div>
                    </Tooltip>                  
                    : '' }

                    {active && !props.plainMode && clause !== undefined &&
                    ['cl','cl1','cl2','cl3','cl4','preamble'].includes(clause.clauseCat) &&
                    clause.blocks.blocks !== undefined && clause.blocks.blocks !== undefined && 
                    clause.blocks.blocks.some((b) => b.entityRanges.length > 0) &&
                    clause.clauseStatus !== 'proposedDel' ?
                    <Tooltip title={
                      cleanMode ? "Revert to Edit mode" : "View as/if changes are accepted"} arrow>
                      <IconButton className={cleanMode ? classes.histIconPrim : classes.histIcon} onClick={e => handleCleanMode()} /*onClick={e => props.handleChOpen(clause.singleClauseID)}*/>
                        <FontAwesomeIcon icon={cleanMode ? faPenAlt : faEye} />
                      </IconButton>
                    </Tooltip>
                    :''}
                    
                    {(active || props.toggleFilter !== null) && !props.templating ?
                    <Tooltip title="Clause history" arrow>
                      <IconButton className={classes.histIcon} onClick={e => props.handleChOpen(clause.singleClauseID)}>
                      <Badge color="secondary" variant="dot" invisible={props.clauseActivityLog === undefined || props.clauseActivityLog.length < 1}>
                        <FontAwesomeIcon icon={faHistory} />
                      </Badge>
                      </IconButton>
                    </Tooltip>
                    :''}

                    {active && !props.laterVersionOwnedByCpty && !props.user.readOnly && !isLocked && !props.plainMode && 
                      ['edit', 'full'].includes(props.editMode) && clause.clauseStatus !== 'proposedDel' && !isLockedByOrgForThisUser ?
                      <>
                      <MenuMoreOptions
                        clauseLock={['custE', 'legalOrg'].includes(props.org.clientType)}
                        level={clause.clauseCat}
                        clid={clause._id}
                        lockedByOrg={clause.lockedByOrg}
                        templating={props.templating}
                        parentAction={handleClauseMoreOptions}
                        user={props.user}
                        blockStyleButton={blockStyleButton}
                        clauseNumbering={
                          clause.numberType === 'None' && clause.title !== 'Preamble' && clause.clauseCat !== 'preamble' ? 'revive' :
                          clause.numberType === 'Manual' ? 'automate' : 'none'}
                      />
                      </>
                      : '' }
                  </Grid>
                </Grid>

              </div>
              : '' }
            </>
        </div>
      </div>
      )
}
// return true if passing nextProps to render would return the same result as passing prevProps to render, otherwise return false
function areEqual(prevProps, nextProps) {

 let equal = true;

 if((prevProps.cl.clauseStatus !== nextProps.cl.clauseStatus) /* clauseStatus */ ||
    (prevProps.cl.commentStatus !== nextProps.cl.commentStatus) /* commentStatus */ ||
    (prevProps.cl.approvalStatus !== nextProps.cl.approvalStatus) /* approvalStatus */ ||
    (prevProps.cl.internalWorkflow !== nextProps.cl.internalWorkflow) /* internalWorkflow */ || 
    (prevProps.cl.lockedBy !== nextProps.cl.lockedBy) /* lockedBy */ ||
    (prevProps.active !== nextProps.active) /* clauseActive */ || 
    (prevProps.lastUpdateDate !== nextProps.lastUpdateDate) /* clauseActive */ || 
    (nextProps.optionClicked.clid === nextProps.cl._id && prevProps.optionClicked.option !== nextProps.optionClicked.option) /* review option clicked */ ||
    (nextProps.avcs !== prevProps.avcs) ||
    (nextProps.toggleFilter !== prevProps.toggleFilter) ||
    (nextProps.smartMode !== prevProps.smartMode) ||
    (nextProps.no !== prevProps.no)
   ) {
    equal = false;
 } 
 return equal
}
export default React.memo(NegoCard, areEqual);
