function getRecipientModule(sender, receiver, module, rel) {
    // NOTE: this function assumes that the org for the sender and receiver is the same
    // It is written in the context of the LegalClient users that may need to go to / be in the "counterparty" module
    let r = 
        receiver.role.startsWith('ClientFor_') || receiver.role === 'Counterparty' ? 
            'counterparty' :
        sender.role !== undefined && sender.role.startsWith('ClientFor_') && ['people','corporate'].includes(rel.relationshipType) ?
            rel.relationshipType :
        sender.role !== undefined && sender.role.startsWith('ClientFor_') && ['partnership'].includes(rel.relationshipType) ?
            'partnerships' :
        sender.role !== undefined && sender.role.startsWith('ClientFor_') && ['b2b'].includes(rel.relationshipType) &&
        sender.orgID === rel.primaryOrgID ?
            'sales' :
        sender.role !== undefined && sender.role.startsWith('ClientFor_') && ['b2b'].includes(rel.relationshipType) &&
        sender.orgID === rel.secondaryOrgID ?
            'procurement' :
            module

    return r;
}

export { getRecipientModule }