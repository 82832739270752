export const taxes = [ // https://stripe.com/docs/billing/customer/tax-ids
    { country: 'AE', type: 'ae_trn', ex: '123456789012345' },
    { country: 'AT', type: 'eu_vat', ex: 'ATU12345678' }, 
    { country: 'AU', type: 'au_abn', ex: '12345678912' },
    { country: 'BE', type: 'eu_vat', ex: 'BE0123456789' },
    { country: 'BG', type: 'eu_vat', ex: 'BG0123456789' },
    { country: 'BR', type: 'br_cnpj', ex: '01.234.456/5432-10' },
    { country: 'BR', type: 'br_cpf', ex: '123.456.789-87' },
    { country: 'CA', type: 'ca_bn', ex: '123456789' },
    { country: 'CA', type: 'ca_qst', ex: '1234567890TQ1234' },
    { country: 'CH', type: 'ch_vat', ex: 'CHE-123.456.789 MWST', },
    { country: 'CL', type: 'cl_tin', ex: '12.345.678-K' },
    { country: 'CY', type: 'eu_vat', ex: 'CY12345678Z' },
    { country: 'CZ', type: 'eu_vat', ex: 'CZ1234567890' },
    { country: 'DE', type: 'eu_vat', ex: 'DE123456789' },
    { country: 'DK', type: 'eu_vat', ex: 'DK12345678' },
    { country: 'EE', type: 'eu_vat', ex: 'EE123456789' },
    { country: 'ES', type: 'es_cif', ex: 'A12345678' },
    { country: 'ES', type: 'eu_vat', ex: 'ESA1234567Z' },
    { country: 'FI', type: 'eu_vat', ex: 'FI12345678' },
    { country: 'FR', type: 'eu_vat', ex: 'FRAB123456789' },
    { country: 'GB', type: 'gb_vat', ex: 'GB123456789' },
    { country: 'GR', type: 'eu_vat', ex: 'EL123456789' },
    { country: 'HK', type: 'hk_br', ex: '12345678' },
    { country: 'HR', type: 'eu_vat', ex: 'HR12345678912' },
    { country: 'HU', type: 'eu_vat', ex: 'HU12345678912' },
    { country: 'ID', type: 'id_npwp', ex: '12.345.678.9-012.345' },
    { country: 'IE', type: 'eu_vat', ex: 'IE1234567AB' },
    { country: 'IN', type: 'in_gst', ex: '12ABCDE3456FGZH' },
    { country: 'IT', type: 'eu_vat', ex: 'IT12345678912' },
    { country: 'JP', type: 'jp_cn', ex: '1234567891234' },
    { country: 'JP', type: 'jp_rn', ex: '12345' },
    { country: 'KR', type: 'kr_brn', ex: '123-45-67890' },
    { country: 'LI', type: 'li_uid', ex: 'CHE123456789' },
    { country: 'LT', type: 'eu_vat', ex: 'LT123456789123' },
    { country: 'LU', type: 'eu_vat', ex: 'LU12345678' },
    { country: 'LV', type: 'eu_vat', ex: 'LV12345678912' },
    { country: 'MT', type: 'eu_vat', ex: 'MT12345678' },
    { country: 'MX', type: 'mx_rfc', ex: 'ABC010203AB9' },
    { country: 'MY', type: 'my_frp', ex: '12345678' },
    { country: 'MY', type: 'my_itn', ex: 'C 1234567890' }, 
    { country: 'MY', type: 'my_sst', ex: 'A12-3456-78912345' },
    { country: 'NL', type: 'eu_vat', ex: 'NL123456789B12' },
    { country: 'NO', type: 'no_vat', ex: '123456789MVA' },
    { country: 'NZ', type: 'nz_gst', ex: '123456789' },
    { country: 'PL', type: 'eu_vat', ex: 'PL1234567890' },
    { country: 'PT', type: 'eu_vat', ex: 'PT123456789' },
    { country: 'RO', type: 'eu_vat', ex: 'RO1234567891' },
    { country: 'RU', type: 'ru_inn', ex: '1234567891' },
    { country: 'RU', type: 'ru_kpp', ex: '123456789' },
    { country: 'SA', type: 'sa_vat', ex: '123456789012345' },
    { country: 'SG', type: 'sg_gst', ex: 'M12345678X' },
    { country: 'SG', type: 'sg_uen', ex: '123456789F' },
    { country: 'SE', type: 'eu_vat', ex: 'SE123456789123' },
    { country: 'SI', type: 'eu_vat', ex: 'SI12345678' },
    { country: 'SK', type: 'eu_vat', ex: 'SK1234567891' },
    { country: 'TH', type: 'th_vat', ex: '1234567891234' },
    { country: 'TW', type: 'tw_vat', ex: '12345678' },
    { country: 'US', type: 'us_ein', ex: '12-3456789' },
    { country: 'ZA', type: 'za_vat', ex: '4123456789' },
];