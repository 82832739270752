function getDefaultModule(org, user) {
    let r = 'sales';

    if(org !== undefined && org !== null && org.clientType === 'custS' && org.modules[0] !== undefined) { r = org.modules[0]; } 
    else if(user !== undefined && user !== null && user.role !== undefined && user.role.startsWith('ClientFor_')) { r = 'counterparty' }
    else if(user !== undefined && user.role === 'Counterparty') { r = 'counterparty' }
    else if(user !== undefined && user.role === 'Procurement') { r = 'procurement' }
    else if(user !== undefined && user.role === 'Partnerships') { r = 'partnerships' }
    else if(user !== undefined && user.role === 'HR') { r = 'people' }
    else if(user !== undefined && user.role === 'Corporate') { r = 'corporate' }
    else if(user !== undefined && user.role === 'Sales') { r = 'sales' }
    else if((user !== undefined && ['Admin', 'Legal'].includes(user.role) && user.defaultModule !== undefined && ['sales', 'procurement', 'partnerships', 'corporate', 'people'].includes(user.defaultModule)) ||
    (user !== undefined && ['Business'].includes(user.role) && user.defaultModule !== undefined && ['sales', 'procurement', 'partnerships'].includes(user.defaultModule))) {
        r = user.defaultModule;
    }
    return r;
}

export { getDefaultModule }