import React, { useEffect } from 'react';
import theme from '../theme/theme';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Avatar, Box, Button, Container, Grid, Link, 
         List, ListItem, ListItemText, TextField, Typography,
         makeStyles, withStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';         
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { DrawerCore, Header, ThumbRelationship } from '../components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faExclamationTriangle, faPhone, faLandmark } from "@fortawesome/free-solid-svg-icons";

const dayjs = require('dayjs')

const CssTextField = withStyles({
  root: {
    [theme.breakpoints.only('xs')]: { width: '300px' },
    [theme.breakpoints.up('sm')]: { width: '500px' },
    marginBottom: '10px',
    color: '#ff0000',
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: '25px',
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grey[500],
      },
      '&:hover fieldset': { // hover
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& fieldset': {
        borderRadius: '25px',
    }
  },
})(TextField);

const filter = createFilterOptions();

function Profile(props) {

  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    outerFlexGrid: {
        minHeight: '75vh',
        paddingTop: '100px',
        paddingBottom: '120px',
    },
    orgLogo: {
      height: '100px',
      width: '100px',
      marginTop: '40px',
      marginBottom: '60px',
    },
    orgDetails: {
      color: theme.palette.grey[800],
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingTop: '5px',
    },
    orgDetailBox: {
      [theme.breakpoints.only('xs')]: { width: '300px', display: 'block', marginBottom: '15px', },
      [theme.breakpoints.only('sm')]: { width: '500px', display: 'block', marginBottom: '15px', },
    },
    iconOrgDetails: {
      paddingRight: '10px',
    },
    linkOrgDetails: {
      color: theme.palette.grey[800],
      fontWeight: '500',
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      }
    },
    orgSearch: {
      [theme.breakpoints.only('xs')]: { width: '300px' },
      [theme.breakpoints.only('sm')]: { width: '400px' },
      [theme.breakpoints.up('md')]: { width: '500px' },
      marginTop: '70px',
      marginBottom: '60px',
    },
    iconError: {
      color: theme.palette.error.main,
      fontSize: '80px',
    },
    errorText: {
      color: theme.palette.error.main,
      fontWeight: '500'
    },
    mt30: {
      marginTop: '30px',
    },
    boxRelTitle: {
      paddingTop: '60px',
      paddingBottom: '0px',
    },
    boxRelYours: {
      padding: '0px 50px 0px 50px',
    },
    searchSkelet: {
      [theme.breakpoints.only('xs')]: { width: '300px', height: '55px' },
      [theme.breakpoints.only('sm')]: { width: '380px', height: '55px' },
      [theme.breakpoints.up('md')]: { width: '440px', height: '55px' },
    },
  }));
  const classes = useStyles();

  const history = useHistory()

  const [searchValue, setSearchValue] = React.useState(null);
  const [searchOptions, setSearchOptions] = React.useState([]);
  const [searchError, setSearchError] = React.useState('');
  const [searchResults, setSearchResults] = React.useState(null);

  const salesRel = props.rels.filter((rel) => rel.relationshipType === 'b2b' && (rel.primaryOrgID === props.user.orgID && rel.secondaryOrgID === props.orgProfile._id))[0];
  const procRel = props.rels.filter((rel) => rel.relationshipType === 'b2b' &&  (rel.secondaryOrgID === props.user.orgID && rel.primaryOrgID === props.orgProfile._id))[0];
  const partnerRel = props.rels.filter((rel) => rel.relationshipType === 'partnership' && 
      ((rel.secondaryOrgID === props.user.orgID && rel.primaryOrgID === props.orgProfile._id) || (rel.primaryOrgID === props.user.orgID && rel.secondaryOrgID === props.orgProfile._id)))[0]

  useEffect(() => {
    if(props.agrTypes !== undefined && props.agrTypes !== null) {
      let options = [] // temparray for agrs
      props.orgProfileAgrTemplates
      .filter((oat) => oat.external)
      .forEach((oat) => {
        // Add all agreements to the search options
        let oatSN = oat.agrShortName !== undefined && oat.agrShortName !== '' ? oat.agrShortName :
        (props.agrTypes.filter((at) => at._id === oat.agrTypeID)[0] !== undefined) ? props.agrTypes.filter((at) => at._id === oat.agrTypeID)[0].shortName : ''
        let oatFN = props.agrTypes.filter((at) => at._id === oat.agrTypeID)[0] !== undefined ? props.agrTypes.filter((at) => at._id === oat.agrTypeID)[0].fullName[0] : 
          oat.agrFullName !== '' ? oat.agrFullName : 'Published Terms';

        options.push({
          oatid: oat._id,
          module: oat.module,
          reference: oat.reference,
          agrTypeID: oat.agrTypeID,
          version: oat.version,
          creationDate: oat.creationDate,
          oatShortName: oatSN,
          oatFullName: oatFN,
          clause: 'na',
          title: oatFN + (oat.reference !== undefined && oat.reference !== null && oat.reference !== '' ? (' (' + oat.reference + ')') : ''),
        });
      })
      setSearchOptions(options);
    }
  }, [props.orgProfileAgrTemplates, props.agrTypes, props.orgurl])

  const handleSelectedValue = (val) => {
    setSearchError('');
    if (typeof val === 'string') {
      handleCustomValue(val)
      return
    }
    if(val !== null) {
      let path = `/network/` + props.orgurl + `/` + val.oatid
      history.push(path);
    }
  }

  const handleStopSearching = () => {
    setSearchError('');
    setSearchResults(null);
    setSearchValue(null);
  }

  const handleSearchValue = (val) => {
    setSearchValue(val)
  }

  const handleCustomValue = (val) => {

    setSearchError('');
    if(val === null || val.length === undefined || (val.length !== undefined && val.length < 3)) {
      setSearchError("The search query is too short, please try again")
    } else {
      let results = [];
      
      props.orgProfileAgrTemplates.forEach((oat) => {
        if(oat.agrFullName !== undefined && oat.agrFullName !== null && val !== undefined && val !== null &&
          oat.agrFullName.toLowerCase().includes(val.toLowerCase())) {
          results.push({
            route: '/network/' + props.orgProfile.orgurl + "/" + oat._id,
            title: oat.agrFullName !== undefined && oat.agrFullName !== '' ? oat.agrFullName :
            props.agrTypes.filter((at) => at._id === oat.agrTypeID)[0] !== undefined ?
              props.agrTypes.filter((at) => at._id === oat.agrTypeID)[0].fullName[0]: "Agreement",
            substring: "Last Update: " + dayjs(oat.lastUpdateDate).format('MMM D, YYYY'),
          })/*
            oat.agrFullName !== undefined && oat.agrFullName !== '' ? oat.agrFullName :
            props.agrTypes.filter((at) => at._id === oat.agrTypeID)[0] !== undefined ?
              props.agrTypes.filter((at) => at._id === oat.agrTypeID)[0].fullName[0]: "Agreement")*/
        }
      })      
      setSearchResults(results);
      if (results.length < 1) {
        setSearchError('No results were found, please try again')
      }
    }
  }

  return(
    <div className={classes.root}>

        <Header 
          module="network"
          user={props.user}
          org={props.org}
          modules={
            props.org !== undefined && props.org.clientType !== undefined && ['custB', 'cust', 'custE', 'legalOrg'].includes(props.org.clientType) ? 
              ['sales','procurement','partnerships','people','corporate'] : 
            props.org !== undefined && props.org.clientType !== undefined && ['custS'].includes(props.org.clientType) && props.org.modules !== undefined ?
              props.org.modules : [] }
          orgProfile={props.orgProfile}
          crumbs={[
            {name: "Home", to: "/network" },
            {name: (props.orgProfile.shortName !== undefined)? props.orgProfile.shortName : "Not found", to: "active"} ]}
          notifications={props.notifications}
          markNotificationAsRead={props.markNotificationAsRead}
          markAllNotificationsAsRead={props.markAllNotificationsAsRead}
        />

        <DrawerCore
          agreements={[]} // props.orgProfileAgrTemplates 
          page="overview" 
          rid=""
          showCerts={props.orgProfileCerts.length > 0}
          orgurl={props.orgurl}
          customurl={props.customurl}
          sections={[]} 
          pageType='profile' 
          userLoggedIn={props.user !== undefined && props.user.email !== undefined}
        />

        <Grid container direction="column" justify="space-between" alignItems="stretch" style={{minHeight: '100vh'}}>
        <Grid item>

          <Container maxWidth="lg">
          <Grid container direction="column" justify="flex-start" alignItems="center" className={classes.outerFlexGrid}>

          {
            (props.orgProfilePending) ? 
            (
              <Grid item>
                <Box mt={5} align="center">
                  <Skeleton variant="circle" animation="wave" width={100} height={100} />
                </Box>
                <Box mt={5} align="center">
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={12} md={4}><Skeleton variant="text" animation="wave" width={120} height={20} /></Grid>
                    <Grid item xs={12} md={4}><Skeleton variant="text" animation="wave" width={120} height={20} /></Grid>
                    <Grid item xs={12} md={4}><Skeleton variant="text" animation="wave" width={120} height={20} /></Grid>
                  </Grid>
                </Box>
                <Box mt={7} align="center">
                  <Skeleton variant="text" animation="wave" className={classes.searchSkelet} />
                </Box>
                <Box mt={6} align="center">
                  <Grid container direction="row" spacing={1} justify="center" alignItems="center">
                    <Grid item xs={6} md={3}><Skeleton variant="text" animation="wave" width={90} height={40} /></Grid>
                    <Grid item xs={6} md={3}><Skeleton variant="text" animation="wave" width={90} height={40} /></Grid>
                  </Grid>
                </Box>
              </Grid>
            )
            :
            (props.orgProfileError !== null) ? 
            (
              <Grid item>
                <Box mt={5} mb={8} align="center">
                  <FontAwesomeIcon icon={faExclamationTriangle} className={classes.iconError} />
                </Box>
                <Typography variant="h6" align="center">
                    This page could not be found
                </Typography>
                <Box mt={5} mb={5} align="center">
                  <Button variant="outlined" color="primary" component={RouterLink} to="/network">Search the Network</Button>
                </Box>
              </Grid>
            )
            :
            (
              <React.Fragment>
              <Grid item>
                <Avatar src={props.orgProfile.logoURL} alt={props.orgProfile.shortName} className={classes.orgLogo} />
              </Grid>
              <Grid item container direction="row" justify="center" alignItems="center">

                  <Box className={classes.orgDetailBox}>
                  <Typography variant="subtitle2" className={classes.orgDetails} align="center">
                    <FontAwesomeIcon icon={faLandmark} className={classes.iconOrgDetails} />
                    {props.orgProfile.legalName}
                  </Typography>
                  </Box>

                {(props.orgProfile.url !== undefined && props.orgProfile.url !== null && props.orgProfile.url !== 'undefined' &&
                  props.orgProfile.url !== '') ?
                  (
                    <Box className={classes.orgDetailBox}>
                    <Typography variant="subtitle2" className={classes.orgDetails} align="center">
                      <FontAwesomeIcon icon={faGlobe} className={classes.iconOrgDetails} />
                      <Link href={props.orgProfile.url} className={classes.linkOrgDetails}>{props.orgProfile.url}</Link>
                    </Typography>
                    </Box>
                  ): ''
                }
                {(props.orgProfile.phone !== undefined && props.orgProfile.phone !== null && props.orgProfile.phone !== 'undefined' && 
                  props.orgProfile.phone !== '') ?
                  (
                    <Box className={classes.orgDetailBox}>
                    <Typography variant="subtitle2" className={classes.orgDetails} align="center">
                      <FontAwesomeIcon icon={faPhone} className={classes.iconOrgDetails} />
                      {props.orgProfile.phone}
                    </Typography>
                    </Box>
                  ): ''
                }
              </Grid>
              
              {
              props.user.orgID === props.orgProfile._id ?
              (
              <Grid item className={classes.boxRelTitle}>
                <Box>
                  <Typography variant="h5" className={classes.boxRelYours}>
                    This is your organization
                  </Typography>
                </Box>
              </Grid>
              )
              :
              props.rels
              .filter((rel) => (rel.primaryOrgID === props.user.orgID && rel.secondaryOrgID === props.orgProfile._id) || 
                                    (rel.secondaryOrgID === props.user.orgID && rel.primaryOrgID === props.orgProfile._id))[0] !== undefined ?
              (
              <>
              <Box>
              <Typography variant="subtitle1" align="center" className={classes.boxRelTitle}>
                You have an existing relationship with this company
              </Typography>
              </Box>

              <Grid item container direction="row" justify="center" alignItems="center" spacing={2} xs={12} className={classes.mt30}>
                
                    {salesRel !== undefined ?
                    (
                      <Grid item>
                      <ThumbRelationship 
                        logo={"Sales"}
                        name={"Sales"}
                        rid={salesRel._id}
                        lastUpdate={salesRel.lastUpdateDate}
                        deactive={salesRel.deactive}
                        hideDot={props.user.lastLoginDate > salesRel.lastUpdateDate}
                        hideProc={true}
                        path={"/core/sales/relationship/" + salesRel._id + "/overview"}
                      />
                      </Grid>
                    )
                    : '' }

                    {procRel !== undefined ?
                    (
                    <Grid item>                        
                      <ThumbRelationship 
                        logo={"Procurement"}
                        name={"Procurement"}
                        rid={procRel._id}
                        lastUpdate={procRel.lastUpdateDate}
                        deactive={procRel.deactive}
                        hideDot={props.user.lastLoginDate > procRel.lastUpdateDate}
                        hideProc={true}
                        path={"/core/procurement/relationship/" + procRel._id + "/overview"}
                      />
                    </Grid>
                    )
                    : '' }

                    {partnerRel !== undefined ?
                    (
                    <Grid item>                        
                      <ThumbRelationship 
                        logo={"Partnership"}
                        name={"Partnership"}
                        rid={partnerRel._id}
                        lastUpdate={partnerRel.lastUpdateDate}
                        deactive={partnerRel.deactive}
                        hideDot={props.user.lastLoginDate > partnerRel.lastUpdateDate}
                        hideProc={true}
                        path={"/core/partnerships/relationship/" + partnerRel._id + "/overview"}
                      />
                    </Grid>
                    )
                    : '' }
              </Grid>
              </>
              ) : '' }

              <Grid item>
                <Autocomplete
                  value={searchValue}
                  onChange={(event, newValue) => {
                    if (newValue && newValue.inputValue) {
                      handleCustomValue(newValue.inputValue)
                      return;
                    }
                    handleSelectedValue(newValue)
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        title: `Search clause content "${params.inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  id="free-solo-with-text-demo"
                  options={searchOptions}
                  getOptionLabel={option => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.title;
                  }}
                  renderOption={option => option.title}
                  className={classes.orgSearch}
                  freeSolo
                  clearOnEscape
                  renderInput={params => (
                    <CssTextField 
                      {...params} 
                      label="Search terms & agreements" 
                      onChange={e => handleSearchValue(e.target.value)}
                      variant="outlined"
                      style={{width: '100%'}} />
                  )}
                />

              </Grid>

              <Grid item>

                <Button 
                type="submit"
                variant="contained" 
                color="primary" 
                disableElevation 
                style={{ borderRadius:25, width: '130px' }}
                onClick={e => handleCustomValue(searchValue)}>
                  Search Terms
                </Button>
                &nbsp;&nbsp;
                <Button 
                variant="contained" 
                color="primary" 
                disableElevation 
                style={{ borderRadius:25, width: '130px' }}
                component={RouterLink}
                to={"/network/" + props.orgurl + "/terms/all" }>
                  Browse Terms
                </Button>
                
              </Grid>
              
              {(searchError !== '') ?
              (
                <Box align="center" mt={4}>
                  <Typography className={classes.errorText} align="center">
                    {searchError}
                  </Typography>
                  <Box mt={4} mb={5}>
                    <Button variant="outlined" color="primary" onClick={handleStopSearching}>Try again</Button>
                  </Box>
                </Box>
              )
              :
              (searchResults !== null) ?
              (
                <Grid item md={6}>
                  <Box mb={5} mt={4}>
                  <List>                
                  {searchResults.map((sr,i) => (
                    <ListItem button component={RouterLink} to={sr.route} key={i}>
                      <ListItemText primary={sr.title} secondary={sr.substring} />
                    </ListItem>
                  ))}
                  </List>
                  </Box>
                </Grid>
              )
              : '' }
              </React.Fragment>
            )
          }
            
          </Grid>
          
          </Container>
        </Grid>
        </Grid>
    </div>
  )

}
export default Profile
