import React from 'react';
import { AvatarInitials, MentionEditor } from '.';
import { convertPlainToBlocksComment } from '../utils'
import { Box, Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";  

const dayjs = require('dayjs')

const CommentDisplayBox = (props) => {

    const useStyles = makeStyles(theme => ({
        smallOnXS: {
            [theme.breakpoints.only('xs')]: { fontSize: '10px' },
            backgroundColor: '#f3f3f3',
            borderRadius: '5px',
            padding: '2px 10px 2px 10px',
        },
        boxSizer: {
            maxWidth: '360px',
            //[theme.breakpoints.only('xs')]: { width: '90%' },
            //[theme.breakpoints.up('sm')]: { width: '80%' }        
        },
        cardPrimaryCpty: {
            borderRadius: '0px',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            border: '1px solid #e5e5e5',
            borderLeft: '4px solid ' + theme.palette.primary.main,
            marginBottom: '6px',
            padding: '0px',
        },
        cardSecondaryCpty: {
            borderRadius: '0px',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            border: '1px solid #e5e5e5',
            borderRight: '4px solid ' + theme.palette.secondary.main,
            marginBottom: '6px',
            padding: '0px',
        },
        secondaryCardPrimaryCpty: {
            borderRadius: '0px',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            border: '1px solid #e5e5e5',
            borderRight: '4px solid ' + theme.palette.primary.main,
            marginBottom: '6px',
            padding: '0px',
        },
        primaryCardSecondaryCpty: {
            borderRadius: '0px',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            border: '1px solid #e5e5e5',
            borderLeft: '4px solid ' + theme.palette.secondary.main,
            marginBottom: '6px',
            padding: '0px',
        },
        author: {
            [theme.breakpoints.only('xs')]: { fontSize: '12px' },
            display: 'inline-block', 
            fontSize: '13px',
            fontWeight: '700',
        },
        date: {
            [theme.breakpoints.only('xs')]: { fontSize: '10px' },
            display: 'inline-block', 
            color: theme.palette.grey[600],
            fontWeight: '300',
            fontSize: '11px',
            padding: '0px 8px 0px 8px',
        },
        miniIconPrimary: {
            color: theme.palette.primary.main,
            fontSize: '11px',
            padding: '4px',
        },
        miniIconSecondary: {
            color: theme.palette.secondary.main,
            fontSize: '11px',
            padding: '4px',
        },
        miniIconB: {
            marginLeft: '10px',
        },
        avatarLeftBlock: {
            [theme.breakpoints.only('xs')]: { display: 'none' },
            textAlign:'right',
        },
        avatarRightBlock: {
            [theme.breakpoints.only('xs')]: { display: 'none' },
            textAlign:'left',
        }
    }));
    const classes = useStyles();

    const RenderAvatar = ({variant, commentIsDeleted}) => {
        return (
        <div>
        <AvatarInitials
        displayName={props.c.creatorDisplayName}
        photoURL={props.user !== undefined && props.user.photoURL !== undefined && props.user.photoURL !== '' ? props.user.photoURL : null }
        variant={variant}
        />

        {!commentIsDeleted ?
        <Box align={variant === 'left' ? "right" : "left"} width={"100%"}>
        <div style={{width: '10px', marginTop: '6px'}}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <g fill={props.color === "primary" ? "#7243dd" : "#E80064"}><polygon points={variant === 'left' ? "40 0, 100 100, 100 0" : "0 0, 60 0, 0 100"}/></g>
        </svg>
        </div>
        </Box>
        :''}
        </div>
        )
    }

    const RenderTimeUpdateBlock = ({variant, commentIsDeleted}) => {
        return (
        <div style={{display: 'inline-block'}}>
            {variant === "right" && commentIsDeleted ?
            <span className={classes.date} style={{fontWeight: '700'}}>
                deleted
            </span>
            :variant === "right" && props.c.lastUpdateDate !== undefined && props.c.lastUpdateDate !== null ? 
            <span className={classes.date} style={{fontWeight: '700'}}>
                edited
            </span>
            : '' }

            <span className={classes.date}>{dayjs(props.c.creationDate).format('MMM D, YYYY h:mm A')}</span>

            {variant === "left" &&
            props.updating !== undefined && props.updating !== null && props.updating === props.c._id ?
            <span className={classes.date} style={{fontWeight: '700'}}>
                being edited
            </span>
            :
            variant === "left" && props.canEdit && !commentIsDeleted ? // This user can update or delete the comment 
            <>
            <IconButton size="small" className={classes.miniIconB} onClick={e => props.setUpdating(props.c._id)}>
                <FontAwesomeIcon icon={faPen} className={props.color === "primary" ? classes.miniIconPrimary : classes.miniIconSecondary} />
            </IconButton>
            <IconButton size="small" onClick={e => props.handleDeleteComment(props.c._id)}>
                <FontAwesomeIcon icon={faTrash} className={props.color === "primary" ? classes.miniIconPrimary : classes.miniIconSecondary} />
            </IconButton>
            </>
            : 
            variant === "left" && commentIsDeleted ?
            <span className={classes.date} style={{fontWeight: '700'}}>
                deleted
            </span>
            :variant === "left" && props.c.lastUpdateDate !== undefined && props.c.lastUpdateDate !== null ? 
            <span className={classes.date} style={{fontWeight: '700'}}>
                edited
            </span>
            : '' }

        </div>
        )
    }

    return (
    <div>
        {props.c.comment.startsWith('===') && props.c.comment.endsWith('===') ?
        <Box width="100%" align="center" mt={2} mb={3} key={props.c._id}>
            <Typography align="center" variant="subtitle2" color="textPrimary" className={classes.smallOnXS}>
                {
                props.c.comment === '===REVIEW STARTED===' ? "Review started" :
                props.c.comment === '===REVIEW COMPLETED===' ? "Review completed" :
                props.c.comment === '===REVIEW CANCELLED===' ? "Review cancelled" : 
                props.c.comment === '===RESOLVED===' ? "Resolved" : // NegoComments
                props.c.comment === '===REOPENED===' ? "Reopened" : // NegoComments
                props.c.comment === '===CANCELLED===' ? "Cancelled" : // NegoApprovals
                props.c.comment === '===APPROVED===' ? "Approved" : // NegoApprovals
                props.c.comment === '===REJECTED===' ? "Rejected" : // NegoApprovals
                props.c.comment === '===REOPENED===' ? "Reopened" : // NegoApprovals
                props.c.comment === '===REASSIGNED===' ? "Reassigned" : // NegoApprovals
                "Updated"} on {dayjs(props.c.creationDate).format('MMM D, YYYY h:mm A')} by {props.c.creatorDisplayName}
            </Typography>
        </Box>
        :
        <Box width="100%"
        key={props.c._id} mb={1}
        align={props.align}>
            <Grid container direction="row"
            justify={props.align === 'left' ? 'flex-start' : 'flex-end'}
            >
                <Grid item className={classes.avatarLeftBlock}>
                    {props.align === 'left' ? <RenderAvatar variant="left" commentIsDeleted={props.c.comment === '___DELETED___'} /> :''}
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2" className={classes.author} gutterBottom>

                        {props.align === 'right' ? <RenderTimeUpdateBlock variant="right" commentIsDeleted={props.c.comment === '___DELETED___'} /> : ''}

                        <span style={props.align === 'right' ? {padding: '0px 8px 0px 8px'} : {padding: '0px 8px 0px 8px'}}>{props.c.creatorDisplayName}</span>

                        {props.align === 'left' ? <RenderTimeUpdateBlock variant="left" commentIsDeleted={props.c.comment === '___DELETED___'} />: ''}

                    </Typography>
                    
                    {props.c.comment !== '___DELETED___' ?
                    <Box className={classes.boxSizer} align="left">
                        <Box variant="outlined" 
                        className={
                                props.align === "left" && props.color === "primary" ? 
                                    classes.cardPrimaryCpty : 
                                props.align === "left" && props.color === "secondary" ?
                                    classes.primaryCardSecondaryCpty :
                                props.align === "right" && props.color === "primary" ? 
                                    classes.secondaryCardPrimaryCpty :
                                    classes.cardSecondaryCpty}>
                            <MentionEditor
                                isPlain={true}
                                beingUpdated={props.updating !== undefined && props.updating !== null && props.updating === props.c._id}
                                cancelUpdating={e => props.setUpdating(null)}
                                handleCommentChangeSubmit={props.handleCommentChangeSubmit}
                                content={ // enabling historic comments that do not have "blocks" yet
                                    props.c.blocks !== undefined && props.c.blocks.blocks !== undefined && props.c.blocks.blocks.length > 0 &&
                                    props.c.blocks.blocks.some((b) => b.text !== '') ? 
                                        props.c.blocks : 
                                    convertPlainToBlocksComment(props.c.comment) !== undefined && 
                                    convertPlainToBlocksComment(props.c.comment).blocks !== undefined ?
                                        convertPlainToBlocksComment(props.c.comment) : {}} 
                                align={props.align}
                                curCpty={props.color}
                            />                       
                        </Box>
                    </Box>
                    :''}
                </Grid>
                <Grid item className={classes.avatarRightBlock}>
                    {props.align === 'right' ? <RenderAvatar variant="right" commentIsDeleted={props.c.comment === '___DELETED___'} /> :''}
                </Grid>
            </Grid>
            
        </Box>
        }
    </div>
    )
}

export default CommentDisplayBox