import { FETCH_AGRTYPES_PENDING, FETCH_AGRTYPES_SUCCESS, FETCH_AGRTYPES_ERROR, UPDATE_AGRTYPE, ADD_AGRTYPE,
         FETCH_AGREXEC_PENDING, FETCH_AGREXEC_ERROR, FETCH_AGREXEC_SUCCESS, REMOVE_AGRTYPE,
        } from '../ActionTypes';

export const AgrTypes = (state  = { 
    pending: false,
    error: null,
    agrTypes: []
}, action) => {
switch(action.type) {
        case FETCH_AGRTYPES_PENDING: 
            return {
                ...state,
                pending: true
            }
        case FETCH_AGRTYPES_SUCCESS:
            return {
                ...state,
                pending: false,
                agrTypes: action.payload
            }
        case FETCH_AGRTYPES_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }
        case UPDATE_AGRTYPE:
            // Find the index of the at to replace with the updated one
            var newat = action.payload
            var newAts = state.agrTypes
            var index = null;
            for (var i=0; i<state.agrTypes.length; i++) {
                if ( state.agrTypes[i]._id === newat._id ) {
                    index = i;
                    break;
                }
            }
            newAts[index] = newat
            return { 
                ...state, 
                agrTypes: newAts};
        case ADD_AGRTYPE:
            var at = action.payload;
            return { 
                ...state, 
                pending: false,
                agrTypes: state.agrTypes.concat(at)};
        case REMOVE_AGRTYPE:
            var atid = action.payload;
            return { 
                ...state, 
                agrTypes: state.agrTypes.filter(({ _id }) => _id !== atid)};
        default: 
            return state;
    }
};

export const AgrExec = (state  = { 
    pending: false,
    error: null,
    agrExec: {}
}, action) => {
switch(action.type) {
        case FETCH_AGREXEC_PENDING: 
            return {
                ...state,
                pending: true
            }
        case FETCH_AGREXEC_SUCCESS:
            return {
                ...state,
                pending: false,
                agrExec: action.payload
            }
        case FETCH_AGREXEC_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }
        default: 
            return state;
    }
};
