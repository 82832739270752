import { sfautofields } from '../assets/static/sfautofields'
import { sfdateperiods } from '../assets/static/sfdateperiods'
import { countries } from '../assets/static/countries';
import { currencies } from '../assets/static/currencies';
import { states } from '../assets/static/states';

function smartfieldValueValidation(type, val1, val2) {
  
    let isValid = true

    if(val1 === undefined || val1 === null || val1.length < 1) { // the value is zero length
        isValid = false;

    } else if(['auto'].includes(type) && !sfautofields.some((sfa) => sfa.val === val1)) { // Auto-field but not in the list of possible auto-fields
        isValid = false;

    } else if (['string-text'].includes(type) && typeof val1 !== 'string') { // A string but the field is not a string - should not occur
        isValid = false;

    } else if (['date-stamp'].includes(type) && isNaN(Date.parse(val1))) { // A date, but not a valid date-stamp
        isValid = false

    } else if (['date-period'].includes(type) && !sfdateperiods.some((sfd) => sfd.val === val2)) { // A date period, without a valid period indicator
        isValid = false

    } else if (['amount'].includes(type) && !currencies.some((ccy) => ccy.iso === val1) ) { // An amount but without a valid ccy
        isValid = false

    } else if ( // Check whether a number is NaN (parseFloat deals with whitespace)
        (['number', 'date-period'].includes(type) && (isNaN(val1) || isNaN(parseFloat(val1)))) ||
        (['amount', 'list-custom'].includes(type) && (isNaN(val2) || isNaN(parseFloat(val2))))
    ) { 
        isValid = false

    //} else if (['email'].includes(type) && !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val1)) {
    } else if (['email'].includes(type) && !/^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val1)) {
        isValid = false

    } else if (['link'].includes(type) && !/(\S+)\.([a-z]{2,}?)(.*?)( |,|$|\.)/.test(val1) ) {
        isValid = false

    } else if (['list-standard'].includes(type) && (!['country', 'state'].includes(val1) || 
    (val1 === 'country' && !countries.some((c) => c.label === val2)) || (val1 === 'state' && !states.some((c) => c.label === val2)))) {
        isValid = false

    }

    return isValid;

}
  
export { smartfieldValueValidation }