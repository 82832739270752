import { getHtmlForClause } from '.'

const axios = require('axios');
const dayjs = require('dayjs')

function sendCommentEmailToUser (module, rel, orgurl, agrID, clause, recipient, cptyLegalName, agrFullName, creator, 
    filteredComments, isPublic, isTemplating, isTerms) {

    const baseURL = ['https://getcanveo.com', 'https://www.getcanveo.com'].includes(window.location.origin) ? 'https://app.getcanveo.com' : window.location.origin

    let isCustomLink = rel !== undefined && rel !== null && rel.customURL !== undefined && rel.customURL.length > 10
    let needsToCreatePassword = recipient.lastLoginDate === undefined && (recipient.password === undefined || recipient.password.length === 20)

    let viewLink = 
        isTemplating ? 
            (baseURL + '/core/loginp/_o/' + recipient._id + '/' + module + '/' + (isTerms ? 'terms' : 'template') + '/' + agrID + '/' + clause.singleClauseID) :
        recipient.role === 'Counterparty' && isCustomLink ? // Scenario 1: Cpty & CustomLink
            (baseURL + '/network/' + orgurl + '/' + rel.customURL + '/' + recipient._id + '/' + agrID + '/' + clause.singleClauseID) : 
        needsToCreatePassword ? // Scenario 2: Create Password
            (baseURL + '/core/createpassword/' + recipient.password) 
        : // Scenario 3: Fallback - view agreement through Login
            (baseURL + '/core/loginp/_a/' + recipient._id + '/' + module + '/' + rel._id + '/' + agrID + '/' + clause.singleClauseID)
    
    let viewText = 
        isTerms ? 
            'View Terms' :
        isTemplating ? // Scenario 0: A template, ie. recipient is legal or admin
            'View Template' :
        recipient.role === 'Counterparty' && isCustomLink ? // Scenario 1: Cpty & CustomLink
            'View Agreement' : 
        needsToCreatePassword ? // Scenario 2: Create Password
            'Create Password'
        : // Scenario 3: Fallback - view agreement through Login
            'View Agreement'

    let whiteLabel = rel !== undefined && rel !== null && rel.whiteLabel !== undefined && rel.whiteLabel !== null &&
        rel.whiteLabel.color !== undefined && rel.whiteLabel.contrastColor !== undefined && recipient.role === 'Counterparty' ? rel.whiteLabel : null;

    let emailBody = ''

    emailBody = emailBody + `
        <table width="100%" cellspacing="0" cellpadding="0"><tr><td align="center" style="padding: 20px 0px 20px 0px;">
        <table cellspacing="10" cellpadding="0">
        <tr>
            <td colspan="2" align="center">
                <table cellspacing="10" cellpadding="0"><tr>
                <td style="border-radius: 6px; background-color: ` + (whiteLabel !== null ? whiteLabel.color : '#E80064') + `;">
                <a href="` + viewLink + `" target="_blank" 
                style="padding: 4px 20px;border: 1px solid ` + (whiteLabel !== null ? whiteLabel.color : '#E80064') + `;border-radius: 6px;font-size: 14px;color: ` + (whiteLabel !== null ? whiteLabel.contrastColor : '#FFFFFF') + `;text-decoration: none;font-weight: bold;display: inline-block;">
                ` + viewText + `
                </a>
                </td>
                </tr></table>
            </td>
        </tr>
        </table>
        </td></tr></table>`    

    // PART 3: HTML version of the clause with redlines
    emailBody = emailBody + getHtmlForClause(clause); 

    // PART 4: Get most recent three comments and include in the email body
    if(filteredComments.length > 0) {
        emailBody = emailBody + `
            <table width="100%" cellspacing="0" cellpadding="0"><tr><td align="center">
            <div style="max-width: 320px; margin: 30px 0px 40px 0px;">
            <p style="text-align: center; font-size: 14px; margin: 20px 0px 20px 0px;"><b>Recent comments</b></p>`
        
        filteredComments
        .sort((a,b) => (a.creationDate < b.creationDate) ? 1 : ((b.creationDate < a.creationDate) ? -1 : 0))
        .slice(0,4)
        .forEach((c) => {

            if(['===RESOLVED===', '===REOPENED==='].includes(c.comment)) {

                emailBody = emailBody + `
                    <div style="width: 100%; margin-top: 20px; margin-bottom: 20px">
                        <p style="text-align: center; color: #827f8f; font-size: 13px"><b>` +
                        ((c.comment === '===RESOLVED===' ? "Cancelled" : c.comment === '===REOPENED===' ? "Approved" : "Updated")
                        +
                        ` on ` +
                        dayjs(c.creationDate).format('MMM D, YYYY h:mm A') + 
                        ` by ` + 
                        c.creatorDisplayName) +
                        `</b></p>
                    </div>`

            } else {

                let isLeft = (!isPublic && recipient._id === c.creatorID) || (isPublic && c.orgID === creator.orgID)
                let isPrimaryColor = isTemplating  || (c.orgID === rel.primaryOrgID)

                emailBody = emailBody + `
                    <div style="max-width: 300px; padding: 5px 20px 10px 20px; text-align: left; margin-bottom: 10px; border-radius: 0px; border: 1px solid #e0e0e0; color: #4d4b59; font-size: 12px;` +
                    (isLeft ? 
                        'border-top-right-radius: 10px; border-bottom-right-radius: 10px; border-left: 4px solid ' + (isPrimaryColor ? '#7243dd' : '#E80064') + ';' : 
                        'border-top-left-radius: 10px; border-bottom-left-radius: 10px; border-right: 4px solid ' + (isPrimaryColor ? '#7243dd' : '#E80064') + ';')
                    + `">
                        <p><b>` + c.creatorDisplayName + `</b>
                        <span style="color: #827f8f; font-size: 10px; margin-left: 10px">` + dayjs(c.creationDate).format('MMM D, YYYY h:mm A') + `</span></p>`;

                    c.comment.split('\n').forEach((cpar) => { 
                        emailBody = emailBody + '<p>' + cpar + '</p>'
                    })
                
                emailBody = emailBody + `
                    </div>`;
            }
        })
        emailBody = emailBody + `</div></td></tr></table>`
    }
    axios.post('/mail/commentmention', {
        recipient: recipient.email,
        creatorFullName: creator.displayName,
        agrFullName: agrFullName,
        cptyName: cptyLegalName,
        emailBody: emailBody,
        isPublic: isPublic,
        isTemplating: isTerms ? 'terms' : isTemplating ? 'template' : null,
        whiteLabel: whiteLabel,
    }).catch((err) => console.log("e", err))
}

export { sendCommentEmailToUser }