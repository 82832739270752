export const FETCH_LOGGEDINUSER_PENDING = 'FETCH_LOGGEDINUSER_PENDING';
export const FETCH_LOGGEDINUSER_ERROR = 'FETCH_LOGGEDINUSER_ERROR';
export const FETCH_LOGGEDINUSER_SUCCESS = 'FETCH_LOGGEDINUSER_SUCCESS';

export const FETCH_USERS_PENDING = 'FETCH_USERS_PENDING';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';
export const ADD_USER = 'ADD_USER';
export const REMOVE_USER = 'REMOVE_USER';

export const FETCH_USERORG_PENDING = 'FETCH_USERORG_PENDING';
export const FETCH_USERORG_ERROR = 'FETCH_USERORG_ERROR';
export const FETCH_USERORG_SUCCESS = 'FETCH_USERORG_SUCCESS';

export const FETCH_ORGPROFILE_PENDING = 'FETCH_ORGPROFILE_PENDING'; 
export const FETCH_ORGPROFILE_ERROR = 'FETCH_ORGPROFILE_ERROR'; 
export const FETCH_ORGPROFILE_SUCCESS = 'FETCH_ORGPROFILE_SUCCESS';

export const FETCH_ORGLABELS_PENDING = 'FETCH_ORGLABELS_PENDING';
export const FETCH_ORGLABELS_ERROR = 'FETCH_ORGLABELS_ERROR';
export const FETCH_ORGLABELS_SUCCESS = 'FETCH_ORGLABELS_SUCCESS';
export const FETCH_ORGLABELS_DONE = 'FETCH_ORGLABELS_DONE';
export const ADD_ORGLABEL = 'ADD_ORGLABEL';
export const UPDATE_ORGLABEL = 'UPDATE_ORGLABEL';
export const REMOVE_ORGLABEL = 'REMOVE_ORGLABEL';

export const FETCH_ORGSMARTFIELDS_PENDING = 'FETCH_ORGSMARTFIELDS_PENDING';
export const FETCH_ORGSMARTFIELDS_SUCCESS = 'FETCH_ORGSMARTFIELDS_SUCCESS';
export const FETCH_ORGSMARTFIELDS_DONE = 'FETCH_ORGSMARTFIELDS_DONE';
export const FETCH_ORGSMARTFIELDS_ERROR = 'FETCH_ORGSMARTFIELDS_ERROR';
export const ADD_ORGSMARTFIELD = 'ADD_ORGSMARTFIELD';
export const UPDATE_ORGSMARTFIELD = 'UPDATE_ORGSMARTFIELD';
export const REMOVE_ORGSMARTFIELD = 'REMOVE_ORGSMARTFIELD';

export const FETCH_NETWORK_PENDING = 'FETCH_NETWORK_PENDING';
export const FETCH_NETWORK_SUCCESS = 'FETCH_NETWORK_SUCCESS';
export const FETCH_NETWORK_ERROR = 'FETCH_NETWORK_ERROR';

export const FETCH_ORGPROFILEAGRTEMPLATES_PENDING = 'FETCH_ORGPROFILEAGRTEMPLATES_PENDING';
export const FETCH_ORGPROFILEAGRTEMPLATES_SUCCESS = 'FETCH_ORGPROFILEAGRTEMPLATES_SUCCESS';
export const FETCH_ORGPROFILEAGRTEMPLATES_ERROR = 'FETCH_ORGPROFILEAGRTEMPLATES_ERROR';

export const FETCH_SUBS_PENDING = 'FETCH_SUBS_PENDING';
export const FETCH_SUBS_SUCCESS = 'FETCH_SUBS_SUCCESS';
export const FETCH_SUBS_ERROR = 'FETCH_SUBS_ERROR';
export const ADD_SUBS = 'ADD_SUBS';
export const REMOVE_SUBS = 'REMOVE_SUBS';
export const UPDATE_SUBS = 'UPDATE_SUBS';

export const FETCH_CPENTS_PENDING = 'FETCH_CPENTS_PENDING';
export const FETCH_CPENTS_SUCCESS = 'FETCH_CPENTS_SUCCESS';
export const FETCH_CPENTS_ERROR = 'FETCH_CPENTS_ERRORFETCH_CPENTS_ERROR';
export const ADD_CPENT = 'ADD_CPENT';
export const REMOVE_CPENT = 'REMOVE_CPENT';
export const UPDATE_CPENT = 'UPDATE_CPENT';

export const FETCH_ORGPROFILESUBS_PENDING = 'FETCH_ORGPROFILESUBS_PENDING'; 
export const FETCH_ORGPROFILESUBS_SUCCESS = 'FETCH_ORGPROFILESUBS_SUCCESS'; 
export const FETCH_ORGPROFILESUBS_ERROR = 'FETCH_ORGPROFILESUBS_ERROR';

export const FETCH_RELS_PENDING = 'FETCH_RELS_PENDING';
export const FETCH_RELS_SUCCESS = 'FETCH_RELS_SUCCESS';
export const FETCH_RELS_ERROR = 'FETCH_RELS_ERROR';
export const UPDATE_REL = 'UPDATE_REL';
export const ADD_REL = 'ADD_REL';
export const REMOVE_REL = 'REMOVE_REL';
export const SET_ACTIVE_REL = 'SET_ACTIVE_REL';

export const FETCH_AGRS_PENDING = 'FETCH_AGRS_PENDING';
export const FETCH_AGRS_ERROR = 'FETCH_AGRS_ERROR';

export const FETCH_AGR_PENDING = 'FETCH_AGR_PENDING';
export const FETCH_AGR_ERROR = 'FETCH_AGR_ERROR';

export const FETCH_AGRTYPES_PENDING = 'FETCH_AGRTYPES_PENDING';
export const FETCH_AGRTYPES_SUCCESS = 'FETCH_AGRTYPES_SUCCESS';
export const FETCH_AGRTYPES_ERROR = 'FETCH_AGRTYPES_ERROR';
export const UPDATE_AGRTYPE = 'UPDATE_AGRTYPE';
export const ADD_AGRTYPE = 'ADD_AGRTYPE';
export const REMOVE_AGRTYPE = 'REMOVE_AGRTYPE';

/*
export const FETCH_CLAUSETYPES_PENDING = 'FETCH_CLAUSETYPES_PENDING';
export const FETCH_CLAUSETYPES_SUCCESS = 'FETCH_CLAUSETYPES_SUCCESS';
export const FETCH_CLAUSETYPES_ERROR = 'FETCH_CLAUSETYPES_ERROR';*/

export const FETCH_AGRVERSIONS_ERROR = 'FETCH_AGRVERSIONS_ERROR';

export const FETCH_AGREXEC_PENDING = 'FETCH_AGREXEC_PENDING'; 
export const FETCH_AGREXEC_ERROR = 'FETCH_AGREXEC_ERROR';
export const FETCH_AGREXEC_SUCCESS = 'FETCH_AGREXEC_SUCCESS';

export const FETCH_ORGAGRTEMPLATES_PENDING = 'FETCH_ORGAGRTEMPLATES_PENDING'; 
export const FETCH_ORGAGRTEMPLATES_SUCCESS = 'FETCH_ORGAGRTEMPLATES_SUCCESS'; 
export const FETCH_ORGAGRTEMPLATES_ERROR = 'FETCH_ORGAGRTEMPLATES_ERROR';
export const UPDATE_ORGAGRTEMPLATE = 'UPDATE_ORGAGRTEMPLATE';
export const ADD_ORGAGRTEMPLATE = 'ADD_ORGAGRTEMPLATE';
export const DELETE_ORGAGRTEMPLATE = 'DELETE_ORGAGRTEMPLATE';

export const FETCH_NOTIFICATIONS_PENDING = 'FETCH_NOTIFICATIONS_PENDING';
export const FETCH_NOTIFICATIONS_DONE = 'FETCH_NOTIFICATIONS_DONE';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_ERROR = 'FETCH_NOTIFICATIONS_ERROR';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';

export const FETCH_CERTS_PENDING = 'FETCH_CERTS_PENDING';
export const FETCH_CERTS_DONE = 'FETCH_CERTS_DONE';
export const FETCH_CERTS_SUCCESS = 'FETCH_CERTS_SUCCESS';
export const FETCH_CERTS_ERROR = 'FETCH_CERTS_ERROR';
export const ADD_CERT = 'ADD_CERT';
export const UPDATE_CERT = 'UPDATE_CERT';
export const REMOVE_CERT = 'REMOVE_CERT';

export const FETCH_ORGPROFILECERTS_PENDING = 'FETCH_ORGPROFILECERTS_PENDING';
export const FETCH_ORGPROFILECERTS_SUCCESS = 'FETCH_ORGPROFILECERTS_SUCCESS';
export const FETCH_ORGPROFILECERTS_ERROR = 'FETCH_ORGPROFILECERTS_ERROR';

export const FETCH_ACTIVITYLOG_PENDING = 'FETCH_ACTIVITYLOG_PENDING';
export const FETCH_ACTIVITYLOG_SUCCESS = 'FETCH_ACTIVITYLOG_SUCCESS';
export const FETCH_ACTIVITYLOG_ERROR = 'FETCH_ACTIVITYLOG_ERROR';
export const ADD_ACTIVITYLOG = 'ADD_ACTIVITYLOG';

export const INCREASE_DASHBOARD_VISITS = 'INCREASE_DASHBOARD_VISITS';
export const DEFINE_SESSION_EXPIRY = 'DEFINE_SESSION_EXPIRY';
export const DEFINE_SMART_MODE = 'DEFINE_SMART_MODE';